import * as Yup from "yup";
import createPostcodeSchema from "libs/validators/yup/createPostcodeSchema";
import { FormFieldsDoctor } from "libs/types/mhaForms";
import { FormSection } from "../../index";

const v1: FormSection = {
  title: "Second Practioner Details",
  state: {
    secondPractitionerName: "",
    secondPractitionerAddress: {
      city: "",
      postcode: "",
      address: "",
    },
  },

  validation: Yup.object().shape({
    secondPractitionerName: Yup.string().trim().required("Please enter the name of the second practitioner"),

    secondPractitionerAddress: Yup.object().shape({
      address: Yup.string(),
      city: Yup.string(),
      postcode: createPostcodeSchema({
        invalidPostcodeMessage: "Please enter a valid address",
        required: true,
        requiredMessage: "Please enter an address",
      }),
    }),
  }),
  componentElements: [
    {
      component: "Text",
      componentProps: {
        instruction: "I",
        guidance: "(PRINT full name and address of second practitioner)",
      },
      fullWidth: true,
    },
    {
      component: "TextInput",
      fieldName: FormFieldsDoctor.SecondPractitionerName,
      componentProps: {
        label: "Name",
      },
    },
    {
      component: "Location",
      fieldName: FormFieldsDoctor.SecondPractitionerAddress,
      componentProps: {
        label: "Address",
      },
    },
  ],
};

const v2: FormSection = {
  title: "Second Practioner Details",
  state: {
    secondPractitionerName: "",
    secondPractitionerAddress: {
      city: "",
      postcode: "",
      address: "",
    },
    secondPractitionerEmailAddress: "",
  },

  validation: Yup.object().shape({
    secondPractitionerName: Yup.string().trim().required("Please enter the name of the second practitioner"),

    secondPractitionerAddress: Yup.object().shape({
      address: Yup.string(),
      city: Yup.string(),
      postcode: createPostcodeSchema({
        invalidPostcodeMessage: "Please enter a valid address",
        required: true,
        requiredMessage: "Please enter an address",
      }),
    }),
    secondPractitionerEmailAddress: Yup.string()
      .trim()
      .email("Please enter a valid email address")
      .required("Please enter an email address"),
  }),
  componentElements: [
    {
      component: "Text",
      componentProps: {
        instruction: "I",
        guidance:
          "(PRINT full name, address and, if sending by means of electronic communication, email address of second practitioner)",
      },
      fullWidth: true,
    },
    {
      component: "TextInput",
      fieldName: FormFieldsDoctor.SecondPractitionerName,
      componentProps: {
        label: "Name",
      },
    },
    {
      component: "Location",
      fieldName: FormFieldsDoctor.SecondPractitionerAddress,
      componentProps: {
        label: "Address",
      },
    },
    {
      component: "TextInput",
      fieldName: FormFieldsDoctor.SecondPractitionerEmailAddress,
      componentProps: {
        label: "Email Address",
      },
    },
  ],
};

export default {
  v1,
  v2,
};
