import React, { useState } from "react";
import { StyleSheet } from "react-native";

import { palette } from "@/theme";
import { useOnAppBlur, useOnAppFocus } from "@/hooks";

import FadeView from "../FadeView";
import { Logo } from "../Logo";

const PrivacyScreen = () => {
  const [isVisible, setVisible] = useState(false);

  useOnAppBlur(() => {
    setVisible(true);
  });

  useOnAppFocus(() => {
    setVisible(false);
  });

  return (
    <FadeView duration={200} isVisible={isVisible} style={styles.container}>
      <Logo />
    </FadeView>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    backgroundColor: palette.white,
    height: "100%",
    justifyContent: "center",
    position: "absolute",
    width: "100%",
    zIndex: 10,
  },
});

export default PrivacyScreen;
