import { StyleSheet } from "react-native";

const css = StyleSheet.create({
  label: {
    flexShrink: 0,
    paddingTop: 4,
    paddingBottom: 3,
    paddingHorizontal: 8,
    borderRadius: 6,
  },

  content: {
    flexShrink: 1,
    paddingRight: 24,
  },
  contentSmall: {
    paddingRight: 14,
  },

  row: {
    flexDirection: "row-reverse",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  rowSmall: {
    flexDirection: "column",
    alignItems: "flex-start",
  },
});

export default css;
