import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { Animated, StyleSheet, ViewStyle } from "react-native";

interface PropTypes {
  animateIn?: boolean;
  animateOut?: boolean;
  duration?: number;
  isVisible?: boolean;
  style?: ViewStyle | Array<ViewStyle>;
}

/**
 * Fade this components children in and out of view before toggling
 * render state.
 *
 * @param props
 * @link https://goshakkk.name/react-native-animated-appearance-disappearance/
 */
const FadeView: FunctionComponent<PropTypes> = props => {
  const { animateIn = true, animateOut = true, children, duration, isVisible, style } = props;
  const [visible, setVisible] = useState(isVisible);
  const opacityAnimation = useRef(new Animated.Value(isVisible ? 1 : 0));

  const interpolatedOpacity = opacityAnimation.current.interpolate({
    inputRange: [0, 1],
    outputRange: [0, 1],
  });

  const animatedStyle = { opacity: interpolatedOpacity };
  const combinedStyles = [styles.container, style, animatedStyle];

  const fadeIn = () => {
    Animated.timing(opacityAnimation.current, {
      duration: animateIn ? duration : 0,
      toValue: 1,
      useNativeDriver: true,
    }).start(() => setVisible(true));
  };

  const fadeOut = () => {
    Animated.timing(opacityAnimation.current, {
      duration: animateOut ? duration : 0,
      toValue: 0,
      useNativeDriver: true,
    }).start(() => setVisible(false));
  };

  useEffect(() => {
    /**
     * This part is confusing as when the animation has completed, we call
     * `setVisible(true)` anyway. However, without this the animation does
     * not play.
     */
    if (isVisible) {
      setVisible(true);
    }

    isVisible ? fadeIn() : fadeOut();
  }, [fadeIn, fadeOut, isVisible]);

  return <Animated.View style={visible ? combinedStyles : animatedStyle}>{visible ? children : null}</Animated.View>;
};

FadeView.defaultProps = {
  duration: 300,
  isVisible: false,
};

const styles = StyleSheet.create({
  container: {
    height: "100%",
    width: "100%",
  },
});

export default FadeView;
