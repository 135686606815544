import gql from "graphql-tag";
import { LocationInput, LocationNameInput, ClaimStatus, AvailabilityType, FormStatus } from "libs/types/API";
import { FormType } from "libs/types/mhaForms";

// Fragments with params available once https://github.com/graphql/graphql-spec/issues/204 is stable

// Fragments broken down by type

// CCG
export const CCGFragment = gql`
  fragment CCGCondensed on Organisation {
    id
    featureFlags
    name
  }
`;

export const GPSurgeryFragment = gql`
  fragment GPSurgeryFields on GPSurgery {
    id
    name
    postcode
  }
`;

// Location
export const LocationCoordsFragment = gql`
  fragment latLon on Location {
    lat
    lon
  }
`;

// LocationName
export const LocationNameFragment = gql`
  fragment fullLocation on LocationName {
    city
    postcode
    address
    addressNotes
  }
`;

export const LocationWithoutAddressNotes = gql`
  fragment LocationWithoutAddressNotes on LocationName {
    city
    postcode
    address
  }
`;

// AmhpTeam
export const AmhpTeamIdAndName = gql`
  fragment AmhpTeamIdAndNameParts on AmhpTeam {
    id
    name
  }
`;

export const AmhpTeamAssessments = gql`
  fragment AmhpTeamAssessmentParts on AmhpTeam {
    assessments
  }
`;

export interface AmhpTeamUsersResponse {
  id: string;
  amhp: {
    id: string;
    name: string;
    deleted?: boolean;
  };
}

export const AmhpTeamUsers = gql`
  fragment AmhpTeamUsers on AmhpTeam {
    users(limit: 500) {
      items {
        id
        amhp {
          id
          name
          deleted
        }
      }
    }
  }
`;

export interface AmhpTeamCommonAddressesResponse {
  id: string;
  location: LocationInput;
  locationName: LocationNameInput;
  ccg: {
    id: string;
    name: string;
    featureFlags: string;
  };
}

export const AmhpTeamCommonAddresses = gql`
  fragment AmhpTeamCommonAddresses on AmhpTeam {
    commonAddresses(limit: 50) {
      items {
        id
        location {
          ...latLon
        }
        locationName {
          ...fullLocation
        }
        ccg {
          ...CCGCondensed
        }
      }
    }
  }
  ${CCGFragment}
  ${LocationCoordsFragment}
  ${LocationNameFragment}
`;

// Organisation
export const OrganisationIdName = gql`
  fragment OrganisationIdAndName on Organisation {
    id
    name
    rotas {
      id
      name
    }
  }
`;

export const OrganisationIdNameTypeFeatures = gql`
  fragment OrganisationIdNameTypeFeatures on Organisation {
    ...OrganisationIdAndName
    type
    featureFlags
  }
  ${OrganisationIdName}
`;

export interface DoctorCondensedResponse {
  id: string;
  name: string;
  phone: string | null;
  email: string;
}

// S12Doctor
export const DoctorCondensedFragment = gql`
  fragment DoctorCondensed on S12Doctor {
    id
    name
    phone
    email
    gmc
  }
`;

export const DoctorFragment = gql`
  fragment DoctorDefaults on S12Doctor {
    legalName
    s12Expiry
    phone2
    employer
    jobTitle
    responsibleOfficer
    lineManager
    specialties
    languages
    gender
    notes
    hideFromSearch
    availabilityLocations {
      ...latLon
    }
    availabilityPostcodes
    ...DoctorCondensed
  }
  ${DoctorCondensedFragment}
`;

// DoctorVisits
export const DoctorVisitsCondensed = gql`
  fragment DoctorVisitsCondensed on DoctorVisit {
    id
    time
    notes
    doctor {
      ...DoctorCondensed
    }
  }
  ${DoctorCondensedFragment}
`;

// DoctorVisits for AMHP Assessment
export const DoctorVisitsCondensedForAssessment = gql`
  fragment DoctorVisitsCondensed on DoctorVisit {
    id
    time
    notes
    doctor {
      ...DoctorCondensed
    }
    claim {
      status
    }
  }
  ${DoctorCondensedFragment}
`;

export const DoctorVisitsWithClaimId = gql`
  fragment DoctorVisitsWithClaimId on DoctorVisit {
    ...DoctorVisitsCondensed
    doctorVisitClaimId
    claimDisplayId
    claimStatus
  }
  ${DoctorVisitsCondensed}
`;

// AMHP View of doctor visits
export interface DoctorVisitsWithClaimIdResponse {
  id: string;
  time: string;
  notes?: string[];
  claim: {
    locationText: string;
    locationType: string;
    claimPostcode: string;
    organisation: {
      id: string;
      name: string;
      featureFlags: string;
    };
  };
  doctor: {
    id: string;
    name: string;
    phone: string | null;
    email: string;
  };
  doctorVisitClaimId?: string;
  claimDisplayId?: string;
  claimStatus?: ClaimStatus;
}

// Forms
export interface AssessmentFormListResponse {
  forms: {
    items: Array<{
      id: string;
      type: FormType;
      status: FormStatus;
      signed?: string[]; // null when not signed
      authors: string[];
      notes: string[]; // formatted as EVENT_TYPE@USER_FULL_NAME(optional EMAIL or PHONE)@TIMESTAMP
      updatedAt: string;
      createdBy: string;
      data?: {
        sections: Array<{ name: string }>;
      };
      isDeleted?: boolean;
      createdAt: string;
    } | null> | null;
  };
}
export const AssessmentFormListFragment = gql`
  fragment AssessmentFormList on Assessment {
    forms(limit: 100, gsi1sk: { eq: "0000" }) {
      items {
        id
        type
        status
        signed
        authors
        notes
        updatedAt
        createdBy
        data {
          sections {
            name
          }
        }
        isDeleted
        createdAt
      }
    }
  }
`;

// MHT
export const MhtDefaults = gql`
  fragment MhtDefaults on MHT {
    id
    abbreviation
    organisation {
      ...OrganisationIdAndName
    }
  }
  ${OrganisationIdName}
`;

export const MhtEmployerDefaults = gql`
  fragment MhtEmployerDefaultItems on MHTS12Doctor {
    id
    mht {
      ...MhtDefaults
    }
  }
  ${MhtDefaults}
`;

// Claim
export const ClaimFragments = gql`
  fragment DefaultClaimParts on Claim {
    id
    displayId
    status
  }
`;

export const fieldsOnCCGClaim = gql`
  fragment fieldsOnClaim on Claim {
    doctor {
      ...DoctorCondensed
      mhtEmployers {
        items {
          ...MhtEmployerDefaultItems
        }
      }
    }
    amhp {
      id
      name
      email
      phone
    }
    claimOrganisationId
    claimVisitId
    organisation {
      featureFlags
      ...OrganisationIdAndName
      phone
      email
      locationName {
        ...fullLocation
      }
    }
    claimData {
      fuelType
      engineSize
      billingAddress {
        address
        city
        postcode
        addressNotes
      }
      billingCompanyName
      vatRegistration
    }
    invoiceData {
      assessmentFee
      travelFee
      locationOfAssessment
    }
    lineManager
    defaultMHT
    patientNhsNumber
    locationText
    claimPostcode
    locationType
    assessmentPostcode
    visitDate
    updatedAt
    createdAt
    receivedDate
    patientInitials
    patientFullName
    startingPostcode
    additionalExpenses
    mileage
    assessmentTookPlace
    additionalInformation
    doctorAdditionalConf
    isIndependentDoctor
    doctorEmployedStatus
    notes
    assigneeId
    assigneeName
    amhpTeamId
    ...DefaultClaimParts
  }
  ${ClaimFragments}
  ${OrganisationIdName}
  ${LocationNameFragment}
  ${DoctorCondensedFragment}
  ${MhtEmployerDefaults}
`;

export const ClaimsFragmentWithoutAssignee = gql`
  fragment ClaimsFragmentWithoutAssignee on Organisation {
    id
    featureFlags
    claims(
      sortDirection: DESC
      limit: 10
      statusAssigneeIdReceivedDate: { beginsWith: { status: $status } }
      filter: $filter
      nextToken: $nextToken
    ) {
      items {
        ...fieldsOnClaim
      }
      nextToken
    }
  }
  ${fieldsOnCCGClaim}
`;

export const RejectedApprovedClaimsFragment = gql`
  fragment RejectedApprovedClaims on Organisation {
    id
    featureFlags
    claims(
      sortDirection: DESC
      limit: 10
      statusAssigneeIdReceivedDate: { beginsWith: { status: $status, assigneeId: "!!!", receivedDate: "" } }
      filter: $filter
      nextToken: $nextToken
    ) {
      items {
        ...fieldsOnClaim
      }
      nextToken
    }
  }
  ${fieldsOnCCGClaim}
`;

export const TeamClaimsFragment = gql`
  fragment TeamClaims on Organisation {
    id
    featureFlags
    claims(
      sortDirection: ASC
      limit: 10
      statusAssigneeIdReceivedDate: { gt: { status: under_review, assigneeId: "!!", receivedDate: "" } }
      nextToken: $nextToken
    ) {
      items {
        ...fieldsOnClaim
      }
      nextToken
    }
  }
  ${fieldsOnCCGClaim}
`;

export const UnderReviewClaimsFragment = gql`
  fragment UnderReviewClaims on Organisation {
    id
    featureFlags
    claims(
      sortDirection: DESC
      limit: $claimLimit
      statusAssigneeIdReceivedDate: { beginsWith: { status: under_review, assigneeId: $userId, receivedDate: "" } }
      #filter: $filter
      nextToken: $nextToken
    ) {
      items {
        ...fieldsOnClaim
      }
      nextToken
    }
  }
  ${fieldsOnCCGClaim}
`;

// GP Practice
export const GpPracticeDefaults = gql`
  fragment GpPracticeDefaultFields on GPPracticeSearchItem {
    id
    name
    postcode
  }
`;

export const GpOrgDefaults = gql`
  fragment GpOrgDefaultFields on GPOrgSearchItem {
    id
    roleId
    name
    postcode
  }
`;

export const gpPracticeFound = gql`
  fragment GpPracticeFound on GPPractice {
    id
    name
    postcode
  }
`;

export interface AvailabilityResponse {
  id: string;
  type: AvailabilityType;
  rrule: string;
  endHour: number; // See details, could start with 99, very tricky
  mht?: {
    id: string;
    abbreviation: string;
    organisation: {
      id: string;
      name: string;
    };
  };
  oncall?: boolean;
  location: LocationInput;
  locationName: LocationNameInput;
}

// Availability
export const AvailabilitiesFragment = gql`
  fragment availabilitiesDefault on Availability {
    id
    type
    rrule
    endDate
    endHour
    rotaId
    rotaName
    mht {
      ...MhtDefaults
    }
    oncall
    rotaId
    rotaName
    location {
      ...latLon
    }
    locationName {
      ...fullLocation
    }
  }
  ${MhtDefaults}
  ${LocationNameFragment}
  ${LocationCoordsFragment}
`;

export const AvailabilitiesExtendedFragment = gql`
  fragment availabilitiesExtended on Availability {
    ...availabilitiesDefault
    notes
    contractList {
      name
    }
  }
  ${AvailabilitiesFragment}
`;

// Assessment
export const AssessmentDefaults = gql`
  fragment AssessmentDefaults on Assessment {
    id
    assessmentDate
    patientName
    location {
      ...latLon
    }
    locationName {
      ...fullLocation
    }
    amhpTeam {
      ...AmhpTeamIdAndNameParts
    }
    amhp {
      id
      name
    }
    doctorVisits {
      items {
        ...DoctorVisitsCondensed
      }
    }
    contactedDoctors(limit: 50) {
      items {
        id
        s12Doctor {
          ...DoctorCondensed
        }
      }
    }
  }
  ${LocationCoordsFragment}
  ${LocationNameFragment}
  ${DoctorCondensedFragment}
  ${DoctorVisitsCondensed}
`;
