import React, { useContext } from "react";
import { View, StyleSheet } from "react-native";
import dayjs from "dayjs";
import { TypographyType } from "@/models/Typography";
import Text from "../../Text";
import DualDatePicker from "../../DatePicker/DualDatePicker";
import { Checkbox } from "../../Checkbox/Checkbox";

import { TextInput } from "../../TextInput";
import { spacing, color } from "../../../theme";
import { setEndOfWeek } from "libs/dates";
import {
  AvailabilityControllerActionTypes,
  AvailabilityModelActionTypes,
  StateContext,
} from "libs/state/availability/initialState";
import { AvailabilityContext } from "../DoctorAvailabilityProvider";

export function AvailabilityRepetition(props: { noPadding?: boolean }) {
  const { state, dispatch, controllerDispatch } = useContext<StateContext>(AvailabilityContext);
  const { noPadding = false } = props;
  const year2036 = new Date(2036, 0, 1);
  const thisMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
  return (
    <View style={[styles.background, noPadding ? styles.noPadding : styles.padding]}>
      <View style={styles.repeatWeeklyWrapper}>
        <Text format={TypographyType.Regular}>every</Text>
        <TextInput
          style={styles.input}
          keyboardType="numeric"
          onBlur={() => {
            if (
              state.repeatWeeklyCount === "" ||
              !Number(state.repeatWeeklyCount) ||
              Number(state.repeatWeeklyCount) < 1
            )
              dispatch({
                type: AvailabilityModelActionTypes.REPEAT_WEEKLY_COUNT,
                payload: "1",
              });
          }}
          maxLength={2}
          onChangeText={(int: string) =>
            dispatch({
              type: AvailabilityModelActionTypes.REPEAT_WEEKLY_COUNT,
              payload: int,
            })
          }
          value={state.repeatWeeklyCount}
        />
        <Text format={TypographyType.Regular}>week(s)</Text>
      </View>
      <Checkbox
        status={state.eventSeriesHasEnd}
        label="Set end date"
        onPress={() => {
          dispatch({
            type: AvailabilityModelActionTypes.SET_END_DATE,
            payload: setEndOfWeek(state.availabilities[0].date),
          });
          controllerDispatch({
            type: AvailabilityControllerActionTypes.TOGGLE_HAS_END_DATE,
          });
        }}
      />
      {state.eventSeriesHasEnd && (
        <DualDatePicker
          label="End Date"
          inputColor={color.backgroundGrey}
          noIcon={true}
          max={year2036}
          min={thisMonth}
          value={state.eventSeriesEndDate || dayjs()}
          onValueChange={value =>
            dispatch({
              type: AvailabilityModelActionTypes.SET_END_DATE,
              payload: value,
            })
          }
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  background: {
    backgroundColor: color.backgroundGrey,
  },
  noPadding: {
    paddingVertical: 0,
    paddingHorizontal: 0,
  },
  padding: {
    paddingVertical: 20,
    paddingHorizontal: 30,
  },
  repeatWeeklyWrapper: {
    flexDirection: "row",
    paddingBottom: spacing[30],
    alignItems: "center",
  },
  input: {
    marginHorizontal: spacing[20],
    // minWidth: 100,
    maxWidth: 100,
    borderBottomColor: color.backgroundGrey,
    borderBottomWidth: 1,
  },
});
