import AttachableFormList from "./AttachableFormList";
import AttachToAssessment from "./AttachToAssessment";
import DashboardFormInbox from "./DashboardFormInbox";
import DashboardAssessments from "./DashboardAssessments";
import SubmittedFormsMultiSelect from "./SubmittedFormsMultiSelect";

export default {
  AttachableFormList,
  AttachToAssessment,
  DashboardFormInbox,
  DashboardAssessments,
  SubmittedFormsMultiSelect,
};
