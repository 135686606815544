import dayjs from "dayjs";
import { FormattedPdfData, MHAFormSection, FormFieldType, MHAFormField } from "libs/types/mhaForms";

export const fieldToPdfValue = (field: MHAFormField) => {
  switch (field.type) {
    case FormFieldType.Date:
      return field.value ? dayjs(field.value) : "";
    case FormFieldType.Location:
      return field.valueLocation;
    case FormFieldType.Checkbox:
      return field.valueArray;
    case FormFieldType.Selection:
      return field.value === "true";
    case FormFieldType.TextInput:
    default:
      return field.value;
  }
};

export const transformResponseToModelForPdf = (sections: MHAFormSection[]): FormattedPdfData => {
  return sections.reduce(
    (sections, section) => ({
      ...sections,
      [section.name]: section.fields.reduce(
        (fields, field) => ({
          ...fields,
          [field.name]: fieldToPdfValue(field), // as is fine here, MHAFormField is a stronger type that asserts the name is more than just a string
        }),
        {}
      ),
    }),
    {}
  );
};
