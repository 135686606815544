import React, { useEffect, useMemo } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";

import { DoctorProfile, UPDATE_DOCTOR_PROFILE } from "@/models/DoctorProfile";
import { TypographyType } from "@/models/Typography";

import { RouteKeys } from "@/navigationv2";
import { useIsWebView } from "@/hooks";
import { palette, spacing } from "@/theme";

import { Button } from "@/components/Button";
import { ContentWrap } from "@/components/ContentWrap";
import { SectionDivider } from "@/components/SectionDivider";
import Text from "@/components/Text";

import { Mod } from "./modules";
import { useNavigation } from "@react-navigation/native";
import { saveCurrentPushNotificationToken, savePushNotificationToken } from "@/utils/helpers";
import { useSetRecoilState } from "recoil";
import { pushToken as recoilPushToken } from "@/utils/recoil/index";
import { useMutation } from "@apollo/react-hooks";
import { UpdateDoctorFunc } from "../DoctorSetupForm/DoctorSetupForm.props";

interface DoctorSetupProps {
  doctor: DoctorProfile;
}
export const DoctorSetup = React.memo((props: DoctorSetupProps) => {
  const isWebView = useIsWebView();
  const navigation = useNavigation();
  const setPushToken = useSetRecoilState(recoilPushToken);
  const [updateDoctor] = useMutation<UpdateDoctorFunc>(UPDATE_DOCTOR_PROFILE);

  useEffect(() => {
    // saving current pushToken to recoil to quickly pull this value when logging out and running a mutation to delete from db
    saveCurrentPushNotificationToken(setPushToken);
  }, []);

  useEffect(() => {
    if (props.doctor.id)
      savePushNotificationToken(props.doctor.pushTokens, (pushTokens: string[]) =>
        updateDoctor({
          variables: {
            input: {
              id: props.doctor.id,
              pushTokens,
            },
          },
        })
      );
  }, [props.doctor]);

  const DoctorSetupWrapper = useMemo(() => (isWebView ? ContentWrap : React.Fragment), [isWebView]);
  const DoctorDetailsWrapper = useMemo(() => (isWebView ? React.Fragment : ContentWrap), [isWebView]);

  const porfileScreenStyles = useMemo(() => [styles.profileScreen, isWebView && styles.profileScreenWeb], [isWebView]);
  const doctorDetailsStyles = useMemo(() => [styles.doctorDetails, isWebView && styles.doctorDetailsWeb], [isWebView]);
  const doctorDetailsHeadingStyles = useMemo(
    () => [styles.doctorDetailsHeading, isWebView && styles.doctorDetailsHeadingWeb],
    [isWebView]
  );
  const sectionsStyles = useMemo(() => [styles.sections, isWebView && styles.sectionsWeb], [isWebView]);
  const sectionStyles = useMemo(() => [styles.section, isWebView && styles.sectionWeb], [isWebView]);

  const _onEditProfilePress = () => navigation.navigate(RouteKeys.EditDoctorProfileScreen);

  return (
    <DoctorSetupWrapper>
      <View style={porfileScreenStyles}>
        <View style={sectionsStyles}>
          {/* DIARY */}
          <View style={sectionStyles}>
            <Mod.DoctorDiarySection navigation={navigation} doctor={props.doctor} />
          </View>

          {!isWebView ? <SectionDivider short /> : null}

          {/* MHA FORMS */}
          <View style={sectionStyles}>
            <Mod.DoctorUnattachedForms navigation={navigation} siteName={props.doctor.siteName} />
          </View>
        </View>

        {!isWebView ? <SectionDivider short /> : null}

        {/* DETAILS */}
        <View style={doctorDetailsStyles}>
          <DoctorDetailsWrapper>
            <View style={doctorDetailsHeadingStyles}>
              <Text format={isWebView ? TypographyType.HeadingSmall : TypographyType.HeadingMedium}>Your Details</Text>
              <TouchableOpacity style={styles.doctorDetailsEditButtonTop} onPress={_onEditProfilePress}>
                <Text format={TypographyType.SmallBold} color={palette.blue}>
                  Edit
                </Text>
              </TouchableOpacity>
            </View>

            <Mod.DoctorDetailsList doctor={props.doctor} />

            {!isWebView ? (
              <Button style={styles.doctorDetailsEditButtonMobile} onPress={_onEditProfilePress}>
                Edit My Details
              </Button>
            ) : null}
          </DoctorDetailsWrapper>
        </View>
      </View>
    </DoctorSetupWrapper>
  );
});
DoctorSetup.displayName = "DoctorSetup";

const styles = StyleSheet.create({
  profileScreen: {
    flexDirection: "column",
  },
  profileScreenWeb: {
    flexDirection: "row-reverse",
  },

  detailsPaneToggle: {
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 3,
    borderTopWidth: 0,
    borderColor: palette.cloud,
    paddingVertical: spacing[10],
    borderBottomLeftRadius: 12,
    borderBottomRightRadius: 12,
    shadowColor: "rgba(255,255,255,0.95)",
    shadowRadius: 10,
    shadowOffset: { width: 0, height: -20 },
  },

  detailsPaneToggleText: {
    borderRadius: 8,
    paddingVertical: 7,
    paddingHorizontal: 14,
    backgroundColor: palette.blueSecondary,
  },

  doctorDetails: {
    width: "100%",
  },
  doctorDetailsWeb: {
    width: "35%",
    borderWidth: 3,
    borderRadius: 12,
    borderColor: palette.cloud,
    paddingVertical: spacing[20],
  },

  doctorDetailsEditButtonMobile: {
    marginTop: spacing[30],
  },

  doctorDetailsEditButtonTop: {
    backgroundColor: palette.blueSecondary,
    marginRight: spacing[20],
    borderRadius: 8,
    paddingVertical: 7,
    paddingHorizontal: 14,
  },

  doctorDetailsHeading: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  doctorDetailsHeadingWeb: {
    alignItems: "center",
    marginTop: spacing[5],
    marginBottom: spacing[20],
    paddingLeft: spacing[30],
  },

  sections: {
    paddingLeft: 0,
  },
  sectionsWeb: {
    flex: 1,
    paddingLeft: spacing[30],
  },

  section: {
    borderWidth: 0,
    borderColor: palette.cloud,
  },
  sectionWeb: {
    borderWidth: 3,
    borderRadius: 12,
    padding: spacing[30],
    marginBottom: spacing[30],
  },
});
