import React, { useMemo, useState } from "react";
import { StyleSheet, View, Platform } from "react-native";
import { TouchableRipple } from "react-native-paper";
import Collapsible from "react-native-collapsible";

import { TypographyType } from "@/models/Typography";
import { palette, spacing } from "../../theme";
import Icon, { IconName } from "../Icon";
import Text from "../Text";

interface AccordionProps {
  // make sure all first level children of Accordion have the correct props
  children: React.ReactElement<AccordionSectionProps>[];
  defaultActiveIndex?: number;
  marginTop?: number;
  marginBottom?: number;
}

export const Accordion: React.FC<AccordionProps> = props => {
  const [activeIndex, setActiveIndex] = useState<number>(
    props.defaultActiveIndex === undefined ? -1 : props.defaultActiveIndex
  );

  const accordionStyles = useMemo(
    () => [
      styles.accordion,
      {
        marginTop: props.marginTop ? props.marginTop : 0,
        marginBottom: props.marginBottom ? props.marginBottom : 0,
      },
    ],
    [props.marginTop, props.marginBottom]
  );

  const sections = props.children.map((section, i) => {
    const { index, heading, subHeading, children, disabled, itemCount = 0 } = section.props;
    const activeItem = activeIndex === index;
    return (
      <React.Fragment key={i}>
        <TouchableRipple
          style={[styles.heading, disabled && styles.headingDisabled]}
          rippleColor={palette.rippleColor}
          disabled={disabled}
          onPress={() => setActiveIndex(activeItem ? -1 : index)}
        >
          <View style={styles.headingContent}>
            <View style={styles.headingText}>
              <Text
                format={TypographyType.HeadingSmall}
                color={disabled ? palette.greyBlue : activeItem ? palette.blue : palette.navy}
              >
                {heading}
              </Text>
              {subHeading && (
                <Text
                  format={TypographyType.TinyBold}
                  style={[styles.subHeading, activeItem && styles.subHeadingActive]}
                  color={disabled ? palette.greyBlue : activeItem ? palette.blue : palette.slate}
                >
                  {subHeading}
                </Text>
              )}
              {itemCount > 0 && (
                <View style={[styles.itemCount, activeItem && styles.itemCountActive]}>
                  <Text format={TypographyType.MicroBold} color={activeItem ? palette.white : palette.slate}>
                    {itemCount}
                  </Text>
                </View>
              )}
            </View>
            <Icon
              size={28}
              name={IconName.chevron_right}
              color={disabled ? palette.grey : activeItem ? palette.blue : palette.greyBlue}
              style={[styles.headingChevron, activeItem && styles.headingChevronActive]}
            />
          </View>
        </TouchableRipple>
        <Collapsible
          collapsed={disabled || !activeItem}
          duration={350}
          easing={"easeInOut"}
          style={styles.accordionContent}
        >
          <>
            {children}
            <View style={styles.accordionContentBorder} />
          </>
        </Collapsible>
      </React.Fragment>
    );
  });
  return <View style={accordionStyles}>{sections}</View>;
};

interface AccordionSectionProps {
  index: number;
  heading: string;
  children: React.ReactNode;
  subHeading?: string;
  disabled?: boolean;
  itemCount?: number;
}

export const AccordionSection = (props: AccordionSectionProps) => {
  return <React.Fragment {...props}>{props.children}</React.Fragment>;
};

const styles = StyleSheet.create({
  accordion: {
    overflow: "hidden",
    borderBottomWidth: 1,
    borderColor: palette.grey,
  },

  accordionContent: {
    position: "relative",
    backgroundColor: palette.cloud,
    padding: spacing[40],
  },

  accordionContentBorder: {
    position: "absolute",
    left: 0,
    top: 0,
    width: 4,
    height: "100%",
    backgroundColor: palette.blue,
    zIndex: -1,
  },

  heading: {
    position: "relative",
    paddingHorizontal: spacing[40],
    paddingVertical: spacing[20],
    backgroundColor: palette.white,
    borderWidth: 1,
    borderBottomWidth: 0,
    borderColor: palette.grey,
    shadowColor: palette.blueShadow,
    shadowRadius: 10,
    shadowOpacity: 0.25,
    shadowOffset: { width: 0, height: 8 },
    zIndex: 1,
    ...Platform.select({
      web: {
        cursor: "pointer",
      },
    }),
  },

  headingDisabled: {
    position: "relative",
    ...Platform.select({
      web: {
        cursor: "default",
      },
    }),
  },

  headingContent: {
    flexDirection: "row",
    justifyContent: "space-between" /* horizontal axis */,
    alignItems: "center" /* vertical axis */,
  },

  headingChevron: {
    transform: [{ rotate: "90deg" }],
  },
  headingChevronActive: {
    transform: [{ rotate: "-90deg" }],
  },

  headingText: {
    flexDirection: "row",
    justifyContent: "flex-start" /* horizontal axis */,
    alignItems: "flex-end" /* vertical axis */,
  },

  itemCount: {
    width: 24,
    height: 24,
    marginLeft: spacing[10],
    borderRadius: 24,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: palette.grey,
    transform: [{ translateY: -2 }],
  },
  itemCountActive: {
    backgroundColor: palette.blue,
  },

  subHeading: {
    marginLeft: spacing[5],
    transform: [{ translateY: -1 }],
  },
  subHeadingActive: {
    opacity: 0.8,
  },
});
