import React, { useEffect, useMemo, useState } from "react";
import {
  Animated,
  Easing,
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  StyleSheet,
  TouchableWithoutFeedback,
  View,
} from "react-native";
import { useRecoilState, useSetRecoilState } from "recoil";
import AddressSearchWithCCG from "../AddressSearchWithCCG";
import { globalBottomSheetVisible, globalBottomSheetData, globalBottomSheetScrollToTop } from "@/utils/recoil/index";
import { LocationBottomSheetData } from "@/utils/recoil/props";
import { useDeviceHeight, useDeviceWidth, useIsWebView } from "@/hooks";
import { palette } from "@/theme";

import { Mod } from "./modules";
import { Button, ButtonList } from "@/components/Button";
import { ContentWrap } from "@/components/ContentWrap";
import LocationSearch from "@/components/LocationSearch";
import { LocationSearchItem, RetrieveDataObject } from "@/components/LocationSearch/LocationSearch.props";
import { IconName } from "../Icon";

export type CloseBottomSheetFn = () => void;

interface SheetProps {
  heading: string | undefined;
  headingIconProps?: {
    name: IconName;
    color: string;
  };
  children: React.ReactNode;
  locationData: LocationBottomSheetData | null;
  onDismiss?: () => void;
  wrapContent?: boolean;
  confirmButton?: { onPress: () => void; label?: string; disabled?: boolean };
  backgroundTransparent?: boolean;
}

const emptyArray: number[] = [];

export function Sheet(props: SheetProps) {
  // Looky-looky-I-got-Hooky
  const deviceHeight = useDeviceHeight();
  const deviceWidth = useDeviceWidth();
  const isWebView = useIsWebView();

  // Magic values
  const sheetWidthOnWeb = 475;
  const gap = isWebView ? 0 : 75; // This sets the amount of the underlying component you can see, between the top of screen and the top of the bottom sheet, when the bottomsheet is open on mobile

  // Component State
  const setGlobalBottomSheetData = useSetRecoilState(globalBottomSheetData);
  const [sheetVisible, setSheetVisible] = useRecoilState(globalBottomSheetVisible);
  const [scrollScrollViewToTop, setScrollScrollViewToTop] = useRecoilState(globalBottomSheetScrollToTop);
  const [renderSheet, setRenderSheet] = useState(false);
  const [sheetOffset] = useState(new Animated.Value(isWebView ? sheetWidthOnWeb * -1 : deviceHeight));
  const [overlayOpacity] = useState(new Animated.Value(0));
  const [searchQuery, setSearchQuery] = useState<string>(props.locationData?.initialQuery || "");
  const [searchResults, setSearchResults] = useState<LocationSearchItem[]>(
    props.locationData?.initialResults ? props.locationData.initialResults.map(r => ({ ...r, selected: true })) : []
  );
  const scrollViewRef = React.useRef<ScrollView>(null);

  const _openBottomSheet = () => {
    setRenderSheet(true);
  };

  const _closeBottomSheet = (dismissed?: boolean) => {
    Animated.parallel([
      Animated.timing(sheetOffset, {
        toValue: isWebView ? sheetWidthOnWeb * -1 : deviceHeight,
        easing: Easing.in(Easing.circle),
        duration: 300,
        delay: 0,
        useNativeDriver: !isWebView,
      }),
      Animated.timing(overlayOpacity, {
        toValue: 0,
        easing: Easing.out(Easing.linear),
        duration: 150,
        delay: 0,
        useNativeDriver: !isWebView,
      }),
    ]).start(() => {
      setRenderSheet(false);
      setSheetVisible(false);
      setSearchQuery("");
      setSearchResults([]);
      dismissed && props.onDismiss && props.onDismiss();
      setGlobalBottomSheetData(null);
    });
  };

  const sheetStyles = useMemo(
    () => [
      styles.sheet,
      isWebView && styles.sheetWeb,
      {
        width: isWebView ? sheetWidthOnWeb : deviceWidth,
        transform: isWebView ? [{ translateX: sheetOffset }] : [{ translateY: sheetOffset }],
      },
    ],
    [deviceWidth, isWebView, sheetWidthOnWeb]
  );
  const sheetContentStyles = useMemo(() => ({ paddingBottom: gap + 40 }), emptyArray);
  const overlayStyles = useMemo(
    () => [styles.overlay, { opacity: overlayOpacity }, !props.backgroundTransparent && styles.background],
    [overlayOpacity, props.backgroundTransparent]
  );

  const ContentWrapper = props.wrapContent ? ContentWrap : React.Fragment;

  useEffect(() => {
    if (scrollScrollViewToTop && scrollViewRef.current) {
      scrollViewRef.current.scrollTo({ x: 0, y: 0, animated: true });
    }
    setScrollScrollViewToTop(false);
  }, [scrollScrollViewToTop]);

  useEffect(() => {
    sheetVisible ? _openBottomSheet() : _closeBottomSheet();
  }, [sheetVisible]);

  useEffect(() => {
    if (renderSheet) {
      Animated.parallel([
        Animated.timing(sheetOffset, {
          toValue: isWebView ? 0 : gap,
          easing: Easing.out(Easing.circle),
          duration: 300,
          delay: 0,
          useNativeDriver: !isWebView,
        }),
        Animated.timing(overlayOpacity, {
          toValue: 1,
          easing: Easing.out(Easing.linear),
          duration: 150,
          delay: 0,
          useNativeDriver: !isWebView,
        }),
      ]).start();
    }
  }, [renderSheet, sheetOffset, isWebView, gap, overlayOpacity]);

  const retrieveData = (data: any) => {
    if (props.locationData && props.locationData.retrieveData && !props.locationData.withConfirm) {
      props.locationData.retrieveData(data);
      return;
    }
    if (props.locationData) {
      setSearchResults([data]);
    }
  };

  const handleConfirm = (data: any) => {
    if (props.locationData && props.locationData.retrieveData) {
      props.locationData.retrieveData(data[0]);
    }
  };

  return renderSheet ? (
    <View style={styles.container}>
      <KeyboardAvoidingView
        behavior={Platform.OS === "ios" ? "padding" : "height"}
        keyboardVerticalOffset={gap}
        style={styles.keyboardAvoidingView}
      >
        {/* Overlay */}
        <TouchableWithoutFeedback onPress={() => _closeBottomSheet(true)}>
          <Animated.View style={overlayStyles} />
        </TouchableWithoutFeedback>

        {/* Sheet */}
        <Animated.View style={sheetStyles}>
          {props.locationData ? (
            <Mod.LocationSearchBar
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              setSearchResults={setSearchResults}
              locationData={props.locationData}
              closeBottomSheet={_closeBottomSheet}
            />
          ) : (
            !!props.heading && (
              <Mod.Header
                heading={props.heading}
                headingIconProps={props.headingIconProps}
                closeSheet={() => _closeBottomSheet(true)}
              />
            )
          )}
          <ScrollView
            contentContainerStyle={sheetContentStyles}
            keyboardShouldPersistTaps={"handled"}
            bounces={false}
            ref={scrollViewRef}
          >
            {props.locationData ? (
              // withConfirm was added so that so that we can add a confirm screen, showing the address and the ccg. The user can then confirm the
              // selection or search again. This is the approach used in the GP search. This is now enabled for claims. However, since withConfirm is
              // optional other areas of the app will remain as before. LocationSearch returns immediately when the address is selected.
              <>
                {props.locationData.withConfirm ? (
                  <AddressSearchWithCCG
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                    searchResults={searchResults}
                    setSearchResults={setSearchResults}
                    initialResults={props.locationData.initialResults}
                    retrieveData={retrieveData}
                    handleConfirm={handleConfirm}
                  />
                ) : (
                  <LocationSearch
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                    searchResults={searchResults}
                    setSearchResults={setSearchResults}
                    initialResults={props.locationData.initialResults}
                    retrieveData={retrieveData}
                  />
                )}
              </>
            ) : (
              <>
                <ContentWrapper>{props.children}</ContentWrapper>
                {!!props.confirmButton && (
                  <ContentWrap>
                    <ButtonList>
                      <Button
                        mode="contained"
                        onPress={() => {
                          props.confirmButton && props.confirmButton.onPress();
                          _closeBottomSheet();
                        }}
                        disabled={props.confirmButton.disabled}
                        marginBottom={0}
                      >
                        {props.confirmButton.label || "Confirm"}
                      </Button>
                      <Button mode="text" onPress={() => _closeBottomSheet(true)} width="100%" marginBottom={0}>
                        Cancel
                      </Button>
                    </ButtonList>
                  </ContentWrap>
                )}
              </>
            )}
          </ScrollView>
        </Animated.View>
      </KeyboardAvoidingView>
    </View>
  ) : (
    <React.Fragment />
  );
}

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },

  overlay: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },

  sheet: {
    flex: 1,
    backgroundColor: palette.white,
    shadowColor: palette.shadowDark,
    shadowRadius: 14,
    shadowOpacity: 0.5,
    shadowOffset: { width: 0, height: -5 },
    overflow: "visible",
  },
  sheetWeb: {
    shadowRadius: 28,
    shadowOffset: { width: 10, height: 0 },
    paddingLeft: 5,
    paddingRight: 15,
  },

  // RNP prop styles
  keyboardAvoidingView: {
    flex: 1,
  },
  background: { backgroundColor: palette.sideSheetOverlayBackground },
});
