import React from "react";
import { View } from "react-native";

import { TableProps } from "./Table.types";
import TableRow from "./TableRow/TableRow";

import { TypographyType } from "@/models/Typography";
import Icon from "../Icon";
import Text from "../Text";
import { palette } from "../../theme";
import css from "./Table.css";

const Table: React.FC<TableProps> = ({ columnWidths, headings, rows, themeColor = palette.greyBlue }) => {
  const Headings = headings.map((th, i) => (
    <View key={i} style={[css.heading, { flexBasis: columnWidths[i] }]}>
      {!!th.icon && <Icon size={18} name={th.icon} color={themeColor} style={css.icon} />}
      <Text format={TypographyType.Tiny} color={palette.greyBlue}>
        {th.text}
      </Text>
    </View>
  ));

  const Rows = rows.map((tr, i) => (
    <TableRow key={i} {...tr} index={i} columndWidths={columnWidths} themeColor={themeColor} />
  ));

  return (
    <>
      <View style={css.headings}>{Headings}</View>
      {Rows}
    </>
  );
};

export default Table;
