/* eslint-disable complexity */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Platform, StyleSheet, View, Keyboard } from "react-native";
import { useMutation } from "@apollo/react-hooks";
import { useSetRecoilState } from "recoil";
import { useNavigation } from "@react-navigation/native";

import { Engine, FuelType, LocationInput, LocationNameInput, UpdateS12DoctorInput } from "libs/types/API";
import allLanguages from "libs/utils/languages";
import allSpecialties from "libs/utils/specialties";
import { isValidEmail, isValidPhone } from "libs/validators";

import { Checkbox } from "@/components/Checkbox/Checkbox";
import { useDialog } from "@/hooks";
import {
  CREATE_MHT_S12_DOCTOR,
  DELETE_MHT_S12_DOCTOR,
  GET_DOCTOR,
  UPDATE_DOCTOR_PROFILE,
} from "@/models/DoctorProfile";
import { buildErr, updateDoctorProfileError } from "@/models/Error";
import { PostcodeItem } from "@/models/Location";
import { TypographyType } from "@/models/Typography";
import { spacing, color } from "@/theme";
import {
  checkMutationInput,
  mqWeb,
  updatePhonePrefix,
  generateEngineSizeDropdownOptions,
  fuelTypeDropdownOptions,
} from "@/utils/helpers";
import { snackbarMessage, lastException } from "@/utils/recoil/index";

import { EditDoctorProfile, UpdateDoctorFunc } from "./DoctorSetupForm.props";
import { Button } from "../Button";
import Switch from "../Switch";
import { ContentWrap } from "../ContentWrap";
import Select from "../Select";
import MultiSelect from "../Select/MultiSelect";
import Text from "../Text";
import { TextInput } from "../TextInput/TextInput";
import { StackNavigationProp } from "@react-navigation/stack";
import { AllDoctorRoutes } from "@/navigationv2/types";
import { MHALocationSearch } from "../Forms/MHALocation/MHALocationSearch/MHALocationSearch";
import { MHALocationInput } from "../Forms/MHALocation/MHALocationInput/MHALocationInput";
import { formatAddress } from "libs/formatters/address";
import { IconButton } from "react-native-paper";
import { SectionDivider } from "../SectionDivider";

type MHT = {
  id: string;
  name: string;
  abbreviation: string;
};

interface PropTypes {
  doctor: EditDoctorProfile;
  getPostcode: (input: string) => Promise<PostcodeItem>;
  mhts: MHT[];
}

const DoctorSetupForm = (props: PropTypes) => {
  const { doctor } = props;
  const isWebView = mqWeb();
  const navigation = useNavigation<StackNavigationProp<AllDoctorRoutes>>();

  const initialState = {
    name: doctor.name,
    phone: doctor.phone,
    phone2: doctor.phone2,
    email: doctor.email,
    location: doctor.location,
    locationName: doctor.locationName,
    employer: doctor.employer,
    jobTitle: doctor.jobTitle,
    responsibleOfficer: doctor.responsibleOfficer,
    lineManager: doctor.lineManager,
    specialties: doctor.specialties,
    languages: doctor.languages,
    gender: doctor.gender,
    notes: doctor.notes,
    claimData: doctor.claimData,
    availabilityPostcodes: doctor.availabilityPostcodes,
    availabilityLocations: doctor.availabilityLocations,
    hideFromSearch: doctor.hideFromSearch,
    sectionFormAddress: doctor.sectionFormAddress,
    sectionFormEmailAddress: doctor.sectionFormEmailAddress,
  };

  const initialMHTEmployers = (doctor.mhtEmployers && doctor.mhtEmployers.items.map(mht => mht.mht.abbreviation)) || [];

  const [profileData, setProfileData] = useState(initialState);
  const [profileDataMHTEmployers, setProfileDataMHTEmployers] = useState(initialMHTEmployers);
  const [inputErrors, setInputErrors] = useState<string[]>([]);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [basePostcode, setBasePostcode] = useState(initialState.locationName.postcode || "");
  const [postcodeError, setPostcodeError] = useState(false);
  const [isVatRegistered, setIsVatRegistered] = useState(!!initialState.claimData?.vatRegistration);

  const engineDropdownOptions = generateEngineSizeDropdownOptions(
    (profileData.claimData && {
      value: profileData.claimData.fuelType as FuelType,
      label: "",
    }) ||
      null
  );

  const setMessage = useSetRecoilState(snackbarMessage);
  const setLastException = useSetRecoilState(lastException);

  const [updateDoctor] = useMutation<UpdateDoctorFunc>(UPDATE_DOCTOR_PROFILE);
  const [createMHT] = useMutation(CREATE_MHT_S12_DOCTOR);
  const [deleteMHT] = useMutation(DELETE_MHT_S12_DOCTOR);

  const onChangeState = (k: string, v: any) => {
    if (inputErrors.includes(k)) setInputErrors(inputErrors.filter(s => s !== k));

    setProfileData(prevState => {
      return {
        ...prevState,
        [k]: v,
      };
    });
  };

  const submitChanges = useCallback(
    (locationData: { location: LocationInput; locationName: LocationNameInput }) => {
      const errors = [];
      const phoneExpression = /^(?:0?(?:(\+|[00])44)?\d{10,11})$/;

      const mhtCreateInput = profileDataMHTEmployers
        .filter(mht => !initialMHTEmployers.includes(mht))
        .map(mht => props.mhts.find(m => m.abbreviation === mht));

      const mhtDeleteInput = initialMHTEmployers
        .filter(mht => !profileDataMHTEmployers.includes(mht))
        .map(mht => doctor.mhtEmployers && doctor.mhtEmployers.items.find(m => m.mht.abbreviation === mht));

      const updPhoneNumber = profileData.phone ? updatePhonePrefix(profileData.phone) : null;
      const updPhone2Number = profileData.phone2 ? updatePhonePrefix(profileData.phone2) : null;

      if (!profileData.name || (profileData.name && profileData.name.trim() === "")) errors.push("name");

      if (!isValidEmail(profileData.email)) {
        errors.push("email");
      }
      if (!!profileData.sectionFormEmailAddress && !isValidEmail(profileData.sectionFormEmailAddress)) {
        errors.push("sectionFormEmailAddress");
      }
      if (updPhoneNumber && updPhoneNumber.length && !isValidPhone(updPhoneNumber)) {
        errors.push("phone");
      }
      if (updPhone2Number && updPhone2Number.length && !phoneExpression.test(String(updPhone2Number))) {
        errors.push("phone2");
      }

      if (errors.length) {
        setInputErrors(errors);
        setShowErrorDialog(true);
        return;
      }

      setSubmitted(true);
      mhtCreateInput.length > 0 &&
        mhtCreateInput.forEach(mht => {
          if (mht)
            createMHT({
              variables: {
                input: {
                  mHTS12DoctorMhtId: mht.id,
                  mHTS12DoctorS12DoctorId: doctor.id,
                },
              },
            }).catch(e => {
              console.error(e);
            });
        });

      mhtDeleteInput.length > 0 &&
        mhtDeleteInput.forEach(mht => {
          if (mht)
            deleteMHT({
              variables: {
                input: {
                  id: mht.id,
                },
              },
            }).catch(e => {
              console.error(e);
            });
        });

      updateDoctor({
        variables: {
          input: checkMutationInput<UpdateS12DoctorInput>({
            id: doctor.id,
            ...profileData,
            ...locationData,
            employer: profileData.employer,
            jobTitle: profileData.jobTitle,
            responsibleOfficer: profileData.responsibleOfficer,
            lineManager: profileData.lineManager,
            gender: profileData.gender && profileData.gender !== "U" ? profileData.gender : null,
            phone: updPhoneNumber,
            phone2: updPhone2Number,
            ...(profileData.claimData && {
              claimData: {
                ...profileData.claimData,
              },
            }),
          }),
        },
        refetchQueries: [
          {
            query: GET_DOCTOR,
            variables: { id: doctor.id },
          },
        ],
      })
        .then(() => {
          setMessage("Profile successfully updated");
          navigation.goBack();
        })
        .catch(e => {
          setSubmitted(false);
          buildErr(updateDoctorProfileError, setLastException)(e);
        });
    },
    [
      createMHT,
      deleteMHT,
      doctor.id,
      doctor.mhtEmployers,
      initialMHTEmployers,
      navigation,
      profileData,
      profileDataMHTEmployers,
      props.mhts,
      setLastException,
      setMessage,
      updateDoctor,
    ]
  );

  const checkLocation = useCallback(async () => {
    let postcodeData: PostcodeItem;
    if (basePostcode !== initialState.locationName.postcode) {
      try {
        postcodeData = await props.getPostcode(basePostcode);
      } catch (e) {
        setPostcodeError(true);
        setShowErrorDialog(true);
        setSubmitted(false);
        return;
      }

      setSubmitted(true);
      setProfileData({
        ...profileData,
        location:
          profileData.email && profileData.email.indexOf("s12.s12doctor+") === 0
            ? { lat: postcodeData.location.lon, lon: postcodeData.location.lat }
            : {
                lat: postcodeData.location.lat,
                lon: postcodeData.location.lon,
              },

        locationName: postcodeData.locationName,
      });
      submitChanges({
        location: postcodeData.location,
        locationName: postcodeData.locationName,
      });
    } else {
      submitChanges({
        location: profileData.location,
        locationName: profileData.locationName,
      });
    }
  }, [basePostcode, initialState.locationName.postcode, profileData, props, submitChanges]);

  const profileModified =
    JSON.stringify(initialState) !== JSON.stringify(profileData) ||
    JSON.stringify(initialMHTEmployers) !== JSON.stringify(profileDataMHTEmployers) ||
    basePostcode !== initialState.locationName.postcode;

  const { openDialog, closeDialog } = useDialog();

  useEffect(() => {
    if (showErrorDialog && (inputErrors.length > 0 || postcodeError)) {
      openDialog({
        heading: "Errors",
        message: [`Cannot save changes`, `Please check the form for errors`],
        confirmButton: {
          label: "Return to Form",
          onPress: () => setShowErrorDialog(false),
        },
        dismissable: false,
      });
    } else {
      closeDialog();
    }
  }, [closeDialog, inputErrors, openDialog, postcodeError, showErrorDialog]);

  return (
    <ContentWrap>
      <Text format={TypographyType.HeadingMedium}>Details</Text>
      <Text format={TypographyType.Small} style={styles.noFormsText}>
        If you would like to update any of this information, please contact the S12 Solutions support team.
      </Text>
      <View>
        <TextInput
          disabled
          label="Full Legal Name"
          value={doctor.legalName}
          inputBoxesStyle={{
            marginLeft: spacing[5],
          }}
        />
        <TextInput
          disabled={!!initialState.email || submitted}
          label="Email Address"
          keyboardType="email-address"
          value={profileData.email || "-"}
          onChangeText={value => onChangeState("email", value)}
          error={inputErrors.includes("email")}
          errorText="Please enter a valid email address"
          inputBoxesStyle={{
            marginLeft: spacing[5],
          }}
        />
        <TextInput
          disabled={!!initialState.phone || submitted}
          autoCorrect={false}
          label="Phone Number"
          autoCapitalize="none"
          returnKeyType="done"
          keyboardType="phone-pad"
          value={profileData.phone || ""}
          onChangeText={value => onChangeState("phone", value)}
          error={inputErrors.includes("phone")}
          errorText="Please enter a valid phone number"
          inputBoxesStyle={{
            marginLeft: spacing[5],
          }}
        />
        <SectionDivider compressed={true} />
        <TextInput
          autoCorrect={false}
          disabled={submitted}
          label="Known As"
          autoCapitalize="none"
          returnKeyType="done"
          autoFocus={true}
          value={profileData.name}
          onChangeText={value => onChangeState("name", value)}
          error={inputErrors.includes("name")}
          errorText="Please enter a name"
          inputBoxesStyle={{
            marginLeft: spacing[5],
          }}
        />
        <TextInput
          autoCorrect={false}
          disabled={submitted}
          label="Additional Phone Number"
          autoCapitalize="none"
          returnKeyType="done"
          keyboardType="phone-pad"
          value={profileData.phone2 || ""}
          onChangeText={value => onChangeState("phone2", value)}
          error={inputErrors.includes("phone2")}
          errorText="Please enter a valid phone number"
          testID="AdditionalPhoneNumber__Input"
          inputBoxesStyle={{
            marginLeft: spacing[5],
          }}
        />
        <TextInput
          autoCorrect={false}
          disabled={submitted}
          label={"Base Postcode"}
          underlabel={"Used to search for doctors by distance from the assessment location"}
          underLabelTextSize="Micro"
          inputBoxesStyle={{
            marginLeft: spacing[5],
          }}
          infoTextStyle={{
            flexShrink: 1,
            marginLeft: 3,
          }}
          autoCapitalize="none"
          returnKeyType="done"
          value={basePostcode}
          onChangeText={value => {
            if (postcodeError) setPostcodeError(false);
            setBasePostcode(value);
          }}
          error={postcodeError}
          errorText="Please enter a valid postcode"
          testID="DoctorBasePostcode__Input"
        />
        <TextInput
          autoCorrect={false}
          disabled={submitted}
          label="Primary Team Name"
          autoCapitalize="none"
          returnKeyType="done"
          value={profileData.employer || ""}
          onChangeText={value => onChangeState("employer", value)}
          inputBoxesStyle={{
            marginLeft: spacing[5],
          }}
        />
        <TextInput
          autoCorrect={false}
          disabled={submitted}
          label="Primary Job Title"
          autoCapitalize="none"
          returnKeyType="done"
          value={profileData.jobTitle || ""}
          onChangeText={value => onChangeState("jobTitle", value)}
          inputBoxesStyle={{
            marginLeft: spacing[5],
          }}
        />
        <TextInput
          autoCorrect={false}
          disabled={submitted}
          label="Responsible Officer Name"
          autoCapitalize="none"
          returnKeyType="done"
          value={profileData.responsibleOfficer || ""}
          onChangeText={value => onChangeState("responsibleOfficer", value)}
          inputBoxesStyle={{
            marginLeft: spacing[5],
          }}
        />
        <TextInput
          autoCorrect={false}
          disabled={submitted}
          label="Line Manager Name"
          autoCapitalize="none"
          returnKeyType="done"
          value={profileData.lineManager || ""}
          onChangeText={value => onChangeState("lineManager", value)}
          inputBoxesStyle={{
            marginLeft: spacing[5],
          }}
        />
        <MultiSelect
          options={useMemo(() => props.mhts.map(mht => mht.abbreviation), [])}
          value={(profileDataMHTEmployers.length > 0 && profileDataMHTEmployers) || []}
          label="MHT Employer"
          style={styles.languages}
          onValueChange={(value: string[]) => {
            setProfileDataMHTEmployers(value);
          }}
        />
        <MultiSelect
          options={useMemo(() => allSpecialties, [])}
          value={profileData.specialties || []}
          style={styles.specialties}
          label="Specialisms"
          onValueChange={(value: string[]) => {
            onChangeState("specialties", value);
          }}
        />
        <MultiSelect
          options={useMemo(() => allLanguages, [])}
          value={profileData.languages || []}
          label="Languages"
          style={styles.languages}
          onValueChange={(value: string[]) => {
            onChangeState("languages", value);
          }}
        />
        <Select
          label="Gender"
          disabled={submitted}
          value={profileData.gender || "U"}
          onFocus={() => Keyboard.dismiss()}
          onValueChange={(_v: string, i: number, d: { value: string; label: string }[]) => {
            onChangeState("gender", d[i].value);
          }}
          options={[
            { name: "Female", id: "F" },
            { name: "Male", id: "M" },
            { name: "Prefer not to say", id: "U" },
          ]}
        />
      </View>
      <TextInput
        label="Notes"
        disabled={submitted}
        numberOfLines={2}
        multiline={true}
        autoCorrect={true}
        spellCheck={true}
        value={profileData.notes || ""}
        returnKeyType="next"
        blurOnSubmit={true}
        onChangeText={value => onChangeState("notes", value)}
      />
      {/* <Switch
        headline="Hide me from search?"
        onState="Yes"
        offState="No"
        value={profileData.hideFromSearch || false}
        onValueChange={(val: boolean) => {
          onChangeState("hideFromSearch", val);
        }}
      /> */}
      {exists(profileData.claimData) && (
        <>
          <Text format={TypographyType.HeadingSmall}>Account Confirmation Details</Text>
          {
            // TODO convert to re-usable form
          }
          <TextInput
            label="Name/Company"
            disabled={submitted}
            value={profileData.claimData.billingCompanyName || ""}
            returnKeyType="next"
            blurOnSubmit={true}
            onChangeText={value =>
              onChangeState("claimData", {
                ...profileData.claimData,
                billingCompanyName: value,
              })
            }
          />
          <TextInput
            label="Billing Address (Line 1)"
            disabled={submitted}
            value={profileData.claimData.billingAddress?.address || ""}
            returnKeyType="next"
            blurOnSubmit={true}
            onChangeText={value =>
              onChangeState("claimData", {
                ...profileData.claimData,
                billingAddress: {
                  ...profileData.claimData?.billingAddress,
                  address: value,
                },
              })
            }
          />
          <TextInput
            label="Billing Address (City)"
            disabled={submitted}
            value={profileData.claimData.billingAddress?.city || ""}
            returnKeyType="next"
            blurOnSubmit={true}
            onChangeText={value =>
              onChangeState("claimData", {
                ...profileData.claimData,
                billingAddress: {
                  ...profileData.claimData?.billingAddress,
                  city: value,
                },
              })
            }
          />
          <TextInput
            label="Billing Address (Postcode)"
            disabled={submitted}
            value={profileData.claimData.billingAddress?.postcode || ""}
            returnKeyType="next"
            blurOnSubmit={true}
            autoCapitalize="characters"
            onChangeText={value =>
              onChangeState("claimData", {
                ...profileData.claimData,
                billingAddress: {
                  ...profileData.claimData?.billingAddress,
                  postcode: value,
                },
              })
            }
          />
          <Checkbox
            status={isVatRegistered}
            onPress={() => {
              if (isVatRegistered) {
                onChangeState("claimData", {
                  ...profileData.claimData,
                  vatRegistration: null,
                });
              }
              setIsVatRegistered(!isVatRegistered);
            }}
            label="I or my company is VAT registered"
            style={!isVatRegistered && { marginBottom: spacing[20] }}
          />
          {isVatRegistered && (
            <TextInput
              label="VAT Registration Number"
              disabled={submitted}
              autoCorrect={false}
              spellCheck={false}
              value={profileData.claimData.vatRegistration || ""}
              returnKeyType="done"
              blurOnSubmit={true}
              onChangeText={value =>
                onChangeState("claimData", {
                  ...profileData.claimData,
                  vatRegistration: value,
                })
              }
            />
          )}

          <Text format={TypographyType.HeadingSmall}>Vehicle Information</Text>
          <Select
            icon="local-gas-station"
            label="Fuel Type"
            value={profileData.claimData?.fuelType || fuelTypeDropdownOptions[0].name}
            onFocus={() => Keyboard.dismiss()}
            onValueChange={(v: string, _i: number, _d: { value: FuelType | string; label: string }[]) => {
              onChangeState("claimData", {
                ...profileData.claimData,
                engineSize: null,
                fuelType: v,
              });
            }}
            options={fuelTypeDropdownOptions}
          />
          {profileData.claimData?.fuelType && !["electric", "lpg"].includes(profileData.claimData?.fuelType) && (
            <Select
              icon="drive-eta"
              label="Engine Size"
              value={profileData.claimData?.engineSize || engineDropdownOptions[0].name}
              onFocus={() => Keyboard.dismiss()}
              onValueChange={(v: string, _i: number, _d: { value: Engine | string; label: string }[]) => {
                onChangeState("claimData", {
                  ...profileData.claimData,
                  engineSize: v,
                });
              }}
              options={engineDropdownOptions}
            />
          )}
        </>
      )}
      <Text format={TypographyType.HeadingSmall}>MHA Form Information</Text>
      <TextInput
        label="MHA Form Email Address"
        disabled={submitted}
        autoCorrect={false}
        spellCheck={false}
        value={profileData.sectionFormEmailAddress || ""}
        returnKeyType="done"
        blurOnSubmit={true}
        onChangeText={value => onChangeState("sectionFormEmailAddress", value)}
        error={inputErrors.includes("sectionFormEmailAddress")}
        errorText="Please enter a valid email address"
        autoCompleteType="off"
      />
      <View style={{ flexDirection: "row" }}>
        <MHALocationInput
          label="MHA Form Address"
          locationValue={profileData.sectionFormAddress ? formatAddress(profileData.sectionFormAddress) : ""}
          setLocationValue={v => {
            onChangeState("sectionFormAddress", v.locationName);
          }}
        />
        {!!profileData.sectionFormAddress && (
          <IconButton icon="close" onPress={() => onChangeState("sectionFormAddress", null)} />
        )}
      </View>

      <View style={[styles.actionButtonsContainer, isWebView ? styles.actionButtonsContainerWeb : {}]}>
        <Button
          mode="outlined"
          onPress={navigation.goBack}
          style={[styles.actionButton, isWebView ? styles.actionButtonWeb : {}]}
          testID="DiscardChanges__Button"
        >
          {profileModified ? "Discard Changes" : "Cancel"}
        </Button>
        <Button
          onPress={checkLocation}
          disabled={!profileModified || submitted}
          style={[styles.actionButton, isWebView ? styles.actionButtonWeb : {}]}
          testID="Doctor-Edit-Profile__Save-changes__Button"
        >
          Save changes
        </Button>
      </View>
    </ContentWrap>
  );
};

export default DoctorSetupForm;

const styles = StyleSheet.create({
  actionButtonsContainer: {
    marginTop: spacing[10],
    marginBottom: spacing[20] * -1,
  },
  actionButtonsContainerWeb: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  headerSpacing: {
    marginBottom: spacing[20],
  },
  actionButton: {
    marginBottom: spacing[10],
  },
  actionButtonWeb: {
    marginLeft: spacing[10],
  },
  specialties: {
    zIndex: (Platform.OS !== "web" && 1) || undefined,
  },
  languages: {
    zIndex: (Platform.OS !== "web" && 2) || undefined,
    marginLeft: spacing[10],
  },
  noFormsText: {
    color: color.textLight,
    marginBottom: spacing[30],
  },
});

function exists<TValue>(arg: TValue | null | undefined): arg is TValue {
  return !!arg;
}
