import DoctorAvailabilityScreen from "./doctor/DoctorAvailabilityScreen";
import DoctorProfileScreen from "./doctor/DoctorProfileScreen";
import DoctorVisitDetailsScreen from "./doctor/DoctorVisitDetailsScreen";
import DoctorVisitScreen from "./doctor/DoctorVisitScreen";
import EditDoctorAvailabilityScreen from "./doctor/EditDoctorAvailabilityScreen";
import EditDoctorProfileScreen from "./doctor/EditDoctorProfileScreen";
import HolidaysScreen from "./HolidaysScreen";
import { MHAExternalFormScreen } from "./MHAExternalFormScreen";
import MHAFormScreen from "./MHAFormScreen";
import { TeamInboxFormViewerScreen } from "./amhp/TeamInboxFormViewerScreen";
import ApprovedClaimsScreen from "./ccg/ApprovedClaimsScreen";
import ApprovedPaidClaimsScreen from "./ccg/ApprovedPaidClaimsScreen";
import AppUpdateScreen from "./shared/AppUpdateScreen";
import AssessmentDetailsScreen from "./amhp/AssessmentDetailsScreen";
import AssessmentScreen from "./amhp/AssessmentScreen";
import AuthSupportScreen from "./AuthSupportScreen";
import ChangePasswordScreen from "./shared/ChangePasswordScreen";
import ClaimDetailsScreen from "./ClaimDetailsScreen";
import ClaimProcessingDetailsScreen from "./ccg/ClaimProcessingDetailsScreen";
import ClaimProcessingSearchScreen from "./ccg/ClaimProcessingSearchScreen";
import WebOnlyClaimsScreen from "./ccg/WebOnlyClaimsScreen";
import ClaimProcessScreen from "./shared/ClaimProcessScreen";
import ClaimsScreen from "./ClaimsScreen";
import ConfirmationScreen from "./ConfirmationScreen";
import CreateAssessmentScreen from "./amhp/CreateAssessmentScreen";
import CreateClaimScreen from "./CreateClaimScreen";
import EulaScreen from "./shared/EulaScreen";
import ForgotPasswordScreen from "./ForgotPasswordScreen";
import MapScreen from "./MapScreen";
import MeetingRoomPreviewScreen from "./shared/MeetingRoomPreviewScreen";
import MeetingRoomScreen from "./shared/MeetingRoomScreen";
import MyClaimsScreen from "./ccg/MyClaimsScreen";
import NewPasswordScreen from "./NewPasswordScreen";
import PrivacyPolicyScreen from "./shared/PrivacyPolicyScreen";
import ProfileScreen from "./ProfileScreen";
import RejectedClaimsScreen from "./ccg/RejectedClaimsScreen";
import ResourcesScreen from "./ResourcesScreen";
import SearchResultsScreen from "./amhp/SearchResultsScreen";
import SendToDoctorScreen from "./amhp/SendToDoctorScreen";
import SignInScreen from "./SignInScreen";
import SignUpScreen from "./SignUpScreen";
import SupportExplainedScreen from "./shared/SupportExplainedScreen";
import SupportScreen from "./shared/SupportScreen";
import TeamClaimsScreen from "./ccg/TeamClaimsScreen";
import UnassignedClaimsScreen from "./ccg/UnassignedClaimsScreen";
import UserDashboardScreen from "./UserDashboardScreen";
import WelcomeScreen from "./WelcomeScreen";
import { ScreenOptions } from "../navigationv2/types";
import TeamSelectScreen from "./TeamSelectScreen";

interface ScreensObject {
  [key: string]: {
    (props: any): JSX.Element;
    screenOptions?: ScreenOptions;
  };
}

const Screens: ScreensObject = {
  ApprovedClaimsScreen,
  ApprovedPaidClaimsScreen,
  AppUpdateScreen,
  AssessmentDetailsScreen,
  AssessmentScreen,
  AuthSupportScreen,
  ChangePasswordScreen,
  ClaimDetailsScreen,
  ClaimProcessingDetailsScreen,
  ClaimProcessingSearchScreen,
  WebOnlyClaimsScreen,
  ClaimProcessScreen,
  ClaimsScreen,
  ConfirmationScreen,
  CreateAssessmentScreen,
  CreateClaimScreen,
  DoctorAvailabilityScreen,
  DoctorProfileScreen,
  DoctorVisitDetailsScreen,
  DoctorVisitScreen,
  EditDoctorAvailabilityScreen,
  EditDoctorProfileScreen,
  EulaScreen,
  ForgotPasswordScreen,
  HolidaysScreen,
  MapScreen,
  MeetingRoomPreviewScreen,
  MeetingRoomScreen,
  MHAExternalFormScreen,
  MHAFormScreen,
  MyClaimsScreen,
  NewPasswordScreen,
  PrivacyPolicyScreen,
  ProfileScreen,
  RejectedClaimsScreen,
  ResourcesScreen,
  SearchResultsScreen,
  SendToDoctorScreen,
  SignInScreen,
  SignUpScreen,
  SupportExplainedScreen,
  SupportScreen,
  TeamClaimsScreen,
  TeamInboxFormViewerScreen,
  UnassignedClaimsScreen,
  UserDashboardScreen,
  WelcomeScreen,
  TeamSelectScreen,
};

export default Screens;

export {
  ApprovedClaimsScreen,
  ApprovedPaidClaimsScreen,
  AppUpdateScreen,
  AssessmentDetailsScreen,
  AssessmentScreen,
  AuthSupportScreen,
  ChangePasswordScreen,
  ClaimDetailsScreen,
  ClaimProcessingDetailsScreen,
  ClaimProcessingSearchScreen,
  WebOnlyClaimsScreen,
  ClaimProcessScreen,
  ClaimsScreen,
  ConfirmationScreen,
  CreateAssessmentScreen,
  CreateClaimScreen,
  DoctorAvailabilityScreen,
  DoctorProfileScreen,
  DoctorVisitDetailsScreen,
  DoctorVisitScreen,
  EditDoctorAvailabilityScreen,
  EditDoctorProfileScreen,
  EulaScreen,
  ForgotPasswordScreen,
  HolidaysScreen,
  MapScreen,
  MeetingRoomPreviewScreen,
  MeetingRoomScreen,
  MHAExternalFormScreen,
  MHAFormScreen,
  MyClaimsScreen,
  NewPasswordScreen,
  PrivacyPolicyScreen,
  ProfileScreen,
  RejectedClaimsScreen,
  ResourcesScreen,
  SearchResultsScreen,
  SendToDoctorScreen,
  SignInScreen,
  SignUpScreen,
  SupportExplainedScreen,
  SupportScreen,
  TeamClaimsScreen,
  TeamInboxFormViewerScreen,
  UnassignedClaimsScreen,
  UserDashboardScreen,
  WelcomeScreen,
  TeamSelectScreen,
};
