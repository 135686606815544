import React, { useEffect, useMemo, useState } from "react";
import { Animated, Easing, Platform, StyleSheet, TouchableOpacity, View } from "react-native";
import { useDebounce } from "use-debounce";

import { TypographyType } from "@/models/Typography";
import { S12Error } from "@/models/Error";
import { useBottomSheet } from "@/hooks";
import { ucFirst } from "@/utils/helpers";
import { palette, spacing } from "@/theme";

import { Button, ButtonList } from "@/components/Button";
import Text from "@/components/Text";

import { GPSearchForFilter, GPSearchByFilter, GpOrgSearchItem, GpPracticeItem } from "./GPSearch.props";
import { Mod } from "./modules";

const animationOutOffset = 50;
const aninmationOffsetSpeed = 500;
const animationOpacitySpeed = 150;

interface GPSearchProps {
  getGPData: (orgId: string, roleId: string) => Promise<GpPracticeItem>;
  getResults: (term: string, postcode: string, searchFor: GPSearchForFilter) => Promise<Array<GpOrgSearchItem>>;
  retrieveData(data: Record<string, any>): void;
  initialQuery?: string;
}

export function GPSearch(props: GPSearchProps): JSX.Element {
  const [query, setQuery] = useState<string>(props.initialQuery || "");
  const [searchResults, setSearchResults] = useState<Array<GpOrgSearchItem>>([]);
  const [selectedResult, setSelectedResult] = useState<GpPracticeItem | null>(null);
  const [searchFor, setSearchFor] = useState<GPSearchForFilter>("practice");
  const [searchBy, setSearchBy] = useState<GPSearchByFilter>("name");
  const [settingsPanelVisible, setSettingsPanelVisible] = useState<boolean>(true);
  const [showConfirmationScreen, setShowConfirmationScreen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [debouncedQuery] = useDebounce(query, 500);
  const { scrollBottomSheetScrollViewToTop } = useBottomSheet();

  const [searchScreenOpacity] = useState<Animated.Value>(new Animated.Value(1));
  const [searchScreenOffset] = useState<Animated.Value>(new Animated.Value(0));
  const [confirmationScreenOpacity] = useState<Animated.Value>(new Animated.Value(0));
  const [confirmationScreenOffset] = useState<Animated.Value>(new Animated.Value(animationOutOffset));

  const _search = React.useCallback(() => {
    props
      .getResults(searchBy === "name" ? debouncedQuery : "", searchBy === "postcode" ? debouncedQuery : "", searchFor)
      .then(data => {
        setSearchResults(data);
        if (data.length === 0) {
          Platform.OS === "web" && setSettingsPanelVisible(true);
          setErrorMessage(
            `No ${
              searchFor === "practice" ? "GP Practices" : "GP Branch Surgeries"
            } found for the ${searchBy}, "${debouncedQuery}".\n\nPlease try again.`
          );
        }
      })
      .catch((e: Error | S12Error) => setErrorMessage(e instanceof S12Error ? e.message : "Error finding results"));
  }, [debouncedQuery, searchBy, searchFor]);

  const _onConfirmResultSelection = React.useCallback(() => {
    if (!selectedResult) {
      return;
    }
    setSearchResults([]);
    props.retrieveData(selectedResult);
  }, [selectedResult]);

  function _animateSearchScreen(direction: "in" | "out", done?: () => void) {
    Animated.timing(searchScreenOpacity, {
      toValue: direction === "in" ? 1 : 0,
      easing: Easing.out(Easing.circle),
      duration: animationOpacitySpeed,
      useNativeDriver: true,
    }).start(() => done && done());
    Animated.timing(searchScreenOffset, {
      toValue: direction === "in" ? 0 : animationOutOffset,
      easing: Easing.out(Easing.circle),
      duration: aninmationOffsetSpeed,
      useNativeDriver: true,
    }).start();
  }

  function _animateConfirmationScreen(direction: "in" | "out", done?: () => void) {
    Animated.timing(confirmationScreenOpacity, {
      toValue: direction === "in" ? 1 : 0,
      easing: Easing.out(Easing.circle),
      duration: animationOpacitySpeed,
      useNativeDriver: true,
    }).start(() => done && done());
    Animated.timing(confirmationScreenOffset, {
      toValue: direction === "in" ? 0 : animationOutOffset,
      easing: Easing.out(Easing.circle),
      duration: aninmationOffsetSpeed,
      useNativeDriver: true,
    }).start();
  }

  useEffect(() => {
    if (selectedResult) {
      _animateSearchScreen("out", () => {
        setShowConfirmationScreen(true);
        _animateConfirmationScreen("in");
        scrollBottomSheetScrollViewToTop();
      });
    } else {
      _animateConfirmationScreen("out", () => {
        setShowConfirmationScreen(false);
        _animateSearchScreen("in");
        scrollBottomSheetScrollViewToTop();
      });
    }
  }, [selectedResult]);

  useEffect(() => {
    if (!debouncedQuery || debouncedQuery.length < 3) {
      setSearchResults([]);
      return;
    }
    _search();
  }, [debouncedQuery]);

  useEffect(() => {
    setErrorMessage("");
    setSearchResults([]);
  }, [query]);

  useEffect(() => {
    if (debouncedQuery.length) {
      setSearchResults([]);
      setErrorMessage("");
      _search();
    }
  }, [searchFor]);

  const searchScreenStyles = useMemo(
    () => ({
      opacity: searchScreenOpacity,
      transform: [{ translateY: searchScreenOffset }],
    }),
    [searchScreenOpacity, searchScreenOffset]
  );

  const confirmationScreenStyles = useMemo(
    () => [
      styles.confirmationScreen,
      {
        opacity: confirmationScreenOpacity,
        transform: [{ translateY: confirmationScreenOffset }],
      },
    ],
    [confirmationScreenOpacity, confirmationScreenOffset]
  );

  return !showConfirmationScreen ? (
    <>
      <Animated.View style={searchScreenStyles}>
        <Mod.Search
          query={query}
          setQuery={setQuery}
          initialQuery={props.initialQuery}
          setSearchResults={setSearchResults}
          searchFor={searchFor}
          setSearchFor={setSearchFor}
          searchBy={searchBy}
          setSearchBy={setSearchBy}
          settingsPanelVisible={settingsPanelVisible}
          setSettingsPanelVisible={setSettingsPanelVisible}
        />

        <View style={styles.results}>
          <View style={styles.resultsLabels}>
            {!!query.length && query.length >= 3 && (
              <Text format={TypographyType.RegularBold} style={styles.resultsHeading}>
                {searchFor === "practice" ? "GP Practice" : "GP Surgery"} Results by {ucFirst(searchBy)}
              </Text>
            )}

            {!query.length && (
              <>
                <Text format={TypographyType.Small} color={palette.greyBlue} marginTop={-5}>
                  Enter a search term in the search bar above to load results
                </Text>
                <Text format={TypographyType.Tiny} color={palette.greyBlue} marginTop={5}>
                  (Searching auto-hides Settings)
                </Text>
              </>
            )}

            {!errorMessage && !!query.length && searchResults.length === 0 && (
              <Text format={TypographyType.Regular} color={palette.greyBlue} style={styles.searchingText}>
                {query.length < 3 ? "Type at least 3 characters to search . . ." : "Searching . . ."}
              </Text>
            )}

            {!!errorMessage && (
              <Text format={TypographyType.Regular} color={palette.greyBlue}>
                {errorMessage}
              </Text>
            )}

            {(!query.length || !!errorMessage) && <Mod.HelperText />}
          </View>

          <View>
            {!!query.length &&
              searchResults.map((result, index) => (
                <Mod.Result
                  key={index}
                  query={query}
                  searchBy={searchBy}
                  result={result}
                  getGPData={props.getGPData}
                  setSelectedResult={setSelectedResult}
                  setErrorMessage={setErrorMessage}
                />
              ))}
          </View>
        </View>
      </Animated.View>
    </>
  ) : (
    <Animated.View style={confirmationScreenStyles}>
      <Text format={TypographyType.HeadingSmall}>Confirm Selection</Text>

      {!!selectedResult && selectedResult.surgery && (
        <>
          <View style={styles.textRow}>
            <Text format={TypographyType.Regular}>You have selected the </Text>
            <Text format={TypographyType.RegularBold}>GP Surgery</Text>
            <Text format={TypographyType.Regular}>:</Text>
          </View>
          {!!selectedResult && (
            <Mod.Result
              result={{
                id: selectedResult.surgery.id,
                roleId: "",
                name: selectedResult.surgery.name,
                postcode: selectedResult.surgery.postcode,
              }}
              readOnly
            />
          )}
        </>
      )}

      {!!selectedResult && (
        <>
          <View style={styles.textRow}>
            <Text format={TypographyType.Regular}>
              {selectedResult.surgery ? "which is part of the " : "You have selected the "}
            </Text>
            <Text format={TypographyType.RegularBold}>GP Practice</Text>
            <Text format={TypographyType.Regular}>:</Text>
          </View>
          <Mod.Result
            result={{
              id: selectedResult.id,
              roleId: "",
              name: selectedResult.name,
              postcode: selectedResult.postcode,
            }}
            readOnly
          />
        </>
      )}

      {!!selectedResult && selectedResult.ccg && (
        <>
          <View style={styles.textRow}>
            <Text format={TypographyType.Regular}>which belongs to the </Text>
            <Text format={TypographyType.RegularBold}>CCG</Text>
            <Text format={TypographyType.Regular}>:</Text>
          </View>
          <Mod.Result
            result={{
              id: selectedResult.ccg.id,
              roleId: "",
              name: selectedResult.ccg.name,
              postcode: "",
            }}
            readOnly
          />
        </>
      )}

      <ButtonList>
        <Button onPress={_onConfirmResultSelection} width="100%">
          Confirm
        </Button>
        <TouchableOpacity onPress={() => setSelectedResult(null)}>
          <Text format={TypographyType.SmallBold} color={palette.blue} align="center">
            Search Again
          </Text>
        </TouchableOpacity>
      </ButtonList>
    </Animated.View>
  );
}

const styles = StyleSheet.create({
  confirmationScreen: {
    padding: spacing[30],
  },

  results: {
    paddingVertical: spacing[30],
  },
  resultsLabels: {
    paddingHorizontal: spacing[30],
  },

  resultsHeading: {
    marginBottom: spacing[10],
  },

  searchingText: {
    marginTop: spacing[15],
  },

  textRow: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: spacing[20],
    marginBottom: spacing[10],
  },
});
