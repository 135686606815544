import * as React from "react";
import Loading from "../Loading";
import { Query } from "react-apollo";
import { ErrorMessage } from "../Error/Error";
import { ClaimProcess } from "./ClaimProcess";
import { GET_CCGS_WITH_CLAIMS_ENABLED } from "@/models/Assessment";
import { hasClaims } from "libs/utils/featureFlags";

interface CcgItem {
  id: string;
  name: string;
  featureFlags: string;
}

interface Response {
  listOrganisations: {
    items: CcgItem[];
  };
}

const sortOrganisations = (a: CcgItem, b: CcgItem) => {
  return a
    ? a.name
        .replace(/(NHS|CCG)/g, "")
        .trim()
        .split(" ")[0]
        .localeCompare(
          b
            ? b.name
                .replace(/(NHS|CCG)/g, "")
                .trim()
                .split(" ")[0]
            : ""
        )
    : 0;
};

export default function ClaimProcessGQL() {
  return (
    <Query<Response> query={GET_CCGS_WITH_CLAIMS_ENABLED}>
      {({ error, data, loading }) => {
        if (error) {
          return <ErrorMessage apolloError={error} />;
        } else if (loading) {
          return <Loading />;
        } else if (data && data.listOrganisations) {
          const { items } = data.listOrganisations;
          const sortedOrganisations = items
            .filter(x => x.id.indexOf("s12Test") === -1)
            .filter(x => hasClaims(x.featureFlags))
            .sort(sortOrganisations);

          return <ClaimProcess ccgs={sortedOrganisations} />;
        } else {
          return <Loading />;
        }
      }}
    </Query>
  );
}
