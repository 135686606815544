import React from "react";
import { GestureResponderEvent, View, StyleSheet, StyleProp, ViewStyle, Platform } from "react-native";
import { TouchableRipple } from "react-native-paper";
import Swipeable from "react-native-gesture-handler/Swipeable";

import { color, spacing } from "../../theme";

import { CardButtons } from "../CardButtons";

interface CardButton {
  label: string;
  icon?: string;
  disabled?: boolean;
  onPress: () => void;
}

interface CardProps {
  children?: React.ReactNode;
  buttons?: CardButton[];
  lastCard?: boolean;
  style?: StyleProp<ViewStyle>;
  wrapperStyle?: StyleProp<ViewStyle>;
  padding?: StyleProp<ViewStyle>;
  marker?: string;
  bgCirclesColor?: string;
  onSwipeLeft?: any;
  onSwipeRight?: any;
  onPress?: (e: GestureResponderEvent) => void;
}

const GenericCard = (props: CardProps) => {
  const { children, lastCard = false } = props;

  const Wrapper: React.ComponentType<any> = Platform.OS === "web" ? View : Swipeable;

  const wrapperProps =
    Platform.OS === "web"
      ? {}
      : {
          renderLeftActions: props.onSwipeLeft && props.onSwipeLeft,
          overshootLeft: false,
          renderRightActions: props.onSwipeRight && props.onSwipeRight,
          overshootRight: false,
        };

  return (
    <Wrapper {...wrapperProps} style={props.wrapperStyle && props.wrapperStyle}>
      {props.onPress ? (
        <TouchableRipple
          onPress={props.onPress}
          rippleColor={color.thinBorder}
          style={[styles.card, lastCard && styles.lastCard, props.style]}
        >
          <View style={styles.row}>
            {!!props.marker && <View style={[styles.marker, { backgroundColor: props.marker }]} />}
            <View style={styles.cardInner}>
              {children && (
                <View style={[styles.content, props.padding ? props.padding : styles.contentPadding]}>{children}</View>
              )}
              {props.bgCirclesColor && (
                <>
                  <View
                    style={
                      props.bgCirclesColor
                        ? [styles.bgCircleBig, { backgroundColor: props.bgCirclesColor }]
                        : styles.bgCircleBig
                    }
                  />
                  <View
                    style={
                      props.bgCirclesColor
                        ? [styles.bgCircleSmall, { backgroundColor: props.bgCirclesColor }]
                        : styles.bgCircleSmall
                    }
                  />
                </>
              )}
            </View>
          </View>
        </TouchableRipple>
      ) : (
        <View style={[styles.card, lastCard && styles.lastCard]}>
          <View style={styles.cardInner}>
            {children && (
              <View style={[styles.content, props.padding ? props.padding : styles.contentPadding]}>{children}</View>
            )}
            {props.buttons && props.buttons.length > 0 && <CardButtons buttons={props.buttons} />}
            {!props.buttons && props.bgCirclesColor && (
              <>
                <View
                  style={
                    props.bgCirclesColor
                      ? [styles.bgCircleBig, { backgroundColor: props.bgCirclesColor }]
                      : styles.bgCircleBig
                  }
                />
                <View
                  style={
                    props.bgCirclesColor
                      ? [styles.bgCircleSmall, { backgroundColor: props.bgCirclesColor }]
                      : styles.bgCircleSmall
                  }
                />
              </>
            )}
          </View>
        </View>
      )}
    </Wrapper>
  );
};

const styles = StyleSheet.create({
  bgCircleBig: {
    position: "absolute",
    right: -80,
    bottom: -80,
    width: 160,
    height: 160,
    borderRadius: 160,
    opacity: 0.15,
    zIndex: 1,
  },

  bgCircleSmall: {
    position: "absolute",
    right: -58,
    bottom: -58,
    width: 116,
    height: 116,
    borderRadius: 160,
    opacity: 0.15,
    zIndex: 2,
  },

  card: {
    marginBottom: Platform.OS === "web" ? 0 : spacing[10],
    backgroundColor: color.background,
    borderRadius: 6,
    shadowColor: color.boxShadow,
    shadowOffset: { width: 4, height: 4 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
  },

  cardInner: {
    position: "relative",
    overflow: "hidden",
    flex: 1,
  },

  content: {
    position: "relative",
  },

  contentPadding: {
    padding: spacing[30],
  },

  lastCard: {
    marginBottom: 0,
  },

  marker: {
    width: 6,
    borderTopLeftRadius: 6,
    borderBottomLeftRadius: 6,
  },

  row: { flexDirection: "row" },
});

export default GenericCard;
