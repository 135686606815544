import React from "react";
import { ucFirst, mqWeb } from "@/utils/helpers";
import { CSVLink } from "react-csv";
import { palette } from "@/theme";
import { Button } from "@/components/Button";

export const CSVExportButton = <T extends Record<string, any>>(props: {
  propertiesToExport: {
    values: { displayItem: string; dependent?: string };
    heading: string;
    callback?: ({ displayItem, dependent }: { displayItem: string; dependent?: string }) => void;
  }[];
  itemsToExport: T[];
  fileName: "assessments" | "visits" | "claims";
  disabled: boolean;
}) => {
  const [csvData, setCSVData] = React.useState<any[] | string>("");
  const isWebView = mqWeb();

  function getCSVData() {
    const headings = props.propertiesToExport.map(d => d.heading);
    const csv = [
      headings,
      ...props.itemsToExport.map(item =>
        props.propertiesToExport.reduce(
          (acc, currentValue) => [
            ...acc,
            currentValue.callback
              ? currentValue.callback({
                  displayItem: item[currentValue.values.displayItem],
                  dependent: currentValue.values.dependent && item[currentValue.values.dependent],
                })
              : item[currentValue.values.displayItem],
          ],
          []
        )
      ),
    ];
    setCSVData(csv);
  }

  return (
    <>
      {props.disabled ? (
        <Button
          disabled={true}
          style={{
            marginBottom: 0,
          }}
        >
          {`export selected ${props.fileName}`}
        </Button>
      ) : (
        <CSVLink
          data={csvData}
          asyncOnClick={true}
          filename={`${ucFirst(props.fileName)}.csv`}
          onClick={(_event, done) => {
            getCSVData();
            setTimeout(() => done(), 0);
          }}
          style={{
            borderWidth: 2,
            borderRadius: isWebView ? 12 : 40,
            borderColor: props.disabled ? palette.cloud : palette.blue,
            backgroundColor: props.disabled ? palette.cloud : palette.blue,
            color: props.disabled ? palette.greyBlue : palette.white,
            textAlign: "center",
            textTransform: "uppercase",
            padding: 15,
            fontFamily: "Lato-Bold",
            fontSize: 14,
            textDecoration: "none",
            alignSelf: "center",
            width: isWebView ? "auto" : "100%",
          }}
        >
          {`export selected ${props.fileName}`}
        </CSVLink>
      )}
    </>
  );
};
