import {
  ConsoleLogger,
  DefaultDeviceController,
  DefaultMeetingSession,
  AudioVideoFacade,
  LogLevel,
  MeetingSessionConfiguration,
  VideoTileState,
  MeetingSessionStatus,
  MeetingSessionVideoAvailability,
  DevicePermission,
  Device,
} from "amazon-chime-sdk-js";

import { IconName } from "../components/Icon";
import { MeetingPrefs } from "../utils/recoil/index";

export enum ActionTypes {
  VideoPermissions = "VideoPermissions",
  MicrophonePermissions = "MicrophonePermissions",
  SelfVideoStarted = "SelfVideoStarted",
  SelfVideoStopped = "SelfVideoStopped",
  UserVideoUpdated = "UserVideoUpdated",
  SelfVideoUpdated = "SelfVideoUpdated",

  UserTurnedVideoOff = "UserTurnedVideoOff",
  TryingToConnect = "TryingToConnect",
  PoorConnection = "PoorConnection",
  GoodConnection = "GoodConnection",
  NoConnection = "NoConnection",
  SessionTimedOut = "SessionTimedOut",

  SelfVideoBlocked = "SelfVideoBlocked",

  UserAudioLevel = "UserAudioLevel",

  UserJoinedOrLeft = "UserJoinedOrLeft",
  UserJoined = "UserJoined",
  UserLeft = "UserLeft",

  NotificationFinished = "NotificationFinished",

  UserToggledMic = "UserToggledMic",

  CaptureAudioVideoDevices = "CaptureAudioVideoDevices", // Used to hold the device list so we can pass this as a prop to windowed deviceSelection
  SetCentreTile = "SetCentreTile",
}

export type UserAudio = {
  userId: string | null;
  attendeeId: string;
  volume: number;
  isMuted: boolean;
};

export type UserJoinedOrLeftPayload = {
  attendeeId: string;
  present: boolean;
  userId: string;
  notificationId: number;
};

export type NotificationFinishedPayload = {
  notificationId: number;
};

export type Notification = {
  id: number;
  message: string;
  icon?: IconName;
};

export type Actions =
  // permission exists to start using the video and audio stream, we can begin a connection and send and display video
  | [ActionTypes.VideoPermissions, DevicePermission]
  | [ActionTypes.MicrophonePermissions, DevicePermission]
  | [ActionTypes.UserJoinedOrLeft, UserJoinedOrLeftPayload]
  | [ActionTypes.NotificationFinished, NotificationFinishedPayload]
  | [ActionTypes.UserToggledMic, { attendeeId: string; micMuted: boolean; userId: string }]
  | [ActionTypes.UserVideoUpdated, VideoTileState | null]
  // the user's own video has updated its state. read the state and optionally update the view
  | [ActionTypes.SelfVideoUpdated, VideoTileState | null]
  // the user's own camera is now running and using the tile
  | [ActionTypes.SelfVideoStarted, VideoTileState | null]
  | [ActionTypes.SelfVideoStopped]
  // the user has left, the tile id is no longer in use. its video stream should be stopped, or the view should be updated with a message
  | [ActionTypes.UserTurnedVideoOff, { tileId: number | null }]
  // bad internet, attempting to connect
  | [ActionTypes.TryingToConnect]
  | [ActionTypes.PoorConnection]
  | [ActionTypes.GoodConnection]
  | [ActionTypes.SessionTimedOut]
  // no internet
  | [ActionTypes.NoConnection]
  // the user has not allowed the camera to be used, or no camera exists
  | [ActionTypes.SelfVideoBlocked]
  | [ActionTypes.UserAudioLevel, UserAudio]
  | [ActionTypes.SetCentreTile, "tile2" | "tile3" | "tile4"];

export class MeetingRoom {
  session: DefaultMeetingSession & {
    audioVideo: AudioVideoFacade & {
      audioVideoController?: {
        sessionStateController: { currentState: number };
      };
    };
  };

  configuration: MeetingSessionConfiguration;
  localAttendeeId: string;
  canStartLocalVideo?: boolean;
  nextNotificationId: number;
  inactive?: boolean = false;
  private dispatch: (action: Actions) => void;
  private deleteNotification: (id: number) => void;

  connectVideoTileStreamToVideoElement(tileId: number, element: HTMLVideoElement) {
    this.session.audioVideo.bindVideoElement(tileId, element);
  }

  disconnectTileFromElement(tileId: number) {
    this.session.audioVideo.removeVideoTile(tileId);
  }

  connectAudioElement(element: HTMLAudioElement) {
    this.session.audioVideo.bindAudioElement(element);
  }

  muteSelf() {
    this.session.audioVideo.realtimeMuteLocalAudio();
  }

  unmuteSelf() {
    this.session.audioVideo.realtimeUnmuteLocalAudio();
  }

  changeSettings(meetingPrefs: MeetingPrefs) {
    // return;
    const { audio, video, quality, microphone } = meetingPrefs;

    if (video && video.value) {
      this.session.audioVideo.listVideoInputDevices().then(devices => {
        devices.forEach(device => {
          if (device.deviceId === video.value) {
            this.session.audioVideo.chooseVideoInputDevice(video.value);
          }
        });
      });
    }

    if (audio && audio.value) {
      this.session.audioVideo.chooseAudioOutputDevice(audio.value);
    }

    if (quality) {
      this.session.audioVideo.chooseVideoInputQuality(
        quality.width,
        quality.height,
        quality.frameRate,
        quality.maxBandwidthKbps
      );
    }

    if (microphone) {
      this.session.audioVideo.chooseAudioInputDevice(microphone.value);
    }
  }

  cameraOn(meetingPrefs: MeetingPrefs) {
    if (this.canStartLocalVideo !== false) {
      this.session.audioVideo
        .listVideoInputDevices()
        .then((devices: Device[]) => {
          const selectedDevice = devices.find(device => {
            return device.deviceId === meetingPrefs.video?.value;
          });

          return this.session.audioVideo.chooseVideoInputDevice(
            selectedDevice ? selectedDevice.deviceId : devices[0]?.deviceId
          );
        })
        .then(_permission => {
          const tileId = this.session.audioVideo.startLocalVideoTile();
          this.dispatch([ActionTypes.SelfVideoStarted, this.session.audioVideo.getVideoTile(tileId)?.state() || null]);
        });
    }
  }

  cameraOff() {
    this.session.audioVideo.stopLocalVideoTile();
    this.dispatch([ActionTypes.SelfVideoStopped]);
  }

  isSelfMuted() {
    return this.session.audioVideo.realtimeIsLocalAudioMuted();
  }

  // public methods

  // private observables

  videoAvailabilityDidChange(availability: MeetingSessionVideoAvailability): void {
    this.canStartLocalVideo = availability.canStartLocalVideo;
  }

  audioSessionDidStart(_isReconnect: boolean) {
    // stfu eslint
  }

  videoSessionDidStartWithStatus(_sessionStatus: MeetingSessionStatus) {
    // stfu eslint
  }

  volumeDidChange(_volumeUpdates: any[]) {
    // stfu eslint
  }

  videoTileDidUpdate(tileState: VideoTileState) {
    if (tileState.localTile) {
      this.dispatch([ActionTypes.SelfVideoUpdated, tileState]);
    } else {
      this.dispatch([ActionTypes.UserVideoUpdated, tileState]);
    }
  }

  audioVideoDidStart() {
    try {
      const tileId = this.session.audioVideo.startLocalVideoTile();
      this.dispatch([ActionTypes.SelfVideoStarted, this.session.audioVideo.getVideoTile(tileId)?.state() || null]);
    } catch (e) {
      this.dispatch([ActionTypes.SelfVideoBlocked]);
    }
  }

  videoTileWasRemoved(tileId: number) {
    if (this.session.audioVideo.audioVideoController?.sessionStateController.currentState === 0) {
      this.dispatch([ActionTypes.SessionTimedOut]);
    }
    this.dispatch([ActionTypes.UserTurnedVideoOff, { tileId }]);
  }

  audioVideoDidStartConnecting(reconnecting: boolean) {
    this.dispatch([ActionTypes.TryingToConnect]);
  }

  connectionDidBecomePoor() {
    this.dispatch([ActionTypes.PoorConnection]);
  }

  attendeePresenceDidChange(attendeeId: string, present: boolean, externalUserId?: string | undefined) {
    const notificationId = this.nextNotificationId++;
    this.dispatch([
      ActionTypes.UserJoinedOrLeft,
      {
        attendeeId,
        present,
        userId: externalUserId!,
        notificationId,
      },
    ]);
    this.deleteNotification(notificationId);

    if (present === false) {
      this.session.audioVideo.realtimeUnsubscribeFromVolumeIndicator(attendeeId);
    } else if (present === true && externalUserId) {
      this.session.audioVideo.realtimeSubscribeToVolumeIndicator(
        attendeeId,
        throttleVolumeChange(1000, this.audioLevelDidChange.bind(this))
      );
    }
  }

  audioLevelDidChange(
    attendeeId: string | null, // random guid
    volume: number | null, // null means no change, number 0-1
    isMuted: boolean | null, // null means no change
    signal: number | null, // null means no change, .5 is weak, 1 is good, 0 is poor
    externalUserId: string // the actual user id in the s12 app
  ) {
    this.dispatch([
      ActionTypes.UserAudioLevel,
      {
        userId: externalUserId,
        attendeeId: attendeeId || "",
        volume: volume || 0,
        isMuted: !!isMuted,
      },
    ]);

    if (isMuted === true || isMuted === false) {
      this.dispatch([
        ActionTypes.UserToggledMic,
        {
          attendeeId: attendeeId || "",
          micMuted: isMuted,
          userId: externalUserId!,
        },
      ]);
    }
  }

  /*
  connectionHealthDidChange(health: ConnectionHealthData) {
    if (health.isGoodSignalRecent(3000)) {
      this.dispatch([ActionTypes.GoodConnection]);
    } else if (health.isWeakSignalRecent(3000)) {
      this.dispatch([ActionTypes.PoorConnection]);
    } else if (health.isNoSignalRecent(3000)) {
      this.dispatch([ActionTypes.NoConnection]);
    }
  } */

  // end private observables

  teardown() {
    this.inactive = true;
    this.dispatch = () => null;
    this.session.audioVideo.removeObserver(this);
    this.session.audioVideo.stopLocalVideoTile();
    this.session.audioVideo.stop();
  }

  constructor(
    meetingInfo: any,
    attendeeInfo: any,
    localAttendeeId: string,
    audioElement: HTMLAudioElement,
    dispatch: (action: Actions) => void,
    meetingPrefs: MeetingPrefs
  ) {
    const logger = new ConsoleLogger("ChimeMeetingLogs", LogLevel.WARN);
    const deviceController = new DefaultDeviceController(logger);
    const configuration = new MeetingSessionConfiguration(meetingInfo, attendeeInfo);
    const meetingSession = new DefaultMeetingSession(configuration, logger, deviceController);

    this.configuration = configuration;
    this.session = meetingSession;
    this.localAttendeeId = localAttendeeId;
    this.dispatch = dispatch;
    this.deleteNotification = (notificationId: number) => {
      setTimeout(() => {
        this.dispatch([ActionTypes.NotificationFinished, { notificationId }]);
      }, 5000);
    };

    this.nextNotificationId = 0;

    this.session.audioVideo.addObserver(this);

    meetingSession.audioVideo
      .listVideoInputDevices()
      .then((devices: Device[]) => {
        if (this.inactive) {
          return;
        }
        const targetSelectedDevice: Device = devices.find(
          (device: Device): Device => {
            return device.deviceId === meetingPrefs.video?.value;
          }
        );

        return meetingSession.audioVideo.chooseVideoInputDevice(
          targetSelectedDevice ? targetSelectedDevice.deviceId : devices[0]?.deviceId || null
        );
      })
      .then(permissions => {
        if (this.inactive) {
          return;
        }
        // TODO: handle no permissions
        this.dispatch([ActionTypes.VideoPermissions, permissions || 0]);
      })
      .then(() => meetingSession.audioVideo.listAudioInputDevices())
      .then(devices => {
        if (this.inactive) {
          return;
        }
        // null equals the default device
        const targetSelectedDevice: Device = devices.find(
          (device: Device): Device => {
            return device.deviceId === meetingPrefs.microphone?.value;
          }
        );

        return meetingSession.audioVideo.chooseAudioInputDevice(
          targetSelectedDevice ? targetSelectedDevice.deviceId : devices[0]?.deviceId || null
        );
      })
      .then(permissions => {
        if (this.inactive) {
          return;
        }
        // TODO: handle no permissions
        this.dispatch([ActionTypes.MicrophonePermissions, permissions as DevicePermission]);
      })
      .then(() => {
        return meetingSession.audioVideo.listAudioOutputDevices();
      })
      .then(devices => {
        if (this.inactive) {
          return;
        }
        // null equals the default device
        return meetingSession.audioVideo.chooseAudioOutputDevice(meetingPrefs.audio?.value || null);
      })
      .then(() => {
        if (this.inactive) {
          return;
        }
        // bind audio must happen before starting up session.
        meetingSession.audioVideo.bindAudioElement(audioElement);
      })
      .then(() => {
        if (this.inactive) {
          return;
        }
        meetingSession.audioVideo.realtimeSetCanUnmuteLocalAudio(true);
        meetingSession.audioVideo.realtimeUnmuteLocalAudio();

        this.session.audioVideo.realtimeSubscribeToVolumeIndicator(
          this.configuration.credentials!.attendeeId || "",
          throttleVolumeChange(500, this.audioLevelDidChange.bind(this))
        );

        this.session.audioVideo.realtimeSubscribeToAttendeeIdPresence(this.attendeePresenceDidChange.bind(this));
      })
      .then(() => {
        if (this.inactive) {
          this.teardown();
          return;
        }
        this.session.audioVideo.start();
      });
  }
}

function throttleVolumeChange(
  waitInMs: number,
  callback: (attendeeId: string, volume: number, ...rest: any[]) => void
) {
  let timeout: NodeJS.Timeout | null;
  let avg = 0;
  let mute: boolean | null = null;
  let count = 0;
  let cleanupCallback: NodeJS.Timeout | null = null;

  return function(attendeeId: string, volume: number, muted: boolean, ...other: any[]) {
    const next = () => {
      if (count > 0) {
        callback(attendeeId, avg, mute, ...other);
      }
      avg = 0;
      count = 0;
      // don't clear muted, leave it in the last state.
      timeout = null;
      cleanupCallback = setTimeout(() => callback(attendeeId, avg, mute, ...other), waitInMs);
    };

    if (cleanupCallback) {
      clearTimeout(cleanupCallback);
      cleanupCallback = null;
    }

    const callNow = !timeout;

    mute = muted;
    count += 1;
    avg = (avg * (count - 1)) / count + volume / count;

    if (callNow) {
      next();
    }

    if (!timeout) {
      timeout = setTimeout(next, waitInMs);
    }
  };
}
