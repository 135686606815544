/* eslint-disable react-native/no-unused-styles */
import React, { useMemo } from "react";
import { StyleSheet, TextStyle, TouchableWithoutFeedback, View, ViewStyle } from "react-native";

import { mqWeb } from "../../../../utils/helpers";
import { palette, spacing } from "../../../../theme";

import { ContentWrap } from "../../../ContentWrap";

interface ExternalAuthFormProps {
  children: React.ReactNode;
}

export const ExternalAuthForm = (props: ExternalAuthFormProps) => {
  const isWebView = mqWeb();
  const layout = useMemo(() => (isWebView ? "Web" : "Mobile"), [isWebView]);

  const Wrapper = useMemo(() => (isWebView ? React.Fragment : ContentWrap), [isWebView]);

  return (
    <TouchableWithoutFeedback onPress={e => e.stopPropagation()}>
      <Wrapper>
        <View style={styles[`container${layout}`]}>{props.children}</View>
      </Wrapper>
    </TouchableWithoutFeedback>
  );
};

const styles: { [key: string]: ViewStyle | TextStyle } = StyleSheet.create({
  containerMobile: {},
  containerWeb: {
    width: 450,
    padding: spacing[50],
    borderRadius: 15,
    shadowColor: palette.shadow,
    shadowRadius: 24,
    shadowOpacity: 0.07,
    shadowOffset: { width: 0, height: 6 },
    alignSelf: "center",
  },
});
