import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import Auth from "@aws-amplify/auth";
import Config from "react-native-config";
import dotenvParseVariables from "dotenv-parse-variables";
import omit from "lodash.omit";

const configProps = omit(Config, ["getConstants"]);
const ENV = dotenvParseVariables(configProps);

const headers: Readonly<Record<string, string | boolean>> = {
  "Access-Control-Request-Headers": "X-Requested-With",
  "Access-Control-Request-Method": "",
  Origin: ENV.WEB_URI,
};

enum Methods {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
}

// For cors the request method needs to be set for each method
const setHeaders = (method: Methods) => {
  switch (method) {
    case Methods.GET:
      return {
        ...headers,
        "Access-Control-Request-Method": "GET",
      };
    case Methods.POST:
      return {
        ...headers,
        "Access-Control-Request-Method": "POST",
      };
    case Methods.PUT:
      return {
        ...headers,
        "Access-Control-Request-Method": "PUT",
      };
    case Methods.DELETE:
      return {
        ...headers,
        "Access-Control-Request-Method": "DELETE",
      };
    default:
      return headers;
  }
};

const injectToken = async (config: AxiosRequestConfig) => {
  try {
    const session = await Auth.currentSession();
    const token = session ? `Bearer ${session.getIdToken().getJwtToken()}` : "";

    if (token) {
      // 🧐 there will always be headers as I've set them when I created the instance
      if (!config.headers) {
        config.headers = {};
      }
      config.headers.Authorization = token;
    }
  } catch (err) {}

  return config;
};

class API {
  private instance: AxiosInstance;

  constructor() {
    const instance = axios.create({
      baseURL: ENV.AWS_HTTP_ENDPOINT_V2,
      headers,
    });

    // Add a request interceptor to inject token
    instance.interceptors.request.use(injectToken, error => Promise.reject(error));

    this.instance = instance;
  }

  get<T = any>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    if (config) {
      if (!config.headers) {
        config.headers = {};
      }
      config.headers = setHeaders(Methods.GET);
    }

    return this.instance.get<T, AxiosResponse<T>>(url, config);
  }

  post<T = any, D = any>(url: string, data?: D, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    if (config) {
      if (!config.headers) {
        config.headers = {};
      }
      config.headers = setHeaders(Methods.POST);
    }

    return this.instance.post<T, AxiosResponse<T>, D>(url, data, config);
  }

  put<T = any>(url: string, data?: T, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    if (config) {
      if (!config.headers) {
        config.headers = {};
      }
      config.headers = setHeaders(Methods.PUT);
    }

    return this.instance.put<T, AxiosResponse<T>>(url, data, config);
  }

  delete<T = any>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    if (config) {
      if (!config.headers) {
        config.headers = {};
      }
      config.headers = setHeaders(Methods.DELETE);
    }

    return this.instance.delete<T, AxiosResponse<T>>(url, config);
  }
}

export default new API();
