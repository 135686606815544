import { PathConfigMap } from "@react-navigation/native";

const basepath = "AMHP";
const dashboardPath = `${basepath}/Dashboard`;
const assessmentsPath = `${basepath}/Assessments`;

const AMHPScreens: PathConfigMap = {
  AMHPDashboardTab: {
    screens: {
      UserDashboardScreen: { path: dashboardPath },
      TeamInboxFormViewerScreen: { path: `${dashboardPath}/inbox/:formType/:formId` },
    },
  },

  AMHPAssessmentsTab: {
    screens: {
      AssessmentScreen: { path: assessmentsPath },
      SendToDoctorScreen: { path: `${assessmentsPath}/assessmentdetails/:assessmentId/sendtodoctor` },
      AssessmentDetailsScreen: { path: `${assessmentsPath}/assessmentdetails/:assessmentId/:notificationUpdate?` },
      MHAFormScreen: { path: `${assessmentsPath}/assessmentdetails/:assessmentId/forms/:formType/:formId?` },
      CreateAssessmentScreen: { path: `${assessmentsPath}/create/:assessmentId?` },
      SearchResultsScreen: { path: `${assessmentsPath}/searchresults` },
      MapScreen: { path: `${assessmentsPath}/map` },
      ProfileScreen: { path: `${assessmentsPath}/doctorprofile/:id/assessment/:assessmentId` },
      CreateClaimScreen: { path: `${assessmentsPath}/createclaim/:visitId` },
      ClaimProcessScreen: { path: `${assessmentsPath}/claimprocess` },
      MeetingRoomPreviewScreen: { path: `${assessmentsPath}/preview/:assessmentId` },
      MeetingRoomScreen: { path: `${assessmentsPath}/video/:assessmentId` },
    },
  },
};

export default AMHPScreens;
