import React from "react";
import { StyleSheet, View } from "react-native";

import { TypographyType } from "@/models/Typography";
import Text from "../components/Text";
import { color } from "../theme/color";

const ResourcesScreen = () => {
  return (
    <View style={styles.container}>
      <Text format={TypographyType.HeadingLarge}>Resources Screen</Text>
    </View>
  );
};

export default ResourcesScreen;

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    backgroundColor: color.background,
    flex: 1,
    justifyContent: "center",
  },
});
