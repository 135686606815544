import { useMemo, ReactNode } from "react";
import { useSetRecoilState } from "recoil";
import { globalModalData as recoilGlobalModalData } from "../utils/recoil/index";

interface OpenModalProps {
  component: ReactNode;
  label: string;
  onDismiss?: () => void;
}

export default () => {
  const setModalData = useSetRecoilState(recoilGlobalModalData);

  const openModal = (modalData: OpenModalProps) => {
    setModalData(modalData);
  };

  const closeModal = () => setModalData(null);

  return useMemo(
    () => ({
      openModal,
      closeModal,
    }),
    []
  );
};
