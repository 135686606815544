import React from "react";
// import { Platform } from "react-native";

import MeetingRoom from "@/components/MeetingRoom/MeetingRoom.gql";
// import MeetingRoomProvider from "@/components/MeetingRoom/native/state/MeetingRoomProvider";
import { RouteKeys } from "@/navigationv2";
import {
  AMHPAssessmentsRoutes,
  DoctorTabRoutes,
  DoctorVisitsRoutes,
  ScreenNavigationProps,
  ScreenOptions,
} from "@/navigationv2/types";
import { createScreenTitle } from "@/navigationv2/utilities";

interface PropTypes
  extends ScreenNavigationProps<
    DoctorTabRoutes,
    AMHPAssessmentsRoutes & DoctorVisitsRoutes,
    RouteKeys.MeetingRoomScreen
  > {}

// const ScreenWrapper: { [key: string]: React.ComponentType<any> } = {
//   ios: MeetingRoomProvider,
//   android: MeetingRoomProvider,
// };

const MeetingRoomScreen = (props: PropTypes) => {
  const { route } = props;
  // const Component = ScreenWrapper[Platform.OS] || React.Fragment;

  return <MeetingRoom assessmentId={route.params.assessmentId} />;
};

const screenOptions: ScreenOptions = {
  title: createScreenTitle("Meeting Room"),
};

MeetingRoomScreen.screenOptions = screenOptions;

export default MeetingRoomScreen;
