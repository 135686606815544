import React, { useMemo } from "react";
import { View, StyleSheet, Platform } from "react-native";
import { useRecoilValue } from "recoil";

import { ContentWrap } from "@/components";
import { color } from "@/theme";
import { ComponentElement } from "libs/types/mhaForms";

import { userDetails as userDetailsRecoil } from "@/utils/recoil";
import { FormikData, getComponentProps, getFormComponent } from "../MHAFormContainer/helpers";

export const MHAFormSection = function(props: {
  formData: FormikData;
  elements: ComponentElement[];
  sectionName: string;
  readonly?: boolean;
}) {
  return (
    <>
      {props.elements.map((element, i) => (
        <Wrapper
          data-test-id={`mha-form-section-${i}`}
          key={props.sectionName + i.toString()}
          formData={props.formData}
          element={element}
          sectionName={props.sectionName}
          readonly={props.readonly}
        />
      ))}
    </>
  );
};

export const Wrapper = (props: {
  formData: FormikData;
  element: ComponentElement;
  sectionName: string;
  readonly?: boolean;
}) => {
  const userDetails = useRecoilValue(userDetailsRecoil);
  const { component, componentProps, genericProps } = props.element;
  const fieldName = "fieldName" in props.element ? props.element.fieldName : undefined;
  const computedProps = getComponentProps(component, props.sectionName, fieldName, props.formData, props.readonly);

  const isExternalDoctor = useMemo(
    () => userDetails?.id.startsWith("Form-") && userDetails?.groups.includes("ExternalDoctor"),
    [userDetails]
  );

  const {
    conditionallyDisabledBy,
    conditionallyDisabledValues,
    conditionallyDisabledSection,
    conditionallyReadonlyBy,
    conditionallyReadonlyValues,
    conditionallyReadonlySection,
    conditionallyRenderedBy,
    conditionallyRenderedValues,
    readonlyIfInitialValue,
    indentation,
  } = genericProps || {};

  const disabledValue: string | string[] = conditionallyDisabledBy
    ? (props.formData.values[conditionallyDisabledSection || props.sectionName][conditionallyDisabledBy] as
        | string
        | string[])
    : "";

  const readonlyValue: string | string[] = conditionallyReadonlyBy
    ? (props.formData.values[conditionallyReadonlySection || props.sectionName][conditionallyReadonlyBy] as
        | string
        | string[])
    : "";

  const conditionalDisabledValue: string = typeof disabledValue === "string" ? disabledValue : disabledValue[0] || "";
  const conditionalReadonlyValue: string = typeof readonlyValue === "string" ? readonlyValue : readonlyValue[0] || "";
  const shouldReadonlyIfInitialValue =
    readonlyIfInitialValue &&
    fieldName &&
    props.formData.initialValues[props.sectionName][fieldName] &&
    !isExternalDoctor;

  // bit of a workaround as initially these were only set for radio button values as such comparing to strings, checkboxes on the other hand are arrays of strings, in all forms only having a single case of disabling based on a single checkbox so if its not a string will compare to the first item of the array
  const isDisabled = props.readonly
    ? false
    : genericProps && disabledValue && conditionallyDisabledValues
    ? conditionallyDisabledValues.includes(conditionalDisabledValue)
    : false;

  const isReadonly = props.readonly
    ? true
    : shouldReadonlyIfInitialValue
    ? true
    : genericProps && readonlyValue && conditionallyReadonlyValues
    ? conditionallyReadonlyValues.includes(conditionalReadonlyValue)
    : false;

  const isRendered = props.readonly
    ? true
    : !genericProps ||
      !conditionallyRenderedValues ||
      (genericProps &&
      conditionallyRenderedValues &&
      conditionallyRenderedBy &&
      props.formData.values[props.sectionName][conditionallyRenderedBy]
        ? conditionallyRenderedValues.includes(
            props.formData.values[props.sectionName][conditionallyRenderedBy]?.toString() as string
          )
        : false);

  // Wipe disabled fields, values
  // TODO: Small Bug, this only wipes data if the section is loaded on screen, it
  // does not ensure the section initial values is correct
  if (
    ((computedProps.value || computedProps.locationValue) &&
      (props.element.component !== "RadioOptionsList" || props.element.genericProps?.nullable) &&
      !isRendered) ||
    (isRendered &&
      isDisabled &&
      (computedProps.value || computedProps.locationValue) &&
      (props.element.component !== "RadioOptionsList" || props.element.genericProps?.nullable))
  ) {
    const fieldName = "fieldName" in props.element ? props.element.fieldName : undefined;
    if (fieldName && computedProps.value && "resetInput" in computedProps) {
      computedProps.resetInput();
    } else if (fieldName && computedProps.locationValue && "resetInput" in computedProps) {
      computedProps.resetInput();
    }
  }

  const Outer: React.ComponentType<any> =
    "fullWidth" in props.element && props.element.fullWidth && !isReadonly ? View : ContentWrap;
  const Component = getFormComponent(component) as React.ComponentType<any>;

  const externalDoctorProps =
    isExternalDoctor && genericProps?.externalDoctorCopy && ["TextInput", "DoctorLocation"].includes(component)
      ? { externalDoctorCopy: genericProps?.externalDoctorCopy }
      : {};

  const indentationMargin = useMemo(
    () =>
      Platform.select({
        web: 20,
        default: 10,
      }),
    []
  );

  return isRendered ? (
    <Outer style={indentation && props.element.component === "Text" ? { backgroundColor: color.MHAFormPink } : {}}>
      <View style={[indentation && { marginLeft: indentationMargin * indentation }, styles.flex]}>
        {isDisabled && <View style={styles.disabled} />}
        <Component readonly={isReadonly} {...componentProps} {...computedProps} {...externalDoctorProps} />
      </View>
    </Outer>
  ) : (
    <></>
  );
};

const styles = StyleSheet.create({
  flex: { flex: 1 },
  disabled: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 10,
    backgroundColor: color.background,
    opacity: 0.8,
  },
});
