import { useEffect, useState, useCallback, DependencyList } from "react";
import { Alert, Dimensions, Keyboard, Platform } from "react-native";
import { ApolloClient } from "apollo-boost";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { color, spacing } from "../theme";
import { ClaimStatus, FuelType, Engine } from "libs/types/API";
import { AvailabilityState } from "libs/types/availability";
import dayjs, { Dayjs } from "dayjs";

export function useDeviceHeight() {
  const device = Platform.OS === "web" ? "window" : "screen";
  const [deviceHeight, setDeviceHeight] = useState(Dimensions.get(device).height);

  useEffect(() => {
    const _handleResize = () => setDeviceHeight(Dimensions.get(device).height);
    Dimensions.addEventListener("change", _handleResize);
    return () => Dimensions.removeEventListener("change", _handleResize);
  }, []);

  return deviceHeight;
}

export function useDeviceWidth() {
  const device = Platform.OS === "web" ? "window" : "screen";
  const [deviceWidth, setDeviceWidth] = useState(Dimensions.get(device).width);

  useEffect(() => {
    const _handleResize = () => setDeviceWidth(Dimensions.get(device).width);
    Dimensions.addEventListener("change", _handleResize);
    return () => Dimensions.removeEventListener("change", _handleResize);
  }, []);

  return deviceWidth;
}

export const useDeviceOrientation = (): "portrait" | "landscape" => {
  const device = Platform.OS === "web" ? "window" : "screen";
  const [deviceWidth, setDeviceWidth] = useState(Dimensions.get(device).width);
  const [deviceHeight, setDeviceHeight] = useState(Dimensions.get(device).height);

  useEffect(() => {
    const _handleResize = () => {
      setDeviceWidth(Dimensions.get(device).width);
      setDeviceHeight(Dimensions.get(device).height);
    };
    Dimensions.addEventListener("change", _handleResize);
    return () => Dimensions.removeEventListener("change", _handleResize);
  }, []);

  if (deviceWidth > deviceHeight) return "landscape";
  return "portrait";
};

export function mqWeb() {
  const deviceWidth = useDeviceWidth();
  return deviceWidth >= 1000;
}

export async function savePushNotificationToken(
  tokens: string[] | undefined,
  callback: (pushTokens: string[]) => void
) {
  if (Platform.OS === "web") return;
  const token = await AsyncStorage.getItem("pushToken");
  if (token && (!tokens || !tokens.includes(token))) callback(tokens?.length ? [...tokens, token] : [token]);
}

export async function saveCurrentPushNotificationToken(callback: (token: string) => void) {
  if (Platform.OS === "web") return;
  const token = await AsyncStorage.getItem("pushToken");
  if (token) callback(token);
}

export function setStartEndVisitTimes(visitTime: Dayjs) {
  const start = visitTime.subtract(30, "minute");
  const end = visitTime.add(60, "minute");

  return {
    start,
    end,
  };
}

export function formatNhsNumber(value: string) {
  value = value.trim().replace(/[^\d]/g, "");

  switch (value.length) {
    case 12:
    case 11:
    case 10:
      value = value
        .substring(0, 3)
        .concat(" ")
        .concat(value.substring(3, 6))
        .concat(" ")
        .concat(value.substring(6, 10));
      break;
    case 9:
      value = value
        .substring(0, 3)
        .concat(" ")
        .concat(value.substring(3, 6))
        .concat(" ")
        .concat(value.substring(6, 9));
      break;
    case 8:
      value = value
        .substring(0, 3)
        .concat(" ")
        .concat(value.substring(3, 6))
        .concat(" ")
        .concat(value.substring(6, 8));
      break;
    case 7:
      value = value
        .substring(0, 3)
        .concat(" ")
        .concat(value.substring(3, 6))
        .concat(" ")
        .concat(value.substring(6, 7));
      break;
    case 6:
      value = value
        .substring(0, 3)
        .concat(" ")
        .concat(value.substring(3, 6));
      break;
    case 5:
      value = value
        .substring(0, 3)
        .concat(" ")
        .concat(value.substring(3, 5));
      break;
    case 4:
      value = value
        .substring(0, 3)
        .concat(" ")
        .concat(value.substring(3, 4));
      break;
    default:
      value = value.substring(0);
      break;
  }

  return value;
}

export function formatPostcode(postcode: string) {
  return postcode.replace(" ", "").slice(0, -3);
}

export function formatSearchResulsPostcode(postcode: string): string {
  const district = formatPostcode(postcode);
  // Isle of wight has their district postcode simply displayed as ISLE
  // Their district postcodes are PO30 - PO41 inclusive
  const wight = district.match(/^PO(\d{2})$/);
  if (wight && wight[1]) {
    const num = Number(wight[1]);
    if (num && num >= 30 && num <= 41) {
      return "ISLE";
    }
  }

  return district;
}

export function getAssessmentLabelColor(time: string) {
  const daysUntilAssessment = dayjs(time)
    .startOf("day")
    .diff(dayjs().startOf("day"), "day");

  const labelColor: "red" | "aqua" | "blue" =
    daysUntilAssessment < 0 ? "red" : daysUntilAssessment === 0 ? "aqua" : "blue";

  const daysLabel: string | false =
    daysUntilAssessment < 0
      ? "Archived"
      : daysUntilAssessment === 0
      ? "Today"
      : daysUntilAssessment === 1
      ? `${daysUntilAssessment} Day`
      : daysUntilAssessment <= 30
      ? `${daysUntilAssessment} Days`
      : false;

  return {
    labelColor,
    daysLabel,
  };
}

export function displayAvailabilityType(doctorAvailability: AvailabilityState) {
  if (doctorAvailability === AvailabilityState.oncall) {
    return "On call";
  }
  return ucFirst(
    doctorAvailability === AvailabilityState.inAssessment
      ? "In Assessment"
      : doctorAvailability === AvailabilityState.noInformation
      ? "No information"
      : doctorAvailability === AvailabilityState.trust
      ? "Trust"
      : doctorAvailability
  );
}

export function checkMutationInput<T extends { [key: string]: any }>(input: T): T {
  for (const property in input) {
    if (Object.prototype.hasOwnProperty.call(input, property)) {
      if (typeof input[property] === "string") {
        input[property] = input[property].trim() === "" ? null : input[property].trim();
      }

      if (typeof input[property] === "object") {
        for (const nestedProp in input[property]) {
          if (typeof input[property][nestedProp] === "string") {
            input[property][nestedProp] =
              input[property][nestedProp].trim() === "" ? null : input[property][nestedProp].trim();
          }
        }
      }
    }
  }
  return input;
}

export function debounce(callback: (...args: any[]) => void) {
  let timeout: NodeJS.Timeout;

  return function(...args: any[]) {
    const next = () => callback(...args);

    clearTimeout(timeout);
    timeout = setTimeout(next, 200);
  };
}

export function alert(
  label: string,
  confirmationMessage?: string,
  onCancel?: () => void,
  onConfirm?: () => void,
  cancelText?: string,
  confirmText?: string
) {
  if (Platform.OS === "web") {
    const action = confirmationMessage ? window.confirm(confirmationMessage) : window.alert(label);
    if (confirmationMessage) {
      action ? onConfirm && onConfirm() : onCancel && onCancel();
    }
  } else {
    return confirmationMessage
      ? Alert.alert(
          label,
          confirmationMessage,
          [
            {
              text: cancelText || "Cancel",
              onPress: onCancel,
              style: "cancel",
            },
            {
              text: confirmText || "OK",
              onPress: onConfirm,
            },
          ],
          { cancelable: true }
        )
      : Alert.alert(label);
  }
}

export function useKeyboard() {
  const INITIAL_VALUES = {
    isActive: false,
    height: spacing[50],
  };

  const [keyboard, setKeyboard] = useState(INITIAL_VALUES);

  const keyboardDidShow = (e: any) => {
    setKeyboard({
      isActive: true,
      height: e.endCoordinates.height - 30,
    });
  };

  const keyboardDidHide = () => {
    setKeyboard(INITIAL_VALUES);
  };

  useEffect(() => {
    const keyboardWillShowListener = Keyboard.addListener("keyboardDidShow", keyboardDidShow);
    const keyboardWillHideListener = Keyboard.addListener("keyboardDidHide", keyboardDidHide);

    return () => {
      keyboardWillShowListener.remove();
      keyboardWillHideListener.remove();
    };
  }, []);

  return keyboard;
}

export function isMobileDevice() {
  let isMobile = false;
  if ("maxTouchPoints" in window.navigator) {
    isMobile = window.navigator.maxTouchPoints > 0;
  } else if ("msMaxTouchPoints" in window.navigator) {
    isMobile = window.navigator.msMaxTouchPoints > 0;
  } else {
    const mQ = window.matchMedia && window.matchMedia("(pointer:coarse)");
    if (mQ && mQ.media === "(pointer:coarse)") {
      isMobile = !!mQ.matches;
    } else if ("orientation" in window) {
      isMobile = true; // deprecated, but good fallback
    } else {
      // Only as a last resort, fall back to user agent sniffing
      const UA = window.navigator.userAgent;
      isMobile =
        /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) || /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
    }
  }

  return isMobile;
}

export function amphClaimStatusCopy(status: ClaimStatus) {
  return status === ClaimStatus.action_required
    ? "With Doctor"
    : status === ClaimStatus.approved_and_paid
    ? "Paid"
    : ucFirst(status.split("_").join(" "));
}

export function activateFullscreen(element: any): void {
  try {
    if (element.requestFullscreen) {
      element.requestFullscreen().catch((e: Error) => console.log("error", e)); // W3C spec
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen().catch((e: Error) => console.log("error", e)); // Firefox
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen().catch((e: Error) => console.log("error", e)); // Safari
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen().catch((e: Error) => console.log("error", e)); // IE/Edge
    }
  } catch (e) {
    console.log("Could not activate fullscreen mode", e);
  }
}

export function deactivateFullscreen() {
  try {
    if (window.document.exitFullscreen) {
      window.document.exitFullscreen().catch((e: Error) => console.log("error", e));
    } else if (window.document.mozCancelFullScreen) {
      window.document.mozCancelFullScreen().catch((e: Error) => console.log("error", e));
    } else if (window.document.webkitExitFullscreen) {
      window.document.webkitExitFullscreen().catch((e: Error) => console.log("error", e));
    }
  } catch (e) {
    console.log("Coult not deactivate fullscreen mode", e);
  }
}

export function formatPhoneNumber(phoneNumber: string) {
  if (phoneNumber.substr(0, 3) === "+44") {
    return `${phoneNumber.substr(0, 3)} (0)${phoneNumber.substr(3, 4)} ${phoneNumber.substr(7)}`;
  }
  return phoneNumber;
}

export function updatePhonePrefix(phoneNumber: string) {
  const updNumber = phoneNumber.replace(/\s/g, "");
  return updNumber.slice(0, 1) === "0" ? `+44${updNumber.slice(1)}` : updNumber;
}

export function formatCCGName(ccg: string) {
  if (ccg.substr(0, 3) === "s12") return ccg;
  const ccgName = ccg.replace("NHS ", "").replace(" CCG", "");

  return formatAllUpperCase(ccgName);
}

export function useVMax() {
  const vMaxDimension = getMaxDimension();
  const [vMax, setVMax] = useState<number>(vMaxDimension);

  useEffect(() => {
    const handleResize = () => {
      const vMaxDimension = getMaxDimension();
      setVMax(vMaxDimension);
    };
    Dimensions.addEventListener("change", handleResize);
    return () => Dimensions.removeEventListener("change", handleResize);
  }, []);

  function getMaxDimension() {
    const windowWidth = Dimensions.get("window").width;
    const windowHeight = Dimensions.get("window").height;
    return windowWidth > windowHeight ? windowWidth : windowHeight;
  }

  return vMax;
}

export function ucFirst(string: string) {
  return string
    ? string
        .split(" ")
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ")
        .trim()
    : "";
}

export function minutesSince20190101(date: string) {
  const startDate = dayjs("2019-01-01");
  const addedDate = dayjs(date);
  return addedDate.diff(startDate, "minute");
}

/**
 * Extract the initials from a full name
 * @param name The full name to convert
 * @returns string of the initials only
 */
export function getInitials(name: string) {
  // eslint-disable-next-line
  return name.replace(/ *(\b[A-z])[A-z\.\-\(\)]* */g, "$1").toUpperCase();
}

/**
 * Create Claim ID
 * @param doctorName Name of the doctor to extract initials from
 * @returns string of the id made from a reduced unix epoch timestamp and doctor initials
 */
export function createClaimId(doctorName: string) {
  const modTimestamp = `${Math.floor(new Date().getTime() / 1000)}`.substring(1); // get the timestamp minus the first number
  const initials = getInitials(doctorName).substring(0, 3); // get the initials and limit to 3 chars max
  return `${modTimestamp}${initials}`;
}

export function formatCamelCase(input: string) {
  return input.replace(/^\w/, c => c.toUpperCase()).replace(/([a-z])([A-Z])/g, "$1 $2");
}

export function formatClaimStatus(status: ClaimStatus) {
  return status === "approved_and_paid" ? "PAID" : status.replace(/_/g, " ").toLocaleUpperCase();
}

export function formatAllUpperCase(input: string) {
  return input
    .toLowerCase()
    .split(" ")
    .map(word => `${word.charAt(0).toUpperCase()}${word.slice(1)}`)
    .join(" ");
}

export function formatEngineSize(fuelType: FuelType | null, engineSize: Engine | null) {
  if (!fuelType || !engineSize) return "";

  const engineSizeValues = {
    small: "< 1L",
    medium: "1L - 1.5L",
    large: "> 1.5L",
  };
  return engineSizeValues[engineSize];
}

export function formatFuelType(fuelType?: FuelType | null, engineSize?: Engine | null) {
  return fuelType
    ? `${formatCamelCase(fuelType)}${engineSize ? " (" + formatEngineSize(fuelType, engineSize) + ")" : ""}`
    : "";
}

/**
 * Formats a claim note with @ as separator
 * @param notes Formatted as TEXT@USER_FULL_NAME@TIMESTAMP
 */
export function sortClaimNotes(notes: string[]) {
  return notes.sort((a, b) => (dayjs(a.split("@")[2]).isAfter(dayjs(b.split("@")[2])) ? 1 : -1));
}

export function checkOfflineCache(client: ApolloClient<any>, query: any, variables?: any) {
  return Platform.OS !== "web"
    ? client.cache.diff({
        query: client.cache.transformDocument(query),
        variables,
        optimistic: false,
      }).complete
    : false;
}

export function generateEngineSizeDropdownOptions(
  fuelType: {
    value: FuelType;
    label: string;
  } | null
) {
  return [
    { name: "- Select -", id: "" },
    {
      id: Engine.small,
      name: formatEngineSize(fuelType && fuelType.value, Engine.small),
    },
    {
      id: Engine.medium,
      name: formatEngineSize(fuelType && fuelType.value, Engine.medium),
    },
    {
      id: Engine.large,
      name: formatEngineSize(fuelType && fuelType.value, Engine.large),
    },
  ];
}

export const fuelTypeDropdownOptions = [
  { name: "- Select -", id: "" },
  {
    id: "petrol",
    name: "Petrol",
  },
  {
    id: "diesel",
    name: "Diesel",
  },
  {
    id: "lpg",
    name: "LPG",
  },
  {
    id: "hybridPetrol",
    name: "Hybrid (Petrol)",
  },
  {
    id: "hybridDiesel",
    name: "Hybrid (Diesel)",
  },
  {
    id: "electric",
    name: "Electric",
  },
  { id: "bicycle", name: "Bicycle" },
];

export function chunkArray(arr: any[], chunk_size: number) {
  let index = 0;
  const arrLen = arr.length;
  const chunks: any[] = [];

  for (index = 0; index < arrLen; index += chunk_size) {
    const chunk = arr.slice(index, index + chunk_size);
    chunks.push(chunk);
  }

  return chunks;
}

export function callbackWithThenable(func: () => void, deps: DependencyList, callback: () => void) {
  return useCallback(() => {
    Promise.resolve(func()).then(callback);
  }, deps);
}

export function getColorByClaimStatus(status: ClaimStatus) {
  return status === ClaimStatus.action_required
    ? color.claimActionRequired
    : status === ClaimStatus.under_review
    ? color.claimProcessing
    : status === ClaimStatus.approved
    ? color.claimApproved
    : status === ClaimStatus.approved_and_paid
    ? color.claimPaid
    : color.claimRejected;
}
