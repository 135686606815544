import { View, Linking, StyleSheet } from "react-native";
import React from "react";
import { TypographyType } from "libs/types/Typography";
import { recordEvent } from "@/utils/analytics";
import { AnalyticsEvent } from "libs/analytics/events";
import { spacing } from "@/theme";
import Text from "../Text";
import { InfoItemIconList, InfoItemIcon } from "../InfoItem";
import { IconName } from "../Icon";
import { VerticalSpacer } from "../Spacer";

const FormsUpsell = () => (
  <View style={styles.upsellContainer}>
    <Text format={TypographyType.HeadingMedium}>Statutory MHA Forms</Text>
    <Text format={TypographyType.Regular}>
      S12 Solutions enables AMHPs and doctors to submit statutory MHA forms electronically. This feature isn’t currently
      enabled for your AMHP team but if you’re interested in this feature, we recommend that your AMHP Lead contacts the
      Mental Health Law Manager at your local Mental Health Trust; please get in touch with the S12 Solutions team for
      more information about this feature and support with local conversations:
    </Text>
    <VerticalSpacer height={20} />
    <InfoItemIconList>
      <InfoItemIcon
        iconName={IconName.email}
        format={TypographyType.Small}
        iconPosition="flex-start"
        linkLabel="Send Email"
        linkOnPress={() =>
          // do we care if the mail app failed to open?
          Linking.openURL("mailto:info@s12solutions.com?subject=MHA%20Forms%20Enquiry")
            .then(() => recordEvent(AnalyticsEvent.MHA_FORMS_ENQUIRY))
            .catch(() => null)
        }
      >
        info@s12solutions.com
      </InfoItemIcon>
    </InfoItemIconList>
  </View>
);

export default React.memo(FormsUpsell);

const styles = StyleSheet.create({
  upsellContainer: {
    paddingHorizontal: spacing[20],
    paddingBottom: spacing[10],
  },
});
