import React, { useEffect, useMemo, useState } from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import merge from "lodash.merge";
import gql from "graphql-tag";
import dayjs from "dayjs";
import { ExportItems, PropertiesToExport } from "./ExportItems";
import { LocationNameInput } from "libs/types/API";
import { formatAddress } from "libs/formatters/address";
import { getDefaultDateRange } from "./helpers";

interface PropTypes {
  username: string;
}

export const GET_ASSESSMENTS = gql`
  query GetAssessmentsByAmhp($id: ID!, $limit: Int, $nextToken: String, $to: String = "Z", $from: String = "0") {
    assessmentsByAmhp(
      amhpId: $id
      sortDirection: DESC
      limit: $limit
      nextToken: $nextToken
      assessmentDate: { between: [$from, $to] }
    ) {
      items {
        id
        assessmentDate
        patientName
        locationName {
          city
          postcode
          address
        }
        doctorVisits {
          items {
            id
            time
            doctor {
              id
              name
            }
            doctorVisitClaimId
            createdAt
          }
        }
        contactedDoctors {
          items {
            createdAt
            s12Doctor {
              id
              name
            }
          }
        }
        contactedDoctors {
          items {
            createdAt
            s12Doctor {
              id
              name
            }
          }
        }
        contactedDoctors {
          items {
            createdAt
            s12Doctor {
              id
              name
            }
          }
        }
      }
      nextToken
    }
  }
`;

interface DoctorVisit {
  id: string;
  time: string;
  doctor: {
    id: string;
    name: string;
  };
  doctorVisitClaimId: string;
  createdAt: string;
}

interface ContactedDoctor {
  createdAt: string;
  s12Doctor: {
    id: string;
    name: string;
  };
}
interface Response {
  assessmentsByAmhp: {
    items: {
      id: string;
      assessmentDate: string;
      patientName: string;
      locationName: LocationNameInput;
      doctorVisits: {
        items: DoctorVisit[];
      };
      contactedDoctors: {
        items: ContactedDoctor[];
      };
    }[];
    nextToken: string;
  };
}

export const ExportAssessments = ({ username }: PropTypes) => {
  const [nextToken, setNextToken] = useState<string | null>(null);

  const listOptions = useMemo(
    () => ({
      variables: {
        id: username,
        limit: 10,
      },
      fetchPolicy: "cache-and-network",
    }),
    [username]
  );

  // using lazyQuery as we will trigger it inside the child component when switching from list to date etc
  const [getAssessments, { loading, error, data, fetchMore, variables }] = useLazyQuery<Response>(GET_ASSESSMENTS);

  // Create a date range for the basic list to ensure that same upper and lower limits are specified
  // const MIN_DATE = dayjs("2000-01-01").toDate();
  // const MAX_DATE = dayjs()
  //   .add(1, "month")
  //   .toDate();
  // const defaultDateRange = {
  //   from: MIN_DATE,
  //   to: MAX_DATE,
  // };

  const defaultDateRange = getDefaultDateRange();

  const assessmentsByAmhp = {
    timestamp: Date.now(),
    items: data?.assessmentsByAmhp.items || [],
    nextToken: data?.assessmentsByAmhp.nextToken,
  };

  // running query on mount
  useEffect(() => {
    getAssessments(listOptions);
  }, []);

  // fn to retrieve initial visit results, optional passing in dateRange object to allow user to query by date
  // will need to run fn inside child component when switching from list to date view
  const getInitialAssessments = (dateRange?: { from: string; to: string }) => {
    let vars = variables;
    if (!dateRange) {
      const { from, to, ...rest } = variables;
      vars = rest;
    }

    // Here we set the limit: 2000 when we are searching by date, 10 when no date is provided.
    getAssessments({
      variables: {
        ...vars,
        limit: dateRange ? 2000 : 10,
        ...(dateRange || { from: defaultDateRange.from, to: defaultDateRange.to }),
        fetchPolicy: dateRange ? "network-only" : "cache-and-network",
      },
    });
  };

  const loadMore = () => {
    if (assessmentsByAmhp.items?.length > 0)
      fetchMore({
        query: GET_ASSESSMENTS,
        variables: {
          ...variables,
          nextToken: nextToken || assessmentsByAmhp?.nextToken,
        },
        updateQuery: (previousResult: Response, { fetchMoreResult }: { fetchMoreResult: Response }) => {
          setNextToken(fetchMoreResult.assessmentsByAmhp.nextToken);
          const mergedAssessements = merge({}, previousResult, {
            assessmentsByAmhp: {
              ...previousResult.assessmentsByAmhp,
              items: [...previousResult.assessmentsByAmhp.items, ...fetchMoreResult.assessmentsByAmhp.items],
              nextToken: fetchMoreResult.assessmentsByAmhp.nextToken,
            },
          });
          return mergedAssessements;
        },
      });
  };

  type ResponseArrayItem = Exclude<typeof assessmentsByAmhp, undefined>["items"][0];

  const exportProperties: PropertiesToExport<ResponseArrayItem>[] = [
    {
      values: {
        displayItem: "assessmentDate",
      },
      heading: "Date of Assessment",
      callback: (args: { displayItem: string }) => dayjs(args.displayItem).format("DD/MM/YYYY"),
    },
    {
      values: {
        displayItem: "locationName",
      },
      heading: "Location",
      callback: (args: { displayItem: LocationNameInput }) => formatAddress(args.displayItem),
    },
    {
      values: {
        displayItem: "patientName",
      },
      heading: "Patient Name",
    },
    {
      values: {
        displayItem: "doctorVisits",
      },
      heading: "Doctor 1",
      callback: (args: { displayItem: { items: DoctorVisit[] } }) =>
        args.displayItem?.items?.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())[0]
          ?.doctor.name || "-",
    },
    {
      values: {
        displayItem: "contactedDoctors",
        dependent: "doctorVisits",
      },
      heading: "First Contact Date/Time",
      callback: (args: { displayItem: { items: ContactedDoctor[] }; dependent: { items: DoctorVisit[] } }) =>
        args.displayItem?.items?.filter(item => item.s12Doctor.id === args.dependent?.items?.[0]?.doctor.id)[0]
          ?.createdAt
          ? dayjs(
              args.displayItem?.items?.filter(item => item.s12Doctor.id === args.dependent?.items?.[0]?.doctor.id)[0]
                ?.createdAt
            ).format("DD/MM/YYYY HH:mm")
          : "-",
    },
    {
      values: {
        displayItem: "contactedDoctors",
        dependent: "doctorVisits",
      },
      heading: "First Contact Date/Time",
      callback: (args: { displayItem: { items: ContactedDoctor[] }; dependent: { items: DoctorVisit[] } }) =>
        args.displayItem?.items?.filter(item => item.s12Doctor.id === args.dependent?.items?.[0]?.doctor.id)[0]
          ?.createdAt
          ? dayjs(
              args.displayItem?.items?.filter(item => item.s12Doctor.id === args.dependent?.items?.[0]?.doctor.id)[0]
                ?.createdAt
            ).format("DD/MM/YYYY HH:mm")
          : "-",
    },
    {
      values: {
        displayItem: "doctorVisits",
      },
      heading: "Visit Time",
      callback: (args: { displayItem: { items: DoctorVisit[] } }) =>
        args.displayItem?.items?.[0]?.time ? dayjs(args.displayItem?.items?.[0]?.time).format("DD/MM/YYYY HH:mm") : "-",
    },
    {
      values: {
        displayItem: "doctorVisits",
      },
      heading: "Claim raised",
      callback: (args: { displayItem: { items: DoctorVisit[] } }) =>
        args.displayItem?.items?.[0]?.doctorVisitClaimId ? "Yes" : "-",
    },
    {
      values: {
        displayItem: "doctorVisits",
      },
      heading: "Doctor 2",
      callback: (args: { displayItem: { items: DoctorVisit[] } }) => args.displayItem?.items?.[1]?.doctor.name || "-",
    },
    {
      values: {
        displayItem: "contactedDoctors",
        dependent: "doctorVisits",
      },
      heading: "First Contact Date/Time",
      callback: (args: { displayItem: { items: ContactedDoctor[] }; dependent: { items: DoctorVisit[] } }) =>
        args.displayItem?.items?.filter(item => item.s12Doctor.id === args.dependent?.items?.[1]?.doctor.id)[0]
          ?.createdAt
          ? dayjs(
              args.displayItem?.items?.filter(item => item.s12Doctor.id === args.dependent?.items?.[1]?.doctor.id)[0]
                ?.createdAt
            ).format("DD/MM/YYYY HH:mm")
          : "-",
    },
    {
      values: {
        displayItem: "doctorVisits",
      },
      heading: "Visit Time",
      callback: (args: { displayItem: { items: DoctorVisit[] } }) =>
        args.displayItem?.items?.[1]?.time ? dayjs(args.displayItem?.items?.[1]?.time).format("DD/MM/YYYY HH:mm") : "-",
    },
    {
      values: {
        displayItem: "contactedDoctors",
        dependent: "doctorVisits",
      },
      heading: "First Contact Date/Time",
      callback: (args: { displayItem: { items: ContactedDoctor[] }; dependent: { items: DoctorVisit[] } }) =>
        args.displayItem?.items?.filter(item => item.s12Doctor.id === args.dependent?.items?.[1]?.doctor.id)[0]
          ?.createdAt
          ? dayjs(
              args.displayItem?.items?.filter(item => item.s12Doctor.id === args.dependent?.items?.[1]?.doctor.id)[0]
                ?.createdAt
            ).format("DD/MM/YYYY HH:mm")
          : "-",
    },
    {
      values: {
        displayItem: "doctorVisits",
      },
      heading: "Claim raised",
      callback: (args: { displayItem: { items: DoctorVisit[] } }) =>
        args.displayItem?.items?.[1]?.doctorVisitClaimId ? "Yes" : "-",
    },
  ];

  const items = useMemo(
    () =>
      assessmentsByAmhp?.items.map(item => ({
        id: item.id,
        heading: item.patientName,
        time: item.assessmentDate,
      })) || [],
    [assessmentsByAmhp?.items]
  );

  return (
    <ExportItems
      type="assessments"
      displayItems={items}
      propertiesToExport={exportProperties}
      queryData={assessmentsByAmhp}
      getItems={getInitialAssessments}
      loadMore={loadMore}
      loading={loading}
      error={error}
    />
  );
};
