/* eslint react/prop-types: 0 */
import React, { useMemo, useRef, useState, useEffect, Fragment } from "react";
import { View, StyleSheet, TouchableOpacity, Platform, StyleProp, TextStyle } from "react-native";
import { RRule } from "rrule";

import { useSetRecoilState, useRecoilValue } from "recoil";
import { useMutation } from "@apollo/react-hooks";
import dayjs, { Dayjs } from "dayjs";
import { CalendarStrip } from "react-native-calendar-strip-dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { TypographyType } from "@/models/Typography";
import Text from "../Text";
import { SwipeAction } from "../SwipeActions";
import { color, convertColor, convertBorder, fontSizes, spacing, palette } from "../../theme";
import { CalendarProps, GroupedItem } from "./Calendar.props";

import Icon from "../Icon";
import { GET_DOCTOR, DELETE_DOCTOR_AVAILABILITY } from "@/models/DoctorProfile";
import { Availability, AvailabilityType, CalendarItem, CalendarEventItem } from "libs/types/availability";
import MiniLabel from "../MiniLabel";
import Paragraph from "../Paragraph";
import { ContentWrap } from "../ContentWrap";
import { Delete as DeleteButton, Edit as EditButton, Navigate as NavigateButton } from "../CircleButton/CircleButton";
import { buildErr, deleteHolidayError, deleteAvailabilityError } from "@/models/Error";
import { alert, mqWeb, formatPostcode } from "../../utils/helpers";
import { EventItem } from "libs/types/holiday";
import {
  formatShortDate,
  formatLongDate,
  mergeHolidaysToAvailabilities,
  convertStartEndToDisplayInterval,
  calendarItemsToAvailabilityDates,
} from "libs/dates";
import { DELETE_HOLIDAYS } from "@/models/Holiday";
import Swipeable from "react-native-gesture-handler/Swipeable";
import { converDateToDisplayTime } from "libs/dates/convert";
import { userDetails, snackbarMessage, lastException, availabilityCalendarDate } from "../../utils/recoil";
import ItemSpacer from "../ItemSpacer";

dayjs.extend(isBetween);

const flatMap = <T extends unknown>(f: (arg0: T) => any, arr: T[]) => (arr || []).reduce((x, y) => [...x, ...f(y)], []);

function groupBy(xs: any[], key: string) {
  return xs.reduce(function(rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}

function filterForDate<T extends { start: Dayjs }>(date: Dayjs): (arg0: any) => boolean {
  return function(availability: T) {
    return availability.start.isSame(date, "date");
  };
}

const sortDotsByAvailabilityType = (
  a: { date: string; type: AvailabilityType; oncall: boolean },
  b: { date: string; type: AvailabilityType; oncall: boolean }
) => {
  const calculateScore = (item: { date: string; type: AvailabilityType; oncall: boolean }) =>
    item.oncall ? 1 : item.type === AvailabilityType.trust ? 2 : item.type === AvailabilityType.independent ? 3 : 4;

  return calculateScore(a) - calculateScore(b);
};

// group events by date, create dot objects for each date,
// remove duplicate dots (only one per calendar type)
const generateCalendarDots = (items: CalendarEventItem[], isDoctor: boolean) => {
  // convert string timespans to dates, handle cases of spans over 2 days (overnight)
  const datesAndTypes = flatMap((a: CalendarEventItem) => {
    if (a.start.date() !== a.end.date()) {
      return [
        {
          date: formatLongDate(a.start),
          type: a.type,
          start: a.start,
          end: a.end,
          oncall: a.oncall,
        },
        {
          date: formatLongDate(a.end),
          type: a.type,
          start: a.start,
          end: a.end,
          oncall: a.oncall,
        },
      ];
    } else {
      return [
        {
          date: formatLongDate(a.start),
          type: a.type,
          start: a.start,
          end: a.end,
          oncall: a.oncall,
        },
      ];
    }
  }, items);

  const grouped = groupBy(datesAndTypes, "date");

  // search duplicate colors on the dots to remove them
  return Object.keys(grouped).map(k => {
    let items;
    const holidaysToDate: GroupedItem[] = grouped[k].filter((g: GroupedItem) =>
      [AvailabilityType.away, AvailabilityType.hidden].includes(g.type)
    );
    if (holidaysToDate.length) {
      const availabilities = grouped[k]
        .filter((g: GroupedItem) => ![AvailabilityType.away, AvailabilityType.hidden].includes(g.type))
        .filter((av: GroupedItem) => {
          const s = holidaysToDate.map(
            holidayToDate =>
              dayjs(av.start).isBetween(holidayToDate.start, holidayToDate.end) &&
              dayjs(av.end).isBetween(holidayToDate.start, holidayToDate.end)
          );
          return s.includes(false);
        });

      const holidays = !isDoctor ? holidaysToDate.filter(h => h.type !== AvailabilityType.hidden) : holidaysToDate;

      items = [...holidays, ...availabilities];
    } else {
      items = grouped[k];
    }

    const dots = items
      .sort(sortDotsByAvailabilityType)
      .map((item: { date: string; type: AvailabilityType; oncall: boolean }) => ({
        color: convertColor(item.oncall ? AvailabilityType.oncall : item.type ? item.type : AvailabilityType.trust),
        top: [AvailabilityType.away, AvailabilityType.booking].includes(item.type),
      }));

    return {
      // structure needed by react-native-calendar-strip
      date: dayjs(k),
      dots: dots.filter(
        (v: { color: string }, i: number, s: { color: string }[]) => s.find(elem => elem.color === v.color) === v
      ),
    };
  });
};

function Interval(props: { event: CalendarEventItem }) {
  const { event } = props;

  let interval = event.type === AvailabilityType.booking ? converDateToDisplayTime(event.start) : "";
  if (event.start && event.end && event.type !== AvailabilityType.booking) {
    interval = convertStartEndToDisplayInterval(event.start, event.end);
  }

  return event.overnightElement && interval.includes("-") ? (
    <View style={{ flexDirection: "row", alignItems: "flex-end" }}>
      <Text format={TypographyType.HeadingSmall} type="paper">
        {interval.split(" -")[0]}
      </Text>
      <Text format={TypographyType.Micro} type="paper">
        {event.overnightElement === 2
          ? ` (${dayjs(event.start)
              .subtract(1, "day")
              .format("DD/MM")})`
          : ""}
      </Text>
      <Text format={TypographyType.HeadingSmall} type="paper">
        {` - `}
      </Text>
      <Text format={TypographyType.HeadingSmall} type="paper">
        {interval.split("- ")[1]}
      </Text>
      <Text format={TypographyType.Micro} type="paper">
        {event.overnightElement === 1
          ? ` (${dayjs(event.start)
              .add(1, "day")
              .format("DD/MM")})`
          : ""}
      </Text>
    </View>
  ) : (
    <Text format={TypographyType.HeadingSmall} type="paper">
      {interval}
    </Text>
  );
}

function EventDot(props: { availabilityType: AvailabilityType }) {
  return (
    <View
      style={[
        styles.dot,
        {
          backgroundColor: convertColor(props.availabilityType ? props.availabilityType : AvailabilityType.trust),
          borderColor: convertBorder(props.availabilityType ? props.availabilityType : AvailabilityType.trust),
        },
      ]}
    />
  );
}

export function CalendarEvent(props: { event: CalendarEventItem }) {
  const boxLabel = props.event.oncall
    ? "On call"
    : props.event.type === "trust"
    ? "Trust"
    : props.event.type === AvailabilityType.away
    ? "Away"
    : props.event.type === AvailabilityType.booking
    ? "Booked Visit"
    : props.event.type === AvailabilityType.hidden
    ? "Unavailable"
    : "Independent";

  const eventType = props.event.oncall ? AvailabilityType.oncall : props.event.type || AvailabilityType.independent;

  // TODO: Secondary data needs data model adjustment to get notes
  return (
    <ContentWrap>
      <View style={styles.calendarEvent}>
        <EventDot availabilityType={eventType} />
        <Interval event={props.event} />
        <View style={styles.row}>
          <Text format={TypographyType.Small} type="paper" style={styles.label}>
            {boxLabel === "On call" && (props.event.rotaName ?? "") !== "" ? props.event.rotaName : boxLabel}
          </Text>
          {["Trust", "On call"].includes(boxLabel) && props.event.mht && (
            <Text format={TypographyType.Tiny} type="paper" style={[styles.label, styles.mhtLabel]}>
              {`(${props.event.mht.abbreviation})`}
            </Text>
          )}
          {props.event.partialPostcode && (
            <Text format={TypographyType.Tiny} type="paper" style={[styles.label, styles.mhtLabel]}>
              {`(${props.event.partialPostcode})`}
            </Text>
          )}
          {!!props.event.locationName && props.event.type !== AvailabilityType.away && (
            <Text format={TypographyType.Tiny} type="paper" style={[styles.label, styles.mhtLabel]}>
              {`(${formatPostcode(props.event.locationName.postcode)})`}
            </Text>
          )}
        </View>
        {props.event.notes && props.event.notes !== "-" && (
          <Text format={TypographyType.Tiny} type="paper" style={styles.notes}>
            {props.event.notes}
          </Text>
        )}
      </View>
    </ContentWrap>
  );
}

export const MemoEditableCalendarEvent = React.memo(function EditableCalendarEvent(props: {
  event: CalendarEventItem;
  holidayTimeSpan?: EventItem[];
  index: number;
  activeEvent: number | null;
  setActiveEvent: React.Dispatch<React.SetStateAction<number | null>>;
  editAvailability?: (v: Availability | null) => void;
  editHoliday?: (v: EventItem | null) => void;
  goToVisit?: (visitId: string) => void;
  swipeable: any;
}) {
  const { event, index, holidayTimeSpan, swipeable, activeEvent, setActiveEvent } = props;
  const [deleteHoliday] = useMutation(DELETE_HOLIDAYS);
  const [deleteAvailability] = useMutation(DELETE_DOCTOR_AVAILABILITY);
  const user = useRecoilValue(userDetails);
  const setMessage = useSetRecoilState(snackbarMessage);
  const setLastException = useSetRecoilState(lastException);

  const isWebView = mqWeb();

  function closeSwipes() {
    if (Platform.OS === "web") return;
    swipeable.current.forEach((el: Swipeable) => el && el.close());
  }

  function removeHoliday(holidayEvent: EventItem | null) {
    if (!holidayEvent) return;

    const confirmationMessage = `Are you sure you want to delete your Time Away from ${formatShortDate(
      (holidayEvent as EventItem).start
    )} until ${formatShortDate((holidayEvent as EventItem).end)}?`;
    const deleteEvent = () => {
      deleteHoliday({
        variables: {
          input: {
            id: event.id,
          },
        },
        optimisticResponse: {
          deleteHoliday: {
            __typename: "Holiday",
            id: event.id,
          },
        },
        update: (store: any, { data }: any) => {
          if (!data || !data.deleteHoliday) return;
          const doctorData = store.readQuery({
            query: GET_DOCTOR,
            variables: { id: user ? user.id : "" },
          });

          store.writeQuery({
            query: GET_DOCTOR,
            variables: { id: user ? user.id : "" },
            data: {
              getS12Doctor: {
                ...doctorData.getS12Doctor,
                holidays: {
                  items: doctorData.getS12Doctor.holidays.items.filter((holiday: EventItem) => holiday.id !== event.id),
                  __typename: "ModelHolidayConnection",
                },
              },
            },
          });
        },
      })
        .then(() => setMessage("Time Away has been deleted successfully."))
        .catch(
          buildErr(
            {
              ...deleteHolidayError,
              additional: event.id,
            },
            setLastException
          )
        );
    };
    alert("Delete Time Away", confirmationMessage, () => setActiveEvent(null), deleteEvent);
  }

  function removeAvailability(event: Availability) {
    const confirmationMessage = /FREQ/.test(event.rrule)
      ? "Are you sure you want to delete this series of events?"
      : "Are you sure you want to delete this entry?";
    const deleteEvent = () => {
      deleteAvailability({
        variables: {
          input: {
            id: event.id,
          },
        },
        optimisticResponse: {
          deleteAvailability: {
            __typename: "Availability",
            id: event.id,
          },
        },
        update: (store: any, { data }: any) => {
          if (!data || !data.deleteAvailability) return;
          const doctorData = store.readQuery({
            query: GET_DOCTOR,
            variables: { id: user ? user.id : "" },
          });

          store.writeQuery({
            query: GET_DOCTOR,
            variables: { id: user ? user.id : "" },
            data: {
              getS12Doctor: {
                ...doctorData.getS12Doctor,
                availabilities: {
                  items: doctorData.getS12Doctor.availabilities.items.filter(
                    (availability: Availability) => availability.id !== event.id
                  ),
                  __typename: "ModelAvailabilityConnection",
                },
              },
            },
          });
        },
      })
        .then(() => setMessage("Availability has been deleted successfully."))

        .catch(
          buildErr(
            {
              ...deleteAvailabilityError,
              additional: event.id,
            },
            setLastException
          )
        );
    };
    alert("Delete Availability", confirmationMessage, () => setActiveEvent(null), deleteEvent);
  }

  const renderButtons = (holidayEvent: EventItem | null) => {
    return holidayEvent ? (
      <>
        <DeleteButton onPress={() => removeHoliday(holidayEvent)} style={styles.deleteButton} />
        {props.editHoliday && (
          <EditButton onPress={() => (props.editHoliday ? props.editHoliday(holidayEvent) : null)} />
        )}
      </>
    ) : (
      <>
        <DeleteButton onPress={() => removeAvailability(event)} style={styles.deleteButton} />
        {props.editAvailability && (
          <EditButton onPress={() => (props.editAvailability ? props.editAvailability(event) : null)} />
        )}
      </>
    );
  };

  const renderVisitButton = (id: string) => (
    <NavigateButton onPress={() => (props.goToVisit ? props.goToVisit(id) : null)} />
  );

  const LeftActions = (progress: any, dragX: any) => {
    function editHoliday() {
      const holidays = holidayTimeSpan && holidayTimeSpan.find(hl => hl.id === event.id);

      if (holidays && props.editHoliday) {
        props.editHoliday(holidays);
        closeSwipes();
      }
    }
    function editAvailability() {
      if (props.editAvailability) {
        props.editAvailability(event);
        closeSwipes();
      }
    }

    return (
      <SwipeAction
        direction="left"
        dragX={dragX}
        fullWidth={true}
        swipeActions={[
          {
            icon: <Icon name="edit" size={36} color={color.textWhite} />,
            color: color.primary,
            onPress: event.type === AvailabilityType.away ? editHoliday : editAvailability,
          },
        ]}
      />
    );
  };

  const GoToRightActions = (progress: any, dragX: any) => {
    return (
      <SwipeAction
        direction="right"
        dragX={dragX}
        fullWidth={true}
        swipeActions={[
          {
            icon: <Icon name="keyboard-arrow-right" size={36} color={color.textWhite} />,
            color: color.primary,
            onPress: () => (props.goToVisit ? props.goToVisit(event.id) : null),
          },
        ]}
      />
    );
  };

  const RightActions = (progress: any, dragX: any) => {
    function deleteHoliday() {
      const holidays = holidayTimeSpan && holidayTimeSpan.find(hl => hl.id === event.id);
      if (holidays) {
        removeHoliday(holidays);
        closeSwipes();
      }
    }
    function deleteAvailability() {
      removeAvailability(event);
      closeSwipes();
    }

    return (
      <SwipeAction
        direction="right"
        dragX={dragX}
        fullWidth={true}
        swipeActions={[
          {
            icon: <Icon name="delete" size={36} color={color.textWhite} />,
            color: color.textError,
            onPress: event.type === AvailabilityType.away ? deleteHoliday : deleteAvailability,
          },
        ]}
      />
    );
  };

  const Wrapper: React.ComponentType<any> = Platform.OS === "web" ? View : Swipeable;

  const wrapperProps =
    Platform.OS === "web"
      ? {}
      : {
          ref: (el: Swipeable) => (swipeable.current[index] = el),
          renderLeftActions: props.editAvailability && event.type !== AvailabilityType.booking && LeftActions,
          overshootLeft: false,
          renderRightActions: event.type === AvailabilityType.booking ? GoToRightActions : RightActions,
          overshootRight: false,
          onSwipeableWillOpen: () => {
            const toClose = swipeable.current.find((el: Swipeable) => el !== swipeable.current[index]);
            if (toClose) toClose.close();
          },
        };

  const InnerWrapper: React.ComponentType<any> = Platform.OS === "web" ? View : TouchableOpacity;

  const innerWrapperProps = useMemo(
    () =>
      Platform.OS === "web"
        ? {}
        : {
            activeOpacity: 1,
            onPress: () => setActiveEvent(curr => (curr === index ? null : index)),
          },
    [index]
  );

  const OuterContainer: React.ComponentType<any> = Platform.OS === "web" ? Fragment : Fragment;

  const InnerContainer: React.ComponentType<any> = Platform.OS === "web" ? Fragment : ContentWrap;

  const boxLabel = event.oncall
    ? "On call"
    : event.type === "trust"
    ? "Trust"
    : event.type === AvailabilityType.away
    ? "Away"
    : props.event.type === AvailabilityType.booking
    ? "Booked Visit"
    : event.type === AvailabilityType.hidden
    ? "Unavailable"
    : "Independent";

  let occurance = null;
  if (
    event.rrule.includes("FREQ=") &&
    ![AvailabilityType.away, AvailabilityType.hidden].includes(event.type || AvailabilityType.independent)
  ) {
    const rule = RRule.fromString(event.rrule.split(/;(.+)/)[1]);
    occurance = rule.options.freq !== 0 ? rule.toText() : null;
  }

  let holidayEvent: EventItem | null = null;
  if (
    [AvailabilityType.away, AvailabilityType.hidden].includes(event.type || AvailabilityType.independent) &&
    holidayTimeSpan
  ) {
    holidayEvent = holidayTimeSpan.find(hl => hl.id === event.id) || null;
  }

  const eventType = event.oncall ? AvailabilityType.oncall : event.type || AvailabilityType.independent;

  // TODO: Secondary data needs data model adjustment to get notes
  return (
    <Wrapper {...wrapperProps}>
      <OuterContainer>
        <View style={styles.containerRow}>
          <InnerContainer>
            <View style={[styles.row, styles.flex]}>
              <View
                style={[
                  styles.calendarEvent,
                  isWebView && styles.calendarEventWeb,
                  styles.editableCalendarEvent,
                  styles.container,
                ]}
              >
                <EventDot availabilityType={eventType} />
                <InnerWrapper {...innerWrapperProps} style={styles.calendarEventCopy}>
                  <Interval event={event} />
                  <View style={styles.row}>
                    <Text format={TypographyType.Small} type="paper" style={styles.label}>
                      {boxLabel === "On call" && (props.event.rotaName ?? "") !== "" ? props.event.rotaName : boxLabel}
                    </Text>
                    {event.partialPostcode && (
                      <Text format={TypographyType.Tiny} type="paper" style={[styles.label, styles.mhtLabel]}>
                        {`(${event.partialPostcode})`}
                      </Text>
                    )}
                    {boxLabel === "Trust" && event.mht && (
                      <Text format={TypographyType.Tiny} type="paper" style={[styles.label, styles.mhtLabel]}>
                        {`(${event.mht.abbreviation})`}
                      </Text>
                    )}
                    {!!event.locationName && (
                      <Text format={TypographyType.Tiny} type="paper" style={[styles.label, styles.mhtLabel]}>
                        {`(${event.locationName.postcode})`}
                      </Text>
                    )}
                  </View>
                  {(activeEvent === index || Platform.OS === "web") && (
                    <>
                      {occurance && event.type !== AvailabilityType.booking && (
                        <Text format={TypographyType.Tiny} type="paper" style={styles.notes}>
                          This event occurs {occurance}
                        </Text>
                      )}

                      {holidayEvent && (
                        <Text format={TypographyType.Tiny} type="paper" style={styles.notes}>
                          You are away from {formatShortDate(holidayEvent.start)} until{" "}
                          {formatShortDate(holidayEvent.end)}
                        </Text>
                      )}

                      {event.notes && event.notes !== "-" && (
                        <>
                          <Text format={TypographyType.RegularBold} style={styles.notesHeader}>
                            Notes:
                          </Text>
                          <Text format={TypographyType.Tiny} type="paper" style={styles.notes}>
                            {event.notes}
                          </Text>
                        </>
                      )}
                      {event.type === AvailabilityType.booking && (
                        <Text format={TypographyType.Tiny} type="paper" style={styles.notes}>
                          You have been booked for an assessment in {event.partialPostcode}. Press arrow button to check
                          details.
                        </Text>
                      )}
                      {!isWebView && (
                        <View style={styles.buttonWrapper}>
                          {props.event.type === AvailabilityType.booking
                            ? renderVisitButton(props.event.id)
                            : renderButtons(holidayEvent)}
                        </View>
                      )}
                    </>
                  )}
                </InnerWrapper>
                {isWebView &&
                  (props.event.type === AvailabilityType.booking
                    ? renderVisitButton(props.event.id)
                    : renderButtons(holidayEvent))}
              </View>
            </View>
          </InnerContainer>
        </View>
      </OuterContainer>
    </Wrapper>
  );
});

// expect a calendar week of information to process
export function Calendar(props: CalendarProps) {
  const isWebView = mqWeb();
  const calendar = useRef<any>();
  const swipeable: { current: Swipeable[] } = useRef([]);
  const [date, setDate] = useState(dayjs(props.startDate));
  const [activeEvent, setActiveEvent] = useState<number | null>(null);
  const setAvailabilityCalendarDate = useSetRecoilState(availabilityCalendarDate);

  const leftIcon = useMemo(
    () => [<Icon key={1} size={36} name="chevron-left" color={color.primary} style={stripStyles.arrowLeft} />],
    []
  );

  const rightIcon = useMemo(
    () => [<Icon key={1} size={36} name="chevron-right" color={color.primary} style={stripStyles.arrowLeft} />],
    []
  );

  useEffect(() => {
    setAvailabilityCalendarDate(dayjs());
  }, []);

  const weekStart = date
    .subtract(1, "day") // in case it's Sunday next week
    .startOf("week")
    .add(1, "day"); // to make the week start from Monday

  const scheduleItems: CalendarItem[] = props.scheduleItems
    ? props.scheduleItems.map(si => ({
        ...si,
        rrule: si.rrule.includes(";")
          ? si.rrule
          : `${si.rrule};RRULE:UNTIL=${dayjs(si.rrule.split("DTSTART:")[1].split("T")[0])
              .add(1, "day")
              .format("YYYYMMDD")}T235900Z`,
      }))
    : [];

  const calendarItems = calendarItemsToAvailabilityDates(
    scheduleItems,
    props.holidays ? props.holidays : ([] as CalendarItem[]),
    props.visits ? props.visits : ([] as CalendarItem[]),
    weekStart
  ).map(x => {
    const res: CalendarEventItem = {
      ...x.item,
      start: x.start,
      end: x.end,
    };
    return res;
  });

  const closeSwipes = () => {
    if (Platform.OS === "web") return;
    swipeable.current.forEach((el: Swipeable) => el && el.close());
  };

  const renderCalendarStrip = () => (
    <CalendarStrip
      ref={calendar}
      startingDate={weekStart}
      selectedDate={date}
      minDate={date.subtract(1, "year")}
      maxDate={date.add(1, "year")}
      markedDates={generateCalendarDots(calendarItems, !!props.editable)}
      customDatesStyles={[1, 2, 3, 4, 5, 6, 7].map(jdd => {
        const startDate = date
          .subtract(1, "day") // in case it's Sunday next week
          .startOf("week")
          .add(jdd, "day");
        const isToday = dayjs().date() === startDate.date();

        return {
          startDate,
          dateContainerStyle: {
            height: 90,
            justifyContent: "flex-start",
          },
          ...(isToday && {
            dateNameStyle: { color: color.primary },
            dateNumberStyle: { color: color.primary },
          }),
        };
      })}
      onDateSelected={(d: Dayjs) => {
        closeSwipes();
        setActiveEvent(null);

        setDate(d.add(d.utcOffset(), "minute"));
        setAvailabilityCalendarDate(d.add(d.utcOffset(), "minute"));
      }}
      onWeekChanged={(d: Dayjs) => {
        calendar && calendar.current && calendar.current.setSelectedDate(d);
      }}
      calendarColor={color.textWhite}
      dateNumberStyle={stripStyles.dateNumber}
      dateNameStyle={stripStyles.dateName}
      markedDatesStyle={markedDateStyle}
      highlightDateNameStyle={stripStyles.dateName}
      highlightDateNumberStyle={stripStyles.highlightDateNumber}
      calendarAnimation={{ type: "sequence", duration: 30 }}
      daySelectionAnimation={{
        type: "border",
        duration: 60,
      }}
      maxDayComponentSize={50}
      style={[styles.strip, isWebView ? styles.stripWeb : {}]}
      calendarHeaderStyle={isWebView ? stripStyles.headerWeb : stripStyles.header}
      shouldAllowFontScaling={true}
      styleWeekend={true}
      customDatesStyle={[]}
      leftSelector={leftIcon}
      rightSelector={rightIcon}
      iconStyle={{ margin: spacing[15] }}
    />
  );

  // const definedOvernightCalenderItems = createOvernightAvailabilities(calendarItems || [])

  const filteredCalendarItems = (calendarItems || []).filter(filterForDate(date));

  const visitItems = filteredCalendarItems.filter(i => i.type === AvailabilityType.booking);
  const holidayAvailabilityItems = filteredCalendarItems.filter(i => i.type !== AvailabilityType.booking);

  const items = mergeHolidaysToAvailabilities(holidayAvailabilityItems);
  const itemsToRender = !props.editable
    ? [...items.filter(i => i.type !== AvailabilityType.hidden), ...visitItems]
        .sort((a, b) => a.start.valueOf() - b.start.valueOf())
        .sort((a, b) => {
          const k1 = a.overnightElement === 2 ? 1 : 0;
          const k2 = b.overnightElement === 2 ? 1 : 0;
          return k2 - k1;
        })
    : [...items, ...visitItems]
        .sort((a, b) => a.start.valueOf() - b.start.valueOf())
        .sort((a, b) => {
          const k1 = a.overnightElement === 2 ? 1 : 0;
          const k2 = b.overnightElement === 2 ? 1 : 0;
          return k2 - k1;
        });

  return (
    <View style={styles.container}>
      <>{renderCalendarStrip()}</>
      <ContentWrap onlyWrap="mobile" style={styles.eventContainer}>
        <View style={[styles.labelBox, isWebView && styles.labelBoxWeb]}>
          <MiniLabel style={styles.availabilityLabel}>{"Availability \u00b7 " + formatLongDate(date)}</MiniLabel>
          {Boolean(itemsToRender.length) && props.displaySwipeInstructions ? (
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <Text format={TypographyType.Tiny} type="paper">
                {`Swipe items `}
              </Text>
              <Text format={TypographyType.TinyBold} type="paper">
                {`right to edit `}
              </Text>
              <Icon name="edit" size={16} color={color.text} />
              <Text format={TypographyType.Tiny} type="paper">
                {`or `}
              </Text>
              <Text format={TypographyType.TinyBold} type="paper">
                {`left to delete `}
              </Text>
              <Icon name="delete" size={16} color={color.text} />
            </View>
          ) : null}
        </View>
      </ContentWrap>
      <ItemSpacer gap={isWebView ? 25 : 10}>
        {itemsToRender
          .map((item, i) => {
            return props.editable ? (
              <MemoEditableCalendarEvent
                key={i}
                event={item}
                holidayTimeSpan={props.holidayTimeSpan}
                index={i}
                activeEvent={activeEvent}
                setActiveEvent={setActiveEvent}
                editAvailability={props.editAvailability}
                editHoliday={props.editHoliday}
                goToVisit={props.goToVisit}
                swipeable={swipeable}
              />
            ) : (
              <CalendarEvent key={i} event={item} />
            );
          })
          // if empty returns a message, otherwise throw away message
          .reduce((prev, curr, i) => (i === 0 ? [curr] : prev.concat(curr)), [
            <ContentWrap onlyWrap="mobile" key={-1}>
              <Paragraph>No availability</Paragraph>
            </ContentWrap>,
          ])}
      </ItemSpacer>
    </View>
  );
}

// library doesn't work with RNW styling
const markedDateStyle = {
  marginHorizontal: 2,
  width: 7,
  height: 7,
};

const dateCircleSize = 36;
const circleStyles: StyleProp<TextStyle> = {
  justifyContent: "center",
  ...(Platform.OS === "web" && { whiteSpace: "nowrap" }),
  padding: 9,
  color: color.text,
  fontSize: dateCircleSize / 3,
  borderWidth: 2,
  borderColor: color.transparent,
  borderRadius: dateCircleSize / 2,
  width: dateCircleSize,
  height: dateCircleSize,
  marginTop: spacing[10],
  marginBottom: spacing[10],
  overflow: "hidden",
};
const arrowStyles: StyleProp<TextStyle> = {
  marginTop: spacing[10],
  fontSize: dateCircleSize,
  height: dateCircleSize,
  width: dateCircleSize,
};

const stripStyles = StyleSheet.create({
  dateNumber: circleStyles,
  dateName: {
    color: color.text,
    fontSize: fontSizes.micro,
    lineHeight: 14,
    fontWeight: "bold",
  },
  highlightDateNumber: {
    ...circleStyles,
    color: color.textWhite,
    backgroundColor: color.primary,
    borderColor: color.primaryBorder,
  },
  arrowRight: arrowStyles,
  arrowLeft: arrowStyles,
  header: { color: color.textLight, fontSize: fontSizes.tin },
  headerWeb: { color: color.textLight, fontSize: fontSizes.tin, paddingBottom: spacing[10] },
});

const styles = StyleSheet.create({
  labelBox: {
    marginTop: 0,
    paddingTop: spacing[25],
  },
  labelBoxWeb: {
    marginTop: spacing[5],
    marginBottom: spacing[10],
    paddingTop: spacing[25],
    borderTopWidth: 2,
    borderTopColor: palette.cloud,
  },

  availabilityLabel: {
    fontWeight: "500",
  },

  availabilityLabelBold: {
    fontWeight: "900",
  },

  container: { flex: 1, flexBasis: "100%" },
  eventContainer: {
    paddingBottom: spacing[10],
  },
  strip: {
    backgroundColor: color.backgroundGrey,
    height: 150,
    paddingTop: spacing[10],
    paddingHorizontal: spacing[10],
    justifyContent: "flex-start",
  },
  stripWeb: {
    height: 145,
    backgroundColor: color.background,
    marginBottom: 0,
    // paddingTop: spacing[20],
    paddingBottom: spacing[15],
    paddingHorizontal: 0,
  },
  calendarEvent: {
    borderBottomColor: color.backgroundGrey,
    borderBottomWidth: 1,
    paddingLeft: 65,
    paddingBottom: spacing[15],
    overflow: "hidden",
  },
  calendarEventWeb: {
    paddingBottom: spacing[25],
  },
  calendarEventCopy: {
    flex: 1,
  },
  editableCalendarEvent: {
    overflow: "visible",
    flexDirection: "row",
    ...Platform.select({
      web: {
        paddingLeft: 65,
      },
      default: {
        paddingTop: 15,
        paddingLeft: 55,
      },
    }),
  },
  dot: {
    width: 26,
    height: 26,
    borderRadius: 18,
    borderWidth: 5,
    position: "absolute",
    ...Platform.select({
      web: {
        left: spacing[10],
      },
      default: {
        top: spacing[15],
      },
    }),
  },
  label: {
    color: color.textExtraLight,
  },
  notes: {
    paddingTop: spacing[10],
    color: color.textLight,
  },
  notesHeader: {
    paddingTop: spacing[15],
  },
  mhtLabel: {
    paddingLeft: spacing[5],
  },
  row: { flexDirection: "row", alignItems: "center" },
  containerRow: {
    flexDirection: "row",
    backgroundColor: color.background,
  },
  flex: { flex: 1 },
  editButton: { marginRight: spacing[10] },
  deleteButton: { marginRight: spacing[10] },
  buttonWrapper: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: spacing[20],
  },
});
