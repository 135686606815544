import React, { useState, useEffect } from "react";
import { Linking, Platform, View } from "react-native";

import Loading from "@/components/Loading";
import API from "@/api";
import { Logo } from "@/components/Logo";
import { Text } from "@/components";
import { TypographyType } from "@/models/Typography";
import { Button } from "@/components/Button";

interface AppV3LiveCheckValue {
  live: string;
  title: string;
  text: string;
  androidLink?: string;
  iosLink?: string;
}
const useAppV3LiveCheck = (): { loading: boolean; value: AppV3LiveCheckValue | null } => {
  const [loading, setLoading] = useState<boolean>(true);
  const [value, setValue] = useState<AppV3LiveCheckValue | null>(null);

  useEffect(() => {
    const checkVersion = async () => {
      try {
        const result = await API.get("/checkAppVersion");

        setValue(result.data);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    checkVersion();
  }, []);

  return { loading, value };
};

const V3LiveWrapper = ({ children }: { children: Element }): any => {
  const { loading, value } = useAppV3LiveCheck();

  if (loading) {
    return <Loading />;
  }

  // JSON parse because `value.live` is either going to be "1" or "0"
  if (value?.live && JSON.parse(value.live)) {
    const shouldDisplayLink = value?.iosLink && value?.androidLink;
    const link = (Platform.OS === "ios" ? value.iosLink : value.androidLink) as string;

    return (
      <View
        style={{
          flex: 1,
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: 16,
        }}
      >
        <View style={{ marginBottom: 32 }}>
          <Logo />
        </View>
        <Text format={TypographyType.HeadingMedium}>{value.title}</Text>
        <Text format={TypographyType.Regular}>{value.text}</Text>
        {shouldDisplayLink && (
          <View style={{ marginTop: 32 }}>
            <Button onPress={() => Linking.openURL(link)}>Download</Button>
          </View>
        )}
      </View>
    );
  }

  return children;
};

export default V3LiveWrapper;
