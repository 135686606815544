import React from "react";
import { Linking, StyleSheet, Platform } from "react-native";
import { TypographyType } from "@/models/Typography";
import { ContentWrap } from "../ContentWrap";
import { Description } from "../Description";
import Text from "../Text";
import { ContactBox } from "../InfoBox";
import { Phone as SquarePhoneButton, Email as SquareEmailButton } from "../SquareButton";
import { recordEvent } from "../../utils/analytics";
import { formatPhoneNumber } from "../../utils/helpers";
import { spacing } from "../../theme";
import { AnalyticsEvent } from "libs/analytics/events";

export default (props: {
  type: string;
  name?: string;
  team?: string;
  phone?: string;
  email?: string;
  emailTitle?: string;
}) => {
  // Check DynamoDB params being empty string opposed to genuine null, as a boolean not string literal [phone2]
  const bAllowPhone = !!(props.phone && props.phone.trim().length > 0);
  const bAllowEmail = !!(props.email && props.email.trim().length > 0);

  return (
    <ContentWrap>
      <Text format={TypographyType.HeadingSmall} style={styles.typeHeading}>
        {props.type}
      </Text>
      {props.team && (
        <Description style={styles.flexBasisAuto} label="AMHP Team">
          {props.team}
        </Description>
      )}
      {props.name && (
        <Description style={[styles.flexBasisAuto, styles.lastDescItem]} label="Full Name">
          {props.name}
        </Description>
      )}
      {bAllowPhone && (
        <ContactBox
          label="Phone"
          data={formatPhoneNumber(props?.phone || "-")}
          onPress={() =>
            Linking.openURL(`${Platform.OS === "ios" ? "telprompt:" : "tel:"}${props.phone}`)
              .then(() =>
                // TODO: this is not a support contact, this is for the amhp
                recordEvent(AnalyticsEvent.CONTACTED_SUPPORT, {
                  method: "phone",
                })
              )
              .catch(() => null)
          }
          icon={
            <SquarePhoneButton
            // do we care if the telephone fails to open?
            />
          }
        />
      )}
      {bAllowEmail && (
        <ContactBox
          label={props.emailTitle || "Email Address"}
          data={props?.email || "-"}
          lastInList={true}
          icon={<SquareEmailButton />}
          onPress={() =>
            // do we care if the mail app failed to open?
            Linking.openURL(`mailto:${props.email}`)
              .then(() =>
                recordEvent(AnalyticsEvent.CONTACTED_SUPPORT, {
                  method: "email",
                })
              )
              .catch(() => null)
          }
        />
      )}
    </ContentWrap>
  );
};

const styles = StyleSheet.create({
  flexBasisAuto: {
    flexBasis: "auto",
  },
  lastDescItem: {
    marginBottom: 0,
  },
  typeHeading: {
    marginBottom: spacing[30],
  },
});
