import { useApolloClient, useQuery } from "@apollo/react-hooks";
import { ApolloClient } from "apollo-boost";
import merge from "lodash.merge";
import React, { useMemo } from "react";

import { GET_DOCTOR_VISITS } from "@/models/DoctorProfile";
import { networkError, S12Error } from "@/models/Error";
import { checkOfflineCache } from "../../utils/helpers";
import { ErrorMessage } from "../Error/Error";
import Loading from "../Loading";
import { LoadMoreButton } from "../RectangleButton/RectangleButton";
import { VisitResultItem } from "./VisitResultItem.props";
import VisitResults from "./VisitResults";

interface Response {
  getS12Doctor: {
    id: string;
    visits: {
      items: VisitResultItem[];
      nextToken: string | null;
    };
  };
}

interface PropTypes {
  username: string;
  refreshRequired: boolean;
  onRefreshComplete: (value: React.SetStateAction<boolean>) => void;
}

function VisitResultsGQL(props: PropTypes) {
  const options = useMemo(
    () => ({
      variables: {
        id: props.username,
      },
      fetchPolicy: "cache-and-network" as const,
    }),
    [props.username]
  );

  const { loading, error, data, fetchMore, refetch } = useQuery<Response>(GET_DOCTOR_VISITS, options);

  const getS12Doctor = data?.getS12Doctor;
  const [loadMore, setLoadMore] = React.useState(false);
  const [nextToken, setNextToken] = React.useState<string | null>(null);

  const LoadMoreComponent = useMemo(() => LoadMoreButton(() => setLoadMore(true), loading), [nextToken, loading]);

  React.useEffect(() => {
    if (loadMore && getS12Doctor) {
      fetchMore({
        query: GET_DOCTOR_VISITS,
        variables: {
          id: props.username,
          nextToken: nextToken,
        },
        updateQuery: (previousResult: Response, { fetchMoreResult }: { fetchMoreResult: Response }) => {
          setNextToken(fetchMoreResult.getS12Doctor.visits.nextToken);
          const mergedVisits = merge({}, previousResult, {
            getS12Doctor: {
              ...previousResult.getS12Doctor,
              visits: {
                items: [...previousResult.getS12Doctor.visits.items, ...fetchMoreResult.getS12Doctor.visits.items],
                nextToken: fetchMoreResult.getS12Doctor.visits.nextToken,
              },
            },
          });
          setLoadMore(false);
          return mergedVisits;
        },
      });
    } else {
      setLoadMore(false);
    }
  }, [loadMore]);

  React.useEffect(() => {
    let hasUnloaded = false;
    if (getS12Doctor && props.refreshRequired) {
      // Prevents data from being fetched twice on initial load
      refetch()
        .then(() => {
          if (hasUnloaded) {
            return;
          }
          props.onRefreshComplete(false);
        })
        .catch(() => console.error(new S12Error(networkError)));
    }
    if (
      getS12Doctor &&
      getS12Doctor.visits &&
      getS12Doctor.visits.nextToken &&
      getS12Doctor.visits.nextToken !== nextToken
    ) {
      setNextToken(getS12Doctor.visits.nextToken);
    }
    return () => {
      hasUnloaded = true;
    };
  }, [getS12Doctor, props.refreshRequired]);

  const client: ApolloClient<any> = useApolloClient();
  const complete = checkOfflineCache(client, GET_DOCTOR_VISITS, {
    id: props.username,
  });
  if (!complete && error) {
    return <ErrorMessage apolloError={error} />;
  } else if (!complete && loading && !getS12Doctor) {
    return <Loading />;
  } else if (getS12Doctor) {
    const { visits } = getS12Doctor;

    return <VisitResults myVisits={visits.items} loadMore={nextToken && LoadMoreComponent} username={props.username} />;
  } else {
    return <Loading />;
  }
}

export default VisitResultsGQL;
