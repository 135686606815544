import React from "react";
import Text from "../../../Text";
import { TypographyType } from "@/models/Typography";
import { TimelineWrapper, TimelineElement } from "../../../Timeline";
import { palette } from "../../../../theme";

export function MHAFormRecordHistory(props: { items: string[]; historyHeader?: string; backgroundColor?: string }) {
  const { items, historyHeader, backgroundColor = palette.cloud } = props;

  return (
    <TimelineWrapper
      heading={historyHeader || "Update History"}
      truncated={items.length > 2}
      backgroundColor={backgroundColor}
    >
      {items.map((el, i) => (
        <TimelineElement
          key={i}
          lastElement={i === items.length - 1}
          filled={i === 0}
          small={true}
          color={palette.mhaPink}
        >
          <Text format={TypographyType.Tiny} color={palette.slate}>
            {el}
          </Text>
        </TimelineElement>
      ))}
    </TimelineWrapper>
  );
}
