import Auth from "@aws-amplify/auth";
import React, { useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { StyleSheet, View } from "react-native";

import { Button, ButtonList } from "@/components/Button";
import { Checkbox } from "@/components/Checkbox/Checkbox";
import { ContentWrap } from "@/components/ContentWrap";
import { ExternalLogin } from "@/components/Forms";
import MHAFormContainer from "@/components/Forms/MHAFormContainer";
import Text from "@/components/Text";
import ENV from "@/config";
import { ExternalAuthScreen } from "@/layouts/ExternalAuthScreen";
import { buildErr, acceptEulaError } from "@/models/Error";
import { TypographyType } from "@/models/Typography";
import { RouteKeys } from "@/navigationv2";
import { BaseScreenProps, ExternalDoctorFormRoutes } from "@/navigationv2/types";

import { spacing } from "@/theme";
import { userDetails, lastException } from "@/utils/recoil/index";

import { Eula } from "./shared/EulaScreen";

interface PropTypes extends BaseScreenProps<ExternalDoctorFormRoutes, RouteKeys.MHAExternalFormScreen> {}

export const MHAExternalFormScreen = (props: PropTypes) => {
  const { route } = props;

  const { formId, pw } = route.params;
  const formIdPart = formId.split("_")[0]; // eslint-disable-line
  const [user, setUserDetails] = useRecoilState(userDetails);
  const setLastException = useSetRecoilState(lastException);
  const [hasAcceptedEula, setHasAcceptedEula] = useState<boolean>(false);

  const onAgreeToEula = () => {
    Auth.currentAuthenticatedUser()
      .then(user =>
        Auth.updateUserAttributes(user, {
          "custom:eula_accepted": ENV.EULA_VERSION.toString(),
        }).then(() => Auth.currentAuthenticatedUser({ bypassCache: true }))
      )
      .then(() => {
        user && setUserDetails({ ...user, eulaVersion: 3 });
      })
      .catch(buildErr(acceptEulaError, setLastException));
  };

  if (!user) {
    return (
      <ExternalAuthScreen>
        <ExternalLogin userId={formId} pw={pw} />
      </ExternalAuthScreen>
    );
  } else if (!user.eulaVersion || user.eulaVersion < ENV.EULA_VERSION) {
    return (
      <ExternalAuthScreen>
        <ContentWrap style={styles.termsWrapper}>
          <Text format={TypographyType.HeadingSmall} style={styles.termsHeading}>
            Terms and Conditions
          </Text>
          <Eula />
          <View style={styles.checkbox}>
            <Checkbox
              status={hasAcceptedEula}
              label={"I accept these terms of use"}
              onPress={() => setHasAcceptedEula(!hasAcceptedEula)}
            />
          </View>
          <ButtonList disablePadding="top">
            <Button disabled={!hasAcceptedEula} onPress={onAgreeToEula}>
              Accept Terms and Conditions
            </Button>
          </ButtonList>
        </ContentWrap>
      </ExternalAuthScreen>
    );
  } else {
    return <MHAFormContainer formId={formIdPart} />;
  }
};

const styles = StyleSheet.create({
  checkbox: {
    alignSelf: "center",
    marginTop: spacing[30],
    marginBottom: spacing[15],
  },

  termsHeading: {
    marginBottom: spacing[20],
  },

  termsWrapper: {
    paddingTop: spacing[20],
    paddingBottom: spacing[10],
  },
});
