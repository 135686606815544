import React, { useState, useEffect, useContext, useMemo, useCallback } from "react";
import { StyleSheet, View, Keyboard } from "react-native";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { useRecoilValue, useSetRecoilState } from "recoil";
import dayjs from "dayjs";

import { HolidayList } from "./HolidayList";

import { ContentWrap } from "../ContentWrap";
import Text from "../Text";
import { Button } from "../Button";
import HolidayForm from "./HolidayForm";
import SectionDivider from "../SectionDivider/SectionDivider";
import Loading from "../Loading";
import { ErrorMessage } from "../Error/Error";

import { GET_DOCTOR, GetDoctorResponse } from "@/models/DoctorProfile";
import { CREATE_HOLIDAYS, UPDATE_HOLIDAYS, DELETE_HOLIDAYS } from "@/models/Holiday";
import { TypographyType } from "@/models/Typography";

import { color, palette, spacing } from "../../theme";
import { saveError, deleteError, buildErr } from "@/models/Error";
import { checkMutationInput, mqWeb } from "../../utils/helpers";
import { HolidayModelActionTypes, HolidayControllerActionTypes, StateContext } from "./state/initialState";
import { HolidayContext } from "./state/HolidayProvider";
import {
  formatDateTime,
  formatShortDate,
  formatLongDate,
  formatShortDateTime,
  convertHolidayToEditForm,
} from "libs/dates";
import { EventItem } from "libs/types/holiday";

import { recordEvent } from "../../utils/analytics";
import { AnalyticsEvent } from "libs/analytics/events";
import { UpdateHolidayInput, CreateHolidayInput } from "libs/types/API";
import {
  userDetails,
  snackbarMessage,
  lastException,
  availabilityCalendarDate as recoilAvailabilityCalendarDate,
} from "../../utils/recoil/index";
import { useBottomSheet, useDialog } from "@/hooks";

const Holidays = () => {
  const [saveHolidaysMtn] = useMutation(CREATE_HOLIDAYS);
  const [updateHolidaysMtn] = useMutation(UPDATE_HOLIDAYS);
  const [deleteHolidaysMtn] = useMutation(DELETE_HOLIDAYS);

  const [editBottomSheet, setEditBottomSheet] = useState(false);

  const [current, setCurrent] = useState<EventItem[]>([]);
  const [upcoming, setUpcoming] = useState<EventItem[]>([]);

  const [selectedHoliday, setSelectedHoliday] = useState<string | undefined>();
  const [submitted, setSubmitted] = useState(false);

  const { state, dispatch, controllerDispatch } = useContext<StateContext>(HolidayContext);
  const { holidayStartDate, holidayEndDate, holidayNotes, holidayVisibility } = state;

  const user = useRecoilValue(userDetails);
  const setMessage = useSetRecoilState(snackbarMessage);
  const setLastException = useSetRecoilState(lastException);
  const availabilityCalendarDate = useRecoilValue(recoilAvailabilityCalendarDate);

  const userID = user ? user.id : "";
  const { loading, error, data } = useQuery<GetDoctorResponse>(GET_DOCTOR, {
    variables: { id: userID },
  });

  function setResetHolidayForm() {
    dispatch({
      type: HolidayModelActionTypes.RESET_HOLIDAY_FORM,
      payload: dayjs(),
    });
    controllerDispatch({ type: HolidayControllerActionTypes.RESET_CONTROLLER });
  }

  function setHolidayDateError() {
    controllerDispatch({
      type: HolidayControllerActionTypes.TOGGLE_HOLIDAY_DATE_ERROR,
    });
  }

  useEffect(() => {
    if (data && data.getS12Doctor) {
      const { holidays } = data.getS12Doctor;
      const now = dayjs();
      setCurrent(
        (holidays?.items || [])
          .filter(notEmpty)
          .map(h => ({ ...h, start: dayjs(h.start), end: dayjs(h.end) }))
          .filter(hol => hol.start.diff(now) <= 0 && hol.end.diff(now) > 0)
      );
      setUpcoming(
        (holidays?.items || [])
          .filter(notEmpty)
          .map(h => ({ ...h, start: dayjs(h.start), end: dayjs(h.end) }))
          .filter(hol => hol.start.diff(now) > 0)
      );
    }
    dispatch({
      type: HolidayModelActionTypes.RESET_HOLIDAY_FORM,
      payload: availabilityCalendarDate || dayjs(),
    });
  }, [data]);

  const formatDate = (date: string, initialStart: number, initialEnd: number) => {
    const dateAsDate = new Date(date);
    return dateAsDate.getHours() !== initialStart || dateAsDate.getMinutes() !== initialEnd
      ? isWebView
        ? formatDateTime(date)
        : formatShortDateTime(date)
      : isWebView
      ? formatLongDate(date)
      : formatShortDate(date);
  };

  const addHolidays = () => {
    setSubmitted(true);
    if (holidayEndDate <= holidayStartDate) {
      closeDialog();
      openErrorDialog();
      setSubmitted(false);
    } else {
      saveHolidaysMtn({
        variables: {
          input: checkMutationInput<CreateHolidayInput>({
            end: holidayEndDate.toISOString(),
            start: holidayStartDate.toISOString(),
            notes: holidayNotes,
            visible: holidayVisibility,
            s12DoctorHolidaysId: userID,
          }),
        },
        refetchQueries: [
          {
            query: GET_DOCTOR,
            variables: { id: user ? user.id : "" },
          },
        ],
      })
        .then(() => {
          setMessage("Time Away successfully added");
          closeDialog();
          setResetHolidayForm();
          setSubmitted(false);

          recordEvent(AnalyticsEvent.HOLIDAY_CREATED, {
            startDate: holidayStartDate.toISOString(),
            endDate: holidayEndDate.toISOString(),
          });
        })
        .catch(e => {
          console.log(e);
          buildErr(saveError, setLastException);
        });
    }
  };

  // Note this is passed to a bottom sheet inside a use effect
  // All data referenced must be in the useEffect dependency array.
  const updateHolidays = (selectedHoliday?: string) => {
    setSubmitted(true);
    if (holidayEndDate <= holidayStartDate) {
      console.log("this is where we throw");
      setHolidayDateError();
      setSubmitted(false);
    } else if (!selectedHoliday) {
      setMessage("No holiday to update");
    } else {
      updateHolidaysMtn({
        variables: {
          input: checkMutationInput<UpdateHolidayInput>({
            id: selectedHoliday,
            end: holidayEndDate.toISOString(),
            start: holidayStartDate.toISOString(),
            notes: holidayNotes,
            visible: holidayVisibility,
            s12DoctorHolidaysId: userID,
          }),
        },
        refetchQueries: [
          {
            query: GET_DOCTOR,
            variables: { id: userID },
          },
        ],
      })
        .then(() => {
          setMessage("Time Away successfully updated");
          setResetHolidayForm();
          setEditBottomSheet(false);
          setSubmitted(false);
        })
        .catch(e => {
          console.log(e);
          buildErr(saveError, setLastException);
        });
    }
  };

  const deleteHolidays = useCallback(
    selectedHoliday => {
      setSubmitted(true);
      deleteHolidaysMtn({
        variables: {
          input: {
            id: selectedHoliday,
          },
        },
        refetchQueries: [
          {
            query: GET_DOCTOR,
            variables: { id: userID },
          },
        ],
      })
        .then(() => {
          setMessage("Time Away successfully deleted");
          closeDialog();
          setSubmitted(false);
        })
        .catch(e => {
          console.log(e);
          buildErr(deleteError, setLastException);
        });
    },
    [userID, selectedHoliday]
  );

  const editHoliday = (hol: EventItem) => {
    setSelectedHoliday(hol.id);
    const { model, controller } = convertHolidayToEditForm(hol);
    dispatch({
      type: HolidayModelActionTypes.SET_EDIT_FORM,
      payload: model,
    });
    controllerDispatch({
      type: HolidayControllerActionTypes.SET_EDIT_FORM,
      payload: controller,
    });
    setEditBottomSheet(true);
  };

  const isWebView = mqWeb();
  const { openDialog, closeDialog } = useDialog();
  const { openBottomSheet } = useBottomSheet();

  // Confirm Dialog
  const openConfirmDialog = useCallback(() => {
    openDialog({
      heading: "Add Time Away",
      message: `Are you sure you want to add Time Away from:\n${formatDate(
        holidayStartDate.toString(),
        0,
        0
      )} until ${formatDate(holidayEndDate.toString(), 23, 59)}`,
      confirmButton: {
        label: "Add Time Away",
        onPress: addHolidays,
      },
      dismissable: true,
      onDismiss: () => {
        closeDialog();
        setResetHolidayForm();
      },
    });
  }, [addHolidays, holidayStartDate, holidayEndDate]);

  // Delete Dialog callback
  const openDeleteDialog = useCallback((hol: EventItem) => {
    openDialog({
      heading: "Confirm Deletion",
      message: "Are you sure you want to delete this entry?",
      confirmButton: {
        label: "Delete",
        isDanger: true,
        onPress: () => deleteHolidays(hol.id),
      },
      dismissable: true,
      onDismiss: () => closeDialog(),
    });
  }, []);

  // Error Dialog
  const openErrorDialog = useCallback(() => {
    openDialog({
      heading: "Input Errors",
      message: [`Invalid details.`, `Please review and resubmit`],
      confirmButton: {
        label: "Review Details",
        onPress: () => {
          closeDialog();
          setHolidayDateError();
        },
      },
      onDismiss: () => {
        closeDialog();
        setHolidayDateError();
      },
      dismissable: true,
    });
  }, []);

  // Edit BottomSheet
  useEffect(() => {
    if (editBottomSheet) {
      openBottomSheet({
        type: "generic",
        data: {
          component: HolidayForm,
          heading: "Edit Time Away",
          componentProps: {
            editMode: true,
            state,
            dispatch,
            controllerDispatch,
          },
          componentContentWrap: true,
          confirmButton: {
            label: "Save Changes",
            onPress: () => updateHolidays(selectedHoliday),
            disabled: submitted,
          },
          onDismiss: () => {
            setResetHolidayForm();
            setEditBottomSheet(false);
          },
        },
      });
    }
  }, [editBottomSheet, state]);

  const containerStyles = useMemo(() => [styles.container, !isWebView && { marginBottom: spacing[50] * -1 }], [
    isWebView,
  ]);

  if (loading) {
    return <Loading />;
  } else if (error) {
    return <ErrorMessage apolloError={error} />;
  } else if (data && data.getS12Doctor) {
    return (
      <>
        <View style={containerStyles}>
          <ContentWrap>
            <Text format={TypographyType.HeadingMedium}>Add Time Away</Text>
            <Text format={TypographyType.Small} marginBottom={30} color={palette.slate}>
              Use Manage Time Away to define periods of uncontactable time, such as annual leave, appointments or
              maternity/paternity leave.
            </Text>
            <HolidayForm />
            <Button
              onPress={() => {
                Keyboard.dismiss();
                closeDialog();
                openConfirmDialog();
              }}
              marginTop={spacing[15]}
              marginBottom={0}
            >
              Add Time Away
            </Button>
          </ContentWrap>

          <SectionDivider />

          <HolidayList
            heading="Current Time Away"
            description="The Time Away listed below is currently active and visible to AMHPs when viewing your diary."
            defaultCopy="You are not currently Away."
            state={current}
            color={palette.red}
            editHoliday={editHoliday}
            deleteHoliday={openDeleteDialog}
          />

          <SectionDivider />

          <HolidayList
            heading="Forthcoming Time Away"
            description={`Each of the away times listed below, will become active on the "From" date.`}
            defaultCopy="You have no forthcoming Time Away."
            state={upcoming}
            color={palette.redFaded}
            editHoliday={editHoliday}
            deleteHoliday={openDeleteDialog}
          />
        </View>
      </>
    );
  } else {
    return <Loading />;
  }
};

export default Holidays;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: color.background,
  },
});

function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}
