import * as React from "react";
import InputMask from "react-input-mask";
import { supportsDateInput, Event, Ref } from "@/utils/web";

import { unstable_createElement as createElement } from "react-native-web";
require("./style.css");

const webInput = (props: any) => createElement("input", props);

const TimePicker = React.forwardRef(
  (
    props: {
      value: { hours: number; minutes: number };
      onValueChange: (v: { hours: number; minutes: number }) => void;
      label?: string;
      open?: boolean;
      disabled?: boolean;
      onWebBlur?: () => void;
      setOpen?: (arg0: boolean) => void;
    },
    ref: Ref
  ) => {
    const shouldDisplayDateInput = React.useMemo(supportsDateInput, []);

    const onTimeInputChange = (event: Event) => {
      const { currentTarget } = event;
      const value = currentTarget.value ? currentTarget.value.split(":") : ["00", "00"];
      props.onValueChange({
        hours: parseInt(value[0]) < 24 ? +value[0] || 0 : 23,
        minutes: parseInt(value[1]) < 60 ? +value[1] || 0 : 59,
      });
    };

    const onKeyUp = (event: Event) => {
      const { keyCode } = event;

      if (keyCode === 13) {
        ref.current && ref.current.blur();
      }
    };

    const disabled = !!props.disabled;

    if (shouldDisplayDateInput) {
      return webInput({
        ref: ref,
        type: "time",
        value: formatTime(props.value),
        disabled,
        step: "900", // 15 minutes, in seconds
        onBlur: props.onWebBlur,
        onChange: (e: { currentTarget: { value: string } }) => {
          const val = e.currentTarget.value ? e.currentTarget.value.split(":") : ["00", "00"];
          props.onValueChange({ hours: +val[0] || 0, minutes: +val[1] || 0 });
        },
      });
    }

    return (
      <InputMask
        disabled={disabled}
        mask="99:99"
        onBlur={props.onWebBlur}
        onChange={onTimeInputChange}
        value={formatTime(props.value)}
      >
        {() =>
          webInput({
            className: "date-input",
            onKeyUp,
            ref,
          })
        }
      </InputMask>
    );
  }
);

TimePicker.displayName = "DatePicker";

export default TimePicker;

function formatTime(value: { hours: number; minutes: number }) {
  return `${leftPad(value.hours)}:${leftPad(value.minutes)}`;
}

function leftPad(num: number, size = 2): string {
  let s = String(num);
  while (s.length < size) {
    s = "0" + s;
  }
  return s;
}
