import Auth from "@aws-amplify/auth";
import React, { useEffect, useRef, useState } from "react";
import { Platform, StyleSheet, TouchableWithoutFeedback, View } from "react-native";
import { useSetRecoilState, useRecoilValue, useRecoilState } from "recoil";

import { BackButtonProps } from "@/components/BackButton/BackButton.props";
import Text from "@/components/Text";
import { TextInput } from "@/components/TextInput";
import { PasswordInput } from "@/components/PasswordInput";
import { AuthScreen } from "@/layouts";
import { TypographyType } from "@/models/Typography";
import { RouteKeys } from "@/navigationv2";
import { AuthNavigationProps, ScreenOptions } from "@/navigationv2/types";
import { createScreenTitle } from "@/navigationv2/utilities";
import { spacing } from "@/theme";

import {
  isDemoEnvironment as recoilIsDemoEnvironment,
  userDetails,
  reset,
  signup,
  cognitoUserSignal,
  setCognitoUser,
  loading,
  snackbarMessage,
  justLoggedIn,
  initialLogin,
} from "@/utils/recoil";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useDialog } from "@/hooks";
import ENV from "@/config";

const isEnvProdOrMaster = !!(ENV.ENV === "prod" || ENV.ENV === "master");

// prettier-ignore
interface PropTypes extends AuthNavigationProps<RouteKeys.SignInScreen> { }

const SignInScreen = (props: PropTypes) => {
  const [isDemoEnvironment, setIsDemoEnvironment] = useRecoilState(recoilIsDemoEnvironment);
  const user = useRecoilValue(userDetails);
  const setReset = useSetRecoilState(reset);
  const setSignup = useSetRecoilState(signup);
  const setCognitoUserSignal = useSetRecoilState(cognitoUserSignal);
  const setMessage = useSetRecoilState(snackbarMessage);
  const setLoading = useSetRecoilState(loading);
  const setJustLoggedIn = useSetRecoilState(justLoggedIn);
  const setInitalLogin = useSetRecoilState(initialLogin);

  const [username, setUsername] = useState(user ? user.email : "");
  const [password, setPassword] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const secondInput = useRef<any>();
  const [loginAttemptCount, setLoginAttemptCount] = useState(0);

  const { openDialog, closeDialog } = useDialog();

  // Force user to reset password after 5 wrong attempts if only Prod/ dev
  useEffect(() => {
    if (isEnvProdOrMaster) {
      AsyncStorage.multiGet([username.toLowerCase().trim(), "loginEmail"])
        .then(response => {
          const loginAttemptCount = response[0][1];
          const email = response[1][1] || "";
          if (loginAttemptCount) {
            setReset({ email: email, password: password });
            Auth.forgotPassword(email)
              .then(() => {
                openDialog({
                  heading: "Warning!",
                  message: [
                    "You have failed to login too many times and your account has been locked. ",
                    "A verification code has been sent to your email. ",
                    "Please reset your password.",
                  ],
                  confirmButton: {
                    label: "OK",
                    onPress: () => {
                      closeDialog();
                    },
                  },
                  dismissable: false,
                });
                props.navigation.navigate("ForgotPassword", {
                  params: "ForgotPassword",
                });
                setMessage("A verification code has been sent to your email");
              })
              .catch(error => {
                setMessage(error.message);
              });
          }
        })
        .catch(() => {
          // Cleaned the storage, ignore
        });
    }
  }, [loginAttemptCount]);

  const genericSigninError = "You have entered incorrect credentials";

  const signIn = async () => {
    let timeout = 0;
    setSubmitted(true);
    if (/^demodr\d+$/i.test(username) || /demoamhp\d+/i.test(username) || /democcg\d+/i.test(username)) {
      if (!isDemoEnvironment) {
        // timeout here to give time for amplify to reconfigure... a hack
        timeout = 1000;
      }
      setIsDemoEnvironment(true);
    } else {
      if (isDemoEnvironment) {
        timeout = 1000;
      }
      setIsDemoEnvironment(false);
    }

    setTimeout(async () => {
      const email =
        // check if it is a zero-based phone number, not an email
        username.indexOf("0") === 0 && username.indexOf("@") === -1
          ? "+44" + username.slice(1)
          : username.toLowerCase();
      if (Platform.OS === "web") {
        await Auth.configure({
          storage: window.sessionStorage,
        });
      }

      if (Platform.OS !== "web") setLoading(true);

      Auth.signIn(email, password)
        .then(user => {
          setCognitoUser(user);
          setJustLoggedIn(true);
          if (isEnvProdOrMaster) {
            AsyncStorage.setItem(username.toLowerCase().trim(), "0");
            AsyncStorage.removeItem("loginEmail");
          }
          setTimeout(() => setLoading(false), 800);

          if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
            setCognitoUserSignal(2);
            props.navigation.navigate("NewPassword");
          } else if (user.challengeName === "SMS_MFA") {
            setCognitoUserSignal(3);
            setSignup({ email, password, challenge: user.challengeName });
            props.navigation.navigate("Confirmation");
          }

          // If use doesn't belong to cognito group of AMHP, Doctor or OrgClaims, we will sign them out
          // and return invalid username or password
          // WARNING: this step must go after the check for status NEW_PASSWORD_REQUIRED because
          // The response from the sign in function will not contain signInUserSession and you
          // Cannot determine what group the user belongs to if you don't have the signInUserSession
          if (
            !user.signInUserSession.idToken.payload["cognito:groups"].some(
              (group: string) => group === "AMHP" || group === "Doctor" || group === "OrgClaims"
            )
          ) {
            Auth.signOut();
            setPasswordError(genericSigninError);
            setUsernameError(genericSigninError);
          }
        })
        .catch(async err => {
          setLoading(false);
          setSubmitted(false);

          if (err.code === "UserNotConfirmedException") {
            await Auth.resendSignUp(email)
              .then(() => {
                setSignup({ email, password, challenge: err.code });
                props.navigation.navigate("Confirmation");
              })
              .catch(err => {
                console.log(err);
                setMessage("Please ensure there's an internet connection or contact support.");
              });
          } else if (err.code === "PasswordResetRequiredException") {
            setReset({ email, password });
            await Auth.forgotPassword(email)
              .then(() => {
                setMessage("A temporary password has been sent to your email");
                props.navigation.navigate("ForgotPassword", {
                  params: "ForgotPassword",
                });
              })
              .catch(() => {
                setMessage("Please ensure there's an internet connection or contact support.");
              });
          } else if (err.code === "NotAuthorizedException") {
            // updating incorrect login attempts
            if (isEnvProdOrMaster) {
              AsyncStorage.getItem(username.toLowerCase().trim()).then(numOfAttempts => {
                let attempts = 1;
                if (numOfAttempts !== null && numOfAttempts !== undefined) {
                  attempts = Number(numOfAttempts) + 1;
                }
                AsyncStorage.setItem(username.toLowerCase().trim(), attempts.toString());
                if (attempts > 4) {
                  setLoginAttemptCount(attempts);
                }
              });
              AsyncStorage.setItem("loginEmail", email);
            }
            // The error message is returned for both username and password so that no indication is given as to
            // what caused the error.
            setPasswordError(genericSigninError);
            setUsernameError(genericSigninError);
          } else if (err.code === "UserNotFoundException") {
            // The error message is returned for both username and password so that no indication is given as to
            // what caused the error.
            setPasswordError(genericSigninError);
            setUsernameError(genericSigninError);
          } else if (err.code === "NetworkError") {
            setPasswordError("Network issue. Please ensure your device is connected and online");
            setUsernameError("Network issue. Please ensure your device is connected and online");
          } else {
            // The error message is returned for both username and password so that no indication is given as to
            // what caused the error.
            setPasswordError(genericSigninError);
            setUsernameError(genericSigninError);
          }
        });
    }, timeout);
  };

  const onSetUsername = (value: string) => {
    if (usernameError !== "") setUsernameError("");
    setUsername(value);
  };

  const onSetPassword = (value: string) => {
    if (passwordError !== "") setPasswordError("");
    setPassword(value);
  };

  const forgotPassword = () => {
    setReset({ email: "", password: "" });
    props.navigation.navigate("ForgotPassword", {
      params: "resetPassword",
    });
  };

  useEffect(() => {
    AsyncStorage.getItem("authVerificationCode").then(value => {
      if (value !== "null" && value !== null && value !== undefined) {
        props.navigation.navigate("SignIn");
      } else if (value === "null") {
        props.navigation.navigate("SignIn");
      }
    });
  }, []);

  const backButtonConfig: BackButtonProps = {
    enabled: true,
    float: true,
    color: "white",
  };

  return (
    <AuthScreen
      testID="Login"
      backButton={backButtonConfig}
      buttons={[
        {
          label: "Login",
          onPress: () => signIn(),
          disabled:
            submitted || String(username).length < 5 || !password || password.trim().length < 8 || /\s/g.test(username),
          testID: "Login__Submit__Button",
        },
        {
          label: "Reset Password",
          onPress: forgotPassword,
          textButton: true,
          testID: "Login__ResetPassword__Button",
        },
        {
          label: "Problem signing in?",
          textButton: true,
          onPress: () => props.navigation.navigate("Support"),
          testID: "Login__Problems__Button",
        },
        {
          label: "Enter a verification code",
          textButton: true,
          onPress: () => {
            props.navigation.navigate("ForgotPassword", {
              params: "pwVerification",
            });
          },
          testID: "Login__verification__Button",
        },
      ]}
    >
      <Text format={TypographyType.HeadingMedium} style={styles.headline} testID="Login__Header">
        Login
      </Text>
      <TouchableWithoutFeedback style={styles.fill} onPress={event => event.stopPropagation()}>
        <View>
          <TextInput
            icon="person-outline"
            label="Email or Mobile Phone"
            keyboardType={"email-address"}
            autoCorrect={false}
            autoFocus={false}
            autoCapitalize="none"
            returnKeyType="next"
            onSubmitEditing={() => {
              secondInput.current && secondInput.current.focus();
            }}
            error={usernameError !== ""}
            errorText={usernameError}
            value={username}
            onChangeText={value => onSetUsername(value)}
            withPasswordField={true}
          />

          <PasswordInput
            icon="lock-outline"
            label="Password"
            returnKeyType="go"
            autoCapitalize="none"
            autoCorrect={false}
            ref={secondInput}
            error={passwordError !== ""}
            errorText={passwordError}
            value={password}
            onChangeText={value => onSetPassword(value)}
            onSubmitEditing={() => signIn()}
          />
        </View>
      </TouchableWithoutFeedback>
    </AuthScreen>
  );
};

const screenOptions: ScreenOptions = {
  title: createScreenTitle("Sign In"),
};

SignInScreen.screenOptions = screenOptions;

export default SignInScreen;

const styles = StyleSheet.create({
  fill: {
    flexDirection: "column",
    maxWidth: 800,
    minWidth: 275,
    width: "100%",
    flexBasis: "auto",
    flexShrink: 0,
    marginTop: spacing[10],
  },
  headline: {
    marginBottom: 40,
    alignSelf: "center",
  },
});
