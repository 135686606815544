/* eslint-disable react-native/no-unused-styles */
/* ^ This is set because styles are selected dynamically, based on a prop; lack of explicit reference of styles causes a false "no-unused-styles" warning, hence the disable flag above. */

import React from "react";
import { StyleSheet, StyleProp, View, ViewStyle } from "react-native";

import { palette } from "../../theme";

import { AvailabilityType, AvailabilityState } from "libs/types/availability";
type StatusType = AvailabilityType | AvailabilityState;

interface AvailabilityDotProps {
  status: StatusType;
  simpleStatus?: boolean;
  border?: boolean;
  style?: StyleProp<ViewStyle>;
}

const AvailabilityDot = (props: AvailabilityDotProps) => {
  const { simpleStatus = false, border = true } = props;
  const detailedAvailabilityTypes = ["trust", "oncall", "independent"];
  const status = simpleStatus
    ? detailedAvailabilityTypes.includes(props.status)
      ? "available"
      : props.status
    : props.status;
  return (
    <View style={[styles.dot, props.style ? props.style : {}]}>
      {border && <View style={[styles.dotOuter, styles[status]]} />}
      <View style={[border ? styles.dotInner : styles.dotInnerNoBorder, styles[status]]} />
    </View>
  );
};

const styles: {
  [key: string]: any;
} = StyleSheet.create({
  dot: {
    position: "relative",
  },

  dotInner: {
    width: 24,
    height: 24,
    borderRadius: 24,
    opacity: 0.4,
  },
  dotInnerNoBorder: {
    width: 10,
    height: 10,
    borderRadius: 10,
    opacity: 1,
  },

  dotOuter: {
    width: 16,
    height: 16,
    position: "absolute",
    left: 4,
    top: 4,
    borderRadius: 16,
    opacity: 1,
  },

  inAssessment: { backgroundColor: palette.yellow },
  available: { backgroundColor: palette.aqua },
  trust: { backgroundColor: palette.aqua },
  oncall: { backgroundColor: palette.aqua },
  booked: { backgroundColor: palette.orange },
  away: { backgroundColor: palette.red },
  independent: { backgroundColor: palette.aqua },
  unavailable: { backgroundColor: palette.greyBlue },
  noInformation: { backgroundColor: palette.grey },
});

export default React.memo(AvailabilityDot);
