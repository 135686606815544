import React from "react";
import { StyleSheet, View, TouchableOpacity } from "react-native";

import { TypographyType } from "@/models/Typography";
import Text from "../Text";
import Icon from "../Icon";

import { spacing, color } from "../../theme";
import { ContentWrap } from "../ContentWrap";

export const InfoIcon: React.FC<{
  onToggle: () => void;
}> = ({ onToggle }) => (
  <TouchableOpacity activeOpacity={1} onPress={onToggle} style={[styles.headerSpacing, { marginLeft: spacing[15] }]}>
    <Icon name="info" color={color.textExtraLight} />
  </TouchableOpacity>
);

export const AdditionalInformation: React.FC<{ onClose: () => void }> = ({ onClose, children }) => (
  <View style={styles.infoSection}>
    <ContentWrap>
      {children}
      <TouchableOpacity activeOpacity={1} onPress={onClose} style={[styles.row, styles.centered]}>
        <Icon name="keyboard-arrow-up" color={color.text} style={styles.closeIcon} />
        <Text format={TypographyType.TinyBold}>Close</Text>
      </TouchableOpacity>
    </ContentWrap>
  </View>
);

const styles = StyleSheet.create({
  buttonWrapMobile: {
    marginTop: spacing[25],
    marginBottom: spacing[20] * -1,
  },
  buttonWrapWeb: {
    marginTop: spacing[30],
    flexDirection: "row",
    justifyContent: "flex-end",
  },

  buttonMobile: {
    marginBottom: spacing[10],
  },
  buttonWeb: {
    marginLeft: spacing[10],
    marginBottom: 0,
  },

  bottomSpacing: {
    paddingBottom: spacing[30],
  },

  confirmationText: {
    marginTop: spacing[10],
  },

  input: {
    marginHorizontal: spacing[20],
    minWidth: 100,
    borderBottomColor: color.backgroundGrey,
    borderBottomWidth: 1,
    textAlign: "center",
  },

  repeatErrorText: {
    marginTop: spacing[25] * -1,
  },

  row: {
    flexDirection: "row",
    alignItems: "center",
  },

  spaceBetween: {
    justifyContent: "space-between",
  },

  centered: { justifyContent: "center" },

  timeInput: {
    backgroundColor: color.background,
  },

  timePickerRowWeb: {
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  timePickerOne: {
    flex: 1,
    zIndex: 5,
  },
  timePickerOneWeb: {
    paddingRight: spacing[20],
  },
  timePickerTwo: {
    flex: 1,
    zIndex: 5,
  },
  timePickerTwoWeb: {
    paddingLeft: spacing[20],
  },

  headerSpacing: {
    marginBottom: spacing[20],
  },

  vertical: {
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: color.thinBorder,
    marginVertical: spacing[30],
    paddingTop: spacing[30],
    paddingBottom: spacing[15],
    backgroundColor: color.backgroundGrey,
  },

  infoSection: {
    backgroundColor: color.backgroundGrey,
    paddingVertical: spacing[30],
    marginBottom: spacing[30],
  },

  closeIcon: { marginRight: 10 },
});
