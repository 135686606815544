import React, { useEffect, useMemo } from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import merge from "lodash.merge";
import gql from "graphql-tag";
import dayjs from "dayjs";
import { ExportItems, PropertiesToExport } from "./ExportItems";
import { ClaimStatus, LocationNameInput } from "libs/types/API";
import { formatClaimStatus, getInitials } from "@/utils/helpers";

interface PropTypes {
  username: string;
}

// GetVisitsByDoctor query -> currently using getS12Doctor query on visitsResults page to access visits via doctor profile
// we might wanna switch it for this, using getVisitsByDoctor query as we will have to query by date
// TODO: kept it to a bare minimum here, query will need to expand based on properties we wanna display in csv file
export const GET_VISITS = gql`
  query GetVisitsByDoctor($id: ID!, $limit: Int, $nextToken: String, $to: String = "Z", $from: String = "0") {
    visitsByDoctor(
      doctorVisitDoctorId: $id
      sortDirection: DESC
      limit: $limit
      nextToken: $nextToken
      time: { between: [$from, $to] }
    ) {
      items {
        id
        time
        claimDisplayId
        claimStatus
        assessment {
          id
          patientName
          locationName {
            city
            postcode
            address
          }
          amhp {
            id
            name
          }
        }
        claim {
          id
          organisation {
            id
            name
          }
        }
      }
      nextToken
    }
  }
`;

interface ResponseAssessment {
  id: string;
  patientName: string;
  locationName: LocationNameInput;
  amhp: {
    id: string;
    name: string;
  };
}

interface ResponseClaim {
  id: string;
  organisation: {
    id: string;
    name: string;
  };
}

interface Response {
  visitsByDoctor: {
    items: {
      id: string;
      time: string;
      claimDisplayId: string;
      claimStatus: ClaimStatus;
      assessment: ResponseAssessment;
      claim: ResponseClaim;
    }[];
    nextToken: string;
  };
}

export const ExportVisits = ({ username }: PropTypes) => {
  const [nextToken, setNextToken] = React.useState<string | null>(null);

  const listOptions = useMemo(
    () => ({
      variables: {
        id: username,
        limit: 10,
      },
      fetchPolicy: "cache-and-network",
    }),
    [username]
  );

  // using lazyQuery as we will trigger it inside the child component when switching from list to date etc
  const [getVisits, { loading, error, data, fetchMore, variables }] = useLazyQuery<Response>(GET_VISITS);
  const visitsByDoctor = {
    timestamp: Date.now(),
    items: data?.visitsByDoctor.items || [],
    nextToken: data?.visitsByDoctor.nextToken,
  };

  // running query on mount
  useEffect(() => {
    getVisits(listOptions);
  }, []);

  // fn to retrieve initial visit results, optional passing in dateRange object to allow user to query by date
  // will need to run fn inside child component when switching from list to date view
  const getInitialVisits = (dateRange?: { from: string; to: string }) => {
    let vars = variables;
    if (!dateRange) {
      const { from, to, ...rest } = variables;
      vars = rest;
    }

    getVisits({
      variables: {
        ...vars,
        limit: dateRange ? 2000 : 10,
        ...(dateRange || {}),
        fetchPolicy: dateRange ? "network-only" : "cache-and-network",
      },
    });
  };

  const loadMore = () => {
    if (visitsByDoctor?.items?.length)
      fetchMore({
        query: GET_VISITS,
        variables: {
          ...variables,
          nextToken: nextToken || visitsByDoctor?.nextToken,
          fetchPolicy: "network-only",
        },
        updateQuery: (previousResult: Response, { fetchMoreResult }: { fetchMoreResult: Response }) => {
          setNextToken(fetchMoreResult.visitsByDoctor.nextToken);
          const mergedVisits = merge({}, previousResult, {
            visitsByDoctor: {
              ...previousResult.visitsByDoctor,
              items: [...previousResult.visitsByDoctor.items, ...fetchMoreResult.visitsByDoctor.items],
              nextToken: fetchMoreResult.visitsByDoctor.nextToken,
            },
          });
          return mergedVisits;
        },
      });
  };

  type ResponseArrayItem = Exclude<typeof visitsByDoctor, undefined>["items"][0];

  const exportProperties: PropertiesToExport<ResponseArrayItem>[] = [
    {
      values: {
        displayItem: "time",
      },
      heading: "Date of Assessment",
      callback: (args: { displayItem: string }) => dayjs(args.displayItem).format("DD/MM/YYYY"),
    },
    {
      values: {
        displayItem: "time",
      },
      heading: "Time",
      callback: (args: { displayItem: string }) => dayjs(args.displayItem).format("HH:mm"),
    },
    {
      values: {
        displayItem: "assessment",
      },
      heading: "Location",
      callback: (args: { displayItem: ResponseAssessment }) => args.displayItem.locationName.postcode,
    },
    {
      values: {
        displayItem: "assessment",
      },
      heading: "Patient Initials",
      callback: (args: { displayItem: ResponseAssessment }) => getInitials(args.displayItem.patientName),
    },
    {
      values: {
        displayItem: "assessment",
      },
      heading: "AMHP",
      callback: (args: { displayItem: ResponseAssessment }) => args.displayItem.amhp.name,
    },
    {
      values: {
        displayItem: "claimDisplayId",
      },
      heading: "Claim ID",
    },
    {
      values: {
        displayItem: "claim",
      },
      heading: "CCG",
      callback: (args: { displayItem: ResponseClaim }) => args.displayItem?.organisation.name || "",
    },
    {
      values: {
        displayItem: "claimStatus",
      },
      heading: "Claim Status",
      callback: (args: { displayItem: ClaimStatus }) => (args.displayItem ? formatClaimStatus(args.displayItem) : ""),
    },
  ];

  const items = useMemo(
    () =>
      visitsByDoctor?.items?.map(item => ({
        id: item.id,
        heading: item.assessment.patientName,
        time: item.time,
      })) || [],
    [visitsByDoctor?.items]
  );

  return (
    <ExportItems
      type="visits"
      displayItems={items}
      propertiesToExport={exportProperties}
      queryData={visitsByDoctor}
      getItems={getInitialVisits}
      loadMore={loadMore}
      loading={loading}
      error={error}
    />
  );
};
