import * as React from "react";
import { View, ViewStyle, StyleProp, StyleSheet } from "react-native";

import ContentWrap from "../ContentWrap/ContentWrap";
import Text from "../Text";
import { TypographyType } from "@/models/Typography";
import { mqWeb } from "../../utils/helpers";
import { Description } from "../Description/Description";

export interface SectionItem {
  label: string;
  value?: string | JSX.Element;
  secondaryValue?: string;
  displayError?: boolean;
  bold?: boolean;
  flex?: number;
  testID?: string;
  descriptionStyle?: StyleProp<ViewStyle>;
}

export function DetailsSection(props: {
  header?: string;
  columns?: number;
  items: SectionItem[];
  spaceBottom?: number;
  spaceTop?: number;
}) {
  const isWeb = mqWeb();
  const columnStyle = (flex: number) =>
    isWeb
      ? props.columns
        ? {
            flexBasis: `${(100 / props.columns) * flex}%`,
          }
        : styles.flexWeb
      : styles.flexMobile;

  return (
    <ContentWrap>
      {!!props.header && <Text format={TypographyType.HeadingMedium}>{props.header}</Text>}
      <View
        style={[
          styles.row,
          props.spaceBottom ? { marginBottom: props.spaceBottom } : {},
          props.spaceTop ? { marginTop: props.spaceTop } : {},
        ]}
      >
        {props.items.filter(notEmpty).map((item: SectionItem, i: number) => (
          <Description
            key={i}
            style={[
              columnStyle(item.flex ? item.flex : 1),
              i === props.items.length - 1 && styles.noMarginBottom,
              item.descriptionStyle && item.descriptionStyle,
            ]}
            label={item.label}
            secondaryData={item.secondaryValue}
            bold={item.bold}
            testID={item.testID}
            displayError={item.displayError || false}
          >
            {item.value}
          </Description>
        ))}
      </View>
    </ContentWrap>
  );
}

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  flexWeb: {
    flexBasis: "50%",
    marginBottom: 0,
  },
  flexMobile: {
    flexBasis: "100%",
  },
  noMarginBottom: {
    marginBottom: 0,
  },
});

function notEmpty(value: SectionItem, _index: number, _array: SectionItem[]) {
  return value !== null && value !== undefined && value.value !== undefined;
}
