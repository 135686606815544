import React from "react";
import { View } from "react-native";

// temporary solution until NetInfo get fixed for web
// https://github.com/react-native-community/react-native-netinfo/issues/174
const OfflineIndicator = () => {
  return <View />;
};

export default OfflineIndicator;
