import dayjs from "dayjs";
import { FormStatus } from "libs/types/API";

/**
 * Creates an MHA history note with the format EVENT@USER@DATE_TIME
 * @param status The new form status
 * @param name The name of the user who made the state change
 */
export function statusChangeToNote(
  status: FormStatus,
  name: string,
  isJointForm: boolean,
  prevStatus?: FormStatus
): string {
  // `Signed by@${user ? user.name : "N/A"}@${dayjs().format()}`
  const suffix = name + "@" + dayjs().format();
  if (status === FormStatus.s20_awaiting_other_doctor) {
    return "Signed by@" + suffix;
  } else if (status === FormStatus.s30_changes_in_progress) {
    return `Signature${isJointForm ? "s" : ""} removed by@` + suffix;
  } else if (status === FormStatus.s60_signed_in_amhp_team_inbox) {
    return "Signed and sent to AMHP Team by@" + suffix;
  } else if (prevStatus === FormStatus.s60_signed_in_amhp_team_inbox && status === FormStatus.s40_signed) {
    return "Attached to Assessment by AMHP@" + suffix;
  } else if (status === FormStatus.s40_signed) {
    return "Signed by@" + suffix;
  } else if (status === FormStatus.s50_signed_and_sent) {
    return "Sent or Downloaded by@" + suffix;
  }
  return "Form Updated by " + name + "@" + dayjs().format();
}
