import gql from "graphql-tag";

import { GpPracticeDefaults, GpOrgDefaults, CCGFragment, GPSurgeryFragment, gpPracticeFound } from "./gql/fragments";

export const GP_ORG_SEACH = gql`
  query findGPOrg($term: String, $postcode: String, $searchFor: String) {
    results: findGPOrg(term: $term, postcode: $postcode, searchFor: $searchFor) {
      ...GpOrgDefaultFields
    }
  }
  ${GpOrgDefaults}
`;
export const GET_GP_PRACTICE_FROM_ORG = gql`
  query getGPPracticeFromOrg($orgId: String, $roleId: String) {
    result: getGPPracticeFromOrg(orgId: $orgId, roleId: $roleId) {
      ...GpPracticeFound
      town
      contact
      ccgId
      ccg {
        ...CCGCondensed
      }
      surgery {
        ...GPSurgeryFields
      }
    }
  }
  ${GPSurgeryFragment}
  ${CCGFragment}
  ${gpPracticeFound}
`;

export const GP_PRACTICE_SEARCH = gql`
  query findGPPractice($term: String) {
    results: findGPPractice(term: $term) {
      ...GpPracticeDefaultFields
    }
  }
  ${GpPracticeDefaults}
`;
export const GET_GP_PRACTICE = gql`
  query getGPPractice($practiceId: String) {
    result: getGPPractice(practiceId: $practiceId) {
      ...GpPracticeFound
      town
      contact
      ccgId
      ccg {
        ...CCGCondensed
      }
    }
  }
  ${CCGFragment}
  ${gpPracticeFound}
`;
