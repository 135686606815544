import React from "react";
import { createStackNavigator } from "@react-navigation/stack";

import { SharedTabRoutes, SupportRoutes, TabNavigationProps } from "@/navigationv2/types";
import screenOptions from "@/navigationv2/config/screenOptions";
import Screens from "@/screens";
import TabIcon from "../TabIcon";

const SupportStack = createStackNavigator<SupportRoutes>();

interface PropTypes extends TabNavigationProps<SharedTabRoutes, "SupportTab"> {}

const SharedSupportStack = () => {
  return (
    <SupportStack.Navigator initialRouteName="SupportScreen" screenOptions={screenOptions}>
      <SupportStack.Screen
        name="SupportScreen"
        component={Screens.SupportScreen}
        options={Screens.SupportScreen.screenOptions}
      />
      <SupportStack.Screen
        name="PrivacyPolicyScreen"
        component={Screens.PrivacyPolicyScreen}
        options={Screens.PrivacyPolicyScreen.screenOptions}
      />
      <SupportStack.Screen
        name="ChangePasswordScreen"
        component={Screens.ChangePasswordScreen}
        options={Screens.ChangePasswordScreen.screenOptions}
      />
      <SupportStack.Screen name="Eula" component={Screens.EulaScreen} options={Screens.EulaScreen.screenOptions} />
      <SupportStack.Screen
        name="ClaimProcessScreen"
        component={Screens.ClaimProcessScreen}
        options={Screens.ClaimProcessScreen.screenOptions}
      />
      <SupportStack.Screen
        name="SupportExplainedScreen"
        component={Screens.SupportExplainedScreen}
        options={Screens.SupportExplainedScreen.screenOptions}
      />
      <SupportStack.Screen
        name="AppUpdateScreen"
        component={Screens.AppUpdateScreen}
        options={Screens.AppUpdateScreen.screenOptions}
      />
    </SupportStack.Navigator>
  );
};

SharedSupportStack.tabOptions = {
  tabBarIcon: TabIcon("help-outline"),
  tabBarLabel: "Support",
};

export default SharedSupportStack;
