import Auth from "@aws-amplify/auth";
import React, { useRef, useState } from "react";
import { StyleSheet, TouchableWithoutFeedback } from "react-native";

import { AnalyticsEvent } from "libs/analytics/events";

import { BackButtonProps } from "@/components/BackButton/BackButton.props";
import { Button } from "@/components/Button";
import { ContentWrap } from "@/components/ContentWrap";
import Text from "@/components/Text";
import { AppScreen } from "@/layouts/AppScreen/AppScreen";
import { TypographyType } from "@/models/Typography";
import { RouteKeys } from "@/navigationv2";
import { SharedTabRoutes, SupportRoutes, ScreenNavigationProps, ScreenOptions } from "@/navigationv2/types";
import { createScreenTitle } from "@/navigationv2/utilities";
import { color, spacing } from "@/theme";
import { recordEvent } from "@/utils/analytics";
import { mqWeb } from "@/utils/helpers";
import { PasswordInput } from "@/components/PasswordInput";

interface PropTypes extends ScreenNavigationProps<SharedTabRoutes, SupportRoutes, RouteKeys.ChangePasswordScreen> {}

const ChangePasswordScreen = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [changeConfirmed, setChangeConfirmed] = useState(false);
  const secondInput = useRef<any>();
  const thirdInput = useRef<any>();

  const resetPassword = async () => {
    await Auth.currentAuthenticatedUser()
      .then(user => {
        return Auth.changePassword(user, currentPassword, password);
      })
      .then(() => {
        setChangeConfirmed(true);
        recordEvent(AnalyticsEvent.RESET_PASSWORD);
      })
      .catch(err => setErrorMessage(err.message));
  };

  const onSetCurrentPassword = (value: string) => {
    if (errorMessage !== "") setErrorMessage("");
    setCurrentPassword(value);
  };

  const passwordsNoMatch = password.length > 0 && passwordConfirm.length > 0 && password !== passwordConfirm;

  const backButtonConfig: BackButtonProps = {
    enabled: true,
    float: true,
    color: color.textWhite,
  };

  const renderContent = () => {
    return !changeConfirmed ? (
      <ContentWrap>
        <Text format={TypographyType.RegularBold} style={styles.heading}>
          Change Password
        </Text>
        <Text format={TypographyType.Small} style={styles.passwordHelp}>
          Your new password must be at least 8 characters long
        </Text>
        <PasswordInput
          label="Current password"
          icon="lock-outline"
          autoCorrect={false}
          autoFocus={false}
          autoCapitalize="none"
          returnKeyType="next"
          value={currentPassword}
          onChangeText={value => onSetCurrentPassword(value)}
          onSubmitEditing={() => {
            secondInput.current && secondInput.current.focus();
          }}
        />
        <PasswordInput
          error={!password || (!!password && password.trim().length > 0 && password.trim().length < 8)}
          errorText="Password must contain at least 8 characters"
          label="New password"
          icon="lock-outline"
          autoCorrect={false}
          autoCapitalize="none"
          returnKeyType="next"
          value={password}
          onChangeText={value => setPassword(value)}
          ref={secondInput}
          onSubmitEditing={() => {
            thirdInput.current && thirdInput.current.focus();
          }}
        />
        <PasswordInput
          error={passwordsNoMatch}
          errorText="Your password and confirmation password do not match"
          label="Confirm new password"
          icon="lock-outline"
          autoCorrect={false}
          autoCapitalize="none"
          returnKeyType="go"
          value={passwordConfirm}
          onChangeText={value => setPasswordConfirm(value)}
          ref={thirdInput}
          onSubmitEditing={() => resetPassword()}
        />
        {errorMessage !== "" && (
          <Text format={TypographyType.Micro} style={styles.errorMessage}>
            {errorMessage}
          </Text>
        )}

        <Button
          onPress={() => resetPassword()}
          style={styles.loginButton}
          mode="contained"
          disabled={
            !currentPassword ||
            (currentPassword && currentPassword.trim() === "") ||
            password.length < 8 ||
            passwordConfirm.length < 8 ||
            passwordsNoMatch
          }
        >
          Reset password
        </Button>
      </ContentWrap>
    ) : (
      <Text format={TypographyType.HeadingSmall} style={styles.helperText}>
        Your password has been successfully updated.
      </Text>
    );
  };

  const isWebView = mqWeb();

  return (
    <TouchableWithoutFeedback onPress={e => e.stopPropagation()}>
      <AppScreen
        contextLabel="Support"
        pageTitle={isWebView ? "Reset Password" : `Reset ${"\n"}Password`}
        backButton={backButtonConfig}
        testID="ResetPasswordScreen"
      >
        {renderContent()}
      </AppScreen>
    </TouchableWithoutFeedback>
  );
};

const screenOptions: ScreenOptions = {
  title: createScreenTitle("Reset Password"),
};

ChangePasswordScreen.screenOptions = screenOptions;

export default ChangePasswordScreen;

const styles = StyleSheet.create({
  errorMessage: {
    color: color.textError,
    alignSelf: "center",
    marginTop: -spacing[15],
    marginBottom: spacing[10],
  },
  loginButton: { minWidth: 150, width: "100%" },
  heading: {
    textAlign: "center",
    marginBottom: spacing[20],
  },
  helperText: {
    marginLeft: spacing[30],
    marginRight: spacing[30],
  },
  passwordHelp: {
    paddingBottom: spacing[40],
    color: color.textLight,
    textAlign: "center",
  },
});
