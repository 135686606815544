import { ApolloClient, NetworkStatus } from "apollo-boost";
import gql from "graphql-tag";

import { GraphQLError } from "graphql";

import { LocationInput, LocationNameInput } from "libs/types/API";
import { getAddressError, S12Error, networkError, getError } from "./Error";
import { LocationNameFragment, LocationCoordsFragment, CCGFragment } from "./gql/fragments";

export const MHA_LOCATION_SEARCH = gql`
  query findAddress($term: String, $addressId: String) {
    results: findAddress(term: $term, addressId: $addressId) {
      id
      itemText
      selected
      isComplete
      isExpandable
      expandsTo
      locationName {
        ...fullLocation
      }
    }
  }
  ${LocationNameFragment}
`;

export const LOCATION_SEARCH = gql`
  query findAddress($term: String, $addressId: String) {
    results: findAddress(term: $term, addressId: $addressId) {
      id
      itemText
      ccgId
      ccgName
      selected
      isComplete
      isExpandable
      expandsTo
      ccg {
        ...CCGCondensed
      }
      location {
        ...latLon
      }
      locationName {
        ...fullLocation
      }
    }
  }
  ${LocationNameFragment}
  ${LocationCoordsFragment}
  ${CCGFragment}
`;

export const POSTCODE_SEARCH = gql`
  query findAddressByPostcode($postcode: String!, $findExactMatch: Boolean) {
    findAddressByPostcode(postcode: $postcode, findExactMatch: $findExactMatch) {
      id
      location {
        lat
        lon
      }
      locationName {
        city
        postcode
      }
    }
  }
`;

export interface PostcodeItem {
  location: LocationInput;
  locationName: LocationNameInput;
}

// eslint-disable-next-line
const postcodeExpression = /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([AZa-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z])))) [0-9][A-Za-z]{2})$/i;

export const getPostcodeData = (client: ApolloClient<any>) => async (
  postcode: string,
  findExactMatch = false
): Promise<{ location: LocationInput; locationName: LocationNameInput }> => {
  if (postcode.trim() === "") {
    throw new Error("No postcode provided");
  }
  const pCode = `${postcode.replace(" ", "").slice(0, -3)} ${postcode.replace(" ", "").slice(-3)}`;
  const postcodeValid = postcodeExpression.test(pCode);

  if (!postcodeValid) {
    throw new Error("Invalid postcode");
  } else {
    const data = await doPostcodeSearchWithClient(client, pCode, findExactMatch);
    return {
      location: { lat: data.location.lat, lon: data.location.lon },
      locationName: {
        city: data.locationName.city,
        postcode: data.locationName.postcode,
      },
    };
  }
};

function doPostcodeSearchWithClient(client: ApolloClient<any>, postcode: string, findExactMatch = false) {
  return client
    .query<{ findAddressByPostcode: PostcodeItem }>({
      query: POSTCODE_SEARCH,
      variables: { postcode, findExactMatch },
    })
    .then(
      (res: {
        data: { findAddressByPostcode: PostcodeItem };
        errors?: GraphQLError[];
        loading: boolean;
        networkStatus: NetworkStatus;
        stale: boolean;
      }) => {
        if (res.data && res.data.findAddressByPostcode) {
          return res.data.findAddressByPostcode;
        } else if (res.errors) {
          throw new S12Error(getAddressError);
        } else if (res.networkStatus === NetworkStatus.error) {
          throw new S12Error(networkError);
        } else {
          throw new S12Error(getError);
        }
      }
    );
}
