import React from "react";
import { StyleSheet, View, Platform } from "react-native";
import { TouchableRipple } from "react-native-paper";
import dayjs, { Dayjs } from "dayjs";
import { useNavigation } from "@react-navigation/native";

import Text from "../../Text";
import { InfoItemLabel } from "../../InfoItem";
import ItemSpacer from "../../ItemSpacer";

import { TypographyType } from "@/models/Typography";
import { FormType } from "libs/types/mhaForms";
import { Forms } from "libs/mhaForms/formSections";
import { RouteKeys } from "@/navigationv2";
import { palette, spacing } from "@/theme";
import { mqWeb } from "@/utils/helpers";
import { getMhaFormVersion } from "libs/mhaForms/helpers";
import ENV from "@/config";
import { AllAMHPRoutes } from "@/navigationv2/types";
import { StackNavigationProp } from "@react-navigation/stack";
import { useNetInfo } from "@react-native-community/netinfo";
import { useDialog } from "@/hooks";

interface Form {
  id: string;
  formType: FormType;
  patientName: string;
  doctorName: string;
  examinationDate: Dayjs;
  createdAt: string;
}
interface SubmittedFormsProps {
  forms: Form[];
}

const SubmittedForms: React.FC<SubmittedFormsProps> = ({ forms }) => {
  const netInfo = useNetInfo();
  const { openDialog, closeDialog } = useDialog();
  const isWebView = mqWeb();
  const navigation = useNavigation<StackNavigationProp<AllAMHPRoutes>>();

  const sortByCreatedDate = (a: Form, b: Form) => {
    return dayjs(b.createdAt).diff(a.createdAt);
  };

  return (
    <View style={styles.outterWrapper}>
      <ItemSpacer gap={10}>
        {forms.sort(sortByCreatedDate).map(form => {
          const mhaFormVersion = getMhaFormVersion(ENV.ENV, form.createdAt);
          const formInfo = Forms[form.formType](mhaFormVersion);
          return (
            <TouchableRipple
              key={form.id}
              style={[styles.container, isWebView ? styles.webWrapper : {}]}
              onPress={() => {
                netInfo.isConnected
                  ? navigation.navigate(RouteKeys.TeamInboxFormViewerScreen, {
                      formType: form.formType,
                      formId: form.id,
                    })
                  : openDialog({
                      heading: "Warning!",
                      message: "To view this form please ensure you have an internet connection.",
                      confirmButton: {
                        label: "OK",
                        onPress: () => closeDialog(),
                      },
                      dismissable: false,
                    });
              }}
            >
              <>
                <View style={styles.flex}>
                  <Text format={TypographyType.RegularBold} color={palette.navy}>
                    {form.patientName}
                  </Text>

                  <View style={styles.subHeading}>
                    <Text format={TypographyType.TinyBold} color={palette.navy}>{`Section ${formInfo.section} `}</Text>
                    <Text
                      format={TypographyType.MicroBold}
                      color={palette.navy}
                      style={styles.formType}
                    >{`(${form.formType})`}</Text>
                  </View>
                </View>

                <View style={[styles.row, styles.flex]}>
                  <View style={styles.flex}>
                    <InfoItemLabel label="Doctor" format={TypographyType.Tiny}>
                      {form.doctorName}
                    </InfoItemLabel>
                  </View>
                  <View style={styles.flex}>
                    <InfoItemLabel label="Examination Date" format={TypographyType.Tiny}>
                      {form.examinationDate.format("DD/MM/YYYY")}
                    </InfoItemLabel>
                  </View>
                  <View style={styles.flex}>
                    <InfoItemLabel label="Signed" format={TypographyType.Tiny}>
                      {dayjs(form.createdAt).format("DD/MM/YYYY HH:mm")}
                    </InfoItemLabel>
                  </View>
                </View>
              </>
            </TouchableRipple>
          );
        })}
      </ItemSpacer>
    </View>
  );
};

export default SubmittedForms;

const styles = StyleSheet.create({
  container: {
    position: "relative",
    paddingHorizontal: spacing[20],
    paddingVertical: spacing[15],
    backgroundColor: palette.white,
    shadowColor: palette.blueShadow,
    borderRadius: 6,
    shadowRadius: 6,
    shadowOpacity: 0.3,
    shadowOffset: { width: 0, height: 0 },
    ...Platform.select({
      web: {
        cursor: "pointer",
      },
    }),
  },

  outterWrapper: {
    paddingBottom: spacing[10],
  },

  webWrapper: {
    flexDirection: "row",
    justifyContent: "center",
  },

  flex: { flex: 1 },

  row: { flexDirection: "row" },

  formType: {
    transform: [{ translateY: -1 }],
  },

  subHeading: {
    flexDirection: "row",
    alignItems: "flex-end",
    marginBottom: spacing[5],
  },
});
