/* Libraries */
import React from "react";
import { View, Linking, StyleSheet, StyleProp, TextStyle, Platform } from "react-native";

/* Models, Types, Props & Utils */
import { TypographyType } from "@/models/Typography";

/* Layouts & Themeing */
import { spacing } from "../../../theme";

/* Custom Components */
import { ContactBox } from "../../InfoBox/InfoBox";
import { ContentWrap } from "../../ContentWrap";
import MiniLabel from "../../MiniLabel";
import Paragraph from "../../Paragraph";
import {
  Phone as SquarePhoneButton,
  Mobile as SquareMobileButton,
  Email as SquareEmailButton,
} from "../../SquareButton";
import Text from "../../Text";
import { CreateContactedDoctorsFunc } from "../../SearchResults/SearchResults.props";
import { GET_ASSESSMENT } from "@/models/Assessment";
import { recordEvent } from "../../../utils/analytics";
import { AnalyticsEvent } from "libs/analytics/events";
import { mqWeb } from "../../../utils/helpers";

import { AvailabilityState } from "libs/types/availability";
import { useRecoilValue } from "recoil";
import { userDetails } from "../../../utils/recoil/index";

export default function Contact(props: {
  notes?: string;
  isAvailable: boolean;
  availabilityState: AvailabilityState;
  phone: string | null;
  phone2: string | null;
  email: string | null;
  textStyle?: StyleProp<TextStyle>;
  addContactedDoctor?: CreateContactedDoctorsFunc;
  assessmentId?: string;
  doctorId: string;
  name?: string;
}) {
  const user = useRecoilValue(userDetails);
  const isWebView = mqWeb();
  const recordContact = (method: string) => {
    if (props.assessmentId && props.addContactedDoctor) {
      props.addContactedDoctor({
        variables: {
          input: {
            assessmentId: props.assessmentId,
            contactedDoctorS12DoctorId: props.doctorId,
            createdAt: new Date().toISOString(),
          },
        },
        refetchQueries: [
          {
            query: GET_ASSESSMENT,
            variables: { id: props.assessmentId },
          },
        ],
      });
      recordEvent(
        AnalyticsEvent.DOCTORS_CONTACTED,
        {
          doctorId: props.doctorId,
          ...(props.email && { doctorEmail: props.email }),
          ...(props.name && { doctorName: props.name }),
          ...(user && {
            amhpId: user.id,
            amhpName: user.name,
            amhpEmail: user.email,
          }),
          doctorAvailability: props.availabilityState,
          assessmentId: props.assessmentId,
          method,
        },
        {
          "Doctors Contacted": 1,
        }
      );
    }
  };

  const bAllowNotes = !!(props.notes && props.notes.trim().length > 0);
  const bAllowPhone = !!(props.phone && props.phone.trim().length > 0);
  const bAllowPhone2 = !!(props.phone2 && props.phone2.trim().length > 0);
  const bAllowEmail = !!(props.email && props.email.trim().length > 0);

  return (
    <ContentWrap>
      <Text format={TypographyType.HeadingMedium} style={props.textStyle}>
        Contact
      </Text>
      {bAllowNotes && (
        <View style={[isWebView ? styles.contactNotesWeb : styles.contactNotes, props.textStyle]}>
          <MiniLabel style={props.textStyle}>Notes</MiniLabel>
          <Paragraph style={props.textStyle}>{props?.notes || "-"}</Paragraph>
        </View>
      )}
      <View style={isWebView && styles.contactBoxesWeb}>
        {bAllowPhone && (
          <ContactBox
            label="Mobile"
            data={props?.phone || "-"}
            icon={<SquareMobileButton />}
            style={isWebView && styles.contactBoxWeb}
            onPress={() => {
              recordContact("phone");
              Linking.openURL(`${Platform.OS === "ios" ? "telprompt:" : "tel:"}${props.phone}`).catch(() => null);
            }}
          />
        )}
        {bAllowPhone2 && (
          <ContactBox
            label="Alternative Phone"
            data={props?.phone2 || "-"}
            icon={<SquarePhoneButton />}
            style={isWebView && styles.contactBoxWeb}
            onPress={() => {
              recordContact("phone");
              Linking.openURL(`${Platform.OS === "ios" ? "telprompt:" : "tel:"}${props.phone2}`).catch(() => null);
            }}
          />
        )}
        {bAllowEmail && (
          <ContactBox
            label="Email Address"
            data={props?.email || "-"}
            lastInList={true}
            icon={<SquareEmailButton />}
            style={isWebView && styles.contactBoxWeb}
            onPress={() => {
              recordContact("email");
              Linking.openURL(`mailto:${props.email}`).catch(() => null);
            }}
          />
        )}
      </View>
    </ContentWrap>
  );
}

const styles = StyleSheet.create({
  contactBoxWeb: {
    width: "calc(100% / 3)",
    borderBottomWidth: 0,
  },

  contactBoxesWeb: {
    flexDirection: "row",
    alignItems: "flex-start",
  },

  contactNotes: {
    marginBottom: spacing[25],
  },
  contactNotesWeb: {
    marginBottom: spacing[5],
  },
});
