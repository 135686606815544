import React from "react";
import { StyleSheet, View } from "react-native";

import { chunkArray, mqWeb } from "../../utils/helpers";
import { spacing } from "../../theme";

import ItemSpacer from "../ItemSpacer";

interface SplitFormInputsProps {
  children: React.ReactNode[];
}

export const SplitFormInputs = (props: SplitFormInputsProps) => {
  const isWebView = mqWeb();

  if (!isWebView) {
    return <ItemSpacer gap={5}>{props.children}</ItemSpacer>;
  }

  const rowsArr = chunkArray(props.children, 2);

  const Rows = rowsArr.map((cols: React.ReactNode[], i: number) => {
    return (
      <View key={i} style={styles.row}>
        {cols.map((col: React.ReactNode, i: number) => {
          /*
            RE: i % 2 === 0 || (styles.col, i % 2 !== 0 && cols[i - 1] === false)
            See details at bottom of file
          */
          return (
            <React.Fragment key={i}>
              {col && (
                <View
                  style={[
                    styles.col,
                    i % 2 === 0 || (styles.col, i % 2 !== 0 && cols[i - 1] === false)
                      ? styles.colLeft
                      : styles.colRight,
                  ]}
                >
                  {col}
                </View>
              )}
            </React.Fragment>
          );
        })}
      </View>
    );
  });

  return <ItemSpacer gap={10}>{Rows}</ItemSpacer>;
};

const styles = StyleSheet.create({
  col: {
    flexBasis: "50%",
  },
  colLeft: { paddingRight: spacing[30] },
  colRight: { paddingLeft: spacing[30] },

  row: {
    flexDirection: "row",
  },
});

/*
  RE: i % 2 === 0 || (styles.col, i % 2 !== 0 && cols[i - 1] === false)
  column will be rendered on the left if the index is even OR if the index is odd but the previous column is false
  this covers off the scenario of rendering a blank column when items passed to SplitFormInputs are conditionally rendered

  E.G:
  const MyComponent = () => {
    isSomethingTrue = useState<boolean>(false)

    return (
      <SplitFormInputs>
        {isSomethingTrue && (
          <Text>I will be `false` inside props.children array if `isSomethineTrue === false`</Text>
        )}
        <Text>I will be the right column when isSomethingTrue === true, and the left column when !isSomethineTrue</Text>
      </SplitFormInputs>
    )
  }
*/
