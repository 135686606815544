import dayjs, { Dayjs } from "dayjs";

export const holidayModelState: StateModelProps = {
  holidayStartDate: dayjs()
    .hour(9)
    .minute(0)
    .second(0)
    .millisecond(1),
  holidayEndDate: dayjs()
    .hour(17)
    .minute(0)
    .second(0)
    .millisecond(1),
  holidayNotes: null,
  holidayVisibility: true,
  startTime: {
    hours: 9,
    minutes: 0,
  },
  endTime: {
    hours: 17,
    minutes: 0,
  },
};

export const holidayControllerState: StateControllerProps = {
  allDayEvent: false,
  resetHolidayForm: false,
  holidayDateError: false,
};

export enum HolidayModelActionTypes {
  SET_HOLIDAY_START_DATE = "SET_HOLIDAY_START_DATE",
  SET_HOLIDAY_END_DATE = "SET_HOLIDAY_END_DATE",
  SET_START_TIME = "SET_START_TIME",
  SET_END_TIME = "SET_END_TIME",
  SET_HOLIDAY_NOTES = "SET_HOLIDAY_NOTES",
  TOGGLE_HOLIDAY_VISIBILITY = "TOGGLE_HOLIDAY_VISIBILITY",
  SET_EDIT_FORM = "SET_EDIT_FORM",
  RESET_HOLIDAY_FORM = "RESET_HOLIDAY_FORM",
}

export enum HolidayControllerActionTypes {
  TOGGLE_ALL_DAY_EVENT = "TOGGLE_ALL_DAY_EVENT",
  RESET_CONTROLLER = "RESET_CONTROLLER",
  SET_EDIT_FORM = "SET_EDIT_FORM",
  TOGGLE_HOLIDAY_DATE_ERROR = "TOGGLE_HOLIDAY_DATE_ERROR",
}

export interface StateModelProps {
  holidayStartDate: Dayjs;
  holidayEndDate: Dayjs;
  holidayNotes: string | null;
  holidayVisibility: boolean;
  startTime: {
    hours: number;
    minutes: number;
  };
  endTime: {
    hours: number;
    minutes: number;
  };
}

export interface StateControllerProps {
  allDayEvent: boolean;
  resetHolidayForm: boolean;
  holidayDateError: boolean;
}

export interface StateProps extends StateModelProps, StateControllerProps {}

export interface StateContext {
  state: StateProps;
  dispatch: ({ type }: { type: string; payload?: any }) => void;
  controllerDispatch: ({ type }: { type: string; payload?: any }) => void;
}
