import { Itemisation } from "libs/types/mhaForms";

export const getItemisation = (itemisation: Itemisation, number: number) => {
  switch (itemisation) {
    case Itemisation.ROMAN:
      return createRomanNumeralFromIndex(number + 1);
    case Itemisation.ALPHABETICAL:
      return createAlphabeticalFromIndex(number);
    default:
      throw new Error("must supply itemisation type");
  }
};

function createRomanNumeralFromIndex(number: number) {
  const numeralCodes = [
    ["", "i", "ii", "iii", "iv", "v", "vi", "vii", "viii", "ix"],
    ["", "x", "xx"],
  ];
  let numeral = "";
  const digits = number.toString().split("").reverse();
  for (let i = 0; i < digits.length; i++) {
    numeral = numeralCodes[i][parseInt(digits[i], 10)] + numeral;
  }
  return numeral;
}

function createAlphabeticalFromIndex(number: number) {
  const letters = "abcdefghijklmnopqrstuvwxyz";
  return letters.slice(number, number + 1);
}
