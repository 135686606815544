import React, { useMemo, useState } from "react";
import { View } from "react-native";
import { TouchableRipple } from "react-native-paper";
import Collapsible from "react-native-collapsible";

import { TableRow as Props, TableCellButton } from "../Table.types";
import css from "./TableRow.css";

import { TypographyType } from "@/models/Typography";
import { palette } from "../../../theme";
import Icon from "../../Icon";
import Text from "../../Text";

interface TableRowProps extends Props {
  index: number;
  columndWidths: string[];
  themeColor: string;
}

const TableRow: React.FC<TableRowProps> = ({ columndWidths, expandableContent, index, items, onPress, themeColor }) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const [isFirstExpansion, setIsFirstExpansion] = useState(true);

  const styles = useMemo(
    () => ({
      expandableContentBG: [css.expandableContentBG, { backgroundColor: themeColor }],
      rowBG: [
        css.rowBG,
        {
          backgroundColor: themeColor,
          opacity: themeColor === palette.aqua ? 0.1 : 0.065,
        },
      ],
    }),
    [themeColor]
  );

  const expandArrow = isCollapsed ? "keyboard-arrow-down" : "keyboard-arrow-up";

  return (
    <TouchableRipple onPress={onPress} rippleColor="rgba(51,51,51,0.05)">
      <>
        <View style={css.rowContent}>
          <View style={css.cells}>
            <Cells items={items} columnWidths={columndWidths} />
          </View>
          {index % 2 === 0 && <View style={styles.rowBG} />}
          {expandableContent && (
            <TouchableRipple
              style={css.expandToggleArrowButton}
              onPress={e => {
                e.stopPropagation();
                setIsCollapsed(!isCollapsed);
                setIsFirstExpansion(false);
              }}
            >
              <>
                <Icon name={expandArrow} color={palette.greyBlue} />
                <View style={css.expandToggleArrowButtonBorder} />
              </>
            </TouchableRipple>
          )}
        </View>
        {expandableContent && (
          <>
            <Collapsible collapsed={isCollapsed} style={css.expandableContent}>
              <>
                {typeof expandableContent === "function" ? !isFirstExpansion && expandableContent() : expandableContent}
                <View style={styles.expandableContentBG} />
              </>
            </Collapsible>
          </>
        )}
      </>
    </TouchableRipple>
  );
};

const Cells = (props: { items: (string | TableCellButton)[]; columnWidths: string[] }) => (
  <>
    {props.items.map((data, i) => {
      const cellText = typeof data === "string" ? data : data.text;
      const textColor = typeof data === "string" ? palette.navy : palette.blue;

      const formattedText = cellText.split("_").map((text, i) =>
        i === 0 ? (
          <Text key={i} format={TypographyType.Small} color={textColor}>
            {text}
          </Text>
        ) : (
          <Text key={i} format={TypographyType.Tiny} color={textColor}>
            {` ${text}`}
          </Text>
        )
      );

      const CellContent =
        typeof data === "string" ? (
          formattedText
        ) : (
          <TouchableRipple onPress={data.onPress} rippleColor="rgba(51,51,51,0.05)">
            <>{formattedText}</>
          </TouchableRipple>
        );

      return (
        <View key={i} style={[css.cell, { flexBasis: props.columnWidths[i] }]}>
          {CellContent}
        </View>
      );
    })}
  </>
);

export default TableRow;
