import React, { useCallback, useEffect, useMemo, useState } from "react";
import { StyleSheet, Platform } from "react-native";

import { TypographyType } from "@/models/Typography";
import { Button, ButtonList } from "../../Button";
import Text from "../../Text";
import { Accordion, AccordionSection } from "../../Accordion";
import { ContentWrap } from "../../ContentWrap";
import { mqWeb } from "../../../utils/helpers";
import { isAmhpForm, isPsuedoAmhpForm, isConveyanceForm } from "libs/mhaForms/helpers";
import { FormListItem, SignedForm, FormType } from "libs/types/mhaForms";
import { FormStatus } from "libs/types/API";

import UnsavedMHAFormRecord from "../../UnsavedMHAFormRecord";

import MHAFormRecordsList from "../../Forms/MHAFormRecord/MHAFormRecordsList/index";
import { offlineMHAForm, userDetails } from "../../../utils/recoil";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  amhpTeamDownloadForms,
  amhpTeamMhaHospitalEmails,
  amhpTeamEmailFormsToSelf,
  amhpTeamEmailFormsToApprovedLists,
  amhpTeamUsesConveyanceForms,
} from "libs/utils/featureFlags";

import { useBottomSheet } from "@/hooks";
import BottomSheets from "../BottomSheets";
import { useNavigation } from "@react-navigation/native";
import { RouteKeys } from "@/navigationv2";
import { recordEvent } from "@/utils/analytics";
import { AnalyticsEvent } from "libs/analytics/events";

interface AssessmentMyFormsProps {
  assessmentId: string;
  hasPatientAddress: boolean;
  createMHAForm: () => void;
  formRecords: FormListItem[];
  amhpTeamFeatures: string;
  refetch: () => void;
  amhpTeamId: string;
  assessmentAmhpId: string;
  patientName: string;
}

enum AMHPFormCategoryIndex {
  Unsaved,
  Drafts,
  ChangesInProgress,
  Signed,
}

const AssessmentMyForms = ({
  assessmentId,
  hasPatientAddress,
  createMHAForm,
  formRecords,
  amhpTeamFeatures,
  refetch,
  amhpTeamId,
  assessmentAmhpId,
  patientName,
}: AssessmentMyFormsProps) => {
  const user = useRecoilValue(userDetails);
  const isWebView = mqWeb();
  const [offlineForm, setOfflineForm] = useRecoilState(offlineMHAForm);
  const TopContentWrapper = useMemo(() => (isWebView ? React.Fragment : ContentWrap), [isWebView]);
  const navigation = useNavigation();

  const draftFormRecords = formRecords
    .filter(fr => isPsuedoAmhpForm(fr.formType))
    .filter(fr => [FormStatus.s10_draft].includes(fr.status));

  const changesInProgressFormRecords = formRecords
    .filter(fr => isPsuedoAmhpForm(fr.formType))
    .filter(fr => fr.status === FormStatus.s30_changes_in_progress);

  const signedFormRecords = formRecords
    .filter(fr => isPsuedoAmhpForm(fr.formType))
    .filter(fr => [FormStatus.s40_signed, FormStatus.s50_signed_and_sent].includes(fr.status));

  // Memo this because it is used in a useEffect dependency.
  const allAMHPAndDoctorSignedForms: Array<SignedForm> = useMemo(
    () =>
      formRecords
        .filter(fr => [FormStatus.s40_signed, FormStatus.s50_signed_and_sent].includes(fr.status))
        .map(({ id, formType, signed, createdAt, expired }) => ({
          id,
          formType,
          signed,
          createdAt,
          patientName,
          expired,
        })),
    [formRecords]
  );

  // TODO: review how we choose to display hospital emails
  const { openBottomSheet, closeBottomSheet } = useBottomSheet();
  const [emailOrExportFormsOption, setEmailOrExportFormsOption] = useState<"email" | "download" | null>(null);
  useEffect(() => {
    if (emailOrExportFormsOption) {
      openBottomSheet({
        type: "generic",
        data: {
          heading: emailOrExportFormsOption === "email" ? "Email Forms" : "Download Forms",
          component: BottomSheets.EmailOrExportForms,
          componentProps: {
            type: emailOrExportFormsOption,
            signedForms: allAMHPAndDoctorSignedForms,
            amhpTeamFeatures,
            amhpTeamId,
            assessmentId,
            resetBottomSheet: () => {
              refetch();
              setEmailOrExportFormsOption(null);
              closeBottomSheet();
            },
          },
          onDismiss: () => setEmailOrExportFormsOption(null),
        },
      });
    }
  }, [emailOrExportFormsOption, allAMHPAndDoctorSignedForms, amhpTeamFeatures, amhpTeamId, assessmentId]);

  const hasEmailToHospitalFeature = amhpTeamMhaHospitalEmails(amhpTeamFeatures);
  const hasEmailToSelfFeature = amhpTeamEmailFormsToSelf(amhpTeamFeatures);
  const hasEmailToListsFeature = amhpTeamEmailFormsToApprovedLists(amhpTeamFeatures);
  const hasDownloadFeature = Platform.OS === "web" && amhpTeamDownloadForms(amhpTeamFeatures);
  const hasCreatedAmhpForm = formRecords.filter(fr => isAmhpForm(fr.formType)).length > 0;
  const hasCreatedConveyanceForm = formRecords.filter(fr => fr.formType === FormType.AUTHORITY_TO_CONVEY).length > 0;
  const shouldShowConveyanceButton = amhpTeamUsesConveyanceForms(amhpTeamFeatures);

  const createConveyanceForm = () => {
    recordEvent(AnalyticsEvent.MHA_FORM_AMHP_CREATED, {
      amhpTeamId,
      assessmentId,
      formType: FormType.AUTHORITY_TO_CONVEY,
    });
    navigation.navigate(RouteKeys.MHAFormScreen, {
      formType: FormType.AUTHORITY_TO_CONVEY,
      assessmentId: assessmentId,
    });
  };
  const isTraineeAmhp = !!user?.isTraineeAmhp;

  return (
    <>
      <TopContentWrapper>
        <Text format={TypographyType.HeadingMedium}>AMHP Forms</Text>
        {isTraineeAmhp ? (
          <Text format={TypographyType.Regular}>
            You have been set up as a trainee AMHP and users with this setting are unable to create or sign
            applications. Please get in touch with our Support Team if you have been set up incorrectly.
          </Text>
        ) : !hasPatientAddress ? (
          <Text format={TypographyType.Regular}>
            To create an MHA form, please edit this assessment and add the patient’s address (to be used for all MHA
            forms for this assessment).
          </Text>
        ) : (
          <Text format={TypographyType.Regular}>Review and manage your MHA statutory forms for this assessment.</Text>
        )}

        <ButtonList>
          {(hasEmailToHospitalFeature || hasEmailToSelfFeature || hasEmailToListsFeature) &&
            !isTraineeAmhp &&
            !!signedFormRecords.length && (
              <Button
                onPress={() => setEmailOrExportFormsOption("email")}
                mode={"outlined"}
                marginBottom={0}
                style={styles.button}
              >
                Email Signed Forms
              </Button>
            )}
          {hasDownloadFeature && !!signedFormRecords.length && !isTraineeAmhp && (
            <Button
              onPress={() => setEmailOrExportFormsOption("download")}
              mode={"outlined"}
              marginBottom={0}
              style={styles.button}
            >
              Download Signed Forms
            </Button>
          )}
          {!isTraineeAmhp && (
            <>
              <Button
                onPress={createMHAForm}
                marginBottom={0}
                style={styles.button}
                disabled={hasCreatedAmhpForm || !hasPatientAddress}
                disabledInfoText={
                  !hasPatientAddress
                    ? "Edit Assessment to add patient address to create forms"
                    : "You have already created an AMHP MHA Form"
                }
              >
                Create MHA Form
              </Button>
              {shouldShowConveyanceButton && (
                <Button
                  onPress={createConveyanceForm}
                  marginTop={10}
                  style={styles.button}
                  mode="outlined"
                  disabled={hasCreatedConveyanceForm || !hasPatientAddress}
                  disabledInfoText={
                    !hasPatientAddress
                      ? "Edit Assessment to add patient address to create forms"
                      : "You have already created an Authority to convey Form"
                  }
                >
                  Create Conveyance Form
                </Button>
              )}
            </>
          )}
        </ButtonList>
      </TopContentWrapper>
      <Accordion
        marginTop={30}
        defaultActiveIndex={
          offlineForm
            ? AMHPFormCategoryIndex.Unsaved
            : draftFormRecords.length
            ? AMHPFormCategoryIndex.Drafts
            : changesInProgressFormRecords.length
            ? AMHPFormCategoryIndex.ChangesInProgress
            : signedFormRecords.length
            ? AMHPFormCategoryIndex.Signed
            : -1
        }
      >
        {offlineForm ? (
          <AccordionSection index={AMHPFormCategoryIndex.Unsaved} heading="Unsaved" itemCount={1}>
            <UnsavedMHAFormRecord
              offlineForm={offlineForm}
              assessmentId={assessmentId}
              clearOfflineForm={() => setOfflineForm(null)}
            />
          </AccordionSection>
        ) : (
          <AccordionSection
            index={AMHPFormCategoryIndex.Drafts}
            heading="Drafts"
            itemCount={draftFormRecords.length}
            disabled={!draftFormRecords.length}
          >
            <MHAFormRecordsList
              formRecords={draftFormRecords}
              assessmentId={assessmentId}
              refetch={refetch}
              isAmhpForm={true}
              user={user}
              isAssessmentAmhp={assessmentAmhpId === user?.id}
            />
          </AccordionSection>
        )}
        <AccordionSection
          index={AMHPFormCategoryIndex.ChangesInProgress}
          heading="Changes in Progress"
          itemCount={changesInProgressFormRecords.length}
          disabled={!changesInProgressFormRecords.length}
        >
          <MHAFormRecordsList
            formRecords={changesInProgressFormRecords}
            assessmentId={assessmentId}
            refetch={refetch}
            isAmhpForm={true}
            user={user}
            isAssessmentAmhp={assessmentAmhpId === user?.id}
          />
        </AccordionSection>
        <AccordionSection
          index={AMHPFormCategoryIndex.Signed}
          heading="Signed"
          itemCount={signedFormRecords.filter(fr => isAmhpForm(fr.formType) || isConveyanceForm(fr.formType)).length}
          disabled={!signedFormRecords.filter(fr => isAmhpForm(fr.formType) || isConveyanceForm(fr.formType)).length}
        >
          <MHAFormRecordsList
            formRecords={signedFormRecords}
            assessmentId={assessmentId}
            refetch={refetch}
            isAmhpForm={true}
            user={user}
            isAssessmentAmhp={assessmentAmhpId === user?.id}
          />
        </AccordionSection>
      </Accordion>
    </>
  );
};

const styles = StyleSheet.create({
  button: { width: "100%" },
});

export default AssessmentMyForms;
