import React, { ReactNode, useMemo } from "react";
import { StyleSheet, View } from "react-native";

import { formatLongDate } from "libs/dates";

import { ContentWrap, Text } from "@/components";
import { TypographyType } from "@/models/Typography";
import { palette, spacing } from "@/theme";
import { Button } from "@/components/Button";
import { mqWeb } from "@/utils/helpers";

import { VisitResult } from "./VisitResult";
import { VisitResultItem } from "./VisitResultItem.props";
import { CardContainer } from "../Card";
import { FilterLabel } from "../FilterLabel";
import { SectionHeading } from "../SectionHeading";
import { useExportVisits } from "@/components/ExportCSV";

const visitCards = (visits: VisitResultItem[]) => {
  return visits.map((visit: VisitResultItem) => (
    <React.Fragment key={visit.id}>
      <VisitResult visit={visit} />
    </React.Fragment>
  ));
};

interface PropTypes {
  username: string;
  loadMore?: ReactNode;
  myVisits: VisitResultItem[];
}

export const VisitResults = (props: PropTypes) => {
  const { myVisits, loadMore } = props;
  const displayExportVisits = useExportVisits();

  const { Small } = TypographyType;
  const VisitCardsComponent = useMemo(() => visitCards(myVisits), [myVisits]);
  const isWebView = mqWeb();

  return (
    <>
      <ContentWrap>
        <SectionHeading heading="My Visits" webText={formatLongDate()} />
        <View style={styles.row}>
          <View style={styles.flex}>
            <FilterLabel text="Sorted by time created" />
          </View>
          {isWebView && (
            <Button
              onPress={displayExportVisits}
              style={{ marginTop: spacing[10], marginBottom: spacing[15], alignSelf: "flex-end" }}
            >
              Export Visits
            </Button>
          )}
        </View>

        {myVisits && myVisits.length === 0 && (
          <Text format={Small} style={styles.noAssessmentsText}>
            You have no open assessments.
          </Text>
        )}
      </ContentWrap>

      {myVisits && myVisits.length >= 1 && <CardContainer>{VisitCardsComponent}</CardContainer>}
      {loadMore}
    </>
  );
};

const styles = StyleSheet.create({
  row: { flexDirection: "row" },
  flex: { flex: 1 },
  noAssessmentsText: {
    color: palette.slate,
  },
});

export default React.memo(VisitResults);
