/**
 * Display the individual MHA form record in the accordion list.
 */
import React, { useState } from "react";
import { View, StyleSheet, Platform, TouchableOpacity } from "react-native";

import { TypographyType } from "@/models/Typography";
import { MHAFormRecordHeading } from "./MHAFormRecordHeading";
import { MHAFormRecordHistory } from "./MHAFormRecordHistory";
import Text from "../../Text";
import { palette, spacing } from "../../../theme";
import { InfoItemLabel } from "@/components/InfoItem";
import Icon, { IconName } from "@/components/Icon";
import Collapsible from "react-native-collapsible";

export type MHAFormRecordCtaButtons = Array<{ label: string; onClick(): void }>;

export function MHAFormRecord(props: {
  formTitle?: string;
  formName: string;
  historyHeader?: string;
  historyElements?: string[];
  topSpacing?: boolean;
  lastElement?: boolean;
  isArchived: boolean;
  ctaButtons?: MHAFormRecordCtaButtons;
  amhpTeamName?: string;
  examinationDate?: string;
  patientName?: string;
  amhpTeam?: any;
}) {
  const {
    formTitle,
    formName,
    historyHeader = "",
    historyElements = [],
    topSpacing = true,
    lastElement = false,
    ctaButtons,
    isArchived,
    amhpTeamName,
    examinationDate,
    patientName,
    amhpTeam,
  } = props;
  const [hideDiaryHelperText, setHideDiaryHelperText] = useState<boolean>(true);
  const _onInfoToggleButtonPress = () => setHideDiaryHelperText(!hideDiaryHelperText);

  return (
    <View
      style={[
        styles.bottomSpacing,
        topSpacing && styles.wrapperTopSpacing,
        !lastElement && styles.wrapperBottomBorder,
        ctaButtons && lastElement && styles.noBottomSpacing,
      ]}
    >
      <Text format={TypographyType.RegularBold} color={palette.navy}>
        {patientName}
      </Text>
      <MHAFormRecordHeading formName={formName} formTitle={formTitle} />

      {/* Display the team name and the examination date if passed in and present */}
      {(amhpTeamName || examinationDate) && (
        <View style={[amhpTeam ? styles.row1 : styles.row, styles.flex]}>
          {amhpTeamName ? <AmhpTeamNameContainer amhpTeamName={amhpTeamName} /> : null}
          {examinationDate ? <ExaminationDateContainer examinationDate={examinationDate} /> : null}
        </View>
      )}

      {/* Contact Details MHA Inbox Instructions */}
      {amhpTeam && (
        <>
          <TouchableOpacity onPress={_onInfoToggleButtonPress} style={styles.diaryHeadingText}>
            <Text format={TypographyType.MicroBold} color={palette.blue}>
              Contact Details
            </Text>
            <View style={styles.infoToggleButton}>
              <Icon
                name={hideDiaryHelperText ? IconName.info_outline : IconName.close}
                size={16}
                color={palette.blue}
              />
            </View>
          </TouchableOpacity>
          <Collapsible collapsed={hideDiaryHelperText}>
            <Text format={TypographyType.Tiny} color={palette.slate}>
              {amhpTeam.mhaInboxInstructions}
            </Text>
          </Collapsible>
        </>
      )}

      {/* Display history if the object of data is given */}
      {historyElements && !!historyElements.length && (
        <MHAFormRecordHistory historyHeader={historyHeader} items={historyElements} />
      )}
      {!isArchived && ctaButtons && Array.isArray(ctaButtons) && !!ctaButtons.length && (
        <ButtonContainer>
          {ctaButtons.map((cta, i) => (
            <CTAButton key={i} label={cta.label} onClick={cta.onClick} lastItem={i === ctaButtons.length - 1} />
          ))}
        </ButtonContainer>
      )}
      {isArchived && <Text format={TypographyType.Micro}>Form no longer available</Text>}
    </View>
  );
}

const AmhpTeamNameContainer = (props: { amhpTeamName: string | undefined }) => (
  <>
    {props.amhpTeamName && (
      <View style={styles.flex}>
        <InfoItemLabel label="AMHP Team" format={TypographyType.Tiny}>
          {props.amhpTeamName}
        </InfoItemLabel>
      </View>
    )}
  </>
);

const ExaminationDateContainer = (props: { examinationDate: string | undefined }) => (
  <>
    {props.examinationDate && (
      <View style={styles.flex}>
        <InfoItemLabel label="Examination Date" format={TypographyType.Tiny}>
          {props.examinationDate}
        </InfoItemLabel>
      </View>
    )}
  </>
);

const ButtonContainer = (props: { children: React.ReactNode }) => (
  <View style={styles.buttonContainer}>{props.children}</View>
);

const CTAButton = (props: { onClick: () => void; label: string; lastItem: boolean }) => (
  <TouchableOpacity onPress={props.onClick} style={[styles.ctaButton, props.lastItem && styles.ctaButtonLastItem]}>
    <Text format={TypographyType.SmallBold} color={palette.blue}>
      {props.label}
    </Text>
  </TouchableOpacity>
);

const styles = StyleSheet.create({
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    marginTop: spacing[15],
    transform: [{ translateX: -15 }],
  },

  ctaButton: {
    alignSelf: "flex-start",
    borderColor: palette.grey,
    borderRightWidth: 2,
    paddingHorizontal: spacing[15],
    ...Platform.select({
      web: {
        cursor: "pointer",
      },
    }),
  },
  ctaButtonLastItem: {
    borderRightWidth: 0,
    paddingRight: 0,
  },

  wrapperTopSpacing: { paddingTop: spacing[20] },
  wrapperBottomBorder: {
    borderBottomColor: palette.grey,
    borderBottomWidth: 1,
  },
  bottomSpacing: {
    paddingBottom: spacing[20],
  },
  noBottomSpacing: {
    // marginBottom: -30,
  },

  flex: { flex: 1 },
  row: { flexDirection: "row", paddingBottom: spacing[30] },
  row1: { flexDirection: "row", paddingBottom: spacing[10] },
  infoToggleButton: {
    padding: spacing[5],
    borderRadius: 44,
    marginBottom: 2,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: palette.blueSecondary,
    transform: [{ translateY: 2 }],
  },
  diaryHeadingText: {
    flexDirection: "row",
    alignItems: "center",
  },
});
