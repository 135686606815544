export interface BaseSchema {
  required?: boolean;
  requiredMessage?: string;
}

/**
 * Merge multiple Yup schemas into a single schema.
 *
 * @param schemas
 * @todo Fix the any[] type. It really should take a dynamic Yup schema type and
 *       return that, but can I for the life of me get it all to match up
 *       correctly? No. No is the answer.
 */
export function mergeSchemas(...schemas: any[]): any {
  const [first, ...rest] = schemas;

  return rest.reduce((mergedSchemas, schema) => mergedSchemas.concat(schema), first);
}
