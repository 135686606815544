import React from "react";
import { createStackNavigator } from "@react-navigation/stack";

import screenOptions from "@/navigationv2/config/screenOptions";
import { CCGUnassignedClaimsRoutes } from "@/navigationv2/types";
import * as Screens from "@/screens";
import TabIcon from "../TabIcon";

const UnassignedStack = createStackNavigator<CCGUnassignedClaimsRoutes>();

const CCGUnassignedClaimsStack = () => {
  return (
    <UnassignedStack.Navigator initialRouteName="UnassignedClaimsScreen" screenOptions={screenOptions}>
      <UnassignedStack.Screen
        name="UnassignedClaimsScreen"
        component={Screens.UnassignedClaimsScreen}
        options={Screens.UnassignedClaimsScreen.screenOptions}
      />
      <UnassignedStack.Screen
        name="ClaimProcessingDetailsScreen"
        component={Screens.ClaimProcessingDetailsScreen}
        options={Screens.ClaimProcessingDetailsScreen.screenOptions}
      />
      <UnassignedStack.Screen
        name="ClaimProcessingSearchScreen"
        component={Screens.ClaimProcessingSearchScreen}
        options={Screens.ClaimProcessingSearchScreen.screenOptions}
      />
    </UnassignedStack.Navigator>
  );
};

CCGUnassignedClaimsStack.tabOptions = {
  tabBarIcon: TabIcon("inbox"),
  tabBarLabel: "Unassigned",
};

export default CCGUnassignedClaimsStack;
