import React, { useMemo } from "react";
import { TouchableOpacityProps, StyleSheet, TouchableOpacity, ViewStyle } from "react-native";

import { TypographyType } from "@/models/Typography";
import { palette, spacing } from "@/theme";

import Text from "@/components/Text";

interface WebSectionButtonProps extends TouchableOpacityProps {
  label: string;
  theme: "primary" | "secondary";
}
export const WebSectionButton = (props: WebSectionButtonProps): JSX.Element => {
  const { label, theme, ...restProps } = props;
  const buttonStyles = useMemo(() => (theme === "primary" ? styles.buttonPrimary : styles.buttonSecondary), [theme]);
  return (
    <TouchableOpacity {...restProps} style={buttonStyles}>
      <Text
        format={theme === "primary" ? TypographyType.Small : TypographyType.SmallBold}
        color={theme === "primary" ? palette.white : palette.blue}
      >
        {label}
      </Text>
    </TouchableOpacity>
  );
};

const defaultStyles: ViewStyle = {
  paddingVertical: spacing[10],
  paddingHorizontal: spacing[15],
  borderRadius: 8,
};

const styles = StyleSheet.create({
  buttonPrimary: {
    ...defaultStyles,
    backgroundColor: palette.blue,
  },

  buttonSecondary: {
    ...defaultStyles,
    backgroundColor: palette.blueSecondary,
  },
});
