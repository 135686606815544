import React, { Dispatch, SetStateAction } from "react";
import { StyleSheet, View } from "react-native";

import Text from "../../Text";
import { MHAFormMultiSelect, MHAFormMultiSelectOption } from "../../Forms/MHAFormMultiSelect";
import { InfoItemLabel } from "../../InfoItem";

import { TypographyType } from "@/models/Typography";
import { FormType } from "libs/types/mhaForms";
import { Forms } from "libs/mhaForms/formSections";
import { palette, spacing } from "../../../theme";
import { mqWeb } from "../../../utils/helpers";
import { Dayjs } from "dayjs";
import { getMhaFormVersion } from "libs/mhaForms/helpers";
import ENV from "@/config";

interface SubmittedFormsMultiSelectProps {
  forms: {
    id: string;
    formType: FormType;
    patientName: string;
    doctorName: string;
    examinationDate: Dayjs;
    createdAt: string;
  }[];
  selectedForms: string[];
  setSelectedForms: Dispatch<SetStateAction<string[]>>;
}

const SubmittedFormsMultiSelect: React.FC<SubmittedFormsMultiSelectProps> = ({
  forms,
  selectedForms,
  setSelectedForms,
}) => {
  const isWebView = mqWeb();
  return (
    <MHAFormMultiSelect>
      {forms.map(form => {
        const checked = selectedForms.includes(form.id);
        const mhaFormVersion = getMhaFormVersion(ENV.ENV, form.createdAt);
        const formInfo = Forms[form.formType](mhaFormVersion);

        return (
          <MHAFormMultiSelectOption
            key={form.id}
            checked={checked}
            onPress={() => {
              if (selectedForms.includes(form.id)) {
                setSelectedForms(selectedForms.filter(formId => formId !== form.id));
              } else {
                setSelectedForms([...selectedForms, form.id]);
              }
            }}
          >
            <View style={isWebView ? styles.webWrapper : {}}>
              <View style={styles.flex}>
                <Text format={TypographyType.RegularBold} color={checked ? palette.blue : palette.navy}>
                  {form.patientName}
                </Text>

                <View style={styles.subHeading}>
                  <Text
                    format={TypographyType.TinyBold}
                    color={checked ? palette.blue : palette.navy}
                  >{`Section ${formInfo.section} `}</Text>
                  <Text
                    format={TypographyType.MicroBold}
                    color={checked ? palette.blue : palette.navy}
                    style={styles.formType}
                  >{`(${form.formType})`}</Text>
                </View>
              </View>

              <View style={[styles.row, styles.flex]}>
                <View style={styles.flex}>
                  <InfoItemLabel label="Doctor" format={TypographyType.Tiny}>
                    {form.doctorName}
                  </InfoItemLabel>
                </View>
                <View style={styles.flex}>
                  <InfoItemLabel label="Examination Date" format={TypographyType.Tiny}>
                    {form.examinationDate.format("DD/MM/YYYY")}
                  </InfoItemLabel>
                </View>
              </View>
            </View>
          </MHAFormMultiSelectOption>
        );
      })}
    </MHAFormMultiSelect>
  );
};

export default SubmittedFormsMultiSelect;

const styles = StyleSheet.create({
  webWrapper: {
    flexDirection: "row",
    justifyContent: "center",
  },

  flex: { flex: 1 },

  row: { flexDirection: "row" },

  formType: {
    transform: [{ translateY: -1 }],
  },

  subHeading: {
    flexDirection: "row",
    alignItems: "flex-end",
    marginBottom: spacing[5],
  },
});
