import gql from "graphql-tag";

export const CREATE_HOLIDAYS = gql`
  mutation CreateHoliday($input: CreateHolidayInput!) {
    createHoliday(input: $input) {
      id
    }
  }
`;

export const UPDATE_HOLIDAYS = gql`
  mutation UpdateHoliday($input: UpdateHolidayInput!) {
    updateHoliday(input: $input) {
      id
    }
  }
`;

export const DELETE_HOLIDAYS = gql`
  mutation DeleteHoliday($input: DeleteHolidayInput!) {
    deleteHoliday(input: $input) {
      id
    }
  }
`;
