import React, { ReactNode, useMemo } from "react";
import { Platform, StyleSheet, StatusBar, View } from "react-native";
import { Provider as ReactNativePaperProvider } from "react-native-paper";
import { useRecoilState } from "recoil";
import { Auth } from "@aws-amplify/auth";

import { buildApolloClient } from "@/apollo";
import CustomApolloProvider from "@/apollo/Provider";
import { OfflineIndicator } from "@/components";
import { BottomSheet, Dialog } from "@/components/#global";
import AutoLogoutWeb from "@/components/AutoLogoutWeb";
import GlobalModal from "@/components/Modal/GlobalModal";
import Snackbar from "@/components/Snackbar/Snackbar";
import { useIsDemoEnvironment } from "@/hooks";
import baseTheme, { color } from "@/theme";
import {
  lastException as recoilLastException,
  snackbarMessage as recoilSnackbarMessage,
  userDetails,
} from "@/utils/recoil/index";
import { useOnAuthStateChange } from "./hooks";

import PrivacyScreen from "./PrivacyScreen";

interface PropTypes {
  children: ReactNode;
}

const GlobalContainer = (props: PropTypes) => {
  const { children } = props;
  const isDemoEnv = useIsDemoEnvironment();
  const [lastException, setLastException] = useRecoilState(recoilLastException);
  const [snackbarMessage, setMessage] = useRecoilState(recoilSnackbarMessage);
  const [user, setUserDetails] = useRecoilState(userDetails);
  const enableAutologoutWeb = user && Platform.OS === "web";

  const apolloClient = useMemo(() => {
    return buildApolloClient(setUserDetails, isDemoEnv);
  }, [isDemoEnv, setUserDetails]);

  const onSnackbarClear = () => {
    setMessage("");
    setLastException(null);
  };

  useOnAuthStateChange(apolloClient);

  return (
    <CustomApolloProvider client={apolloClient}>
      <ReactNativePaperProvider theme={baseTheme}>
        {Platform.OS === "android" && (
          <View style={styles.statusBar}>
            <StatusBar translucent backgroundColor={color.primary} {...props} />
          </View>
        )}

        {children}

        <Snackbar clear={onSnackbarClear} isError={lastException !== null}>
          {(lastException && lastException.message) || snackbarMessage}
        </Snackbar>
        <OfflineIndicator />
        <GlobalModal />
        <BottomSheet />
        {enableAutologoutWeb && <AutoLogoutWeb onLogout={() => Auth.signOut()} />}
        <Dialog />
        <PrivacyScreen />
      </ReactNativePaperProvider>
    </CustomApolloProvider>
  );
};

const styles = StyleSheet.create({
  statusBar: {
    height: StatusBar.currentHeight,
  },
});

export default GlobalContainer;
