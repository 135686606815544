import React, { useState, useCallback, useEffect } from "react";
import { StyleSheet, TouchableOpacity } from "react-native";
import { useDebounce } from "use-debounce";

import { LocationNameInput } from "libs/types/API";

import { MHALocationSearchItem, RetrieveMHADataObject } from "./MHALocationSearch.props";
import MHASearchInput from "../MHALocationInput/MHASearchInput";

import { LocationSuggestion } from "../../../LocationSearch/LocationSuggestion";
import Text from "../../../Text";

import { S12Error } from "@/models/Error";
import { TypographyType } from "@/models/Typography";
import { color, palette, spacing } from "../../../../theme";

interface MHALocationSearchPropTypes {
  label: string;
  inputText: string;
  retrieveData(data: RetrieveMHADataObject): void;
  getResults: (term?: string, addressId?: string) => Promise<MHALocationSearchItem[]>;
  patientAddress?: boolean;
  initialResults?: MHALocationSearchItem[];
  disableStrictPostcodeValidation?: boolean;
}

export function MHALocationSearch(props: MHALocationSearchPropTypes) {
  const {
    label,
    inputText,
    initialResults = [],
    disableStrictPostcodeValidation,
    retrieveData,
    getResults,
    patientAddress,
  } = props;
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [resultsLength, setResultsLength] = useState<number | null>(null);
  const [searchQuery, setSearchQuery] = useState(inputText);
  const [searchResults, setSearchResults] = useState<MHALocationSearchItem[]>(
    initialResults.map(r => ({ ...r, selected: true }))
  );
  const [showInstruction, setShowInstruction] = useState<boolean>(false);
  const [debouncedSearchQuery] = useDebounce(searchQuery, 500);

  const onResultPress = useCallback((result, i?: number) => {
    if ((result.isExpandable || !result.selected) && result.itemText) {
      setShowInstruction(result.isExpandable || false);
      search(null, result.id);
    } else if (result.selected) {
      retrieveData({
        locationName: result.locationName as LocationNameInput,
      });
    }
  }, []);

  const search = useCallback(
    (term: string | null, id?: string) => {
      if (!term && !id && (!debouncedSearchQuery || debouncedSearchQuery.length < 4)) {
        setSearchResults(initialResults);
        return;
      }

      setResultsLength(null);
      getResults(id ? undefined : term || debouncedSearchQuery, id)
        .then(data => {
          setErrorMessage(null);
          setSearchResults(data);
          setResultsLength(data.length);
          if (data.length === 1 && data[0].selected) {
            retrieveData({
              locationName: data[0].locationName as LocationNameInput,
            });
          }
        })
        .catch((e: Error | S12Error) => setErrorMessage(e instanceof S12Error ? e.message : "Error finding addresses"));
    },
    [debouncedSearchQuery, retrieveData]
  );

  useEffect(
    function setInitialResults() {
      return setSearchResults(
        initialResults.map(result => {
          return { ...result, selected: true };
        })
      );
    },
    [initialResults]
  );

  useEffect(
    function perFormSearch() {
      search(debouncedSearchQuery);
    },
    [debouncedSearchQuery]
  );

  useEffect(
    function resetResultsOnValueChange() {
      setSearchResults([]);
    },
    [searchQuery]
  );

  return (
    <MHASearchInput
      label={label}
      inputText={searchQuery}
      patientAddress={patientAddress}
      errorMessage={errorMessage}
      disableStrictPostcodeValidation={disableStrictPostcodeValidation}
      search={() => search(searchQuery)}
      onChangeText={val => {
        setSearchQuery(val);
      }}
      setCustomLocation={props.retrieveData}
      onClear={() => {
        setSearchQuery("");
        setSearchResults(initialResults);
      }}
    >
      <>
        {showInstruction && searchResults.length > 0 && (
          <Text format={TypographyType.Regular} type="paper">
            You must pick an address below
          </Text>
        )}

        {searchQuery.length > 0 && searchResults.length === 0 && (
          <Text format={TypographyType.Regular} align="center" marginTop={spacing[60]} color={palette.greyBlue}>
            {resultsLength === 0 ? "No results found" : "Searching . . ."}
          </Text>
        )}
        {searchResults.map((s: MHALocationSearchItem, i: number) => (
          <TouchableOpacity key={i} activeOpacity={0.6} style={styles.rowBorder} onPress={() => onResultPress(s, i)}>
            <LocationSuggestion suggestion={s} />
          </TouchableOpacity>
        ))}
      </>
    </MHASearchInput>
  );
}

const styles = StyleSheet.create({
  rowBorder: {
    borderBottomWidth: 1,
    borderBottomColor: color.thinBorder,
  },
});
