import React from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import { FAB } from "react-native-paper";

import { AvailabilityType, AvailabilityState } from "libs/types/availability";
import { LocationInput } from "libs/types/API";
import { RouteKeys } from "@/navigationv2";
import { color, convertBorder, convertColor } from "../../theme";
import Icon from "../Icon";
import { useSetRecoilState } from "recoil";
import { mapCriteria } from "../../utils/recoil/index";
import { ParamListBase, useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { callbackWithThenable } from "@/utils/helpers";

export default function CircleButton(props: {
  onPress?: () => void;
  color: string;
  icon: string;
  disabled?: boolean;
  style?: StyleProp<ViewStyle>;
  fabStyle?: StyleProp<ViewStyle>;
  iconColor?: string;
}) {
  return (
    <View
      style={[
        styles.border,
        {
          borderColor: props.disabled ? color.disabledBorder : convertBorder(props.color),
        },
        props.style,
      ]}
    >
      <FAB
        disabled={props.disabled}
        style={[
          styles.container,
          {
            backgroundColor: props.disabled ? color.disabled : convertColor(props.color),
          },
          props.fabStyle,
        ]}
        onPress={props.onPress}
        icon={() => <Icon name={props.icon} color={props.iconColor || "white"} size={24} style={styles.icon} />}
      />
    </View>
  );
}

export const Calendar = (props: { onPress: () => void; disabled?: boolean }) => (
  <CircleButton onPress={props.onPress} icon="today" color="primary" disabled={props.disabled} />
);

export const Message = (props: { onPress: () => void; disabled?: boolean; isAvailable?: boolean }) => (
  <CircleButton
    onPress={props.onPress}
    icon="chat"
    color={props.isAvailable ? "available" : "unavailable"}
    disabled={props.disabled}
  />
);

export const Profile = (props: { onPress: () => void; disabled?: boolean }) => (
  <CircleButton onPress={props.onPress} icon="person" color="available" disabled={props.disabled} />
);

export const Phone = (props: { onPress: () => void; disabled?: boolean; isAvailable?: boolean }) => (
  <CircleButton
    onPress={props.onPress}
    icon="call"
    color={props.isAvailable ? "available" : "unavailable"}
    disabled={props.disabled}
  />
);

export const Mobile = (props: { onPress: () => void; disabled?: boolean; isAvailable?: boolean }) => (
  <CircleButton
    onPress={props.onPress}
    icon="smartphone"
    color={props.isAvailable ? "available" : "unavailable"}
    disabled={props.disabled}
  />
);

export const EMail = (props: { onPress: () => void; disabled?: boolean; isAvailable?: boolean }) => (
  <CircleButton
    onPress={props.onPress}
    icon="send"
    color={props.isAvailable ? "available" : "unavailable"}
    disabled={props.disabled}
  />
);

interface MapPropTypes {
  location: LocationInput;
  id?: string;
  distance?: number;
  doctorLocation?: LocationInput;
  disabled?: boolean;
  isAvailable?: boolean;
  type?: AvailabilityType | AvailabilityState;
  single?: boolean;
  timeSpan?: { start: Date; end: Date };
  assessmentDoctorIds?: string[];
}

export const Map = (props: MapPropTypes) => {
  const setMapCriteria = useSetRecoilState(mapCriteria);

  const asyncMapCriteriaUpdate = callbackWithThenable(
    () => {
      const params = !props.single
        ? {
            assessmentId: props.id || "",
            locationLat: props.location.lat,
            locationLon: props.location.lon,
            distance: props.distance && props.distance,
            startTime: props.timeSpan && props.timeSpan.start.toISOString(),
            endTime: props.timeSpan && props.timeSpan.end.toISOString(),
            assessmentDoctorIds: props.assessmentDoctorIds,
          }
        : {
            assessmentId: props.id || "",
            locationLat: props.location.lat || 0,
            locationLon: props.location.lon || 0,
            markerLat: (props.doctorLocation && props.doctorLocation.lat) || 0,
            markerLon: (props.doctorLocation && props.doctorLocation.lon) || 0,
            type: props.type,
            single: props.single,
            startTime: props.timeSpan && props.timeSpan.start.toISOString(),
            endTime: props.timeSpan && props.timeSpan.end.toISOString(),
          };
      setMapCriteria(params);
    },
    [props],
    () => {
      const navigation = useNavigation<StackNavigationProp<ParamListBase>>();
      !props.disabled && navigation.navigate(RouteKeys.MapScreen);
    }
  );

  return (
    <CircleButton
      onPress={() => asyncMapCriteriaUpdate()}
      disabled={props.disabled}
      icon="map"
      color={props.isAvailable ? "available" : "unavailable"}
    />
  );
};

export const AddDoctor = (props: {
  addDoctorToAssessment?: () => Promise<string>;
  isAddedToAssessment?: boolean;
  disabled?: boolean;
  style?: StyleProp<ViewStyle>;
}) => {
  return (
    <CircleButton
      onPress={() => props.addDoctorToAssessment && props.addDoctorToAssessment()}
      style={[styles.fabOuter, props.style]}
      fabStyle={styles.fab}
      disabled={props.isAddedToAssessment || props.disabled}
      icon={props.isAddedToAssessment ? "person" : "person-add"}
      iconColor={color.text}
      color="doctor"
    />
  );
};

export const Remove = (props: { onPress?: () => void; style?: StyleProp<ViewStyle> }) => {
  return (
    <CircleButton
      onPress={props.onPress}
      style={[styles.fabOuter, props.style]}
      fabStyle={styles.fab}
      icon={"remove"}
      iconColor={color.textWhite}
      color="textError"
    />
  );
};

export const Delete = (props: { onPress?: () => void; style?: StyleProp<ViewStyle> }) => {
  return (
    <CircleButton
      onPress={props.onPress}
      style={[styles.fabOuter, props.style]}
      fabStyle={styles.fab}
      icon={"delete"}
      iconColor={color.primary}
      color="sky"
    />
  );
};

export const Edit = (props: { onPress?: () => void; style?: StyleProp<ViewStyle> }) => {
  return (
    <CircleButton
      onPress={props.onPress}
      style={[styles.fabOuter, props.style]}
      fabStyle={styles.fab}
      icon={"edit"}
      iconColor={color.textWhite}
      color="primary"
    />
  );
};

export const Navigate = (props: { onPress?: () => void; style?: StyleProp<ViewStyle> }) => {
  return (
    <CircleButton
      onPress={props.onPress}
      style={[styles.fabOuter, props.style]}
      fabStyle={styles.fab}
      icon={"keyboard-arrow-right"}
      iconColor={color.textWhite}
      color="primary"
    />
  );
};

const styles = StyleSheet.create({
  border: {
    borderRadius: 48,
    borderWidth: 5,
    width: 66,
    height: 66,
  },
  container: {
    elevation: 0,
    overflow: "visible",
    borderRadius: 28,
  },
  icon: {
    lineHeight: 24,
    overflow: "visible",
  },
  fabOuter: { borderWidth: 0, width: 56, height: 56 },
  fab: {
    elevation: 2,
  },
});
