import { LinkingOptions } from "@react-navigation/native";
import { Platform } from "react-native";

import AMHPScreens from "./AMHPScreens";
import CCGScreens from "./CCGScreens";
import authScreens from "./authScreens";
import doctorScreens from "./doctorScreens";
import externalDoctorScreens from "./externalDoctorScreens";
import sharedScreens from "./sharedScreens";

function ensureLeadingSlash(input: string, shouldAppend: boolean): string {
  const hasSlash = input.endsWith("/");
  if (hasSlash && !shouldAppend) {
    return input.substring(0, input.length - 1);
  } else if (!hasSlash && shouldAppend) {
    return `${input}/`;
  }
  return input;
}

function ensureTrailingSlash(input: string, shouldAppend: boolean): string {
  const hasSlash = input.startsWith("/");
  if (hasSlash && !shouldAppend) {
    return input.substring(1);
  } else if (!hasSlash && shouldAppend) {
    return `/${input}`;
  }
  return input;
}

/**
 * Lifted straight from the Expo source code. No shame. It's not as feature
 * rich as we only need it for the web, the expo version creates it for any
 * platform.
 *
 * @param path
 * @link https://github.com/expo/expo/blob/master/packages/expo-linking/src/Linking.ts
 */
function makeWebUrl(path = ""): string {
  if (Platform.OS === "web") {
    const origin = ensureLeadingSlash(window.location.origin, false);
    const outputPath = ensureTrailingSlash(path, true);

    return encodeURI(`${origin}${outputPath}`);
  }

  return "";
}

/**
 * @link https://reactnavigation.org/docs/configuring-links
 */
const linkingConfig: LinkingOptions = {
  prefixes: ["s12://", makeWebUrl("/")],
  config: {
    screens: {
      ...authScreens,
      ...externalDoctorScreens,
      HomeNavigator: {
        screens: {
          ...AMHPScreens,
          ...CCGScreens,
          ...doctorScreens,
          ...sharedScreens,
        },
      },
    },
  },
};

export default linkingConfig;
