import * as Yup from "yup";
import { ComponentElement, FormFieldsAmhp } from "libs/types/mhaForms";

const componentElements: ComponentElement[] = [
  {
    component: "Text",
    componentProps: {
      instruction: "I am acting on behalf of",
      guidance: "(PRINT name of local social services authority)",
    },
    fullWidth: true,
  },
  {
    component: "TextInput",
    fieldName: FormFieldsAmhp.AuthorityName,
    componentProps: {
      label: "Name",
    },
  },
  {
    component: "Text",
    componentProps: {
      instruction: "and am approved to act as an approved mental health professional for the purposes of the Act by",
      guidance: "(Check as appropriate)",
      pdfOnlyGuidance: "(delete as appropriate)",
    },
    fullWidth: true,
  },
  {
    component: "Checkbox",
    fieldName: FormFieldsAmhp.Approved,
    componentProps: {
      options: [
        {
          status: "approved",
          label: "that authority",
        },
      ],
    },
    genericProps: {
      indentation: 1,
    },
  },
  {
    component: "Text",
    componentProps: {
      instruction: "",
      guidance: "(name of local social services authority that approved you, if different)",
    },
    fullWidth: true,
    genericProps: {
      indentation: 1,
    },
  },
  {
    component: "TextInput",
    fieldName: FormFieldsAmhp.LocalAuthorityName,
    componentProps: {
      label: "Local services authority name",
    },
    genericProps: {
      conditionallyDisabledBy: FormFieldsAmhp.Approved,
      conditionallyDisabledValues: ["approved"],
      indentation: 1,
    },
  },
];

export default {
  title: "Local Authority",
  state: {
    authorityName: "",
    approved: ["approved"],
    localAuthorityName: "",
  },

  validation: Yup.object().shape({
    authorityName: Yup.string()
      .trim()
      .required("Please enter the name of the authority")
      .min(3, "Authority name must be at least 3 characters in length"),
    localAuthorityName: Yup.mixed().when("approved", {
      is: (approved) => !approved.length,
      then: Yup.string()
        .trim()
        .required("Please enter the name of the authority")
        .min(3, "Authority name must be at least 3 characters in length"),
      otherwise: Yup.string(),
    }),
  }),

  componentElements,
};
