import { StyleSheet } from "react-native";
import { spacing } from "../../theme";

const css = StyleSheet.create({
  heading: {
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: spacing[20],
  },

  headings: {
    flexDirection: "row",
    marginBottom: spacing[15],
    paddingRight: 54,
  },

  icon: {
    marginRight: spacing[5],
  },

  row: {
    flexDirection: "row",
    paddingVertical: spacing[15],
  },
});

export default css;
