import React, { useCallback } from "react";
import { Keyboard, StyleSheet, View } from "react-native";
import { TouchableRipple } from "react-native-paper";
import { useRecoilValue } from "recoil";

import { userDetails } from "@/utils/recoil";
import { TypographyType } from "@/models/Typography";
import { useBottomSheet } from "@/hooks";
import { palette, spacing } from "@/theme";

import { InfoText } from "@/components/InfoText";
import Text from "@/components/Text";
import { TextInput } from "@/components/TextInput/TextInput";

import MHALocationSearch from "../MHALocationSearch";
import { MHALocationSearchItem, RetrieveMHADataObject } from "../MHALocationSearch/MHALocationSearch.props";

export interface MHALocationProps {
  label?: string;
  locationValue?: string;
  setLocationValue: (value: RetrieveMHADataObject) => void;
  initialResults?: MHALocationSearchItem[];
  disableStrictPostcodeValidation?: boolean;
  hideIcon?: boolean;
  disabled?: boolean;
  error?: boolean;
  errorText?: {
    postcode: string;
  };
  externalDoctorCopy?: string;
  addressChangeWarning?: string;
}

export const MHALocationInput = (props: MHALocationProps) => {
  const { openBottomSheet, closeBottomSheet } = useBottomSheet();
  const label = props.label || "Address";

  const user = useRecoilValue(userDetails);

  const passState = useCallback(
    (locationData: RetrieveMHADataObject) => {
      props.setLocationValue(locationData);
      closeBottomSheet();
    },
    [props.setLocationValue]
  );

  const onContainerPress = (value: string) => {
    Keyboard.dismiss();
    showLocationSearch(value);
  };

  // There is no hard and fast way of judging the below, this is a little hacky and i dont like it but short of quite drastically changing how the props
  // are being fed in this is the best we can do for now. Add to the list of V3 improvements.
  const patientAddressBool =
    props?.label?.toLowerCase().includes("patient address") ||
    props?.addressChangeWarning?.toLowerCase().includes("patient address");

  const showLocationSearch = (value: string) => {
    openBottomSheet({
      type: "generic",
      data: {
        heading: label,
        component: MHALocationSearch,
        componentProps: {
          label: "",
          patientAddress: patientAddressBool,
          inputText: props.locationValue || value,
          initialResults: props.initialResults || [],
          disableStrictPostcodeValidation: props.disableStrictPostcodeValidation,
          retrieveData: passState,
        },
        componentContentWrap: true,
      },
    });
  };

  const TextRender = useCallback(
    renderProps => {
      const { style, value } = renderProps;
      return (
        <TouchableRipple onPress={() => onContainerPress(value)} disabled={props.disabled}>
          <Text format={TypographyType.Regular} style={[style, props.disabled && { color: palette.greyBlue }]}>
            {value}
          </Text>
        </TouchableRipple>
      );
    },
    [props.initialResults, passState]
  );

  return (
    <>
      <TextInput
        label={label}
        error={props.error}
        errorText={props.errorText?.postcode}
        value={props.locationValue || ""}
        render={TextRender}
      />
      {!user?.groups.includes("ExternalDoctor") && props.addressChangeWarning && (
        <View style={styles.addressChangeWarning}>
          <InfoText paragraphs={props.addressChangeWarning.split(/\r?\n/)} />
        </View>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  addressChangeWarning: {
    marginBottom: spacing[15],
  },
});
