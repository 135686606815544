import React, { useContext } from "react";
import { View, Platform, StyleSheet, TouchableOpacity } from "react-native";
import dayjs, { Dayjs } from "dayjs";
import DualDatePicker from "../../DatePicker/DualDatePicker";
import DualTimePicker from "../../TimePicker/DualTimePicker";
import Icon from "../../Icon";
import { AvailabilityContext } from "../DoctorAvailabilityProvider";
import {
  AvailabilityControllerActionTypes,
  AvailabilityModelActionTypes,
  StateContext,
} from "libs/state/availability/initialState";
import { AvailabilityDetails } from "../AvailabilityDetails";
import { Checkbox } from "../../Checkbox/Checkbox";
import { ContentWrap } from "../../ContentWrap";
import { TypographyType } from "@/models/Typography";
import Text from "../../Text";
import { spacing, color } from "../../../theme";
import { setWeekday, setEndOfWeek, leftPad } from "libs/dates";
import { mqWeb } from "../../../utils/helpers";

const year2036 = new Date(2036, 0, 1);

export function AvailabilityDate(props: {
  index: number;
  availability: {
    date: Dayjs;
    startTime: { hours: number; minutes: number };
    endTime: { hours: number; minutes: number };
  };
  mhts: any[];
}) {
  const { state, dispatch, controllerDispatch } = useContext<StateContext>(AvailabilityContext);
  const { availabilities, eventSeriesEndDate } = state;
  const {
    index,
    availability: { date, startTime, endTime },
  } = props;

  const thisMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
  const isWebView = mqWeb();
  const showDetails = state.activeElements.includes(index);
  const hasMultiple = state.availabilities.length > 1;
  const endTimeInvalid =
    (!availabilities[index].overnight &&
      (startTime.hours > endTime.hours ||
        (startTime.hours === endTime.hours && startTime.minutes >= endTime.minutes))) ||
    (availabilities[index].overnight &&
      (startTime.hours < endTime.hours || (startTime.hours === endTime.hours && startTime.minutes < endTime.minutes)));
  const error = availabilities[index].hasError || Boolean(availabilities[index].touched && !!endTimeInvalid);
  return (
    <>
      <ContentWrap>
        <View style={styles.wrapper}>
          {hasMultiple && (
            <TouchableOpacity
              activeOpacity={1}
              onPress={() =>
                dispatch({
                  type: AvailabilityModelActionTypes.DELETE_AVAILABILITY,
                  payload: index,
                })
              }
            >
              <Icon name="remove-circle" color={color.textError} style={styles.removeIcon} />
            </TouchableOpacity>
          )}
          <View style={[styles.dateItem, styles.itemSpacing]}>
            <DualDatePicker
              label="Start Date"
              noIcon={true}
              max={year2036}
              min={thisMonth}
              value={date}
              onValueChange={value => {
                const day = dayjs(value).day();

                if (value.diff(eventSeriesEndDate) > 0 && availabilities.length === 1) {
                  dispatch({
                    type: AvailabilityModelActionTypes.SET_END_DATE,
                    payload: setEndOfWeek(value),
                  });
                }

                dispatch({
                  type: AvailabilityModelActionTypes.SET_AVAILABILITY,
                  payload: {
                    index: index,
                    data: {
                      date: value.isAfter(year2036) ? year2036 : value,
                    },
                  },
                });
                dispatch({
                  type: AvailabilityModelActionTypes.SET_REPEAT_WEEKDAYS,
                  payload: [setWeekday(day)],
                });
              }}
            />
          </View>
          <View style={[styles.timeItem, styles.itemSpacing]}>
            <DualTimePicker
              label="Start"
              noIcon={true}
              value={startTime}
              style={styles.timeInput}
              onValueChange={v =>
                dispatch({
                  type: AvailabilityModelActionTypes.SET_AVAILABILITY,
                  payload: {
                    index: index,
                    data: {
                      startTime: v,
                      hasError: false,
                      touched: true,
                    },
                  },
                })
              }
            />
          </View>
          <View style={styles.timeItem}>
            <DualTimePicker
              label={`End${error ? "* " : " "}${availabilities[index].overnight ? "(next day)" : ""}`}
              noIcon={true}
              value={endTime}
              style={styles.timeInput}
              error={error}
              errorText={
                isWebView
                  ? `* Availability must end ${availabilities[index].overnight ? "before" : "after"} ${leftPad(
                      startTime.hours
                    )}:${leftPad(startTime.minutes)}`
                  : null
              }
              onValueChange={v =>
                dispatch({
                  type: AvailabilityModelActionTypes.SET_AVAILABILITY,
                  payload: {
                    index: index,
                    data: {
                      endTime: v,
                      hasError: false,
                      touched: true,
                    },
                  },
                })
              }
            />
          </View>

          {hasMultiple && (
            <TouchableOpacity
              activeOpacity={1}
              onPress={() =>
                controllerDispatch({
                  type: AvailabilityControllerActionTypes.SET_ACTIVE_ELEMENT,
                  payload: index,
                })
              }
              style={styles.accordionIconWrapper}
            >
              <Icon
                name="arrow-drop-down-circle"
                color={color.inAssessment}
                style={[styles.accordionIcon, showDetails ? styles.accordionIconOpen : {}]}
              />
            </TouchableOpacity>
          )}
        </View>
      </ContentWrap>
      {error && !isWebView && (
        <ContentWrap style={{ marginTop: -20, marginBottom: spacing[20] }}>
          <Text format={TypographyType.Micro} color={color.textError}>
            * Availability must end {availabilities[index].overnight ? "before" : "after"} {leftPad(startTime.hours)}:
            {leftPad(startTime.minutes)}
          </Text>
        </ContentWrap>
      )}
      {showDetails && hasMultiple && (
        <View style={styles.customBlock}>
          <ContentWrap>
            <Text format={TypographyType.RegularBold} style={styles.headerSpacing}>
              Custom Details
            </Text>
            <Text format={TypographyType.Tiny} style={styles.headerSpacing}>
              The details you enter below will override your default information and apply only to this entry.
            </Text>
            <AvailabilityDetails mhts={props.mhts} isCustom={true} index={index} />
          </ContentWrap>
        </View>
      )}
      <ContentWrap style={{ marginTop: -10, marginBottom: spacing[30], alignItems: "flex-start" }}>
        <Checkbox
          status={!!availabilities[index].overnight}
          label="Overnight Availability"
          onPress={() =>
            dispatch({
              type: AvailabilityModelActionTypes.SET_AVAILABILITY,
              payload: {
                index: index,
                data: {
                  overnight: !availabilities[index].overnight,
                  hasError: false,
                  touched: true,
                },
              },
            })
          }
        />
      </ContentWrap>
    </>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  timeInput: {
    backgroundColor: color.background,
  },
  headerSpacing: {
    marginBottom: spacing[20],
  },
  removeIcon: { marginTop: 24 },
  accordionIcon: { marginTop: 24 },
  accordionIconOpen: { transform: [{ rotate: "180deg" }] },
  accordionIconWrapper: { zIndex: 5 },
  customBlock: {
    marginTop: -30,
    backgroundColor: color.backgroundGrey,
    paddingTop: 30,
    marginBottom: 30,
  },
  dateItem: {
    flex: 3,
    overflow: "hidden",
  },
  timeItem: {
    flex: 2,
    overflow: "hidden",
  },
  itemSpacing: {
    marginRight: Platform.OS === "web" ? spacing[10] : spacing[5],
  },
});
