import React, { useEffect, useState } from "react";
import { StyleSheet, View, Platform } from "react-native";
import { Button } from "react-native-paper";
import { Auth } from "@aws-amplify/auth";
import { useRecoilValue } from "recoil";

import { ContactUs } from "@/components/ContactUs";
import { ContentWrap } from "@/components/ContentWrap";
import { DeviceSelection } from "@/components/MeetingRoom/DeviceSelection";
import SectionDivider from "@/components/SectionDivider/SectionDivider";
import Text from "@/components/Text";

import Config from "@/config";
import { useBottomSheet, useSignout } from "@/hooks";
import { AppScreen } from "@/layouts/AppScreen/AppScreen";
import { TypographyType } from "@/models/Typography";
import { RouteKeys } from "@/navigationv2";
import { BaseScreenProps, SupportRoutes, ScreenOptions } from "@/navigationv2/types";
import { createScreenTitle } from "@/navigationv2/utilities";
import { spacing } from "@/theme/spacing";
import { alert, mqWeb } from "@/utils/helpers";
import { userDetails } from "@/utils/recoil";

import { version, devBuild } from "@/../package.json";

interface PropTypes extends BaseScreenProps<SupportRoutes, RouteKeys.SupportScreen> {}

export const SupportScreen = (props: PropTypes) => {
  const isWebView = mqWeb();
  const { navigation } = props;
  const [showVideoTest, setShowVideoTest] = useState(false);
  const signout = useSignout();
  const { openBottomSheet } = useBottomSheet();
  const user = useRecoilValue(userDetails);

  const isNotExternalUser = user?.id.indexOf("Form-") !== 0;

  const signOut = () => {
    alert(
      "Logout",
      "Are you sure you want to logout?",
      () => null,
      () => signout(),
      "Cancel logout",
      "Yes, logout"
    );
  };

  useEffect(() => {
    if (showVideoTest) {
      openBottomSheet({
        type: "generic",
        data: {
          heading: "Video/Audio Settings",
          component: DeviceSelection,
          onDismiss: () => setShowVideoTest(false),
        },
      });
    }
  });

  return (
    <AppScreen contextLabel="S12 Solutions" pageTitle="Support" testID="SupportScreen">
      <ContentWrap>
        <ContactUs />
        <SectionDivider compressed={isWebView} />
        <Button
          onPress={() => navigation.navigate("SupportExplainedScreen")}
          style={isWebView ? styles.buttonLinksWeb : styles.buttonLinks}
          testID="supportExplained__link"
        >
          Support Explained
        </Button>
        <SectionDivider compressed={isWebView} />
        {isNotExternalUser && (
          <Button
            onPress={() => navigation.navigate("ChangePasswordScreen")}
            style={[styles.buttonLinks, styles.buttonLinksStandalone, isWebView && styles.buttonLinksWeb]}
          >
            Reset password
          </Button>
        )}

        <Button onPress={() => signOut()} style={isWebView ? styles.buttonLinksWeb : styles.buttonLinks}>
          Logout
        </Button>
        <SectionDivider compressed={isWebView} />
        {Platform.OS === "web" && isNotExternalUser && (
          <>
            <Button
              onPress={() => setShowVideoTest(true)}
              style={isWebView ? styles.buttonLinksWeb : styles.buttonLinks}
            >
              Test Video Meeting Devices
            </Button>
            <SectionDivider compressed={isWebView} />
          </>
        )}

        {Config.ENABLE_CLAIMS && isNotExternalUser ? (
          <Button
            onPress={() => navigation.navigate("ClaimProcessScreen")}
            style={isWebView ? styles.buttonLinksWeb : styles.buttonLinks}
            testID="claimProcessExplained__link"
          >
            Claim Process Explained
          </Button>
        ) : (
          <></>
        )}
        <Button
          onPress={() => navigation.navigate("Eula")}
          style={isWebView ? styles.buttonLinksWeb : styles.buttonLinks}
          testID="TermsOfUse__link"
        >
          Terms of Use
        </Button>
        <Button
          onPress={() => navigation.navigate("PrivacyPolicyScreen")}
          style={isWebView ? styles.buttonLinksWeb : styles.buttonLinks}
          testID="PrivacyPolicy__link"
        >
          Privacy Policy
        </Button>
        <View style={styles.version}>
          <Text format={TypographyType.Micro}>{`${Platform.OS === "web" ? "Website" : "App"} Version: ${version}${
            process.env.ENV !== "prod" ? `${devBuild}` : ``
          }`}</Text>
        </View>
      </ContentWrap>
    </AppScreen>
  );
};

const screenOptions: ScreenOptions = {
  title: createScreenTitle("Support"),
};

SupportScreen.screenOptions = screenOptions;

export default SupportScreen;

const styles = StyleSheet.create({
  buttonLinksStandalone: {
    marginTop: spacing[20],
  },
  buttonLinks: {
    marginBottom: spacing[10],
  },
  buttonLinksWeb: {
    marginTop: spacing[5],
    marginBottom: spacing[5],
  },
  version: {
    alignItems: "center",
    marginVertical: spacing[20],
  },
});
