import React from "react";

import { BackButtonProps } from "@/components/BackButton/BackButton.props";
import ContentWrap from "@/components/ContentWrap/ContentWrap";
import Text from "@/components/Text";
import { AppScreen } from "@/layouts/AppScreen/AppScreen";
import { TypographyType } from "@/models/Typography";
import { RouteKeys } from "@/navigationv2";
import { SharedTabRoutes, SupportRoutes, ScreenNavigationProps, ScreenOptions } from "@/navigationv2/types";
import { createScreenTitle } from "@/navigationv2/utilities";
import { color, palette } from "@/theme";
import ItemSpacer from "@/components/ItemSpacer";
import { SectionDivider } from "@/components/SectionDivider";

const backButtonConfig: BackButtonProps = {
  enabled: true,
  float: true,
  color: color.textWhite,
};

interface PropTypes extends ScreenNavigationProps<SharedTabRoutes, SupportRoutes, RouteKeys.SupportExplainedScreen> {}

const SupportExplainedScreen = () => {
  return (
    <AppScreen
      contextLabel="Support"
      pageTitle="Support Explained"
      backButton={backButtonConfig}
      testID="SupportExplainedScreen"
    >
      <ContentWrap>
        <Text format={TypographyType.HeadingMedium} testID="SupportExplained__Header">
          Support Explained
        </Text>
        <ItemSpacer gap={10}>
          {[
            `Target resolution time of 4 hours for critical issues, 24 hours for major issues, 72 hours for minor issues.`,
            `Critical issues prevent access to important workflows and data. Major issues prevent access to secondary data and workflows (reporting, onboarding).`,
            `Minor issues prevent access, but workarounds exist or the issue affect a subset of non-critical users (device specific issues, issues that can be resolved with administrator intervention, usability annoyances).`,
          ].map((sentence, i) => (
            <Text key={i} format={TypographyType.Regular}>
              {sentence}
            </Text>
          ))}
        </ItemSpacer>
      </ContentWrap>
      <SectionDivider short={true} />
      <ContentWrap>
        <Text format={TypographyType.HeadingSmall} marginBottom={15}>
          Support Hours
        </Text>
        <Text format={TypographyType.Regular} marginBottom={5}>
          Monday-Friday - 8.30am-5.30pm
        </Text>
        <Text format={TypographyType.Micro} color={palette.greyBlue}>
          (Excluding Bank Holidays).
        </Text>
      </ContentWrap>
    </AppScreen>
  );
};

const screenOptions: ScreenOptions = {
  title: createScreenTitle("Support Explained"),
};

SupportExplainedScreen.screenOptions = screenOptions;

export default SupportExplainedScreen;
