/**
 * Attach to assessment screen
 * Access via: app, login as amhp, dashboard, Submitted MHT Forms section,
 * click a form and 'Attach to Assessment' on the next screen.
 */
import React, { useState, useEffect } from "react";
import { StyleSheet, View } from "react-native";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { useRecoilValue, useSetRecoilState } from "recoil";
import dayjs, { Dayjs } from "dayjs";
import { ApolloError } from "apollo-boost";

import { ContentWrap } from "../../ContentWrap";
import AttachableFormItem from "./AttachableFormItem";
import Text from "../../Text";
import { Button, ButtonList } from "../../Button";
import Select from "../../Select";
import ItemSpacer from "../../ItemSpacer";
import Loading from "../../../components/Loading";

import {
  getAmhpAssessmentsQuery,
  GetTeamAssessmentsQueryResponse,
  GetTeamInformationResponse,
  GET_TEAM_INFORMATION,
} from "@/models/Assessment";
import { TypographyType } from "@/models/Typography";
import {
  userDetails,
  lastException,
  snackbarMessage,
  refreshTeamInboxRequired,
  quickAssessment,
  quickAssessmentLocationState,
} from "@/utils/recoil";
import { saveFormError, addVisitError, buildErr, S12Error } from "@/models/Error";
import { CREATE_DOCTOR_VISIT } from "@/models/DoctorProfile";
import { spacing, palette } from "../../../theme";
import { FormType } from "libs/types/mhaForms";
import { FormStatus } from "libs/types/API";
import { saveForm } from "@/models/MHAForm/api";
import { MHAFormMetadata, FormikValues } from "@/models/MHAForm/MHAForm";
import { RouteKeys } from "@/navigationv2";
import notFalsy from "libs/utils/notFalsy";
import { useAPIVersion, useBottomSheet } from "@/hooks";

import BottomSheets from "@/components/UserDashboard/BottomSheets";
import { RetrieveDataObject } from "../../LocationSearch/LocationSearch.props";
import { QuickAssessmentLocationState } from "@/utils/recoil/props";
import { API_V2_TOGGLES } from "@/api/types";

interface AttachToAssessmentProps {
  form: FormikValues;
  formMetadata: Omit<MHAFormMetadata, "notes">;
  navigation: any;
}

const AssessmentSelection = (props: {
  loading: boolean;
  error?: ApolloError;
  options: {
    id: string;
    name: string;
  }[];
  selectedAssessment: string;
  setSelectedAssessment: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const { loading, error, options, selectedAssessment, setSelectedAssessment } = props;

  return loading ? (
    <Loading text="Loading Assessments" />
  ) : error ? (
    <Text format={TypographyType.HeadingSmall} style={styles.smallHeading}>
      There was an error loading assessments
    </Text>
  ) : (
    <ItemSpacer gap={20}>
      <Select
        label="Select Assessment"
        value={selectedAssessment}
        onValueChange={setSelectedAssessment}
        options={options}
      />
    </ItemSpacer>
  );
};

const AttachToAssessment = ({ form, formMetadata, navigation }: AttachToAssessmentProps) => {
  const user = useRecoilValue(userDetails);
  const [selectedAssessment, setSelectedAssessment] = useState<string>("");
  const [selectedPatientName, setSelectedPatientName] = useState<string>("");
  const [hasDoctorVisit, setHasDoctorVisit] = useState(true);
  const [confirmation, setConfirmation] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [locationSearch, setLocationSearch] = useState(false);
  const setAssessmentRecoil = useSetRecoilState(quickAssessment);
  const setQuickAssessmentLocationRecoil = useSetRecoilState(quickAssessmentLocationState);
  const { openBottomSheet, closeBottomSheet } = useBottomSheet();
  const setMessage = useSetRecoilState(snackbarMessage);
  const setLastException = useSetRecoilState(lastException);
  const setRefreshTeamInboxRequired = useSetRecoilState(refreshTeamInboxRequired);
  const { v2 } = useAPIVersion(API_V2_TOGGLES.MHA_FORMS);

  const [createDoctorVisit] = useMutation(CREATE_DOCTOR_VISIT);
  const { loading, error, data, refetch, variables } = useQuery<GetTeamAssessmentsQueryResponse>(
    getAmhpAssessmentsQuery,
    {
      variables: {
        today: dayjs().format("YYYY-MM-DD"),
        amhpId: user?.id,
        amhpTeamId: formMetadata.amhpTeamId,
        limit: 20,
      },
      fetchPolicy: "cache-and-network",
    }
  );

  const assessments = [...(data?.teamAssessments?.items || []), ...(data?.teamArchivedAssessments?.items || [])];

  const attachableAssessments = assessments.filter(notFalsy).map(a => ({
    name: `${a.patientName} - ${dayjs(a.assessmentDate).format("DD/MM/YYYY")}`,
    id: a.id,
  }));
  const patientName = Object.values(form).find(f => f.patientName)?.patientName as string;
  const examinationDate = Object.values(form).find(f => f.examinationDate)?.examinationDate as string;
  const { data: teamInformationData } = useQuery<GetTeamInformationResponse>(GET_TEAM_INFORMATION, {
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (selectedAssessment) {
      const assessment = assessments ? assessments.find(a => a?.id === selectedAssessment) : null;
      const assessmentHasDoctorVisit = !!(assessment
        ? assessment?.doctorVisits?.items.find(visit => visit?.doctor.id === doctorId)
        : null);
      setHasDoctorVisit(assessmentHasDoctorVisit);
    }

    // Extract the patient name selected from the assessment ready for testing against match below
    setSelectedPatientName(assessments.find(a => a?.id === selectedAssessment)?.patientName || "");
  }, [selectedAssessment]);

  if (!formMetadata.amhpTeamId) return <></>;

  const doctorName = formMetadata?.doctorNames?.[0] || "";
  const doctorId = formMetadata?.authors?.[0] || "";

  const saveFormToAssessment = (successMessage: string) => {
    saveForm(v2, {
      id: formMetadata.id,
      assessmentId: selectedAssessment,
      amhpTeamId: formMetadata.amhpTeamId,
      amhpTeamInboxId: "!!!",
      type: formMetadata.type,
      authors: formMetadata.authors,
      version: formMetadata.version,
      nextVersion: formMetadata.version + 1,
      status: FormStatus.s40_signed,
    }).then(response => {
      if ("isError" in response && response.isError && response.message?.includes("Network Error")) {
        const error = new S12Error(saveFormError);
        setLastException(error);
        buildErr(saveFormError, setLastException)("Network Error");
      } else {
        setRefreshTeamInboxRequired(true);
        setMessage(successMessage);
        navigation.navigate(RouteKeys.UserDashboardScreen);
        closeBottomSheet();
      }
    });
  };

  function attachForm() {
    setSubmitting(true);
    if (!hasDoctorVisit) {
      const assesmentDate = examinationDate ? dayjs(examinationDate) : dayjs();
      const assessmentDateTime = assesmentDate.hour(12).minute(0);
      const assessmentDateTimeString = assessmentDateTime.format();
      createDoctorVisit({
        variables: {
          doctorId,
          assessmentId: selectedAssessment,
          time: assessmentDateTimeString,
        },
      })
        .then(() => {
          refetch(variables);
          saveFormToAssessment("Visit added and form successfully attached");
        })
        .catch(buildErr({ ...addVisitError, additional: doctorId }, setLastException));
    } else {
      saveFormToAssessment("Form successfully attached");
    }
  }

  const openLocationSearch = (a: boolean, commonAddresses: any) => {
    if (a) {
      setLocationSearch(!a);
      openBottomSheet({
        type: "locationSearch",
        data: {
          initialQuery: "",
          initialResults: commonAddresses,
          onDismiss: () => {
            setTimeout(() => {
              openBottomSheet({
                type: "generic",
                data: {
                  heading: "Create new assessment",
                  component: BottomSheets.QuickAssessment,
                  componentProps: {
                    formMetadata,
                    patientName,
                    locationSearch,
                    teamInformationData,
                    locationSearchFun: (a, b) => {
                      openLocationSearch(a, b);
                    },
                    navigation,
                    examinationDate,
                    doctorId,
                  },
                },
              });
            }, 500);
          },
          retrieveData: (data: RetrieveDataObject) => {
            setQuickAssessmentLocationRecoil({
              location: data.location,
              locationName: data.locationName,
              ccgId: data.ccg ? data.ccg.id : "XXX",
            } as QuickAssessmentLocationState);
            openBottomSheet({
              type: "generic",
              data: {
                heading: "Create new assessment",
                component: BottomSheets.QuickAssessment,
                componentProps: {
                  formMetadata,
                  patientName,
                  locationSearch,
                  teamInformationData,
                  locationSearchFun: (a, b) => {
                    openLocationSearch(a, b);
                  },
                  navigation,
                  examinationDate,
                  doctorId,
                },
              },
            });
          },
        },
      });
    }
  };

  return !confirmation ? (
    <ContentWrap>
      <AssessmentSelection
        loading={loading}
        error={error}
        options={[
          {
            name: "- Select -",
            id: "",
          },
          ...attachableAssessments,
        ]}
        selectedAssessment={
          attachableAssessments.length
            ? (attachableAssessments.find(a => a.id === selectedAssessment)?.id as string)
            : ""
        }
        setSelectedAssessment={setSelectedAssessment}
      />
      {formMetadata && (
        <AttachableFormItem
          form={{
            id: formMetadata.id || "",
            formType: formMetadata.type,
            patientName: patientName || "",
            doctorName,
            ...(!!examinationDate && { examinationDate: dayjs(examinationDate) }),
          }}
        />
      )}

      {/* If the selected patient is different from the assessment patient, give a warning. */}
      {patientName !== selectedPatientName && selectedPatientName !== "" && (
        <View style={styles.warning}>
          <Text format={TypographyType.Tiny}>
            <Text format={TypographyType.Tiny}>The patient name on the form </Text>
            <Text format={TypographyType.Tiny} style={{ fontFamily: "Lato-Bold" }}>
              does not
            </Text>
            <Text format={TypographyType.Tiny}>{` match the patient name on the assessment.\n\n`}</Text>
            <Text format={TypographyType.Small}>Assessment Name: </Text>
            <Text format={TypographyType.Small} style={{ fontFamily: "Lato-Bold" }}>
              {`${selectedPatientName}.\n\n`}
            </Text>
            <Text format={TypographyType.Small}>Form Name: </Text>
            <Text format={TypographyType.Small} style={{ fontFamily: "Lato-Bold" }}>
              {`${patientName}.\n\n`}
            </Text>
            <Text format={TypographyType.Tiny}>
              By selecting ‘Attach Selected Form’ (below), the assessment details will be updated to match the name on
              the form. The form can only be updated by the original author.
            </Text>
          </Text>
        </View>
      )}

      <ButtonList>
        <Button onPress={() => setConfirmation(true)} disabled={selectedAssessment === ""}>
          Attach Selected Form
        </Button>
        <Button
          onPress={() => {
            setAssessmentRecoil(null);
            setQuickAssessmentLocationRecoil(null);
            openBottomSheet({
              type: "generic",
              data: {
                heading: "Create new assessment",
                component: BottomSheets.QuickAssessment,
                componentProps: {
                  formMetadata,
                  patientName,
                  locationSearch,
                  teamInformationData,
                  locationSearchFun: (a, b) => {
                    openLocationSearch(a, b);
                  },
                  navigation,
                  examinationDate,
                  doctorId,
                },
              },
            });
          }}
          disabled={loading}
        >
          Create assessment
        </Button>
      </ButtonList>
    </ContentWrap>
  ) : (
    <ContentWrap>
      <Text format={TypographyType.Regular} style={styles.bottom20}>
        Are you sure you want to assign
      </Text>

      <AttachableFormItem
        form={{
          id: formMetadata?.id as string,
          formType: formMetadata?.type as FormType,
          patientName: patientName || "",
          doctorName: formMetadata?.doctorNames?.[0] || "",
          ...(!!examinationDate && { examinationDate: dayjs(examinationDate) }),
          createdAt: formMetadata.createdAt,
        }}
      />

      <Text format={TypographyType.Regular} style={styles.top20}>
        to assessment
      </Text>
      <View style={styles.row}>
        <Text format={TypographyType.RegularBold}>
          {(attachableAssessments.find(a => a.id === selectedAssessment)?.name as string) || ""}
        </Text>
        <Text format={TypographyType.Regular}>?</Text>
      </View>
      {!hasDoctorVisit && (
        <Text format={TypographyType.Tiny} style={styles.top20}>
          {`Attaching the form will create a visit in order for ${doctorName} to review the form and potentially make edits.`}
        </Text>
      )}
      <ButtonList>
        <Button onPress={attachForm} disabled={selectedAssessment === "" || submitting}>
          Confirm
        </Button>
        <Text
          format={TypographyType.SmallBold}
          color={palette.blue}
          onPress={() => setConfirmation(false)}
          style={styles.center}
        >
          Go Back
        </Text>
      </ButtonList>
    </ContentWrap>
  );
};

export default AttachToAssessment;

const styles = StyleSheet.create({
  smallHeading: {
    marginBottom: spacing[15],
  },

  row: { flexDirection: "row" },

  top20: { paddingTop: spacing[20] },

  bottom20: { paddingBottom: spacing[20] },

  center: { textAlign: "center" },

  warning: { borderColor: palette.red, borderWidth: 4, borderRadius: 16, padding: spacing[10] },
});
