import { StyleSheet } from "react-native";
import { spacing } from "../../../theme/spacing";
import { palette } from "../../../theme/palette";

export default StyleSheet.create({
  doctorCardsHeading: {
    paddingTop: spacing[30],
    backgroundColor: palette.cloud,
  },
  noResultsTextMobile: {
    paddingBottom: spacing[30],
  },
  claimInfo: {
    flexDirection: "row",
    marginTop: spacing[15],
  },
  flex: { flex: 1 },
  cardHeading: {
    flexShrink: 1,
    paddingRight: 24,
  },

  cardHeader: {
    flexDirection: "row",
    alignItems: "center",
  },

  cardHeaderDate: {
    flexDirection: "row",
    alignItems: "flex-end",
  },

  cardHeadingRow: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },

  contactedDoctorsTable: {
    marginTop: 60,
    paddingBottom: spacing[20],
  },

  contactHistoryLabel: {
    marginBottom: spacing[10],
  },

  findDoctorsButton: {
    marginBottom: 0,
  },
});
