import React, { useCallback, useEffect, useState } from "react";
import Auth from "@aws-amplify/auth";
import { Credentials } from "@aws-amplify/core";
import { NavigationContainer, NavigationState, Route } from "@react-navigation/native";
import { useRecoilState, useSetRecoilState } from "recoil";
import { SafeAreaProvider } from "react-native-safe-area-context";
import SplashScreen from "react-native-splash-screen";
import AsyncStorage from "@react-native-async-storage/async-storage";
import * as Sentry from "@sentry/core";
import { Platform } from "react-native";
import PushNotificationIOS from "@react-native-community/push-notification-ios";

import Config from "@/config";
import configureApi from "@/config/configureApi";
import configureAnalytics from "@/config/configureAnalytics";
import {
  configurePushNotificationToken,
  handleIncomingLocalNotificationIOS,
  handleLocalNotificationAndroid,
  foregroundNotificationsListener,
} from "@/config/configurePushNotifications";
import configureAmplify from "@/config/configureAmplify";
import configureAuth from "@/config/configureAuth";
import * as configureSentry from "@/config/configureSentry";
import { useIsDemoEnvironment, useOnMount, usePreviousValue } from "@/hooks";
import { linkingConfig, navigationRef } from "@/navigationv2";
import { RootNavigator } from "@/navigationv2/navigators";
import { previousPathnameState } from "@/navigationv2/state";
import demoEnvironment from "@/utils/demoEnvironment";
import { isDemoEnvironment as recoilIsDemoEnvironment, userDetails } from "@/utils/recoil";

import CustomErrorBoundary from "./CustomErrorBoundary";
import GlobalContainer from "./GlobalContainer";
import { useHandleAuthenticatedUser, useInitialNavigationState } from "./hooks";
import { updateUserDetails } from "./utils";
import { RecordPageView } from "@/utils/analytics";
import externalEnvironment from "@/utils/externalEnvironment";
import UserAuthenticationScreen from "@/screens/UserAuthenticationScreen/UserAuthenticationScreen";
import UserInactivity from "react-native-user-inactivity";
import V3LiveWrapper from "../V3Wrapper/V3Wrapper";

const PERSISTENCE_KEY = "NAVIGATION_STATE";

/**
 * The basis for our entire application. Set up all necessary global providers,
 * wrap in an error boundary, initiate the navigation and configure the app.
 *
 * I've tried to make this component as simple as possible, with code executing
 * as linear as I could.
 *
 * @TODO: Investigate whether some of the initial app setup can be extracted.
 */
const Root = () => {
  const isDemoEnv = useIsDemoEnvironment();
  let envConfig = isDemoEnv ? demoEnvironment : Config;
  const [user, setUserDetails] = useRecoilState(userDetails);
  const [isUserActive, setIsUserActive] = useState(true);
  const [isDemoEnvironment, setIsDemoEnvironment] = useRecoilState(recoilIsDemoEnvironment);
  const [userFetchComplete, setUserFetchComplete] = useState(false);
  const [isExternal, setIsExternal] = useState<boolean>(false);
  const [currentRoute, setCurrentRoute] = useState<Route<string> | undefined>(navigationRef.current?.getCurrentRoute());
  const { initialState, isReady } = useInitialNavigationState(PERSISTENCE_KEY);
  const previousRoute = usePreviousValue<Route<string> | undefined>(currentRoute);
  const setPreviousPathname = useSetRecoilState(previousPathnameState);

  if (isExternal) {
    envConfig = externalEnvironment;
  }

  /**
   * Listen for when the navigation route has changed and update the current
   * route. The current route is used for analytics tracking.
   *
   * We also set the current route state for the navigation. This allows the
   * user to resume from the same screen they return to the app on
   * native devices.
   */
  const onNavigationStateChange = useCallback((state: NavigationState) => {
    setCurrentRoute(navigationRef.current?.getCurrentRoute());
    AsyncStorage.setItem(PERSISTENCE_KEY, JSON.stringify(state));
  }, []);

  /**
   * On web-based devices it's possible for a user to try and access a URl when
   * they aren't authenticated. If that happens, we store this URL in Recoil
   * state to fetch later and push the user to where they inteded to go.
   *
   * This is not necessary on native devices, as the only way a user can try to
   * acess a screen directly is through deep-links.
   */
  const registerPreviousUrl = useCallback(() => {
    if (Platform.OS === "web") {
      const { pathname } = window.location;

      if (pathname.indexOf("Auth") === -1) {
        setPreviousPathname(pathname);
      }
    }
  }, [setPreviousPathname]);

  /**
   * Set up the demo environment when logging in as one of the demo AMHP users.
   */
  const setupDemoEnv = useCallback(async () => {
    const isDemo = (await AsyncStorage.getItem("isDemoEnvironment")) || isDemoEnvironment;

    if (isDemo) {
      envConfig.WEB_URI = Config.WEB_URI;
    }

    if ((isDemo === "true" && !isDemoEnvironment) || isDemoEnvironment) {
      setIsDemoEnvironment(isDemo === "true");
    }

    if (isDemo === "true" && isExternal) {
      envConfig.AWS_USER_POOLS_ID = Config.AWS_EXTERNAL_USER_POOLS_ID;
      envConfig.AWS_USER_POOLS_WEB_CLIENT_ID = Config.AWS_EXTERNAL_USER_POOLS_WEB_CLIENT_ID;
    }
  }, [envConfig, isDemoEnvironment, isExternal, setIsDemoEnvironment]);

  useHandleAuthenticatedUser({ hasBooted: userFetchComplete });

  useOnMount(function configureApp() {
    const WEB_pathname = Platform.OS === "web" && window.location.pathname;
    const externalPathnamRegex = /External\/Form/;

    if (envConfig.ENABLE_ANALYTICS) {
      configureAnalytics({
        pinpointAppId: envConfig.AWS_PINPOINT_APP_ID,
        pinpointRegion: envConfig.AWS_PINPOINT_REGION,
      });
    }

    configureAuth();
    setIsExternal(WEB_pathname && externalPathnamRegex.test(WEB_pathname));
    setupDemoEnv();

    Auth.currentAuthenticatedUser({ bypassCache: false })
      .then(data => {
        updateUserDetails(data, setUserDetails);
        setUserFetchComplete(true);
      })
      .catch(() => {
        registerPreviousUrl();
        setUserDetails(null);
        setUserFetchComplete(true);
      });
  });

  useEffect(
    function setUpPushNotifications() {
      if (Platform.OS === "web" || !(isReady && userFetchComplete)) return;

      // requesting user permission and setting up push token
      configurePushNotificationToken();

      // adding notification listener for incoming remote fcm message
      foregroundNotificationsListener();

      // adding notification listener for android and ios
      if (Platform.OS === "ios") {
        PushNotificationIOS.addEventListener("localNotification", handleIncomingLocalNotificationIOS);
      } else {
        handleLocalNotificationAndroid();
      }

      return () => {
        foregroundNotificationsListener();
        Platform.OS === "ios" && PushNotificationIOS.removeEventListener("localNotification");
      };
    },
    [isReady, userFetchComplete]
  );

  /**
   * When we switch environments, credentials from the old environment will be
   * re-applied for API calls until they expire. Handle this by clearing
   * credentials when environment switches.
   */
  useEffect(
    function onEnvChange() {
      configureApi({ env: envConfig.ENV, restApiEndpoint: envConfig.AWS_HTTP_ENDPOINT });

      configureAmplify({
        awsCognitoIdentityPoolId: envConfig.AWS_COGNITO_IDENTITY_POOL_ID,
        awsCognitoRegion: envConfig.AWS_COGNITO_REGION,
        awsProjectRegion: envConfig.AWS_PROJECT_REGION,
        awsUserPoolsId: envConfig.AWS_USER_POOLS_ID,
        awsUserPoolsWebClientId: envConfig.AWS_USER_POOLS_WEB_CLIENT_ID,
        webURI: envConfig.WEB_URI,
        env: envConfig.ENV,
      });

      try {
        if (Credentials.getCredSource()) {
          Credentials.clear();
        }
      } catch (e) {
        // Do nothing. Credentials lib has a bug accessing storage when it might not exist yet.
      }
    },
    [envConfig, isDemoEnv]
  );

  useEffect(
    function onRouteChange() {
      if (currentRoute?.name) {
        RecordPageView(currentRoute.name, currentRoute.params as Record<string, string> | undefined);
      }

      if (currentRoute?.name && previousRoute?.name !== currentRoute?.name) {
        Sentry.addBreadcrumb({
          category: "nav",
          message: currentRoute.name,
        });
      }
    },
    [currentRoute, previousRoute]
  );

  useEffect(
    function onUserChange() {
      configureSentry.setUserDetails(user);
      user && AsyncStorage.setItem("userId", user.id);
    },
    [user]
  );

  /**
   * Listen for when the user fetch has completed. We fetch the user on mount,
   * and until that time we have nothing to show them. So instead of showing a
   * loader after the Splash Screen, we just delay hiding it until we have
   * finished fetching the user, regardless of whether it was successful or not.
   */
  useEffect(
    function onUserFetchChange() {
      Platform.OS !== "web" && userFetchComplete && SplashScreen.hide();
    },
    [userFetchComplete]
  );

  return (
    <CustomErrorBoundary>
      <SafeAreaProvider>
        <UserInactivity
          timeForInactivity={300000}
          onAction={(isActive: any) => {
            setIsUserActive(isActive);
          }}
        >
          {isReady && userFetchComplete && (
            <V3LiveWrapper>
              <NavigationContainer
                initialState={initialState}
                linking={linkingConfig}
                onStateChange={onNavigationStateChange}
                ref={navigationRef}
              >
                <GlobalContainer>
                  <UserAuthenticationScreen user={user} isUserActive={isUserActive} />
                  <RootNavigator isExternal={isExternal} user={user} />
                </GlobalContainer>
              </NavigationContainer>
            </V3LiveWrapper>
          )}
        </UserInactivity>
      </SafeAreaProvider>
    </CustomErrorBoundary>
  );
};

export default Root;
