import React from "react";
import { Image } from "react-native";
import { LogoProps } from "./Logo.props";
import { presets } from "./Logo.presets";

const source = require("./logo.png");
const mini = require("./logo_mini.png");

export function Logo(props: LogoProps) {
  const { preset = "small", style: styleOverride } = props;

  const presetToUse = presets[preset] || presets.small;
  const style = [presetToUse, styleOverride];

  return <Image source={preset === "mini" ? mini : source} style={style} />;
}
