import React, { useMemo } from "react";
import { StyleSheet, View } from "react-native";

import { TypographyType } from "@/models/Typography";
import { palette, spacing } from "@/theme";

import Text from "@/components/Text";

interface DoctorDetailProps {
  isWebView: boolean;
  label: string;
  value: string;
  renderDivider?: boolean;
}
export const DoctorDetail = ({ value, isWebView, label, renderDivider = true }: DoctorDetailProps) => {
  const detailStyles = useMemo(() => (isWebView ? styles.detailWeb : {}), [isWebView]);
  return (
    <React.Fragment>
      <View style={detailStyles}>
        <Text format={TypographyType.Tiny} color={palette.greyBlue} marginBottom={5}>
          {label}
        </Text>
        <Text format={TypographyType.Regular} color={palette.navy}>
          {value}
        </Text>
      </View>
      {renderDivider && <View style={styles.detailDivider} />}
    </React.Fragment>
  );
};

const styles = StyleSheet.create({
  detailWeb: {
    marginBottom: 0,
    paddingHorizontal: spacing[30],
  },

  detailDivider: {
    width: "100%",
    height: 2,
    marginVertical: 15,
    backgroundColor: palette.cloud,
  },
});
