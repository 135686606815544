import * as React from "react";
import { useRecoilValue } from "recoil";

import { LocationInput, NearbyS12DoctorsQueryVariables } from "libs/types/API";

import { RouteKeys } from "@/navigationv2";
import { searchResults as recoilSearchResults } from "@/utils/recoil/index";

import QueryMap from "./QueryMap";
import { RedirectUser } from "../../RedirectUser";

type Props = NearbyS12DoctorsQueryVariables & {
  assessmentId: string;
  location: LocationInput;
  distance: number;
};

// TODO: Loading pages, error pages, error message routing
const QueryMapGQL = (props: Props) => {
  const searchResults = useRecoilValue(recoilSearchResults);

  return searchResults ? (
    <QueryMap marker={searchResults} {...props} />
  ) : (
    <RedirectUser redirectTo={RouteKeys.AssessmentScreen} />
  );
};

export default QueryMapGQL;
