import * as Yup from "yup";
import dayjs from "dayjs";

import createDateSchema from "libs/validators/yup/createDateSchema";
import { ComponentElement, FormFieldsDoctor } from "libs/types/mhaForms";

const componentElements: ComponentElement[] = [
  {
    component: "Text",
    componentProps: {
      instruction: "I last examined this patient on",
    },
    fullWidth: true,
  },
  {
    component: "Date",
    fieldName: FormFieldsDoctor.ExaminationDate,
    componentProps: {
      label: "Date",
    },
  },
  {
    component: "Checkbox",
    fieldName: FormFieldsDoctor.Background,
    componentProps: {
      options: [
        {
          status: "previousAcquaintance",
          label: "I had previous acquaintance with the patient before I conducted that examination.",
        },
        {
          status: "approved",
          label:
            "I am approved under section 12 of the Act as having special experience in the diagnosis or treatment of mental disorder.",
        },
      ],
    },
  },
  {
    component: "Text",
    componentProps: {
      guidance: "(Check as applicable)",
      pdfOnlyGuidance: "(* Delete if not applicable)",
    },
    fullWidth: true,
  },
];

export default {
  title: "Examination Details",
  state: {
    examinationDate: "",
    background: [],
  },

  validation: Yup.object().shape({
    examinationDate: Yup.lazy(() =>
      createDateSchema({
        min: dayjs().subtract(15, "day").startOf("day"),
        max: dayjs().endOf("day"),
        required: true,
      })
    ),
    background: Yup.array(),
  }),

  componentElements,
};
