import React from "react";
import { StyleProp, View, ViewStyle } from "react-native";
import Svg, { Path } from "react-native-svg";

const Wave2 = ({ width, style }: { width: number; style?: StyleProp<ViewStyle> }) => (
  <View style={[style, { width }]}>
    <Svg width="100%" height="100%" viewBox="0 0 1279 24" fill="none">
      <Path
        opacity="0.15"
        d="M1.26468e-06 8.99993C235.972 12.5201 289.5 30.5 611.502 20.4473C888.767 11.7913 1209.16 18.3502 1280 22.9939L1280 5.34058e-05L2.05149e-06 -5.84954e-05L1.26468e-06 8.99993Z"
        fill="#1065D0"
      />
    </Svg>
  </View>
);

export default Wave2;
