import React, { useState, useMemo, useCallback } from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import Text from "../Text";
import Icon from "../Icon";
import { TypographyType } from "@/models/Typography";
import { spacing, palette } from "../../theme";

export const TimelineWrapper = (props: {
  children: React.ReactNode;
  heading?: string;
  backgroundColor?: string;
  truncated?: boolean;
  truncatedHeight?: number;
}) => {
  const { heading, backgroundColor = palette.white, truncated, truncatedHeight = 75, children } = props;
  const [isTruncated, setIsTruncated] = useState(!!truncated);

  const truncatedStyle = useMemo(() => (isTruncated ? [{ height: truncatedHeight }, styles.hidden] : {}), [
    isTruncated,
  ]);
  const containerStyle = useMemo(() => ({ backgroundColor }), [backgroundColor]);
  const gradientColours = useMemo(() => ["rgba(255, 255, 255, 0)", backgroundColor], [backgroundColor]);
  const innerTruncatedStyle = useMemo(() => [styles.row, isTruncated ? { marginTop: spacing[10] } : {}], [isTruncated]);
  const toggleTruncated = useCallback(() => setIsTruncated(old => !old), []);

  return (
    <View style={containerStyle}>
      {heading && (
        <Text format={TypographyType.Micro} style={styles.heading}>
          {heading}
        </Text>
      )}
      <View style={truncatedStyle}>{children}</View>
      {truncated && (
        <TouchableOpacity activeOpacity={1} style={innerTruncatedStyle} onPress={toggleTruncated}>
          <Icon
            name={isTruncated ? "add" : "remove"}
            color={palette.blue}
            size={16}
            style={styles.viewFullHistoryIcon}
          />
          <Text format={TypographyType.TinyBold} color={palette.blue}>
            {isTruncated ? "View Full History" : "Hide Full History"}
          </Text>
        </TouchableOpacity>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  heading: {
    marginBottom: spacing[5],
    color: palette.greyBlue,
  },
  gradient: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    height: 25,
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
  },
  hidden: {
    overflow: "hidden",
  },
  viewFullHistoryIcon: {
    marginRight: spacing[5],
  },
});
