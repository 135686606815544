import React from "react";
import { useRecoilValue } from "recoil";

import { BackButtonProps } from "@/components/BackButton/BackButton.props";
import DoctorSetupForm from "@/components/DoctorSetupForm";
import { AppScreen } from "@/layouts/AppScreen/AppScreen";
import { RouteKeys } from "@/navigationv2";
import { DoctorProfileRoutes, DoctorTabRoutes, ScreenNavigationProps, ScreenOptions } from "@/navigationv2/types";
import { createScreenTitle } from "@/navigationv2/utilities";
import { color } from "@/theme";
import { userDetails } from "@/utils/recoil/index";

interface PropTypes
  extends ScreenNavigationProps<DoctorTabRoutes, DoctorProfileRoutes, RouteKeys.EditDoctorProfileScreen> {}

const EditDoctorProfileScreen = () => {
  const user = useRecoilValue(userDetails);
  const backButtonConfig: BackButtonProps = {
    enabled: true,
    float: true,
    color: color.textWhite,
  };

  return (
    <AppScreen
      contextLabel="Edit Profile"
      pageTitle={user ? user.name : ""}
      testID="DoctorEditProfileScreen"
      backButton={backButtonConfig}
    >
      <DoctorSetupForm username={user ? user.id : ""} />
    </AppScreen>
  );
};

const screenOptions: ScreenOptions = {
  title: createScreenTitle("Edit Profile"),
};

EditDoctorProfileScreen.screenOptions = screenOptions;

export default EditDoctorProfileScreen;
