import React, { useState } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import Collapsible from "react-native-collapsible";

import { TypographyType } from "@/models/Typography";
import { palette, spacing } from "@/theme";

import Icon, { IconName } from "@/components/Icon";
import ItemSpacer from "@/components/ItemSpacer";
import { SectionDivider } from "@/components/SectionDivider";
import Text from "@/components/Text";

export const HelperText = () => {
  const [showMorePracticeOrSurgery, setShowPracticeOrSuergery] = useState<boolean>(false);
  const [showMoreCantFindResult, setShowMoreCantFindResult] = useState<boolean>(false);

  return (
    <View>
      <SectionDivider short />
      <View style={styles.infoSection}>
        <View style={styles.heading}>
          <Icon style={styles.headingIcon} size={18} name={IconName.info_outline} color={palette.greyBlue} />
          <Text format={TypographyType.SmallBold} color={palette.slate}>
            "GP Practice" vs. "GP Branch Surgery"?
          </Text>
        </View>
        <ItemSpacer gap={10}>
          <Text format={TypographyType.Small} color={palette.slate}>
            GP Practices can operate from either a single surgery or from multiple surgeries.
          </Text>
          <Text format={TypographyType.Small} color={palette.slate}>
            When a GP Practice operates from a single surgery, the GP Practice shares its name and address with the
            surgery.
          </Text>
        </ItemSpacer>
        <Collapsible collapsed={!showMorePracticeOrSurgery}>
          <ItemSpacer gap={10}>
            <Text format={TypographyType.Small} color={palette.slate} marginTop={10}>
              When a GP Practice operates from multiple surgeries, it usually has both:
            </Text>
            <Text format={TypographyType.Small} color={palette.slate}>
              - A "main surgery" (that shares it's name and address with the GP Practice), and
            </Text>
            <Text format={TypographyType.Small} color={palette.slate}>
              - One or more "branch surgeries" (that each have their own name and address)
            </Text>
            <Text format={TypographyType.Small} color={palette.slate}>
              Often, people know the name of a branch surgery, without realising the GP Practice it's related to, has a
              different name and address.
            </Text>
            <Text format={TypographyType.Small} color={palette.slate}>
              To help with this, we've provided the option to search by "GP Branch Surgery".
            </Text>
            <Text format={TypographyType.Small} color={palette.slate}>
              Simply search by "GP Branch Surgery", click on the result you want, and the S12 app will automatically
              determine the GP Practice from the selected GP Branch Surgery.
            </Text>
          </ItemSpacer>
        </Collapsible>
        <TouchableOpacity
          onPress={() => setShowPracticeOrSuergery(!showMorePracticeOrSurgery)}
          style={styles.readMoreToggle}
        >
          <Text format={TypographyType.SmallBold} color={palette.blue}>
            Read {showMorePracticeOrSurgery ? "Less" : "More"}
          </Text>
        </TouchableOpacity>
      </View>

      <View style={styles.infoSection}>
        <View style={styles.heading}>
          <Icon style={styles.headingIcon} size={18} name={IconName.info_outline} color={palette.greyBlue} />
          <Text format={TypographyType.SmallBold} color={palette.slate}>
            Can't find what you're looking for?
          </Text>
        </View>
        <ItemSpacer gap={10}>
          <Text format={TypographyType.Small} color={palette.slate}>
            Search results are provided by NHS Digital's Organisation Data Service (ODS).
          </Text>
          <Text format={TypographyType.Small} color={palette.slate}>
            If the NHS Digital ODS search isn't returning the result you're looking for, try tweaking your search:
          </Text>
        </ItemSpacer>
        <Collapsible collapsed={!showMoreCantFindResult}>
          <ItemSpacer gap={10}>
            <Text format={TypographyType.Small} color={palette.slate} marginTop={10}>
              - Add/remove "The" from the start of your search
            </Text>
            <Text format={TypographyType.Small} color={palette.slate}>
              - Add/remove apostrophes: "mary's" or, "marys"
            </Text>
            <Text format={TypographyType.Small} color={palette.slate}>
              - Add/remove punctuation: "st." or, "st "
            </Text>
            <Text format={TypographyType.Small} color={palette.slate}>
              If you are still unable to find the Practice / Branch Surgery you're looking for, feel free to contact our
              Support team.
            </Text>
          </ItemSpacer>
        </Collapsible>
        <TouchableOpacity
          onPress={() => setShowMoreCantFindResult(!showMoreCantFindResult)}
          style={styles.readMoreToggle}
        >
          <Text format={TypographyType.SmallBold} color={palette.blue}>
            Read {showMoreCantFindResult ? "Less" : "More"}
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  heading: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    flexWrap: "wrap",
    marginBottom: spacing[10],
  },
  headingIcon: {
    marginRight: spacing[5],
  },
  infoSection: {
    marginBottom: spacing[50],
  },

  readMoreToggle: {
    marginTop: spacing[10],
  },
});
