import React, { useCallback, useEffect, useState } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { useDebounce } from "use-debounce";

import { LocationInput, LocationNameInput } from "libs/types/API";
import { S12Error } from "@/models/Error";
import { TypographyType } from "@/models/Typography";
import { useDidUpdate } from "@/hooks";
import { palette, spacing } from "@/theme";

import Text from "@/components/Text";
import { LocationSearchItem, RetrieveDataObject } from "./LocationSearch.props";
import { LocationSuggestion } from "./LocationSuggestion";

interface LocationSearchPropTypes {
  searchQuery: string;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
  searchResults: LocationSearchItem[];
  setSearchResults: React.Dispatch<React.SetStateAction<LocationSearchItem[]>>;
  initialResults?: LocationSearchItem[];
  retrieveData(data: RetrieveDataObject): void;
  getResults: (term?: string, addressId?: string) => Promise<LocationSearchItem[]>;
}

export function LocationSearch(props: LocationSearchPropTypes) {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [resultsLength, setResultsLength] = useState<number | null>(null);
  const [showInstruction, setShowInstruction] = useState<boolean>(false);
  const [debouncedSearchQuery] = useDebounce(props.searchQuery, 500);

  const search = useCallback(
    (term: string | null, id?: string) => {
      if (!term && !id && (!debouncedSearchQuery || debouncedSearchQuery.length < 4)) {
        props.setSearchResults(props.initialResults ? props.initialResults.map(r => ({ ...r, selected: true })) : []);
        return;
      }
      setResultsLength(null);
      props
        .getResults(id ? undefined : term || debouncedSearchQuery, id)
        .then(data => {
          setErrorMessage(null);
          props.setSearchResults(data);
          setResultsLength(data.length);
          if (data.length === 1 && data[0].selected && props.retrieveData) {
            props.retrieveData({
              location: data[0].location as LocationInput,
              description: data[0].itemText as string,
              locationName: data[0].locationName as LocationNameInput,
              ccg: data[0].ccg,
            });
          }
        })
        .catch((e: Error | S12Error) => setErrorMessage(e instanceof S12Error ? e.message : "Error finding addresses"));
    },
    [debouncedSearchQuery, props.getResults, props.retrieveData]
  );

  const onResultPress = useCallback(
    (result, i?: number) => {
      if ((result.isExpandable || !result.selected) && result.itemText) {
        setShowInstruction(result.isExpandable || false);
        search(null, result.id);
      } else if (result.selected) {
        if (props.retrieveData) {
          props.retrieveData({
            location: result.location as LocationInput,
            description:
              result.itemText ||
              ((result.locationName &&
                [result.locationName.address, result.locationName.city, result.locationName.postcode].join(
                  ", "
                )) as string),
            locationName: result.locationName as LocationNameInput,
            ccg: result.ccg ? result.ccg : undefined,
          });
        }
      }
    },
    [search, props.retrieveData]
  );

  useEffect(() => {
    if (props.initialResults) {
      props.setSearchResults(props.initialResults.map(result => ({ ...result, selected: true })));
    }
  }, [props.initialResults]);

  useEffect(
    function perFormSearch() {
      search(debouncedSearchQuery);
    },
    [debouncedSearchQuery]
  );

  useDidUpdate(
    function resetResultsOnValueChange() {
      props.setSearchResults([]);
    },
    [props.searchQuery]
  );

  return (
    <>
      {!!errorMessage && (
        <Text format={TypographyType.Tiny} type="paper" style={styles.errorMessage}>
          {errorMessage}
        </Text>
      )}

      <View style={styles.searchResults}>
        {showInstruction && props.searchResults.length > 0 && (
          <Text format={TypographyType.Small} type="paper" marginBottom={spacing[10]} color={palette.slate}>
            You must pick an address below
          </Text>
        )}

        {props.searchQuery.length > 0 && props.searchResults.length === 0 && (
          <Text format={TypographyType.Regular} align="center" marginTop={spacing[60]} color={palette.greyBlue}>
            {resultsLength === 0 ? "No results found" : "Searching . . ."}
          </Text>
        )}

        {props.searchResults.map((s: LocationSearchItem, i: number) => (
          <TouchableOpacity key={i} activeOpacity={0.6} style={styles.rowBorder} onPress={() => onResultPress(s, i)}>
            <LocationSuggestion suggestion={s} />
          </TouchableOpacity>
        ))}
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  errorMessage: {
    paddingLeft: spacing[15],
    paddingVertical: spacing[10],
    color: palette.red,
  },

  loadingContainer: {
    width: "100%",
    height: "100%",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },

  searchResults: {
    padding: spacing[20],
    paddingBottom: 0,
  },

  searchResultsHeading: {
    paddingBottom: spacing[15],
  },

  rowBorder: {
    borderBottomWidth: 1,
    borderBottomColor: palette.grey,
  },
});
