import React from "react";
import { View, StyleSheet } from "react-native";

import { TypographyType } from "@/models/Typography";
import Text from "../Text";
import { spacing, color } from "../../theme";

const SmallDescription = (props: { label: string; children?: string | JSX.Element; testID?: string }) => {
  const testID = props.testID || props.label.replace(/\s/g, "");

  return (
    <View testID={`SmallDescription__${testID}`}>
      <Text format={TypographyType.Micro} style={styles.label} testID={`SmallDescription-Label__${testID}`}>
        {props.label}
      </Text>
      {typeof props.children === "object" ? (
        props.children
      ) : (
        <Text format={TypographyType.Tiny} testID={`MiniDescription-Text__${testID}`}>
          {props.children || ""}
        </Text>
      )}
    </View>
  );
};

export default SmallDescription;

const styles = StyleSheet.create({
  label: {
    marginBottom: spacing[5],
    color: color.textExtraLight,
  },
});
