/* eslint-disable react-native/no-unused-styles */
import React from "react";
import { StyleSheet, TextStyle, ViewStyle, View } from "react-native";

import { TypographyType } from "@/models/Typography";
import { mqWeb } from "../../utils/helpers";
import { Icon, Text } from "../../components";
import { palette, spacing } from "../../theme";

interface FilterLabelProps {
  text: string;
  disableMargin?: "none" | "top" | "bottom" | "topAndBottom";
}

const FilterLabel = ({ text, disableMargin = "none" }: FilterLabelProps) => {
  const isWebView = mqWeb();

  // Text between asterisks will be *bold* after this formatting
  const boldifiedText = text.split("*").map((string, index) =>
    string.match(/^[a-z](.*[a-z])?$/gim) ? (
      <Text key={index} format={TypographyType.MicroBold} color={palette.greyBlue}>
        {string}
      </Text>
    ) : (
      <Text key={index} format={TypographyType.Micro} color={palette.greyBlue}>
        {string}
      </Text>
    )
  );

  const marginStyles = styles[`${disableMargin}MarginDisabled`];

  return (
    <View style={[styles.container, !isWebView && styles.containerMobile, marginStyles]}>
      <Icon size={16} name="filter-list" color={palette.greyBlue} style={styles.icon} />
      <View style={styles.label}>{boldifiedText}</View>
    </View>
  );
};

const styles: { [key: string]: TextStyle | ViewStyle } = StyleSheet.create({
  container: {
    marginTop: spacing[20],
    marginBottom: spacing[20],
    flexDirection: "row",
    alignItems: "flex-start",
  },
  containerMobile: {
    marginBottom: spacing[10],
  },

  icon: {
    marginRight: spacing[10],
  },

  label: {
    flexDirection: "row",
    transform: [{ translateY: -1 }],
  },

  topMarginDisabled: { marginTop: 0 },
  bottomMarginDisabled: { marginBottom: 0 },
  topAndBottomMarginDisabled: { marginTop: 0, marginBottom: 0 },
});

export default FilterLabel;
