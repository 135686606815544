import React, { useMemo } from "react";
import { View, StyleSheet } from "react-native";
import Icon from "../Icon";
import { spacing, palette } from "../../theme";

interface TimelineElementProps {
  children: React.ReactNode;
  color?: string;
  small?: boolean;
  filled?: boolean;
  completed?: boolean;
  lastElement?: boolean;
}

export const TimelineElement = (props: TimelineElementProps) => {
  const { children, color = palette.aqua, small = false, filled, completed, lastElement = false } = props;
  const circleSize = useMemo(() => (small ? 12 : 18), [small]);

  const circleStyles = useMemo(
    () => [
      styles.circle,
      {
        width: circleSize,
        height: circleSize,
        borderColor: color,
        backgroundColor: completed || filled ? color : palette.transparent,
      },
    ],
    [color, completed, filled, circleSize]
  );
  const circleWrapperStyles = useMemo(() => [styles.circleWrapper, { transform: [{ translateY: small ? 5 : -1 }] }], [
    small,
  ]);

  return (
    <View style={styles.row}>
      <View style={circleWrapperStyles}>
        <View style={circleStyles}>
          {completed && <Icon name="check" size={circleSize / 1.5} color={palette.white} />}
        </View>
        {!lastElement && <View style={[styles.line, { backgroundColor: color }]} />}
      </View>
      <View style={[styles.textContainer, small && styles.textContainerSmall]}>{children}</View>
    </View>
  );
};

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
  },
  circleWrapper: {
    marginRight: spacing[10],
    alignItems: "center",
  },
  circle: {
    borderWidth: 2,
    borderRadius: 100,
    justifyContent: "center",
    alignItems: "center",
  },
  line: {
    width: 2,
    flex: 1,
  },
  textContainer: {
    flex: 1,
    paddingBottom: spacing[10],
  },
  textContainerSmall: {
    flex: 1,
    paddingBottom: spacing[5],
  },
});
