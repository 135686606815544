import * as React from "react";
import { StyleSheet, Text } from "react-native";
import { DataTable } from "react-native-paper";
import { GQL_LIMITS } from "@/models/gql/limits";

const defaultPageSize = GQL_LIMITS.ccgClaims;
interface TablePaginatorProps {
  currentPage: number;
  pageSize: number;
  total: number;
  handlePageChange: (page: number) => void;
}

const TablePaginator: React.FC<TablePaginatorProps> = ({
  currentPage,
  pageSize = defaultPageSize,
  total,
  handlePageChange,
}) => {
  const from = total ? currentPage * pageSize + 1 : 0;
  let to = (currentPage + 1) * pageSize;
  if (to > total) {
    // Handle records on the last page
    to = total;
  }
  return (
    <DataTable style={styles.container}>
      <DataTable.Pagination
        page={currentPage}
        numberOfPages={Math.ceil(total / pageSize)}
        onPageChange={page => handlePageChange(page)}
        label={
          <Text style={styles.text}>
            from {from}-{to} of {total}
          </Text>
        }
      />
    </DataTable>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
  },
  text: {
    fontSize: 15,
  },
});
export default TablePaginator;
