import React from "react";
import { StyleProp, StyleSheet, TouchableOpacity, ViewStyle } from "react-native";

import { palette } from "../../../theme";
import { ContainIcon, CoverIcon } from "../icons";

interface VideoTileFitToggleProps {
  fit: "contain" | "cover";
  onPress: () => void;
  style?: StyleProp<ViewStyle>;
}

export const VideoTileFitToggle: React.FC<VideoTileFitToggleProps> = props => {
  const Icon = props.fit === "contain" ? CoverIcon : ContainIcon;
  return (
    <TouchableOpacity style={[styles.toggle, props.style && props.style]} onPress={props.onPress}>
      <Icon size={16} />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  toggle: {
    padding: 8,
    borderRadius: 6,
    backgroundColor: palette.blue,
  },
});
