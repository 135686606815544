import React, { useEffect, useState, useCallback } from "react";
import { RefreshControl } from "react-native";
import { useRecoilState } from "recoil";

import UserDashboard from "@/components/UserDashboard";
import { AppScreen } from "@/layouts/AppScreen/AppScreen";
import { RouteKeys } from "@/navigationv2";
import { AMHPDashboardRoutes, AMHPTabRoutes, ScreenNavigationProps, ScreenOptions } from "@/navigationv2/types";
import { createScreenTitle } from "@/navigationv2/utilities";

import { redirectUserAfterLogin } from "./utils";
import { useOnScreenFocus } from "@/hooks";
import { refreshTeamInboxRequired, refreshAmhpDashboardRequired } from "@/utils/recoil";

const UserDashboardMemo = React.memo(UserDashboard);

interface PropTypes extends ScreenNavigationProps<AMHPTabRoutes, AMHPDashboardRoutes, RouteKeys.UserDashboardScreen> {}

const UserDashboardScreen = () => {
  /**
   * If the user has tried to access a previous url but was unauthenticated,
   * they are dumped to the login screen where `previousUrl` is set. Then on
   * successful log in, this is passed to the screen where a hard redirect
   * is made to the previous route.
   */
  const [teamInboxRefresh, setTeamInboxRefresh] = useRecoilState(refreshTeamInboxRequired);
  const [refreshDashboardRequired, setRefreshDashboard] = useRecoilState(refreshAmhpDashboardRequired);
  const [isUnmounted, setIsUnmounted] = useState(false);
  const onRefresh = useCallback(() => {
    if (!isUnmounted) {
      setTeamInboxRefresh(true);
      setRefreshDashboard(true);
    }
  }, [isUnmounted]);

  useEffect(() => {
    redirectUserAfterLogin();
    return () => setIsUnmounted(true);
  }, []);

  useOnScreenFocus(() => {
    setTeamInboxRefresh(true);
    setRefreshDashboard(true);
  });

  return (
    <AppScreen
      contextLabel="S12 Solutions"
      pageTitle="Dashboard"
      testID="UserDashboardScreen"
      refreshControl={
        <RefreshControl refreshing={teamInboxRefresh || refreshDashboardRequired} onRefresh={onRefresh} />
      }
    >
      <UserDashboardMemo />
    </AppScreen>
  );
};

const screenOptions: ScreenOptions = {
  title: createScreenTitle("My Dashboard"),
};

UserDashboardScreen.screenOptions = screenOptions;

export default UserDashboardScreen;
