import React, { useMemo } from "react";
import { Platform, StyleSheet, View } from "react-native";
import { Badge, TouchableRipple } from "react-native-paper";
import { Hoverable } from "react-native-web-hooks";

import Text from "../Text";
import { TypographyType } from "@/models/Typography";

import { palette } from "../../theme";

interface TabProps {
  label: string;
  badgeCount: number;
  active: boolean;
  onPress: () => void;
}

export const Tab = ({ label, badgeCount, active, onPress }: TabProps) => {
  const outerStyle = useMemo(() => [styles.tabContainer, active ? styles.active : styles.inActive], [active]);

  return (
    <Hoverable>
      {isHovered => {
        return (
          <View style={[outerStyle, isHovered && !active && styles.hover]}>
            <TouchableRipple onPress={onPress} style={styles.touchable}>
              <View style={styles.row}>
                <Text format={TypographyType.MicroBold} color={active ? palette.blue : palette.navy}>
                  {label.toUpperCase()}
                </Text>
                {badgeCount > 0 && (
                  <Badge size={12} style={styles.badge}>
                    {badgeCount}
                  </Badge>
                )}
              </View>
            </TouchableRipple>
          </View>
        );
      }}
    </Hoverable>
  );
};

const styles = StyleSheet.create({
  active: {
    backgroundColor: palette.white,
    borderTopColor: palette.blue,
    ...Platform.select({
      web: { cursor: "default" },
    }),
  },
  inActive: {
    backgroundColor: palette.cloud,
    borderTopColor: palette.grey,
  },
  hover: {
    backgroundColor: palette.grey,
  },
  tabContainer: {
    borderTopWidth: 2,
    flex: 1,
    marginLeft: 2,
  },
  touchable: {
    paddingVertical: 16,
    justifyContent: "center",
    alignItems: "center",
  },
  badge: {
    position: "absolute",
    top: -5,
    right: -10,
  },
  row: {
    flexDirection: "row",
  },
});
