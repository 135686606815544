import * as Yup from "yup";
import { ComponentElement, FormFieldsAmhp } from "libs/types/mhaForms";
import { FormSection } from "../..";
import { isValidPhone } from "libs/validators";

const componentElements: ComponentElement[] = [
  {
    component: "Text",
    componentProps: {
      instruction: "I",
      guidance: "(PRINT your full name)",
    },
    fullWidth: true,
  },
  {
    component: "TextInput",
    fieldName: FormFieldsAmhp.AmhpName,
    componentProps: {
      label: "Name",
    },
  },
  {
    component: "Text",
    componentProps: {
      instruction: "Contact telephone number for AMHP",
      guidance: "(PRINT your contact telephone number)",
    },
    fullWidth: true,
  },
  {
    component: "TextInput",
    fieldName: FormFieldsAmhp.AmhpContactNumber,
    componentProps: {
      label: "Contact Number",
      underlabel: "Changes made here apply to all subsequent forms.",
    },
  },
  {
    component: "Text",
    componentProps: {
      instruction: "from AMHP Team",
      guidance: "(PRINT name of your AMHP team)",
    },
    fullWidth: true,
  },
  {
    component: "TextInput",
    fieldName: FormFieldsAmhp.AmhpTeamName,
    componentProps: {
      label: "AMHP Team",
    },
  },
];

const v: FormSection = {
  title: "Your Details",
  state: {
    amhpName: "",
    amhpContactNumber: "",
    amhpTeamName: "",
  },

  validation: Yup.object().shape({
    amhpName: Yup.string().trim().required("Please enter your name"),
    amhpContactNumber: Yup.string()
      .trim()
      .required("Please enter a phone number")
      .test({
        name: "contactNumber",
        test: (value) => isValidPhone(value),
        message: "please enter a valid phone number",
      }),
    amhpTeamName: Yup.string().trim().required("Please enter your amhp team name"),
  }),

  componentElements,
};

export default v;
