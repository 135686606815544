import { Dayjs } from "dayjs";
import { DateSchema } from "yup";

import { SHORT_DATE_FORMAT } from "libs/dates/format";

import * as Yup from "./index";
import { BaseSchema, mergeSchemas } from "./baseSchema";

export interface CreateDateSchema extends BaseSchema {
  dateFormat?: string;
  max?: Dayjs | null;
  min?: Dayjs | null;
}

/**
 * Create a Yup validation date schema with some handy default options.
 *
 * @param options
 */
export default function createDateSchema(options: CreateDateSchema = {}): DateSchema {
  const {
    dateFormat = SHORT_DATE_FORMAT,
    max = null,
    min = null,
    required = false,
    requiredMessage = "Please add a date",
  } = options;

  const schemas = [Yup.date()];

  required && schemas.push(Yup.date().required(requiredMessage));
  max && schemas.push(Yup.date().max(max.toDate(), `Please select a date on or before ${max.format(dateFormat)}`));
  min && schemas.push(Yup.date().min(min.toDate(), `Please select a date on or after ${min.format(dateFormat)}`));

  return mergeSchemas(...schemas);
}
