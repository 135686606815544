import { Dayjs } from "dayjs";
import { ClaimStatus } from "libs/types/API";
import { NextToken } from "libs/types/graphql";
import { CCGClaim } from "libs/types/claim";

export type ClaimModalType = "export" | "assign" | "reassign" | "approve" | "byRange" | "pay";

export interface GetCCGClaimsResponse {
  claims?: {
    items?: CCGClaim[];
    nextToken: string | null;
  };
}
export interface CCGClaimsResponse {
  findClaims?: {
    items?: CCGClaim[];
    nextToken: string | null;
    total: number;
  };
}

export interface GetCCGClaimsResponseWithItems {
  claims?: {
    items: Array<CCGClaim | null> | null;
    nextToken: string | null;
  };
}
export interface CCGClaimsResponseWithItems {
  findClaims?: {
    items: Array<CCGClaim | null> | null;
    nextToken: string | null;
    total: number;
  };
}

export interface GetUserDataResponse {
  data: GetCCGClaimsResponse;
}

export interface CCGFilterOption {
  name: string;
  id: string;
}

export interface ClaimProcessingDetailsScreenProps {
  claimId: string;
}

export interface ClaimProcessingSearchScreenProps {
  displayId: string;
}

export interface RangeValues {
  from: Dayjs;
  to: Dayjs;
}

export interface ClaimProcessingQueryVariables {
  orgId?: string;
  userId: string;
  nextToken: NextToken;
  limit?: number;
  csuId?: string;
  show?: ClaimStatus.approved;
  status?: ClaimStatus;
  type?: string;
}

export const doctorConfText = {
  dpt_inside_contracted_hours: "Inside Hours",
  dpt_outside_contracted_hours: "Outside Hours",
  independent_capacity: "Independent",
};

export const doctorEmpStatusText = {
  payroll: "Yes",
  non_payroll: "No",
};
