import * as React from "react";
import { Platform, SafeAreaView, StyleSheet, View } from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scrollview";

import BackButton from "@/components/BackButton/BackButton";
import { BackButtonProps } from "@/components/BackButton/BackButton.props";
import { color } from "@/theme/color";

interface PropTypes {
  children: any;
  style?: any;
  backButton?: BackButtonProps;
}

export const SafeAreaScrollContainer = React.forwardRef((props: PropTypes, ref: (ref: any) => void) => {
  const showBackButton = props.backButton && props.backButton.enabled && Platform.OS !== "web";

  return (
    <SafeAreaView style={styles.container}>
      <KeyboardAwareScrollView
        keyboardDismissMode="interactive"
        keyboardShouldPersistTaps="handled"
        contentContainerStyle={styles.scrollview}
        automaticallyAdjustContentInsets={false}
        ref={ref}
      >
        {showBackButton && props.backButton && <BackButton {...props.backButton} />}
        <View style={styles.content}>{props.children}</View>
      </KeyboardAwareScrollView>
    </SafeAreaView>
  );
});

SafeAreaScrollContainer.displayName = "SafeAreaScrollContainer";

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  scrollview: {
    flexGrow: 1,
    justifyContent: "center",
  },
  content: {
    flexGrow: 1,
    justifyContent: "flex-start",
    backgroundColor: color.background,
  },
});
