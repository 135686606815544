import React from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { FormStatus } from "libs/types/API";
import { FormType } from "libs/types/mhaForms";

import { Button } from "../../Button";
import ItemSpacer from "../../ItemSpacer";
import { VerticalSpacer } from "../../Spacer";
import SplitSection from "../../SplitSection/SplitSection";
import AssignForReview from "./AssignForReview";
import AttachToAssessment from "../../UserDashboard/modules/AttachToAssessment";

import { ProgressBar, ContentWrap, Text } from "../../../components";
import { TypographyType } from "@/models/Typography";
import { palette } from "../../../theme/palette";
import { spacing } from "../../../theme/spacing";
import {
  mhaFormApiStatus,
  selectedAmhpTeamId,
  // lastException,
  // snackbarMessage,
} from "../../../utils/recoil";
import { useBottomSheet } from "@/hooks";
import { UserDetails } from "../../../utils/recoil/props";
import { MHAFormMetadata, FormikValues } from "@/models/MHAForm/MHAForm";
import { SectionInfo, actionsForForm } from "./helpers";
import { RouteKeys } from "@/navigationv2";
import { ParamListBase, useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";

interface BottomContentPropTypes {
  activeSection: number;
  isWebView: boolean;
  sectionInfo: SectionInfo[];
  onSelectSection?: (index: number) => void;
}

export const BottomContent = React.memo(
  (props: BottomContentPropTypes) => {
    const { activeSection, isWebView, onSelectSection, sectionInfo } = props;

    return isWebView ? (
      <SplitSection
        aside={<MemoView />}
        main={
          <View style={styles.bottomContainer}>
            <ProgressBar activeSection={activeSection} sections={sectionInfo} />
          </View>
        }
      />
    ) : (
      <View style={styles.bottomContainer}>
        <ProgressBar activeSection={activeSection} onSelectSection={onSelectSection} sections={sectionInfo} />
      </View>
    );
  },
  (prev, next) => {
    if (prev.activeSection !== next.activeSection) {
      return false;
    } else if (prev.sectionInfo.length !== next.sectionInfo.length) {
      return false;
    } else if (!prev.sectionInfo.every(o => next.sectionInfo.includes(o))) {
      return false;
    } else if (!next.sectionInfo.every(o => prev.sectionInfo.includes(o))) {
      return false;
    } else if (prev.isWebView !== next.isWebView) {
      return false;
    }

    return true;
  }
);
BottomContent.displayName = "BottomContent";

interface AsideContentPropTypes {
  activeSection: number;
  formType: FormType;
  isFormOwner: boolean;
  onSelectSection: (id: number) => void;
  saveAndExit: () => void;
  sections: string[];
  shouldSaveProgress?: boolean;
  isUnattachedForm?: boolean;
  isExternalDoctor?: boolean;
}

export const AsideContent = React.memo(
  (props: AsideContentPropTypes) => {
    const {
      activeSection,
      shouldSaveProgress = true,
      onSelectSection,
      saveAndExit,
      sections,
      isExternalDoctor,
    } = props;

    const submitStatus = useRecoilValue(mhaFormApiStatus);
    const canSubmit = shouldSaveProgress && !submitStatus.inProgress;

    return (
      <View>
        <View>
          {sections.map((section, i) => (
            <Text
              key={i}
              format={i !== activeSection ? TypographyType.RegularBold : TypographyType.HeadingSmall}
              onPress={onSelectSection.bind(null, i)}
              color={i !== activeSection ? palette.blue : palette.shadowDark}
              style={{ marginBottom: spacing[20] }}
            >
              {`${i + 1}. ${section}`}
            </Text>
          ))}
        </View>

        <Button
          disabled={!canSubmit}
          minDisabledTime={1000}
          mode="outlined"
          onPress={saveAndExit}
          style={styles.saveAndExit}
        >
          {`Save ${isExternalDoctor ? "Progress" : "and Exit"}`}
        </Button>
      </View>
    );
  },
  (old, props) => {
    if (old.activeSection !== props.activeSection) {
      return false;
    } else if (old.shouldSaveProgress !== props.shouldSaveProgress) {
      return false;
    } else if (old.sections.length !== props.sections.length) {
      return false;
    } else if (!old.sections.every(o => props.sections.includes(o))) {
      return false;
    } else if (!props.sections.every(o => old.sections.includes(o))) {
      return false;
    }

    return true;
  }
);
AsideContent.displayName = "AsideContent";

export const ReviewActionButtons = (props: {
  formType: FormType;
  formStatus: FormStatus | undefined;
  formData: FormikValues;
  formMetadata: Omit<MHAFormMetadata, "notes">;
  userDetails: UserDetails | null;
  isEditable: boolean;
  saveNewStatus: (formStatus: FormStatus, successMessage: string) => Promise<void>;
  goBack: () => void;
  isUnattachedForm?: boolean;
}) => {
  const { openBottomSheet, closeBottomSheet } = useBottomSheet();
  const navigation = useNavigation<StackNavigationProp<ParamListBase>>();

  const setSelectedAmhpTeamId = useSetRecoilState(selectedAmhpTeamId);

  if (!props.formStatus) {
    return <></>;
  }

  const attachToAssessment =
    props.formStatus === FormStatus.s60_signed_in_amhp_team_inbox && !!props.userDetails?.groups.includes("AMHP");

  const sendToAMHPTeam = () => {
    props.formMetadata.amhpTeamId && setSelectedAmhpTeamId(props.formMetadata.amhpTeamId);
    openBottomSheet({
      type: "generic",
      data: {
        heading: "Send to AMHP Team",
        component: AssignForReview,
        componentProps: {
          formData: props.formData,
          metadata: props.formMetadata,
          onSuccess: () => {
            closeBottomSheet();
            navigation.navigate(RouteKeys.DoctorProfileScreen);
          },
        },
      },
    });
  };

  // This is the absolute BEST we can do under the current navigation architecture sadly.
  const { routes } = navigation.dangerouslyGetState();

  // copy routes so not to mutate them, reverse the copy to place latest routes first, find last visit and extract visitId
  const doctorVisit: any = [...routes].reverse().find(r => r.name === "DoctorVisitDetailsScreen");
  const visitId = doctorVisit?.params?.visitId;

  return (
    <ContentWrap>
      <VerticalSpacer height={15} />
      <ItemSpacer gap={15}>
        {actionsForForm(
          props.formType,
          props.formStatus,
          props.formMetadata,
          props.userDetails,
          props.formMetadata.authors,
          props.formMetadata.createdBy
        ).map((action, i, arr) => (
          <Button
            key={i}
            mode={arr.length > 1 && i === 0 ? "outlined" : "contained"}
            onPress={() =>
              props.isUnattachedForm ? sendToAMHPTeam() : props.saveNewStatus(action.status, action.text + " Complete")
            }
            style={arr.length > 1 && i === 0 && { backgroundColor: "white" }}
          >
            {action.text}
          </Button>
        ))}
      </ItemSpacer>
      <VerticalSpacer height={15} />
      {props.isEditable && (
        <Button mode="text" onPress={props.goBack}>
          Make Edits
        </Button>
      )}
      {attachToAssessment && (
        <Button
          disabled={false}
          onPress={() => {
            const { formData, formMetadata } = props;
            openBottomSheet({
              type: "generic",
              data: {
                heading: "Attach Forms",
                component: AttachToAssessment,
                componentProps: {
                  form: formData,
                  formMetadata,
                  navigation,
                },
              },
            });
          }}
        >
          Attach to Assessment
        </Button>
      )}
      <Button
        key="backNavigationAssessment"
        mode="outlined"
        onPress={() =>
          props.isUnattachedForm
            ? props.userDetails?.groups.includes("Doctor")
              ? navigation.navigate(RouteKeys.DoctorProfileScreen)
              : navigation.navigate(RouteKeys.UserDashboardScreen)
            : props.userDetails?.groups.includes("Doctor")
            ? visitId
              ? navigation.navigate(RouteKeys.DoctorVisitDetailsScreen, {
                  visitId,
                })
              : navigation.navigate(RouteKeys.DoctorVisitScreen)
            : navigation.navigate(RouteKeys.AssessmentDetailsScreen, {
                assessmentId: props.formMetadata.assessmentId,
              })
        }
        style={{ backgroundColor: "white" }}
      >
        {props.isUnattachedForm
          ? props.userDetails?.groups.includes("Doctor")
            ? "Return to profile"
            : "Return to dashboard"
          : props.userDetails?.groups.includes("Doctor")
          ? visitId
            ? "Return to visit"
            : "Return to visits"
          : "Return to assessment"}
      </Button>
    </ContentWrap>
  );
};

interface ActionButtonsPropTypes {
  activeSection: number;
  formType: FormType;
  isFormOwner: boolean;
  isLastSection: boolean;
  isWebView: boolean;
  review: () => void;
  save: (advance?: 1 | -1) => void;
  sectionInfo: SectionInfo[];
  saveAndExit: () => void;
  submit: () => void;
  shouldSaveProgress?: boolean;
  isUnattachedForm?: boolean;
  isExternalDoctor?: boolean;
}

export const ActionButtons = React.memo(
  (props: ActionButtonsPropTypes) => {
    const { activeSection, shouldSaveProgress = true, isLastSection, isWebView, review, save, sectionInfo } = props;
    const submitStatus = useRecoilValue(mhaFormApiStatus);
    const reviewDisabled = sectionInfo.filter(section => !section.valid).length > 0;

    return (
      <ContentWrap>
        {isLastSection ? (
          <>
            <Button
              disabled={reviewDisabled}
              onPress={() => {
                if (!submitStatus.inProgress) {
                  save();
                }
                review();
              }}
            >
              Review Form
            </Button>

            {reviewDisabled && (
              <Text format={TypographyType.Tiny} style={styles.reviewButtonDisabledText} color={palette.greyBlue}>
                All form sections must be valid and complete before review is available.
              </Text>
            )}
          </>
        ) : (
          <Button onPress={save.bind(null, 1)}>Next</Button>
        )}

        {!isWebView && (
          <>
            <Button
              disabled={!shouldSaveProgress && !submitStatus.inProgress}
              minDisabledTime={1000}
              mode="outlined"
              onPress={props.saveAndExit}
            >
              {`Save ${props.isExternalDoctor ? "Progress" : "and Exit"}`}
            </Button>
          </>
        )}

        <View style={styles.goBackContainer}>
          <TouchableOpacity disabled={activeSection === 0} onPress={save.bind(null, -1)} style={styles.goBackButton}>
            <Text
              format={activeSection === 0 ? TypographyType.Small : TypographyType.SmallBold}
              color={activeSection === 0 ? palette.greyBlue : palette.blue}
            >
              Go Back
            </Text>
          </TouchableOpacity>
        </View>
      </ContentWrap>
    );
  },
  (prev, next) => {
    return (
      prev.activeSection === next.activeSection &&
      prev.shouldSaveProgress === next.shouldSaveProgress &&
      prev.isLastSection === next.isLastSection &&
      prev.isWebView === next.isWebView &&
      deepEqual(prev.sectionInfo, next.sectionInfo)
    );
  }
);
ActionButtons.displayName = "ActionButtons";

const styles = StyleSheet.create({
  goBackContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  goBackButton: {
    padding: spacing[5],
  },
  bottomContainer: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
  },
  flex: {
    flex: 1,
  },
  reviewButtonDisabledText: {
    marginTop: spacing[5] * -1,
    paddingBottom: spacing[10],
    textAlign: "center",
  },
  saveAndExit: {
    width: "80%",
    marginTop: spacing[20],
    alignSelf: "flex-start",
  },
});

const MemoView = React.memo(() => <View />);

function deepEqual(obj1: any, obj2: any) {
  if (obj1 === obj2) {
    return true;
  } else if (isObject(obj1) && isObject(obj2)) {
    if (Object.keys(obj1).length !== Object.keys(obj2).length) {
      return false;
    }
    for (const prop in obj1) {
      if (!deepEqual(obj1[prop], obj2[prop])) {
        return false;
      }
    }
    return true;
  } else {
    return false;
  }

  // Private
  function isObject(obj: any) {
    if (typeof obj === "object" && obj != null) {
      return true;
    } else {
      return false;
    }
  }
}
