import * as Yup from "yup";
import createPostcodeSchema from "libs/validators/yup/createPostcodeSchema";
import { ComponentElement, FormFieldsDoctor } from "libs/types/mhaForms";

const v1ComponentElements: ComponentElement[] = [
  {
    component: "Text",
    componentProps: { pdfOnlyGuidance: "THIS FORM IS TO BE USED ONLY FOR AN EMERGENCY APPLICATION " },
  },
  {
    component: "Text",
    componentProps: {
      instruction: "I",
      guidance: "(PRINT full name and address of medical practitioner)",
    },
    fullWidth: true,
  },
  {
    component: "TextInput",
    fieldName: FormFieldsDoctor.PractitionerName,
    componentProps: {
      label: "Name",
    },
  },
  {
    component: "Location",
    fieldName: FormFieldsDoctor.PractitionerAddress,
    componentProps: {
      label: "Address",
      addressChangeWarning:
        "Address changes made here will automatically be applied to all future MHA forms containing your address.",
    },
  },
];

const v2ComponentElements: ComponentElement[] = [
  {
    component: "Text",
    componentProps: { pdfOnlyGuidance: "THIS FORM IS TO BE USED ONLY FOR AN EMERGENCY APPLICATION " },
  },
  {
    component: "Text",
    componentProps: {
      instruction: "I",
      guidance:
        "(PRINT full name, address and, if sending by means of electronic communication, email address of medical practitioner)",
    },
    fullWidth: true,
  },
  {
    component: "TextInput",
    fieldName: FormFieldsDoctor.PractitionerName,
    componentProps: {
      label: "Name",
    },
  },
  {
    component: "Location",
    fieldName: FormFieldsDoctor.PractitionerAddress,
    componentProps: {
      label: "Address",
      addressChangeWarning:
        "Address changes made here will automatically be applied to all future MHA forms containing your address.",
    },
  },
  {
    component: "TextInput",
    fieldName: FormFieldsDoctor.PractitionerEmailAddress,
    componentProps: {
      label: "Email Address",
      underlabel:
        "Email Address changes made here will automatically be applied to all future MHA forms containing your email address.",
    },
  },
];

const v1 = {
  title: "Your Details",
  state: {
    practitionerName: "",
    practitionerAddress: {
      city: "",
      postcode: "",
      address: "",
    },
  },

  validation: Yup.object().shape({
    practitionerName: Yup.string().trim().required("Please enter your name"),

    practitionerAddress: Yup.object().shape({
      address: Yup.string(),
      city: Yup.string(),
      postcode: createPostcodeSchema({
        invalidPostcodeMessage: "Please enter a valid address",
        required: true,
        requiredMessage: "Please enter an address",
      }),
    }),
  }),

  componentElements: v1ComponentElements,
};

const v2 = {
  title: "Your Details",
  state: {
    practitionerName: "",
    practitionerAddress: {
      city: "",
      postcode: "",
      address: "",
    },
    practitionerEmailAddress: "",
  },

  validation: Yup.object().shape({
    practitionerName: Yup.string().trim().required("Please enter your name"),
    practitionerAddress: Yup.object().shape({
      address: Yup.string(),
      city: Yup.string(),
      postcode: createPostcodeSchema({
        invalidPostcodeMessage: "Please enter a valid address",
        required: true,
        requiredMessage: "Please enter an address",
      }),
    }),
    practitionerEmailAddress: Yup.string()
      .trim()
      .email("Please enter a valid email address")
      .required("Please enter an email address"),
  }),

  componentElements: v2ComponentElements,
};

export default {
  v1,
  v2,
};
