import * as Yup from "libs/validators/yup";
import { FormikTouched, FormikErrors } from "formik";
import dayjs, { Dayjs } from "dayjs";
import { useCallback } from "react";

import {
  isJSON,
  isAmhpForm,
  getAvailableNextStatusForFormActionButton,
  isJointForm,
  getFormCategory,
  isConveyanceForm,
} from "libs/mhaForms/helpers";
import { LocationNameInput, FormStatus } from "libs/types/API";

// form components
import TextWithIcon from "../../Text/TextWithIcon";
import MHADatePicker from "../MHADatePicker";
import MHATimePicker from "../MHATimePicker";
import MHALocation from "../MHALocation";

import {
  MHACheckbox,
  MHATextInput,
  MHAFormLabel,
  MHABottomTextInput,
  MHARadioOptionsList as RadioOptionsList,
  MHASelection,
} from "@/components/Forms";
import { TypographyProps } from "@/models/Typography";
import { FormikValues, MHAFormMetadata } from "@/models/MHAForm/MHAForm";
import {
  FormType,
  Itemisation,
  ComponentElement,
  FormFieldsAmhp,
  FormFieldsDoctor,
  MHAFormSection,
  UserTypeWhoCanModifyForms,
} from "libs/types/mhaForms";

import { UserDetails } from "../../../utils/recoil/props";
import { MHALocationProps } from "../MHALocation/MHALocation";
import { GestureResponderEvent } from "react-native";
import { formatAddress } from "libs/formatters/address";

export interface SectionInfo {
  touched: boolean;
  completed: boolean;
  valid: boolean;
}

export interface FormikData {
  values: FormikValues;
  initialValues: FormikValues;
  setFieldValue: (n: string, v: any) => void; // string | Dayjs | boolean | RetrieveDataObject
  setFieldTouched: (n: string, v: boolean) => void;
  touched?: FormikTouched<{ [key: string]: { [key: string]: boolean } }>; // section,field
  errors?: FormikErrors<{ [key: string]: { [key: string]: string } }>; // section,field
}

export interface TextWithIconProps extends TypographyProps {
  iconName: string;
  iconColor?: string;
  iconPosition?: "flex-start" | "center";
}

export interface RadioOptionsListProps {
  itemisation?: Itemisation;
  options: {
    label: string;
    value: string;
    onPress?: (event: GestureResponderEvent, value: string | number) => void;
    prefix?: string;
  }[];
}

export interface SectionData {
  name: string;
  title: string;
  componentElements: ComponentElement[];
}
export interface SelectionProps {
  label: string;
  value: string;
  setValue: (value: string) => void;
  options: {
    id: string;
    name: string;
    conditionalComponents?: ComponentElement[];
  }[];
}

export interface SectionType {
  title: string;
  state: any;
  validation: Yup.ObjectSchema<any>;
  componentElements: ComponentElement[];
}

// oh yes, thats the big one
export const buildFormikSections = (sections: SectionType[], userDetails: UserDetails | null, formType: FormType) => {
  const validation: { [key: string]: any } = {};
  const initialState = { formType } as FormikValues;
  const sectionData: SectionData[] = [];

  // taking in every single section and adding its state to the overarching initialState and the validation to the overall validation schema
  // also setting sections by setting name and number of section
  sections.forEach((s, i) => {
    const name = `section${i + 1}`;
    validation[name] = s.validation;

    initialState[name] = s.state;
    sectionData.push({
      name,
      title: s.title,
      componentElements: s.componentElements,
    });
  });

  return {
    validationSchema: Yup.object().shape(validation),
    initialState,
    sectionData,
  };
};

type FormElements = {
  [key in ComponentElement["component"]]: React.ReactNode;
};

// pulling appropriate component as in section only set as a string
export const getFormComponent = (component: ComponentElement["component"]) => {
  const formElements: FormElements = {
    TextInput: MHATextInput,
    Checkbox: MHACheckbox,
    Text: MHAFormLabel,
    TextWithIcon,
    Date: MHADatePicker,
    Time: MHATimePicker,
    Location: MHALocation,
    DoctorLocation: MHALocation,
    RadioOptionsList,
    BottomTextInput: MHABottomTextInput,
    Selection: MHASelection,
  };

  return formElements[component];
};

// setting default component props like errors, values etc based on the component type
export const getComponentProps = (
  component: ComponentElement["component"],
  section: string,
  fieldName: string | undefined,
  formData: FormikData,
  readonly?: boolean
) => {
  const getProps: {
    [key in Exclude<ComponentElement["component"], "Text" | "TextWithIcon">]: any;
  } = {
    TextInput: getTextInputProps,
    Checkbox: getCheckboxProps,
    Location: getLocationProps,
    DoctorLocation: getDoctorLocationProps,
    Date: getDateProps,
    Time: getTimeProps,
    BottomTextInput: getBottomTextInputProps,
    RadioOptionsList: getRadioOptionsListProps,
    Selection: getSelectionProps,
  };

  if (!Object.keys(getProps).includes(component)) return {};

  if (component === "Text" || component === "TextWithIcon") {
    return {};
  }

  return getProps[component](section, fieldName, formData, readonly);
};

// default TextInput props like error, errorText, value etc based on the section and fieldName
const getTextInputProps = (section: string, fieldName: FormFieldsAmhp | FormFieldsDoctor, formData: FormikData) => ({
  error: !!(formData.touched?.[section] && formData.errors?.[section]?.[fieldName]),
  errorText: formData.errors?.[section]?.[fieldName],
  value: formData.values[section][fieldName],
  resetInput: useCallback(() => {
    formData.setFieldValue(`${section}.${fieldName}`, "");
    formData.setFieldTouched(`${section}.${fieldName}`, false);
  }, []),
  onChangeText: useCallback((text: string) => formData.setFieldValue(`${section}.${fieldName}`, text), []),
  onBlur: useCallback(() => formData.setFieldTouched(`${section}.${fieldName}`, true), []),
});

// default BottomTextInput props like error, errorText, value etc based on the section and fieldName
const getBottomTextInputProps = (
  section: string,
  fieldName: FormFieldsAmhp | FormFieldsDoctor,
  formData: FormikData
) => {
  return {
    error: !!(formData.touched?.[section] && formData.errors?.[section]?.[fieldName]),
    errorText: formData.errors?.[section]?.[fieldName],
    formType: formData.initialValues.formType,
    value: formData.values[section][fieldName],
    resetInput: useCallback(() => {
      formData.setFieldValue(`${section}.${fieldName}`, "");
      formData.setFieldTouched(`${section}.${fieldName}`, false);
    }, []),
    setValue: useCallback((text: string) => formData.setFieldValue(`${section}.${fieldName}`, text), []),
    onBlur: useCallback(() => formData.setFieldTouched(`${section}.${fieldName}`, true), []),
  };
};

// default Checkbox props like error, errorText, value etc based on the section and fieldName
export const getCheckboxProps = (
  section: string,
  fieldName: FormFieldsAmhp | FormFieldsDoctor,
  formData: FormikData
) => {
  return {
    checkedValues: formData.values[section][fieldName],
    error: !!(formData.touched?.[section] && formData.errors?.[section]?.[fieldName]),
    errorText: formData.errors?.[section]?.[fieldName],
    onPress: useCallback(
      (values: string) => {
        formData.setFieldTouched(`${section}.${fieldName}`, true);
        formData.setFieldValue(`${section}.${fieldName}`, values);
      },
      [formData.values[section][fieldName]]
    ),
  };
};

// default Date props like error, errorText, value etc based on the section and fieldName
export const getDateProps = (
  section: string,
  fieldName: FormFieldsAmhp | FormFieldsDoctor,
  formData: FormikData,
  readonly?: boolean
) => {
  const value = formData.values[section][fieldName];

  return readonly
    ? { value }
    : {
        value,
        error: !!(formData.touched?.[section] && formData.errors?.[section]?.[fieldName]),
        errorText: formData.errors?.[section]?.[fieldName],
        onBlur: useCallback(() => {
          formData.setFieldTouched(`${section}.${fieldName}`, true);
        }, []),
        // onValueChange: checking date before setting value as date otherwise resets to 01/01/1970 on mobile
        onValueChange: useCallback((date: Dayjs) => {
          formData.setFieldValue(`${section}.${fieldName}`, date);
        }, []),
        min: dayjs()
          .subtract(15, "day")
          .startOf("day")
          .toDate(),
        max: dayjs().toDate(),
      };
};

// default Time props like error, errorText, value etc based on the section and fieldName
export const getTimeProps = (section: string, fieldName: FormFieldsAmhp | FormFieldsDoctor, formData: FormikData) => {
  const timeValue: { hours: number; minutes: number } = isJSON(formData.values[section][fieldName] as string)
    ? JSON.parse(formData.values[section][fieldName] as string)
    : { hours: 12, minutes: 0 };
  return {
    value: timeValue,
    onValueChange: useCallback(time => formData.setFieldValue?.(`${section}.${fieldName}`, JSON.stringify(time)), []),
  };
};

// default DoctorLocation props like error, errorText, value etc based on the section and fieldName
export const getDoctorLocationProps = (
  section: string,
  fieldName: FormFieldsAmhp | FormFieldsDoctor,
  formData: FormikData,
  readonly?: boolean
): MHALocationProps => {
  const initialValue = formData.initialValues[section][fieldName] as LocationNameInput;
  const obj = formData.values[section][fieldName] as LocationNameInput;
  const locationValue = obj ? formatAddress(obj) : "";

  return {
    error: !!(formData.touched?.[section] && formData.errors?.[section]?.[fieldName]),
    errorText: (formData.errors?.[section]?.[fieldName] as any) as { postcode: string },
    locationValue,
    readonly: readonly || initialValue.postcode !== "",
    resetInput: useCallback(() => {
      formData.setFieldValue(`${section}.${fieldName}`, {
        city: "",
        postcode: "",
        address: "",
      });
      formData.setFieldTouched(`${section}.${fieldName}`, false);
    }, []),
    setLocationValue: useCallback((data: { locationName: LocationNameInput }) => {
      formData.setFieldTouched(`${section}.${fieldName}`, true);
      formData.setFieldValue(`${section}.${fieldName}`, data.locationName);
    }, []),
  };
};

// default Location props like error, errorText, value etc based on the section and fieldName
export const getLocationProps = (
  section: string,
  fieldName: FormFieldsAmhp | FormFieldsDoctor,
  formData: FormikData,
  readonly?: boolean
): MHALocationProps => {
  const obj = formData.values[section][fieldName] as LocationNameInput;
  const locationValue = obj ? formatAddress(obj) : "";

  return {
    error: !!(formData.touched?.[section] && formData.errors?.[section]?.[fieldName]),
    errorText: (formData.errors?.[section]?.[fieldName] as any) as { postcode: string },
    locationValue,
    readonly,
    resetInput: useCallback(() => {
      formData.setFieldValue(`${section}.${fieldName}`, {
        city: "",
        postcode: "",
        address: "",
      });
      formData.setFieldTouched(`${section}.${fieldName}`, false);
    }, []),
    setLocationValue: useCallback((data: { locationName: LocationNameInput }) => {
      formData.setFieldTouched(`${section}.${fieldName}`, true);
      formData.setFieldValue(`${section}.${fieldName}`, data.locationName);
    }, []),
  };
};

// default RadioOptionsList props like error, errorText, value etc based on the section and fieldName
export const getRadioOptionsListProps = (
  section: string,
  fieldName: FormFieldsAmhp | FormFieldsDoctor,
  formData: FormikData
) => {
  return {
    fieldName,
    formData,
    section,
    value: formData.values[section][fieldName],
    error: !!(formData.touched?.[section] && formData.errors?.[section]?.[fieldName]),
    errorText: formData.errors?.[section]?.[fieldName],
    resetInput: () => {
      formData.setFieldValue(`${section}.${fieldName}`, "");
      formData.setFieldTouched(`${section}.${fieldName}`, false);
    },
    onItemPress: useCallback((value: string) => formData.setFieldValue(`${section}.${fieldName}`, value), [
      formData.values[section][fieldName],
    ]),
    onValueChange: useCallback((newvalue: string) => formData.setFieldValue(`${section}.${fieldName}`, newvalue), [
      formData.values[section][fieldName],
    ]),
  };
};

// default Selection props like error, errorText, value etc based on the section and fieldName
export const getSelectionProps = (
  section: string,
  fieldName: FormFieldsAmhp | FormFieldsDoctor,
  formData: FormikData
) => {
  return {
    error: !!(formData.touched?.[section] && formData.errors?.[section]?.[fieldName]),
    errorText: formData.errors?.[section]?.[fieldName],
    value: formData.values[section][fieldName],
    setValue: (newvalue: string) => {
      formData.setFieldValue(`${section}.${fieldName}`, newvalue);
    },
  };
};

export const isFormOwner = (formType: FormType, userDetails: UserDetails | null): boolean => {
  if (isAmhpForm(formType) && userDetails && userDetails.groups.includes("AMHP")) {
    return true;
  } else if (
    !isAmhpForm(formType) &&
    userDetails &&
    (userDetails.groups?.includes("Doctor") || userDetails.id.indexOf("Form-") === 0)
  ) {
    return true;
  }

  return false;
};

/**
 * If doing a single med rec, an External Doctor is no different from a Doctor;
 * If doing a joint med rec, an External Doctor is no different from a SecondDoctorInJointForm;
 * (as External Doctors in joint forms are only ever the second doctor).
 */
function getFormUserType(
  userDetails: UserDetails | null,
  formType: FormType,
  formCreator?: string
): UserTypeWhoCanModifyForms | "Unknown" {
  if (userDetails?.groups.includes("AMHP")) {
    if (userDetails.isTraineeAmhp) {
      return "Unknown"; // Force this status so that trainee AMHPs cannot access any transitions and only ever get readonly views
    }
    return "AMHP";
  } else if (userDetails?.groups.includes("Doctor") || userDetails?.groups.includes("ExternalDoctor")) {
    if (isJointForm(formType) && formCreator && formCreator === userDetails.id) {
      return "PrimaryDoctorInJointForm";
    } else if (isJointForm(formType) && formCreator && formCreator !== userDetails.id) {
      return "SecondDoctorInJointForm";
    } else {
      return "Doctor";
    }
  } else {
    return "Unknown";
  }
}

// setting actions at bottom of form when in review mode based on type, status, author etc
export const actionsForForm = (
  formType: FormType,
  formStatus: FormStatus,
  formMetadata: Omit<MHAFormMetadata, "notes">,
  userDetails: UserDetails | null,
  authors: string[],
  createdBy?: string
): { status: FormStatus; text: string }[] => {
  const isUnattachedForm = !formMetadata.assessmentId || formMetadata.assessmentId === "!!!";
  const formCategory = getFormCategory(formType, isUnattachedForm);
  const formUserType = getFormUserType(userDetails, formType, createdBy);
  const isADoctorUser =
    formUserType === "Doctor" ||
    formUserType === "PrimaryDoctorInJointForm" ||
    formUserType === "SecondDoctorInJointForm";
  const isAnAMHPUser = formUserType === "AMHP";
  const amhpLookingAtExternalDoctorForm =
    isAnAMHPUser && createdBy === userDetails?.id && authors.some(a => a.startsWith("Form-"));

  if (!formUserType && userDetails) {
    throw new Error("Unable to process actions for unknown user type");
  }

  // if a form is in an amhp team inbox, and the user is an amhp, they must first move it
  // into an assessment before they can take action
  if (formStatus === FormStatus.s60_signed_in_amhp_team_inbox && isAnAMHPUser) return [];

  // amhp looking at a form has been sent to an external doctor cannot modify the form
  if (amhpLookingAtExternalDoctorForm) return [];

  // DEV-NOTE: broke this out into a variable so it's easier to log what's being returned from this function
  const actionsForForm = getAvailableNextStatusForFormActionButton(formUserType, formCategory, formStatus)
    .map((nextStatus: FormStatus) => {
      const isPsuedoAmhpForm = isAmhpForm(formType) || isConveyanceForm(formType);
      if (isPsuedoAmhpForm && (!userDetails || !authors.includes(userDetails?.id))) {
        // only the amhp owner can modify an amhp form
        return null;
      } else if (!isPsuedoAmhpForm && isADoctorUser && (!userDetails || !authors.includes(userDetails?.id))) {
        // doctors can only modify their own form
        return null;
      } else if (isPsuedoAmhpForm && isADoctorUser) {
        // doctors cannot modify an amhp form
        return null;
      } else if (!isPsuedoAmhpForm && isAnAMHPUser) {
        // amhps cannot modify a doctor form
        return null;
      } else if (nextStatus === FormStatus.s50_signed_and_sent) {
        // cannot transition to signed and sent, it is a server action
        return null;
      } else if (!isJointForm(formType) && isADoctorUser && nextStatus === FormStatus.s20_awaiting_other_doctor) {
        // s20_awaiting_other_doctor is only for joint med recs
        return null;
      } else if (nextStatus === formStatus && nextStatus === FormStatus.s60_signed_in_amhp_team_inbox) {
        // if the current status is signed, don't show the button to sign again
        return null;
      }

      return {
        status: nextStatus,
        text:
          nextStatus === FormStatus.s30_changes_in_progress
            ? "Remove Signature and Edit Form"
            : nextStatus === FormStatus.s60_signed_in_amhp_team_inbox
            ? "Sign and Send to AMHP Team"
            : nextStatus === FormStatus.s40_signed || nextStatus === FormStatus.s20_awaiting_other_doctor
            ? "Sign Form"
            : isAnAMHPUser && isAmhpForm(formType)
            ? "Remove Signature and Edit Form"
            : "Make Edits",
      };
    })
    .filter(notEmpty);

  return actionsForForm;
};

function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && typeof value !== "undefined";
}

// pulling out individual values from data sections
export function getValueFromDataSections(
  sections: MHAFormSection[],
  key: "name" | "type" | "valueArray" | "valueLocation" | "value",
  value: string
): string | (string | null)[] | LocationNameInput {
  const section = sections.filter(s => s.fields.find(field => field[key] === value));
  if (section && section[0]) {
    const item = section[0].fields.find(field => field[key] === value);
    if (!item) {
      return "";
    }
    return item.value || item.valueLocation || item.valueArray || "";
  }
  return "";
}
