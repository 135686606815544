import React, { createContext, ReactNode, useReducer, useMemo } from "react";

import {
  claimModelState,
  claimUiState,
  claimProcessingModelReducer,
  claimProcessingUiReducer,
  // ClaimModelStateProps,
  ClaimUiStateProps,
  ClaimProcessingStateProps,
  ClaimProcessingStateContext,
} from "./ClaimProcessingState";

export const ClaimProcessingContext = createContext<ClaimProcessingStateContext>({
  state: { ...claimModelState, ...claimUiState },
  dispatch: () => null,
});

function ClaimProcessingProvider(props: { children: ReactNode }) {
  const [modelState, modelDispatch]: [any, any] = useReducer(claimProcessingModelReducer, claimModelState);
  const [controllerState, controllerDispatch]: [ClaimUiStateProps, any] = useReducer(
    claimProcessingUiReducer,
    claimUiState
  );

  const state: ClaimProcessingStateProps = {
    ...modelState,
    ...controllerState,
  };

  const dispatch = useMemo(
    () => (action: any) => {
      modelDispatch(action);
      controllerDispatch(action);
    },
    [controllerDispatch, modelDispatch]
  );

  return (
    <ClaimProcessingContext.Provider value={{ state, dispatch }}>{props.children}</ClaimProcessingContext.Provider>
  );
}

export default ClaimProcessingProvider;
