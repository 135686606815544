import React from "react";

import { BackButtonProps } from "@/components/BackButton/BackButton.props";
import ClaimProcess from "@/components/ClaimProcess";
import { AppScreen } from "@/layouts/AppScreen/AppScreen";
import { RouteKeys } from "@/navigationv2";
import { SharedTabRoutes, SupportRoutes, ScreenNavigationProps, ScreenOptions } from "@/navigationv2/types";
import { createScreenTitle } from "@/navigationv2/utilities";
import { color } from "@/theme";

const backButtonConfig: BackButtonProps = {
  enabled: true,
  float: true,
  color: color.textWhite,
};

interface PropTypes extends ScreenNavigationProps<SharedTabRoutes, SupportRoutes, RouteKeys.ClaimProcessScreen> {}

const ClaimProcessScreen = () => {
  return (
    <AppScreen
      contextLabel="Support"
      pageTitle="Claim Process"
      backButton={backButtonConfig}
      testID="ClaimProcessScreen"
    >
      <ClaimProcess />
    </AppScreen>
  );
};

const screenOptions: ScreenOptions = {
  title: createScreenTitle("Claim Process"),
};

ClaimProcessScreen.screenOptions = screenOptions;

export default ClaimProcessScreen;
