/* eslint react/prop-types: 0 */
import Auth from "@aws-amplify/auth";
import API from "@aws-amplify/api";
import React, { useState, useEffect } from "react";
import { StyleSheet, TouchableWithoutFeedback, View, Platform } from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { CommonActions } from "@react-navigation/core";
import { useSetRecoilState, useRecoilValue } from "recoil";

import { BackButtonProps } from "@/components/BackButton/BackButton.props";
import Text from "@/components/Text";
import { TextInput } from "@/components/TextInput";
import { AuthScreen } from "@/layouts";
import { S12Error, resetPasswordError } from "@/models/Error";
import { TypographyType } from "@/models/Typography";
import { color, spacing } from "@/theme";
import { RouteKeys } from "@/navigationv2";
import { AuthNavigationProps, ScreenOptions } from "@/navigationv2/types";
import { createScreenTitle } from "@/navigationv2/utilities";
import {
  isDemoEnvironment,
  signup,
  cognitoUserSignal,
  setCognitoUser,
  loading,
  reset as recoilReset,
  snackbarMessage,
  justLoggedIn,
} from "@/utils/recoil/index";
import { PasswordInput } from "@/components/PasswordInput";
import ENV from "@/config";

const isEnvProdOrMaster = !!(ENV.ENV === "prod" || ENV.ENV === "master");

interface PropTypes extends AuthNavigationProps<RouteKeys.ForgotPasswordScreen> {}

const isTest = process?.env?.NODE_ENV === "test";

const ForgotPasswordScreen = (props: PropTypes) => {
  const setIsDemoEnvironment = useSetRecoilState(isDemoEnvironment);
  const setSignup = useSetRecoilState(signup);
  const setCognitoUserSignal = useSetRecoilState(cognitoUserSignal);
  const setMessage = useSetRecoilState(snackbarMessage);
  const setLoading = useSetRecoilState(loading);
  const reset = useRecoilValue(recoilReset);
  const setJustLoggedIn = useSetRecoilState(justLoggedIn);

  const [username, setUsername] = useState(reset.email !== "" ? reset.email : "");
  const [authCode, setAuthCode] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [confirmed, setConfirmed] = useState(reset.email !== "");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (props.route.params.params === "pwVerification") {
      setConfirmed(true);
    }
    if (props.route.params.params === "resetPassword") {
      setConfirmed(false);
    }
    AsyncStorage.getItem("authVerificationCode").then(value => {
      if (value !== "null" && value !== null && value !== undefined) {
        setUsername(value);
      }
    });
    const resetDemoEnvironment = async () => {
      await AsyncStorage.setItem("isDemoEnvironment", "false");
      setIsDemoEnvironment(false);
    };

    !isTest && resetDemoEnvironment();
  }, []);

  const forgotPassword = async () => {
    if (Platform.OS !== "web") setLoading(true);

    await Auth.forgotPassword(username.toLowerCase())
      .then(() => {
        AsyncStorage.setItem("authVerificationCode", username.toString());
        setConfirmed(true);
      })
      .catch(async err => {
        if (err.code === "NotAuthorizedException") {
          const resp = await API.post("signupResend", "/", {
            body: { email: username.toLowerCase().trim() },
          }).catch(e => {
            const error = new S12Error(resetPasswordError, e);
            setErrorMessage(error.message);
            error.save();
          });
          if (resp && resp.statusCode === 200) {
            setMessage("A new welcome email has been sent to your email or phone.");
            const resetAction = CommonActions.reset({
              index: 0,
              routes: [
                {
                  name: "SignIn",
                },
              ],
            });
            props.navigation.dispatch(resetAction);
          } else if (!resp || resp.statusCode >= 400) {
            const error = new S12Error(
              {
                ...resetPasswordError,
                additional: JSON.stringify({ response: resp, username }),
              },
              resp?.statusCode || "no status code"
            );
            error.save();
            setErrorMessage(
              resp && resp.body && resp.statusCode < 500 ? JSON.parse(resp.body) : resetPasswordError.message
            );
          }
        } else if (err.code === "UserNotFoundException") {
          const notFoundErr = new S12Error({
            ...resetPasswordError,
            additional: "username: " + username,
          });
          notFoundErr.save();
          setErrorMessage("Username not found");
        } else {
          setErrorMessage(err.message);
          const otherErr = new S12Error({
            ...resetPasswordError,
            additional: JSON.stringify({
              message: err.message,
              username,
              code: err.code,
            }),
          });
          otherErr.save();
        }
      });
    setTimeout(() => setLoading(false), 300);
  };

  const resetPassword = async () => {
    if (Platform.OS !== "web") setLoading(true);
    await Auth.forgotPasswordSubmit(username.toLowerCase().trim(), authCode.trim(), password)
      .then(async () => {
        await Auth.signIn(username.toLowerCase().trim(), password)
          .then(user => {
            AsyncStorage.setItem("authVerificationCode", "null");
            if (isEnvProdOrMaster) {
              AsyncStorage.setItem(username.toLowerCase().trim(), "0");
            }
            setJustLoggedIn(true);
            setTimeout(() => setLoading(false), 800);
            if (user.challengeName === "SMS_MFA") {
              setCognitoUser(user);
              setCognitoUserSignal(4);
              setSignup({
                email: username.toLowerCase().trim(),
                password,
                challenge: user.challengeName,
              });
              props.navigation.navigate(RouteKeys.Confirmation);
            }
            if (isEnvProdOrMaster) {
              AsyncStorage.setItem("numOfAttempts", "0");
            }
          })
          .catch(() => {
            // cognito will store the error.
            // setting message because we navigate the user
            setMessage("Could not reset password");
            setTimeout(() => setLoading(false), 800);
            props.navigation.navigate(RouteKeys.WelcomeScreen);
          });
      })
      .catch(err => {
        setTimeout(() => setLoading(false), 800);
        setErrorMessage(err.message);
      });
  };

  const passwordsNoMatch = password.length > 0 && passwordConfirm.length > 0 && password !== passwordConfirm;

  const backButtonConfig: BackButtonProps = {
    enabled: true,
    float: true,
    color: "white",
  };

  const renderCopy = () => {
    return reset.email !== "" ? (
      <Text format={TypographyType.Tiny} style={styles.helperText}>
        Please reset your password.
      </Text>
    ) : !confirmed ? (
      <Text format={TypographyType.Tiny} style={styles.helperText}>
        To confirm your identity, please enter the email address associated with your account.
      </Text>
    ) : (
      <Text format={TypographyType.Tiny} style={styles.helperText}>
        Please enter the verification{"\n"}code sent to your email address
      </Text>
    );
  };

  const onSetUsername = (value: string) => {
    if (errorMessage !== "") setErrorMessage("");
    // dont change capitalisation here as it is used to display the current value
    setUsername(value);
  };

  // eslint-disable-next-line
  const emailExpression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;

  const cancelButton = {
    label: "Cancel",
    textButton: true,
    onPress: () => props.navigation.reset({ index: 1, routes: [{ name: "SignIn" }] }),
  };

  const buttons = !confirmed
    ? [
        {
          label: "Send Verification Code",
          disabled: !emailExpression.test(String(username).toLowerCase()),
          onPress: () => forgotPassword(),
        },
        cancelButton,
      ]
    : [
        {
          label: "Reset Password",
          disabled:
            !authCode ||
            (authCode && authCode.trim() === "") ||
            password.length === 0 ||
            passwordConfirm.length === 0 ||
            passwordsNoMatch,
          onPress: () => resetPassword(),
        },
        cancelButton,
      ];

  const renderContent = () => {
    return !confirmed ? (
      <View>
        <TextInput
          error={errorMessage !== ""}
          errorText={errorMessage}
          icon="email"
          label="Email address"
          autoCorrect={false}
          autoCapitalize="none"
          keyboardType="email-address"
          returnKeyType="send"
          onSubmitEditing={() => forgotPassword()}
          value={username}
          onChangeText={value => onSetUsername(value)}
        />
      </View>
    ) : (
      <View>
        <TouchableWithoutFeedback style={styles.fill} onPress={e => e.stopPropagation()}>
          <View>
            {/*
              This is to stop chrome auto-filling Verification Code and "New Password" inputs with user's login details.
              As you can see, autoCompleteType="off" is set but don't do jack on Chrome; smh.
            */}
            <TextInput label="email" style={styles.ghostInput} />
            <TextInput label="password" secureTextEntry={true} style={styles.ghostInput} />

            <TextInput
              label="Verification code"
              icon="security"
              autoCorrect={false}
              autoFocus={true}
              autoCapitalize="none"
              autoCompleteType="off"
              returnKeyType="done"
              value={authCode}
              onChangeText={value => setAuthCode(value)}
              withPasswordField={true}
            />
            <PasswordInput
              error={!password || (!!password && password.trim().length > 0 && password.trim().length < 8)}
              errorText="Password must contain at least 8 characters"
              label="New password"
              icon="lock-outline"
              autoCorrect={false}
              autoCompleteType="off"
              autoCapitalize="none"
              returnKeyType="done"
              value={password}
              onChangeText={value => setPassword(value)}
            />
            <PasswordInput
              error={passwordsNoMatch}
              errorText="Your password and confirmation password do not match"
              label="Confirm new password"
              icon="lock-outline"
              autoCorrect={false}
              autoCompleteType="off"
              autoCapitalize="none"
              returnKeyType="done"
              value={passwordConfirm}
              onChangeText={value => setPasswordConfirm(value)}
            />
            {errorMessage !== "" && (
              <Text format={TypographyType.Micro} style={styles.errorMessage}>
                {errorMessage}
              </Text>
            )}
          </View>
        </TouchableWithoutFeedback>
      </View>
    );
  };

  return (
    <AuthScreen buttons={buttons} backButton={backButtonConfig} testID="Forgot-Password__Screen">
      <Text format={TypographyType.HeadingMedium} style={styles.headline}>
        {reset.email !== "" ? "Reset Password" : "Forgotten Password"}
      </Text>
      <View style={styles.helperTextContainer}>{renderCopy()}</View>
      {renderContent()}
    </AuthScreen>
  );
};

const screenOptions: ScreenOptions = {
  title: createScreenTitle("Forgot Password"),
};

ForgotPasswordScreen.screenOptions = screenOptions;

export default ForgotPasswordScreen;

const styles = StyleSheet.create({
  fill: {
    flex: 1,
    flexDirection: "column",
    maxWidth: 800,
    minWidth: 275,
    width: "100%",
    flexBasis: "auto",
    flexShrink: 0,
    marginBottom: spacing[10],
  },
  headline: {
    marginBottom: 40,
    alignSelf: "center",
  },
  errorMessage: {
    color: color.textError,
    alignSelf: "center",
    marginTop: -spacing[15],
    marginBottom: spacing[10],
  },

  helperText: {
    color: color.textLight,
    textAlign: "center",
  },
  helperTextContainer: {
    marginBottom: 50,
    alignSelf: "center",
    maxWidth: 275,
  },

  ghostInput: {
    position: "absolute",
    width: 0,
    height: 0,
    opacity: 0,
  },
});
