import { setUser as setSentryUser } from "@sentry/react-native";

import { UserDetails } from "@/utils/recoil/props";

export function setUserDetails(user: UserDetails | null) {
  return user
    ? setSentryUser({
        id: user.id,
        username: user.name,
        email: user.email,
      })
    : undefined;
}
