import React from "react";
import { createStackNavigator } from "@react-navigation/stack";

import Screens from "@/screens";
import { UserDetails } from "@/utils/recoil/props";

import AuthNavigator from "./Auth";
import ExternalDoctorNavigator from "./ExternalDoctor";
import HomeNavigator from "./Home";
import screenOptions from "../config/screenOptions";
import { RootNavigationRoutes } from "../types";

const RootStack = createStackNavigator<RootNavigationRoutes>();

interface PropTypes {
  isExternal: boolean;
  user: UserDetails | null;
}

const Root = (props: PropTypes) => {
  return (
    <RootStack.Navigator screenOptions={screenOptions}>
      {props.isExternal && <RootStack.Screen name="ExternalDoctorNavigator" component={ExternalDoctorNavigator} />}

      {!props.isExternal && (
        <>
          {!props.user && <RootStack.Screen name="AuthNavigator" component={AuthNavigator} />}
          {props.user && <RootStack.Screen name="HomeNavigator" component={HomeNavigator} />}
        </>
      )}

      <RootStack.Screen name="Eula" component={Screens.EulaScreen} options={Screens.EulaScreen.screenOptions} />
    </RootStack.Navigator>
  );
};

export default Root;
