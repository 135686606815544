import { atom } from "recoil";

import {
  BottomTabDescriptorMap,
  BottomTabBarProps,
  BottomTabNavigationHelpers,
} from "@react-navigation/bottom-tabs/lib/typescript/src/types";
import { ParamListBase, TabNavigationState } from "@react-navigation/native";

export const bottomTabBarPropsState = atom<BottomTabBarProps>({
  key: "tabBarProps",
  default: {
    state: {} as TabNavigationState<ParamListBase>,
    descriptors: {} as BottomTabDescriptorMap,
    navigation: {} as BottomTabNavigationHelpers,
  },
});

export const previousPathnameState = atom<string>({
  key: "previousPathname",
  default: "",
});
