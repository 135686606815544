import React from "react";
import { createStackNavigator } from "@react-navigation/stack";

import screenOptions from "@/navigationv2/config/screenOptions";
import { CCGApprovedClaimsRoutes } from "@/navigationv2/types";
import * as Screens from "@/screens";
import TabIcon from "../TabIcon";

const ApprovedStack = createStackNavigator<CCGApprovedClaimsRoutes>();

const CCGApprovedClaimsStack = () => {
  return (
    <ApprovedStack.Navigator initialRouteName="ApprovedClaimsScreen" screenOptions={screenOptions}>
      <ApprovedStack.Screen
        name="ApprovedClaimsScreen"
        component={Screens.ApprovedClaimsScreen}
        options={Screens.ApprovedClaimsScreen.screenOptions}
      />
      <ApprovedStack.Screen
        name="ClaimProcessingDetailsScreen"
        component={Screens.ClaimProcessingDetailsScreen}
        options={Screens.ClaimProcessingDetailsScreen.screenOptions}
      />
      <ApprovedStack.Screen
        name="ClaimProcessingSearchScreen"
        component={Screens.ClaimProcessingSearchScreen}
        options={Screens.ClaimProcessingSearchScreen.screenOptions}
      />
    </ApprovedStack.Navigator>
  );
};

CCGApprovedClaimsStack.tabOptions = {
  tabBarIcon: TabIcon("check"),
  tabBarLabel: "Approved",
};

export default CCGApprovedClaimsStack;
