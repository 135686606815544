import { Dayjs } from "dayjs";
import { AvailabilityState, Availability, AvailabilityDate } from "libs/types/availability";
import { palette } from "@/theme";

export const getAvailabilityState = (
  isAddedToAssessment: boolean,
  isDoctorAvailable: boolean,
  availability: AvailabilityDate | undefined,
  mhtEmployers: any[],
  availabilities: Availability[] | undefined,
  onHoliday:
    | {
        isAway: boolean;
        isPartial: boolean;
        availability: Dayjs | null;
      }
    | undefined,
  booked:
    | {
        isBooked: boolean;
        partiallyBooked: boolean;
      }
    | undefined
) =>
  isAddedToAssessment
    ? AvailabilityState.inAssessment
    : onHoliday && onHoliday.isAway
    ? AvailabilityState.away
    : booked && booked.isBooked
    ? AvailabilityState.booked
    : availability &&
      (availability as AvailabilityDate).availability &&
      (availability as AvailabilityDate).availability.oncall
    ? AvailabilityState.oncall
    : isDoctorAvailable &&
      availability &&
      (availability as AvailabilityDate).availability &&
      (availability as AvailabilityDate).availability.type
    ? (((availability as AvailabilityDate).availability.type as unknown) as AvailabilityState)
    : isDoctorAvailable && mhtEmployers && mhtEmployers.length === 0
    ? AvailabilityState.independent
    : isDoctorAvailable
    ? AvailabilityState.available
    : booked && booked.partiallyBooked
    ? AvailabilityState.booked
    : availabilities?.length
    ? AvailabilityState.unavailable
    : AvailabilityState.noInformation;

export function getAvailabilityColorForDoctorSearchItem(doctorAvailability: AvailabilityState): string {
  return doctorAvailability === AvailabilityState.available ||
    doctorAvailability === AvailabilityState.oncall ||
    doctorAvailability === AvailabilityState.independent ||
    doctorAvailability === AvailabilityState.trust
    ? palette.aqua
    : doctorAvailability === AvailabilityState.inAssessment
    ? palette.yellow
    : doctorAvailability === AvailabilityState.booked
    ? palette.orange
    : doctorAvailability === AvailabilityState.away
    ? palette.red
    : doctorAvailability === AvailabilityState.noInformation
    ? palette.grey
    : palette.greyBlue;
}
