import * as Yup from "yup";
import dayjs from "dayjs";

import createDateSchema from "libs/validators/yup/createDateSchema";
import { FormType, ComponentElement, FormFieldsAmhp } from "libs/types/mhaForms";

const componentElements: ComponentElement[] = [
  {
    component: "Text",
    componentProps: {
      instruction: "I last saw the patient on",
    },
    fullWidth: true,
  },
  {
    component: "Date",
    fieldName: FormFieldsAmhp.AssessmentDate,
    componentProps: {
      label: "Date",
    },
  },
  {
    component: "Text",
    componentProps: {
      instruction: "at",
    },
    fullWidth: true,
  },
  {
    component: "Time",
    fieldName: FormFieldsAmhp.AssessmentTime,
    componentProps: {
      label: "Time",
    },
  },
  {
    component: "Text",
    componentProps: {
      instruction: `which was within the last 24 hours.\n\nI have interviewed the patient and I am satisfied that detention in a hospital is in all the circumstances of the case the most appropriate way of providing the care and medical treatment of which the patient stands in need.\n\nIn my opinion it is of urgent necessity for the patient to be admitted and detained under section 2 of the Act and compliance with the provisions of Part 2 of the Act relating to applications under that section would involve undesirable delay.\n\nThis application is founded on a medical recommendation in the prescribed form.\n\n`,
      guidance:
        "If the medical practitioner did not have previous acquaintance with the patient before making the recommendation, please explain why you could not get a recommendation from a medical practitioner who did have previous acquaintance with the patient —",
    },
    genericProps: {
      pdfBreak: [FormType.A10],
    },
    fullWidth: true,
  },
  {
    component: "BottomTextInput",
    fieldName: FormFieldsAmhp.Reasons,
    fullWidth: true,
    componentProps: {
      label: "Reasons",
      buttonLabel: "Reasons",
      defaultText: "No details currently given",
    },
  },
];

export default {
  title: "Admission Details",
  state: {
    assessmentDate: "",
    assessmentTime: JSON.stringify({
      hours: 12,
      minutes: 0,
    }),
    reasons: "",
  },

  validation: Yup.object().shape({
    assessmentDate: Yup.lazy(() =>
      createDateSchema({
        min: dayjs().subtract(15, "day").startOf("day"),
        max: dayjs().endOf("day"),
        required: true,
      })
    ),
    reasons: Yup.string(),
  }),

  componentElements,
};
