import React, { useCallback, useEffect, useMemo, useState } from "react";
import { StyleSheet, Platform, View } from "react-native";
import { useSetRecoilState, useRecoilValue } from "recoil";
import API from "@aws-amplify/api";
import APIV2 from "@/api";

import { RadioButton, RadioButtonGroup } from "@/components/Radio/Radio";

import { Button, ButtonList } from "@/components/Button";
import { ContentWrap } from "@/components/ContentWrap";
import Text from "@/components/Text";

import { buildErr, sendEmailToAddressError, downloadFormError } from "@/models/Error";
import { TypographyType } from "@/models/Typography";
import { palette, spacing } from "@/theme";
import { lastException, snackbarMessage, userDetails } from "@/utils/recoil";
import { recordEvent } from "@/utils/analytics";
import { AnalyticsEvent } from "libs/analytics/events";
import {
  amhpTeamMhaHospitalEmails,
  amhpTeamEmailFormsToSelf,
  amhpTeamEmailFormsToApprovedLists,
} from "libs/utils/featureFlags";
import { SignedForm } from "libs/types/mhaForms";

import { Mod } from "./modules";
import { useAPIVersion } from "@/hooks";
import { API_V2_TOGGLES } from "@/api/types";

interface EmailOrExportFormsProps {
  type: "email" | "download" | null;
  signedForms: SignedForm[];
  amhpTeamFeatures: string;
  amhpTeamId?: string;
  assessmentId?: string;
  resetBottomSheet: () => void;
  showPatientNameOnFormOptions?: boolean;
}

export const EmailOrExportForms = ({
  assessmentId,
  signedForms,
  resetBottomSheet,
  amhpTeamFeatures,
  amhpTeamId,
  type,
  showPatientNameOnFormOptions = false,
}: EmailOrExportFormsProps) => {
  const [submitted, setSubmitted] = useState(false);
  const [formsToEmail, setFormsToEmail] = useState<string[]>([]);
  const [selectedHospital, setSelectedHospital] = useState<number>(0);
  const [emailAddresses, setEmailAddresses] = useState<string[]>([]);
  const [hospitalChoice, setHospitalChoice] = useState({
    hospital: "",
    ward: "",
  });
  const [approvedRecipientChoice, setApprovedRecipientChoice] = useState([]);
  const setMessage = useSetRecoilState(snackbarMessage);
  const setLastException = useSetRecoilState(lastException);
  const user = useRecoilValue(userDetails);
  const hasEmailToHospitalFeature = amhpTeamMhaHospitalEmails(amhpTeamFeatures);
  const hasEmailToSelfFeature = amhpTeamEmailFormsToSelf(amhpTeamFeatures);
  const hasEmailToListsFeature = amhpTeamEmailFormsToApprovedLists(amhpTeamFeatures);

  const { v2 } = useAPIVersion(API_V2_TOGGLES.MHA_FORMS);

  const formActions: {
    type: "emailToWard" | "emailToSelf" | "emailToApprovedSets" | "download";
    label?: string;
  }[] = useMemo(
    () =>
      type === "email"
        ? [
            ...(hasEmailToHospitalFeature
              ? [
                  {
                    type: "emailToWard" as const,
                    label: "Email to hospital ward",
                  },
                ]
              : []),
            ...(hasEmailToListsFeature
              ? [
                  {
                    type: "emailToApprovedSets" as const,
                    label: "Email to set addresses",
                  },
                ]
              : []),
            ...(hasEmailToSelfFeature
              ? [
                  {
                    type: "emailToSelf" as const,
                    label: "Email to yourself",
                  },
                ]
              : []),
          ]
        : Platform.OS === "web"
        ? [
            {
              type: "download" as const,
            },
          ]
        : [],
    [hasEmailToHospitalFeature]
  );

  const onSetHospitalChoice = (values: { hospital?: string; ward?: string }) =>
    setHospitalChoice(prevChoice => ({ ...prevChoice, ...values }));

  const [formAction, setFormAction] = useState<
    "emailToWard" | "emailToSelf" | "emailToApprovedSets" | "download" | null
  >(formActions.length ? formActions[0].type : null);

  const handleEmailForm = (body: any) => {
    const emailFormEndpoint = "/mhaforms/emailForm";

    return v2 ? APIV2.post(emailFormEndpoint, { body }) : API.post("RestAPI", emailFormEndpoint, { body });
  };
  const _onSendForms = useCallback(async () => {
    setSubmitted(true);

    // clean email addresses
    const cleanEmailAddresses = await emailAddresses.map(e => {
      return e.split("~")[0];
    });

    const body = {
      emails: cleanEmailAddresses,
      formIds: formsToEmail,
      amhpTeamId,
      type: "external",
      ...(formAction === "emailToWard" && { headingPrefix: `${hospitalChoice.hospital} | ${hospitalChoice.ward} - ` }),
      ...(formAction === "emailToApprovedSets" && { headingPrefix: approvedRecipientChoice.join(" | ") + " - " }),
    };

    await handleEmailForm(body)
      .then(async () => {
        setMessage("Form sent to selected address(es)");
        await Promise.all(
          formsToEmail.map(formId =>
            recordEvent(AnalyticsEvent.MHA_FORM_AMHP_EXPORTED, {
              assessmentId: assessmentId || "",
              amhpTeamId: amhpTeamId || "",
              exportLocation: formAction === "emailToWard" ? "Email to hospital ward" : "Email to selected list",
              formId,
            })
          )
        );
      })
      .catch(async e => {
        await Promise.all(
          formsToEmail.map(formId =>
            recordEvent(AnalyticsEvent.MHA_FORM_AMHP_EXPORTED_FAILURE, {
              assessmentId: assessmentId || "",
              amhpTeamId: amhpTeamId || "",
              exportLocation: formAction === "emailToWard" ? "Email to hospital ward" : "Email to selected list",
              formId,
            })
          )
        );
        buildErr(sendEmailToAddressError, setLastException);
      })
      .then(() => resetBottomSheet());
  }, [emailAddresses, formsToEmail]);

  const _onSendFormsToYourself = useCallback(async () => {
    if (user) {
      setSubmitted(true);
      const body = {
        emails: [user.email],
        formIds: formsToEmail,
        amhpTeamId,
        type: "internal",
      };
      await handleEmailForm(body)
        .then(async () => {
          setMessage(`Form sent to ${user.email}`);
          await Promise.all(
            formsToEmail.map(formId =>
              recordEvent(AnalyticsEvent.MHA_FORM_AMHP_EXPORTED, {
                assessmentId: assessmentId || "",
                amhpTeamId: amhpTeamId || "",
                exportLocation: "Email to yourself",
                formId,
              })
            )
          );
        })
        .catch(async e => {
          await Promise.all(
            formsToEmail.map(formId =>
              recordEvent(AnalyticsEvent.MHA_FORM_AMHP_EXPORTED_FAILURE, {
                assessmentId: assessmentId || "",
                amhpTeamId: amhpTeamId || "",
                exportLocation: "Email to yourself",
                formId,
              })
            )
          );
          buildErr(sendEmailToAddressError, setLastException);
        })
        .then(() => resetBottomSheet());
    }
  }, [user, formsToEmail]);

  const _onDownloadForms = useCallback(() => {
    setSubmitted(true);
    formsToEmail.forEach(async f => {
      const body = {
        formId: f,
        amhpTeamId,
      };

      const downloadEndpoint = "/mhaforms/downloadForm";
      const downloadParams = { body, responseType: "blob", response: true };
      const downloadPromise = () =>
        v2 ? APIV2.post(downloadEndpoint, downloadParams) : API.post("RestAPI", downloadEndpoint, downloadParams);

      await downloadPromise()
        .then(response => {
          // @ts-ignore-start
          const blob = new Blob([response.data], { type: "application/pdf" });

          // @ts-ignore-end
          let filename = "mhaForm";
          if (response.config?.data && typeof response.config.data === "string") {
            try {
              const configData = JSON.parse(response.config.data);

              // API V1
              if ("formId" in configData) {
                filename = configData.formId;
                // API V2
              } else if ("formId" in configData.body) {
                filename = configData.body.formId;
              }
            } catch (e) {}
          }

          if (typeof window.navigator.msSaveBlob !== "undefined") {
            window.navigator.msSaveBlob(blob, filename);
          } else {
            // ? V2 endpoint doesn't like the blob download
            const blobURL = v2 ? `data:application/pdf;base64,${response.data}` : window.URL.createObjectURL(blob);
            // @ts-ignore-start
            if (typeof document !== "undefined") {
              // @ts-ignore
              const link = document.createElement("a");
              link.setAttribute("href", blobURL);
              link.setAttribute("download", filename);

              if (typeof link.download === "undefined") {
                link.setAttribute("target", "_self");
              }

              // @ts-ignore
              document.body.appendChild(link); // Required for FF
              link.click();
              // @ts-ignore
              document.body.removeChild(link);
              if (!v2) window.URL.revokeObjectURL(blobURL);
              // @ts-ignore-end
            } else {
              recordEvent(AnalyticsEvent.MHA_FORM_AMHP_EXPORTED_FAILURE, {
                error: "Unable to download file",
              });
              throw new Error("Unable to download file");
            }
          }
        })
        .then(() =>
          Promise.all(
            formsToEmail.map(formId =>
              recordEvent(AnalyticsEvent.MHA_FORM_AMHP_EXPORTED, {
                assessmentId: assessmentId || "",
                amhpTeamId: amhpTeamId || "",
                exportLocation: "Download form",
                formId,
              })
            )
          )
        )
        .catch(async e => {
          await recordEvent(AnalyticsEvent.MHA_FORM_AMHP_EXPORTED_FAILURE, {
            error: e.message,
          });
          buildErr(downloadFormError, setLastException);
        })
        .then(() => resetBottomSheet());
    });
  }, [formsToEmail]);

  const hasNoRecipient =
    ["emailToWard", "emailToApprovedSets"].includes(formAction || "never") && emailAddresses && !emailAddresses.length;

  return (
    <>
      {formAction !== null && formActions.length > 1 && (
        <>
          <ContentWrap style={styles.radioOptions}>
            <RadioButtonGroup
              onValueChange={(value: "emailToWard" | "emailToSelf" | "emailToApprovedSets") => {
                if (formAction !== value) setFormAction(value);
              }}
              value={formAction}
            >
              {formActions.map(fa => (
                <RadioButton key={fa.type} value={fa.type} status={formAction === fa.type} label={fa.label} />
              ))}
            </RadioButtonGroup>
          </ContentWrap>
        </>
      )}
      {hasEmailToHospitalFeature && (
        <Mod.HospitalEmails
          amhpTeamId={amhpTeamId}
          selectedHospital={selectedHospital}
          setSelectedHospital={setSelectedHospital}
          hospitalEmailAddress={emailAddresses}
          setHospitalEmailAddress={setEmailAddresses}
          setHospitalChoice={onSetHospitalChoice}
          displayComponent={formAction === "emailToWard"}
        />
      )}
      {hasEmailToListsFeature && (
        <Mod.EmailLists
          amhpTeamId={amhpTeamId}
          emailAddresses={emailAddresses}
          setEmailAddresses={setEmailAddresses}
          displayComponent={formAction === "emailToApprovedSets"}
          setApprovedRecipientChoice={setApprovedRecipientChoice}
        />
      )}
      <ContentWrap>
        {formAction === "emailToSelf" && (
          <View style={styles.emailAddressContent}>
            <Text format={TypographyType.Regular} style={styles.smallHeading}>
              Selected email address(es)
            </Text>
            <Text format={TypographyType.Micro} color={palette.greyBlue} style={styles.smallHeading}>
              {user && user.email}
            </Text>
          </View>
        )}
        <Text format={TypographyType.HeadingSmall} style={styles.smallHeading}>
          Select Form(s)
        </Text>
        {type === "email" ? (
          <Text format={TypographyType.Small} color={palette.greyBlue}>
            Tap on the MHA form(s) you would like to email.
          </Text>
        ) : (
          <Text format={TypographyType.Small} color={palette.greyBlue}>
            Tap on the MHA form(s) you would like to download.
          </Text>
        )}
      </ContentWrap>
      <Mod.AssessmentFormsMultiSelect
        forms={signedForms}
        selectedForms={formsToEmail}
        setSelectedForms={setFormsToEmail}
        showPatientNameOnFormOptions={showPatientNameOnFormOptions}
      />

      <ContentWrap>
        <ButtonList>
          {hasEmailToHospitalFeature && formAction === "emailToWard" && (
            <Button onPress={_onSendForms} disabled={!formsToEmail.length || hasNoRecipient || submitted}>
              Send Selected Forms To Hospital
            </Button>
          )}
          {formAction === "emailToApprovedSets" && (
            <Button onPress={_onSendForms} disabled={!formsToEmail.length || hasNoRecipient || submitted}>
              Send Selected Forms To Recipients
            </Button>
          )}
          {user && user.email && formAction === "emailToSelf" && (
            <Button onPress={_onSendFormsToYourself} disabled={!formsToEmail.length || submitted}>
              Send Selected Forms To Yourself
            </Button>
          )}
          {formAction === "download" && Platform.OS === "web" && (
            <Button onPress={_onDownloadForms} disabled={!formsToEmail.length || submitted}>
              Download
            </Button>
          )}
        </ButtonList>
      </ContentWrap>
    </>
  );
};

const styles = StyleSheet.create({
  smallHeading: {
    marginBottom: spacing[15],
  },
  radioOptions: {
    marginBottom: spacing[20],
  },
  emailAddressContent: {
    flexDirection: "column",
    alignItems: "flex-start",
    flex: 1,
    flexWrap: "wrap",
    marginBottom: spacing[15],
  },
});
