import React from "react";
import { StyleSheet } from "react-native";

import EventItem from "./EventItem";
import { EventsListProps } from "./EventsList.props";
import { spacing } from "../../theme";

const EventsList: React.FC<EventsListProps> = ({ events, themeColor }) => {
  const Events = events.map((event, index, arr) => {
    const renderLine = index !== arr.length - 1;
    return (
      <EventItem
        key={index}
        event={event}
        themeColor={themeColor}
        renderLine={renderLine}
        style={[styles.event, index === arr.length - 1 && styles.eventLast]}
      />
    );
  });

  return <>{Events}</>;
};

const styles = StyleSheet.create({
  event: {
    position: "relative",
    flexDirection: "row",
    alignItems: "flex-start",
    marginBottom: spacing[10],
  },
  eventLast: {
    marginBottom: 0,
  },
});

export default EventsList;
