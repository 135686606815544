import * as React from "react";
import { View, StyleSheet, TouchableHighlight, ScrollView, Platform } from "react-native";
import { Modal as RNPModal, Portal } from "react-native-paper";
import MaterialIcon from "react-native-vector-icons/MaterialIcons";

import { CardButtons } from "../CardButtons";
import { CardButton } from "../CardButtons/CardButtons";
import Text from "../Text";
import { TypographyType } from "@/models/Typography";
import { color, spacing } from "../../theme";
import { useKeyboard, useDeviceHeight } from "../../utils/helpers";
import { useOnAppBlur } from "../../hooks";

export function Modal(props: {
  children: React.ReactNode;
  visible: boolean;
  dismissable: boolean;
  onDismiss?: () => void;
  headline: string;
  headerBackgroundColor?: string;
  headerTextColor?: string;
  removeInnerScroller?: boolean;
  subheading?: string;
  buttons?: CardButton[];
  testID?: string;
  wrapContent?: boolean;
}) {
  const keyboard = useKeyboard();
  const deviceHeight = useDeviceHeight();
  const [contentHeight, setContentHeight] = React.useState(0);
  const [containerHeight, setContainerHeight] = React.useState(0);
  const [headerHeight, setHeaderHeight] = React.useState(0);
  const [bottomHeight, setBottomHeight] = React.useState(0);
  const { wrapContent = true } = props;

  const scrollHeight = () => {
    if (Platform.OS !== "web") return "auto";
    return contentHeight > containerHeight - (headerHeight + bottomHeight) ? deviceHeight * 0.6 : "100%";
  };

  const Wrapper = props.removeInnerScroller ? View : ScrollView;
  const wrapperStyles = props.removeInnerScroller
    ? {}
    : {
        // eslint-disable-next-line{
        height: scrollHeight(),
        paddingBottom: 50,
      };

  useOnAppBlur(props?.onDismiss);

  return (
    <Portal>
      <RNPModal
        visible={props.visible}
        dismissable={props.dismissable || false}
        onDismiss={props.onDismiss}
        contentContainerStyle={styles.modalWrapper}
      >
        <View
          style={[styles.modalContainer, keyboard.isActive ? styles.adjustedHeight : {}]}
          onLayout={(e: { nativeEvent: any }) => {
            if (Platform.OS === "web") setContainerHeight(e.nativeEvent.layout.height);
          }}
        >
          <View
            style={[
              styles.modalHeaderContainer,
              props.headerBackgroundColor ? { backgroundColor: props.headerBackgroundColor } : {},
            ]}
            testID={`RNPModal__${props.testID}`}
            onLayout={(e: { nativeEvent: any }) => {
              if (Platform.OS === "web") setHeaderHeight(e.nativeEvent.layout.height);
            }}
          >
            <View>
              <Text
                format={TypographyType.ButtonText}
                type="paper"
                style={[styles.modalHeadline, props.headerTextColor ? { color: props.headerTextColor } : {}]}
              >
                {props.headline}
              </Text>
              {props.subheading && (
                <Text format={TypographyType.Small} style={styles.modalSubheading}>
                  {props.subheading}
                </Text>
              )}
            </View>
            {props.dismissable && (
              <TouchableHighlight onPress={props.onDismiss} underlayColor={color.textError} style={styles.closeIcon}>
                <MaterialIcon name="close" size={24} color={color.textWhite} />
              </TouchableHighlight>
            )}
          </View>
          <View
            style={[
              styles.modalContent,
              !wrapContent && styles.modalContentNoPadding,
              props.buttons && { marginBottom: spacing[30] },
              {
                paddingBottom: keyboard.height,
              },
            ]}
          >
            <Wrapper style={wrapperStyles}>
              <View
                onLayout={(e: { nativeEvent: any }) => {
                  if (Platform.OS === "web") setContentHeight(e.nativeEvent.layout.height);
                }}
              >
                {props.children}
              </View>
            </Wrapper>
          </View>
          {props.buttons && (
            <View
              style={styles.bottomBar}
              onLayout={(e: { nativeEvent: any }) => {
                if (Platform.OS === "web") setBottomHeight(e.nativeEvent.layout.height);
              }}
            >
              <CardButtons buttons={props.buttons} />
            </View>
          )}
        </View>
      </RNPModal>
    </Portal>
  );
}

const styles = StyleSheet.create({
  closeIcon: {
    position: "absolute",
    right: 0,
    top: 0,
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: spacing[25],
    // eslint-disable-next-line
    backgroundColor: color.darken,
    borderTopRightRadius: 8,
  },

  modalHeaderContainer: {
    position: "relative",
    alignItems: "flex-start",
    justifyContent: "space-between",
    flexDirection: "row",
    backgroundColor: color.primary,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  modalHeadline: {
    color: color.textWhite,
    paddingVertical: spacing[25],
    paddingLeft: spacing[30],
  },
  modalWrapper: {
    width: "90%",
    maxWidth: 800,
    flexBasis: "95%",
    flexShrink: 1,
    flex: 0,
    alignSelf: "center",
    overflow: "visible",
  },
  modalContainer: {
    width: "100%",
    maxHeight: "90%",
    backgroundColor: color.background,
    paddingBottom: 50,
    borderRadius: 8,
    overflow: "visible",
  },
  modalSubheading: {
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: spacing[20],
  },
  modalContent: {
    paddingHorizontal: spacing[30],
    paddingVertical: spacing[25],
  },
  modalContentNoPadding: {
    paddingHorizontal: 0,
    paddingVertical: 0,
  },
  adjustedHeight: { maxHeight: "100%" },
  bottomBar: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    backgroundColor: color.background,
  },
});
