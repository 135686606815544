import * as Yup from "yup";
import dayjs from "dayjs";

import createDateSchema from "libs/validators/yup/createDateSchema";
import { ComponentElement, FormFieldsDoctor } from "libs/types/mhaForms";

const componentElements: ComponentElement[] = [
  {
    component: "Text",
    componentProps: {
      instruction: "I last examined this patient on",
    },
    fullWidth: true,
  },
  {
    component: "Date",
    fieldName: FormFieldsDoctor.AssessmentDate,
    componentProps: {},
  },
  {
    component: "Text",
    componentProps: {
      instruction: "at",
    },
    fullWidth: true,
  },
  {
    component: "Time",
    fieldName: FormFieldsDoctor.AssessmentTime,
    componentProps: {
      label: "Time",
    },
  },

  {
    component: "Checkbox",
    fieldName: FormFieldsDoctor.ExaminationType,
    componentProps: {
      options: [
        {
          status: "previousAcquaintance",
          label: "I had previous acquaintance with the patient before I conducted that examination.",
        },
        {
          status: "section12Approved",
          label:
            "I am approved under section 12 of the Act as having special experience in the diagnosis or treatment of mental disorder.",
        },
      ],
    },
  },

  {
    component: "Text",
    componentProps: {
      guidance: "(Check as applicable)",
      pdfOnlyGuidance: "(* Delete if not applicable)",
    },
    fullWidth: true,
  },
];

export default {
  title: "Examination Details",
  state: {
    assessmentDate: "",
    assessmentTime: JSON.stringify({
      hours: 12,
      minutes: 0,
    }),
    examinationType: [],
  },

  validation: Yup.object().shape({
    assessmentDate: Yup.lazy(() =>
      createDateSchema({
        min: dayjs().subtract(15, "day").startOf("day"),
        max: dayjs().endOf("day"),
        required: true,
      })
    ),
    examinationType: Yup.array(),
  }),

  componentElements,
};
