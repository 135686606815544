import React from "react";
import {
  GestureResponderEvent,
  Platform,
  StyleProp,
  StyleSheet,
  TouchableWithoutFeedback,
  View,
  ViewStyle,
  Keyboard,
} from "react-native";

import { TypographyType } from "@/models/Typography";
import { palette, spacing } from "../../theme";
import Icon from "../Icon";
import Text from "../Text/Text";

export interface CheckboxProps {
  status: boolean;
  disabled?: boolean;
  label?: string;
  onPress?: (event: GestureResponderEvent) => void;
  prefix?: string;
  style?: StyleProp<ViewStyle>;
  verticalLayout?: boolean;
}

// TODO: add "prefix" as seen in mhaForm radioButtonComponent
export const Checkbox = (props: CheckboxProps) => {
  const { status, onPress, label, prefix, verticalLayout, style, disabled = false } = props;

  const onCheckboxPress = (event: GestureResponderEvent) => {
    onPress && onPress(event);
    Keyboard.dismiss();
  };

  return (
    <TouchableWithoutFeedback onPress={event => onCheckboxPress(event)} disabled={disabled}>
      <View
        style={[
          styles.checkboxWrapper,
          verticalLayout && styles.verticalCheckboxWrapper,
          disabled && styles.disabledWrapper,
        ]}
      >
        <View style={[checkboxStyles(status, verticalLayout), style]}>
          {status && <Icon name="check" color={palette.navy} />}
        </View>

        <View style={styles.textContainer}>
          {prefix && (
            <Text
              format={status ? TypographyType.TinyBold : TypographyType.Tiny}
              style={[styles.prefix, disabled && styles.disabledText]}
            >
              {prefix}
            </Text>
          )}

          <Text
            format={TypographyType.Tiny}
            style={[status && styles.fontBold, disabled ? styles.disabledText : styles.text]}
          >
            {label}
          </Text>
        </View>
      </View>
    </TouchableWithoutFeedback>
  );
};

const checkboxStyles = (status: boolean, verticalLayout?: boolean) => [
  styles.checkbox,
  status && styles.checkboxChecked,
  verticalLayout && styles.verticalCheckbox,
];

const styles = StyleSheet.create({
  checkboxWrapper: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "row",
    flexShrink: 1,
    marginBottom: Platform.OS === "web" ? 0 : spacing[15],
    ...Platform.select({
      web: {
        cursor: "pointer",
      },
    }),
  },
  disabledWrapper: {
    display: "flex",
    ...Platform.select({
      web: {
        cursor: "auto",
      },
    }),
  },
  verticalCheckboxWrapper: {
    flexDirection: "column",
  },
  fontBold: {
    fontWeight: "bold",
  },
  disabledText: {
    color: palette.greyBlue,
  },
  prefix: {
    marginRight: spacing[10],
  },
  textContainer: {
    alignItems: "flex-start",
    flexShrink: 1,
    flexDirection: "row",
    paddingTop: 4,
  },
  text: {
    color: palette.navy,
  },
  checkbox: {
    width: 28,
    height: 28,
    borderWidth: 2,
    borderRadius: 6,
    marginRight: spacing[15],
    borderColor: palette.greyBlue,
    backgroundColor: palette.white,
  },
  checkboxChecked: {
    borderColor: palette.aqua,
    backgroundColor: palette.aqua,
  },
  verticalCheckbox: {
    marginRight: 0,
    marginBottom: spacing[5],
  },
});
