import React, { useMemo } from "react";
import { ApolloProvider } from "react-apollo";
import { getApolloContext as getApolloHooksContext } from "@apollo/react-hooks";
import ApolloClient from "apollo-boost";

// see https://github.com/apollographql/react-apollo/issues/3595#issuecomment-577586255
type Client = ApolloClient<Record<string, unknown>>;

const CustomApolloProvider = (props: { client: Client; children: React.ReactNode }) => {
  const ApolloContext = getApolloHooksContext();
  const value = useMemo(() => ({ client: props.client }), [props.client]);
  return (
    <ApolloContext.Provider value={value}>
      <ApolloProvider client={value.client}>{props.children}</ApolloProvider>
    </ApolloContext.Provider>
  );
};
// use normal for ssr
export default CustomApolloProvider;
/*

<ApolloProvider
client={isDemoEnvironment ? demoApolloClient : apolloClient}
>
<ApolloHooksProvider
  client={isDemoEnvironment ? demoApolloClient : apolloClient}
> */
