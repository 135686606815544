import React, { useMemo, Fragment } from "react";
import { StyleSheet, View } from "react-native";
import { HelperText } from "react-native-paper";

import { TypographyType } from "@/models/Typography";
import { palette, spacing, color } from "../../../theme";
import { Checkbox } from "../../Checkbox/Checkbox";
import Text from "../../Text/Text";
import ItemSpacer from "../../ItemSpacer";
import {
  MHAEditableCheckboxComponentProps,
  MHACheckboxOptionProps,
  MHAItemisedCheckboxOptionProps,
} from "libs/types/mhaForms";
import { getItemisation } from "libs/mhaForms/helpers";

export const MHACheckbox = React.memo((props: MHAEditableCheckboxComponentProps) => {
  const { readonly, disabled, options, checkedValues, onPress, error, errorText, itemisation } = props;
  const readonlyStyle = useMemo(() => [{ color: disabled ? palette.greyBlue : palette.navy }], [readonly, disabled]);

  const readOnlyCheckboxes = useMemo(
    () =>
      options.map((option: MHACheckboxOptionProps | MHAItemisedCheckboxOptionProps, i) => {
        const prefix = option.prefix || (itemisation && "(" + getItemisation(itemisation, i) + ")");
        return (
          <View key={i} style={{ flexDirection: "row" }}>
            {options.length > 1 && !prefix && (
              <Text
                format={TypographyType.Tiny}
                style={[
                  readonlyStyle,
                  !checkedValues.includes(option.status) && readonly && styles.checkboxStrikethrough,
                ]}
              >
                *
              </Text>
            )}
            {prefix && (
              <Text
                format={TypographyType.Tiny}
                style={[
                  readonlyStyle,
                  !checkedValues.includes(option.status) && readonly && styles.checkboxStrikethrough,
                  styles.readonly,
                ]}
              >
                {prefix}
              </Text>
            )}
            <Text
              format={TypographyType.Tiny}
              style={[
                readonlyStyle,
                !checkedValues.includes(option.status) && readonly && styles.checkboxStrikethrough,
              ]}
            >
              {option.label}
            </Text>
          </View>
        );
      }),
    [readonly, checkedValues, options, itemisation]
  );

  const checkboxes = useMemo(
    () =>
      options.map((option: MHACheckboxOptionProps | MHAItemisedCheckboxOptionProps, i: number) => (
        <Fragment key={i}>
          <Checkbox
            {...option}
            status={checkedValues.includes(option.status)}
            onPress={() =>
              onPress(
                checkedValues.includes(option.status)
                  ? checkedValues.filter(s => s !== option.status)
                  : [...checkedValues, option.status]
              )
            }
          />
        </Fragment>
      )),
    [options, checkedValues, itemisation]
  );

  return (
    <View style={styles.wrapper}>
      <ItemSpacer gap={10}>{readonly ? readOnlyCheckboxes : checkboxes}</ItemSpacer>
      {!props.readonly && (
        <HelperText style={styles.error} type="error" visible={error}>
          {errorText || ""}
        </HelperText>
      )}
    </View>
  );
});

const styles = StyleSheet.create({
  wrapper: {
    marginTop: spacing[10],
  },
  readonly: {
    minWidth: 23,
  },
  checkboxStrikethrough: {
    textDecorationLine: "line-through",
    textDecorationStyle: "solid",
  },
  error: { color: color.textError, minHeight: 23 },
});
