import React, { useState } from "react";
import { StyleSheet, View } from "react-native";
import Auth from "@aws-amplify/auth";
import { useRecoilState, useSetRecoilState } from "recoil";
import { StackNavigationOptions } from "@react-navigation/stack";

import { BackButtonProps } from "@/components/BackButton/BackButton.props";
import { Button } from "@/components/Button/Button";
import { Checkbox } from "@/components/Checkbox/Checkbox";
import { ContentWrap } from "@/components/ContentWrap";
import Text from "@/components/Text";
import ENV from "@/config";
import { AppScreen } from "@/layouts/AppScreen/AppScreen";
import { buildErr, acceptEulaError } from "@/models/Error";
import { TypographyType } from "@/models/Typography";
import { RouteKeys } from "@/navigationv2";
import { RootNavigationProps } from "@/navigationv2/types";
import { createScreenTitle } from "@/navigationv2/utilities";
import { color } from "@/theme/color";
import { spacing } from "@/theme/spacing";
import { userDetails, lastException } from "@/utils/recoil/index";
import { isExternalDoctor } from "../../utils/user";

interface PropTypes extends RootNavigationProps<RouteKeys.EulaScreen> {}

const EulaScreen = (props: PropTypes) => {
  const { navigation, route } = props;
  const [user, setUserDetails] = useRecoilState(userDetails);
  const setLastException = useSetRecoilState(lastException);
  const [hasAccepted, setHasAccepted] = useState(false);

  const backButtonConfig: BackButtonProps = {
    enabled: !route.params || route.params.isAccepted !== "false",
    float: true,
    color: color.textWhite,
  };

  return (
    <AppScreen contextLabel="Support" pageTitle="Terms of Use" backButton={backButtonConfig} testID="EulaScreen">
      <ContentWrap style={styles.container}>
        <Text format={TypographyType.HeadingSmall} style={styles.heading}>
          Terms and Conditions
        </Text>
        <Eula />
        {route.params && route.params.isAccepted === "false" && (
          <View style={styles.checkbox}>
            <Checkbox
              status={hasAccepted}
              label={"I accept these terms of use"}
              onPress={() => setHasAccepted(!hasAccepted)}
            />
            <Button
              style={styles.button}
              disabled={!hasAccepted}
              onPress={() =>
                Auth.currentAuthenticatedUser()
                  .then(user =>
                    // error catch bubbles up in the promise chain
                    Auth.updateUserAttributes(user, {
                      "custom:eula_accepted": ENV.EULA_VERSION.toString(),
                    }).then(() => Auth.currentAuthenticatedUser({ bypassCache: true }))
                  )
                  // setting user details will cause container navigator to route properly.
                  // when changing eula version here when eula updates, change it in the container navigator routing logic
                  .then(() => {
                    user && setUserDetails({ ...user, eulaVersion: ENV.EULA_VERSION });
                    isExternalDoctor(user)
                      ? navigation.navigate("ExternalDoctorNavigator")
                      : navigation.navigate("HomeNavigator");
                  })
                  .catch(buildErr(acceptEulaError, setLastException))
              }
            >
              Submit
            </Button>
          </View>
        )}
      </ContentWrap>
    </AppScreen>
  );
};

const screenOptions: StackNavigationOptions = {
  title: createScreenTitle("Welcome"),
};

EulaScreen.screenOptions = screenOptions;

export default EulaScreen;

export const Eula = () => (
  <>
    <Text format={TypographyType.RegularBold}>S12 SOLUTIONS LIMITED APP EULA{"\n"}</Text>
    <Text format={TypographyType.RegularBold}>PLEASE READ THESE LICENCE TERMS CAREFULLY</Text>
    <Text format={TypographyType.Tiny}>
      BY DOWNLOADING AND/OR USING THE APP YOU ARE AGREEING TO THESE TERMS WHICH WILL BIND YOU. IF YOU DO NOT WISH TO
      AGREE TO THESE TERMS YOU MAY NOT DOWNLOAD OR USE THE APP.
      {"\n"}
      {"\n"}
    </Text>
    <Text format={TypographyType.TinyBold}>Who we are and what these terms do</Text>
    <Text format={TypographyType.Tiny}>
      We S12 Solutions Limited of 5 Kelso Place, Bath BA1 3AU license you to use:{"\n"}
      S12 Solutions mobile application software, the data supplied with the software (App) and any updates or
      supplements to it; and {"\n"}
      the service you connect to via the App and the content we provide to you through it (Service). {"\n"}
      as permitted in these terms.
      {"\n"}
      {"\n"}
    </Text>
    <Text format={TypographyType.TinyBold}>You must be an Approved Person or External User</Text>
    <Text format={TypographyType.Tiny}>
      You must not download or use the App unless you are an approved mental health professional (AMHP) or a medical
      practitioner approved by the relevant authorities under Section 12(2) of the Mental Health Act 1983 or are a user
      performing a purely administrative function (Approved Person) or someone invited to use the video calling function
      and/or statutory form function of the App by an Approved Person that is an AMHP (External User).{"\n"}
      If you cease to be an Approved Person or an External User your licence to use the App shall automatically
      terminate, you must stop using the App and delete it from your device.
      {"\n"}
      {"\n"}
    </Text>
    <Text format={TypographyType.TinyBold}>If you are an External User</Text>
    <Text format={TypographyType.Tiny}>
      The licence granted to you under these terms is limited to a licence to access the video calling and/or statutory
      form functions as notified to you by us or by an Approved Person that is an AMHP. You shall not access any other
      functions of the App.{"\n"}
      Your licence to access the video calling function ends when your participation in a video call ends. Your licence
      to access the statutory form function ends when the relevant statutory form has been deleted from the App (whether
      by you or by us under our automatic deletion policy){"\n"}
      You may only access the App in your professional capacity and warrant to us that you have appropriate professional
      qualifications and an appropriate reason to be a participant in a video call and/or view and amend statutory forms
      held in the App.{"\n"}
      You may not access the statutory form function unless you are a medical practitioner approved by the relevant
      authorities under Section 12(2) of the Mental Health Act 1983.
      {"\n"}
      {"\n"}
    </Text>
    <Text format={TypographyType.TinyBold}>If you are an Approved Person that is an AMHP</Text>
    <Text format={TypographyType.Tiny}>
      ou may request permission for an External User to be given temporary access to the video calling and/or statutory
      form functions in the App. Prior to requesting permission for such access you shall verify the professional
      qualifications of the External User and determine whether such External User is a suitable person to be given such
      access.{"\n"}
      For the video calling function you may only invite a person that is participating in such video call in an
      appropriate professional capacity and who has appropriate professional qualifications.{"\n"}
      For the statutory form function you may only invite a person that is a medical practitioner approved by the
      relevant authorities under Section 12(2) of the Mental Health Act 1983.{"\n"}
      {"\n"}
    </Text>
    <Text format={TypographyType.TinyBold}>Your privacy</Text>
    <Text format={TypographyType.Tiny}>
      We only use any personal data we collect through your use of the App and the Service in the ways set out in our
      privacy policy http://s12solutions.com/policies/app-privacy.pdf.{"\n"}
      Please be aware that internet transmissions are never completely private or secure and that any message or
      information you send using the App or the Service may be read or intercepted by others, even if there is a special
      notice that a particular transmission is encrypted.{"\n"}
      You must not upload any personal data belonging to a third party unless you have the consent of that third party
      or another legal basis on which to do so.{"\n"}
      {"\n"}
    </Text>
    <Text format={TypographyType.TinyBold}>Amazon Website Services, Inc 's terms also apply</Text>
    <Text format={TypographyType.Tiny}>
      The ways in which you can use the App may also be controlled by Amazon Website Service, Inc's rules and policies
      https://aws.amazon.com/legal/?nc1=f_cc and Amazon Website Service, Inc's rules and policies will apply instead of
      these terms where there are differences between the two.{"\n"}
      {"\n"}
    </Text>
    <Text format={TypographyType.TinyBold}>Support for the App and how to tell us about problems</Text>
    <Text format={TypographyType.Tiny}>
      Support. If you want to learn more about the App or the Service or have any problems using them please contact
      s12solutions-support@vitalhub.com
    </Text>
    <Text format={TypographyType.Tiny}>
      Contacting us (including with complaints). If you think the App or the Service are faulty or misdescribed or wish
      to contact us for any other reason please email our customer service team at s12solutions-support@vitalhub.com.
      {"\n"}
      How we will communicate with you. If we have to contact you we will do so by email, by SMS or by pre-paid post,
      using the contact details you have provided to us.{"\n"}
      How you may use the App, including how many devices you may use it on:{"\n"}
      In return for your agreeing to comply with these terms you may: download or stream a copy of the App onto your
      personal computer, phone or other handheld device and view, use and display the App and the Service on such
      devices for your personal purposes only (where you are an External User you may only access the video call
      function and/or the statutory form function as appropriate); and receive and use any free supplementary software
      code or update of the App incorporating "patches" and corrections of errors as we may provide to you.{"\n"}
      {"\n"}
    </Text>
    <Text format={TypographyType.TinyBold}>You must be 18 to accept these terms and download the App</Text>
    <Text format={TypographyType.Tiny}>
      You must be 18 or over to accept these terms and download the App.{"\n"}
      {"\n"}
    </Text>
    <Text format={TypographyType.TinyBold}>You may not transfer the App to someone else</Text>
    <Text format={TypographyType.Tiny}>
      We are giving you personally the right to use the App and the Service as set out above. You may not otherwise
      transfer the App or the Service to someone else, whether for money, for anything else or for free. If you sell any
      device on which the App is installed, you must remove the App from it prior to sale.{"\n"}
      {"\n"}
    </Text>
    <Text format={TypographyType.TinyBold}>Changes to these terms</Text>
    <Text format={TypographyType.Tiny}>
      We may need to change these terms to reflect changes in law or best practice or to deal with additional features
      which we introduce.{"\n"}
      We will give you at least 30 days’ notice of any change by sending you an SMS with details of the change or by
      notifying you of a change when you next start the App.{"\n"}
      If you do not wish to accept the notified changes you must not continue to use the App and the Service.{"\n"}
      {"\n"}
    </Text>
    <Text format={TypographyType.TinyBold}>Update to the App and changes to the Service</Text>
    <Text format={TypographyType.Tiny}>
      From time to time we may automatically update the App and change the Service to improve performance, enhance
      functionality, reflect changes to the operating system or address security issues. Alternatively we may ask you to
      update the App for these reasons.{"\n"}
      If you choose not to install such updates or if you opt out of automatic updates you may not be able to continue
      using the App and the Service.{"\n"}
      {"\n"}
    </Text>
    <Text format={TypographyType.TinyBold}>If someone else owns the phone or device you are using</Text>
    <Text format={TypographyType.Tiny}>
      If you download or stream the App onto any phone or other device not owned by you, you must have the owner's
      permission to do so. You will be responsible for complying with these terms, whether or not you own the phone or
      other device.{"\n"}
      {"\n"}
    </Text>
    <Text format={TypographyType.TinyBold}>We may collect technical data about your device</Text>
    <Text format={TypographyType.Tiny}>
      By using the App or the Service, you agree to us collecting and using technical information about the devices you
      use the App on and related software, hardware and peripherals to improve our products and to provide Service to
      you.{"\n"}
      {"\n"}
    </Text>
    <Text format={TypographyType.TinyBold}>We may process audio and video data</Text>
    <Text format={TypographyType.Tiny}>
      If the video call function is made available to you on the App and you choose to use it that will result in audio
      and video data from that video call being transferred from your device to the devices of the other participants in
      that video call. We do not view, record or store any of that audio or video data and none of it is transferred
      outside of the UK.{"\n"}
      By using the video call function, you agree to us collecting and using such data to provide that function. If you
      do not want your data to be processed in this way then do not access the video call function on the App.{"\n"}
      {"\n"}
    </Text>
    <Text format={TypographyType.TinyBold}>We may process statutory form data</Text>
    <Text format={TypographyType.Tiny}>
      If the statutory form function is made available to you on the App and you choose to use it that will result in
      personal data relating to persons undergoing a mental health assessment being entered into the App. You shall not
      enter any such personal data into the App unless you are lawfully able to do so (including being lawfully able to
      provide such personal data to us).{"\n"}
      If you enter any such personal data into the App you must inform such persons that their personal data will be
      processed in accordance with our privacy policy and refer them to our privacy policy for further information. We
      will provide a copy of our privacy policy to any such person if they contact us through our website at
      s12solutions.com or by emailing us at s12solutions-support@vitalhub.com.{"\n"}
      You understand and agree that all data contained in the statutory form function of the App will be deleted by us
      30 days after it has been created. If you will require access to that data after the deletion date you must export
      that data before it is deleted, it will not be recoverable after that date.{"\n"}
      {"\n"}
    </Text>
    <Text format={TypographyType.TinyBold}>We are not responsible for other websites you link to</Text>
    <Text format={TypographyType.Tiny}>
      The App or the Service may contain links to other independent websites which are not provided by us. Such
      independent sites are not under our control, and we are not responsible for and have not checked and approved
      their content or their privacy policies (if any).{"\n"}
      You will need to make your own independent judgement about whether to use any such independent sites, including
      whether to buy any products or Service offered by them.{"\n"}
      {"\n"}
    </Text>
    <Text format={TypographyType.TinyBold}>Licence restrictions</Text>
    <Text format={TypographyType.Tiny}>
      You agree that you will:{"\n"}
      not rent, lease, sub-license, loan, provide, or otherwise make available, the App or the Service in any form, in
      whole or in part to any person without prior written consent from us;{"\n"}
      not copy the App or Service, except as part of the normal use of the App or where it is necessary for the purpose
      of back-up or operational security;{"\n"}
      not translate, merge, adapt, vary, alter or modify, the whole or any part of the App or Service nor permit the App
      or the Service or any part of them to be combined with, or become incorporated in, any other programs, except as
      necessary to use the App and the Service on devices as permitted in these terms;{"\n"}
      not disassemble, de-compile, reverse engineer or create derivative works based on the whole or any part of the App
      or the Service nor attempt to do any such things, except to the extent that (by virtue of sections 50B and 296A of
      the Copyright, Designs and Patents Act 1988) such actions cannot be prohibited because they are necessary to
      decompile the App to obtain the information necessary to create an independent program that can be operated with
      the App or with another program (Permitted Objective), and provided that the information obtained by you during
      such activities:{"\n"}
      is not disclosed or communicated without our prior written consent to any third party to whom it is not necessary
      to disclose or communicate it in order to achieve the Permitted Objective; and{"\n"}
      is not used to create any software that is substantially similar in its expression to the App;{"\n"}
      is kept secure; and{"\n"}
      is used only for the Permitted Objective;{"\n"}
      {"\n"}
      comply with all applicable technology control or export laws and regulations that apply to the technology used or
      supported by the App or the Service
      {"\n"}
      {"\n"}
    </Text>
    <Text format={TypographyType.TinyBold}>Acceptable use restrictions</Text>
    <Text format={TypographyType.Tiny}>
      You must: not use the App or the Service in any unlawful manner, for any unlawful purpose, or in any manner
      inconsistent with these terms, or act fraudulently or maliciously, for example, by hacking into or inserting
      malicious code, such as viruses, or harmful data, into the App, the Service or any operating system;{"\n"}
      not infringe our intellectual property rights or those of any third party in relation to your use of the App or
      the Service, including by the submission of any material (to the extent that such use is not licensed by these
      terms);{"\n"}
      not transmit any material that is defamatory, offensive or otherwise objectionable in relation to your use of the
      App or the Service;{"\n"}
      not use the App or the Service in a way that could damage, disable, overburden, impair or compromise our systems
      or security or interfere with other users; and{"\n"}
      not collect or harvest any information or data from the Service or our systems or attempt to decipher any
      transmissions to or from the servers running the Service.{"\n"}
      {"\n"}
    </Text>
    <Text format={TypographyType.TinyBold}>Submitted Content</Text>
    <Text format={TypographyType.Tiny}>
      You agree that if you submit any content to the App:{"\n"}
      any submission will be accurate and complete;{"\n"}
      it will not contain any special category personal data other than:{"\n"}
      where you are not using the statutory form function - the location, date and time of a mental health assessment
      and the NHS number, name, address (home and place of the mental health assessment) and GP practice of the person
      being assessed; or where you are using the statutory form function – the special category personal data set out
      above and in addition the date a subject was last examined by a doctor, date and time an approved mental health
      professional last saw the subject, whether the hospital admission is in an emergency or for an assessment and or
      treatment of mental disorder, name and address of the subject’s relatives/person authorised to act on their
      behalf, doctors’ opinion on why the subject should be detained, reasons why a medical practitioner with prior
      knowledge of the subject is not providing an opinion (if relevant), name and address of person(s) completing the
      form{"\n"}
      by making such submission you grant us and our suppliers a royalty free, worldwide right to use the data submitted
      in connection with the App and the Service;{"\n"}
      you have all necessary rights and consents from relevant third parties to submit the content;{"\n"}
      you are responsible for the content of such submission and all consequences of its publication, that we do not
      accept any liability for such content and have the right to remove it if we think necessary; and{"\n"}
      the submission will be made available to other users of the App and recipients of the Service and you waive any
      rights to confidentiality in respect of such submission.{"\n"}
      {"\n"}
    </Text>
    <Text format={TypographyType.TinyBold}>Intellectual property rights</Text>
    <Text format={TypographyType.Tiny}>
      All intellectual property rights in the App, the Service and any documentation provided to you relating to the App
      or the Service throughout the world belong to us (or our licensors) and the rights in the App and the Service are
      licensed (not sold) to you. You have no intellectual property rights in, or to, the App, the Service or such
      documentation other than the right to use them in accordance with these terms.{"\n"}
      {"\n"}
    </Text>
    <Text format={TypographyType.TinyBold}>Our responsibility for loss or damage suffered by you</Text>
    <Text format={TypographyType.Tiny}>
      We are responsible to you for foreseeable loss and damage caused by us. If we fail to comply with these terms, we
      are responsible for loss or damage you suffer that is a foreseeable result of our breaking these terms or our
      failing to use reasonable care and skill. Loss or damage is foreseeable if either it is obvious that it will
      happen or if, at the time you accepted these terms, both we and you knew it might happen.{"\n"}
      We do not exclude or limit in any way our liability to you where it would be unlawful to do so. This includes
      liability for death or personal injury caused by our negligence or the negligence of our employees, agents or
      subcontractors or for fraud or fraudulent misrepresentation.{"\n"}
      When we are liable for damage to your property. If defective digital content that we have supplied damages a
      device or digital content belonging to you, we will either repair the damage or pay you compensation. However, we
      will not be liable for damage that you could have avoided by following our advice to apply an update offered to
      you free of charge or for damage that was caused by you failing to correctly follow installation instructions or
      to have in place the minimum system requirements advised by us.{"\n"}
      We are not liable for business losses. If you use the App for any commercial, business or resale purpose we will
      have no liability to you for any loss of profit, loss of business, business interruption, or loss of business
      opportunity.{"\n"}
      Limitations to the App and the Service. The App and the Service do not offer advice on which you should rely. You
      must obtain professional or specialist advice and use your own professional judgment before taking, or refraining
      from, any action based on information obtained from the App or the Service. Although we make reasonable efforts to
      update the information provided by the App and the Service, we make no representations, warranties or guarantees,
      whether express or implied, that such information is accurate, complete or up to date.{"\n"}
      Please back-up content and data used with the App. We recommend that you back-up any content and data used in
      connection with the App, to protect yourself in case of problems with the App or the Service.{"\n"}
      Check that the App and the Service are suitable for you. The App and the Service have not been developed to meet
      your individual requirements. Please check that the facilities and functions of the App and the Service meet your
      requirements.{"\n"}
      We are not responsible for events outside our control. If our provision of the Service or support for the App or
      the Service is delayed by an event outside our control then we will contact you as soon as possible to let you
      know and we will take steps to minimise the effect of the delay. Provided we do this we will not be liable for
      delays caused by the event but if there is a risk of substantial delay you may contact us to end your contract
      with us.{"\n"}
      {"\n"}
    </Text>
    <Text format={TypographyType.TinyBold}>
      We may end your rights to use the App and the Service if you break these terms
    </Text>
    <Text format={TypographyType.Tiny}>
      We may end your rights to use the App and the Service at any time by contacting you if you have broken these terms
      in a serious way. If what you have done can be put right we will give you a reasonable opportunity to do so.{"\n"}
      If we end your rights to use the App and the Service:{"\n"}
      you must stop all activities authorised by these terms, including your use of the App and the Service;{"\n"}
      you must delete or remove the App from all devices in your possession and immediately destroy all copies of the
      App which you have{"\n"}
      and confirm to us that you have done this; and{"\n"}
      we may remotely access your devices and remove the App from them and cease providing you with access to the
      Service.{"\n"}
      {"\n"}
    </Text>
    <Text format={TypographyType.TinyBold}>We may transfer these terms to someone else</Text>
    <Text format={TypographyType.Tiny}>
      We may transfer our rights and obligations under these terms to another organisation. We will always tell you in
      {"\n"}
      writing if this happens and we will ensure that the transfer will not affect your rights under the contract.{"\n"}
      {"\n"}
    </Text>
    <Text format={TypographyType.TinyBold}>You need our consent to transfer your rights to someone else</Text>
    <Text format={TypographyType.Tiny}>
      You may only transfer your rights or your obligations under these terms to another person if we agree in writing.
      {"\n"}
      {"\n"}
    </Text>
    <Text format={TypographyType.TinyBold}>No rights for third parties</Text>
    <Text format={TypographyType.Tiny}>
      These terms do not give rise to any rights under the Contracts (Rights of Third Parties) Act 1999 to enforce any
      term of these terms.{"\n"}
      {"\n"}
    </Text>
    <Text format={TypographyType.TinyBold}>
      If a court finds part of these terms illegal, the rest will continue in force
    </Text>
    <Text format={TypographyType.Tiny}>
      Each paragraph of these terms operates separately. If any court or relevant authority decides that any of them are
      unlawful, the remaining paragraphs will remain in full force and effect.{"\n"}
      {"\n"}
    </Text>
    <Text format={TypographyType.TinyBold}>
      Even if we delay in enforcing these terms, we can still enforce them later
    </Text>
    <Text format={TypographyType.Tiny}>
      Even if we delay in enforcing these terms, we can still enforce them later. If we do not insist immediately that
      you do anything you are required to do under these terms, or if we delay in taking steps against you in respect of
      your breaking these terms, that will not mean that you do not have to do those things and it will not prevent us
      taking steps against you at a later date.{"\n"}
      {"\n"}
    </Text>
    <Text format={TypographyType.TinyBold}>
      Which laws apply to these terms and where you may bring legal proceedings
    </Text>
    <Text format={TypographyType.Tiny}>
      These terms are governed by English law and you can bring legal proceedings in respect of the products in the
      English courts. If you live in Scotland you can bring legal proceedings in respect of the products in either the
      Scottish or the English courts. If you live in Northern Ireland you can bring legal proceedings in respect of the
      products in either the Northern Irish or the English courts.{"\n"}
      {"\n"}
    </Text>
  </>
);

const styles = StyleSheet.create({
  container: {
    backgroundColor: color.background,
  },
  heading: {
    marginBottom: spacing[30],
  },
  button: {
    marginTop: spacing[10],
  },
  checkbox: {
    marginTop: spacing[10],
  },
});
