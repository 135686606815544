import React, { forwardRef } from "react";
import { StyleProp } from "react-native";

const DeviceVideo = forwardRef(
  (
    props: {
      controls?: boolean;
      style?: StyleProp<any>;
    },
    ref: (ref: any) => void
  ) => {
    return (
      <video
        style={props.style}
        width="100%"
        height="100%"
        ref={ref}
        autoPlay
        playsInline
        controls={props.controls || false}
      />
    );
  }
);

DeviceVideo.displayName = "DeviceVideo";

export default DeviceVideo;
