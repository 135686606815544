import React, { useState, useEffect } from "react";

import { BackButtonProps } from "@/components/BackButton/BackButton.props";
import ClaimDetails from "@/components/ClaimDetails";
import { ErrorMessage } from "@/components/Error/Error";
import { AppScreen } from "@/layouts/AppScreen/AppScreen";
import { RouteKeys } from "@/navigationv2";
import { DoctorTabRoutes, DoctorClaimsRoutes, ScreenNavigationProps, ScreenOptions } from "@/navigationv2/types";
import { createScreenTitle } from "@/navigationv2/utilities";
import { color } from "@/theme";
import { mqWeb } from "@/utils/helpers";

interface PropTypes extends ScreenNavigationProps<DoctorTabRoutes, DoctorClaimsRoutes, RouteKeys.ClaimDetailsScreen> {}

const ClaimDetailsScreen = (props: PropTypes) => {
  const { navigation, route } = props;
  // TODO: link thrown errors to error codes/messages? - michael
  const [patientName, setPatientName] = useState("");

  const { claimId = "" } = route.params;
  const backButtonConfig: BackButtonProps = {
    enabled: true,
    float: true,
    color: color.textWhite,
  };

  const isWebView = mqWeb();

  useEffect(() => {
    patientName && navigation.setOptions({ title: createScreenTitle(`${patientName} Claim Details`) });
  }, [navigation, patientName]);

  return (
    <AppScreen
      contextLabel="Claim"
      pageTitle={
        isWebView
          ? patientName
          : patientName
              .split(/ (.+)/)
              .map((word: string, i: number) => (i === 0 ? `${word}${"\n"}` : word))
              .join()
              .replace(/,/g, "")
      }
      backButton={backButtonConfig}
    >
      {!claimId ? (
        <ErrorMessage message="No Claim ID" />
      ) : (
        <ClaimDetails id={claimId} retrievePatientName={(name: string) => setPatientName(name)} />
      )}
    </AppScreen>
  );
};

const screenOptions: ScreenOptions = {
  title: createScreenTitle("Claim Details"),
};

ClaimDetailsScreen.screenOptions = screenOptions;

export default ClaimDetailsScreen;
