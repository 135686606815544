import Auth from "@aws-amplify/auth";
import React, { Dispatch, SetStateAction, useRef, useState } from "react";
import { StyleSheet, TouchableWithoutFeedback, View, Platform } from "react-native";
import { TextInput as RNTextInput } from "react-native-paper";
import { useSetRecoilState } from "recoil";

import { isValidEmail, isValidPhone } from "libs/validators";

import { BackButtonProps } from "@/components/BackButton/BackButton.props";
import Text from "@/components/Text";
import { TextInput } from "@/components/TextInput/TextInput";
import { AuthScreen } from "@/layouts";
import { TypographyType } from "@/models/Typography";
import { RouteKeys } from "@/navigationv2";
import { AuthNavigationProps, ScreenOptions } from "@/navigationv2/types";
import { createScreenTitle } from "@/navigationv2/utilities";
import { color, spacing } from "@/theme";
import { signup, loading } from "@/utils/recoil";
import { PasswordInput } from "@/components/PasswordInput";

interface PropTypes extends AuthNavigationProps<RouteKeys.SignUpScreen> {}

const SignUpScreen = (props: PropTypes) => {
  const emailInput = useRef<RNTextInput>(null);
  const passwordInput = useRef<RNTextInput>(null);
  const phoneInput = useRef<RNTextInput>(null);

  const [fullName, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [errors, seterrors] = useState([] as string[]);
  const [errorMessage, setErrorMessage] = useState("");
  const setSignup = useSetRecoilState(signup);
  const setLoading = useSetRecoilState(loading);

  const onChangeText = (setFunction: Dispatch<SetStateAction<string>>, v: any) => {
    setFunction(v);
  };

  const signUp = async () => {
    if (Platform.OS !== "web") setLoading(true);
    seterrors([]);
    setErrorMessage("");
    const errors = [];

    const updPhoneNumber = phoneNumber.replace(/\s/g, "");

    if (!isValidEmail(String(email).toLowerCase())) {
      errors.push("email");
    }
    if (!isValidPhone(String(updPhoneNumber))) {
      errors.push("phoneNumber");
    }
    if (!password || (password && password.trim().length < 8)) {
      errors.push("password");
    }
    if (errors.length) {
      seterrors(errors);
      return;
    }

    const phoneNumberFormatted = updPhoneNumber.slice(0, 1) === "0" ? `+44${updPhoneNumber.slice(1)}` : updPhoneNumber;

    await Auth.signUp({
      username: email,
      password,
      // rename variable to conform with Amplify Auth field phone attribute
      attributes: {
        email,
        // eslint-disable-next-line
        phone_number: phoneNumberFormatted,
        // gmcNumber,

        // TODO: This splitting is inaccurate for many name types, consider breaking down into two fields during data entry
        // eslint-disable-next-line
        given_name: fullName.split(" ")[0],
        // eslint-disable-next-line
        family_name: fullName.split(" ").slice(-1)[0],
      },
    })
      .then(() => {
        setTimeout(() => setLoading(false), 800);
        setSignup({ email, password });
        props.navigation.navigate(RouteKeys.Confirmation);
      })
      .catch(err => {
        setTimeout(() => setLoading(false), 800);
        setErrorMessage(err.message);
      });
  };

  const backButtonConfig: BackButtonProps = {
    enabled: true,
    float: true,
    color: "white",
  };

  return (
    <AuthScreen
      backButton={backButtonConfig}
      buttons={[
        {
          label: "Sign Up",
          disabled:
            !email ||
            (!!email && email.trim() === "") ||
            !password ||
            (!!password && password.trim() === "") ||
            !phoneNumber ||
            (!!phoneNumber && phoneNumber.trim() === "") ||
            !fullName ||
            (!!fullName && fullName.trim() === ""),
          onPress: () => signUp(),
        },
        {
          label: "Cancel",
          textButton: true,
          onPress: () => props.navigation.goBack(),
        },
        {
          label: "Problem signing up?",
          textButton: true,
          onPress: () => props.navigation.navigate(RouteKeys.Support),
        },
      ]}
    >
      <Text format={TypographyType.HeadingMedium} style={styles.headline}>
        Sign Up
      </Text>
      <TouchableWithoutFeedback onPress={e => e.stopPropagation()}>
        <View>
          <TextInput
            icon="person-outline"
            label="Full legal name"
            autoCorrect={false}
            autoCapitalize="none"
            returnKeyType="next"
            onSubmitEditing={() => {
              if (emailInput.current) emailInput.current.focus();
            }}
            value={fullName}
            onChangeText={value => onChangeText(setFullname, value)}
            withPasswordField={true}
          />
          {errors.length >= 1 && <View style={styles.errorMessageContainer} />}
          <TextInput
            error={errors.includes("email")}
            errorText="Please enter a valid email address"
            icon="email"
            label="Email address"
            autoCorrect={false}
            autoCapitalize="none"
            keyboardType="email-address"
            returnKeyType="next"
            ref={emailInput}
            onSubmitEditing={() => {
              if (phoneInput.current) phoneInput.current.focus();
            }}
            value={email}
            onChangeText={value => onChangeText(setEmail, value)}
            withPasswordField={true}
          />
          <TextInput
            error={errors.includes("phoneNumber")}
            errorText="Please enter a valid phone number"
            icon="phone"
            label="Phone number"
            autoCorrect={false}
            autoCapitalize="none"
            keyboardType="phone-pad"
            returnKeyType="next"
            onSubmitEditing={() => {
              if (passwordInput.current) passwordInput.current.focus();
            }}
            ref={phoneInput}
            value={phoneNumber}
            onChangeText={value => onChangeText(setPhoneNumber, value)}
            withPasswordField={true}
          />
          <PasswordInput
            error={errors.includes("password")}
            errorText="Your Password must be at least 8 characters in length."
            icon="lock-outline"
            label="Password"
            autoCorrect={false}
            autoCapitalize="none"
            returnKeyType="next"
            onSubmitEditing={() => {
              signUp();
            }}
            ref={passwordInput}
            value={password}
            underlineColor={errors.includes("password") ? color.textError : undefined}
            onChangeText={value => onChangeText(setPassword, value)}
          />
          {errorMessage !== "" && (
            <View>
              <Text format={TypographyType.Tiny} style={styles.errorMessage}>
                {errorMessage}
              </Text>
            </View>
          )}
        </View>
      </TouchableWithoutFeedback>
    </AuthScreen>
  );
};

const screenOptions: ScreenOptions = {
  title: createScreenTitle("Sign Up"),
};

SignUpScreen.screenOptions = screenOptions;

export default SignUpScreen;

const styles = StyleSheet.create({
  headline: {
    marginBottom: 40,
    alignSelf: "center",
  },
  errorMessageContainer: {
    minHeight: spacing[25],
    justifyContent: "center",
    width: "100%",
  },
  errorMessage: {
    color: color.textError,
    paddingLeft: 10,
  },
});
