import React from "react";

import { BackButtonProps } from "@/components/BackButton/BackButton.props";
import CreateClaim from "@/components/CreateClaim";
import { AppScreen } from "@/layouts/AppScreen/AppScreen";
import { RouteKeys } from "@/navigationv2";
import { AMHPAssessmentsRoutes, AMHPTabRoutes, ScreenNavigationProps, ScreenOptions } from "@/navigationv2/types";
import { createScreenTitle } from "@/navigationv2/utilities";
import { color } from "@/theme";
import { mqWeb } from "@/utils/helpers";

interface PropTypes extends ScreenNavigationProps<AMHPTabRoutes, AMHPAssessmentsRoutes, RouteKeys.CreateClaimScreen> {}

export default function CreateClaimScreen(props: PropTypes) {
  const { route } = props;
  const backButtonConfig: BackButtonProps = {
    enabled: true,
    float: true,
    color: color.textWhite,
  };

  const isWebView = mqWeb();

  return (
    <AppScreen
      contextLabel="Assessments"
      pageTitle={isWebView ? "Create Claim" : `Create ${"\n"}Claim`}
      backButton={backButtonConfig}
    >
      <CreateClaim visitId={route.params.visitId} />
    </AppScreen>
  );
}

const screenOptions: ScreenOptions = {
  title: createScreenTitle("Create Claim"),
};

CreateClaimScreen.screenOptions = screenOptions;
