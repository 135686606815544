import React from "react";
import { View, StyleSheet, Platform } from "react-native";

import { mqWeb } from "../../utils/helpers";
import { Tab } from "./Tab";
import { spacing } from "../../theme";

interface TabsProps {
  tabs: {
    label: string;
    active: boolean;
    badgeCount: number;
    onPress: () => void;
  }[];
}

export const Tabs: React.FC<TabsProps> = ({ tabs = [] }) => {
  const isWebView = mqWeb();
  return (
    <View style={isWebView ? styles.tabsRowWeb : styles.tabsRowMobile}>
      {tabs.map((tab, i) => (
        <Tab {...tab} key={i} />
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  tabsRowMobile: {
    paddingTop: spacing[20],
    paddingBottom: spacing[30],
    flexDirection: "row",
  },
  tabsRowWeb: {
    paddingTop: 0,
    paddingBottom: spacing[50],
    flexDirection: "row",
    ...Platform.select({
      web: {
        cursor: "pointer",
      },
    }),
  },
});
