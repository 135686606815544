import React from "react";
import { View, StyleSheet } from "react-native";

import { TypographyType } from "@/models/Typography";
import Text from "../../Text";
import DualTimePicker, { DualTimePickerProps } from "../../TimePicker/DualTimePicker";
import { spacing, palette } from "../../../theme";
import { leftPad } from "libs/dates";

interface MHATimePickerProps extends DualTimePickerProps {
  readonly?: boolean;
}

const MHATimePicker = (props: MHATimePickerProps) => {
  const { readonly = false, ...rest } = props;

  return !readonly ? (
    <DualTimePicker {...rest} noIcon />
  ) : (
    <View style={styles.readonlyWrapper}>
      <View
        style={{
          flexDirection: "row",
          paddingVertical: spacing[15],
          paddingHorizontal: spacing[20],
          backgroundColor: "white",
          marginRight: spacing[10],
        }}
      >
        <Text format={TypographyType.Small}>{leftPad(props.value.hours)}</Text>
        <Text format={TypographyType.Small} style={styles.timeDivider}>
          :
        </Text>
        <Text format={TypographyType.Small}>{leftPad(props.value.minutes)}</Text>
      </View>
      <View style={{ justifyContent: "center" }}>
        <Text format={TypographyType.Small}>(Time)</Text>
      </View>
    </View>
  );
};

export default MHATimePicker;

const styles = StyleSheet.create({
  readonlyWrapper: {
    flexDirection: "row",
    flex: 1,
  },
  timeDivider: { color: palette.greyBlue, marginHorizontal: spacing[10] },
});
