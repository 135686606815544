import React, { useMemo } from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import { Modal as RNPModal } from "react-native-paper";
import { useSetRecoilState, useRecoilValue } from "recoil";

import { globalDialogData } from "@/utils/recoil";
import { DialogData } from "@/utils/recoil/props";
import { TypographyType } from "@/models/Typography";
import { useDeviceHeight, useIsWebView, useOnAppBlur } from "@/hooks";
import { palette, spacing } from "@/theme";

import Icon, { IconName } from "@/components/Icon";
import ItemSpacer from "@/components/ItemSpacer";
import Text from "@/components/Text";

import { Mod } from "./modules";

export default function Dialog() {
  const dialogData = useRecoilValue(globalDialogData);
  return dialogData ? <DialogWindow data={dialogData} /> : <React.Fragment />;
}

function DialogWindow({ data }: { data: DialogData }) {
  const setDialogData = useSetRecoilState(globalDialogData);

  const isWebView = useIsWebView();
  const deviceHeight = useDeviceHeight();

  const dialogStyles = useMemo(() => [styles.dialog, isWebView && styles.dialogWeb, { maxHeight: deviceHeight - 20 }], [
    deviceHeight,
    isWebView,
  ]);

  const TitleFormat = isWebView ? TypographyType.HeadingSmall : TypographyType.RegularBold;
  const CopyFormat = isWebView ? TypographyType.Regular : TypographyType.Small;

  const buttonConfirmWrapperStyles = data.dismissable ? styles.buttonConfirmWrapperPadded : styles.buttonConfirmWrapper;

  const Copy = Array.isArray(data.message) ? (
    data.message.map((string, index) => (
      <Text key={index} format={CopyFormat}>
        {string}
      </Text>
    ))
  ) : (
    <Text format={CopyFormat}>{data.message}</Text>
  );

  const dismissDialog = () => {
    data.dismissable && data.onDismiss();
    setDialogData(null);
  };

  if (data.dismissable) {
    useOnAppBlur(data.onDismiss);
  }

  return (
    <RNPModal
      visible={!!data}
      dismissable={data.dismissable || false}
      onDismiss={dismissDialog}
      contentContainerStyle={styles.contentContainerStyle}
    >
      <View style={dialogStyles}>
        <View style={styles.header}>
          <Text format={TitleFormat} style={styles.heading}>
            {data.heading}
          </Text>
          {data.dismissable && (
            <TouchableOpacity onPress={dismissDialog}>
              <Icon name={IconName.close} size={22} color={palette.blue} />
            </TouchableOpacity>
          )}
        </View>

        <View style={styles.content}>
          <ItemSpacer gap={15}>{Copy}</ItemSpacer>
        </View>

        <View style={styles.buttons}>
          {data.dismissable && (
            <View style={styles.buttonCancelWrapper}>
              <Mod.DialogButton mode="cancel" label={data.cancelButtonLabel} onPress={dismissDialog} />
            </View>
          )}
          <View style={buttonConfirmWrapperStyles}>
            <Mod.DialogButton
              mode="confirm"
              label={data.confirmButton.label}
              onPress={data.confirmButton.onPress}
              disabled={data.confirmButton.disabled}
              isDanger={data.confirmButton.isDanger}
            />
          </View>
        </View>
      </View>
    </RNPModal>
  );
}

const styles = StyleSheet.create({
  buttons: {
    flexDirection: "row",
    justifyContent: "space-between",
  },

  buttonCancelWrapper: {
    flex: 1,
    paddingRight: spacing[5],
  },
  buttonConfirmWrapper: {
    flex: 1,
  },
  buttonConfirmWrapperPadded: {
    flex: 1,
    paddingLeft: spacing[5],
  },

  content: {
    paddingVertical: spacing[30],
  },

  dialog: {
    borderRadius: 20,
    padding: spacing[30],
    backgroundColor: palette.white,
  },
  dialogWeb: {
    padding: spacing[40],
  },

  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },

  heading: {
    margin: 0,
  },

  // RNP Modal style props
  contentContainerStyle: {
    width: "100%",
    maxWidth: 520,
    alignSelf: "center",
    paddingHorizontal: spacing[10],
  },
});
