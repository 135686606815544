import { createRef } from "react";
import {
  NavigationContainerRef,
  ParamListBase,
  StackActions,
  NavigationAction,
  NavigationState,
} from "@react-navigation/native";

/**
 * A stored reference to the Navigation Container.
 * This should be added in components/Root/Root to the <NavigationContainer>
 * component.
 *
 * @link https://reactnavigation.org/docs/navigation-container/
 */
export const navigationRef = createRef<NavigationContainerRef>();

type NavigatorParams<ParamList> =
  | {
      [K in keyof ParamList]: ParamList[K];
    }[keyof ParamList]
  | undefined;

type DispatchAction<State> = NavigationAction | ((state: State) => NavigationAction);

/**
 * Dispatch a CommonAction.
 *
 * @param action
 * @link https://reactnavigation.org/docs/navigation-actions/
 */
export function dispatch<State extends NavigationState = NavigationState>(action: DispatchAction<State>): void {
  if (navigationRef.current) {
    navigationRef.current.dispatch(action);
  }
}

/**
 * Navigate to the named route.
 * Will only work if navigationRef has been set correctly.
 *
 * @param {string} name Name of route to navigate to.
 * @param {ParamList | undefined} params Any params to pass to the named route.
 */
export function navigate<Routes extends ParamListBase>(name: keyof Routes, params?: NavigatorParams<Routes>) {
  if (navigationRef.current) {
    navigationRef.current.navigate(name as string, params);
  }
}

/**
 * Replace the existing route with the new named route.
 * Will only work if navigationRef has been set correctly.
 *
 * @param {string} name Name of route to navigate to.
 * @param {ParamList | undefined} params Any params to pass to the named route.
 */
export function replace<Routes extends ParamListBase>(name: keyof Routes, params?: NavigatorParams<Routes>) {
  if (navigationRef.current) {
    navigationRef.current.dispatch(StackActions.replace(name as string, params));
  }
}
