import React, { useMemo } from "react";
import dayjs from "dayjs";

import DoctorProfileView from "@/components/DoctorProfile";
import { RouteKeys } from "@/navigationv2";
import {
  DoctorVisitsRoutes,
  DoctorTabRoutes,
  ScreenNavigationProps,
  ScreenOptions,
  AllAMHPRoutes,
} from "@/navigationv2/types";
import { createScreenTitle } from "@/navigationv2/utilities";
import { DoctorLoadResponse } from "@/components/DoctorProfile/DoctorProfile.gql";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";

interface PropTypes extends ScreenNavigationProps<DoctorTabRoutes, DoctorVisitsRoutes, RouteKeys.ProfileScreen> {}

const ProfileScreen = (props: PropTypes) => {
  const navigation = useNavigation<StackNavigationProp<AllAMHPRoutes>>();
  const { route } = props;

  const {
    id,
    assessmentId,
    assessmentLocationLat,
    assessmentLocationLon,
    assessmentDistance,
    assessmentStartTime,
    assessmentEndTime,
    addVisit,
  } = route.params;

  const timeSpan = useMemo(
    () =>
      assessmentStartTime && assessmentEndTime
        ? {
            start: dayjs(assessmentStartTime),
            end: dayjs(assessmentEndTime),
          }
        : undefined,
    [assessmentStartTime, assessmentEndTime]
  );

  const location = useMemo(
    () =>
      assessmentLocationLat && assessmentLocationLon
        ? {
            lat: assessmentLocationLat,
            lon: assessmentLocationLon,
          }
        : undefined,
    [assessmentLocationLat, assessmentLocationLon]
  );

  const onDoctorLoad = (data: DoctorLoadResponse) => {
    navigation.setOptions({ title: createScreenTitle(`${data.doctor.name} Profile`) });
  };

  return (
    <DoctorProfileView
      id={id}
      assessmentId={assessmentId}
      assessmentTimeSpan={timeSpan}
      assessmentLocation={location}
      assessmentDistance={assessmentDistance}
      addVisit={addVisit}
      onDoctorLoad={onDoctorLoad}
    />
  );
};

const screenOptions: ScreenOptions = {
  title: createScreenTitle("Doctor Profile"),
};

ProfileScreen.screenOptions = screenOptions;

export default ProfileScreen;
