import { useState, useEffect } from "react";
import API from "@/api";

type useAPIVersionResult = {
  loading: boolean;
  v2: boolean;
};

/**
 * useAPIVersion
 * @param {string} key the key of the feature flag for the new api you are checking
 * @returns {boolean} true or false based on if the feature is in use or not
 */
const useAPIVersion = (key: string): useAPIVersionResult => {
  const [loading, setLoading] = useState(false);
  const [v2, setV2] = useState<boolean>(false);

  useEffect(() => {
    async function getFeatures() {
      try {
        setLoading(true);
        const result = await API.get("/checkFeatureToggle", {
          params: {
            feature: key,
          },
        });

        setV2(result.data.v2);
      } catch (error) {
        setV2(false);
      } finally {
        setLoading(false);
      }
    }
    if (key) {
      getFeatures();
    }
  }, [key]);

  return {
    loading,
    v2,
  };
};

export default useAPIVersion;
