import * as React from "react";
import { StyleProp, ViewStyle, TextStyle } from "react-native";
import MaterialIcon from "react-native-vector-icons/MaterialIcons";
import { color } from "../../theme";

function Icon(props: {
  name: string | IconName;
  size?: number;
  color?: string;
  onPress?: () => void;
  style?: StyleProp<ViewStyle | TextStyle>;
}) {
  return (
    <MaterialIcon
      name={props.name}
      size={props.size || 24}
      color={props.color || color.icon}
      style={props.style}
      onPress={props.onPress}
    />
  );
}

export default React.memo(Icon);

export enum IconName {
  three_d_rotation = "3d-rotation",
  ac_unit = "ac-unit",
  access_alarm = "access-alarm",
  access_alarms = "access-alarms",
  access_time = "access-time",
  accessibility = "accessibility",
  accessible = "accessible",
  account_balance = "account-balance",
  account_balance_wallet = "account-balance-wallet",
  account_box = "account-box",
  account_circle = "account-circle",
  adb = "adb",
  add = "add",
  add_a_photo = "add-a-photo",
  add_alarm = "add-alarm",
  add_alert = "add-alert",
  add_box = "add-box",
  add_circle = "add-circle",
  add_circle_outline = "add-circle-outline",
  add_location = "add-location",
  add_shopping_cart = "add-shopping-cart",
  add_to_photos = "add-to-photos",
  add_to_queue = "add-to-queue",
  adjust = "adjust",
  airline_seat_flat = "airline-seat-flat",
  airline_seat_flat_angled = "airline-seat-flat-angled",
  airline_seat_individual_suite = "airline-seat-individual-suite",
  airline_seat_legroom_extra = "airline-seat-legroom-extra",
  airline_seat_legroom_normal = "airline-seat-legroom-normal",
  airline_seat_legroom_reduced = "airline-seat-legroom-reduced",
  airline_seat_recline_extra = "airline-seat-recline-extra",
  airline_seat_recline_normal = "airline-seat-recline-normal",
  airplanemode_active = "airplanemode-active",
  airplanemode_inactive = "airplanemode-inactive",
  airplay = "airplay",
  airport_shuttle = "airport-shuttle",
  alarm = "alarm",
  alarm_add = "alarm-add",
  alarm_off = "alarm-off",
  alarm_on = "alarm-on",
  album = "album",
  all_inclusive = "all-inclusive",
  all_out = "all-out",
  android = "android",
  announcement = "announcement",
  apps = "apps",
  archive = "archive",
  arrow_back = "arrow-back",
  arrow_downward = "arrow-downward",
  arrow_drop_down = "arrow-drop-down",
  arrow_drop_down_circle = "arrow-drop-down-circle",
  arrow_drop_up = "arrow-drop-up",
  arrow_forward = "arrow-forward",
  arrow_upward = "arrow-upward",
  art_track = "art-track",
  aspect_ratio = "aspect-ratio",
  assessment = "assessment",
  assignment = "assignment",
  assignment_ind = "assignment-ind",
  assignment_late = "assignment-late",
  assignment_return = "assignment-return",
  assignment_returned = "assignment-returned",
  assignment_turned_in = "assignment-turned-in",
  assistant = "assistant",
  assistant_photo = "assistant-photo",
  attach_file = "attach-file",
  attach_money = "attach-money",
  attachment = "attachment",
  audiotrack = "audiotrack",
  autorenew = "autorenew",
  av_timer = "av-timer",
  backspace = "backspace",
  backup = "backup",
  battery_alert = "battery-alert",
  battery_charging_full = "battery-charging-full",
  battery_full = "battery-full",
  battery_std = "battery-std",
  battery_unknown = "battery-unknown",
  beach_access = "beach-access",
  beenhere = "beenhere",
  block = "block",
  bluetooth = "bluetooth",
  bluetooth_audio = "bluetooth-audio",
  bluetooth_connected = "bluetooth-connected",
  bluetooth_disabled = "bluetooth-disabled",
  bluetooth_searching = "bluetooth-searching",
  blur_circular = "blur-circular",
  blur_linear = "blur-linear",
  blur_off = "blur-off",
  blur_on = "blur-on",
  book = "book",
  bookmark = "bookmark",
  bookmark_border = "bookmark-border",
  border_all = "border-all",
  border_bottom = "border-bottom",
  border_clear = "border-clear",
  border_color = "border-color",
  border_horizontal = "border-horizontal",
  border_inner = "border-inner",
  border_left = "border-left",
  border_outer = "border-outer",
  border_right = "border-right",
  border_style = "border-style",
  border_top = "border-top",
  border_vertical = "border-vertical",
  branding_watermark = "branding-watermark",
  brightness_1 = "brightness-1",
  brightness_2 = "brightness-2",
  brightness_3 = "brightness-3",
  brightness_4 = "brightness-4",
  brightness_5 = "brightness-5",
  brightness_6 = "brightness-6",
  brightness_7 = "brightness-7",
  brightness_auto = "brightness-auto",
  brightness_high = "brightness-high",
  brightness_low = "brightness-low",
  brightness_medium = "brightness-medium",
  broken_image = "broken-image",
  brush = "brush",
  bubble_chart = "bubble-chart",
  bug_report = "bug-report",
  build = "build",
  burst_mode = "burst-mode",
  business = "business",
  business_center = "business-center",
  cached = "cached",
  cake = "cake",
  call = "call",
  call_end = "call-end",
  call_made = "call-made",
  call_merge = "call-merge",
  call_missed = "call-missed",
  call_missed_outgoing = "call-missed-outgoing",
  call_received = "call-received",
  call_split = "call-split",
  call_to_action = "call-to-action",
  camera = "camera",
  camera_alt = "camera-alt",
  camera_enhance = "camera-enhance",
  camera_front = "camera-front",
  camera_rear = "camera-rear",
  camera_roll = "camera-roll",
  cancel = "cancel",
  card_giftcard = "card-giftcard",
  card_membership = "card-membership",
  card_travel = "card-travel",
  casino = "casino",
  cast = "cast",
  cast_connected = "cast-connected",
  center_focus_strong = "center-focus-strong",
  center_focus_weak = "center-focus-weak",
  change_history = "change-history",
  chat = "chat",
  chat_bubble = "chat-bubble",
  chat_bubble_outline = "chat-bubble-outline",
  check = "check",
  check_box = "check-box",
  check_box_outline_blank = "check-box-outline-blank",
  check_circle = "check-circle",
  chevron_left = "chevron-left",
  chevron_right = "chevron-right",
  child_care = "child-care",
  child_friendly = "child-friendly",
  chrome_reader_mode = "chrome-reader-mode",
  class = "class",
  clear = "clear",
  clear_all = "clear-all",
  close = "close",
  closed_caption = "closed-caption",
  cloud = "cloud",
  cloud_circle = "cloud-circle",
  cloud_done = "cloud-done",
  cloud_download = "cloud-download",
  cloud_off = "cloud-off",
  cloud_queue = "cloud-queue",
  cloud_upload = "cloud-upload",
  code = "code",
  collections = "collections",
  collections_bookmark = "collections-bookmark",
  color_lens = "color-lens",
  colorize = "colorize",
  comment = "comment",
  compare = "compare",
  compare_arrows = "compare-arrows",
  computer = "computer",
  confirmation_number = "confirmation-number",
  contact_mail = "contact-mail",
  contact_phone = "contact-phone",
  contacts = "contacts",
  content_copy = "content-copy",
  content_cut = "content-cut",
  content_paste = "content-paste",
  control_point = "control-point",
  control_point_duplicate = "control-point-duplicate",
  copyright = "copyright",
  create = "create",
  create_new_folder = "create-new-folder",
  credit_card = "credit-card",
  crop = "crop",
  crop_16_9 = "crop-16-9",
  crop_3_2 = "crop-3-2",
  crop_5_4 = "crop-5-4",
  crop_7_5 = "crop-7-5",
  crop_din = "crop-din",
  crop_free = "crop-free",
  crop_landscape = "crop-landscape",
  crop_original = "crop-original",
  crop_portrait = "crop-portrait",
  crop_rotate = "crop-rotate",
  crop_square = "crop-square",
  dashboard = "dashboard",
  data_usage = "data-usage",
  date_range = "date-range",
  dehaze = "dehaze",
  delete = "delete",
  delete_forever = "delete-forever",
  delete_sweep = "delete-sweep",
  description = "description",
  desktop_mac = "desktop-mac",
  desktop_windows = "desktop-windows",
  details = "details",
  developer_board = "developer-board",
  developer_mode = "developer-mode",
  device_hub = "device-hub",
  devices = "devices",
  devices_other = "devices-other",
  dialer_sip = "dialer-sip",
  dialpad = "dialpad",
  directions = "directions",
  directions_bike = "directions-bike",
  directions_boat = "directions-boat",
  directions_bus = "directions-bus",
  directions_car = "directions-car",
  directions_railway = "directions-railway",
  directions_run = "directions-run",
  directions_subway = "directions-subway",
  directions_transit = "directions-transit",
  directions_walk = "directions-walk",
  disc_full = "disc-full",
  dns = "dns",
  do_not_disturb = "do-not-disturb",
  do_not_disturb_alt = "do-not-disturb-alt",
  do_not_disturb_off = "do-not-disturb-off",
  do_not_disturb_on = "do-not-disturb-on",
  dock = "dock",
  domain = "domain",
  done = "done",
  done_all = "done-all",
  donut_large = "donut-large",
  donut_small = "donut-small",
  drafts = "drafts",
  drag_handle = "drag-handle",
  drive_eta = "drive-eta",
  dvr = "dvr",
  edit = "edit",
  edit_location = "edit-location",
  eject = "eject",
  email = "email",
  enhanced_encryption = "enhanced-encryption",
  equalizer = "equalizer",
  error = "error",
  error_outline = "error-outline",
  euro_symbol = "euro-symbol",
  ev_station = "ev-station",
  event = "event",
  event_available = "event-available",
  event_busy = "event-busy",
  event_note = "event-note",
  event_seat = "event-seat",
  exit_to_app = "exit-to-app",
  expand_less = "expand-less",
  expand_more = "expand-more",
  explicit = "explicit",
  explore = "explore",
  exposure = "exposure",
  exposure_neg_1 = "exposure-neg-1",
  exposure_neg_2 = "exposure-neg-2",
  exposure_plus_1 = "exposure-plus-1",
  exposure_plus_2 = "exposure-plus-2",
  exposure_zero = "exposure-zero",
  extension = "extension",
  face = "face",
  fast_forward = "fast-forward",
  fast_rewind = "fast-rewind",
  favorite = "favorite",
  favorite_border = "favorite-border",
  featured_play_list = "featured-play-list",
  featured_video = "featured-video",
  feedback = "feedback",
  fiber_dvr = "fiber-dvr",
  fiber_manual_record = "fiber-manual-record",
  fiber_new = "fiber-new",
  fiber_pin = "fiber-pin",
  fiber_smart_record = "fiber-smart-record",
  file_download = "file-download",
  file_upload = "file-upload",
  filter = "filter",
  filter_1 = "filter-1",
  filter_2 = "filter-2",
  filter_3 = "filter-3",
  filter_4 = "filter-4",
  filter_5 = "filter-5",
  filter_6 = "filter-6",
  filter_7 = "filter-7",
  filter_8 = "filter-8",
  filter_9 = "filter-9",
  filter_9_plus = "filter-9-plus",
  filter_b_and_w = "filter-b-and-w",
  filter_center_focus = "filter-center-focus",
  filter_drama = "filter-drama",
  filter_frames = "filter-frames",
  filter_hdr = "filter-hdr",
  filter_list = "filter-list",
  filter_none = "filter-none",
  filter_tilt_shift = "filter-tilt-shift",
  filter_vintage = "filter-vintage",
  find_in_page = "find-in-page",
  find_replace = "find-replace",
  fingerprint = "fingerprint",
  first_page = "first-page",
  fitness_center = "fitness-center",
  flag = "flag",
  flare = "flare",
  flash_auto = "flash-auto",
  flash_off = "flash-off",
  flash_on = "flash-on",
  flight = "flight",
  flight_land = "flight-land",
  flight_takeoff = "flight-takeoff",
  flip = "flip",
  flip_to_back = "flip-to-back",
  flip_to_front = "flip-to-front",
  folder = "folder",
  folder_open = "folder-open",
  folder_shared = "folder-shared",
  folder_special = "folder-special",
  font_download = "font-download",
  format_align_center = "format-align-center",
  format_align_justify = "format-align-justify",
  format_align_left = "format-align-left",
  format_align_right = "format-align-right",
  format_bold = "format-bold",
  format_clear = "format-clear",
  format_color_fill = "format-color-fill",
  format_color_reset = "format-color-reset",
  format_color_text = "format-color-text",
  format_indent_decrease = "format-indent-decrease",
  format_indent_increase = "format-indent-increase",
  format_italic = "format-italic",
  format_line_spacing = "format-line-spacing",
  format_list_bulleted = "format-list-bulleted",
  format_list_numbered = "format-list-numbered",
  format_paint = "format-paint",
  format_quote = "format-quote",
  format_shapes = "format-shapes",
  format_size = "format-size",
  format_strikethrough = "format-strikethrough",
  format_textdirection_l_to_r = "format-textdirection-l-to-r",
  format_textdirection_r_to_l = "format-textdirection-r-to-l",
  format_underlined = "format-underlined",
  forum = "forum",
  forward = "forward",
  forward_10 = "forward-10",
  forward_30 = "forward-30",
  forward_5 = "forward-5",
  free_breakfast = "free-breakfast",
  fullscreen = "fullscreen",
  fullscreen_exit = "fullscreen-exit",
  functions = "functions",
  g_translate = "g-translate",
  gamepad = "gamepad",
  games = "games",
  gavel = "gavel",
  gesture = "gesture",
  get_app = "get-app",
  gif = "gif",
  golf_course = "golf-course",
  gps_fixed = "gps-fixed",
  gps_not_fixed = "gps-not-fixed",
  gps_off = "gps-off",
  grade = "grade",
  gradient = "gradient",
  grain = "grain",
  graphic_eq = "graphic-eq",
  grid_off = "grid-off",
  grid_on = "grid-on",
  group = "group",
  group_add = "group-add",
  group_work = "group-work",
  hd = "hd",
  hdr_off = "hdr-off",
  hdr_on = "hdr-on",
  hdr_strong = "hdr-strong",
  hdr_weak = "hdr-weak",
  headset = "headset",
  headset_mic = "headset-mic",
  healing = "healing",
  hearing = "hearing",
  help = "help",
  help_outline = "help-outline",
  high_quality = "high-quality",
  highlight = "highlight",
  highlight_off = "highlight-off",
  history = "history",
  home = "home",
  hot_tub = "hot-tub",
  hotel = "hotel",
  hourglass_empty = "hourglass-empty",
  hourglass_full = "hourglass-full",
  http = "http",
  https = "https",
  image = "image",
  image_aspect_ratio = "image-aspect-ratio",
  import_contacts = "import-contacts",
  import_export = "import-export",
  important_devices = "important-devices",
  inbox = "inbox",
  indeterminate_check_box = "indeterminate-check-box",
  info = "info",
  info_outline = "info-outline",
  input = "input",
  insert_chart = "insert-chart",
  insert_comment = "insert-comment",
  insert_drive_file = "insert-drive-file",
  insert_emoticon = "insert-emoticon",
  insert_invitation = "insert-invitation",
  insert_link = "insert-link",
  insert_photo = "insert-photo",
  invert_colors = "invert-colors",
  invert_colors_off = "invert-colors-off",
  iso = "iso",
  keyboard = "keyboard",
  keyboard_arrow_down = "keyboard-arrow-down",
  keyboard_arrow_left = "keyboard-arrow-left",
  keyboard_arrow_right = "keyboard-arrow-right",
  keyboard_arrow_up = "keyboard-arrow-up",
  keyboard_backspace = "keyboard-backspace",
  keyboard_capslock = "keyboard-capslock",
  keyboard_hide = "keyboard-hide",
  keyboard_return = "keyboard-return",
  keyboard_tab = "keyboard-tab",
  keyboard_voice = "keyboard-voice",
  kitchen = "kitchen",
  label = "label",
  label_outline = "label-outline",
  landscape = "landscape",
  language = "language",
  laptop = "laptop",
  laptop_chromebook = "laptop-chromebook",
  laptop_mac = "laptop-mac",
  laptop_windows = "laptop-windows",
  last_page = "last-page",
  launch = "launch",
  layers = "layers",
  layers_clear = "layers-clear",
  leak_add = "leak-add",
  leak_remove = "leak-remove",
  lens = "lens",
  library_add = "library-add",
  library_books = "library-books",
  library_music = "library-music",
  lightbulb_outline = "lightbulb-outline",
  line_style = "line-style",
  line_weight = "line-weight",
  linear_scale = "linear-scale",
  link = "link",
  linked_camera = "linked-camera",
  list = "list",
  live_help = "live-help",
  live_tv = "live-tv",
  local_activity = "local-activity",
  local_airport = "local-airport",
  local_atm = "local-atm",
  local_bar = "local-bar",
  local_cafe = "local-cafe",
  local_car_wash = "local-car-wash",
  local_convenience_store = "local-convenience-store",
  local_dining = "local-dining",
  local_drink = "local-drink",
  local_florist = "local-florist",
  local_gas_station = "local-gas-station",
  local_grocery_store = "local-grocery-store",
  local_hospital = "local-hospital",
  local_hotel = "local-hotel",
  local_laundry_service = "local-laundry-service",
  local_library = "local-library",
  local_mall = "local-mall",
  local_movies = "local-movies",
  local_offer = "local-offer",
  local_parking = "local-parking",
  local_pharmacy = "local-pharmacy",
  local_phone = "local-phone",
  local_pizza = "local-pizza",
  local_play = "local-play",
  local_post_office = "local-post-office",
  local_printshop = "local-printshop",
  local_see = "local-see",
  local_shipping = "local-shipping",
  local_taxi = "local-taxi",
  location_city = "location-city",
  location_disabled = "location-disabled",
  location_off = "location-off",
  location_on = "location-on",
  location_searching = "location-searching",
  lock = "lock",
  lock_open = "lock-open",
  lock_outline = "lock-outline",
  looks = "looks",
  looks_3 = "looks-3",
  looks_4 = "looks-4",
  looks_5 = "looks-5",
  looks_6 = "looks-6",
  looks_one = "looks-one",
  looks_two = "looks-two",
  loop = "loop",
  loupe = "loupe",
  low_priority = "low-priority",
  loyalty = "loyalty",
  mail = "mail",
  mail_outline = "mail-outline",
  map = "map",
  markunread = "markunread",
  markunread_mailbox = "markunread-mailbox",
  memory = "memory",
  menu = "menu",
  merge_type = "merge-type",
  message = "message",
  mic = "mic",
  mic_none = "mic-none",
  mic_off = "mic-off",
  mms = "mms",
  mode_comment = "mode-comment",
  mode_edit = "mode-edit",
  monetization_on = "monetization-on",
  money_off = "money-off",
  monochrome_photos = "monochrome-photos",
  mood = "mood",
  mood_bad = "mood-bad",
  more = "more",
  more_horiz = "more-horiz",
  more_vert = "more-vert",
  motorcycle = "motorcycle",
  mouse = "mouse",
  move_to_inbox = "move-to-inbox",
  movie = "movie",
  movie_creation = "movie-creation",
  movie_filter = "movie-filter",
  multiline_chart = "multiline-chart",
  music_note = "music-note",
  music_video = "music-video",
  my_location = "my-location",
  nature = "nature",
  nature_people = "nature-people",
  navigate_before = "navigate-before",
  navigate_next = "navigate-next",
  navigation = "navigation",
  near_me = "near-me",
  network_cell = "network-cell",
  network_check = "network-check",
  network_locked = "network-locked",
  network_wifi = "network-wifi",
  new_releases = "new-releases",
  next_week = "next-week",
  nfc = "nfc",
  no_encryption = "no-encryption",
  no_sim = "no-sim",
  not_interested = "not-interested",
  note = "note",
  note_add = "note-add",
  notifications = "notifications",
  notifications_active = "notifications-active",
  notifications_none = "notifications-none",
  notifications_off = "notifications-off",
  notifications_paused = "notifications-paused",
  offline_pin = "offline-pin",
  ondemand_video = "ondemand-video",
  opacity = "opacity",
  open_in_browser = "open-in-browser",
  open_in_new = "open-in-new",
  open_with = "open-with",
  pages = "pages",
  pageview = "pageview",
  palette = "palette",
  pan_tool = "pan-tool",
  panorama = "panorama",
  panorama_fish_eye = "panorama-fish-eye",
  panorama_horizontal = "panorama-horizontal",
  panorama_vertical = "panorama-vertical",
  panorama_wide_angle = "panorama-wide-angle",
  party_mode = "party-mode",
  pause = "pause",
  pause_circle_filled = "pause-circle-filled",
  pause_circle_outline = "pause-circle-outline",
  payment = "payment",
  people = "people",
  people_outline = "people-outline",
  perm_camera_mic = "perm-camera-mic",
  perm_contact_calendar = "perm-contact-calendar",
  perm_data_setting = "perm-data-setting",
  perm_device_information = "perm-device-information",
  perm_identity = "perm-identity",
  perm_media = "perm-media",
  perm_phone_msg = "perm-phone-msg",
  perm_scan_wifi = "perm-scan-wifi",
  person = "person",
  person_add = "person-add",
  person_outline = "person-outline",
  person_pin = "person-pin",
  person_pin_circle = "person-pin-circle",
  personal_video = "personal-video",
  pets = "pets",
  phone = "phone",
  phone_android = "phone-android",
  phone_bluetooth_speaker = "phone-bluetooth-speaker",
  phone_forwarded = "phone-forwarded",
  phone_in_talk = "phone-in-talk",
  phone_iphone = "phone-iphone",
  phone_locked = "phone-locked",
  phone_missed = "phone-missed",
  phone_paused = "phone-paused",
  phonelink = "phonelink",
  phonelink_erase = "phonelink-erase",
  phonelink_lock = "phonelink-lock",
  phonelink_off = "phonelink-off",
  phonelink_ring = "phonelink-ring",
  phonelink_setup = "phonelink-setup",
  photo = "photo",
  photo_album = "photo-album",
  photo_camera = "photo-camera",
  photo_filter = "photo-filter",
  photo_library = "photo-library",
  photo_size_select_actual = "photo-size-select-actual",
  photo_size_select_large = "photo-size-select-large",
  photo_size_select_small = "photo-size-select-small",
  picture_as_pdf = "picture-as-pdf",
  picture_in_picture = "picture-in-picture",
  picture_in_picture_alt = "picture-in-picture-alt",
  pie_chart = "pie-chart",
  pie_chart_outlined = "pie-chart-outlined",
  pin_drop = "pin-drop",
  place = "place",
  play_arrow = "play-arrow",
  play_circle_filled = "play-circle-filled",
  play_circle_outline = "play-circle-outline",
  play_for_work = "play-for-work",
  playlist_add = "playlist-add",
  playlist_add_check = "playlist-add-check",
  playlist_play = "playlist-play",
  plus_one = "plus-one",
  poll = "poll",
  polymer = "polymer",
  pool = "pool",
  portable_wifi_off = "portable-wifi-off",
  portrait = "portrait",
  power = "power",
  power_input = "power-input",
  power_settings_new = "power-settings-new",
  pregnant_woman = "pregnant-woman",
  present_to_all = "present-to-all",
  print = "print",
  priority_high = "priority-high",
  public = "public",
  publish = "publish",
  query_builder = "query-builder",
  question_answer = "question-answer",
  queue = "queue",
  queue_music = "queue-music",
  queue_play_next = "queue-play-next",
  radio = "radio",
  radio_button_checked = "radio-button-checked",
  radio_button_unchecked = "radio-button-unchecked",
  rate_review = "rate-review",
  receipt = "receipt",
  recent_actors = "recent-actors",
  record_voice_over = "record-voice-over",
  redeem = "redeem",
  redo = "redo",
  refresh = "refresh",
  remove = "remove",
  remove_circle = "remove-circle",
  remove_circle_outline = "remove-circle-outline",
  remove_from_queue = "remove-from-queue",
  remove_red_eye = "remove-red-eye",
  remove_shopping_cart = "remove-shopping-cart",
  reorder = "reorder",
  repeat = "repeat",
  repeat_one = "repeat-one",
  replay = "replay",
  replay_10 = "replay-10",
  replay_30 = "replay-30",
  replay_5 = "replay-5",
  reply = "reply",
  reply_all = "reply-all",
  report = "report",
  report_problem = "report-problem",
  restaurant = "restaurant",
  restaurant_menu = "restaurant-menu",
  restore = "restore",
  restore_page = "restore-page",
  ring_volume = "ring-volume",
  room = "room",
  room_service = "room-service",
  rotate_90_degrees_ccw = "rotate-90-degrees-ccw",
  rotate_left = "rotate-left",
  rotate_right = "rotate-right",
  rounded_corner = "rounded-corner",
  router = "router",
  rowing = "rowing",
  rss_feed = "rss-feed",
  rv_hookup = "rv-hookup",
  satellite = "satellite",
  save = "save",
  scanner = "scanner",
  schedule = "schedule",
  school = "school",
  screen_lock_landscape = "screen-lock-landscape",
  screen_lock_portrait = "screen-lock-portrait",
  screen_lock_rotation = "screen-lock-rotation",
  screen_rotation = "screen-rotation",
  screen_share = "screen-share",
  sd_card = "sd-card",
  sd_storage = "sd-storage",
  search = "search",
  security = "security",
  select_all = "select-all",
  send = "send",
  sentiment_dissatisfied = "sentiment-dissatisfied",
  sentiment_neutral = "sentiment-neutral",
  sentiment_satisfied = "sentiment-satisfied",
  sentiment_very_dissatisfied = "sentiment-very-dissatisfied",
  sentiment_very_satisfied = "sentiment-very-satisfied",
  settings = "settings",
  settings_applications = "settings-applications",
  settings_backup_restore = "settings-backup-restore",
  settings_bluetooth = "settings-bluetooth",
  settings_brightness = "settings-brightness",
  settings_cell = "settings-cell",
  settings_ethernet = "settings-ethernet",
  settings_input_antenna = "settings-input-antenna",
  settings_input_component = "settings-input-component",
  settings_input_composite = "settings-input-composite",
  settings_input_hdmi = "settings-input-hdmi",
  settings_input_svideo = "settings-input-svideo",
  settings_overscan = "settings-overscan",
  settings_phone = "settings-phone",
  settings_power = "settings-power",
  settings_remote = "settings-remote",
  settings_system_daydream = "settings-system-daydream",
  settings_voice = "settings-voice",
  share = "share",
  shop = "shop",
  shop_two = "shop-two",
  shopping_basket = "shopping-basket",
  shopping_cart = "shopping-cart",
  short_text = "short-text",
  show_chart = "show-chart",
  shuffle = "shuffle",
  signal_cellular_4_bar = "signal-cellular-4-bar",
  signal_cellular_connected_no_internet_4_bar = "signal-cellular-connected-no-internet-4-bar",
  signal_cellular_no_sim = "signal-cellular-no-sim",
  signal_cellular_null = "signal-cellular-null",
  signal_cellular_off = "signal-cellular-off",
  signal_wifi_4_bar = "signal-wifi-4-bar",
  signal_wifi_4_bar_lock = "signal-wifi-4-bar-lock",
  signal_wifi_off = "signal-wifi-off",
  sim_card = "sim-card",
  sim_card_alert = "sim-card-alert",
  skip_next = "skip-next",
  skip_previous = "skip-previous",
  slideshow = "slideshow",
  slow_motion_video = "slow-motion-video",
  smartphone = "smartphone",
  smoke_free = "smoke-free",
  smoking_rooms = "smoking-rooms",
  sms = "sms",
  sms_failed = "sms-failed",
  snooze = "snooze",
  sort = "sort",
  sort_by_alpha = "sort-by-alpha",
  spa = "spa",
  space_bar = "space-bar",
  speaker = "speaker",
  speaker_group = "speaker-group",
  speaker_notes = "speaker-notes",
  speaker_notes_off = "speaker-notes-off",
  speaker_phone = "speaker-phone",
  spellcheck = "spellcheck",
  star = "star",
  star_border = "star-border",
  star_half = "star-half",
  stars = "stars",
  stay_current_landscape = "stay-current-landscape",
  stay_current_portrait = "stay-current-portrait",
  stay_primary_landscape = "stay-primary-landscape",
  stay_primary_portrait = "stay-primary-portrait",
  stop = "stop",
  stop_screen_share = "stop-screen-share",
  storage = "storage",
  store = "store",
  store_mall_directory = "store-mall-directory",
  straighten = "straighten",
  streetview = "streetview",
  strikethrough_s = "strikethrough-s",
  style = "style",
  subdirectory_arrow_left = "subdirectory-arrow-left",
  subdirectory_arrow_right = "subdirectory-arrow-right",
  subject = "subject",
  subscriptions = "subscriptions",
  subtitles = "subtitles",
  subway = "subway",
  supervisor_account = "supervisor-account",
  surround_sound = "surround-sound",
  swap_calls = "swap-calls",
  swap_horiz = "swap-horiz",
  swap_vert = "swap-vert",
  swap_vertical_circle = "swap-vertical-circle",
  switch_camera = "switch-camera",
  switch_video = "switch-video",
  sync = "sync",
  sync_disabled = "sync-disabled",
  sync_problem = "sync-problem",
  system_update = "system-update",
  system_update_alt = "system-update-alt",
  tab = "tab",
  tab_unselected = "tab-unselected",
  tablet = "tablet",
  tablet_android = "tablet-android",
  tablet_mac = "tablet-mac",
  tag_faces = "tag-faces",
  tap_and_play = "tap-and-play",
  terrain = "terrain",
  text_fields = "text-fields",
  text_format = "text-format",
  textsms = "textsms",
  texture = "texture",
  theaters = "theaters",
  thumb_down = "thumb-down",
  thumb_up = "thumb-up",
  thumbs_up_down = "thumbs-up-down",
  time_to_leave = "time-to-leave",
  timelapse = "timelapse",
  timeline = "timeline",
  timer = "timer",
  timer_10 = "timer-10",
  timer_3 = "timer-3",
  timer_off = "timer-off",
  title = "title",
  toc = "toc",
  today = "today",
  toll = "toll",
  tonality = "tonality",
  touch_app = "touch-app",
  toys = "toys",
  track_changes = "track-changes",
  traffic = "traffic",
  train = "train",
  tram = "tram",
  transfer_within_a_station = "transfer-within-a-station",
  transform = "transform",
  translate = "translate",
  trending_down = "trending-down",
  trending_flat = "trending-flat",
  trending_up = "trending-up",
  tune = "tune",
  turned_in = "turned-in",
  turned_in_not = "turned-in-not",
  tv = "tv",
  unarchive = "unarchive",
  undo = "undo",
  unfold_less = "unfold-less",
  unfold_more = "unfold-more",
  update = "update",
  usb = "usb",
  verified_user = "verified-user",
  vertical_align_bottom = "vertical-align-bottom",
  vertical_align_center = "vertical-align-center",
  vertical_align_top = "vertical-align-top",
  vibration = "vibration",
  video_call = "video-call",
  video_label = "video-label",
  video_library = "video-library",
  videocam = "videocam",
  videocam_off = "videocam-off",
  videogame_asset = "videogame-asset",
  view_agenda = "view-agenda",
  view_array = "view-array",
  view_carousel = "view-carousel",
  view_column = "view-column",
  view_comfy = "view-comfy",
  view_compact = "view-compact",
  view_day = "view-day",
  view_headline = "view-headline",
  view_list = "view-list",
  view_module = "view-module",
  view_quilt = "view-quilt",
  view_stream = "view-stream",
  view_week = "view-week",
  vignette = "vignette",
  visibility = "visibility",
  visibility_off = "visibility-off",
  voice_chat = "voice-chat",
  voicemail = "voicemail",
  volume_down = "volume-down",
  volume_mute = "volume-mute",
  volume_off = "volume-off",
  volume_up = "volume-up",
  vpn_key = "vpn-key",
  vpn_lock = "vpn-lock",
  wallpaper = "wallpaper",
  warning = "warning",
  watch = "watch",
  watch_later = "watch-later",
  wb_auto = "wb-auto",
  wb_cloudy = "wb-cloudy",
  wb_incandescent = "wb-incandescent",
  wb_iridescent = "wb-iridescent",
  wb_sunny = "wb-sunny",
  wc = "wc",
  web = "web",
  web_asset = "web-asset",
  weekend = "weekend",
  whatshot = "whatshot",
  widgets = "widgets",
  wifi = "wifi",
  wifi_lock = "wifi-lock",
  wifi_tethering = "wifi-tethering",
  work = "work",
  wrap_text = "wrap-text",
  youtube_searched_for = "youtube-searched-for",
  zoom_in = "zoom-in",
  zoom_out = "zoom-out",
  zoom_out_map1 = "zoom-out-map",
}
