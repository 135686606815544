import React from "react";
import { View, Platform } from "react-native";
import { meetingStyles } from "../styles";
import { VideoActionButton } from "./VideoActionButton";
import { VideoInputToggle } from "./VideoInputToggle";
import { AudioInputToggle } from "./AudioInputToggle";
import { useDisplayExitMeeting, useDisplayAttendeesAndNotes } from "../helpers";
import { AssessmentDetails, ControlBarMeetingDetails, MediaDevice } from "../MeetingRoom.types";
import { IconName } from "@/components/Icon";

interface ControlBarProps {
  assessmentDetails: AssessmentDetails;
  meetingDetails: ControlBarMeetingDetails;
  attendeeCount: number;
  onExitPress?: () => void;
  videoActive: boolean;
  onVideoPress: () => void;
  audioLevel?: number;
  micActive: boolean;
  onMicPress: () => void;
  settingsActive?: boolean;
  onSettingsPress?: () => void;
  activeCamera?: MediaDevice | null;
  onSwitchPress?: () => void;
}

export const ControlBar: React.FC<ControlBarProps> = ({
  assessmentDetails,
  meetingDetails,
  attendeeCount,
  onExitPress,
  videoActive,
  onVideoPress,
  audioLevel,
  micActive,
  onMicPress,
  settingsActive,
  onSettingsPress,
  activeCamera,
  onSwitchPress,
}) => {
  const { showExitMeeting, toggleExitMeeting } = useDisplayExitMeeting(onExitPress);
  const { showAttendeesAndNotes, toggleAttendeesAndNotes } = useDisplayAttendeesAndNotes(
    assessmentDetails,
    meetingDetails
  );

  return (
    <View style={meetingStyles.controlBar}>
      <View style={meetingStyles.controlBarButtonsGroup}>
        <VideoActionButton
          theme="red"
          icon={IconName.exit_to_app}
          label="Leave Assessment"
          isActive={showExitMeeting}
          onPress={toggleExitMeeting}
        />
        {Platform.OS === "web" && onSettingsPress && (
          <VideoActionButton
            theme="yellow"
            icon={IconName.settings}
            label="Settings"
            isActive={settingsActive}
            onPress={onSettingsPress}
          />
        )}
        <VideoActionButton
          theme="yellow"
          icon={IconName.more_vert}
          label="Details"
          badgeCount={attendeeCount}
          isActive={showAttendeesAndNotes}
          onPress={toggleAttendeesAndNotes}
        />
      </View>

      <View style={meetingStyles.controlBarButtonsGroup}>
        <AudioInputToggle
          icon={micActive ? IconName.mic : IconName.mic_off}
          audioLevel={audioLevel}
          active={micActive}
          onPress={onMicPress}
        />
        <VideoInputToggle
          icon={videoActive ? IconName.videocam : IconName.videocam_off}
          active={videoActive}
          onPress={onVideoPress}
        />
        {Platform.OS !== "web" && onSwitchPress && (
          <VideoInputToggle
            icon={
              !activeCamera || activeCamera?.type === "videoFrontCamera" ? IconName.camera_front : IconName.camera_rear
            }
            active={!!activeCamera?.type}
            onPress={onSwitchPress}
          />
        )}
      </View>
    </View>
  );
};
