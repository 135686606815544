import { Platform } from "react-native";

import Config from "../config";

function get(url: string) {
  return fetch(url).then((response: any) => {
    if (response.status === 200) {
      return response;
    }

    throw response;
  });
}

function fetchAndroidVersion() {
  return get(Config.ANDROID_APPSTORE_LOOKUP_URL).then(async (response: any) => {
    const tp = await response.text();
    const regex = /Current Version<\/div>(<\/?\w+((\s+\w+(\s*=\s*(?:".*?"|'.*?'|[\^'">\s]+))?)+\s*|\s*)\/?>){3}(\d.\d.\d)/g;

    // Extract via regex so we can keep everything in JS land
    const match = regex.exec(tp);

    if (match && match.length === 6) {
      return match[5];
    }
    return "";
  });
}

function fetchIosVersion() {
  return get(Config.IOS_APPSTORE_LOOKUP_URL)
    .then((response: any) => {
      return new Promise((resolve, reject) => {
        response
          .json()
          .then((result: any) => {
            response.data = result;
            resolve(response);
          })
          .catch((err: any) => {
            response.data = err;
            reject(response);
          });
      });
    })
    .then((d: any) => {
      const [{ version }] = d.data.results;

      if (!version) {
        throw new Error("App not found!");
      }

      return version;
    });
}

function getMajorMinorUpdateVersion(version: string) {
  return Number(
    version
      .split(".")
      .slice(0, 2)
      .join("")
  );
}

/**
 * Get the current application version from the current platform.
 * Ignored for web-based devices.
 *
 * @param appUpdateDimissed Has the user previously dismissed an update notification modal.
 * @param version The current application version on the device.
 * @author Alex McCabe [Alex McCabe](https://github.com/alexmccabe)
 * @version 1.0.0
 */
export default async function fetchVersion(appUpdateDimissed: boolean, version: string) {
  try {
    if (Platform.OS === "web") {
      return false;
    }

    const appstoreVersion = Platform.select({
      ios: await fetchIosVersion(),
      android: await fetchAndroidVersion(),
    });

    const isValidVersionNumber = /\d.\d.\d/.test(version);

    if (!isValidVersionNumber) {
      return false;
    }

    if (version && !appUpdateDimissed) {
      const localAppVersion: number = getMajorMinorUpdateVersion(version);
      const remoteAppVersion: number = getMajorMinorUpdateVersion(appstoreVersion);

      if (remoteAppVersion > localAppVersion) {
        return true;
      }

      return false;
    }

    return false;
  } catch (error) {
    console.error(error);

    return false; // Return false because we couldn't check the version.json for some reason
  }
}
