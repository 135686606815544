import React from "react";
import { StyleSheet } from "react-native";

import { MHALocationInput } from "./MHALocationInput/MHALocationInput";
import { LocationProps } from "../../Location/Location";
import { InfoItemLabel } from "../../InfoItem";

import { TypographyType } from "@/models/Typography";
import Text from "../../Text";
import { spacing, palette } from "../../../theme";
import ENV from "../../../config";

const devLocations = [
  {
    id: "00001",
    itemText: "S12 Head Office",

    locationName: { address: "5 Kelso Place, Upper Bristol Road", city: "Bath", postcode: "BA1 3AU" },
    isComplete: true,
  },
];

// TODO: RW - Why do we have resetInput for the component, it never seems to be used
export type MHALocationProps = (LocationProps & {
  addressChangeWarning?: string;
  disableStrictPostcodeValidation?: boolean;
  resetInput?: () => void;
  externalDoctorCopy?: string;
}) &
  MHALocationReadonlyProps;

export interface MHALocationReadonlyProps {
  readonly?: boolean;
  locationValue: string;
  externalDoctorCopy?: string;
}

const MHALocation = (props: MHALocationProps) => {
  const {
    externalDoctorCopy,
    disableStrictPostcodeValidation = false,
    label = "",
    locationValue,
    readonly = false,
  } = props;
  const hasExternalDoctorCopy = Boolean(externalDoctorCopy);
  const hasLabel = Boolean(label);

  if (readonly) {
    return (
      <Text
        style={[styles.readonlyInput, !props.locationValue && styles.shaded]}
        format={TypographyType.RegularBold}
        color={props.externalDoctorCopy && !locationValue ? palette.red : palette.navy}
      >
        {locationValue || externalDoctorCopy || "N/A"}
      </Text>
    );
  }

  if (hasExternalDoctorCopy) {
    return (
      <InfoItemLabel label={hasLabel ? label : "Address"} format={TypographyType.RegularBold} color={palette.red}>
        {externalDoctorCopy}
      </InfoItemLabel>
    );
  }

  return (
    <MHALocationInput
      {...(props as LocationProps)}
      initialResults={ENV.ENV === "master" ? devLocations : undefined}
      disableStrictPostcodeValidation={disableStrictPostcodeValidation}
    />
  );
};

export default MHALocation;

const styles = StyleSheet.create({
  readonlyInput: {
    backgroundColor: palette.white,
    paddingHorizontal: spacing[20],
    paddingVertical: spacing[15],
  },
  shaded: {
    backgroundColor: palette.cloud,
  },
});
