/**
 * This component provides the grey background for a list of card components on mobile;
 * On web, the cards are contained within a ContentWrap
 */
import React, { useMemo } from "react";
import { StyleSheet, View } from "react-native";

import { mqWeb } from "../../../utils/helpers";
import { palette, spacing } from "../../../theme";

import { ContentWrap } from "../../../components/ContentWrap";

const CardContainer = (props: { children: React.ReactNode }) => {
  const isWebView = mqWeb();
  const { children } = props;

  const CardContainerInner = isWebView ? ContentWrap : React.Fragment;
  const containerStyle = useMemo(() => [styles.cardContainer, isWebView ? styles.cardContainerWeb : {}], [isWebView]);

  return (
    <View style={containerStyle}>
      <CardContainerInner>{children}</CardContainerInner>
    </View>
  );
};

const styles = StyleSheet.create({
  cardContainer: {
    width: "100%",
    padding: spacing[10],
    paddingBottom: 0,
    backgroundColor: palette.cloud,
  },

  cardContainerWeb: {
    marginBottom: spacing[10] * -1,
    padding: 0,
    backgroundColor: palette.transparent,
  },
});

export default CardContainer;
