import { useApolloClient, useQuery } from "@apollo/react-hooks";
import merge from "lodash.merge";
import React, { useState, useMemo } from "react";
import dayjs from "dayjs";

import notFalsy from "libs/utils/notFalsy";

import {
  getAmhpAssessmentsQuery,
  // TODO: Is this interface named incorrect? Its very confusing
  GetTeamAssessmentsQueryResponse,
  AssessmentListResponse,
  AssessmentCategory,
} from "@/models/Assessment";
import { getAssessmentError } from "@/models/Error";
import { checkOfflineCache } from "@/utils/helpers";

import AssessmentResults from "./AssessmentResults";
import { ErrorMessage } from "../Error/Error";

interface Variables {
  amhpID: string;
  amhpTeamId: string;
  refreshRequired: boolean;
  onRefreshComplete: (value: React.SetStateAction<boolean>) => void;
}

export interface AssessmentNextTokens {
  my: string | null;
  team: string | null;
  myArchived: string | null;
  teamArchived: string | null;
}

// TODO: remove duplicate from claimResults (doctor), replace with generic if possible
const extractNextToken = (assessmentList: AssessmentListResponse) => {
  return assessmentList && assessmentList.items && assessmentList.items.length >= 10 ? assessmentList.nextToken : null;
};

export default (props: Variables) => {
  const queryOptions = useMemo(
    () => ({
      partialRefetch: true,
      fetchPolicy: "cache-and-network" as const,
      variables: {
        amhpId: props.amhpID,
        amhpTeamId: props.amhpTeamId,
        today: dayjs().format("YYYY-MM-DD"),
      },
    }),
    [props.amhpID, props.amhpTeamId, new Date().toDateString()]
  );

  let { loading, error, data, fetchMore, refetch } = useQuery<GetTeamAssessmentsQueryResponse>(
    getAmhpAssessmentsQuery,
    queryOptions
  );

  const { myAssessments, teamAssessments, myArchivedAssessments, teamArchivedAssessments } = data || {};

  const [nextTokens, setNextTokens] = useState<AssessmentNextTokens>({
    my: null,
    team: null,
    myArchived: null,
    teamArchived: null,
  });

  const [fetchMoreAssessmentsType, setFetchMoreAssessmentsType] = useState<AssessmentCategory | null>(null);

  React.useEffect(() => {
    if (fetchMoreAssessmentsType) {
      fetchMore({
        query: getAmhpAssessmentsQuery,
        variables: {
          ...queryOptions.variables,
          [`${fetchMoreAssessmentsType}NextToken`]: nextTokens[fetchMoreAssessmentsType],
          limit: 10,
        },
        updateQuery: (
          previousResult,
          {
            fetchMoreResult,
          }: {
            fetchMoreResult: GetTeamAssessmentsQueryResponse;
          }
        ) => {
          const prevFetchMoreResultAssessment = previousResult[`${fetchMoreAssessmentsType}Assessments`]?.items || [];

          const fetchMoreResultAssessment = fetchMoreResult[`${fetchMoreAssessmentsType}Assessments`];

          const newAssessments = {
            [`${fetchMoreAssessmentsType}Assessments`]: {
              items: [...prevFetchMoreResultAssessment, ...(fetchMoreResultAssessment?.items || [])],
              nextToken: fetchMoreResultAssessment.nextToken,
            },
          };
          const mergedAssessments = merge({}, previousResult, newAssessments);
          setFetchMoreAssessmentsType(null);
          return mergedAssessments;
        },
      });
    }
  }, [fetchMoreAssessmentsType]);

  React.useEffect(() => {
    let hasUnloaded = false;
    if (props.refreshRequired && !loading) {
      // Prevents data from being fetched twice on initial load
      loading = true;
      refetch().then(() => {
        if (hasUnloaded) {
          return;
        }
        props.onRefreshComplete(false);
        loading = false;
      });
    }

    if (!loading && myAssessments && teamAssessments && myArchivedAssessments && teamArchivedAssessments) {
      setNextTokens({
        my: extractNextToken(myAssessments),
        team: extractNextToken(teamAssessments),
        myArchived: extractNextToken(myArchivedAssessments),
        teamArchived: extractNextToken(teamArchivedAssessments),
      });
    }

    return () => {
      hasUnloaded = true;
    };
  }, [myAssessments, teamAssessments, myArchivedAssessments, teamArchivedAssessments, props.refreshRequired]);

  const client = useApolloClient();

  const complete = checkOfflineCache(client, getAmhpAssessmentsQuery, queryOptions.variables);
  if (!complete && error) {
    return <ErrorMessage apolloError={error} message={getAssessmentError.message} />;
  } else {
    return (
      <AssessmentsResultsMemo
        myAssessments={myAssessments?.items?.filter(notFalsy) || []}
        teamAssessments={teamAssessments?.items?.filter(notFalsy) || []}
        teamArchivedAssessments={teamArchivedAssessments?.items?.filter(notFalsy) || []}
        myArchivedAssessments={myArchivedAssessments?.items?.filter(notFalsy) || []}
        isLoading={loading}
        setFetchMoreTypeFn={setFetchMoreAssessmentsType}
        nextTokens={nextTokens}
      />
    );
  }
};

const AssessmentsResultsMemo = React.memo(AssessmentResults);
