import React from "react";
import { StyleSheet, View } from "react-native";

import { Notification } from "@/models/MeetingRoom";
import { TypographyType } from "@/models/Typography";
import { palette, spacing } from "../../../theme";

import Icon from "../../Icon";
import Text from "../../Text";

interface NotificationProps {
  notifications: Notification[];
}

interface NotificationToastProps {
  message: string;
  icon?: string;
}

// Item
const NotificationToast: React.FC<NotificationToastProps> = ({ message, icon }) => {
  return (
    <View style={styles.toast}>
      <View style={styles.toastContent}>
        {!!icon && <Icon name={icon} size={20} color={palette.aqua} style={styles.toastIcon} />}
        <Text format={TypographyType.Small} color={palette.white}>
          {message}
        </Text>
      </View>
      <View style={styles.toastBG} />
      <View style={styles.toastBorder} />
    </View>
  );
};

// List View
export const Notifications = React.memo<NotificationProps>(({ notifications }) => {
  return (
    <View style={styles.container}>
      {notifications.map(notification => (
        <NotificationToast key={notification.id} message={notification.message} icon={notification.icon} />
      ))}
    </View>
  );
});

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    alignItems: "flex-start",
  },

  toast: {
    width: "100%",
    position: "relative",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
  },

  toastBG: {
    position: "absolute",
    left: 0,
    bottom: 1,
    width: "100%",
    height: "100%",
    backgroundColor: palette.fadedIconBG,
    opacity: 0.85,
    zIndex: -1,
  },

  toastBorder: {
    position: "absolute",
    left: 0,
    bottom: 0,
    width: "100%",
    height: 1,
    backgroundColor: palette.fadedIconBG,
    opacity: 0.65,
    zIndex: -1,
  },

  toastContent: {
    position: "relative",
    flexDirection: "row",
    alignItems: "center",
    padding: spacing[10],
    zIndex: 1,
  },

  toastIcon: {
    marginRight: spacing[10],
  },
});
