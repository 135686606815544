import React, { useState, useEffect } from "react";
import { Linking, Platform, View } from "react-native";

import Loading from "@/components/Loading";
import API from "@/api";
import { Text } from "@/components";
import { TypographyType } from "@/models/Typography";
import { Button } from "@/components/Button";
import Auth from "@aws-amplify/auth";
import { navigate } from "@reach/router";

interface NewClaimsLiveCheckValue {
  live: string;
  title: string;
  text: string;
  link?: string;
}
const useNewClaimsLiveCheck = (): { loading: boolean; value: NewClaimsLiveCheckValue | null } => {
  const [loading, setLoading] = useState<boolean>(true);
  const [value, setValue] = useState<NewClaimsLiveCheckValue | null>(null);

  useEffect(() => {
    const checkVersion = async () => {
      try {
        const result = await API.get("/checkNewClaimsLive");
        setValue(result.data);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    checkVersion();
  }, []);

  return { loading, value };
};

const NewClaimsLiveWrapper = (props: { isCCG: boolean; children?: React.ReactNode }): any => {
  if (props.isCCG) {
    const { loading, value } = useNewClaimsLiveCheck();

    if (loading) {
      return <Loading />;
    }

    // JSON parse because `value.live` is either going to be "1" or "0"
    if (value?.live && JSON.parse(value.live)) {
      const link = value?.link as string;

      return (
        <View
          style={{
            flex: 1,
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: 16,
          }}
        >
          <Text format={TypographyType.HeadingMedium}>{value.title}</Text>
          <Text format={TypographyType.Regular}>{value.text}</Text>
          {link && (
            <View style={{ marginTop: 32 }}>
              <Button onPress={() => Linking.openURL(link)}>Click here to access</Button>
            </View>
          )}
          <View style={{ marginTop: 32 }}>
            <Button onPress={() => Auth.signOut()}>Logout</Button>
          </View>
        </View>
      );
    }
  }

  return props.children;
};

export default NewClaimsLiveWrapper;
