import React, { useState, useCallback } from "react";
import { RefreshControl } from "react-native";
import { redirectUserAfterLogin } from "../utils";

import { AppScreen } from "../../layouts/AppScreen";
import ClaimProcessing from "../../components/ClaimProcessing";
import ClaimProcessingProvider from "../../components/ClaimProcessing/ClaimProcessingProvider";
import { FIND_CLAIMS } from "@/models/Claim";
import { useRecoilValue, useRecoilState } from "recoil";
import { userDetails, refreshClaimsRequired as recoilRefreshClaimsRequired } from "../../utils/recoil/index";
import { organisationIdFromPropsAndUser } from "./helpers";
import { RouteKeys } from "@/navigationv2";
import { CCGTeamClaimsRoutes, CCGTabRoutes, ScreenNavigationProps, ScreenOptions } from "@/navigationv2/types";
import { createScreenTitle } from "@/navigationv2/utilities";
import { useOnMount, useOnScreenFocus } from "@/hooks";

interface PropTypes extends ScreenNavigationProps<CCGTabRoutes, CCGTeamClaimsRoutes, RouteKeys.TeamClaimsScreen> {}

const TeamClaimsScreen = (props: PropTypes) => {
  const { route } = props;

  const user = useRecoilValue(userDetails);
  const [refreshClaimsRequired, setRefreshClaimsRequired] = useRecoilState(recoilRefreshClaimsRequired);

  const [isUnmounted, setIsUnmounted] = useState(false);
  const onRefresh = useCallback(() => !isUnmounted && setRefreshClaimsRequired(true), [isUnmounted]);

  const organisationId = organisationIdFromPropsAndUser(route, user);

  const selectedQuery = FIND_CLAIMS;

  /**
   * If the user has tried to access a previous url but was unauthenticated,
   * they are dumped to the login screen where `previousUrl` is set. Then on
   * successful log in, this is passed to the screen where a hard redirect
   * is made to the previous route.
   */
  useOnMount(() => {
    redirectUserAfterLogin();
    return () => setIsUnmounted(true);
  });

  useOnScreenFocus(() => {
    setRefreshClaimsRequired(true);
  });

  return (
    <>
      <AppScreen
        contextLabel="Claims"
        pageTitle="Team Claims"
        refreshControl={<RefreshControl refreshing={refreshClaimsRequired} onRefresh={onRefresh} />}
        testID="CCG__TeamClaims__Screen"
      >
        <ClaimProcessingProvider>
          <ClaimProcessing
            orgId={organisationId}
            selectedQuery={selectedQuery}
            claimsScreen={RouteKeys.TeamClaimsScreen}
            type="Team"
            refreshRequired={refreshClaimsRequired}
            onRefreshComplete={setRefreshClaimsRequired}
          />
        </ClaimProcessingProvider>
      </AppScreen>
    </>
  );
};

const screenOptions: ScreenOptions = {
  title: createScreenTitle("Team Claims"),
};

TeamClaimsScreen.screenOptions = screenOptions;

export default TeamClaimsScreen;
