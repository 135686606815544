import { SearchResultItem } from "./SearchResults.props";
import { Gender, LocationInput } from "libs/types/API";
import { RRule, RRuleSet } from "rrule";
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";

import { availabilitiesToNextAvailable } from "libs/dates";
import { Availability } from "libs/types/availability";
import { EventItem, Holiday } from "libs/types/holiday";

import { DoctorVisit } from "libs/types/visit";
import { convertRRuleDateTimeToUTCDate } from "libs/dates/convert";
import { RotaItem } from "../../../../admin/src/utils/queries";

dayjs.extend(utc);

interface SearchFilterCriteria {
  timeSpan: TimeSpan;
  doctorGender?: Gender | null;
  doctorLanguages?: string[];
  doctorSpecialties?: string[];
  doctorName?: string;
  location: LocationInput;
  distance: number;
}

interface TimeSpan {
  start: Dayjs;
  end: Dayjs;
}

interface AvailabilityItem {
  start: Dayjs;
  end: Dayjs;
  availability: Availability;
  distance: number;
}

export function filterSearchResults(
  results: SearchResultItem[] | undefined,
  searchCriteria: SearchFilterCriteria
): SearchResultItem[] {
  if (!results || results.length === 0) {
    return [];
  }

  const result = results
    .filter(filterGender(searchCriteria))
    .filter(filterLanguage(searchCriteria))
    .filter(filterSpecialties(searchCriteria))
    .map(filterAndConvertAvailabilityDataToDisplayStructure<SearchResultItem>(searchCriteria))
    .filter(d => d.distance < searchCriteria.distance);

  return result;
}

function isFullIntersect(outer: string[], inner: string[]) {
  return outer.filter(i => inner.indexOf(i) === -1).length === 0;
}

const filterGender = (searchCriteria: SearchFilterCriteria) => (doctor: SearchResultItem) =>
  !searchCriteria.doctorGender ||
  !searchCriteria.doctorGender.length ||
  (doctor.gender && doctor.gender.toLowerCase() === searchCriteria.doctorGender.toLowerCase());

const filterLanguage = (searchCriteria: SearchFilterCriteria) => (doctor: SearchResultItem) =>
  !searchCriteria.doctorLanguages ||
  !searchCriteria.doctorLanguages.length ||
  isFullIntersect(
    searchCriteria.doctorLanguages.map(l => l.toLowerCase()),
    doctor.languages.map(l => l.toLowerCase())
  );

const filterSpecialties = (searchCriteria: SearchFilterCriteria) => (doctor: SearchResultItem) =>
  !searchCriteria.doctorSpecialties ||
  !searchCriteria.doctorSpecialties.length ||
  isFullIntersect(
    searchCriteria.doctorSpecialties.map(s => s.toLowerCase()),
    doctor.specialties.map(s => s.toLowerCase())
  );

// checks for the next possible availability after a certain date i.e. end of holiday, end of availability
const checkNextPossibleAvailability = (availabilities: Availability[], holidays: EventItem[], endDate: Dayjs) => {
  const afterSetArr: Dayjs[] = [];
  // TODO: This is an rrule creation, its logic needs to go into the common folder and unit tested.
  availabilities.forEach(av => {
    const rruleSet = new RRuleSet(true);
    rruleSet.rrule(RRule.fromString(av.rrule.replace(";", "\n")));
    const afterSet = rruleSet.after(endDate.toDate());
    if (afterSet) afterSetArr.push(dayjs(convertRRuleDateTimeToUTCDate(afterSet)).utc());
  });

  return afterSetArr
    .filter(el => {
      const hol = holidays.map(hl => el.isBetween(hl.start, hl.end));
      return !hol.includes(true);
    })
    .sort((a, b) => (a.isAfter(b) ? 1 : -1))[0];
};

// checks if two timespans overlap
const checkCompleteOverlap = (timeSpan1: TimeSpan, timeSpan2: TimeSpan) => {
  return (
    timeSpan1.start.isBetween(timeSpan2.start, timeSpan2.end) && timeSpan1.end.isBetween(timeSpan2.start, timeSpan2.end)
  );
};

// checks if there is at least a partial overlap of two timespans
const checkPartialOverlap = (timeSpan1: TimeSpan, timeSpan2: TimeSpan) => {
  return (
    timeSpan1.start.isBetween(timeSpan2.start, timeSpan2.end) ||
    timeSpan1.end.isBetween(timeSpan2.start, timeSpan2.end) ||
    (timeSpan2.start.isBetween(timeSpan1.start, timeSpan1.end) &&
      timeSpan2.end.isBetween(timeSpan1.start, timeSpan1.end))
  );
};

// checks overlap of one date with an array of dates
const dateHasCompleteOverlap = (holidays: EventItem[], date: TimeSpan) => {
  const overlapTimespan: TimeSpan = {
    start: date.start.add(1, "second"),
    end: date.end.subtract(1, "second"),
  };
  const overlap: boolean[] = holidays.map(ph => checkCompleteOverlap(overlapTimespan, ph));
  return overlap.includes(true);
};

// complete multiple steps:
// sets next availability by checking which availabilities are in bounds
// finds the distance of this next availability.
export const filterAndConvertAvailabilityDataToDisplayStructure = <
  T extends {
    availabilities?: Availability[];
    location: LocationInput;
    holidays?: Holiday[];
    visits?: {
      id: string;
      time: Dayjs;
      partialPostcode: string | null;
    }[];
  }
>(
  searchCriteria: SearchFilterCriteria
) => {
  const { distance, location, timeSpan } = searchCriteria;

  // TODO: RRULE string manipulation should be done in one place in teh app and tested
  return (doctor: T) => {
    // when displaying the doctor distance, find the minimum distance for all availabilities
    let closestDoctorDistanceForAllAvailabilities = 999;
    const availabilities =
      doctor.availabilities
        ?.map(a => ({
          ...a,
          // some rrules have no end date (they are not repeating and are a single event),
          // so we set an end date of tommorrow at midnight
          rrule: a.rrule.includes(";")
            ? a.rrule
            : `${a.rrule};RRULE:UNTIL=${dayjs(a.rrule.split("DTSTART:")[1].split("T")[0])
                .add(1, "day")
                .format("YYYYMMDD")}T235900Z`,

          distance: distanceInMiles(a.location || doctor.location, location),
        }))
        .filter(a => {
          if (!a.location) {
            return true;
          }

          if (a.distance < distance) {
            closestDoctorDistanceForAllAvailabilities = Math.min(closestDoctorDistanceForAllAvailabilities, a.distance);
            return true;
          }

          // we get all availabilities from the network response, including out of search bounds, so filter here
          return false;
        }) || [];

    // TODO: Document why we are adding/subtracting 1 second
    const overlapTimespan: TimeSpan = {
      start: timeSpan.start.add(1, "second"),
      end: timeSpan.end.subtract(1, "second"),
    };

    const bookedOverlap: TimeSpan = {
      start: timeSpan.start.add(30, "minute"),
      end: timeSpan.end.subtract(60, "minute"),
    };

    // check if doctor is on holiday during search time span
    const currentHoliday: EventItem | undefined = doctor.holidays
      ? doctor.holidays.find((hl: EventItem) =>
          checkCompleteOverlap(overlapTimespan, {
            start: hl.start,
            end: hl.end,
          })
        )
      : undefined;

    const currentlyBooked: DoctorVisit | null | undefined = doctor.visits
      ? doctor.visits.find((v: DoctorVisit) =>
          checkCompleteOverlap(bookedOverlap, {
            start: v.time,
            end: v.time,
          })
        )
      : null;

    const isAway = currentHoliday !== undefined;
    const isBooked = currentlyBooked !== undefined;

    // store availability rotas
    const availabilityRotas: any[] = [];

    // check for nextAvailability if doctor is not away
    const nextAvailability =
      !isAway && !isBooked
        ? availabilitiesToNextAvailable(availabilities, [], [], timeSpan.start, timeSpan.end).map(a => ({
            ...a,
            distance: distanceInMiles(a.availability.location || doctor.location, location),
          }))
        : [];

    let adjustedAvailability: AvailabilityItem | undefined;

    // nextAvailability needs adjusting based on results
    if (nextAvailability.length === 0) {
      adjustedAvailability = undefined;
    } else if (nextAvailability.length > 1) {
      // if multiple results returned, check if any or all of them overlap with a holiday and remove these
      let availabilities: AvailabilityItem[] = [];
      if (doctor.holidays) {
        availabilities = nextAvailability.filter(av => !dateHasCompleteOverlap(doctor.holidays!, av));
      } else {
        availabilities = nextAvailability;
      }

      // sort to return oncall first, then the closest, then earliest availability
      adjustedAvailability =
        availabilities.length > 0
          ? availabilities
              .sort((a, b) => (a.start.isAfter(b.start) ? 1 : -1))
              .sort((a, b) => a.distance - b.distance)
              .sort((a, b) => Number(b.availability.oncall || false) - Number(a.availability.oncall || false))[0]
          : undefined;

      // extract any rotas
      availabilities.map((a: AvailabilityItem) => {
        const rotaItem = { id: a.availability.rotaId, name: a.availability.rotaName };
        if (a.availability.rotaId !== null && !availabilityRotas.includes(rotaItem)) {
          availabilityRotas.push(rotaItem);
        }
      });
    } else {
      if (doctor.holidays) {
        adjustedAvailability = dateHasCompleteOverlap(doctor.holidays, nextAvailability[0])
          ? undefined
          : nextAvailability[0];
      } else {
        adjustedAvailability = nextAvailability[0];
      }

      // extract any rota
      if (nextAvailability[0].availability.rotaId !== null) {
        availabilityRotas.push({
          id: nextAvailability[0].availability.rotaId,
          name: nextAvailability[0].availability.rotaName,
        });
      }
    }

    const partiallyBooked =
      (!isBooked &&
        doctor.visits?.find(v =>
          checkPartialOverlap(timeSpan, {
            // TODO: bookedOverlap also does subtraction/addition of these times, reuse?
            start: v.time.subtract(30, "minute"),
            end: v.time.add(60, "minute"),
          })
        ) !== undefined) ||
      doctor.visits?.find(v =>
        checkCompleteOverlap(
          {
            // TODO: bookedOverlap also does subtraction/addition of these times, reuse?
            start: v.time.subtract(30, "minute"),
            end: v.time.add(60, "minute"),
          },
          timeSpan
        )
      ) !== undefined;

    const now = dayjs();

    // doctor set to partial, if on holiday for parts of the time window and available outside the holiday
    const isPartial =
      !isAway &&
      (adjustedAvailability &&
        doctor.holidays &&
        doctor.holidays.find((ph: EventItem) =>
          checkPartialOverlap(
            {
              start: adjustedAvailability ? adjustedAvailability.start : now,
              end: adjustedAvailability ? adjustedAvailability.end : now,
            },
            { start: ph.start, end: ph.end }
          )
        )) !== undefined;

    // setting endTime to check for next possible availability based of end of search window or end of holiday
    const endTime = currentHoliday
      ? currentHoliday.end.isAfter(timeSpan.end)
        ? currentHoliday.end
        : timeSpan.end
      : timeSpan.end;

    const availability = isAway
      ? endTime
      : !adjustedAvailability
      ? checkNextPossibleAvailability(availabilities, doctor.holidays ? doctor.holidays : [], endTime)
      : null;

    const output = {
      ...doctor,
      distance: adjustedAvailability
        ? adjustedAvailability.distance
        : // no availability? use base location
          distanceInMiles(doctor.location, location),
      nextAvailability: adjustedAvailability,
      availabilityRotas,
      booked: {
        isBooked,
        partiallyBooked,
      },
      onHoliday: {
        isAway,
        isPartial,
        availability: availability?.utc() || null,
      },
    };

    return output;
  };
};

function distanceInMiles(location1: LocationInput, location2: LocationInput) {
  if (location1.lat === location2.lat && location1.lon === location2.lon) {
    return 0;
  } else {
    const radlat1 = (Math.PI * location1.lat) / 180;
    const radlat2 = (Math.PI * location2.lat) / 180;
    const theta = location1.lon - location2.lon;
    const radtheta = (Math.PI * theta) / 180;
    let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    return dist;
  }
}
