import React, { useState, useMemo } from "react";
import {
  GestureResponderEvent,
  StyleProp,
  StyleSheet,
  TouchableOpacity,
  View,
  ViewStyle,
  Platform,
} from "react-native";

import { TypographyType } from "@/models/Typography";
import { color, palette, spacing } from "../../theme";
import Icon from "../Icon";
import Text from "../Text";
import { Divider, TouchableRipple } from "react-native-paper";

const baseStyles = {
  background: {
    borderRadius: 12,
    backgroundColor: color.available,
    paddingLeft: spacing[20],
    paddingRight: spacing[20],
    paddingVertical: spacing[10],
  },
  text: {
    color: color.text,
  },
};

const disabledStyles = {
  background: {
    backgroundColor: color.backgroundGreyDark,
  },
  text: {
    color: color.textExtraLight,
  },
};

const backgroundStyle: { [key: string]: any } = StyleSheet.create({
  // eslint-disable-next-line
  action: { ...baseStyles.background },
  // eslint-disable-next-line
  cancel: { ...baseStyles.background, backgroundColor: color.textError },
  // eslint-disable-next-line
  redirect: {
    ...baseStyles.background,
    flexDirection: "row",
    backgroundColor: color.oncall,
  },
});

const contentStyles: { [key: string]: any } = StyleSheet.create({
  // eslint-disable-next-line
  action: { ...baseStyles.text },
  // eslint-disable-next-line
  cancel: { ...baseStyles.text, color: color.textWhite },
  // eslint-disable-next-line
  redirect: { ...baseStyles.text, color: color.textWhite },
});

type IconPosition = "left" | "right";

interface RectangleButtonProps {
  children: React.ReactNode;
  onPress: (event: GestureResponderEvent) => void;
  mode?: "action" | "redirect" | "cancel";
  disabled?: boolean;
  contentStyle?: StyleProp<ViewStyle>;
  style?: StyleProp<ViewStyle>;
  icon?: string;
  iconPosition?: IconPosition;
}

export const RectangleButton = (props: RectangleButtonProps) => {
  let { style, contentStyle, children, disabled, onPress, mode = "action", icon, iconPosition = "left" } = props;

  style = useMemo(
    () => [
      backgroundStyle[mode],
      disabled && disabledStyles.background,
      props.style,
      { flexDirection: "row", alignItems: "flex-end" },
    ],
    [mode, disabled, props.style]
  );
  contentStyle = useMemo(
    () => [
      contentStyles[mode],
      mode === "redirect" && { paddingRight: spacing[10] },
      disabled && disabledStyles.text,
      props.contentStyle,
    ],
    [mode, props.style, disabled, props.contentStyle]
  );

  const SpecialIcon = useMemo(() => {
    const iconSpacing = children
      ? iconPosition === "left"
        ? { paddingRight: spacing[10] }
        : { paddingLeft: spacing[10] }
      : null;
    return icon ? <Icon name={icon} style={[contentStyle, iconSpacing]} /> : <></>;
  }, [icon, mode, props.style, disabled, props.contentStyle]);
  return (
    <TouchableOpacity activeOpacity={1} onPress={onPress} disabled={disabled} style={style}>
      {icon && iconPosition === "left" && SpecialIcon}
      <Text format={TypographyType.TinyBold} style={contentStyle}>
        {children}
      </Text>
      {icon && iconPosition === "right" && SpecialIcon}
      {mode === "redirect" && <Icon name="arrow-forward" />}
    </TouchableOpacity>
  );
};

export const DropdownButton = (props: {
  children: React.ReactNode;
  mode: "action" | "redirect" | "cancel";
  disabled?: boolean;
  icon?: string;
  dropdownButtons: RectangleButtonProps[];
}) => {
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const { children, disabled, icon = "arrow-drop-down", dropdownButtons } = props;

  return (
    <View style={styles.column}>
      <RectangleButton
        onPress={() => setDropdownOpen(!dropdownOpen)}
        icon={icon}
        iconPosition="right"
        disabled={disabled}
      >
        {children}
      </RectangleButton>
      <View style={styles.dropdownButtonView}>
        {dropdownButtons &&
          dropdownOpen &&
          dropdownButtons.map((button: RectangleButtonProps, idx: number) => {
            return (
              <View key={idx} style={styles.dropdownButton}>
                <RectangleButton
                  onPress={e => {
                    button.onPress(e);
                    setDropdownOpen(!dropdownOpen);
                  }}
                  icon={button.icon || undefined}
                  mode="action"
                  disabled={button.disabled}
                  style={styles.noBorderRadius}
                >
                  {button.children}
                </RectangleButton>
                {idx < dropdownButtons.length - 1 && <Divider />}
              </View>
            );
          })}
      </View>
    </View>
  );
};

export const LoadMoreButton = (loadMoreAction: any, isLoading?: boolean) => {
  return (
    <View style={styles.row}>
      <TouchableRipple
        onPress={loadMoreAction}
        disabled={isLoading}
        rippleColor={palette.rippleColor}
        style={styles.loadMoreButton}
      >
        <View style={styles.loadMoreButtonInner}>
          <Icon size={18} name="file-download" color={palette.blue} />
          <Text format={TypographyType.MicroBold} color={palette.blue}>
            LOAD MORE
          </Text>
        </View>
      </TouchableRipple>
    </View>
  );
};

const styles = StyleSheet.create({
  column: {
    flexDirection: "column",
  },
  noBorderRadius: {
    borderRadius: 0,
  },

  dropdownButton: {
    flex: 1,
  },
  dropdownButtonView: {
    position: "absolute",
    top: spacing[50],
    backgroundColor: color.secondary,
    borderRadius: 12,
    flex: 1,
    width: "100%",
    flexGrow: 1,
    overflow: "hidden",
  },

  loadMoreButton: {
    width: "100%",
    borderRadius: 4,
    padding: spacing[10],
    ...Platform.select({
      web: {
        cursor: "pointer",
      },
    }),
  },

  loadMoreButtonInner: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },

  row: {
    alignItems: "flex-end",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: spacing[10],
  },
});
