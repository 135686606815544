import API from "@aws-amplify/api";
import Auth from "@aws-amplify/auth";

interface OptionParams {
  restApiEndpoint: string;
  env?: string;
}

/**
 * Configure our API endpoints.
 *
 * @param {OptionParams} options
 */
export default function configureApi(options: OptionParams) {
  const { restApiEndpoint, env = "prod" } = options;
  const baseUrl = "https://lambda.eu-west-2.amazonaws.com/2015-03-31/functions";

  API.configure({
    endpoints: [
      {
        name: "signupResend",
        endpoint: `${baseUrl}/signupResend-${env}/invocations`,
        service: "lambda",
        region: "eu-west-2",
      },
      {
        name: "generateInvoice",
        endpoint: `${baseUrl}/invoice-${env}/invocations`,
        service: "lambda",
        region: "eu-west-2",
      },
      {
        name: "RestAPI",
        endpoint: restApiEndpoint,
        custom_header: async () => {
          return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` };
        },
      },
    ],
  });
}
