import React from "react";

import { TypographyType } from "@/models/Typography";

import { Accordion, AccordionSection } from "../../Accordion";
import { ContentWrap } from "../../ContentWrap";
import { ResponsiveImage } from "../../ResponsiveImage";
import Text from "../../Text";
import { palette, spacing } from "../../../theme";

export const PermissionsHelp: React.FC = () => {
  const { HeadingMedium, HeadingSmall, Regular, RegularBold, Tiny } = TypographyType;

  return (
    <>
      <ContentWrap>
        <Text format={HeadingMedium}>Settings Help</Text>
        <Text format={Regular} marginBottom={spacing[20]}>
          In order to use the S12 Solutions Meeting Room feature, you first need to grant the S12 Solutions app
          permission to access your camera and microphone.
        </Text>
        <Text format={Regular} marginBottom={spacing[20]}>
          When you first join an S12 Meeting Room, you will be prompted to allow access to your device camera and
          microphone.
        </Text>
        <Text format={Regular}>
          If you missed this prompt, or accidentally denied access to your mic and/or camera, please select the name of
          the browser you are using from the list below, for instructions on how to enable mic and camera access.
        </Text>
      </ContentWrap>

      <ContentWrap>
        <Text format={HeadingSmall} marginTop={spacing[50]}>
          Select your browser
        </Text>
      </ContentWrap>

      <Accordion marginTop={spacing[30]}>
        <AccordionSection index={0} heading="Chrome" subHeading="(Web)">
          <Text format={Regular} marginBottom={spacing[20]}>
            At the top of your browser window, click on the padlock icon, to the left of the website address, in the
            address bar (see image below).
          </Text>
          <Text format={Regular} marginBottom={spacing[20]}>
            Doing this will open a dialog window, where you can toggle the permission settings for your camera and
            microphone.
          </Text>
          <Text format={RegularBold} marginBottom={spacing[30]}>
            Once you have updated your settings here, you will need to refresh the page for the changes to take effect.
          </Text>
          <ResponsiveImage src={require("../images/chrome.jpg")} srcWidth={710} srcHeight={639} />
        </AccordionSection>

        <AccordionSection index={1} heading="Edge, New" subHeading="(Web)">
          <Text format={Tiny} color={palette.greyBlue} marginBottom={spacing[20]}>
            N.B: If you use Edge but these instructions do not work for you, it's likely because you are using the old
            version of Edge; instructions for the old Edge can be found below.
          </Text>
          <Text format={Regular} marginBottom={spacing[20]}>
            At the top of your browser window, click on the padlock icon, to the left of the website address, in the
            address bar (see image below).
          </Text>
          <Text format={Regular} marginBottom={spacing[20]}>
            Doing this will open a dialog window, where you can toggle the permission settings for your camera and
            microphone.
          </Text>
          <Text format={RegularBold} marginBottom={spacing[20]}>
            Once you have updated your settings here, you will need to refresh the page for the changes to take effect.
          </Text>
          <ResponsiveImage src={require("../images/edge-new.jpg")} srcWidth={376} srcHeight={376} />
        </AccordionSection>

        <AccordionSection index={2} heading="Edge, Old" subHeading="(Web)">
          <Text format={Tiny} color={palette.greyBlue} marginBottom={spacing[20]}>
            N.B: If you use Edge but these instructions do not work for you, it's likely because you are using the new
            version of Edge; instructions for the new Edge can be found above.
          </Text>
          <Text format={Regular} marginBottom={spacing[20]}>
            Click the icon with three dots, in the top-right corner of the Edge browser window, and select "Settings"
            from the menu.
          </Text>
          <ResponsiveImage src={require("../images/edge-old-1.jpg")} srcWidth={438} srcHeight={628} />
          <Text format={Regular} marginBottom={spacing[20]}>
            From the settings menu, click on the "Advanced" tab on the left of the menu.
          </Text>
          <ResponsiveImage src={require("../images/edge-old-2.jpg")} srcWidth={438} srcHeight={628} />
          <Text format={Regular} marginBottom={spacing[20]} marginTop={spacing[20]}>
            From the Advanced menu, find and click on the "Manage permissions" button.
          </Text>
          <ResponsiveImage src={require("../images/edge-old-3.jpg")} srcWidth={438} srcHeight={628} />
          <Text format={Regular} marginBottom={spacing[20]} marginTop={spacing[20]}>
            Now select the S12 Solutions website
          </Text>
          <ResponsiveImage src={require("../images/edge-old-4.jpg")} srcWidth={438} srcHeight={628} />
          <Text format={Regular} marginBottom={spacing[20]} marginTop={spacing[20]}>
            From here, you can toggle the permission settings for your camera and microphone.
          </Text>
          <ResponsiveImage src={require("../images/edge-old-5.jpg")} srcWidth={438} srcHeight={628} />
        </AccordionSection>

        <AccordionSection index={3} heading="Firefox" subHeading="(Web)">
          <Text format={Regular} marginBottom={spacing[20]}>
            At the top of your browser window, click on the padlock icon, to the left of the website address, in the
            address bar (see image below).
          </Text>
          <Text format={Regular} marginBottom={spacing[20]}>
            Doing this will open a dialog window, where you can toggle the permission settings for your camera and
            microphone.
          </Text>
          <Text format={Regular} marginBottom={spacing[20]}>
            Click on the "X" next to any/all blocked input devices, to enable permissions for them.
          </Text>
          <Text format={RegularBold} marginBottom={spacing[20]}>
            N.B: Once you have updated your settings here, you will need to refresh the page for the changes to take
            effect.
          </Text>
          <ResponsiveImage src={require("../images/firefox.jpg")} srcWidth={926} srcHeight={600} />
        </AccordionSection>

        <AccordionSection index={4} heading="Safari" subHeading="(Web, macOS)">
          <Text format={Regular} marginBottom={spacing[20]}>
            From the Apple menu bar, select "Safari", then click, "Settings for This Website..." (see image below).
          </Text>
          <ResponsiveImage src={require("../images/safari1.jpg")} srcWidth={648} srcHeight={612} />
          <Text format={Regular} marginBottom={spacing[20]}>
            This will open a dialog menu, where you can toggle the permission settings for your camera and microphone.
          </Text>
          <Text format={Regular} marginBottom={spacing[20]}>
            Click on the settings next to camera and microphone, and make sure they are both set to "Allow".
          </Text>
          <ResponsiveImage src={require("../images/safari2.jpg")} srcWidth={848} srcHeight={714} />
        </AccordionSection>
      </Accordion>
    </>
  );
};
