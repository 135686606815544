import { amhpTeamVideoMeetings } from "libs/utils/featureFlags";
import dayjs from "dayjs";
import React, { useCallback, useMemo } from "react";
import { Linking, Platform, View, StyleSheet } from "react-native";
import { useRecoilValue } from "recoil";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";

import { Button, ButtonList } from "@/components/Button";
import { ContentWrap } from "@/components/ContentWrap";
import Text from "@/components/Text";
import { AssessmentItem, GET_ASSESSMENT } from "@/models/Assessment";
import { TypographyType } from "@/models/Typography";
import { AllAMHPRoutes } from "@/navigationv2/types";
import { mqWeb } from "@/utils/helpers";
import { userDetails } from "@/utils/recoil";

import { ContactedDoctor } from "../AssessmentDetails.props";
import { DoctorTables } from "./DoctorTables";
import { DoctorCards } from "./DoctorCards";
import { useNavigateUserToMeetingRoom } from "../../MeetingRoom/helpers";
import { ExportDoctorHistory } from "@/components/ExportCSV/ExportDoctorHistory";

interface PropTypes {
  addContactedDoctor: any;
  assessment: AssessmentItem;
  contacted: ContactedDoctor[];
  editMode: boolean;
  findDoctors: () => void;
  submitted: boolean;
}

const AssessmentDoctors = (props: PropTypes) => {
  const { addContactedDoctor, assessment, contacted, editMode, findDoctors, submitted } = props;
  const navigation = useNavigation<StackNavigationProp<AllAMHPRoutes>>();
  const navigateUserToMeeting = useNavigateUserToMeetingRoom();
  const user = useRecoilValue(userDetails);
  const isWebView = mqWeb();
  const TopContentWrapper = useMemo(() => (isWebView ? React.Fragment : ContentWrap), [isWebView]);
  const disableFindDoctorsButton =
    assessment.doctorVisits && assessment.doctorVisits.items && assessment.doctorVisits.items.length === 2;

  const viewProfile = useCallback(
    (doctorId: string) =>
      navigation.navigate("ProfileScreen", {
        assessmentId: assessment.id,
        id: doctorId,
      }),
    [assessment.id, navigation.navigate]
  );
  const createClaim = useCallback(
    (visitId: string) => {
      navigation.navigate("CreateClaimScreen", { visitId });
    },
    [navigation.navigate]
  );

  const callContactedDoctorOnPressAction = (doctorId: string, doctorPhone: string, assessmentId: string) => {
    addContactedDoctor({
      variables: {
        input: {
          assessmentId: assessmentId,
          contactedDoctorS12DoctorId: doctorId,
          createdAt: new Date().toISOString(),
        },
      },
      refetchQueries: [
        {
          query: GET_ASSESSMENT,
          variables: { id: assessment.id },
        },
      ],
    });
    Linking.openURL(`${Platform.OS === "ios" ? "telprompt:" : "tel:"}${doctorPhone}`).catch(() => null);
  };

  return (
    <>
      <TopContentWrapper>
        <View style={styles.row}>
          <Text format={TypographyType.HeadingMedium}>Doctors</Text>
          {Platform.OS === "web" && isWebView && <ExportDoctorHistory assessmentId={assessment.id} />}
        </View>
        <ButtonList disablePadding="top">
          {amhpTeamVideoMeetings(assessment.amhpTeam.featureFlags) &&
            Platform.OS === "web" &&
            !!assessment.doctorVisits?.items?.length &&
            assessment.amhp.id === user?.id &&
            Math.min(...assessment.doctorVisits?.items.map(i => Math.abs(dayjs(i.time).diff(dayjs(), "hour")))) <
              24 && (
              <Button
                onPress={() => navigateUserToMeeting(assessment.id)}
                marginBottom={0}
                width="100%"
                mode="outlined"
                testID="MeetingRoomScreen__link"
              >
                Start Video Meeting
              </Button>
            )}
          <Button
            marginBottom={0}
            width="100%"
            onPress={findDoctors}
            disabled={disableFindDoctorsButton || submitted || editMode}
          >
            Find Doctors
          </Button>
        </ButtonList>
      </TopContentWrapper>
      {isWebView ? (
        <DoctorTables
          isWebView={true}
          hasVisits={(assessment?.doctorVisits?.items?.length || 0) > 0}
          visits={assessment?.doctorVisits?.items || []}
          contactedDoctors={contacted}
          assessment={assessment}
          contactDoctor={callContactedDoctorOnPressAction}
          viewProfile={viewProfile}
          createClaim={createClaim}
        />
      ) : (
        <DoctorCards
          isWebView={true}
          hasVisits={(assessment?.doctorVisits?.items?.length || 0) > 0}
          visits={assessment?.doctorVisits?.items || []}
          contactedDoctors={contacted}
          assessment={assessment}
          contactDoctor={callContactedDoctorOnPressAction}
          viewProfile={viewProfile}
          createClaim={createClaim}
        />
      )}
    </>
  );
};

export default AssessmentDoctors;

const styles = StyleSheet.create({
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
});
