/* eslint-disable complexity */
import React, { useEffect, useRef, useState } from "react";
import { ButtonProps as RNButtonProps } from "react-native-paper/typings/components/Button";
import { Button as RNPButton } from "react-native-paper";
import { StyleSheet, View, ViewStyle, TextStyle } from "react-native";

import { mqWeb } from "../../utils/helpers";
import Icon, { IconName } from "../Icon";
import { TypographyType } from "@/models/Typography";
import Text from "../Text";
import { color, spacing, palette } from "../../theme";

interface ButtonProps extends RNButtonProps {
  inverted?: boolean;
  marginTop?: number;
  marginRight?: number;
  marginBottom?: number;
  marginLeft?: number;
  minDisabledTime?: number;
  webHeaderButton?: boolean;
  width?: "auto" | "100%";
  disabledInfoText?: string;
}

export const Button = (props: ButtonProps) => {
  let {
    style,
    contentStyle,
    children,
    disabled,
    mode = "contained",
    inverted,
    webHeaderButton = false,
    marginTop,
    marginRight,
    marginBottom = 10,
    marginLeft,
    minDisabledTime = 0,
    ...restProps
  } = props;

  const isWebView = mqWeb();
  const lastDisabledTime = useRef(0);
  const [isDisabled, setDisabled] = useState(false);

  // TODO: try to get rid of inline styles.
  const baseStyle: ViewStyle = {
    flexBasis: "auto",
    flexDirection: "column",
    alignItems: "stretch",
    alignSelf: "center",
    width: props.width ? props.width : isWebView ? "auto" : "100%",
    marginTop: marginTop === 0 ? 0 : marginTop,
    marginRight: marginRight === 0 ? 0 : marginRight,
    marginBottom: props.disabledInfoText && disabled ? 0 : marginBottom === 0 ? 0 : marginBottom,
    marginLeft: marginLeft === 0 ? 0 : marginLeft,
    borderWidth: 2,
    borderRadius: isWebView ? 12 : 40,
  };

  const webTextStyles: TextStyle = {
    textTransform: "uppercase",
  };

  // TODO: more inline styles...
  const styles: {
    [key: string]: any;
    contained: Record<string, unknown>;
    outlined: Record<string, unknown>;
    text: Record<string, unknown>;
    inverted: Record<string, unknown>;
  } = {
    // eslint-disable-next-line
    contained: {
      ...baseStyle,
      borderColor: isDisabled ? palette.cloud : props.color || palette.blue,
      backgroundColor: isDisabled ? palette.cloud : props.color || palette.blue,
    },
    // eslint-disable-next-line
    outlined: {
      ...baseStyle,
      borderColor: isDisabled ? palette.grey : props.color || palette.blue,
      backgroundColor: palette.transparent,
    },
    // eslint-disable-next-line
    inverted: {
      ...baseStyle,
      backgroundColor: isDisabled ? color.backgroundGrey : color.background,
    },
    // eslint-disable-next-line
    text: { ...baseStyle, marginLeft: -8, marginRight: -8 },
  };

  const parentContentstyle = {
    buttonContainer: {
      paddingHorizontal: isWebView ? (webHeaderButton ? 0 : 3) : spacing[10],
      paddingVertical: isWebView && webHeaderButton ? 0 : 2,
      borderRadius: 40,
    },
  };

  const contentStyles: { [key: string]: any } = {
    // eslint-disable-next-line
    contained: {
      color: isDisabled
        ? palette.greyBlue
        : props.color === palette.aqua || props.color === palette.yellow
        ? palette.navy
        : palette.white,
    },
    // eslint-disable-next-line
    outlined: {
      color: isDisabled ? palette.greyBlue : props.color || palette.blue,
    },
    // eslint-disable-next-line
    text: {
      color: isDisabled ? palette.greyBlue : props.color || palette.blue,
    },
    // eslint-disable-next-line
    inverted: {
      color: isDisabled ? palette.greyBlue : props.color || palette.blue,
    },
  };

  style = [styles[inverted ? "inverted" : mode], props.style];
  contentStyle = [contentStyles[inverted ? "inverted" : mode], props.contentStyle];

  useEffect(
    function setDisabledValue() {
      let timeoutId: number;

      if (disabled) {
        setDisabled(true);
        lastDisabledTime.current = Date.now();
      } else {
        const timeLeft = Date.now() - lastDisabledTime.current;

        if (timeLeft < minDisabledTime) {
          timeoutId = window.setTimeout(() => {
            setDisabled(false);
            lastDisabledTime.current = 0;
          }, minDisabledTime - timeLeft);
        } else {
          setDisabled(false);
          lastDisabledTime.current = 0;
        }
      }

      return () => clearTimeout(timeoutId);
    },
    [disabled]
  );

  return (
    <View>
      <RNPButton
        {...restProps}
        disabled={isDisabled}
        style={style}
        contentStyle={parentContentstyle.buttonContainer}
        mode={mode as ButtonProps["mode"]}
      >
        <Text
          format={
            isWebView
              ? webHeaderButton
                ? TypographyType.MicroBold
                : TypographyType.TinyBold
              : TypographyType.RegularBold
          }
          type="paper"
          style={[contentStyle, isWebView ? webTextStyles : {}]}
        >
          {children}
        </Text>
      </RNPButton>
      {disabled && props.disabledInfoText && (
        <View style={disabledInfoTextStyles.container}>
          <Icon size={14} name={IconName.info_outline} style={disabledInfoTextStyles.icon} color={palette.greyBlue} />
          <Text format={TypographyType.Micro} color={palette.greyBlue}>
            {props.disabledInfoText}
          </Text>
        </View>
      )}
    </View>
  );
};

const disabledInfoTextStyles = StyleSheet.create({
  container: {
    marginTop: spacing[5],
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  icon: {
    marginRight: spacing[5],
  },
});

export const ToggleButton = (props: {
  toggleFn: any;
  value: boolean;
  text: string;
  disabled?: boolean;
  icon?: string;
  compact?: boolean;
  style?: any;
}) => {
  return (
    <Button
      onPress={() => props.toggleFn(!props.value)}
      disabled={props.disabled}
      icon={props.icon || undefined}
      mode="text"
      compact={props.compact}
      style={props.style}
    >
      {props.text}
    </Button>
  );
};
