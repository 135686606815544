import React, { useContext } from "react";
import { View, StyleSheet } from "react-native";
import { RadioButton, RadioButtonGroup } from "../../Radio/Radio";
import { Checkbox } from "../../Checkbox/Checkbox";
import { ContentWrap } from "../../ContentWrap";

import { AvailabilityRepetition } from "../AvailabilityRepetition";
import { spacing, color } from "../../../theme";
import {
  AvailabilityControllerActionTypes,
  AvailabilityModelActionTypes,
  weekdays,
  StateContext,
} from "libs/state/availability/initialState";
import { AvailabilityContext } from "../DoctorAvailabilityProvider";

export function AvailabilityPattern() {
  const { state, dispatch, controllerDispatch } = useContext<StateContext>(AvailabilityContext);
  const setRepeatWeekdays = (value: string[]) =>
    dispatch({
      type: AvailabilityModelActionTypes.SET_REPEAT_WEEKDAYS,
      payload: value,
    });

  return (
    <View style={styles.wrapper}>
      <ContentWrap>
        <View style={styles.weekdaysContainer}>
          {weekdays.map((day: { label: string; value: string }) => (
            <Checkbox
              key={day.value}
              verticalLayout={true}
              status={state.repeatWeekdays.includes(day.value)}
              label={day.label}
              onPress={() => {
                state.repeatWeekdays.includes(day.value)
                  ? setRepeatWeekdays(state.repeatWeekdays.filter(ds => ds !== day.value))
                  : setRepeatWeekdays([...state.repeatWeekdays, day.value]);
              }}
            />
          ))}
        </View>
        <RadioButtonGroup
          onValueChange={(val: string) => {
            if (state.repeatWeekly !== val) {
              controllerDispatch({
                type: AvailabilityControllerActionTypes.TOGGLE_REPEAT_WEEKLY,
                payload: val,
              });
              dispatch({
                type: AvailabilityModelActionTypes.REPEAT_WEEKLY_COUNT,
                payload: "1",
              });
            }
          }}
          value={state.repeatWeekly}
        >
          <RadioButton value={"false"} status={state.repeatWeekly === "false"} label={"Only this week"} />
          <RadioButton value={"true"} status={state.repeatWeekly === "true"} label={"Repeat over multiple weeks"} />
        </RadioButtonGroup>
        {state.repeatWeekly === "true" && <AvailabilityRepetition noPadding={true} />}
      </ContentWrap>
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    backgroundColor: color.backgroundGrey,
    paddingVertical: spacing[30],
  },
  weekdaysContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingBottom: spacing[30],
  },
});
