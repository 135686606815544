import { SetterOrUpdater } from "recoil";
import { CognitoUser } from "@aws-amplify/auth";

import { UserDetails } from "@/utils/recoil/props";
import { CognitoUserGroupName } from "@/navigationv2/types";

/**
 * Update the the current user details stored in Recoil state.
 *
 * @author Alex McCabe
 * @param {CognitoUser} userData AWS Cognito `Auth.currentAuthenticatedUser`
 *     user data response.
 * @param {SetterOrUpdater<UserDetails>} setUserDetails Recoil set state
 *     function that will update the current stored user details.
 */
export default function updateUserDetails(userData: CognitoUser, setUserDetails: SetterOrUpdater<UserDetails>) {
  const idTokenPayload = userData.getSignInUserSession()?.getIdToken().payload;

  if (idTokenPayload) {
    setUserDetails({
      groups: idTokenPayload["cognito:groups"],
      teams: JSON.parse(idTokenPayload["s12:amhpTeams"] || "[]"),
      id: idTokenPayload["cognito:username"],
      email: idTokenPayload.email,
      name: !idTokenPayload["cognito:groups"].includes(CognitoUserGroupName.Doctor) ? idTokenPayload.name : "",
      eulaVersion: +(idTokenPayload["custom:eula_accepted"] || "0"),
      organisations: JSON.parse(idTokenPayload["s12:organisations"] || "[]"),
      csu: (JSON.parse(idTokenPayload["s12:CSUs"] || "[]") as string[])[0],
      sites: JSON.parse(idTokenPayload["s12:sites"] || "[]"),
      ...(idTokenPayload["custom:is_trainee_amhp"] && {
        isTraineeAmhp: JSON.parse(idTokenPayload["custom:is_trainee_amhp"]),
      }),
    });
  }
}
