import React from "react";
import { Modal } from "./Modal";
import { useRecoilState } from "recoil";
import { globalModalData } from "../../utils/recoil";

const GlobalModal = () => {
  const [modalData, setModalData] = useRecoilState(globalModalData);

  return modalData ? (
    <Modal
      visible={!!(modalData && modalData.component)}
      dismissable={true}
      onDismiss={() => {
        modalData.onDismiss && modalData.onDismiss();
        setModalData(null);
      }}
      headline={modalData?.label || ""}
      removeInnerScroller={true}
    >
      {modalData.component}
    </Modal>
  ) : (
    <React.Fragment />
  );
};

export default GlobalModal;
