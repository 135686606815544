import React from "react";
import { View, StyleSheet } from "react-native";
import DualDatePicker, { DualDatePickerProps } from "../../DatePicker/DualDatePicker";

import { TypographyType } from "@/models/Typography";
import Text from "../../Text";
import { spacing, palette } from "../../../theme";
import { leftPad } from "libs/dates";

interface MHADatePickerProps extends DualDatePickerProps {
  readonly?: boolean;
}

const MHADatePicker = (props: MHADatePickerProps) => {
  const { readonly = false, ...rest } = props;

  if (!readonly) {
    return <DualDatePicker {...rest} errorText={props.errorText || "none"} noIcon />;
  }

  return (
    <View style={styles.readonlyWrapper}>
      <View
        style={{
          flexDirection: "row",
          paddingVertical: spacing[15],
          paddingHorizontal: spacing[20],
          backgroundColor: "white",
          marginRight: spacing[10],
        }}
      >
        <Text format={TypographyType.Small}>{props.value ? leftPad(props.value.date()) : "-"}</Text>
        <Text format={TypographyType.Small} style={styles.dateDivider}>
          /
        </Text>
        <Text format={TypographyType.Small}>{props.value ? leftPad(props.value.month() + 1) : "-"}</Text>
        <Text format={TypographyType.Small} style={styles.dateDivider}>
          /
        </Text>
        <Text format={TypographyType.Small}>{props.value ? props.value.year() : "-"}</Text>
      </View>
      <View style={{ justifyContent: "center" }}>
        <Text format={TypographyType.Small}>(Date)</Text>
      </View>
    </View>
  );
};

export default MHADatePicker;

const styles = StyleSheet.create({
  readonlyWrapper: {
    flexDirection: "row",
    flex: 1,
  },
  dateDivider: { color: palette.greyBlue, marginHorizontal: spacing[10] },
});
