import React, { useState, useEffect } from "react";
import { View, Platform } from "react-native";
import { ParamListBase, useNavigation } from "@react-navigation/native";
import { RouteKeys } from "@/navigationv2";

import { Description } from "../../Description";
import { ContentWrap } from "../../ContentWrap";
import { SectionDivider } from "../../SectionDivider";
import Text from "../../Text";

import { AssessmentDetails, ControlBarMeetingDetails } from "../MeetingRoom.types";
import { TypographyType } from "@/models/Typography";
import { useBottomSheet, useDialog } from "@/hooks";
import { spacing } from "../../../theme";
import { meetingStyles } from "../styles";
import { useRecoilValue } from "recoil";
import { userDetails } from "@/utils/recoil";
import { StackNavigationProp } from "@react-navigation/stack";
import ENV from "@/config";
import { openLinkNative } from "@/utils";

export function useGoBack() {
  const navigation = useNavigation<StackNavigationProp<ParamListBase>>();
  const user = useRecoilValue(userDetails);
  const isDoctor = user?.groups.includes("Doctor");
  // if user can't navigate back i.e. user refreshed screen and lost history -> navigate user depending on user type to parent component
  return () =>
    navigation.canGoBack()
      ? navigation.goBack()
      : navigation.navigate(isDoctor ? RouteKeys.DoctorVisitScreen : RouteKeys.AssessmentScreen);
}

export function useNavigateUserToMeetingRoom() {
  const navigation = useNavigation<StackNavigationProp<ParamListBase>>();

  function navigateUserToMeetingRoom(assessmentId: string, isDoctor?: boolean) {
    const webUrl = `${ENV.WEB_URI}${isDoctor ? "Doctor/Visits" : "AMHP/Assessments"}/preview/${assessmentId}`;

    return Platform.OS === "web"
      ? navigation.navigate(RouteKeys.MeetingRoomPreviewScreen, {
          assessmentId: assessmentId,
        })
      : openLinkNative({ url: webUrl, openInNewTab: true });
  }

  return navigateUserToMeetingRoom;
}

function Participant({ name, subText }: { name: string; subText: string }) {
  return (
    <View style={{ paddingVertical: spacing[10] }}>
      <Text format={TypographyType.SmallBold}>{name}</Text>
      <Text format={TypographyType.Tiny}>{subText}</Text>
    </View>
  );
}

export function useDisplayExitMeeting(onExit?: () => void) {
  const { openDialog, closeDialog } = useDialog();
  const goBack = useGoBack();
  const [showExitMeeting, setShowExitMeeting] = useState(false);

  const exit = () => {
    setShowExitMeeting(false);
    setTimeout(() => {
      if (onExit) onExit();
      goBack();
    }, 0);
  };

  useEffect(() => {
    if (showExitMeeting) {
      openDialog({
        heading: "Leave Assessment",
        message: ["Are you sure you want to leave the assessment video and return to the S12 Solutions app?"],
        confirmButton: {
          label: "Leave Assessment",
          onPress: exit,
        },
        dismissable: true,
        onDismiss: () => setShowExitMeeting(false),
      });
    } else {
      closeDialog();
    }
  }, [showExitMeeting]);

  return {
    showExitMeeting,
    toggleExitMeeting: () => setShowExitMeeting(em => !em),
  };
}

export function useDisplayAttendeesAndNotes(
  assessmentDetails: AssessmentDetails,
  meetingDetails: ControlBarMeetingDetails
) {
  const { openBottomSheet, closeBottomSheet } = useBottomSheet();
  const [showAttendeesAndNotes, setShowAttendeesAndNotes] = useState(false);

  const AttendeesAndNotes = () => (
    <ContentWrap>
      <Text format={TypographyType.HeadingSmall} marginBottom={spacing[15]}>
        Attendees
      </Text>
      <Participant
        name={meetingDetails.userDisplayName || "Self"}
        subText={!Number(meetingDetails.userId) ? "Self" : `Self - ${meetingDetails.userId}`}
      />
      {meetingDetails.attendees.map(t => (
        <Participant
          key={t.attendeeId}
          name={t.userDisplayName || "unknown"}
          subText={t.userId ? (!Number(t.userId) && t.userId.indexOf("demodr") === -1 ? "AMHP" : t.userId) : "unknown"}
        />
      ))}
      <SectionDivider />
      <Text format={TypographyType.HeadingSmall} marginBottom={spacing[15]}>
        Details
      </Text>
      <Description label="Patient Name" style={meetingStyles.descriptionItem}>
        {assessmentDetails.patientName}
      </Description>
      {assessmentDetails.patientNhsNumber && (
        <Description label="Patient NHS Number" style={meetingStyles.descriptionItem}>
          {assessmentDetails.patientNhsNumber}
        </Description>
      )}
    </ContentWrap>
  );

  useEffect(() => {
    if (showAttendeesAndNotes) {
      openBottomSheet({
        type: "generic",
        data: {
          heading: "Assessment",
          component: AttendeesAndNotes,
          onDismiss: () => setShowAttendeesAndNotes(false),
        },
      });
    } else {
      closeBottomSheet();
    }
  }, [showAttendeesAndNotes]);

  return {
    showAttendeesAndNotes,
    toggleAttendeesAndNotes: () => setShowAttendeesAndNotes(a => !a),
  };
}
