import React, { useEffect, useMemo } from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import merge from "lodash.merge";
import gql from "graphql-tag";
import dayjs from "dayjs";
import { ExportItems, PropertiesToExport } from "./ExportItems";
import { ClaimStatus } from "libs/types/API";
import { formatClaimStatus } from "@/utils/helpers";
import { shouldConfirmAssessmentTookPlace } from "libs/utils/featureFlags";

interface PropTypes {
  username: string;
}

// TODO: kept it to a bare minimum here, query will need to expand based on properties we wanna display in csv file
export const GET_CLAIMS = gql`
  query GetClaimsByDoctor($id: ID!, $filter: ModelClaimFilterInput, $limit: Int, $nextToken: String) {
    claimsByDoctor(
      claimDoctorId: $id
      filter: $filter
      sortDirection: DESC
      limit: $limit
      nextToken: $nextToken # statusUpdatedAt: { between: [$from, $to] }
    ) {
      items {
        id
        visitDate
        patientFullName
        patientInitials
        displayId
        organisation {
          id
          name
          featureFlags
        }
        status
        assessmentTookPlace
        updatedAt
      }
      nextToken
    }
  }
`;

interface Response {
  claimsByDoctor: {
    items: {
      id: string;
      visitDate: string;
      patientFullName: string;
      patientInitials: string;
      displayId: string;
      organisation: {
        id: string;
        name: string;
        featureFlags: string;
      };
      status: ClaimStatus;
      assessmentTookPlace: boolean;
      updatedAt: string;
    }[];
    nextToken: string;
  };
}

export const ExportClaims = ({ username }: PropTypes) => {
  const [nextToken, setNextToken] = React.useState<string | null>(null);

  const listOptions = useMemo(
    () => ({
      variables: {
        id: username,
        limit: 10,
      },
      fetchPolicy: "cache-and-network",
    }),
    [username]
  );

  // using lazyQuery as we will trigger it inside the child component when switching from list to date etc
  const [getClaims, { loading, error, data, fetchMore, variables }] = useLazyQuery<Response>(GET_CLAIMS);
  const claimsByDoctor = {
    timestamp: Date.now(),
    items: data?.claimsByDoctor.items || [],
    nextToken: data?.claimsByDoctor.nextToken,
  };

  // running query on mount
  useEffect(() => {
    getClaims(listOptions);
  }, []);

  // fn to retrieve initial visit results, optional passing in dateRange object to allow user to query by date
  // will need to run fn inside child component when switching from list to date view
  const getInitialClaims = (dateRange?: { from: string; to: string }) => {
    let vars = variables;
    if (!dateRange) {
      const { filter, ...rest } = variables;
      vars = rest;
    }

    getClaims({
      variables: {
        ...vars,
        limit: dateRange ? 2000 : 10,
        ...(dateRange
          ? {
              filter: {
                visitDate: { between: [dateRange.from, dateRange.to] },
              },
            }
          : {}),
        fetchPolicy: dateRange ? "network-only" : "cache-and-network",
      },
    });
  };

  const loadMore = () => {
    if (claimsByDoctor.items?.length > 0)
      fetchMore({
        query: GET_CLAIMS,
        variables: {
          ...variables,
          nextToken: nextToken || claimsByDoctor?.nextToken,
          fetchPolicy: "network-only",
        },
        updateQuery: (previousResult: Response, { fetchMoreResult }: { fetchMoreResult: Response }) => {
          setNextToken(fetchMoreResult.claimsByDoctor.nextToken);
          const mergedClaims = merge({}, previousResult, {
            claimsByDoctor: {
              ...previousResult.claimsByDoctor,
              items: [...previousResult.claimsByDoctor.items, ...fetchMoreResult.claimsByDoctor.items],
              nextToken: fetchMoreResult.claimsByDoctor.nextToken,
            },
          });
          return mergedClaims;
        },
      });
  };

  type ResponseArrayItem = Exclude<typeof claimsByDoctor, undefined>["items"][0];

  const exportProperties: PropertiesToExport<ResponseArrayItem>[] = [
    {
      values: {
        displayItem: "visitDate",
      },
      heading: "Date of Assessment",
      callback: (args: { displayItem: string }) => dayjs(args.displayItem).format("DD/MM/YYYY HH:mm"),
    },
    {
      values: {
        displayItem: "patientInitials",
      },
      heading: "Patient Initials",
    },
    {
      values: {
        displayItem: "displayId",
      },
      heading: "Claim ID",
    },
    {
      values: {
        displayItem: "organisation",
      },
      heading: "CCG",
      callback: (args: { displayItem: { id: string; name: string; featureFlags: string } }) =>
        args.displayItem.name || "",
    },
    {
      values: {
        displayItem: "status",
      },
      heading: "Claim Status",
      callback: (args: { displayItem: ClaimStatus }) => formatClaimStatus(args.displayItem),
    },
    {
      values: {
        displayItem: "updatedAt",
      },
      heading: "Last updated",
      callback: (args: { displayItem: string }) => dayjs(args.displayItem).format("DD/MM/YYYY HH:mm"),
    },
    {
      values: {
        displayItem: "assessmentTookPlace",
        dependent: "organisation",
      },
      heading: "Assessment took place",
      callback: (args: { displayItem: boolean; dependent: { id: string; name: string; featureFlags: string } }) =>
        shouldConfirmAssessmentTookPlace(args.dependent.featureFlags) ? (args.displayItem ? "Yes" : "No") : "-",
    },
  ];

  const items = useMemo(
    () =>
      claimsByDoctor?.items.map(item => ({
        id: item.id,
        heading: item.patientFullName || item.patientInitials,
        time: item.visitDate,
        status: item.status,
      })) || [],
    [claimsByDoctor?.items]
  );

  console.log({
    nextToken,
    claimsByDoctor: claimsByDoctor?.nextToken,
  });

  return (
    <ExportItems
      type="claims"
      displayItems={items}
      propertiesToExport={exportProperties}
      queryData={claimsByDoctor}
      getItems={getInitialClaims}
      loadMore={loadMore}
      loading={loading}
      error={error}
    />
  );
};
