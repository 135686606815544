import React from "react";
import API from "@/api";
import { S12Error, getError } from "@/models/Error";
import { GPSearch } from "./GPSearch";

const getSearchResults = async (term: string, postcode: string, searchFor: string) => {
  try {
    const res = await API.get("/gpSearch", {
      params: {
        term,
        postcode,
        searchFor,
      },
    });

    return res.data;
  } catch (error) {
    throw new S12Error(getError);
  }
};

const getResultData = async (orgId: string, roleId: string) => {
  try {
    const res = await API.get("/gpSearch", {
      params: {
        orgId,
        roleId,
      },
    });

    return res.data;
  } catch (error) {
    throw new S12Error(getError);
  }
};

interface GPSearchContextProps {
  initialQuery?: string; // gp practice name
  retrieveData(data: unknown): void;
}
export default function GPSearchContext(props: GPSearchContextProps) {
  return (
    <GPSearch
      initialQuery={props.initialQuery}
      getGPData={getResultData}
      getResults={getSearchResults}
      retrieveData={props.retrieveData}
    />
  );
}
