import React from "react";

import { mqWeb } from "../../utils/helpers";
import { AuthScreenProps } from "@/models/AuthScreen";
import { Mobile, Web } from "./templates";

export const AuthScreen = (props: AuthScreenProps) => {
  const isWebView = mqWeb();
  const Template = isWebView ? Web : Mobile;

  return <Template {...props} />;
};
