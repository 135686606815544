import { Platform } from "react-native";

export function allPropsSameButNavigation<T extends { [key: string]: any }>(prev: T, next: T): boolean {
  if (!prev || !next) {
    return false;
  }

  const { navigation: prevNav, ...prevRest } = prev;
  const { navigation: nextNav, ...nextRest } = next;

  for (const key in prevRest) {
    if (prevRest[key] !== nextRest[key]) {
      return false;
    }
  }

  if (!prevNav && nextNav) {
    return false;
  }
  return true;
}

/**
 * Redirect the user after login. Will not redirect the user to a screen they
 * are not authorised to visit. Manually called from each landing component.
 * If no url parameter is provided, it will check to see if there is a
 * `redirectUrl` queryString parameter. If neither url string is provided, nor
 * a supported queryString is set, then the user will not be redirected.
 *
 * @param url Optional URL to direct the user to.
 */
export function redirectUserAfterLogin(url?: string): void {
  if (Platform.OS === "web") {
    const urlParams = new window.URLSearchParams(window.location.search);

    if (url) {
      window.location.replace(url);
    } else if (urlParams.has("redirectUrl")) {
      window.location.replace(`/${urlParams.get("redirectUrl")}`);
    }
  }
}
