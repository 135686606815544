import API from "@aws-amplify/api";
import APIV2 from "@/api";
import gql from "graphql-tag";
import { MutationFn } from "react-apollo";

import {
  CreateMHAFormInput,
  UpdateMHAFormInput,
  FormType,
  MHAFormSection,
  FormFieldsDoctor,
  FormFieldsAmhp,
  CreateMHAFormField,
  FormFieldType,
} from "libs/types/mhaForms";
import { isJSON } from "libs/mhaForms/helpers";
import dayjs, { Dayjs } from "dayjs";
import { LocationNameInput, UpdateAmhpProfileInput } from "libs/types/API";
import { MHAFormMetadata } from "./MHAForm";
import { FormikValues } from "@/models/MHAForm/MHAForm";
import { S12Error, deleteFormError } from "@/models/Error";

export async function saveForm(
  v2:boolean,
  metadata: Omit<MHAFormMetadata & { type: FormType }, "notes"> & { nextVersion: number },
  data?: FormikValues,
  isExternal?: boolean
): Promise<MHAFormMetadata | { isError: true; message: string }> {
  try {
    const formattedData = formatFormInput(metadata, data);
    const saveFormEndpoint = isExternal ? "/mhaforms/external" : "/mhaforms";
    const saveFormParams = {
      body: formattedData,
    }
    const saveFormPromise = () => v2 ? APIV2.post(saveFormEndpoint, saveFormParams) : API.post("RestAPI", saveFormEndpoint, saveFormParams);
    const response = await saveFormPromise()

    return response.data as MHAFormMetadata;
  } catch (e) {
    // returning errorMessage to check if it was a network issue. if thats the case we will save these form changes to recoil to enable "offline-mode"
    return {
      isError: true,
      message: e.toString(),
    };
  }
}

export async function deleteForm(v2: boolean, id: string) {
  try {
    const deleteEndpoint = "/mhaforms/" + id;
    const deleteParams = {};
    const deletePromise = () => v2 ? APIV2.delete(deleteEndpoint, deleteParams) : API.del("RestAPI", deleteEndpoint, deleteParams);

    const response = await deletePromise();
    return response.data as MHAFormMetadata;
  } catch (e) {
    throw new S12Error(deleteFormError);
  }
}

function formatFormInput(
  metadata: Omit<MHAFormMetadata & { type: FormType; nextVersion: number }, "notes">,
  data?: FormikValues
): CreateMHAFormInput | UpdateMHAFormInput {
  const formattedData = {
    ...metadata,
    ...(data && {
      data: {
        sections: formatFormikToStorageSections(data),
      },
    }),
  };

  // stupid typescript hack.
  if ("id" in formattedData && typeof formattedData !== "undefined") {
    return formattedData as UpdateMHAFormInput;
  }

  return formattedData as CreateMHAFormInput;
}

export function formatFormikToStorageSections(data: FormikValues): MHAFormSection[] {
  return Object.entries(data).map(([name, fields]) => ({
    name,
    fields: Object.entries(fields).map(([fieldName, fieldValue]) =>
      // object entries always converts to names being any string.
      typeof fieldValue !== 'undefined' ? fieldValueToStorageProperty((fieldName as unknown) as FormFieldsAmhp | FormFieldsDoctor, fieldValue) : null
    ).filter<CreateMHAFormField>((fields: CreateMHAFormField | null): fields is CreateMHAFormField => fields !== null),
  }));
}

function fieldValueToStorageProperty(
  fieldName: FormFieldsAmhp | FormFieldsDoctor,
  fieldValue: string | boolean | Dayjs | LocationNameInput | string[]
): CreateMHAFormField {
  const result: CreateMHAFormField = {
    type: FormFieldType.TextInput, // default type
    name: fieldName,
    value: null,
    valueArray: null,
    valueLocation: null,
  };

  if (typeof fieldValue === "string" && isJSON(fieldValue) && fieldValue.includes("hours")) {
    result.type = FormFieldType.Time;
    result.value = fieldValue;
  } else if (typeof fieldValue === "string") {
    result.value = fieldValue;
  } else if (typeof fieldValue === "boolean") {
    result.value = fieldValue ? "true" : "false";
    result.type = FormFieldType.Selection;
  } else if (dayjs.isDayjs(fieldValue)) {
    result.type = FormFieldType.Date;
    result.value = fieldValue.toISOString();
  } else if (fieldValue && "city" in fieldValue) {
    result.type = FormFieldType.Location;
    result.valueLocation = fieldValue;
  } else if (Array.isArray(fieldValue)) {
    result.type = FormFieldType.Checkbox;
    result.valueArray = fieldValue;
  }

  return result;
}

export const UPDATE_AMHP_PROFILE = gql`
  mutation UpdateAmhpProfile($input: UpdateAmhpProfileInput!) {
    updateAmhpProfile(input: $input) {
      id
    }
  }
`;

export interface AmhpReturn {
  id: string;
}

export interface UpdateInput {
  input: UpdateAmhpProfileInput;
}

export type UpdateAmhpProfileFunc = MutationFn<AmhpReturn, UpdateInput>;