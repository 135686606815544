import { useEffect } from "react";
import { useRecoilValue } from "recoil";
import Auth from "@aws-amplify/auth";

import ENV from "@/config";
import { S12Error } from "@/models/Error";
import { UpdateEndpoint } from "@/utils/analytics";
import { navigate } from "@/navigationv2/rootNavigation";
import { userDetails as recoilUserDetails, lastException as recoilLastException } from "@/utils/recoil/index";

interface Options {
  hasBooted: boolean;
}

export default function useHandleAuthenticatedUser(options: Options) {
  const { hasBooted } = options;
  const user = useRecoilValue(recoilUserDetails);
  const lastException = useRecoilValue(recoilLastException);
  const userHasGroups = Boolean(user?.groups && user?.groups.length);
  const userHasValidEula = Boolean(user?.eulaVersion && user?.eulaVersion >= ENV.EULA_VERSION);

  useEffect(() => {
    if (!hasBooted) {
      return;
    }

    if (user && !userHasValidEula) {
      UpdateEndpoint(user);
      navigate("Eula", { isAccepted: "false" });
    } else if (user && userHasGroups) {
      UpdateEndpoint(user);
    } else if (user && !userHasGroups) {
      Auth.signOut();
    } else {
      Auth.signOut();
    }
  }, [user]);

  useEffect(
    function setLastException() {
      return lastException && lastException instanceof S12Error ? lastException.save() : undefined;
    },
    [lastException]
  );
}
