import React from "react";

import { BackButtonProps } from "@/components/BackButton/BackButton.props";
import DoctorAvailability from "@/components/DoctorAvailability";
import AvailabilityProvider from "@/components/DoctorAvailability/DoctorAvailabilityProvider";
import { AppScreen } from "@/layouts/AppScreen/AppScreen";
import { ScreenOptions } from "@/navigationv2/types";
import { createScreenTitle } from "@/navigationv2/utilities";
import { color } from "@/theme";

const backButtonConfig: BackButtonProps = {
  enabled: true,
  float: true,
  color: color.textWhite,
};

const DoctorAvailabilityScreen = () => {
  return (
    <AppScreen
      contextLabel="Diary"
      pageTitle="Add Availability"
      backButton={backButtonConfig}
      testID="DoctorAvailabilityScreen"
    >
      <AvailabilityProvider>
        <DoctorAvailability />
      </AvailabilityProvider>
    </AppScreen>
  );
};

const screenOptions: ScreenOptions = {
  title: createScreenTitle("My Availability"),
};

DoctorAvailabilityScreen.screenOptions = screenOptions;

export default DoctorAvailabilityScreen;
