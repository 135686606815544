import { palette } from "./palette";
import fonts, { fontStyles } from "./typography";
import colors from "./color";
export { color, convertColor, convertBorder } from "./color";
export { palette } from "./palette";
export { spacing } from "./spacing";
export { typography, fontSizes } from "./typography";
export { timing } from "./timing";
export { fontStyles } from "./typography";

export default {
  dark: false,
  roundness: 4,
  palette,
  fonts,
  fontStyles,
  colors,
  fontSizes: {
    data: 14,
    infoBox: 14,
    heading: 20,
  },
};
