import { Device } from "amazon-chime-sdk-js";

const createDeviceLabel = (label: string, kind: string, index: number) => {
  return label && label !== ""
    ? label
    : kind === "videoinput"
    ? `camera ${index}`
    : kind === "audioinput"
    ? `microphone ${index}`
    : `speaker ${index}`;
};

const filterAndMapDevices = (devices: Device[], kind: string) => {
  return devices
    .filter(device => device.kind === kind)
    .sort((a, _b) => {
      if (a.deviceId === "default") return 1; // We want the default to appear at the top of the list at all times
      return -1;
    })
    .map((device: Device, deviceIndex: number) => {
      return {
        name: createDeviceLabel(device.label, device.kind, deviceIndex + 1),
        id: device.deviceId,
      };
    });
};

export const getAvailableInputDevices = () => {
  if (!window.navigator.mediaDevices || !window.navigator.mediaDevices.enumerateDevices) {
    throw new Error("Device enumeration is not supported on this device.");
  }
  return window.navigator.mediaDevices
    .enumerateDevices()
    .then((devices: Device[]) => {
      return {
        mic: filterAndMapDevices(devices, "audioinput"),
        cam: filterAndMapDevices(devices, "videoinput"),
        aud: filterAndMapDevices(devices, "audiooutput"),
      };
    })
    .catch((e: Error) => console.log(`Error: ${e}`));
};

export const subscribeToDeviceChange = (callback: any) => {
  window.navigator.mediaDevices.ondevicechange = () => callback;
};

export const unsubscribeToDeviceChange = () => {
  window.navigator.mediaDevices.ondevicechange = null;
};

export const destroyMediaStream = (mediaStream: MediaStream, videoRef: any) => {
  if (videoRef?.current?.srcObject && "getTracks" in videoRef.current.srcObject) {
    videoRef.current.srcObject.getTracks().forEach((track: any) => track.stop());
    videoRef.current.srcObject = null;
  }
  if (mediaStream) {
    const mediaTracks = mediaStream.getTracks();
    mediaTracks.forEach((track: MediaStreamTrack) => track.stop());
  }
};

export const getUserMediaStream = (constraints: any) => {
  return window.navigator.mediaDevices
    .getUserMedia(constraints)
    .then((mediaStream: MediaStream) => {
      return mediaStream;
    })
    .catch((e: Error) => console.log(`Error: ${e}`));
};

export const connectMediaStreamToVideoElement = (videoRef: any, mediaStream: MediaStream) => {
  const videoStream = new MediaStream(mediaStream.getVideoTracks());
  const video = videoRef.current;
  if (video) video.srcObject = videoStream;
};

export const connectMediaStreamToAudio = (
  mediaStream: MediaStream,
  unloaded: boolean,
  callback: (value: number) => void
) => {
  const audioContext: AudioContext = new (window.AudioContext || window.webkitAudioContext)(); // needs this check for ios / chrome mobile etc
  const analyser = audioContext.createAnalyser();
  const microphone = audioContext.createMediaStreamSource(mediaStream);
  const javascriptNode = audioContext.createScriptProcessor(1024, 1, 1);
  analyser.fftSize = 1024;
  analyser.smoothingTimeConstant = 0.8;
  microphone.connect(analyser);
  analyser.connect(javascriptNode);
  javascriptNode.connect(audioContext.destination);
  javascriptNode.onaudioprocess = !unloaded
    ? () => {
        const array = new Uint8Array(analyser.frequencyBinCount);
        analyser.getByteFrequencyData(array);
        let values = 0;
        const { length } = array;
        for (let i = 0; i < length; i++) {
          values += array[i];
        }
        const average = values / length;
        callback(Math.round(average) / 100);
      }
    : null;
};
