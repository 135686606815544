import * as React from "react";
import { SafeAreaView, StyleProp, StyleSheet, TextStyle, View, ViewStyle } from "react-native";

import { TypographyType } from "@/models/Typography";

import ContentWrap from "../ContentWrap/ContentWrap";
import { Logo } from "../Logo";
import Text from "../Text";
import { color, spacing } from "../../theme";

interface HeaderProps {
  heading: string;
  pageContextLabel?: string;
  children?: React.ReactNode;
  pageContextLabelStyle?: StyleProp<TextStyle>;
  headingStyle?: StyleProp<TextStyle>;
  style?: StyleProp<ViewStyle>;
}

function splitter(str: string, l: number) {
  const strs = [];
  while (str.length > l) {
    let pos = str.substring(0, l).lastIndexOf(" ");
    pos = pos <= 0 ? l : pos;
    strs.push(str.substring(0, pos));
    let i = str.indexOf(" ", pos) + 1;
    if (i < pos || i > pos + l) i = pos;
    str = str.substring(i);
  }
  strs.push(str);
  return strs;
}

const Header = (props: HeaderProps) => {
  const { pageContextLabel, heading, children } = props;

  const Heading = splitter(heading, 20).map((word: string, index: number) => (
    <Text format={TypographyType.HeadingLarge} style={[styles.heading, props.headingStyle]} key={index}>
      {word}
    </Text>
  ));

  return (
    <SafeAreaView>
      <View style={[styles.header, props.style]}>
        <View style={styles.topBar}>
          <View style={styles.logo}>
            <Logo preset="mini" />
          </View>
        </View>

        <ContentWrap>
          {pageContextLabel && (
            <Text format={TypographyType.TinyBold} style={[styles.pageContextLabel, props.pageContextLabelStyle]}>
              {pageContextLabel}
            </Text>
          )}

          <View>{Heading}</View>

          {children && <View style={styles.children}>{children}</View>}
        </ContentWrap>
      </View>
    </SafeAreaView>
  );
};

export default React.memo(Header);

const styles = StyleSheet.create({
  header: {
    width: "100%",
    position: "relative",
    paddingTop: spacing[25],
    paddingBottom: spacing[30],
    backgroundColor: color.primary,
  },

  topBar: {
    alignItems: "center",
    paddingBottom: spacing[10],
  },

  logo: {
    width: 45,
    height: 45,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: color.background,
    borderRadius: 40,
  },

  pageContextLabel: {
    color: color.primaryBorderFrosted,
    paddingBottom: spacing[5],
  },

  heading: {
    color: color.background,
    textTransform: "capitalize",
  },

  children: {
    paddingTop: spacing[15],
  },
});
