import React, { useMemo } from "react";
import { StyleSheet, View, Dimensions } from "react-native";

import { TileState, AttendeeVideoTileState } from "../MeetingRoom.types";
import { TypographyType } from "@/models/Typography";
import { mqWeb, useDeviceWidth } from "../../../utils/helpers";
import { palette, spacing } from "../../../theme";

import Icon, { IconName } from "../../Icon";
import Text from "../../Text";
import { VideoTileInputIndicator } from "./VideoTileInputIndicator";
import { VideoTileFitToggle } from "./VideoTileFitToggle";
import { Button, ButtonList } from "@/components/Button";

interface VideoTileCenterUserInfoProps {
  tileState: TileState | AttendeeVideoTileState | undefined;
  poorConnection: boolean | undefined;
  videoFit?: "contain" | "cover";
  onVideoFitToggle?: () => void;
  displayExitFullScreen?: boolean;
  onExitFullScreen?: () => void;
  hideUserInfo?: boolean;
}

export const VideoTileCenterUserInfo: React.FC<VideoTileCenterUserInfoProps> = props => {
  const isWebView = mqWeb();
  const deviceWidth = useDeviceWidth();

  const { HeadingSmall, SmallBold, Regular, Micro } = TypographyType;

  const gradientBGStyles = useMemo(() => [styles.gradientBG, { width: deviceWidth }], [deviceWidth]);

  return (
    <View style={styles.container}>
      {!props.hideUserInfo && (
        <View style={styles.nameAndMicIndicator}>
          <Text format={isWebView ? HeadingSmall : SmallBold} color={palette.white}>
            {props.tileState?.userDisplayName}
          </Text>
          <View style={styles.controls}>
            {props.videoFit && props.onVideoFitToggle && (
              <VideoTileFitToggle fit={props.videoFit} onPress={props.onVideoFitToggle} style={styles.videoFitToggle} />
            )}
            <VideoTileInputIndicator
              device="mic"
              deviceActive={!props.tileState?.isMuted}
              audioActive={props.tileState?.audioActive}
            />
          </View>
        </View>
      )}
      <View style={styles.additonalInfo}>
        {!props.hideUserInfo && (
          <Text format={isWebView ? Regular : Micro} color={palette.white} style={styles.subText}>
            {props.tileState?.userId ? (!Number(props.tileState?.userId) ? "AMHP" : props.tileState?.userId) : ""}
          </Text>
        )}
        {props.poorConnection && (
          <View style={styles.poorConnectionIcon}>
            <Icon size={14} color={palette.white} name={IconName.signal_cellular_connected_no_internet_4_bar} />
          </View>
        )}
      </View>
      {props.displayExitFullScreen && props.onExitFullScreen && (
        <ButtonList>
          <Button onPress={props.onExitFullScreen} width="100%" marginBottom={0}>
            Exit Fullscreen
          </Button>
        </ButtonList>
      )}
    </View>
  );
};

const gradient = ["rgba(30,30,30,1)", "rgba(30,30,30,0.2)", "rgba(30,30,30,0)"];
const locations = [0, 0.65, 1];
const start = { x: 0, y: 0 };
const end = { x: 0, y: 0.9 };

const styles = StyleSheet.create({
  additonalInfo: {
    flexDirection: "row",
  },

  container: {
    position: "absolute",
    left: 20,
    bottom: 20,
    width: Dimensions.get("screen").width - 40,
  },

  gradientBG: {
    position: "absolute",
    left: -20,
    bottom: -40,
    width: "100%",
    height: 200,
    zIndex: -1,
    opacity: 0.75,
    transform: [{ scaleY: -1 }],
  },

  controls: {
    marginLeft: 15,
    zIndex: 10,
    flexDirection: "row",
  },

  nameAndMicIndicator: {
    flexDirection: "row",
    alignItems: "center",
  },

  poorConnectionIcon: {
    width: 24,
    height: 24,
    borderRadius: 4,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: palette.red,
    zIndex: 2,
  },

  subText: {
    marginRight: spacing[10],
  },

  videoFitToggle: {
    marginRight: spacing[5],
  },
});
