import { StyleSheet } from "react-native";
import { spacing, palette } from "../../../theme";

const shared = {
  container: {
    backgroundColor: palette.cloud,
  },
};

const css = StyleSheet.create({
  container: {
    ...shared.container,
    borderRadius: 0,
    padding: spacing[10],
    paddingBottom: 0,
  },
  containerWeb: {
    ...shared.container,
    borderRadius: 20,
    padding: spacing[30],
    paddingBottom: spacing[20],
  },
});

export default css;
