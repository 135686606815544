import React from "react";
import { useRecoilValue } from "recoil";
import { globalBottomSheetData } from "@/utils/recoil";

import { Sheet } from "@/components/Sheet";

export default function BottomSheet() {
  const recoilData = useRecoilValue(globalBottomSheetData);

  const BottomSheetComponent = recoilData && recoilData.type === "generic" ? recoilData.data.component : React.Fragment;

  const props = React.useMemo(
    () => (recoilData && recoilData.type === "generic" ? { ...recoilData.data.componentProps } : {}),
    [recoilData]
  );

  return (
    <Sheet
      heading={recoilData?.type === "generic" ? recoilData.data.heading : ""}
      headingIconProps={recoilData?.data?.headingIconProps}
      onDismiss={recoilData?.data.onDismiss}
      wrapContent={recoilData?.type === "generic" ? recoilData.data.componentContentWrap : false}
      locationData={recoilData?.type === "locationSearch" ? recoilData.data : null}
      confirmButton={recoilData?.type === "generic" ? recoilData.data.confirmButton : undefined}
      backgroundTransparent={recoilData?.data?.backgroundTransparent}
    >
      <BottomSheetComponent {...props} />
    </Sheet>
  );
}
