import React from "react";
import { MHAEditableSelectionComponentProps } from "libs/types/mhaForms";

import Select from "../../Select";

const MHASelection = (props: MHAEditableSelectionComponentProps) => {
  const { label, value, setValue, disabled = false, options = [], error, errorText, readonly } = props;
  if (readonly) {
    return <></>;
  }
  return (
    <Select
      label={label}
      value={value}
      disabled={disabled}
      onValueChange={setValue}
      options={options}
      error={error}
      errorText={errorText}
    />
  );
};

export default MHASelection;
