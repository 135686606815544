import React from "react";
import { createStackNavigator } from "@react-navigation/stack";

import Config from "@/config";
import { AMHPAssessmentsRoutes, AMHPTabRoutes, TabNavigationProps } from "@/navigationv2/types";
import screenOptions from "@/navigationv2/config/screenOptions";
import Screens from "@/screens";
import TabIcon from "../TabIcon";

const AssessmentsStack = createStackNavigator<AMHPAssessmentsRoutes>();

interface PropTypes extends TabNavigationProps<AMHPTabRoutes, "AMHPAssessmentsTab"> {}

const AMHPAssessmentsStack = () => {
  return (
    <AssessmentsStack.Navigator initialRouteName="AssessmentScreen" screenOptions={screenOptions}>
      <AssessmentsStack.Screen
        name="AssessmentScreen"
        component={Screens.AssessmentScreen}
        options={Screens.AssessmentScreen.screenOptions}
      />
      <AssessmentsStack.Screen
        name="AssessmentDetailsScreen"
        component={Screens.AssessmentDetailsScreen}
        options={Screens.AssessmentDetailsScreen.screenOptions}
      />
      <AssessmentsStack.Screen
        name="CreateAssessmentScreen"
        component={Screens.CreateAssessmentScreen}
        options={Screens.CreateAssessmentScreen.screenOptions}
      />
      <AssessmentsStack.Screen
        name="SendToDoctorScreen"
        component={Screens.SendToDoctorScreen}
        options={Screens.SendToDoctorScreen.screenOptions}
      />
      <AssessmentsStack.Screen
        name="SearchResultsScreen"
        component={Screens.SearchResultsScreen}
        options={Screens.SearchResultsScreen.screenOptions}
      />
      <AssessmentsStack.Screen
        name="MapScreen"
        component={Screens.MapScreen}
        options={Screens.MapScreen.screenOptions}
      />
      <AssessmentsStack.Screen
        name="ProfileScreen"
        component={Screens.ProfileScreen}
        options={Screens.ProfileScreen.screenOptions}
      />
      <AssessmentsStack.Screen
        name="CreateClaimScreen"
        component={Screens.CreateClaimScreen}
        options={Screens.CreateClaimScreen.screenOptions}
      />
      <AssessmentsStack.Screen
        name="ClaimProcessScreen"
        component={Screens.ClaimProcessScreen}
        options={Screens.ClaimProcessScreen.screenOptions}
      />

      <AssessmentsStack.Screen
        name="MeetingRoomPreviewScreen"
        component={Screens.MeetingRoomPreviewScreen}
        options={Screens.MeetingRoomPreviewScreen.screenOptions}
      />
      <AssessmentsStack.Screen
        name="MeetingRoomScreen"
        component={Screens.MeetingRoomScreen}
        options={Screens.MeetingRoomScreen.screenOptions}
      />
      {Config.ENABLE_FORMS && (
        <AssessmentsStack.Screen
          name="MHAFormScreen"
          component={Screens.MHAFormScreen}
          options={Screens.MHAFormScreen.screenOptions}
        />
      )}
    </AssessmentsStack.Navigator>
  );
};

AMHPAssessmentsStack.tabOptions = {
  tabBarIcon: TabIcon("assignment-ind"),
  tabBarLabel: "Assessments",
};

export default AMHPAssessmentsStack;
