import { PathConfigMap } from "@react-navigation/native";

const basepath = "External";
const formPath = `${basepath}/Form`;

const doctorScreens: PathConfigMap = {
  ExternalDoctorNavigator: {
    screens: {
      MHAExternalFormScreen: { path: `${formPath}/:formId/:pw?` },
    },
  },
};

export default doctorScreens;
