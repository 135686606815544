import React, { useMemo } from "react";
import { StyleSheet } from "react-native";
import dayjs from "dayjs";
import { useNavigation, ParamListBase } from "@react-navigation/native";

import notFalsy from "libs/utils/notFalsy";

import { EventsList } from "@/components/EventsList";
import { Table, TableRow } from "@/components/Table";
import Text from "@/components/Text/Text";
import { AssessmentResultItem, AssessmentCategory } from "@/models/Assessment";
import { TypographyType } from "@/models/Typography";

import { palette } from "@/theme";
import { StackNavigationProp } from "@react-navigation/stack";
import { AllAMHPRoutes } from "@/navigationv2/types";

interface PropTypes {
  assessments: AssessmentResultItem[];
  category: AssessmentCategory;
  themeColor: string;
}

const AssessmentTable = (props: PropTypes) => {
  const { assessments, category, themeColor } = props;
  const navigation = useNavigation<StackNavigationProp<AllAMHPRoutes>>();
  const columnWidths = useMemo(() => createColumnWidths(category), [category]);
  const headings = useMemo(() => createHeadings(category), [category]);
  const rows = useMemo(() => createRows(assessments, category, themeColor, navigation), [
    assessments,
    category,
    navigation,
  ]);

  return <MemoTable columnWidths={columnWidths} headings={headings} rows={rows} themeColor={themeColor} />;
};

const MemoTable = React.memo(function TableMemo(props: {
  columnWidths: string[];
  headings: { text: string; icon: string }[];
  rows: TableRow[];
  themeColor: string;
}) {
  return (
    <Table
      columnWidths={props.columnWidths}
      headings={props.headings}
      rows={props.rows}
      themeColor={props.themeColor}
    />
  );
});

export default AssessmentTable;

const styles = StyleSheet.create({
  doctorVisitLabel: {
    marginBottom: 10,
  },
});

function createColumnWidths(category: AssessmentCategory) {
  return category === AssessmentCategory.MyArchive ? ["50%", "50%"] : ["40%", "30%", "30%"];
}

function createHeadings(category: AssessmentCategory) {
  // All assessment categories start with these two table columns
  const tableHeadings = [
    { text: "Patient", icon: "person-outline" },
    { text: "Assessment Date", icon: "today" },
  ];

  // Show the "Next Visit" column for user's active assessments
  if (category === AssessmentCategory.MyAssessments) {
    tableHeadings.push({ text: "Next Visit", icon: "alarm" });
  }

  // Show the "AMHP" column for team assessments (active and archived)
  if (category === AssessmentCategory.TeamAssessments || category === AssessmentCategory.TeamArchive) {
    tableHeadings.push({ text: "AMHP", icon: "person-outline" });
  }

  // No third column for user's archived assessments, i.e. "myArchive"

  return tableHeadings;
}

function createRows(
  assessments: AssessmentResultItem[],
  category: AssessmentCategory,
  themeColor: string,
  navigation: StackNavigationProp<ParamListBase>
) {
  return assessments.map(assessment => {
    const hasDoctorVisits = (assessment.doctorVisits?.items?.length || 0) > 0;

    // All assessment categories show patient name and assessment date
    const cellData = [
      assessment.patientName,
      assessment.assessmentDate ? dayjs(assessment.assessmentDate).format("DD/MM/YYYY") : "Unknown",
    ];

    // Show the "Next Visit" data for the user's active assessments
    if (category === AssessmentCategory.MyAssessments) {
      const nextVisitData = hasDoctorVisits
        ? dayjs(
            assessment.doctorVisits?.items?.filter(notFalsy).reduce((acc, curr) => (acc.time < curr.time ? acc : curr))
              .time
          ).format("HH:mm [_]DD/MM/YYYY")
        : "-";

      cellData.push(nextVisitData);
    }

    // Show the assigned AMHP for team assessments (active and archived)
    if (category === AssessmentCategory.TeamAssessments || category === AssessmentCategory.TeamArchive) {
      cellData.push(assessment.amhp.name);
    }

    // No third column for user's archived assessments, i.e. "myArchive"

    const row: TableRow = {
      items: cellData,
      onPress: () =>
        navigation.navigate("AssessmentDetailsScreen", {
          assessmentId: assessment.id,
        }),
    };

    // Removed code from below due to android crash.
    // {`${visit.doctor.name} at ${dayjs(visit.time).format("HH:mm [on] DD/MM/YYYY")} ${
    //   visit.claim ? (visit.claim.status !== "rejected" ? "(Claim created)" : "") : ""
    // }`}
    if (hasDoctorVisits) {
      const doctorVisitsListItems = assessment.doctorVisits?.items?.filter(notFalsy).map((visit, index) => (
        <Text
          key={index}
          format={TypographyType.Tiny}
          color={dayjs(visit.time).isBefore(dayjs()) ? palette.slate : palette.navy}
        >
          {`${visit.doctor.name} at ${dayjs(visit.time).format("HH:mm [on] DD/MM/YYYY")} ${
            visit.claim ? (visit.claim.status !== "rejected" ? "(Claim created)" : "") : ""
          }`}
        </Text>
      ));

      row.expandableContent = function ExpandableContent() {
        return (
          <>
            <Text format={TypographyType.Micro} color={palette.greyBlue} style={styles.doctorVisitLabel}>
              Doctor Visits:
            </Text>
            <EventsList events={doctorVisitsListItems || []} themeColor={themeColor} />
          </>
        );
      };
    }

    return row;
  });
}
