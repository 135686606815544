import React from "react";

import { ErrorMessage } from "@/components/Error/Error";
import SendToDoctor from "@/components/SendToDoctor";
import { BackButtonProps } from "@/components/BackButton/BackButton.props";
import { AppScreen } from "@/layouts/AppScreen/AppScreen";
import { color } from "@/theme";
import { RouteKeys } from "@/navigationv2";
import { AMHPAssessmentsRoutes, AMHPTabRoutes, ScreenNavigationProps, ScreenOptions } from "@/navigationv2/types";
import { createScreenTitle } from "@/navigationv2/utilities";

interface PropTypes {
  assessmentId: string;
}

const backButtonConfig: BackButtonProps = {
  enabled: true,
  float: true,
  color: color.textWhite,
};

interface PropTypes extends ScreenNavigationProps<AMHPTabRoutes, AMHPAssessmentsRoutes, RouteKeys.SendToDoctorScreen> {}

const SendToDoctorScreen = (props: PropTypes) => {
  const { route } = props;
  const { assessmentId } = route.params;

  if (!assessmentId) {
    return <ErrorMessage />;
  }

  return (
    <AppScreen backButton={backButtonConfig} contextLabel="Assessments" pageTitle={`Off-Platform ${"\n"}Doctor Form`}>
      <SendToDoctor assessmentId={assessmentId}></SendToDoctor>
    </AppScreen>
  );
};

const screenOptions: ScreenOptions = {
  title: createScreenTitle("Send to off-platform doctor"),
};

SendToDoctorScreen.screenOptions = screenOptions;

export default SendToDoctorScreen;
