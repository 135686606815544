import React, { useState, useCallback, useEffect } from "react";
import { View, StyleSheet, TouchableOpacity, Platform } from "react-native";
import { HelperText } from "react-native-paper";
import Collapsible from "react-native-collapsible";

import { TypographyType } from "@/models/Typography";
import { useBottomSheet, useIsWebView, useDialog } from "@/hooks";
import { palette, spacing } from "@/theme";

import { Button } from "@/components/Button";
import { ContentWrap } from "@/components/ContentWrap";
import { MHAFormLabel } from "../MHAFormLabel/MHAFormLabel";
import Text from "@/components/Text";
import { TextInput } from "@//components/TextInput/TextInput";
import { FormType, FormSampleText } from "libs/types/mhaForms";
import { SampleTexts, PropBasedSampleTexts } from "./SampleTexts";
import { DialogData } from "@/utils/recoil/props";

export interface BottomTextInputProps {
  label?: string;
  value?: string;
  defaultText?: string;
  buttonLabel?: string;
  error?: boolean;
  errorText?: string;
  setValue?: (value: string) => void;
  onBlur?: () => void;
  readonly?: boolean;
  formType?: FormType;
  helperText?: string; // This is the italic text taken verbatim from the paper MHA Forms
  sampleText?: FormSampleText;
  hideSampleText?: boolean;
  compact?: boolean;
}

const renderReasonDialog = (
  // This is shared by web and mobile. On web it is called from the main page. On mobile it is called after
  // navigating to a page where reason text is entered.
  showRemoveConfirm: boolean,
  setShowRemoveConfirm: (value: boolean) => void,
  openDialog: (data: DialogData) => void,
  closeDialog: () => void,
  setTextValue: ((value: string) => void) | undefined
) => {
  if (showRemoveConfirm) {
    console.log();
    openDialog({
      heading: "Confirm Delete Reasons",
      message: "Are you sure you want to delete the reasons entered? This action cannot be undone.",
      dismissable: true,
      onDismiss: () => setShowRemoveConfirm(false),
      confirmButton: {
        label: "Delete Reasons",
        isDanger: true,
        onPress: () => {
          setTextValue && setTextValue("");
          setShowRemoveConfirm(false);
        },
      },
    });
  } else {
    closeDialog();
  }
};

const MHABottomTextInput = (props: BottomTextInputProps) => {
  const { openBottomSheet } = useBottomSheet();
  const { openDialog, closeDialog } = useDialog();
  const isWeb = useIsWebView();
  const [showRemoveConfirm, setShowRemoveConfirm] = useState<boolean>(false);
  const onWebChange = useCallback((textValue: string) => props.setValue && props.setValue(textValue), [props.setValue]);
  const sampleText = props.hideSampleText
    ? null
    : props.sampleText
    ? PropBasedSampleTexts[props.sampleText]
    : props.formType
    ? SampleTexts[props.formType]
    : null;

  if (props.readonly) {
    return (
      <Text style={[styles.readonlyInput, !props.value && styles.shaded]} format={TypographyType.RegularBold}>
        {props.value || "N/A"}
      </Text>
    );
  }

  if (isWeb) {
    return (
      <View style={[styles.flex, props.compact && styles.compact]}>
        {!!props.formType && !!sampleText && (
          <BottomSheetSampleTextMemo sampleText={sampleText} setTextValue={onWebChange} />
        )}
        <TextInput
          multiline
          spellCheck
          error={props.error}
          errorText={props.errorText}
          placeholderTextColor={palette.greyBlue}
          placeholder={props.defaultText}
          label={props.label || ""}
          value={props.value}
          onBlur={props.onBlur}
          onChangeText={onWebChange}
          numberOfLines={props.compact ? 5 : 25}
          style={styles.webBottomTextBox}
          stretchLines
        />
      </View>
    );
  }

  useEffect(() => {
    renderReasonDialog(showRemoveConfirm, setShowRemoveConfirm, openDialog, closeDialog, props.setValue);
  }, [showRemoveConfirm]);

  return (
    <ContentWrap>
      <View style={styles.wrapper}>
        <Text format={TypographyType.Regular}>{props.value || props.defaultText}</Text>
        <View style={styles.buttonRow}>
          {!props.value ? (
            <Text
              format={TypographyType.SmallBold}
              color={palette.blue}
              onPress={() => {
                openBottomSheet({
                  type: "generic",
                  data: {
                    heading: props.label || "",
                    component: BottomSheetInput,
                    componentProps: { ...props },
                  },
                });
              }}
            >
              {`Add ${props.buttonLabel || ""}`}
            </Text>
          ) : (
            <>
              <Text
                format={TypographyType.SmallBold}
                color={palette.blue}
                style={styles.editButton}
                onPress={() => {
                  openBottomSheet({
                    type: "generic",
                    data: {
                      heading: props.label || "",
                      component: BottomSheetInput,
                      componentProps: { ...props },
                    },
                  });
                }}
              >
                {`Edit ${props.buttonLabel || ""}`}
              </Text>
              <Text
                format={TypographyType.SmallBold}
                color={palette.blue}
                style={styles.removeButton}
                onPress={() => setShowRemoveConfirm(true)}
              >
                {`Delete ${props.buttonLabel || ""}`}
              </Text>
            </>
          )}
        </View>

        <HelperText style={styles.error} type="error" visible={props.error}>
          {props.errorText || ""}
        </HelperText>
      </View>
    </ContentWrap>
  );
};

export default MHABottomTextInput;

function BottomSheetInput(props: BottomTextInputProps) {
  const [textValue, setTextValue] = useState(props.value || "");
  const { closeBottomSheet } = useBottomSheet();
  const sampleText = props.hideSampleText
    ? null
    : props.sampleText
    ? PropBasedSampleTexts[props.sampleText]
    : props.formType
    ? SampleTexts[props.formType]
    : null;

  return (
    <View style={styles.flex}>
      {/* In forms such as A4, A8, A11 you will see a popup containing reasons. The line below contains the info text that the user sees */}
      {!!props.helperText && <MHAFormLabel guidance={props.helperText} formType={props.formType} />}
      <ContentWrap style={styles.form}>
        <View style={styles.flex}>
          {!!props.formType && !!sampleText && (
            <BottomSheetSampleTextMemo sampleText={sampleText} setTextValue={setTextValue} />
          )}
          <TextInput
            multiline
            spellCheck
            label={props.label || ""}
            value={textValue}
            onBlur={props.onBlur}
            onChangeText={setTextValue}
            numberOfLines={15}
            scrollEnabled={false}
            stretchLines
          />
        </View>
        <View>
          <Button
            onPress={() => {
              props.setValue && props.setValue(textValue);
              closeBottomSheet();
            }}
            disabled={textValue.trim() === ""}
            width="100%"
          >
            Add to Form
          </Button>
          <Text
            format={TypographyType.TinyBold}
            color={palette.blue}
            onPress={() => closeBottomSheet()}
            style={styles.centeredText}
          >
            {`Cancel ${props.buttonLabel || ""}`}
          </Text>
        </View>
      </ContentWrap>
    </View>
  );
}

const BottomSheetSampleTextMemo = React.memo(function BottomSheetSampleText(props: {
  sampleText: string;
  setTextValue: (value: string) => void;
}) {
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const [showRemoveConfirm, setShowRemoveConfirm] = useState<boolean>(false);
  const { openDialog, closeDialog } = useDialog();
  const isWeb = useIsWebView();
  useEffect(() => {
    renderReasonDialog(showRemoveConfirm, setShowRemoveConfirm, openDialog, closeDialog, props.setTextValue);
  }, [showRemoveConfirm]);
  return (
    <View style={styles.sampleTextContainer}>
      <Collapsible collapsed={collapsed}>
        <View style={styles.sampleTextCollapsibleContent}>
          <Text format={TypographyType.RegularBold} style={styles.sampleTextHeading}>
            Reasons Template
          </Text>
          <Text format={TypographyType.Small} color={palette.slate} selectable>
            {props.sampleText}
          </Text>
        </View>
      </Collapsible>
      <View style={styles.webBottomTextButtons}>
        <TouchableOpacity onPress={() => setCollapsed(!collapsed)}>
          <Text format={TypographyType.SmallBold} color={palette.blue}>
            {collapsed ? "Show Reasons Template" : "Hide Reasons Template"}
          </Text>
        </TouchableOpacity>
        {/* This section shows up for both web and mobile. */}
        {/*  However, mobile has a different mechanism for removing the reason text . */}
        {/* Consequently, this button is only required on the web view */}
        {isWeb && (
          <TouchableOpacity onPress={() => setShowRemoveConfirm(true)}>
            <Text format={TypographyType.SmallBold} color={palette.blue}>
              Delete Your Reasons
            </Text>
          </TouchableOpacity>
        )}
      </View>
    </View>
  );
});

const styles = StyleSheet.create({
  flex: { flex: 1 },

  form: {
    marginTop: spacing[10],
  },

  buttonRow: { flexDirection: "row", marginTop: spacing[15] },

  editButton: {
    borderRightColor: palette.grey,
    borderRightWidth: 1,
    paddingRight: spacing[15],
  },

  removeButton: { paddingLeft: spacing[15] },

  error: { color: palette.red, minHeight: 23, padding: 0 },

  wrapper: { marginVertical: spacing[10] },

  centeredText: { textAlign: "center" },

  readonlyInput: {
    backgroundColor: "white",
    paddingHorizontal: spacing[20],
    paddingVertical: spacing[15],
    ...Platform.select({ web: { wordBreak: "break-word" } }),
  },
  shaded: {
    backgroundColor: palette.cloud,
  },
  sampleTextContainer: {
    paddingVertical: spacing[20],
  },
  sampleTextCollapsibleContent: {
    paddingBottom: spacing[20],
    ...Platform.select({
      web: {
        userSelect: "text",
      },
    }),
  },
  sampleTextHeading: {
    paddingBottom: spacing[15],
  },
  webBottomTextBox: {
    borderColor: palette.cloud,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    borderTopWidth: 2,
    borderLeftWidth: 2,
    borderRightWidth: 2,
  },
  webBottomTextButtons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  compact: {
    marginBottom: 10,
  },
});
