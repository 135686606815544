import { PathConfigMap } from "@react-navigation/native";

const supportPath = "Support";

const sharedScreens: PathConfigMap = {
  SupportTab: {
    screens: {
      SupportScreen: { path: supportPath },
      PrivacyPolicyScreen: { path: `${supportPath}/privacypolicy` },
      ChangePasswordScreen: { path: `${supportPath}/changepassword` },
      EulaScreen: { path: `${supportPath}/eula` },
      ClaimProcessScreen: { path: `${supportPath}/claimprocess` },
      SupportExplainedScreen: { path: `${supportPath}/explained` },
      AppUpdateScreen: { path: `${supportPath}/update` },
    },
  },
};

export default sharedScreens;
