import React, { useState, useCallback, useMemo } from "react";
import { RefreshControl } from "react-native";
import { useRecoilValue } from "recoil";

import { formatLongDate } from "libs/dates";

import DoctorVisitResults from "@/components/DoctorVisitResults";
import { useOnScreenFocus } from "@/hooks";
import { AppScreen } from "@/layouts/AppScreen/AppScreen";
import { ScreenOptions } from "@/navigationv2/types";
import { createScreenTitle } from "@/navigationv2/utilities";
import { userDetails } from "@/utils/recoil/index";

export default function DoctorVisitScreen() {
  const user = useRecoilValue(userDetails);
  const [refreshRequired, setRefreshRequired] = useState(false);
  const longDate = useMemo(() => formatLongDate(), []);

  const onRefresh = useCallback(() => {
    setRefreshRequired(true);
  }, []);

  useOnScreenFocus(() => {
    setRefreshRequired(true);
  });

  return (
    <AppScreen
      contextLabel={longDate}
      pageTitle="Visits"
      refreshControl={<RefreshControl refreshing={refreshRequired} onRefresh={onRefresh} />}
      testID="DoctorVisitsScreen"
      greyWebBG={true}
    >
      <DoctorVisitResults
        username={user ? user.id : ""}
        refreshRequired={refreshRequired}
        onRefreshComplete={setRefreshRequired}
      />
    </AppScreen>
  );
}

const screenOptions: ScreenOptions = {
  title: createScreenTitle("My Visits"),
};

DoctorVisitScreen.screenOptions = screenOptions;
