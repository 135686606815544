import { ChangeEvent } from "react";
import { TextInput } from "react-native";

export interface Event extends ChangeEvent<HTMLInputElement> {
  keyCode: number;
  key: string;
  preventDefault: () => void;
  currentTarget: {
    value: string;
  };
}

export type Ref = { current: TextInput | null };

export function supportsDateInput(): boolean {
  const input = window.document.createElement("input");
  const notADateValue = "not-a-date";

  input.setAttribute("type", "date");
  input.setAttribute("value", notADateValue);

  return input.value !== notADateValue;
}
