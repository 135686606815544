import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";

import { userDetails } from "@/utils/recoil";

export default function useIsAuthenticated() {
  const [isAuthenticated, setAuthenticated] = useState(false);
  const user = useRecoilValue(userDetails);

  useEffect(() => {
    setAuthenticated(Boolean(user));
  }, [user]);

  return isAuthenticated;
}
