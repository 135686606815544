import React, { useState, useEffect, useRef } from "react";
import { StyleSheet, View, Dimensions } from "react-native";
import { LocationInput } from "libs/types/API";
import { AvailabilityType, AvailabilityState } from "libs/types/availability";
// @ts-ignore
import ReactMapGL, { Marker, FlyToInterpolator } from "react-map-gl";
import WebMercatorViewport from "viewport-mercator-project";
import { color } from "@/theme";
import { MapIcon } from "../icons/MapIcon";
import Icon, { IconName } from "@/components/Icon";

export default function Map(props: {
  location: LocationInput;
  marker: LocationInput;
  type?: AvailabilityType | AvailabilityState;
}) {
  const { location, marker, type } = props;
  // TODO: fix height issue
  const [viewport, setViewport] = useState({
    longitude: location.lon,
    latitude: location.lat,
    width: Dimensions.get("screen").width,
    height: Dimensions.get("screen").height - 54,
    zoom: 14,
    transitionDuration: 300,
    transitionInterpolator: new FlyToInterpolator(),
    maxZoom: 16,
  });

  useEffect(() => {
    const handleResize = () => {
      setViewport({
        ...viewport,
        width: Dimensions.get("window").width,
        height: Dimensions.get("window").height - 54,
      });
    };
    Dimensions.addEventListener("change", handleResize);
    return () => Dimensions.removeEventListener("change", handleResize);
  });

  const refMap = useRef<ReactMapGL>(null);
  refMap.current &&
    refMap.current.getMap().on("load", () => {
      const nec: LocationInput = { lon: location.lon, lat: location.lat };
      const swc: LocationInput = { lon: location.lon, lat: location.lat };
      [location, marker].forEach(m => {
        if (m.lon > nec.lon) {
          nec.lon = m.lon;
        }
        if (m.lat > nec.lat) {
          nec.lat = m.lat;
        }
        if (m.lon < swc.lon) {
          swc.lon = m.lon;
        }
        if (m.lat < swc.lat) {
          swc.lat = m.lat;
        }
      });
      const newViewport = new WebMercatorViewport(viewport);
      const { longitude, latitude, zoom } = newViewport.fitBounds(
        [
          [nec.lon, nec.lat],
          [swc.lon, swc.lat],
        ],
        { padding: 200 }
      );
      setViewport({
        ...viewport,
        longitude,
        latitude,
        zoom,
      });
    });

  return (
    <View style={styles.container}>
      <ReactMapGL
        ref={refMap}
        mapStyle="https://api.maptiler.com/maps/streets/style.json?key=omwfDcyrASd6Qybt7Hk2"
        onViewportChange={newViewport => setViewport(newViewport)}
        {...viewport}
      >
        {location && (
          <Marker latitude={location.lat} longitude={location.lon}>
            <Icon name={IconName.location_on} size={48} color={color.primary} />
          </Marker>
        )}
        {marker && (
          <Marker latitude={marker.lat} longitude={marker.lon}>
            <MapIcon size={48} primaryColor={type ? color[type] : color.unavailable} />
          </Marker>
        )}
      </ReactMapGL>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: "relative",
  },
  circle: {
    width: 24,
    height: 24,
    backgroundColor: color.primary,
    borderRadius: 12,
    borderWidth: 3,
    borderColor: color.background,
  },
});
