import { SortedAssessments, AssessmentResultItem } from "@/models/Assessment";
import { AssessmentDateInfo } from "@/models/UserProfile";
import dayjs from "dayjs";
import notFalsy from "libs/utils/notFalsy";

// sorts assessments from oldest to newest
export function sortAssessments(assessments: AssessmentResultItem[]) {
  return assessments.concat().sort(sortByDoctorVisitsTime);
}

export function sortUserAssessments<T extends AssessmentDateInfo>(
  assessments: AssessmentDateInfo[]
): SortedAssessments<T> {
  const archived: T[] = [];
  const active: T[] = [];
  const today = dayjs().format("YYYY-MM-DD");

  // sort assessments by assessmentDate, with most recent first
  // concat used to avoid mutating original array
  const orderedRefs = assessments.concat().sort(sortByDoctorVisitsTime) as T[];

  // peel off archived and active into relevant arrays
  orderedRefs.forEach(assessment => {
    isAssessmentActive(assessment, today) ? active.push(assessment) : archived.push(assessment);
  });

  // return sorted user assessments
  return {
    active,
    archived,
    count: {
      total: orderedRefs.length,
      active: active.length,
      archived: archived.length,
    },
  };
}

export const isAssessmentActive = (a: AssessmentDateInfo, dateTime: string) =>
  a.doctorVisits && a.doctorVisits.items && a.doctorVisits.items.length
    ? a.doctorVisits.items.filter(v => v && v.time > dateTime).length > 0
    : a.assessmentDate
    ? a.assessmentDate >= dateTime
    : false;

// Gets the next doctor visit, if it exists,
// or get the last visit in time, if it exists,
// or return the assessment date, if exists,
// or return now
export const earliestFutureOrLastAssessementVisitTime = (a: AssessmentDateInfo) => {
  const today = dayjs().format("YYYY-MM-DD");
  const hasVisits = a.doctorVisits && a.doctorVisits.items && a.doctorVisits.items.length;
  const futureVisits = hasVisits && a.doctorVisits?.items?.filter(notFalsy).filter(x => x.time > today);

  if (futureVisits && futureVisits.length) {
    return futureVisits.reduce((acc, curr) => (acc.time < curr.time ? acc : curr)).time;
  } else if (hasVisits) {
    return a.doctorVisits?.items?.filter(notFalsy).reduce((acc, curr) => (acc.time > curr.time ? acc : curr)).time;
  } else {
    return a.assessmentDate || today;
  }
};

export const earliestAssessmentVisitTime = (a: AssessmentDateInfo) =>
  a.doctorVisits?.items?.filter(notFalsy).reduce((acc, curr) => (acc.time < curr.time ? acc : curr)).time ||
  a.assessmentDate ||
  new Date().toISOString();

export const eariestFutureAssessmentVisitTime = (a: AssessmentDateInfo) => {
  const today = dayjs().format("YYYY-MM-DD");
  const futureVisitTime =
    a.doctorVisits?.items
      ?.filter(notFalsy)
      .reduce((acc, curr) => (curr.time > today && (acc || { time: "999" }).time > curr.time ? curr : acc), null) ||
    null;

  return (futureVisitTime && futureVisitTime.time) || a.assessmentDate || "0";
};

const sortByDoctorVisitsTime = (a: AssessmentDateInfo, b: AssessmentDateInfo) => {
  const minA = eariestFutureAssessmentVisitTime(a) || "0";
  const minB = eariestFutureAssessmentVisitTime(b) || "0";

  return minA.localeCompare(minB);
};
