import { Device } from "amazon-chime-sdk-js";
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { View, StyleSheet, Platform } from "react-native";

import { TypographyType } from "@/models/Typography";
import { spacing, palette } from "../../theme";
import { mqWeb, useDeviceHeight, useDeviceOrientation } from "../../utils/helpers";
import { MeetingPrefs } from "../../utils/recoil/props";
import { load, save } from "../../utils/storage";
import { Button } from "../Button";
import Select from "../Select";
import Text from "../Text";
import { AudioInputToggle } from "./components/AudioInputToggle";
import { ContentWrap } from "../ContentWrap";
import { useRecoilState } from "recoil";
import { meetingPrefs as recoilMeetingPrefs } from "../../utils/recoil/index";
import { DeviceVideo } from "./components/AudioVideo";
import {
  connectMediaStreamToAudio,
  connectMediaStreamToVideoElement,
  destroyMediaStream,
  getAvailableInputDevices,
  getUserMediaStream,
  subscribeToDeviceChange,
  unsubscribeToDeviceChange,
} from "./userMedia";
import { IconName } from "../Icon";

const inputQualityDropdown = [
  { name: "High", id: "720p" },
  { name: "Medium", id: "540p" },
  { name: "Low", id: "360p" },
];

const setVideoQuality = (quality: { value: string; label: string }, orientation: "landscape" | "portrait") => {
  if (quality.value === "720p") {
    return {
      width: orientation === "landscape" ? 1280 : 720,
      height: orientation === "landscape" ? 720 : 1280,
      frameRate: 15,
      maxBandwidthKbps: 1400,
      value: quality.value,
    };
  }
  if (quality.value === "540p") {
    return {
      width: orientation === "landscape" ? 960 : 540,
      height: orientation === "landscape" ? 540 : 960,
      frameRate: 15,
      maxBandwidthKbps: 1400,
      value: quality.value,
    };
  }
  if (quality.value === "360p") {
    return {
      width: orientation === "landscape" ? 640 : 360,
      height: orientation === "landscape" ? 360 : 640,
      frameRate: 15,
      maxBandwidthKbps: 600,
      value: quality.value,
    };
  } else {
    return {
      width: orientation === "landscape" ? 640 : 360,
      height: orientation === "landscape" ? 360 : 640,
      frameRate: 15,
      maxBandwidthKbps: 600,
      value: quality.value,
    };
  }
};

const saveMeetingPrefs = (
  setMeetingPrefs: Dispatch<SetStateAction<MeetingPrefs>>,
  meetingPrefs: MeetingPrefs,
  inputQualityDropdown: any,
  inputDevices: {
    mic: Device[];
    cam: Device[];
    aud: Device[];
  } | null,
  orientation: "landscape" | "portrait"
) => {
  return setMeetingPrefs({
    audio: meetingPrefs.audio || null,
    microphone: meetingPrefs.microphone || null,
    video: meetingPrefs.video ? meetingPrefs.video : inputDevices?.cam ? inputDevices?.cam[0].deviceId : null, // Can't set default as doesn't exist
    quality: meetingPrefs.quality || setVideoQuality(inputQualityDropdown[0], orientation),
  });
};

export const DeviceSelection = (props: { mode: "windowed" | "self"; onPress?: () => void; previewMode?: boolean }) => {
  const [meetingPrefs, setMeetingPrefs] = useRecoilState(recoilMeetingPrefs);
  const selfVideo = useRef<HtmlMediaElement>(null);
  const [inputDevices, setInputDevices] = useState<{
    mic: Device[];
    cam: Device[];
    aud: Device[];
  } | null>(null);

  const { onPress, mode } = props;
  const [audioVolume, setAudioVolume] = useState(0);
  const [permissionsDenied, setPermissionsDenied] = useState(false);
  const deviceHeight = useDeviceHeight();
  const deviceWidth = useDeviceHeight();
  const orientation = useDeviceOrientation();

  useEffect(() => {
    let isUnloaded = false;

    if (mode === "self") {
      const loadMeetingPrefsFromStorage = async () => {
        const asyncMeetingPrefs = (await load("meetingPrefs")) as MeetingPrefs;
        if (asyncMeetingPrefs && !meetingPrefs.video) {
          setMeetingPrefs(asyncMeetingPrefs);
        }
      };
      loadMeetingPrefsFromStorage();
    }

    getAvailableInputDevices()
      .then((availableDevices: any) => {
        if (inputDevices !== availableDevices && !isUnloaded) {
          setInputDevices(availableDevices);
        }
      })
      .catch((e: Error) => console.log(`Error: ${e}`));

    subscribeToDeviceChange(() => {
      getAvailableInputDevices()
        .then((availableDevices: any) => {
          if (availableDevices && inputDevices !== availableDevices && isUnloaded) {
            setInputDevices(availableDevices);
          }
        })
        .catch((e: Error) => console.log(`Error: ${e}`));
    });

    return () => {
      isUnloaded = true;
      unsubscribeToDeviceChange();
    };
  }, []);

  useEffect(() => {
    let isUnloaded = false;

    save("meetingPrefs", meetingPrefs);
    const constraints = {
      audio: {
        sampleSize: 1,
        echoCancellation: true,
        ...(meetingPrefs.microphone?.value && {
          deviceId: meetingPrefs.microphone.value,
        }),
      },
      video: {
        width: meetingPrefs.quality?.width || 1280,
        height: meetingPrefs.quality?.height || 720,
        frameRate: { ideal: 15, max: 20 },
        ...(meetingPrefs.video?.value
          ? {
              deviceId: meetingPrefs.video.value,
            }
          : { deviceId: inputDevices?.cam[0].id }),
      },
    };

    const mediaStreamCleanupPromise = getUserMediaStream(constraints)
      .then((mediaStream: MediaStream | null) => {
        if (mediaStream) {
          connectMediaStreamToVideoElement(selfVideo, mediaStream);
          connectMediaStreamToAudio(mediaStream, isUnloaded, (volume: number) => {
            if (!isUnloaded) {
              setAudioVolume(volume);
            }
          });
          return () => destroyMediaStream(mediaStream, selfVideo);
        }
        return () => {
          console.log("empty destroy function"); // Here until we add the native bits
        };
      })
      .catch((error: Error) => {
        if (!isUnloaded && error.name === "NotAllowedError") {
          setPermissionsDenied(true);
        }
        console.log(`Error: ${error}`);
      });

    return () => {
      isUnloaded = true;
      if (mediaStreamCleanupPromise) {
        mediaStreamCleanupPromise.then((destroyFunction: () => void) => destroyFunction());
      }
    };
  }, [meetingPrefs]);

  const isWeb = mqWeb();

  return permissionsDenied ? (
    <ContentWrap>
      <Text format={TypographyType.RegularBold}>
        Access to your camera and microphone has been blocked, you will need to change this before proceeding to video.
      </Text>
    </ContentWrap>
  ) : (
    <>
      <View>
        <ContentWrap style={[styles.previewContainer, isWeb && styles.previewContainerWeb]}>
          <DeviceVideo
            {...(Platform.OS === "web" && { ref: selfVideo, controls: false })}
            style={{
              objectFit: "contain",
              maxHeight: deviceHeight * 0.5,
            }}
          />

          <View style={styles.previewTileMicIcon}>
            <AudioInputToggle
              icon={IconName.mic}
              disabled={true}
              active={true}
              audioLevel={audioVolume}
              previewMode={true}
              onPress={() =>
                setMeetingPrefs(prevMp => ({
                  ...prevMp,
                  microphone: prevMp.microphone ? null : inputDevices?.mic[0] || [],
                }))
              }
            />
          </View>
        </ContentWrap>
        <ContentWrap style={[styles.deviceSelectContent, isWeb && styles.deviceSelectContentWeb]}>
          <View style={[styles.deviceSelectInputs, isWeb && styles.deviceSelectInputsWeb]}>
            <Text format={TypographyType.HeadingSmall} marginBottom={spacing[30]}>
              Select devices
            </Text>
            <Select
              label="Microphone"
              value={meetingPrefs.microphone?.value || ""}
              onValueChange={(_v: string, i: number, d: { value: string; label: string }[]) =>
                setMeetingPrefs({
                  ...meetingPrefs,
                  microphone: d[i],
                })
              }
              options={inputDevices?.mic || []}
              icon="mic"
            />
            <Select
              label="Camera"
              value={meetingPrefs.video?.value || ""}
              onValueChange={(_v: string, i: number, d: { value: string; label: string }[]) =>
                setMeetingPrefs({
                  ...meetingPrefs,
                  video: d[i],
                })
              }
              options={inputDevices?.cam || []}
              icon="videocam"
            />
            <Select
              label="Video Input Quality"
              value={meetingPrefs.quality?.value || inputQualityDropdown[0].id}
              onValueChange={(_v: string, i: number, d: { value: string; label: string }[]) => {
                setMeetingPrefs({
                  ...meetingPrefs,
                  quality: setVideoQuality(d[i], orientation),
                });
              }}
              options={inputQualityDropdown}
              icon="speaker"
            />
          </View>
          {props.previewMode && (
            <View style={[styles.previewModeText, isWeb && styles.previewModeTextWeb]}>
              <Text format={TypographyType.Regular} style={{ marginBottom: spacing[30] }}>
                You’re about to join a video meeting for an assessment. Please check your camera, microphone and speaker
                inputs before joining.
              </Text>
              {onPress && (
                <Button
                  onPress={() => {
                    saveMeetingPrefs(setMeetingPrefs, meetingPrefs, inputQualityDropdown, inputDevices, orientation);
                    onPress();
                  }}
                  style={[styles.joinMeetingBtn, isWeb && styles.joinMeetingBtnWeb]}
                >
                  Join Meeting
                </Button>
              )}
            </View>
          )}
        </ContentWrap>
      </View>
      <View
        style={{
          ...(!isWeb
            ? {
                maxHeight: 500,
                height: deviceWidth * 0.9,
                marginBottom: spacing[30],
              }
            : { flex: 1, marginLeft: spacing[20] }),
          transform: [
            {
              scaleX: -1,
            },
          ],
        }}
      ></View>
    </>
  );
};

const styles = StyleSheet.create({
  deviceSelectContent: {
    flexDirection: "column",
  },
  deviceSelectContentWeb: {
    flexDirection: "row",
  },
  deviceSelectInputs: {},
  deviceSelectInputsWeb: {
    flexBasis: "50%",
    paddingRight: spacing[25],
  },

  joinMeetingBtn: {
    marginTop: spacing[10],
  },
  joinMeetingBtnWeb: {
    alignSelf: "flex-start",
  },

  previewModeText: {
    flexBasis: "50%",
    marginTop: spacing[10],
  },
  previewModeTextWeb: {
    marginTop: 0,
    paddingLeft: spacing[25],
  },

  previewContainer: {
    transform: [{ scaleX: -1 }],
    marginTop: spacing[50] * -1,
    marginBottom: spacing[30],
    paddingVertical: spacing[30],
    backgroundColor: palette.shadowDark,
  },
  previewContainerWeb: {
    marginTop: 0,
    borderRadius: 12,
    marginBottom: spacing[50],
  },

  previewTileMicIcon: {
    position: "absolute",
    left: 0,
    bottom: 10,
  },
});
