/* eslint-disable complexity */

/**
 * Claims Processing - Table claim line
 * Renders an individual record into the claims table, taking into account what the user can see.
 */
import React, { useContext } from "react";
import { Platform, StyleSheet, View } from "react-native";
import { Divider, TouchableRipple } from "react-native-paper";
import { useNavigation } from "@react-navigation/native";
import { AllCCGRoutes } from "@/navigationv2/types";
import { StackNavigationProp } from "@react-navigation/stack";

import { TypographyType } from "@/models/Typography";
import { color, spacing } from "../../theme";
import { formatShortDate } from "libs/dates";
import { mqWeb } from "../../utils/helpers";
import { getApprovedDate, getPostcodeFromLocation, retrieveStatusText } from "../ClaimProcessing/helpers";

import { Checkbox } from "../Checkbox/Checkbox";
import { ClaimProcessingDetailsScreenProps, doctorConfText } from "../ClaimProcessing/ClaimProcessing.props";
import ContentWrap from "../ContentWrap/ContentWrap";
import { Description } from "../Description/Description";
import Text from "../Text";

import { RouteKeys } from "@/navigationv2";
import { ClaimStatus, DoctorEmployedStatusValues } from "libs/types/API";
import { ClaimProcessingContext } from "../ClaimProcessing/ClaimProcessingProvider";
import { ClaimUiActionType } from "../ClaimProcessing/ClaimProcessingState";
import { CCGClaim } from "libs/types/claim";

const ClaimProcessingCard = (props: { claim: CCGClaim; type: string; userId: string | undefined }) => {
  const { claim, type, userId } = props;
  const navigation = useNavigation<StackNavigationProp<AllCCGRoutes>>();
  const { state, dispatch } = useContext(ClaimProcessingContext);
  const { selectedClaims, onPressAction, fieldList } = state;
  const isSelected = selectedClaims.includes(claim.id);

  const selectedClaimsAction = () => {
    const items = isSelected ? selectedClaims.filter((c: string) => c !== claim.id) : [...selectedClaims, claim.id];
    dispatch({
      type: ClaimUiActionType.SET_SELECTED_CLAIMS,
      payload: items,
    });
  };

  const renderCheckbox = () => {
    return (
      <Checkbox
        status={isSelected}
        onPress={() => {
          if (Platform.OS === "web" || onPressAction === "setselectedclaims") selectedClaimsAction();
        }}
      />
    );
  };

  const isWeb = mqWeb();

  return (
    <View key={claim.id}>
      <TouchableRipple
        style={[isSelected && styles.activeRow, !isSelected && selectedClaims.length > 0 && styles.inactiveRow]}
        onPress={() => {
          if (onPressAction === "setselectedclaims") {
            selectedClaimsAction();
          } else {
            dispatch({
              type: ClaimUiActionType.RESET_ON_PRESS_AND_SELECTED_CLAIMS,
            });
            navigation.push(RouteKeys.ClaimProcessingDetailsScreen, {
              claimId: claim.id,
            } as ClaimProcessingDetailsScreenProps);
          }
        }}
      >
        <ContentWrap style={styles.row}>
          {isWeb && renderCheckbox()}
          <View
            style={[
              isWeb && styles.row,
              styles.flex,
              {
                paddingVertical: spacing[30],
              },
            ]}
          >
            <View style={[styles.row, styles.flex]}>
              {fieldList.includes("claimid") && (
                <Text format={isWeb ? TypographyType.Small : TypographyType.RegularBold} style={styles.flex}>
                  {claim.displayId}
                </Text>
              )}
              {!isWeb && onPressAction === "setselectedclaims" && (
                <View style={styles.checkboxContainer}>{renderCheckbox()}</View>
              )}
            </View>
            {isWeb
              ? fieldList.includes("doctor") && (
                  <View style={[styles.row, styles.flex]}>
                    <Text format={isWeb ? TypographyType.Small : TypographyType.RegularBold} style={styles.flex}>
                      {claim.doctor.name}
                    </Text>
                  </View>
                )
              : fieldList.includes("doctor") && (
                  <Text format={TypographyType.Micro} style={styles.flex}>
                    Doctor: <Text format={TypographyType.MicroBold}>{claim.doctor.name}</Text>
                  </Text>
                )}
            {isWeb ? (
              <>
                {/* If independent doctor, line manager and MHT are not specified  */}
                {fieldList.includes("defaultMHT") && (
                  <Text format={TypographyType.Small} style={styles.flex}>
                    {claim.isIndependentDoctor ? "N/A" : claim.defaultMHT ? claim.defaultMHT : "Not Specified"}
                  </Text>
                )}
                {fieldList.includes("lineManager") && (
                  <Text format={TypographyType.Small} style={styles.flex}>
                    {claim.isIndependentDoctor ? "N/A" : claim.lineManager ? claim.lineManager : "Not Specified"}
                  </Text>
                )}
                {fieldList.includes("ccg") && (
                  <Text format={TypographyType.Small} style={styles.flex}>
                    {claim.organisation.name}
                  </Text>
                )}
                {fieldList.includes("status") && (
                  <Text format={TypographyType.Small} style={styles.flex}>
                    {retrieveStatusText(claim.status, claim.assigneeId, userId)}
                  </Text>
                )}
                {fieldList.includes("amhpteamname") && (
                  <Text format={TypographyType.Small} style={styles.flex}>
                    {claim.amhpTeamId}
                  </Text>
                )}
                {fieldList.includes("employer") && (
                  <Text format={TypographyType.Small} style={styles.flex}>
                    {claim.doctor
                      ? `${claim.doctor.mhtEmployers?.items
                          ?.map(i => i.mht.abbreviation)
                          .join(" ")
                          .trim() || (claim.doctor.employer ? claim.doctor.employer : "Not supplied")}`
                      : claim.isIndependentDoctor === true
                      ? "Independent"
                      : "Not supplied"}
                  </Text>
                )}
                {fieldList.includes("distance") && (
                  <Text format={TypographyType.Small} style={styles.flex}>
                    {claim.mileage ? `${claim.mileage} miles` : "N/A"}
                  </Text>
                )}
                {fieldList.includes("postcode") && (
                  <Text format={TypographyType.Small} style={styles.flex}>
                    {getPostcodeFromLocation({ locationType: claim.locationType, locationText: claim.locationText })}
                  </Text>
                )}
                {fieldList.includes("expenses") && (
                  <Text format={TypographyType.Small} style={styles.flex}>
                    {claim.additionalExpenses &&
                      (claim.additionalExpenses !== "0.00" ? `£${claim.additionalExpenses}` : "-")}
                  </Text>
                )}
                {fieldList.includes("doctorAdditionalConf") && (
                  <Text format={TypographyType.Small} style={styles.flex}>
                    {claim.doctorAdditionalConf ? doctorConfText[claim.doctorAdditionalConf] : "Not Specified"}
                  </Text>
                )}
                {fieldList.includes("doctorEmployedStatus") && (
                  <Text format={TypographyType.Small} style={styles.flex}>
                    {claim.doctorEmployedStatus === DoctorEmployedStatusValues.payroll
                      ? "Yes"
                      : claim.doctorEmployedStatus === DoctorEmployedStatusValues.non_payroll
                      ? "No"
                      : "No"}
                  </Text>
                )}
                {fieldList.includes("visitdate") && (
                  <Text format={TypographyType.Small} style={styles.flex}>
                    {formatShortDate(claim.visitDate)}
                  </Text>
                )}
                {/* For NHS Coventry and Warwickshire CCG we are replacing the visit date with the approved date on the approved screen.
                Note: the approved date doesn't appear in the claim. We need to extract it from the notes.  */}
                {fieldList.includes("approvedDate") && (
                  <Text format={TypographyType.Small} style={styles.flex}>
                    {formatShortDate(getApprovedDate(claim.notes) as string)}
                  </Text>
                )}
                {fieldList.includes("receiveddate") && (
                  <Text format={TypographyType.Small} style={styles.flex}>
                    {formatShortDate(claim.receivedDate)}
                  </Text>
                )}
                {fieldList.includes("paid") && (
                  <Text format={TypographyType.Small} style={styles.flex}>
                    {claim.status === ClaimStatus.approved_and_paid ? "Yes" : "No"}
                  </Text>
                )}
              </>
            ) : (
              <>
                <View style={[styles.row, styles.marginSmall]}>
                  {fieldList.includes("ccg") && (
                    <Description style={[styles.splitRow, styles.bottomMargin20]} label="CCG (Assignee)">
                      {claim.organisation.name}
                    </Description>
                  )}
                  {fieldList.includes("distance") && (
                    <Description style={[styles.splitRow, styles.bottomMargin20]} label="Distance Travelled">
                      {claim.mileage ? `${claim.mileage} miles` : "N/A"}
                    </Description>
                  )}
                </View>
                <View style={styles.row}>
                  {fieldList.includes("visitdate") && (
                    <Description style={styles.splitRow} label="Visit Date">
                      {formatShortDate(claim.visitDate)}
                    </Description>
                  )}
                  {fieldList.includes("expenses") && (
                    <Description style={styles.splitRow} label="Additional Expenses">
                      {claim.additionalExpenses ? `£${claim.additionalExpenses}` : "-"}
                    </Description>
                  )}
                  {fieldList.includes("receiveddate") && (
                    <Description style={styles.splitRow} label="Received Date">
                      {formatShortDate(claim.receivedDate)}
                    </Description>
                  )}
                  {fieldList.includes("amhpteamname") && (
                    <Description style={styles.splitRow} label="Amhp Team Name">
                      {claim.amhpTeamId}
                    </Description>
                  )}
                </View>
              </>
            )}
          </View>
        </ContentWrap>
      </TouchableRipple>
      <ContentWrap>
        <Divider />
      </ContentWrap>
    </View>
  );
};

export default ClaimProcessingCard;

const styles = StyleSheet.create({
  checkboxContainer: {
    justifyContent: "flex-end",
    alignSelf: "flex-end",
  },
  flex: {
    flex: 1,
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
  },
  splitRow: {
    width: "50%",
    flexBasis: "50%",
  },
  activeRow: {
    backgroundColor: color.backgroundGrey,
  },
  inactiveRow: {
    opacity: 0.6,
  },
  bottomMargin20: {
    marginBottom: spacing[20],
  },
  marginSmall: {
    marginTop: spacing[20],
  },
});
