import Analytics from "@aws-amplify/analytics";
import { EventMetrics } from "@aws-amplify/analytics/lib/types/Analytics";
import Config from "../config";
import { UserDetails } from "./recoil/props";
import NetInfo from "@react-native-community/netinfo";
import { AnalyticsEvent } from "libs/analytics/events";
import { version } from "../../package.json";

export type EventAttributes = { [key: string]: string };

/**
 * Wrapper function to add amplify analytics events.
 * Could be refined in future dependent on differing needs between web and natve
 * @param name name of event
 * @param attributes
 * @param metrics
 */
export const recordEvent = async (
  name: AnalyticsEvent,
  eventAttributes?: EventAttributes,
  eventMetrics?: EventMetrics
) => {
  // Can't do away with await unless we get rid of NetInfo check
  if (Config.ENABLE_ANALYTICS && (await NetInfo.fetch()).isConnected) {
    await Analytics.record({
      name,
      attributes: { ...eventAttributes },
      metrics: { ...eventMetrics },
    }).catch((_e: Error) => {
      // There are reasons why this won't record properly, such as when the user is logged out.
      // Sentry.captureException(e);
    });
  }
};

export const UpdateEndpoint = async (user: UserDetails) => {
  if (Config.ENABLE_ANALYTICS) {
    const endpointAttributes: EndpointAttributes = {
      optOut: "NONE",
      userId: user.email,
      address: user.email,
      channelType: "EMAIL", // gets overridden on native by AWSPinpointProvider.ts
      userAttributes: {
        Email: [user.email],
        "User Groups": user.groups || [],
        "User Orgs": user.organisations || [],
        "User Teams": user.teams || [],
        Site: user.sites || [],
        "App Version": [version],
      },
    };
    Analytics.configure({
      endpointId: user.email,
    });

    await Analytics.updateEndpoint(endpointAttributes).catch((_e: Error) => {
      // There are reasons why this won't record properly, such as when the user is logged out.
      // Sentry.captureException(e);
    });
  }
};

/**
 * Page view tracking
 * Currently not supported by default in https://aws-amplify.github.io/docs/js/analytics
 * Use our own custom function
 * @param routeName name of the route
 */
export const RecordPageView = async (routeName: string, params?: Record<string, string>) => {
  // Can't do away with await unless we get rid of NetInfo check
  if (Config.ENABLE_ANALYTICS && (await NetInfo.fetch()).isConnected) {
    Analytics.record({
      name: AnalyticsEvent.PAGE_VIEW,
      attributes: {
        ...params,
        routeName,
      },
    }).catch((e: Error) => {
      // There are reasons why this won't record properly, such as when the user is logged out.
      // console.log("Analytics.recordPageView ", e);
    });
  }
};

export interface EndpointAttributes {
  address?: string; // The unique identifier for the recipient. For example, an address could be a device token, email address, or mobile phone number.
  attributes?: {
    // Custom attributes that your app reports to Amazon Pinpoint. You can use these attributes as selection criteria when you create a segment.
    [key: string]: string[];
  };
  channelType?: "APNS" | "GCM" | "EMAIL"; // The channel type. Valid values: APNS, GCM
  demographic?: {
    appVersion?: string; // The version of the application associated with the endpoint.
    locale?: string; // The endpoint locale in the following format: The ISO 639-1 alpha-2 code, followed by an underscore, followed by an ISO 3166-1 alpha-2 value
    make?: string; // The manufacturer of the endpoint device, such as Apple or Samsung.
    model?: string; // The model name or number of the endpoint device, such as iPhone.
    modelVersion?: string; // The model version of the endpoint device.
    platform?: string; // The platform of the endpoint device, such as iOS or Android.
    platformVersion?: string; // The platform version of the endpoint device.
    timezone?: string; // The timezone of the endpoint. Specified as a tz database value, such as Americas/Los_Angeles.
  };
  location?: {
    city?: string; // The city where the endpoint is located.
    country?: string; // The two-letter code for the country or region of the endpoint. Specified as an ISO 3166-1 alpha-2 code, such as "US" for the United States.
    latitude?: number; // The latitude of the endpoint location, rounded to one decimal place.
    longitude?: number; // The longitude of the endpoint location, rounded to one decimal place.
    postalCode?: string; // The postal code or zip code of the endpoint.
    region?: string; // The region of the endpoint location. For example, in the United States, this corresponds to a state.
  };
  metrics?: {
    // Custom metrics that your app reports to Amazon Pinpoint.
    [key: string]: number;
  };
  /** Indicates whether a user has opted out of receiving messages with one of the following values:
   * ALL - User has opted out of all messages.
   * NONE - Users has not opted out and receives all messages.
   */
  optOut?: "ALL" | "NONE";
  userId?: string; // Customized userId
  // User attributes
  userAttributes?: {
    [key: string]: string[];
  };
}
