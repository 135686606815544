import React from "react";
import Text from "../Text";
import { Snackbar as RNPSnackbar } from "react-native-paper";
import { StyleSheet } from "react-native";

import { TypographyType } from "@/models/Typography";
import { color } from "../../theme/color";
import { useKeyboard } from "../../utils/helpers";

interface SnackbarProps {
  children: string;
  clear: () => void;
  isError?: boolean;
}

export default function Snackbar(props: SnackbarProps) {
  const keyboard = useKeyboard();
  return (
    <RNPSnackbar
      style={[{ marginBottom: keyboard.height }, props.isError ? styles.error : styles.regular]}
      visible={props.children.length > 0}
      onDismiss={props.clear}
      duration={RNPSnackbar.DURATION_SHORT}
    >
      <Text format={TypographyType.Regular}>{props.children}</Text>
    </RNPSnackbar>
  );
}

const styles = StyleSheet.create({
  regular: {
    backgroundColor: color.secondary,
  },
  error: {
    backgroundColor: color.unavailable,
  },
});
