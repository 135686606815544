import React, { useMemo } from "react";
import { StyleSheet, View } from "react-native";

import { EventProps } from "./EventsList.props";
import { spacing } from "../../theme";
import { useDeviceWidth } from "../../utils/helpers";

const EventItem: React.FC<EventProps> = ({ event, renderLine, style, themeColor }) => {
  const deviceWidth = useDeviceWidth();
  const dotStyles = useMemo(() => [styles.dot, deviceWidth <= 320 && styles.dotSmall, { borderColor: themeColor }], [
    themeColor,
    deviceWidth,
  ]);

  const lineStyles = useMemo(() => [styles.line, { backgroundColor: themeColor }], [themeColor]);

  return (
    <View style={style}>
      {renderLine && <View style={lineStyles} />}
      <View style={dotStyles} />
      {event}
    </View>
  );
};

const styles = StyleSheet.create({
  dot: {
    width: 12,
    height: 12,
    marginRight: spacing[10],
    borderRadius: 12,
    borderWidth: 1.5,
    transform: [{ translateY: 5 }],
  },
  dotSmall: {
    width: 8,
    height: 8,
    marginRight: 8,
    transform: [{ translateY: 6 }],
  },

  line: {
    width: 2,
    height: "100%",
    position: "absolute",
    left: 5,
    top: 17,
    zIndex: -1,
  },
});

export default EventItem;
