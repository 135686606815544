export const CCG_Flags: {
  [key: string]: string;
} = {
  claims: "Claims",
  mileage: "Pays Mileage",
  additionalExpenses: "Pays travel expenses",
  fullPatientName: "Requires Full patient name",
  ccgRequiresBillingInformation: "Requires billing information", // CCG enforces that billing information is supplied with claim
  confirmAssessmentTookPlace: "AMHP confirm visit took place", // CCG required the amhp to note if the visit took place
  provideVehicleInformation: "Doctor Provide vehicle information",
  ccgRequiresNhsNumber: "Requires NHS number",
  ccgRequiresDoctorToInvoice: "Requires doctor to invoice",
  ccgRequiresLineManager: "Requires Line manager",
  ccgRequiresGmcNumber: "Requires GMC number",
  ccgDoesNotAcceptHospitalAssessments: "Does not accept hospital assessments",
  ccgRequiresAmhpTeamName: "Requires AMHP team name",
  ccgRequiresDoctorMhtAssociations: "Requires doctor MHT associations",
  ccgDisplayClaimLocation: "Display claim location",
  ccgRequiresDoctorAdditionalConfirmation: "Requires doctor additional confirmation",
  doctorFeesMileageWithoutInvoice: "Requires Doctor fees mileage without invoice",
  mhtAndLineManager: "MHT and line manager",
  ccgRequiresDoctorEmployedStatus: "Doctors should confirm payroll status",
};

export const AMHP_Team_Flags: {
  [key: string]: string;
} = {
  amhpShouldProvideNhsNumber: "Require NHS number", // AMHP team level flag - requires the amhp to provide NHS # when creating a claim
  amhpTeamVideoMeetings: "Enable video meetings", // Enable video meetings for AMHP teams
  amhpTeamMHAForms: "MHA forms",
  amhpTeamMHAExternalDoctors: "Enable MHA external doctors",
  amhpTeamMhaHospitalEmails: "Enable hospital/ward emails", // Show hospitals/wards for amhps to submit MHA forms to
  amhpTeamUsesJointMedRecs: "Enable joint medical recommendations", // AMHP team level flag - enables joint medical recommendations
  amhpTeamInbox: "Enable MHA Form Inbox", // AMHP team inbox for unattached mha forms
  amhpTeamDownloadForms: "Enable form downloads", // AMHP team can download signed mha forms
  amhpTeamEmailFormsToSelf: "Allow sending forms to self", // AMHP team can email forms to themselves
  amhpTeamEmailFormsToApprovedLists: "Enable Approved Recipient List", // AMHP team can send bulk emails to approved recipient lists
  amhpTeamUsesConveyanceForms: "Enable Conveyance", // Blue conveyance forms available
  docInformalMedRecs: 'Enable "Medical Statement of Reason" forms',
};

export const MHT_Flags: { [key: string]: number } = {};

export const getMhaFormVersion2ActiveDate = (env: string) => {
  // ISO 8601 datetime string after which version 2 changes will be active
  return env === "prod" ? "2020-12-01T00:00:00.001Z" : "2020-11-19T00:00:00.001Z";
};
