// External libraries
import { VideoTileState } from "amazon-chime-sdk-js";
import React, { useEffect, useMemo, useReducer, useRef, useState, useCallback } from "react";
import { View } from "react-native";
import { useNavigation, useIsFocused, ParamListBase } from "@react-navigation/native";
import { TileName, TilePosition, AssessmentDetails, State, TileState, S12HTMLVideoElement } from "./MeetingRoom.types";
import { meetingStyles } from "./styles";

// MeetingRoom model stuff and components
import {
  Actions,
  ActionTypes,
  MeetingRoom as MeetingRoomModel,
  Notification,
  NotificationFinishedPayload,
  UserAudio,
  UserJoinedOrLeftPayload,
} from "@/models/MeetingRoom";
import { TypographyType } from "@/models/Typography";
import { palette, spacing } from "../../theme";
import {
  activateFullscreen,
  deactivateFullscreen,
  isMobileDevice,
  useDeviceHeight,
  useDeviceWidth,
  useDeviceOrientation,
} from "../../utils/helpers";
// Shared components
import { IconName } from "../Icon";
import { Modal } from "../Modal";
import { Button } from "../Button";
import Text from "../Text";
import {
  Notifications,
  StatusBar,
  StatusType,
  VideoContainerBackground,
  VideoTile,
  ControlBar,
  PermissionsHelp,
} from "./components";

import { useRecoilValue } from "recoil";
import { userDetails, meetingPrefs as recoilMeetingPrefs } from "../../utils/recoil/index";
import { DeviceAudio } from "./components/AudioVideo";
import { DeviceSelection } from "./DeviceSelection";
import { SectionDivider } from "../SectionDivider";
import { useGoBack } from "./helpers";
import { StackNavigationProp } from "@react-navigation/stack";

const emptyArray: never[] = [];
export interface MeetingRoomProps {
  assessmentDetails: AssessmentDetails;
  meetingDetails: any;
  attendeeDetails: any;
  localUserId: string;
}

const MeetingRoom = (props: MeetingRoomProps) => {
  // Global State
  const user = useRecoilValue(userDetails);
  const meetingPrefs = useRecoilValue(recoilMeetingPrefs);

  const navigation = useNavigation<StackNavigationProp<ParamListBase>>();

  // Used to cleanup when focus is lost
  // TODO: Do this on preview screen as well?
  const isFocused = useIsFocused();
  const goBack = useGoBack();

  // Refs - Elements
  const tile1Ref = useRef<S12HTMLVideoElement | null>(null);
  const tile2Ref = useRef<S12HTMLVideoElement | null>(null);
  const tile3Ref = useRef<S12HTMLVideoElement | null>(null);
  const tile4Ref = useRef<S12HTMLVideoElement | null>(null);
  // We need to listen for changes, make this a state, useRef does not update component on change
  const [meetingAudio, setMeetingAudio] = useState<HTMLAudioElement | null>(null);
  const [showSettings, setShowSettings] = useState(false);

  // ref callback for audio when the element is created
  const getMeetingAudioElement = useCallback(
    (element: HTMLAudioElement | null) => element !== null && setMeetingAudio(element),
    emptyArray
  );

  // Refs - Singletons
  // Ref is ok here, we don't need to know when these update
  const videoManager = useRef<MeetingRoomModel | null>(null);
  // bind the reducer so that we can send in the video manager and video tiles
  const boundReducer = useRef<(state: State, action: any) => State>(
    reducer.bind(null, videoManager, [tile1Ref, tile2Ref, tile3Ref, tile4Ref])
  );

  // State - Reducer
  // This isn't just a reducer, it has side effects handling calls back to the video manager as well.
  // TODO: Find a good way to remove side efefcts
  const [state, dispatch] = useReducer(boundReducer.current, {
    assessment: props.assessmentDetails,
    self: {
      userId: props.attendeeDetails.ExternalUserId,
      attendeeId: props.attendeeDetails.AttendeeId,
      userDisplayName: user?.name,
      hideVideo: false,
    },
    roster: [],
    // will temporary house the videoitems for which the (roster) user turned the cam off, to keep reference
    // in tact and enable to still display information like user name etc.
    suspendedVideoItems: [],
    notifications: [],
    sessionTimedOut: false,
    // which tile occupies the centre area
    centreTile: "tile2",
  } as State);

  console.log("state", state);

  function setNavigationBar(isVisible: boolean) {
    return navigation.dangerouslyGetParent()?.setOptions({ tabBarVisible: isVisible });
  }

  // TODO: Hide bottom nav is not working well on iphone, the element is hidden, but you can still scroll down.
  // TODO: On mobile we should also move the self tile to the bottom right, have 3rd user in bottom left, 4th user in top right.
  useEffect(() => {
    const isMobile = isMobileDevice();
    setNavigationBar(false);
    if (isMobile && window && window.document) {
      activateFullscreen(window.document.documentElement);
    }
    return () => {
      setNavigationBar(true);
      if (isMobile && window && window.document) deactivateFullscreen();
    };
  }, emptyArray);

  // Adjust meeting settings based on prefs updates
  useEffect(() => {
    if (videoManager.current) {
      videoManager.current.changeSettings(meetingPrefs);
    }
  }, [meetingPrefs]);

  // Effect - initialise video manager when elements are ready
  // useEffectDebugger
  useEffect(
    () => {
      if (
        isFocused &&
        props.assessmentDetails &&
        props.meetingDetails &&
        props.attendeeDetails &&
        meetingAudio &&
        meetingPrefs &&
        !videoManager.current
      ) {
        videoManager.current = new MeetingRoomModel(
          props.meetingDetails,
          props.attendeeDetails,
          props.localUserId,
          meetingAudio,
          dispatch,
          meetingPrefs
        );
      } else if (!isFocused && videoManager.current) {
        videoManager.current.teardown();
        videoManager.current = null;
      }

      return () => {
        if (videoManager.current) {
          videoManager.current.teardown();
          videoManager.current = null;
        }
      };
    },
    [props.meetingDetails, meetingAudio, props.attendeeDetails, isFocused]
    // ["meeting details", "audio", "attendee", "isfocused"]
  );

  const deviceWidth = useDeviceWidth();
  const deviceHeight = useDeviceHeight();
  const deviceOrientation = useDeviceOrientation();

  const screenStyles = useMemo(() => [meetingStyles.screen, { width: deviceWidth, height: deviceHeight }], [
    deviceWidth,
    deviceHeight,
  ]);

  const shouldShowPermissionsBlockedBar = useMemo<boolean>(
    () =>
      (!!state.self.permissions?.microphone &&
        state.self.permissions.microphone > 1 &&
        state.self.permissions.microphone < 4) ||
      (!!state.self.permissions?.video && state.self.permissions.video > 1 && state.self.permissions.video < 4),
    [state.self.permissions]
  );

  const setCentreTile = useCallback((tileName: TileName) => dispatch([ActionTypes.SetCentreTile, tileName]), [
    dispatch,
  ]);

  const micActive =
    typeof state.self.isMuted === "undefined" ? !videoManager.current?.isSelfMuted() || false : !state.self.isMuted;

  const toggleSettings = useCallback(() => setShowSettings(st => !st), [showSettings]);

  const toggleVideo = useCallback(() => {
    if (state.self.hideVideo) {
      return videoManager.current?.cameraOn(meetingPrefs);
    } else {
      return videoManager.current?.cameraOff();
    }
  }, [meetingPrefs, state.self.hideVideo]);

  const toggleMic = useCallback(() => {
    if (micActive) {
      return videoManager.current?.muteSelf();
    } else {
      return videoManager.current?.unmuteSelf();
    }
  }, [micActive]);

  const controlBarMeetingDetails = useMemo(
    () => ({
      userDisplayName: state.self.userDisplayName,
      userId: state.self.userId,
      attendees: state.roster.map(r => ({
        attendeeId: r.attendeeId,
        userDisplayName: r.userDisplayName,
        userId: r.userId,
      })),
    }),
    [state.self.userDisplayName, state.self.userId, state.roster]
  );

  return (
    <View style={screenStyles}>
      <View style={meetingStyles.videoContainer}>
        <View style={meetingStyles.videoTopNotificationArea}>
          {shouldShowPermissionsBlockedBar && <StatusBar status={StatusType.PERMISSIONS_BLOCKED} />}
          <StatusBar status={StatusType.NOT_RECORDING} />
        </View>

        {/* A single audio element plays all meeting audio */}
        <DeviceAudio id="meeting-audio" ref={getMeetingAudioElement} />

        {/* The Local User Video Tile, always top right */}
        <VideoTile
          ref={tile1Ref}
          tileName={TileName.tile1}
          tileState={state.self}
          position={TilePosition.self}
          videoWidth={tile1Ref.current?.videoWidth || 0}
          videoHeight={tile1Ref.current?.videoHeight || 0}
          deviceHeight={deviceHeight}
          deviceOrientation={deviceOrientation}
        />

        <VideoTile
          ref={tile2Ref}
          tileName={TileName.tile2}
          tileState={[...state.roster, ...state.suspendedVideoItems].find(r => r.videoElement === tile2Ref)}
          position={state.centreTile === TileName.tile2 ? TilePosition.center : TilePosition.bottom}
          onPress={setCentreTile}
          videoWidth={tile2Ref.current?.videoWidth || 0}
          videoHeight={tile2Ref.current?.videoHeight || 0}
          deviceHeight={deviceHeight}
          deviceOrientation={deviceOrientation}
        />

        <VideoTile
          ref={tile3Ref}
          tileName={TileName.tile3}
          tileState={[...state.roster, ...state.suspendedVideoItems].find(r => r.videoElement === tile3Ref)}
          position={state.centreTile === TileName.tile3 ? TilePosition.center : TilePosition.bottom}
          onPress={setCentreTile}
          videoWidth={tile3Ref.current?.videoWidth || 0}
          videoHeight={tile3Ref.current?.videoHeight || 0}
          deviceHeight={deviceHeight}
          deviceOrientation={deviceOrientation}
        />

        <VideoTile
          ref={tile4Ref}
          tileName={TileName.tile4}
          tileState={[...state.roster, ...state.suspendedVideoItems].find(r => r.videoElement === tile4Ref)}
          position={state.centreTile === TileName.tile4 ? TilePosition.center : TilePosition.left}
          onPress={setCentreTile}
          videoWidth={tile4Ref.current?.videoWidth || 0}
          videoHeight={tile4Ref.current?.videoHeight || 0}
          deviceHeight={deviceHeight}
          deviceOrientation={deviceOrientation}
        />

        <View
          // eslint-disable-next-line
          style={meetingStyles.notificationContainer}
        >
          <Notifications notifications={state.notifications} />
        </View>

        <VideoContainerBackground showWaitingForPeopleText={!state.roster.length} />
      </View>

      <ControlBar
        assessmentDetails={props.assessmentDetails}
        meetingDetails={controlBarMeetingDetails}
        attendeeCount={state.roster.length + 1}
        videoActive={!state.self.hideVideo || false}
        onVideoPress={toggleVideo}
        audioLevel={state.self.audioLevel}
        micActive={micActive}
        onMicPress={toggleMic}
        settingsActive={showSettings}
        onSettingsPress={toggleSettings}
      />

      {showSettings && (
        <Modal headline="Video/Audio Settings" dismissable={true} visible={showSettings} onDismiss={toggleSettings}>
          <DeviceSelection mode="windowed" />
          <SectionDivider />
          <PermissionsHelp />
        </Modal>
      )}

      {state.sessionTimedOut && (
        <Modal
          visible={state.sessionTimedOut}
          dismissable={false}
          headline="Disconnected"
          headerBackgroundColor={palette.red}
          removeInnerScroller={true}
        >
          <View style={meetingStyles.sessionTimeoutModalContent}>
            <Text format={TypographyType.Regular} style={{ marginBottom: spacing[20] }}>
              You have been disconnected. You can re-
              {state.roster.length > 0 ? "join" : "start"} the meeting or return to the assessment.
            </Text>
            <Button
              onPress={() => {
                // TODO: currently not working
                return null;
              }}
            >
              Re-{state.roster.length > 0 ? "join" : "start"} Meeting
            </Button>
            <Button mode="outlined" onPress={goBack}>
              Go back
            </Button>
          </View>
        </Modal>
      )}
    </View>
  );
};

export default MeetingRoom;

function reducer(
  videoManager: React.MutableRefObject<MeetingRoomModel>,
  videos: React.MutableRefObject<HTMLVideoElement> & { current: { videoWidth: number; videoHeight: number } }[],
  state: State,
  action: Actions
): State {
  switch (action[0]) {
    case ActionTypes.NotificationFinished:
      return handleNotificationFinished(state, action[1]);
    case ActionTypes.UserJoinedOrLeft:
      return handleUserJoinedOrLeft(state, action[1], videoManager);
    case ActionTypes.SessionTimedOut:
      return {
        ...state,
        sessionTimedOut: true,
      };
    case ActionTypes.UserVideoUpdated:
      return action[1] ? userVideoTileUpdated(videoManager.current, state, videos, action[1]) : state;
    case ActionTypes.UserTurnedVideoOff:
      return handleUserTurnedVideoOff(state, action[1], videos);
    // case ActionTypes.UserToggledMic:
    //  return handleUserToggledMic(state, action[1]);
    case ActionTypes.SelfVideoStarted:
      return action[1] ? selfVideoStarted(videoManager.current, state, videos, action[1]) : state;
    case ActionTypes.SelfVideoStopped:
      return {
        ...state,
        self: {
          ...state.self,
          hideVideo: true,
          // if you remove the video element, you also remove the audio unless we separate streams
          // videoElement: undefined
        },
      };

    case ActionTypes.SelfVideoUpdated:
      return state; // action[1]
    // ? selfToTile(videoManager.current, state, videos, action[1])

    case ActionTypes.VideoPermissions:
      return {
        ...state,
        self: {
          ...state.self,
          permissions: {
            ...state.self.permissions,
            video: action[1],
          },
        },
      };

    case ActionTypes.UserAudioLevel:
      return updateAudioForUser(state, action[1]);
    case ActionTypes.MicrophonePermissions:
      return {
        ...state,
        self: {
          ...state.self,
          permissions: {
            ...state.self.permissions,
            microphone: action[1],
          },
        },
      };
    case ActionTypes.SetCentreTile:
      return handleSetCentreTile(state, action[1], videos);
    default:
      return state;
  }
}

function handleSetCentreTile(
  state: State,
  payload: "tile2" | "tile3" | "tile4",
  videos: React.MutableRefObject<HTMLVideoElement> & { current: { videoWidth: number; videoHeight: number } }[]
): State {
  const current = state.centreTile;
  if (current === payload) {
    // NO-OP
    return state;
  }

  // get tile information, make sure we want to allow this switch
  const tileElement = payload === "tile2" ? videos[1] : payload === "tile3" ? videos[2] : videos[3];
  const rosterItem = state.roster.find(ri => ri.videoElement === tileElement);

  if (rosterItem?.present) {
    return {
      ...state,
      centreTile: payload,
    };
  }

  return state;
}

function handleUserTurnedVideoOff(
  state: State,
  payload: {
    tileId: number | null;
  },
  videos: React.MutableRefObject<HTMLVideoElement> & { current: { videoWidth: number; videoHeight: number } }[]
): State {
  if (!payload.tileId) return state;
  let { roster, suspendedVideoItems = [] } = state;
  const rosterTileIndex = roster.findIndex(t => t.tileState?.tileId === payload.tileId);

  let newCenterTile = state.centreTile;

  if (rosterTileIndex > -1 && roster[rosterTileIndex] && roster[rosterTileIndex].present) {
    suspendedVideoItems = [...suspendedVideoItems, { ...roster[rosterTileIndex] }];

    // set the new centre tile if it needs to change, a user that turns off their video should no longer occupy the second tile
    const videoIndex = videos.findIndex(v => v === roster[rosterTileIndex].videoElement);
    if (
      (videoIndex === 1 && state.centreTile === "tile2") ||
      (videoIndex === 2 && state.centreTile === "tile3") ||
      (videoIndex === 3 && state.centreTile === "tile4")
    ) {
      const newCenterRoster = roster.find((r, i) => !!r.videoElement && i !== rosterTileIndex);
      if (newCenterRoster) {
        const newVideoIndex = videos.findIndex(v => v === newCenterRoster?.videoElement);
        if (newVideoIndex) {
          newCenterTile = newVideoIndex === 1 ? "tile2" : newVideoIndex === 2 ? "tile3" : "tile4";
        }
      }
    }
  }

  return {
    ...state,
    centreTile: newCenterTile,
    roster: roster.map((ri, i) => (i === rosterTileIndex ? { ...roster[rosterTileIndex], hideVideo: true } : ri)),
    suspendedVideoItems,
  };
}
/*
function handleUserToggledMic(
  state: State,
  payload: {
    attendeeId: string;
    micMuted: boolean;
    userId: string;
  }
): State {
  if (payload.userId === state.self.userId) {
    // we don't need to act on our own presence
    return {
      ...state,
      self: {
        ...state.self,
        isMuted: payload.micMuted
      }
    };
  }

  const newState: State = {
    ...state,
    roster: state.roster.map(item => {
      return item.attendeeId === payload.attendeeId
        ? {
            ...item,
            isMuted: payload.micMuted
          }
        : item;
    })
  };

  return newState;
}
*/
function handleNotificationFinished(state: State, payload: NotificationFinishedPayload): State {
  return {
    ...state,
    notifications: state.notifications.filter(n => n.id !== payload.notificationId),
  };
}

function handleUserJoinedOrLeft(
  state: State,
  payload: UserJoinedOrLeftPayload,
  videoManager: React.MutableRefObject<MeetingRoomModel>
): State {
  if (payload.userId === state.self.userId) {
    // we don't need to act on our own presence
    return state;
  }
  const rosterItemExists = checkIfRosterItemExists(payload.attendeeId, state.roster);

  if (!rosterItemExists) {
    // user must have JOINED the room, as there is no roster item for them yet
    // let's create a rosterItem for the user and add it (and a notification) to the roster state
    const newRosterItem = createRosterItem(payload.attendeeId, state, payload.userId);

    if (payload.present === true || payload.present === false) {
      newRosterItem.present = payload.present;
    }

    const notification: Notification = {
      id: payload.notificationId,
      message: `${newRosterItem.userDisplayName} joined the room`,
      icon: IconName.person_add,
    };

    return {
      ...state,
      roster: [...state.roster, newRosterItem],
      notifications: [...state.notifications, notification],
    };
  } else {
    // a rosterItem for this attendeeId already exists in state
    // let's grab it
    const { rosterItemIndex, rosterItem } = getRosterItem(payload.attendeeId, state.roster);

    if (
      !(rosterItem.userId && payload.userId) ||
      payload.present === true ||
      (payload.present === false && payload.present !== rosterItem.present)
    ) {
      // user must have JOINED the room, as the existing rosterItem for the user does not yet have a userId set
      //   -> this means this rosterItem was created by userVideoTileUpdated()
      //   -> when creating a rosterItem, userVideoTileUpdated() only has access to tileState, not userId (or present)

      // let's update the existing rosterItem with the newly available information
      const updatedRosterItem = {
        ...rosterItem,
        userId: payload.userId,
        userDisplayName: getUserDisplayName(payload.userId, state),
        present: payload.present === true || payload.present === false ? payload.present : rosterItem.present,
      };

      if (payload.present === true && rosterItem.present !== payload.present) {
        const notification: Notification = {
          id: payload.notificationId,
          message: `${updatedRosterItem.userDisplayName} joined the room`,
          icon: IconName.person_add,
        };
        return {
          ...state,
          roster: state.roster.map((ri, index) => (index === rosterItemIndex ? updatedRosterItem : ri)),

          notifications: [...state.notifications, notification],
        };
      } else if (payload.present === false && rosterItem.present !== payload.present) {
        const notification: Notification = {
          id: payload.notificationId,
          message: `${rosterItem.userDisplayName} left the room`,
          icon: IconName.person,
        };

        if (rosterItem.tileState?.tileId) {
          videoManager.current.disconnectTileFromElement(rosterItem.tileState?.tileId);
        }
        return {
          ...state,
          roster: state.roster.filter((ri, index) => index !== rosterItemIndex),
          suspendedVideoItems: state.suspendedVideoItems.filter(svi => svi.userId !== rosterItem.userId),
          notifications: [...state.notifications, notification],
        };
      }

      return {
        ...state,
        roster: state.roster.map((ri, index) => (index === rosterItemIndex ? updatedRosterItem : ri)),
      };
    }
    return state;
  }
}

function getUserDisplayName(userId: string, state: State) {
  return state.assessment.amhp.id === userId
    ? state.assessment.amhp.name
    : (
        state.assessment.doctors.find(d => d.id === userId) || {
          name: "Name not found",
        }
      ).name;
}

function createRosterItem(
  attendeeId: string,
  state: State,
  userId?: string | null,
  tileState?: VideoTileState | null
): TileState {
  // We need to create a new roster item for each new user (not self) in the room
  // This can happen either when:
  //  -> userVideoTileUpdated() gets called (in which case we have tileState available, but not userId)
  //  -> handleUserJoinedOrLeft() gets called (in which case we have userId available, but not tileState)
  //  -> We cannot rely on which of the two functions above gets called first;
  //     This means this function will always have userId OR tileState, but never both
  return {
    attendeeId,
    ...(tileState && { tileState, hideVideo: false }),
    ...(userId && {
      userId,
      userDisplayName: getUserDisplayName(userId, state),
    }),
  };
}

function checkIfRosterItemExists(attendeeId: string, roster: TileState[]): boolean {
  return !!roster.find(ri => ri.attendeeId === attendeeId);
}

function getRosterItem(
  attendeeId: string,
  roster: TileState[]
): {
  rosterItemIndex: number;
  rosterItem: TileState;
} {
  const rosterItemIndex = roster.findIndex(ri => ri.attendeeId === attendeeId);
  const rosterItem = roster[rosterItemIndex];
  return { rosterItemIndex, rosterItem };
}

function updateAudioForUser(state: State, payload: UserAudio): State {
  if (payload.attendeeId === state.self.attendeeId) {
    if (payload.isMuted === true || payload.isMuted === false) {
      const temps = {
        ...state,
        self: {
          ...state.self,
          audioLevel: payload.volume,
          isMuted: payload.isMuted,
        },
      };

      return temps;
    }

    return {
      ...state,
      self: {
        ...state.self,
        audioLevel: payload.volume,
      },
    };
  }

  const rosterItemIndex = state.roster.findIndex(r => r.attendeeId === payload.attendeeId);

  const rosterItem = state.roster[rosterItemIndex];

  if (!rosterItem) {
    return state;
  }

  if (payload.isMuted !== null) {
    rosterItem.isMuted = payload.isMuted;
  }

  rosterItem.audioActive = payload.volume > 0.1 && !payload.isMuted;

  return {
    ...state,
    roster: state.roster.map((ri, i) => (i === rosterItemIndex ? { ...ri } : ri)),
  };
}

function selfVideoStarted(
  videoManager: MeetingRoomModel,
  state: State,
  videos: React.MutableRefObject<HTMLVideoElement> & { current: { videoWidth: number; videoHeight: number } }[],
  tileState: VideoTileState
): State {
  state.self.tileState = tileState;
  if (!state.self.videoElement && tileState.tileId) {
    state.self.videoElement = videos[0];
    videoManager.connectVideoTileStreamToVideoElement(tileState.tileId, state.self.videoElement.current);
  }
  return {
    ...state,
    self: {
      ...state.self,
      hideVideo: false,
    },
  };
}

function userVideoTileUpdated(
  videoManager: MeetingRoomModel,
  state: State,
  videos: React.MutableRefObject<HTMLVideoElement> & { current: { videoWidth: number; videoHeight: number } }[],
  tileState: VideoTileState
): State {
  let shouldUpdate = false;
  if (!tileState.boundAttendeeId || tileState.boundAttendeeId === state.self.attendeeId) {
    // this will get called later when the attendee id is known, ok to ignore for now.
    return state;
  }

  let itemIndex = state.roster.findIndex(r => r.attendeeId === tileState.boundAttendeeId);

  let item = state.roster[itemIndex];

  if (!item) {
    // this is a new roster item.
    // create an item
    item = {
      attendeeId: tileState.boundAttendeeId,
      tileState: tileState,
    } as TileState;

    state.roster.push(item);
    itemIndex = state.roster.length - 1;
    shouldUpdate = true;
  }

  if (!tileState.boundVideoElement) {
    console.log("no boundVideoElement", item.videoElement, videos);
    if (!item.videoElement) {
      const firstUnusedVideo = videos.slice(1).find(v => state.roster.findIndex(r => r.videoElement === v) === -1);
      console.log("firstUnusedVideo", firstUnusedVideo);
      if (!firstUnusedVideo) {
        return state;
      }
      item.videoElement = firstUnusedVideo;
    }
    videoManager.connectVideoTileStreamToVideoElement(tileState.tileId!, item.videoElement.current);
    shouldUpdate = true;
  }

  // only update the video tile if something we care about changed
  if (
    item.tileState?.boundVideoElement !== tileState.boundVideoElement ||
    item.tileState?.poorConnection !== tileState.poorConnection ||
    item.tileState.boundAttendeeId !== tileState.boundAttendeeId ||
    item.tileState?.active !== tileState.active
  ) {
    if (item.tileState?.active !== tileState.active) item.hideVideo = !tileState.active;
    shouldUpdate = true;
    item.tileState = tileState;
  }

  const videoIndex = videos.findIndex(v => v === item.videoElement);
  const newCentreTile =
    state.roster.filter(ri => !ri.hideVideo).length === 1
      ? videoIndex === 1
        ? "tile2"
        : videoIndex === 2
        ? "tile3"
        : "tile4"
      : state.centreTile;

  if (newCentreTile !== state.centreTile) {
    shouldUpdate = true;
  }

  if (!shouldUpdate) {
    return state;
  }

  return {
    ...state,
    centreTile: newCentreTile,
    roster: state.roster.map((ri, i) => (i === itemIndex ? { ...item } : ri)),
    suspendedVideoItems: state.suspendedVideoItems.filter(
      i => i.tileState?.boundAttendeeId !== tileState.boundAttendeeId
    ),
  };
}
/*
const usePrevious = (value: any, initialValue: any) => {
  const ref = useRef(initialValue);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const useEffectDebugger = (
  effectHook: any,
  dependencies: any,
  dependencyNames = [] as string[]
) => {
  const previousDeps = usePrevious(dependencies, []);

  const changedDeps = dependencies.reduce(
    (accum: any, dependency: any, index: any) => {
      if (dependency !== previousDeps[index]) {
        const keyName = dependencyNames[index] || index;
        return {
          ...accum,
          [keyName]: {
            before: previousDeps[index],
            after: dependency
          }
        };
      }

      return accum;
    },
    {}
  );

  if (Object.keys(changedDeps).length) {
    console.log("[use-effect-debugger] ", changedDeps);
  }

  useEffect(effectHook, dependencies);
}; */
