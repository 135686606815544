import React from "react";
import { StyleSheet, View } from "react-native";

import { mqWeb } from "../../utils/helpers";
import { TypographyType } from "@/models/Typography";
import Text from "../../components/Text/Text";
import { palette } from "../../theme";

const SectionHeading = ({
  heading,
  webText = "",
  button = undefined,
}: {
  heading: string;
  webText?: string;
  button?: any;
}) => {
  const isWebView = mqWeb();
  return (
    <View style={styles.sectionHeading}>
      <Text format={TypographyType.HeadingSmall}>
        {heading} {button}
      </Text>
      {isWebView && (
        <Text format={TypographyType.Tiny} color={palette.slate}>
          {webText}
        </Text>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  sectionHeading: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
});

export default SectionHeading;
