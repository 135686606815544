import { StyleSheet } from "react-native";
import { palette } from "../../../theme";

const css = StyleSheet.create({
  button: {
    height: 44,
    // flexBasis: 0,
    // flexGrow: 1,
    flexGrow: 1,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    borderLeftColor: palette.grey,
  },

  icon: {
    marginRight: 5,
  },

  text: {
    textTransform: "uppercase",
  },
});

export default css;
