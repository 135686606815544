/**
 * Don't run the callback the first time the component has mounted.
 *
 * @param callback Function to run when any of the dependencies change, except when the component first mounts.
 * @param dependencies Array of state variables
 */
import { useEffect, useRef } from "react";

export default function useDidUpdate(callback: () => void, dependencies: any[]): void {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) callback();
    else didMountRef.current = true;
  }, dependencies);
}
