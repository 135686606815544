import { ImageStyle } from "react-native";

export const presets = {
  small: {
    resizeMode: "stretch",
    width: 75,
    height: 75,
  } as ImageStyle,
  mini: {
    resizeMode: "center",
    width: 30,
    height: 30,
  } as ImageStyle,
};

export type LogoPresets = keyof typeof presets;
