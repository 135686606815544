import React from "react";

/**
 * The User Authentication screen is not currently supported on web-based
 * devices. This is due to not supporting biometric verification, and we
 * currently do not wish to implement a pin verfication for web users.
 */
const UserAuthenticationScreen = () => {
  return <></>;
};

export default UserAuthenticationScreen;
