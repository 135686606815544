import React, { useEffect, useMemo } from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import dayjs from "dayjs";
import { LocationNameInput } from "libs/types/API";
import { palette } from "@/theme";
import { CSVLink } from "react-csv";
import { userDetails } from "@/utils/recoil";
import { useRecoilValue } from "recoil";

interface PropTypes {
  assessmentId: string;
}

export const GET_ASSESSMENTS = gql`
  query GetAssessmentsByAmhp($id: ID!) {
    assessmentsByAmhp(amhpId: $id, sortDirection: DESC) {
      items {
        id
        patientName
        doctorVisits {
          items {
            id
            time
            doctor {
              id
              name
            }
            doctorVisitClaimId
            createdAt
          }
        }
        contactedDoctors {
          items {
            createdAt
            s12Doctor {
              id
              name
            }
          }
        }
      }
    }
  }
`;

interface DoctorVisit {
  id: string;
  time: string;
  doctor: {
    id: string;
    name: string;
  };
  doctorVisitClaimId: string;
  createdAt: string;
}

interface ContactedDoctor {
  createdAt: string;
  s12Doctor: {
    id: string;
    name: string;
  };
}
interface Response {
  assessmentsByAmhp: {
    items: {
      id: string;
      assessmentDate: string;
      patientName: string;
      locationName: LocationNameInput;
      doctorVisits: {
        items: DoctorVisit[];
      };
      contactedDoctors: {
        items: ContactedDoctor[];
      };
    }[];
    nextToken: string;
  };
}

export const ExportDoctorHistory = ({ assessmentId }: PropTypes) => {
  const user = useRecoilValue(userDetails);
  const [csvData, setCSVData] = React.useState<any[] | string>("");

  const listOptions = useMemo(
    () => ({
      variables: {
        id: user?.id,
      },
      fetchPolicy: "cache-and-network",
    }),
    [user?.id]
  );

  // using lazyQuery as we will trigger it inside the child component when switching from list to date etc
  const [getAssessments, { loading, error, data, fetchMore, variables }] = useLazyQuery<Response>(GET_ASSESSMENTS);

  const assessmentsByAmhp = {
    timestamp: Date.now(),
    items: data?.assessmentsByAmhp.items || [],
  };

  // running query on mount
  useEffect(() => {
    getAssessments(listOptions);
  }, []);

  const getCSVData = () => {
    const assessmentData = assessmentsByAmhp.items.filter(items => items.id === assessmentId)[0] ?? [];
    const patientName = assessmentData.patientName ?? "";
    const contactedDoctors = assessmentData.contactedDoctors.items;
    const visitsDoctors = assessmentData.doctorVisits.items;
    const csvContacted: React.SetStateAction<string | any[]> = [];

    contactedDoctors.map(i =>
      csvContacted.push({
        patientName: patientName,
        contactedDoctors: i.s12Doctor.name,
        contactedDoctorsDate: dayjs(i.createdAt).format("DD/MM/YYYY"),
        contactedDoctorsTime: dayjs(i.createdAt).format("HH:mm"),
        doctorVisits: "",
        doctorVisitsTime: "",
        orderby: i.createdAt,
      })
    );
    visitsDoctors.map(i =>
      csvContacted.push({
        patientName: patientName,
        contactedDoctors: "",
        contactedDoctorsTime: "",
        doctorVisits: i.doctor.name,
        doctorVisitsDate: dayjs(i.time).format("DD/MM/YYYY"),
        doctorVisitsTime: dayjs(i.time).format("HH:mm"),
        orderby: i.time,
      })
    );
    csvContacted.sort((a, b) => {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return dayjs(a.orderby).valueOf() - dayjs(b.orderby).valueOf();
    });

    setCSVData(csvContacted);
  };

  const headers = [
    { label: "Patient Name", key: "patientName" },
    { label: "Contacted Doctors", key: "contactedDoctors" },
    { label: "Date Of Contact", key: "contactedDoctorsDate" },
    { label: "Time Of Contact", key: "contactedDoctorsTime" },
    { label: "Scheduled Doctors", key: "doctorVisits" },
    { label: "Date Of Visit", key: "doctorVisitsDate" },
    { label: "Time Of Visit", key: "doctorVisitsTime" },
    { label: "", key: "" },
  ];

  return (
    <CSVLink
      data={csvData}
      headers={headers}
      asyncOnClick={true}
      filename={"AssessmentSetUpReport.csv"}
      onClick={(_event, done) => {
        getCSVData();
        setTimeout(() => done(), 0);
      }}
      style={{
        borderWidth: 2,
        borderRadius: 12,
        borderColor: palette.yellow,
        backgroundColor: palette.yellow,
        color: palette.navy,
        textAlign: "center",
        textTransform: "uppercase",
        padding: 15,
        fontFamily: "Lato-Bold",
        fontSize: 14,
        textDecoration: "none",
        alignSelf: "center",
        width: "auto",
        marginBottom: 15,
      }}
    >
      {"Assessment Set Up Report"}
    </CSVLink>
  );
};
