import React, { useState } from "react";
import { useSetRecoilState, useRecoilValue } from "recoil";

import { formatLongDate } from "libs/dates";

import { BackButtonProps } from "@/components/BackButton/BackButton.props";
import CreateAssessment from "@/components/CreateAssessment";
import { useOnScreenBlur, useOnScreenFocus } from "@/hooks";
import { AppScreen } from "@/layouts/AppScreen/AppScreen";
import { RouteKeys } from "@/navigationv2";
import { AMHPAssessmentsRoutes, AMHPTabRoutes, ScreenNavigationProps, ScreenOptions } from "@/navigationv2/types";
import { createScreenTitle } from "@/navigationv2/utilities";
import { color } from "@/theme";
import { mqWeb } from "@/utils/helpers";
import { assessment, duplicateAssessment as recoilDuplicateAssessment } from "@/utils/recoil/index";

interface PropTypes
  extends ScreenNavigationProps<AMHPTabRoutes, AMHPAssessmentsRoutes, RouteKeys.CreateAssessmentScreen> {}

export default function CreateAssessmentScreen(props: PropTypes) {
  const { route } = props;
  const [refreshRequired, setRefreshRequired] = useState(false);
  const setAssessment = useSetRecoilState(assessment);
  const duplicateAssessment = useRecoilValue(recoilDuplicateAssessment);
  const isWebView = mqWeb();
  const hasAssessment = Boolean(route.params?.assessmentId);
  let pageTitle = isWebView ? "Create Assessment" : `Create ${"\n"}Assessment`;

  const backButtonConfig: BackButtonProps = {
    enabled: true,
    float: true,
    color: color.textWhite,
  };

  if (hasAssessment) {
    pageTitle = isWebView ? "Update Assessment" : `Update ${"\n"}Assessment`;
  }

  useOnScreenBlur(() => {
    setAssessment(null);
  });

  useOnScreenFocus(() => {
    setRefreshRequired(true);
  });

  return (
    <AppScreen
      contextLabel={formatLongDate()}
      pageTitle={pageTitle}
      backButton={backButtonConfig}
      testID="Create-Assessment__Screen"
    >
      <CreateAssessment
        refreshRequired={refreshRequired}
        onRefreshComplete={setRefreshRequired}
        assessmentId={route.params?.assessmentId}
        isDuplicate={duplicateAssessment !== null}
        editDoctor={route.params?.editDoctor}
      />
    </AppScreen>
  );
}

const screenOptions: ScreenOptions = {
  title: createScreenTitle("Create Assessment"),
};

CreateAssessmentScreen.screenOptions = screenOptions;
