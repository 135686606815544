import React, { useMemo, useRef, useState } from "react";
import { StyleSheet, TextInput, TouchableOpacity, View } from "react-native";

import { LocationBottomSheetData } from "@/utils/recoil/props";
import { LocationSearchItem } from "@/components/LocationSearch/LocationSearch.props";
import { useContentPadding, useIsWebView, useOnMount } from "@/hooks";

import Icon, { IconName } from "@/components/Icon";
import { palette } from "@/theme";

interface LocationSearchBarProps {
  searchQuery: string;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
  setSearchResults: React.Dispatch<React.SetStateAction<LocationSearchItem[]>>;
  locationData: LocationBottomSheetData;
  closeBottomSheet: () => void;
}

export const LocationSearchBar = (props: LocationSearchBarProps) => {
  // State
  const [searchHasFocus, setSearchHasFocus] = useState<boolean>(false);
  const contentPadding = useContentPadding();

  // Hook values
  const isWebView = useIsWebView();

  // Refs
  const searchTextInputRef = useRef<TextInput>(null);

  const searchBarStyles = useMemo(
    () => [
      styles.searchBar,
      searchHasFocus ? styles.searchBarFocused : styles.searchBarBlurred,
      { paddingHorizontal: contentPadding },
    ],
    [searchHasFocus]
  );

  useOnMount(() => {
    if (searchTextInputRef.current && isWebView) {
      searchTextInputRef.current.focus();
    }
  });

  const onSearchButtonPress = () => {
    if (props.searchQuery.length > 0) {
      // reset search
      props.setSearchQuery("");
      props.setSearchResults(
        props.locationData.initialResults ? props.locationData.initialResults.map(r => ({ ...r, selected: true })) : []
      );
    } else {
      // search bar is empty; user must have clicked on search icon to search; let's focus the input for them
      if (searchTextInputRef.current) {
        searchTextInputRef.current.focus();
      }
    }
  };

  return (
    <View style={searchBarStyles}>
      <TouchableOpacity style={styles.backButton} onPress={props.closeBottomSheet}>
        <Icon size={20} name={IconName.arrow_back} color={palette.blue} />
      </TouchableOpacity>

      <TextInput
        value={props.searchQuery}
        style={styles.searchTextInput}
        onChangeText={val => props.setSearchQuery(val)}
        returnKeyType="done"
        onFocus={() => setSearchHasFocus(true)}
        onBlur={() => setSearchHasFocus(false)}
        placeholder="Search"
        placeholderTextColor={palette.placeholderText}
        ref={searchTextInputRef}
      />

      <TouchableOpacity style={styles.searchButton} onPress={onSearchButtonPress}>
        <Icon
          size={24}
          name={props.searchQuery.length > 0 ? IconName.refresh : IconName.search}
          color={props.searchQuery.length > 0 ? palette.blue : palette.greyBlue}
        />
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  backButton: {
    width: 35,
    justifyContent: "center",
    alignItems: "flex-start",
  },

  searchButton: {
    width: 35,
    justifyContent: "center",
    alignItems: "flex-end",
  },

  searchBar: {
    height: 70,
    backgroundColor: palette.white,
    flexDirection: "row",
  },
  searchBarBlurred: {
    shadowColor: palette.blueShadow,
    shadowRadius: 8,
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.5,
    elevation: 5,
  },
  searchBarFocused: {
    shadowColor: palette.blueShadow,
    shadowRadius: 10,
    shadowOffset: { width: 0, height: 8 },
    shadowOpacity: 0.75,
    elevation: 15,
  },

  searchTextInput: {
    height: 70,
    flex: 1,
    backgroundColor: palette.white,
    fontFamily: "Lato-Bold",
    fontSize: 20,
    color: palette.navy,
  },
});
