import * as Yup from "yup";
import { ComponentElement, FormFieldsAmhp } from "libs/types/mhaForms";

const componentElements: ComponentElement[] = [
  {
    component: "Text",
    componentProps: {
      instruction: "apply for the admission of",
      guidance: "(PRINT full name and address of patient)",
    },
    fullWidth: true,
  },
  {
    component: "TextInput",
    fieldName: FormFieldsAmhp.PatientName,
    componentProps: {
      label: "Name",
    },
    genericProps: {
      readonlyIfInitialValue: true,
    },
  },
  {
    component: "Location",
    fieldName: FormFieldsAmhp.PatientAddress,
    componentProps: {
      disableStrictPostcodeValidation: true,
      label: "Address",
      addressChangeWarning:
        "Patient address changes made here will automatically be applied to all unsigned forms for this assessment.",
    },
  },
  {
    component: "Text",
    componentProps: {
      instruction: "for assessment in accordance with Part 2 of the Mental Health Act 1983.",
    },
    fullWidth: true,
  },
  //  The following text was appearing on the final form in error. We need to find a mechanism to add this text
  // for info when the form is being created, but prevent it appearing on the form itself.
  // {
  //   component: "Text",
  //   componentProps: {
  //     guidance:
  //       "To edit the patient name in this form, please navigate to the ‘Assessment Details’ Screen and ‘Edit' then ‘Save Changes’ accordingly. The updated information will sync across all unsigned forms for this patient. Please note, if you update this information and doctors have signed their forms, they need to ‘remove signature’, update and resign. ",
  //   },
  //   fullWidth: true,
  // },
];

export default {
  title: "Patient Details",
  state: {
    patientName: "",
    patientAddress: {
      city: "",
      postcode: "",
      address: "",
    },
  },

  validation: Yup.object().shape({
    patientName: Yup.string().trim().required("Please enter the patient's name"),
    patientAddress: Yup.object().shape({
      city: Yup.string().nullable(),
      postcode: Yup.string().trim().required("Please enter an address"),
      address: Yup.string().nullable(),
    }),
  }),

  componentElements,
};
