import React from "react";
import { KeyboardAvoidingView, SafeAreaView, ScrollView, StyleSheet } from "react-native";

import Mod from "./modules";

import { palette, spacing } from "../../theme";

interface ExternalAuthScreenProps {
  children: React.ReactNode;
}

export const ExternalAuthScreen = (props: ExternalAuthScreenProps) => {
  return (
    <>
      <Mod.TopLogoArea />
      <SafeAreaView style={styles.content}>
        <KeyboardAvoidingView behavior="padding" style={styles.keyboardAvoidingView}>
          <ScrollView
            keyboardDismissMode="interactive"
            keyboardShouldPersistTaps="always"
            overScrollMode="never"
            bounces={false}
            style={styles.scrollView}
          >
            <>{props.children}</>
          </ScrollView>
        </KeyboardAvoidingView>
      </SafeAreaView>
    </>
  );
};

const styles = StyleSheet.create({
  content: {
    paddingTop: spacing[30],
    backgroundColor: palette.white,
    flex: 1,
  },

  keyboardAvoidingView: {
    flex: 1,
  },

  scrollView: {
    flex: 1,
  },
});
