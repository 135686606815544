import * as Yup from "yup";
import { ComponentElement, FormFieldsAmhp, Itemisation } from "libs/types/mhaForms";

const componentElements: ComponentElement[] = [
  {
    component: "Selection",
    fieldName: FormFieldsAmhp.NearestRelativeKnown,
    componentProps: {
      label: "Do you know who the nearest relative is?",
      options: [
        {
          id: "-",
          name: "-",
        },
        {
          id: "yes",
          name: "Yes",
        },
        {
          id: "no",
          name: "No",
        },
      ],
    },
  },
  {
    component: "Text",
    componentProps: {
      guidance: "Complete (a) or (b) as applicable",
      pdfOnlyGuidance:
        "Complete the following if you know who the nearest relative is.\nComplete (a) or (b) as applicable and delete the other.",
    },
    genericProps: {
      conditionallyRenderedBy: FormFieldsAmhp.NearestRelativeKnown,
      conditionallyRenderedValues: ["yes"],
    },
    fullWidth: true,
  },
  {
    component: "RadioOptionsList",
    fieldName: FormFieldsAmhp.DegreeOfCertainty,
    componentProps: {
      options: [
        {
          label: "To the best of my knowledge and belief (PRINT full name and address)",
          value: "knowledge",
          prefix: "(a)",
        },
      ],
    },
    genericProps: {
      nullable: true,
      conditionallyDisabledBy: FormFieldsAmhp.NearestRelativeKnown,
      conditionallyDisabledValues: ["no"],
      conditionallyRenderedBy: FormFieldsAmhp.NearestRelativeKnown,
      conditionallyRenderedValues: ["yes"],
      indentation: 1,
    },
  },
  {
    component: "TextInput",
    fieldName: FormFieldsAmhp.KnownRelativeName,
    componentProps: {
      label: "Name",
    },
    genericProps: {
      conditionallyRenderedBy: FormFieldsAmhp.NearestRelativeKnown,
      conditionallyRenderedValues: ["yes"],
      conditionallyDisabledBy: FormFieldsAmhp.DegreeOfCertainty,
      conditionallyDisabledValues: ["understanding"],
      indentation: 2,
    },
  },
  {
    component: "Location",
    fieldName: FormFieldsAmhp.KnownRelativeAddress,
    componentProps: {
      disableStrictPostcodeValidation: true,
      label: "Address",
    },
    genericProps: {
      conditionallyRenderedBy: FormFieldsAmhp.NearestRelativeKnown,
      conditionallyRenderedValues: ["yes"],
      conditionallyDisabledBy: FormFieldsAmhp.DegreeOfCertainty,
      conditionallyDisabledValues: ["understanding"],
      indentation: 2,
    },
  },
  {
    component: "Text",
    componentProps: {
      instruction: "is the patient’s nearest relative within the meaning of the Act.",
    },
    genericProps: {
      conditionallyRenderedBy: FormFieldsAmhp.NearestRelativeKnown,
      conditionallyRenderedValues: ["yes"],
      conditionallyDisabledBy: FormFieldsAmhp.DegreeOfCertainty,
      conditionallyDisabledValues: ["understanding"],
      indentation: 2,
    },
    fullWidth: true,
  },
  {
    component: "RadioOptionsList",
    fieldName: FormFieldsAmhp.DegreeOfCertainty,
    componentProps: {
      itemisation: Itemisation.ALPHABETICAL,
      options: [
        {
          label: "I understand that (PRINT full name and address)",
          value: "understanding",
          prefix: "(b)",
        },
      ],
    },
    genericProps: {
      nullable: true,
      conditionallyDisabledBy: FormFieldsAmhp.NearestRelativeKnown,
      conditionallyDisabledValues: ["no"],
      conditionallyRenderedBy: FormFieldsAmhp.NearestRelativeKnown,
      conditionallyRenderedValues: ["yes"],
      indentation: 1,
    },
  },
  {
    component: "TextInput",
    fieldName: FormFieldsAmhp.SuspectedRelativeName,
    componentProps: {
      label: "Name",
    },
    genericProps: {
      conditionallyRenderedBy: FormFieldsAmhp.NearestRelativeKnown,
      conditionallyRenderedValues: ["yes"],
      conditionallyDisabledBy: FormFieldsAmhp.DegreeOfCertainty,
      conditionallyDisabledValues: ["knowledge"],
      indentation: 2,
    },
  },
  {
    component: "Location",
    fieldName: FormFieldsAmhp.SuspectedRelativeAddress,
    componentProps: {
      disableStrictPostcodeValidation: true,
      label: "Address",
    },
    genericProps: {
      conditionallyRenderedBy: FormFieldsAmhp.NearestRelativeKnown,
      conditionallyRenderedValues: ["yes"],
      conditionallyDisabledBy: FormFieldsAmhp.DegreeOfCertainty,
      conditionallyDisabledValues: ["knowledge"],
      indentation: 2,
    },
  },
  {
    component: "Text",
    componentProps: {
      instruction: "has been authorised by",
    },
    genericProps: {
      conditionallyRenderedBy: FormFieldsAmhp.NearestRelativeKnown,
      conditionallyRenderedValues: ["yes"],
      conditionallyDisabledBy: FormFieldsAmhp.DegreeOfCertainty,
      conditionallyDisabledValues: ["knowledge"],
      indentation: 2,
    },
    fullWidth: true,
  },
  {
    component: "RadioOptionsList",
    fieldName: FormFieldsAmhp.AuthorisedBy,
    componentProps: {
      itemisation: Itemisation.ROMAN,
      options: [
        {
          label: "a county court",
          value: "countyCourt",
        },
        {
          label: "the patient's nearest relative",
          value: "nearestRelative",
        },
      ],
    },
    genericProps: {
      nullable: true,
      conditionallyRenderedBy: FormFieldsAmhp.NearestRelativeKnown,
      conditionallyRenderedValues: ["yes"],
      conditionallyDisabledBy: FormFieldsAmhp.DegreeOfCertainty,
      conditionallyDisabledValues: ["knowledge"],
      indentation: 2,
    },
  },
  {
    component: "Text",
    componentProps: {
      instruction: "to exercise the functions under the Act of the patient’s nearest relative.",
      guidance: "(Select the phrase which applies)",
      pdfOnlyGuidance: "(* Delete the phrase which does not apply)",
    },
    genericProps: {
      conditionallyRenderedBy: FormFieldsAmhp.NearestRelativeKnown,
      conditionallyRenderedValues: ["yes"],
      conditionallyDisabledBy: FormFieldsAmhp.DegreeOfCertainty,
      conditionallyDisabledValues: ["knowledge"],
      indentation: 2,
    },
    fullWidth: true,
  },

  {
    component: "RadioOptionsList",
    fieldName: FormFieldsAmhp.Informed,
    componentProps: {
      itemisation: Itemisation.ROMAN,
      options: [
        {
          label: "I have",
          value: "true",
        },
        {
          label: "I have not yet",
          value: "false",
        },
      ],
    },
    genericProps: {
      nullable: true,
      conditionallyRenderedBy: FormFieldsAmhp.NearestRelativeKnown,
      conditionallyRenderedValues: ["yes"],
    },
  },
  {
    component: "Text",
    componentProps: {
      instruction:
        "informed that person that this application is to be made and of the nearest relative’s power to order the discharge of the patient.",
      guidance: "(Select the phrase which applies)",
      pdfOnlyGuidance: "(* Delete the phrase which does not apply)",
    },
    genericProps: {
      conditionallyRenderedBy: FormFieldsAmhp.NearestRelativeKnown,
      conditionallyRenderedValues: ["yes"],
    },
    fullWidth: true,
  },
  {
    component: "Text",
    componentProps: {
      guidance: "Select (a) or (b)",
      pdfOnlyGuidance: "Complete the following if you do not know who the nearest relative is.\nDelete (a) or (b).",
    },
    genericProps: {
      conditionallyRenderedBy: FormFieldsAmhp.NearestRelativeKnown,
      conditionallyRenderedValues: ["no"],
    },
    fullWidth: true,
  },
  {
    component: "RadioOptionsList",
    fieldName: FormFieldsAmhp.NoNearestRelatives,
    componentProps: {
      itemisation: Itemisation.ALPHABETICAL,
      options: [
        {
          label: "I have been unable to ascertain who is the patient’s nearest relative within the meaning of the Act.",
          value: "unableToAscertain",
        },
        {
          label:
            "To the best of my knowledge and belief this patient has no nearest relative within the meaning of the Act.",
          value: "bestKnowledge",
        },
      ],
    },
    genericProps: {
      nullable: true,
      conditionallyRenderedBy: FormFieldsAmhp.NearestRelativeKnown,
      conditionallyRenderedValues: ["no"],
      indentation: 1,
    },
  },
  {
    component: "Text",
    componentProps: {
      pdfOnlyGuidance: "The remainder of the form must be completed in all cases.",
    },
  },
];

export default {
  title: "Nearest Relative",
  state: {
    nearestRelativeKnown: "-",
    degreeOfCertainty: "",
    knownRelativeName: "",
    knownRelativeAddress: {
      city: "",
      postcode: "",
      address: "",
    },
    suspectedRelativeName: "",
    suspectedRelativeAddress: {
      city: "",
      postcode: "",
      address: "",
    },
    authorisedBy: "",
    informed: "",
    noNearestRelatives: "",
  },

  validation: Yup.object().shape({
    nearestRelativeKnown: Yup.mixed().oneOf(["yes", "no"], "Please select a valid option"),
    degreeOfCertainty: Yup.mixed().when(["nearestRelativeKnown"], {
      is: (nearestRelativeKnown) => nearestRelativeKnown === "yes",
      then: Yup.mixed()
        .oneOf(["knowledge", "understanding"], "Please select either option a) or b)")
        .required("Please select either option a) or b)"),
      otherwise: Yup.string(),
    }),
    knownRelativeName: Yup.mixed().when(["nearestRelativeKnown", "degreeOfCertainty"], {
      is: (nearestRelativeKnown, degreeOfCertainty) =>
        nearestRelativeKnown === "yes" && degreeOfCertainty === "knowledge",
      then: Yup.string().trim().required("Please enter a name"),
      otherwise: Yup.string(),
    }),
    knownRelativeAddress: Yup.mixed().when(["nearestRelativeKnown", "degreeOfCertainty"], {
      is: (nearestRelativeKnown, degreeOfCertainty) =>
        nearestRelativeKnown === "yes" && degreeOfCertainty === "knowledge",
      then: Yup.object().shape({
        address: Yup.string().nullable(),
        city: Yup.string().nullable(),
        postcode: Yup.string().trim().required("Please enter an address"),
      }),
      otherwise: Yup.object().shape({
        address: Yup.string(),
        city: Yup.string(),
        postcode: Yup.string(),
      }),
    }),
    suspectedRelativeName: Yup.mixed().when(["nearestRelativeKnown", "degreeOfCertainty"], {
      is: (nearestRelativeKnown, degreeOfCertainty) =>
        nearestRelativeKnown === "yes" && degreeOfCertainty === "understanding",
      then: Yup.string().trim().required("Please enter a name"),
      otherwise: Yup.string(),
    }),
    suspectedRelativeAddress: Yup.mixed().when(["nearestRelativeKnown", "degreeOfCertainty"], {
      is: (nearestRelativeKnown, degreeOfCertainty) =>
        nearestRelativeKnown === "yes" && degreeOfCertainty === "understanding",
      then: Yup.object().shape({
        address: Yup.string().nullable(),
        city: Yup.string().nullable(),
        postcode: Yup.string().trim().required("Please enter an address"),
      }),
      otherwise: Yup.object().shape({
        address: Yup.string(),
        city: Yup.string(),
        postcode: Yup.string(),
      }),
    }),
    authorisedBy: Yup.mixed().when(["nearestRelativeKnown", "degreeOfCertainty"], {
      is: (nearestRelativeKnown, degreeOfCertainty) =>
        nearestRelativeKnown === "yes" && degreeOfCertainty === "understanding",
      then: Yup.mixed()
        .oneOf(["countyCourt", "nearestRelative"], "Please select a valid option")
        .required("Please select a valid option"),
      otherwise: Yup.string(),
    }),
    informed: Yup.mixed().when(["nearestRelativeKnown"], {
      is: (nearestRelativeKnown) => nearestRelativeKnown === "yes",
      then: Yup.mixed()
        .oneOf(["true", "false"], "Please select a valid option")
        .required("Please select a valid option"),
      otherwise: Yup.string(),
    }),
    noNearestRelatives: Yup.mixed().when(["nearestRelativeKnown"], {
      is: (nearestRelativeKnown) => nearestRelativeKnown === "no",
      then: Yup.mixed()
        .oneOf(["unableToAscertain", "bestKnowledge"], "Please select a valid option")
        .required("Please select a valid option"),
      otherwise: Yup.string(),
    }),
  }),
  componentElements,
};
