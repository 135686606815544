import React, { useState, useCallback } from "react";
import { redirectUserAfterLogin } from "../utils";

import { AppScreen } from "../../layouts/AppScreen";
import { useRecoilValue, useRecoilState } from "recoil";
import { userDetails, refreshClaimsRequired as recoilRefreshClaimsRequired } from "../../utils/recoil/index";

import { FIND_CLAIMS } from "@/models/Claim";
import { organisationIdFromPropsAndUser } from "./helpers";
import { RouteKeys } from "@/navigationv2";
import { CCGUnassignedClaimsRoutes, CCGTabRoutes, ScreenNavigationProps, ScreenOptions } from "@/navigationv2/types";
import { createScreenTitle } from "@/navigationv2/utilities";
import { useOnMount, useOnScreenFocus } from "@/hooks";

import { View } from "react-native";
import { Text } from "@/components";
import { TypographyType } from "@/models/Typography";

interface PropTypes
  extends ScreenNavigationProps<CCGTabRoutes, CCGUnassignedClaimsRoutes, RouteKeys.UnassignedClaimsScreen> {}

const WebOnlyClaimsScreen = (props: PropTypes) => {
  const [isUnmounted, setIsUnmounted] = useState(false);
  useOnMount(() => {
    redirectUserAfterLogin();
    return () => setIsUnmounted(true);
  });

  return (
    <>
      <AppScreen contextLabel="Claims" pageTitle="Web Only" testID="WebOnlyClaimsScreen">
        <View
          style={{
            flex: 1,
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: 16,
          }}
        >
          <Text format={TypographyType.HeadingMedium}>Claims is now web-only</Text>
          <Text format={TypographyType.Regular}>Please use the web-version of the app for claims processing.</Text>
        </View>
      </AppScreen>
    </>
  );
};

export default WebOnlyClaimsScreen;
