import * as React from "react";
import { Linking, View, StyleSheet } from "react-native";

import { TypographyType } from "@/models/Typography";
import Text from "../Text";
import { Phone as SquarePhoneButton, Email as SquareEmailButton } from "../SquareButton";
import { ContactBox } from "../InfoBox";
import { recordEvent } from "../../utils/analytics";
import { AnalyticsEvent } from "libs/analytics/events";
import { spacing } from "../../theme";

export function ContactUs() {
  return (
    <>
      <Text format={TypographyType.HeadingSmall}>Contact Us</Text>
      <View>
        <ContactBox
          style={styles.contactIconContainer}
          label="Phone"
          data="0808 168 0160"
          secondaryData={`Monday - Friday, 8:30am - 5:30pm\n(excl bank holidays)`}
          lastInList={true}
          onPress={() =>
            Linking.openURL("tel:08081680160")
              .then(() =>
                recordEvent(AnalyticsEvent.CONTACTED_SUPPORT, {
                  method: "phone",
                })
              )
              .catch(() => null)
          }
          icon={
            <SquarePhoneButton
            // do we care if the telephone fails to open?
            />
          }
        />
        <ContactBox
          style={styles.contactIconContainer}
          label="Email Address"
          data={`s12solutions-support@vitalhub.com`}
          secondaryData={`Monday - Friday, 8:30am - 5:30pm\n(excl bank holidays)`}
          lastInList={true}
          onPress={() =>
            // do we care if the mail app failed to open?
            Linking.openURL("mailto:s12solutions-support@vitalhub.com")
              .then(() =>
                recordEvent(AnalyticsEvent.CONTACTED_SUPPORT, {
                  method: "email",
                })
              )
              .catch(() => null)
          }
          icon={<SquareEmailButton />}
        />
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  contactIconContainer: {
    paddingTop: spacing[35],
  },
});
