import {
  AvailabilityModelActionTypes,
  AvailabilityControllerActionTypes,
  availabilityModelState,
  availabilityControllerState,
  StateModelProps,
  StateControllerProps,
} from "./initialState";
import { setWeekday, setEndOfWeek } from "libs/dates";
import dayjs from "dayjs";

// pretty straight forward reducer for the model
export function availabilityModelReducer(
  state: StateModelProps,
  { type, payload }: { type: string; payload?: any }
): StateModelProps {
  switch (type) {
    case AvailabilityModelActionTypes.ADD_AVAILABILITY:
      return addAvailability(state);
    case AvailabilityModelActionTypes.SET_AVAILABILITY:
      return {
        ...state,
        availabilities: [
          ...state.availabilities.slice(0, payload.index),
          {
            ...state.availabilities[payload.index],
            ...payload.data,
          },
          ...state.availabilities.slice(payload.index + 1),
        ],
      };
    case AvailabilityModelActionTypes.SET_ALL_AVAILABILITIES:
      return {
        ...state,
        availabilities: payload,
      };
    case AvailabilityModelActionTypes.DELETE_AVAILABILITY:
      return {
        ...state,
        availabilities: state.availabilities.filter((el, i) => i !== payload),
      };
    case AvailabilityModelActionTypes.RESET_AVAILABILITY_DATE:
      return {
        ...state,
        availabilities: [
          {
            date: dayjs(payload),
            startTime: {
              hours: dayjs().add(1, "hour").get("hour"),
              minutes: 0,
            },
            endTime: {
              hours: dayjs().add(2, "hour").get("hour"),
              minutes: 0,
            },
            hasError: false,
            postcodeError: false,
            touched: false,
          },
        ],
        repeatWeekdays: [setWeekday(dayjs(payload).day())],
        eventSeriesEndDate: setEndOfWeek(dayjs(payload)),
      };
    case AvailabilityModelActionTypes.SET_SINGLE_AVAILABILITY:
      return {
        ...state,
        availabilities: [state.availabilities[0]],
        repeatWeeklyCount: "1",
        repeatWeekdays: [setWeekday(dayjs(state.availabilities[0].date).day())],
      };
    case AvailabilityModelActionTypes.SET_DEFAULT_MHT:
      return {
        ...state,
        defaultMHT: payload,
      };
    case AvailabilityModelActionTypes.SET_DEFAULT_ROTA:
      return {
        ...state,
        defaultRota: payload,
      };
    case AvailabilityModelActionTypes.SET_DEFAULT_ONCALL:
      return {
        ...state,
        defaultOncall: payload,
      };
    case AvailabilityModelActionTypes.SET_BASE_POSTCODE:
      return {
        ...state,
        defaultPostcode: payload,
      };
    case AvailabilityModelActionTypes.SET_DEFAULT_NOTE:
      return {
        ...state,
        defaultNotes: payload,
      };
    case AvailabilityModelActionTypes.SET_REPEAT_WEEKDAYS:
      return {
        ...state,
        repeatWeekdays: payload,
      };
    case AvailabilityModelActionTypes.SET_END_DATE:
      return {
        ...state,
        eventSeriesEndDate: payload,
      };
    case AvailabilityModelActionTypes.REPEAT_WEEKLY_COUNT:
      return {
        ...state,
        repeatWeeklyCount: payload,
      };
    case AvailabilityModelActionTypes.RESET_FORM:
      return {
        ...availabilityModelState,
        repeatWeekdays: [setWeekday(dayjs(new Date()).day())],
      };
    case AvailabilityModelActionTypes.SET_EDIT_FORM:
      return payload;
    default:
      return state;
  }
}

// reducer for the controller / toggles etc
export function availabilityControllerReducer(
  state: StateControllerProps,
  { type, payload }: { type: string; payload?: any }
) {
  switch (type) {
    case AvailabilityControllerActionTypes.TOGGLE_SINGLE_ITEM:
      return {
        ...state,
        isSingleItem: payload,
        eventSeriesHasEnd: payload === "true" ? state.eventSeriesHasEnd : false,
      };
    case AvailabilityControllerActionTypes.TOGGLE_HAS_END_DATE:
      return {
        ...state,
        eventSeriesHasEnd: !state.eventSeriesHasEnd,
      };
    case AvailabilityControllerActionTypes.TOGGLE_REPEAT_WEEKLY:
      return {
        ...state,
        repeatWeekly: payload,
        eventSeriesHasEnd: payload !== "true",
      };
    case AvailabilityControllerActionTypes.SET_SAVING:
      return {
        ...state,
        isSaving: payload,
      };
    case AvailabilityControllerActionTypes.SET_ACTIVE_ELEMENT:
      return {
        ...state,
        activeElements: state.activeElements.includes(payload)
          ? state.activeElements.filter((i: number) => i !== payload)
          : [...state.activeElements, payload],
      };
    case AvailabilityControllerActionTypes.SET_POSTCODE_ERROR:
      return {
        ...state,
        postcodeError: payload,
        isSaving: payload ? false : state.isSaving,
        openErrorModal: payload ? true : state.openErrorModal,
      };
    case AvailabilityControllerActionTypes.SET_OPEN_ERROR_MODAL:
      return {
        ...state,
        openErrorModal: payload,
        isSaving: payload ? false : state.isSaving,
      };
    case AvailabilityControllerActionTypes.SET_SHOW_MORE_INFORMATION:
      return {
        ...state,
        showMoreInformation: !state.showMoreInformation,
      };
    case AvailabilityControllerActionTypes.RESET_CONTROLLER:
      return availabilityControllerState;
    case AvailabilityControllerActionTypes.SET_EDIT_CONTROLLER:
      return payload;
    default:
      return state;
  }
}

function addAvailability(state: StateModelProps): StateModelProps {
  const today = state.availabilities[0].date;
  const day = today.add(state.availabilities.length, "day");
  const now = dayjs();
  return {
    ...state,
    availabilities: [
      ...state.availabilities,
      {
        date: day,
        startTime: {
          hours: now.add(1, "hour").get("hour"),
          minutes: 0,
        },
        endTime: {
          hours: now.add(2, "hour").get("hour"),
          minutes: 0,
        },
        hasError: false,
        postcodeError: false,
        touched: false,
      },
    ],
  };
}
