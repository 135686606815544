import React, { forwardRef, useCallback, useRef, useState } from "react";
import { View, StyleSheet, Platform, Keyboard, StyleProp, ViewStyle } from "react-native";
import { TouchableRipple } from "react-native-paper";
import dayjs, { Dayjs } from "dayjs";

import DatePicker, { DatePickerPropTypes } from "./DatePicker";

import Text from "../Text";
import { TextInput } from "../TextInput/TextInput";

import { TypographyType } from "@/models/Typography";
import { color } from "../../theme";
import { mqWeb } from "../../utils/helpers";

export interface DualDatePickerProps {
  onValueChange: (v: Dayjs) => void;
  value: Dayjs | "";
  disabled?: boolean;
  error?: boolean;
  errorText?: string;
  inputColor?: string;
  label?: string;
  max?: Date;
  min?: Date;
  noIcon?: boolean;
  onBlur?: () => void;
  style?: StyleProp<ViewStyle>;
}

const DualDatePicker = forwardRef((props: DualDatePickerProps, ref: (ref: any) => void) => {
  const inputRef = useRef<any>();
  const [open, setOpen] = useState(false);
  const [currentValue, setCurrentValue] = useState(props.value);

  const touchable = {
    paddingTop: 2,
    backgroundColor: props.inputColor || color.background,
  };
  const isWebView = mqWeb();

  const onPress = useCallback(() => {
    if (props.disabled) {
      return;
    }

    if (Platform.OS === "web") {
      inputRef.current && inputRef.current.focus();
    } else {
      setOpen(true);
      Keyboard.dismiss();
    }
  }, [props.disabled]);

  const onChange = useCallback(
    (newDate: Date) => {
      if (newDate) {
        const dayJsDate = dayjs(newDate);

        setCurrentValue(dayJsDate);
        props.onValueChange && props.onValueChange(dayJsDate);
      } else {
        setCurrentValue("");
      }
    },
    [props.onValueChange]
  );

  // MF: The icon adds jank in web view for claims export by date range modal
  // const iconProp = props.noIcon ? {} : { icon: "today" };

  const datePickerBaseProps = {
    disabled: props.disabled,
    label: props.label,
    max: props.max,
    min: props.min,
    open,
    onBlur: props.onBlur,
    onValueChange: onChange,
    setOpen,
    value: currentValue ? currentValue.toDate() : new Date(),
  };

  const datePickerProps =
    Platform.OS === "web"
      ? { ...datePickerBaseProps, value: !currentValue ? "" : datePickerBaseProps.value }
      : datePickerBaseProps;

  const renderTextInput = useCallback(
    p => {
      const { style, value } = p;
      return (
        <View style={styles.textContainer}>
          <TouchableRipple style={touchable} disabled={props.disabled} onPress={onPress}>
            <Text format={TypographyType.Regular} style={[style, Platform.OS === "web" && styles.webText]}>
              {value}
            </Text>
          </TouchableRipple>
          {!!props.label && (
            <Text format={TypographyType.Micro} style={styles.label} marginBottom={-5} color={color.textExtraLight}>
              {props.label}
            </Text>
          )}
        </View>
      );
    },
    [props.disabled, props.label, onPress]
  );

  return (
    <View style={[styles.container, isWebView ? styles.containerWeb : {}, props.style]}>
      <TextInput
        // {...iconProp}
        label={props.label}
        onIconPress={onPress}
        ref={ref}
        style={styles.left}
        disabled={props.disabled}
        value={currentValue ? dayjs(currentValue).format("DD/MM/YYYY") : currentValue}
        render={renderTextInput}
        error={props.error}
        errorText={props.errorText}
      />

      <DatePicker {...(datePickerProps as DatePickerPropTypes)} ref={inputRef} />
    </View>
  );
});

DualDatePicker.displayName = "DualDatePicker";

export default DualDatePicker;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    alignItems: "baseline",
  },
  containerWeb: {
    alignItems: "stretch",
    flexDirection: "column",
  },
  textContainer: {
    position: "relative",
  },
  label: {
    position: "absolute",
    top: 2,
    left: 12,
  },
  webText: {
    flexBasis: "25%",
  },
  left: {
    minWidth: 180,
  },
});
