import { Text } from "@/components";
import { CardLabel } from "@/components/CardLabel";
import { GenericCard } from "@/components/GenericCard";
import { IconName } from "@/components/Icon";
import { TypographyType } from "@/models/Typography";
import { color, timing } from "@/theme";
import { displayAvailabilityType } from "@/utils/helpers";
import { AvailabilityState, AvailabilityType } from "libs/types/availability";
import React, { useEffect, useState } from "react";
import { Animated, Dimensions, StyleSheet, View } from "react-native";

interface MapUserProps {
  user: {
    userName: string;
    userLocation?: string;
    distance: string;
    availabilityState?: AvailabilityState;
    nextAvailability: any;
  };
  additionalOptions?: { label: string; icon: IconName; onPress: () => void }[];
  onPress: () => void;
  isActive: boolean;
}

const MapUser: React.FC<MapUserProps> = ({ user, onPress, isActive, additionalOptions = [] }) => {
  const { userName, userLocation, distance, availabilityState, nextAvailability } = user;
  const [infoBoxPosition] = useState(new Animated.Value(200));

  useEffect(() => {
    Animated.timing(infoBoxPosition, {
      toValue: isActive ? 0 : 200,
      duration: timing.quick,
      useNativeDriver: true,
    }).start();
  }, [isActive]);

  return (
    <Animated.View
      style={[
        styles.infoBoxContainer,
        {
          transform: [{ translateY: infoBoxPosition }],
          width: Dimensions.get("screen").width - 20,
        },
      ]}
    >
      <GenericCard
        buttons={[
          {
            label: "Profile",
            icon: "person",
            onPress: onPress,
          },
        ].concat(additionalOptions)}
      >
        <View style={styles.doctorCardTextLabel}>
          <View style={styles.doctorCardText}>
            {!!userName && <Text format={TypographyType.RegularBold}>{userName}</Text>}
            {!!userLocation && (
              <View style={styles.row}>
                <Text format={TypographyType.Tiny} style={styles.textLight}>
                  {`${userLocation} ${" "}${"\u00b7"}${" "} ${distance} miles away`}
                </Text>
              </View>
            )}
            {nextAvailability && nextAvailability.availability.type === AvailabilityType.trust && (
              <View style={styles.row}>
                <Text format={TypographyType.Tiny} style={styles.textLight}>
                  {nextAvailability && nextAvailability.availability.mht
                    ? `MHT: ${nextAvailability.availability.mht.abbreviation}`
                    : ""}
                </Text>
              </View>
            )}
          </View>
          {!!availabilityState && (
            <CardLabel text={displayAvailabilityType(availabilityState)} color={availabilityState} />
          )}
        </View>
      </GenericCard>
    </Animated.View>
  );
};

export default MapUser;

const styles = StyleSheet.create({
  infoBoxContainer: {
    maxWidth: 400,
    backgroundColor: color.background,
  },
  row: {
    flexDirection: "row",
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
  },
  doctorCardTextLabel: {
    flexDirection: "row",
    flex: 1,
    justifyContent: "space-between",
  },
  doctorCardText: {
    flexShrink: 1,
    flexWrap: "wrap",
  },
  textLight: {
    color: color.textLight,
  },
});
