import React, { useMemo, useState } from "react";
import { Animated, Easing, Platform, StyleSheet, TouchableOpacity, View } from "react-native";
import { Hoverable } from "react-native-web-hooks";

import { TypographyType } from "@/models/Typography";
import { palette, spacing } from "@/theme";

import { ContentWrap } from "@/components/ContentWrap";
import Icon, { IconName } from "@/components/Icon";
import Text from "@/components/Text";
import { useIsWebView } from "@/hooks";

interface HeaderProps {
  heading: string;
  closeSheet: () => void;
  headingIconProps?: {
    name: IconName;
    color: string;
  };
}

export const Header = ({ closeSheet, heading, headingIconProps }: HeaderProps) => {
  const [closeBtnBGScale] = useState(new Animated.Value(0));
  const [closeBtnBGOpacity] = useState(new Animated.Value(0));
  const closeButtonBGStyles = useMemo(
    () => [styles.closeButtonBG, { transform: [{ scale: closeBtnBGScale }], opacity: closeBtnBGOpacity }],
    [closeBtnBGOpacity, closeBtnBGScale]
  );
  const isWebView = useIsWebView();

  const _onMouseEnterCloseButton = () => {
    Animated.parallel([
      Animated.timing(closeBtnBGScale, {
        toValue: 1.75,
        easing: Easing.out(Easing.linear),
        duration: 125,
        useNativeDriver: true,
      }),
      Animated.timing(closeBtnBGOpacity, {
        toValue: 1,
        easing: Easing.out(Easing.linear),
        duration: 125,
        useNativeDriver: true,
      }),
    ]).start();
  };

  const _onMouseLeaveCloseButton = () => {
    Animated.parallel([
      Animated.timing(closeBtnBGScale, {
        toValue: 0,
        easing: Easing.out(Easing.linear),
        duration: 125,
        useNativeDriver: true,
      }),
      Animated.timing(closeBtnBGOpacity, {
        toValue: 0,
        easing: Easing.out(Easing.linear),
        duration: 125,
        useNativeDriver: true,
      }),
    ]).start();
  };

  return (
    <View style={styles.header}>
      <ContentWrap style={[styles.row, !isWebView && { paddingRight: 25 }]}>
        {!!headingIconProps && <Icon {...headingIconProps} size={42} />}
        <Text format={TypographyType.HeadingMedium} color={palette.navy} style={styles.heading}>
          {heading}
        </Text>
        <Hoverable onHoverIn={_onMouseEnterCloseButton} onHoverOut={_onMouseLeaveCloseButton}>
          {() => (
            <TouchableOpacity onPress={closeSheet} style={styles.closeButton}>
              <Icon name={IconName.close} size={28} color={palette.blue} />
              <Animated.View style={closeButtonBGStyles} />
            </TouchableOpacity>
          )}
        </Hoverable>
      </ContentWrap>
    </View>
  );
};

const styles = StyleSheet.create({
  closeButton: {
    width: 36,
    height: 36,
    justifyContent: "center",
    alignItems: "center",
    ...Platform.select({
      web: {
        cursor: "pointer",
      },
    }),
  },
  closeButtonBG: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: palette.cloud,
    borderRadius: 36,
    zIndex: -1,
  },

  header: {
    paddingVertical: spacing[30],
    backgroundColor: palette.white,
  },

  heading: {
    marginBottom: 0,
    flex: 1,
  },

  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
});
