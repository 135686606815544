import * as Sentry from "@sentry/browser";
import Config from "../config";
import { version } from "../../package.json";
export * from "@sentry/browser";

if (Config.ENABLE_SENTRY) {
  Sentry.init({
    dsn: "https://c66aa883e9f048229e8d411b4f674a03@exceptions.s12solutions.com/2",
    environment: Config.ENV,
    release: version + "-" + Config.ENV,
  });
}
