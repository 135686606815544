/* eslint-disable react-native/no-unused-styles */
/* ^ This is set because styles are selected dynamically, based on a prop; lack of explicit reference of styles causes a false "no-unused-styles" warning, hence the disable flag above. */

import React, { useEffect } from "react";
import {
  Animated,
  Easing,
  Platform,
  SafeAreaView,
  StyleSheet,
  View,
  Text as RNText,
  StatusBar,
  ScrollView,
  KeyboardAvoidingView,
} from "react-native";
import { useDeviceWidth, useVMax } from "@/utils/helpers";

import { Button } from "@/components/Button";
import BackButton from "@/components/BackButton/BackButton";
import { ContentWrap } from "@/components/ContentWrap";
import { Logo } from "@/components/Logo";
import Text from "@/components/Text";

import { AuthScreenProps } from "@/models/AuthScreen";
import { TypographyType } from "@/models/Typography";
import { color, fontSizes, spacing, typography } from "@/theme";

const isTest = process?.env?.NODE_ENV === "test";

export const Mobile = (props: AuthScreenProps) => {
  const { backButton, buttons = [], children, welcome = false, testID } = props;
  const vmax = useVMax();
  const deviceWidth = useDeviceWidth();

  const logoAnimatedValue = new Animated.Value(-50);
  const logoAnimatedStyle = {
    transform: [{ translateY: logoAnimatedValue }],
  };

  // TODO: Animations on bottom buttons are disabled temp,
  // causes 3rd button to the support page to not appear on devices.
  const buttonsAnimatedValue = new Animated.Value(isTest ? 0 : 0);
  const buttonsAnimatedStyle = {
    transform: [{ translateY: buttonsAnimatedValue }],
  };

  useEffect(() => {
    if (isTest) {
      return;
    }
    Animated.timing(logoAnimatedValue, {
      toValue: 0,
      duration: 1000,
      easing: Easing.out(Easing.cubic),
      useNativeDriver: true,
    }).start();

    Animated.timing(buttonsAnimatedValue, {
      toValue: 0,
      duration: 1000,
      easing: Easing.out(Easing.cubic),
      useNativeDriver: true,
    }).start();
  }, []);

  const logoType = deviceWidth <= 320 ? "Small" : "Standard";

  const showBackButton = backButton && backButton.enabled && Platform.OS !== "web";

  const actions = buttons.map(({ label, onPress, disabled = false, textButton = false, testID }, key, buttons) => {
    const marginBottom = key === buttons.length - 1 ? 0 : 15;
    if (textButton) {
      return (
        <Text
          key={key}
          format={TypographyType.TinyBold}
          onPress={onPress}
          style={[styles.textButton, { marginBottom }]}
          testID={`AuthScreen__Action-Link__${testID}`}
        >
          {label}
        </Text>
      );
    }

    return (
      <Button
        key={key}
        disabled={disabled}
        onPress={onPress}
        style={[styles.button, { marginBottom }]}
        testID={`AuthScreen__Action-Button__${testID}`}
      >
        {label}
      </Button>
    );
  });

  return (
    <View style={[styles.container, styles.topWrapper]} testID={`AuthScreen__${testID}`}>
      <View style={styles.backgroundTop} />
      <SafeAreaView style={styles.container}>
        <KeyboardAvoidingView behavior="padding" style={styles.container}>
          <ScrollView
            keyboardDismissMode="interactive"
            keyboardShouldPersistTaps="always"
            overScrollMode="never"
            bounces={false}
            contentContainerStyle={styles.scrollview}
            ref={() => undefined}
          >
            {showBackButton && props.backButton && <BackButton {...props.backButton} />}
            <View style={styles.content}>
              <View style={styles.main}>
                <Animated.View
                  style={[
                    styles.logoArea,
                    logoAnimatedStyle,
                    welcome && styles.logoAreaWelcome,
                    // eslint-disable-next-line
                    !welcome && deviceWidth <= 320 && { height: 180 },
                  ]}
                >
                  <View style={[styles.logoOuterCircle, styles[`logoOuterCircle${logoType}`]]}>
                    <View style={[styles.logoInnerCircle, styles[`logoInnerCircle${logoType}`]]}>
                      <Logo style={styles[`logo${logoType}`]} />
                    </View>
                  </View>
                  {welcome && [
                    <RNText key="welcome-screen-app-name" style={styles.appName}>
                      S12 Solutions
                    </RNText>,
                    <RNText key="welcome-screen-app-tagline" style={styles.appTagline}>
                      Connecting AMHPs and s.12 doctors
                    </RNText>,
                  ]}

                  <View style={styles.blueBgContainer}>
                    <View
                      style={[
                        styles.blueBg,
                        // eslint-disable-next-line
                        {
                          width: vmax * 1.25,
                          height: vmax * 1.25,
                          borderRadius: Platform.OS !== "web" ? vmax * 1.25 : 0,
                        },
                      ]}
                    />
                  </View>
                </Animated.View>

                {!welcome && <ContentWrap style={styles.children}>{children}</ContentWrap>}
              </View>

              {buttons && buttons.length > 0 && (
                <Animated.View style={[styles.buttons, buttonsAnimatedStyle]}>
                  <ContentWrap>{actions}</ContentWrap>
                </Animated.View>
              )}
            </View>
          </ScrollView>
        </KeyboardAvoidingView>
      </SafeAreaView>
    </View>
  );
};

const styles: { [key: string]: any } = StyleSheet.create({
  appName: {
    marginTop: spacing[40],
    color: color.textWhite,
    fontSize: fontSizes.h2,
    fontFamily: typography.secondary.medium,
    textTransform: "uppercase",
    zIndex: 2,
  },

  appTagline: {
    marginTop: spacing[30],
    color: color.textWhite,
    textAlign: "center",
    fontSize: fontSizes.regular,
    lineHeight: 28,
    fontFamily: typography.secondary.medium,
    zIndex: 2,
  },

  backgroundTop: {
    position: "absolute",
    width: "100%",
    height: 100,
    backgroundColor: color.primary,
    top: 0,
    left: 0,
  },

  blueBgContainer: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    height: "100%",
    backgroundColor: color.background,
    overflow: "hidden",
    alignItems: "center",
    justifyContent: "flex-end",
  },

  blueBg: {
    backgroundColor: color.primary,
    borderWidth: 20,
    borderColor: color.primaryBorderFrosted,
  },

  buttons: {
    alignItems: "center",
    paddingTop: spacing[40],
    paddingBottom: spacing[30],
    backgroundColor: color.background,
  },

  button: {
    marginBottom: 0,
  },

  children: {
    paddingTop: spacing[50],
  },

  container: {
    flex: 1,
  },

  content: {
    flexGrow: 1,
    justifyContent: "flex-start",
    backgroundColor: color.background,
  },

  logoArea: {
    height: 250 + ((Platform.OS === "android" && StatusBar.currentHeight) || 0),
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    overflow: "hidden",
  },

  logoAreaWelcome: {
    flex: 1,
    height: "auto",
  },

  logoOuterCircle: {
    zIndex: 2,
    borderWidth: 10,
    borderRadius: 100,
    marginTop: -20,
    borderColor: color.logoAuthOuterBorder,
  },

  logoInnerCircle: {
    padding: spacing[20],
    borderWidth: 10,
    borderRadius: 100,
    borderColor: color.logoAuthInnerBorder,
    backgroundColor: color.background,
  },

  main: {
    position: "relative",
    flex: 1,
    backgroundColor: color.background,
    overflow: "hidden",
  },

  scrollview: {
    flexGrow: 1,
    justifyContent: "center",
  },

  textButton: {
    textAlign: "center",
  },

  /* Dynamic logo styles */
  logoSmall: {
    width: 45,
    height: 45,
  },
  logoOuterCircleSmall: {
    borderWidth: 8,
  },
  logoInnerCircleSmall: {
    padding: spacing[10],
    borderWidth: 8,
  },

  logoStandard: {
    width: 70,
    height: 70,
  },

  topWrapper: {
    backgroundColor: color.background,
    position: "relative",
  },
});
