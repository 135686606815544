import React from "react";
import { ButtonProps } from "react-native-paper/typings/components/Button";
import { Button as RNPButton, Text } from "react-native-paper";
import { StyleSheet, ViewStyle } from "react-native";

import Icon from "../Icon";
import { color, palette } from "../../theme";

interface SquareButtonProps extends ButtonProps {
  icon: string;
  iconColor?: string;
}

export default function SquareButton(props: SquareButtonProps) {
  let { icon, iconColor, style, mode = "contained", ...restProps } = props;

  const baseStyle: ViewStyle = {
    width: 60,
    height: 60,
    flexBasis: "auto",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
    borderRadius: 20,
  };

  const styles: {
    [key: string]: any;
    contained: Record<string, unknown>;
  } = StyleSheet.create({
    // eslint-disable-next-line react-native/no-unused-styles
    contained: {
      ...baseStyle,
      backgroundColor: color.secondary,
    },
  });

  const parentContentstyle = StyleSheet.create({
    buttonContainer: {
      padding: 4,
      borderRadius: 40,
    },
  });

  style = [styles.contained, props.style];

  return (
    <RNPButton
      {...restProps}
      style={style}
      contentStyle={parentContentstyle.buttonContainer}
      mode={mode as ButtonProps["mode"]}
    >
      <Icon name={icon} color={iconColor || palette.white} size={24} />
    </RNPButton>
  );
}

export const Phone = (props: { onPress?: () => void; mode?: any }) => {
  const { mode = "contained" } = props;
  return (
    <SquareButton icon="call" onPress={props.onPress} mode={mode}>
      <Text>Call</Text>
    </SquareButton>
  );
};

export const Mobile = (props: { onPress?: () => void; mode?: any }) => {
  const { mode = "contained" } = props;
  return (
    <SquareButton icon="phone-android" onPress={props.onPress} mode={mode}>
      <Text>Call</Text>
    </SquareButton>
  );
};

export const Email = (props: { onPress?: () => void; mode?: any }) => {
  const { mode = "contained" } = props;
  return (
    <SquareButton icon="mail-outline" onPress={props.onPress} mode={mode}>
      <Text>Call</Text>
    </SquareButton>
  );
};

export const Map = (props: { onPress?: () => void; mode?: any }) => {
  const { mode = "contained" } = props;
  return (
    <SquareButton icon="map" onPress={props.onPress} mode={mode}>
      <Text>Map</Text>
    </SquareButton>
  );
};
