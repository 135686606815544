import React from "react";
import { View, StyleSheet } from "react-native";

import { TimelineWrapper, TimelineElement } from "../Timeline";
import Text from "../Text";

import { TypographyType } from "@/models/Typography";
import { sortClaimNotes } from "../../utils/helpers";
import { color } from "../../theme";
import { formatOrdinalDate } from "libs/dates/format";

const ClaimHistory = (props: { claimNotes: string[] }) => {
  // TODO: The map here to a type could be standarised into a Timeline component and helpers
  const notes = sortClaimNotes(props.claimNotes).map(claimNote => {
    const [note, author, date, ...additionalNote] = claimNote.split("@");
    return {
      mainText: note,
      mainSmallText: `(${author})`,
      bottomText: formatOrdinalDate(date),
      additionalNote: additionalNote.join("@"),
    };
  });
  return (
    <TimelineWrapper>
      <>
        {notes.map((n, i) => (
          <TimelineElement filled={i === notes.length - 1} lastElement={i === notes.length - 1} key={i}>
            <View style={styles.textContainer}>
              <View style={styles.noteWrapper}>
                <Text format={TypographyType.Regular}>{n.mainText}</Text>
                {!!n.mainSmallText && <Text format={TypographyType.Small}> {n.mainSmallText}</Text>}
              </View>
              {!!n.additionalNote && <Text format={TypographyType.Small}>{n.additionalNote}</Text>}
              {!!n.bottomText && (
                <Text format={TypographyType.Micro} style={styles.date}>
                  {n.bottomText}
                </Text>
              )}
            </View>
          </TimelineElement>
        ))}
      </>
    </TimelineWrapper>
  );
};

export default ClaimHistory;

const styles = StyleSheet.create({
  textContainer: {
    flex: 1,
    marginTop: -8,
  },
  noteWrapper: {
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
  },
  date: {
    color: color.textExtraLight,
  },
});
