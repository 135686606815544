import { Platform } from "react-native";

export const typography = {
  /**
   * The primary font.  Used in most places.
   */
  primary: Platform.select({ ios: "System", android: "System" }),

  /**
   * An alternate font used for perhaps titles and stuff.
   */
  secondary: Platform.select({
    ios: {
      regular: "Dosis-Regular",
      medium: "Dosis-Medium",
      semiBold: "Dosis-SemiBold",
    },
    android: {
      regular: "Dosis-Regular",
      medium: "Dosis-Medium",
      semiBold: "Dosis-SemiBold",
    },
    default: {
      regular: "Dosis-Regular",
      medium: "Dosis-Medium",
      semiBold: "Dosis-SemiBold",
    },
  }),
};

export const fontSizes: { [key: string]: number } = {
  h1: 36,
  h2: 30,
  h3: 24,
  button: 20,
  regular: 18,
  small: 16,
  tiny: 14,
  micro: 12,
};

export const fontStyles = {
  h1: {
    fontFamily: "Lato-Bold",
    fontSize: fontSizes.h1,
    lineHeight: 44,
  },
  h2: {
    fontFamily: "Lato-Bold",
    fontSize: fontSizes.h2,
    lineHeight: 36,
  },
  h3: {
    fontFamily: "Lato-Bold",
    fontSize: fontSizes.h3,
    lineHeight: 30,
  },
  button: {
    fontFamily: "Lato-Bold",
    fontSize: fontSizes.button,
    lineHeight: 21,
  },
  regular: {
    fontFamily: "Lato-Regular",
    fontSize: fontSizes.regular,
    lineHeight: 28,
  },
  regularBold: {
    fontFamily: "Lato-Bold",
    fontSize: fontSizes.regular,
    lineHeight: 28,
  },
  small: {
    fontFamily: "Lato-Regular",
    fontSize: fontSizes.small,
    lineHeight: 24,
  },
  smallBold: {
    fontFamily: "Lato-Bold",
    fontSize: fontSizes.small,
    lineHeight: 24,
  },
  tiny: {
    fontFamily: "Lato-Regular",
    fontSize: fontSizes.tiny,
    lineHeight: 22,
  },
  tinyBold: {
    fontFamily: "Lato-Bold",
    fontSize: fontSizes.tiny,
    lineHeight: 22,
  },
  micro: {
    fontFamily: "Lato-Regular",
    fontSize: fontSizes.micro,
    lineHeight: 20,
  },
  microBold: {
    fontFamily: "Lato-Bold",
    fontSize: fontSizes.micro,
    lineHeight: 20,
  },
};

const fonts = Platform.select({
  ios: {
    regular: "Lato-Regular",
    medium: "Lato-Bold",
    thin: "Lato-Light",
    light: "Lato-Light",
  },
  android: {
    regular: "Lato-Regular",
    medium: "Lato-Bold",
    thin: "Lato-Light",
    light: "Lato-Light",
  },
  default: {
    regular: "Lato-Regular",
    medium: "Lato-Bold",
    thin: "Lato-Light",
    light: "Lato-Light",
  },
});

export default fonts;
