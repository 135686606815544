import React from "react";
import { useNavigation } from "@react-navigation/native";

import { StackNavigationProp } from "@react-navigation/stack";

import { MeetingRoutes } from "@/navigationv2/types";

import { PermissionsHelp } from "./components";
import { DeviceSelection } from "./DeviceSelection";

import { SectionDivider } from "../SectionDivider";

interface PropTypes {
  assessmentId: string;
}

const PreviewScreen = (props: PropTypes) => {
  const navigation = useNavigation<StackNavigationProp<MeetingRoutes>>();

  const onDeviceSelectionPress = () => {
    navigation.replace("MeetingRoomScreen", {
      assessmentId: props.assessmentId,
    });
  };

  return (
    <>
      <DeviceSelection previewMode={true} onPress={onDeviceSelectionPress} mode="self" />
      <SectionDivider />
      <PermissionsHelp />
    </>
  );
};

export default PreviewScreen;
