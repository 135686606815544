import React, { useState, useMemo } from "react";
import { StyleSheet, View, Linking, Platform } from "react-native";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import dayjs from "dayjs";

import { ContentWrap } from "../../ContentWrap";
import { Button, ButtonList } from "../../Button";
import Select from "../../Select";
import ItemSpacer from "../../ItemSpacer";
import Loading from "../../../components/Loading";
import Text from "../../Text";
import AttachableFormItem from "../../UserDashboard/modules/AttachableFormItem";

import { saveForm as apiSaveForm } from "@/models/MHAForm/api";
import { TypographyType } from "@/models/Typography";
import { MHAFormMetadata, FormikValues } from "@/models/MHAForm/MHAForm";
import { spacing, palette } from "@/theme";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  userDetails,
  snackbarMessage,
  lastException,
  refreshDoctorFormInboxRequired,
  selectedAmhpTeamId,
  refreshDoctorDraftFormInboxRequired,
} from "@/utils/recoil";
import { reassignFormError, S12Error } from "@/models/Error";
import { FormStatus } from "libs/types/API";
import { ContactBox } from "@/components/InfoBox";
import { Mobile, Email } from "../../SquareButton/SquareButton.android";
import { InfoText } from "@/components/InfoText";
import { Checkbox } from "@/components/Checkbox/Checkbox";
import { AnalyticsEvent } from "libs/analytics/events";
import { recordEvent } from "@/utils/analytics";
import { amhpTeamInbox } from "libs/utils/featureFlags";
import { useAPIVersion, useBottomSheet } from "@/hooks";
import { API_V2_TOGGLES } from "@/api/types";

interface AssignForReviewProps {
  formData: FormikValues;
  metadata: Omit<MHAFormMetadata, "notes">;
  onSuccess?: () => void;
  onError?: () => void;
}

const amhpTeamQuery = gql`
  query GetSite($id: ID!) {
    getSite(id: $id) {
      amhpTeams {
        items {
          id
          name
          emails
          phoneNumbers
          mhaInboxInstructions
          featureFlags
        }
      }
    }
  }
`;

const AssignForReview = ({ formData, metadata, onSuccess }: AssignForReviewProps) => {
  const user = useRecoilValue(userDetails);
  const amhpTeamId = useRecoilValue(selectedAmhpTeamId);
  const setSelectedAmhpTeamId = useSetRecoilState(selectedAmhpTeamId);
  const setLastException = useSetRecoilState(lastException);
  const setDoctorFormInboxRefresh = useSetRecoilState(refreshDoctorFormInboxRequired);
  const setDoctorDraftFormInboxRefresh = useSetRecoilState(refreshDoctorDraftFormInboxRequired);
  const setMessage = useSetRecoilState(snackbarMessage);
  const { closeBottomSheet } = useBottomSheet();

  const [confirmation, setConfirmation] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [instructionsConfirmed, setInstructionsConfirmed] = useState(false);
  const [selectedAmhpTeam, setSelectedAmhpTeam] = useState(amhpTeamId);

  const { v2 } = useAPIVersion(API_V2_TOGGLES.MHA_FORMS);

  const patientName = Object.values(formData).find(f => f.patientName)?.patientName as string;
  const examinationDate = Object.values(formData).find(f => f.examinationDate)?.examinationDate as string;

  const { loading, error, data } = useQuery<{
    getSite: {
      amhpTeams: {
        items: {
          id: string;
          name: string;
          emails: string[] | null;
          phoneNumbers: string[] | null;
          mhaInboxInstructions: string | null;
          featureFlags: string;
        }[];
      };
    };
  }>(amhpTeamQuery, {
    variables: {
      id: user?.sites?.[0],
    },
    fetchPolicy: "cache-and-network",
  });

  // creating amhp team dropdown -> check if amhp team has amhpTeamInbox feature enabled &
  // is not the currently assigned team, to avoid user "reassigning" form to same team if the form is not unattached
  let amhpTeams;
  if (!!metadata.amhpTeamInboxId && metadata.amhpTeamInboxId !== "!!!") {
    amhpTeams = useMemo(
      () => [
        {
          name: "- Select -",
          id: "",
        },
        ...(data?.getSite.amhpTeams.items
          .filter(a => amhpTeamInbox(a.featureFlags))
          .filter(a => a.id !== metadata.amhpTeamId) || []),
      ],
      [data?.getSite.amhpTeams.items, metadata]
    );
  } else {
    amhpTeams = useMemo(
      () => [
        {
          name: "- Select -",
          id: "",
        },
        ...(data?.getSite.amhpTeams.items.filter(a => amhpTeamInbox(a.featureFlags)) || []),
      ],
      [data?.getSite.amhpTeams.items, metadata]
    );
  }
  const selectedTeam = amhpTeams[amhpTeams.findIndex(e => e.id === selectedAmhpTeam)];

  const displayReassignmentError = (amhpTeamId: string, message: string) => {
    closeBottomSheet();
    recordEvent(AnalyticsEvent.MHA_FORM_ATTACHED_TO_ASSESSMENT_FAILURE, {
      amhpTeamId,
      error: message,
    });
    const error = new S12Error(reassignFormError);
    setLastException(error);
  };

  const assignForm = () => {
    setSubmitted(true);
    const amhpTeamId = selectedTeam.id || "";

    try {
      apiSaveForm(
        v2,
        {
          ...metadata,
          amhpTeamId,
          amhpTeamInboxId: amhpTeamId,
          amhpTeamName: selectedTeam.name || "",
          authors: (user && [user.id]) || [],
          doctorNames: (user && [user.name]) || [],
          status: FormStatus.s60_signed_in_amhp_team_inbox,
          nextVersion: metadata.version + 1,
        },
        formData
      ).then(results => {
        if ("isError" in results && results.isError) {
          return displayReassignmentError(amhpTeamId, results.message);
        }

        setMessage("Form assigned to selected AMHP team");
        setDoctorFormInboxRefresh(true);
        setDoctorDraftFormInboxRefresh(true);
        onSuccess && onSuccess();
        recordEvent(AnalyticsEvent.MHA_FORM_ATTACHED_TO_ASSESSMENT, {
          amhpTeamId,
          amhpTeamName: selectedTeam.name || "",
          currentStatus: metadata.status,
          nextStatus: FormStatus.s60_signed_in_amhp_team_inbox,
        });
        setSelectedAmhpTeamId("");
      });
    } catch (e) {
      return displayReassignmentError(amhpTeamId, e.message);
    }
  };

  const reassign = !!metadata.amhpTeamInboxId && metadata.amhpTeamInboxId !== "!!!";

  return !confirmation ? (
    <ContentWrap>
      {loading ? (
        <Loading text="Loading AMHP Teams" />
      ) : error ? (
        <Text format={TypographyType.HeadingSmall} style={styles.smallHeading}>
          There was an error loading AMHP teams.
        </Text>
      ) : (
        <>
          <ItemSpacer gap={20}>
            <Select
              label="Select AMHP Team"
              value={selectedAmhpTeam}
              onValueChange={(_v, i) => {
                setSelectedAmhpTeam(_v);
              }}
              options={amhpTeams}
            />
          </ItemSpacer>
        </>
      )}
      <AttachableFormItem
        form={{
          id: "!!!",
          patientName,
          doctorName: user?.name || "",
          formType: metadata.type,
          ...(examinationDate && { examinationDate: dayjs(examinationDate) }),
        }}
      />
      <ButtonList>
        <Button onPress={() => setConfirmation(true)} disabled={selectedAmhpTeam === ""}>
          {reassign ? "Reassign Form" : "Assign Form"}
        </Button>
      </ButtonList>
    </ContentWrap>
  ) : (
    <ContentWrap>
      <View style={styles.confirmation}>
        <Text format={TypographyType.Regular}>{`Are you sure you want to ${
          reassign ? "reassign" : "assign"
        } the form to `}</Text>
        <Text format={TypographyType.RegularBold}>{selectedTeam.name || ""}</Text>
        <Text format={TypographyType.Regular}>?</Text>
      </View>
      <AttachableFormItem
        form={{
          id: "!!!",
          patientName,
          doctorName: user?.name || "",
          formType: metadata.type,
          ...(examinationDate && { examinationDate: dayjs(examinationDate) }),
        }}
      />

      <ItemSpacer gap={10}>
        {"mhaInboxInstructions" in selectedTeam && selectedTeam.mhaInboxInstructions !== null && (
          <>
            <View style={styles.infoText}>
              <InfoText paragraphs={[selectedTeam.mhaInboxInstructions]} />
            </View>
            <Checkbox
              status={instructionsConfirmed === true}
              onPress={() => setInstructionsConfirmed(curr => !curr)}
              label="I Agree"
            />
          </>
        )}
        {(("emails" in selectedTeam && selectedTeam.emails !== null) ||
          ("phoneNumbers" in selectedTeam && selectedTeam.phoneNumbers !== null)) && (
          <Text style={styles.contactDetails} format={TypographyType.SmallBold}>
            Team Contact Details:
          </Text>
        )}
        {"emails" in selectedTeam &&
          selectedTeam.emails !== null &&
          selectedTeam.emails.map(teamEmail => (
            <ContactBox
              key={teamEmail}
              label="Email Address"
              data={teamEmail}
              icon={<Email />}
              onPress={() => {
                Linking.openURL(`mailto:${teamEmail}`).catch(() => null);
              }}
            />
          ))}
        {"phoneNumbers" in selectedTeam &&
          selectedTeam.phoneNumbers !== null &&
          selectedTeam.phoneNumbers.map(teamPhone => (
            <ContactBox
              key={teamPhone}
              label="Phone Number"
              data={teamPhone}
              icon={<Mobile />}
              onPress={() => {
                Linking.openURL(`${Platform.OS === "ios" ? "telprompt:" : "tel:"}${teamPhone}`).catch(() => null);
              }}
            />
          ))}
      </ItemSpacer>

      <ButtonList>
        <Button
          onPress={assignForm}
          disabled={
            submitted ||
            ("mhaInboxInstructions" in selectedTeam &&
              selectedTeam.mhaInboxInstructions !== null &&
              !instructionsConfirmed)
          }
        >
          Confirm Assignment
        </Button>
        <Text
          format={TypographyType.SmallBold}
          color={palette.blue}
          onPress={() => setConfirmation(false)}
          style={styles.center}
        >
          Go Back
        </Text>
      </ButtonList>
    </ContentWrap>
  );
};

export default AssignForReview;

const styles = StyleSheet.create({
  smallHeading: {
    marginBottom: spacing[15],
  },

  confirmation: { flexDirection: "row", paddingBottom: spacing[30], flexWrap: "wrap" },

  center: { textAlign: "center" },
  contactDetails: {
    marginTop: spacing[20],
  },

  infoText: {
    marginVertical: spacing[20],
  },
});
