/* eslint-disable complexity */

/**
 * Claims Processing - Interface
 * Take the given data and parameters and create the interface for the claims system, including filtering and claims table.
 * Also setup the actions for processing claims.
 */
import { useMutation } from "@apollo/react-hooks";
import { ClaimStatus, ModelSortDirection } from "libs/types/API";
import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { Platform, StyleSheet, View, TouchableOpacity } from "react-native";
import { ActivityIndicator, Divider, FAB, Portal, Chip } from "react-native-paper";
import { useSetRecoilState, useRecoilValue, useRecoilState } from "recoil";
import { CommonActions, useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { ClaimProcessingSearchScreenProps } from "../ClaimProcessing/ClaimProcessing.props";

import notFalsy from "libs/utils/notFalsy";
import { AnalyticsEvent } from "libs/analytics/events";
import { CCGClaim, MhtProps } from "libs/types/claim";

import { RouteKeys } from "@/navigationv2";
import { UPDATE_CLAIM } from "@/models/Claim";
import { buildErr, updateClaimError } from "@/models/Error";
import { GetUserOrgResponseOrg } from "@/models/Organisation";
import { TypographyType } from "@/models/Typography";
import { TextInput } from "../TextInput/TextInput";
import { AllCCGRoutes } from "@/navigationv2/types";

import { color, spacing, palette } from "@/theme";
import { mqWeb, ucFirst } from "@/utils/helpers";
import { Checkbox } from "@/components/Checkbox/Checkbox";
import ClaimProcessingList from "@/components/ClaimProcessingList";
import { ContentWrap } from "@/components/ContentWrap";
import DualDatePicker from "@/components/DatePicker/DualDatePicker";
import { ErrorMessage } from "@/components/Error/Error";
import Loading from "@/components/Loading";
import { Modal } from "@/components/Modal";
import Icon, { IconName } from "@/components/Icon";
import { DropdownButton, RectangleButton } from "@/components/RectangleButton";
import Select from "@/components/Select/Select";
import Text from "@/components/Text";
import { ClaimModalType } from "./ClaimProcessing.props";
import { ClaimProcessingContext } from "./ClaimProcessingProvider";
import {
  ClaimProcessingStateContext,
  ClaimModelActionType,
  ClaimUiActionType,
  resetSelectedClaims,
  setClaimProcessingModalType,
  setExportDateRange,
} from "./ClaimProcessingState";
import { constructCsvData, retrieveSortFeatures, NotesTexts } from "./helpers";
import { LoadMoreButton } from "@/components/RectangleButton/RectangleButton";
import { recordEvent } from "@/utils/analytics";
import {
  snackbarMessage,
  lastException,
  userDetails,
  refreshClaimsRequired,
  ccgFilter as recoilCcgFilter,
} from "@/utils/recoil/index";
import { EVENTUAL_CONSISTENCY_TIMEOUT } from "@/config/constants";

interface PropTypes {
  claims: CCGClaim[];
  type: ClaimStatus | "My" | "Team" | "Search";
  organisations: GetUserOrgResponseOrg[];
  refreshQuery: any;
  refreshVariables: any;
  loading?: boolean;
  error?: any;
  organisationId?: string;
  resetPage: (page: number) => void;
  // Populated when we are displaying search results
  displayId?: string;
}

const ClaimProcessing = (props: PropTypes) => {
  const navigation = useNavigation<StackNavigationProp<AllCCGRoutes>>();
  const [filter, setFilter] = useRecoilState(recoilCcgFilter);

  const { state, dispatch } = useContext<ClaimProcessingStateContext>(ClaimProcessingContext);

  const { selectedClaims, modalType, onPressAction, csvData } = state;

  const user = useRecoilValue(userDetails);
  const setMessage = useSetRecoilState(snackbarMessage);
  const setLastException = useSetRecoilState(lastException);
  const setRefreshClaimsRequired = useSetRecoilState(refreshClaimsRequired);

  const { loading, error, type, organisations, displayId } = props;

  const [updateClaim] = useMutation(UPDATE_CLAIM);

  const [isUnmounted, setIsUnmounted] = useState(false);
  useEffect(() => () => setIsUnmounted(true), []);

  useEffect(() => {
    resetSelectedClaims(dispatch);
    if (!filter) {
      navigation.setParams({ organisationId: undefined });
      return;
    }
    navigation.setParams({ organisationId: filter });
  }, [filter]);

  const toggleAll = () => {
    if (selectedClaims.length === state.claims.length) {
      resetSelectedClaims(dispatch);
      return;
    }

    dispatch({
      type: ClaimUiActionType.SET_SELECTED_CLAIMS,
      payload: state.claims.map(c => c.id),
    });
  };

  useEffect(() => {
    if (displayId) {
      dispatch({
        type: ClaimModelActionType.SET_SEARCH_BY_DISPLAY_ID,
        payload: displayId,
      });
    }
  }, [displayId]);

  const exportClaims = () => {
    dispatch({
      type: ClaimModelActionType.SET_CSV_DATA,
      payload: constructCsvData(
        // filter to only those in the selected ccg org
        props.organisations.filter(i => i.id === props.refreshVariables.orgId || props.refreshVariables.csuId),
        state.claims,
        state.selectedClaims
      ),
    });
    setClaimProcessingModalType(dispatch, "export");
  };

  const payClaims = (claim: CCGClaim, i: number): Promise<void> => {
    const input = {
      id: claim.id,
      claimVisitId: claim.claimVisitId,
      notes: `${NotesTexts.paid}@${user ? user.name : "N/A"}@${dayjs().format()}`,
      status: ClaimStatus.approved_and_paid,
    };

    const callback = () => {
      if (i !== state.selectedClaims.length - 1) return;
      dispatch({
        type: ClaimUiActionType.RESET_ON_PRESS_AND_SELECTED_CLAIMS,
      });

      setMessage(`Claim${selectedClaims.length > 1 ? "s" : ""} marked as paid`);
    };

    return updateClaims(input, callback, claim);
  };

  const approveClaims = (claim: CCGClaim, i: number): Promise<void> => {
    const input = {
      id: claim.id,
      claimVisitId: claim.claimVisitId,
      notes: `Claim approved by team member@${user ? user.name : "N/A"}@${dayjs().format()}`,
      status: ClaimStatus.approved,
    };

    const callback = () => {
      if (i !== selectedClaims.length - 1) return;
      recordEvent(
        AnalyticsEvent.CLAIM_APPROVED,
        {
          ccg: claim.claimOrganisationId,
        },
        {
          "Minutes To Approve Claim": dayjs(claim.createdAt).diff(dayjs(), "minute"),
        }
      );

      return Promise.resolve();
    };
    dispatch({
      type: ClaimUiActionType.RESET_ON_PRESS_AND_SELECTED_CLAIMS,
    });
    return updateClaims(input, callback, claim);
  };

  const assignClaims = (claim: CCGClaim, i: number): Promise<void> => {
    const input = {
      id: claim.id,
      claimVisitId: claim.claimVisitId,
      assigneeId: user ? user.id : "!!!",
      assigneeName: user ? user.name : null,
      notes: `Claim ${type === "Team" ? "re" : ""}assigned to team member@${
        user ? user.name : "N/A"
      }@${dayjs().format()}`,
      status: claim.status,
    };

    const callback = () => {
      recordEvent(
        AnalyticsEvent.CLAIM_ASSIGNED,
        {
          ccg: claim.claimOrganisationId,
        },
        {
          "Minutes To Assign Claim": dayjs(claim.updatedAt).diff(dayjs(), "minute"),
        }
      );
      if (state.selectedClaims.length > 0 && i !== selectedClaims.length - 1) {
        return;
      }
      dispatch({
        type: ClaimUiActionType.RESET_ON_PRESS_AND_SELECTED_CLAIMS,
      });

      setMessage(`Claim${state.selectedClaims.length > 1 ? "s" : ""} ${type === "Team" ? "re" : ""}assigned`);
    };

    return updateClaims(input, callback, claim);
  };

  const updateClaims = (input: any, callback: () => void, claim: CCGClaim): Promise<void> => {
    return updateClaim({
      variables: {
        input,
      },
      refetchQueries: [
        {
          query: props.refreshQuery,
          variables: props.refreshVariables,
        },
      ],
    })
      .then(() => {
        if (isUnmounted) {
          return;
        }
        setClaimProcessingModalType(dispatch, null);
        callback();
      })
      .catch(error => {
        buildErr(
          {
            ...updateClaimError,
            additional: claim.id,
          },
          setLastException
        );
        return Promise.reject(error);
      });
  };

  const submitClaims = () => {
    dispatch({ type: ClaimUiActionType.DISABLE_BUTTONS });
    return Promise.all(
      state.selectedClaims.map<Promise<void>>((claim: string, i: number) => {
        const currClaim = state.claims.find((c: CCGClaim) => c.id === claim);
        if (currClaim) {
          dispatch({
            type: ClaimModelActionType.UPDATE_CLAIM,
            payload: {
              claim,
              modalType,
            },
          });

          return modalType === "assign" || modalType === "reassign"
            ? assignClaims(currClaim, i)
            : modalType === "approve"
            ? approveClaims(currClaim, i)
            : payClaims(currClaim, i);
        } else {
          return Promise.resolve();
        }
      })
    )
      .catch(error =>
        dispatch({
          type: ClaimModelActionType.UPDATE_CLAIMS_FAILURE,
          payload: { error },
        })
      )
      .then(() => {
        dispatch({
          type: ClaimUiActionType.RESET_ON_PRESS_AND_SELECTED_CLAIMS,
        });
        dispatch({
          type: ClaimModelActionType.UPDATE_TOTAL_RECORDS,
        });
        // Allow 5s for eventual consistency before refetching data
        setTimeout(() => setRefreshClaimsRequired(true), EVENTUAL_CONSISTENCY_TIMEOUT);
      });
  };

  const isWeb = mqWeb();

  const onSearch = () => {
    // When the user enters a value in the search field and clicks on the
    // search icon, we navigate to a new screen showing the list of claims that
    // match the search criteria.
    navigation.push(RouteKeys.ClaimProcessingSearchScreen, {
      displayId: state.searchField,
    } as ClaimProcessingSearchScreenProps);
  };

  const renderSortableFieldHeader = (label: string, sortField: string | null) => {
    // Default state
    let iconName = IconName.unfold_more;
    let iconColor = "#aaa";

    if (state.columnSort.sortField && sortField === state.columnSort.sortField) {
      iconColor = "#000";
      state.columnSort.sortDirection === ModelSortDirection.DESC
        ? (iconName = IconName.expand_less)
        : (iconName = IconName.expand_more);
    }

    return (
      <TouchableOpacity
        onPress={() => {
          const { columnSort } = state;
          const payload = retrieveSortFeatures(sortField, columnSort);
          props.resetPage(0);
          dispatch({
            type: ClaimModelActionType.SET_COLUMN_SORT,
            payload,
          });
        }}
        style={[styles.flex, styles.row]}
      >
        <Text format={TypographyType.Micro}>{label}</Text>
        {iconName && <Icon name={iconName} size={20} color={iconColor} />}
      </TouchableOpacity>
    );
  };

  const createWebActionBar = (fieldList: string[]) => {
    return (
      <ContentWrap style={{ zIndex: 500 }}>
        <View style={[styles.row, styles.marginBig, styles.centerItems, { zIndex: 5000 }]}>
          <View style={styles.header}>
            <View style={styles.filter}>
              <View style={styles.searchContainer}>
                <TextInput
                  autoCapitalize="none"
                  autoCorrect={false}
                  label="Claim search"
                  onChangeText={value => {
                    dispatch({
                      type: ClaimModelActionType.SET_SEARCH_BY_DISPLAY_ID,
                      payload: value.toUpperCase(),
                    });
                  }}
                  onSubmitEditing={onSearch}
                  placeholder="Enter Claim ID (full or partial)"
                  returnKeyType="done"
                  value={state.searchField}
                  style={styles.searchBox}
                />
                <TouchableOpacity onPress={onSearch} disabled={!state.searchField}>
                  <View>
                    <Icon
                      name={IconName.search}
                      size={24}
                      style={state.searchField ? styles.searchEnabled : styles.searchDisabled}
                    />
                  </View>
                </TouchableOpacity>
              </View>
              <View style={styles.select}>
                {props.type !== "Search" && organisations && organisations.length > 1 && renderSelect()}
              </View>
            </View>
          </View>
          {Platform.OS === "web" && (
            <DropdownButton
              mode="action"
              disabled={!state.buttonsEnabled}
              dropdownButtons={[
                {
                  children: "EXPORT SELECTED",
                  onPress: exportClaims,
                  disabled: state.selectedClaims.length === 0,
                },
                {
                  children: "EXPORT BY RANGE",
                  onPress: () => setClaimProcessingModalType(dispatch, "byRange"),
                },
              ]}
            >
              EXPORT OPTIONS
            </DropdownButton>
          )}
          {type === "My" && (
            <RectangleButton
              onPress={() => setClaimProcessingModalType(dispatch, "approve")}
              disabled={!state.buttonsEnabled || state.selectedClaims.length === 0}
              mode="action"
              style={{ marginLeft: spacing[10] }}
              icon="done"
            >
              APPROVE
            </RectangleButton>
          )}
          {type === "Team" && (
            <RectangleButton
              onPress={() => setClaimProcessingModalType(dispatch, "reassign")}
              disabled={!state.buttonsEnabled || state.selectedClaims.length === 0}
              mode="action"
              style={{ marginLeft: spacing[10] }}
              icon="compare-arrows"
            >
              RE-ASSIGN TO ME
            </RectangleButton>
          )}
          {type === ClaimStatus.under_review && (
            <RectangleButton
              onPress={() => setClaimProcessingModalType(dispatch, "assign")}
              disabled={!state.buttonsEnabled || state.selectedClaims.length === 0}
              mode="action"
              style={{ marginLeft: spacing[10] }}
              icon="assignment"
            >
              ASSIGN TO ME
            </RectangleButton>
          )}
          {type === ClaimStatus.approved && (
            <RectangleButton
              onPress={() => setClaimProcessingModalType(dispatch, "pay")}
              disabled={
                !state.buttonsEnabled ||
                selectedClaims.length === 0 ||
                state.claims.filter(c => selectedClaims.includes(c.id) && c.status === ClaimStatus.approved_and_paid)
                  .length > 0
              }
              mode="action"
              style={{ marginLeft: spacing[10] }}
              icon="assignment"
            >
              MARK AS PAID
            </RectangleButton>
          )}
        </View>

        {props.type === "Search" && (
          <Text format={TypographyType.HeadingSmall} style={styles.pageTitleWeb}>
            Results
          </Text>
        )}

        <View style={[styles.row, styles.marginBig, { zIndex: 3 }]}>
          <Checkbox
            status={selectedClaims.length === state.claims.length && state.claims.length > 0}
            onPress={toggleAll}
            disabled={state.claims.length === 0}
          />
          <View style={[styles.flex, styles.row]}>
            {fieldList.includes("claimid") && renderSortableFieldHeader("Claim ID", "displayId")}
            {fieldList.includes("doctor") && renderSortableFieldHeader(`Doctor\n(Preferred name)`, "doctor.name")}
            {fieldList.includes("defaultMHT") && (
              <Text format={TypographyType.Micro} style={styles.flex}>
                MHT
              </Text>
            )}
            {fieldList.includes("lineManager") && (
              <Text format={TypographyType.Micro} style={styles.flex}>
                Line Manager
              </Text>
            )}
            {fieldList.includes("ccg") && (
              <Text format={TypographyType.Micro} style={styles.flex}>
                CCG{"\n"}(Assignee)
              </Text>
            )}
            {fieldList.includes("status") && renderSortableFieldHeader("Claim Status", "status")}
            {fieldList.includes("amhpteamname") && (
              <Text format={TypographyType.Micro} style={styles.flex}>
                AMHP Team Name
              </Text>
            )}
            {fieldList.includes("employer") && (
              <Text format={TypographyType.Micro} style={styles.flex}>
                Employer
              </Text>
            )}
            {fieldList.includes("distance") && (
              <Text format={TypographyType.Micro} style={styles.flex}>
                Distance Travelled{"\n"}(Miles)
              </Text>
            )}
            {fieldList.includes("postcode") && (
              <Text format={TypographyType.Micro} style={styles.flex}>
                Postcode
              </Text>
            )}
            {fieldList.includes("expenses") && (
              <Text format={TypographyType.Micro} style={styles.flex}>
                Additional Expenses{"\n"}(Travel)
              </Text>
            )}
            {fieldList.includes("doctorAdditionalConf") && (
              <Text format={TypographyType.Micro} style={styles.flex}>
                Doctor Confirmation
              </Text>
            )}
            {fieldList.includes("doctorEmployedStatus") && (
              <Text format={TypographyType.Micro} style={styles.flex}>
                Payroll
              </Text>
            )}
            {fieldList.includes("visitdate") && renderSortableFieldHeader(`Visit Date`, "visitDate")}
            {/* For NHS Coventry and Warwickshire CCG we are replacing the visit date with the approved date on the approved screen.
                 Note: the approved date doesn't appear in the claim. We need to extract it from the notes.  */}
            {fieldList.includes("approvedDate") && (
              <Text format={TypographyType.Micro} style={styles.flex}>
                Approved Date
              </Text>
            )}
            {fieldList.includes("receiveddate") && renderSortableFieldHeader(`Received Date`, "receivedDate")}
          </View>
          <Divider />
        </View>
      </ContentWrap>
    );
  };

  const isUnassigned = type === ClaimStatus.under_review;
  const label = ["My"].includes(type)
    ? "My Claims"
    : ["Team"].includes(type)
    ? type
    : isUnassigned
    ? "Unassigned"
    : `${type.charAt(0).toUpperCase()}${type.slice(1)}`;

  const displayContent = (fieldList: string[]) => {
    if (loading) {
      return <Loading />;
    } else if (error) {
      return <ErrorMessage apolloError={error} />;
    } else {
      return (
        <>
          {isWeb && (
            <div style={{ zIndex: 10, position: "sticky", top: 0, backgroundColor: "white" }}>
              <View>{createWebActionBar(fieldList)}</View>
            </div>
          )}
          <View style={{ zIndex: 2, position: "relative" }}>
            <ClaimProcessingList type={type} label={label} userId={user?.id} />
            {state.shouldLoadMore && (
              <ContentWrap style={{ paddingVertical: spacing[10] }}>
                <ActivityIndicator animating={true} color={color.text} />
              </ContentWrap>
            )}
            {state.nextToken &&
              LoadMoreButton(() => dispatch({ type: ClaimModelActionType.LOAD_MORE_CLAIMS }), state.shouldLoadMore)}
          </View>
        </>
      );
    }
  };

  const filterOptions = organisations.length
    ? [...(user?.csu ? [{ name: "Show All", id: "all" }] : [])].concat(organisations.filter(o => o.type === "CCG"))
    : [{ name: "Unavailable", id: "none" }];

  const createModalHeadline = (modalType: ClaimModalType) => {
    return modalType === "byRange" ? "Export Claims By Date Range" : `${ucFirst(modalType)} Claims`;
  };

  const renderSelect = () => (
    <Select
      label="CCG Filter"
      value={filter || filterOptions[0].name}
      onValueChange={v => {
        setExportDateRange(dispatch, {
          payload: { from: null, to: null },
        });
        !isUnmounted && setFilter(v === "all" || v === "none" ? null : v);
      }}
      options={filterOptions}
    />
  );

  const modalContent = (modalType: ClaimModalType | null, num: number) => {
    if (modalType === null) return null;
    const plural = state.selectedClaims.length > 1 ? "s" : "";
    const type = modalType === "assign" || modalType === "reassign" ? " to yourself" : "";

    const approve =
      "By approving these claim forms, you're confirming that the information provided is accurate and the fee is eligble for payment.";
    const paid = `By marking these claims as paid, you're confirming the payment for the claim${plural} has been made.`;
    const assignment = `You are about to ${modalType} ${num} claim${plural}${type}.\n\nAre you sure you want to do this?`;

    return modalType === "byRange" ? (
      <>
        <View style={{ flexDirection: "column", flex: 1, display: "flex" }}>
          <View style={{ flexDirection: "row", flex: 1, display: "flex" }}>
            <DualDatePicker
              label="Date Received (from)"
              min={dayjs()
                .subtract(15, "month")
                .toDate()}
              max={dayjs().toDate()}
              value={state.rangeValues.from}
              onValueChange={value =>
                setExportDateRange(dispatch, {
                  payload: { from: value },
                })
              }
              style={{ flex: 1 }}
            />
            <DualDatePicker
              label="Date Received (to)"
              max={dayjs().toDate()}
              disabled={dayjs(state.rangeValues.from) === dayjs(state.rangeValues.to)}
              min={dayjs(state.rangeValues.from)
                .add(1, "day")
                .toDate()}
              value={state.rangeValues.to}
              onValueChange={value =>
                setExportDateRange(dispatch, {
                  payload: { to: value },
                })
              }
              style={{ flex: 1 }}
            />
          </View>
          {state.rangeCsvData && state.rangeCsvData.length === 0 && (
            <Text format={TypographyType.Regular}>
              There were no claims available for export in this period, please select a different date range to try
              again
            </Text>
          )}
        </View>
      </>
    ) : (
      <Text format={TypographyType.Regular} style={modalType === "export" && styles.modalInfoText}>
        {modalType === "approve" ? approve : modalType === "pay" ? paid : assignment}
      </Text>
    );
  };

  // ? Helper - use to review the current list of fields available
  // console.log(state.fieldList);

  return (
    <>
      {displayContent(state.fieldList)}
      {!isWeb && (
        <Portal>
          <View style={styles.fabHolder}>
            {onPressAction === "navigate" && props.claims.length > 0 && ![ClaimStatus.rejected].includes(type as any) && (
              <FAB
                icon="done-all"
                label="SELECT MULTIPLE"
                color={color.textWhite}
                style={[styles.multiApprove, styles.fab]}
                onPress={() => {
                  dispatch({
                    type: ClaimUiActionType.SET_ON_PRESS_ACTION,
                    payload: "setselectedclaims",
                  });
                }}
              />
            )}
            {type === "My" && onPressAction === "setselectedclaims" && (
              <FAB
                icon="done"
                label="APPROVE"
                color={selectedClaims.length === 0 ? color.textWhite : color.text}
                style={[styles.approve, styles.fab, selectedClaims.length === 0 && styles.disabled]}
                disabled={selectedClaims.length === 0}
                onPress={() => setClaimProcessingModalType(dispatch, "approve")}
              />
            )}
            {type === ClaimStatus.under_review && onPressAction === "setselectedclaims" && (
              <FAB
                icon="assignment"
                label="ASSIGN TO ME"
                color={selectedClaims.length === 0 ? color.textWhite : color.text}
                style={[styles.approve, styles.fab, selectedClaims.length === 0 && styles.disabled]}
                disabled={selectedClaims.length === 0}
                onPress={() => setClaimProcessingModalType(dispatch, "assign")}
              />
            )}
            {type === "Team" && onPressAction === "setselectedclaims" && (
              <FAB
                icon="assignment"
                label="REASSIGN TO ME"
                color={selectedClaims.length === 0 ? color.textWhite : color.text}
                style={[styles.approve, styles.fab, selectedClaims.length === 0 && styles.disabled]}
                disabled={selectedClaims.length === 0}
                onPress={() => setClaimProcessingModalType(dispatch, "reassign")}
              />
            )}
            {Platform.OS === "web" && onPressAction === "setselectedclaims" && (
              <FAB
                icon="insert-drive-file"
                label="EXPORT TO SPREADSHEET"
                color={selectedClaims.length === 0 ? color.textWhite : color.text}
                style={[styles.multiExport, styles.fab, selectedClaims.length === 0 && styles.disabled]}
                onPress={exportClaims}
              />
            )}
            {Platform.OS === "web" && onPressAction === "setselectedclaims" && type === ClaimStatus.approved && (
              <FAB
                icon="payment"
                label="MARK AS PAID"
                color={selectedClaims.length === 0 ? color.textWhite : color.text}
                style={[styles.multiExport, styles.fab, selectedClaims.length === 0 && styles.disabled]}
                onPress={() => setClaimProcessingModalType(dispatch, "pay")}
              />
            )}
            <FAB
              visible={onPressAction === "setselectedclaims"}
              icon="cancel"
              label="cancel"
              color={color.textWhite}
              style={[styles.multiExport, styles.cancelFab, styles.fab]}
              onPress={() => {
                dispatch({
                  type: ClaimUiActionType.RESET_ON_PRESS_AND_SELECTED_CLAIMS,
                });
              }}
            />
          </View>
        </Portal>
      )}
      {/* CCGModal */}
      <Modal
        visible={modalType !== null}
        dismissable={true}
        onDismiss={() => setClaimProcessingModalType(dispatch, null)}
        headline={modalType ? createModalHeadline(modalType) : ""}
        buttons={[
          {
            label: "Cancel",
            onPress: () => setClaimProcessingModalType(dispatch, null),
          },
          modalType === "byRange"
            ? {
                label: "Export Claims By Range",
                icon: "file-download",
                onPress: () => {
                  dispatch({
                    type: ClaimModelActionType.LOAD_RANGE_DATA,
                  });
                },
              }
            : modalType === "export" && Platform.OS === "web"
            ? {
                label: `Export Claim${selectedClaims.length > 1 ? "s" : ""}`,
                renderButton: () => (
                  <CSVLink
                    key={2}
                    data={csvData}
                    filename={`${dayjs().format("DD-MM-YYYY")}__${(type || "All").toLocaleLowerCase()}_claims.csv`}
                    onClick={() => {
                      resetSelectedClaims(dispatch);
                    }}
                    style={webStyle.csvLink}
                  >
                    <Text format={TypographyType.TinyBold} style={styles.buttonText}>
                      EXPORT CLAIM{selectedClaims.length > 1 && "S"}
                    </Text>
                  </CSVLink>
                ),
              }
            : modalType !== null && modalType !== "export"
            ? {
                label: `${ucFirst(modalType)} Claim${selectedClaims.length > 1 ? "s" : ""}`,
                disabled: !state.buttonsEnabled,
                onPress: submitClaims,
              }
            : null,
        ].filter(notFalsy)}
      >
        <Text format={TypographyType.Regular} style={modalType === "export" && styles.modalInfoText}>
          {modalContent(modalType, selectedClaims.length)}
        </Text>
      </Modal>
    </>
  );
};

export default ClaimProcessing;

const styles = StyleSheet.create({
  flex: {
    flex: 1,
  },
  header: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  filter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    minWidth: 500,
  },
  row: {
    flexDirection: "row",
    alignItems: "flex-end",
  },
  fab: { marginTop: spacing[10] },
  centerItems: {
    alignItems: "center",
  },
  marginBig: {
    marginBottom: spacing[20],
  },
  select: { maxWidth: 250 },
  multiExport: {
    backgroundColor: color.secondary,
  },
  multiApprove: {
    backgroundColor: color.primary,
  },
  modalInfoText: {
    marginBottom: spacing[30],
  },
  buttonText: {
    textAlign: "center",
    color: color.primary,
  },
  cancelFab: {
    backgroundColor: color.primary,
  },
  fabHolder: {
    position: "absolute",
    flexDirection: "column-reverse",
    right: 20,
    bottom: 20,
    alignItems: "flex-end",
  },
  disabled: {
    backgroundColor: color.backgroundGreyDark,
  },
  approve: {
    backgroundColor: color.available,
  },
  pageTitleWeb: {
    color: palette.navy,
    marginBottom: spacing[10],
  },
  searchContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    flexGrow: 1,
    marginRight: spacing[10],
  },
  searchBox: {
    padding: 0,
    margin: 0,
  },
  searchEnabled: {
    color: palette.blue,
  },
  searchDisabled: {
    color: palette.grey,
  },
  chip: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: color.primary,
    borderRadius: 20,
    color: palette.white,
    height: spacing[30],
  },
  chipText: {
    color: "white",
  },
});

const webStyle = {
  csvLink: {
    textDecoration: "none",
  },
};
