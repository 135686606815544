/**
 * Subscribe to a string value that informs the orientation of the user's device
 */
import { useEffect, useState } from "react";
import { Dimensions, Platform } from "react-native";

export default function useDeviceOrientation(): "portrait" | "landscape" {
  const device = Platform.OS === "web" ? "window" : "screen";
  const [deviceWidth, setDeviceWidth] = useState(Dimensions.get(device).width);
  const [deviceHeight, setDeviceHeight] = useState(Dimensions.get(device).height);

  useEffect(() => {
    const _handleResize = () => {
      setDeviceWidth(Dimensions.get(device).width);
      setDeviceHeight(Dimensions.get(device).height);
    };
    Dimensions.addEventListener("change", _handleResize);
    return () => Dimensions.removeEventListener("change", _handleResize);
  }, []);

  if (deviceWidth > deviceHeight) return "landscape";
  return "portrait";
}
