import React, { useMemo, useCallback } from "react";
import { StyleSheet, View } from "react-native";

import { AssessmentResultItem, AssessmentCategory } from "@/models/Assessment";
import { palette, spacing } from "@/theme";
import { mqWeb } from "@/utils/helpers";

import { AssessmentNextTokens } from "./AssessmentResults.gql";
import { AssessmentSection } from "./AssessmentsSection";

interface PropTypes {
  setFetchMoreTypeFn: any;
  nextTokens: AssessmentNextTokens;
  myAssessments?: AssessmentResultItem[];
  teamAssessments?: AssessmentResultItem[];
  myArchivedAssessments?: AssessmentResultItem[];
  teamArchivedAssessments?: AssessmentResultItem[];
  isLoading?: boolean;
}

const AssessmentResults = (props: PropTypes) => {
  const {
    setFetchMoreTypeFn,
    nextTokens,
    myAssessments,
    teamAssessments,
    myArchivedAssessments,
    teamArchivedAssessments,
  } = props;
  const isWebView = mqWeb();

  const resultsStyles = useMemo(() => [!isWebView && styles.resultsMobile], [isWebView]);

  const moreMy = useCallback(() => setFetchMoreTypeFn(AssessmentCategory.MyAssessments), [setFetchMoreTypeFn]);
  const moreTeam = useCallback(() => setFetchMoreTypeFn(AssessmentCategory.TeamAssessments), [setFetchMoreTypeFn]);
  const moreMyArchive = useCallback(() => setFetchMoreTypeFn(AssessmentCategory.MyArchive), [setFetchMoreTypeFn]);
  const moreTeamArchive = useCallback(() => setFetchMoreTypeFn(AssessmentCategory.TeamArchive), [setFetchMoreTypeFn]);

  return (
    <View style={resultsStyles}>
      <AssessmentSection
        heading="My Assessments"
        nextToken={nextTokens.my}
        assessments={myAssessments}
        category={AssessmentCategory.MyAssessments}
        fetchMoreFn={moreMy}
        themeColor={palette.aqua}
        bottomDivider
      />

      <AssessmentSection
        heading="Team Assessments"
        subHeading={teamAssessments && teamAssessments[0] ? teamAssessments[0].amhpTeam.name : false}
        nextToken={nextTokens.team}
        assessments={teamAssessments}
        category={AssessmentCategory.TeamAssessments}
        fetchMoreFn={moreTeam}
        themeColor={palette.blue}
        bottomDivider
      />

      <AssessmentSection
        heading="My Archive"
        nextToken={nextTokens.myArchived}
        assessments={myArchivedAssessments}
        category={AssessmentCategory.MyArchive}
        fetchMoreFn={moreMyArchive}
        themeColor={palette.red}
        bottomDivider
      />

      <AssessmentSection
        heading="Team Archive"
        subHeading={
          teamArchivedAssessments && teamArchivedAssessments[0] ? teamArchivedAssessments[0].amhpTeam.name : false
        }
        nextToken={nextTokens.teamArchived}
        assessments={teamArchivedAssessments}
        category={AssessmentCategory.TeamArchive}
        fetchMoreFn={moreTeamArchive}
        themeColor={palette.red}
      />
    </View>
  );
};

// Necessary so content finishes above FAB button on mobile
const styles = StyleSheet.create({
  resultsMobile: {
    marginBottom: spacing[30],
  },
});

export default AssessmentResults;
