import React from "react";
import { Query } from "react-apollo";

import { AmhpAssessmentQueryVariables } from "libs/types/API";

import { GET_ASSESSMENT, GetAssessmentResponse } from "@/models/Assessment";
import { checkOfflineCache } from "@/utils/helpers";

import { AssessmentDetails } from "./AssessmentDetails";
import { ErrorMessage } from "../Error/Error";
import Loading from "../Loading";

type PropTypes = AmhpAssessmentQueryVariables & {
  retrievePatientName: (val: string) => void;
  refreshRequired: boolean;
  setRefreshDone: () => void;
  notificationUpdate?: string;
};

export default function AssessmentDetailsGQL(props: PropTypes) {
  return (
    <Query<GetAssessmentResponse, AmhpAssessmentQueryVariables>
      query={GET_ASSESSMENT}
      variables={{ id: props.id }}
      fetchPolicy="cache-and-network"
    >
      {({ error, data, loading, client, variables, refetch }) => {
        if (props.refreshRequired) {
          setTimeout(() => refetch(), 0);
          props.setRefreshDone();
        }
        const complete = checkOfflineCache(client, GET_ASSESSMENT, variables);
        if (!complete && error) {
          return <ErrorMessage apolloError={error} />;
        } else if (!complete && loading && !data) {
          return <Loading />;
        } else if (data && data.assessment) {
          const { assessment } = data;
          props.retrievePatientName(assessment.patientName);
          return <AssessmentDetails assessment={assessment} refetch={refetch} />;
        } else {
          return <Loading />;
        }
      }}
    </Query>
  );
}
