import { StyleSheet, TextStyle } from "react-native";

import { TypographyType } from "@/models/Typography";

export const fontSettings = {
  headingLarge: {
    standard: { size: 36, lineHeight: 44 },
    reduced: { size: 29, lineHeight: 36 },
  },
  headingMedium: {
    standard: { size: 28, lineHeight: 36 },
    reduced: { size: 24, lineHeight: 32 },
  },
  headingSmall: {
    standard: { size: 22, lineHeight: 30 },
    reduced: { size: 20, lineHeight: 27 },
  },
  buttonText: {
    standard: { size: 20, lineHeight: 21 },
    reduced: { size: 18, lineHeight: 19 },
  },
  regular: {
    standard: { size: 18, lineHeight: 28 },
    reduced: { size: 16, lineHeight: 25 },
  },
  small: {
    standard: { size: 16, lineHeight: 24 },
    reduced: { size: 14, lineHeight: 22 },
  },
  tiny: {
    standard: { size: 14, lineHeight: 22 },
    reduced: { size: 13, lineHeight: 20 },
  },
  micro: {
    standard: { size: 12, lineHeight: 20 },
    reduced: { size: 11, lineHeight: 18 },
  },
};

export const standardSizes: { [key: string]: TextStyle } = StyleSheet.create({
  [TypographyType.HeadingLarge]: {
    fontSize: fontSettings.headingLarge.standard.size,
    lineHeight: fontSettings.headingLarge.standard.lineHeight,
  },

  [TypographyType.HeadingMedium]: {
    fontSize: fontSettings.headingMedium.standard.size,
    lineHeight: fontSettings.headingMedium.standard.lineHeight,
  },

  [TypographyType.HeadingSmall]: {
    fontSize: fontSettings.headingSmall.standard.size,
    lineHeight: fontSettings.headingSmall.standard.lineHeight,
  },

  [TypographyType.ButtonText]: {
    fontSize: fontSettings.buttonText.standard.size,
    lineHeight: fontSettings.buttonText.standard.lineHeight,
  },

  [TypographyType.Regular]: {
    fontSize: fontSettings.regular.standard.size,
    lineHeight: fontSettings.regular.standard.lineHeight,
  },
  [TypographyType.RegularBold]: {
    fontSize: fontSettings.regular.standard.size,
    lineHeight: fontSettings.regular.standard.lineHeight,
  },

  [TypographyType.Small]: {
    fontSize: fontSettings.small.standard.size,
    lineHeight: fontSettings.small.standard.lineHeight,
  },
  [TypographyType.SmallBold]: {
    fontSize: fontSettings.small.standard.size,
    lineHeight: fontSettings.small.standard.lineHeight,
  },

  [TypographyType.Tiny]: {
    fontSize: fontSettings.tiny.standard.size,
    lineHeight: fontSettings.tiny.standard.lineHeight,
  },
  [TypographyType.TinyBold]: {
    fontSize: fontSettings.tiny.standard.size,
    lineHeight: fontSettings.tiny.standard.lineHeight,
  },

  [TypographyType.Micro]: {
    fontSize: fontSettings.micro.standard.size,
    lineHeight: fontSettings.micro.standard.lineHeight,
  },
  [TypographyType.MicroBold]: {
    fontSize: fontSettings.micro.standard.size,
    lineHeight: fontSettings.micro.standard.lineHeight,
  },
});

export const reducedSizes: { [key: string]: TextStyle } = StyleSheet.create({
  [TypographyType.HeadingLarge]: {
    fontSize: fontSettings.headingLarge.reduced.size,
    lineHeight: fontSettings.headingLarge.reduced.lineHeight,
  },

  [TypographyType.HeadingMedium]: {
    fontSize: fontSettings.headingMedium.reduced.size,
    lineHeight: fontSettings.headingMedium.reduced.lineHeight,
  },

  [TypographyType.HeadingSmall]: {
    fontSize: fontSettings.headingSmall.reduced.size,
    lineHeight: fontSettings.headingSmall.reduced.lineHeight,
  },

  [TypographyType.ButtonText]: {
    fontSize: fontSettings.buttonText.reduced.size,
    lineHeight: fontSettings.buttonText.reduced.lineHeight,
  },

  [TypographyType.Regular]: {
    fontSize: fontSettings.regular.reduced.size,
    lineHeight: fontSettings.regular.reduced.lineHeight,
  },
  [TypographyType.RegularBold]: {
    fontSize: fontSettings.regular.reduced.size,
    lineHeight: fontSettings.regular.reduced.lineHeight,
  },

  [TypographyType.Small]: {
    fontSize: fontSettings.small.reduced.size,
    lineHeight: fontSettings.small.reduced.lineHeight,
  },
  [TypographyType.SmallBold]: {
    fontSize: fontSettings.small.reduced.size,
    lineHeight: fontSettings.small.reduced.lineHeight,
  },

  [TypographyType.Tiny]: {
    fontSize: fontSettings.tiny.reduced.size,
    lineHeight: fontSettings.tiny.reduced.lineHeight,
  },
  [TypographyType.TinyBold]: {
    fontSize: fontSettings.tiny.reduced.size,
    lineHeight: fontSettings.tiny.reduced.lineHeight,
  },

  [TypographyType.Micro]: {
    fontSize: fontSettings.micro.reduced.size,
    lineHeight: fontSettings.micro.reduced.lineHeight,
  },
  [TypographyType.MicroBold]: {
    fontSize: fontSettings.micro.reduced.size,
    lineHeight: fontSettings.micro.reduced.lineHeight,
  },
});
