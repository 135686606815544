import { Platform } from "react-native";

type Options = {
  prefix?: string;
  suffix?: string;
  webPrefix?: string;
  webSuffix?: string;
};

const defaults: Options = {
  prefix: "",
  suffix: "",
  webPrefix: "",
  webSuffix: " | S12 Solutions",
};

export default function createScreenTitle(title: string, options: Options = defaults): string {
  const prefix = Platform.select({ default: options.prefix, web: options.webPrefix });
  const suffix = Platform.select({ default: options.suffix, web: options.webSuffix });

  return prefix + title + suffix;
}
