import React, { useMemo } from "react";
import { StyleSheet } from "react-native";
import { useRecoilValue, useRecoilState } from "recoil";

import { isJointForm } from "libs/mhaForms/helpers";
import { FormListItem, FormType } from "libs/types/mhaForms";
import { FormStatus } from "libs/types/API";
import { TypographyType } from "@/models/Typography";

import { Accordion, AccordionSection } from "@/components/Accordion";
import { Button } from "@/components/Button";
import { ContentWrap } from "@/components/ContentWrap";
import { MHAFormRecordsList } from "@/components/Forms/MHAFormRecord";
import Text, { TextWithIcon } from "@/components/Text";
import UnsavedMHAFormRecord from "@/components/UnsavedMHAFormRecord";

import { userDetails, offlineMHAForm } from "@/utils/recoil";
import { mqWeb } from "@/utils/helpers";
import { spacing } from "@/theme";

import { docInformalMedRecs } from "libs/utils/featureFlags";
import { useNavigation } from "@react-navigation/core";
import { StackNavigationProp } from "@react-navigation/stack";
import { AllAMHPRoutes } from "@/navigationv2/types";
import { RouteKeys } from "@/navigationv2";

interface DoctorFormsProps {
  assessmentId: string;
  visitId: string;
  createMHAForm: () => void;
  formRecords: FormListItem[];
  refetch: () => void;
  doctorId: string;
  hasPatientAddress: boolean;
  featureFlags?: string;
  navigationParam?: { [key: string]: string };
}

const DoctorForms: React.FC<DoctorFormsProps> = ({
  assessmentId,
  createMHAForm,
  formRecords,
  refetch,
  doctorId,
  hasPatientAddress,
  featureFlags,
  navigationParam,
}) => {
  const user = useRecoilValue(userDetails);
  const [offlineForm, setOfflineForm] = useRecoilState(offlineMHAForm);
  const navigation = useNavigation<StackNavigationProp<AllAMHPRoutes>>();

  const isWebView = mqWeb();
  const TopContentWrapper = useMemo(() => (isWebView ? React.Fragment : ContentWrap), [isWebView]);

  // "Draft" forms
  const drafts = formRecords.filter(
    fr =>
      fr &&
      fr.status === FormStatus.s10_draft &&
      (!isJointForm(fr.formType) || (isJointForm(fr.formType) && fr.createdBy === doctorId))
  );

  // "Changes in Progress" forms
  const changesInProgress = formRecords.filter(
    fr =>
      fr &&
      fr.status === FormStatus.s30_changes_in_progress &&
      (!isJointForm(fr.formType) || (isJointForm(fr.formType) && fr.createdBy === doctorId))
  );

  // "Joint Forms" (i.e. joint forms created by another doctor)
  const jointFormsNotCreatedByDoctor = formRecords.filter(
    fr =>
      fr &&
      isJointForm(fr.formType) &&
      fr.createdBy !== doctorId &&
      fr.authors &&
      fr.authors.length === 2 &&
      fr.authors.includes(doctorId) &&
      [FormStatus.s10_draft, FormStatus.s20_awaiting_other_doctor, FormStatus.s30_changes_in_progress].includes(
        fr.status
      )
  );

  // "Signed" forms
  const signed = formRecords.filter(
    fr =>
      // All signed doctor forms (either single med recs with one doctor signature, or, joint med recs with two doctor signatures)
      (fr && [FormStatus.s40_signed, FormStatus.s50_signed_and_sent].includes(fr.status)) ||
      // All joint med recs created and signed by the current doctor user
      (isJointForm(fr.formType) && fr.createdBy === doctorId && fr.status === FormStatus.s20_awaiting_other_doctor)
  );

  enum DoctorFormCategoryIndex {
    Unsaved,
    Drafts,
    ChangesInProgress,
    JointFormsNotCreatedByDoctor,
    Signed,
  }

  const hasCreatedForm = formRecords.length > 0;

  return (
    <>
      <TopContentWrapper>
        <Text format={TypographyType.HeadingMedium}>My Forms</Text>
        {!hasCreatedForm && (
          <Button onPress={createMHAForm} style={styles.button}>
            Create MHA Form
          </Button>
        )}
        {docInformalMedRecs(featureFlags || "{}") && !hasCreatedForm && (
          <Button
            onPress={() =>
              navigation.navigate(RouteKeys.MHAFormScreen, {
                ...(navigationParam || {}),
                formType: FormType.STATEMENT_OF_REASON,
              })
            }
            marginTop={10}
            style={styles.button}
            mode="outlined"
          >
            Create No Med Rec Form
          </Button>
        )}
      </TopContentWrapper>
      <Accordion
        marginTop={30}
        defaultActiveIndex={
          drafts.length
            ? DoctorFormCategoryIndex.Drafts
            : changesInProgress.length
            ? DoctorFormCategoryIndex.ChangesInProgress
            : jointFormsNotCreatedByDoctor.length
            ? DoctorFormCategoryIndex.JointFormsNotCreatedByDoctor
            : signed.length
            ? DoctorFormCategoryIndex.Signed
            : -1
        }
      >
        {offlineForm ? (
          <AccordionSection index={DoctorFormCategoryIndex.Unsaved} heading="Unsaved" itemCount={1}>
            <UnsavedMHAFormRecord
              offlineForm={offlineForm}
              assessmentId={assessmentId}
              clearOfflineForm={() => setOfflineForm(null)}
            />
          </AccordionSection>
        ) : (
          <AccordionSection
            index={DoctorFormCategoryIndex.Drafts}
            heading="Drafts"
            itemCount={drafts.length}
            disabled={!drafts.length}
          >
            <MHAFormRecordsList
              formRecords={drafts}
              assessmentId={assessmentId}
              isAmhpForm={false}
              refetch={refetch}
              user={user}
            />
          </AccordionSection>
        )}

        <AccordionSection
          index={DoctorFormCategoryIndex.ChangesInProgress}
          heading="Changes in Progress"
          itemCount={changesInProgress.length}
          disabled={!changesInProgress.length}
        >
          <MHAFormRecordsList
            formRecords={changesInProgress}
            assessmentId={assessmentId}
            isAmhpForm={false}
            refetch={refetch}
            user={user}
          />
        </AccordionSection>

        <AccordionSection
          index={DoctorFormCategoryIndex.JointFormsNotCreatedByDoctor}
          heading="Joint Forms"
          itemCount={jointFormsNotCreatedByDoctor.length}
          disabled={!jointFormsNotCreatedByDoctor.length}
        >
          <TextWithIcon iconName="info" format={TypographyType.RegularBold}>
            Please click View below to review and sign the form.
          </TextWithIcon>
          <MHAFormRecordsList
            formRecords={jointFormsNotCreatedByDoctor}
            assessmentId={assessmentId}
            isAmhpForm={false}
            refetch={refetch}
            user={user}
          />
        </AccordionSection>

        <AccordionSection
          index={DoctorFormCategoryIndex.Signed}
          heading="Signed"
          itemCount={signed.length}
          disabled={!signed.length}
        >
          <MHAFormRecordsList
            formRecords={signed}
            assessmentId={assessmentId}
            isAmhpForm={false}
            refetch={refetch}
            user={user}
          />
        </AccordionSection>
      </Accordion>
    </>
  );
};

const styles = StyleSheet.create({
  button: {
    width: "100%",
    marginBottom: spacing[10],
  },
});

export default DoctorForms;
