import * as Yup from "yup";
import createPostcodeSchema from "libs/validators/yup/createPostcodeSchema";
import { ComponentElement, FormFieldsDoctor } from "libs/types/mhaForms";

const v1ComponentElements: ComponentElement[] = [
  {
    component: "Text",
    componentProps: {
      instruction: "I",
      guidance: "(PRINT full name and address of first practitioner)",
    },
    fullWidth: true,
  },
  {
    component: "TextInput",
    fieldName: FormFieldsDoctor.FirstPractitionerName,
    componentProps: {
      label: "Name",
    },
  },
  {
    component: "Location",
    fieldName: FormFieldsDoctor.FirstPractitionerAddress,
    componentProps: {
      label: "Address",
    },
  },
];

const v2ComponentElements: ComponentElement[] = [
  {
    component: "Text",
    componentProps: {
      instruction: "I",
      guidance:
        "(PRINT full name, address and, if sending by means of electronic communication, email address of first practitioner)",
    },
    fullWidth: true,
  },
  {
    component: "TextInput",
    fieldName: FormFieldsDoctor.FirstPractitionerName,
    componentProps: {
      label: "Name",
    },
  },
  {
    component: "Location",
    fieldName: FormFieldsDoctor.FirstPractitionerAddress,
    componentProps: {
      label: "Address",
    },
  },
  {
    component: "TextInput",
    fieldName: FormFieldsDoctor.FirstPractitionerEmailAddress,
    componentProps: {
      label: "Email Address",
      underlabel:
        "Email Address changes made here will automatically be applied to all future MHA forms containing your email address.",
    },
  },
];

export default {
  v1: {
    title: "Your Details",
    state: {
      firstPractitionerName: "",
      firstPractitionerAddress: {
        city: "",
        postcode: "",
        address: "",
      },
    },

    validation: Yup.object().shape({
      firstPractitionerName: Yup.string().trim().required("Please enter your name"),

      firstPractitionerAddress: Yup.object().shape({
        address: Yup.string(),
        city: Yup.string(),
        postcode: createPostcodeSchema({
          invalidPostcodeMessage: "Please enter a valid address",
          required: true,
          requiredMessage: "Please enter an address",
        }),
      }),
    }),

    componentElements: v1ComponentElements,
  },
  v2: {
    title: "Your Details",
    state: {
      firstPractitionerName: "",
      firstPractitionerAddress: {
        city: "",
        postcode: "",
        address: "",
      },
      firstPractitionerEmailAddress: "",
    },

    validation: Yup.object().shape({
      firstPractitionerName: Yup.string().trim().required("Please enter your name"),

      firstPractitionerAddress: Yup.object().shape({
        address: Yup.string(),
        city: Yup.string(),
        postcode: createPostcodeSchema({
          invalidPostcodeMessage: "Please enter a valid address",
          required: true,
          requiredMessage: "Please enter an address",
        }),
      }),
      firstPractitionerEmailAddress: Yup.string()
        .trim()
        .email("Please enter a valid email address")
        .required("Please enter an email address"),
    }),

    componentElements: v2ComponentElements,
  },
};
