import React, { useState } from "react";
import { ChildDataProps, graphql } from "react-apollo";
import merge from "lodash.merge";
import { useApolloClient } from "@apollo/react-hooks";
import { useSetRecoilState } from "recoil";

import {
  GET_DOCTOR_CLAIMS_BY_STATUS,
  GetDoctorClaimsResponse,
  GetDoctorClaimsByStatusResponse,
  ClaimsByStatusSectionResponse,
} from "@/models/DoctorProfile";
import { networkError, S12Error } from "@/models/Error";
import { GQL_LIMITS } from "@/models/gql/limits";
import { checkOfflineCache } from "@/utils/helpers";
import { doctorClaimsTabBadge } from "@/utils/recoil/index";

import ClaimResults from "./ClaimResults";
import { ErrorMessage } from "../Error/Error";
import Loading from "../Loading";

interface PropTypes {
  username: string;
  refreshRequired: boolean;
  onRefreshComplete: (value: React.SetStateAction<boolean>) => void;
}

export interface DoctorClaimNextTokens {
  actionRequired: string | null;
  underReview: string | null;
  approved: string | null;
  paid: string | null;
  rejected: string | null;
  onHold: string | null;
}

export type fetchMoreDoctorClaimTypes = "actionRequired" | "underReview" | "approved" | "paid" | "rejected" | "onHold";

const builder = graphql<
  PropTypes,
  GetDoctorClaimsResponse,
  Record<string, unknown>,
  ChildDataProps<Record<string, unknown>, GetDoctorClaimsByStatusResponse, Record<string, unknown>>
>(GET_DOCTOR_CLAIMS_BY_STATUS, {
  options: (props: PropTypes) => ({
    variables: {
      id: props.username,
      limit: GQL_LIMITS.doctorClaims,
      fetchPolicy: "cache-and-network",
    },
  }),
});

// TODO: remove duplicate from assessmentResults, replace with generic if possible
const extractNextToken = (claimList: ClaimsByStatusSectionResponse) => {
  return claimList && claimList.items && claimList.items.length >= GQL_LIMITS.doctorClaims ? claimList.nextToken : null;
};

export default builder(({ data, ...props }) => {
  const { loading, error, actionRequired, underReview, approved, paid, rejected, onHold, fetchMore } = data;
  const setDoctorClaimsTabBadge = useSetRecoilState(doctorClaimsTabBadge);

  const [nextTokens, setNextTokens] = useState<DoctorClaimNextTokens>({
    actionRequired: null,
    underReview: null,
    approved: null,
    paid: null,
    rejected: null,
    onHold: null,
  });

  const [fetchMoreDoctorClaimsType, setFetchMoreDoctorClaimsType] = useState<fetchMoreDoctorClaimTypes | null>(null);

  React.useEffect(() => {
    if (fetchMoreDoctorClaimsType) {
      fetchMore({
        query: GET_DOCTOR_CLAIMS_BY_STATUS,
        variables: {
          ...data.variables,
          [`${fetchMoreDoctorClaimsType}NextToken`]: nextTokens[fetchMoreDoctorClaimsType],
        },
        updateQuery: (
          previousResult,
          {
            fetchMoreResult,
          }: {
            fetchMoreResult: GetDoctorClaimsByStatusResponse;
          }
        ) => {
          const prevFetchMoreResultDoctorClaims = previousResult[fetchMoreDoctorClaimsType];
          const fetchMoreResultDoctorClaims = fetchMoreResult[fetchMoreDoctorClaimsType];

          const newClaims = {
            [`${fetchMoreDoctorClaimsType}`]: {
              items: [...prevFetchMoreResultDoctorClaims.items, ...fetchMoreResultDoctorClaims.items],
              nextToken: fetchMoreResultDoctorClaims.nextToken,
            },
          };
          const mergedClaims = merge({}, previousResult, newClaims);
          setFetchMoreDoctorClaimsType(null);
          return mergedClaims;
        },
      });
    }
  }, [fetchMoreDoctorClaimsType]);

  React.useEffect(() => {
    let hasUnloaded = false;
    if (data && props.refreshRequired) {
      // Prevents data from being fetched twice on initial load
      data
        .refetch()
        .then(() => {
          if (hasUnloaded) {
            return;
          }
          props.onRefreshComplete(false);
        })
        .catch(() => console.error(new S12Error(networkError)));
    }

    if (actionRequired && underReview && approved && rejected && paid && onHold) {
      setNextTokens({
        actionRequired: extractNextToken(actionRequired),
        underReview: extractNextToken(underReview),
        approved: extractNextToken(approved),
        paid: extractNextToken(paid),
        rejected: extractNextToken(rejected),
        onHold: extractNextToken(onHold),
      });
    }

    return () => {
      hasUnloaded = true;
    };
  }, [actionRequired, underReview, approved, rejected, paid, props.refreshRequired]);

  React.useEffect(() => {
    actionRequired && actionRequired.items.length > 0
      ? setDoctorClaimsTabBadge(actionRequired.items.length)
      : setDoctorClaimsTabBadge(0);
  }, [actionRequired]);

  const client = useApolloClient();
  const { variables } = data;

  const complete = checkOfflineCache(client, GET_DOCTOR_CLAIMS_BY_STATUS, variables);
  if (error && !complete) {
    console.log(error);
    return <ErrorMessage apolloError={error} />;
  } else if (!complete && !actionRequired) {
    return <Loading />;
  } else {
    return (
      <ClaimResults
        loading={loading}
        actionRequiredClaims={actionRequired ? actionRequired.items : []}
        underReviewClaims={underReview ? underReview.items : []}
        approvedClaims={approved ? approved.items : []}
        rejectedClaims={rejected ? rejected.items : []}
        paidClaims={paid ? paid.items : []}
        onHoldClaims={onHold ? onHold.items : []}
        setFetchMoreTypeFn={setFetchMoreDoctorClaimsType}
        nextTokens={nextTokens}
      />
    );
  }
});
