import { useEffect } from "react";
import { ParamListBase, useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";

/**
 * Run the provided callback every time a screen has gained focus.
 *
 * @param {Function} callback
 * @author [Alex McCabe](https://github.com/alexmccabe)
 */
export default function useOnScreenFocus(callback: () => void) {
  const navigation = useNavigation<StackNavigationProp<ParamListBase>>();

  useEffect(() => {
    const unsubscribe = navigation.addListener("focus", callback);

    return unsubscribe;
  }, [navigation]);
}
