import React, { useRef } from "react";
import { Platform, StyleSheet, View } from "react-native";
import { TouchableRipple } from "react-native-paper";
import { useHover } from "react-native-web-hooks";

import { MultiSelectCheckbox } from "./MultiSelectCheckbox";
import { palette, spacing } from "@/theme";
import Text from "@/components/Text";
import { TypographyType } from "@/models/Typography";
import Icon, { IconName } from "@/components/Icon";

interface MultiSelectOptionProps {
  checked: boolean;
  children: React.ReactNode;
  onPress: () => void;
  readonly?: boolean;
  color?: string;
  disabled?: boolean;
}

export const MultiSelectOption = ({
  checked,
  children,
  onPress,
  readonly,
  color,
  disabled,
}: MultiSelectOptionProps) => {
  const optionRef = useRef<TouchableRipple>(null);
  const isHovered = useHover(optionRef);

  return (
    <TouchableRipple
      ref={optionRef}
      rippleColor={palette.rippleColor}
      style={styles.option}
      onPress={onPress}
      disabled={disabled}
    >
      <>
        {!!color && <View style={[styles.sideClip, { backgroundColor: color }]} />}
        <View style={styles.row}>
          <View style={styles.content}>
            {children}
            {disabled ? (
              <View style={styles.tidyRow}>
                <Icon name={IconName.info_outline} color={palette.navy} size={14} style={{ marginRight: 5 }} />
                <Text style={{ marginTop: 5 }} format={TypographyType.Micro} color={palette.navy} marginBottom={5}>
                  Form has expired in line with our data retention policy
                </Text>
              </View>
            ) : null}
          </View>

          {!disabled && !readonly && (
            <View style={styles.checkbox}>
              <MultiSelectCheckbox checked={checked} />
            </View>
          )}
        </View>
        {!disabled
          ? isHovered && <View style={styles.hoveredBG} />
          : isHovered && <View style={styles.hoveredDisabled} />}
      </>
    </TouchableRipple>
  );
};

const styles = StyleSheet.create({
  checkbox: {
    flexShrink: 0,
  },

  content: {
    flex: 1,
    flexShrink: 1,
    paddingRight: spacing[20],
  },

  sideClip: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    width: 4,
    borderTopLeftRadius: 6,
    borderBottomLeftRadius: 6,
  },

  hoveredBG: {
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    backgroundColor: palette.cloud,
    zIndex: -1,
    opacity: 0.5,
  },

  hoveredDisabled: {
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    backgroundColor: palette.pinkFaded,
    zIndex: -1,
    opacity: 0.5,
  },

  option: {
    position: "relative",
    paddingHorizontal: spacing[20],
    paddingVertical: spacing[15],
    backgroundColor: palette.white,
    shadowColor: palette.blueShadow,
    borderRadius: 6,
    shadowRadius: 6,
    shadowOpacity: 0.3,
    shadowOffset: { width: 0, height: 0 },
    ...Platform.select({
      web: {
        cursor: "pointer",
      },
    }),
  },

  row: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    flex: 1,
  },

  tidyRow: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    flex: 1,
  },
});
