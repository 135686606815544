import React from "react";

import Icon from "@/components/Icon";

interface PropTypes {
  color: string;
  size?: number;
}

const TabIcon = (name: string) => (props: PropTypes) => {
  const { color, size } = props;

  return <Icon {...{ color, name, size }} />;
};

export default TabIcon;
