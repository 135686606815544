/* tslint:disable */
//  This file was automatically generated and should not be edited.

export enum OrgType {
  CCG = "CCG",
  CSU = "CSU",
  MHT = "MHT",
  LA = "LA",
}


export enum LocationType {
  gPPractice = "gPPractice",
  assessmentLocation = "assessmentLocation",
  patientAddress = "patientAddress",
}


export enum Gender {
  M = "M",
  F = "F",
  TF = "TF",
  TM = "TM",
  U = "U",
}


export enum FuelType {
  petrol = "petrol",
  diesel = "diesel",
  lpg = "lpg",
  hybridPetrol = "hybridPetrol",
  hybridDiesel = "hybridDiesel",
  electric = "electric",
  bicycle = "bicycle",
}


export enum Engine {
  small = "small",
  medium = "medium",
  large = "large",
}


export enum ClaimStatus {
  action_required = "action_required",
  under_review = "under_review",
  approved = "approved",
  rejected = "rejected",
  approved_and_paid = "approved_and_paid",
  on_hold = "on_hold"
}


export enum DoctorConfirmation {
  dpt_inside_contracted_hours = "dpt_inside_contracted_hours",
  dpt_outside_contracted_hours = "dpt_outside_contracted_hours",
  independent_capacity = "independent_capacity",
}


export enum DoctorEmployedStatusValues {
  payroll = "payroll",
  non_payroll = "non_payroll",
}


export type CreateClaimInput = {
  id?: string | null,
  claimVisitId: string,
  claimDoctorId: string,
  amhpTeamId: string,
  claimOrganisationId: string,
  displayId: string,
  locationText: string,
  claimPostcode?: string | null,
  locationType: LocationType,
  assessmentPostcode?: string | null,
  locationName?: LocationNameInput | null,
  patientInitials: string,
  patientFullName?: string | null,
  visitDate: string,
  assessmentTookPlace?: boolean | null,
  patientNhsNumber?: string | null,
  notes?: Array< string | null > | null,
  claimAmhpId: string,
};

export type LocationNameInput = {
  city?: string | null,
  postcode: string,
  address?: string | null,
  addressNotes?: string | null,
};

export type UpdateClaimInput = {
  id: string,
  assigneeId?: string | null,
  assigneeName?: string | null,
  status?: ClaimStatus | null,
  mileage?: string | null,
  startingPostcode?: string | null,
  visitDate?: string | null,
  notes?: string | null,
  additionalExpenses?: string | null,
  additionalInformation?: string | null,
  declaration?: string | null,
  paid?: boolean | null,
  claimData?: ClaimDataInput | null,
  invoiceData?: InvoiceDataInput | null,
  lineManager?: string | null,
  doctorAdditionalConf?: DoctorConfirmation | null,
  isIndependentDoctor?: boolean | null,
  defaultMHT?: string | null,
  doctorEmployedStatus?: DoctorEmployedStatusValues | null,
  claimVisitId?: string | null,
  claimDoctorId?: string | null,
  claimAmhpId?: string | null,
  claimOrganisationId?: string | null,
};

export type ClaimDataInput = {
  billingAddress?: LocationNameInput | null,
  billingCompanyName?: string | null,
  vatRegistration?: string | null,
  fuelType?: FuelType | null,
  engineSize?: Engine | null,
};

export type InvoiceDataInput = {
  assessmentFee: string,
  travelFee: string,
  locationOfAssessment?: string | null,
};

export type DeleteClaimInput = {
  id?: string | null,
};

export type AdminCreateAmhpInput = {
  name: string,
  email: string,
  phone?: string | null,
  mfa?: boolean | null,
  teams: Array< string >,
  sso?: string | null,
  updatedBy: string,
  isTraineeAmhp?: boolean | null,
};

export type AdminUpdateAmhpInput = {
  id: string,
  name?: string | null,
  email?: string | null,
  phone?: string | null,
  mfa?: boolean | null,
  teams: Array< string >,
  sso?: string | null,
  updatedBy: string,
  isTraineeAmhp?: boolean | null,
};

export type AdminCreateDoctorInput = {
  id?: string | null,
  gmc: string,
  name?: string | null,
  legalName: string,
  phone?: string | null,
  phone2?: string | null,
  email: string,
  location: LocationInput,
  locationName: LocationNameInput,
  availabilityLocations?: Array< LocationInput | null > | null,
  availabilityPostcodes?: Array< string | null > | null,
  addMhts?: Array< string | null > | null,
  removeMhts?: Array< string | null > | null,
  employer?: string | null,
  specialties: Array< string | null >,
  languages: Array< string | null >,
  gender?: Gender | null,
  notes?: string | null,
  siteName: string,
  sso?: string | null,
  mfa?: boolean | null,
  isOutsideEngland?: boolean | null,
  hideFromSearch?: boolean | null,
  updatedBy: string,
};

export type LocationInput = {
  lat: number,
  lon: number,
};

export type AdminUpdateDoctorInput = {
  id: string,
  gmc?: string | null,
  name?: string | null,
  legalName?: string | null,
  phone?: string | null,
  phone2?: string | null,
  email?: string | null,
  location?: LocationInput | null,
  locationName?: LocationNameInput | null,
  availabilityLocations?: Array< LocationInput | null > | null,
  availabilityPostcodes?: Array< string | null > | null,
  addMhts?: Array< string | null > | null,
  removeMhts?: Array< string | null > | null,
  employer?: string | null,
  specialties?: Array< string | null > | null,
  languages?: Array< string | null > | null,
  gender?: Gender | null,
  notes?: string | null,
  siteName?: string | null,
  sso?: string | null,
  mfa?: boolean | null,
  isOutsideEngland?: boolean | null,
  hideFromSearch?: boolean | null,
  updatedBy: string,
};

export type DeleteUserInput = {
  id?: string | null,
  gmc?: string | null,
  email: string,
  type: DeleteUserType,
  teams?: Array< string > | null,
  deletedBy: string,
  organisations?: Array< string > | null,
};

export enum DeleteUserType {
  AMHP = "AMHP",
  DOCTOR = "DOCTOR",
  ORGCLAIMS = "ORGCLAIMS",
}


export type AdminCreateAmhpTeamInput = {
  name: string,
  featureFlags?: string | null,
  features?: string | null,
  localAuthority?: string | null,
  emails?: Array< string > | null,
  hospitalEmails?: Array< EmailAddressInput > | null,
  mhaEmailAddresses?: Array< Array< EmailAddressInput > | null > | null,
  mhaInboxInstructions?: string | null,
  phoneNumbers?: Array< string > | null,
  updatedBy: string,
};

export type EmailAddressInput = {
  name: string,
  email: string,
};

export type AdminUpdateAmhpTeamInput = {
  id: string,
  name: string,
  featureFlags?: string | null,
  features?: string | null,
  localAuthority?: string | null,
  emails?: Array< string > | null,
  hospitalEmails?: Array< EmailAddressInput > | null,
  mhaEmailAddresses?: Array< Array< EmailAddressInput > | null > | null,
  mhaInboxInstructions?: string | null,
  phoneNumbers?: Array< string > | null,
  updatedBy: string,
};

export type AdminResetPasswordInput = {
  userName: string,
  password: string,
};

export type DisableUserInput = {
  gmc?: string | null,
  email: string,
  type: DeleteUserType,
  updatedBy: string,
};

export type ReenableUserInput = {
  gmc?: string | null,
  email: string,
  type: DeleteUserType,
  updatedBy: string,
};

export type CreateAvailabilityInput = {
  id?: string | null,
  createdAt?: string | null,
  type?: AvailabilityType | null,
  location?: LocationInput | null,
  locationName?: LocationNameInput | null,
  rrule: string,
  endHour: number,
  endDate: string,
  contractList?: ContractListInput | null,
  notes?: string | null,
  s12DoctorAvailabilitiesId: string,
  availabilityDoctorId: string,
  oncall?: boolean | null,
  rotaId?: string | null,
  rotaName?: string | null,
  availabilityMhtId?: string | null,
};

export enum AvailabilityType {
  trust = "trust",
  independent = "independent",
  oncall = "oncall",
  away = "away",
  hidden = "hidden",
  booking = "booking",
  unavailable = "unavailable",
}


export type ContractListInput = {
  name: string,
  description?: string | null,
};

export type UpdateAvailabilityInput = {
  id: string,
  createdAt?: string | null,
  type?: AvailabilityType | null,
  location?: LocationInput | null,
  locationName?: LocationNameInput | null,
  rrule?: string | null,
  endHour?: number | null,
  endDate?: string | null,
  contractList?: ContractListInput | null,
  notes?: string | null,
  s12DoctorAvailabilitiesId?: string | null,
  availabilityDoctorId?: string | null,
  oncall?: boolean | null,
  rotaId?: string | null,
  rotaName?: string | null,
  availabilityMhtId?: string | null,
};

export type DeleteAvailabilityInput = {
  id?: string | null,
};

export type CreateHolidayInput = {
  id?: string | null,
  start: string,
  end: string,
  visible: boolean,
  notes?: string | null,
  s12DoctorHolidaysId: string,
};

export type UpdateHolidayInput = {
  id: string,
  start?: string | null,
  end?: string | null,
  visible?: boolean | null,
  notes?: string | null,
  s12DoctorHolidaysId?: string | null,
};

export type DeleteHolidayInput = {
  id?: string | null,
};

export type CreateOrganisationInput = {
  id: string,
  name: string,
  featureFlags?: string | null,
  features?: string | null,
  type: OrgType,
  location?: LocationInput | null,
  locationName?: LocationNameInput | null,
  phone?: string | null,
  email?: string | null,
  expensesInstruction?: string | null,
  declarationText?: string | null,
  additionalNotesText?: string | null,
  paymentRates?: Array< PaymentRatesInput | null > | null,
  invoiceEmail?: string | null,
  updatedBy?: string | null,
  mhtHospitalEmails?: Array< HospitalEmailsInput | null > | null,
  rotas?: Array< RotaItemInput | null > | null,
  deleted?: boolean | null,
  organisationCcgId?: string | null,
  organisationMhtId?: string | null,
};

export type PaymentRatesInput = {
  description?: string | null,
  assessmentFee: number,
  mileage: number,
};

export type HospitalEmailsInput = {
  name: string,
  wards: Array< EmailAddressInput >,
};

export type RotaItemInput = {
  id: string,
  name: string,
};

export type UpdateOrganisationInput = {
  id: string,
  name?: string | null,
  featureFlags?: string | null,
  features?: string | null,
  type: OrgType,
  location?: LocationInput | null,
  locationName?: LocationNameInput | null,
  phone?: string | null,
  email?: string | null,
  expensesInstruction?: string | null,
  declarationText?: string | null,
  additionalNotesText?: string | null,
  paymentRates?: Array< PaymentRatesInput | null > | null,
  invoiceEmail?: string | null,
  updatedBy?: string | null,
  mhtHospitalEmails?: Array< HospitalEmailsInput | null > | null,
  rotas?: Array< RotaItemInput | null > | null,
  deleted?: boolean | null,
  organisationCcgId?: string | null,
  organisationMhtId?: string | null,
};

export type DeleteOrganisationInput = {
  type: OrgType,
  id: string,
};

export type CreateMHTInput = {
  id?: string | null,
  abbreviation: string,
  mHTOrganisationId: string,
};

export type UpdateMHTInput = {
  id: string,
  abbreviation?: string | null,
  mHTOrganisationId?: string | null,
};

export type DeleteMHTInput = {
  id?: string | null,
};

export type CreateMHTS12DoctorInput = {
  id?: string | null,
  mHTS12DoctorS12DoctorId: string,
  mHTS12DoctorMhtId: string,
};

export type UpdateMHTS12DoctorInput = {
  id: string,
  mHTS12DoctorS12DoctorId?: string | null,
  mHTS12DoctorMhtId?: string | null,
};

export type DeleteMHTS12DoctorInput = {
  id?: string | null,
};

export type CreateOrganisationUserInput = {
  id?: string | null,
  organisationUserOrganisationId: string,
  type: string,
  organisationUserUserId: string,
};

export type UpdateOrganisationUserInput = {
  id: string,
  organisationUserOrganisationId?: string | null,
  type?: string | null,
  organisationUserUserId?: string | null,
};

export type DeleteOrganisationUserInput = {
  id?: string | null,
};

export type CreateUserInput = {
  id?: string | null,
  name: string,
  email: string,
  phone?: string | null,
};

export type UpdateUserInput = {
  id: string,
  name?: string | null,
  email?: string | null,
  phone?: string | null,
};

export type CreateAmhpTeamUserInput = {
  id?: string | null,
  amhpTeamUserAmhpTeamId: string,
  amhpTeamUserAmhpId: string,
};

export type UpdateAmhpTeamUserInput = {
  id: string,
  amhpTeamUserAmhpTeamId?: string | null,
  amhpTeamUserAmhpId?: string | null,
};

export type DeleteAmhpTeamUserInput = {
  id?: string | null,
};

export type CreateSiteInput = {
  id?: string | null,
  name: string,
};

export type UpdateSiteInput = {
  id: string,
  name?: string | null,
};

export type DeleteSiteInput = {
  id?: string | null,
};

export type CreateLinkedSitesInput = {
  id?: string | null,
  parentSite: string,
  childSite: string,
};

export type UpdateLinkedSitesInput = {
  id: string,
  parentSite?: string | null,
  childSite?: string | null,
};

export type DeleteLinkedSitesInput = {
  id?: string | null,
};

export type CreateSiteOrganisationInput = {
  id?: string | null,
  organisationType?: string | null,
  siteOrganisationSiteId: string,
  siteOrganisationOrganisationId: string,
};

export type UpdateSiteOrganisationInput = {
  id: string,
  organisationType?: string | null,
  siteOrganisationSiteId?: string | null,
  siteOrganisationOrganisationId?: string | null,
};

export type DeleteSiteOrganisationInput = {
  id?: string | null,
};

export type CreateAmhpTeamInput = {
  id?: string | null,
  name: string,
  featureFlags?: string | null,
  features?: string | null,
  localAuthority?: string | null,
  hospitalEmails?: Array< HospitalEmailsInput > | null,
  mhaEmailAddresses?: Array< RecipientEmailsInput | null > | null,
  emails?: Array< string > | null,
  phoneNumbers?: Array< string > | null,
  mhaInboxInstructions?: string | null,
  updatedBy?: string | null,
  siteAmhpTeamsId?: string | null,
};

export type RecipientEmailsInput = {
  name?: string | null,
  recipients?: Array< RecipientEmailAddressInput | null > | null,
};

export type RecipientEmailAddressInput = {
  name?: string | null,
  email?: string | null,
};

export type UpdateAmhpTeamInput = {
  id: string,
  name?: string | null,
  featureFlags?: string | null,
  features?: string | null,
  localAuthority?: string | null,
  hospitalEmails?: Array< HospitalEmailsInput > | null,
  mhaEmailAddresses?: Array< RecipientEmailsInput | null > | null,
  emails?: Array< string > | null,
  phoneNumbers?: Array< string > | null,
  mhaInboxInstructions?: string | null,
  updatedBy?: string | null,
  siteAmhpTeamsId?: string | null,
};

export type DeleteAmhpTeamInput = {
  id?: string | null,
};

export type CreateAddressInput = {
  id?: string | null,
  amhpTeamId: string,
  locationName: LocationNameInput,
  location: LocationInput,
  ccgId: string,
};

export type UpdateAddressInput = {
  id: string,
  amhpTeamId?: string | null,
  locationName?: LocationNameInput | null,
  location?: LocationInput | null,
  ccgId?: string | null,
};

export type DeleteAddressInput = {
  id?: string | null,
};

export type CreateAssessmentInput = {
  id?: string | null,
  createdAt: string,
  amhpTeamId: string,
  amhpId: string,
  patientName: string,
  location: LocationInput,
  ccgId?: string | null,
  ccgDetermination?: LocationType | null,
  locationName: LocationNameInput,
  hadVisitAdded?: boolean | null,
  patientNhsNumber?: string | null,
  assessmentDate?: string | null,
  patientAddress?: LocationNameInput | null,
};

export enum FormStatus {
  s10_draft = "s10_draft",
  s20_awaiting_other_doctor = "s20_awaiting_other_doctor",
  s30_changes_in_progress = "s30_changes_in_progress",
  s40_signed = "s40_signed",
  s50_signed_and_sent = "s50_signed_and_sent",
  s60_signed_in_amhp_team_inbox = "s60_signed_in_amhp_team_inbox",
  s70_multi_stage_form_progressed = "s70_multi_stage_form_progressed",
}


export type UpdateAssessmentInput = {
  id: string,
  createdAt?: string | null,
  amhpTeamId?: string | null,
  amhpId?: string | null,
  patientName?: string | null,
  location?: LocationInput | null,
  ccgId?: string | null,
  ccgDetermination?: LocationType | null,
  locationName?: LocationNameInput | null,
  hadVisitAdded?: boolean | null,
  patientNhsNumber?: string | null,
  assessmentDate?: string | null,
  patientAddress?: LocationNameInput | null,
};

export type DeleteAssessmentInput = {
  id?: string | null,
};

export type CreateContactedDoctorInput = {
  id?: string | null,
  assessmentId: string,
  contactedDoctorS12DoctorId: string,
  createdAt: string,
};

export type UpdateContactedDoctorInput = {
  id: string,
  assessmentId?: string | null,
  contactedDoctorS12DoctorId?: string | null,
  createdAt?: string | null,
};

export type DeleteContactedDoctorInput = {
  id?: string | null,
};

export type CreateS12DoctorInput = {
  id?: string | null,
  gmc: string,
  s12Expiry?: string | null,
  name: string,
  legalName: string,
  phone?: string | null,
  phone2?: string | null,
  email: string,
  location: LocationInput,
  locationName: LocationNameInput,
  gl?: boolean | null,
  employer?: string | null,
  jobTitle?: string | null,
  responsibleOfficer?: string | null,
  lineManager?: string | null,
  defaultMHT?: string | null,
  specialties: Array< string | null >,
  languages: Array< string | null >,
  gender?: Gender | null,
  notes?: string | null,
  pushTokens?: Array< string | null > | null,
  distance?: number | null,
  hideFromSearch?: boolean | null,
  availabilityLocations?: Array< LocationInput | null > | null,
  availabilityPostcodes?: Array< string | null > | null,
  siteName: string,
  claimData?: ClaimDataInput | null,
  sectionFormAddress?: LocationNameInput | null,
  sectionFormEmailAddress?: string | null,
  createdAt?: string | null,
  lastActivity?: string | null,
  isOutsideEngland?: boolean | null,
};

export type UpdateS12DoctorInput = {
  id: string,
  gmc?: string | null,
  s12Expiry?: string | null,
  name?: string | null,
  legalName?: string | null,
  phone?: string | null,
  phone2?: string | null,
  email?: string | null,
  location?: LocationInput | null,
  locationName?: LocationNameInput | null,
  gl?: boolean | null,
  employer?: string | null,
  jobTitle?: string | null,
  responsibleOfficer?: string | null,
  lineManager?: string | null,
  defaultMHT?: string | null,
  specialties?: Array< string | null > | null,
  languages?: Array< string | null > | null,
  gender?: Gender | null,
  notes?: string | null,
  pushTokens?: Array< string | null > | null,
  distance?: number | null,
  hideFromSearch?: boolean | null,
  availabilityLocations?: Array< LocationInput | null > | null,
  availabilityPostcodes?: Array< string | null > | null,
  siteName?: string | null,
  claimData?: ClaimDataInput | null,
  sectionFormAddress?: LocationNameInput | null,
  sectionFormEmailAddress?: string | null,
  createdAt?: string | null,
  lastActivity?: string | null,
  isOutsideEngland?: boolean | null,
};

export type DeleteS12DoctorInput = {
  id?: string | null,
};

export type UpdateDoctorVisitInput = {
  id: string,
  createdAt?: string | null,
  assessmentId?: string | null,
  time?: string | null,
  teamId?: string | null,
  doctorVisitDoctorId?: string | null,
  notes?: Array< string | null > | null,
  partialPostcode?: string | null,
  doctorVisitClaimId?: string | null,
  claimDisplayId?: string | null,
  claimStatus?: ClaimStatus | null,
};

export type DeleteDoctorVisitInput = {
  id?: string | null,
};

export type CreateAmhpProfileInput = {
  id?: string | null,
  email: string,
  phone?: string | null,
  name: string,
  legalName?: string | null,
  deleted?: boolean | null,
  warrantInfo?: string | null,
  accreditation?: string | null,
  sectionFormAddress?: LocationNameInput | null,
  sectionFormEmailAddress?: string | null,
  sectionFormContactNumber?: string | null,
  pushTokens?: Array< string | null > | null,
  lastActivity?: string | null,
  createdAt?: string | null,
};

export type UpdateAmhpProfileInput = {
  id: string,
  email?: string | null,
  phone?: string | null,
  name?: string | null,
  legalName?: string | null,
  deleted?: boolean | null,
  warrantInfo?: string | null,
  accreditation?: string | null,
  sectionFormAddress?: LocationNameInput | null,
  sectionFormEmailAddress?: string | null,
  sectionFormContactNumber?: string | null,
  pushTokens?: Array< string | null > | null,
  lastActivity?: string | null,
  createdAt?: string | null,
};

export type DeleteAmhpProfileInput = {
  id?: string | null,
};

export type CreateCCGInput = {
  id?: string | null,
  claimProcessorType?: OrgType | null,
  cCGOrganisationId: string,
  cCGClaimProcessorId?: string | null,
};

export type UpdateCCGInput = {
  id: string,
  claimProcessorType?: OrgType | null,
  cCGOrganisationId?: string | null,
  cCGClaimProcessorId?: string | null,
};

export type DeleteCCGInput = {
  id?: string | null,
};

export type CreateReportingInput = {
  id?: string | null,
  pk: string,
  sk: string,
  ttl?: number | null,
  gsi1pk?: string | null,
  gsi1sk?: string | null,
  gsi2pk?: string | null,
  gsi2sk?: string | null,
  gsi3pk?: string | null,
  gsi3sk?: string | null,
  eventName: string,
  userId: string,
  timestamp: string,
  period: string,
  count?: number | null,
  siteId?: string | null,
  teamId?: string | null,
  orgId?: string | null,
  amhpId?: string | null,
  doctorId?: string | null,
  claimProcessorId?: string | null,
  attributes?: Array< AttributeInput > | null,
  metrics?: Array< MetricInput > | null,
};

export type AttributeInput = {
  name: string,
  value: string,
};

export type MetricInput = {
  name: string,
  value: number,
};

export type UpdateReportingInput = {
  id: string,
  pk: string,
  sk: string,
  ttl?: number | null,
  gsi1pk?: string | null,
  gsi1sk?: string | null,
  gsi2pk?: string | null,
  gsi2sk?: string | null,
  gsi3pk?: string | null,
  gsi3sk?: string | null,
  eventName?: string | null,
  userId?: string | null,
  timestamp?: string | null,
  period?: string | null,
  count?: number | null,
  siteId?: string | null,
  teamId?: string | null,
  orgId?: string | null,
  amhpId?: string | null,
  doctorId?: string | null,
  claimProcessorId?: string | null,
  attributes?: Array< AttributeInput > | null,
  metrics?: Array< MetricInput > | null,
};

export type DeleteReportingInput = {
  pk: string,
  sk: string,
};

export type ModelClaimFilterInput = {
  id?: ModelIDFilterInput | null,
  claimAssessmentId?: ModelIDFilterInput | null,
  claimVisitId?: ModelIDFilterInput | null,
  claimDoctorId?: ModelIDFilterInput | null,
  assigneeId?: ModelIDFilterInput | null,
  assigneeName?: ModelStringFilterInput | null,
  amhpTeamId?: ModelIDFilterInput | null,
  claimAmhpId?: ModelIDFilterInput | null,
  claimOrganisationId?: ModelIDFilterInput | null,
  claimCsuId?: ModelIDFilterInput | null,
  status?: ModelClaimStatusFilterInput | null,
  displayId?: ModelStringFilterInput | null,
  mileage?: ModelStringFilterInput | null,
  locationText?: ModelStringFilterInput | null,
  claimPostcode?: ModelStringFilterInput | null,
  locationType?: ModelLocationTypeFilterInput | null,
  assessmentPostcode?: ModelStringFilterInput | null,
  patientInitials?: ModelStringFilterInput | null,
  patientFullName?: ModelStringFilterInput | null,
  startingPostcode?: ModelStringFilterInput | null,
  visitDate?: ModelStringFilterInput | null,
  assessmentTookPlace?: ModelBooleanFilterInput | null,
  notes?: ModelStringFilterInput | null,
  unread?: ModelBooleanFilterInput | null,
  createdAt?: ModelStringFilterInput | null,
  updatedAt?: ModelStringFilterInput | null,
  receivedDate?: ModelStringFilterInput | null,
  additionalExpenses?: ModelStringFilterInput | null,
  additionalInformation?: ModelStringFilterInput | null,
  patientNhsNumber?: ModelStringFilterInput | null,
  declaration?: ModelStringFilterInput | null,
  paid?: ModelBooleanFilterInput | null,
  lineManager?: ModelStringFilterInput | null,
  and?: Array< ModelClaimFilterInput | null > | null,
  or?: Array< ModelClaimFilterInput | null > | null,
  not?: ModelClaimFilterInput | null,
};

export type ModelIDFilterInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelStringFilterInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelClaimStatusFilterInput = {
  eq?: ClaimStatus | null,
  ne?: ClaimStatus | null,
};

export type ModelLocationTypeFilterInput = {
  eq?: LocationType | null,
  ne?: LocationType | null,
};

export type ModelBooleanFilterInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type PaginateClauseInput = {
  limit: number,
  offset: number,
};

export type GenericOrderClauseInput = {
  field: string,
  direction: ModelSortDirection,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelAssessmentFilterInput = {
  id?: ModelIDFilterInput | null,
  createdAt?: ModelStringFilterInput | null,
  amhpTeamId?: ModelIDFilterInput | null,
  amhpId?: ModelIDFilterInput | null,
  patientName?: ModelStringFilterInput | null,
  ccgId?: ModelStringFilterInput | null,
  ccgDetermination?: ModelLocationTypeFilterInput | null,
  hadVisitAdded?: ModelBooleanFilterInput | null,
  patientNhsNumber?: ModelStringFilterInput | null,
  assessmentDate?: ModelStringFilterInput | null,
  and?: Array< ModelAssessmentFilterInput | null > | null,
  or?: Array< ModelAssessmentFilterInput | null > | null,
  not?: ModelAssessmentFilterInput | null,
};

export type ModelFormFilterInput = {
  id?: ModelIDFilterInput | null,
  sk?: ModelStringFilterInput | null,
  ttl?: ModelIntFilterInput | null,
  version?: ModelIntFilterInput | null,
  gsi1pk?: ModelIDFilterInput | null,
  gsi1sk?: ModelStringFilterInput | null,
  gsi2pk?: ModelIDFilterInput | null,
  gsi2sk?: ModelStringFilterInput | null,
  gsi3pk?: ModelStringFilterInput | null,
  gsi3sk?: ModelStringFilterInput | null,
  assessmentId?: ModelIDFilterInput | null,
  status?: ModelFormStatusFilterInput | null,
  createdBy?: ModelStringFilterInput | null,
  createdAt?: ModelStringFilterInput | null,
  updatedAt?: ModelStringFilterInput | null,
  updatedBy?: ModelStringFilterInput | null,
  amhpTeamInboxId?: ModelStringFilterInput | null,
  amhpTeamId?: ModelStringFilterInput | null,
  authors?: ModelStringFilterInput | null,
  doctorNames?: ModelStringFilterInput | null,
  signed?: ModelStringFilterInput | null,
  signedClaims?: ModelStringFilterInput | null,
  sha256?: ModelStringFilterInput | null,
  notes?: ModelStringFilterInput | null,
  type?: ModelStringFilterInput | null,
  isDeleted?: ModelBooleanFilterInput | null,
  and?: Array< ModelFormFilterInput | null > | null,
  or?: Array< ModelFormFilterInput | null > | null,
  not?: ModelFormFilterInput | null,
};

export type ModelIntFilterInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelFormStatusFilterInput = {
  eq?: FormStatus | null,
  ne?: FormStatus | null,
};

export type ModelSiteFilterInput = {
  id?: ModelIDFilterInput | null,
  name?: ModelStringFilterInput | null,
  createdAt?: ModelStringFilterInput | null,
  and?: Array< ModelSiteFilterInput | null > | null,
  or?: Array< ModelSiteFilterInput | null > | null,
  not?: ModelSiteFilterInput | null,
};

export type ModelS12DoctorFilterInput = {
  id?: ModelIDFilterInput | null,
  gmc?: ModelStringFilterInput | null,
  s12Expiry?: ModelStringFilterInput | null,
  name?: ModelStringFilterInput | null,
  legalName?: ModelStringFilterInput | null,
  phone?: ModelStringFilterInput | null,
  phone2?: ModelStringFilterInput | null,
  email?: ModelStringFilterInput | null,
  gl?: ModelBooleanFilterInput | null,
  employer?: ModelStringFilterInput | null,
  jobTitle?: ModelStringFilterInput | null,
  responsibleOfficer?: ModelStringFilterInput | null,
  lineManager?: ModelStringFilterInput | null,
  specialties?: ModelStringFilterInput | null,
  languages?: ModelStringFilterInput | null,
  gender?: ModelGenderFilterInput | null,
  notes?: ModelStringFilterInput | null,
  pushTokens?: ModelStringFilterInput | null,
  distance?: ModelFloatFilterInput | null,
  availabilityPostcodes?: ModelStringFilterInput | null,
  siteName?: ModelStringFilterInput | null,
  sectionFormEmailAddress?: ModelStringFilterInput | null,
  createdAt?: ModelStringFilterInput | null,
  lastActivity?: ModelStringFilterInput | null,
  isOutsideEngland?: ModelBooleanFilterInput | null,
  hideFromSearch?: ModelBooleanFilterInput | null,
  and?: Array< ModelS12DoctorFilterInput | null > | null,
  or?: Array< ModelS12DoctorFilterInput | null > | null,
  not?: ModelS12DoctorFilterInput | null,
};

export type ModelGenderFilterInput = {
  eq?: Gender | null,
  ne?: Gender | null,
};

export type ModelFloatFilterInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelSiteOrganisationFilterInput = {
  id?: ModelIDFilterInput | null,
  organisationType?: ModelStringFilterInput | null,
  and?: Array< ModelSiteOrganisationFilterInput | null > | null,
  or?: Array< ModelSiteOrganisationFilterInput | null > | null,
  not?: ModelSiteOrganisationFilterInput | null,
};

export type ModelAddressFilterInput = {
  id?: ModelIDFilterInput | null,
  amhpTeamId?: ModelIDFilterInput | null,
  ccgId?: ModelIDFilterInput | null,
  and?: Array< ModelAddressFilterInput | null > | null,
  or?: Array< ModelAddressFilterInput | null > | null,
  not?: ModelAddressFilterInput | null,
};

export type ModelDoctorVisitFilterInput = {
  id?: ModelIDFilterInput | null,
  createdAt?: ModelStringFilterInput | null,
  assessmentId?: ModelIDFilterInput | null,
  time?: ModelStringFilterInput | null,
  teamId?: ModelIDFilterInput | null,
  doctorVisitDoctorId?: ModelIDFilterInput | null,
  notes?: ModelStringFilterInput | null,
  partialPostcode?: ModelStringFilterInput | null,
  doctorVisitClaimId?: ModelIDFilterInput | null,
  claimDisplayId?: ModelIDFilterInput | null,
  claimStatus?: ModelClaimStatusFilterInput | null,
  and?: Array< ModelDoctorVisitFilterInput | null > | null,
  or?: Array< ModelDoctorVisitFilterInput | null > | null,
  not?: ModelDoctorVisitFilterInput | null,
};

export type ModelAvailabilityFilterInput = {
  id?: ModelIDFilterInput | null,
  createdAt?: ModelStringFilterInput | null,
  type?: ModelAvailabilityTypeFilterInput | null,
  rrule?: ModelStringFilterInput | null,
  endHour?: ModelIntFilterInput | null,
  endDate?: ModelStringFilterInput | null,
  notes?: ModelStringFilterInput | null,
  s12DoctorAvailabilitiesId?: ModelIDFilterInput | null,
  availabilityDoctorId?: ModelIDFilterInput | null,
  oncall?: ModelBooleanFilterInput | null,
  rotaId?: ModelStringFilterInput | null,
  and?: Array< ModelAvailabilityFilterInput | null > | null,
  or?: Array< ModelAvailabilityFilterInput | null > | null,
  not?: ModelAvailabilityFilterInput | null,
};

export type ModelAvailabilityTypeFilterInput = {
  eq?: AvailabilityType | null,
  ne?: AvailabilityType | null,
};

export type ModelUserFilterInput = {
  id?: ModelIDFilterInput | null,
  name?: ModelStringFilterInput | null,
  email?: ModelStringFilterInput | null,
  phone?: ModelStringFilterInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
};

export type ModelHolidayFilterInput = {
  id?: ModelIDFilterInput | null,
  start?: ModelStringFilterInput | null,
  end?: ModelStringFilterInput | null,
  visible?: ModelBooleanFilterInput | null,
  notes?: ModelStringFilterInput | null,
  s12DoctorHolidaysId?: ModelIDFilterInput | null,
  and?: Array< ModelHolidayFilterInput | null > | null,
  or?: Array< ModelHolidayFilterInput | null > | null,
  not?: ModelHolidayFilterInput | null,
};

export type ModelMHTFilterInput = {
  id?: ModelIDFilterInput | null,
  abbreviation?: ModelStringFilterInput | null,
  mHTOrganisationId?: ModelStringFilterInput | null,
  and?: Array< ModelMHTFilterInput | null > | null,
  or?: Array< ModelMHTFilterInput | null > | null,
  not?: ModelMHTFilterInput | null,
};

export type ModelMHTS12DoctorFilterInput = {
  id?: ModelIDFilterInput | null,
  mHTS12DoctorS12DoctorId?: ModelIDFilterInput | null,
  and?: Array< ModelMHTS12DoctorFilterInput | null > | null,
  or?: Array< ModelMHTS12DoctorFilterInput | null > | null,
  not?: ModelMHTS12DoctorFilterInput | null,
};

export type ModelAmhpTeamUserFilterInput = {
  id?: ModelIDFilterInput | null,
  and?: Array< ModelAmhpTeamUserFilterInput | null > | null,
  or?: Array< ModelAmhpTeamUserFilterInput | null > | null,
  not?: ModelAmhpTeamUserFilterInput | null,
};

export type ModelAmhpTeamFilterInput = {
  id?: ModelIDFilterInput | null,
  name?: ModelStringFilterInput | null,
  featureFlags?: ModelStringFilterInput | null,
  features?: ModelStringFilterInput | null,
  localAuthority?: ModelStringFilterInput | null,
  emails?: ModelStringFilterInput | null,
  phoneNumbers?: ModelStringFilterInput | null,
  mhaInboxInstructions?: ModelStringFilterInput | null,
  updatedBy?: ModelStringFilterInput | null,
  and?: Array< ModelAmhpTeamFilterInput | null > | null,
  or?: Array< ModelAmhpTeamFilterInput | null > | null,
  not?: ModelAmhpTeamFilterInput | null,
};

export type ModelOrganisationFilterInput = {
  id?: ModelIDFilterInput | null,
  name?: ModelStringFilterInput | null,
  featureFlags?: ModelStringFilterInput | null,
  features?: ModelStringFilterInput | null,
  type?: ModelOrgTypeFilterInput | null,
  phone?: ModelStringFilterInput | null,
  email?: ModelStringFilterInput | null,
  expensesInstruction?: ModelStringFilterInput | null,
  declarationText?: ModelStringFilterInput | null,
  additionalNotesText?: ModelStringFilterInput | null,
  invoiceEmail?: ModelStringFilterInput | null,
  updatedBy?: ModelStringFilterInput | null,
  and?: Array< ModelOrganisationFilterInput | null > | null,
  or?: Array< ModelOrganisationFilterInput | null > | null,
  not?: ModelOrganisationFilterInput | null,
};

export type ModelOrgTypeFilterInput = {
  eq?: OrgType | null,
  ne?: OrgType | null,
};

export type ModelOrganisationUserFilterInput = {
  id?: ModelIDFilterInput | null,
  organisationUserOrganisationId?: ModelIDFilterInput | null,
  type?: ModelStringFilterInput | null,
  organisationUserUserId?: ModelIDFilterInput | null,
  user?: ModelUserFilterInput | null,
  organisation?: ModelOrganisationFilterInput | null,
  and?: Array< ModelOrganisationUserFilterInput | null > | null,
  or?: Array< ModelOrganisationUserFilterInput | null > | null,
  not?: ModelOrganisationUserFilterInput | null,
};

export type ModelAmhpProfileFilterInput = {
  id?: ModelIDFilterInput | null,
  email?: ModelStringFilterInput | null,
  phone?: ModelStringFilterInput | null,
  name?: ModelStringFilterInput | null,
  warrantInfo?: ModelStringFilterInput | null,
  accreditation?: ModelStringFilterInput | null,
  sectionFormEmailAddress?: ModelStringFilterInput | null,
  sectionFormContactNumber?: ModelStringFilterInput | null,
  pushTokens?: ModelStringFilterInput | null,
  lastActivity?: ModelStringFilterInput | null,
  createdAt?: ModelStringFilterInput | null,
  deleted?: ModelBooleanFilterInput | null,
  and?: Array< ModelAmhpProfileFilterInput | null > | null,
  or?: Array< ModelAmhpProfileFilterInput | null > | null,
  not?: ModelAmhpProfileFilterInput | null,
};

export type ModelCCGFilterInput = {
  id?: ModelIDFilterInput | null,
  claimProcessorType?: ModelOrgTypeFilterInput | null,
  cCGOrganisationId?: ModelIDFilterInput | null,
  and?: Array< ModelCCGFilterInput | null > | null,
  or?: Array< ModelCCGFilterInput | null > | null,
  not?: ModelCCGFilterInput | null,
};

export type ModelContactedDoctorFilterInput = {
  id?: ModelIDFilterInput | null,
  assessmentId?: ModelIDFilterInput | null,
  contactedDoctorS12DoctorId?: ModelIDFilterInput | null,
  createdAt?: ModelStringFilterInput | null,
  and?: Array< ModelContactedDoctorFilterInput | null > | null,
  or?: Array< ModelContactedDoctorFilterInput | null > | null,
  not?: ModelContactedDoctorFilterInput | null,
};

export type ModelReportingFilterInput = {
  id?: ModelIDFilterInput | null,
  pk?: ModelIDFilterInput | null,
  sk?: ModelStringFilterInput | null,
  ttl?: ModelIntFilterInput | null,
  gsi1pk?: ModelIDFilterInput | null,
  gsi1sk?: ModelStringFilterInput | null,
  gsi2pk?: ModelIDFilterInput | null,
  gsi2sk?: ModelStringFilterInput | null,
  gsi3pk?: ModelIDFilterInput | null,
  gsi3sk?: ModelStringFilterInput | null,
  eventName?: ModelStringFilterInput | null,
  userId?: ModelIDFilterInput | null,
  timestamp?: ModelStringFilterInput | null,
  period?: ModelStringFilterInput | null,
  count?: ModelIntFilterInput | null,
  siteId?: ModelIDFilterInput | null,
  teamId?: ModelIDFilterInput | null,
  orgId?: ModelIDFilterInput | null,
  amhpId?: ModelIDFilterInput | null,
  doctorId?: ModelIDFilterInput | null,
  claimProcessorId?: ModelIDFilterInput | null,
  and?: Array< ModelReportingFilterInput | null > | null,
  or?: Array< ModelReportingFilterInput | null > | null,
  not?: ModelReportingFilterInput | null,
};

export enum AdminGetUsersUserType {
  AMHP = "AMHP",
  Doctor = "Doctor",
  OrgClaims = "OrgClaims",
  All = "All",
}


export enum AdminGetUserFilterField {
  username = "username",
  email = "email",
  name = "name",
  phone = "phone",
  gmc = "gmc",
}


export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelIDKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelLinkedSitesFilterInput = {
  id?: ModelIDFilterInput | null,
  parentSite?: ModelStringFilterInput | null,
  childSite?: ModelStringFilterInput | null,
  and?: Array< ModelLinkedSitesFilterInput | null > | null,
  or?: Array< ModelLinkedSitesFilterInput | null > | null,
  not?: ModelLinkedSitesFilterInput | null,
};

export type ModelClaimDoctorClaimsCompositeKeyConditionInput = {
  eq?: ModelClaimDoctorClaimsCompositeKeyInput | null,
  le?: ModelClaimDoctorClaimsCompositeKeyInput | null,
  lt?: ModelClaimDoctorClaimsCompositeKeyInput | null,
  ge?: ModelClaimDoctorClaimsCompositeKeyInput | null,
  gt?: ModelClaimDoctorClaimsCompositeKeyInput | null,
  between?: Array< ModelClaimDoctorClaimsCompositeKeyInput | null > | null,
  beginsWith?: ModelClaimDoctorClaimsCompositeKeyInput | null,
};

export type ModelClaimDoctorClaimsCompositeKeyInput = {
  status?: ClaimStatus | null,
  updatedAt?: string | null,
};

export type ModelClaimOrgClaims2CompositeKeyConditionInput = {
  eq?: ModelClaimOrgClaims2CompositeKeyInput | null,
  le?: ModelClaimOrgClaims2CompositeKeyInput | null,
  lt?: ModelClaimOrgClaims2CompositeKeyInput | null,
  ge?: ModelClaimOrgClaims2CompositeKeyInput | null,
  gt?: ModelClaimOrgClaims2CompositeKeyInput | null,
  between?: Array< ModelClaimOrgClaims2CompositeKeyInput | null > | null,
  beginsWith?: ModelClaimOrgClaims2CompositeKeyInput | null,
};

export type ModelClaimOrgClaims2CompositeKeyInput = {
  status?: ClaimStatus | null,
  receivedDate?: string | null,
};

export type ModelClaimCsuClaimsCompositeKeyConditionInput = {
  eq?: ModelClaimCsuClaimsCompositeKeyInput | null,
  le?: ModelClaimCsuClaimsCompositeKeyInput | null,
  lt?: ModelClaimCsuClaimsCompositeKeyInput | null,
  ge?: ModelClaimCsuClaimsCompositeKeyInput | null,
  gt?: ModelClaimCsuClaimsCompositeKeyInput | null,
  between?: Array< ModelClaimCsuClaimsCompositeKeyInput | null > | null,
  beginsWith?: ModelClaimCsuClaimsCompositeKeyInput | null,
};

export type ModelClaimCsuClaimsCompositeKeyInput = {
  status?: ClaimStatus | null,
  receivedDate?: string | null,
};

export type ModelClaimCsuAssigneeClaimsCompositeKeyConditionInput = {
  eq?: ModelClaimCsuAssigneeClaimsCompositeKeyInput | null,
  le?: ModelClaimCsuAssigneeClaimsCompositeKeyInput | null,
  lt?: ModelClaimCsuAssigneeClaimsCompositeKeyInput | null,
  ge?: ModelClaimCsuAssigneeClaimsCompositeKeyInput | null,
  gt?: ModelClaimCsuAssigneeClaimsCompositeKeyInput | null,
  between?: Array< ModelClaimCsuAssigneeClaimsCompositeKeyInput | null > | null,
  beginsWith?: ModelClaimCsuAssigneeClaimsCompositeKeyInput | null,
};

export type ModelClaimCsuAssigneeClaimsCompositeKeyInput = {
  status?: ClaimStatus | null,
  assigneeId?: string | null,
  receivedDate?: string | null,
};

export type ModelClaimAssigneeClaims2CompositeKeyConditionInput = {
  eq?: ModelClaimAssigneeClaims2CompositeKeyInput | null,
  le?: ModelClaimAssigneeClaims2CompositeKeyInput | null,
  lt?: ModelClaimAssigneeClaims2CompositeKeyInput | null,
  ge?: ModelClaimAssigneeClaims2CompositeKeyInput | null,
  gt?: ModelClaimAssigneeClaims2CompositeKeyInput | null,
  between?: Array< ModelClaimAssigneeClaims2CompositeKeyInput | null > | null,
  beginsWith?: ModelClaimAssigneeClaims2CompositeKeyInput | null,
};

export type ModelClaimAssigneeClaims2CompositeKeyInput = {
  status?: ClaimStatus | null,
  assigneeId?: string | null,
  receivedDate?: string | null,
};

export type ModelClaimTeamClaimsCompositeKeyConditionInput = {
  eq?: ModelClaimTeamClaimsCompositeKeyInput | null,
  le?: ModelClaimTeamClaimsCompositeKeyInput | null,
  lt?: ModelClaimTeamClaimsCompositeKeyInput | null,
  ge?: ModelClaimTeamClaimsCompositeKeyInput | null,
  gt?: ModelClaimTeamClaimsCompositeKeyInput | null,
  between?: Array< ModelClaimTeamClaimsCompositeKeyInput | null > | null,
  beginsWith?: ModelClaimTeamClaimsCompositeKeyInput | null,
};

export type ModelClaimTeamClaimsCompositeKeyInput = {
  status?: ClaimStatus | null,
  receivedDate?: string | null,
  assigneeId?: string | null,
};

export type ModelClaimCsuTeamClaimsCompositeKeyConditionInput = {
  eq?: ModelClaimCsuTeamClaimsCompositeKeyInput | null,
  le?: ModelClaimCsuTeamClaimsCompositeKeyInput | null,
  lt?: ModelClaimCsuTeamClaimsCompositeKeyInput | null,
  ge?: ModelClaimCsuTeamClaimsCompositeKeyInput | null,
  gt?: ModelClaimCsuTeamClaimsCompositeKeyInput | null,
  between?: Array< ModelClaimCsuTeamClaimsCompositeKeyInput | null > | null,
  beginsWith?: ModelClaimCsuTeamClaimsCompositeKeyInput | null,
};

export type ModelClaimCsuTeamClaimsCompositeKeyInput = {
  status?: ClaimStatus | null,
  receivedDate?: string | null,
  assigneeId?: string | null,
};

export type SearchableS12DoctorFilterInput = {
  id?: SearchableIDFilterInput | null,
  gmc?: SearchableStringFilterInput | null,
  s12Expiry?: SearchableStringFilterInput | null,
  name?: SearchableStringFilterInput | null,
  legalName?: SearchableStringFilterInput | null,
  phone?: SearchableStringFilterInput | null,
  phone2?: SearchableStringFilterInput | null,
  email?: SearchableStringFilterInput | null,
  gl?: SearchableBooleanFilterInput | null,
  employer?: SearchableStringFilterInput | null,
  jobTitle?: SearchableStringFilterInput | null,
  responsibleOfficer?: SearchableStringFilterInput | null,
  lineManager?: SearchableStringFilterInput | null,
  defaultMHT?: SearchableStringFilterInput | null,
  specialties?: SearchableStringFilterInput | null,
  languages?: SearchableStringFilterInput | null,
  notes?: SearchableStringFilterInput | null,
  pushTokens?: SearchableStringFilterInput | null,
  distance?: SearchableFloatFilterInput | null,
  hideFromSearch?: SearchableBooleanFilterInput | null,
  availabilityPostcodes?: SearchableStringFilterInput | null,
  siteName?: SearchableStringFilterInput | null,
  sectionFormEmailAddress?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  lastActivity?: SearchableStringFilterInput | null,
  isOutsideEngland?: SearchableBooleanFilterInput | null,
  and?: Array< SearchableS12DoctorFilterInput | null > | null,
  or?: Array< SearchableS12DoctorFilterInput | null > | null,
  not?: SearchableS12DoctorFilterInput | null,
};

export type SearchableIDFilterInput = {
  ne?: string | null,
  gt?: string | null,
  lt?: string | null,
  gte?: string | null,
  lte?: string | null,
  eq?: string | null,
  match?: string | null,
  matchPhrase?: string | null,
  matchPhrasePrefix?: string | null,
  multiMatch?: string | null,
  exists?: boolean | null,
  wildcard?: string | null,
  regexp?: string | null,
};

export type SearchableStringFilterInput = {
  ne?: string | null,
  gt?: string | null,
  lt?: string | null,
  gte?: string | null,
  lte?: string | null,
  eq?: string | null,
  match?: string | null,
  matchPhrase?: string | null,
  matchPhrasePrefix?: string | null,
  multiMatch?: string | null,
  exists?: boolean | null,
  wildcard?: string | null,
  regexp?: string | null,
};

export type SearchableBooleanFilterInput = {
  eq?: boolean | null,
  ne?: boolean | null,
};

export type SearchableFloatFilterInput = {
  ne?: number | null,
  gt?: number | null,
  lt?: number | null,
  gte?: number | null,
  lte?: number | null,
  eq?: number | null,
  range?: Array< number | null > | null,
};

export type SearchableS12DoctorSortInput = {
  field?: SearchableS12DoctorSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableS12DoctorSortableFields {
  id = "id",
  gmc = "gmc",
  s12Expiry = "s12Expiry",
  name = "name",
  legalName = "legalName",
  phone = "phone",
  phone2 = "phone2",
  email = "email",
  gl = "gl",
  employer = "employer",
  jobTitle = "jobTitle",
  responsibleOfficer = "responsibleOfficer",
  lineManager = "lineManager",
  defaultMHT = "defaultMHT",
  specialties = "specialties",
  languages = "languages",
  notes = "notes",
  pushTokens = "pushTokens",
  distance = "distance",
  hideFromSearch = "hideFromSearch",
  availabilityPostcodes = "availabilityPostcodes",
  siteName = "siteName",
  sectionFormEmailAddress = "sectionFormEmailAddress",
  createdAt = "createdAt",
  lastActivity = "lastActivity",
  isOutsideEngland = "isOutsideEngland",
}


export enum SearchableSortDirection {
  asc = "asc",
  desc = "desc",
}


export type CreateDoctorVisitMutationVariables = {
  doctorId: string,
  assessmentId: string,
  time: string,
  notes?: Array< string | null > | null,
};

export type CreateDoctorVisitMutation = {
  createDoctorVisit:  {
    __typename: "DoctorVisit",
    id: string,
    createdAt: string | null,
    assessmentId: string,
    assessment:  {
      __typename: "Assessment",
      id: string,
      createdAt: string,
      amhpTeamId: string,
      amhpTeam:  {
        __typename: "AmhpTeam",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        localAuthority: string | null,
        emails: Array< string > | null,
        phoneNumbers: Array< string > | null,
        mhaInboxInstructions: string | null,
        updatedBy: string | null,
      },
      amhpId: string,
      amhp:  {
        __typename: "AmhpProfile",
        id: string,
        email: string,
        phone: string | null,
        name: string,
        legalName: string | null,
        deleted: boolean | null,
        warrantInfo: string | null,
        accreditation: string | null,
        sectionFormEmailAddress: string | null,
        sectionFormContactNumber: string | null,
        pushTokens: Array< string | null > | null,
        lastActivity: string | null,
        createdAt: string | null,
      },
      patientName: string,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      },
      ccgId: string | null,
      ccg:  {
        __typename: "Organisation",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        type: OrgType,
        phone: string | null,
        email: string | null,
        expensesInstruction: string | null,
        declarationText: string | null,
        additionalNotesText: string | null,
        invoiceEmail: string | null,
        updatedBy: string | null,
        deleted: boolean | null,
      } | null,
      ccgDetermination: LocationType | null,
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      },
      hadVisitAdded: boolean | null,
      doctorVisits:  {
        __typename: "ModelDoctorVisitConnection",
        nextToken: string | null,
      } | null,
      patientNhsNumber: string | null,
      assessmentDate: string | null,
      contactedDoctors:  {
        __typename: "ModelContactedDoctorConnection",
        nextToken: string | null,
      } | null,
      forms:  {
        __typename: "ModelFormConnection",
        nextToken: string | null,
      } | null,
      patientAddress:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
    },
    time: string,
    teamId: string,
    team:  {
      __typename: "AmhpTeam",
      id: string,
      name: string,
      featureFlags: string | null,
      features: string | null,
      assessments:  {
        __typename: "ModelAssessmentConnection",
        nextToken: string | null,
      } | null,
      users:  {
        __typename: "ModelAmhpTeamUserConnection",
        nextToken: string | null,
      } | null,
      commonAddresses:  {
        __typename: "ModelAddressConnection",
        nextToken: string | null,
      } | null,
      localAuthority: string | null,
      hospitalEmails:  Array< {
        __typename: "HospitalEmails",
        name: string,
      } > | null,
      mhaEmailAddresses:  Array< {
        __typename: "RecipientEmails",
        name: string | null,
      } | null > | null,
      emails: Array< string > | null,
      phoneNumbers: Array< string > | null,
      mhaInboxInstructions: string | null,
      reports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      summaryReports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      updatedBy: string | null,
    },
    doctorVisitDoctorId: string,
    doctor:  {
      __typename: "S12Doctor",
      id: string,
      gmc: string,
      s12Expiry: string | null,
      name: string,
      legalName: string,
      phone: string | null,
      phone2: string | null,
      email: string,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      },
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      },
      gl: boolean | null,
      employer: string | null,
      jobTitle: string | null,
      responsibleOfficer: string | null,
      lineManager: string | null,
      defaultMHT: string | null,
      mhtEmployers:  {
        __typename: "ModelMHTS12DoctorConnection",
        nextToken: string | null,
      } | null,
      availabilities:  {
        __typename: "ModelAvailabilityConnection",
        nextToken: string | null,
      } | null,
      holidays:  {
        __typename: "ModelHolidayConnection",
        nextToken: string | null,
      } | null,
      specialties: Array< string | null >,
      languages: Array< string | null >,
      gender: Gender | null,
      notes: string | null,
      pushTokens: Array< string | null > | null,
      distance: number | null,
      hideFromSearch: boolean | null,
      visits:  {
        __typename: "ModelDoctorVisitConnection",
        nextToken: string | null,
      } | null,
      doctors:  {
        __typename: "ModelContactedDoctorConnection",
        nextToken: string | null,
      } | null,
      claims:  {
        __typename: "ModelClaimConnection",
        nextToken: string | null,
      } | null,
      availabilityLocations:  Array< {
        __typename: "Location",
        lat: number,
        lon: number,
      } | null > | null,
      availabilityPostcodes: Array< string | null > | null,
      siteName: string,
      claimData:  {
        __typename: "ClaimData",
        billingCompanyName: string | null,
        vatRegistration: string | null,
        fuelType: FuelType | null,
        engineSize: Engine | null,
      } | null,
      sectionFormAddress:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      sectionFormEmailAddress: string | null,
      reports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      summaryReports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      createdAt: string | null,
      lastActivity: string | null,
      isOutsideEngland: boolean | null,
    },
    notes: Array< string | null > | null,
    partialPostcode: string | null,
    doctorVisitClaimId: string | null,
    claimDisplayId: string | null,
    claimStatus: ClaimStatus | null,
    claim:  {
      __typename: "Claim",
      id: string,
      claimAssessmentId: string | null,
      claimVisitId: string,
      visit:  {
        __typename: "DoctorVisit",
        id: string,
        createdAt: string | null,
        assessmentId: string,
        time: string,
        teamId: string,
        doctorVisitDoctorId: string,
        notes: Array< string | null > | null,
        partialPostcode: string | null,
        doctorVisitClaimId: string | null,
        claimDisplayId: string | null,
        claimStatus: ClaimStatus | null,
      },
      claimDoctorId: string,
      doctor:  {
        __typename: "S12Doctor",
        id: string,
        gmc: string,
        s12Expiry: string | null,
        name: string,
        legalName: string,
        phone: string | null,
        phone2: string | null,
        email: string,
        gl: boolean | null,
        employer: string | null,
        jobTitle: string | null,
        responsibleOfficer: string | null,
        lineManager: string | null,
        defaultMHT: string | null,
        specialties: Array< string | null >,
        languages: Array< string | null >,
        gender: Gender | null,
        notes: string | null,
        pushTokens: Array< string | null > | null,
        distance: number | null,
        hideFromSearch: boolean | null,
        availabilityPostcodes: Array< string | null > | null,
        siteName: string,
        sectionFormEmailAddress: string | null,
        createdAt: string | null,
        lastActivity: string | null,
        isOutsideEngland: boolean | null,
      },
      assigneeId: string,
      assigneeName: string | null,
      amhpTeamId: string,
      claimAmhpId: string,
      amhp:  {
        __typename: "AmhpProfile",
        id: string,
        email: string,
        phone: string | null,
        name: string,
        legalName: string | null,
        deleted: boolean | null,
        warrantInfo: string | null,
        accreditation: string | null,
        sectionFormEmailAddress: string | null,
        sectionFormContactNumber: string | null,
        pushTokens: Array< string | null > | null,
        lastActivity: string | null,
        createdAt: string | null,
      },
      claimOrganisationId: string,
      claimCsuId: string,
      organisation:  {
        __typename: "Organisation",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        type: OrgType,
        phone: string | null,
        email: string | null,
        expensesInstruction: string | null,
        declarationText: string | null,
        additionalNotesText: string | null,
        invoiceEmail: string | null,
        updatedBy: string | null,
        deleted: boolean | null,
      },
      status: ClaimStatus,
      displayId: string,
      mileage: string | null,
      locationText: string,
      locationType: LocationType,
      claimPostcode: string | null,
      assessmentPostcode: string | null,
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      patientInitials: string,
      patientFullName: string | null,
      startingPostcode: string | null,
      visitDate: string,
      assessmentTookPlace: boolean | null,
      notes: Array< string | null > | null,
      unread: boolean | null,
      createdAt: string | null,
      updatedAt: string,
      receivedDate: string,
      additionalExpenses: string | null,
      additionalInformation: string | null,
      patientNhsNumber: string | null,
      declaration: string | null,
      paid: boolean | null,
      claimData:  {
        __typename: "ClaimData",
        billingCompanyName: string | null,
        vatRegistration: string | null,
        fuelType: FuelType | null,
        engineSize: Engine | null,
      } | null,
      invoiceData:  {
        __typename: "InvoiceData",
        assessmentFee: string,
        travelFee: string | null,
        locationOfAssessment: string | null,
      } | null,
      lineManager: string | null,
      doctorAdditionalConf: DoctorConfirmation | null,
      isIndependentDoctor: boolean | null,
      defaultMHT: string | null,
      doctorEmployedStatus: DoctorEmployedStatusValues | null,
    } | null,
  } | null,
};

export type CreateClaimMutationVariables = {
  input?: CreateClaimInput | null,
};

export type CreateClaimMutation = {
  createClaim:  {
    __typename: "Claim",
    id: string,
    claimAssessmentId: string | null,
    claimVisitId: string,
    visit:  {
      __typename: "DoctorVisit",
      id: string,
      createdAt: string | null,
      assessmentId: string,
      assessment:  {
        __typename: "Assessment",
        id: string,
        createdAt: string,
        amhpTeamId: string,
        amhpId: string,
        patientName: string,
        ccgId: string | null,
        ccgDetermination: LocationType | null,
        hadVisitAdded: boolean | null,
        patientNhsNumber: string | null,
        assessmentDate: string | null,
      },
      time: string,
      teamId: string,
      team:  {
        __typename: "AmhpTeam",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        localAuthority: string | null,
        emails: Array< string > | null,
        phoneNumbers: Array< string > | null,
        mhaInboxInstructions: string | null,
        updatedBy: string | null,
      },
      doctorVisitDoctorId: string,
      doctor:  {
        __typename: "S12Doctor",
        id: string,
        gmc: string,
        s12Expiry: string | null,
        name: string,
        legalName: string,
        phone: string | null,
        phone2: string | null,
        email: string,
        gl: boolean | null,
        employer: string | null,
        jobTitle: string | null,
        responsibleOfficer: string | null,
        lineManager: string | null,
        defaultMHT: string | null,
        specialties: Array< string | null >,
        languages: Array< string | null >,
        gender: Gender | null,
        notes: string | null,
        pushTokens: Array< string | null > | null,
        distance: number | null,
        hideFromSearch: boolean | null,
        availabilityPostcodes: Array< string | null > | null,
        siteName: string,
        sectionFormEmailAddress: string | null,
        createdAt: string | null,
        lastActivity: string | null,
        isOutsideEngland: boolean | null,
      },
      notes: Array< string | null > | null,
      partialPostcode: string | null,
      doctorVisitClaimId: string | null,
      claimDisplayId: string | null,
      claimStatus: ClaimStatus | null,
      claim:  {
        __typename: "Claim",
        id: string,
        claimAssessmentId: string | null,
        claimVisitId: string,
        claimDoctorId: string,
        assigneeId: string,
        assigneeName: string | null,
        amhpTeamId: string,
        claimAmhpId: string,
        claimOrganisationId: string,
        claimCsuId: string,
        status: ClaimStatus,
        displayId: string,
        mileage: string | null,
        locationText: string,
        locationType: LocationType,
        claimPostcode: string | null,
        assessmentPostcode: string | null,
        patientInitials: string,
        patientFullName: string | null,
        startingPostcode: string | null,
        visitDate: string,
        assessmentTookPlace: boolean | null,
        notes: Array< string | null > | null,
        unread: boolean | null,
        createdAt: string | null,
        updatedAt: string,
        receivedDate: string,
        additionalExpenses: string | null,
        additionalInformation: string | null,
        patientNhsNumber: string | null,
        declaration: string | null,
        paid: boolean | null,
        lineManager: string | null,
        doctorAdditionalConf: DoctorConfirmation | null,
        isIndependentDoctor: boolean | null,
        defaultMHT: string | null,
        doctorEmployedStatus: DoctorEmployedStatusValues | null,
      } | null,
    },
    claimDoctorId: string,
    doctor:  {
      __typename: "S12Doctor",
      id: string,
      gmc: string,
      s12Expiry: string | null,
      name: string,
      legalName: string,
      phone: string | null,
      phone2: string | null,
      email: string,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      },
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      },
      gl: boolean | null,
      employer: string | null,
      jobTitle: string | null,
      responsibleOfficer: string | null,
      lineManager: string | null,
      defaultMHT: string | null,
      mhtEmployers:  {
        __typename: "ModelMHTS12DoctorConnection",
        nextToken: string | null,
      } | null,
      availabilities:  {
        __typename: "ModelAvailabilityConnection",
        nextToken: string | null,
      } | null,
      holidays:  {
        __typename: "ModelHolidayConnection",
        nextToken: string | null,
      } | null,
      specialties: Array< string | null >,
      languages: Array< string | null >,
      gender: Gender | null,
      notes: string | null,
      pushTokens: Array< string | null > | null,
      distance: number | null,
      hideFromSearch: boolean | null,
      visits:  {
        __typename: "ModelDoctorVisitConnection",
        nextToken: string | null,
      } | null,
      doctors:  {
        __typename: "ModelContactedDoctorConnection",
        nextToken: string | null,
      } | null,
      claims:  {
        __typename: "ModelClaimConnection",
        nextToken: string | null,
      } | null,
      availabilityLocations:  Array< {
        __typename: "Location",
        lat: number,
        lon: number,
      } | null > | null,
      availabilityPostcodes: Array< string | null > | null,
      siteName: string,
      claimData:  {
        __typename: "ClaimData",
        billingCompanyName: string | null,
        vatRegistration: string | null,
        fuelType: FuelType | null,
        engineSize: Engine | null,
      } | null,
      sectionFormAddress:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      sectionFormEmailAddress: string | null,
      reports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      summaryReports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      createdAt: string | null,
      lastActivity: string | null,
      isOutsideEngland: boolean | null,
    },
    assigneeId: string,
    assigneeName: string | null,
    amhpTeamId: string,
    claimAmhpId: string,
    amhp:  {
      __typename: "AmhpProfile",
      id: string,
      email: string,
      phone: string | null,
      name: string,
      legalName: string | null,
      deleted: boolean | null,
      warrantInfo: string | null,
      accreditation: string | null,
      sectionFormAddress:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      sectionFormEmailAddress: string | null,
      sectionFormContactNumber: string | null,
      assessments:  {
        __typename: "ModelAssessmentConnection",
        nextToken: string | null,
      } | null,
      pushTokens: Array< string | null > | null,
      teams:  {
        __typename: "ModelAmhpTeamUserConnection",
        nextToken: string | null,
      } | null,
      lastActivity: string | null,
      createdAt: string | null,
      reports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      summaryReports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
    },
    claimOrganisationId: string,
    claimCsuId: string,
    organisation:  {
      __typename: "Organisation",
      id: string,
      name: string,
      featureFlags: string | null,
      features: string | null,
      type: OrgType,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      } | null,
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      phone: string | null,
      email: string | null,
      users:  {
        __typename: "ModelOrganisationUserConnection",
        nextToken: string | null,
      } | null,
      sites:  {
        __typename: "ModelSiteOrganisationConnection",
        nextToken: string | null,
      } | null,
      ccg:  {
        __typename: "CCG",
        id: string,
        claimProcessorType: OrgType | null,
        cCGOrganisationId: string,
      } | null,
      mht:  {
        __typename: "MHT",
        id: string,
        abbreviation: string,
        mHTOrganisationId: string,
      } | null,
      claims:  {
        __typename: "ModelClaimConnection",
        nextToken: string | null,
      } | null,
      expensesInstruction: string | null,
      declarationText: string | null,
      additionalNotesText: string | null,
      paymentRates:  Array< {
        __typename: "PaymentRates",
        description: string | null,
        assessmentFee: number,
        mileage: number,
      } | null > | null,
      invoiceEmail: string | null,
      updatedBy: string | null,
      mhtHospitalEmails:  Array< {
        __typename: "HospitalEmails",
        name: string,
      } | null > | null,
      rotas:  Array< {
        __typename: "RotaItem",
        id: string,
        name: string,
      } | null > | null,
      deleted: boolean | null,
    },
    status: ClaimStatus,
    displayId: string,
    mileage: string | null,
    locationText: string,
    locationType: LocationType,
    claimPostcode: string | null,
    assessmentPostcode: string | null,
    locationName:  {
      __typename: "LocationName",
      city: string | null,
      postcode: string,
      address: string | null,
      addressNotes: string | null,
    } | null,
    patientInitials: string,
    patientFullName: string | null,
    startingPostcode: string | null,
    visitDate: string,
    assessmentTookPlace: boolean | null,
    notes: Array< string | null > | null,
    unread: boolean | null,
    createdAt: string | null,
    updatedAt: string,
    receivedDate: string,
    additionalExpenses: string | null,
    additionalInformation: string | null,
    patientNhsNumber: string | null,
    declaration: string | null,
    paid: boolean | null,
    claimData:  {
      __typename: "ClaimData",
      billingAddress:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      billingCompanyName: string | null,
      vatRegistration: string | null,
      fuelType: FuelType | null,
      engineSize: Engine | null,
    } | null,
    invoiceData:  {
      __typename: "InvoiceData",
      assessmentFee: string,
      travelFee: string | null,
      locationOfAssessment: string | null,
    } | null,
    lineManager: string | null,
    doctorAdditionalConf: DoctorConfirmation | null,
    isIndependentDoctor: boolean | null,
    defaultMHT: string | null,
    doctorEmployedStatus: DoctorEmployedStatusValues | null,
  } | null,
};

export type UpdateClaimMutationVariables = {
  input?: UpdateClaimInput | null,
};

export type UpdateClaimMutation = {
  updateClaim:  {
    __typename: "Claim",
    id: string,
    claimAssessmentId: string | null,
    claimVisitId: string,
    visit:  {
      __typename: "DoctorVisit",
      id: string,
      createdAt: string | null,
      assessmentId: string,
      assessment:  {
        __typename: "Assessment",
        id: string,
        createdAt: string,
        amhpTeamId: string,
        amhpId: string,
        patientName: string,
        ccgId: string | null,
        ccgDetermination: LocationType | null,
        hadVisitAdded: boolean | null,
        patientNhsNumber: string | null,
        assessmentDate: string | null,
      },
      time: string,
      teamId: string,
      team:  {
        __typename: "AmhpTeam",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        localAuthority: string | null,
        emails: Array< string > | null,
        phoneNumbers: Array< string > | null,
        mhaInboxInstructions: string | null,
        updatedBy: string | null,
      },
      doctorVisitDoctorId: string,
      doctor:  {
        __typename: "S12Doctor",
        id: string,
        gmc: string,
        s12Expiry: string | null,
        name: string,
        legalName: string,
        phone: string | null,
        phone2: string | null,
        email: string,
        gl: boolean | null,
        employer: string | null,
        jobTitle: string | null,
        responsibleOfficer: string | null,
        lineManager: string | null,
        defaultMHT: string | null,
        specialties: Array< string | null >,
        languages: Array< string | null >,
        gender: Gender | null,
        notes: string | null,
        pushTokens: Array< string | null > | null,
        distance: number | null,
        hideFromSearch: boolean | null,
        availabilityPostcodes: Array< string | null > | null,
        siteName: string,
        sectionFormEmailAddress: string | null,
        createdAt: string | null,
        lastActivity: string | null,
        isOutsideEngland: boolean | null,
      },
      notes: Array< string | null > | null,
      partialPostcode: string | null,
      doctorVisitClaimId: string | null,
      claimDisplayId: string | null,
      claimStatus: ClaimStatus | null,
      claim:  {
        __typename: "Claim",
        id: string,
        claimAssessmentId: string | null,
        claimVisitId: string,
        claimDoctorId: string,
        assigneeId: string,
        assigneeName: string | null,
        amhpTeamId: string,
        claimAmhpId: string,
        claimOrganisationId: string,
        claimCsuId: string,
        status: ClaimStatus,
        displayId: string,
        mileage: string | null,
        locationText: string,
        locationType: LocationType,
        claimPostcode: string | null,
        assessmentPostcode: string | null,
        patientInitials: string,
        patientFullName: string | null,
        startingPostcode: string | null,
        visitDate: string,
        assessmentTookPlace: boolean | null,
        notes: Array< string | null > | null,
        unread: boolean | null,
        createdAt: string | null,
        updatedAt: string,
        receivedDate: string,
        additionalExpenses: string | null,
        additionalInformation: string | null,
        patientNhsNumber: string | null,
        declaration: string | null,
        paid: boolean | null,
        lineManager: string | null,
        doctorAdditionalConf: DoctorConfirmation | null,
        isIndependentDoctor: boolean | null,
        defaultMHT: string | null,
        doctorEmployedStatus: DoctorEmployedStatusValues | null,
      } | null,
    },
    claimDoctorId: string,
    doctor:  {
      __typename: "S12Doctor",
      id: string,
      gmc: string,
      s12Expiry: string | null,
      name: string,
      legalName: string,
      phone: string | null,
      phone2: string | null,
      email: string,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      },
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      },
      gl: boolean | null,
      employer: string | null,
      jobTitle: string | null,
      responsibleOfficer: string | null,
      lineManager: string | null,
      defaultMHT: string | null,
      mhtEmployers:  {
        __typename: "ModelMHTS12DoctorConnection",
        nextToken: string | null,
      } | null,
      availabilities:  {
        __typename: "ModelAvailabilityConnection",
        nextToken: string | null,
      } | null,
      holidays:  {
        __typename: "ModelHolidayConnection",
        nextToken: string | null,
      } | null,
      specialties: Array< string | null >,
      languages: Array< string | null >,
      gender: Gender | null,
      notes: string | null,
      pushTokens: Array< string | null > | null,
      distance: number | null,
      hideFromSearch: boolean | null,
      visits:  {
        __typename: "ModelDoctorVisitConnection",
        nextToken: string | null,
      } | null,
      doctors:  {
        __typename: "ModelContactedDoctorConnection",
        nextToken: string | null,
      } | null,
      claims:  {
        __typename: "ModelClaimConnection",
        nextToken: string | null,
      } | null,
      availabilityLocations:  Array< {
        __typename: "Location",
        lat: number,
        lon: number,
      } | null > | null,
      availabilityPostcodes: Array< string | null > | null,
      siteName: string,
      claimData:  {
        __typename: "ClaimData",
        billingCompanyName: string | null,
        vatRegistration: string | null,
        fuelType: FuelType | null,
        engineSize: Engine | null,
      } | null,
      sectionFormAddress:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      sectionFormEmailAddress: string | null,
      reports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      summaryReports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      createdAt: string | null,
      lastActivity: string | null,
      isOutsideEngland: boolean | null,
    },
    assigneeId: string,
    assigneeName: string | null,
    amhpTeamId: string,
    claimAmhpId: string,
    amhp:  {
      __typename: "AmhpProfile",
      id: string,
      email: string,
      phone: string | null,
      name: string,
      legalName: string | null,
      deleted: boolean | null,
      warrantInfo: string | null,
      accreditation: string | null,
      sectionFormAddress:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      sectionFormEmailAddress: string | null,
      sectionFormContactNumber: string | null,
      assessments:  {
        __typename: "ModelAssessmentConnection",
        nextToken: string | null,
      } | null,
      pushTokens: Array< string | null > | null,
      teams:  {
        __typename: "ModelAmhpTeamUserConnection",
        nextToken: string | null,
      } | null,
      lastActivity: string | null,
      createdAt: string | null,
      reports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      summaryReports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
    },
    claimOrganisationId: string,
    claimCsuId: string,
    organisation:  {
      __typename: "Organisation",
      id: string,
      name: string,
      featureFlags: string | null,
      features: string | null,
      type: OrgType,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      } | null,
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      phone: string | null,
      email: string | null,
      users:  {
        __typename: "ModelOrganisationUserConnection",
        nextToken: string | null,
      } | null,
      sites:  {
        __typename: "ModelSiteOrganisationConnection",
        nextToken: string | null,
      } | null,
      ccg:  {
        __typename: "CCG",
        id: string,
        claimProcessorType: OrgType | null,
        cCGOrganisationId: string,
      } | null,
      mht:  {
        __typename: "MHT",
        id: string,
        abbreviation: string,
        mHTOrganisationId: string,
      } | null,
      claims:  {
        __typename: "ModelClaimConnection",
        nextToken: string | null,
      } | null,
      expensesInstruction: string | null,
      declarationText: string | null,
      additionalNotesText: string | null,
      paymentRates:  Array< {
        __typename: "PaymentRates",
        description: string | null,
        assessmentFee: number,
        mileage: number,
      } | null > | null,
      invoiceEmail: string | null,
      updatedBy: string | null,
      mhtHospitalEmails:  Array< {
        __typename: "HospitalEmails",
        name: string,
      } | null > | null,
      rotas:  Array< {
        __typename: "RotaItem",
        id: string,
        name: string,
      } | null > | null,
      deleted: boolean | null,
    },
    status: ClaimStatus,
    displayId: string,
    mileage: string | null,
    locationText: string,
    locationType: LocationType,
    claimPostcode: string | null,
    assessmentPostcode: string | null,
    locationName:  {
      __typename: "LocationName",
      city: string | null,
      postcode: string,
      address: string | null,
      addressNotes: string | null,
    } | null,
    patientInitials: string,
    patientFullName: string | null,
    startingPostcode: string | null,
    visitDate: string,
    assessmentTookPlace: boolean | null,
    notes: Array< string | null > | null,
    unread: boolean | null,
    createdAt: string | null,
    updatedAt: string,
    receivedDate: string,
    additionalExpenses: string | null,
    additionalInformation: string | null,
    patientNhsNumber: string | null,
    declaration: string | null,
    paid: boolean | null,
    claimData:  {
      __typename: "ClaimData",
      billingAddress:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      billingCompanyName: string | null,
      vatRegistration: string | null,
      fuelType: FuelType | null,
      engineSize: Engine | null,
    } | null,
    invoiceData:  {
      __typename: "InvoiceData",
      assessmentFee: string,
      travelFee: string | null,
      locationOfAssessment: string | null,
    } | null,
    lineManager: string | null,
    doctorAdditionalConf: DoctorConfirmation | null,
    isIndependentDoctor: boolean | null,
    defaultMHT: string | null,
    doctorEmployedStatus: DoctorEmployedStatusValues | null,
  } | null,
};

export type DeleteClaimMutationVariables = {
  input?: DeleteClaimInput | null,
};

export type DeleteClaimMutation = {
  deleteClaim:  {
    __typename: "Claim",
    id: string,
    claimAssessmentId: string | null,
    claimVisitId: string,
    visit:  {
      __typename: "DoctorVisit",
      id: string,
      createdAt: string | null,
      assessmentId: string,
      assessment:  {
        __typename: "Assessment",
        id: string,
        createdAt: string,
        amhpTeamId: string,
        amhpId: string,
        patientName: string,
        ccgId: string | null,
        ccgDetermination: LocationType | null,
        hadVisitAdded: boolean | null,
        patientNhsNumber: string | null,
        assessmentDate: string | null,
      },
      time: string,
      teamId: string,
      team:  {
        __typename: "AmhpTeam",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        localAuthority: string | null,
        emails: Array< string > | null,
        phoneNumbers: Array< string > | null,
        mhaInboxInstructions: string | null,
        updatedBy: string | null,
      },
      doctorVisitDoctorId: string,
      doctor:  {
        __typename: "S12Doctor",
        id: string,
        gmc: string,
        s12Expiry: string | null,
        name: string,
        legalName: string,
        phone: string | null,
        phone2: string | null,
        email: string,
        gl: boolean | null,
        employer: string | null,
        jobTitle: string | null,
        responsibleOfficer: string | null,
        lineManager: string | null,
        defaultMHT: string | null,
        specialties: Array< string | null >,
        languages: Array< string | null >,
        gender: Gender | null,
        notes: string | null,
        pushTokens: Array< string | null > | null,
        distance: number | null,
        hideFromSearch: boolean | null,
        availabilityPostcodes: Array< string | null > | null,
        siteName: string,
        sectionFormEmailAddress: string | null,
        createdAt: string | null,
        lastActivity: string | null,
        isOutsideEngland: boolean | null,
      },
      notes: Array< string | null > | null,
      partialPostcode: string | null,
      doctorVisitClaimId: string | null,
      claimDisplayId: string | null,
      claimStatus: ClaimStatus | null,
      claim:  {
        __typename: "Claim",
        id: string,
        claimAssessmentId: string | null,
        claimVisitId: string,
        claimDoctorId: string,
        assigneeId: string,
        assigneeName: string | null,
        amhpTeamId: string,
        claimAmhpId: string,
        claimOrganisationId: string,
        claimCsuId: string,
        status: ClaimStatus,
        displayId: string,
        mileage: string | null,
        locationText: string,
        locationType: LocationType,
        claimPostcode: string | null,
        assessmentPostcode: string | null,
        patientInitials: string,
        patientFullName: string | null,
        startingPostcode: string | null,
        visitDate: string,
        assessmentTookPlace: boolean | null,
        notes: Array< string | null > | null,
        unread: boolean | null,
        createdAt: string | null,
        updatedAt: string,
        receivedDate: string,
        additionalExpenses: string | null,
        additionalInformation: string | null,
        patientNhsNumber: string | null,
        declaration: string | null,
        paid: boolean | null,
        lineManager: string | null,
        doctorAdditionalConf: DoctorConfirmation | null,
        isIndependentDoctor: boolean | null,
        defaultMHT: string | null,
        doctorEmployedStatus: DoctorEmployedStatusValues | null,
      } | null,
    },
    claimDoctorId: string,
    doctor:  {
      __typename: "S12Doctor",
      id: string,
      gmc: string,
      s12Expiry: string | null,
      name: string,
      legalName: string,
      phone: string | null,
      phone2: string | null,
      email: string,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      },
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      },
      gl: boolean | null,
      employer: string | null,
      jobTitle: string | null,
      responsibleOfficer: string | null,
      lineManager: string | null,
      defaultMHT: string | null,
      mhtEmployers:  {
        __typename: "ModelMHTS12DoctorConnection",
        nextToken: string | null,
      } | null,
      availabilities:  {
        __typename: "ModelAvailabilityConnection",
        nextToken: string | null,
      } | null,
      holidays:  {
        __typename: "ModelHolidayConnection",
        nextToken: string | null,
      } | null,
      specialties: Array< string | null >,
      languages: Array< string | null >,
      gender: Gender | null,
      notes: string | null,
      pushTokens: Array< string | null > | null,
      distance: number | null,
      hideFromSearch: boolean | null,
      visits:  {
        __typename: "ModelDoctorVisitConnection",
        nextToken: string | null,
      } | null,
      doctors:  {
        __typename: "ModelContactedDoctorConnection",
        nextToken: string | null,
      } | null,
      claims:  {
        __typename: "ModelClaimConnection",
        nextToken: string | null,
      } | null,
      availabilityLocations:  Array< {
        __typename: "Location",
        lat: number,
        lon: number,
      } | null > | null,
      availabilityPostcodes: Array< string | null > | null,
      siteName: string,
      claimData:  {
        __typename: "ClaimData",
        billingCompanyName: string | null,
        vatRegistration: string | null,
        fuelType: FuelType | null,
        engineSize: Engine | null,
      } | null,
      sectionFormAddress:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      sectionFormEmailAddress: string | null,
      reports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      summaryReports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      createdAt: string | null,
      lastActivity: string | null,
      isOutsideEngland: boolean | null,
    },
    assigneeId: string,
    assigneeName: string | null,
    amhpTeamId: string,
    claimAmhpId: string,
    amhp:  {
      __typename: "AmhpProfile",
      id: string,
      email: string,
      phone: string | null,
      name: string,
      legalName: string | null,
      deleted: boolean | null,
      warrantInfo: string | null,
      accreditation: string | null,
      sectionFormAddress:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      sectionFormEmailAddress: string | null,
      sectionFormContactNumber: string | null,
      assessments:  {
        __typename: "ModelAssessmentConnection",
        nextToken: string | null,
      } | null,
      pushTokens: Array< string | null > | null,
      teams:  {
        __typename: "ModelAmhpTeamUserConnection",
        nextToken: string | null,
      } | null,
      lastActivity: string | null,
      createdAt: string | null,
      reports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      summaryReports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
    },
    claimOrganisationId: string,
    claimCsuId: string,
    organisation:  {
      __typename: "Organisation",
      id: string,
      name: string,
      featureFlags: string | null,
      features: string | null,
      type: OrgType,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      } | null,
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      phone: string | null,
      email: string | null,
      users:  {
        __typename: "ModelOrganisationUserConnection",
        nextToken: string | null,
      } | null,
      sites:  {
        __typename: "ModelSiteOrganisationConnection",
        nextToken: string | null,
      } | null,
      ccg:  {
        __typename: "CCG",
        id: string,
        claimProcessorType: OrgType | null,
        cCGOrganisationId: string,
      } | null,
      mht:  {
        __typename: "MHT",
        id: string,
        abbreviation: string,
        mHTOrganisationId: string,
      } | null,
      claims:  {
        __typename: "ModelClaimConnection",
        nextToken: string | null,
      } | null,
      expensesInstruction: string | null,
      declarationText: string | null,
      additionalNotesText: string | null,
      paymentRates:  Array< {
        __typename: "PaymentRates",
        description: string | null,
        assessmentFee: number,
        mileage: number,
      } | null > | null,
      invoiceEmail: string | null,
      updatedBy: string | null,
      mhtHospitalEmails:  Array< {
        __typename: "HospitalEmails",
        name: string,
      } | null > | null,
      rotas:  Array< {
        __typename: "RotaItem",
        id: string,
        name: string,
      } | null > | null,
      deleted: boolean | null,
    },
    status: ClaimStatus,
    displayId: string,
    mileage: string | null,
    locationText: string,
    locationType: LocationType,
    claimPostcode: string | null,
    assessmentPostcode: string | null,
    locationName:  {
      __typename: "LocationName",
      city: string | null,
      postcode: string,
      address: string | null,
      addressNotes: string | null,
    } | null,
    patientInitials: string,
    patientFullName: string | null,
    startingPostcode: string | null,
    visitDate: string,
    assessmentTookPlace: boolean | null,
    notes: Array< string | null > | null,
    unread: boolean | null,
    createdAt: string | null,
    updatedAt: string,
    receivedDate: string,
    additionalExpenses: string | null,
    additionalInformation: string | null,
    patientNhsNumber: string | null,
    declaration: string | null,
    paid: boolean | null,
    claimData:  {
      __typename: "ClaimData",
      billingAddress:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      billingCompanyName: string | null,
      vatRegistration: string | null,
      fuelType: FuelType | null,
      engineSize: Engine | null,
    } | null,
    invoiceData:  {
      __typename: "InvoiceData",
      assessmentFee: string,
      travelFee: string | null,
      locationOfAssessment: string | null,
    } | null,
    lineManager: string | null,
    doctorAdditionalConf: DoctorConfirmation | null,
    isIndependentDoctor: boolean | null,
    defaultMHT: string | null,
    doctorEmployedStatus: DoctorEmployedStatusValues | null,
  } | null,
};

export type AdminCreateAmhpMutationVariables = {
  input: AdminCreateAmhpInput,
};

export type AdminCreateAmhpMutation = {
  adminCreateAmhp:  {
    __typename: "AmhpProfile",
    id: string,
    email: string,
    phone: string | null,
    name: string,
    legalName: string | null,
    deleted: boolean | null,
    warrantInfo: string | null,
    accreditation: string | null,
    sectionFormAddress:  {
      __typename: "LocationName",
      city: string | null,
      postcode: string,
      address: string | null,
      addressNotes: string | null,
    } | null,
    sectionFormEmailAddress: string | null,
    sectionFormContactNumber: string | null,
    assessments:  {
      __typename: "ModelAssessmentConnection",
      items:  Array< {
        __typename: "Assessment",
        id: string,
        createdAt: string,
        amhpTeamId: string,
        amhpId: string,
        patientName: string,
        ccgId: string | null,
        ccgDetermination: LocationType | null,
        hadVisitAdded: boolean | null,
        patientNhsNumber: string | null,
        assessmentDate: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    pushTokens: Array< string | null > | null,
    teams:  {
      __typename: "ModelAmhpTeamUserConnection",
      items:  Array< {
        __typename: "AmhpTeamUser",
        id: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
    lastActivity: string | null,
    createdAt: string | null,
    reports:  {
      __typename: "ModelReportingConnection",
      items:  Array< {
        __typename: "Reporting",
        id: string,
        pk: string,
        sk: string,
        ttl: number | null,
        gsi1pk: string | null,
        gsi1sk: string | null,
        gsi2pk: string | null,
        gsi2sk: string | null,
        gsi3pk: string | null,
        gsi3sk: string | null,
        eventName: string,
        userId: string,
        timestamp: string,
        period: string,
        count: number | null,
        siteId: string | null,
        teamId: string | null,
        orgId: string | null,
        amhpId: string | null,
        doctorId: string | null,
        claimProcessorId: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    summaryReports:  {
      __typename: "ModelReportingConnection",
      items:  Array< {
        __typename: "Reporting",
        id: string,
        pk: string,
        sk: string,
        ttl: number | null,
        gsi1pk: string | null,
        gsi1sk: string | null,
        gsi2pk: string | null,
        gsi2sk: string | null,
        gsi3pk: string | null,
        gsi3sk: string | null,
        eventName: string,
        userId: string,
        timestamp: string,
        period: string,
        count: number | null,
        siteId: string | null,
        teamId: string | null,
        orgId: string | null,
        amhpId: string | null,
        doctorId: string | null,
        claimProcessorId: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  },
};

export type AdminUpdateAmhpMutationVariables = {
  input: AdminUpdateAmhpInput,
};

export type AdminUpdateAmhpMutation = {
  adminUpdateAmhp:  {
    __typename: "AmhpProfile",
    id: string,
    email: string,
    phone: string | null,
    name: string,
    legalName: string | null,
    deleted: boolean | null,
    warrantInfo: string | null,
    accreditation: string | null,
    sectionFormAddress:  {
      __typename: "LocationName",
      city: string | null,
      postcode: string,
      address: string | null,
      addressNotes: string | null,
    } | null,
    sectionFormEmailAddress: string | null,
    sectionFormContactNumber: string | null,
    assessments:  {
      __typename: "ModelAssessmentConnection",
      items:  Array< {
        __typename: "Assessment",
        id: string,
        createdAt: string,
        amhpTeamId: string,
        amhpId: string,
        patientName: string,
        ccgId: string | null,
        ccgDetermination: LocationType | null,
        hadVisitAdded: boolean | null,
        patientNhsNumber: string | null,
        assessmentDate: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    pushTokens: Array< string | null > | null,
    teams:  {
      __typename: "ModelAmhpTeamUserConnection",
      items:  Array< {
        __typename: "AmhpTeamUser",
        id: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
    lastActivity: string | null,
    createdAt: string | null,
    reports:  {
      __typename: "ModelReportingConnection",
      items:  Array< {
        __typename: "Reporting",
        id: string,
        pk: string,
        sk: string,
        ttl: number | null,
        gsi1pk: string | null,
        gsi1sk: string | null,
        gsi2pk: string | null,
        gsi2sk: string | null,
        gsi3pk: string | null,
        gsi3sk: string | null,
        eventName: string,
        userId: string,
        timestamp: string,
        period: string,
        count: number | null,
        siteId: string | null,
        teamId: string | null,
        orgId: string | null,
        amhpId: string | null,
        doctorId: string | null,
        claimProcessorId: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    summaryReports:  {
      __typename: "ModelReportingConnection",
      items:  Array< {
        __typename: "Reporting",
        id: string,
        pk: string,
        sk: string,
        ttl: number | null,
        gsi1pk: string | null,
        gsi1sk: string | null,
        gsi2pk: string | null,
        gsi2sk: string | null,
        gsi3pk: string | null,
        gsi3sk: string | null,
        eventName: string,
        userId: string,
        timestamp: string,
        period: string,
        count: number | null,
        siteId: string | null,
        teamId: string | null,
        orgId: string | null,
        amhpId: string | null,
        doctorId: string | null,
        claimProcessorId: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  },
};

export type AdminCreateDoctorMutationVariables = {
  input: AdminCreateDoctorInput,
};

export type AdminCreateDoctorMutation = {
  adminCreateDoctor:  {
    __typename: "IDResult",
    id: string,
  },
};

export type AdminUpdateDoctorMutationVariables = {
  input: AdminUpdateDoctorInput,
};

export type AdminUpdateDoctorMutation = {
  adminUpdateDoctor:  {
    __typename: "IDResult",
    id: string,
  },
};

export type AdminDeleteUserMutationVariables = {
  input: DeleteUserInput,
};

export type AdminDeleteUserMutation = {
  adminDeleteUser:  {
    __typename: "IDResult",
    id: string,
  },
};

export type AdminCreateAmhpTeamMutationVariables = {
  input: AdminCreateAmhpTeamInput,
};

export type AdminCreateAmhpTeamMutation = {
  adminCreateAmhpTeam:  {
    __typename: "IDResult",
    id: string,
  },
};

export type AdminUpdateAmhpTeamMutationVariables = {
  input: AdminUpdateAmhpTeamInput,
};

export type AdminUpdateAmhpTeamMutation = {
  adminUpdateAmhpTeam:  {
    __typename: "IDResult",
    id: string,
  },
};

export type AdminResetUserPasswordMutationVariables = {
  input: AdminResetPasswordInput,
};

export type AdminResetUserPasswordMutation = {
  adminResetUserPassword:  {
    __typename: "IDResult",
    id: string,
  },
};

export type AdminDisableUserMutationVariables = {
  input: DisableUserInput,
};

export type AdminDisableUserMutation = {
  adminDisableUser:  {
    __typename: "IDResult",
    id: string,
  },
};

export type AdminReenableUserMutationVariables = {
  input: ReenableUserInput,
};

export type AdminReenableUserMutation = {
  adminReenableUser:  {
    __typename: "IDResult",
    id: string,
  },
};

export type CreateAvailabilityMutationVariables = {
  input: CreateAvailabilityInput,
};

export type CreateAvailabilityMutation = {
  createAvailability:  {
    __typename: "Availability",
    id: string,
    createdAt: string | null,
    type: AvailabilityType | null,
    location:  {
      __typename: "Location",
      lat: number,
      lon: number,
    } | null,
    locationName:  {
      __typename: "LocationName",
      city: string | null,
      postcode: string,
      address: string | null,
      addressNotes: string | null,
    } | null,
    rrule: string,
    endHour: number,
    endDate: string,
    contractList:  {
      __typename: "ContractList",
      name: string,
      description: string | null,
      owner:  {
        __typename: "Organisation",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        type: OrgType,
        phone: string | null,
        email: string | null,
        expensesInstruction: string | null,
        declarationText: string | null,
        additionalNotesText: string | null,
        invoiceEmail: string | null,
        updatedBy: string | null,
        deleted: boolean | null,
      } | null,
    } | null,
    notes: string | null,
    s12DoctorAvailabilitiesId: string,
    availabilityDoctorId: string,
    oncall: boolean | null,
    mht:  {
      __typename: "MHT",
      id: string,
      abbreviation: string,
      organisation:  {
        __typename: "Organisation",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        type: OrgType,
        phone: string | null,
        email: string | null,
        expensesInstruction: string | null,
        declarationText: string | null,
        additionalNotesText: string | null,
        invoiceEmail: string | null,
        updatedBy: string | null,
        deleted: boolean | null,
      },
      mHTOrganisationId: string,
      s12Doctors:  {
        __typename: "ModelMHTS12DoctorConnection",
        nextToken: string | null,
      } | null,
    } | null,
    doctor:  {
      __typename: "S12Doctor",
      id: string,
      gmc: string,
      s12Expiry: string | null,
      name: string,
      legalName: string,
      phone: string | null,
      phone2: string | null,
      email: string,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      },
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      },
      gl: boolean | null,
      employer: string | null,
      jobTitle: string | null,
      responsibleOfficer: string | null,
      lineManager: string | null,
      defaultMHT: string | null,
      mhtEmployers:  {
        __typename: "ModelMHTS12DoctorConnection",
        nextToken: string | null,
      } | null,
      availabilities:  {
        __typename: "ModelAvailabilityConnection",
        nextToken: string | null,
      } | null,
      holidays:  {
        __typename: "ModelHolidayConnection",
        nextToken: string | null,
      } | null,
      specialties: Array< string | null >,
      languages: Array< string | null >,
      gender: Gender | null,
      notes: string | null,
      pushTokens: Array< string | null > | null,
      distance: number | null,
      hideFromSearch: boolean | null,
      visits:  {
        __typename: "ModelDoctorVisitConnection",
        nextToken: string | null,
      } | null,
      doctors:  {
        __typename: "ModelContactedDoctorConnection",
        nextToken: string | null,
      } | null,
      claims:  {
        __typename: "ModelClaimConnection",
        nextToken: string | null,
      } | null,
      availabilityLocations:  Array< {
        __typename: "Location",
        lat: number,
        lon: number,
      } | null > | null,
      availabilityPostcodes: Array< string | null > | null,
      siteName: string,
      claimData:  {
        __typename: "ClaimData",
        billingCompanyName: string | null,
        vatRegistration: string | null,
        fuelType: FuelType | null,
        engineSize: Engine | null,
      } | null,
      sectionFormAddress:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      sectionFormEmailAddress: string | null,
      reports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      summaryReports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      createdAt: string | null,
      lastActivity: string | null,
      isOutsideEngland: boolean | null,
    },
    rotaId: string | null,
    rotaName: string | null,
  } | null,
};

export type UpdateAvailabilityMutationVariables = {
  input: UpdateAvailabilityInput,
};

export type UpdateAvailabilityMutation = {
  updateAvailability:  {
    __typename: "Availability",
    id: string,
    createdAt: string | null,
    type: AvailabilityType | null,
    location:  {
      __typename: "Location",
      lat: number,
      lon: number,
    } | null,
    locationName:  {
      __typename: "LocationName",
      city: string | null,
      postcode: string,
      address: string | null,
      addressNotes: string | null,
    } | null,
    rrule: string,
    endHour: number,
    endDate: string,
    contractList:  {
      __typename: "ContractList",
      name: string,
      description: string | null,
      owner:  {
        __typename: "Organisation",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        type: OrgType,
        phone: string | null,
        email: string | null,
        expensesInstruction: string | null,
        declarationText: string | null,
        additionalNotesText: string | null,
        invoiceEmail: string | null,
        updatedBy: string | null,
        deleted: boolean | null,
      } | null,
    } | null,
    notes: string | null,
    s12DoctorAvailabilitiesId: string,
    availabilityDoctorId: string,
    oncall: boolean | null,
    mht:  {
      __typename: "MHT",
      id: string,
      abbreviation: string,
      organisation:  {
        __typename: "Organisation",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        type: OrgType,
        phone: string | null,
        email: string | null,
        expensesInstruction: string | null,
        declarationText: string | null,
        additionalNotesText: string | null,
        invoiceEmail: string | null,
        updatedBy: string | null,
        deleted: boolean | null,
      },
      mHTOrganisationId: string,
      s12Doctors:  {
        __typename: "ModelMHTS12DoctorConnection",
        nextToken: string | null,
      } | null,
    } | null,
    doctor:  {
      __typename: "S12Doctor",
      id: string,
      gmc: string,
      s12Expiry: string | null,
      name: string,
      legalName: string,
      phone: string | null,
      phone2: string | null,
      email: string,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      },
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      },
      gl: boolean | null,
      employer: string | null,
      jobTitle: string | null,
      responsibleOfficer: string | null,
      lineManager: string | null,
      defaultMHT: string | null,
      mhtEmployers:  {
        __typename: "ModelMHTS12DoctorConnection",
        nextToken: string | null,
      } | null,
      availabilities:  {
        __typename: "ModelAvailabilityConnection",
        nextToken: string | null,
      } | null,
      holidays:  {
        __typename: "ModelHolidayConnection",
        nextToken: string | null,
      } | null,
      specialties: Array< string | null >,
      languages: Array< string | null >,
      gender: Gender | null,
      notes: string | null,
      pushTokens: Array< string | null > | null,
      distance: number | null,
      hideFromSearch: boolean | null,
      visits:  {
        __typename: "ModelDoctorVisitConnection",
        nextToken: string | null,
      } | null,
      doctors:  {
        __typename: "ModelContactedDoctorConnection",
        nextToken: string | null,
      } | null,
      claims:  {
        __typename: "ModelClaimConnection",
        nextToken: string | null,
      } | null,
      availabilityLocations:  Array< {
        __typename: "Location",
        lat: number,
        lon: number,
      } | null > | null,
      availabilityPostcodes: Array< string | null > | null,
      siteName: string,
      claimData:  {
        __typename: "ClaimData",
        billingCompanyName: string | null,
        vatRegistration: string | null,
        fuelType: FuelType | null,
        engineSize: Engine | null,
      } | null,
      sectionFormAddress:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      sectionFormEmailAddress: string | null,
      reports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      summaryReports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      createdAt: string | null,
      lastActivity: string | null,
      isOutsideEngland: boolean | null,
    },
    rotaId: string | null,
    rotaName: string | null,
  } | null,
};

export type DeleteAvailabilityMutationVariables = {
  input: DeleteAvailabilityInput,
};

export type DeleteAvailabilityMutation = {
  deleteAvailability:  {
    __typename: "Availability",
    id: string,
    createdAt: string | null,
    type: AvailabilityType | null,
    location:  {
      __typename: "Location",
      lat: number,
      lon: number,
    } | null,
    locationName:  {
      __typename: "LocationName",
      city: string | null,
      postcode: string,
      address: string | null,
      addressNotes: string | null,
    } | null,
    rrule: string,
    endHour: number,
    endDate: string,
    contractList:  {
      __typename: "ContractList",
      name: string,
      description: string | null,
      owner:  {
        __typename: "Organisation",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        type: OrgType,
        phone: string | null,
        email: string | null,
        expensesInstruction: string | null,
        declarationText: string | null,
        additionalNotesText: string | null,
        invoiceEmail: string | null,
        updatedBy: string | null,
        deleted: boolean | null,
      } | null,
    } | null,
    notes: string | null,
    s12DoctorAvailabilitiesId: string,
    availabilityDoctorId: string,
    oncall: boolean | null,
    mht:  {
      __typename: "MHT",
      id: string,
      abbreviation: string,
      organisation:  {
        __typename: "Organisation",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        type: OrgType,
        phone: string | null,
        email: string | null,
        expensesInstruction: string | null,
        declarationText: string | null,
        additionalNotesText: string | null,
        invoiceEmail: string | null,
        updatedBy: string | null,
        deleted: boolean | null,
      },
      mHTOrganisationId: string,
      s12Doctors:  {
        __typename: "ModelMHTS12DoctorConnection",
        nextToken: string | null,
      } | null,
    } | null,
    doctor:  {
      __typename: "S12Doctor",
      id: string,
      gmc: string,
      s12Expiry: string | null,
      name: string,
      legalName: string,
      phone: string | null,
      phone2: string | null,
      email: string,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      },
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      },
      gl: boolean | null,
      employer: string | null,
      jobTitle: string | null,
      responsibleOfficer: string | null,
      lineManager: string | null,
      defaultMHT: string | null,
      mhtEmployers:  {
        __typename: "ModelMHTS12DoctorConnection",
        nextToken: string | null,
      } | null,
      availabilities:  {
        __typename: "ModelAvailabilityConnection",
        nextToken: string | null,
      } | null,
      holidays:  {
        __typename: "ModelHolidayConnection",
        nextToken: string | null,
      } | null,
      specialties: Array< string | null >,
      languages: Array< string | null >,
      gender: Gender | null,
      notes: string | null,
      pushTokens: Array< string | null > | null,
      distance: number | null,
      hideFromSearch: boolean | null,
      visits:  {
        __typename: "ModelDoctorVisitConnection",
        nextToken: string | null,
      } | null,
      doctors:  {
        __typename: "ModelContactedDoctorConnection",
        nextToken: string | null,
      } | null,
      claims:  {
        __typename: "ModelClaimConnection",
        nextToken: string | null,
      } | null,
      availabilityLocations:  Array< {
        __typename: "Location",
        lat: number,
        lon: number,
      } | null > | null,
      availabilityPostcodes: Array< string | null > | null,
      siteName: string,
      claimData:  {
        __typename: "ClaimData",
        billingCompanyName: string | null,
        vatRegistration: string | null,
        fuelType: FuelType | null,
        engineSize: Engine | null,
      } | null,
      sectionFormAddress:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      sectionFormEmailAddress: string | null,
      reports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      summaryReports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      createdAt: string | null,
      lastActivity: string | null,
      isOutsideEngland: boolean | null,
    },
    rotaId: string | null,
    rotaName: string | null,
  } | null,
};

export type CreateHolidayMutationVariables = {
  input: CreateHolidayInput,
};

export type CreateHolidayMutation = {
  createHoliday:  {
    __typename: "Holiday",
    id: string,
    start: string,
    end: string,
    visible: boolean,
    notes: string | null,
    s12DoctorHolidaysId: string,
    doctor:  {
      __typename: "S12Doctor",
      id: string,
      gmc: string,
      s12Expiry: string | null,
      name: string,
      legalName: string,
      phone: string | null,
      phone2: string | null,
      email: string,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      },
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      },
      gl: boolean | null,
      employer: string | null,
      jobTitle: string | null,
      responsibleOfficer: string | null,
      lineManager: string | null,
      defaultMHT: string | null,
      mhtEmployers:  {
        __typename: "ModelMHTS12DoctorConnection",
        nextToken: string | null,
      } | null,
      availabilities:  {
        __typename: "ModelAvailabilityConnection",
        nextToken: string | null,
      } | null,
      holidays:  {
        __typename: "ModelHolidayConnection",
        nextToken: string | null,
      } | null,
      specialties: Array< string | null >,
      languages: Array< string | null >,
      gender: Gender | null,
      notes: string | null,
      pushTokens: Array< string | null > | null,
      distance: number | null,
      hideFromSearch: boolean | null,
      visits:  {
        __typename: "ModelDoctorVisitConnection",
        nextToken: string | null,
      } | null,
      doctors:  {
        __typename: "ModelContactedDoctorConnection",
        nextToken: string | null,
      } | null,
      claims:  {
        __typename: "ModelClaimConnection",
        nextToken: string | null,
      } | null,
      availabilityLocations:  Array< {
        __typename: "Location",
        lat: number,
        lon: number,
      } | null > | null,
      availabilityPostcodes: Array< string | null > | null,
      siteName: string,
      claimData:  {
        __typename: "ClaimData",
        billingCompanyName: string | null,
        vatRegistration: string | null,
        fuelType: FuelType | null,
        engineSize: Engine | null,
      } | null,
      sectionFormAddress:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      sectionFormEmailAddress: string | null,
      reports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      summaryReports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      createdAt: string | null,
      lastActivity: string | null,
      isOutsideEngland: boolean | null,
    },
  } | null,
};

export type UpdateHolidayMutationVariables = {
  input: UpdateHolidayInput,
};

export type UpdateHolidayMutation = {
  updateHoliday:  {
    __typename: "Holiday",
    id: string,
    start: string,
    end: string,
    visible: boolean,
    notes: string | null,
    s12DoctorHolidaysId: string,
    doctor:  {
      __typename: "S12Doctor",
      id: string,
      gmc: string,
      s12Expiry: string | null,
      name: string,
      legalName: string,
      phone: string | null,
      phone2: string | null,
      email: string,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      },
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      },
      gl: boolean | null,
      employer: string | null,
      jobTitle: string | null,
      responsibleOfficer: string | null,
      lineManager: string | null,
      defaultMHT: string | null,
      mhtEmployers:  {
        __typename: "ModelMHTS12DoctorConnection",
        nextToken: string | null,
      } | null,
      availabilities:  {
        __typename: "ModelAvailabilityConnection",
        nextToken: string | null,
      } | null,
      holidays:  {
        __typename: "ModelHolidayConnection",
        nextToken: string | null,
      } | null,
      specialties: Array< string | null >,
      languages: Array< string | null >,
      gender: Gender | null,
      notes: string | null,
      pushTokens: Array< string | null > | null,
      distance: number | null,
      hideFromSearch: boolean | null,
      visits:  {
        __typename: "ModelDoctorVisitConnection",
        nextToken: string | null,
      } | null,
      doctors:  {
        __typename: "ModelContactedDoctorConnection",
        nextToken: string | null,
      } | null,
      claims:  {
        __typename: "ModelClaimConnection",
        nextToken: string | null,
      } | null,
      availabilityLocations:  Array< {
        __typename: "Location",
        lat: number,
        lon: number,
      } | null > | null,
      availabilityPostcodes: Array< string | null > | null,
      siteName: string,
      claimData:  {
        __typename: "ClaimData",
        billingCompanyName: string | null,
        vatRegistration: string | null,
        fuelType: FuelType | null,
        engineSize: Engine | null,
      } | null,
      sectionFormAddress:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      sectionFormEmailAddress: string | null,
      reports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      summaryReports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      createdAt: string | null,
      lastActivity: string | null,
      isOutsideEngland: boolean | null,
    },
  } | null,
};

export type DeleteHolidayMutationVariables = {
  input: DeleteHolidayInput,
};

export type DeleteHolidayMutation = {
  deleteHoliday:  {
    __typename: "Holiday",
    id: string,
    start: string,
    end: string,
    visible: boolean,
    notes: string | null,
    s12DoctorHolidaysId: string,
    doctor:  {
      __typename: "S12Doctor",
      id: string,
      gmc: string,
      s12Expiry: string | null,
      name: string,
      legalName: string,
      phone: string | null,
      phone2: string | null,
      email: string,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      },
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      },
      gl: boolean | null,
      employer: string | null,
      jobTitle: string | null,
      responsibleOfficer: string | null,
      lineManager: string | null,
      defaultMHT: string | null,
      mhtEmployers:  {
        __typename: "ModelMHTS12DoctorConnection",
        nextToken: string | null,
      } | null,
      availabilities:  {
        __typename: "ModelAvailabilityConnection",
        nextToken: string | null,
      } | null,
      holidays:  {
        __typename: "ModelHolidayConnection",
        nextToken: string | null,
      } | null,
      specialties: Array< string | null >,
      languages: Array< string | null >,
      gender: Gender | null,
      notes: string | null,
      pushTokens: Array< string | null > | null,
      distance: number | null,
      hideFromSearch: boolean | null,
      visits:  {
        __typename: "ModelDoctorVisitConnection",
        nextToken: string | null,
      } | null,
      doctors:  {
        __typename: "ModelContactedDoctorConnection",
        nextToken: string | null,
      } | null,
      claims:  {
        __typename: "ModelClaimConnection",
        nextToken: string | null,
      } | null,
      availabilityLocations:  Array< {
        __typename: "Location",
        lat: number,
        lon: number,
      } | null > | null,
      availabilityPostcodes: Array< string | null > | null,
      siteName: string,
      claimData:  {
        __typename: "ClaimData",
        billingCompanyName: string | null,
        vatRegistration: string | null,
        fuelType: FuelType | null,
        engineSize: Engine | null,
      } | null,
      sectionFormAddress:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      sectionFormEmailAddress: string | null,
      reports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      summaryReports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      createdAt: string | null,
      lastActivity: string | null,
      isOutsideEngland: boolean | null,
    },
  } | null,
};

export type CreateOrganisationMutationVariables = {
  input: CreateOrganisationInput,
};

export type CreateOrganisationMutation = {
  createOrganisation:  {
    __typename: "Organisation",
    id: string,
    name: string,
    featureFlags: string | null,
    features: string | null,
    type: OrgType,
    location:  {
      __typename: "Location",
      lat: number,
      lon: number,
    } | null,
    locationName:  {
      __typename: "LocationName",
      city: string | null,
      postcode: string,
      address: string | null,
      addressNotes: string | null,
    } | null,
    phone: string | null,
    email: string | null,
    users:  {
      __typename: "ModelOrganisationUserConnection",
      items:  Array< {
        __typename: "OrganisationUser",
        id: string,
        organisationUserOrganisationId: string,
        type: string,
        organisationUserUserId: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
    sites:  {
      __typename: "ModelSiteOrganisationConnection",
      items:  Array< {
        __typename: "SiteOrganisation",
        id: string,
        organisationType: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    ccg:  {
      __typename: "CCG",
      id: string,
      claimProcessorType: OrgType | null,
      claimProcessor:  {
        __typename: "Organisation",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        type: OrgType,
        phone: string | null,
        email: string | null,
        expensesInstruction: string | null,
        declarationText: string | null,
        additionalNotesText: string | null,
        invoiceEmail: string | null,
        updatedBy: string | null,
        deleted: boolean | null,
      } | null,
      cCGOrganisationId: string,
      organisation:  {
        __typename: "Organisation",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        type: OrgType,
        phone: string | null,
        email: string | null,
        expensesInstruction: string | null,
        declarationText: string | null,
        additionalNotesText: string | null,
        invoiceEmail: string | null,
        updatedBy: string | null,
        deleted: boolean | null,
      },
    } | null,
    mht:  {
      __typename: "MHT",
      id: string,
      abbreviation: string,
      organisation:  {
        __typename: "Organisation",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        type: OrgType,
        phone: string | null,
        email: string | null,
        expensesInstruction: string | null,
        declarationText: string | null,
        additionalNotesText: string | null,
        invoiceEmail: string | null,
        updatedBy: string | null,
        deleted: boolean | null,
      },
      mHTOrganisationId: string,
      s12Doctors:  {
        __typename: "ModelMHTS12DoctorConnection",
        nextToken: string | null,
      } | null,
    } | null,
    claims:  {
      __typename: "ModelClaimConnection",
      items:  Array< {
        __typename: "Claim",
        id: string,
        claimAssessmentId: string | null,
        claimVisitId: string,
        claimDoctorId: string,
        assigneeId: string,
        assigneeName: string | null,
        amhpTeamId: string,
        claimAmhpId: string,
        claimOrganisationId: string,
        claimCsuId: string,
        status: ClaimStatus,
        displayId: string,
        mileage: string | null,
        locationText: string,
        locationType: LocationType,
        claimPostcode: string | null,
        assessmentPostcode: string | null,
        patientInitials: string,
        patientFullName: string | null,
        startingPostcode: string | null,
        visitDate: string,
        assessmentTookPlace: boolean | null,
        notes: Array< string | null > | null,
        unread: boolean | null,
        createdAt: string | null,
        updatedAt: string,
        receivedDate: string,
        additionalExpenses: string | null,
        additionalInformation: string | null,
        patientNhsNumber: string | null,
        declaration: string | null,
        paid: boolean | null,
        lineManager: string | null,
        doctorAdditionalConf: DoctorConfirmation | null,
        isIndependentDoctor: boolean | null,
        defaultMHT: string | null,
        doctorEmployedStatus: DoctorEmployedStatusValues | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    expensesInstruction: string | null,
    declarationText: string | null,
    additionalNotesText: string | null,
    paymentRates:  Array< {
      __typename: "PaymentRates",
      description: string | null,
      assessmentFee: number,
      mileage: number,
    } | null > | null,
    invoiceEmail: string | null,
    updatedBy: string | null,
    mhtHospitalEmails:  Array< {
      __typename: "HospitalEmails",
      name: string,
      wards:  Array< {
        __typename: "EmailAddress",
        name: string,
        email: string,
      } >,
    } | null > | null,
    rotas:  Array< {
      __typename: "RotaItem",
      id: string,
      name: string,
    } | null > | null,
    deleted: boolean | null,
  } | null,
};

export type UpdateOrganisationMutationVariables = {
  input: UpdateOrganisationInput,
};

export type UpdateOrganisationMutation = {
  updateOrganisation:  {
    __typename: "Organisation",
    id: string,
    name: string,
    featureFlags: string | null,
    features: string | null,
    type: OrgType,
    location:  {
      __typename: "Location",
      lat: number,
      lon: number,
    } | null,
    locationName:  {
      __typename: "LocationName",
      city: string | null,
      postcode: string,
      address: string | null,
      addressNotes: string | null,
    } | null,
    phone: string | null,
    email: string | null,
    users:  {
      __typename: "ModelOrganisationUserConnection",
      items:  Array< {
        __typename: "OrganisationUser",
        id: string,
        organisationUserOrganisationId: string,
        type: string,
        organisationUserUserId: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
    sites:  {
      __typename: "ModelSiteOrganisationConnection",
      items:  Array< {
        __typename: "SiteOrganisation",
        id: string,
        organisationType: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    ccg:  {
      __typename: "CCG",
      id: string,
      claimProcessorType: OrgType | null,
      claimProcessor:  {
        __typename: "Organisation",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        type: OrgType,
        phone: string | null,
        email: string | null,
        expensesInstruction: string | null,
        declarationText: string | null,
        additionalNotesText: string | null,
        invoiceEmail: string | null,
        updatedBy: string | null,
        deleted: boolean | null,
      } | null,
      cCGOrganisationId: string,
      organisation:  {
        __typename: "Organisation",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        type: OrgType,
        phone: string | null,
        email: string | null,
        expensesInstruction: string | null,
        declarationText: string | null,
        additionalNotesText: string | null,
        invoiceEmail: string | null,
        updatedBy: string | null,
        deleted: boolean | null,
      },
    } | null,
    mht:  {
      __typename: "MHT",
      id: string,
      abbreviation: string,
      organisation:  {
        __typename: "Organisation",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        type: OrgType,
        phone: string | null,
        email: string | null,
        expensesInstruction: string | null,
        declarationText: string | null,
        additionalNotesText: string | null,
        invoiceEmail: string | null,
        updatedBy: string | null,
        deleted: boolean | null,
      },
      mHTOrganisationId: string,
      s12Doctors:  {
        __typename: "ModelMHTS12DoctorConnection",
        nextToken: string | null,
      } | null,
    } | null,
    claims:  {
      __typename: "ModelClaimConnection",
      items:  Array< {
        __typename: "Claim",
        id: string,
        claimAssessmentId: string | null,
        claimVisitId: string,
        claimDoctorId: string,
        assigneeId: string,
        assigneeName: string | null,
        amhpTeamId: string,
        claimAmhpId: string,
        claimOrganisationId: string,
        claimCsuId: string,
        status: ClaimStatus,
        displayId: string,
        mileage: string | null,
        locationText: string,
        locationType: LocationType,
        claimPostcode: string | null,
        assessmentPostcode: string | null,
        patientInitials: string,
        patientFullName: string | null,
        startingPostcode: string | null,
        visitDate: string,
        assessmentTookPlace: boolean | null,
        notes: Array< string | null > | null,
        unread: boolean | null,
        createdAt: string | null,
        updatedAt: string,
        receivedDate: string,
        additionalExpenses: string | null,
        additionalInformation: string | null,
        patientNhsNumber: string | null,
        declaration: string | null,
        paid: boolean | null,
        lineManager: string | null,
        doctorAdditionalConf: DoctorConfirmation | null,
        isIndependentDoctor: boolean | null,
        defaultMHT: string | null,
        doctorEmployedStatus: DoctorEmployedStatusValues | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    expensesInstruction: string | null,
    declarationText: string | null,
    additionalNotesText: string | null,
    paymentRates:  Array< {
      __typename: "PaymentRates",
      description: string | null,
      assessmentFee: number,
      mileage: number,
    } | null > | null,
    invoiceEmail: string | null,
    updatedBy: string | null,
    mhtHospitalEmails:  Array< {
      __typename: "HospitalEmails",
      name: string,
      wards:  Array< {
        __typename: "EmailAddress",
        name: string,
        email: string,
      } >,
    } | null > | null,
    rotas:  Array< {
      __typename: "RotaItem",
      id: string,
      name: string,
    } | null > | null,
    deleted: boolean | null,
  } | null,
};

export type DeleteOrganisationMutationVariables = {
  input: DeleteOrganisationInput,
};

export type DeleteOrganisationMutation = {
  deleteOrganisation:  {
    __typename: "Organisation",
    id: string,
    name: string,
    featureFlags: string | null,
    features: string | null,
    type: OrgType,
    location:  {
      __typename: "Location",
      lat: number,
      lon: number,
    } | null,
    locationName:  {
      __typename: "LocationName",
      city: string | null,
      postcode: string,
      address: string | null,
      addressNotes: string | null,
    } | null,
    phone: string | null,
    email: string | null,
    users:  {
      __typename: "ModelOrganisationUserConnection",
      items:  Array< {
        __typename: "OrganisationUser",
        id: string,
        organisationUserOrganisationId: string,
        type: string,
        organisationUserUserId: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
    sites:  {
      __typename: "ModelSiteOrganisationConnection",
      items:  Array< {
        __typename: "SiteOrganisation",
        id: string,
        organisationType: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    ccg:  {
      __typename: "CCG",
      id: string,
      claimProcessorType: OrgType | null,
      claimProcessor:  {
        __typename: "Organisation",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        type: OrgType,
        phone: string | null,
        email: string | null,
        expensesInstruction: string | null,
        declarationText: string | null,
        additionalNotesText: string | null,
        invoiceEmail: string | null,
        updatedBy: string | null,
        deleted: boolean | null,
      } | null,
      cCGOrganisationId: string,
      organisation:  {
        __typename: "Organisation",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        type: OrgType,
        phone: string | null,
        email: string | null,
        expensesInstruction: string | null,
        declarationText: string | null,
        additionalNotesText: string | null,
        invoiceEmail: string | null,
        updatedBy: string | null,
        deleted: boolean | null,
      },
    } | null,
    mht:  {
      __typename: "MHT",
      id: string,
      abbreviation: string,
      organisation:  {
        __typename: "Organisation",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        type: OrgType,
        phone: string | null,
        email: string | null,
        expensesInstruction: string | null,
        declarationText: string | null,
        additionalNotesText: string | null,
        invoiceEmail: string | null,
        updatedBy: string | null,
        deleted: boolean | null,
      },
      mHTOrganisationId: string,
      s12Doctors:  {
        __typename: "ModelMHTS12DoctorConnection",
        nextToken: string | null,
      } | null,
    } | null,
    claims:  {
      __typename: "ModelClaimConnection",
      items:  Array< {
        __typename: "Claim",
        id: string,
        claimAssessmentId: string | null,
        claimVisitId: string,
        claimDoctorId: string,
        assigneeId: string,
        assigneeName: string | null,
        amhpTeamId: string,
        claimAmhpId: string,
        claimOrganisationId: string,
        claimCsuId: string,
        status: ClaimStatus,
        displayId: string,
        mileage: string | null,
        locationText: string,
        locationType: LocationType,
        claimPostcode: string | null,
        assessmentPostcode: string | null,
        patientInitials: string,
        patientFullName: string | null,
        startingPostcode: string | null,
        visitDate: string,
        assessmentTookPlace: boolean | null,
        notes: Array< string | null > | null,
        unread: boolean | null,
        createdAt: string | null,
        updatedAt: string,
        receivedDate: string,
        additionalExpenses: string | null,
        additionalInformation: string | null,
        patientNhsNumber: string | null,
        declaration: string | null,
        paid: boolean | null,
        lineManager: string | null,
        doctorAdditionalConf: DoctorConfirmation | null,
        isIndependentDoctor: boolean | null,
        defaultMHT: string | null,
        doctorEmployedStatus: DoctorEmployedStatusValues | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    expensesInstruction: string | null,
    declarationText: string | null,
    additionalNotesText: string | null,
    paymentRates:  Array< {
      __typename: "PaymentRates",
      description: string | null,
      assessmentFee: number,
      mileage: number,
    } | null > | null,
    invoiceEmail: string | null,
    updatedBy: string | null,
    mhtHospitalEmails:  Array< {
      __typename: "HospitalEmails",
      name: string,
      wards:  Array< {
        __typename: "EmailAddress",
        name: string,
        email: string,
      } >,
    } | null > | null,
    rotas:  Array< {
      __typename: "RotaItem",
      id: string,
      name: string,
    } | null > | null,
    deleted: boolean | null,
  } | null,
};

export type CreateMhtMutationVariables = {
  input: CreateMHTInput,
};

export type CreateMhtMutation = {
  createMHT:  {
    __typename: "MHT",
    id: string,
    abbreviation: string,
    organisation:  {
      __typename: "Organisation",
      id: string,
      name: string,
      featureFlags: string | null,
      features: string | null,
      type: OrgType,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      } | null,
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      phone: string | null,
      email: string | null,
      users:  {
        __typename: "ModelOrganisationUserConnection",
        nextToken: string | null,
      } | null,
      sites:  {
        __typename: "ModelSiteOrganisationConnection",
        nextToken: string | null,
      } | null,
      ccg:  {
        __typename: "CCG",
        id: string,
        claimProcessorType: OrgType | null,
        cCGOrganisationId: string,
      } | null,
      mht:  {
        __typename: "MHT",
        id: string,
        abbreviation: string,
        mHTOrganisationId: string,
      } | null,
      claims:  {
        __typename: "ModelClaimConnection",
        nextToken: string | null,
      } | null,
      expensesInstruction: string | null,
      declarationText: string | null,
      additionalNotesText: string | null,
      paymentRates:  Array< {
        __typename: "PaymentRates",
        description: string | null,
        assessmentFee: number,
        mileage: number,
      } | null > | null,
      invoiceEmail: string | null,
      updatedBy: string | null,
      mhtHospitalEmails:  Array< {
        __typename: "HospitalEmails",
        name: string,
      } | null > | null,
      rotas:  Array< {
        __typename: "RotaItem",
        id: string,
        name: string,
      } | null > | null,
      deleted: boolean | null,
    },
    mHTOrganisationId: string,
    s12Doctors:  {
      __typename: "ModelMHTS12DoctorConnection",
      items:  Array< {
        __typename: "MHTS12Doctor",
        id: string,
        mHTS12DoctorS12DoctorId: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type UpdateMhtMutationVariables = {
  input: UpdateMHTInput,
};

export type UpdateMhtMutation = {
  updateMHT:  {
    __typename: "MHT",
    id: string,
    abbreviation: string,
    organisation:  {
      __typename: "Organisation",
      id: string,
      name: string,
      featureFlags: string | null,
      features: string | null,
      type: OrgType,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      } | null,
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      phone: string | null,
      email: string | null,
      users:  {
        __typename: "ModelOrganisationUserConnection",
        nextToken: string | null,
      } | null,
      sites:  {
        __typename: "ModelSiteOrganisationConnection",
        nextToken: string | null,
      } | null,
      ccg:  {
        __typename: "CCG",
        id: string,
        claimProcessorType: OrgType | null,
        cCGOrganisationId: string,
      } | null,
      mht:  {
        __typename: "MHT",
        id: string,
        abbreviation: string,
        mHTOrganisationId: string,
      } | null,
      claims:  {
        __typename: "ModelClaimConnection",
        nextToken: string | null,
      } | null,
      expensesInstruction: string | null,
      declarationText: string | null,
      additionalNotesText: string | null,
      paymentRates:  Array< {
        __typename: "PaymentRates",
        description: string | null,
        assessmentFee: number,
        mileage: number,
      } | null > | null,
      invoiceEmail: string | null,
      updatedBy: string | null,
      mhtHospitalEmails:  Array< {
        __typename: "HospitalEmails",
        name: string,
      } | null > | null,
      rotas:  Array< {
        __typename: "RotaItem",
        id: string,
        name: string,
      } | null > | null,
      deleted: boolean | null,
    },
    mHTOrganisationId: string,
    s12Doctors:  {
      __typename: "ModelMHTS12DoctorConnection",
      items:  Array< {
        __typename: "MHTS12Doctor",
        id: string,
        mHTS12DoctorS12DoctorId: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type DeleteMhtMutationVariables = {
  input: DeleteMHTInput,
};

export type DeleteMhtMutation = {
  deleteMHT:  {
    __typename: "MHT",
    id: string,
    abbreviation: string,
    organisation:  {
      __typename: "Organisation",
      id: string,
      name: string,
      featureFlags: string | null,
      features: string | null,
      type: OrgType,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      } | null,
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      phone: string | null,
      email: string | null,
      users:  {
        __typename: "ModelOrganisationUserConnection",
        nextToken: string | null,
      } | null,
      sites:  {
        __typename: "ModelSiteOrganisationConnection",
        nextToken: string | null,
      } | null,
      ccg:  {
        __typename: "CCG",
        id: string,
        claimProcessorType: OrgType | null,
        cCGOrganisationId: string,
      } | null,
      mht:  {
        __typename: "MHT",
        id: string,
        abbreviation: string,
        mHTOrganisationId: string,
      } | null,
      claims:  {
        __typename: "ModelClaimConnection",
        nextToken: string | null,
      } | null,
      expensesInstruction: string | null,
      declarationText: string | null,
      additionalNotesText: string | null,
      paymentRates:  Array< {
        __typename: "PaymentRates",
        description: string | null,
        assessmentFee: number,
        mileage: number,
      } | null > | null,
      invoiceEmail: string | null,
      updatedBy: string | null,
      mhtHospitalEmails:  Array< {
        __typename: "HospitalEmails",
        name: string,
      } | null > | null,
      rotas:  Array< {
        __typename: "RotaItem",
        id: string,
        name: string,
      } | null > | null,
      deleted: boolean | null,
    },
    mHTOrganisationId: string,
    s12Doctors:  {
      __typename: "ModelMHTS12DoctorConnection",
      items:  Array< {
        __typename: "MHTS12Doctor",
        id: string,
        mHTS12DoctorS12DoctorId: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type CreateMhts12DoctorMutationVariables = {
  input: CreateMHTS12DoctorInput,
};

export type CreateMhts12DoctorMutation = {
  createMHTS12Doctor:  {
    __typename: "MHTS12Doctor",
    id: string,
    mht:  {
      __typename: "MHT",
      id: string,
      abbreviation: string,
      organisation:  {
        __typename: "Organisation",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        type: OrgType,
        phone: string | null,
        email: string | null,
        expensesInstruction: string | null,
        declarationText: string | null,
        additionalNotesText: string | null,
        invoiceEmail: string | null,
        updatedBy: string | null,
        deleted: boolean | null,
      },
      mHTOrganisationId: string,
      s12Doctors:  {
        __typename: "ModelMHTS12DoctorConnection",
        nextToken: string | null,
      } | null,
    },
    mHTS12DoctorS12DoctorId: string,
    s12Doctor:  {
      __typename: "S12Doctor",
      id: string,
      gmc: string,
      s12Expiry: string | null,
      name: string,
      legalName: string,
      phone: string | null,
      phone2: string | null,
      email: string,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      },
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      },
      gl: boolean | null,
      employer: string | null,
      jobTitle: string | null,
      responsibleOfficer: string | null,
      lineManager: string | null,
      defaultMHT: string | null,
      mhtEmployers:  {
        __typename: "ModelMHTS12DoctorConnection",
        nextToken: string | null,
      } | null,
      availabilities:  {
        __typename: "ModelAvailabilityConnection",
        nextToken: string | null,
      } | null,
      holidays:  {
        __typename: "ModelHolidayConnection",
        nextToken: string | null,
      } | null,
      specialties: Array< string | null >,
      languages: Array< string | null >,
      gender: Gender | null,
      notes: string | null,
      pushTokens: Array< string | null > | null,
      distance: number | null,
      hideFromSearch: boolean | null,
      visits:  {
        __typename: "ModelDoctorVisitConnection",
        nextToken: string | null,
      } | null,
      doctors:  {
        __typename: "ModelContactedDoctorConnection",
        nextToken: string | null,
      } | null,
      claims:  {
        __typename: "ModelClaimConnection",
        nextToken: string | null,
      } | null,
      availabilityLocations:  Array< {
        __typename: "Location",
        lat: number,
        lon: number,
      } | null > | null,
      availabilityPostcodes: Array< string | null > | null,
      siteName: string,
      claimData:  {
        __typename: "ClaimData",
        billingCompanyName: string | null,
        vatRegistration: string | null,
        fuelType: FuelType | null,
        engineSize: Engine | null,
      } | null,
      sectionFormAddress:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      sectionFormEmailAddress: string | null,
      reports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      summaryReports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      createdAt: string | null,
      lastActivity: string | null,
      isOutsideEngland: boolean | null,
    },
  } | null,
};

export type UpdateMhts12DoctorMutationVariables = {
  input: UpdateMHTS12DoctorInput,
};

export type UpdateMhts12DoctorMutation = {
  updateMHTS12Doctor:  {
    __typename: "MHTS12Doctor",
    id: string,
    mht:  {
      __typename: "MHT",
      id: string,
      abbreviation: string,
      organisation:  {
        __typename: "Organisation",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        type: OrgType,
        phone: string | null,
        email: string | null,
        expensesInstruction: string | null,
        declarationText: string | null,
        additionalNotesText: string | null,
        invoiceEmail: string | null,
        updatedBy: string | null,
        deleted: boolean | null,
      },
      mHTOrganisationId: string,
      s12Doctors:  {
        __typename: "ModelMHTS12DoctorConnection",
        nextToken: string | null,
      } | null,
    },
    mHTS12DoctorS12DoctorId: string,
    s12Doctor:  {
      __typename: "S12Doctor",
      id: string,
      gmc: string,
      s12Expiry: string | null,
      name: string,
      legalName: string,
      phone: string | null,
      phone2: string | null,
      email: string,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      },
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      },
      gl: boolean | null,
      employer: string | null,
      jobTitle: string | null,
      responsibleOfficer: string | null,
      lineManager: string | null,
      defaultMHT: string | null,
      mhtEmployers:  {
        __typename: "ModelMHTS12DoctorConnection",
        nextToken: string | null,
      } | null,
      availabilities:  {
        __typename: "ModelAvailabilityConnection",
        nextToken: string | null,
      } | null,
      holidays:  {
        __typename: "ModelHolidayConnection",
        nextToken: string | null,
      } | null,
      specialties: Array< string | null >,
      languages: Array< string | null >,
      gender: Gender | null,
      notes: string | null,
      pushTokens: Array< string | null > | null,
      distance: number | null,
      hideFromSearch: boolean | null,
      visits:  {
        __typename: "ModelDoctorVisitConnection",
        nextToken: string | null,
      } | null,
      doctors:  {
        __typename: "ModelContactedDoctorConnection",
        nextToken: string | null,
      } | null,
      claims:  {
        __typename: "ModelClaimConnection",
        nextToken: string | null,
      } | null,
      availabilityLocations:  Array< {
        __typename: "Location",
        lat: number,
        lon: number,
      } | null > | null,
      availabilityPostcodes: Array< string | null > | null,
      siteName: string,
      claimData:  {
        __typename: "ClaimData",
        billingCompanyName: string | null,
        vatRegistration: string | null,
        fuelType: FuelType | null,
        engineSize: Engine | null,
      } | null,
      sectionFormAddress:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      sectionFormEmailAddress: string | null,
      reports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      summaryReports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      createdAt: string | null,
      lastActivity: string | null,
      isOutsideEngland: boolean | null,
    },
  } | null,
};

export type DeleteMhts12DoctorMutationVariables = {
  input: DeleteMHTS12DoctorInput,
};

export type DeleteMhts12DoctorMutation = {
  deleteMHTS12Doctor:  {
    __typename: "MHTS12Doctor",
    id: string,
    mht:  {
      __typename: "MHT",
      id: string,
      abbreviation: string,
      organisation:  {
        __typename: "Organisation",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        type: OrgType,
        phone: string | null,
        email: string | null,
        expensesInstruction: string | null,
        declarationText: string | null,
        additionalNotesText: string | null,
        invoiceEmail: string | null,
        updatedBy: string | null,
        deleted: boolean | null,
      },
      mHTOrganisationId: string,
      s12Doctors:  {
        __typename: "ModelMHTS12DoctorConnection",
        nextToken: string | null,
      } | null,
    },
    mHTS12DoctorS12DoctorId: string,
    s12Doctor:  {
      __typename: "S12Doctor",
      id: string,
      gmc: string,
      s12Expiry: string | null,
      name: string,
      legalName: string,
      phone: string | null,
      phone2: string | null,
      email: string,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      },
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      },
      gl: boolean | null,
      employer: string | null,
      jobTitle: string | null,
      responsibleOfficer: string | null,
      lineManager: string | null,
      defaultMHT: string | null,
      mhtEmployers:  {
        __typename: "ModelMHTS12DoctorConnection",
        nextToken: string | null,
      } | null,
      availabilities:  {
        __typename: "ModelAvailabilityConnection",
        nextToken: string | null,
      } | null,
      holidays:  {
        __typename: "ModelHolidayConnection",
        nextToken: string | null,
      } | null,
      specialties: Array< string | null >,
      languages: Array< string | null >,
      gender: Gender | null,
      notes: string | null,
      pushTokens: Array< string | null > | null,
      distance: number | null,
      hideFromSearch: boolean | null,
      visits:  {
        __typename: "ModelDoctorVisitConnection",
        nextToken: string | null,
      } | null,
      doctors:  {
        __typename: "ModelContactedDoctorConnection",
        nextToken: string | null,
      } | null,
      claims:  {
        __typename: "ModelClaimConnection",
        nextToken: string | null,
      } | null,
      availabilityLocations:  Array< {
        __typename: "Location",
        lat: number,
        lon: number,
      } | null > | null,
      availabilityPostcodes: Array< string | null > | null,
      siteName: string,
      claimData:  {
        __typename: "ClaimData",
        billingCompanyName: string | null,
        vatRegistration: string | null,
        fuelType: FuelType | null,
        engineSize: Engine | null,
      } | null,
      sectionFormAddress:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      sectionFormEmailAddress: string | null,
      reports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      summaryReports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      createdAt: string | null,
      lastActivity: string | null,
      isOutsideEngland: boolean | null,
    },
  } | null,
};

export type CreateOrganisationUserMutationVariables = {
  input: CreateOrganisationUserInput,
};

export type CreateOrganisationUserMutation = {
  createOrganisationUser:  {
    __typename: "OrganisationUser",
    id: string,
    organisationUserOrganisationId: string,
    type: string,
    organisation:  {
      __typename: "Organisation",
      id: string,
      name: string,
      featureFlags: string | null,
      features: string | null,
      type: OrgType,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      } | null,
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      phone: string | null,
      email: string | null,
      users:  {
        __typename: "ModelOrganisationUserConnection",
        nextToken: string | null,
      } | null,
      sites:  {
        __typename: "ModelSiteOrganisationConnection",
        nextToken: string | null,
      } | null,
      ccg:  {
        __typename: "CCG",
        id: string,
        claimProcessorType: OrgType | null,
        cCGOrganisationId: string,
      } | null,
      mht:  {
        __typename: "MHT",
        id: string,
        abbreviation: string,
        mHTOrganisationId: string,
      } | null,
      claims:  {
        __typename: "ModelClaimConnection",
        nextToken: string | null,
      } | null,
      expensesInstruction: string | null,
      declarationText: string | null,
      additionalNotesText: string | null,
      paymentRates:  Array< {
        __typename: "PaymentRates",
        description: string | null,
        assessmentFee: number,
        mileage: number,
      } | null > | null,
      invoiceEmail: string | null,
      updatedBy: string | null,
      mhtHospitalEmails:  Array< {
        __typename: "HospitalEmails",
        name: string,
      } | null > | null,
      rotas:  Array< {
        __typename: "RotaItem",
        id: string,
        name: string,
      } | null > | null,
      deleted: boolean | null,
    },
    organisationUserUserId: string,
    user:  {
      __typename: "User",
      id: string,
      name: string,
      email: string,
      phone: string | null,
      organisations:  {
        __typename: "ModelOrganisationUserConnection",
        nextToken: string | null,
      } | null,
    },
  } | null,
};

export type UpdateOrganisationUserMutationVariables = {
  input: UpdateOrganisationUserInput,
};

export type UpdateOrganisationUserMutation = {
  updateOrganisationUser:  {
    __typename: "OrganisationUser",
    id: string,
    organisationUserOrganisationId: string,
    type: string,
    organisation:  {
      __typename: "Organisation",
      id: string,
      name: string,
      featureFlags: string | null,
      features: string | null,
      type: OrgType,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      } | null,
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      phone: string | null,
      email: string | null,
      users:  {
        __typename: "ModelOrganisationUserConnection",
        nextToken: string | null,
      } | null,
      sites:  {
        __typename: "ModelSiteOrganisationConnection",
        nextToken: string | null,
      } | null,
      ccg:  {
        __typename: "CCG",
        id: string,
        claimProcessorType: OrgType | null,
        cCGOrganisationId: string,
      } | null,
      mht:  {
        __typename: "MHT",
        id: string,
        abbreviation: string,
        mHTOrganisationId: string,
      } | null,
      claims:  {
        __typename: "ModelClaimConnection",
        nextToken: string | null,
      } | null,
      expensesInstruction: string | null,
      declarationText: string | null,
      additionalNotesText: string | null,
      paymentRates:  Array< {
        __typename: "PaymentRates",
        description: string | null,
        assessmentFee: number,
        mileage: number,
      } | null > | null,
      invoiceEmail: string | null,
      updatedBy: string | null,
      mhtHospitalEmails:  Array< {
        __typename: "HospitalEmails",
        name: string,
      } | null > | null,
      rotas:  Array< {
        __typename: "RotaItem",
        id: string,
        name: string,
      } | null > | null,
      deleted: boolean | null,
    },
    organisationUserUserId: string,
    user:  {
      __typename: "User",
      id: string,
      name: string,
      email: string,
      phone: string | null,
      organisations:  {
        __typename: "ModelOrganisationUserConnection",
        nextToken: string | null,
      } | null,
    },
  } | null,
};

export type DeleteOrganisationUserMutationVariables = {
  input: DeleteOrganisationUserInput,
};

export type DeleteOrganisationUserMutation = {
  deleteOrganisationUser:  {
    __typename: "OrganisationUser",
    id: string,
    organisationUserOrganisationId: string,
    type: string,
    organisation:  {
      __typename: "Organisation",
      id: string,
      name: string,
      featureFlags: string | null,
      features: string | null,
      type: OrgType,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      } | null,
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      phone: string | null,
      email: string | null,
      users:  {
        __typename: "ModelOrganisationUserConnection",
        nextToken: string | null,
      } | null,
      sites:  {
        __typename: "ModelSiteOrganisationConnection",
        nextToken: string | null,
      } | null,
      ccg:  {
        __typename: "CCG",
        id: string,
        claimProcessorType: OrgType | null,
        cCGOrganisationId: string,
      } | null,
      mht:  {
        __typename: "MHT",
        id: string,
        abbreviation: string,
        mHTOrganisationId: string,
      } | null,
      claims:  {
        __typename: "ModelClaimConnection",
        nextToken: string | null,
      } | null,
      expensesInstruction: string | null,
      declarationText: string | null,
      additionalNotesText: string | null,
      paymentRates:  Array< {
        __typename: "PaymentRates",
        description: string | null,
        assessmentFee: number,
        mileage: number,
      } | null > | null,
      invoiceEmail: string | null,
      updatedBy: string | null,
      mhtHospitalEmails:  Array< {
        __typename: "HospitalEmails",
        name: string,
      } | null > | null,
      rotas:  Array< {
        __typename: "RotaItem",
        id: string,
        name: string,
      } | null > | null,
      deleted: boolean | null,
    },
    organisationUserUserId: string,
    user:  {
      __typename: "User",
      id: string,
      name: string,
      email: string,
      phone: string | null,
      organisations:  {
        __typename: "ModelOrganisationUserConnection",
        nextToken: string | null,
      } | null,
    },
  } | null,
};

export type CreateUserMutationVariables = {
  input: CreateUserInput,
};

export type CreateUserMutation = {
  createUser:  {
    __typename: "User",
    id: string,
    name: string,
    email: string,
    phone: string | null,
    organisations:  {
      __typename: "ModelOrganisationUserConnection",
      items:  Array< {
        __typename: "OrganisationUser",
        id: string,
        organisationUserOrganisationId: string,
        type: string,
        organisationUserUserId: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput,
};

export type UpdateUserMutation = {
  updateUser:  {
    __typename: "User",
    id: string,
    name: string,
    email: string,
    phone: string | null,
    organisations:  {
      __typename: "ModelOrganisationUserConnection",
      items:  Array< {
        __typename: "OrganisationUser",
        id: string,
        organisationUserOrganisationId: string,
        type: string,
        organisationUserUserId: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput,
};

export type DeleteUserMutation = {
  deleteUser:  {
    __typename: "User",
    id: string,
    name: string,
    email: string,
    phone: string | null,
    organisations:  {
      __typename: "ModelOrganisationUserConnection",
      items:  Array< {
        __typename: "OrganisationUser",
        id: string,
        organisationUserOrganisationId: string,
        type: string,
        organisationUserUserId: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type CreateAmhpTeamUserMutationVariables = {
  input: CreateAmhpTeamUserInput,
};

export type CreateAmhpTeamUserMutation = {
  createAmhpTeamUser:  {
    __typename: "AmhpTeamUser",
    id: string,
    amhpTeam:  {
      __typename: "AmhpTeam",
      id: string,
      name: string,
      featureFlags: string | null,
      features: string | null,
      assessments:  {
        __typename: "ModelAssessmentConnection",
        nextToken: string | null,
      } | null,
      users:  {
        __typename: "ModelAmhpTeamUserConnection",
        nextToken: string | null,
      } | null,
      commonAddresses:  {
        __typename: "ModelAddressConnection",
        nextToken: string | null,
      } | null,
      localAuthority: string | null,
      hospitalEmails:  Array< {
        __typename: "HospitalEmails",
        name: string,
      } > | null,
      mhaEmailAddresses:  Array< {
        __typename: "RecipientEmails",
        name: string | null,
      } | null > | null,
      emails: Array< string > | null,
      phoneNumbers: Array< string > | null,
      mhaInboxInstructions: string | null,
      reports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      summaryReports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      updatedBy: string | null,
    },
    amhp:  {
      __typename: "AmhpProfile",
      id: string,
      email: string,
      phone: string | null,
      name: string,
      legalName: string | null,
      deleted: boolean | null,
      warrantInfo: string | null,
      accreditation: string | null,
      sectionFormAddress:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      sectionFormEmailAddress: string | null,
      sectionFormContactNumber: string | null,
      assessments:  {
        __typename: "ModelAssessmentConnection",
        nextToken: string | null,
      } | null,
      pushTokens: Array< string | null > | null,
      teams:  {
        __typename: "ModelAmhpTeamUserConnection",
        nextToken: string | null,
      } | null,
      lastActivity: string | null,
      createdAt: string | null,
      reports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      summaryReports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
    },
  } | null,
};

export type UpdateAmhpTeamUserMutationVariables = {
  input: UpdateAmhpTeamUserInput,
};

export type UpdateAmhpTeamUserMutation = {
  updateAmhpTeamUser:  {
    __typename: "AmhpTeamUser",
    id: string,
    amhpTeam:  {
      __typename: "AmhpTeam",
      id: string,
      name: string,
      featureFlags: string | null,
      features: string | null,
      assessments:  {
        __typename: "ModelAssessmentConnection",
        nextToken: string | null,
      } | null,
      users:  {
        __typename: "ModelAmhpTeamUserConnection",
        nextToken: string | null,
      } | null,
      commonAddresses:  {
        __typename: "ModelAddressConnection",
        nextToken: string | null,
      } | null,
      localAuthority: string | null,
      hospitalEmails:  Array< {
        __typename: "HospitalEmails",
        name: string,
      } > | null,
      mhaEmailAddresses:  Array< {
        __typename: "RecipientEmails",
        name: string | null,
      } | null > | null,
      emails: Array< string > | null,
      phoneNumbers: Array< string > | null,
      mhaInboxInstructions: string | null,
      reports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      summaryReports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      updatedBy: string | null,
    },
    amhp:  {
      __typename: "AmhpProfile",
      id: string,
      email: string,
      phone: string | null,
      name: string,
      legalName: string | null,
      deleted: boolean | null,
      warrantInfo: string | null,
      accreditation: string | null,
      sectionFormAddress:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      sectionFormEmailAddress: string | null,
      sectionFormContactNumber: string | null,
      assessments:  {
        __typename: "ModelAssessmentConnection",
        nextToken: string | null,
      } | null,
      pushTokens: Array< string | null > | null,
      teams:  {
        __typename: "ModelAmhpTeamUserConnection",
        nextToken: string | null,
      } | null,
      lastActivity: string | null,
      createdAt: string | null,
      reports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      summaryReports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
    },
  } | null,
};

export type DeleteAmhpTeamUserMutationVariables = {
  input: DeleteAmhpTeamUserInput,
};

export type DeleteAmhpTeamUserMutation = {
  deleteAmhpTeamUser:  {
    __typename: "AmhpTeamUser",
    id: string,
    amhpTeam:  {
      __typename: "AmhpTeam",
      id: string,
      name: string,
      featureFlags: string | null,
      features: string | null,
      assessments:  {
        __typename: "ModelAssessmentConnection",
        nextToken: string | null,
      } | null,
      users:  {
        __typename: "ModelAmhpTeamUserConnection",
        nextToken: string | null,
      } | null,
      commonAddresses:  {
        __typename: "ModelAddressConnection",
        nextToken: string | null,
      } | null,
      localAuthority: string | null,
      hospitalEmails:  Array< {
        __typename: "HospitalEmails",
        name: string,
      } > | null,
      mhaEmailAddresses:  Array< {
        __typename: "RecipientEmails",
        name: string | null,
      } | null > | null,
      emails: Array< string > | null,
      phoneNumbers: Array< string > | null,
      mhaInboxInstructions: string | null,
      reports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      summaryReports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      updatedBy: string | null,
    },
    amhp:  {
      __typename: "AmhpProfile",
      id: string,
      email: string,
      phone: string | null,
      name: string,
      legalName: string | null,
      deleted: boolean | null,
      warrantInfo: string | null,
      accreditation: string | null,
      sectionFormAddress:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      sectionFormEmailAddress: string | null,
      sectionFormContactNumber: string | null,
      assessments:  {
        __typename: "ModelAssessmentConnection",
        nextToken: string | null,
      } | null,
      pushTokens: Array< string | null > | null,
      teams:  {
        __typename: "ModelAmhpTeamUserConnection",
        nextToken: string | null,
      } | null,
      lastActivity: string | null,
      createdAt: string | null,
      reports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      summaryReports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
    },
  } | null,
};

export type CreateSiteMutationVariables = {
  input: CreateSiteInput,
};

export type CreateSiteMutation = {
  createSite:  {
    __typename: "Site",
    id: string,
    name: string,
    organisations:  {
      __typename: "ModelSiteOrganisationConnection",
      items:  Array< {
        __typename: "SiteOrganisation",
        id: string,
        organisationType: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    amhpTeams:  {
      __typename: "ModelAmhpTeamConnection",
      items:  Array< {
        __typename: "AmhpTeam",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        localAuthority: string | null,
        emails: Array< string > | null,
        phoneNumbers: Array< string > | null,
        mhaInboxInstructions: string | null,
        updatedBy: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    doctors:  {
      __typename: "ModelS12DoctorConnection",
      items:  Array< {
        __typename: "S12Doctor",
        id: string,
        gmc: string,
        s12Expiry: string | null,
        name: string,
        legalName: string,
        phone: string | null,
        phone2: string | null,
        email: string,
        gl: boolean | null,
        employer: string | null,
        jobTitle: string | null,
        responsibleOfficer: string | null,
        lineManager: string | null,
        defaultMHT: string | null,
        specialties: Array< string | null >,
        languages: Array< string | null >,
        gender: Gender | null,
        notes: string | null,
        pushTokens: Array< string | null > | null,
        distance: number | null,
        hideFromSearch: boolean | null,
        availabilityPostcodes: Array< string | null > | null,
        siteName: string,
        sectionFormEmailAddress: string | null,
        createdAt: string | null,
        lastActivity: string | null,
        isOutsideEngland: boolean | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    doctorReports:  {
      __typename: "ModelReportingConnection",
      items:  Array< {
        __typename: "Reporting",
        id: string,
        pk: string,
        sk: string,
        ttl: number | null,
        gsi1pk: string | null,
        gsi1sk: string | null,
        gsi2pk: string | null,
        gsi2sk: string | null,
        gsi3pk: string | null,
        gsi3sk: string | null,
        eventName: string,
        userId: string,
        timestamp: string,
        period: string,
        count: number | null,
        siteId: string | null,
        teamId: string | null,
        orgId: string | null,
        amhpId: string | null,
        doctorId: string | null,
        claimProcessorId: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    summaryReports:  {
      __typename: "ModelReportingConnection",
      items:  Array< {
        __typename: "Reporting",
        id: string,
        pk: string,
        sk: string,
        ttl: number | null,
        gsi1pk: string | null,
        gsi1sk: string | null,
        gsi2pk: string | null,
        gsi2sk: string | null,
        gsi3pk: string | null,
        gsi3sk: string | null,
        eventName: string,
        userId: string,
        timestamp: string,
        period: string,
        count: number | null,
        siteId: string | null,
        teamId: string | null,
        orgId: string | null,
        amhpId: string | null,
        doctorId: string | null,
        claimProcessorId: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type UpdateSiteMutationVariables = {
  input: UpdateSiteInput,
};

export type UpdateSiteMutation = {
  updateSite:  {
    __typename: "Site",
    id: string,
    name: string,
    organisations:  {
      __typename: "ModelSiteOrganisationConnection",
      items:  Array< {
        __typename: "SiteOrganisation",
        id: string,
        organisationType: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    amhpTeams:  {
      __typename: "ModelAmhpTeamConnection",
      items:  Array< {
        __typename: "AmhpTeam",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        localAuthority: string | null,
        emails: Array< string > | null,
        phoneNumbers: Array< string > | null,
        mhaInboxInstructions: string | null,
        updatedBy: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    doctors:  {
      __typename: "ModelS12DoctorConnection",
      items:  Array< {
        __typename: "S12Doctor",
        id: string,
        gmc: string,
        s12Expiry: string | null,
        name: string,
        legalName: string,
        phone: string | null,
        phone2: string | null,
        email: string,
        gl: boolean | null,
        employer: string | null,
        jobTitle: string | null,
        responsibleOfficer: string | null,
        lineManager: string | null,
        defaultMHT: string | null,
        specialties: Array< string | null >,
        languages: Array< string | null >,
        gender: Gender | null,
        notes: string | null,
        pushTokens: Array< string | null > | null,
        distance: number | null,
        hideFromSearch: boolean | null,
        availabilityPostcodes: Array< string | null > | null,
        siteName: string,
        sectionFormEmailAddress: string | null,
        createdAt: string | null,
        lastActivity: string | null,
        isOutsideEngland: boolean | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    doctorReports:  {
      __typename: "ModelReportingConnection",
      items:  Array< {
        __typename: "Reporting",
        id: string,
        pk: string,
        sk: string,
        ttl: number | null,
        gsi1pk: string | null,
        gsi1sk: string | null,
        gsi2pk: string | null,
        gsi2sk: string | null,
        gsi3pk: string | null,
        gsi3sk: string | null,
        eventName: string,
        userId: string,
        timestamp: string,
        period: string,
        count: number | null,
        siteId: string | null,
        teamId: string | null,
        orgId: string | null,
        amhpId: string | null,
        doctorId: string | null,
        claimProcessorId: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    summaryReports:  {
      __typename: "ModelReportingConnection",
      items:  Array< {
        __typename: "Reporting",
        id: string,
        pk: string,
        sk: string,
        ttl: number | null,
        gsi1pk: string | null,
        gsi1sk: string | null,
        gsi2pk: string | null,
        gsi2sk: string | null,
        gsi3pk: string | null,
        gsi3sk: string | null,
        eventName: string,
        userId: string,
        timestamp: string,
        period: string,
        count: number | null,
        siteId: string | null,
        teamId: string | null,
        orgId: string | null,
        amhpId: string | null,
        doctorId: string | null,
        claimProcessorId: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type DeleteSiteMutationVariables = {
  input: DeleteSiteInput,
};

export type DeleteSiteMutation = {
  deleteSite:  {
    __typename: "Site",
    id: string,
    name: string,
    organisations:  {
      __typename: "ModelSiteOrganisationConnection",
      items:  Array< {
        __typename: "SiteOrganisation",
        id: string,
        organisationType: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    amhpTeams:  {
      __typename: "ModelAmhpTeamConnection",
      items:  Array< {
        __typename: "AmhpTeam",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        localAuthority: string | null,
        emails: Array< string > | null,
        phoneNumbers: Array< string > | null,
        mhaInboxInstructions: string | null,
        updatedBy: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    doctors:  {
      __typename: "ModelS12DoctorConnection",
      items:  Array< {
        __typename: "S12Doctor",
        id: string,
        gmc: string,
        s12Expiry: string | null,
        name: string,
        legalName: string,
        phone: string | null,
        phone2: string | null,
        email: string,
        gl: boolean | null,
        employer: string | null,
        jobTitle: string | null,
        responsibleOfficer: string | null,
        lineManager: string | null,
        defaultMHT: string | null,
        specialties: Array< string | null >,
        languages: Array< string | null >,
        gender: Gender | null,
        notes: string | null,
        pushTokens: Array< string | null > | null,
        distance: number | null,
        hideFromSearch: boolean | null,
        availabilityPostcodes: Array< string | null > | null,
        siteName: string,
        sectionFormEmailAddress: string | null,
        createdAt: string | null,
        lastActivity: string | null,
        isOutsideEngland: boolean | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    doctorReports:  {
      __typename: "ModelReportingConnection",
      items:  Array< {
        __typename: "Reporting",
        id: string,
        pk: string,
        sk: string,
        ttl: number | null,
        gsi1pk: string | null,
        gsi1sk: string | null,
        gsi2pk: string | null,
        gsi2sk: string | null,
        gsi3pk: string | null,
        gsi3sk: string | null,
        eventName: string,
        userId: string,
        timestamp: string,
        period: string,
        count: number | null,
        siteId: string | null,
        teamId: string | null,
        orgId: string | null,
        amhpId: string | null,
        doctorId: string | null,
        claimProcessorId: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    summaryReports:  {
      __typename: "ModelReportingConnection",
      items:  Array< {
        __typename: "Reporting",
        id: string,
        pk: string,
        sk: string,
        ttl: number | null,
        gsi1pk: string | null,
        gsi1sk: string | null,
        gsi2pk: string | null,
        gsi2sk: string | null,
        gsi3pk: string | null,
        gsi3sk: string | null,
        eventName: string,
        userId: string,
        timestamp: string,
        period: string,
        count: number | null,
        siteId: string | null,
        teamId: string | null,
        orgId: string | null,
        amhpId: string | null,
        doctorId: string | null,
        claimProcessorId: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type CreateLinkedSitesMutationVariables = {
  input: CreateLinkedSitesInput,
};

export type CreateLinkedSitesMutation = {
  createLinkedSites:  {
    __typename: "LinkedSites",
    id: string,
    parentSite: string,
    childSite: string,
  } | null,
};

export type UpdateLinkedSitesMutationVariables = {
  input: UpdateLinkedSitesInput,
};

export type UpdateLinkedSitesMutation = {
  updateLinkedSites:  {
    __typename: "LinkedSites",
    id: string,
    parentSite: string,
    childSite: string,
  } | null,
};

export type DeleteLinkedSitesMutationVariables = {
  input: DeleteLinkedSitesInput,
};

export type DeleteLinkedSitesMutation = {
  deleteLinkedSites:  {
    __typename: "LinkedSites",
    id: string,
    parentSite: string,
    childSite: string,
  } | null,
};

export type CreateSiteOrganisationMutationVariables = {
  input: CreateSiteOrganisationInput,
};

export type CreateSiteOrganisationMutation = {
  createSiteOrganisation:  {
    __typename: "SiteOrganisation",
    id: string,
    site:  {
      __typename: "Site",
      id: string,
      name: string,
      organisations:  {
        __typename: "ModelSiteOrganisationConnection",
        nextToken: string | null,
      } | null,
      amhpTeams:  {
        __typename: "ModelAmhpTeamConnection",
        nextToken: string | null,
      } | null,
      doctors:  {
        __typename: "ModelS12DoctorConnection",
        nextToken: string | null,
      } | null,
      doctorReports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      summaryReports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
    },
    organisationType: string | null,
    organisation:  {
      __typename: "Organisation",
      id: string,
      name: string,
      featureFlags: string | null,
      features: string | null,
      type: OrgType,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      } | null,
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      phone: string | null,
      email: string | null,
      users:  {
        __typename: "ModelOrganisationUserConnection",
        nextToken: string | null,
      } | null,
      sites:  {
        __typename: "ModelSiteOrganisationConnection",
        nextToken: string | null,
      } | null,
      ccg:  {
        __typename: "CCG",
        id: string,
        claimProcessorType: OrgType | null,
        cCGOrganisationId: string,
      } | null,
      mht:  {
        __typename: "MHT",
        id: string,
        abbreviation: string,
        mHTOrganisationId: string,
      } | null,
      claims:  {
        __typename: "ModelClaimConnection",
        nextToken: string | null,
      } | null,
      expensesInstruction: string | null,
      declarationText: string | null,
      additionalNotesText: string | null,
      paymentRates:  Array< {
        __typename: "PaymentRates",
        description: string | null,
        assessmentFee: number,
        mileage: number,
      } | null > | null,
      invoiceEmail: string | null,
      updatedBy: string | null,
      mhtHospitalEmails:  Array< {
        __typename: "HospitalEmails",
        name: string,
      } | null > | null,
      rotas:  Array< {
        __typename: "RotaItem",
        id: string,
        name: string,
      } | null > | null,
      deleted: boolean | null,
    },
  } | null,
};

export type UpdateSiteOrganisationMutationVariables = {
  input: UpdateSiteOrganisationInput,
};

export type UpdateSiteOrganisationMutation = {
  updateSiteOrganisation:  {
    __typename: "SiteOrganisation",
    id: string,
    site:  {
      __typename: "Site",
      id: string,
      name: string,
      organisations:  {
        __typename: "ModelSiteOrganisationConnection",
        nextToken: string | null,
      } | null,
      amhpTeams:  {
        __typename: "ModelAmhpTeamConnection",
        nextToken: string | null,
      } | null,
      doctors:  {
        __typename: "ModelS12DoctorConnection",
        nextToken: string | null,
      } | null,
      doctorReports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      summaryReports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
    },
    organisationType: string | null,
    organisation:  {
      __typename: "Organisation",
      id: string,
      name: string,
      featureFlags: string | null,
      features: string | null,
      type: OrgType,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      } | null,
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      phone: string | null,
      email: string | null,
      users:  {
        __typename: "ModelOrganisationUserConnection",
        nextToken: string | null,
      } | null,
      sites:  {
        __typename: "ModelSiteOrganisationConnection",
        nextToken: string | null,
      } | null,
      ccg:  {
        __typename: "CCG",
        id: string,
        claimProcessorType: OrgType | null,
        cCGOrganisationId: string,
      } | null,
      mht:  {
        __typename: "MHT",
        id: string,
        abbreviation: string,
        mHTOrganisationId: string,
      } | null,
      claims:  {
        __typename: "ModelClaimConnection",
        nextToken: string | null,
      } | null,
      expensesInstruction: string | null,
      declarationText: string | null,
      additionalNotesText: string | null,
      paymentRates:  Array< {
        __typename: "PaymentRates",
        description: string | null,
        assessmentFee: number,
        mileage: number,
      } | null > | null,
      invoiceEmail: string | null,
      updatedBy: string | null,
      mhtHospitalEmails:  Array< {
        __typename: "HospitalEmails",
        name: string,
      } | null > | null,
      rotas:  Array< {
        __typename: "RotaItem",
        id: string,
        name: string,
      } | null > | null,
      deleted: boolean | null,
    },
  } | null,
};

export type DeleteSiteOrganisationMutationVariables = {
  input: DeleteSiteOrganisationInput,
};

export type DeleteSiteOrganisationMutation = {
  deleteSiteOrganisation:  {
    __typename: "SiteOrganisation",
    id: string,
    site:  {
      __typename: "Site",
      id: string,
      name: string,
      organisations:  {
        __typename: "ModelSiteOrganisationConnection",
        nextToken: string | null,
      } | null,
      amhpTeams:  {
        __typename: "ModelAmhpTeamConnection",
        nextToken: string | null,
      } | null,
      doctors:  {
        __typename: "ModelS12DoctorConnection",
        nextToken: string | null,
      } | null,
      doctorReports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      summaryReports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
    },
    organisationType: string | null,
    organisation:  {
      __typename: "Organisation",
      id: string,
      name: string,
      featureFlags: string | null,
      features: string | null,
      type: OrgType,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      } | null,
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      phone: string | null,
      email: string | null,
      users:  {
        __typename: "ModelOrganisationUserConnection",
        nextToken: string | null,
      } | null,
      sites:  {
        __typename: "ModelSiteOrganisationConnection",
        nextToken: string | null,
      } | null,
      ccg:  {
        __typename: "CCG",
        id: string,
        claimProcessorType: OrgType | null,
        cCGOrganisationId: string,
      } | null,
      mht:  {
        __typename: "MHT",
        id: string,
        abbreviation: string,
        mHTOrganisationId: string,
      } | null,
      claims:  {
        __typename: "ModelClaimConnection",
        nextToken: string | null,
      } | null,
      expensesInstruction: string | null,
      declarationText: string | null,
      additionalNotesText: string | null,
      paymentRates:  Array< {
        __typename: "PaymentRates",
        description: string | null,
        assessmentFee: number,
        mileage: number,
      } | null > | null,
      invoiceEmail: string | null,
      updatedBy: string | null,
      mhtHospitalEmails:  Array< {
        __typename: "HospitalEmails",
        name: string,
      } | null > | null,
      rotas:  Array< {
        __typename: "RotaItem",
        id: string,
        name: string,
      } | null > | null,
      deleted: boolean | null,
    },
  } | null,
};

export type CreateAmhpTeamMutationVariables = {
  input: CreateAmhpTeamInput,
};

export type CreateAmhpTeamMutation = {
  createAmhpTeam:  {
    __typename: "AmhpTeam",
    id: string,
    name: string,
    featureFlags: string | null,
    features: string | null,
    assessments:  {
      __typename: "ModelAssessmentConnection",
      items:  Array< {
        __typename: "Assessment",
        id: string,
        createdAt: string,
        amhpTeamId: string,
        amhpId: string,
        patientName: string,
        ccgId: string | null,
        ccgDetermination: LocationType | null,
        hadVisitAdded: boolean | null,
        patientNhsNumber: string | null,
        assessmentDate: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    users:  {
      __typename: "ModelAmhpTeamUserConnection",
      items:  Array< {
        __typename: "AmhpTeamUser",
        id: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
    commonAddresses:  {
      __typename: "ModelAddressConnection",
      items:  Array< {
        __typename: "Address",
        id: string,
        amhpTeamId: string,
        ccgId: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
    localAuthority: string | null,
    hospitalEmails:  Array< {
      __typename: "HospitalEmails",
      name: string,
      wards:  Array< {
        __typename: "EmailAddress",
        name: string,
        email: string,
      } >,
    } > | null,
    mhaEmailAddresses:  Array< {
      __typename: "RecipientEmails",
      name: string | null,
      recipients:  Array< {
        __typename: "RecipientEmailAddress",
        name: string | null,
        email: string | null,
      } | null > | null,
    } | null > | null,
    emails: Array< string > | null,
    phoneNumbers: Array< string > | null,
    mhaInboxInstructions: string | null,
    reports:  {
      __typename: "ModelReportingConnection",
      items:  Array< {
        __typename: "Reporting",
        id: string,
        pk: string,
        sk: string,
        ttl: number | null,
        gsi1pk: string | null,
        gsi1sk: string | null,
        gsi2pk: string | null,
        gsi2sk: string | null,
        gsi3pk: string | null,
        gsi3sk: string | null,
        eventName: string,
        userId: string,
        timestamp: string,
        period: string,
        count: number | null,
        siteId: string | null,
        teamId: string | null,
        orgId: string | null,
        amhpId: string | null,
        doctorId: string | null,
        claimProcessorId: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    summaryReports:  {
      __typename: "ModelReportingConnection",
      items:  Array< {
        __typename: "Reporting",
        id: string,
        pk: string,
        sk: string,
        ttl: number | null,
        gsi1pk: string | null,
        gsi1sk: string | null,
        gsi2pk: string | null,
        gsi2sk: string | null,
        gsi3pk: string | null,
        gsi3sk: string | null,
        eventName: string,
        userId: string,
        timestamp: string,
        period: string,
        count: number | null,
        siteId: string | null,
        teamId: string | null,
        orgId: string | null,
        amhpId: string | null,
        doctorId: string | null,
        claimProcessorId: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    updatedBy: string | null,
  } | null,
};

export type UpdateAmhpTeamMutationVariables = {
  input: UpdateAmhpTeamInput,
};

export type UpdateAmhpTeamMutation = {
  updateAmhpTeam:  {
    __typename: "AmhpTeam",
    id: string,
    name: string,
    featureFlags: string | null,
    features: string | null,
    assessments:  {
      __typename: "ModelAssessmentConnection",
      items:  Array< {
        __typename: "Assessment",
        id: string,
        createdAt: string,
        amhpTeamId: string,
        amhpId: string,
        patientName: string,
        ccgId: string | null,
        ccgDetermination: LocationType | null,
        hadVisitAdded: boolean | null,
        patientNhsNumber: string | null,
        assessmentDate: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    users:  {
      __typename: "ModelAmhpTeamUserConnection",
      items:  Array< {
        __typename: "AmhpTeamUser",
        id: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
    commonAddresses:  {
      __typename: "ModelAddressConnection",
      items:  Array< {
        __typename: "Address",
        id: string,
        amhpTeamId: string,
        ccgId: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
    localAuthority: string | null,
    hospitalEmails:  Array< {
      __typename: "HospitalEmails",
      name: string,
      wards:  Array< {
        __typename: "EmailAddress",
        name: string,
        email: string,
      } >,
    } > | null,
    mhaEmailAddresses:  Array< {
      __typename: "RecipientEmails",
      name: string | null,
      recipients:  Array< {
        __typename: "RecipientEmailAddress",
        name: string | null,
        email: string | null,
      } | null > | null,
    } | null > | null,
    emails: Array< string > | null,
    phoneNumbers: Array< string > | null,
    mhaInboxInstructions: string | null,
    reports:  {
      __typename: "ModelReportingConnection",
      items:  Array< {
        __typename: "Reporting",
        id: string,
        pk: string,
        sk: string,
        ttl: number | null,
        gsi1pk: string | null,
        gsi1sk: string | null,
        gsi2pk: string | null,
        gsi2sk: string | null,
        gsi3pk: string | null,
        gsi3sk: string | null,
        eventName: string,
        userId: string,
        timestamp: string,
        period: string,
        count: number | null,
        siteId: string | null,
        teamId: string | null,
        orgId: string | null,
        amhpId: string | null,
        doctorId: string | null,
        claimProcessorId: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    summaryReports:  {
      __typename: "ModelReportingConnection",
      items:  Array< {
        __typename: "Reporting",
        id: string,
        pk: string,
        sk: string,
        ttl: number | null,
        gsi1pk: string | null,
        gsi1sk: string | null,
        gsi2pk: string | null,
        gsi2sk: string | null,
        gsi3pk: string | null,
        gsi3sk: string | null,
        eventName: string,
        userId: string,
        timestamp: string,
        period: string,
        count: number | null,
        siteId: string | null,
        teamId: string | null,
        orgId: string | null,
        amhpId: string | null,
        doctorId: string | null,
        claimProcessorId: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    updatedBy: string | null,
  } | null,
};

export type DeleteAmhpTeamMutationVariables = {
  input: DeleteAmhpTeamInput,
};

export type DeleteAmhpTeamMutation = {
  deleteAmhpTeam:  {
    __typename: "AmhpTeam",
    id: string,
    name: string,
    featureFlags: string | null,
    features: string | null,
    assessments:  {
      __typename: "ModelAssessmentConnection",
      items:  Array< {
        __typename: "Assessment",
        id: string,
        createdAt: string,
        amhpTeamId: string,
        amhpId: string,
        patientName: string,
        ccgId: string | null,
        ccgDetermination: LocationType | null,
        hadVisitAdded: boolean | null,
        patientNhsNumber: string | null,
        assessmentDate: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    users:  {
      __typename: "ModelAmhpTeamUserConnection",
      items:  Array< {
        __typename: "AmhpTeamUser",
        id: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
    commonAddresses:  {
      __typename: "ModelAddressConnection",
      items:  Array< {
        __typename: "Address",
        id: string,
        amhpTeamId: string,
        ccgId: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
    localAuthority: string | null,
    hospitalEmails:  Array< {
      __typename: "HospitalEmails",
      name: string,
      wards:  Array< {
        __typename: "EmailAddress",
        name: string,
        email: string,
      } >,
    } > | null,
    mhaEmailAddresses:  Array< {
      __typename: "RecipientEmails",
      name: string | null,
      recipients:  Array< {
        __typename: "RecipientEmailAddress",
        name: string | null,
        email: string | null,
      } | null > | null,
    } | null > | null,
    emails: Array< string > | null,
    phoneNumbers: Array< string > | null,
    mhaInboxInstructions: string | null,
    reports:  {
      __typename: "ModelReportingConnection",
      items:  Array< {
        __typename: "Reporting",
        id: string,
        pk: string,
        sk: string,
        ttl: number | null,
        gsi1pk: string | null,
        gsi1sk: string | null,
        gsi2pk: string | null,
        gsi2sk: string | null,
        gsi3pk: string | null,
        gsi3sk: string | null,
        eventName: string,
        userId: string,
        timestamp: string,
        period: string,
        count: number | null,
        siteId: string | null,
        teamId: string | null,
        orgId: string | null,
        amhpId: string | null,
        doctorId: string | null,
        claimProcessorId: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    summaryReports:  {
      __typename: "ModelReportingConnection",
      items:  Array< {
        __typename: "Reporting",
        id: string,
        pk: string,
        sk: string,
        ttl: number | null,
        gsi1pk: string | null,
        gsi1sk: string | null,
        gsi2pk: string | null,
        gsi2sk: string | null,
        gsi3pk: string | null,
        gsi3sk: string | null,
        eventName: string,
        userId: string,
        timestamp: string,
        period: string,
        count: number | null,
        siteId: string | null,
        teamId: string | null,
        orgId: string | null,
        amhpId: string | null,
        doctorId: string | null,
        claimProcessorId: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    updatedBy: string | null,
  } | null,
};

export type CreateAddressMutationVariables = {
  input: CreateAddressInput,
};

export type CreateAddressMutation = {
  createAddress:  {
    __typename: "Address",
    id: string,
    amhpTeamId: string,
    locationName:  {
      __typename: "LocationName",
      city: string | null,
      postcode: string,
      address: string | null,
      addressNotes: string | null,
    },
    location:  {
      __typename: "Location",
      lat: number,
      lon: number,
    },
    ccgId: string,
    ccg:  {
      __typename: "Organisation",
      id: string,
      name: string,
      featureFlags: string | null,
      features: string | null,
      type: OrgType,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      } | null,
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      phone: string | null,
      email: string | null,
      users:  {
        __typename: "ModelOrganisationUserConnection",
        nextToken: string | null,
      } | null,
      sites:  {
        __typename: "ModelSiteOrganisationConnection",
        nextToken: string | null,
      } | null,
      ccg:  {
        __typename: "CCG",
        id: string,
        claimProcessorType: OrgType | null,
        cCGOrganisationId: string,
      } | null,
      mht:  {
        __typename: "MHT",
        id: string,
        abbreviation: string,
        mHTOrganisationId: string,
      } | null,
      claims:  {
        __typename: "ModelClaimConnection",
        nextToken: string | null,
      } | null,
      expensesInstruction: string | null,
      declarationText: string | null,
      additionalNotesText: string | null,
      paymentRates:  Array< {
        __typename: "PaymentRates",
        description: string | null,
        assessmentFee: number,
        mileage: number,
      } | null > | null,
      invoiceEmail: string | null,
      updatedBy: string | null,
      mhtHospitalEmails:  Array< {
        __typename: "HospitalEmails",
        name: string,
      } | null > | null,
      rotas:  Array< {
        __typename: "RotaItem",
        id: string,
        name: string,
      } | null > | null,
      deleted: boolean | null,
    } | null,
  } | null,
};

export type UpdateAddressMutationVariables = {
  input: UpdateAddressInput,
};

export type UpdateAddressMutation = {
  updateAddress:  {
    __typename: "Address",
    id: string,
    amhpTeamId: string,
    locationName:  {
      __typename: "LocationName",
      city: string | null,
      postcode: string,
      address: string | null,
      addressNotes: string | null,
    },
    location:  {
      __typename: "Location",
      lat: number,
      lon: number,
    },
    ccgId: string,
    ccg:  {
      __typename: "Organisation",
      id: string,
      name: string,
      featureFlags: string | null,
      features: string | null,
      type: OrgType,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      } | null,
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      phone: string | null,
      email: string | null,
      users:  {
        __typename: "ModelOrganisationUserConnection",
        nextToken: string | null,
      } | null,
      sites:  {
        __typename: "ModelSiteOrganisationConnection",
        nextToken: string | null,
      } | null,
      ccg:  {
        __typename: "CCG",
        id: string,
        claimProcessorType: OrgType | null,
        cCGOrganisationId: string,
      } | null,
      mht:  {
        __typename: "MHT",
        id: string,
        abbreviation: string,
        mHTOrganisationId: string,
      } | null,
      claims:  {
        __typename: "ModelClaimConnection",
        nextToken: string | null,
      } | null,
      expensesInstruction: string | null,
      declarationText: string | null,
      additionalNotesText: string | null,
      paymentRates:  Array< {
        __typename: "PaymentRates",
        description: string | null,
        assessmentFee: number,
        mileage: number,
      } | null > | null,
      invoiceEmail: string | null,
      updatedBy: string | null,
      mhtHospitalEmails:  Array< {
        __typename: "HospitalEmails",
        name: string,
      } | null > | null,
      rotas:  Array< {
        __typename: "RotaItem",
        id: string,
        name: string,
      } | null > | null,
      deleted: boolean | null,
    } | null,
  } | null,
};

export type DeleteAddressMutationVariables = {
  input: DeleteAddressInput,
};

export type DeleteAddressMutation = {
  deleteAddress:  {
    __typename: "Address",
    id: string,
    amhpTeamId: string,
    locationName:  {
      __typename: "LocationName",
      city: string | null,
      postcode: string,
      address: string | null,
      addressNotes: string | null,
    },
    location:  {
      __typename: "Location",
      lat: number,
      lon: number,
    },
    ccgId: string,
    ccg:  {
      __typename: "Organisation",
      id: string,
      name: string,
      featureFlags: string | null,
      features: string | null,
      type: OrgType,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      } | null,
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      phone: string | null,
      email: string | null,
      users:  {
        __typename: "ModelOrganisationUserConnection",
        nextToken: string | null,
      } | null,
      sites:  {
        __typename: "ModelSiteOrganisationConnection",
        nextToken: string | null,
      } | null,
      ccg:  {
        __typename: "CCG",
        id: string,
        claimProcessorType: OrgType | null,
        cCGOrganisationId: string,
      } | null,
      mht:  {
        __typename: "MHT",
        id: string,
        abbreviation: string,
        mHTOrganisationId: string,
      } | null,
      claims:  {
        __typename: "ModelClaimConnection",
        nextToken: string | null,
      } | null,
      expensesInstruction: string | null,
      declarationText: string | null,
      additionalNotesText: string | null,
      paymentRates:  Array< {
        __typename: "PaymentRates",
        description: string | null,
        assessmentFee: number,
        mileage: number,
      } | null > | null,
      invoiceEmail: string | null,
      updatedBy: string | null,
      mhtHospitalEmails:  Array< {
        __typename: "HospitalEmails",
        name: string,
      } | null > | null,
      rotas:  Array< {
        __typename: "RotaItem",
        id: string,
        name: string,
      } | null > | null,
      deleted: boolean | null,
    } | null,
  } | null,
};

export type CreateAssessmentMutationVariables = {
  input: CreateAssessmentInput,
};

export type CreateAssessmentMutation = {
  createAssessment:  {
    __typename: "Assessment",
    id: string,
    createdAt: string,
    amhpTeamId: string,
    amhpTeam:  {
      __typename: "AmhpTeam",
      id: string,
      name: string,
      featureFlags: string | null,
      features: string | null,
      assessments:  {
        __typename: "ModelAssessmentConnection",
        nextToken: string | null,
      } | null,
      users:  {
        __typename: "ModelAmhpTeamUserConnection",
        nextToken: string | null,
      } | null,
      commonAddresses:  {
        __typename: "ModelAddressConnection",
        nextToken: string | null,
      } | null,
      localAuthority: string | null,
      hospitalEmails:  Array< {
        __typename: "HospitalEmails",
        name: string,
      } > | null,
      mhaEmailAddresses:  Array< {
        __typename: "RecipientEmails",
        name: string | null,
      } | null > | null,
      emails: Array< string > | null,
      phoneNumbers: Array< string > | null,
      mhaInboxInstructions: string | null,
      reports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      summaryReports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      updatedBy: string | null,
    },
    amhpId: string,
    amhp:  {
      __typename: "AmhpProfile",
      id: string,
      email: string,
      phone: string | null,
      name: string,
      legalName: string | null,
      deleted: boolean | null,
      warrantInfo: string | null,
      accreditation: string | null,
      sectionFormAddress:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      sectionFormEmailAddress: string | null,
      sectionFormContactNumber: string | null,
      assessments:  {
        __typename: "ModelAssessmentConnection",
        nextToken: string | null,
      } | null,
      pushTokens: Array< string | null > | null,
      teams:  {
        __typename: "ModelAmhpTeamUserConnection",
        nextToken: string | null,
      } | null,
      lastActivity: string | null,
      createdAt: string | null,
      reports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      summaryReports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
    },
    patientName: string,
    location:  {
      __typename: "Location",
      lat: number,
      lon: number,
    },
    ccgId: string | null,
    ccg:  {
      __typename: "Organisation",
      id: string,
      name: string,
      featureFlags: string | null,
      features: string | null,
      type: OrgType,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      } | null,
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      phone: string | null,
      email: string | null,
      users:  {
        __typename: "ModelOrganisationUserConnection",
        nextToken: string | null,
      } | null,
      sites:  {
        __typename: "ModelSiteOrganisationConnection",
        nextToken: string | null,
      } | null,
      ccg:  {
        __typename: "CCG",
        id: string,
        claimProcessorType: OrgType | null,
        cCGOrganisationId: string,
      } | null,
      mht:  {
        __typename: "MHT",
        id: string,
        abbreviation: string,
        mHTOrganisationId: string,
      } | null,
      claims:  {
        __typename: "ModelClaimConnection",
        nextToken: string | null,
      } | null,
      expensesInstruction: string | null,
      declarationText: string | null,
      additionalNotesText: string | null,
      paymentRates:  Array< {
        __typename: "PaymentRates",
        description: string | null,
        assessmentFee: number,
        mileage: number,
      } | null > | null,
      invoiceEmail: string | null,
      updatedBy: string | null,
      mhtHospitalEmails:  Array< {
        __typename: "HospitalEmails",
        name: string,
      } | null > | null,
      rotas:  Array< {
        __typename: "RotaItem",
        id: string,
        name: string,
      } | null > | null,
      deleted: boolean | null,
    } | null,
    ccgDetermination: LocationType | null,
    locationName:  {
      __typename: "LocationName",
      city: string | null,
      postcode: string,
      address: string | null,
      addressNotes: string | null,
    },
    hadVisitAdded: boolean | null,
    doctorVisits:  {
      __typename: "ModelDoctorVisitConnection",
      items:  Array< {
        __typename: "DoctorVisit",
        id: string,
        createdAt: string | null,
        assessmentId: string,
        time: string,
        teamId: string,
        doctorVisitDoctorId: string,
        notes: Array< string | null > | null,
        partialPostcode: string | null,
        doctorVisitClaimId: string | null,
        claimDisplayId: string | null,
        claimStatus: ClaimStatus | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    patientNhsNumber: string | null,
    assessmentDate: string | null,
    contactedDoctors:  {
      __typename: "ModelContactedDoctorConnection",
      items:  Array< {
        __typename: "ContactedDoctor",
        id: string,
        assessmentId: string,
        contactedDoctorS12DoctorId: string,
        createdAt: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
    forms:  {
      __typename: "ModelFormConnection",
      items:  Array< {
        __typename: "Form",
        id: string,
        sk: string,
        ttl: number,
        version: number,
        gsi1pk: string,
        gsi1sk: string,
        gsi2pk: string,
        gsi2sk: string,
        gsi3pk: string,
        gsi3sk: string,
        assessmentId: string,
        status: FormStatus,
        createdBy: string,
        createdAt: string,
        updatedAt: string,
        updatedBy: string,
        amhpTeamInboxId: string,
        amhpTeamId: string,
        authors: Array< string >,
        doctorNames: Array< string > | null,
        signed: Array< string > | null,
        signedClaims: Array< string > | null,
        sha256: string | null,
        notes: Array< string >,
        type: string,
        isDeleted: boolean | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    patientAddress:  {
      __typename: "LocationName",
      city: string | null,
      postcode: string,
      address: string | null,
      addressNotes: string | null,
    } | null,
  } | null,
};

export type UpdateAssessmentMutationVariables = {
  input: UpdateAssessmentInput,
};

export type UpdateAssessmentMutation = {
  updateAssessment:  {
    __typename: "Assessment",
    id: string,
    createdAt: string,
    amhpTeamId: string,
    amhpTeam:  {
      __typename: "AmhpTeam",
      id: string,
      name: string,
      featureFlags: string | null,
      features: string | null,
      assessments:  {
        __typename: "ModelAssessmentConnection",
        nextToken: string | null,
      } | null,
      users:  {
        __typename: "ModelAmhpTeamUserConnection",
        nextToken: string | null,
      } | null,
      commonAddresses:  {
        __typename: "ModelAddressConnection",
        nextToken: string | null,
      } | null,
      localAuthority: string | null,
      hospitalEmails:  Array< {
        __typename: "HospitalEmails",
        name: string,
      } > | null,
      mhaEmailAddresses:  Array< {
        __typename: "RecipientEmails",
        name: string | null,
      } | null > | null,
      emails: Array< string > | null,
      phoneNumbers: Array< string > | null,
      mhaInboxInstructions: string | null,
      reports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      summaryReports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      updatedBy: string | null,
    },
    amhpId: string,
    amhp:  {
      __typename: "AmhpProfile",
      id: string,
      email: string,
      phone: string | null,
      name: string,
      legalName: string | null,
      deleted: boolean | null,
      warrantInfo: string | null,
      accreditation: string | null,
      sectionFormAddress:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      sectionFormEmailAddress: string | null,
      sectionFormContactNumber: string | null,
      assessments:  {
        __typename: "ModelAssessmentConnection",
        nextToken: string | null,
      } | null,
      pushTokens: Array< string | null > | null,
      teams:  {
        __typename: "ModelAmhpTeamUserConnection",
        nextToken: string | null,
      } | null,
      lastActivity: string | null,
      createdAt: string | null,
      reports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      summaryReports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
    },
    patientName: string,
    location:  {
      __typename: "Location",
      lat: number,
      lon: number,
    },
    ccgId: string | null,
    ccg:  {
      __typename: "Organisation",
      id: string,
      name: string,
      featureFlags: string | null,
      features: string | null,
      type: OrgType,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      } | null,
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      phone: string | null,
      email: string | null,
      users:  {
        __typename: "ModelOrganisationUserConnection",
        nextToken: string | null,
      } | null,
      sites:  {
        __typename: "ModelSiteOrganisationConnection",
        nextToken: string | null,
      } | null,
      ccg:  {
        __typename: "CCG",
        id: string,
        claimProcessorType: OrgType | null,
        cCGOrganisationId: string,
      } | null,
      mht:  {
        __typename: "MHT",
        id: string,
        abbreviation: string,
        mHTOrganisationId: string,
      } | null,
      claims:  {
        __typename: "ModelClaimConnection",
        nextToken: string | null,
      } | null,
      expensesInstruction: string | null,
      declarationText: string | null,
      additionalNotesText: string | null,
      paymentRates:  Array< {
        __typename: "PaymentRates",
        description: string | null,
        assessmentFee: number,
        mileage: number,
      } | null > | null,
      invoiceEmail: string | null,
      updatedBy: string | null,
      mhtHospitalEmails:  Array< {
        __typename: "HospitalEmails",
        name: string,
      } | null > | null,
      rotas:  Array< {
        __typename: "RotaItem",
        id: string,
        name: string,
      } | null > | null,
      deleted: boolean | null,
    } | null,
    ccgDetermination: LocationType | null,
    locationName:  {
      __typename: "LocationName",
      city: string | null,
      postcode: string,
      address: string | null,
      addressNotes: string | null,
    },
    hadVisitAdded: boolean | null,
    doctorVisits:  {
      __typename: "ModelDoctorVisitConnection",
      items:  Array< {
        __typename: "DoctorVisit",
        id: string,
        createdAt: string | null,
        assessmentId: string,
        time: string,
        teamId: string,
        doctorVisitDoctorId: string,
        notes: Array< string | null > | null,
        partialPostcode: string | null,
        doctorVisitClaimId: string | null,
        claimDisplayId: string | null,
        claimStatus: ClaimStatus | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    patientNhsNumber: string | null,
    assessmentDate: string | null,
    contactedDoctors:  {
      __typename: "ModelContactedDoctorConnection",
      items:  Array< {
        __typename: "ContactedDoctor",
        id: string,
        assessmentId: string,
        contactedDoctorS12DoctorId: string,
        createdAt: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
    forms:  {
      __typename: "ModelFormConnection",
      items:  Array< {
        __typename: "Form",
        id: string,
        sk: string,
        ttl: number,
        version: number,
        gsi1pk: string,
        gsi1sk: string,
        gsi2pk: string,
        gsi2sk: string,
        gsi3pk: string,
        gsi3sk: string,
        assessmentId: string,
        status: FormStatus,
        createdBy: string,
        createdAt: string,
        updatedAt: string,
        updatedBy: string,
        amhpTeamInboxId: string,
        amhpTeamId: string,
        authors: Array< string >,
        doctorNames: Array< string > | null,
        signed: Array< string > | null,
        signedClaims: Array< string > | null,
        sha256: string | null,
        notes: Array< string >,
        type: string,
        isDeleted: boolean | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    patientAddress:  {
      __typename: "LocationName",
      city: string | null,
      postcode: string,
      address: string | null,
      addressNotes: string | null,
    } | null,
  } | null,
};

export type DeleteAssessmentMutationVariables = {
  input: DeleteAssessmentInput,
};

export type DeleteAssessmentMutation = {
  deleteAssessment:  {
    __typename: "Assessment",
    id: string,
    createdAt: string,
    amhpTeamId: string,
    amhpTeam:  {
      __typename: "AmhpTeam",
      id: string,
      name: string,
      featureFlags: string | null,
      features: string | null,
      assessments:  {
        __typename: "ModelAssessmentConnection",
        nextToken: string | null,
      } | null,
      users:  {
        __typename: "ModelAmhpTeamUserConnection",
        nextToken: string | null,
      } | null,
      commonAddresses:  {
        __typename: "ModelAddressConnection",
        nextToken: string | null,
      } | null,
      localAuthority: string | null,
      hospitalEmails:  Array< {
        __typename: "HospitalEmails",
        name: string,
      } > | null,
      mhaEmailAddresses:  Array< {
        __typename: "RecipientEmails",
        name: string | null,
      } | null > | null,
      emails: Array< string > | null,
      phoneNumbers: Array< string > | null,
      mhaInboxInstructions: string | null,
      reports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      summaryReports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      updatedBy: string | null,
    },
    amhpId: string,
    amhp:  {
      __typename: "AmhpProfile",
      id: string,
      email: string,
      phone: string | null,
      name: string,
      legalName: string | null,
      deleted: boolean | null,
      warrantInfo: string | null,
      accreditation: string | null,
      sectionFormAddress:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      sectionFormEmailAddress: string | null,
      sectionFormContactNumber: string | null,
      assessments:  {
        __typename: "ModelAssessmentConnection",
        nextToken: string | null,
      } | null,
      pushTokens: Array< string | null > | null,
      teams:  {
        __typename: "ModelAmhpTeamUserConnection",
        nextToken: string | null,
      } | null,
      lastActivity: string | null,
      createdAt: string | null,
      reports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      summaryReports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
    },
    patientName: string,
    location:  {
      __typename: "Location",
      lat: number,
      lon: number,
    },
    ccgId: string | null,
    ccg:  {
      __typename: "Organisation",
      id: string,
      name: string,
      featureFlags: string | null,
      features: string | null,
      type: OrgType,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      } | null,
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      phone: string | null,
      email: string | null,
      users:  {
        __typename: "ModelOrganisationUserConnection",
        nextToken: string | null,
      } | null,
      sites:  {
        __typename: "ModelSiteOrganisationConnection",
        nextToken: string | null,
      } | null,
      ccg:  {
        __typename: "CCG",
        id: string,
        claimProcessorType: OrgType | null,
        cCGOrganisationId: string,
      } | null,
      mht:  {
        __typename: "MHT",
        id: string,
        abbreviation: string,
        mHTOrganisationId: string,
      } | null,
      claims:  {
        __typename: "ModelClaimConnection",
        nextToken: string | null,
      } | null,
      expensesInstruction: string | null,
      declarationText: string | null,
      additionalNotesText: string | null,
      paymentRates:  Array< {
        __typename: "PaymentRates",
        description: string | null,
        assessmentFee: number,
        mileage: number,
      } | null > | null,
      invoiceEmail: string | null,
      updatedBy: string | null,
      mhtHospitalEmails:  Array< {
        __typename: "HospitalEmails",
        name: string,
      } | null > | null,
      rotas:  Array< {
        __typename: "RotaItem",
        id: string,
        name: string,
      } | null > | null,
      deleted: boolean | null,
    } | null,
    ccgDetermination: LocationType | null,
    locationName:  {
      __typename: "LocationName",
      city: string | null,
      postcode: string,
      address: string | null,
      addressNotes: string | null,
    },
    hadVisitAdded: boolean | null,
    doctorVisits:  {
      __typename: "ModelDoctorVisitConnection",
      items:  Array< {
        __typename: "DoctorVisit",
        id: string,
        createdAt: string | null,
        assessmentId: string,
        time: string,
        teamId: string,
        doctorVisitDoctorId: string,
        notes: Array< string | null > | null,
        partialPostcode: string | null,
        doctorVisitClaimId: string | null,
        claimDisplayId: string | null,
        claimStatus: ClaimStatus | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    patientNhsNumber: string | null,
    assessmentDate: string | null,
    contactedDoctors:  {
      __typename: "ModelContactedDoctorConnection",
      items:  Array< {
        __typename: "ContactedDoctor",
        id: string,
        assessmentId: string,
        contactedDoctorS12DoctorId: string,
        createdAt: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
    forms:  {
      __typename: "ModelFormConnection",
      items:  Array< {
        __typename: "Form",
        id: string,
        sk: string,
        ttl: number,
        version: number,
        gsi1pk: string,
        gsi1sk: string,
        gsi2pk: string,
        gsi2sk: string,
        gsi3pk: string,
        gsi3sk: string,
        assessmentId: string,
        status: FormStatus,
        createdBy: string,
        createdAt: string,
        updatedAt: string,
        updatedBy: string,
        amhpTeamInboxId: string,
        amhpTeamId: string,
        authors: Array< string >,
        doctorNames: Array< string > | null,
        signed: Array< string > | null,
        signedClaims: Array< string > | null,
        sha256: string | null,
        notes: Array< string >,
        type: string,
        isDeleted: boolean | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    patientAddress:  {
      __typename: "LocationName",
      city: string | null,
      postcode: string,
      address: string | null,
      addressNotes: string | null,
    } | null,
  } | null,
};

export type CreateContactedDoctorMutationVariables = {
  input: CreateContactedDoctorInput,
};

export type CreateContactedDoctorMutation = {
  createContactedDoctor:  {
    __typename: "ContactedDoctor",
    id: string,
    assessmentId: string,
    contactedDoctorS12DoctorId: string,
    assessment:  {
      __typename: "Assessment",
      id: string,
      createdAt: string,
      amhpTeamId: string,
      amhpTeam:  {
        __typename: "AmhpTeam",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        localAuthority: string | null,
        emails: Array< string > | null,
        phoneNumbers: Array< string > | null,
        mhaInboxInstructions: string | null,
        updatedBy: string | null,
      },
      amhpId: string,
      amhp:  {
        __typename: "AmhpProfile",
        id: string,
        email: string,
        phone: string | null,
        name: string,
        legalName: string | null,
        deleted: boolean | null,
        warrantInfo: string | null,
        accreditation: string | null,
        sectionFormEmailAddress: string | null,
        sectionFormContactNumber: string | null,
        pushTokens: Array< string | null > | null,
        lastActivity: string | null,
        createdAt: string | null,
      },
      patientName: string,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      },
      ccgId: string | null,
      ccg:  {
        __typename: "Organisation",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        type: OrgType,
        phone: string | null,
        email: string | null,
        expensesInstruction: string | null,
        declarationText: string | null,
        additionalNotesText: string | null,
        invoiceEmail: string | null,
        updatedBy: string | null,
        deleted: boolean | null,
      } | null,
      ccgDetermination: LocationType | null,
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      },
      hadVisitAdded: boolean | null,
      doctorVisits:  {
        __typename: "ModelDoctorVisitConnection",
        nextToken: string | null,
      } | null,
      patientNhsNumber: string | null,
      assessmentDate: string | null,
      contactedDoctors:  {
        __typename: "ModelContactedDoctorConnection",
        nextToken: string | null,
      } | null,
      forms:  {
        __typename: "ModelFormConnection",
        nextToken: string | null,
      } | null,
      patientAddress:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
    },
    s12Doctor:  {
      __typename: "S12Doctor",
      id: string,
      gmc: string,
      s12Expiry: string | null,
      name: string,
      legalName: string,
      phone: string | null,
      phone2: string | null,
      email: string,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      },
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      },
      gl: boolean | null,
      employer: string | null,
      jobTitle: string | null,
      responsibleOfficer: string | null,
      lineManager: string | null,
      defaultMHT: string | null,
      mhtEmployers:  {
        __typename: "ModelMHTS12DoctorConnection",
        nextToken: string | null,
      } | null,
      availabilities:  {
        __typename: "ModelAvailabilityConnection",
        nextToken: string | null,
      } | null,
      holidays:  {
        __typename: "ModelHolidayConnection",
        nextToken: string | null,
      } | null,
      specialties: Array< string | null >,
      languages: Array< string | null >,
      gender: Gender | null,
      notes: string | null,
      pushTokens: Array< string | null > | null,
      distance: number | null,
      hideFromSearch: boolean | null,
      visits:  {
        __typename: "ModelDoctorVisitConnection",
        nextToken: string | null,
      } | null,
      doctors:  {
        __typename: "ModelContactedDoctorConnection",
        nextToken: string | null,
      } | null,
      claims:  {
        __typename: "ModelClaimConnection",
        nextToken: string | null,
      } | null,
      availabilityLocations:  Array< {
        __typename: "Location",
        lat: number,
        lon: number,
      } | null > | null,
      availabilityPostcodes: Array< string | null > | null,
      siteName: string,
      claimData:  {
        __typename: "ClaimData",
        billingCompanyName: string | null,
        vatRegistration: string | null,
        fuelType: FuelType | null,
        engineSize: Engine | null,
      } | null,
      sectionFormAddress:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      sectionFormEmailAddress: string | null,
      reports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      summaryReports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      createdAt: string | null,
      lastActivity: string | null,
      isOutsideEngland: boolean | null,
    },
    createdAt: string,
  } | null,
};

export type UpdateContactedDoctorMutationVariables = {
  input: UpdateContactedDoctorInput,
};

export type UpdateContactedDoctorMutation = {
  updateContactedDoctor:  {
    __typename: "ContactedDoctor",
    id: string,
    assessmentId: string,
    contactedDoctorS12DoctorId: string,
    assessment:  {
      __typename: "Assessment",
      id: string,
      createdAt: string,
      amhpTeamId: string,
      amhpTeam:  {
        __typename: "AmhpTeam",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        localAuthority: string | null,
        emails: Array< string > | null,
        phoneNumbers: Array< string > | null,
        mhaInboxInstructions: string | null,
        updatedBy: string | null,
      },
      amhpId: string,
      amhp:  {
        __typename: "AmhpProfile",
        id: string,
        email: string,
        phone: string | null,
        name: string,
        legalName: string | null,
        deleted: boolean | null,
        warrantInfo: string | null,
        accreditation: string | null,
        sectionFormEmailAddress: string | null,
        sectionFormContactNumber: string | null,
        pushTokens: Array< string | null > | null,
        lastActivity: string | null,
        createdAt: string | null,
      },
      patientName: string,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      },
      ccgId: string | null,
      ccg:  {
        __typename: "Organisation",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        type: OrgType,
        phone: string | null,
        email: string | null,
        expensesInstruction: string | null,
        declarationText: string | null,
        additionalNotesText: string | null,
        invoiceEmail: string | null,
        updatedBy: string | null,
        deleted: boolean | null,
      } | null,
      ccgDetermination: LocationType | null,
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      },
      hadVisitAdded: boolean | null,
      doctorVisits:  {
        __typename: "ModelDoctorVisitConnection",
        nextToken: string | null,
      } | null,
      patientNhsNumber: string | null,
      assessmentDate: string | null,
      contactedDoctors:  {
        __typename: "ModelContactedDoctorConnection",
        nextToken: string | null,
      } | null,
      forms:  {
        __typename: "ModelFormConnection",
        nextToken: string | null,
      } | null,
      patientAddress:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
    },
    s12Doctor:  {
      __typename: "S12Doctor",
      id: string,
      gmc: string,
      s12Expiry: string | null,
      name: string,
      legalName: string,
      phone: string | null,
      phone2: string | null,
      email: string,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      },
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      },
      gl: boolean | null,
      employer: string | null,
      jobTitle: string | null,
      responsibleOfficer: string | null,
      lineManager: string | null,
      defaultMHT: string | null,
      mhtEmployers:  {
        __typename: "ModelMHTS12DoctorConnection",
        nextToken: string | null,
      } | null,
      availabilities:  {
        __typename: "ModelAvailabilityConnection",
        nextToken: string | null,
      } | null,
      holidays:  {
        __typename: "ModelHolidayConnection",
        nextToken: string | null,
      } | null,
      specialties: Array< string | null >,
      languages: Array< string | null >,
      gender: Gender | null,
      notes: string | null,
      pushTokens: Array< string | null > | null,
      distance: number | null,
      hideFromSearch: boolean | null,
      visits:  {
        __typename: "ModelDoctorVisitConnection",
        nextToken: string | null,
      } | null,
      doctors:  {
        __typename: "ModelContactedDoctorConnection",
        nextToken: string | null,
      } | null,
      claims:  {
        __typename: "ModelClaimConnection",
        nextToken: string | null,
      } | null,
      availabilityLocations:  Array< {
        __typename: "Location",
        lat: number,
        lon: number,
      } | null > | null,
      availabilityPostcodes: Array< string | null > | null,
      siteName: string,
      claimData:  {
        __typename: "ClaimData",
        billingCompanyName: string | null,
        vatRegistration: string | null,
        fuelType: FuelType | null,
        engineSize: Engine | null,
      } | null,
      sectionFormAddress:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      sectionFormEmailAddress: string | null,
      reports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      summaryReports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      createdAt: string | null,
      lastActivity: string | null,
      isOutsideEngland: boolean | null,
    },
    createdAt: string,
  } | null,
};

export type DeleteContactedDoctorMutationVariables = {
  input: DeleteContactedDoctorInput,
};

export type DeleteContactedDoctorMutation = {
  deleteContactedDoctor:  {
    __typename: "ContactedDoctor",
    id: string,
    assessmentId: string,
    contactedDoctorS12DoctorId: string,
    assessment:  {
      __typename: "Assessment",
      id: string,
      createdAt: string,
      amhpTeamId: string,
      amhpTeam:  {
        __typename: "AmhpTeam",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        localAuthority: string | null,
        emails: Array< string > | null,
        phoneNumbers: Array< string > | null,
        mhaInboxInstructions: string | null,
        updatedBy: string | null,
      },
      amhpId: string,
      amhp:  {
        __typename: "AmhpProfile",
        id: string,
        email: string,
        phone: string | null,
        name: string,
        legalName: string | null,
        deleted: boolean | null,
        warrantInfo: string | null,
        accreditation: string | null,
        sectionFormEmailAddress: string | null,
        sectionFormContactNumber: string | null,
        pushTokens: Array< string | null > | null,
        lastActivity: string | null,
        createdAt: string | null,
      },
      patientName: string,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      },
      ccgId: string | null,
      ccg:  {
        __typename: "Organisation",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        type: OrgType,
        phone: string | null,
        email: string | null,
        expensesInstruction: string | null,
        declarationText: string | null,
        additionalNotesText: string | null,
        invoiceEmail: string | null,
        updatedBy: string | null,
        deleted: boolean | null,
      } | null,
      ccgDetermination: LocationType | null,
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      },
      hadVisitAdded: boolean | null,
      doctorVisits:  {
        __typename: "ModelDoctorVisitConnection",
        nextToken: string | null,
      } | null,
      patientNhsNumber: string | null,
      assessmentDate: string | null,
      contactedDoctors:  {
        __typename: "ModelContactedDoctorConnection",
        nextToken: string | null,
      } | null,
      forms:  {
        __typename: "ModelFormConnection",
        nextToken: string | null,
      } | null,
      patientAddress:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
    },
    s12Doctor:  {
      __typename: "S12Doctor",
      id: string,
      gmc: string,
      s12Expiry: string | null,
      name: string,
      legalName: string,
      phone: string | null,
      phone2: string | null,
      email: string,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      },
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      },
      gl: boolean | null,
      employer: string | null,
      jobTitle: string | null,
      responsibleOfficer: string | null,
      lineManager: string | null,
      defaultMHT: string | null,
      mhtEmployers:  {
        __typename: "ModelMHTS12DoctorConnection",
        nextToken: string | null,
      } | null,
      availabilities:  {
        __typename: "ModelAvailabilityConnection",
        nextToken: string | null,
      } | null,
      holidays:  {
        __typename: "ModelHolidayConnection",
        nextToken: string | null,
      } | null,
      specialties: Array< string | null >,
      languages: Array< string | null >,
      gender: Gender | null,
      notes: string | null,
      pushTokens: Array< string | null > | null,
      distance: number | null,
      hideFromSearch: boolean | null,
      visits:  {
        __typename: "ModelDoctorVisitConnection",
        nextToken: string | null,
      } | null,
      doctors:  {
        __typename: "ModelContactedDoctorConnection",
        nextToken: string | null,
      } | null,
      claims:  {
        __typename: "ModelClaimConnection",
        nextToken: string | null,
      } | null,
      availabilityLocations:  Array< {
        __typename: "Location",
        lat: number,
        lon: number,
      } | null > | null,
      availabilityPostcodes: Array< string | null > | null,
      siteName: string,
      claimData:  {
        __typename: "ClaimData",
        billingCompanyName: string | null,
        vatRegistration: string | null,
        fuelType: FuelType | null,
        engineSize: Engine | null,
      } | null,
      sectionFormAddress:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      sectionFormEmailAddress: string | null,
      reports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      summaryReports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      createdAt: string | null,
      lastActivity: string | null,
      isOutsideEngland: boolean | null,
    },
    createdAt: string,
  } | null,
};

export type CreateS12DoctorMutationVariables = {
  input: CreateS12DoctorInput,
};

export type CreateS12DoctorMutation = {
  createS12Doctor:  {
    __typename: "S12Doctor",
    id: string,
    gmc: string,
    s12Expiry: string | null,
    name: string,
    legalName: string,
    phone: string | null,
    phone2: string | null,
    email: string,
    location:  {
      __typename: "Location",
      lat: number,
      lon: number,
    },
    locationName:  {
      __typename: "LocationName",
      city: string | null,
      postcode: string,
      address: string | null,
      addressNotes: string | null,
    },
    gl: boolean | null,
    employer: string | null,
    jobTitle: string | null,
    responsibleOfficer: string | null,
    lineManager: string | null,
    defaultMHT: string | null,
    mhtEmployers:  {
      __typename: "ModelMHTS12DoctorConnection",
      items:  Array< {
        __typename: "MHTS12Doctor",
        id: string,
        mHTS12DoctorS12DoctorId: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
    availabilities:  {
      __typename: "ModelAvailabilityConnection",
      items:  Array< {
        __typename: "Availability",
        id: string,
        createdAt: string | null,
        type: AvailabilityType | null,
        rrule: string,
        endHour: number,
        endDate: string,
        notes: string | null,
        s12DoctorAvailabilitiesId: string,
        availabilityDoctorId: string,
        oncall: boolean | null,
        rotaId: string | null,
        rotaName: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    holidays:  {
      __typename: "ModelHolidayConnection",
      items:  Array< {
        __typename: "Holiday",
        id: string,
        start: string,
        end: string,
        visible: boolean,
        notes: string | null,
        s12DoctorHolidaysId: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
    specialties: Array< string | null >,
    languages: Array< string | null >,
    gender: Gender | null,
    notes: string | null,
    pushTokens: Array< string | null > | null,
    distance: number | null,
    hideFromSearch: boolean | null,
    visits:  {
      __typename: "ModelDoctorVisitConnection",
      items:  Array< {
        __typename: "DoctorVisit",
        id: string,
        createdAt: string | null,
        assessmentId: string,
        time: string,
        teamId: string,
        doctorVisitDoctorId: string,
        notes: Array< string | null > | null,
        partialPostcode: string | null,
        doctorVisitClaimId: string | null,
        claimDisplayId: string | null,
        claimStatus: ClaimStatus | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    doctors:  {
      __typename: "ModelContactedDoctorConnection",
      items:  Array< {
        __typename: "ContactedDoctor",
        id: string,
        assessmentId: string,
        contactedDoctorS12DoctorId: string,
        createdAt: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
    claims:  {
      __typename: "ModelClaimConnection",
      items:  Array< {
        __typename: "Claim",
        id: string,
        claimAssessmentId: string | null,
        claimVisitId: string,
        claimDoctorId: string,
        assigneeId: string,
        assigneeName: string | null,
        amhpTeamId: string,
        claimAmhpId: string,
        claimOrganisationId: string,
        claimCsuId: string,
        status: ClaimStatus,
        displayId: string,
        mileage: string | null,
        locationText: string,
        locationType: LocationType,
        claimPostcode: string | null,
        assessmentPostcode: string | null,
        patientInitials: string,
        patientFullName: string | null,
        startingPostcode: string | null,
        visitDate: string,
        assessmentTookPlace: boolean | null,
        notes: Array< string | null > | null,
        unread: boolean | null,
        createdAt: string | null,
        updatedAt: string,
        receivedDate: string,
        additionalExpenses: string | null,
        additionalInformation: string | null,
        patientNhsNumber: string | null,
        declaration: string | null,
        paid: boolean | null,
        lineManager: string | null,
        doctorAdditionalConf: DoctorConfirmation | null,
        isIndependentDoctor: boolean | null,
        defaultMHT: string | null,
        doctorEmployedStatus: DoctorEmployedStatusValues | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    availabilityLocations:  Array< {
      __typename: "Location",
      lat: number,
      lon: number,
    } | null > | null,
    availabilityPostcodes: Array< string | null > | null,
    siteName: string,
    claimData:  {
      __typename: "ClaimData",
      billingAddress:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      billingCompanyName: string | null,
      vatRegistration: string | null,
      fuelType: FuelType | null,
      engineSize: Engine | null,
    } | null,
    sectionFormAddress:  {
      __typename: "LocationName",
      city: string | null,
      postcode: string,
      address: string | null,
      addressNotes: string | null,
    } | null,
    sectionFormEmailAddress: string | null,
    reports:  {
      __typename: "ModelReportingConnection",
      items:  Array< {
        __typename: "Reporting",
        id: string,
        pk: string,
        sk: string,
        ttl: number | null,
        gsi1pk: string | null,
        gsi1sk: string | null,
        gsi2pk: string | null,
        gsi2sk: string | null,
        gsi3pk: string | null,
        gsi3sk: string | null,
        eventName: string,
        userId: string,
        timestamp: string,
        period: string,
        count: number | null,
        siteId: string | null,
        teamId: string | null,
        orgId: string | null,
        amhpId: string | null,
        doctorId: string | null,
        claimProcessorId: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    summaryReports:  {
      __typename: "ModelReportingConnection",
      items:  Array< {
        __typename: "Reporting",
        id: string,
        pk: string,
        sk: string,
        ttl: number | null,
        gsi1pk: string | null,
        gsi1sk: string | null,
        gsi2pk: string | null,
        gsi2sk: string | null,
        gsi3pk: string | null,
        gsi3sk: string | null,
        eventName: string,
        userId: string,
        timestamp: string,
        period: string,
        count: number | null,
        siteId: string | null,
        teamId: string | null,
        orgId: string | null,
        amhpId: string | null,
        doctorId: string | null,
        claimProcessorId: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    createdAt: string | null,
    lastActivity: string | null,
    isOutsideEngland: boolean | null,
  } | null,
};

export type UpdateS12DoctorMutationVariables = {
  input: UpdateS12DoctorInput,
};

export type UpdateS12DoctorMutation = {
  updateS12Doctor:  {
    __typename: "S12Doctor",
    id: string,
    gmc: string,
    s12Expiry: string | null,
    name: string,
    legalName: string,
    phone: string | null,
    phone2: string | null,
    email: string,
    location:  {
      __typename: "Location",
      lat: number,
      lon: number,
    },
    locationName:  {
      __typename: "LocationName",
      city: string | null,
      postcode: string,
      address: string | null,
      addressNotes: string | null,
    },
    gl: boolean | null,
    employer: string | null,
    jobTitle: string | null,
    responsibleOfficer: string | null,
    lineManager: string | null,
    defaultMHT: string | null,
    mhtEmployers:  {
      __typename: "ModelMHTS12DoctorConnection",
      items:  Array< {
        __typename: "MHTS12Doctor",
        id: string,
        mHTS12DoctorS12DoctorId: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
    availabilities:  {
      __typename: "ModelAvailabilityConnection",
      items:  Array< {
        __typename: "Availability",
        id: string,
        createdAt: string | null,
        type: AvailabilityType | null,
        rrule: string,
        endHour: number,
        endDate: string,
        notes: string | null,
        s12DoctorAvailabilitiesId: string,
        availabilityDoctorId: string,
        oncall: boolean | null,
        rotaId: string | null,
        rotaName: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    holidays:  {
      __typename: "ModelHolidayConnection",
      items:  Array< {
        __typename: "Holiday",
        id: string,
        start: string,
        end: string,
        visible: boolean,
        notes: string | null,
        s12DoctorHolidaysId: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
    specialties: Array< string | null >,
    languages: Array< string | null >,
    gender: Gender | null,
    notes: string | null,
    pushTokens: Array< string | null > | null,
    distance: number | null,
    hideFromSearch: boolean | null,
    visits:  {
      __typename: "ModelDoctorVisitConnection",
      items:  Array< {
        __typename: "DoctorVisit",
        id: string,
        createdAt: string | null,
        assessmentId: string,
        time: string,
        teamId: string,
        doctorVisitDoctorId: string,
        notes: Array< string | null > | null,
        partialPostcode: string | null,
        doctorVisitClaimId: string | null,
        claimDisplayId: string | null,
        claimStatus: ClaimStatus | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    doctors:  {
      __typename: "ModelContactedDoctorConnection",
      items:  Array< {
        __typename: "ContactedDoctor",
        id: string,
        assessmentId: string,
        contactedDoctorS12DoctorId: string,
        createdAt: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
    claims:  {
      __typename: "ModelClaimConnection",
      items:  Array< {
        __typename: "Claim",
        id: string,
        claimAssessmentId: string | null,
        claimVisitId: string,
        claimDoctorId: string,
        assigneeId: string,
        assigneeName: string | null,
        amhpTeamId: string,
        claimAmhpId: string,
        claimOrganisationId: string,
        claimCsuId: string,
        status: ClaimStatus,
        displayId: string,
        mileage: string | null,
        locationText: string,
        locationType: LocationType,
        claimPostcode: string | null,
        assessmentPostcode: string | null,
        patientInitials: string,
        patientFullName: string | null,
        startingPostcode: string | null,
        visitDate: string,
        assessmentTookPlace: boolean | null,
        notes: Array< string | null > | null,
        unread: boolean | null,
        createdAt: string | null,
        updatedAt: string,
        receivedDate: string,
        additionalExpenses: string | null,
        additionalInformation: string | null,
        patientNhsNumber: string | null,
        declaration: string | null,
        paid: boolean | null,
        lineManager: string | null,
        doctorAdditionalConf: DoctorConfirmation | null,
        isIndependentDoctor: boolean | null,
        defaultMHT: string | null,
        doctorEmployedStatus: DoctorEmployedStatusValues | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    availabilityLocations:  Array< {
      __typename: "Location",
      lat: number,
      lon: number,
    } | null > | null,
    availabilityPostcodes: Array< string | null > | null,
    siteName: string,
    claimData:  {
      __typename: "ClaimData",
      billingAddress:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      billingCompanyName: string | null,
      vatRegistration: string | null,
      fuelType: FuelType | null,
      engineSize: Engine | null,
    } | null,
    sectionFormAddress:  {
      __typename: "LocationName",
      city: string | null,
      postcode: string,
      address: string | null,
      addressNotes: string | null,
    } | null,
    sectionFormEmailAddress: string | null,
    reports:  {
      __typename: "ModelReportingConnection",
      items:  Array< {
        __typename: "Reporting",
        id: string,
        pk: string,
        sk: string,
        ttl: number | null,
        gsi1pk: string | null,
        gsi1sk: string | null,
        gsi2pk: string | null,
        gsi2sk: string | null,
        gsi3pk: string | null,
        gsi3sk: string | null,
        eventName: string,
        userId: string,
        timestamp: string,
        period: string,
        count: number | null,
        siteId: string | null,
        teamId: string | null,
        orgId: string | null,
        amhpId: string | null,
        doctorId: string | null,
        claimProcessorId: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    summaryReports:  {
      __typename: "ModelReportingConnection",
      items:  Array< {
        __typename: "Reporting",
        id: string,
        pk: string,
        sk: string,
        ttl: number | null,
        gsi1pk: string | null,
        gsi1sk: string | null,
        gsi2pk: string | null,
        gsi2sk: string | null,
        gsi3pk: string | null,
        gsi3sk: string | null,
        eventName: string,
        userId: string,
        timestamp: string,
        period: string,
        count: number | null,
        siteId: string | null,
        teamId: string | null,
        orgId: string | null,
        amhpId: string | null,
        doctorId: string | null,
        claimProcessorId: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    createdAt: string | null,
    lastActivity: string | null,
    isOutsideEngland: boolean | null,
  } | null,
};

export type DeleteS12DoctorMutationVariables = {
  input: DeleteS12DoctorInput,
};

export type DeleteS12DoctorMutation = {
  deleteS12Doctor:  {
    __typename: "S12Doctor",
    id: string,
    gmc: string,
    s12Expiry: string | null,
    name: string,
    legalName: string,
    phone: string | null,
    phone2: string | null,
    email: string,
    location:  {
      __typename: "Location",
      lat: number,
      lon: number,
    },
    locationName:  {
      __typename: "LocationName",
      city: string | null,
      postcode: string,
      address: string | null,
      addressNotes: string | null,
    },
    gl: boolean | null,
    employer: string | null,
    jobTitle: string | null,
    responsibleOfficer: string | null,
    lineManager: string | null,
    defaultMHT: string | null,
    mhtEmployers:  {
      __typename: "ModelMHTS12DoctorConnection",
      items:  Array< {
        __typename: "MHTS12Doctor",
        id: string,
        mHTS12DoctorS12DoctorId: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
    availabilities:  {
      __typename: "ModelAvailabilityConnection",
      items:  Array< {
        __typename: "Availability",
        id: string,
        createdAt: string | null,
        type: AvailabilityType | null,
        rrule: string,
        endHour: number,
        endDate: string,
        notes: string | null,
        s12DoctorAvailabilitiesId: string,
        availabilityDoctorId: string,
        oncall: boolean | null,
        rotaId: string | null,
        rotaName: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    holidays:  {
      __typename: "ModelHolidayConnection",
      items:  Array< {
        __typename: "Holiday",
        id: string,
        start: string,
        end: string,
        visible: boolean,
        notes: string | null,
        s12DoctorHolidaysId: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
    specialties: Array< string | null >,
    languages: Array< string | null >,
    gender: Gender | null,
    notes: string | null,
    pushTokens: Array< string | null > | null,
    distance: number | null,
    hideFromSearch: boolean | null,
    visits:  {
      __typename: "ModelDoctorVisitConnection",
      items:  Array< {
        __typename: "DoctorVisit",
        id: string,
        createdAt: string | null,
        assessmentId: string,
        time: string,
        teamId: string,
        doctorVisitDoctorId: string,
        notes: Array< string | null > | null,
        partialPostcode: string | null,
        doctorVisitClaimId: string | null,
        claimDisplayId: string | null,
        claimStatus: ClaimStatus | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    doctors:  {
      __typename: "ModelContactedDoctorConnection",
      items:  Array< {
        __typename: "ContactedDoctor",
        id: string,
        assessmentId: string,
        contactedDoctorS12DoctorId: string,
        createdAt: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
    claims:  {
      __typename: "ModelClaimConnection",
      items:  Array< {
        __typename: "Claim",
        id: string,
        claimAssessmentId: string | null,
        claimVisitId: string,
        claimDoctorId: string,
        assigneeId: string,
        assigneeName: string | null,
        amhpTeamId: string,
        claimAmhpId: string,
        claimOrganisationId: string,
        claimCsuId: string,
        status: ClaimStatus,
        displayId: string,
        mileage: string | null,
        locationText: string,
        locationType: LocationType,
        claimPostcode: string | null,
        assessmentPostcode: string | null,
        patientInitials: string,
        patientFullName: string | null,
        startingPostcode: string | null,
        visitDate: string,
        assessmentTookPlace: boolean | null,
        notes: Array< string | null > | null,
        unread: boolean | null,
        createdAt: string | null,
        updatedAt: string,
        receivedDate: string,
        additionalExpenses: string | null,
        additionalInformation: string | null,
        patientNhsNumber: string | null,
        declaration: string | null,
        paid: boolean | null,
        lineManager: string | null,
        doctorAdditionalConf: DoctorConfirmation | null,
        isIndependentDoctor: boolean | null,
        defaultMHT: string | null,
        doctorEmployedStatus: DoctorEmployedStatusValues | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    availabilityLocations:  Array< {
      __typename: "Location",
      lat: number,
      lon: number,
    } | null > | null,
    availabilityPostcodes: Array< string | null > | null,
    siteName: string,
    claimData:  {
      __typename: "ClaimData",
      billingAddress:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      billingCompanyName: string | null,
      vatRegistration: string | null,
      fuelType: FuelType | null,
      engineSize: Engine | null,
    } | null,
    sectionFormAddress:  {
      __typename: "LocationName",
      city: string | null,
      postcode: string,
      address: string | null,
      addressNotes: string | null,
    } | null,
    sectionFormEmailAddress: string | null,
    reports:  {
      __typename: "ModelReportingConnection",
      items:  Array< {
        __typename: "Reporting",
        id: string,
        pk: string,
        sk: string,
        ttl: number | null,
        gsi1pk: string | null,
        gsi1sk: string | null,
        gsi2pk: string | null,
        gsi2sk: string | null,
        gsi3pk: string | null,
        gsi3sk: string | null,
        eventName: string,
        userId: string,
        timestamp: string,
        period: string,
        count: number | null,
        siteId: string | null,
        teamId: string | null,
        orgId: string | null,
        amhpId: string | null,
        doctorId: string | null,
        claimProcessorId: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    summaryReports:  {
      __typename: "ModelReportingConnection",
      items:  Array< {
        __typename: "Reporting",
        id: string,
        pk: string,
        sk: string,
        ttl: number | null,
        gsi1pk: string | null,
        gsi1sk: string | null,
        gsi2pk: string | null,
        gsi2sk: string | null,
        gsi3pk: string | null,
        gsi3sk: string | null,
        eventName: string,
        userId: string,
        timestamp: string,
        period: string,
        count: number | null,
        siteId: string | null,
        teamId: string | null,
        orgId: string | null,
        amhpId: string | null,
        doctorId: string | null,
        claimProcessorId: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    createdAt: string | null,
    lastActivity: string | null,
    isOutsideEngland: boolean | null,
  } | null,
};

export type UpdateDoctorVisitMutationVariables = {
  input: UpdateDoctorVisitInput,
};

export type UpdateDoctorVisitMutation = {
  updateDoctorVisit:  {
    __typename: "DoctorVisit",
    id: string,
    createdAt: string | null,
    assessmentId: string,
    assessment:  {
      __typename: "Assessment",
      id: string,
      createdAt: string,
      amhpTeamId: string,
      amhpTeam:  {
        __typename: "AmhpTeam",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        localAuthority: string | null,
        emails: Array< string > | null,
        phoneNumbers: Array< string > | null,
        mhaInboxInstructions: string | null,
        updatedBy: string | null,
      },
      amhpId: string,
      amhp:  {
        __typename: "AmhpProfile",
        id: string,
        email: string,
        phone: string | null,
        name: string,
        legalName: string | null,
        deleted: boolean | null,
        warrantInfo: string | null,
        accreditation: string | null,
        sectionFormEmailAddress: string | null,
        sectionFormContactNumber: string | null,
        pushTokens: Array< string | null > | null,
        lastActivity: string | null,
        createdAt: string | null,
      },
      patientName: string,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      },
      ccgId: string | null,
      ccg:  {
        __typename: "Organisation",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        type: OrgType,
        phone: string | null,
        email: string | null,
        expensesInstruction: string | null,
        declarationText: string | null,
        additionalNotesText: string | null,
        invoiceEmail: string | null,
        updatedBy: string | null,
        deleted: boolean | null,
      } | null,
      ccgDetermination: LocationType | null,
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      },
      hadVisitAdded: boolean | null,
      doctorVisits:  {
        __typename: "ModelDoctorVisitConnection",
        nextToken: string | null,
      } | null,
      patientNhsNumber: string | null,
      assessmentDate: string | null,
      contactedDoctors:  {
        __typename: "ModelContactedDoctorConnection",
        nextToken: string | null,
      } | null,
      forms:  {
        __typename: "ModelFormConnection",
        nextToken: string | null,
      } | null,
      patientAddress:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
    },
    time: string,
    teamId: string,
    team:  {
      __typename: "AmhpTeam",
      id: string,
      name: string,
      featureFlags: string | null,
      features: string | null,
      assessments:  {
        __typename: "ModelAssessmentConnection",
        nextToken: string | null,
      } | null,
      users:  {
        __typename: "ModelAmhpTeamUserConnection",
        nextToken: string | null,
      } | null,
      commonAddresses:  {
        __typename: "ModelAddressConnection",
        nextToken: string | null,
      } | null,
      localAuthority: string | null,
      hospitalEmails:  Array< {
        __typename: "HospitalEmails",
        name: string,
      } > | null,
      mhaEmailAddresses:  Array< {
        __typename: "RecipientEmails",
        name: string | null,
      } | null > | null,
      emails: Array< string > | null,
      phoneNumbers: Array< string > | null,
      mhaInboxInstructions: string | null,
      reports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      summaryReports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      updatedBy: string | null,
    },
    doctorVisitDoctorId: string,
    doctor:  {
      __typename: "S12Doctor",
      id: string,
      gmc: string,
      s12Expiry: string | null,
      name: string,
      legalName: string,
      phone: string | null,
      phone2: string | null,
      email: string,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      },
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      },
      gl: boolean | null,
      employer: string | null,
      jobTitle: string | null,
      responsibleOfficer: string | null,
      lineManager: string | null,
      defaultMHT: string | null,
      mhtEmployers:  {
        __typename: "ModelMHTS12DoctorConnection",
        nextToken: string | null,
      } | null,
      availabilities:  {
        __typename: "ModelAvailabilityConnection",
        nextToken: string | null,
      } | null,
      holidays:  {
        __typename: "ModelHolidayConnection",
        nextToken: string | null,
      } | null,
      specialties: Array< string | null >,
      languages: Array< string | null >,
      gender: Gender | null,
      notes: string | null,
      pushTokens: Array< string | null > | null,
      distance: number | null,
      hideFromSearch: boolean | null,
      visits:  {
        __typename: "ModelDoctorVisitConnection",
        nextToken: string | null,
      } | null,
      doctors:  {
        __typename: "ModelContactedDoctorConnection",
        nextToken: string | null,
      } | null,
      claims:  {
        __typename: "ModelClaimConnection",
        nextToken: string | null,
      } | null,
      availabilityLocations:  Array< {
        __typename: "Location",
        lat: number,
        lon: number,
      } | null > | null,
      availabilityPostcodes: Array< string | null > | null,
      siteName: string,
      claimData:  {
        __typename: "ClaimData",
        billingCompanyName: string | null,
        vatRegistration: string | null,
        fuelType: FuelType | null,
        engineSize: Engine | null,
      } | null,
      sectionFormAddress:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      sectionFormEmailAddress: string | null,
      reports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      summaryReports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      createdAt: string | null,
      lastActivity: string | null,
      isOutsideEngland: boolean | null,
    },
    notes: Array< string | null > | null,
    partialPostcode: string | null,
    doctorVisitClaimId: string | null,
    claimDisplayId: string | null,
    claimStatus: ClaimStatus | null,
    claim:  {
      __typename: "Claim",
      id: string,
      claimAssessmentId: string | null,
      claimVisitId: string,
      visit:  {
        __typename: "DoctorVisit",
        id: string,
        createdAt: string | null,
        assessmentId: string,
        time: string,
        teamId: string,
        doctorVisitDoctorId: string,
        notes: Array< string | null > | null,
        partialPostcode: string | null,
        doctorVisitClaimId: string | null,
        claimDisplayId: string | null,
        claimStatus: ClaimStatus | null,
      },
      claimDoctorId: string,
      doctor:  {
        __typename: "S12Doctor",
        id: string,
        gmc: string,
        s12Expiry: string | null,
        name: string,
        legalName: string,
        phone: string | null,
        phone2: string | null,
        email: string,
        gl: boolean | null,
        employer: string | null,
        jobTitle: string | null,
        responsibleOfficer: string | null,
        lineManager: string | null,
        defaultMHT: string | null,
        specialties: Array< string | null >,
        languages: Array< string | null >,
        gender: Gender | null,
        notes: string | null,
        pushTokens: Array< string | null > | null,
        distance: number | null,
        hideFromSearch: boolean | null,
        availabilityPostcodes: Array< string | null > | null,
        siteName: string,
        sectionFormEmailAddress: string | null,
        createdAt: string | null,
        lastActivity: string | null,
        isOutsideEngland: boolean | null,
      },
      assigneeId: string,
      assigneeName: string | null,
      amhpTeamId: string,
      claimAmhpId: string,
      amhp:  {
        __typename: "AmhpProfile",
        id: string,
        email: string,
        phone: string | null,
        name: string,
        legalName: string | null,
        deleted: boolean | null,
        warrantInfo: string | null,
        accreditation: string | null,
        sectionFormEmailAddress: string | null,
        sectionFormContactNumber: string | null,
        pushTokens: Array< string | null > | null,
        lastActivity: string | null,
        createdAt: string | null,
      },
      claimOrganisationId: string,
      claimCsuId: string,
      organisation:  {
        __typename: "Organisation",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        type: OrgType,
        phone: string | null,
        email: string | null,
        expensesInstruction: string | null,
        declarationText: string | null,
        additionalNotesText: string | null,
        invoiceEmail: string | null,
        updatedBy: string | null,
        deleted: boolean | null,
      },
      status: ClaimStatus,
      displayId: string,
      mileage: string | null,
      locationText: string,
      locationType: LocationType,
      claimPostcode: string | null,
      assessmentPostcode: string | null,
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      patientInitials: string,
      patientFullName: string | null,
      startingPostcode: string | null,
      visitDate: string,
      assessmentTookPlace: boolean | null,
      notes: Array< string | null > | null,
      unread: boolean | null,
      createdAt: string | null,
      updatedAt: string,
      receivedDate: string,
      additionalExpenses: string | null,
      additionalInformation: string | null,
      patientNhsNumber: string | null,
      declaration: string | null,
      paid: boolean | null,
      claimData:  {
        __typename: "ClaimData",
        billingCompanyName: string | null,
        vatRegistration: string | null,
        fuelType: FuelType | null,
        engineSize: Engine | null,
      } | null,
      invoiceData:  {
        __typename: "InvoiceData",
        assessmentFee: string,
        travelFee: string | null,
        locationOfAssessment: string | null,
      } | null,
      lineManager: string | null,
      doctorAdditionalConf: DoctorConfirmation | null,
      isIndependentDoctor: boolean | null,
      defaultMHT: string | null,
      doctorEmployedStatus: DoctorEmployedStatusValues | null,
    } | null,
  } | null,
};

export type DeleteDoctorVisitMutationVariables = {
  input: DeleteDoctorVisitInput,
};

export type DeleteDoctorVisitMutation = {
  deleteDoctorVisit:  {
    __typename: "DoctorVisit",
    id: string,
    createdAt: string | null,
    assessmentId: string,
    assessment:  {
      __typename: "Assessment",
      id: string,
      createdAt: string,
      amhpTeamId: string,
      amhpTeam:  {
        __typename: "AmhpTeam",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        localAuthority: string | null,
        emails: Array< string > | null,
        phoneNumbers: Array< string > | null,
        mhaInboxInstructions: string | null,
        updatedBy: string | null,
      },
      amhpId: string,
      amhp:  {
        __typename: "AmhpProfile",
        id: string,
        email: string,
        phone: string | null,
        name: string,
        legalName: string | null,
        deleted: boolean | null,
        warrantInfo: string | null,
        accreditation: string | null,
        sectionFormEmailAddress: string | null,
        sectionFormContactNumber: string | null,
        pushTokens: Array< string | null > | null,
        lastActivity: string | null,
        createdAt: string | null,
      },
      patientName: string,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      },
      ccgId: string | null,
      ccg:  {
        __typename: "Organisation",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        type: OrgType,
        phone: string | null,
        email: string | null,
        expensesInstruction: string | null,
        declarationText: string | null,
        additionalNotesText: string | null,
        invoiceEmail: string | null,
        updatedBy: string | null,
        deleted: boolean | null,
      } | null,
      ccgDetermination: LocationType | null,
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      },
      hadVisitAdded: boolean | null,
      doctorVisits:  {
        __typename: "ModelDoctorVisitConnection",
        nextToken: string | null,
      } | null,
      patientNhsNumber: string | null,
      assessmentDate: string | null,
      contactedDoctors:  {
        __typename: "ModelContactedDoctorConnection",
        nextToken: string | null,
      } | null,
      forms:  {
        __typename: "ModelFormConnection",
        nextToken: string | null,
      } | null,
      patientAddress:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
    },
    time: string,
    teamId: string,
    team:  {
      __typename: "AmhpTeam",
      id: string,
      name: string,
      featureFlags: string | null,
      features: string | null,
      assessments:  {
        __typename: "ModelAssessmentConnection",
        nextToken: string | null,
      } | null,
      users:  {
        __typename: "ModelAmhpTeamUserConnection",
        nextToken: string | null,
      } | null,
      commonAddresses:  {
        __typename: "ModelAddressConnection",
        nextToken: string | null,
      } | null,
      localAuthority: string | null,
      hospitalEmails:  Array< {
        __typename: "HospitalEmails",
        name: string,
      } > | null,
      mhaEmailAddresses:  Array< {
        __typename: "RecipientEmails",
        name: string | null,
      } | null > | null,
      emails: Array< string > | null,
      phoneNumbers: Array< string > | null,
      mhaInboxInstructions: string | null,
      reports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      summaryReports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      updatedBy: string | null,
    },
    doctorVisitDoctorId: string,
    doctor:  {
      __typename: "S12Doctor",
      id: string,
      gmc: string,
      s12Expiry: string | null,
      name: string,
      legalName: string,
      phone: string | null,
      phone2: string | null,
      email: string,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      },
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      },
      gl: boolean | null,
      employer: string | null,
      jobTitle: string | null,
      responsibleOfficer: string | null,
      lineManager: string | null,
      defaultMHT: string | null,
      mhtEmployers:  {
        __typename: "ModelMHTS12DoctorConnection",
        nextToken: string | null,
      } | null,
      availabilities:  {
        __typename: "ModelAvailabilityConnection",
        nextToken: string | null,
      } | null,
      holidays:  {
        __typename: "ModelHolidayConnection",
        nextToken: string | null,
      } | null,
      specialties: Array< string | null >,
      languages: Array< string | null >,
      gender: Gender | null,
      notes: string | null,
      pushTokens: Array< string | null > | null,
      distance: number | null,
      hideFromSearch: boolean | null,
      visits:  {
        __typename: "ModelDoctorVisitConnection",
        nextToken: string | null,
      } | null,
      doctors:  {
        __typename: "ModelContactedDoctorConnection",
        nextToken: string | null,
      } | null,
      claims:  {
        __typename: "ModelClaimConnection",
        nextToken: string | null,
      } | null,
      availabilityLocations:  Array< {
        __typename: "Location",
        lat: number,
        lon: number,
      } | null > | null,
      availabilityPostcodes: Array< string | null > | null,
      siteName: string,
      claimData:  {
        __typename: "ClaimData",
        billingCompanyName: string | null,
        vatRegistration: string | null,
        fuelType: FuelType | null,
        engineSize: Engine | null,
      } | null,
      sectionFormAddress:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      sectionFormEmailAddress: string | null,
      reports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      summaryReports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      createdAt: string | null,
      lastActivity: string | null,
      isOutsideEngland: boolean | null,
    },
    notes: Array< string | null > | null,
    partialPostcode: string | null,
    doctorVisitClaimId: string | null,
    claimDisplayId: string | null,
    claimStatus: ClaimStatus | null,
    claim:  {
      __typename: "Claim",
      id: string,
      claimAssessmentId: string | null,
      claimVisitId: string,
      visit:  {
        __typename: "DoctorVisit",
        id: string,
        createdAt: string | null,
        assessmentId: string,
        time: string,
        teamId: string,
        doctorVisitDoctorId: string,
        notes: Array< string | null > | null,
        partialPostcode: string | null,
        doctorVisitClaimId: string | null,
        claimDisplayId: string | null,
        claimStatus: ClaimStatus | null,
      },
      claimDoctorId: string,
      doctor:  {
        __typename: "S12Doctor",
        id: string,
        gmc: string,
        s12Expiry: string | null,
        name: string,
        legalName: string,
        phone: string | null,
        phone2: string | null,
        email: string,
        gl: boolean | null,
        employer: string | null,
        jobTitle: string | null,
        responsibleOfficer: string | null,
        lineManager: string | null,
        defaultMHT: string | null,
        specialties: Array< string | null >,
        languages: Array< string | null >,
        gender: Gender | null,
        notes: string | null,
        pushTokens: Array< string | null > | null,
        distance: number | null,
        hideFromSearch: boolean | null,
        availabilityPostcodes: Array< string | null > | null,
        siteName: string,
        sectionFormEmailAddress: string | null,
        createdAt: string | null,
        lastActivity: string | null,
        isOutsideEngland: boolean | null,
      },
      assigneeId: string,
      assigneeName: string | null,
      amhpTeamId: string,
      claimAmhpId: string,
      amhp:  {
        __typename: "AmhpProfile",
        id: string,
        email: string,
        phone: string | null,
        name: string,
        legalName: string | null,
        deleted: boolean | null,
        warrantInfo: string | null,
        accreditation: string | null,
        sectionFormEmailAddress: string | null,
        sectionFormContactNumber: string | null,
        pushTokens: Array< string | null > | null,
        lastActivity: string | null,
        createdAt: string | null,
      },
      claimOrganisationId: string,
      claimCsuId: string,
      organisation:  {
        __typename: "Organisation",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        type: OrgType,
        phone: string | null,
        email: string | null,
        expensesInstruction: string | null,
        declarationText: string | null,
        additionalNotesText: string | null,
        invoiceEmail: string | null,
        updatedBy: string | null,
        deleted: boolean | null,
      },
      status: ClaimStatus,
      displayId: string,
      mileage: string | null,
      locationText: string,
      locationType: LocationType,
      claimPostcode: string | null,
      assessmentPostcode: string | null,
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      patientInitials: string,
      patientFullName: string | null,
      startingPostcode: string | null,
      visitDate: string,
      assessmentTookPlace: boolean | null,
      notes: Array< string | null > | null,
      unread: boolean | null,
      createdAt: string | null,
      updatedAt: string,
      receivedDate: string,
      additionalExpenses: string | null,
      additionalInformation: string | null,
      patientNhsNumber: string | null,
      declaration: string | null,
      paid: boolean | null,
      claimData:  {
        __typename: "ClaimData",
        billingCompanyName: string | null,
        vatRegistration: string | null,
        fuelType: FuelType | null,
        engineSize: Engine | null,
      } | null,
      invoiceData:  {
        __typename: "InvoiceData",
        assessmentFee: string,
        travelFee: string | null,
        locationOfAssessment: string | null,
      } | null,
      lineManager: string | null,
      doctorAdditionalConf: DoctorConfirmation | null,
      isIndependentDoctor: boolean | null,
      defaultMHT: string | null,
      doctorEmployedStatus: DoctorEmployedStatusValues | null,
    } | null,
  } | null,
};

export type CreateAmhpProfileMutationVariables = {
  input: CreateAmhpProfileInput,
};

export type CreateAmhpProfileMutation = {
  createAmhpProfile:  {
    __typename: "AmhpProfile",
    id: string,
    email: string,
    phone: string | null,
    name: string,
    legalName: string | null,
    deleted: boolean | null,
    warrantInfo: string | null,
    accreditation: string | null,
    sectionFormAddress:  {
      __typename: "LocationName",
      city: string | null,
      postcode: string,
      address: string | null,
      addressNotes: string | null,
    } | null,
    sectionFormEmailAddress: string | null,
    sectionFormContactNumber: string | null,
    assessments:  {
      __typename: "ModelAssessmentConnection",
      items:  Array< {
        __typename: "Assessment",
        id: string,
        createdAt: string,
        amhpTeamId: string,
        amhpId: string,
        patientName: string,
        ccgId: string | null,
        ccgDetermination: LocationType | null,
        hadVisitAdded: boolean | null,
        patientNhsNumber: string | null,
        assessmentDate: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    pushTokens: Array< string | null > | null,
    teams:  {
      __typename: "ModelAmhpTeamUserConnection",
      items:  Array< {
        __typename: "AmhpTeamUser",
        id: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
    lastActivity: string | null,
    createdAt: string | null,
    reports:  {
      __typename: "ModelReportingConnection",
      items:  Array< {
        __typename: "Reporting",
        id: string,
        pk: string,
        sk: string,
        ttl: number | null,
        gsi1pk: string | null,
        gsi1sk: string | null,
        gsi2pk: string | null,
        gsi2sk: string | null,
        gsi3pk: string | null,
        gsi3sk: string | null,
        eventName: string,
        userId: string,
        timestamp: string,
        period: string,
        count: number | null,
        siteId: string | null,
        teamId: string | null,
        orgId: string | null,
        amhpId: string | null,
        doctorId: string | null,
        claimProcessorId: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    summaryReports:  {
      __typename: "ModelReportingConnection",
      items:  Array< {
        __typename: "Reporting",
        id: string,
        pk: string,
        sk: string,
        ttl: number | null,
        gsi1pk: string | null,
        gsi1sk: string | null,
        gsi2pk: string | null,
        gsi2sk: string | null,
        gsi3pk: string | null,
        gsi3sk: string | null,
        eventName: string,
        userId: string,
        timestamp: string,
        period: string,
        count: number | null,
        siteId: string | null,
        teamId: string | null,
        orgId: string | null,
        amhpId: string | null,
        doctorId: string | null,
        claimProcessorId: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type UpdateAmhpProfileMutationVariables = {
  input: UpdateAmhpProfileInput,
};

export type UpdateAmhpProfileMutation = {
  updateAmhpProfile:  {
    __typename: "AmhpProfile",
    id: string,
    email: string,
    phone: string | null,
    name: string,
    legalName: string | null,
    deleted: boolean | null,
    warrantInfo: string | null,
    accreditation: string | null,
    sectionFormAddress:  {
      __typename: "LocationName",
      city: string | null,
      postcode: string,
      address: string | null,
      addressNotes: string | null,
    } | null,
    sectionFormEmailAddress: string | null,
    sectionFormContactNumber: string | null,
    assessments:  {
      __typename: "ModelAssessmentConnection",
      items:  Array< {
        __typename: "Assessment",
        id: string,
        createdAt: string,
        amhpTeamId: string,
        amhpId: string,
        patientName: string,
        ccgId: string | null,
        ccgDetermination: LocationType | null,
        hadVisitAdded: boolean | null,
        patientNhsNumber: string | null,
        assessmentDate: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    pushTokens: Array< string | null > | null,
    teams:  {
      __typename: "ModelAmhpTeamUserConnection",
      items:  Array< {
        __typename: "AmhpTeamUser",
        id: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
    lastActivity: string | null,
    createdAt: string | null,
    reports:  {
      __typename: "ModelReportingConnection",
      items:  Array< {
        __typename: "Reporting",
        id: string,
        pk: string,
        sk: string,
        ttl: number | null,
        gsi1pk: string | null,
        gsi1sk: string | null,
        gsi2pk: string | null,
        gsi2sk: string | null,
        gsi3pk: string | null,
        gsi3sk: string | null,
        eventName: string,
        userId: string,
        timestamp: string,
        period: string,
        count: number | null,
        siteId: string | null,
        teamId: string | null,
        orgId: string | null,
        amhpId: string | null,
        doctorId: string | null,
        claimProcessorId: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    summaryReports:  {
      __typename: "ModelReportingConnection",
      items:  Array< {
        __typename: "Reporting",
        id: string,
        pk: string,
        sk: string,
        ttl: number | null,
        gsi1pk: string | null,
        gsi1sk: string | null,
        gsi2pk: string | null,
        gsi2sk: string | null,
        gsi3pk: string | null,
        gsi3sk: string | null,
        eventName: string,
        userId: string,
        timestamp: string,
        period: string,
        count: number | null,
        siteId: string | null,
        teamId: string | null,
        orgId: string | null,
        amhpId: string | null,
        doctorId: string | null,
        claimProcessorId: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type DeleteAmhpProfileMutationVariables = {
  input: DeleteAmhpProfileInput,
};

export type DeleteAmhpProfileMutation = {
  deleteAmhpProfile:  {
    __typename: "AmhpProfile",
    id: string,
    email: string,
    phone: string | null,
    name: string,
    legalName: string | null,
    deleted: boolean | null,
    warrantInfo: string | null,
    accreditation: string | null,
    sectionFormAddress:  {
      __typename: "LocationName",
      city: string | null,
      postcode: string,
      address: string | null,
      addressNotes: string | null,
    } | null,
    sectionFormEmailAddress: string | null,
    sectionFormContactNumber: string | null,
    assessments:  {
      __typename: "ModelAssessmentConnection",
      items:  Array< {
        __typename: "Assessment",
        id: string,
        createdAt: string,
        amhpTeamId: string,
        amhpId: string,
        patientName: string,
        ccgId: string | null,
        ccgDetermination: LocationType | null,
        hadVisitAdded: boolean | null,
        patientNhsNumber: string | null,
        assessmentDate: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    pushTokens: Array< string | null > | null,
    teams:  {
      __typename: "ModelAmhpTeamUserConnection",
      items:  Array< {
        __typename: "AmhpTeamUser",
        id: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
    lastActivity: string | null,
    createdAt: string | null,
    reports:  {
      __typename: "ModelReportingConnection",
      items:  Array< {
        __typename: "Reporting",
        id: string,
        pk: string,
        sk: string,
        ttl: number | null,
        gsi1pk: string | null,
        gsi1sk: string | null,
        gsi2pk: string | null,
        gsi2sk: string | null,
        gsi3pk: string | null,
        gsi3sk: string | null,
        eventName: string,
        userId: string,
        timestamp: string,
        period: string,
        count: number | null,
        siteId: string | null,
        teamId: string | null,
        orgId: string | null,
        amhpId: string | null,
        doctorId: string | null,
        claimProcessorId: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    summaryReports:  {
      __typename: "ModelReportingConnection",
      items:  Array< {
        __typename: "Reporting",
        id: string,
        pk: string,
        sk: string,
        ttl: number | null,
        gsi1pk: string | null,
        gsi1sk: string | null,
        gsi2pk: string | null,
        gsi2sk: string | null,
        gsi3pk: string | null,
        gsi3sk: string | null,
        eventName: string,
        userId: string,
        timestamp: string,
        period: string,
        count: number | null,
        siteId: string | null,
        teamId: string | null,
        orgId: string | null,
        amhpId: string | null,
        doctorId: string | null,
        claimProcessorId: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type CreateCcgMutationVariables = {
  input: CreateCCGInput,
};

export type CreateCcgMutation = {
  createCCG:  {
    __typename: "CCG",
    id: string,
    claimProcessorType: OrgType | null,
    claimProcessor:  {
      __typename: "Organisation",
      id: string,
      name: string,
      featureFlags: string | null,
      features: string | null,
      type: OrgType,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      } | null,
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      phone: string | null,
      email: string | null,
      users:  {
        __typename: "ModelOrganisationUserConnection",
        nextToken: string | null,
      } | null,
      sites:  {
        __typename: "ModelSiteOrganisationConnection",
        nextToken: string | null,
      } | null,
      ccg:  {
        __typename: "CCG",
        id: string,
        claimProcessorType: OrgType | null,
        cCGOrganisationId: string,
      } | null,
      mht:  {
        __typename: "MHT",
        id: string,
        abbreviation: string,
        mHTOrganisationId: string,
      } | null,
      claims:  {
        __typename: "ModelClaimConnection",
        nextToken: string | null,
      } | null,
      expensesInstruction: string | null,
      declarationText: string | null,
      additionalNotesText: string | null,
      paymentRates:  Array< {
        __typename: "PaymentRates",
        description: string | null,
        assessmentFee: number,
        mileage: number,
      } | null > | null,
      invoiceEmail: string | null,
      updatedBy: string | null,
      mhtHospitalEmails:  Array< {
        __typename: "HospitalEmails",
        name: string,
      } | null > | null,
      rotas:  Array< {
        __typename: "RotaItem",
        id: string,
        name: string,
      } | null > | null,
      deleted: boolean | null,
    } | null,
    cCGOrganisationId: string,
    organisation:  {
      __typename: "Organisation",
      id: string,
      name: string,
      featureFlags: string | null,
      features: string | null,
      type: OrgType,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      } | null,
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      phone: string | null,
      email: string | null,
      users:  {
        __typename: "ModelOrganisationUserConnection",
        nextToken: string | null,
      } | null,
      sites:  {
        __typename: "ModelSiteOrganisationConnection",
        nextToken: string | null,
      } | null,
      ccg:  {
        __typename: "CCG",
        id: string,
        claimProcessorType: OrgType | null,
        cCGOrganisationId: string,
      } | null,
      mht:  {
        __typename: "MHT",
        id: string,
        abbreviation: string,
        mHTOrganisationId: string,
      } | null,
      claims:  {
        __typename: "ModelClaimConnection",
        nextToken: string | null,
      } | null,
      expensesInstruction: string | null,
      declarationText: string | null,
      additionalNotesText: string | null,
      paymentRates:  Array< {
        __typename: "PaymentRates",
        description: string | null,
        assessmentFee: number,
        mileage: number,
      } | null > | null,
      invoiceEmail: string | null,
      updatedBy: string | null,
      mhtHospitalEmails:  Array< {
        __typename: "HospitalEmails",
        name: string,
      } | null > | null,
      rotas:  Array< {
        __typename: "RotaItem",
        id: string,
        name: string,
      } | null > | null,
      deleted: boolean | null,
    },
  } | null,
};

export type UpdateCcgMutationVariables = {
  input: UpdateCCGInput,
};

export type UpdateCcgMutation = {
  updateCCG:  {
    __typename: "CCG",
    id: string,
    claimProcessorType: OrgType | null,
    claimProcessor:  {
      __typename: "Organisation",
      id: string,
      name: string,
      featureFlags: string | null,
      features: string | null,
      type: OrgType,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      } | null,
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      phone: string | null,
      email: string | null,
      users:  {
        __typename: "ModelOrganisationUserConnection",
        nextToken: string | null,
      } | null,
      sites:  {
        __typename: "ModelSiteOrganisationConnection",
        nextToken: string | null,
      } | null,
      ccg:  {
        __typename: "CCG",
        id: string,
        claimProcessorType: OrgType | null,
        cCGOrganisationId: string,
      } | null,
      mht:  {
        __typename: "MHT",
        id: string,
        abbreviation: string,
        mHTOrganisationId: string,
      } | null,
      claims:  {
        __typename: "ModelClaimConnection",
        nextToken: string | null,
      } | null,
      expensesInstruction: string | null,
      declarationText: string | null,
      additionalNotesText: string | null,
      paymentRates:  Array< {
        __typename: "PaymentRates",
        description: string | null,
        assessmentFee: number,
        mileage: number,
      } | null > | null,
      invoiceEmail: string | null,
      updatedBy: string | null,
      mhtHospitalEmails:  Array< {
        __typename: "HospitalEmails",
        name: string,
      } | null > | null,
      rotas:  Array< {
        __typename: "RotaItem",
        id: string,
        name: string,
      } | null > | null,
      deleted: boolean | null,
    } | null,
    cCGOrganisationId: string,
    organisation:  {
      __typename: "Organisation",
      id: string,
      name: string,
      featureFlags: string | null,
      features: string | null,
      type: OrgType,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      } | null,
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      phone: string | null,
      email: string | null,
      users:  {
        __typename: "ModelOrganisationUserConnection",
        nextToken: string | null,
      } | null,
      sites:  {
        __typename: "ModelSiteOrganisationConnection",
        nextToken: string | null,
      } | null,
      ccg:  {
        __typename: "CCG",
        id: string,
        claimProcessorType: OrgType | null,
        cCGOrganisationId: string,
      } | null,
      mht:  {
        __typename: "MHT",
        id: string,
        abbreviation: string,
        mHTOrganisationId: string,
      } | null,
      claims:  {
        __typename: "ModelClaimConnection",
        nextToken: string | null,
      } | null,
      expensesInstruction: string | null,
      declarationText: string | null,
      additionalNotesText: string | null,
      paymentRates:  Array< {
        __typename: "PaymentRates",
        description: string | null,
        assessmentFee: number,
        mileage: number,
      } | null > | null,
      invoiceEmail: string | null,
      updatedBy: string | null,
      mhtHospitalEmails:  Array< {
        __typename: "HospitalEmails",
        name: string,
      } | null > | null,
      rotas:  Array< {
        __typename: "RotaItem",
        id: string,
        name: string,
      } | null > | null,
      deleted: boolean | null,
    },
  } | null,
};

export type DeleteCcgMutationVariables = {
  input: DeleteCCGInput,
};

export type DeleteCcgMutation = {
  deleteCCG:  {
    __typename: "CCG",
    id: string,
    claimProcessorType: OrgType | null,
    claimProcessor:  {
      __typename: "Organisation",
      id: string,
      name: string,
      featureFlags: string | null,
      features: string | null,
      type: OrgType,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      } | null,
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      phone: string | null,
      email: string | null,
      users:  {
        __typename: "ModelOrganisationUserConnection",
        nextToken: string | null,
      } | null,
      sites:  {
        __typename: "ModelSiteOrganisationConnection",
        nextToken: string | null,
      } | null,
      ccg:  {
        __typename: "CCG",
        id: string,
        claimProcessorType: OrgType | null,
        cCGOrganisationId: string,
      } | null,
      mht:  {
        __typename: "MHT",
        id: string,
        abbreviation: string,
        mHTOrganisationId: string,
      } | null,
      claims:  {
        __typename: "ModelClaimConnection",
        nextToken: string | null,
      } | null,
      expensesInstruction: string | null,
      declarationText: string | null,
      additionalNotesText: string | null,
      paymentRates:  Array< {
        __typename: "PaymentRates",
        description: string | null,
        assessmentFee: number,
        mileage: number,
      } | null > | null,
      invoiceEmail: string | null,
      updatedBy: string | null,
      mhtHospitalEmails:  Array< {
        __typename: "HospitalEmails",
        name: string,
      } | null > | null,
      rotas:  Array< {
        __typename: "RotaItem",
        id: string,
        name: string,
      } | null > | null,
      deleted: boolean | null,
    } | null,
    cCGOrganisationId: string,
    organisation:  {
      __typename: "Organisation",
      id: string,
      name: string,
      featureFlags: string | null,
      features: string | null,
      type: OrgType,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      } | null,
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      phone: string | null,
      email: string | null,
      users:  {
        __typename: "ModelOrganisationUserConnection",
        nextToken: string | null,
      } | null,
      sites:  {
        __typename: "ModelSiteOrganisationConnection",
        nextToken: string | null,
      } | null,
      ccg:  {
        __typename: "CCG",
        id: string,
        claimProcessorType: OrgType | null,
        cCGOrganisationId: string,
      } | null,
      mht:  {
        __typename: "MHT",
        id: string,
        abbreviation: string,
        mHTOrganisationId: string,
      } | null,
      claims:  {
        __typename: "ModelClaimConnection",
        nextToken: string | null,
      } | null,
      expensesInstruction: string | null,
      declarationText: string | null,
      additionalNotesText: string | null,
      paymentRates:  Array< {
        __typename: "PaymentRates",
        description: string | null,
        assessmentFee: number,
        mileage: number,
      } | null > | null,
      invoiceEmail: string | null,
      updatedBy: string | null,
      mhtHospitalEmails:  Array< {
        __typename: "HospitalEmails",
        name: string,
      } | null > | null,
      rotas:  Array< {
        __typename: "RotaItem",
        id: string,
        name: string,
      } | null > | null,
      deleted: boolean | null,
    },
  } | null,
};

export type CreateReportingMutationVariables = {
  input: CreateReportingInput,
};

export type CreateReportingMutation = {
  createReporting:  {
    __typename: "Reporting",
    id: string,
    pk: string,
    sk: string,
    ttl: number | null,
    gsi1pk: string | null,
    gsi1sk: string | null,
    gsi2pk: string | null,
    gsi2sk: string | null,
    gsi3pk: string | null,
    gsi3sk: string | null,
    eventName: string,
    userId: string,
    timestamp: string,
    period: string,
    count: number | null,
    siteId: string | null,
    teamId: string | null,
    orgId: string | null,
    amhpId: string | null,
    amhp:  {
      __typename: "AmhpProfile",
      id: string,
      email: string,
      phone: string | null,
      name: string,
      legalName: string | null,
      deleted: boolean | null,
      warrantInfo: string | null,
      accreditation: string | null,
      sectionFormAddress:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      sectionFormEmailAddress: string | null,
      sectionFormContactNumber: string | null,
      assessments:  {
        __typename: "ModelAssessmentConnection",
        nextToken: string | null,
      } | null,
      pushTokens: Array< string | null > | null,
      teams:  {
        __typename: "ModelAmhpTeamUserConnection",
        nextToken: string | null,
      } | null,
      lastActivity: string | null,
      createdAt: string | null,
      reports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      summaryReports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
    } | null,
    doctorId: string | null,
    doctor:  {
      __typename: "S12Doctor",
      id: string,
      gmc: string,
      s12Expiry: string | null,
      name: string,
      legalName: string,
      phone: string | null,
      phone2: string | null,
      email: string,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      },
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      },
      gl: boolean | null,
      employer: string | null,
      jobTitle: string | null,
      responsibleOfficer: string | null,
      lineManager: string | null,
      defaultMHT: string | null,
      mhtEmployers:  {
        __typename: "ModelMHTS12DoctorConnection",
        nextToken: string | null,
      } | null,
      availabilities:  {
        __typename: "ModelAvailabilityConnection",
        nextToken: string | null,
      } | null,
      holidays:  {
        __typename: "ModelHolidayConnection",
        nextToken: string | null,
      } | null,
      specialties: Array< string | null >,
      languages: Array< string | null >,
      gender: Gender | null,
      notes: string | null,
      pushTokens: Array< string | null > | null,
      distance: number | null,
      hideFromSearch: boolean | null,
      visits:  {
        __typename: "ModelDoctorVisitConnection",
        nextToken: string | null,
      } | null,
      doctors:  {
        __typename: "ModelContactedDoctorConnection",
        nextToken: string | null,
      } | null,
      claims:  {
        __typename: "ModelClaimConnection",
        nextToken: string | null,
      } | null,
      availabilityLocations:  Array< {
        __typename: "Location",
        lat: number,
        lon: number,
      } | null > | null,
      availabilityPostcodes: Array< string | null > | null,
      siteName: string,
      claimData:  {
        __typename: "ClaimData",
        billingCompanyName: string | null,
        vatRegistration: string | null,
        fuelType: FuelType | null,
        engineSize: Engine | null,
      } | null,
      sectionFormAddress:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      sectionFormEmailAddress: string | null,
      reports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      summaryReports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      createdAt: string | null,
      lastActivity: string | null,
      isOutsideEngland: boolean | null,
    } | null,
    claimProcessorId: string | null,
    claimProcessor:  {
      __typename: "OrganisationUser",
      id: string,
      organisationUserOrganisationId: string,
      type: string,
      organisation:  {
        __typename: "Organisation",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        type: OrgType,
        phone: string | null,
        email: string | null,
        expensesInstruction: string | null,
        declarationText: string | null,
        additionalNotesText: string | null,
        invoiceEmail: string | null,
        updatedBy: string | null,
        deleted: boolean | null,
      },
      organisationUserUserId: string,
      user:  {
        __typename: "User",
        id: string,
        name: string,
        email: string,
        phone: string | null,
      },
    } | null,
    attributes:  Array< {
      __typename: "Attribute",
      name: string,
      value: string,
    } > | null,
    metrics:  Array< {
      __typename: "Metric",
      name: string,
      value: number,
    } > | null,
  } | null,
};

export type UpdateReportingMutationVariables = {
  input: UpdateReportingInput,
};

export type UpdateReportingMutation = {
  updateReporting:  {
    __typename: "Reporting",
    id: string,
    pk: string,
    sk: string,
    ttl: number | null,
    gsi1pk: string | null,
    gsi1sk: string | null,
    gsi2pk: string | null,
    gsi2sk: string | null,
    gsi3pk: string | null,
    gsi3sk: string | null,
    eventName: string,
    userId: string,
    timestamp: string,
    period: string,
    count: number | null,
    siteId: string | null,
    teamId: string | null,
    orgId: string | null,
    amhpId: string | null,
    amhp:  {
      __typename: "AmhpProfile",
      id: string,
      email: string,
      phone: string | null,
      name: string,
      legalName: string | null,
      deleted: boolean | null,
      warrantInfo: string | null,
      accreditation: string | null,
      sectionFormAddress:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      sectionFormEmailAddress: string | null,
      sectionFormContactNumber: string | null,
      assessments:  {
        __typename: "ModelAssessmentConnection",
        nextToken: string | null,
      } | null,
      pushTokens: Array< string | null > | null,
      teams:  {
        __typename: "ModelAmhpTeamUserConnection",
        nextToken: string | null,
      } | null,
      lastActivity: string | null,
      createdAt: string | null,
      reports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      summaryReports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
    } | null,
    doctorId: string | null,
    doctor:  {
      __typename: "S12Doctor",
      id: string,
      gmc: string,
      s12Expiry: string | null,
      name: string,
      legalName: string,
      phone: string | null,
      phone2: string | null,
      email: string,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      },
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      },
      gl: boolean | null,
      employer: string | null,
      jobTitle: string | null,
      responsibleOfficer: string | null,
      lineManager: string | null,
      defaultMHT: string | null,
      mhtEmployers:  {
        __typename: "ModelMHTS12DoctorConnection",
        nextToken: string | null,
      } | null,
      availabilities:  {
        __typename: "ModelAvailabilityConnection",
        nextToken: string | null,
      } | null,
      holidays:  {
        __typename: "ModelHolidayConnection",
        nextToken: string | null,
      } | null,
      specialties: Array< string | null >,
      languages: Array< string | null >,
      gender: Gender | null,
      notes: string | null,
      pushTokens: Array< string | null > | null,
      distance: number | null,
      hideFromSearch: boolean | null,
      visits:  {
        __typename: "ModelDoctorVisitConnection",
        nextToken: string | null,
      } | null,
      doctors:  {
        __typename: "ModelContactedDoctorConnection",
        nextToken: string | null,
      } | null,
      claims:  {
        __typename: "ModelClaimConnection",
        nextToken: string | null,
      } | null,
      availabilityLocations:  Array< {
        __typename: "Location",
        lat: number,
        lon: number,
      } | null > | null,
      availabilityPostcodes: Array< string | null > | null,
      siteName: string,
      claimData:  {
        __typename: "ClaimData",
        billingCompanyName: string | null,
        vatRegistration: string | null,
        fuelType: FuelType | null,
        engineSize: Engine | null,
      } | null,
      sectionFormAddress:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      sectionFormEmailAddress: string | null,
      reports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      summaryReports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      createdAt: string | null,
      lastActivity: string | null,
      isOutsideEngland: boolean | null,
    } | null,
    claimProcessorId: string | null,
    claimProcessor:  {
      __typename: "OrganisationUser",
      id: string,
      organisationUserOrganisationId: string,
      type: string,
      organisation:  {
        __typename: "Organisation",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        type: OrgType,
        phone: string | null,
        email: string | null,
        expensesInstruction: string | null,
        declarationText: string | null,
        additionalNotesText: string | null,
        invoiceEmail: string | null,
        updatedBy: string | null,
        deleted: boolean | null,
      },
      organisationUserUserId: string,
      user:  {
        __typename: "User",
        id: string,
        name: string,
        email: string,
        phone: string | null,
      },
    } | null,
    attributes:  Array< {
      __typename: "Attribute",
      name: string,
      value: string,
    } > | null,
    metrics:  Array< {
      __typename: "Metric",
      name: string,
      value: number,
    } > | null,
  } | null,
};

export type DeleteReportingMutationVariables = {
  input: DeleteReportingInput,
};

export type DeleteReportingMutation = {
  deleteReporting:  {
    __typename: "Reporting",
    id: string,
    pk: string,
    sk: string,
    ttl: number | null,
    gsi1pk: string | null,
    gsi1sk: string | null,
    gsi2pk: string | null,
    gsi2sk: string | null,
    gsi3pk: string | null,
    gsi3sk: string | null,
    eventName: string,
    userId: string,
    timestamp: string,
    period: string,
    count: number | null,
    siteId: string | null,
    teamId: string | null,
    orgId: string | null,
    amhpId: string | null,
    amhp:  {
      __typename: "AmhpProfile",
      id: string,
      email: string,
      phone: string | null,
      name: string,
      legalName: string | null,
      deleted: boolean | null,
      warrantInfo: string | null,
      accreditation: string | null,
      sectionFormAddress:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      sectionFormEmailAddress: string | null,
      sectionFormContactNumber: string | null,
      assessments:  {
        __typename: "ModelAssessmentConnection",
        nextToken: string | null,
      } | null,
      pushTokens: Array< string | null > | null,
      teams:  {
        __typename: "ModelAmhpTeamUserConnection",
        nextToken: string | null,
      } | null,
      lastActivity: string | null,
      createdAt: string | null,
      reports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      summaryReports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
    } | null,
    doctorId: string | null,
    doctor:  {
      __typename: "S12Doctor",
      id: string,
      gmc: string,
      s12Expiry: string | null,
      name: string,
      legalName: string,
      phone: string | null,
      phone2: string | null,
      email: string,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      },
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      },
      gl: boolean | null,
      employer: string | null,
      jobTitle: string | null,
      responsibleOfficer: string | null,
      lineManager: string | null,
      defaultMHT: string | null,
      mhtEmployers:  {
        __typename: "ModelMHTS12DoctorConnection",
        nextToken: string | null,
      } | null,
      availabilities:  {
        __typename: "ModelAvailabilityConnection",
        nextToken: string | null,
      } | null,
      holidays:  {
        __typename: "ModelHolidayConnection",
        nextToken: string | null,
      } | null,
      specialties: Array< string | null >,
      languages: Array< string | null >,
      gender: Gender | null,
      notes: string | null,
      pushTokens: Array< string | null > | null,
      distance: number | null,
      hideFromSearch: boolean | null,
      visits:  {
        __typename: "ModelDoctorVisitConnection",
        nextToken: string | null,
      } | null,
      doctors:  {
        __typename: "ModelContactedDoctorConnection",
        nextToken: string | null,
      } | null,
      claims:  {
        __typename: "ModelClaimConnection",
        nextToken: string | null,
      } | null,
      availabilityLocations:  Array< {
        __typename: "Location",
        lat: number,
        lon: number,
      } | null > | null,
      availabilityPostcodes: Array< string | null > | null,
      siteName: string,
      claimData:  {
        __typename: "ClaimData",
        billingCompanyName: string | null,
        vatRegistration: string | null,
        fuelType: FuelType | null,
        engineSize: Engine | null,
      } | null,
      sectionFormAddress:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      sectionFormEmailAddress: string | null,
      reports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      summaryReports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      createdAt: string | null,
      lastActivity: string | null,
      isOutsideEngland: boolean | null,
    } | null,
    claimProcessorId: string | null,
    claimProcessor:  {
      __typename: "OrganisationUser",
      id: string,
      organisationUserOrganisationId: string,
      type: string,
      organisation:  {
        __typename: "Organisation",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        type: OrgType,
        phone: string | null,
        email: string | null,
        expensesInstruction: string | null,
        declarationText: string | null,
        additionalNotesText: string | null,
        invoiceEmail: string | null,
        updatedBy: string | null,
        deleted: boolean | null,
      },
      organisationUserUserId: string,
      user:  {
        __typename: "User",
        id: string,
        name: string,
        email: string,
        phone: string | null,
      },
    } | null,
    attributes:  Array< {
      __typename: "Attribute",
      name: string,
      value: string,
    } > | null,
    metrics:  Array< {
      __typename: "Metric",
      name: string,
      value: number,
    } > | null,
  } | null,
};

export type GetLinkedChildSitesQueryVariables = {
  siteId: string,
};

export type GetLinkedChildSitesQuery = {
  getLinkedChildSites: Array< string | null > | null,
};

export type NearbyS12DoctorsQueryVariables = {
  location: LocationInput,
  distance: number,
  limit?: number | null,
  nextToken?: string | null,
};

export type NearbyS12DoctorsQuery = {
  nearbyS12Doctors:  {
    __typename: "S12DoctorConnection",
    items:  Array< {
      __typename: "S12Doctor",
      id: string,
      gmc: string,
      s12Expiry: string | null,
      name: string,
      legalName: string,
      phone: string | null,
      phone2: string | null,
      email: string,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      },
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      },
      gl: boolean | null,
      employer: string | null,
      jobTitle: string | null,
      responsibleOfficer: string | null,
      lineManager: string | null,
      defaultMHT: string | null,
      mhtEmployers:  {
        __typename: "ModelMHTS12DoctorConnection",
        nextToken: string | null,
      } | null,
      availabilities:  {
        __typename: "ModelAvailabilityConnection",
        nextToken: string | null,
      } | null,
      holidays:  {
        __typename: "ModelHolidayConnection",
        nextToken: string | null,
      } | null,
      specialties: Array< string | null >,
      languages: Array< string | null >,
      gender: Gender | null,
      notes: string | null,
      pushTokens: Array< string | null > | null,
      distance: number | null,
      hideFromSearch: boolean | null,
      visits:  {
        __typename: "ModelDoctorVisitConnection",
        nextToken: string | null,
      } | null,
      doctors:  {
        __typename: "ModelContactedDoctorConnection",
        nextToken: string | null,
      } | null,
      claims:  {
        __typename: "ModelClaimConnection",
        nextToken: string | null,
      } | null,
      availabilityLocations:  Array< {
        __typename: "Location",
        lat: number,
        lon: number,
      } | null > | null,
      availabilityPostcodes: Array< string | null > | null,
      siteName: string,
      claimData:  {
        __typename: "ClaimData",
        billingCompanyName: string | null,
        vatRegistration: string | null,
        fuelType: FuelType | null,
        engineSize: Engine | null,
      } | null,
      sectionFormAddress:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      sectionFormEmailAddress: string | null,
      reports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      summaryReports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      createdAt: string | null,
      lastActivity: string | null,
      isOutsideEngland: boolean | null,
    } | null > | null,
    total: number | null,
    nextToken: string | null,
  } | null,
};

export type GetExternalFormQueryVariables = {
  id: string,
  sk: string,
};

export type GetExternalFormQuery = {
  getExternalForm:  {
    __typename: "Form",
    id: string,
    sk: string,
    ttl: number,
    version: number,
    gsi1pk: string,
    gsi1sk: string,
    gsi2pk: string,
    gsi2sk: string,
    gsi3pk: string,
    gsi3sk: string,
    assessmentId: string,
    assessment:  {
      __typename: "Assessment",
      id: string,
      createdAt: string,
      amhpTeamId: string,
      amhpTeam:  {
        __typename: "AmhpTeam",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        localAuthority: string | null,
        emails: Array< string > | null,
        phoneNumbers: Array< string > | null,
        mhaInboxInstructions: string | null,
        updatedBy: string | null,
      },
      amhpId: string,
      amhp:  {
        __typename: "AmhpProfile",
        id: string,
        email: string,
        phone: string | null,
        name: string,
        legalName: string | null,
        deleted: boolean | null,
        warrantInfo: string | null,
        accreditation: string | null,
        sectionFormEmailAddress: string | null,
        sectionFormContactNumber: string | null,
        pushTokens: Array< string | null > | null,
        lastActivity: string | null,
        createdAt: string | null,
      },
      patientName: string,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      },
      ccgId: string | null,
      ccg:  {
        __typename: "Organisation",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        type: OrgType,
        phone: string | null,
        email: string | null,
        expensesInstruction: string | null,
        declarationText: string | null,
        additionalNotesText: string | null,
        invoiceEmail: string | null,
        updatedBy: string | null,
        deleted: boolean | null,
      } | null,
      ccgDetermination: LocationType | null,
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      },
      hadVisitAdded: boolean | null,
      doctorVisits:  {
        __typename: "ModelDoctorVisitConnection",
        nextToken: string | null,
      } | null,
      patientNhsNumber: string | null,
      assessmentDate: string | null,
      contactedDoctors:  {
        __typename: "ModelContactedDoctorConnection",
        nextToken: string | null,
      } | null,
      forms:  {
        __typename: "ModelFormConnection",
        nextToken: string | null,
      } | null,
      patientAddress:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
    } | null,
    status: FormStatus,
    createdBy: string,
    createdAt: string,
    updatedAt: string,
    updatedBy: string,
    amhpTeamInboxId: string,
    amhpTeamId: string,
    authors: Array< string >,
    doctorNames: Array< string > | null,
    signed: Array< string > | null,
    signedClaims: Array< string > | null,
    sha256: string | null,
    notes: Array< string >,
    type: string,
    data:  {
      __typename: "FormData",
      sections:  Array< {
        __typename: "FormSection",
        name: string,
      } >,
    } | null,
    isDeleted: boolean | null,
    amhpTeam:  {
      __typename: "AmhpTeam",
      id: string,
      name: string,
      featureFlags: string | null,
      features: string | null,
      assessments:  {
        __typename: "ModelAssessmentConnection",
        nextToken: string | null,
      } | null,
      users:  {
        __typename: "ModelAmhpTeamUserConnection",
        nextToken: string | null,
      } | null,
      commonAddresses:  {
        __typename: "ModelAddressConnection",
        nextToken: string | null,
      } | null,
      localAuthority: string | null,
      hospitalEmails:  Array< {
        __typename: "HospitalEmails",
        name: string,
      } > | null,
      mhaEmailAddresses:  Array< {
        __typename: "RecipientEmails",
        name: string | null,
      } | null > | null,
      emails: Array< string > | null,
      phoneNumbers: Array< string > | null,
      mhaInboxInstructions: string | null,
      reports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      summaryReports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      updatedBy: string | null,
    } | null,
  } | null,
};

export type FindDoctorsQueryVariables = {
  location: LocationInput,
  distance: number,
  limit?: number | null,
  nextToken?: string | null,
  doctorName?: string | null,
};

export type FindDoctorsQuery = {
  findDoctors:  {
    __typename: "S12DoctorConnection",
    items:  Array< {
      __typename: "S12Doctor",
      id: string,
      gmc: string,
      s12Expiry: string | null,
      name: string,
      legalName: string,
      phone: string | null,
      phone2: string | null,
      email: string,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      },
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      },
      gl: boolean | null,
      employer: string | null,
      jobTitle: string | null,
      responsibleOfficer: string | null,
      lineManager: string | null,
      defaultMHT: string | null,
      mhtEmployers:  {
        __typename: "ModelMHTS12DoctorConnection",
        nextToken: string | null,
      } | null,
      availabilities:  {
        __typename: "ModelAvailabilityConnection",
        nextToken: string | null,
      } | null,
      holidays:  {
        __typename: "ModelHolidayConnection",
        nextToken: string | null,
      } | null,
      specialties: Array< string | null >,
      languages: Array< string | null >,
      gender: Gender | null,
      notes: string | null,
      pushTokens: Array< string | null > | null,
      distance: number | null,
      hideFromSearch: boolean | null,
      visits:  {
        __typename: "ModelDoctorVisitConnection",
        nextToken: string | null,
      } | null,
      doctors:  {
        __typename: "ModelContactedDoctorConnection",
        nextToken: string | null,
      } | null,
      claims:  {
        __typename: "ModelClaimConnection",
        nextToken: string | null,
      } | null,
      availabilityLocations:  Array< {
        __typename: "Location",
        lat: number,
        lon: number,
      } | null > | null,
      availabilityPostcodes: Array< string | null > | null,
      siteName: string,
      claimData:  {
        __typename: "ClaimData",
        billingCompanyName: string | null,
        vatRegistration: string | null,
        fuelType: FuelType | null,
        engineSize: Engine | null,
      } | null,
      sectionFormAddress:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      sectionFormEmailAddress: string | null,
      reports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      summaryReports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      createdAt: string | null,
      lastActivity: string | null,
      isOutsideEngland: boolean | null,
    } | null > | null,
    total: number | null,
    nextToken: string | null,
  } | null,
};

export type MyProfileQuery = {
  myProfile:  {
    __typename: "AmhpProfile",
    id: string,
    email: string,
    phone: string | null,
    name: string,
    legalName: string | null,
    deleted: boolean | null,
    warrantInfo: string | null,
    accreditation: string | null,
    sectionFormAddress:  {
      __typename: "LocationName",
      city: string | null,
      postcode: string,
      address: string | null,
      addressNotes: string | null,
    } | null,
    sectionFormEmailAddress: string | null,
    sectionFormContactNumber: string | null,
    assessments:  {
      __typename: "ModelAssessmentConnection",
      items:  Array< {
        __typename: "Assessment",
        id: string,
        createdAt: string,
        amhpTeamId: string,
        amhpId: string,
        patientName: string,
        ccgId: string | null,
        ccgDetermination: LocationType | null,
        hadVisitAdded: boolean | null,
        patientNhsNumber: string | null,
        assessmentDate: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    pushTokens: Array< string | null > | null,
    teams:  {
      __typename: "ModelAmhpTeamUserConnection",
      items:  Array< {
        __typename: "AmhpTeamUser",
        id: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
    lastActivity: string | null,
    createdAt: string | null,
    reports:  {
      __typename: "ModelReportingConnection",
      items:  Array< {
        __typename: "Reporting",
        id: string,
        pk: string,
        sk: string,
        ttl: number | null,
        gsi1pk: string | null,
        gsi1sk: string | null,
        gsi2pk: string | null,
        gsi2sk: string | null,
        gsi3pk: string | null,
        gsi3sk: string | null,
        eventName: string,
        userId: string,
        timestamp: string,
        period: string,
        count: number | null,
        siteId: string | null,
        teamId: string | null,
        orgId: string | null,
        amhpId: string | null,
        doctorId: string | null,
        claimProcessorId: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    summaryReports:  {
      __typename: "ModelReportingConnection",
      items:  Array< {
        __typename: "Reporting",
        id: string,
        pk: string,
        sk: string,
        ttl: number | null,
        gsi1pk: string | null,
        gsi1sk: string | null,
        gsi2pk: string | null,
        gsi2sk: string | null,
        gsi3pk: string | null,
        gsi3sk: string | null,
        eventName: string,
        userId: string,
        timestamp: string,
        period: string,
        count: number | null,
        siteId: string | null,
        teamId: string | null,
        orgId: string | null,
        amhpId: string | null,
        doctorId: string | null,
        claimProcessorId: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type AmhpAssessmentQueryVariables = {
  id: string,
};

export type AmhpAssessmentQuery = {
  amhpAssessment:  {
    __typename: "Assessment",
    id: string,
    createdAt: string,
    amhpTeamId: string,
    amhpTeam:  {
      __typename: "AmhpTeam",
      id: string,
      name: string,
      featureFlags: string | null,
      features: string | null,
      assessments:  {
        __typename: "ModelAssessmentConnection",
        nextToken: string | null,
      } | null,
      users:  {
        __typename: "ModelAmhpTeamUserConnection",
        nextToken: string | null,
      } | null,
      commonAddresses:  {
        __typename: "ModelAddressConnection",
        nextToken: string | null,
      } | null,
      localAuthority: string | null,
      hospitalEmails:  Array< {
        __typename: "HospitalEmails",
        name: string,
      } > | null,
      mhaEmailAddresses:  Array< {
        __typename: "RecipientEmails",
        name: string | null,
      } | null > | null,
      emails: Array< string > | null,
      phoneNumbers: Array< string > | null,
      mhaInboxInstructions: string | null,
      reports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      summaryReports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      updatedBy: string | null,
    },
    amhpId: string,
    amhp:  {
      __typename: "AmhpProfile",
      id: string,
      email: string,
      phone: string | null,
      name: string,
      legalName: string | null,
      deleted: boolean | null,
      warrantInfo: string | null,
      accreditation: string | null,
      sectionFormAddress:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      sectionFormEmailAddress: string | null,
      sectionFormContactNumber: string | null,
      assessments:  {
        __typename: "ModelAssessmentConnection",
        nextToken: string | null,
      } | null,
      pushTokens: Array< string | null > | null,
      teams:  {
        __typename: "ModelAmhpTeamUserConnection",
        nextToken: string | null,
      } | null,
      lastActivity: string | null,
      createdAt: string | null,
      reports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      summaryReports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
    },
    patientName: string,
    location:  {
      __typename: "Location",
      lat: number,
      lon: number,
    },
    ccgId: string | null,
    ccg:  {
      __typename: "Organisation",
      id: string,
      name: string,
      featureFlags: string | null,
      features: string | null,
      type: OrgType,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      } | null,
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      phone: string | null,
      email: string | null,
      users:  {
        __typename: "ModelOrganisationUserConnection",
        nextToken: string | null,
      } | null,
      sites:  {
        __typename: "ModelSiteOrganisationConnection",
        nextToken: string | null,
      } | null,
      ccg:  {
        __typename: "CCG",
        id: string,
        claimProcessorType: OrgType | null,
        cCGOrganisationId: string,
      } | null,
      mht:  {
        __typename: "MHT",
        id: string,
        abbreviation: string,
        mHTOrganisationId: string,
      } | null,
      claims:  {
        __typename: "ModelClaimConnection",
        nextToken: string | null,
      } | null,
      expensesInstruction: string | null,
      declarationText: string | null,
      additionalNotesText: string | null,
      paymentRates:  Array< {
        __typename: "PaymentRates",
        description: string | null,
        assessmentFee: number,
        mileage: number,
      } | null > | null,
      invoiceEmail: string | null,
      updatedBy: string | null,
      mhtHospitalEmails:  Array< {
        __typename: "HospitalEmails",
        name: string,
      } | null > | null,
      rotas:  Array< {
        __typename: "RotaItem",
        id: string,
        name: string,
      } | null > | null,
      deleted: boolean | null,
    } | null,
    ccgDetermination: LocationType | null,
    locationName:  {
      __typename: "LocationName",
      city: string | null,
      postcode: string,
      address: string | null,
      addressNotes: string | null,
    },
    hadVisitAdded: boolean | null,
    doctorVisits:  {
      __typename: "ModelDoctorVisitConnection",
      items:  Array< {
        __typename: "DoctorVisit",
        id: string,
        createdAt: string | null,
        assessmentId: string,
        time: string,
        teamId: string,
        doctorVisitDoctorId: string,
        notes: Array< string | null > | null,
        partialPostcode: string | null,
        doctorVisitClaimId: string | null,
        claimDisplayId: string | null,
        claimStatus: ClaimStatus | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    patientNhsNumber: string | null,
    assessmentDate: string | null,
    contactedDoctors:  {
      __typename: "ModelContactedDoctorConnection",
      items:  Array< {
        __typename: "ContactedDoctor",
        id: string,
        assessmentId: string,
        contactedDoctorS12DoctorId: string,
        createdAt: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
    forms:  {
      __typename: "ModelFormConnection",
      items:  Array< {
        __typename: "Form",
        id: string,
        sk: string,
        ttl: number,
        version: number,
        gsi1pk: string,
        gsi1sk: string,
        gsi2pk: string,
        gsi2sk: string,
        gsi3pk: string,
        gsi3sk: string,
        assessmentId: string,
        status: FormStatus,
        createdBy: string,
        createdAt: string,
        updatedAt: string,
        updatedBy: string,
        amhpTeamInboxId: string,
        amhpTeamId: string,
        authors: Array< string >,
        doctorNames: Array< string > | null,
        signed: Array< string > | null,
        signedClaims: Array< string > | null,
        sha256: string | null,
        notes: Array< string >,
        type: string,
        isDeleted: boolean | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    patientAddress:  {
      __typename: "LocationName",
      city: string | null,
      postcode: string,
      address: string | null,
      addressNotes: string | null,
    } | null,
  } | null,
};

export type FindAddressQueryVariables = {
  term?: string | null,
  addressId?: string | null,
};

export type FindAddressQuery = {
  findAddress:  Array< {
    __typename: "AddressSearchItem",
    id: string,
    selected: boolean,
    isComplete: boolean,
    isExpandable: boolean,
    expandsTo: number | null,
    location:  {
      __typename: "Location",
      lat: number,
      lon: number,
    } | null,
    itemText: string | null,
    locationName:  {
      __typename: "LocationName",
      city: string | null,
      postcode: string,
      address: string | null,
      addressNotes: string | null,
    } | null,
    ccgId: string | null,
    ccgName: string | null,
    ccg:  {
      __typename: "Organisation",
      id: string,
      name: string,
      featureFlags: string | null,
      features: string | null,
      type: OrgType,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      } | null,
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      phone: string | null,
      email: string | null,
      users:  {
        __typename: "ModelOrganisationUserConnection",
        nextToken: string | null,
      } | null,
      sites:  {
        __typename: "ModelSiteOrganisationConnection",
        nextToken: string | null,
      } | null,
      ccg:  {
        __typename: "CCG",
        id: string,
        claimProcessorType: OrgType | null,
        cCGOrganisationId: string,
      } | null,
      mht:  {
        __typename: "MHT",
        id: string,
        abbreviation: string,
        mHTOrganisationId: string,
      } | null,
      claims:  {
        __typename: "ModelClaimConnection",
        nextToken: string | null,
      } | null,
      expensesInstruction: string | null,
      declarationText: string | null,
      additionalNotesText: string | null,
      paymentRates:  Array< {
        __typename: "PaymentRates",
        description: string | null,
        assessmentFee: number,
        mileage: number,
      } | null > | null,
      invoiceEmail: string | null,
      updatedBy: string | null,
      mhtHospitalEmails:  Array< {
        __typename: "HospitalEmails",
        name: string,
      } | null > | null,
      rotas:  Array< {
        __typename: "RotaItem",
        id: string,
        name: string,
      } | null > | null,
      deleted: boolean | null,
    } | null,
  } | null > | null,
};

export type FindAddressByPostcodeQueryVariables = {
  postcode?: string | null,
  findExactMatch?: boolean | null,
};

export type FindAddressByPostcodeQuery = {
  findAddressByPostcode:  {
    __typename: "AddressSearchItem",
    id: string,
    selected: boolean,
    isComplete: boolean,
    isExpandable: boolean,
    expandsTo: number | null,
    location:  {
      __typename: "Location",
      lat: number,
      lon: number,
    } | null,
    itemText: string | null,
    locationName:  {
      __typename: "LocationName",
      city: string | null,
      postcode: string,
      address: string | null,
      addressNotes: string | null,
    } | null,
    ccgId: string | null,
    ccgName: string | null,
    ccg:  {
      __typename: "Organisation",
      id: string,
      name: string,
      featureFlags: string | null,
      features: string | null,
      type: OrgType,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      } | null,
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      phone: string | null,
      email: string | null,
      users:  {
        __typename: "ModelOrganisationUserConnection",
        nextToken: string | null,
      } | null,
      sites:  {
        __typename: "ModelSiteOrganisationConnection",
        nextToken: string | null,
      } | null,
      ccg:  {
        __typename: "CCG",
        id: string,
        claimProcessorType: OrgType | null,
        cCGOrganisationId: string,
      } | null,
      mht:  {
        __typename: "MHT",
        id: string,
        abbreviation: string,
        mHTOrganisationId: string,
      } | null,
      claims:  {
        __typename: "ModelClaimConnection",
        nextToken: string | null,
      } | null,
      expensesInstruction: string | null,
      declarationText: string | null,
      additionalNotesText: string | null,
      paymentRates:  Array< {
        __typename: "PaymentRates",
        description: string | null,
        assessmentFee: number,
        mileage: number,
      } | null > | null,
      invoiceEmail: string | null,
      updatedBy: string | null,
      mhtHospitalEmails:  Array< {
        __typename: "HospitalEmails",
        name: string,
      } | null > | null,
      rotas:  Array< {
        __typename: "RotaItem",
        id: string,
        name: string,
      } | null > | null,
      deleted: boolean | null,
    } | null,
  } | null,
};

export type FindGpPracticeQueryVariables = {
  term?: string | null,
  postcode?: string | null,
};

export type FindGpPracticeQuery = {
  findGPPractice:  Array< {
    __typename: "GPPracticeSearchItem",
    id: string,
    name: string,
    postcode: string | null,
  } | null > | null,
};

export type GetGpPracticeQueryVariables = {
  practiceId?: string | null,
};

export type GetGpPracticeQuery = {
  getGPPractice:  {
    __typename: "GPPractice",
    id: string,
    name: string,
    postcode: string | null,
    town: string | null,
    contact: string | null,
    ccgId: string | null,
    ccg:  {
      __typename: "Organisation",
      id: string,
      name: string,
      featureFlags: string | null,
      features: string | null,
      type: OrgType,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      } | null,
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      phone: string | null,
      email: string | null,
      users:  {
        __typename: "ModelOrganisationUserConnection",
        nextToken: string | null,
      } | null,
      sites:  {
        __typename: "ModelSiteOrganisationConnection",
        nextToken: string | null,
      } | null,
      ccg:  {
        __typename: "CCG",
        id: string,
        claimProcessorType: OrgType | null,
        cCGOrganisationId: string,
      } | null,
      mht:  {
        __typename: "MHT",
        id: string,
        abbreviation: string,
        mHTOrganisationId: string,
      } | null,
      claims:  {
        __typename: "ModelClaimConnection",
        nextToken: string | null,
      } | null,
      expensesInstruction: string | null,
      declarationText: string | null,
      additionalNotesText: string | null,
      paymentRates:  Array< {
        __typename: "PaymentRates",
        description: string | null,
        assessmentFee: number,
        mileage: number,
      } | null > | null,
      invoiceEmail: string | null,
      updatedBy: string | null,
      mhtHospitalEmails:  Array< {
        __typename: "HospitalEmails",
        name: string,
      } | null > | null,
      rotas:  Array< {
        __typename: "RotaItem",
        id: string,
        name: string,
      } | null > | null,
      deleted: boolean | null,
    } | null,
    surgery:  {
      __typename: "GPSurgery",
      id: string | null,
      name: string | null,
      postcode: string | null,
    } | null,
  } | null,
};

export type FindGpOrgQueryVariables = {
  term?: string | null,
  postcode?: string | null,
  searchFor?: string | null,
};

export type FindGpOrgQuery = {
  findGPOrg:  Array< {
    __typename: "GPOrgSearchItem",
    id: string,
    roleId: string,
    name: string,
    postcode: string | null,
  } | null > | null,
};

export type GetGpPracticeFromOrgQueryVariables = {
  orgId?: string | null,
  roleId?: string | null,
};

export type GetGpPracticeFromOrgQuery = {
  getGPPracticeFromOrg:  {
    __typename: "GPPractice",
    id: string,
    name: string,
    postcode: string | null,
    town: string | null,
    contact: string | null,
    ccgId: string | null,
    ccg:  {
      __typename: "Organisation",
      id: string,
      name: string,
      featureFlags: string | null,
      features: string | null,
      type: OrgType,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      } | null,
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      phone: string | null,
      email: string | null,
      users:  {
        __typename: "ModelOrganisationUserConnection",
        nextToken: string | null,
      } | null,
      sites:  {
        __typename: "ModelSiteOrganisationConnection",
        nextToken: string | null,
      } | null,
      ccg:  {
        __typename: "CCG",
        id: string,
        claimProcessorType: OrgType | null,
        cCGOrganisationId: string,
      } | null,
      mht:  {
        __typename: "MHT",
        id: string,
        abbreviation: string,
        mHTOrganisationId: string,
      } | null,
      claims:  {
        __typename: "ModelClaimConnection",
        nextToken: string | null,
      } | null,
      expensesInstruction: string | null,
      declarationText: string | null,
      additionalNotesText: string | null,
      paymentRates:  Array< {
        __typename: "PaymentRates",
        description: string | null,
        assessmentFee: number,
        mileage: number,
      } | null > | null,
      invoiceEmail: string | null,
      updatedBy: string | null,
      mhtHospitalEmails:  Array< {
        __typename: "HospitalEmails",
        name: string,
      } | null > | null,
      rotas:  Array< {
        __typename: "RotaItem",
        id: string,
        name: string,
      } | null > | null,
      deleted: boolean | null,
    } | null,
    surgery:  {
      __typename: "GPSurgery",
      id: string | null,
      name: string | null,
      postcode: string | null,
    } | null,
  } | null,
};

export type FindClaimsQueryVariables = {
  filter?: ModelClaimFilterInput | null,
  paginateClause?: PaginateClauseInput | null,
  orderClause?: GenericOrderClauseInput | null,
};

export type FindClaimsQuery = {
  findClaims:  {
    __typename: "ClaimsResult",
    items:  Array< {
      __typename: "Claim",
      id: string,
      claimAssessmentId: string | null,
      claimVisitId: string,
      visit:  {
        __typename: "DoctorVisit",
        id: string,
        createdAt: string | null,
        assessmentId: string,
        time: string,
        teamId: string,
        doctorVisitDoctorId: string,
        notes: Array< string | null > | null,
        partialPostcode: string | null,
        doctorVisitClaimId: string | null,
        claimDisplayId: string | null,
        claimStatus: ClaimStatus | null,
      },
      claimDoctorId: string,
      doctor:  {
        __typename: "S12Doctor",
        id: string,
        gmc: string,
        s12Expiry: string | null,
        name: string,
        legalName: string,
        phone: string | null,
        phone2: string | null,
        email: string,
        gl: boolean | null,
        employer: string | null,
        jobTitle: string | null,
        responsibleOfficer: string | null,
        lineManager: string | null,
        defaultMHT: string | null,
        specialties: Array< string | null >,
        languages: Array< string | null >,
        gender: Gender | null,
        notes: string | null,
        pushTokens: Array< string | null > | null,
        distance: number | null,
        hideFromSearch: boolean | null,
        availabilityPostcodes: Array< string | null > | null,
        siteName: string,
        sectionFormEmailAddress: string | null,
        createdAt: string | null,
        lastActivity: string | null,
        isOutsideEngland: boolean | null,
      },
      assigneeId: string,
      assigneeName: string | null,
      amhpTeamId: string,
      claimAmhpId: string,
      amhp:  {
        __typename: "AmhpProfile",
        id: string,
        email: string,
        phone: string | null,
        name: string,
        legalName: string | null,
        deleted: boolean | null,
        warrantInfo: string | null,
        accreditation: string | null,
        sectionFormEmailAddress: string | null,
        sectionFormContactNumber: string | null,
        pushTokens: Array< string | null > | null,
        lastActivity: string | null,
        createdAt: string | null,
      },
      claimOrganisationId: string,
      claimCsuId: string,
      organisation:  {
        __typename: "Organisation",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        type: OrgType,
        phone: string | null,
        email: string | null,
        expensesInstruction: string | null,
        declarationText: string | null,
        additionalNotesText: string | null,
        invoiceEmail: string | null,
        updatedBy: string | null,
        deleted: boolean | null,
      },
      status: ClaimStatus,
      displayId: string,
      mileage: string | null,
      locationText: string,
      locationType: LocationType,
      claimPostcode: string | null,
      assessmentPostcode: string | null,
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      patientInitials: string,
      patientFullName: string | null,
      startingPostcode: string | null,
      visitDate: string,
      assessmentTookPlace: boolean | null,
      notes: Array< string | null > | null,
      unread: boolean | null,
      createdAt: string | null,
      updatedAt: string,
      receivedDate: string,
      additionalExpenses: string | null,
      additionalInformation: string | null,
      patientNhsNumber: string | null,
      declaration: string | null,
      paid: boolean | null,
      claimData:  {
        __typename: "ClaimData",
        billingCompanyName: string | null,
        vatRegistration: string | null,
        fuelType: FuelType | null,
        engineSize: Engine | null,
      } | null,
      invoiceData:  {
        __typename: "InvoiceData",
        assessmentFee: string,
        travelFee: string | null,
        locationOfAssessment: string | null,
      } | null,
      lineManager: string | null,
      doctorAdditionalConf: DoctorConfirmation | null,
      isIndependentDoctor: boolean | null,
      defaultMHT: string | null,
      doctorEmployedStatus: DoctorEmployedStatusValues | null,
    } >,
    orderClause:  {
      __typename: "GenericOrderClause",
      field: string,
      direction: ModelSortDirection,
    },
    paginateClause:  {
      __typename: "PaginateClause",
      limit: number,
      offset: number,
    },
    total: number,
  },
};

export type FindAssessmentsQueryVariables = {
  filter?: ModelAssessmentFilterInput | null,
  paginateClause?: PaginateClauseInput | null,
  orderClause?: GenericOrderClauseInput | null,
};

export type FindAssessmentsQuery = {
  findAssessments:  {
    __typename: "AssessmentsResult",
    items:  Array< {
      __typename: "Assessment",
      id: string,
      createdAt: string,
      amhpTeamId: string,
      amhpTeam:  {
        __typename: "AmhpTeam",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        localAuthority: string | null,
        emails: Array< string > | null,
        phoneNumbers: Array< string > | null,
        mhaInboxInstructions: string | null,
        updatedBy: string | null,
      },
      amhpId: string,
      amhp:  {
        __typename: "AmhpProfile",
        id: string,
        email: string,
        phone: string | null,
        name: string,
        legalName: string | null,
        deleted: boolean | null,
        warrantInfo: string | null,
        accreditation: string | null,
        sectionFormEmailAddress: string | null,
        sectionFormContactNumber: string | null,
        pushTokens: Array< string | null > | null,
        lastActivity: string | null,
        createdAt: string | null,
      },
      patientName: string,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      },
      ccgId: string | null,
      ccg:  {
        __typename: "Organisation",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        type: OrgType,
        phone: string | null,
        email: string | null,
        expensesInstruction: string | null,
        declarationText: string | null,
        additionalNotesText: string | null,
        invoiceEmail: string | null,
        updatedBy: string | null,
        deleted: boolean | null,
      } | null,
      ccgDetermination: LocationType | null,
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      },
      hadVisitAdded: boolean | null,
      doctorVisits:  {
        __typename: "ModelDoctorVisitConnection",
        nextToken: string | null,
      } | null,
      patientNhsNumber: string | null,
      assessmentDate: string | null,
      contactedDoctors:  {
        __typename: "ModelContactedDoctorConnection",
        nextToken: string | null,
      } | null,
      forms:  {
        __typename: "ModelFormConnection",
        nextToken: string | null,
      } | null,
      patientAddress:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
    } >,
    orderClause:  {
      __typename: "GenericOrderClause",
      field: string,
      direction: ModelSortDirection,
    },
    paginateClause:  {
      __typename: "PaginateClause",
      limit: number,
      offset: number,
    },
    total: number,
  },
};

export type FindFormsQueryVariables = {
  filter?: ModelFormFilterInput | null,
  paginateClause?: PaginateClauseInput | null,
  orderClause?: GenericOrderClauseInput | null,
};

export type FindFormsQuery = {
  findForms:  {
    __typename: "FormsResult",
    items:  Array< {
      __typename: "Form",
      id: string,
      sk: string,
      ttl: number,
      version: number,
      gsi1pk: string,
      gsi1sk: string,
      gsi2pk: string,
      gsi2sk: string,
      gsi3pk: string,
      gsi3sk: string,
      assessmentId: string,
      assessment:  {
        __typename: "Assessment",
        id: string,
        createdAt: string,
        amhpTeamId: string,
        amhpId: string,
        patientName: string,
        ccgId: string | null,
        ccgDetermination: LocationType | null,
        hadVisitAdded: boolean | null,
        patientNhsNumber: string | null,
        assessmentDate: string | null,
      } | null,
      status: FormStatus,
      createdBy: string,
      createdAt: string,
      updatedAt: string,
      updatedBy: string,
      amhpTeamInboxId: string,
      amhpTeamId: string,
      authors: Array< string >,
      doctorNames: Array< string > | null,
      signed: Array< string > | null,
      signedClaims: Array< string > | null,
      sha256: string | null,
      notes: Array< string >,
      type: string,
      isDeleted: boolean | null,
      amhpTeam:  {
        __typename: "AmhpTeam",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        localAuthority: string | null,
        emails: Array< string > | null,
        phoneNumbers: Array< string > | null,
        mhaInboxInstructions: string | null,
        updatedBy: string | null,
      } | null,
    } >,
    orderClause:  {
      __typename: "GenericOrderClause",
      field: string,
      direction: ModelSortDirection,
    },
    paginateClause:  {
      __typename: "PaginateClause",
      limit: number,
      offset: number,
    },
    total: number,
  },
};

export type FindSitesQueryVariables = {
  filter?: ModelSiteFilterInput | null,
  paginateClause?: PaginateClauseInput | null,
  orderClause?: GenericOrderClauseInput | null,
};

export type FindSitesQuery = {
  findSites:  {
    __typename: "SitesResult",
    items:  Array< {
      __typename: "Site",
      id: string,
      name: string,
      organisations:  {
        __typename: "ModelSiteOrganisationConnection",
        nextToken: string | null,
      } | null,
      amhpTeams:  {
        __typename: "ModelAmhpTeamConnection",
        nextToken: string | null,
      } | null,
      doctors:  {
        __typename: "ModelS12DoctorConnection",
        nextToken: string | null,
      } | null,
      doctorReports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      summaryReports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
    } >,
    orderClause:  {
      __typename: "GenericOrderClause",
      field: string,
      direction: ModelSortDirection,
    },
    paginateClause:  {
      __typename: "PaginateClause",
      limit: number,
      offset: number,
    },
    total: number,
  },
};

export type FindS12DoctorsQueryVariables = {
  filter?: ModelS12DoctorFilterInput | null,
  paginateClause?: PaginateClauseInput | null,
  orderClause?: GenericOrderClauseInput | null,
};

export type FindS12DoctorsQuery = {
  findS12Doctors:  {
    __typename: "S12DoctorsResult",
    items:  Array< {
      __typename: "S12Doctor",
      id: string,
      gmc: string,
      s12Expiry: string | null,
      name: string,
      legalName: string,
      phone: string | null,
      phone2: string | null,
      email: string,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      },
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      },
      gl: boolean | null,
      employer: string | null,
      jobTitle: string | null,
      responsibleOfficer: string | null,
      lineManager: string | null,
      defaultMHT: string | null,
      mhtEmployers:  {
        __typename: "ModelMHTS12DoctorConnection",
        nextToken: string | null,
      } | null,
      availabilities:  {
        __typename: "ModelAvailabilityConnection",
        nextToken: string | null,
      } | null,
      holidays:  {
        __typename: "ModelHolidayConnection",
        nextToken: string | null,
      } | null,
      specialties: Array< string | null >,
      languages: Array< string | null >,
      gender: Gender | null,
      notes: string | null,
      pushTokens: Array< string | null > | null,
      distance: number | null,
      hideFromSearch: boolean | null,
      visits:  {
        __typename: "ModelDoctorVisitConnection",
        nextToken: string | null,
      } | null,
      doctors:  {
        __typename: "ModelContactedDoctorConnection",
        nextToken: string | null,
      } | null,
      claims:  {
        __typename: "ModelClaimConnection",
        nextToken: string | null,
      } | null,
      availabilityLocations:  Array< {
        __typename: "Location",
        lat: number,
        lon: number,
      } | null > | null,
      availabilityPostcodes: Array< string | null > | null,
      siteName: string,
      claimData:  {
        __typename: "ClaimData",
        billingCompanyName: string | null,
        vatRegistration: string | null,
        fuelType: FuelType | null,
        engineSize: Engine | null,
      } | null,
      sectionFormAddress:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      sectionFormEmailAddress: string | null,
      reports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      summaryReports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      createdAt: string | null,
      lastActivity: string | null,
      isOutsideEngland: boolean | null,
    } >,
    orderClause:  {
      __typename: "GenericOrderClause",
      field: string,
      direction: ModelSortDirection,
    },
    paginateClause:  {
      __typename: "PaginateClause",
      limit: number,
      offset: number,
    },
    total: number,
  },
};

export type FindSiteOrganisationsQueryVariables = {
  filter?: ModelSiteOrganisationFilterInput | null,
  paginateClause?: PaginateClauseInput | null,
  orderClause?: GenericOrderClauseInput | null,
};

export type FindSiteOrganisationsQuery = {
  findSiteOrganisations:  {
    __typename: "SiteOrganisationsResult",
    items:  Array< {
      __typename: "SiteOrganisation",
      id: string,
      site:  {
        __typename: "Site",
        id: string,
        name: string,
      },
      organisationType: string | null,
      organisation:  {
        __typename: "Organisation",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        type: OrgType,
        phone: string | null,
        email: string | null,
        expensesInstruction: string | null,
        declarationText: string | null,
        additionalNotesText: string | null,
        invoiceEmail: string | null,
        updatedBy: string | null,
        deleted: boolean | null,
      },
    } >,
    orderClause:  {
      __typename: "GenericOrderClause",
      field: string,
      direction: ModelSortDirection,
    },
    paginateClause:  {
      __typename: "PaginateClause",
      limit: number,
      offset: number,
    },
    total: number,
  },
};

export type FindAddressesQueryVariables = {
  filter?: ModelAddressFilterInput | null,
  paginateClause?: PaginateClauseInput | null,
  orderClause?: GenericOrderClauseInput | null,
};

export type FindAddressesQuery = {
  findAddresses:  {
    __typename: "AddressesResult",
    items:  Array< {
      __typename: "Address",
      id: string,
      amhpTeamId: string,
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      },
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      },
      ccgId: string,
      ccg:  {
        __typename: "Organisation",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        type: OrgType,
        phone: string | null,
        email: string | null,
        expensesInstruction: string | null,
        declarationText: string | null,
        additionalNotesText: string | null,
        invoiceEmail: string | null,
        updatedBy: string | null,
        deleted: boolean | null,
      } | null,
    } >,
    orderClause:  {
      __typename: "GenericOrderClause",
      field: string,
      direction: ModelSortDirection,
    },
    paginateClause:  {
      __typename: "PaginateClause",
      limit: number,
      offset: number,
    },
    total: number,
  },
};

export type FindDoctorVisitsQueryVariables = {
  filter?: ModelDoctorVisitFilterInput | null,
  paginateClause?: PaginateClauseInput | null,
  orderClause?: GenericOrderClauseInput | null,
};

export type FindDoctorVisitsQuery = {
  findDoctorVisits:  {
    __typename: "DoctorVisitsResult",
    items:  Array< {
      __typename: "DoctorVisit",
      id: string,
      createdAt: string | null,
      assessmentId: string,
      assessment:  {
        __typename: "Assessment",
        id: string,
        createdAt: string,
        amhpTeamId: string,
        amhpId: string,
        patientName: string,
        ccgId: string | null,
        ccgDetermination: LocationType | null,
        hadVisitAdded: boolean | null,
        patientNhsNumber: string | null,
        assessmentDate: string | null,
      },
      time: string,
      teamId: string,
      team:  {
        __typename: "AmhpTeam",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        localAuthority: string | null,
        emails: Array< string > | null,
        phoneNumbers: Array< string > | null,
        mhaInboxInstructions: string | null,
        updatedBy: string | null,
      },
      doctorVisitDoctorId: string,
      doctor:  {
        __typename: "S12Doctor",
        id: string,
        gmc: string,
        s12Expiry: string | null,
        name: string,
        legalName: string,
        phone: string | null,
        phone2: string | null,
        email: string,
        gl: boolean | null,
        employer: string | null,
        jobTitle: string | null,
        responsibleOfficer: string | null,
        lineManager: string | null,
        defaultMHT: string | null,
        specialties: Array< string | null >,
        languages: Array< string | null >,
        gender: Gender | null,
        notes: string | null,
        pushTokens: Array< string | null > | null,
        distance: number | null,
        hideFromSearch: boolean | null,
        availabilityPostcodes: Array< string | null > | null,
        siteName: string,
        sectionFormEmailAddress: string | null,
        createdAt: string | null,
        lastActivity: string | null,
        isOutsideEngland: boolean | null,
      },
      notes: Array< string | null > | null,
      partialPostcode: string | null,
      doctorVisitClaimId: string | null,
      claimDisplayId: string | null,
      claimStatus: ClaimStatus | null,
      claim:  {
        __typename: "Claim",
        id: string,
        claimAssessmentId: string | null,
        claimVisitId: string,
        claimDoctorId: string,
        assigneeId: string,
        assigneeName: string | null,
        amhpTeamId: string,
        claimAmhpId: string,
        claimOrganisationId: string,
        claimCsuId: string,
        status: ClaimStatus,
        displayId: string,
        mileage: string | null,
        locationText: string,
        locationType: LocationType,
        claimPostcode: string | null,
        assessmentPostcode: string | null,
        patientInitials: string,
        patientFullName: string | null,
        startingPostcode: string | null,
        visitDate: string,
        assessmentTookPlace: boolean | null,
        notes: Array< string | null > | null,
        unread: boolean | null,
        createdAt: string | null,
        updatedAt: string,
        receivedDate: string,
        additionalExpenses: string | null,
        additionalInformation: string | null,
        patientNhsNumber: string | null,
        declaration: string | null,
        paid: boolean | null,
        lineManager: string | null,
        doctorAdditionalConf: DoctorConfirmation | null,
        isIndependentDoctor: boolean | null,
        defaultMHT: string | null,
        doctorEmployedStatus: DoctorEmployedStatusValues | null,
      } | null,
    } >,
    orderClause:  {
      __typename: "GenericOrderClause",
      field: string,
      direction: ModelSortDirection,
    },
    paginateClause:  {
      __typename: "PaginateClause",
      limit: number,
      offset: number,
    },
    total: number,
  },
};

export type FindAvailabilitiesQueryVariables = {
  filter?: ModelAvailabilityFilterInput | null,
  paginateClause?: PaginateClauseInput | null,
  orderClause?: GenericOrderClauseInput | null,
};

export type FindAvailabilitiesQuery = {
  findAvailabilities:  {
    __typename: "AvailabilitiesResult",
    items:  Array< {
      __typename: "Availability",
      id: string,
      createdAt: string | null,
      type: AvailabilityType | null,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      } | null,
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      rrule: string,
      endHour: number,
      endDate: string,
      contractList:  {
        __typename: "ContractList",
        name: string,
        description: string | null,
      } | null,
      notes: string | null,
      s12DoctorAvailabilitiesId: string,
      availabilityDoctorId: string,
      oncall: boolean | null,
      mht:  {
        __typename: "MHT",
        id: string,
        abbreviation: string,
        mHTOrganisationId: string,
      } | null,
      doctor:  {
        __typename: "S12Doctor",
        id: string,
        gmc: string,
        s12Expiry: string | null,
        name: string,
        legalName: string,
        phone: string | null,
        phone2: string | null,
        email: string,
        gl: boolean | null,
        employer: string | null,
        jobTitle: string | null,
        responsibleOfficer: string | null,
        lineManager: string | null,
        defaultMHT: string | null,
        specialties: Array< string | null >,
        languages: Array< string | null >,
        gender: Gender | null,
        notes: string | null,
        pushTokens: Array< string | null > | null,
        distance: number | null,
        hideFromSearch: boolean | null,
        availabilityPostcodes: Array< string | null > | null,
        siteName: string,
        sectionFormEmailAddress: string | null,
        createdAt: string | null,
        lastActivity: string | null,
        isOutsideEngland: boolean | null,
      },
      rotaId: string | null,
      rotaName: string | null,
    } >,
    orderClause:  {
      __typename: "GenericOrderClause",
      field: string,
      direction: ModelSortDirection,
    },
    paginateClause:  {
      __typename: "PaginateClause",
      limit: number,
      offset: number,
    },
    total: number,
  },
};

export type FindUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  paginateClause?: PaginateClauseInput | null,
  orderClause?: GenericOrderClauseInput | null,
};

export type FindUsersQuery = {
  findUsers:  {
    __typename: "UsersResult",
    items:  Array< {
      __typename: "User",
      id: string,
      name: string,
      email: string,
      phone: string | null,
      organisations:  {
        __typename: "ModelOrganisationUserConnection",
        nextToken: string | null,
      } | null,
    } >,
    orderClause:  {
      __typename: "GenericOrderClause",
      field: string,
      direction: ModelSortDirection,
    },
    paginateClause:  {
      __typename: "PaginateClause",
      limit: number,
      offset: number,
    },
    total: number,
  },
};

export type FindHolidaysQueryVariables = {
  filter?: ModelHolidayFilterInput | null,
  paginateClause?: PaginateClauseInput | null,
  orderClause?: GenericOrderClauseInput | null,
};

export type FindHolidaysQuery = {
  findHolidays:  {
    __typename: "HolidaysResult",
    items:  Array< {
      __typename: "Holiday",
      id: string,
      start: string,
      end: string,
      visible: boolean,
      notes: string | null,
      s12DoctorHolidaysId: string,
      doctor:  {
        __typename: "S12Doctor",
        id: string,
        gmc: string,
        s12Expiry: string | null,
        name: string,
        legalName: string,
        phone: string | null,
        phone2: string | null,
        email: string,
        gl: boolean | null,
        employer: string | null,
        jobTitle: string | null,
        responsibleOfficer: string | null,
        lineManager: string | null,
        defaultMHT: string | null,
        specialties: Array< string | null >,
        languages: Array< string | null >,
        gender: Gender | null,
        notes: string | null,
        pushTokens: Array< string | null > | null,
        distance: number | null,
        hideFromSearch: boolean | null,
        availabilityPostcodes: Array< string | null > | null,
        siteName: string,
        sectionFormEmailAddress: string | null,
        createdAt: string | null,
        lastActivity: string | null,
        isOutsideEngland: boolean | null,
      },
    } >,
    orderClause:  {
      __typename: "GenericOrderClause",
      field: string,
      direction: ModelSortDirection,
    },
    paginateClause:  {
      __typename: "PaginateClause",
      limit: number,
      offset: number,
    },
    total: number,
  },
};

export type FindMhTsQueryVariables = {
  filter?: ModelMHTFilterInput | null,
  paginateClause?: PaginateClauseInput | null,
  orderClause?: GenericOrderClauseInput | null,
};

export type FindMhTsQuery = {
  findMHTs:  {
    __typename: "MHTsResult",
    items:  Array< {
      __typename: "MHT",
      id: string,
      abbreviation: string,
      organisation:  {
        __typename: "Organisation",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        type: OrgType,
        phone: string | null,
        email: string | null,
        expensesInstruction: string | null,
        declarationText: string | null,
        additionalNotesText: string | null,
        invoiceEmail: string | null,
        updatedBy: string | null,
        deleted: boolean | null,
      },
      mHTOrganisationId: string,
      s12Doctors:  {
        __typename: "ModelMHTS12DoctorConnection",
        nextToken: string | null,
      } | null,
    } >,
    orderClause:  {
      __typename: "GenericOrderClause",
      field: string,
      direction: ModelSortDirection,
    },
    paginateClause:  {
      __typename: "PaginateClause",
      limit: number,
      offset: number,
    },
    total: number,
  },
};

export type FindMhts12DoctorsQueryVariables = {
  filter?: ModelMHTS12DoctorFilterInput | null,
  paginateClause?: PaginateClauseInput | null,
  orderClause?: GenericOrderClauseInput | null,
};

export type FindMhts12DoctorsQuery = {
  findMHTS12Doctors:  {
    __typename: "MHTS12DoctorsResult",
    items:  Array< {
      __typename: "MHTS12Doctor",
      id: string,
      mht:  {
        __typename: "MHT",
        id: string,
        abbreviation: string,
        mHTOrganisationId: string,
      },
      mHTS12DoctorS12DoctorId: string,
      s12Doctor:  {
        __typename: "S12Doctor",
        id: string,
        gmc: string,
        s12Expiry: string | null,
        name: string,
        legalName: string,
        phone: string | null,
        phone2: string | null,
        email: string,
        gl: boolean | null,
        employer: string | null,
        jobTitle: string | null,
        responsibleOfficer: string | null,
        lineManager: string | null,
        defaultMHT: string | null,
        specialties: Array< string | null >,
        languages: Array< string | null >,
        gender: Gender | null,
        notes: string | null,
        pushTokens: Array< string | null > | null,
        distance: number | null,
        hideFromSearch: boolean | null,
        availabilityPostcodes: Array< string | null > | null,
        siteName: string,
        sectionFormEmailAddress: string | null,
        createdAt: string | null,
        lastActivity: string | null,
        isOutsideEngland: boolean | null,
      },
    } >,
    orderClause:  {
      __typename: "GenericOrderClause",
      field: string,
      direction: ModelSortDirection,
    },
    paginateClause:  {
      __typename: "PaginateClause",
      limit: number,
      offset: number,
    },
    total: number,
  },
};

export type FindAmhpTeamUsersQueryVariables = {
  filter?: ModelAmhpTeamUserFilterInput | null,
  paginateClause?: PaginateClauseInput | null,
  orderClause?: GenericOrderClauseInput | null,
};

export type FindAmhpTeamUsersQuery = {
  findAmhpTeamUsers:  {
    __typename: "AmhpTeamUsersResult",
    items:  Array< {
      __typename: "AmhpTeamUser",
      id: string,
      amhpTeam:  {
        __typename: "AmhpTeam",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        localAuthority: string | null,
        emails: Array< string > | null,
        phoneNumbers: Array< string > | null,
        mhaInboxInstructions: string | null,
        updatedBy: string | null,
      },
      amhp:  {
        __typename: "AmhpProfile",
        id: string,
        email: string,
        phone: string | null,
        name: string,
        legalName: string | null,
        deleted: boolean | null,
        warrantInfo: string | null,
        accreditation: string | null,
        sectionFormEmailAddress: string | null,
        sectionFormContactNumber: string | null,
        pushTokens: Array< string | null > | null,
        lastActivity: string | null,
        createdAt: string | null,
      },
    } >,
    orderClause:  {
      __typename: "GenericOrderClause",
      field: string,
      direction: ModelSortDirection,
    },
    paginateClause:  {
      __typename: "PaginateClause",
      limit: number,
      offset: number,
    },
    total: number,
  },
};

export type FindAmhpTeamsQueryVariables = {
  filter?: ModelAmhpTeamFilterInput | null,
  paginateClause?: PaginateClauseInput | null,
  orderClause?: GenericOrderClauseInput | null,
};

export type FindAmhpTeamsQuery = {
  findAmhpTeams:  {
    __typename: "AmhpTeamsResult",
    items:  Array< {
      __typename: "AmhpTeam",
      id: string,
      name: string,
      featureFlags: string | null,
      features: string | null,
      assessments:  {
        __typename: "ModelAssessmentConnection",
        nextToken: string | null,
      } | null,
      users:  {
        __typename: "ModelAmhpTeamUserConnection",
        nextToken: string | null,
      } | null,
      commonAddresses:  {
        __typename: "ModelAddressConnection",
        nextToken: string | null,
      } | null,
      localAuthority: string | null,
      hospitalEmails:  Array< {
        __typename: "HospitalEmails",
        name: string,
      } > | null,
      mhaEmailAddresses:  Array< {
        __typename: "RecipientEmails",
        name: string | null,
      } | null > | null,
      emails: Array< string > | null,
      phoneNumbers: Array< string > | null,
      mhaInboxInstructions: string | null,
      reports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      summaryReports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      updatedBy: string | null,
    } >,
    orderClause:  {
      __typename: "GenericOrderClause",
      field: string,
      direction: ModelSortDirection,
    },
    paginateClause:  {
      __typename: "PaginateClause",
      limit: number,
      offset: number,
    },
    total: number,
  },
};

export type FindOrganisationsQueryVariables = {
  filter?: ModelOrganisationFilterInput | null,
  paginateClause?: PaginateClauseInput | null,
  orderClause?: GenericOrderClauseInput | null,
};

export type FindOrganisationsQuery = {
  findOrganisations:  {
    __typename: "OrganisationResult",
    items:  Array< {
      __typename: "Organisation",
      id: string,
      name: string,
      featureFlags: string | null,
      features: string | null,
      type: OrgType,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      } | null,
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      phone: string | null,
      email: string | null,
      users:  {
        __typename: "ModelOrganisationUserConnection",
        nextToken: string | null,
      } | null,
      sites:  {
        __typename: "ModelSiteOrganisationConnection",
        nextToken: string | null,
      } | null,
      ccg:  {
        __typename: "CCG",
        id: string,
        claimProcessorType: OrgType | null,
        cCGOrganisationId: string,
      } | null,
      mht:  {
        __typename: "MHT",
        id: string,
        abbreviation: string,
        mHTOrganisationId: string,
      } | null,
      claims:  {
        __typename: "ModelClaimConnection",
        nextToken: string | null,
      } | null,
      expensesInstruction: string | null,
      declarationText: string | null,
      additionalNotesText: string | null,
      paymentRates:  Array< {
        __typename: "PaymentRates",
        description: string | null,
        assessmentFee: number,
        mileage: number,
      } | null > | null,
      invoiceEmail: string | null,
      updatedBy: string | null,
      mhtHospitalEmails:  Array< {
        __typename: "HospitalEmails",
        name: string,
      } | null > | null,
      rotas:  Array< {
        __typename: "RotaItem",
        id: string,
        name: string,
      } | null > | null,
      deleted: boolean | null,
    } >,
    orderClause:  {
      __typename: "GenericOrderClause",
      field: string,
      direction: ModelSortDirection,
    },
    paginateClause:  {
      __typename: "PaginateClause",
      limit: number,
      offset: number,
    },
    total: number,
  },
};

export type FindOrganisationUsersQueryVariables = {
  filter?: ModelOrganisationUserFilterInput | null,
  paginateClause?: PaginateClauseInput | null,
  orderClause?: GenericOrderClauseInput | null,
};

export type FindOrganisationUsersQuery = {
  findOrganisationUsers:  {
    __typename: "OrganisationUserResult",
    items:  Array< {
      __typename: "OrganisationUser",
      id: string,
      organisationUserOrganisationId: string,
      type: string,
      organisation:  {
        __typename: "Organisation",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        type: OrgType,
        phone: string | null,
        email: string | null,
        expensesInstruction: string | null,
        declarationText: string | null,
        additionalNotesText: string | null,
        invoiceEmail: string | null,
        updatedBy: string | null,
        deleted: boolean | null,
      },
      organisationUserUserId: string,
      user:  {
        __typename: "User",
        id: string,
        name: string,
        email: string,
        phone: string | null,
      },
    } >,
    orderClause:  {
      __typename: "GenericOrderClause",
      field: string,
      direction: ModelSortDirection,
    },
    paginateClause:  {
      __typename: "PaginateClause",
      limit: number,
      offset: number,
    },
    total: number,
  },
};

export type FindAmhpProfilesQueryVariables = {
  filter?: ModelAmhpProfileFilterInput | null,
  paginateClause?: PaginateClauseInput | null,
  orderClause?: GenericOrderClauseInput | null,
};

export type FindAmhpProfilesQuery = {
  findAmhpProfiles:  {
    __typename: "AmhpProfileResult",
    items:  Array< {
      __typename: "AmhpProfile",
      id: string,
      email: string,
      phone: string | null,
      name: string,
      legalName: string | null,
      deleted: boolean | null,
      warrantInfo: string | null,
      accreditation: string | null,
      sectionFormAddress:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      sectionFormEmailAddress: string | null,
      sectionFormContactNumber: string | null,
      assessments:  {
        __typename: "ModelAssessmentConnection",
        nextToken: string | null,
      } | null,
      pushTokens: Array< string | null > | null,
      teams:  {
        __typename: "ModelAmhpTeamUserConnection",
        nextToken: string | null,
      } | null,
      lastActivity: string | null,
      createdAt: string | null,
      reports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      summaryReports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
    } >,
    orderClause:  {
      __typename: "GenericOrderClause",
      field: string,
      direction: ModelSortDirection,
    },
    paginateClause:  {
      __typename: "PaginateClause",
      limit: number,
      offset: number,
    },
    total: number,
  },
};

export type FindCcGsQueryVariables = {
  filter?: ModelCCGFilterInput | null,
  paginateClause?: PaginateClauseInput | null,
  orderClause?: GenericOrderClauseInput | null,
};

export type FindCcGsQuery = {
  findCCGs:  {
    __typename: "CCGResult",
    items:  Array< {
      __typename: "CCG",
      id: string,
      claimProcessorType: OrgType | null,
      claimProcessor:  {
        __typename: "Organisation",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        type: OrgType,
        phone: string | null,
        email: string | null,
        expensesInstruction: string | null,
        declarationText: string | null,
        additionalNotesText: string | null,
        invoiceEmail: string | null,
        updatedBy: string | null,
        deleted: boolean | null,
      } | null,
      cCGOrganisationId: string,
      organisation:  {
        __typename: "Organisation",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        type: OrgType,
        phone: string | null,
        email: string | null,
        expensesInstruction: string | null,
        declarationText: string | null,
        additionalNotesText: string | null,
        invoiceEmail: string | null,
        updatedBy: string | null,
        deleted: boolean | null,
      },
    } >,
    orderClause:  {
      __typename: "GenericOrderClause",
      field: string,
      direction: ModelSortDirection,
    },
    paginateClause:  {
      __typename: "PaginateClause",
      limit: number,
      offset: number,
    },
    total: number,
  },
};

export type FindContactedDoctorsQueryVariables = {
  filter?: ModelContactedDoctorFilterInput | null,
  paginateClause?: PaginateClauseInput | null,
  orderClause?: GenericOrderClauseInput | null,
};

export type FindContactedDoctorsQuery = {
  findContactedDoctors:  {
    __typename: "ContactedDoctorResult",
    items:  Array< {
      __typename: "ContactedDoctor",
      id: string,
      assessmentId: string,
      contactedDoctorS12DoctorId: string,
      assessment:  {
        __typename: "Assessment",
        id: string,
        createdAt: string,
        amhpTeamId: string,
        amhpId: string,
        patientName: string,
        ccgId: string | null,
        ccgDetermination: LocationType | null,
        hadVisitAdded: boolean | null,
        patientNhsNumber: string | null,
        assessmentDate: string | null,
      },
      s12Doctor:  {
        __typename: "S12Doctor",
        id: string,
        gmc: string,
        s12Expiry: string | null,
        name: string,
        legalName: string,
        phone: string | null,
        phone2: string | null,
        email: string,
        gl: boolean | null,
        employer: string | null,
        jobTitle: string | null,
        responsibleOfficer: string | null,
        lineManager: string | null,
        defaultMHT: string | null,
        specialties: Array< string | null >,
        languages: Array< string | null >,
        gender: Gender | null,
        notes: string | null,
        pushTokens: Array< string | null > | null,
        distance: number | null,
        hideFromSearch: boolean | null,
        availabilityPostcodes: Array< string | null > | null,
        siteName: string,
        sectionFormEmailAddress: string | null,
        createdAt: string | null,
        lastActivity: string | null,
        isOutsideEngland: boolean | null,
      },
      createdAt: string,
    } >,
    orderClause:  {
      __typename: "GenericOrderClause",
      field: string,
      direction: ModelSortDirection,
    },
    paginateClause:  {
      __typename: "PaginateClause",
      limit: number,
      offset: number,
    },
    total: number,
  },
};

export type FindReportingsQueryVariables = {
  filter?: ModelReportingFilterInput | null,
  paginateClause?: PaginateClauseInput | null,
  orderClause?: GenericOrderClauseInput | null,
};

export type FindReportingsQuery = {
  findReportings:  {
    __typename: "ReportingResult",
    items:  Array< {
      __typename: "Reporting",
      id: string,
      pk: string,
      sk: string,
      ttl: number | null,
      gsi1pk: string | null,
      gsi1sk: string | null,
      gsi2pk: string | null,
      gsi2sk: string | null,
      gsi3pk: string | null,
      gsi3sk: string | null,
      eventName: string,
      userId: string,
      timestamp: string,
      period: string,
      count: number | null,
      siteId: string | null,
      teamId: string | null,
      orgId: string | null,
      amhpId: string | null,
      amhp:  {
        __typename: "AmhpProfile",
        id: string,
        email: string,
        phone: string | null,
        name: string,
        legalName: string | null,
        deleted: boolean | null,
        warrantInfo: string | null,
        accreditation: string | null,
        sectionFormEmailAddress: string | null,
        sectionFormContactNumber: string | null,
        pushTokens: Array< string | null > | null,
        lastActivity: string | null,
        createdAt: string | null,
      } | null,
      doctorId: string | null,
      doctor:  {
        __typename: "S12Doctor",
        id: string,
        gmc: string,
        s12Expiry: string | null,
        name: string,
        legalName: string,
        phone: string | null,
        phone2: string | null,
        email: string,
        gl: boolean | null,
        employer: string | null,
        jobTitle: string | null,
        responsibleOfficer: string | null,
        lineManager: string | null,
        defaultMHT: string | null,
        specialties: Array< string | null >,
        languages: Array< string | null >,
        gender: Gender | null,
        notes: string | null,
        pushTokens: Array< string | null > | null,
        distance: number | null,
        hideFromSearch: boolean | null,
        availabilityPostcodes: Array< string | null > | null,
        siteName: string,
        sectionFormEmailAddress: string | null,
        createdAt: string | null,
        lastActivity: string | null,
        isOutsideEngland: boolean | null,
      } | null,
      claimProcessorId: string | null,
      claimProcessor:  {
        __typename: "OrganisationUser",
        id: string,
        organisationUserOrganisationId: string,
        type: string,
        organisationUserUserId: string,
      } | null,
      attributes:  Array< {
        __typename: "Attribute",
        name: string,
        value: string,
      } > | null,
      metrics:  Array< {
        __typename: "Metric",
        name: string,
        value: number,
      } > | null,
    } >,
    orderClause:  {
      __typename: "GenericOrderClause",
      field: string,
      direction: ModelSortDirection,
    },
    paginateClause:  {
      __typename: "PaginateClause",
      limit: number,
      offset: number,
    },
    total: number,
  },
};

export type JoinMeetingQueryVariables = {
  assessmentId: string,
};

export type JoinMeetingQuery = {
  joinMeeting: string,
};

export type AdminGetUsersQueryVariables = {
  userType?: AdminGetUsersUserType | null,
  filterField?: AdminGetUserFilterField | null,
  searchQuery?: string | null,
};

export type AdminGetUsersQuery = {
  adminGetUsers:  Array< {
    __typename: "AdminUser",
    id: string,
    name: string,
    email: string,
    phone: string | null,
    mfa: boolean | null,
    enabled: boolean | null,
    isTraineeAmhp: boolean | null,
  } >,
};

export type AdminDoctorReportQueryVariables = {
  siteId: string,
  siteName: string,
  yearMonth: string,
};

export type AdminDoctorReportQuery = {
  adminDoctorReport: Array< Array< string | null > | null >,
};

export type AdminAmhpReportQueryVariables = {
  siteId: string,
  siteName: string,
  yearMonth: string,
};

export type AdminAmhpReportQuery = {
  adminAmhpReport: Array< Array< string | null > | null >,
};

export type AdminSiteReportQueryVariables = {
  siteId: string,
  siteName: string,
  yearMonth: string,
};

export type AdminSiteReportQuery = {
  adminSiteReport: Array< Array< string | null > | null >,
};

export type AdminExportAvailabilityQueryVariables = {
  siteName?: string | null,
  start?: string | null,
  end?: string | null,
};

export type AdminExportAvailabilityQuery = {
  adminExportAvailability: Array< Array< string | null > | null >,
};

export type GetGmcApprovedStatusQueryVariables = {
  gmc: string,
};

export type GetGmcApprovedStatusQuery = {
  getGMCApprovedStatus: boolean,
};

export type AdminGetUserDetailsQueryVariables = {
  userId: string,
};

export type AdminGetUserDetailsQuery = {
  adminGetUserDetails:  {
    __typename: "AdminUser",
    id: string,
    name: string,
    email: string,
    phone: string | null,
    mfa: boolean | null,
    enabled: boolean | null,
    isTraineeAmhp: boolean | null,
  },
};

export type GetFormQueryVariables = {
  id: string,
  sk: string,
};

export type GetFormQuery = {
  getForm:  {
    __typename: "Form",
    id: string,
    sk: string,
    ttl: number,
    version: number,
    gsi1pk: string,
    gsi1sk: string,
    gsi2pk: string,
    gsi2sk: string,
    gsi3pk: string,
    gsi3sk: string,
    assessmentId: string,
    assessment:  {
      __typename: "Assessment",
      id: string,
      createdAt: string,
      amhpTeamId: string,
      amhpTeam:  {
        __typename: "AmhpTeam",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        localAuthority: string | null,
        emails: Array< string > | null,
        phoneNumbers: Array< string > | null,
        mhaInboxInstructions: string | null,
        updatedBy: string | null,
      },
      amhpId: string,
      amhp:  {
        __typename: "AmhpProfile",
        id: string,
        email: string,
        phone: string | null,
        name: string,
        legalName: string | null,
        deleted: boolean | null,
        warrantInfo: string | null,
        accreditation: string | null,
        sectionFormEmailAddress: string | null,
        sectionFormContactNumber: string | null,
        pushTokens: Array< string | null > | null,
        lastActivity: string | null,
        createdAt: string | null,
      },
      patientName: string,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      },
      ccgId: string | null,
      ccg:  {
        __typename: "Organisation",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        type: OrgType,
        phone: string | null,
        email: string | null,
        expensesInstruction: string | null,
        declarationText: string | null,
        additionalNotesText: string | null,
        invoiceEmail: string | null,
        updatedBy: string | null,
        deleted: boolean | null,
      } | null,
      ccgDetermination: LocationType | null,
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      },
      hadVisitAdded: boolean | null,
      doctorVisits:  {
        __typename: "ModelDoctorVisitConnection",
        nextToken: string | null,
      } | null,
      patientNhsNumber: string | null,
      assessmentDate: string | null,
      contactedDoctors:  {
        __typename: "ModelContactedDoctorConnection",
        nextToken: string | null,
      } | null,
      forms:  {
        __typename: "ModelFormConnection",
        nextToken: string | null,
      } | null,
      patientAddress:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
    } | null,
    status: FormStatus,
    createdBy: string,
    createdAt: string,
    updatedAt: string,
    updatedBy: string,
    amhpTeamInboxId: string,
    amhpTeamId: string,
    authors: Array< string >,
    doctorNames: Array< string > | null,
    signed: Array< string > | null,
    signedClaims: Array< string > | null,
    sha256: string | null,
    notes: Array< string >,
    type: string,
    data:  {
      __typename: "FormData",
      sections:  Array< {
        __typename: "FormSection",
        name: string,
      } >,
    } | null,
    isDeleted: boolean | null,
    amhpTeam:  {
      __typename: "AmhpTeam",
      id: string,
      name: string,
      featureFlags: string | null,
      features: string | null,
      assessments:  {
        __typename: "ModelAssessmentConnection",
        nextToken: string | null,
      } | null,
      users:  {
        __typename: "ModelAmhpTeamUserConnection",
        nextToken: string | null,
      } | null,
      commonAddresses:  {
        __typename: "ModelAddressConnection",
        nextToken: string | null,
      } | null,
      localAuthority: string | null,
      hospitalEmails:  Array< {
        __typename: "HospitalEmails",
        name: string,
      } > | null,
      mhaEmailAddresses:  Array< {
        __typename: "RecipientEmails",
        name: string | null,
      } | null > | null,
      emails: Array< string > | null,
      phoneNumbers: Array< string > | null,
      mhaInboxInstructions: string | null,
      reports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      summaryReports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      updatedBy: string | null,
    } | null,
  } | null,
};

export type ListFormsQueryVariables = {
  id?: string | null,
  sk?: ModelStringKeyConditionInput | null,
  filter?: ModelFormFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListFormsQuery = {
  listForms:  {
    __typename: "ModelFormConnection",
    items:  Array< {
      __typename: "Form",
      id: string,
      sk: string,
      ttl: number,
      version: number,
      gsi1pk: string,
      gsi1sk: string,
      gsi2pk: string,
      gsi2sk: string,
      gsi3pk: string,
      gsi3sk: string,
      assessmentId: string,
      assessment:  {
        __typename: "Assessment",
        id: string,
        createdAt: string,
        amhpTeamId: string,
        amhpId: string,
        patientName: string,
        ccgId: string | null,
        ccgDetermination: LocationType | null,
        hadVisitAdded: boolean | null,
        patientNhsNumber: string | null,
        assessmentDate: string | null,
      } | null,
      status: FormStatus,
      createdBy: string,
      createdAt: string,
      updatedAt: string,
      updatedBy: string,
      amhpTeamInboxId: string,
      amhpTeamId: string,
      authors: Array< string >,
      doctorNames: Array< string > | null,
      signed: Array< string > | null,
      signedClaims: Array< string > | null,
      sha256: string | null,
      notes: Array< string >,
      type: string,
      isDeleted: boolean | null,
      amhpTeam:  {
        __typename: "AmhpTeam",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        localAuthority: string | null,
        emails: Array< string > | null,
        phoneNumbers: Array< string > | null,
        mhaInboxInstructions: string | null,
        updatedBy: string | null,
      } | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetAvailabilityQueryVariables = {
  id: string,
};

export type GetAvailabilityQuery = {
  getAvailability:  {
    __typename: "Availability",
    id: string,
    createdAt: string | null,
    type: AvailabilityType | null,
    location:  {
      __typename: "Location",
      lat: number,
      lon: number,
    } | null,
    locationName:  {
      __typename: "LocationName",
      city: string | null,
      postcode: string,
      address: string | null,
      addressNotes: string | null,
    } | null,
    rrule: string,
    endHour: number,
    endDate: string,
    contractList:  {
      __typename: "ContractList",
      name: string,
      description: string | null,
      owner:  {
        __typename: "Organisation",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        type: OrgType,
        phone: string | null,
        email: string | null,
        expensesInstruction: string | null,
        declarationText: string | null,
        additionalNotesText: string | null,
        invoiceEmail: string | null,
        updatedBy: string | null,
        deleted: boolean | null,
      } | null,
    } | null,
    notes: string | null,
    s12DoctorAvailabilitiesId: string,
    availabilityDoctorId: string,
    oncall: boolean | null,
    mht:  {
      __typename: "MHT",
      id: string,
      abbreviation: string,
      organisation:  {
        __typename: "Organisation",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        type: OrgType,
        phone: string | null,
        email: string | null,
        expensesInstruction: string | null,
        declarationText: string | null,
        additionalNotesText: string | null,
        invoiceEmail: string | null,
        updatedBy: string | null,
        deleted: boolean | null,
      },
      mHTOrganisationId: string,
      s12Doctors:  {
        __typename: "ModelMHTS12DoctorConnection",
        nextToken: string | null,
      } | null,
    } | null,
    doctor:  {
      __typename: "S12Doctor",
      id: string,
      gmc: string,
      s12Expiry: string | null,
      name: string,
      legalName: string,
      phone: string | null,
      phone2: string | null,
      email: string,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      },
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      },
      gl: boolean | null,
      employer: string | null,
      jobTitle: string | null,
      responsibleOfficer: string | null,
      lineManager: string | null,
      defaultMHT: string | null,
      mhtEmployers:  {
        __typename: "ModelMHTS12DoctorConnection",
        nextToken: string | null,
      } | null,
      availabilities:  {
        __typename: "ModelAvailabilityConnection",
        nextToken: string | null,
      } | null,
      holidays:  {
        __typename: "ModelHolidayConnection",
        nextToken: string | null,
      } | null,
      specialties: Array< string | null >,
      languages: Array< string | null >,
      gender: Gender | null,
      notes: string | null,
      pushTokens: Array< string | null > | null,
      distance: number | null,
      hideFromSearch: boolean | null,
      visits:  {
        __typename: "ModelDoctorVisitConnection",
        nextToken: string | null,
      } | null,
      doctors:  {
        __typename: "ModelContactedDoctorConnection",
        nextToken: string | null,
      } | null,
      claims:  {
        __typename: "ModelClaimConnection",
        nextToken: string | null,
      } | null,
      availabilityLocations:  Array< {
        __typename: "Location",
        lat: number,
        lon: number,
      } | null > | null,
      availabilityPostcodes: Array< string | null > | null,
      siteName: string,
      claimData:  {
        __typename: "ClaimData",
        billingCompanyName: string | null,
        vatRegistration: string | null,
        fuelType: FuelType | null,
        engineSize: Engine | null,
      } | null,
      sectionFormAddress:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      sectionFormEmailAddress: string | null,
      reports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      summaryReports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      createdAt: string | null,
      lastActivity: string | null,
      isOutsideEngland: boolean | null,
    },
    rotaId: string | null,
    rotaName: string | null,
  } | null,
};

export type ListAvailabilitysQueryVariables = {
  filter?: ModelAvailabilityFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAvailabilitysQuery = {
  listAvailabilitys:  {
    __typename: "ModelAvailabilityConnection",
    items:  Array< {
      __typename: "Availability",
      id: string,
      createdAt: string | null,
      type: AvailabilityType | null,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      } | null,
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      rrule: string,
      endHour: number,
      endDate: string,
      contractList:  {
        __typename: "ContractList",
        name: string,
        description: string | null,
      } | null,
      notes: string | null,
      s12DoctorAvailabilitiesId: string,
      availabilityDoctorId: string,
      oncall: boolean | null,
      mht:  {
        __typename: "MHT",
        id: string,
        abbreviation: string,
        mHTOrganisationId: string,
      } | null,
      doctor:  {
        __typename: "S12Doctor",
        id: string,
        gmc: string,
        s12Expiry: string | null,
        name: string,
        legalName: string,
        phone: string | null,
        phone2: string | null,
        email: string,
        gl: boolean | null,
        employer: string | null,
        jobTitle: string | null,
        responsibleOfficer: string | null,
        lineManager: string | null,
        defaultMHT: string | null,
        specialties: Array< string | null >,
        languages: Array< string | null >,
        gender: Gender | null,
        notes: string | null,
        pushTokens: Array< string | null > | null,
        distance: number | null,
        hideFromSearch: boolean | null,
        availabilityPostcodes: Array< string | null > | null,
        siteName: string,
        sectionFormEmailAddress: string | null,
        createdAt: string | null,
        lastActivity: string | null,
        isOutsideEngland: boolean | null,
      },
      rotaId: string | null,
      rotaName: string | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetHolidayQueryVariables = {
  id: string,
};

export type GetHolidayQuery = {
  getHoliday:  {
    __typename: "Holiday",
    id: string,
    start: string,
    end: string,
    visible: boolean,
    notes: string | null,
    s12DoctorHolidaysId: string,
    doctor:  {
      __typename: "S12Doctor",
      id: string,
      gmc: string,
      s12Expiry: string | null,
      name: string,
      legalName: string,
      phone: string | null,
      phone2: string | null,
      email: string,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      },
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      },
      gl: boolean | null,
      employer: string | null,
      jobTitle: string | null,
      responsibleOfficer: string | null,
      lineManager: string | null,
      defaultMHT: string | null,
      mhtEmployers:  {
        __typename: "ModelMHTS12DoctorConnection",
        nextToken: string | null,
      } | null,
      availabilities:  {
        __typename: "ModelAvailabilityConnection",
        nextToken: string | null,
      } | null,
      holidays:  {
        __typename: "ModelHolidayConnection",
        nextToken: string | null,
      } | null,
      specialties: Array< string | null >,
      languages: Array< string | null >,
      gender: Gender | null,
      notes: string | null,
      pushTokens: Array< string | null > | null,
      distance: number | null,
      hideFromSearch: boolean | null,
      visits:  {
        __typename: "ModelDoctorVisitConnection",
        nextToken: string | null,
      } | null,
      doctors:  {
        __typename: "ModelContactedDoctorConnection",
        nextToken: string | null,
      } | null,
      claims:  {
        __typename: "ModelClaimConnection",
        nextToken: string | null,
      } | null,
      availabilityLocations:  Array< {
        __typename: "Location",
        lat: number,
        lon: number,
      } | null > | null,
      availabilityPostcodes: Array< string | null > | null,
      siteName: string,
      claimData:  {
        __typename: "ClaimData",
        billingCompanyName: string | null,
        vatRegistration: string | null,
        fuelType: FuelType | null,
        engineSize: Engine | null,
      } | null,
      sectionFormAddress:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      sectionFormEmailAddress: string | null,
      reports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      summaryReports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      createdAt: string | null,
      lastActivity: string | null,
      isOutsideEngland: boolean | null,
    },
  } | null,
};

export type ListHolidaysQueryVariables = {
  filter?: ModelHolidayFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListHolidaysQuery = {
  listHolidays:  {
    __typename: "ModelHolidayConnection",
    items:  Array< {
      __typename: "Holiday",
      id: string,
      start: string,
      end: string,
      visible: boolean,
      notes: string | null,
      s12DoctorHolidaysId: string,
      doctor:  {
        __typename: "S12Doctor",
        id: string,
        gmc: string,
        s12Expiry: string | null,
        name: string,
        legalName: string,
        phone: string | null,
        phone2: string | null,
        email: string,
        gl: boolean | null,
        employer: string | null,
        jobTitle: string | null,
        responsibleOfficer: string | null,
        lineManager: string | null,
        defaultMHT: string | null,
        specialties: Array< string | null >,
        languages: Array< string | null >,
        gender: Gender | null,
        notes: string | null,
        pushTokens: Array< string | null > | null,
        distance: number | null,
        hideFromSearch: boolean | null,
        availabilityPostcodes: Array< string | null > | null,
        siteName: string,
        sectionFormEmailAddress: string | null,
        createdAt: string | null,
        lastActivity: string | null,
        isOutsideEngland: boolean | null,
      },
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetOrganisationQueryVariables = {
  type: OrgType,
  id: string,
};

export type GetOrganisationQuery = {
  getOrganisation:  {
    __typename: "Organisation",
    id: string,
    name: string,
    featureFlags: string | null,
    features: string | null,
    type: OrgType,
    location:  {
      __typename: "Location",
      lat: number,
      lon: number,
    } | null,
    locationName:  {
      __typename: "LocationName",
      city: string | null,
      postcode: string,
      address: string | null,
      addressNotes: string | null,
    } | null,
    phone: string | null,
    email: string | null,
    users:  {
      __typename: "ModelOrganisationUserConnection",
      items:  Array< {
        __typename: "OrganisationUser",
        id: string,
        organisationUserOrganisationId: string,
        type: string,
        organisationUserUserId: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
    sites:  {
      __typename: "ModelSiteOrganisationConnection",
      items:  Array< {
        __typename: "SiteOrganisation",
        id: string,
        organisationType: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    ccg:  {
      __typename: "CCG",
      id: string,
      claimProcessorType: OrgType | null,
      claimProcessor:  {
        __typename: "Organisation",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        type: OrgType,
        phone: string | null,
        email: string | null,
        expensesInstruction: string | null,
        declarationText: string | null,
        additionalNotesText: string | null,
        invoiceEmail: string | null,
        updatedBy: string | null,
        deleted: boolean | null,
      } | null,
      cCGOrganisationId: string,
      organisation:  {
        __typename: "Organisation",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        type: OrgType,
        phone: string | null,
        email: string | null,
        expensesInstruction: string | null,
        declarationText: string | null,
        additionalNotesText: string | null,
        invoiceEmail: string | null,
        updatedBy: string | null,
        deleted: boolean | null,
      },
    } | null,
    mht:  {
      __typename: "MHT",
      id: string,
      abbreviation: string,
      organisation:  {
        __typename: "Organisation",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        type: OrgType,
        phone: string | null,
        email: string | null,
        expensesInstruction: string | null,
        declarationText: string | null,
        additionalNotesText: string | null,
        invoiceEmail: string | null,
        updatedBy: string | null,
        deleted: boolean | null,
      },
      mHTOrganisationId: string,
      s12Doctors:  {
        __typename: "ModelMHTS12DoctorConnection",
        nextToken: string | null,
      } | null,
    } | null,
    claims:  {
      __typename: "ModelClaimConnection",
      items:  Array< {
        __typename: "Claim",
        id: string,
        claimAssessmentId: string | null,
        claimVisitId: string,
        claimDoctorId: string,
        assigneeId: string,
        assigneeName: string | null,
        amhpTeamId: string,
        claimAmhpId: string,
        claimOrganisationId: string,
        claimCsuId: string,
        status: ClaimStatus,
        displayId: string,
        mileage: string | null,
        locationText: string,
        locationType: LocationType,
        claimPostcode: string | null,
        assessmentPostcode: string | null,
        patientInitials: string,
        patientFullName: string | null,
        startingPostcode: string | null,
        visitDate: string,
        assessmentTookPlace: boolean | null,
        notes: Array< string | null > | null,
        unread: boolean | null,
        createdAt: string | null,
        updatedAt: string,
        receivedDate: string,
        additionalExpenses: string | null,
        additionalInformation: string | null,
        patientNhsNumber: string | null,
        declaration: string | null,
        paid: boolean | null,
        lineManager: string | null,
        doctorAdditionalConf: DoctorConfirmation | null,
        isIndependentDoctor: boolean | null,
        defaultMHT: string | null,
        doctorEmployedStatus: DoctorEmployedStatusValues | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    expensesInstruction: string | null,
    declarationText: string | null,
    additionalNotesText: string | null,
    paymentRates:  Array< {
      __typename: "PaymentRates",
      description: string | null,
      assessmentFee: number,
      mileage: number,
    } | null > | null,
    invoiceEmail: string | null,
    updatedBy: string | null,
    mhtHospitalEmails:  Array< {
      __typename: "HospitalEmails",
      name: string,
      wards:  Array< {
        __typename: "EmailAddress",
        name: string,
        email: string,
      } >,
    } | null > | null,
    rotas:  Array< {
      __typename: "RotaItem",
      id: string,
      name: string,
    } | null > | null,
    deleted: boolean | null,
  } | null,
};

export type ListOrganisationsQueryVariables = {
  type?: OrgType | null,
  id?: ModelIDKeyConditionInput | null,
  filter?: ModelOrganisationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListOrganisationsQuery = {
  listOrganisations:  {
    __typename: "ModelOrganisationConnection",
    items:  Array< {
      __typename: "Organisation",
      id: string,
      name: string,
      featureFlags: string | null,
      features: string | null,
      type: OrgType,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      } | null,
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      phone: string | null,
      email: string | null,
      users:  {
        __typename: "ModelOrganisationUserConnection",
        nextToken: string | null,
      } | null,
      sites:  {
        __typename: "ModelSiteOrganisationConnection",
        nextToken: string | null,
      } | null,
      ccg:  {
        __typename: "CCG",
        id: string,
        claimProcessorType: OrgType | null,
        cCGOrganisationId: string,
      } | null,
      mht:  {
        __typename: "MHT",
        id: string,
        abbreviation: string,
        mHTOrganisationId: string,
      } | null,
      claims:  {
        __typename: "ModelClaimConnection",
        nextToken: string | null,
      } | null,
      expensesInstruction: string | null,
      declarationText: string | null,
      additionalNotesText: string | null,
      paymentRates:  Array< {
        __typename: "PaymentRates",
        description: string | null,
        assessmentFee: number,
        mileage: number,
      } | null > | null,
      invoiceEmail: string | null,
      updatedBy: string | null,
      mhtHospitalEmails:  Array< {
        __typename: "HospitalEmails",
        name: string,
      } | null > | null,
      rotas:  Array< {
        __typename: "RotaItem",
        id: string,
        name: string,
      } | null > | null,
      deleted: boolean | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetMhtQueryVariables = {
  id: string,
};

export type GetMhtQuery = {
  getMHT:  {
    __typename: "MHT",
    id: string,
    abbreviation: string,
    organisation:  {
      __typename: "Organisation",
      id: string,
      name: string,
      featureFlags: string | null,
      features: string | null,
      type: OrgType,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      } | null,
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      phone: string | null,
      email: string | null,
      users:  {
        __typename: "ModelOrganisationUserConnection",
        nextToken: string | null,
      } | null,
      sites:  {
        __typename: "ModelSiteOrganisationConnection",
        nextToken: string | null,
      } | null,
      ccg:  {
        __typename: "CCG",
        id: string,
        claimProcessorType: OrgType | null,
        cCGOrganisationId: string,
      } | null,
      mht:  {
        __typename: "MHT",
        id: string,
        abbreviation: string,
        mHTOrganisationId: string,
      } | null,
      claims:  {
        __typename: "ModelClaimConnection",
        nextToken: string | null,
      } | null,
      expensesInstruction: string | null,
      declarationText: string | null,
      additionalNotesText: string | null,
      paymentRates:  Array< {
        __typename: "PaymentRates",
        description: string | null,
        assessmentFee: number,
        mileage: number,
      } | null > | null,
      invoiceEmail: string | null,
      updatedBy: string | null,
      mhtHospitalEmails:  Array< {
        __typename: "HospitalEmails",
        name: string,
      } | null > | null,
      rotas:  Array< {
        __typename: "RotaItem",
        id: string,
        name: string,
      } | null > | null,
      deleted: boolean | null,
    },
    mHTOrganisationId: string,
    s12Doctors:  {
      __typename: "ModelMHTS12DoctorConnection",
      items:  Array< {
        __typename: "MHTS12Doctor",
        id: string,
        mHTS12DoctorS12DoctorId: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type ListMhTsQueryVariables = {
  filter?: ModelMHTFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMhTsQuery = {
  listMHTs:  {
    __typename: "ModelMHTConnection",
    items:  Array< {
      __typename: "MHT",
      id: string,
      abbreviation: string,
      organisation:  {
        __typename: "Organisation",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        type: OrgType,
        phone: string | null,
        email: string | null,
        expensesInstruction: string | null,
        declarationText: string | null,
        additionalNotesText: string | null,
        invoiceEmail: string | null,
        updatedBy: string | null,
        deleted: boolean | null,
      },
      mHTOrganisationId: string,
      s12Doctors:  {
        __typename: "ModelMHTS12DoctorConnection",
        nextToken: string | null,
      } | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetUserQueryVariables = {
  id: string,
};

export type GetUserQuery = {
  getUser:  {
    __typename: "User",
    id: string,
    name: string,
    email: string,
    phone: string | null,
    organisations:  {
      __typename: "ModelOrganisationUserConnection",
      items:  Array< {
        __typename: "OrganisationUser",
        id: string,
        organisationUserOrganisationId: string,
        type: string,
        organisationUserUserId: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type ListUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersQuery = {
  listUsers:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      name: string,
      email: string,
      phone: string | null,
      organisations:  {
        __typename: "ModelOrganisationUserConnection",
        nextToken: string | null,
      } | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetSiteQueryVariables = {
  id: string,
};

export type GetSiteQuery = {
  getSite:  {
    __typename: "Site",
    id: string,
    name: string,
    organisations:  {
      __typename: "ModelSiteOrganisationConnection",
      items:  Array< {
        __typename: "SiteOrganisation",
        id: string,
        organisationType: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    amhpTeams:  {
      __typename: "ModelAmhpTeamConnection",
      items:  Array< {
        __typename: "AmhpTeam",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        localAuthority: string | null,
        emails: Array< string > | null,
        phoneNumbers: Array< string > | null,
        mhaInboxInstructions: string | null,
        updatedBy: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    doctors:  {
      __typename: "ModelS12DoctorConnection",
      items:  Array< {
        __typename: "S12Doctor",
        id: string,
        gmc: string,
        s12Expiry: string | null,
        name: string,
        legalName: string,
        phone: string | null,
        phone2: string | null,
        email: string,
        gl: boolean | null,
        employer: string | null,
        jobTitle: string | null,
        responsibleOfficer: string | null,
        lineManager: string | null,
        defaultMHT: string | null,
        specialties: Array< string | null >,
        languages: Array< string | null >,
        gender: Gender | null,
        notes: string | null,
        pushTokens: Array< string | null > | null,
        distance: number | null,
        hideFromSearch: boolean | null,
        availabilityPostcodes: Array< string | null > | null,
        siteName: string,
        sectionFormEmailAddress: string | null,
        createdAt: string | null,
        lastActivity: string | null,
        isOutsideEngland: boolean | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    doctorReports:  {
      __typename: "ModelReportingConnection",
      items:  Array< {
        __typename: "Reporting",
        id: string,
        pk: string,
        sk: string,
        ttl: number | null,
        gsi1pk: string | null,
        gsi1sk: string | null,
        gsi2pk: string | null,
        gsi2sk: string | null,
        gsi3pk: string | null,
        gsi3sk: string | null,
        eventName: string,
        userId: string,
        timestamp: string,
        period: string,
        count: number | null,
        siteId: string | null,
        teamId: string | null,
        orgId: string | null,
        amhpId: string | null,
        doctorId: string | null,
        claimProcessorId: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    summaryReports:  {
      __typename: "ModelReportingConnection",
      items:  Array< {
        __typename: "Reporting",
        id: string,
        pk: string,
        sk: string,
        ttl: number | null,
        gsi1pk: string | null,
        gsi1sk: string | null,
        gsi2pk: string | null,
        gsi2sk: string | null,
        gsi3pk: string | null,
        gsi3sk: string | null,
        eventName: string,
        userId: string,
        timestamp: string,
        period: string,
        count: number | null,
        siteId: string | null,
        teamId: string | null,
        orgId: string | null,
        amhpId: string | null,
        doctorId: string | null,
        claimProcessorId: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type ListSitesQueryVariables = {
  filter?: ModelSiteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSitesQuery = {
  listSites:  {
    __typename: "ModelSiteConnection",
    items:  Array< {
      __typename: "Site",
      id: string,
      name: string,
      organisations:  {
        __typename: "ModelSiteOrganisationConnection",
        nextToken: string | null,
      } | null,
      amhpTeams:  {
        __typename: "ModelAmhpTeamConnection",
        nextToken: string | null,
      } | null,
      doctors:  {
        __typename: "ModelS12DoctorConnection",
        nextToken: string | null,
      } | null,
      doctorReports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      summaryReports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetLinkedSitesQueryVariables = {
  id: string,
};

export type GetLinkedSitesQuery = {
  getLinkedSites:  {
    __typename: "LinkedSites",
    id: string,
    parentSite: string,
    childSite: string,
  } | null,
};

export type ListLinkedSitessQueryVariables = {
  filter?: ModelLinkedSitesFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListLinkedSitessQuery = {
  listLinkedSitess:  {
    __typename: "ModelLinkedSitesConnection",
    items:  Array< {
      __typename: "LinkedSites",
      id: string,
      parentSite: string,
      childSite: string,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetAmhpTeamQueryVariables = {
  id: string,
};

export type GetAmhpTeamQuery = {
  getAmhpTeam:  {
    __typename: "AmhpTeam",
    id: string,
    name: string,
    featureFlags: string | null,
    features: string | null,
    assessments:  {
      __typename: "ModelAssessmentConnection",
      items:  Array< {
        __typename: "Assessment",
        id: string,
        createdAt: string,
        amhpTeamId: string,
        amhpId: string,
        patientName: string,
        ccgId: string | null,
        ccgDetermination: LocationType | null,
        hadVisitAdded: boolean | null,
        patientNhsNumber: string | null,
        assessmentDate: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    users:  {
      __typename: "ModelAmhpTeamUserConnection",
      items:  Array< {
        __typename: "AmhpTeamUser",
        id: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
    commonAddresses:  {
      __typename: "ModelAddressConnection",
      items:  Array< {
        __typename: "Address",
        id: string,
        amhpTeamId: string,
        ccgId: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
    localAuthority: string | null,
    hospitalEmails:  Array< {
      __typename: "HospitalEmails",
      name: string,
      wards:  Array< {
        __typename: "EmailAddress",
        name: string,
        email: string,
      } >,
    } > | null,
    mhaEmailAddresses:  Array< {
      __typename: "RecipientEmails",
      name: string | null,
      recipients:  Array< {
        __typename: "RecipientEmailAddress",
        name: string | null,
        email: string | null,
      } | null > | null,
    } | null > | null,
    emails: Array< string > | null,
    phoneNumbers: Array< string > | null,
    mhaInboxInstructions: string | null,
    reports:  {
      __typename: "ModelReportingConnection",
      items:  Array< {
        __typename: "Reporting",
        id: string,
        pk: string,
        sk: string,
        ttl: number | null,
        gsi1pk: string | null,
        gsi1sk: string | null,
        gsi2pk: string | null,
        gsi2sk: string | null,
        gsi3pk: string | null,
        gsi3sk: string | null,
        eventName: string,
        userId: string,
        timestamp: string,
        period: string,
        count: number | null,
        siteId: string | null,
        teamId: string | null,
        orgId: string | null,
        amhpId: string | null,
        doctorId: string | null,
        claimProcessorId: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    summaryReports:  {
      __typename: "ModelReportingConnection",
      items:  Array< {
        __typename: "Reporting",
        id: string,
        pk: string,
        sk: string,
        ttl: number | null,
        gsi1pk: string | null,
        gsi1sk: string | null,
        gsi2pk: string | null,
        gsi2sk: string | null,
        gsi3pk: string | null,
        gsi3sk: string | null,
        eventName: string,
        userId: string,
        timestamp: string,
        period: string,
        count: number | null,
        siteId: string | null,
        teamId: string | null,
        orgId: string | null,
        amhpId: string | null,
        doctorId: string | null,
        claimProcessorId: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    updatedBy: string | null,
  } | null,
};

export type ListAmhpTeamsQueryVariables = {
  filter?: ModelAmhpTeamFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAmhpTeamsQuery = {
  listAmhpTeams:  {
    __typename: "ModelAmhpTeamConnection",
    items:  Array< {
      __typename: "AmhpTeam",
      id: string,
      name: string,
      featureFlags: string | null,
      features: string | null,
      assessments:  {
        __typename: "ModelAssessmentConnection",
        nextToken: string | null,
      } | null,
      users:  {
        __typename: "ModelAmhpTeamUserConnection",
        nextToken: string | null,
      } | null,
      commonAddresses:  {
        __typename: "ModelAddressConnection",
        nextToken: string | null,
      } | null,
      localAuthority: string | null,
      hospitalEmails:  Array< {
        __typename: "HospitalEmails",
        name: string,
      } > | null,
      mhaEmailAddresses:  Array< {
        __typename: "RecipientEmails",
        name: string | null,
      } | null > | null,
      emails: Array< string > | null,
      phoneNumbers: Array< string > | null,
      mhaInboxInstructions: string | null,
      reports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      summaryReports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      updatedBy: string | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetAddressQueryVariables = {
  id: string,
};

export type GetAddressQuery = {
  getAddress:  {
    __typename: "Address",
    id: string,
    amhpTeamId: string,
    locationName:  {
      __typename: "LocationName",
      city: string | null,
      postcode: string,
      address: string | null,
      addressNotes: string | null,
    },
    location:  {
      __typename: "Location",
      lat: number,
      lon: number,
    },
    ccgId: string,
    ccg:  {
      __typename: "Organisation",
      id: string,
      name: string,
      featureFlags: string | null,
      features: string | null,
      type: OrgType,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      } | null,
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      phone: string | null,
      email: string | null,
      users:  {
        __typename: "ModelOrganisationUserConnection",
        nextToken: string | null,
      } | null,
      sites:  {
        __typename: "ModelSiteOrganisationConnection",
        nextToken: string | null,
      } | null,
      ccg:  {
        __typename: "CCG",
        id: string,
        claimProcessorType: OrgType | null,
        cCGOrganisationId: string,
      } | null,
      mht:  {
        __typename: "MHT",
        id: string,
        abbreviation: string,
        mHTOrganisationId: string,
      } | null,
      claims:  {
        __typename: "ModelClaimConnection",
        nextToken: string | null,
      } | null,
      expensesInstruction: string | null,
      declarationText: string | null,
      additionalNotesText: string | null,
      paymentRates:  Array< {
        __typename: "PaymentRates",
        description: string | null,
        assessmentFee: number,
        mileage: number,
      } | null > | null,
      invoiceEmail: string | null,
      updatedBy: string | null,
      mhtHospitalEmails:  Array< {
        __typename: "HospitalEmails",
        name: string,
      } | null > | null,
      rotas:  Array< {
        __typename: "RotaItem",
        id: string,
        name: string,
      } | null > | null,
      deleted: boolean | null,
    } | null,
  } | null,
};

export type ListAddresssQueryVariables = {
  filter?: ModelAddressFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAddresssQuery = {
  listAddresss:  {
    __typename: "ModelAddressConnection",
    items:  Array< {
      __typename: "Address",
      id: string,
      amhpTeamId: string,
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      },
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      },
      ccgId: string,
      ccg:  {
        __typename: "Organisation",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        type: OrgType,
        phone: string | null,
        email: string | null,
        expensesInstruction: string | null,
        declarationText: string | null,
        additionalNotesText: string | null,
        invoiceEmail: string | null,
        updatedBy: string | null,
        deleted: boolean | null,
      } | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetAssessmentQueryVariables = {
  id: string,
};

export type GetAssessmentQuery = {
  getAssessment:  {
    __typename: "Assessment",
    id: string,
    createdAt: string,
    amhpTeamId: string,
    amhpTeam:  {
      __typename: "AmhpTeam",
      id: string,
      name: string,
      featureFlags: string | null,
      features: string | null,
      assessments:  {
        __typename: "ModelAssessmentConnection",
        nextToken: string | null,
      } | null,
      users:  {
        __typename: "ModelAmhpTeamUserConnection",
        nextToken: string | null,
      } | null,
      commonAddresses:  {
        __typename: "ModelAddressConnection",
        nextToken: string | null,
      } | null,
      localAuthority: string | null,
      hospitalEmails:  Array< {
        __typename: "HospitalEmails",
        name: string,
      } > | null,
      mhaEmailAddresses:  Array< {
        __typename: "RecipientEmails",
        name: string | null,
      } | null > | null,
      emails: Array< string > | null,
      phoneNumbers: Array< string > | null,
      mhaInboxInstructions: string | null,
      reports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      summaryReports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      updatedBy: string | null,
    },
    amhpId: string,
    amhp:  {
      __typename: "AmhpProfile",
      id: string,
      email: string,
      phone: string | null,
      name: string,
      legalName: string | null,
      deleted: boolean | null,
      warrantInfo: string | null,
      accreditation: string | null,
      sectionFormAddress:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      sectionFormEmailAddress: string | null,
      sectionFormContactNumber: string | null,
      assessments:  {
        __typename: "ModelAssessmentConnection",
        nextToken: string | null,
      } | null,
      pushTokens: Array< string | null > | null,
      teams:  {
        __typename: "ModelAmhpTeamUserConnection",
        nextToken: string | null,
      } | null,
      lastActivity: string | null,
      createdAt: string | null,
      reports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      summaryReports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
    },
    patientName: string,
    location:  {
      __typename: "Location",
      lat: number,
      lon: number,
    },
    ccgId: string | null,
    ccg:  {
      __typename: "Organisation",
      id: string,
      name: string,
      featureFlags: string | null,
      features: string | null,
      type: OrgType,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      } | null,
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      phone: string | null,
      email: string | null,
      users:  {
        __typename: "ModelOrganisationUserConnection",
        nextToken: string | null,
      } | null,
      sites:  {
        __typename: "ModelSiteOrganisationConnection",
        nextToken: string | null,
      } | null,
      ccg:  {
        __typename: "CCG",
        id: string,
        claimProcessorType: OrgType | null,
        cCGOrganisationId: string,
      } | null,
      mht:  {
        __typename: "MHT",
        id: string,
        abbreviation: string,
        mHTOrganisationId: string,
      } | null,
      claims:  {
        __typename: "ModelClaimConnection",
        nextToken: string | null,
      } | null,
      expensesInstruction: string | null,
      declarationText: string | null,
      additionalNotesText: string | null,
      paymentRates:  Array< {
        __typename: "PaymentRates",
        description: string | null,
        assessmentFee: number,
        mileage: number,
      } | null > | null,
      invoiceEmail: string | null,
      updatedBy: string | null,
      mhtHospitalEmails:  Array< {
        __typename: "HospitalEmails",
        name: string,
      } | null > | null,
      rotas:  Array< {
        __typename: "RotaItem",
        id: string,
        name: string,
      } | null > | null,
      deleted: boolean | null,
    } | null,
    ccgDetermination: LocationType | null,
    locationName:  {
      __typename: "LocationName",
      city: string | null,
      postcode: string,
      address: string | null,
      addressNotes: string | null,
    },
    hadVisitAdded: boolean | null,
    doctorVisits:  {
      __typename: "ModelDoctorVisitConnection",
      items:  Array< {
        __typename: "DoctorVisit",
        id: string,
        createdAt: string | null,
        assessmentId: string,
        time: string,
        teamId: string,
        doctorVisitDoctorId: string,
        notes: Array< string | null > | null,
        partialPostcode: string | null,
        doctorVisitClaimId: string | null,
        claimDisplayId: string | null,
        claimStatus: ClaimStatus | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    patientNhsNumber: string | null,
    assessmentDate: string | null,
    contactedDoctors:  {
      __typename: "ModelContactedDoctorConnection",
      items:  Array< {
        __typename: "ContactedDoctor",
        id: string,
        assessmentId: string,
        contactedDoctorS12DoctorId: string,
        createdAt: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
    forms:  {
      __typename: "ModelFormConnection",
      items:  Array< {
        __typename: "Form",
        id: string,
        sk: string,
        ttl: number,
        version: number,
        gsi1pk: string,
        gsi1sk: string,
        gsi2pk: string,
        gsi2sk: string,
        gsi3pk: string,
        gsi3sk: string,
        assessmentId: string,
        status: FormStatus,
        createdBy: string,
        createdAt: string,
        updatedAt: string,
        updatedBy: string,
        amhpTeamInboxId: string,
        amhpTeamId: string,
        authors: Array< string >,
        doctorNames: Array< string > | null,
        signed: Array< string > | null,
        signedClaims: Array< string > | null,
        sha256: string | null,
        notes: Array< string >,
        type: string,
        isDeleted: boolean | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    patientAddress:  {
      __typename: "LocationName",
      city: string | null,
      postcode: string,
      address: string | null,
      addressNotes: string | null,
    } | null,
  } | null,
};

export type ListAssessmentsQueryVariables = {
  filter?: ModelAssessmentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAssessmentsQuery = {
  listAssessments:  {
    __typename: "ModelAssessmentConnection",
    items:  Array< {
      __typename: "Assessment",
      id: string,
      createdAt: string,
      amhpTeamId: string,
      amhpTeam:  {
        __typename: "AmhpTeam",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        localAuthority: string | null,
        emails: Array< string > | null,
        phoneNumbers: Array< string > | null,
        mhaInboxInstructions: string | null,
        updatedBy: string | null,
      },
      amhpId: string,
      amhp:  {
        __typename: "AmhpProfile",
        id: string,
        email: string,
        phone: string | null,
        name: string,
        legalName: string | null,
        deleted: boolean | null,
        warrantInfo: string | null,
        accreditation: string | null,
        sectionFormEmailAddress: string | null,
        sectionFormContactNumber: string | null,
        pushTokens: Array< string | null > | null,
        lastActivity: string | null,
        createdAt: string | null,
      },
      patientName: string,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      },
      ccgId: string | null,
      ccg:  {
        __typename: "Organisation",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        type: OrgType,
        phone: string | null,
        email: string | null,
        expensesInstruction: string | null,
        declarationText: string | null,
        additionalNotesText: string | null,
        invoiceEmail: string | null,
        updatedBy: string | null,
        deleted: boolean | null,
      } | null,
      ccgDetermination: LocationType | null,
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      },
      hadVisitAdded: boolean | null,
      doctorVisits:  {
        __typename: "ModelDoctorVisitConnection",
        nextToken: string | null,
      } | null,
      patientNhsNumber: string | null,
      assessmentDate: string | null,
      contactedDoctors:  {
        __typename: "ModelContactedDoctorConnection",
        nextToken: string | null,
      } | null,
      forms:  {
        __typename: "ModelFormConnection",
        nextToken: string | null,
      } | null,
      patientAddress:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetContactedDoctorQueryVariables = {
  id: string,
};

export type GetContactedDoctorQuery = {
  getContactedDoctor:  {
    __typename: "ContactedDoctor",
    id: string,
    assessmentId: string,
    contactedDoctorS12DoctorId: string,
    assessment:  {
      __typename: "Assessment",
      id: string,
      createdAt: string,
      amhpTeamId: string,
      amhpTeam:  {
        __typename: "AmhpTeam",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        localAuthority: string | null,
        emails: Array< string > | null,
        phoneNumbers: Array< string > | null,
        mhaInboxInstructions: string | null,
        updatedBy: string | null,
      },
      amhpId: string,
      amhp:  {
        __typename: "AmhpProfile",
        id: string,
        email: string,
        phone: string | null,
        name: string,
        legalName: string | null,
        deleted: boolean | null,
        warrantInfo: string | null,
        accreditation: string | null,
        sectionFormEmailAddress: string | null,
        sectionFormContactNumber: string | null,
        pushTokens: Array< string | null > | null,
        lastActivity: string | null,
        createdAt: string | null,
      },
      patientName: string,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      },
      ccgId: string | null,
      ccg:  {
        __typename: "Organisation",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        type: OrgType,
        phone: string | null,
        email: string | null,
        expensesInstruction: string | null,
        declarationText: string | null,
        additionalNotesText: string | null,
        invoiceEmail: string | null,
        updatedBy: string | null,
        deleted: boolean | null,
      } | null,
      ccgDetermination: LocationType | null,
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      },
      hadVisitAdded: boolean | null,
      doctorVisits:  {
        __typename: "ModelDoctorVisitConnection",
        nextToken: string | null,
      } | null,
      patientNhsNumber: string | null,
      assessmentDate: string | null,
      contactedDoctors:  {
        __typename: "ModelContactedDoctorConnection",
        nextToken: string | null,
      } | null,
      forms:  {
        __typename: "ModelFormConnection",
        nextToken: string | null,
      } | null,
      patientAddress:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
    },
    s12Doctor:  {
      __typename: "S12Doctor",
      id: string,
      gmc: string,
      s12Expiry: string | null,
      name: string,
      legalName: string,
      phone: string | null,
      phone2: string | null,
      email: string,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      },
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      },
      gl: boolean | null,
      employer: string | null,
      jobTitle: string | null,
      responsibleOfficer: string | null,
      lineManager: string | null,
      defaultMHT: string | null,
      mhtEmployers:  {
        __typename: "ModelMHTS12DoctorConnection",
        nextToken: string | null,
      } | null,
      availabilities:  {
        __typename: "ModelAvailabilityConnection",
        nextToken: string | null,
      } | null,
      holidays:  {
        __typename: "ModelHolidayConnection",
        nextToken: string | null,
      } | null,
      specialties: Array< string | null >,
      languages: Array< string | null >,
      gender: Gender | null,
      notes: string | null,
      pushTokens: Array< string | null > | null,
      distance: number | null,
      hideFromSearch: boolean | null,
      visits:  {
        __typename: "ModelDoctorVisitConnection",
        nextToken: string | null,
      } | null,
      doctors:  {
        __typename: "ModelContactedDoctorConnection",
        nextToken: string | null,
      } | null,
      claims:  {
        __typename: "ModelClaimConnection",
        nextToken: string | null,
      } | null,
      availabilityLocations:  Array< {
        __typename: "Location",
        lat: number,
        lon: number,
      } | null > | null,
      availabilityPostcodes: Array< string | null > | null,
      siteName: string,
      claimData:  {
        __typename: "ClaimData",
        billingCompanyName: string | null,
        vatRegistration: string | null,
        fuelType: FuelType | null,
        engineSize: Engine | null,
      } | null,
      sectionFormAddress:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      sectionFormEmailAddress: string | null,
      reports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      summaryReports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      createdAt: string | null,
      lastActivity: string | null,
      isOutsideEngland: boolean | null,
    },
    createdAt: string,
  } | null,
};

export type ListContactedDoctorsQueryVariables = {
  filter?: ModelContactedDoctorFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListContactedDoctorsQuery = {
  listContactedDoctors:  {
    __typename: "ModelContactedDoctorConnection",
    items:  Array< {
      __typename: "ContactedDoctor",
      id: string,
      assessmentId: string,
      contactedDoctorS12DoctorId: string,
      assessment:  {
        __typename: "Assessment",
        id: string,
        createdAt: string,
        amhpTeamId: string,
        amhpId: string,
        patientName: string,
        ccgId: string | null,
        ccgDetermination: LocationType | null,
        hadVisitAdded: boolean | null,
        patientNhsNumber: string | null,
        assessmentDate: string | null,
      },
      s12Doctor:  {
        __typename: "S12Doctor",
        id: string,
        gmc: string,
        s12Expiry: string | null,
        name: string,
        legalName: string,
        phone: string | null,
        phone2: string | null,
        email: string,
        gl: boolean | null,
        employer: string | null,
        jobTitle: string | null,
        responsibleOfficer: string | null,
        lineManager: string | null,
        defaultMHT: string | null,
        specialties: Array< string | null >,
        languages: Array< string | null >,
        gender: Gender | null,
        notes: string | null,
        pushTokens: Array< string | null > | null,
        distance: number | null,
        hideFromSearch: boolean | null,
        availabilityPostcodes: Array< string | null > | null,
        siteName: string,
        sectionFormEmailAddress: string | null,
        createdAt: string | null,
        lastActivity: string | null,
        isOutsideEngland: boolean | null,
      },
      createdAt: string,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetS12DoctorQueryVariables = {
  id: string,
};

export type GetS12DoctorQuery = {
  getS12Doctor:  {
    __typename: "S12Doctor",
    id: string,
    gmc: string,
    s12Expiry: string | null,
    name: string,
    legalName: string,
    phone: string | null,
    phone2: string | null,
    email: string,
    location:  {
      __typename: "Location",
      lat: number,
      lon: number,
    },
    locationName:  {
      __typename: "LocationName",
      city: string | null,
      postcode: string,
      address: string | null,
      addressNotes: string | null,
    },
    gl: boolean | null,
    employer: string | null,
    jobTitle: string | null,
    responsibleOfficer: string | null,
    lineManager: string | null,
    defaultMHT: string | null,
    mhtEmployers:  {
      __typename: "ModelMHTS12DoctorConnection",
      items:  Array< {
        __typename: "MHTS12Doctor",
        id: string,
        mHTS12DoctorS12DoctorId: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
    availabilities:  {
      __typename: "ModelAvailabilityConnection",
      items:  Array< {
        __typename: "Availability",
        id: string,
        createdAt: string | null,
        type: AvailabilityType | null,
        rrule: string,
        endHour: number,
        endDate: string,
        notes: string | null,
        s12DoctorAvailabilitiesId: string,
        availabilityDoctorId: string,
        oncall: boolean | null,
        rotaId: string | null,
        rotaName: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    holidays:  {
      __typename: "ModelHolidayConnection",
      items:  Array< {
        __typename: "Holiday",
        id: string,
        start: string,
        end: string,
        visible: boolean,
        notes: string | null,
        s12DoctorHolidaysId: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
    specialties: Array< string | null >,
    languages: Array< string | null >,
    gender: Gender | null,
    notes: string | null,
    pushTokens: Array< string | null > | null,
    distance: number | null,
    hideFromSearch: boolean | null,
    visits:  {
      __typename: "ModelDoctorVisitConnection",
      items:  Array< {
        __typename: "DoctorVisit",
        id: string,
        createdAt: string | null,
        assessmentId: string,
        time: string,
        teamId: string,
        doctorVisitDoctorId: string,
        notes: Array< string | null > | null,
        partialPostcode: string | null,
        doctorVisitClaimId: string | null,
        claimDisplayId: string | null,
        claimStatus: ClaimStatus | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    doctors:  {
      __typename: "ModelContactedDoctorConnection",
      items:  Array< {
        __typename: "ContactedDoctor",
        id: string,
        assessmentId: string,
        contactedDoctorS12DoctorId: string,
        createdAt: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
    claims:  {
      __typename: "ModelClaimConnection",
      items:  Array< {
        __typename: "Claim",
        id: string,
        claimAssessmentId: string | null,
        claimVisitId: string,
        claimDoctorId: string,
        assigneeId: string,
        assigneeName: string | null,
        amhpTeamId: string,
        claimAmhpId: string,
        claimOrganisationId: string,
        claimCsuId: string,
        status: ClaimStatus,
        displayId: string,
        mileage: string | null,
        locationText: string,
        locationType: LocationType,
        claimPostcode: string | null,
        assessmentPostcode: string | null,
        patientInitials: string,
        patientFullName: string | null,
        startingPostcode: string | null,
        visitDate: string,
        assessmentTookPlace: boolean | null,
        notes: Array< string | null > | null,
        unread: boolean | null,
        createdAt: string | null,
        updatedAt: string,
        receivedDate: string,
        additionalExpenses: string | null,
        additionalInformation: string | null,
        patientNhsNumber: string | null,
        declaration: string | null,
        paid: boolean | null,
        lineManager: string | null,
        doctorAdditionalConf: DoctorConfirmation | null,
        isIndependentDoctor: boolean | null,
        defaultMHT: string | null,
        doctorEmployedStatus: DoctorEmployedStatusValues | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    availabilityLocations:  Array< {
      __typename: "Location",
      lat: number,
      lon: number,
    } | null > | null,
    availabilityPostcodes: Array< string | null > | null,
    siteName: string,
    claimData:  {
      __typename: "ClaimData",
      billingAddress:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      billingCompanyName: string | null,
      vatRegistration: string | null,
      fuelType: FuelType | null,
      engineSize: Engine | null,
    } | null,
    sectionFormAddress:  {
      __typename: "LocationName",
      city: string | null,
      postcode: string,
      address: string | null,
      addressNotes: string | null,
    } | null,
    sectionFormEmailAddress: string | null,
    reports:  {
      __typename: "ModelReportingConnection",
      items:  Array< {
        __typename: "Reporting",
        id: string,
        pk: string,
        sk: string,
        ttl: number | null,
        gsi1pk: string | null,
        gsi1sk: string | null,
        gsi2pk: string | null,
        gsi2sk: string | null,
        gsi3pk: string | null,
        gsi3sk: string | null,
        eventName: string,
        userId: string,
        timestamp: string,
        period: string,
        count: number | null,
        siteId: string | null,
        teamId: string | null,
        orgId: string | null,
        amhpId: string | null,
        doctorId: string | null,
        claimProcessorId: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    summaryReports:  {
      __typename: "ModelReportingConnection",
      items:  Array< {
        __typename: "Reporting",
        id: string,
        pk: string,
        sk: string,
        ttl: number | null,
        gsi1pk: string | null,
        gsi1sk: string | null,
        gsi2pk: string | null,
        gsi2sk: string | null,
        gsi3pk: string | null,
        gsi3sk: string | null,
        eventName: string,
        userId: string,
        timestamp: string,
        period: string,
        count: number | null,
        siteId: string | null,
        teamId: string | null,
        orgId: string | null,
        amhpId: string | null,
        doctorId: string | null,
        claimProcessorId: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    createdAt: string | null,
    lastActivity: string | null,
    isOutsideEngland: boolean | null,
  } | null,
};

export type ListS12DoctorsQueryVariables = {
  filter?: ModelS12DoctorFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListS12DoctorsQuery = {
  listS12Doctors:  {
    __typename: "ModelS12DoctorConnection",
    items:  Array< {
      __typename: "S12Doctor",
      id: string,
      gmc: string,
      s12Expiry: string | null,
      name: string,
      legalName: string,
      phone: string | null,
      phone2: string | null,
      email: string,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      },
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      },
      gl: boolean | null,
      employer: string | null,
      jobTitle: string | null,
      responsibleOfficer: string | null,
      lineManager: string | null,
      defaultMHT: string | null,
      mhtEmployers:  {
        __typename: "ModelMHTS12DoctorConnection",
        nextToken: string | null,
      } | null,
      availabilities:  {
        __typename: "ModelAvailabilityConnection",
        nextToken: string | null,
      } | null,
      holidays:  {
        __typename: "ModelHolidayConnection",
        nextToken: string | null,
      } | null,
      specialties: Array< string | null >,
      languages: Array< string | null >,
      gender: Gender | null,
      notes: string | null,
      pushTokens: Array< string | null > | null,
      distance: number | null,
      hideFromSearch: boolean | null,
      visits:  {
        __typename: "ModelDoctorVisitConnection",
        nextToken: string | null,
      } | null,
      doctors:  {
        __typename: "ModelContactedDoctorConnection",
        nextToken: string | null,
      } | null,
      claims:  {
        __typename: "ModelClaimConnection",
        nextToken: string | null,
      } | null,
      availabilityLocations:  Array< {
        __typename: "Location",
        lat: number,
        lon: number,
      } | null > | null,
      availabilityPostcodes: Array< string | null > | null,
      siteName: string,
      claimData:  {
        __typename: "ClaimData",
        billingCompanyName: string | null,
        vatRegistration: string | null,
        fuelType: FuelType | null,
        engineSize: Engine | null,
      } | null,
      sectionFormAddress:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      sectionFormEmailAddress: string | null,
      reports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      summaryReports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      createdAt: string | null,
      lastActivity: string | null,
      isOutsideEngland: boolean | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetDoctorVisitQueryVariables = {
  id: string,
};

export type GetDoctorVisitQuery = {
  getDoctorVisit:  {
    __typename: "DoctorVisit",
    id: string,
    createdAt: string | null,
    assessmentId: string,
    assessment:  {
      __typename: "Assessment",
      id: string,
      createdAt: string,
      amhpTeamId: string,
      amhpTeam:  {
        __typename: "AmhpTeam",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        localAuthority: string | null,
        emails: Array< string > | null,
        phoneNumbers: Array< string > | null,
        mhaInboxInstructions: string | null,
        updatedBy: string | null,
      },
      amhpId: string,
      amhp:  {
        __typename: "AmhpProfile",
        id: string,
        email: string,
        phone: string | null,
        name: string,
        legalName: string | null,
        deleted: boolean | null,
        warrantInfo: string | null,
        accreditation: string | null,
        sectionFormEmailAddress: string | null,
        sectionFormContactNumber: string | null,
        pushTokens: Array< string | null > | null,
        lastActivity: string | null,
        createdAt: string | null,
      },
      patientName: string,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      },
      ccgId: string | null,
      ccg:  {
        __typename: "Organisation",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        type: OrgType,
        phone: string | null,
        email: string | null,
        expensesInstruction: string | null,
        declarationText: string | null,
        additionalNotesText: string | null,
        invoiceEmail: string | null,
        updatedBy: string | null,
        deleted: boolean | null,
      } | null,
      ccgDetermination: LocationType | null,
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      },
      hadVisitAdded: boolean | null,
      doctorVisits:  {
        __typename: "ModelDoctorVisitConnection",
        nextToken: string | null,
      } | null,
      patientNhsNumber: string | null,
      assessmentDate: string | null,
      contactedDoctors:  {
        __typename: "ModelContactedDoctorConnection",
        nextToken: string | null,
      } | null,
      forms:  {
        __typename: "ModelFormConnection",
        nextToken: string | null,
      } | null,
      patientAddress:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
    },
    time: string,
    teamId: string,
    team:  {
      __typename: "AmhpTeam",
      id: string,
      name: string,
      featureFlags: string | null,
      features: string | null,
      assessments:  {
        __typename: "ModelAssessmentConnection",
        nextToken: string | null,
      } | null,
      users:  {
        __typename: "ModelAmhpTeamUserConnection",
        nextToken: string | null,
      } | null,
      commonAddresses:  {
        __typename: "ModelAddressConnection",
        nextToken: string | null,
      } | null,
      localAuthority: string | null,
      hospitalEmails:  Array< {
        __typename: "HospitalEmails",
        name: string,
      } > | null,
      mhaEmailAddresses:  Array< {
        __typename: "RecipientEmails",
        name: string | null,
      } | null > | null,
      emails: Array< string > | null,
      phoneNumbers: Array< string > | null,
      mhaInboxInstructions: string | null,
      reports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      summaryReports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      updatedBy: string | null,
    },
    doctorVisitDoctorId: string,
    doctor:  {
      __typename: "S12Doctor",
      id: string,
      gmc: string,
      s12Expiry: string | null,
      name: string,
      legalName: string,
      phone: string | null,
      phone2: string | null,
      email: string,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      },
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      },
      gl: boolean | null,
      employer: string | null,
      jobTitle: string | null,
      responsibleOfficer: string | null,
      lineManager: string | null,
      defaultMHT: string | null,
      mhtEmployers:  {
        __typename: "ModelMHTS12DoctorConnection",
        nextToken: string | null,
      } | null,
      availabilities:  {
        __typename: "ModelAvailabilityConnection",
        nextToken: string | null,
      } | null,
      holidays:  {
        __typename: "ModelHolidayConnection",
        nextToken: string | null,
      } | null,
      specialties: Array< string | null >,
      languages: Array< string | null >,
      gender: Gender | null,
      notes: string | null,
      pushTokens: Array< string | null > | null,
      distance: number | null,
      hideFromSearch: boolean | null,
      visits:  {
        __typename: "ModelDoctorVisitConnection",
        nextToken: string | null,
      } | null,
      doctors:  {
        __typename: "ModelContactedDoctorConnection",
        nextToken: string | null,
      } | null,
      claims:  {
        __typename: "ModelClaimConnection",
        nextToken: string | null,
      } | null,
      availabilityLocations:  Array< {
        __typename: "Location",
        lat: number,
        lon: number,
      } | null > | null,
      availabilityPostcodes: Array< string | null > | null,
      siteName: string,
      claimData:  {
        __typename: "ClaimData",
        billingCompanyName: string | null,
        vatRegistration: string | null,
        fuelType: FuelType | null,
        engineSize: Engine | null,
      } | null,
      sectionFormAddress:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      sectionFormEmailAddress: string | null,
      reports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      summaryReports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      createdAt: string | null,
      lastActivity: string | null,
      isOutsideEngland: boolean | null,
    },
    notes: Array< string | null > | null,
    partialPostcode: string | null,
    doctorVisitClaimId: string | null,
    claimDisplayId: string | null,
    claimStatus: ClaimStatus | null,
    claim:  {
      __typename: "Claim",
      id: string,
      claimAssessmentId: string | null,
      claimVisitId: string,
      visit:  {
        __typename: "DoctorVisit",
        id: string,
        createdAt: string | null,
        assessmentId: string,
        time: string,
        teamId: string,
        doctorVisitDoctorId: string,
        notes: Array< string | null > | null,
        partialPostcode: string | null,
        doctorVisitClaimId: string | null,
        claimDisplayId: string | null,
        claimStatus: ClaimStatus | null,
      },
      claimDoctorId: string,
      doctor:  {
        __typename: "S12Doctor",
        id: string,
        gmc: string,
        s12Expiry: string | null,
        name: string,
        legalName: string,
        phone: string | null,
        phone2: string | null,
        email: string,
        gl: boolean | null,
        employer: string | null,
        jobTitle: string | null,
        responsibleOfficer: string | null,
        lineManager: string | null,
        defaultMHT: string | null,
        specialties: Array< string | null >,
        languages: Array< string | null >,
        gender: Gender | null,
        notes: string | null,
        pushTokens: Array< string | null > | null,
        distance: number | null,
        hideFromSearch: boolean | null,
        availabilityPostcodes: Array< string | null > | null,
        siteName: string,
        sectionFormEmailAddress: string | null,
        createdAt: string | null,
        lastActivity: string | null,
        isOutsideEngland: boolean | null,
      },
      assigneeId: string,
      assigneeName: string | null,
      amhpTeamId: string,
      claimAmhpId: string,
      amhp:  {
        __typename: "AmhpProfile",
        id: string,
        email: string,
        phone: string | null,
        name: string,
        legalName: string | null,
        deleted: boolean | null,
        warrantInfo: string | null,
        accreditation: string | null,
        sectionFormEmailAddress: string | null,
        sectionFormContactNumber: string | null,
        pushTokens: Array< string | null > | null,
        lastActivity: string | null,
        createdAt: string | null,
      },
      claimOrganisationId: string,
      claimCsuId: string,
      organisation:  {
        __typename: "Organisation",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        type: OrgType,
        phone: string | null,
        email: string | null,
        expensesInstruction: string | null,
        declarationText: string | null,
        additionalNotesText: string | null,
        invoiceEmail: string | null,
        updatedBy: string | null,
        deleted: boolean | null,
      },
      status: ClaimStatus,
      displayId: string,
      mileage: string | null,
      locationText: string,
      locationType: LocationType,
      claimPostcode: string | null,
      assessmentPostcode: string | null,
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      patientInitials: string,
      patientFullName: string | null,
      startingPostcode: string | null,
      visitDate: string,
      assessmentTookPlace: boolean | null,
      notes: Array< string | null > | null,
      unread: boolean | null,
      createdAt: string | null,
      updatedAt: string,
      receivedDate: string,
      additionalExpenses: string | null,
      additionalInformation: string | null,
      patientNhsNumber: string | null,
      declaration: string | null,
      paid: boolean | null,
      claimData:  {
        __typename: "ClaimData",
        billingCompanyName: string | null,
        vatRegistration: string | null,
        fuelType: FuelType | null,
        engineSize: Engine | null,
      } | null,
      invoiceData:  {
        __typename: "InvoiceData",
        assessmentFee: string,
        travelFee: string | null,
        locationOfAssessment: string | null,
      } | null,
      lineManager: string | null,
      doctorAdditionalConf: DoctorConfirmation | null,
      isIndependentDoctor: boolean | null,
      defaultMHT: string | null,
      doctorEmployedStatus: DoctorEmployedStatusValues | null,
    } | null,
  } | null,
};

export type ListDoctorVisitsQueryVariables = {
  filter?: ModelDoctorVisitFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDoctorVisitsQuery = {
  listDoctorVisits:  {
    __typename: "ModelDoctorVisitConnection",
    items:  Array< {
      __typename: "DoctorVisit",
      id: string,
      createdAt: string | null,
      assessmentId: string,
      assessment:  {
        __typename: "Assessment",
        id: string,
        createdAt: string,
        amhpTeamId: string,
        amhpId: string,
        patientName: string,
        ccgId: string | null,
        ccgDetermination: LocationType | null,
        hadVisitAdded: boolean | null,
        patientNhsNumber: string | null,
        assessmentDate: string | null,
      },
      time: string,
      teamId: string,
      team:  {
        __typename: "AmhpTeam",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        localAuthority: string | null,
        emails: Array< string > | null,
        phoneNumbers: Array< string > | null,
        mhaInboxInstructions: string | null,
        updatedBy: string | null,
      },
      doctorVisitDoctorId: string,
      doctor:  {
        __typename: "S12Doctor",
        id: string,
        gmc: string,
        s12Expiry: string | null,
        name: string,
        legalName: string,
        phone: string | null,
        phone2: string | null,
        email: string,
        gl: boolean | null,
        employer: string | null,
        jobTitle: string | null,
        responsibleOfficer: string | null,
        lineManager: string | null,
        defaultMHT: string | null,
        specialties: Array< string | null >,
        languages: Array< string | null >,
        gender: Gender | null,
        notes: string | null,
        pushTokens: Array< string | null > | null,
        distance: number | null,
        hideFromSearch: boolean | null,
        availabilityPostcodes: Array< string | null > | null,
        siteName: string,
        sectionFormEmailAddress: string | null,
        createdAt: string | null,
        lastActivity: string | null,
        isOutsideEngland: boolean | null,
      },
      notes: Array< string | null > | null,
      partialPostcode: string | null,
      doctorVisitClaimId: string | null,
      claimDisplayId: string | null,
      claimStatus: ClaimStatus | null,
      claim:  {
        __typename: "Claim",
        id: string,
        claimAssessmentId: string | null,
        claimVisitId: string,
        claimDoctorId: string,
        assigneeId: string,
        assigneeName: string | null,
        amhpTeamId: string,
        claimAmhpId: string,
        claimOrganisationId: string,
        claimCsuId: string,
        status: ClaimStatus,
        displayId: string,
        mileage: string | null,
        locationText: string,
        locationType: LocationType,
        claimPostcode: string | null,
        assessmentPostcode: string | null,
        patientInitials: string,
        patientFullName: string | null,
        startingPostcode: string | null,
        visitDate: string,
        assessmentTookPlace: boolean | null,
        notes: Array< string | null > | null,
        unread: boolean | null,
        createdAt: string | null,
        updatedAt: string,
        receivedDate: string,
        additionalExpenses: string | null,
        additionalInformation: string | null,
        patientNhsNumber: string | null,
        declaration: string | null,
        paid: boolean | null,
        lineManager: string | null,
        doctorAdditionalConf: DoctorConfirmation | null,
        isIndependentDoctor: boolean | null,
        defaultMHT: string | null,
        doctorEmployedStatus: DoctorEmployedStatusValues | null,
      } | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetAmhpProfileQueryVariables = {
  id: string,
};

export type GetAmhpProfileQuery = {
  getAmhpProfile:  {
    __typename: "AmhpProfile",
    id: string,
    email: string,
    phone: string | null,
    name: string,
    legalName: string | null,
    deleted: boolean | null,
    warrantInfo: string | null,
    accreditation: string | null,
    sectionFormAddress:  {
      __typename: "LocationName",
      city: string | null,
      postcode: string,
      address: string | null,
      addressNotes: string | null,
    } | null,
    sectionFormEmailAddress: string | null,
    sectionFormContactNumber: string | null,
    assessments:  {
      __typename: "ModelAssessmentConnection",
      items:  Array< {
        __typename: "Assessment",
        id: string,
        createdAt: string,
        amhpTeamId: string,
        amhpId: string,
        patientName: string,
        ccgId: string | null,
        ccgDetermination: LocationType | null,
        hadVisitAdded: boolean | null,
        patientNhsNumber: string | null,
        assessmentDate: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    pushTokens: Array< string | null > | null,
    teams:  {
      __typename: "ModelAmhpTeamUserConnection",
      items:  Array< {
        __typename: "AmhpTeamUser",
        id: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
    lastActivity: string | null,
    createdAt: string | null,
    reports:  {
      __typename: "ModelReportingConnection",
      items:  Array< {
        __typename: "Reporting",
        id: string,
        pk: string,
        sk: string,
        ttl: number | null,
        gsi1pk: string | null,
        gsi1sk: string | null,
        gsi2pk: string | null,
        gsi2sk: string | null,
        gsi3pk: string | null,
        gsi3sk: string | null,
        eventName: string,
        userId: string,
        timestamp: string,
        period: string,
        count: number | null,
        siteId: string | null,
        teamId: string | null,
        orgId: string | null,
        amhpId: string | null,
        doctorId: string | null,
        claimProcessorId: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    summaryReports:  {
      __typename: "ModelReportingConnection",
      items:  Array< {
        __typename: "Reporting",
        id: string,
        pk: string,
        sk: string,
        ttl: number | null,
        gsi1pk: string | null,
        gsi1sk: string | null,
        gsi2pk: string | null,
        gsi2sk: string | null,
        gsi3pk: string | null,
        gsi3sk: string | null,
        eventName: string,
        userId: string,
        timestamp: string,
        period: string,
        count: number | null,
        siteId: string | null,
        teamId: string | null,
        orgId: string | null,
        amhpId: string | null,
        doctorId: string | null,
        claimProcessorId: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type ListAmhpProfilesQueryVariables = {
  filter?: ModelAmhpProfileFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAmhpProfilesQuery = {
  listAmhpProfiles:  {
    __typename: "ModelAmhpProfileConnection",
    items:  Array< {
      __typename: "AmhpProfile",
      id: string,
      email: string,
      phone: string | null,
      name: string,
      legalName: string | null,
      deleted: boolean | null,
      warrantInfo: string | null,
      accreditation: string | null,
      sectionFormAddress:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      sectionFormEmailAddress: string | null,
      sectionFormContactNumber: string | null,
      assessments:  {
        __typename: "ModelAssessmentConnection",
        nextToken: string | null,
      } | null,
      pushTokens: Array< string | null > | null,
      teams:  {
        __typename: "ModelAmhpTeamUserConnection",
        nextToken: string | null,
      } | null,
      lastActivity: string | null,
      createdAt: string | null,
      reports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      summaryReports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetCcgQueryVariables = {
  id: string,
};

export type GetCcgQuery = {
  getCCG:  {
    __typename: "CCG",
    id: string,
    claimProcessorType: OrgType | null,
    claimProcessor:  {
      __typename: "Organisation",
      id: string,
      name: string,
      featureFlags: string | null,
      features: string | null,
      type: OrgType,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      } | null,
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      phone: string | null,
      email: string | null,
      users:  {
        __typename: "ModelOrganisationUserConnection",
        nextToken: string | null,
      } | null,
      sites:  {
        __typename: "ModelSiteOrganisationConnection",
        nextToken: string | null,
      } | null,
      ccg:  {
        __typename: "CCG",
        id: string,
        claimProcessorType: OrgType | null,
        cCGOrganisationId: string,
      } | null,
      mht:  {
        __typename: "MHT",
        id: string,
        abbreviation: string,
        mHTOrganisationId: string,
      } | null,
      claims:  {
        __typename: "ModelClaimConnection",
        nextToken: string | null,
      } | null,
      expensesInstruction: string | null,
      declarationText: string | null,
      additionalNotesText: string | null,
      paymentRates:  Array< {
        __typename: "PaymentRates",
        description: string | null,
        assessmentFee: number,
        mileage: number,
      } | null > | null,
      invoiceEmail: string | null,
      updatedBy: string | null,
      mhtHospitalEmails:  Array< {
        __typename: "HospitalEmails",
        name: string,
      } | null > | null,
      rotas:  Array< {
        __typename: "RotaItem",
        id: string,
        name: string,
      } | null > | null,
      deleted: boolean | null,
    } | null,
    cCGOrganisationId: string,
    organisation:  {
      __typename: "Organisation",
      id: string,
      name: string,
      featureFlags: string | null,
      features: string | null,
      type: OrgType,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      } | null,
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      phone: string | null,
      email: string | null,
      users:  {
        __typename: "ModelOrganisationUserConnection",
        nextToken: string | null,
      } | null,
      sites:  {
        __typename: "ModelSiteOrganisationConnection",
        nextToken: string | null,
      } | null,
      ccg:  {
        __typename: "CCG",
        id: string,
        claimProcessorType: OrgType | null,
        cCGOrganisationId: string,
      } | null,
      mht:  {
        __typename: "MHT",
        id: string,
        abbreviation: string,
        mHTOrganisationId: string,
      } | null,
      claims:  {
        __typename: "ModelClaimConnection",
        nextToken: string | null,
      } | null,
      expensesInstruction: string | null,
      declarationText: string | null,
      additionalNotesText: string | null,
      paymentRates:  Array< {
        __typename: "PaymentRates",
        description: string | null,
        assessmentFee: number,
        mileage: number,
      } | null > | null,
      invoiceEmail: string | null,
      updatedBy: string | null,
      mhtHospitalEmails:  Array< {
        __typename: "HospitalEmails",
        name: string,
      } | null > | null,
      rotas:  Array< {
        __typename: "RotaItem",
        id: string,
        name: string,
      } | null > | null,
      deleted: boolean | null,
    },
  } | null,
};

export type ListCcGsQueryVariables = {
  filter?: ModelCCGFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCcGsQuery = {
  listCCGs:  {
    __typename: "ModelCCGConnection",
    items:  Array< {
      __typename: "CCG",
      id: string,
      claimProcessorType: OrgType | null,
      claimProcessor:  {
        __typename: "Organisation",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        type: OrgType,
        phone: string | null,
        email: string | null,
        expensesInstruction: string | null,
        declarationText: string | null,
        additionalNotesText: string | null,
        invoiceEmail: string | null,
        updatedBy: string | null,
        deleted: boolean | null,
      } | null,
      cCGOrganisationId: string,
      organisation:  {
        __typename: "Organisation",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        type: OrgType,
        phone: string | null,
        email: string | null,
        expensesInstruction: string | null,
        declarationText: string | null,
        additionalNotesText: string | null,
        invoiceEmail: string | null,
        updatedBy: string | null,
        deleted: boolean | null,
      },
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetClaimQueryVariables = {
  id: string,
};

export type GetClaimQuery = {
  getClaim:  {
    __typename: "Claim",
    id: string,
    claimAssessmentId: string | null,
    claimVisitId: string,
    visit:  {
      __typename: "DoctorVisit",
      id: string,
      createdAt: string | null,
      assessmentId: string,
      assessment:  {
        __typename: "Assessment",
        id: string,
        createdAt: string,
        amhpTeamId: string,
        amhpId: string,
        patientName: string,
        ccgId: string | null,
        ccgDetermination: LocationType | null,
        hadVisitAdded: boolean | null,
        patientNhsNumber: string | null,
        assessmentDate: string | null,
      },
      time: string,
      teamId: string,
      team:  {
        __typename: "AmhpTeam",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        localAuthority: string | null,
        emails: Array< string > | null,
        phoneNumbers: Array< string > | null,
        mhaInboxInstructions: string | null,
        updatedBy: string | null,
      },
      doctorVisitDoctorId: string,
      doctor:  {
        __typename: "S12Doctor",
        id: string,
        gmc: string,
        s12Expiry: string | null,
        name: string,
        legalName: string,
        phone: string | null,
        phone2: string | null,
        email: string,
        gl: boolean | null,
        employer: string | null,
        jobTitle: string | null,
        responsibleOfficer: string | null,
        lineManager: string | null,
        defaultMHT: string | null,
        specialties: Array< string | null >,
        languages: Array< string | null >,
        gender: Gender | null,
        notes: string | null,
        pushTokens: Array< string | null > | null,
        distance: number | null,
        hideFromSearch: boolean | null,
        availabilityPostcodes: Array< string | null > | null,
        siteName: string,
        sectionFormEmailAddress: string | null,
        createdAt: string | null,
        lastActivity: string | null,
        isOutsideEngland: boolean | null,
      },
      notes: Array< string | null > | null,
      partialPostcode: string | null,
      doctorVisitClaimId: string | null,
      claimDisplayId: string | null,
      claimStatus: ClaimStatus | null,
      claim:  {
        __typename: "Claim",
        id: string,
        claimAssessmentId: string | null,
        claimVisitId: string,
        claimDoctorId: string,
        assigneeId: string,
        assigneeName: string | null,
        amhpTeamId: string,
        claimAmhpId: string,
        claimOrganisationId: string,
        claimCsuId: string,
        status: ClaimStatus,
        displayId: string,
        mileage: string | null,
        locationText: string,
        locationType: LocationType,
        claimPostcode: string | null,
        assessmentPostcode: string | null,
        patientInitials: string,
        patientFullName: string | null,
        startingPostcode: string | null,
        visitDate: string,
        assessmentTookPlace: boolean | null,
        notes: Array< string | null > | null,
        unread: boolean | null,
        createdAt: string | null,
        updatedAt: string,
        receivedDate: string,
        additionalExpenses: string | null,
        additionalInformation: string | null,
        patientNhsNumber: string | null,
        declaration: string | null,
        paid: boolean | null,
        lineManager: string | null,
        doctorAdditionalConf: DoctorConfirmation | null,
        isIndependentDoctor: boolean | null,
        defaultMHT: string | null,
        doctorEmployedStatus: DoctorEmployedStatusValues | null,
      } | null,
    },
    claimDoctorId: string,
    doctor:  {
      __typename: "S12Doctor",
      id: string,
      gmc: string,
      s12Expiry: string | null,
      name: string,
      legalName: string,
      phone: string | null,
      phone2: string | null,
      email: string,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      },
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      },
      gl: boolean | null,
      employer: string | null,
      jobTitle: string | null,
      responsibleOfficer: string | null,
      lineManager: string | null,
      defaultMHT: string | null,
      mhtEmployers:  {
        __typename: "ModelMHTS12DoctorConnection",
        nextToken: string | null,
      } | null,
      availabilities:  {
        __typename: "ModelAvailabilityConnection",
        nextToken: string | null,
      } | null,
      holidays:  {
        __typename: "ModelHolidayConnection",
        nextToken: string | null,
      } | null,
      specialties: Array< string | null >,
      languages: Array< string | null >,
      gender: Gender | null,
      notes: string | null,
      pushTokens: Array< string | null > | null,
      distance: number | null,
      hideFromSearch: boolean | null,
      visits:  {
        __typename: "ModelDoctorVisitConnection",
        nextToken: string | null,
      } | null,
      doctors:  {
        __typename: "ModelContactedDoctorConnection",
        nextToken: string | null,
      } | null,
      claims:  {
        __typename: "ModelClaimConnection",
        nextToken: string | null,
      } | null,
      availabilityLocations:  Array< {
        __typename: "Location",
        lat: number,
        lon: number,
      } | null > | null,
      availabilityPostcodes: Array< string | null > | null,
      siteName: string,
      claimData:  {
        __typename: "ClaimData",
        billingCompanyName: string | null,
        vatRegistration: string | null,
        fuelType: FuelType | null,
        engineSize: Engine | null,
      } | null,
      sectionFormAddress:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      sectionFormEmailAddress: string | null,
      reports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      summaryReports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      createdAt: string | null,
      lastActivity: string | null,
      isOutsideEngland: boolean | null,
    },
    assigneeId: string,
    assigneeName: string | null,
    amhpTeamId: string,
    claimAmhpId: string,
    amhp:  {
      __typename: "AmhpProfile",
      id: string,
      email: string,
      phone: string | null,
      name: string,
      legalName: string | null,
      deleted: boolean | null,
      warrantInfo: string | null,
      accreditation: string | null,
      sectionFormAddress:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      sectionFormEmailAddress: string | null,
      sectionFormContactNumber: string | null,
      assessments:  {
        __typename: "ModelAssessmentConnection",
        nextToken: string | null,
      } | null,
      pushTokens: Array< string | null > | null,
      teams:  {
        __typename: "ModelAmhpTeamUserConnection",
        nextToken: string | null,
      } | null,
      lastActivity: string | null,
      createdAt: string | null,
      reports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      summaryReports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
    },
    claimOrganisationId: string,
    claimCsuId: string,
    organisation:  {
      __typename: "Organisation",
      id: string,
      name: string,
      featureFlags: string | null,
      features: string | null,
      type: OrgType,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      } | null,
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      phone: string | null,
      email: string | null,
      users:  {
        __typename: "ModelOrganisationUserConnection",
        nextToken: string | null,
      } | null,
      sites:  {
        __typename: "ModelSiteOrganisationConnection",
        nextToken: string | null,
      } | null,
      ccg:  {
        __typename: "CCG",
        id: string,
        claimProcessorType: OrgType | null,
        cCGOrganisationId: string,
      } | null,
      mht:  {
        __typename: "MHT",
        id: string,
        abbreviation: string,
        mHTOrganisationId: string,
      } | null,
      claims:  {
        __typename: "ModelClaimConnection",
        nextToken: string | null,
      } | null,
      expensesInstruction: string | null,
      declarationText: string | null,
      additionalNotesText: string | null,
      paymentRates:  Array< {
        __typename: "PaymentRates",
        description: string | null,
        assessmentFee: number,
        mileage: number,
      } | null > | null,
      invoiceEmail: string | null,
      updatedBy: string | null,
      mhtHospitalEmails:  Array< {
        __typename: "HospitalEmails",
        name: string,
      } | null > | null,
      rotas:  Array< {
        __typename: "RotaItem",
        id: string,
        name: string,
      } | null > | null,
      deleted: boolean | null,
    },
    status: ClaimStatus,
    displayId: string,
    mileage: string | null,
    locationText: string,
    locationType: LocationType,
    claimPostcode: string | null,
    assessmentPostcode: string | null,
    locationName:  {
      __typename: "LocationName",
      city: string | null,
      postcode: string,
      address: string | null,
      addressNotes: string | null,
    } | null,
    patientInitials: string,
    patientFullName: string | null,
    startingPostcode: string | null,
    visitDate: string,
    assessmentTookPlace: boolean | null,
    notes: Array< string | null > | null,
    unread: boolean | null,
    createdAt: string | null,
    updatedAt: string,
    receivedDate: string,
    additionalExpenses: string | null,
    additionalInformation: string | null,
    patientNhsNumber: string | null,
    declaration: string | null,
    paid: boolean | null,
    claimData:  {
      __typename: "ClaimData",
      billingAddress:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      billingCompanyName: string | null,
      vatRegistration: string | null,
      fuelType: FuelType | null,
      engineSize: Engine | null,
    } | null,
    invoiceData:  {
      __typename: "InvoiceData",
      assessmentFee: string,
      travelFee: string | null,
      locationOfAssessment: string | null,
    } | null,
    lineManager: string | null,
    doctorAdditionalConf: DoctorConfirmation | null,
    isIndependentDoctor: boolean | null,
    defaultMHT: string | null,
    doctorEmployedStatus: DoctorEmployedStatusValues | null,
  } | null,
};

export type ListClaimsQueryVariables = {
  filter?: ModelClaimFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListClaimsQuery = {
  listClaims:  {
    __typename: "ModelClaimConnection",
    items:  Array< {
      __typename: "Claim",
      id: string,
      claimAssessmentId: string | null,
      claimVisitId: string,
      visit:  {
        __typename: "DoctorVisit",
        id: string,
        createdAt: string | null,
        assessmentId: string,
        time: string,
        teamId: string,
        doctorVisitDoctorId: string,
        notes: Array< string | null > | null,
        partialPostcode: string | null,
        doctorVisitClaimId: string | null,
        claimDisplayId: string | null,
        claimStatus: ClaimStatus | null,
      },
      claimDoctorId: string,
      doctor:  {
        __typename: "S12Doctor",
        id: string,
        gmc: string,
        s12Expiry: string | null,
        name: string,
        legalName: string,
        phone: string | null,
        phone2: string | null,
        email: string,
        gl: boolean | null,
        employer: string | null,
        jobTitle: string | null,
        responsibleOfficer: string | null,
        lineManager: string | null,
        defaultMHT: string | null,
        specialties: Array< string | null >,
        languages: Array< string | null >,
        gender: Gender | null,
        notes: string | null,
        pushTokens: Array< string | null > | null,
        distance: number | null,
        hideFromSearch: boolean | null,
        availabilityPostcodes: Array< string | null > | null,
        siteName: string,
        sectionFormEmailAddress: string | null,
        createdAt: string | null,
        lastActivity: string | null,
        isOutsideEngland: boolean | null,
      },
      assigneeId: string,
      assigneeName: string | null,
      amhpTeamId: string,
      claimAmhpId: string,
      amhp:  {
        __typename: "AmhpProfile",
        id: string,
        email: string,
        phone: string | null,
        name: string,
        legalName: string | null,
        deleted: boolean | null,
        warrantInfo: string | null,
        accreditation: string | null,
        sectionFormEmailAddress: string | null,
        sectionFormContactNumber: string | null,
        pushTokens: Array< string | null > | null,
        lastActivity: string | null,
        createdAt: string | null,
      },
      claimOrganisationId: string,
      claimCsuId: string,
      organisation:  {
        __typename: "Organisation",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        type: OrgType,
        phone: string | null,
        email: string | null,
        expensesInstruction: string | null,
        declarationText: string | null,
        additionalNotesText: string | null,
        invoiceEmail: string | null,
        updatedBy: string | null,
        deleted: boolean | null,
      },
      status: ClaimStatus,
      displayId: string,
      mileage: string | null,
      locationText: string,
      locationType: LocationType,
      claimPostcode: string | null,
      assessmentPostcode: string | null,
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      patientInitials: string,
      patientFullName: string | null,
      startingPostcode: string | null,
      visitDate: string,
      assessmentTookPlace: boolean | null,
      notes: Array< string | null > | null,
      unread: boolean | null,
      createdAt: string | null,
      updatedAt: string,
      receivedDate: string,
      additionalExpenses: string | null,
      additionalInformation: string | null,
      patientNhsNumber: string | null,
      declaration: string | null,
      paid: boolean | null,
      claimData:  {
        __typename: "ClaimData",
        billingCompanyName: string | null,
        vatRegistration: string | null,
        fuelType: FuelType | null,
        engineSize: Engine | null,
      } | null,
      invoiceData:  {
        __typename: "InvoiceData",
        assessmentFee: string,
        travelFee: string | null,
        locationOfAssessment: string | null,
      } | null,
      lineManager: string | null,
      doctorAdditionalConf: DoctorConfirmation | null,
      isIndependentDoctor: boolean | null,
      defaultMHT: string | null,
      doctorEmployedStatus: DoctorEmployedStatusValues | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetReportingQueryVariables = {
  pk: string,
  sk: string,
};

export type GetReportingQuery = {
  getReporting:  {
    __typename: "Reporting",
    id: string,
    pk: string,
    sk: string,
    ttl: number | null,
    gsi1pk: string | null,
    gsi1sk: string | null,
    gsi2pk: string | null,
    gsi2sk: string | null,
    gsi3pk: string | null,
    gsi3sk: string | null,
    eventName: string,
    userId: string,
    timestamp: string,
    period: string,
    count: number | null,
    siteId: string | null,
    teamId: string | null,
    orgId: string | null,
    amhpId: string | null,
    amhp:  {
      __typename: "AmhpProfile",
      id: string,
      email: string,
      phone: string | null,
      name: string,
      legalName: string | null,
      deleted: boolean | null,
      warrantInfo: string | null,
      accreditation: string | null,
      sectionFormAddress:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      sectionFormEmailAddress: string | null,
      sectionFormContactNumber: string | null,
      assessments:  {
        __typename: "ModelAssessmentConnection",
        nextToken: string | null,
      } | null,
      pushTokens: Array< string | null > | null,
      teams:  {
        __typename: "ModelAmhpTeamUserConnection",
        nextToken: string | null,
      } | null,
      lastActivity: string | null,
      createdAt: string | null,
      reports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      summaryReports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
    } | null,
    doctorId: string | null,
    doctor:  {
      __typename: "S12Doctor",
      id: string,
      gmc: string,
      s12Expiry: string | null,
      name: string,
      legalName: string,
      phone: string | null,
      phone2: string | null,
      email: string,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      },
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      },
      gl: boolean | null,
      employer: string | null,
      jobTitle: string | null,
      responsibleOfficer: string | null,
      lineManager: string | null,
      defaultMHT: string | null,
      mhtEmployers:  {
        __typename: "ModelMHTS12DoctorConnection",
        nextToken: string | null,
      } | null,
      availabilities:  {
        __typename: "ModelAvailabilityConnection",
        nextToken: string | null,
      } | null,
      holidays:  {
        __typename: "ModelHolidayConnection",
        nextToken: string | null,
      } | null,
      specialties: Array< string | null >,
      languages: Array< string | null >,
      gender: Gender | null,
      notes: string | null,
      pushTokens: Array< string | null > | null,
      distance: number | null,
      hideFromSearch: boolean | null,
      visits:  {
        __typename: "ModelDoctorVisitConnection",
        nextToken: string | null,
      } | null,
      doctors:  {
        __typename: "ModelContactedDoctorConnection",
        nextToken: string | null,
      } | null,
      claims:  {
        __typename: "ModelClaimConnection",
        nextToken: string | null,
      } | null,
      availabilityLocations:  Array< {
        __typename: "Location",
        lat: number,
        lon: number,
      } | null > | null,
      availabilityPostcodes: Array< string | null > | null,
      siteName: string,
      claimData:  {
        __typename: "ClaimData",
        billingCompanyName: string | null,
        vatRegistration: string | null,
        fuelType: FuelType | null,
        engineSize: Engine | null,
      } | null,
      sectionFormAddress:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      sectionFormEmailAddress: string | null,
      reports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      summaryReports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      createdAt: string | null,
      lastActivity: string | null,
      isOutsideEngland: boolean | null,
    } | null,
    claimProcessorId: string | null,
    claimProcessor:  {
      __typename: "OrganisationUser",
      id: string,
      organisationUserOrganisationId: string,
      type: string,
      organisation:  {
        __typename: "Organisation",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        type: OrgType,
        phone: string | null,
        email: string | null,
        expensesInstruction: string | null,
        declarationText: string | null,
        additionalNotesText: string | null,
        invoiceEmail: string | null,
        updatedBy: string | null,
        deleted: boolean | null,
      },
      organisationUserUserId: string,
      user:  {
        __typename: "User",
        id: string,
        name: string,
        email: string,
        phone: string | null,
      },
    } | null,
    attributes:  Array< {
      __typename: "Attribute",
      name: string,
      value: string,
    } > | null,
    metrics:  Array< {
      __typename: "Metric",
      name: string,
      value: number,
    } > | null,
  } | null,
};

export type ListReportingsQueryVariables = {
  pk?: string | null,
  sk?: ModelStringKeyConditionInput | null,
  filter?: ModelReportingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListReportingsQuery = {
  listReportings:  {
    __typename: "ModelReportingConnection",
    items:  Array< {
      __typename: "Reporting",
      id: string,
      pk: string,
      sk: string,
      ttl: number | null,
      gsi1pk: string | null,
      gsi1sk: string | null,
      gsi2pk: string | null,
      gsi2sk: string | null,
      gsi3pk: string | null,
      gsi3sk: string | null,
      eventName: string,
      userId: string,
      timestamp: string,
      period: string,
      count: number | null,
      siteId: string | null,
      teamId: string | null,
      orgId: string | null,
      amhpId: string | null,
      amhp:  {
        __typename: "AmhpProfile",
        id: string,
        email: string,
        phone: string | null,
        name: string,
        legalName: string | null,
        deleted: boolean | null,
        warrantInfo: string | null,
        accreditation: string | null,
        sectionFormEmailAddress: string | null,
        sectionFormContactNumber: string | null,
        pushTokens: Array< string | null > | null,
        lastActivity: string | null,
        createdAt: string | null,
      } | null,
      doctorId: string | null,
      doctor:  {
        __typename: "S12Doctor",
        id: string,
        gmc: string,
        s12Expiry: string | null,
        name: string,
        legalName: string,
        phone: string | null,
        phone2: string | null,
        email: string,
        gl: boolean | null,
        employer: string | null,
        jobTitle: string | null,
        responsibleOfficer: string | null,
        lineManager: string | null,
        defaultMHT: string | null,
        specialties: Array< string | null >,
        languages: Array< string | null >,
        gender: Gender | null,
        notes: string | null,
        pushTokens: Array< string | null > | null,
        distance: number | null,
        hideFromSearch: boolean | null,
        availabilityPostcodes: Array< string | null > | null,
        siteName: string,
        sectionFormEmailAddress: string | null,
        createdAt: string | null,
        lastActivity: string | null,
        isOutsideEngland: boolean | null,
      } | null,
      claimProcessorId: string | null,
      claimProcessor:  {
        __typename: "OrganisationUser",
        id: string,
        organisationUserOrganisationId: string,
        type: string,
        organisationUserUserId: string,
      } | null,
      attributes:  Array< {
        __typename: "Attribute",
        name: string,
        value: string,
      } > | null,
      metrics:  Array< {
        __typename: "Metric",
        name: string,
        value: number,
      } > | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetFormsByAssessmentQueryVariables = {
  gsi1pk?: string | null,
  gsi1sk?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFormFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetFormsByAssessmentQuery = {
  getFormsByAssessment:  {
    __typename: "ModelFormConnection",
    items:  Array< {
      __typename: "Form",
      id: string,
      sk: string,
      ttl: number,
      version: number,
      gsi1pk: string,
      gsi1sk: string,
      gsi2pk: string,
      gsi2sk: string,
      gsi3pk: string,
      gsi3sk: string,
      assessmentId: string,
      assessment:  {
        __typename: "Assessment",
        id: string,
        createdAt: string,
        amhpTeamId: string,
        amhpId: string,
        patientName: string,
        ccgId: string | null,
        ccgDetermination: LocationType | null,
        hadVisitAdded: boolean | null,
        patientNhsNumber: string | null,
        assessmentDate: string | null,
      } | null,
      status: FormStatus,
      createdBy: string,
      createdAt: string,
      updatedAt: string,
      updatedBy: string,
      amhpTeamInboxId: string,
      amhpTeamId: string,
      authors: Array< string >,
      doctorNames: Array< string > | null,
      signed: Array< string > | null,
      signedClaims: Array< string > | null,
      sha256: string | null,
      notes: Array< string >,
      type: string,
      isDeleted: boolean | null,
      amhpTeam:  {
        __typename: "AmhpTeam",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        localAuthority: string | null,
        emails: Array< string > | null,
        phoneNumbers: Array< string > | null,
        mhaInboxInstructions: string | null,
        updatedBy: string | null,
      } | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetFormsByAmhpInboxIdQueryVariables = {
  gsi2pk?: string | null,
  gsi2sk?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFormFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetFormsByAmhpInboxIdQuery = {
  getFormsByAmhpInboxId:  {
    __typename: "ModelFormConnection",
    items:  Array< {
      __typename: "Form",
      id: string,
      sk: string,
      ttl: number,
      version: number,
      gsi1pk: string,
      gsi1sk: string,
      gsi2pk: string,
      gsi2sk: string,
      gsi3pk: string,
      gsi3sk: string,
      assessmentId: string,
      assessment:  {
        __typename: "Assessment",
        id: string,
        createdAt: string,
        amhpTeamId: string,
        amhpId: string,
        patientName: string,
        ccgId: string | null,
        ccgDetermination: LocationType | null,
        hadVisitAdded: boolean | null,
        patientNhsNumber: string | null,
        assessmentDate: string | null,
      } | null,
      status: FormStatus,
      createdBy: string,
      createdAt: string,
      updatedAt: string,
      updatedBy: string,
      amhpTeamInboxId: string,
      amhpTeamId: string,
      authors: Array< string >,
      doctorNames: Array< string > | null,
      signed: Array< string > | null,
      signedClaims: Array< string > | null,
      sha256: string | null,
      notes: Array< string >,
      type: string,
      isDeleted: boolean | null,
      amhpTeam:  {
        __typename: "AmhpTeam",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        localAuthority: string | null,
        emails: Array< string > | null,
        phoneNumbers: Array< string > | null,
        mhaInboxInstructions: string | null,
        updatedBy: string | null,
      } | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetFormsByCreatedByQueryVariables = {
  gsi3pk?: string | null,
  gsi3sk?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFormFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetFormsByCreatedByQuery = {
  getFormsByCreatedBy:  {
    __typename: "ModelFormConnection",
    items:  Array< {
      __typename: "Form",
      id: string,
      sk: string,
      ttl: number,
      version: number,
      gsi1pk: string,
      gsi1sk: string,
      gsi2pk: string,
      gsi2sk: string,
      gsi3pk: string,
      gsi3sk: string,
      assessmentId: string,
      assessment:  {
        __typename: "Assessment",
        id: string,
        createdAt: string,
        amhpTeamId: string,
        amhpId: string,
        patientName: string,
        ccgId: string | null,
        ccgDetermination: LocationType | null,
        hadVisitAdded: boolean | null,
        patientNhsNumber: string | null,
        assessmentDate: string | null,
      } | null,
      status: FormStatus,
      createdBy: string,
      createdAt: string,
      updatedAt: string,
      updatedBy: string,
      amhpTeamInboxId: string,
      amhpTeamId: string,
      authors: Array< string >,
      doctorNames: Array< string > | null,
      signed: Array< string > | null,
      signedClaims: Array< string > | null,
      sha256: string | null,
      notes: Array< string >,
      type: string,
      isDeleted: boolean | null,
      amhpTeam:  {
        __typename: "AmhpTeam",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        localAuthority: string | null,
        emails: Array< string > | null,
        phoneNumbers: Array< string > | null,
        mhaInboxInstructions: string | null,
        updatedBy: string | null,
      } | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type AvailabilityByRotaQueryVariables = {
  rotaId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAvailabilityFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AvailabilityByRotaQuery = {
  availabilityByRota:  {
    __typename: "ModelAvailabilityConnection",
    items:  Array< {
      __typename: "Availability",
      id: string,
      createdAt: string | null,
      type: AvailabilityType | null,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      } | null,
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      rrule: string,
      endHour: number,
      endDate: string,
      contractList:  {
        __typename: "ContractList",
        name: string,
        description: string | null,
      } | null,
      notes: string | null,
      s12DoctorAvailabilitiesId: string,
      availabilityDoctorId: string,
      oncall: boolean | null,
      mht:  {
        __typename: "MHT",
        id: string,
        abbreviation: string,
        mHTOrganisationId: string,
      } | null,
      doctor:  {
        __typename: "S12Doctor",
        id: string,
        gmc: string,
        s12Expiry: string | null,
        name: string,
        legalName: string,
        phone: string | null,
        phone2: string | null,
        email: string,
        gl: boolean | null,
        employer: string | null,
        jobTitle: string | null,
        responsibleOfficer: string | null,
        lineManager: string | null,
        defaultMHT: string | null,
        specialties: Array< string | null >,
        languages: Array< string | null >,
        gender: Gender | null,
        notes: string | null,
        pushTokens: Array< string | null > | null,
        distance: number | null,
        hideFromSearch: boolean | null,
        availabilityPostcodes: Array< string | null > | null,
        siteName: string,
        sectionFormEmailAddress: string | null,
        createdAt: string | null,
        lastActivity: string | null,
        isOutsideEngland: boolean | null,
      },
      rotaId: string | null,
      rotaName: string | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type MhtByOrgIdQueryVariables = {
  mHTOrganisationId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMHTFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MhtByOrgIdQuery = {
  mhtByOrgId:  {
    __typename: "ModelMHTConnection",
    items:  Array< {
      __typename: "MHT",
      id: string,
      abbreviation: string,
      organisation:  {
        __typename: "Organisation",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        type: OrgType,
        phone: string | null,
        email: string | null,
        expensesInstruction: string | null,
        declarationText: string | null,
        additionalNotesText: string | null,
        invoiceEmail: string | null,
        updatedBy: string | null,
        deleted: boolean | null,
      },
      mHTOrganisationId: string,
      s12Doctors:  {
        __typename: "ModelMHTS12DoctorConnection",
        nextToken: string | null,
      } | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type MhtsByDoctorQueryVariables = {
  mHTS12DoctorS12DoctorId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMHTS12DoctorFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MhtsByDoctorQuery = {
  mhtsByDoctor:  {
    __typename: "ModelMHTS12DoctorConnection",
    items:  Array< {
      __typename: "MHTS12Doctor",
      id: string,
      mht:  {
        __typename: "MHT",
        id: string,
        abbreviation: string,
        mHTOrganisationId: string,
      },
      mHTS12DoctorS12DoctorId: string,
      s12Doctor:  {
        __typename: "S12Doctor",
        id: string,
        gmc: string,
        s12Expiry: string | null,
        name: string,
        legalName: string,
        phone: string | null,
        phone2: string | null,
        email: string,
        gl: boolean | null,
        employer: string | null,
        jobTitle: string | null,
        responsibleOfficer: string | null,
        lineManager: string | null,
        defaultMHT: string | null,
        specialties: Array< string | null >,
        languages: Array< string | null >,
        gender: Gender | null,
        notes: string | null,
        pushTokens: Array< string | null > | null,
        distance: number | null,
        hideFromSearch: boolean | null,
        availabilityPostcodes: Array< string | null > | null,
        siteName: string,
        sectionFormEmailAddress: string | null,
        createdAt: string | null,
        lastActivity: string | null,
        isOutsideEngland: boolean | null,
      },
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type AddressesForAmhpTeamQueryVariables = {
  amhpTeamId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAddressFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AddressesForAmhpTeamQuery = {
  addressesForAmhpTeam:  {
    __typename: "ModelAddressConnection",
    items:  Array< {
      __typename: "Address",
      id: string,
      amhpTeamId: string,
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      },
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      },
      ccgId: string,
      ccg:  {
        __typename: "Organisation",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        type: OrgType,
        phone: string | null,
        email: string | null,
        expensesInstruction: string | null,
        declarationText: string | null,
        additionalNotesText: string | null,
        invoiceEmail: string | null,
        updatedBy: string | null,
        deleted: boolean | null,
      } | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type AssessmentsByAmhpTeamQueryVariables = {
  amhpTeamId?: string | null,
  assessmentDate?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAssessmentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AssessmentsByAmhpTeamQuery = {
  assessmentsByAmhpTeam:  {
    __typename: "ModelAssessmentConnection",
    items:  Array< {
      __typename: "Assessment",
      id: string,
      createdAt: string,
      amhpTeamId: string,
      amhpTeam:  {
        __typename: "AmhpTeam",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        localAuthority: string | null,
        emails: Array< string > | null,
        phoneNumbers: Array< string > | null,
        mhaInboxInstructions: string | null,
        updatedBy: string | null,
      },
      amhpId: string,
      amhp:  {
        __typename: "AmhpProfile",
        id: string,
        email: string,
        phone: string | null,
        name: string,
        legalName: string | null,
        deleted: boolean | null,
        warrantInfo: string | null,
        accreditation: string | null,
        sectionFormEmailAddress: string | null,
        sectionFormContactNumber: string | null,
        pushTokens: Array< string | null > | null,
        lastActivity: string | null,
        createdAt: string | null,
      },
      patientName: string,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      },
      ccgId: string | null,
      ccg:  {
        __typename: "Organisation",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        type: OrgType,
        phone: string | null,
        email: string | null,
        expensesInstruction: string | null,
        declarationText: string | null,
        additionalNotesText: string | null,
        invoiceEmail: string | null,
        updatedBy: string | null,
        deleted: boolean | null,
      } | null,
      ccgDetermination: LocationType | null,
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      },
      hadVisitAdded: boolean | null,
      doctorVisits:  {
        __typename: "ModelDoctorVisitConnection",
        nextToken: string | null,
      } | null,
      patientNhsNumber: string | null,
      assessmentDate: string | null,
      contactedDoctors:  {
        __typename: "ModelContactedDoctorConnection",
        nextToken: string | null,
      } | null,
      forms:  {
        __typename: "ModelFormConnection",
        nextToken: string | null,
      } | null,
      patientAddress:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type AssessmentsByAmhpQueryVariables = {
  amhpId?: string | null,
  assessmentDate?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAssessmentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AssessmentsByAmhpQuery = {
  assessmentsByAmhp:  {
    __typename: "ModelAssessmentConnection",
    items:  Array< {
      __typename: "Assessment",
      id: string,
      createdAt: string,
      amhpTeamId: string,
      amhpTeam:  {
        __typename: "AmhpTeam",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        localAuthority: string | null,
        emails: Array< string > | null,
        phoneNumbers: Array< string > | null,
        mhaInboxInstructions: string | null,
        updatedBy: string | null,
      },
      amhpId: string,
      amhp:  {
        __typename: "AmhpProfile",
        id: string,
        email: string,
        phone: string | null,
        name: string,
        legalName: string | null,
        deleted: boolean | null,
        warrantInfo: string | null,
        accreditation: string | null,
        sectionFormEmailAddress: string | null,
        sectionFormContactNumber: string | null,
        pushTokens: Array< string | null > | null,
        lastActivity: string | null,
        createdAt: string | null,
      },
      patientName: string,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      },
      ccgId: string | null,
      ccg:  {
        __typename: "Organisation",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        type: OrgType,
        phone: string | null,
        email: string | null,
        expensesInstruction: string | null,
        declarationText: string | null,
        additionalNotesText: string | null,
        invoiceEmail: string | null,
        updatedBy: string | null,
        deleted: boolean | null,
      } | null,
      ccgDetermination: LocationType | null,
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      },
      hadVisitAdded: boolean | null,
      doctorVisits:  {
        __typename: "ModelDoctorVisitConnection",
        nextToken: string | null,
      } | null,
      patientNhsNumber: string | null,
      assessmentDate: string | null,
      contactedDoctors:  {
        __typename: "ModelContactedDoctorConnection",
        nextToken: string | null,
      } | null,
      forms:  {
        __typename: "ModelFormConnection",
        nextToken: string | null,
      } | null,
      patientAddress:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type VisitsByDoctorQueryVariables = {
  doctorVisitDoctorId?: string | null,
  time?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDoctorVisitFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type VisitsByDoctorQuery = {
  visitsByDoctor:  {
    __typename: "ModelDoctorVisitConnection",
    items:  Array< {
      __typename: "DoctorVisit",
      id: string,
      createdAt: string | null,
      assessmentId: string,
      assessment:  {
        __typename: "Assessment",
        id: string,
        createdAt: string,
        amhpTeamId: string,
        amhpId: string,
        patientName: string,
        ccgId: string | null,
        ccgDetermination: LocationType | null,
        hadVisitAdded: boolean | null,
        patientNhsNumber: string | null,
        assessmentDate: string | null,
      },
      time: string,
      teamId: string,
      team:  {
        __typename: "AmhpTeam",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        localAuthority: string | null,
        emails: Array< string > | null,
        phoneNumbers: Array< string > | null,
        mhaInboxInstructions: string | null,
        updatedBy: string | null,
      },
      doctorVisitDoctorId: string,
      doctor:  {
        __typename: "S12Doctor",
        id: string,
        gmc: string,
        s12Expiry: string | null,
        name: string,
        legalName: string,
        phone: string | null,
        phone2: string | null,
        email: string,
        gl: boolean | null,
        employer: string | null,
        jobTitle: string | null,
        responsibleOfficer: string | null,
        lineManager: string | null,
        defaultMHT: string | null,
        specialties: Array< string | null >,
        languages: Array< string | null >,
        gender: Gender | null,
        notes: string | null,
        pushTokens: Array< string | null > | null,
        distance: number | null,
        hideFromSearch: boolean | null,
        availabilityPostcodes: Array< string | null > | null,
        siteName: string,
        sectionFormEmailAddress: string | null,
        createdAt: string | null,
        lastActivity: string | null,
        isOutsideEngland: boolean | null,
      },
      notes: Array< string | null > | null,
      partialPostcode: string | null,
      doctorVisitClaimId: string | null,
      claimDisplayId: string | null,
      claimStatus: ClaimStatus | null,
      claim:  {
        __typename: "Claim",
        id: string,
        claimAssessmentId: string | null,
        claimVisitId: string,
        claimDoctorId: string,
        assigneeId: string,
        assigneeName: string | null,
        amhpTeamId: string,
        claimAmhpId: string,
        claimOrganisationId: string,
        claimCsuId: string,
        status: ClaimStatus,
        displayId: string,
        mileage: string | null,
        locationText: string,
        locationType: LocationType,
        claimPostcode: string | null,
        assessmentPostcode: string | null,
        patientInitials: string,
        patientFullName: string | null,
        startingPostcode: string | null,
        visitDate: string,
        assessmentTookPlace: boolean | null,
        notes: Array< string | null > | null,
        unread: boolean | null,
        createdAt: string | null,
        updatedAt: string,
        receivedDate: string,
        additionalExpenses: string | null,
        additionalInformation: string | null,
        patientNhsNumber: string | null,
        declaration: string | null,
        paid: boolean | null,
        lineManager: string | null,
        doctorAdditionalConf: DoctorConfirmation | null,
        isIndependentDoctor: boolean | null,
        defaultMHT: string | null,
        doctorEmployedStatus: DoctorEmployedStatusValues | null,
      } | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type VisitsByAssessmentQueryVariables = {
  assessmentId?: string | null,
  time?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDoctorVisitFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type VisitsByAssessmentQuery = {
  visitsByAssessment:  {
    __typename: "ModelDoctorVisitConnection",
    items:  Array< {
      __typename: "DoctorVisit",
      id: string,
      createdAt: string | null,
      assessmentId: string,
      assessment:  {
        __typename: "Assessment",
        id: string,
        createdAt: string,
        amhpTeamId: string,
        amhpId: string,
        patientName: string,
        ccgId: string | null,
        ccgDetermination: LocationType | null,
        hadVisitAdded: boolean | null,
        patientNhsNumber: string | null,
        assessmentDate: string | null,
      },
      time: string,
      teamId: string,
      team:  {
        __typename: "AmhpTeam",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        localAuthority: string | null,
        emails: Array< string > | null,
        phoneNumbers: Array< string > | null,
        mhaInboxInstructions: string | null,
        updatedBy: string | null,
      },
      doctorVisitDoctorId: string,
      doctor:  {
        __typename: "S12Doctor",
        id: string,
        gmc: string,
        s12Expiry: string | null,
        name: string,
        legalName: string,
        phone: string | null,
        phone2: string | null,
        email: string,
        gl: boolean | null,
        employer: string | null,
        jobTitle: string | null,
        responsibleOfficer: string | null,
        lineManager: string | null,
        defaultMHT: string | null,
        specialties: Array< string | null >,
        languages: Array< string | null >,
        gender: Gender | null,
        notes: string | null,
        pushTokens: Array< string | null > | null,
        distance: number | null,
        hideFromSearch: boolean | null,
        availabilityPostcodes: Array< string | null > | null,
        siteName: string,
        sectionFormEmailAddress: string | null,
        createdAt: string | null,
        lastActivity: string | null,
        isOutsideEngland: boolean | null,
      },
      notes: Array< string | null > | null,
      partialPostcode: string | null,
      doctorVisitClaimId: string | null,
      claimDisplayId: string | null,
      claimStatus: ClaimStatus | null,
      claim:  {
        __typename: "Claim",
        id: string,
        claimAssessmentId: string | null,
        claimVisitId: string,
        claimDoctorId: string,
        assigneeId: string,
        assigneeName: string | null,
        amhpTeamId: string,
        claimAmhpId: string,
        claimOrganisationId: string,
        claimCsuId: string,
        status: ClaimStatus,
        displayId: string,
        mileage: string | null,
        locationText: string,
        locationType: LocationType,
        claimPostcode: string | null,
        assessmentPostcode: string | null,
        patientInitials: string,
        patientFullName: string | null,
        startingPostcode: string | null,
        visitDate: string,
        assessmentTookPlace: boolean | null,
        notes: Array< string | null > | null,
        unread: boolean | null,
        createdAt: string | null,
        updatedAt: string,
        receivedDate: string,
        additionalExpenses: string | null,
        additionalInformation: string | null,
        patientNhsNumber: string | null,
        declaration: string | null,
        paid: boolean | null,
        lineManager: string | null,
        doctorAdditionalConf: DoctorConfirmation | null,
        isIndependentDoctor: boolean | null,
        defaultMHT: string | null,
        doctorEmployedStatus: DoctorEmployedStatusValues | null,
      } | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type CcgByOrgIdQueryVariables = {
  cCGOrganisationId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCCGFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CcgByOrgIdQuery = {
  ccgByOrgId:  {
    __typename: "ModelCCGConnection",
    items:  Array< {
      __typename: "CCG",
      id: string,
      claimProcessorType: OrgType | null,
      claimProcessor:  {
        __typename: "Organisation",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        type: OrgType,
        phone: string | null,
        email: string | null,
        expensesInstruction: string | null,
        declarationText: string | null,
        additionalNotesText: string | null,
        invoiceEmail: string | null,
        updatedBy: string | null,
        deleted: boolean | null,
      } | null,
      cCGOrganisationId: string,
      organisation:  {
        __typename: "Organisation",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        type: OrgType,
        phone: string | null,
        email: string | null,
        expensesInstruction: string | null,
        declarationText: string | null,
        additionalNotesText: string | null,
        invoiceEmail: string | null,
        updatedBy: string | null,
        deleted: boolean | null,
      },
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type ClaimsByDoctorQueryVariables = {
  claimDoctorId?: string | null,
  statusUpdatedAt?: ModelClaimDoctorClaimsCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelClaimFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ClaimsByDoctorQuery = {
  claimsByDoctor:  {
    __typename: "ModelClaimConnection",
    items:  Array< {
      __typename: "Claim",
      id: string,
      claimAssessmentId: string | null,
      claimVisitId: string,
      visit:  {
        __typename: "DoctorVisit",
        id: string,
        createdAt: string | null,
        assessmentId: string,
        time: string,
        teamId: string,
        doctorVisitDoctorId: string,
        notes: Array< string | null > | null,
        partialPostcode: string | null,
        doctorVisitClaimId: string | null,
        claimDisplayId: string | null,
        claimStatus: ClaimStatus | null,
      },
      claimDoctorId: string,
      doctor:  {
        __typename: "S12Doctor",
        id: string,
        gmc: string,
        s12Expiry: string | null,
        name: string,
        legalName: string,
        phone: string | null,
        phone2: string | null,
        email: string,
        gl: boolean | null,
        employer: string | null,
        jobTitle: string | null,
        responsibleOfficer: string | null,
        lineManager: string | null,
        defaultMHT: string | null,
        specialties: Array< string | null >,
        languages: Array< string | null >,
        gender: Gender | null,
        notes: string | null,
        pushTokens: Array< string | null > | null,
        distance: number | null,
        hideFromSearch: boolean | null,
        availabilityPostcodes: Array< string | null > | null,
        siteName: string,
        sectionFormEmailAddress: string | null,
        createdAt: string | null,
        lastActivity: string | null,
        isOutsideEngland: boolean | null,
      },
      assigneeId: string,
      assigneeName: string | null,
      amhpTeamId: string,
      claimAmhpId: string,
      amhp:  {
        __typename: "AmhpProfile",
        id: string,
        email: string,
        phone: string | null,
        name: string,
        legalName: string | null,
        deleted: boolean | null,
        warrantInfo: string | null,
        accreditation: string | null,
        sectionFormEmailAddress: string | null,
        sectionFormContactNumber: string | null,
        pushTokens: Array< string | null > | null,
        lastActivity: string | null,
        createdAt: string | null,
      },
      claimOrganisationId: string,
      claimCsuId: string,
      organisation:  {
        __typename: "Organisation",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        type: OrgType,
        phone: string | null,
        email: string | null,
        expensesInstruction: string | null,
        declarationText: string | null,
        additionalNotesText: string | null,
        invoiceEmail: string | null,
        updatedBy: string | null,
        deleted: boolean | null,
      },
      status: ClaimStatus,
      displayId: string,
      mileage: string | null,
      locationText: string,
      locationType: LocationType,
      claimPostcode: string | null,
      assessmentPostcode: string | null,
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      patientInitials: string,
      patientFullName: string | null,
      startingPostcode: string | null,
      visitDate: string,
      assessmentTookPlace: boolean | null,
      notes: Array< string | null > | null,
      unread: boolean | null,
      createdAt: string | null,
      updatedAt: string,
      receivedDate: string,
      additionalExpenses: string | null,
      additionalInformation: string | null,
      patientNhsNumber: string | null,
      declaration: string | null,
      paid: boolean | null,
      claimData:  {
        __typename: "ClaimData",
        billingCompanyName: string | null,
        vatRegistration: string | null,
        fuelType: FuelType | null,
        engineSize: Engine | null,
      } | null,
      invoiceData:  {
        __typename: "InvoiceData",
        assessmentFee: string,
        travelFee: string | null,
        locationOfAssessment: string | null,
      } | null,
      lineManager: string | null,
      doctorAdditionalConf: DoctorConfirmation | null,
      isIndependentDoctor: boolean | null,
      defaultMHT: string | null,
      doctorEmployedStatus: DoctorEmployedStatusValues | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type ClaimsByOrgQueryVariables = {
  claimOrganisationId?: string | null,
  statusReceivedDate?: ModelClaimOrgClaims2CompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelClaimFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ClaimsByOrgQuery = {
  claimsByOrg:  {
    __typename: "ModelClaimConnection",
    items:  Array< {
      __typename: "Claim",
      id: string,
      claimAssessmentId: string | null,
      claimVisitId: string,
      visit:  {
        __typename: "DoctorVisit",
        id: string,
        createdAt: string | null,
        assessmentId: string,
        time: string,
        teamId: string,
        doctorVisitDoctorId: string,
        notes: Array< string | null > | null,
        partialPostcode: string | null,
        doctorVisitClaimId: string | null,
        claimDisplayId: string | null,
        claimStatus: ClaimStatus | null,
      },
      claimDoctorId: string,
      doctor:  {
        __typename: "S12Doctor",
        id: string,
        gmc: string,
        s12Expiry: string | null,
        name: string,
        legalName: string,
        phone: string | null,
        phone2: string | null,
        email: string,
        gl: boolean | null,
        employer: string | null,
        jobTitle: string | null,
        responsibleOfficer: string | null,
        lineManager: string | null,
        defaultMHT: string | null,
        specialties: Array< string | null >,
        languages: Array< string | null >,
        gender: Gender | null,
        notes: string | null,
        pushTokens: Array< string | null > | null,
        distance: number | null,
        hideFromSearch: boolean | null,
        availabilityPostcodes: Array< string | null > | null,
        siteName: string,
        sectionFormEmailAddress: string | null,
        createdAt: string | null,
        lastActivity: string | null,
        isOutsideEngland: boolean | null,
      },
      assigneeId: string,
      assigneeName: string | null,
      amhpTeamId: string,
      claimAmhpId: string,
      amhp:  {
        __typename: "AmhpProfile",
        id: string,
        email: string,
        phone: string | null,
        name: string,
        legalName: string | null,
        deleted: boolean | null,
        warrantInfo: string | null,
        accreditation: string | null,
        sectionFormEmailAddress: string | null,
        sectionFormContactNumber: string | null,
        pushTokens: Array< string | null > | null,
        lastActivity: string | null,
        createdAt: string | null,
      },
      claimOrganisationId: string,
      claimCsuId: string,
      organisation:  {
        __typename: "Organisation",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        type: OrgType,
        phone: string | null,
        email: string | null,
        expensesInstruction: string | null,
        declarationText: string | null,
        additionalNotesText: string | null,
        invoiceEmail: string | null,
        updatedBy: string | null,
        deleted: boolean | null,
      },
      status: ClaimStatus,
      displayId: string,
      mileage: string | null,
      locationText: string,
      locationType: LocationType,
      claimPostcode: string | null,
      assessmentPostcode: string | null,
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      patientInitials: string,
      patientFullName: string | null,
      startingPostcode: string | null,
      visitDate: string,
      assessmentTookPlace: boolean | null,
      notes: Array< string | null > | null,
      unread: boolean | null,
      createdAt: string | null,
      updatedAt: string,
      receivedDate: string,
      additionalExpenses: string | null,
      additionalInformation: string | null,
      patientNhsNumber: string | null,
      declaration: string | null,
      paid: boolean | null,
      claimData:  {
        __typename: "ClaimData",
        billingCompanyName: string | null,
        vatRegistration: string | null,
        fuelType: FuelType | null,
        engineSize: Engine | null,
      } | null,
      invoiceData:  {
        __typename: "InvoiceData",
        assessmentFee: string,
        travelFee: string | null,
        locationOfAssessment: string | null,
      } | null,
      lineManager: string | null,
      doctorAdditionalConf: DoctorConfirmation | null,
      isIndependentDoctor: boolean | null,
      defaultMHT: string | null,
      doctorEmployedStatus: DoctorEmployedStatusValues | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type ClaimsByCsuQueryVariables = {
  claimCsuId?: string | null,
  statusReceivedDate?: ModelClaimCsuClaimsCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelClaimFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ClaimsByCsuQuery = {
  claimsByCsu:  {
    __typename: "ModelClaimConnection",
    items:  Array< {
      __typename: "Claim",
      id: string,
      claimAssessmentId: string | null,
      claimVisitId: string,
      visit:  {
        __typename: "DoctorVisit",
        id: string,
        createdAt: string | null,
        assessmentId: string,
        time: string,
        teamId: string,
        doctorVisitDoctorId: string,
        notes: Array< string | null > | null,
        partialPostcode: string | null,
        doctorVisitClaimId: string | null,
        claimDisplayId: string | null,
        claimStatus: ClaimStatus | null,
      },
      claimDoctorId: string,
      doctor:  {
        __typename: "S12Doctor",
        id: string,
        gmc: string,
        s12Expiry: string | null,
        name: string,
        legalName: string,
        phone: string | null,
        phone2: string | null,
        email: string,
        gl: boolean | null,
        employer: string | null,
        jobTitle: string | null,
        responsibleOfficer: string | null,
        lineManager: string | null,
        defaultMHT: string | null,
        specialties: Array< string | null >,
        languages: Array< string | null >,
        gender: Gender | null,
        notes: string | null,
        pushTokens: Array< string | null > | null,
        distance: number | null,
        hideFromSearch: boolean | null,
        availabilityPostcodes: Array< string | null > | null,
        siteName: string,
        sectionFormEmailAddress: string | null,
        createdAt: string | null,
        lastActivity: string | null,
        isOutsideEngland: boolean | null,
      },
      assigneeId: string,
      assigneeName: string | null,
      amhpTeamId: string,
      claimAmhpId: string,
      amhp:  {
        __typename: "AmhpProfile",
        id: string,
        email: string,
        phone: string | null,
        name: string,
        legalName: string | null,
        deleted: boolean | null,
        warrantInfo: string | null,
        accreditation: string | null,
        sectionFormEmailAddress: string | null,
        sectionFormContactNumber: string | null,
        pushTokens: Array< string | null > | null,
        lastActivity: string | null,
        createdAt: string | null,
      },
      claimOrganisationId: string,
      claimCsuId: string,
      organisation:  {
        __typename: "Organisation",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        type: OrgType,
        phone: string | null,
        email: string | null,
        expensesInstruction: string | null,
        declarationText: string | null,
        additionalNotesText: string | null,
        invoiceEmail: string | null,
        updatedBy: string | null,
        deleted: boolean | null,
      },
      status: ClaimStatus,
      displayId: string,
      mileage: string | null,
      locationText: string,
      locationType: LocationType,
      claimPostcode: string | null,
      assessmentPostcode: string | null,
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      patientInitials: string,
      patientFullName: string | null,
      startingPostcode: string | null,
      visitDate: string,
      assessmentTookPlace: boolean | null,
      notes: Array< string | null > | null,
      unread: boolean | null,
      createdAt: string | null,
      updatedAt: string,
      receivedDate: string,
      additionalExpenses: string | null,
      additionalInformation: string | null,
      patientNhsNumber: string | null,
      declaration: string | null,
      paid: boolean | null,
      claimData:  {
        __typename: "ClaimData",
        billingCompanyName: string | null,
        vatRegistration: string | null,
        fuelType: FuelType | null,
        engineSize: Engine | null,
      } | null,
      invoiceData:  {
        __typename: "InvoiceData",
        assessmentFee: string,
        travelFee: string | null,
        locationOfAssessment: string | null,
      } | null,
      lineManager: string | null,
      doctorAdditionalConf: DoctorConfirmation | null,
      isIndependentDoctor: boolean | null,
      defaultMHT: string | null,
      doctorEmployedStatus: DoctorEmployedStatusValues | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type ClaimsByCsuAssigneeQueryVariables = {
  claimCsuId?: string | null,
  statusAssigneeIdReceivedDate?: ModelClaimCsuAssigneeClaimsCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelClaimFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ClaimsByCsuAssigneeQuery = {
  claimsByCsuAssignee:  {
    __typename: "ModelClaimConnection",
    items:  Array< {
      __typename: "Claim",
      id: string,
      claimAssessmentId: string | null,
      claimVisitId: string,
      visit:  {
        __typename: "DoctorVisit",
        id: string,
        createdAt: string | null,
        assessmentId: string,
        time: string,
        teamId: string,
        doctorVisitDoctorId: string,
        notes: Array< string | null > | null,
        partialPostcode: string | null,
        doctorVisitClaimId: string | null,
        claimDisplayId: string | null,
        claimStatus: ClaimStatus | null,
      },
      claimDoctorId: string,
      doctor:  {
        __typename: "S12Doctor",
        id: string,
        gmc: string,
        s12Expiry: string | null,
        name: string,
        legalName: string,
        phone: string | null,
        phone2: string | null,
        email: string,
        gl: boolean | null,
        employer: string | null,
        jobTitle: string | null,
        responsibleOfficer: string | null,
        lineManager: string | null,
        defaultMHT: string | null,
        specialties: Array< string | null >,
        languages: Array< string | null >,
        gender: Gender | null,
        notes: string | null,
        pushTokens: Array< string | null > | null,
        distance: number | null,
        hideFromSearch: boolean | null,
        availabilityPostcodes: Array< string | null > | null,
        siteName: string,
        sectionFormEmailAddress: string | null,
        createdAt: string | null,
        lastActivity: string | null,
        isOutsideEngland: boolean | null,
      },
      assigneeId: string,
      assigneeName: string | null,
      amhpTeamId: string,
      claimAmhpId: string,
      amhp:  {
        __typename: "AmhpProfile",
        id: string,
        email: string,
        phone: string | null,
        name: string,
        legalName: string | null,
        deleted: boolean | null,
        warrantInfo: string | null,
        accreditation: string | null,
        sectionFormEmailAddress: string | null,
        sectionFormContactNumber: string | null,
        pushTokens: Array< string | null > | null,
        lastActivity: string | null,
        createdAt: string | null,
      },
      claimOrganisationId: string,
      claimCsuId: string,
      organisation:  {
        __typename: "Organisation",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        type: OrgType,
        phone: string | null,
        email: string | null,
        expensesInstruction: string | null,
        declarationText: string | null,
        additionalNotesText: string | null,
        invoiceEmail: string | null,
        updatedBy: string | null,
        deleted: boolean | null,
      },
      status: ClaimStatus,
      displayId: string,
      mileage: string | null,
      locationText: string,
      locationType: LocationType,
      claimPostcode: string | null,
      assessmentPostcode: string | null,
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      patientInitials: string,
      patientFullName: string | null,
      startingPostcode: string | null,
      visitDate: string,
      assessmentTookPlace: boolean | null,
      notes: Array< string | null > | null,
      unread: boolean | null,
      createdAt: string | null,
      updatedAt: string,
      receivedDate: string,
      additionalExpenses: string | null,
      additionalInformation: string | null,
      patientNhsNumber: string | null,
      declaration: string | null,
      paid: boolean | null,
      claimData:  {
        __typename: "ClaimData",
        billingCompanyName: string | null,
        vatRegistration: string | null,
        fuelType: FuelType | null,
        engineSize: Engine | null,
      } | null,
      invoiceData:  {
        __typename: "InvoiceData",
        assessmentFee: string,
        travelFee: string | null,
        locationOfAssessment: string | null,
      } | null,
      lineManager: string | null,
      doctorAdditionalConf: DoctorConfirmation | null,
      isIndependentDoctor: boolean | null,
      defaultMHT: string | null,
      doctorEmployedStatus: DoctorEmployedStatusValues | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type ClaimsByOrgAssigneeQueryVariables = {
  claimOrganisationId?: string | null,
  statusAssigneeIdReceivedDate?: ModelClaimAssigneeClaims2CompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelClaimFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ClaimsByOrgAssigneeQuery = {
  claimsByOrgAssignee:  {
    __typename: "ModelClaimConnection",
    items:  Array< {
      __typename: "Claim",
      id: string,
      claimAssessmentId: string | null,
      claimVisitId: string,
      visit:  {
        __typename: "DoctorVisit",
        id: string,
        createdAt: string | null,
        assessmentId: string,
        time: string,
        teamId: string,
        doctorVisitDoctorId: string,
        notes: Array< string | null > | null,
        partialPostcode: string | null,
        doctorVisitClaimId: string | null,
        claimDisplayId: string | null,
        claimStatus: ClaimStatus | null,
      },
      claimDoctorId: string,
      doctor:  {
        __typename: "S12Doctor",
        id: string,
        gmc: string,
        s12Expiry: string | null,
        name: string,
        legalName: string,
        phone: string | null,
        phone2: string | null,
        email: string,
        gl: boolean | null,
        employer: string | null,
        jobTitle: string | null,
        responsibleOfficer: string | null,
        lineManager: string | null,
        defaultMHT: string | null,
        specialties: Array< string | null >,
        languages: Array< string | null >,
        gender: Gender | null,
        notes: string | null,
        pushTokens: Array< string | null > | null,
        distance: number | null,
        hideFromSearch: boolean | null,
        availabilityPostcodes: Array< string | null > | null,
        siteName: string,
        sectionFormEmailAddress: string | null,
        createdAt: string | null,
        lastActivity: string | null,
        isOutsideEngland: boolean | null,
      },
      assigneeId: string,
      assigneeName: string | null,
      amhpTeamId: string,
      claimAmhpId: string,
      amhp:  {
        __typename: "AmhpProfile",
        id: string,
        email: string,
        phone: string | null,
        name: string,
        legalName: string | null,
        deleted: boolean | null,
        warrantInfo: string | null,
        accreditation: string | null,
        sectionFormEmailAddress: string | null,
        sectionFormContactNumber: string | null,
        pushTokens: Array< string | null > | null,
        lastActivity: string | null,
        createdAt: string | null,
      },
      claimOrganisationId: string,
      claimCsuId: string,
      organisation:  {
        __typename: "Organisation",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        type: OrgType,
        phone: string | null,
        email: string | null,
        expensesInstruction: string | null,
        declarationText: string | null,
        additionalNotesText: string | null,
        invoiceEmail: string | null,
        updatedBy: string | null,
        deleted: boolean | null,
      },
      status: ClaimStatus,
      displayId: string,
      mileage: string | null,
      locationText: string,
      locationType: LocationType,
      claimPostcode: string | null,
      assessmentPostcode: string | null,
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      patientInitials: string,
      patientFullName: string | null,
      startingPostcode: string | null,
      visitDate: string,
      assessmentTookPlace: boolean | null,
      notes: Array< string | null > | null,
      unread: boolean | null,
      createdAt: string | null,
      updatedAt: string,
      receivedDate: string,
      additionalExpenses: string | null,
      additionalInformation: string | null,
      patientNhsNumber: string | null,
      declaration: string | null,
      paid: boolean | null,
      claimData:  {
        __typename: "ClaimData",
        billingCompanyName: string | null,
        vatRegistration: string | null,
        fuelType: FuelType | null,
        engineSize: Engine | null,
      } | null,
      invoiceData:  {
        __typename: "InvoiceData",
        assessmentFee: string,
        travelFee: string | null,
        locationOfAssessment: string | null,
      } | null,
      lineManager: string | null,
      doctorAdditionalConf: DoctorConfirmation | null,
      isIndependentDoctor: boolean | null,
      defaultMHT: string | null,
      doctorEmployedStatus: DoctorEmployedStatusValues | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type ClaimsByTeamOrgQueryVariables = {
  claimOrganisationId?: string | null,
  statusReceivedDateAssigneeId?: ModelClaimTeamClaimsCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelClaimFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ClaimsByTeamOrgQuery = {
  claimsByTeamOrg:  {
    __typename: "ModelClaimConnection",
    items:  Array< {
      __typename: "Claim",
      id: string,
      claimAssessmentId: string | null,
      claimVisitId: string,
      visit:  {
        __typename: "DoctorVisit",
        id: string,
        createdAt: string | null,
        assessmentId: string,
        time: string,
        teamId: string,
        doctorVisitDoctorId: string,
        notes: Array< string | null > | null,
        partialPostcode: string | null,
        doctorVisitClaimId: string | null,
        claimDisplayId: string | null,
        claimStatus: ClaimStatus | null,
      },
      claimDoctorId: string,
      doctor:  {
        __typename: "S12Doctor",
        id: string,
        gmc: string,
        s12Expiry: string | null,
        name: string,
        legalName: string,
        phone: string | null,
        phone2: string | null,
        email: string,
        gl: boolean | null,
        employer: string | null,
        jobTitle: string | null,
        responsibleOfficer: string | null,
        lineManager: string | null,
        defaultMHT: string | null,
        specialties: Array< string | null >,
        languages: Array< string | null >,
        gender: Gender | null,
        notes: string | null,
        pushTokens: Array< string | null > | null,
        distance: number | null,
        hideFromSearch: boolean | null,
        availabilityPostcodes: Array< string | null > | null,
        siteName: string,
        sectionFormEmailAddress: string | null,
        createdAt: string | null,
        lastActivity: string | null,
        isOutsideEngland: boolean | null,
      },
      assigneeId: string,
      assigneeName: string | null,
      amhpTeamId: string,
      claimAmhpId: string,
      amhp:  {
        __typename: "AmhpProfile",
        id: string,
        email: string,
        phone: string | null,
        name: string,
        legalName: string | null,
        deleted: boolean | null,
        warrantInfo: string | null,
        accreditation: string | null,
        sectionFormEmailAddress: string | null,
        sectionFormContactNumber: string | null,
        pushTokens: Array< string | null > | null,
        lastActivity: string | null,
        createdAt: string | null,
      },
      claimOrganisationId: string,
      claimCsuId: string,
      organisation:  {
        __typename: "Organisation",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        type: OrgType,
        phone: string | null,
        email: string | null,
        expensesInstruction: string | null,
        declarationText: string | null,
        additionalNotesText: string | null,
        invoiceEmail: string | null,
        updatedBy: string | null,
        deleted: boolean | null,
      },
      status: ClaimStatus,
      displayId: string,
      mileage: string | null,
      locationText: string,
      locationType: LocationType,
      claimPostcode: string | null,
      assessmentPostcode: string | null,
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      patientInitials: string,
      patientFullName: string | null,
      startingPostcode: string | null,
      visitDate: string,
      assessmentTookPlace: boolean | null,
      notes: Array< string | null > | null,
      unread: boolean | null,
      createdAt: string | null,
      updatedAt: string,
      receivedDate: string,
      additionalExpenses: string | null,
      additionalInformation: string | null,
      patientNhsNumber: string | null,
      declaration: string | null,
      paid: boolean | null,
      claimData:  {
        __typename: "ClaimData",
        billingCompanyName: string | null,
        vatRegistration: string | null,
        fuelType: FuelType | null,
        engineSize: Engine | null,
      } | null,
      invoiceData:  {
        __typename: "InvoiceData",
        assessmentFee: string,
        travelFee: string | null,
        locationOfAssessment: string | null,
      } | null,
      lineManager: string | null,
      doctorAdditionalConf: DoctorConfirmation | null,
      isIndependentDoctor: boolean | null,
      defaultMHT: string | null,
      doctorEmployedStatus: DoctorEmployedStatusValues | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type ClaimsByTeamCsuQueryVariables = {
  claimCsuId?: string | null,
  statusReceivedDateAssigneeId?: ModelClaimCsuTeamClaimsCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelClaimFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ClaimsByTeamCsuQuery = {
  claimsByTeamCsu:  {
    __typename: "ModelClaimConnection",
    items:  Array< {
      __typename: "Claim",
      id: string,
      claimAssessmentId: string | null,
      claimVisitId: string,
      visit:  {
        __typename: "DoctorVisit",
        id: string,
        createdAt: string | null,
        assessmentId: string,
        time: string,
        teamId: string,
        doctorVisitDoctorId: string,
        notes: Array< string | null > | null,
        partialPostcode: string | null,
        doctorVisitClaimId: string | null,
        claimDisplayId: string | null,
        claimStatus: ClaimStatus | null,
      },
      claimDoctorId: string,
      doctor:  {
        __typename: "S12Doctor",
        id: string,
        gmc: string,
        s12Expiry: string | null,
        name: string,
        legalName: string,
        phone: string | null,
        phone2: string | null,
        email: string,
        gl: boolean | null,
        employer: string | null,
        jobTitle: string | null,
        responsibleOfficer: string | null,
        lineManager: string | null,
        defaultMHT: string | null,
        specialties: Array< string | null >,
        languages: Array< string | null >,
        gender: Gender | null,
        notes: string | null,
        pushTokens: Array< string | null > | null,
        distance: number | null,
        hideFromSearch: boolean | null,
        availabilityPostcodes: Array< string | null > | null,
        siteName: string,
        sectionFormEmailAddress: string | null,
        createdAt: string | null,
        lastActivity: string | null,
        isOutsideEngland: boolean | null,
      },
      assigneeId: string,
      assigneeName: string | null,
      amhpTeamId: string,
      claimAmhpId: string,
      amhp:  {
        __typename: "AmhpProfile",
        id: string,
        email: string,
        phone: string | null,
        name: string,
        legalName: string | null,
        deleted: boolean | null,
        warrantInfo: string | null,
        accreditation: string | null,
        sectionFormEmailAddress: string | null,
        sectionFormContactNumber: string | null,
        pushTokens: Array< string | null > | null,
        lastActivity: string | null,
        createdAt: string | null,
      },
      claimOrganisationId: string,
      claimCsuId: string,
      organisation:  {
        __typename: "Organisation",
        id: string,
        name: string,
        featureFlags: string | null,
        features: string | null,
        type: OrgType,
        phone: string | null,
        email: string | null,
        expensesInstruction: string | null,
        declarationText: string | null,
        additionalNotesText: string | null,
        invoiceEmail: string | null,
        updatedBy: string | null,
        deleted: boolean | null,
      },
      status: ClaimStatus,
      displayId: string,
      mileage: string | null,
      locationText: string,
      locationType: LocationType,
      claimPostcode: string | null,
      assessmentPostcode: string | null,
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      patientInitials: string,
      patientFullName: string | null,
      startingPostcode: string | null,
      visitDate: string,
      assessmentTookPlace: boolean | null,
      notes: Array< string | null > | null,
      unread: boolean | null,
      createdAt: string | null,
      updatedAt: string,
      receivedDate: string,
      additionalExpenses: string | null,
      additionalInformation: string | null,
      patientNhsNumber: string | null,
      declaration: string | null,
      paid: boolean | null,
      claimData:  {
        __typename: "ClaimData",
        billingCompanyName: string | null,
        vatRegistration: string | null,
        fuelType: FuelType | null,
        engineSize: Engine | null,
      } | null,
      invoiceData:  {
        __typename: "InvoiceData",
        assessmentFee: string,
        travelFee: string | null,
        locationOfAssessment: string | null,
      } | null,
      lineManager: string | null,
      doctorAdditionalConf: DoctorConfirmation | null,
      isIndependentDoctor: boolean | null,
      defaultMHT: string | null,
      doctorEmployedStatus: DoctorEmployedStatusValues | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type SearchS12DoctorsQueryVariables = {
  filter?: SearchableS12DoctorFilterInput | null,
  sort?: SearchableS12DoctorSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SearchS12DoctorsQuery = {
  searchS12Doctors:  {
    __typename: "SearchableS12DoctorConnection",
    items:  Array< {
      __typename: "S12Doctor",
      id: string,
      gmc: string,
      s12Expiry: string | null,
      name: string,
      legalName: string,
      phone: string | null,
      phone2: string | null,
      email: string,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      },
      locationName:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      },
      gl: boolean | null,
      employer: string | null,
      jobTitle: string | null,
      responsibleOfficer: string | null,
      lineManager: string | null,
      defaultMHT: string | null,
      mhtEmployers:  {
        __typename: "ModelMHTS12DoctorConnection",
        nextToken: string | null,
      } | null,
      availabilities:  {
        __typename: "ModelAvailabilityConnection",
        nextToken: string | null,
      } | null,
      holidays:  {
        __typename: "ModelHolidayConnection",
        nextToken: string | null,
      } | null,
      specialties: Array< string | null >,
      languages: Array< string | null >,
      gender: Gender | null,
      notes: string | null,
      pushTokens: Array< string | null > | null,
      distance: number | null,
      hideFromSearch: boolean | null,
      visits:  {
        __typename: "ModelDoctorVisitConnection",
        nextToken: string | null,
      } | null,
      doctors:  {
        __typename: "ModelContactedDoctorConnection",
        nextToken: string | null,
      } | null,
      claims:  {
        __typename: "ModelClaimConnection",
        nextToken: string | null,
      } | null,
      availabilityLocations:  Array< {
        __typename: "Location",
        lat: number,
        lon: number,
      } | null > | null,
      availabilityPostcodes: Array< string | null > | null,
      siteName: string,
      claimData:  {
        __typename: "ClaimData",
        billingCompanyName: string | null,
        vatRegistration: string | null,
        fuelType: FuelType | null,
        engineSize: Engine | null,
      } | null,
      sectionFormAddress:  {
        __typename: "LocationName",
        city: string | null,
        postcode: string,
        address: string | null,
        addressNotes: string | null,
      } | null,
      sectionFormEmailAddress: string | null,
      reports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      summaryReports:  {
        __typename: "ModelReportingConnection",
        nextToken: string | null,
      } | null,
      createdAt: string | null,
      lastActivity: string | null,
      isOutsideEngland: boolean | null,
    } | null > | null,
    nextToken: string | null,
    total: number | null,
  } | null,
};
