import React from "react";
import Text from "../Text";
import { View } from "react-native";
import InfoItem from "./InfoItem";
import { TypographyProps, TypographyType } from "@/models/Typography";
import { palette } from "../../theme";

const InfoItemLabel = (
  props: {
    label: string;
    linkLabel?: string;
    linkOnPress?: () => void;
  } & TypographyProps
) => {
  const { label, linkLabel, linkOnPress, format, ...restProps } = props;
  return (
    <View>
      <Text format={TypographyType.Micro} color={palette.greyBlue}>
        {label}
      </Text>
      <InfoItem {...restProps} format={format} linkLabel={linkLabel} linkOnPress={linkOnPress} />
    </View>
  );
};

export default InfoItemLabel;
