import * as Yup from "yup";
import dayjs from "dayjs";

import { ComponentElement, FormFieldsAmhp } from "libs/types/mhaForms";
import createDateSchema from "libs/validators/yup/createDateSchema";

const componentElements: ComponentElement[] = [
  {
    component: "Text",
    componentProps: {
      instruction: "I saw the patient on",
    },
    fullWidth: true,
  },
  {
    component: "Date",
    fieldName: FormFieldsAmhp.AssessmentDate,
    componentProps: {
      label: "Date",
    },
  },
  {
    component: "Text",
    componentProps: {
      instruction: `which was within the period of 14 days ending on the day this application is completed.\n\nI have interviewed the patient and I am satisfied that detention in a hospital is in all the circumstances of the case the most appropriate way of providing the care and medical treatment of which the patient stands in need.\n\nThis application is founded on two medical recommendations in the prescribed form.\n\n`,
      guidance:
        "If neither of the medical practitioners had previous acquaintance with the patient before making their recommendations, please explain why you could not get a recommendation from a medical practitioner who did have previous acquaintance with the patient —",
    },
    fullWidth: true,
  },
  {
    component: "BottomTextInput",
    fullWidth: true,
    fieldName: FormFieldsAmhp.Reasons,
    componentProps: {
      label: "Reasons",
      helperText:
        "This application is founded on two medical recommendations in the prescribed form.\n\nIf neither of the medical practitioners had previous acquaintance with the patient before making their recommendations, please explain why you could not get a recommendation from a medical practitioner who did have previous acquaintance with the patient",
      buttonLabel: "Reasons",
      defaultText: "No details currently given",
    },
  },
];

export default {
  title: "Assessment Details",
  state: {
    assessmentDate: "",
    reasons: "",
  },

  validation: Yup.object().shape({
    assessmentDate: Yup.lazy(() =>
      createDateSchema({
        min: dayjs().subtract(15, "day").startOf("day"),
        max: dayjs().endOf("day"),
        required: true,
      })
    ),
    reasons: Yup.string(),
  }),

  componentElements,
};
