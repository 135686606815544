import {
  FirstDoctorDetails,
  FirstDoctorExaminationDetails,
  JointDetentionDetails,
  JointDetentionDetailsTreatment,
  JointHospitalRecommendations,
  JointPatientDetails,
  SecondDoctorDetails,
  SecondDoctorExaminationDetails,
} from "./jointForms";

export { default as DoctorDetails } from "./doctorDetails";
export { default as DoctorPatientDetails } from "./patientDetails";
export { default as DoctorPatientDetailsTreatment } from "./patientDetailsTreatment";
export { default as ExaminationDetails } from "./examinationDetails";
export { default as DetentionDetails } from "./detentionDetails";
export { default as DetentionTreatmentDetails } from "./detentionTreatmentDetails";
export { default as HospitalRecommendations } from "./hospitalRecommendations";

export {
  FirstDoctorDetails,
  FirstDoctorExaminationDetails,
  JointDetentionDetails,
  JointDetentionDetailsTreatment,
  JointHospitalRecommendations,
  JointPatientDetails,
  SecondDoctorDetails,
  SecondDoctorExaminationDetails,
};
