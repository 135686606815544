import React from "react";
import { StyleSheet, View, ViewStyle } from "react-native";

interface ItemSpacerProps {
  children: React.ReactNode;
  gap: 5 | 10 | 15 | 20 | 25 | 30 | 35 | 40 | 45 | 50;
  direction?: "column" | "row";
}

const ItemSpacer = ({ gap, direction = "column", children }: ItemSpacerProps) => {
  const items = React.Children.map(children, (child: React.ReactElement, i: number) => {
    if (child) {
      const lastItem = i === React.Children.count(children) - 1;
      return lastItem ? <View>{child}</View> : <View style={styles[direction + gap]}>{child}</View>;
    }
    return true;
  });
  return <>{items}</>;
};

const styles: { [key: string]: ViewStyle } = StyleSheet.create({
  column5: { paddingBottom: 5 },
  column10: { paddingBottom: 10 },
  column15: { paddingBottom: 15 },
  column20: { paddingBottom: 20 },
  column25: { paddingBottom: 25 },
  column30: { paddingBottom: 30 },
  column35: { paddingBottom: 35 },
  column40: { paddingBottom: 40 },
  column45: { paddingBottom: 45 },
  column50: { paddingBottom: 50 },

  row5: { paddingRight: 5 },
  row10: { paddingRight: 10 },
  row15: { paddingRight: 15 },
  row20: { paddingRight: 20 },
  row25: { paddingRight: 25 },
  row30: { paddingRight: 30 },
  row35: { paddingRight: 35 },
  row40: { paddingRight: 40 },
  row45: { paddingRight: 45 },
  row50: { paddingRight: 50 },
});

export default ItemSpacer;
