import { PathConfigMap } from "@react-navigation/native";

const authScreens: PathConfigMap = {
  AuthNavigator: {
    screens: {
      Confirmation: { path: "Auth/confirmation" },
      Eula: { path: "Auth/eula" },
      ForgotPassword: { path: "Auth/forgotpassword" },
      NewPassword: { path: "Auth/newpassword" },
      SignIn: { path: "Auth/signin" },
      Signup: { path: "Auth/signup" },
      Support: { path: "Auth/support" },
      Welcome: { path: "Auth/welcome" },
    },
  },
};

export default authScreens;
