import * as React from "react";
import { View, StyleSheet } from "react-native";
import Text from "../Text";
import { TypographyType } from "@/models/Typography";

export default function Loading() {
  return (
    <View style={styles.container}>
      <Text format={TypographyType.HeadingMedium}>Loading...</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    position: "relative",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});
