import * as React from "react";
import { StyleProp, StyleSheet, TouchableOpacity, View, ViewStyle } from "react-native";

import { TypographyType } from "@/models/Typography";
import { color, spacing } from "../../theme";
import Text from "../Text";
import { mqWeb } from "../../utils/helpers";

export function ContactBox(props: {
  label?: string;
  data: string;
  style?: StyleProp<ViewStyle>;
  dataTypography?: TypographyType;
  secondaryData?: string;
  lastInList?: boolean;
  icon: JSX.Element;
  onPress: () => void;
}) {
  const { lastInList = false, onPress } = props;
  const isWebView = mqWeb();

  return (
    <TouchableOpacity style={[styles.contactBox, lastInList && styles.lastInList, props.style]} onPress={onPress}>
      <View style={[styles.contactIconContainer, isWebView && styles.contactIconContainerWeb, props.style]}>
        {props.icon}
      </View>
      <View style={styles.contactBoxText}>
        {props.label && (
          <Text format={TypographyType.Tiny} style={styles.infoLabel}>
            {props.label}
          </Text>
        )}
        <Text format={props.dataTypography || TypographyType.Small} style={styles.infoData}>
          {props.data}
        </Text>
        {props.secondaryData && (
          <Text format={TypographyType.Micro} style={styles.secondaryData}>
            {props.secondaryData}
          </Text>
        )}
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  contactBox: {
    borderBottomWidth: 1,
    borderBottomColor: color.thinBorderLight,
    paddingTop: spacing[25],
    paddingBottom: spacing[25],
    flexDirection: "row",
    alignItems: "center",
  },

  contactIconContainer: {
    paddingTop: spacing[10],
    width: 60,
  },

  contactIconContainerWeb: {
    alignSelf: "flex-start",
  },

  contactBoxText: {
    justifyContent: "flex-start",
    paddingLeft: spacing[10],
    maxWidth: "100%",
  },

  lastInList: {
    paddingBottom: 0,
    borderBottomWidth: 0,
  },

  infoLabel: {
    marginBottom: spacing[5],
    color: color.textExtraLight,
  },

  infoData: {
    color: color.text,
    alignSelf: "flex-start",
    zIndex: 1,
    backgroundColor: color.background + "66",
  },

  secondaryData: {
    color: color.text,
    alignSelf: "flex-start",
    zIndex: 1,
    backgroundColor: color.background + "66",
  },
});
