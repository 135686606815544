// eslint-disable-next-line
import dayjs, { Dayjs } from "dayjs";
import React from "react";
import { Animated, Platform, StyleSheet, TouchableOpacity, View } from "react-native";
import { useMutation } from "@apollo/react-hooks";
import { Chip, FAB, Portal } from "react-native-paper";
import SendSMS from "react-native-sms";
import { useNavigation } from "@react-navigation/native";

import { Gender, LocationInput } from "libs/types/API";
import { GET_ASSESSMENT, CREATE_CONTACTED_DOCTORS } from "@/models/Assessment";
import { TypographyType } from "@/models/Typography";
import { RouteKeys } from "@/navigationv2";
import { color, fontSizes, spacing, timing } from "../../theme";
import { recordEvent } from "../../utils/analytics";
import { ContentWrap } from "../ContentWrap";
import Icon from "../Icon";
import Text from "../Text";
import { AnalyticsEvent } from "libs/analytics/events";

import { useSetRecoilState, useRecoilValue } from "recoil";
import { mapCriteria, userDetails } from "../../utils/recoil/index";
import { SearchResultsLoading, SearchResultList } from "./SearchResultList";
import { CreateContactedDoctorsFunc, SearchResultItem } from "./SearchResults.props";
import { AllAMHPRoutes } from "@/navigationv2/types";
import { StackNavigationProp } from "@react-navigation/stack";
import { callbackWithThenable } from "@/utils/helpers";

const { useState } = React;

const recordDoctorsContacted = (
  selectedDoctors: SearchResultItem[],
  assessmentId: string,
  saveContactedDoctors: CreateContactedDoctorsFunc,
  user: { email: string; id: string; name: string }
) => {
  // Save contacted doctors, then record the event
  const numberSelectedDoctors = selectedDoctors.length;
  const refetchElement = numberSelectedDoctors > 1 ? selectedDoctors.pop() : selectedDoctors[0];

  if (numberSelectedDoctors > 1) {
    selectedDoctors.map((doctor: SearchResultItem) => {
      saveContactedDoctors({
        variables: {
          input: {
            assessmentId: assessmentId,
            contactedDoctorS12DoctorId: doctor.id,
            createdAt: new Date().toISOString(),
          },
        },
      });
    });
  }

  if (refetchElement) {
    saveContactedDoctors({
      variables: {
        input: {
          assessmentId: assessmentId,
          contactedDoctorS12DoctorId: refetchElement.id,
          createdAt: new Date().toISOString(),
        },
      },
      refetchQueries: [{ query: GET_ASSESSMENT, variables: { id: assessmentId } }],
    });
  }

  selectedDoctors.forEach(dr => {
    recordEvent(
      AnalyticsEvent.DOCTORS_CONTACTED,
      {
        ...(dr.name && { doctorName: dr.name }),
        ...(user && {
          amhpId: user.id,
          amhpName: user.name,
          amhpEmail: user.email,
        }),
        ...(dr.nextAvailability?.availability.type && {
          doctorAvailability: dr.nextAvailability?.availability.type,
        }),
        assessmentId,
        doctorId: dr.id,
      },
      {
        "Doctors Contacted": 1,
      }
    );
  });
};

function sendMultiSMSOnNative(
  selectedDoctors: SearchResultItem[],
  assessmentId: string,
  saveContactedDoctors: CreateContactedDoctorsFunc,
  user: { id: string; name: string; email: string }
) {
  const recipients: string[] = selectedDoctors.map(x => x.phone).filter(x => !!x) as string[]; // Make sure there are no nulls
  SendSMS.send(
    {
      body: "Please reply to this message and include your name.",
      recipients,
      successTypes: ["all"],
      allowAndroidSendWithoutReadPermission: true,
    } as any, // hack to get around react-native-sms having a bad type definition
    (): void => {
      // Cannot detect if sms sucessfully sent, make assumption it has - michael
      recordDoctorsContacted(selectedDoctors, assessmentId, saveContactedDoctors, user);
    }
  );
}

function toggleSelected(
  state: SearchResultItem[],
  setState: (item: SearchResultItem[]) => void,
  item: SearchResultItem
) {
  const index = state.findIndex(x => x.id === item.id);
  if (index === -1 && item.id !== undefined) {
    setState([...state, item]);
  } else {
    setState(state.filter(d => d.id !== item.id));
  }
}

function SeachParamChip(props: { children: React.ReactNode }) {
  return (
    <Chip mode={"flat"} style={styles.chip} textStyle={styles.chipText}>
      {props.children}
    </Chip>
  );
}

function chipTimespan(start: Dayjs, end: Dayjs) {
  return `${start.date() !== dayjs().date() ? start.format("DD/MM") : ""} ${start.format("HH:00")} to ${
    end.date() !== start.date() ? end.format("DD/MM HH:00") : end.format("HH:00")
  }`;
}

function SearchResults(props: {
  isLoading?: boolean;
  assessment?: {
    id: string;
    doctors?: { id: string }[];
    doctorVisits?: { id: string; doctor: { id: string } }[];
  };
  results?: SearchResultItem[] | null;
  searchCriteria: {
    locationName: string;
    location: LocationInput;
    timeSpan: {
      start: Dayjs;
      end: Dayjs;
    };
    distance: number;
    doctorGender?: Gender | null;
    doctorLanguages?: string[];
    doctorSpecialties?: string[];
    doctorName?: string;
  };
}) {
  const navigation = useNavigation<StackNavigationProp<AllAMHPRoutes>>();
  const [selectedItems, setSelectedItems] = useState<SearchResultItem[]>([]);
  const [loadingBoxOpacity] = useState(new Animated.Value(0.3));
  const [resultsBoxOpacity] = useState(new Animated.Value(0.05));
  const [hideLoading, setHideLoading] = useState(false);
  const [onPressAction, setOnPressAction] = useState("navigate");
  const [createContactedDoctors] = useMutation(CREATE_CONTACTED_DOCTORS);

  const setMapCriteria = useSetRecoilState(mapCriteria);
  const user = useRecoilValue(userDetails);

  const filteredResults = props.results;

  React.useEffect(() => {
    if (!hideLoading && !props.isLoading) {
      Animated.parallel([
        Animated.timing(loadingBoxOpacity, {
          toValue: 0.05,
          duration: timing.quick,
          useNativeDriver: Platform.OS !== "web",
        }),
        Animated.timing(resultsBoxOpacity, {
          toValue: 1,
          duration: timing.quick,
          useNativeDriver: Platform.OS !== "web",
        }),
      ]).start(() => setHideLoading(true));
    }
  }, [props.isLoading]);

  const { timeSpan, distance, locationName, location } = props.searchCriteria;
  const [fabGroupOpen, setFabGroupOpen] = useState(false);
  const [fabGroupVisible, setFabGroupVisible] = useState(true);

  const asyncMapCriteriaUpdate = callbackWithThenable(
    () => {
      const params = {
        locationLat: location.lat,
        locationLon: location.lon,
        distance: distance || 10,
        assessmentId: (props.assessment && props.assessment.id) || "",
      };
      setMapCriteria(params);
    },
    [props, location, distance],
    () => navigation.navigate(RouteKeys.MapScreen)
  );

  const fabGroupActions = [
    {
      icon: "forum",
      label: "Send Multi-Text",
      color: color.textWhite,
      style: styles.greenMiniFab,
      onPress: () => {
        onPressAction === "navigate" ? setOnPressAction("sms") : setOnPressAction("navigate");
        setSelectedItems([]);
        setFabGroupVisible(false);
      },
    },
    ...(Platform.OS === "web"
      ? [
          {
            icon: "map",
            label: "View Results on Map",
            color: color.textWhite,
            style: styles.blueMiniFab,
            onPress: () => asyncMapCriteriaUpdate(),
          },
        ]
      : []),
  ];

  return (
    <>
      {filteredResults && filteredResults.length > 0 && (
        <Portal>
          <FAB.Group
            open={fabGroupOpen}
            icon={fabGroupOpen ? "close" : "more-vert"}
            fabStyle={styles.fabStyles}
            color={color.text}
            visible={fabGroupVisible}
            actions={Platform.OS !== "web" ? fabGroupActions : [fabGroupActions[1]]}
            onStateChange={({ open }) => setFabGroupOpen(open)}
          />
          <FAB
            visible={onPressAction === "sms"}
            icon="clear"
            label="Cancel"
            color={color.textWhite}
            style={styles.multiTextFabCancel}
            onPress={() => {
              setSelectedItems([]);
              setOnPressAction("navigate");
              setFabGroupVisible(true);
            }}
          />
          <FAB
            visible={!fabGroupVisible}
            icon="send"
            label="Send Texts"
            color={selectedItems.length === 0 ? color.textExtraLight : color.text}
            style={[styles.multiTextFabSend, selectedItems.length === 0 && styles.multiTextFabSendDisabled]}
            disabled={selectedItems.length === 0}
            onPress={() =>
              sendMultiSMSOnNative(
                selectedItems,
                props.assessment ? props.assessment.id : "",
                createContactedDoctors,
                user || { id: "unknown", email: "unknown", name: "unknown" }
              )
            }
          />
        </Portal>
      )}
      <ContentWrap>
        <View style={styles.chipsHeading}>
          <Icon name="filter-list" style={styles.chipsHeadingIcon} />
          <Text format={TypographyType.SmallBold}>Filters</Text>
        </View>
        <TouchableOpacity style={styles.chipsContainer} onPress={() => navigation.goBack()} activeOpacity={1}>
          <SeachParamChip>{locationName}</SeachParamChip>
          <SeachParamChip>{chipTimespan(timeSpan.start, timeSpan.end)}</SeachParamChip>
          <SeachParamChip>{`${distance} Miles`}</SeachParamChip>
          {props.searchCriteria.doctorLanguages && props.searchCriteria.doctorLanguages.length > 0 && (
            <SeachParamChip>{`${props.searchCriteria.doctorLanguages.join(", ")}`}</SeachParamChip>
          )}
          {props.searchCriteria.doctorSpecialties && props.searchCriteria.doctorSpecialties.length > 0 && (
            <SeachParamChip>{`${props.searchCriteria.doctorSpecialties.join(", ")}`}</SeachParamChip>
          )}
          {props.searchCriteria.doctorGender && props.searchCriteria.doctorGender.length > 0 && (
            <SeachParamChip>
              {["M", "T-M"].indexOf(props.searchCriteria.doctorGender) > -1 ? "Male" : "Female"}
            </SeachParamChip>
          )}
          {!!props.searchCriteria.doctorName && (
            <SeachParamChip>{`Doctor Name: ${props.searchCriteria.doctorName}`}</SeachParamChip>
          )}
        </TouchableOpacity>
      </ContentWrap>

      <SearchResultList
        results={filteredResults}
        assessmentId={props.assessment && props.assessment.id}
        assessmentDistance={distance}
        assessmentDoctorIds={props.assessment && props.assessment.doctors && props.assessment.doctors.map(d => d.id)}
        assessmentDoctorVisit={props.assessment && props.assessment.doctorVisits}
        timeSpan={props.searchCriteria.timeSpan}
        toggleSelected={toggleSelected.bind(null, selectedItems, setSelectedItems)}
        onPressAction={onPressAction}
        selectedItems={selectedItems}
        mainLocation={props.searchCriteria.location}
        ignoreDefaultSort={!!props.searchCriteria.doctorName}
      />

      {/* TODO: LOADING & MODAL COMPONENTS - TO BE RE-STYLED */}
      {!hideLoading && (
        <Animated.View
          style={[
            styles.loadingSearchResults,
            {
              opacity: loadingBoxOpacity,
            },
          ]}
        >
          <SearchResultsLoading />
        </Animated.View>
      )}
    </>
  );
}

export default SearchResults;

const styles = StyleSheet.create({
  chipsContainer: {
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "flex-start",
  },

  chipsHeading: {
    marginBottom: spacing[20],
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  chipsHeadingIcon: {
    marginRight: spacing[10],
    color: color.textExtraLight,
  },

  chip: {
    marginRight: spacing[10],
    marginBottom: spacing[10],
    backgroundColor: color.backgroundGrey,
    borderRadius: 5,
  },
  chipText: {
    color: color.textLight,
    fontSize: fontSizes.tiny,
  },

  fabStyles: {
    backgroundColor: color.doctor,
  },
  greenMiniFab: { backgroundColor: color.secondary },
  blueMiniFab: { backgroundColor: color.primary },

  loadingSearchResults: {
    position: "absolute",
    backgroundColor: color.backgroundGrey,
    top: 222,
    left: spacing[15],
    right: spacing[15],
  },

  modalInner: {
    flex: 1,
    alignContent: "center",
    paddingHorizontal: spacing[15],
  },

  multiTextFabSend: {
    position: "absolute",
    right: 20,
    bottom: 20,
    backgroundColor: color.inAssessment,
  },
  multiTextFabSendDisabled: {
    backgroundColor: color.inAssessmentBorder,
  },

  multiTextFabCancel: {
    position: "absolute",
    right: 20,
    bottom: 80,
    backgroundColor: color.primary,
  },
});
