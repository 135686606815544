import React from "react";
import Svg, { Path } from "react-native-svg";

export const ContainIcon: React.FC<{ size: number; color?: string }> = ({ size = 45, color = "#FFFFFF" }) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <Path d="M3,4.4L4.4,3l4.5,4.5V3.9h2v7h-7v-2h3.6" fill={color} />
    <Path d="M19.6,3L21,4.4l-4.5,4.5h3.6v2h-7v-7h2v3.6" fill={color} />
    <Path d="M21,19.6L19.6,21l-4.5-4.5v3.6h-2v-7h7v2h-3.6" fill={color} />
    <Path d="M4.4,21L3,19.6l4.5-4.5H3.9v-2h7v7h-2v-3.6" fill={color} />
  </Svg>
);
