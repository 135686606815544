import { VideoTileState } from "amazon-chime-sdk-js";
import { Notification } from "@/models/MeetingRoom";

export interface TileState {
  attendeeId: string;
  userId?: string;
  present?: boolean;
  userDisplayName?: string;
  isMuted?: boolean;
  // boundVideoElement?: React.MutableRefObject<HTMLVideoElement>;
  // boundVideoStream?: { active: boolean };
  audioLevel?: number; // 0 to 1
  audioActive?: boolean; // if active, user is speaking
  connectionQuality?: "poor" | "average" | "good";
  tileState?: VideoTileState;
  videoElement?: React.MutableRefObject<HTMLVideoElement> & {
    current?: { videoWidth: number; videoHeight: number };
  };
  permissions?: {
    microphone?: number;
    video?: number;
    audio?: number;
  };
  hideVideo?: boolean;
}

export interface State {
  centreTile: "tile2" | "tile3" | "tile4";
  assessment: AssessmentDetails;
  self: TileState;
  roster: TileState[];
  suspendedVideoItems: TileState[];
  notifications: Notification[];
  sessionTimedOut: boolean;
}

export interface S12HTMLVideoElement extends HTMLVideoElement {
  videoWidth: number;
  videoHeight: number;
}

export interface AssessmentDetails {
  id: string;
  patientName: string;
  patientNhsNumber?: string;
  amhpTeam: {
    id: string;
    name: string;
    featureFlags: string;
  };
  amhp: {
    id: string;
    name: string;
  };
  doctors: Array<{
    id: string;
    name: string;
    notes?: string | null;
  }>;
}

export interface ControlBarMeetingDetails {
  userId?: string;
  userDisplayName?: string;
  attendees: {
    attendeeId: string;
    userDisplayName?: string;
    userId?: string;
  }[];
}

export enum TileName {
  tile1 = "tile1",
  tile2 = "tile2",
  tile3 = "tile3",
  tile4 = "tile4",
}

export enum TilePosition {
  self = "self",
  center = "center",
  bottom = "bottom",
  left = "left",
}

export enum MediaDeviceType {
  audioBluetooth = "audioBluetooth",
  audioBuiltInSpeaker = "audioBuiltInSpeaker",
  audioHandset = "audioHandset",
  audioWiredHeadset = "audioWiredHeadset",
  videoBackCamera = "videoBackCamera",
  videoFrontCamera = "videoFrontCamera",
  other = "other",
}

export interface MediaDevice {
  description: string;
  label: string;
  type: MediaDeviceType;
}

export enum VolumeLevel {
  muted = -1,
  notSpeaking = 0,
  low = 1,
  medium = 2,
  high = 3,
}

export interface VolumeUpdate {
  attendeeId: string;
  description: string;
  volumeLevel: VolumeLevel;
}

export type AttendeeVideoTileState = {
  attendeeId?: string;
  userDisplayName?: string;
  hideVideo?: boolean;
  userId?: string;
  tileId?: number | null;
  isLocalTile?: boolean;
  isMuted?: boolean;
  audioActive?: boolean;
  videoStreamContentHeight?: number;
  videoStreamContentWidth?: number;
  volumeLevel?: number;
};

export type SDKEventAttendee = {
  attendeeId: string;
  externalUserId: string;
};

export interface MeetingAttendees {
  [key: string]: User;
}

export type User = SDKEventAttendee & { tileState?: AttendeeVideoTileState };
export type UserNotification = { userId: string; type: "joined" | "left"; id: number };
