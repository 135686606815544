import ApolloClient, { InMemoryCache } from "apollo-boost";
import Config from "../config";
import Auth from "@aws-amplify/auth";

// import { Hermes } from "apollo-cache-hermes";
import { UserDetails } from "../utils/recoil/props";
import demoEnvironment from "../utils/demoEnvironment";
import { S12Error } from "../models/Error";
import * as Sentry from "@sentry/core";
import { Severity } from "@sentry/types";

type SetUser = (arg0: UserDetails | null) => void;

export function buildApolloClient(setUser: SetUser, isDemo = false): ApolloClient<Record<string, unknown>> {
  const { AWS_APPSYNC_GRAPHQLENDPOINT } = isDemo ? demoEnvironment : Config;

  const client = new ApolloClient<Record<string, unknown>>({
    uri: AWS_APPSYNC_GRAPHQLENDPOINT,
    fetchOptions: {
      credentials: "include",
    },
    cache: new InMemoryCache(), // new Hermes(),
    request: async operation => {
      if (operation.variables) {
        const omitTypename = (key: string, value: any) => (key === "__typename" ? undefined : value);
        const variables = JSON.parse(JSON.stringify(operation.variables), omitTypename);

        operation.setContext({
          variables,
        });
        operation.variables = variables;
      }
      Sentry.addBreadcrumb({
        type: "GQL Request",
        level: Severity.Info,
        category: "Network",
        message: operation.operationName,
        timestamp: new Date().valueOf(),
        data: operation.variables && operation.variables.id ? { id: operation.variables.id } : undefined,
      });

      try {
        // this method will refresh tokens if needed / possible
        const session = await Auth.currentSession();
        const token = session.getIdToken().getJwtToken();
        const userId = session.getIdToken().payload["cognito:username"] as string | undefined;
        if (!token || !token.length) {
          // stop processing request
          throw new Error("unauthenticated");
        }

        operation.setContext({
          headers: {
            authorization: token,
            ...(userId && { "x-gql-username": userId }),
          },
        });
      } catch (error) {
        // no network
        Sentry.addBreadcrumb(new S12Error(new Error("Failed to get or refresh session token")));
        if (error.code === "NetworkError" || error.message === "Network error") {
          const { cache } = operation.getContext();
          cache && cache.writeData({ data: { isConnected: false } });
        } else {
          setUser(null);
          // stop processing request
          throw new Error("unauthenticated");
        }
      }
    },
    onError: ({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.map(graphQLError => {
          Sentry.addBreadcrumb(new S12Error(graphQLError));
        });
      }
      if (networkError && Object.prototype.hasOwnProperty.call(networkError, "statusCode")) {
        const err: { statusCode: number } = networkError as {
          statusCode: number;
        };
        if (err.statusCode === 401) {
          Auth.signOut().then(() => setUser(null));
        } else if (err.statusCode === 403) {
          Auth.signOut().then(() => setUser(null));
          // TODO: Show forbidden render into view
        }

        if (err.statusCode !== 0 && err.statusCode !== 401) {
          Sentry.captureException(new S12Error(networkError));
        }
      }
    },
    /*
    clientState: {
      defaults: {
        isConnected: true
      },
      resolvers: {
        Mutation: {
          updateNetworkStatus: (_, { isConnected }, { cache }) => {
            // cache.writeData({ data: { isConnected } });
            return null;
          },
          updateJWT: (_, { jwt }, { cache }) => {
            // cache.writeData({ data: { jwt } });
            return null;
          }
        }
      }
    }
    */
  });

  client.defaultOptions = {
    watchQuery: {
      fetchPolicy: "cache-and-network",
      errorPolicy: "all",
    },
    query: {
      fetchPolicy: "network-only",
    },
  };

  return client;
}
