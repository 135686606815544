import gql from "graphql-tag";
import React, { useMemo, useRef, useState } from "react";
import { Query } from "react-apollo";
import { GraphQLError } from "graphql";
import { useRecoilValue } from "recoil";
import { amhpTeamVideoMeetings } from "libs/utils/featureFlags";
import { userDetails } from "@/utils/recoil/index";
import Loading from "../Loading";
import { ErrorMessage } from "../Error";
import MeetingRoom, { MeetingRoomProps } from "./MeetingRoom";

const INITIAL_RETRY_DELAY = 250; // number of ms to begin exponential backoff retries to join meeting
const MAX_RETRIES = 5; // maximum number of times to attempt joining a meeting before we throw an error and let the user retry manually

// we start with doctor visits because doctors and amhps can both read it
// then we go to the assessment, which they can all read
// then we fetch the visits again... because we are going from assessment -> visit, doctors will have permission
// to read other doctor's info.
const query = gql`
  query GetAssessment($id: ID!) {
    joinMeeting: joinMeeting(assessmentId: $id)
    visits: visitsByAssessment(assessmentId: $id) {
      items {
        id
        team {
          id
          name
          featureFlags
        }
        assessment {
          id
          patientName
          patientNhsNumber
          id
          amhp {
            id
            name
            email
            phone
          }
          doctorVisits {
            items {
              id
              doctorVisitDoctorId
              notes
              doctor {
                id
                name
                email
                phone
              }
            }
          }
        }
      }
    }
  }
`;

type MeetingRoomQuery = {
  joinMeeting: string;
  visits: {
    items: Array<{
      id: string;
      team: {
        id: string;
        name: string;
        featureFlags: string;
      };
      assessment: {
        id: string;
        patientName: string;
        patientNhsNumber?: string;
        amhp: {
          id: string;
          name: string;
          email: string;
          phone: string;
        };
        doctorVisits: {
          items: Array<{
            id: string;
            doctorVisitDoctorId: string;
            notes: string;
            doctor: {
              id: string;
              name: string;
              phone: string | null;
              email: string;
            };
          } | null> | null;
        } | null;
      };
    } | null> | null;
  } | null;
};

export default function MeetingRoomGQL(props: { assessmentId?: string }) {
  const user = useRecoilValue(userDetails);
  // caching for the join meeting.
  const joinMeetingDetails = useRef<null | any>(null);
  const gqlResult = useRef<string | null>(null);
  const [retriesRemaining, setRetriesRemaining] = useState(MAX_RETRIES);
  // cache the variables
  const variables = useMemo(
    () => ({
      id: props.assessmentId,
    }),
    [props.assessmentId]
  );
  if (!props.assessmentId) {
    return <Loading />;
  }

  return (
    <Query<MeetingRoomQuery> query={query} variables={variables}>
      {({ error, data, loading, refetch }) => {
        if (error) {
          if (error.graphQLErrors) {
            const rateExceeded = error.graphQLErrors.find(
              (error: GraphQLError & { errorType: string }) => error.errorType === "Lambda:TooManyRequestsException"
            );
            if (rateExceeded && retriesRemaining > 0) {
              setTimeout(() => {
                setRetriesRemaining(retriesRemaining - 1);
                refetch(variables);
              }, INITIAL_RETRY_DELAY * (MAX_RETRIES - (retriesRemaining - 1)));
              return <Loading />;
            }
          }
          return <ErrorMessage apolloError={error} />;
        } else if (loading) {
          return <Loading />;
        } else if (data && data.visits) {
          gqlResult.current = data.joinMeeting;

          const { visits, joinMeeting } = data;

          // handle changes to the response vs. the cache
          if (!joinMeetingDetails.current || (gqlResult.current && gqlResult.current !== data.joinMeeting)) {
            joinMeetingDetails.current = JSON.parse(joinMeeting);
          }

          const assessmentDetails = cleanAssessment(visits);
          if (!amhpTeamVideoMeetings(assessmentDetails.amhpTeam.featureFlags)) {
            return (
              <ErrorMessage
                message={`The AMHP team: ${assessmentDetails.amhpTeam.name} does not support video meetings`}
              />
            );
          }
          if (!assessmentDetails.doctors) {
            return <ErrorMessage message="No Doctor visits scheduled for assessment" />;
          }
          return (
            <MeetingRoom
              assessmentDetails={assessmentDetails}
              meetingDetails={joinMeetingDetails.current.meeting}
              attendeeDetails={joinMeetingDetails.current.attendee}
              localUserId={user?.id || ""}
            />
          );
        } else {
          return <Loading />;
        }
      }}
    </Query>
  );
}

function cleanAssessment(visits: MeetingRoomQuery["visits"]): MeetingRoomProps["assessmentDetails"] {
  return visits!.items!.filter(notEmpty).reduce(
    (acc, curr) => ({
      doctors:
        acc.doctors ||
        curr.assessment.doctorVisits?.items?.filter(notEmpty).map(dv => ({
          ...dv?.doctor,
          notes: dv?.notes ? dv.notes[0] : null,
        })) ||
        [],
      id: curr.assessment.id,
      amhp: curr.assessment.amhp,
      amhpTeam: curr.team,
      patientName: curr.assessment.patientName,
      patientNhsNumber: curr.assessment.patientNhsNumber,
    }),
    {} as MeetingRoomProps["assessmentDetails"]
  );
}

function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && typeof value !== "undefined";
}
