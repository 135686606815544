/* eslint-disable complexity */
import React, { useRef, useState, useEffect, useCallback, useMemo } from "react";
import dayjs from "dayjs";
import { StyleSheet, View, Platform, Linking } from "react-native";
import { useMutation } from "@apollo/react-hooks";
import API from "@aws-amplify/api";
import APIV2 from "@/api";
import Icon from "../Icon";

import { AnalyticsEvent } from "libs/analytics/events";
import { LONG_DATE_FORMAT } from "libs/dates";
import {
  ClaimDataInput,
  ClaimStatus,
  Engine,
  FuelType,
  UpdateClaimInput,
  UpdateS12DoctorInput,
  InvoiceDataInput,
  DoctorConfirmation,
  DoctorEmployedStatusValues,
} from "libs/types/API";

import { DoctorClaimDetail } from "libs/types/claim";
import { useAPIVersion, useBottomSheet, useDialog } from "@/hooks";
import { RadioButton, RadioButtonGroup } from "../Radio/Radio";
import { CLAIM_QUERY_FOR_DOCTOR, DELETE_CLAIM, UPDATE_CLAIM } from "@/models/Claim";
import { GET_DOCTOR_CLAIMS_BY_STATUS, UPDATE_DOCTOR_PROFILE } from "@/models/DoctorProfile";
import { buildErr, cancelClaimError, S12Error, updateClaimError } from "@/models/Error";
import { GQL_LIMITS } from "@/models/gql/limits";
import { TypographyType } from "@/models/Typography";

import { RouteKeys } from "@/navigationv2";
import { color, spacing } from "@/theme";
import { recordEvent } from "@/utils/analytics";
import {
  checkMutationInput,
  formatClaimStatus,
  formatFuelType,
  mqWeb,
  fuelTypeDropdownOptions,
  generateEngineSizeDropdownOptions,
  formatPostcode,
} from "@/utils/helpers";
import { Button } from "../Button";
import { Checkbox } from "../Checkbox/Checkbox";
import ClaimContact from "../ClaimContact";
import ClaimHistory from "../ClaimHistory";
import { ContentWrap } from "../ContentWrap";
import { DetailsSection } from "../DetailsSection";
import { SectionItem } from "../DetailsSection/DetailsSection";
import { UpdateDoctorFunc } from "../DoctorSetupForm/DoctorSetupForm.props";
import { SectionDivider } from "../SectionDivider/SectionDivider";
import Select from "../Select/Select";
import Text from "../Text";
import { TextInput } from "../TextInput/TextInput";
import { DeleteClaimFunc, UpdateClaimFunc } from "./Claim.props";
import {
  hasMileage,
  hasAdditionalExpenses,
  ccgRequiresDoctorToInvoice,
  shouldProvideVehicleInformation,
  ccgRequiresBillingInformation,
  ccgRequiresLineManager,
  ccgRequiresGmcNumber,
  ccgDoesNotAcceptHospitalAssessments,
  ccgRequiresDoctorAdditionalConfirmation,
  mhtAndLineManager,
  doctorFeesMileageWithoutInvoice,
  ccgRequiresDoctorEmployedStatus,
  ccgRequiresDoctorMhtAssociations,
} from "libs/utils/featureFlags";

import Auth from "@aws-amplify/auth";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { userDetails, snackbarMessage, lastException } from "../../utils/recoil";
import { formatAddress } from "libs/formatters/address";
import BillingInformation from "./BillingInformation";

import BottomSheets from "./BottomSheets";
import { ParamListBase, useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { calculateInvoiceTotals } from "libs/utils/invoicing";
import shouldDisableSubmit from "./shouldDisableSubmit";
import { Button as TextButton } from "react-native-paper";
import { ContactBox } from "../InfoBox";
import { Email as SquareEmailButton } from "../SquareButton";
import { API_V2_TOGGLES } from "@/api/types";

interface EngineSize {
  value: Engine;
  label: string;
}

interface FuelTypeSelection {
  value: FuelType;
  label: string;
}

type BillingInformationRef = React.ElementRef<typeof BillingInformation>;

interface PropTypes {
  claim: DoctorClaimDetail;
  mhts: { id: string; abbreviation: string }[];
}

const generateInvoice = async (
  useV2: boolean,
  claim: DoctorClaimDetail,
  fileName: string,
  isMobileDevice: boolean,
  startCallback: () => void,
  successCallback: () => void,
  failureCallback: (error: Error) => void
) => {
  startCallback();
  const body = {
    claim,
    isMobileDevice,
  };
  let generateInvoicePromise;
  if (useV2) {
    generateInvoicePromise = () => APIV2.post("/invoice", body);
  } else {
    const token = (await Auth.currentSession()).getIdToken().getJwtToken();
    generateInvoicePromise = () =>
      API.post("generateInvoice", "/", {
        body: {
          ...body,
          token,
        },
      });
  }

  generateInvoicePromise()
    .then(response => {
      if ("errorMessage" in response) {
        // This isnt great but unfortunately consistent with rest of app
        // Review when revisting our default lambda approaches
        failureCallback(new Error(response.errorMessage));
        return;
      }
      if (isMobileDevice) {
        // Show the success message
        successCallback();
      } else {
        const base64res = response.body || response.data.body;

        Linking.canOpenURL(`data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64res}`)
          .then(() => {
            const xlsxContent = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64res}`;
            // @ts-ignore-start
            if (typeof document !== "undefined") {
              const encodedUri = encodeURI(xlsxContent);
              // @ts-ignore
              const link = document.createElement("a");
              link.setAttribute("href", encodedUri);
              link.setAttribute("download", `${fileName}-invoice.xlsx`);
              // @ts-ignore
              document.body.appendChild(link); // Required for FF
              // @ts-ignore-end
              link.click();
              successCallback();
            } else {
              throw new Error("Unable to generate file");
            }
          })
          .catch((error: Error) => {
            /**
             * There was an error in creating the invoice,
             * or the user cannot open this type of URL
             *  */
            failureCallback(error);
          });
      }
    })
    .catch((error: Error) => {
      failureCallback(error);
    });
};

// TODO: This has gotten complex enough to require a UI reducer, and a logic reducer.
export const ClaimDetails = (props: PropTypes) => {
  const { claim, mhts } = props;
  const initialMHTEmployers =
    (claim.doctor.mhtEmployers && claim.doctor.mhtEmployers.items.map(item => item.mht.abbreviation)) || [];
  let mhtOptions: { id: string; name: string }[] = [];
  if (claim.doctor.mhtEmployers) {
    mhtOptions = claim.doctor.mhtEmployers.items.map(m => {
      return { id: m.mht.id, name: m.mht.abbreviation };
    });
  }

  const { openBottomSheet, closeBottomSheet } = useBottomSheet();
  const { openDialog, closeDialog } = useDialog();
  const navigation = useNavigation<StackNavigationProp<ParamListBase>>();

  const [updateClaim] = useMutation<UpdateClaimFunc>(UPDATE_CLAIM);
  const [deleteClaim] = useMutation<DeleteClaimFunc>(DELETE_CLAIM);

  const { v2 } = useAPIVersion(API_V2_TOGGLES.INVOICE);

  const milesTravelledInput = useRef<any>();
  const billingInformationRef = useRef<BillingInformationRef>(null);
  const [startingPostcode, setStartingPostcode] = useState<string>(claim.startingPostcode || "");
  const [additionalExpenses, setAdditionalExpenses] = useState(claim.additionalExpenses || "0.00");
  const [additionalInformation, setAdditionalInformation] = useState(claim.additionalInformation || "");
  const [lineManager, setLineManager] = useState(claim.lineManager || claim.doctor?.lineManager || "");
  const [profileDataMHTEmployers, setProfileDataMHTEmployers] = useState(initialMHTEmployers);
  const [distanceTravelled, setDistanceTravelled] = useState(claim.mileage || "0.00");
  const [mileageDisclaimer, setMileageDisclaimer] = useState(false);
  const [employedBySWYorkshire, setEmployedBySWYorkshire] = useState<boolean | null>(false);
  const [isIndependentDoctor, setIsIndependentDoctor] = useState(claim.isIndependentDoctor || false);
  const [doctorEmployedStatus, setDoctorEmployedStatus] = useState<DoctorEmployedStatusValues | null>(
    claim.doctorEmployedStatus || null
  );
  const [doctorAdditionalConf, setDoctorAdditionalConf] = useState<DoctorConfirmation | null>(
    claim.doctorAdditionalConf || null
  );
  const [postcodeError, setPostcodeError] = useState(false);
  const [distanceError, setDistanceError] = useState(false);
  const [additionalExpensesError, setAdditionalExpensesError] = useState(false);
  const [lineManagerError, setLineManagerError] = useState(false);
  const [invoiceDownloadError, setInvoiceDownloadError] = useState<string | null>(null);
  const [invoiceData, setInvoiceData] = useState<InvoiceDataInput>({
    locationOfAssessment: claim.visit?.assessment?.locationName
      ? formatPostcode(claim.visit.assessment.locationName.postcode)
      : "",
    assessmentFee: claim.invoiceData?.assessmentFee || "0.00",
    travelFee: claim.invoiceData?.travelFee || "0.00",
  });

  const [patientNotInpatient, setPatientNotInpatient] = useState(false); // used only for the CCG_DOES_NOT_ACCEPT_HOSPITAL_ASSESSMENTS check
  const [assessmentWasNotAtHospital, setAssessmentWasNotAtHospital] = useState(false); // used only for the CCG_DOES_NOT_ACCEPT_HOSPITAL_ASSESSMENTS check

  let defaultMHT: { id: string; abbreviation: string } = { id: "", abbreviation: "" };
  if (claim.doctor.defaultMHT) {
    const currentMHT = mhts.find(m => m.abbreviation === claim.doctor.defaultMHT);
    if (currentMHT) {
      defaultMHT = currentMHT;
    }
  } else {
    // The default MHT is saved on the doctor schema and is used to auto-select the MHT from
    // the list of MHTs attached to the doctor. Consequently, when we save an MHT on the claim, we
    // update this on the doctor record as the defaultMHT.
    // However, the first time the doctor saves a claim, where the MHT is required, there will be no
    // default MHT. In this case, we need to select the first MHT and allow the doctor to change this
    // if required. If the MHT is required, there are no MHTs on the doctor's profile and they are not,
    // an independent doctor, then the doctor needs to add MHTs in their profile before the claim can
    // be saved.
    if (claim.doctor.mhtEmployers?.items?.length) defaultMHT = { ...claim.doctor.mhtEmployers.items[0].mht };
  }

  const [selectedMHT, setSelectedMHT] = useState<{ id: string; abbreviation: string }>({
    ...defaultMHT,
  });

  const isWebView = mqWeb();
  const isMobileDevice = Platform.OS !== "web";

  // Merged Claim is created with doctor claim data first
  // so that if any previously entered claim Data has been entered
  // the items from the already completed form, are inherited to be displayed

  // TODO: if we are merging these together and using them, we should not reference claim.doctor.claimData or
  // claim.claimData after this point. Currently claim.doctor.claimData is being used as a test for valid data
  // but it is modalClaimData that is submitted to the server and should  be used instead
  const mergedClaimData: ClaimDataInput = {
    ...claim.doctor.claimData,
    ...claim.claimData,
  };

  const getInitialFuelType = (mergedClaimData: ClaimDataInput) => {
    if (!mergedClaimData.fuelType) return null;
    const drProfileFuelType =
      mergedClaimData.fuelType &&
      fuelTypeDropdownOptions.find(option => {
        return option.id === mergedClaimData.fuelType;
      });

    if (drProfileFuelType) {
      return {
        value: drProfileFuelType.id,
        label: drProfileFuelType.name,
      } as FuelTypeSelection;
    }
    return null;
  };

  const getInitialEngineSize = (fuelType: FuelTypeSelection | null, options: { name: string; id: string }[]) => {
    if (!fuelType) {
      return null;
    }
    if (!mergedClaimData.engineSize || !options) return null;
    const drProfileEngineSize =
      mergedClaimData.engineSize &&
      options.find(option => {
        return option.id === mergedClaimData.engineSize;
      });

    if (drProfileEngineSize) {
      return {
        value: drProfileEngineSize.id,
        label: drProfileEngineSize.name,
      } as EngineSize;
    }
    return null;
  };

  const initialFuelType = getInitialFuelType(mergedClaimData);
  const initialEngineSizeDropdownOptions = generateEngineSizeDropdownOptions(initialFuelType);

  const [fuelType, setFuelType] = useState<FuelTypeSelection | null>(initialFuelType);

  const [engineSize, setEngineSize] = useState<EngineSize | null>(
    getInitialEngineSize(initialFuelType, initialEngineSizeDropdownOptions)
  );

  const [submitted, setSubmitted] = useState(false);
  const { locationName, assessmentDate } = claim.visit.assessment;
  const user = useRecoilValue(userDetails);
  const setMessage = useSetRecoilState(snackbarMessage);
  const setLastException = useSetRecoilState(lastException);

  const mileageAllowed = hasMileage(claim.organisation.featureFlags);
  const additionExpensesAllowed = hasAdditionalExpenses(claim.organisation.featureFlags);
  const shouldDoctorSendInvoiceToCCG = ccgRequiresDoctorToInvoice(claim.organisation.featureFlags);
  const showFeesMileageWithoutInvoice = doctorFeesMileageWithoutInvoice(claim.organisation.featureFlags);
  const mustProvideBillingInformation = ccgRequiresBillingInformation(claim.organisation.featureFlags);
  const ccgRequestsLineManager = ccgRequiresLineManager(claim.organisation.featureFlags);
  const vehicleInformationRequested = shouldProvideVehicleInformation(claim.organisation.featureFlags);
  const shouldDisplayDoctorGmcNumber = ccgRequiresGmcNumber(claim.organisation.featureFlags);
  const shouldDisplayAdditionalConf = ccgRequiresDoctorAdditionalConfirmation(claim.organisation.featureFlags);
  const shouldDisplayMhtAndLineManager = mhtAndLineManager(claim.organisation.featureFlags);
  const shouldConfirmAssessmentWasNotAtHospital = ccgDoesNotAcceptHospitalAssessments(claim.organisation.featureFlags);
  const shouldDisplayDoctorEmployedStatus = ccgRequiresDoctorEmployedStatus(claim.organisation.featureFlags);
  const doctorMhtEmployerItems = claim.doctor.mhtEmployers?.items || [];
  const doctorMhtEmployerFeatures = doctorMhtEmployerItems?.map(employer => {
    const {
      mht: {
        organisation: { featureFlags },
      },
    } = employer;
    return featureFlags;
  });

  const [modalClaimData, setModalClaimData] = useState<ClaimDataInput>(mergedClaimData);

  useEffect(() => {
    setModalClaimData(mergedClaimData);
  }, [claim.claimData, claim.doctor.claimData]);

  const [updateDoctor] = useMutation<UpdateDoctorFunc>(UPDATE_DOCTOR_PROFILE);

  // Decline Dialog
  const [showDeclineModal, setShowDeclineModal] = useState(false);
  useEffect(() => {
    if (showDeclineModal) {
      openDialog({
        heading: "Decline Claim",
        message: [`Declining will delete this claim form.`, `Are you sure you want to decline?`],
        confirmButton: {
          label: "Decline Claim",
          onPress: cancelClaim,
        },
        dismissable: true,
        onDismiss: () => setShowDeclineModal(false),
      });
    } else {
      closeDialog();
    }
  }, [showDeclineModal]);

  // Error Dialog
  const [showErrorModal, setShowErrorModal] = useState(false);
  useEffect(() => {
    if (showErrorModal) {
      openDialog({
        heading: "Input Errors",
        message: "Invalid details. Please review and submit.",
        confirmButton: {
          label: "Review Details",
          onPress: () => setShowErrorModal(false),
        },
        dismissable: false,
      });
    } else {
      closeDialog();
    }
  }, [showErrorModal]);

  // Submit BottomSheet
  const [showSubmitBottomSheet, setShowSubmitBottomSheet] = useState<boolean>(false);
  const submitBottomSheetConfirmButton = shouldDoctorSendInvoiceToCCG
    ? {
        label: `${isMobileDevice ? "email" : "download"} invoice & submit`,
        disabled: submitted,
        onPress: async () =>
          generateInvoice(
            v2,
            {
              ...claim,
              claimData: {
                ...modalClaimData,
              },
              additionalExpenses,
              invoiceData,
            },
            claim.displayId,
            isMobileDevice,
            () => setSubmitted(true),
            () => {
              submitClaim(true, isMobileDevice);
              setMessage(
                isMobileDevice ? `Claim successfully emailed to ${claim.doctor.email}` : "Claim successfully generated"
              );
              setSubmitted(false);
            },
            (error: Error) => {
              setLastException(error as S12Error);
              setInvoiceDownloadError(error.message);
              setSubmitted(false);
            }
          ),
      }
    : {
        label: "Submit",
        onPress: () => submitClaim(false),
        disabled: submitted,
      };
  useEffect(() => {
    if (showSubmitBottomSheet) {
      openBottomSheet({
        type: "generic",
        data: {
          heading: "Declaration",
          component: BottomSheets.SubmitClaim,
          componentProps: {
            claim,
            invoiceDownloadError,
            isMobileDevice,
            shouldDoctorSendInvoiceToCCG,
          },
          // TODO:
          // Note that all data referenced in the submit function needs to be in the dependency array.
          // This is problematic and likely to result in bugs. We need a better way to prevent stale daa
          // when using bottom sheet.
          confirmButton: submitBottomSheetConfirmButton,
          onDismiss: () => setShowSubmitBottomSheet(false),
        },
      });
    } else {
      closeBottomSheet();
    }
  }, [
    showSubmitBottomSheet,
    claim,
    invoiceDownloadError,
    isMobileDevice,
    shouldDoctorSendInvoiceToCCG,
    invoiceData,
    modalClaimData,
  ]);

  // Additional Notes BottomSheet
  const [showAdditionalInfoBottomSheet, setShowAdditionalInfoBottomSheet] = useState<boolean>(false);
  useEffect(() => {
    if (showAdditionalInfoBottomSheet) {
      openBottomSheet({
        type: "generic",
        data: {
          heading: "Notes",
          component: BottomSheets.AdditionalInfo,
          componentProps: { claim },
          componentContentWrap: true,
          onDismiss: () => setShowAdditionalInfoBottomSheet(false),
        },
      });
    }
  }, [showAdditionalInfoBottomSheet, claim]);

  useEffect(() => {
    setModalClaimData({
      ...modalClaimData,
      ...(fuelType && { fuelType: fuelType.value }),
      ...(engineSize ? { engineSize: engineSize.value } : { engineSize: null }),
    });
  }, [fuelType, engineSize]);

  const reopenClaim = () => {
    setSubmitted(true);
    updateClaim({
      variables: {
        input: checkMutationInput({
          id: claim.id,
          claimVisitId: claim.visit.id,
          mileage: claim.mileage,
          additionalExpenses: claim.additionalExpenses,
          startingPostcode: claim.startingPostcode,
          additionalInformation: claim.additionalInformation,
          doctorAdditionalConf: claim.doctorAdditionalConf,
          isIndependentDoctor,
          doctorEmployedStatus: claim.doctorEmployedStatus,
          engineSize: claim.engineSize,
          fuelType: claim.fuelType,
          status: ClaimStatus.action_required,
          defaultMHT: selectedMHT.abbreviation,
        }),
      },
      refetchQueries: [
        {
          query: GET_DOCTOR_CLAIMS_BY_STATUS,
          variables: {
            id: user ? user.id : "",
            limit: GQL_LIMITS.doctorClaims,
          },
        },
        {
          query: CLAIM_QUERY_FOR_DOCTOR,
          variables: {
            id: claim.id,
          },
        },
      ],
    })
      .then(() => {
        setMessage("Claim successfully re-opened");
        recordEvent(AnalyticsEvent.CLAIM_REOPENED);
        setSubmitted(false);
      })
      .catch(e => {
        setSubmitted(false);
        buildErr(
          {
            ...updateClaimError,
            additional: claim.id,
          },
          setLastException
        )(e);
      });
  };

  const cancelClaim = () => {
    setSubmitted(true);
    deleteClaim({
      variables: {
        input: {
          id: claim.id,
        },
      },
      refetchQueries: [
        {
          query: GET_DOCTOR_CLAIMS_BY_STATUS,
          variables: {
            id: user ? user.id : "",
            limit: GQL_LIMITS.doctorClaims,
          },
        },
      ],
    })
      .then(() => {
        setShowDeclineModal(false);
        setMessage("Claim successfully cancelled");
        recordEvent(AnalyticsEvent.CLAIM_CANCELLED);
        navigation.navigate(RouteKeys.ClaimsScreen);
      })
      .catch(e => {
        setShowDeclineModal(false);
        setSubmitted(false);
        buildErr(
          {
            ...cancelClaimError,
            additional: claim.id,
          },
          setLastException
        )(e);
      });
  };

  const checkInputValidAndDisplayErrors = () => {
    const distanceValid = !isNaN(Number(distanceTravelled));

    // only check for mileage and postcode when an organisation offers mileage to be paid
    if (hasMileage(claim.organisation.featureFlags)) {
      // when the distance entered is greater than 0, we require a postcode
      if (!startingPostcode && distanceValid && Number(distanceTravelled) > 0) {
        setPostcodeError(true);
        return false;
      }

      const pCode = `${startingPostcode.replace(" ", "").slice(0, -3)} ${startingPostcode.replace(" ", "").slice(-3)}`;
      const postcodeExpression = /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([AZa-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z])))) [0-9][A-Za-z]{2})$/i;
      // if the distance is zero, don't worry about the postcode value (possible bug, should it be set to null)
      const postcodeValid = Number(distanceTravelled) === 0 || postcodeExpression.test(pCode);

      if (!distanceValid || !postcodeValid) {
        !distanceValid && setDistanceError(true);
        !postcodeValid && setPostcodeError(true);
        return false;
      }
    }

    if (additionExpensesAllowed) {
      const expensesValid = !isNaN(Number(additionalExpenses)) && Number(additionalExpenses) >= 0;
      if (!expensesValid) {
        setAdditionalExpensesError(true);
        return false;
      }
    }

    return true;
  };

  const submitClaim = (shouldDoctorSendInvoiceToCCG: boolean, isMobileDevice?: boolean) => {
    const mhtCreateInput = profileDataMHTEmployers
      .filter(mht => !initialMHTEmployers.includes(mht))
      .map(mht => props.mhts.find(m => m.abbreviation === mht));

    const mhtDeleteInput: any = initialMHTEmployers
      .filter(mht => !profileDataMHTEmployers.includes(mht))
      .map(mht => claim.doctor.mhtEmployers && claim.doctor.mhtEmployers.items.find(m => m.mht.abbreviation === mht));

    const pCode = startingPostcode
      ? `${startingPostcode.replace(" ", "").slice(0, -3)} ${startingPostcode.replace(" ", "").slice(-3)}`
      : "N/A";

    if (!checkInputValidAndDisplayErrors()) {
      return;
    }
    setSubmitted(true);
    setShowSubmitBottomSheet(false);

    const inputNotePrefix = shouldDoctorSendInvoiceToCCG
      ? isMobileDevice
        ? `Invoice generated and emailed to ${claim.doctor.email}`
        : "Invoice created & claim submitted"
      : "Claim submitted";

    const { billingAddress, billingCompanyName, vatRegistration } = modalClaimData;

    const claimNeedsClaimData =
      vehicleInformationRequested || shouldDoctorSendInvoiceToCCG || mustProvideBillingInformation;
    const shouldProvideCompanyInformation = shouldDoctorSendInvoiceToCCG || mustProvideBillingInformation;
    const shouldUpdateDoctorLineManager = Boolean(
      (showLineManager || showLineManagerAndMht) && lineManager && lineManager !== claim.doctor?.lineManager
    );
    const shouldUpdateDoctorDefaultMHT = Boolean(selectedMHT.id !== claim.doctor?.defaultMHT);
    profileDataMHTEmployers.sort();
    initialMHTEmployers.sort();
    // const shouldUpdateMHTs = Boolean(!isEqual(profileDataMHTEmployers, initialMHTEmployers));

    const input = checkMutationInput<UpdateClaimInput>({
      id: claim.id,
      claimVisitId: claim.visit.id,
      status: ClaimStatus.under_review,
      notes: `${inputNotePrefix} by Doctor@${user ? user.name : "N/A"}@${dayjs().format("MM/DD/YY")}`,
      doctorAdditionalConf,
      additionalInformation,
      doctorEmployedStatus,
      defaultMHT: selectedMHT.abbreviation,
      isIndependentDoctor,
      ...(additionExpensesAllowed && {
        additionalExpenses,
      }),
      ...(mileageAllowed && {
        startingPostcode: pCode.toUpperCase(),
        mileage: distanceTravelled,
      }),
      ...((showLineManager || showLineManagerAndMht) && { lineManager }),
      ...(claimNeedsClaimData && {
        claimData: {
          ...(shouldProvideCompanyInformation && {
            billingAddress,
            billingCompanyName,
            vatRegistration,
          }),
          ...(vehicleInformationRequested &&
            engineSize && {
              engineSize: engineSize?.value,
            }),
          ...(vehicleInformationRequested &&
            fuelType && {
              fuelType: fuelType.value,
            }),
        },
        ...((shouldDoctorSendInvoiceToCCG || showFeesMileageWithoutInvoice) && {
          invoiceData: invoiceData,
        }),
      }),
      // addition to show the invoice data if showFeesMileageWithoutInvoice applied and above doesn't
      ...(!claimNeedsClaimData &&
        showFeesMileageWithoutInvoice && {
          invoiceData: invoiceData,
        }),
      ...(claim.organisation.declarationText && {
        declaration: claim.organisation.declarationText,
      }),
    });

    updateClaim({
      variables: {
        input,
      },
      refetchQueries: [
        {
          query: GET_DOCTOR_CLAIMS_BY_STATUS,
          variables: {
            id: user ? user.id : "",
            limit: GQL_LIMITS.doctorClaims,
          },
        },
      ],
    })
      .then(() => {
        const successMessage = shouldDoctorSendInvoiceToCCG
          ? isMobileDevice
            ? `Invoice generated and emailed to ${claim.doctor.email}`
            : "Invoice successfully created"
          : "Claim successfully submitted";
        setMessage(successMessage);
        recordEvent(AnalyticsEvent.CLAIM_UPDATED, {
          "Mileage Required": mileageAllowed.toString(),
        });

        if (shouldUpdateDoctorLineManager) {
          updateDoctor({
            variables: {
              input: checkMutationInput<UpdateS12DoctorInput>({
                id: claim.doctor.id,
                lineManager,
              }),
            },
          });
        }
        // If we have chosen a different default MHT to that which is currently
        // indicated on the doctor, update the doctor profile.
        if (shouldUpdateDoctorDefaultMHT) {
          updateDoctor({
            variables: {
              input: checkMutationInput<UpdateS12DoctorInput>({
                id: claim.doctor.id,
                defaultMHT: selectedMHT.abbreviation,
              }),
            },
          });
        }

        // If vehicleInformationRequested is true, vehicle information will show on the claim.
        // If vehicle information is visible and it has changed, we then  update vehicle information
        // on the doctor's profile.
        if (
          vehicleInformationRequested &&
          (claim.doctor.claimData.fuelType !== fuelType?.value ||
            claim.doctor.claimData.engineSize !== engineSize?.value)
        ) {
          // Save the vehicle information once to dr profile
          // These details may only be updated later from 'Edit Profile'

          updateDoctor({
            variables: {
              input: checkMutationInput<UpdateS12DoctorInput>({
                id: claim.doctor.id,
                claimData: modalClaimData,
              }),
            },
          })
            .then(() => {
              setTimeout(() => navigation.goBack(), 100);
            })
            .catch((error: Error) => {
              setMessage(error.message);
            });
        } else {
          setTimeout(() => navigation.goBack(), 100);
        }
      })
      .catch(e => {
        setSubmitted(false);
        buildErr(
          {
            ...updateClaimError,
            additional: claim.id,
          },
          setLastException
        )(e);
      });
  };

  const detailSectionItems = useMemo(() => {
    return [
      {
        label: "Claim ID",
        value: claim.displayId,
        bold: true,
      },
      {
        label: "Claim Status",
        value: formatClaimStatus(claim.status),
        displayError: claim.status === ClaimStatus.rejected,
        bold: true,
      },
      ...(claim.status === ClaimStatus.rejected
        ? [
            {
              label: "Reason for rejection",
              // the note reason iteself could have an @, so after splitting, get from space 3 to end and then rejoin
              // note format from ClaimProcessingDetails.tsx action@USER@DATE@reason
              value:
                claim.notes && claim.notes.length > 0
                  ? claim.notes[claim.notes.length - 1]
                      .split("@")
                      .slice(3)
                      .join("@")
                  : "",
              displayError: claim.status === ClaimStatus.rejected,
              flex: 2,
              bold: true,
            },
          ]
        : []),
      ...(claim.status === ClaimStatus.on_hold
        ? [
            {
              label: "Reason for on hold",
              // the note reason iteself could have an @, so after splitting, get from space 3 to end and then rejoin
              // note format from ClaimProcessingDetails.tsx action@USER@DATE@reason
              value:
                claim.notes && claim.notes.length > 0
                  ? claim.notes[claim.notes.length - 1]
                      .split("@")
                      .slice(3)
                      .join("@")
                  : "",
              displayError: claim.status === ClaimStatus.on_hold,
              flex: 2,
              bold: true,
            },
          ]
        : []),
      ...(claim.status !== ClaimStatus.action_required
        ? [
            {
              label: "Mileage Submitted",
              value: hasMileage(claim.organisation.featureFlags) && claim.mileage && `${claim.mileage} Miles`,
            },
            {
              label: "Vehicle Information",
              value:
                vehicleInformationRequested &&
                claim.claimData &&
                formatFuelType(claim.claimData.fuelType, claim.claimData.engineSize || null),
            },
            {
              label: "Additional Expenses",
              value:
                hasAdditionalExpenses(claim.organisation.featureFlags) &&
                claim.additionalExpenses &&
                `£${claim.additionalExpenses}`,
            },
          ]
        : []),
      {
        label: "Visit Date",
        value: dayjs(assessmentDate).format(LONG_DATE_FORMAT),
      },
      {
        label: "Visit Time",
        value: dayjs(claim.visit.time).format("HH:mm"),
      },
      {
        label: "Visit Location",
        value: formatAddress(locationName),
      },
      ...(claim.status !== ClaimStatus.action_required
        ? [
            {
              label: "Additional Information",
              value: claim.additionalInformation || undefined,
              flex: 3,
            },
          ]
        : []),
      { label: "Line Manager", value: claim.lineManager ?? undefined },
      shouldDisplayDoctorGmcNumber && { label: "GMC Number", value: claim.doctor?.gmc ?? undefined },
    ].filter(x => x && x.value) as SectionItem[];
  }, [claim, vehicleInformationRequested, shouldDisplayDoctorGmcNumber]);

  const invoiceSectionItems = useMemo(() => {
    if (
      !claim.invoiceData ||
      !ccgRequiresDoctorToInvoice(claim.organisation.featureFlags) ||
      claim.status === ClaimStatus.action_required
    ) {
      return [];
    }
    const { subtotal, vat, totalExcVat, totalIncVat } = calculateInvoiceTotals(
      claim,
      Boolean(claim.doctor?.claimData?.vatRegistration)
    );
    return [
      { label: "Assessment Fee (GBP)", value: `£${claim.invoiceData?.assessmentFee}` },
      { label: "Mileage Fee (GBP)", value: `£${claim.invoiceData?.travelFee}` },
      ...(claim.additionalExpenses
        ? [
            {
              label: "Additional Expenses",
              value: claim.additionalExpenses && `£${claim.additionalExpenses}`,
            },
          ]
        : []),
      {
        label: "VAT",
        value: `£${vat}`,
      },
      {
        label: "Subtotal",
        value: `£${subtotal}`,
      },
      {
        label: "TOTAL DUE (EXC. VAT)",
        value: `£${totalExcVat}`,
      },
      {
        label: "TOTAL DUE (INC. VAT)",
        value: `£${totalIncVat}`,
      },
    ].filter(x => !!x) as SectionItem[];
  }, [claim.status, claim.invoiceData, claim.organisation.featureFlags]);

  const previousNotes = claim.receivedDate
    ? [
        `Claim created by AMHP@${claim.visit.assessment.amhp.name}@${claim.createdAt}`,
        `Claim submitted by Doctor@${claim.visit.doctor.name}@${claim.receivedDate}`,
      ]
    : [`Claim created by AMHP@${claim.visit.assessment.amhp.name}@${claim.createdAt}`];

  const claimHistory = claim.notes && claim.notes.length > 0 ? [...previousNotes, ...claim.notes] : previousNotes;

  const showBillingInformation =
    claim.status === ClaimStatus.action_required && (shouldDoctorSendInvoiceToCCG || mustProvideBillingInformation);

  const showLineManager = claim.status === ClaimStatus.action_required && ccgRequestsLineManager;

  const showLineManagerAndMht = claim.status === ClaimStatus.action_required && shouldDisplayMhtAndLineManager;

  const onBillingInformationSave = useCallback(
    data => {
      const { mhtEmployers, ...doctorWithoutMhtEmployers } = claim.doctor;

      updateDoctor({
        variables: {
          input: checkMutationInput<UpdateS12DoctorInput>({
            ...doctorWithoutMhtEmployers,
            claimData: data,
          }),
        },
        refetchQueries: [
          {
            query: CLAIM_QUERY_FOR_DOCTOR,
            variables: { id: claim.id },
          },
        ],
      })
        .then(() => {
          billingInformationRef.current && billingInformationRef.current.closeModal();
          setMessage("Billing Information Successfully Updated");
        })
        .catch(error =>
          buildErr(
            {
              ...updateClaimError,
              additional: claim.id,
            },
            setLastException
          )(error)
        );
    },
    [buildErr, checkMutationInput, claim, setMessage]
  );

  // Determine whether to hide submit button
  const submitDisabled = shouldDisableSubmit({
    featureFlags: claim.organisation.featureFlags,
    mileageDisclaimer,
    doctorAdditionalConf,
    distanceTravelled,
    fuelType,
    engineSize,
    claimData: claim.doctor?.claimData,
    invoiceData,
    assessmentWasNotAtHospital,
    patientNotInpatient,
    showLineManager,
    showLineManagerAndMht,
    lineManager,
    defaultMHT,
    submitted,
    isIndependentDoctor,
    employedBySWYorkshire,
    doctorEmployedStatus,
  });

  const renderClaimButtons = (navigation: any) => (
    <View style={isWebView ? styles.buttonWrapperWeb : styles.buttonWrapperMobile}>
      <Button
        onPress={() =>
          navigation.navigate(RouteKeys.DoctorVisitDetailsScreen, {
            visitId: claim.visit.id,
          })
        }
        mode="outlined"
        style={isWebView ? styles.buttonWeb : {}}
      >
        View Visit
      </Button>

      {claim.status === ClaimStatus.action_required && (
        <>
          <Button
            onPress={() => setShowDeclineModal(true)}
            style={[styles.cancel, isWebView ? styles.buttonWeb : {}]}
            disabled={submitted}
            color={color.textError}
          >
            Decline Claim
          </Button>
          <Button
            onPress={() => {
              if (checkInputValidAndDisplayErrors()) {
                setShowSubmitBottomSheet(true);
              } else {
                setShowErrorModal(true);
              }
            }}
            disabled={submitDisabled}
            style={isWebView ? styles.buttonWeb : {}}
          >
            Submit For Processing
          </Button>
        </>
      )}

      {claim.status !== ClaimStatus.action_required && shouldDoctorSendInvoiceToCCG && (
        <Button
          disabled={submitted}
          style={isWebView ? styles.buttonWeb : {}}
          onPress={async () =>
            generateInvoice(
              v2,
              {
                ...claim,
                claimData: {
                  ...modalClaimData,
                },
                invoiceData,
              },
              claim.displayId,
              isMobileDevice,
              () => setSubmitted(true),
              () => {
                setMessage(
                  isMobileDevice
                    ? `Claim successfully emailed to ${claim.doctor.email}`
                    : "Claim successfully generated"
                );
                setSubmitted(false);
              },
              (error: Error) => {
                setLastException(error as S12Error);
                setSubmitted(false);
              }
            )
          }
        >
          {isMobileDevice ? "Email" : "Download"} Invoice
        </Button>
      )}
      {(claim.status === ClaimStatus.rejected || claim.status === ClaimStatus.on_hold) && (
        <Button onPress={reopenClaim} disabled={submitted} style={isWebView ? styles.buttonWeb : {}}>
          Re-open Claim
        </Button>
      )}
    </View>
  );

  interface FeeProps {
    title: string;
    label: string;
    amount: string;
    fieldId: string;
  }

  const renderAmount = (props: FeeProps) => {
    const { title, label, amount, fieldId } = props;
    return (
      <>
        <Text format={TypographyType.Small} style={styles.sectionHeader}>
          {title}
        </Text>
        <TextInput
          label={label}
          icon="payment"
          autoCorrect={false}
          error={!!amount && isNaN(Number(amount))}
          errorText="Please enter a valid amount"
          keyboardType="numeric"
          autoCapitalize="none"
          returnKeyType="done"
          value={!Number.isNaN(Number(amount)) ? amount : "0.00"}
          onChangeText={value => {
            if (
              value !== "" &&
              (!/^\d/.test(value) || value.replace(/[^.]/g, "").length > 1 || Number.isNaN(Number(value)))
            )
              return;
            setInvoiceData({ ...invoiceData, [fieldId]: value });
          }}
          onBlur={() =>
            setInvoiceData({
              ...invoiceData,
              [`${fieldId}`]: Number(amount)
                .toFixed(2)
                .toString(),
            })
          }
          style={styles.inputBoxes}
          selectTextOnFocus={true}
        />
      </>
    );
  };

  const renderFeesAndMileage = () => (
    <>
      <Text format={TypographyType.HeadingSmall} style={styles.sectionHeader}>
        Fees
      </Text>
      {renderAmount({
        title: "Assessment",
        label: "Fee (GBP) Excluding VAT",
        amount: invoiceData.assessmentFee,
        fieldId: "assessmentFee",
      })}
      {startingPostcode !== "" &&
        Number(distanceTravelled) > 0 &&
        renderAmount({
          title: "Mileage",
          label: "Fee (GBP) Excluding VAT",
          amount: invoiceData.travelFee,
          fieldId: "travelFee",
        })}
    </>
  );

  // Check DynamoDB params being empty string opposed to genuine null, as a boolean not string literal
  const bAllowEmail = !!(claim.organisation.email && claim.organisation.email.trim().length > 0);

  return (
    <>
      <ContentWrap style={styles.historyBottom}>
        <Text align="center" style={!isWebView && { fontSize: 16 }} format={TypographyType.Regular}>
          Please contact the paying organisation for any information regarding your claim, including updating your bank
          account details or to be set up as a new supplier.
        </Text>
      </ContentWrap>
      <ContentWrap>
        <Text format={TypographyType.HeadingMedium}>Contact Information</Text>
      </ContentWrap>
      <ClaimContact type={claim.organisation.name} phone={claim.organisation.phone} email={claim.organisation.email} />
      <SectionDivider />
      <ContentWrap style={styles.historyBottom}>
        <Text format={TypographyType.Regular} align="center">
          Please read through these notes before submitting your claim:
        </Text>
        <TextButton
          onPress={() => setShowAdditionalInfoBottomSheet(true)}
          style={isWebView ? styles.buttonLinksWeb : styles.buttonLinks}
          testID="claimsNotes__link"
        >
          CLAIMS INFORMATION
        </TextButton>
      </ContentWrap>
      {/** If MHT and Line Manager needed but none specified, let them know */}
      {!isIndependentDoctor && showLineManagerAndMht && !selectedMHT.id && (
        <ContentWrap>
          <Text format={TypographyType.Regular} align="center" style={{ paddingBottom: 15 }}>
            Please ensure that you select your MHT below to submit this claim.
          </Text>
        </ContentWrap>
      )}
      <ContentWrap style={[styles.claimHeader, isWebView ? styles.claimHeaderWeb : {}]}>
        <Text format={TypographyType.HeadingMedium} style={isWebView && styles.claimHeaderTextWeb}>
          Claim Details
        </Text>
        {isWebView && renderClaimButtons(navigation)}
      </ContentWrap>
      <DetailsSection
        items={detailSectionItems}
        columns={3}
        spaceBottom={claim.organisation.paymentRates ? spacing[50] : spacing[30]}
      />
      {!!invoiceSectionItems.length && (
        <DetailsSection header="Invoice Details" items={invoiceSectionItems} columns={3} />
      )}
      {claim.organisation.paymentRates &&
      showFeesMileageWithoutInvoice && // Only show section if showFeesMileageWithoutInvoice
        claim.organisation.paymentRates.map((paymentRate, i) => (
          <DetailsSection
            key={i}
            header={paymentRate.description ? paymentRate.description : "Payment Rates"}
            items={[
              {
                label: "Assessment Fee",
                value: `£${paymentRate.assessmentFee}`,
              },
              {
                label: "Mileage Rate",
                value:
                  mileageAllowed && !showFeesMileageWithoutInvoice // Only show mileage rate if mileage allowed and NOT DOCTOR_FEES_MILEAGE_WITHOUT_INVOICE
                    ? `£${paymentRate.mileage} per mile`
                    : `See Claims Information in the link above`,
              },
            ]}
            spaceBottom={spacing[30]}
            columns={3}
          />
        ))}

      {claim.status === ClaimStatus.action_required && mileageAllowed && (
        <ContentWrap>
          <Text format={TypographyType.HeadingSmall} style={styles.sectionHeader}>
            Mileage Information
          </Text>
          <Text format={TypographyType.Small}>Please enter your journey details below:</Text>
          <View style={styles.inputContainer}>
            <TextInput
              label="Starting Postcode"
              icon="location-on"
              autoCorrect={false}
              error={postcodeError}
              errorText="Please enter a valid postcode"
              autoCapitalize="characters"
              returnKeyType="done"
              onSubmitEditing={() => milesTravelledInput.current && milesTravelledInput.current.focus()}
              value={startingPostcode}
              onChangeText={val => {
                if (postcodeError) setPostcodeError(false);
                setStartingPostcode(val);
              }}
              style={styles.inputBoxes}
            />
            <TextInput
              ref={milesTravelledInput}
              label="Distance Travelled (miles)"
              icon="drive-eta"
              autoCorrect={false}
              error={distanceError}
              errorText="Please enter a valid distance"
              keyboardType="numeric"
              autoCapitalize="none"
              returnKeyType="done"
              value={!Number.isNaN(Number(distanceTravelled)) ? distanceTravelled : "0.00"}
              onBlur={() => {
                if (distanceTravelled.trim() === "") {
                  setDistanceTravelled("0.00");
                  setEngineSize(null);
                } else {
                  setDistanceTravelled(
                    Number(distanceTravelled)
                      .toFixed(2)
                      .toString()
                  );
                }
              }}
              onChangeText={val => {
                if (distanceError) setDistanceError(false);
                if (
                  val !== "" &&
                  (!/^\d/.test(val) || val.replace(/[^.]/g, "").length > 1 || Number.isNaN(Number(val)))
                )
                  return;
                setDistanceTravelled(val);
              }}
              style={
                (styles.inputBoxes,
                {
                  marginRight: isWebView ? spacing[20] : undefined,
                })
              }
              selectTextOnFocus={true}
            />
            {vehicleInformationRequested && (
              <>
                <Select
                  icon="local-gas-station"
                  label="Fuel Type"
                  value={(fuelType && fuelType.value) || fuelTypeDropdownOptions[0].name}
                  onValueChange={(_v: string, i: number, d: { value: FuelType | string; label: string }[]) => {
                    setEngineSize(null);
                    setFuelType(d[i].value === "" ? null : (d[i] as { value: FuelType; label: string }));
                  }}
                  options={fuelTypeDropdownOptions}
                />
                {fuelType && !["electric", "lpg", "bicycle"].includes(fuelType.value) && (
                  <Select
                    icon="drive-eta"
                    label="Engine Size"
                    value={(engineSize && engineSize.value) || initialEngineSizeDropdownOptions[0].name}
                    onValueChange={(_v: string, i: number, d: { value: Engine | string; label: string }[]) =>
                      setEngineSize(d[i].value === "" ? null : (d[i] as { value: Engine; label: string }))
                    }
                    options={generateEngineSizeDropdownOptions(fuelType)}
                  />
                )}
              </>
            )}
          </View>
          <View style={styles.checkboxWrapper}>
            <Checkbox
              label="By checking this box, I confirm that I will not claim mileage expenses for this assessment from any
                other organisation."
              status={mileageDisclaimer}
              onPress={() => setMileageDisclaimer(!mileageDisclaimer)}
            />
          </View>
          {mileageDisclaimer && shouldDisplayAdditionalConf && (
            <View style={styles.radioButtonWrapper}>
              <RadioButtonGroup
                onValueChange={(val: DoctorConfirmation) => {
                  setDoctorAdditionalConf(val);
                }}
                value={
                  doctorAdditionalConf === DoctorConfirmation.dpt_inside_contracted_hours
                    ? "0"
                    : doctorAdditionalConf === DoctorConfirmation.dpt_outside_contracted_hours
                    ? "1"
                    : "2"
                }
              >
                <RadioButton
                  value={DoctorConfirmation.dpt_inside_contracted_hours}
                  status={doctorAdditionalConf === DoctorConfirmation.dpt_inside_contracted_hours}
                  label={"I'm employed by DPT and the assessment was undertaken inside my contracted hours"}
                />
                <RadioButton
                  value={DoctorConfirmation.dpt_outside_contracted_hours}
                  status={doctorAdditionalConf === DoctorConfirmation.dpt_outside_contracted_hours}
                  label={"I'm employed by DPT and the assessment was undertaken outside my contracted hours"}
                />
                <RadioButton
                  value={DoctorConfirmation.independent_capacity}
                  status={doctorAdditionalConf === DoctorConfirmation.independent_capacity}
                  label={"I undertook this assessment in an Independent capacity"}
                />
              </RadioButtonGroup>
            </View>
          )}
        </ContentWrap>
      )}
      {claim.status === ClaimStatus.action_required && shouldConfirmAssessmentWasNotAtHospital && (
        <ContentWrap>
          <View style={styles.checkboxWrapper}>
            <Checkbox
              label="By checking this box, I confirm that the patient is not an in-patient."
              status={patientNotInpatient}
              onPress={() => setPatientNotInpatient(!patientNotInpatient)}
            />
          </View>
          <View style={styles.checkboxWrapper}>
            <Checkbox
              label="By checking this box, I confirm that the assessment is not in the hospital where I work."
              status={assessmentWasNotAtHospital}
              onPress={() => setAssessmentWasNotAtHospital(!assessmentWasNotAtHospital)}
            />
          </View>
        </ContentWrap>
      )}
      {claim.status === ClaimStatus.action_required && !mileageAllowed && !shouldDisplayDoctorEmployedStatus && (
        <ContentWrap>
          <Text format={TypographyType.Small} style={styles.sectionHeader}>
            This CCG does not reimburse mileage expenses. Please use the CCG's contact information below if you'd like
            to discuss this claim with their team.
          </Text>
        </ContentWrap>
      )}
      {claim.status === ClaimStatus.action_required && additionExpensesAllowed && (
        <ContentWrap>
          <Text format={TypographyType.HeadingSmall} style={styles.sectionHeader}>
            Additional Expenses
          </Text>
          {claim.organisation.expensesInstruction ? (
            <Text format={TypographyType.Small}>{claim.organisation?.expensesInstruction}</Text>
          ) : (
            <Text format={TypographyType.Small}>
              Please enter the total value of any additional expenses to be reimbursed and email photographs of your
              receipts to the paying organisation with the unique claim ID written in the subject line of your email.
            </Text>
          )}
          <View style={styles.inputContainer}>
            <TextInput
              label="Expenses incurred (GBP; Exc. VAT)"
              icon="payment"
              autoCorrect={false}
              error={additionalExpensesError}
              errorText="Please enter a valid amount"
              keyboardType="numeric"
              autoCapitalize="none"
              returnKeyType="done"
              value={!Number.isNaN(Number(additionalExpenses)) ? additionalExpenses : "0.00"}
              onBlur={() => {
                if (additionalExpenses.trim() === "" || !Number(additionalExpenses)) {
                  setAdditionalExpenses("0.00");
                } else {
                  setAdditionalExpenses(
                    Number(additionalExpenses)
                      .toFixed(2)
                      .toString()
                  );
                }
              }}
              onChangeText={val => {
                if (additionalExpensesError) setAdditionalExpensesError(false);
                if (
                  val !== "" &&
                  (!/^\d/.test(val) || val.replace(/[^.]/g, "").length > 1 || Number.isNaN(Number(val)))
                )
                  return;
                setAdditionalExpenses(val);
              }}
              style={styles.inputBoxes}
              selectTextOnFocus={true}
            />
          </View>
          {bAllowEmail && (
            <ContactBox
              label="Email address to send receipts to:"
              data={claim.organisation?.email || "-"}
              lastInList={true}
              icon={<SquareEmailButton />}
              style={styles.contactBoxEmail}
              onPress={() =>
                // do we care if the mail app failed to open?
                Linking.openURL(`mailto:${claim.organisation.email}`)
                  .then(() =>
                    recordEvent(AnalyticsEvent.CONTACTED_SUPPORT, {
                      method: "email",
                    })
                  )
                  .catch(() => null)
              }
            />
          )}
        </ContentWrap>
      )}

      {/* The doctor is confirming whether they need to be paid by payroll. We use the doctorEmployedStatus
            flag on the claim to record the choice
      */}
      {claim.status === ClaimStatus.action_required && shouldDisplayDoctorEmployedStatus && (
        <ContentWrap>
          <View style={styles.checkboxWrapper}>
            <Checkbox
              label="Check the box if you are employed by South West Yorkshire Partnership NHS Foundation Trust"
              status={!!employedBySWYorkshire}
              onPress={() => setEmployedBySWYorkshire(!employedBySWYorkshire)}
            />
          </View>
          {employedBySWYorkshire && (
            <View style={styles.radioButtonWrapper}>
              <Text format={TypographyType.Small} style={styles.textDescriptor}>
                Do you want to be paid by payroll?
              </Text>
              <RadioButtonGroup
                onValueChange={(val: DoctorEmployedStatusValues) => {
                  setDoctorEmployedStatus(val);
                }}
                value={doctorEmployedStatus === DoctorEmployedStatusValues.payroll ? "0" : "1"}
              >
                <RadioButton
                  value={DoctorEmployedStatusValues.payroll}
                  status={doctorEmployedStatus === DoctorEmployedStatusValues.payroll}
                  label={"Yes"}
                />
                <RadioButton
                  value={DoctorEmployedStatusValues.non_payroll}
                  status={doctorEmployedStatus === DoctorEmployedStatusValues.non_payroll}
                  label={"No"}
                />
              </RadioButtonGroup>
            </View>
          )}
        </ContentWrap>
      )}

      {claim.status === ClaimStatus.action_required && (
        <>
          {shouldDoctorSendInvoiceToCCG && (
            <ContentWrap>
              <Text format={TypographyType.HeadingSmall} style={styles.sectionHeader}>
                Location of Assessment
              </Text>
              <TextInput
                label="Location of Assessment"
                style={styles.inputBoxes}
                selectTextOnFocus={true}
                value={invoiceData.locationOfAssessment || ""}
                onChangeText={value =>
                  setInvoiceData({
                    ...invoiceData,
                    locationOfAssessment: value,
                  })
                }
              />
              {renderFeesAndMileage()}
              {claim.doctor.claimData?.vatRegistration && (
                <>
                  <Text format={TypographyType.RegularBold}>Subtotal</Text>
                  <Text format={TypographyType.Regular}>
                    £
                    {Number(
                      Number(invoiceData.assessmentFee) + Number(invoiceData.travelFee) + Number(additionalExpenses)
                    )
                      .toFixed(2)
                      .toString()}
                  </Text>
                </>
              )}
              {claim.doctor.claimData?.vatRegistration && (
                <>
                  <Text format={TypographyType.RegularBold}>VAT</Text>
                  <Text format={TypographyType.Regular}>
                    £
                    {(
                      Number(
                        Number(invoiceData.assessmentFee) + Number(invoiceData.travelFee) + Number(additionalExpenses)
                      ) * 0.2
                    )
                      .toFixed(2)
                      .toString()}
                  </Text>
                </>
              )}
              <Text format={TypographyType.RegularBold}>Total Invoice Value</Text>
              <Text format={TypographyType.Regular}>
                £
                {(
                  Number(
                    Number(invoiceData.assessmentFee) + Number(invoiceData.travelFee) + Number(additionalExpenses)
                  ) * (claim.doctor.claimData?.vatRegistration ? 1.2 : 1)
                )
                  .toFixed(2)
                  .toString()}
              </Text>
            </ContentWrap>
          )}
          {/* If the invoice flag is set, we will show fees and mileage. If it is not set, we may still show fees and mileage
                if the  the DOCTOR_FEES_MILEAGE_WITHOUT_INVOICE flag is set */}
          {!shouldDoctorSendInvoiceToCCG && showFeesMileageWithoutInvoice && (
            <ContentWrap>{renderFeesAndMileage()}</ContentWrap>
          )}
          <ContentWrap>
            <Text format={TypographyType.HeadingSmall} style={styles.sectionHeader}>
              Additional Information
            </Text>
            <Text format={TypographyType.Small}>
              Please fill in any information that could be helpful (max: 250 characters)
            </Text>
            <TextInput
              label="Additional Information"
              icon="note"
              autoCorrect={false}
              maxLength={250}
              autoCapitalize="none"
              returnKeyType="done"
              value={additionalInformation}
              multiline={true}
              numberOfLines={2}
              onChangeText={setAdditionalInformation}
              style={styles.inputBoxes}
              selectTextOnFocus={true}
            />
            <Text
              format={TypographyType.Micro}
              style={{
                alignSelf: "flex-end",
                marginTop: -20,
                marginBottom: 20,
              }}
            >
              {250 - additionalInformation.length}/250
            </Text>
          </ContentWrap>
        </>
      )}
      {showBillingInformation && (
        <BillingInformation
          doctor={claim.doctor}
          initialData={mergedClaimData}
          submitted={submitted}
          ref={billingInformationRef}
          onSave={onBillingInformationSave}
          mustProvideBillingInformation={mustProvideBillingInformation}
        />
      )}
      {(showLineManager || showLineManagerAndMht) && (
        <ContentWrap>
          <Text format={TypographyType.HeadingSmall} style={styles.sectionHeader}>
            Employment Status
          </Text>
          <Text format={TypographyType.SmallBold}>
            The paying organisation needs this information to be able to process your claim correctly.
          </Text>
          <View style={styles.checkboxWrapper}>
            <Checkbox
              label="I am not employed by an MHT"
              status={isIndependentDoctor}
              onPress={async () => {
                await setIsIndependentDoctor(!isIndependentDoctor);

                // Update just this independent doctor field.
                updateClaim({
                  variables: {
                    input: checkMutationInput({
                      id: claim.id,
                      isIndependentDoctor: !isIndependentDoctor,
                    }),
                  },
                });
              }}
            />
          </View>
          {!isIndependentDoctor && showLineManagerAndMht && (
            <View>
              <View style={styles.checkboxWrapper}>
                <Icon style={styles.infoIcon} name="info" color={color.secondary} />
                <Text format={TypographyType.Small}>
                  If your MHT doesn’t appear here, please edit your profile and add your MHT using the dropdown under
                  MHT Employer.
                </Text>
              </View>
              <Select
                label="MHT Employer"
                value={selectedMHT.id}
                onValueChange={v => {
                  // We are saving both the id and abbreviation, so derive that from options
                  const mht = mhtOptions.find(m => m.id === v);
                  if (mht) {
                    setSelectedMHT({ id: mht.id, abbreviation: mht.name });
                  }
                }}
                options={mhtOptions}
              />
            </View>
          )}
          {!isIndependentDoctor && (
            <View style={styles.inputContainer}>
              <Text format={TypographyType.Small}>Please enter your Line Manager's Name.</Text>
            </View>
          )}
          {!isIndependentDoctor && (
            <View>
              <TextInput
                label="Line Manager Name"
                icon="person"
                autoCorrect={false}
                error={lineManagerError}
                errorText="Please enter your line manager's name"
                autoCapitalize="words"
                returnKeyType="done"
                value={lineManager}
                onBlur={() => {
                  lineManager.trim();
                  setLineManagerError(!lineManager.length && !isIndependentDoctor);
                }}
                onChangeText={value => {
                  if (lineManagerError) {
                    setLineManagerError(false);
                  }

                  setLineManager(value);
                }}
                style={styles.inputBoxes}
              />
            </View>
          )}
        </ContentWrap>
      )}
      {!isWebView && <ContentWrap>{renderClaimButtons(navigation)}</ContentWrap>}
      <SectionDivider />
      <ContentWrap>
        <Text format={TypographyType.HeadingMedium}>Claim History</Text>
        <ClaimHistory claimNotes={claimHistory} />
      </ContentWrap>
      <SectionDivider />
      <View>
        <ClaimContact
          type="AMHP Contact Information"
          team={claim.visit.assessment.amhpTeam.name}
          name={claim.visit.assessment.amhp.name}
          phone={claim.visit.assessment.amhp.phone}
          email={claim.visit.assessment.amhp.email}
        />
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  additionalNotesLink: {
    padding: 0,
    textTransform: "none",
  },
  buttonWeb: {
    marginLeft: spacing[10],
  },
  buttonWrapperMobile: {
    marginBottom: spacing[10] * -1,
  },
  buttonWrapperWeb: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  claimHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  claimHeaderWeb: {
    marginTop: spacing[20],
    marginBottom: spacing[40],
    alignItems: "center",
  },
  claimHeaderTextWeb: {
    marginBottom: 0,
  },
  historyBottom: {
    marginBottom: spacing[30],
  },
  inputContainer: {
    paddingTop: spacing[30],
    paddingBottom: spacing[20],
  },
  inputBoxes: {
    backgroundColor: color.background,
    flex: 1,
  },
  sectionHeader: {
    paddingVertical: spacing[20],
  },
  checkboxWrapper: {
    flexDirection: "row",
    alignItems: "flex-start",
    marginTop: spacing[30],
    marginBottom: spacing[20],
    paddingRight: spacing[10],
  },
  infoIcon: {
    marginRight: spacing[10],
  },
  cancel: {
    backgroundColor: color.textError,
  },
  radioButtonWrapper: {
    marginLeft: spacing[10],
  },
  mhtEmployers: {
    zIndex: (Platform.OS !== "web" && 2) || undefined,
    ...Platform.select({
      web: {
        height: 30,
      },
    }),
  },
  textDescriptor: {
    marginBottom: spacing[20],
  },
  buttonLinks: {
    marginBottom: spacing[10],
  },
  buttonLinksWeb: {
    marginTop: spacing[5],
    marginBottom: spacing[5],
  },
  contactBoxEmail: {
    paddingTop: 0,
    paddingBottom: 20,
  },
});
