import React from "react";
import { StyleSheet, View } from "react-native";

import { palette } from "@/theme";

interface MultiSelectCheckboxProps {
  checked?: boolean;
}

export const MultiSelectCheckbox = ({ checked = false }: MultiSelectCheckboxProps) => {
  return <View style={styles.checkbox}>{checked && <View style={styles.check} />}</View>;
};

const styles = StyleSheet.create({
  checkbox: {
    width: 28,
    height: 28,
    alignItems: "center",
    justifyContent: "center",
    borderWidth: 2,
    borderRadius: 6,
    backgroundColor: palette.white,
    borderColor: palette.checkboxBorder,
  },

  check: {
    width: 14,
    height: 14,
    borderRadius: 3,
    backgroundColor: palette.blue,
  },
});
