import { TextProps } from "react-native";

export enum TypographyType {
  HeadingLarge = "h1",
  HeadingMedium = "h2",
  HeadingSmall = "h3",
  ButtonText = "button",
  Regular = "regular",
  RegularBold = "regularBold",
  Small = "small",
  SmallBold = "smallBold",
  Tiny = "tiny",
  TinyBold = "tinyBold",
  Micro = "micro",
  MicroBold = "microBold",
  Brand = "brand",
}

export interface TypographyProps extends TextProps {
  format: TypographyType;
  align?: "left" | "center" | "right";
  color?: string;
  marginTop?: number;
  marginBottom?: number;
  type?: "native" | "paper";
  children?: any;
  error?: boolean;
}
