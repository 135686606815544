import React from "react";
import { Platform, StyleSheet, View } from "react-native";

import { palette, spacing } from "../../../theme";

const CardInfoItem = (props: { children: React.ReactNode; color: string; lastItem: boolean }) => {
  const { children, color, lastItem = false } = props;
  const isNative = Platform.OS !== "web";

  return (
    <View style={styles.item}>
      <View style={[styles.circle, { borderColor: color }]} />
      <View style={[lastItem ? styles.borderMask : {}, isNative ? styles.borderMaskNative : {}]} />
      {children}
    </View>
  );
};

const styles = StyleSheet.create({
  borderMask: {
    position: "absolute",
    left: -2,
    bottom: 0,
    width: 4,
    height: "100%",
    backgroundColor: palette.white,
    transform: [{ translateY: 17 }],
  },
  borderMaskNative: {
    transform: [{ translateY: 2 }],
  },

  circle: {
    position: "absolute",
    top: 7,
    left: -5,
    width: 10,
    height: 10,
    borderWidth: 2,
    borderRadius: 10,
    borderStyle: "solid",
    backgroundColor: palette.white,
  },

  item: {
    position: "relative",
    paddingHorizontal: spacing[25],
    paddingBottom: spacing[15],
    justifyContent: "center",
  },
});

export default CardInfoItem;
