export const palette = {
  // brand colors
  aqua: "#2AD2C9",
  aquaFaded: "#B1E4E3",
  blue: "#0072CE",
  blueFaded: "#A7C6ED",
  blueSecondary: "#ECF6FF", // watch this space . . .
  yellow: "#FFC600",
  yellowFaded: "#F6EB61",
  red: "#FF5C39",
  redFaded: "#FFB3AB",
  orange: "#FF9300",
  orangeFaded: "#FAC698",
  purple: "#9B7DD4",
  purpleFaded: "#C5B4E3",
  pink: "#FFBFD1",
  pinkFaded: "#FFDCEB",

  // shades
  navy: "#002B49",
  slate: "#435570",
  greyBlue: "#647692",
  grey: "#EAECF2",
  cloud: "#F5F6FA",
  white: "#FFFFFF",

  // utility colors
  blueShadow: "#CFD3E4",
  blueShadowLight: "#DAE1E9",
  checkboxBorder: "#BBC3CF",
  shadow: "#333333",
  shadowDark: "#0e0e0e",
  bgSwooshBorder: "#D9EAF8",
  videoBorderColor: "#444444",
  fadedIconBG: "#1e1e1e",
  transparent: "transparent",
  rippleColor: "rgba(51,75,161,0.025)",
  nameTagBackground: "rgba(20,20,20,0.5)",
  sideSheetOverlayBackground: "rgba(10,10,10,0.85)",
  placeholderText: "#9a9a9a",

  // MHA stat forms
  mhaFormPink: "#FFEAF0",
  mhaPink: "#FFB4CA",
};
