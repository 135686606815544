import { ContractListInput } from "./API";
import { AvailabilityType, LocationInput, LocationNameInput } from "libs/types/API";
import { Dayjs } from "dayjs";
export { AvailabilityType } from "libs/types/API";

export enum AvailabilityState {
  unavailable = "unavailable",
  away = "away",
  oncall = "oncall",
  noInformation = "noInformation",
  available = "available",
  independent = "independent",
  trust = "trust",
  inAssessment = "inAssessment",
  booked = "booked",
  rota = "rota",
}

export interface Availability {
  id: string;
  type?: AvailabilityType | null;
  location?: LocationInput | null;
  locationName?: LocationNameInput | null;
  rrule: string;
  endHour: number;
  endDate?: string | null;
  contractList?: ContractListInput | null;
  notes?: string | null;
  mht?: {
    id: string;
    abbreviation: string;
    organisation: {
      id: string;
      name: string;
    };
  };
  oncall?: boolean | null;
  rotaId?: string | null;
  rotaName?: string | null;
  availabilityDoctorId?: string | null;
  overnightElement?: number;
  s12DoctorAvailabilitiesId?: string | null;
}

export interface ContractList {
  name: string;
  description: string;
}

// TODO: rework all uses of this to a calendarEventItem,
// reduce number of types floating around
export interface AvailabilityDate {
  availability: Availability;
  start: Dayjs; // utc date
  end: Dayjs; // utc date
  onHoliday?: {
    isAway: boolean;
    isPartial: boolean;
    availability: string | null;
  };
  booked?: {
    isBooked: boolean;
    partiallyBooked: boolean;
  };
}

export type CalendarItem = Availability & {
  distance?: number | null;
  contractShortCode?: string;
  visible?: boolean;
  partialPostcode?: string | null;
};

export type CalendarEventItem = CalendarItem & {
  start: Dayjs;
  end: Dayjs;
  overnightElement?: number;
};

export interface MHTState {
  value: string;
  label: string;
}

export interface AvailabilityModelState {
  date: Dayjs;
  startTime: {
    hours: number;
    minutes: number;
  };
  endTime: {
    hours: number;
    minutes: number;
  };
  hasError: boolean;
  postcodeError: boolean;
  postcode?: string;
  mht?: MHTState;
  notes?: string;
  overnight?: boolean;
}

export interface AvailabilityStateModel {
  availabilities: AvailabilityModelState[];
  defaultMHT: MHTState;
  defaultOncall: boolean;
  defaultRota: { id: string; name: string };
  defaultNotes: string;
  defaultPostcode: string;
  repeatWeekdays: string[];
  eventSeriesEndDate: Dayjs | null;
  repeatWeeklyCount: string;
}

export interface AvailabilityStateController {
  isSingleItem: string;
  eventSeriesHasEnd: boolean;
  isSaving: boolean;
  postcodeError: boolean;
  openErrorModal: boolean;
  repeatWeekly: string;
  activeElements: number[];
}

export interface EditableAvailabilityState {
  id: string;
  location?: LocationInput | null;
  locationName?: LocationNameInput | null;
  model: AvailabilityStateModel;
  controller: AvailabilityStateController;
}
