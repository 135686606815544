import { FormStatus } from "libs/types/API";
import { FormCategory, UserTypeWhoCanModifyForms } from "libs/types/mhaForms";
import { validFormStatusTransitions } from "libs/mhaForms/validFormStatusTransitions";

export function getAvailableNextStatusForFormActionButton(
  userType: UserTypeWhoCanModifyForms | "Unknown",
  formCategory: FormCategory,
  formCurrentStatus: FormStatus
): FormStatus[] {
  const statusGroup = validFormStatusTransitions[formCategory][formCurrentStatus];
  return Object.keys(statusGroup || {}).length > 0
    ? Object.entries(statusGroup as { [key in FormStatus]: UserTypeWhoCanModifyForms[] })
        .filter(
          ([formStatus, userTypesThatCanModifyForms]) =>
            userType !== "Unknown" &&
            userTypesThatCanModifyForms.includes(userType) &&
            (formStatus !== formCurrentStatus || formStatus === FormStatus.s60_signed_in_amhp_team_inbox)
        )
        .map(([formStatus]) => formStatus as FormStatus)
    : [];
}
