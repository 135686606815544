export { default as HospitalDetails } from "./hospitalDetails";
export { default as AMHPDetails } from "./amhpDetails";
export { default as LocalAuthority } from "./localAuthority";
export { default as PatientDetails } from "./patientDetails";
export { default as PatientDetailsTreatment } from "./patientDetailsTreatment";
export { default as AssessmentDetails } from "./assessmentDetails";
export { default as NearestRelative } from "./nearestRelative";
export { default as EmergencyAdmissionDetails } from "./emergencyAdmissionDetails";

// A6
export { default as A6LocalAuthority } from "./A6/localAuthority";
export { default as NearestRelativeConsulted } from "./A6/nearestRelativeConsulted";
export { default as A6AssessmentDetails } from "./A6/assessmentDetails";

// A10
export { default as A10HospitalDetails } from "./A10/hospitalDetails";

// Conveyance
export { default as ConveyanceAmhpDetails } from "./conveyance/amhpDetails";
export { default as ConveyancePatientDetails } from "./conveyance/patientDetails";
export { default as ConveyanceFormDetails } from "./conveyance/formDetails";
export { default as ConveyanceSummaryOfRisk } from "./conveyance/summaryOfRisk";
