import React from "react";
import { View } from "react-native";
import { useNavigation, ParamListBase } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";

import { useOnMount } from "@/hooks";
import { RouteKeys } from "@/navigationv2";

interface PropTypes {
  redirectTo: RouteKeys;
}

export const RedirectUser = (props: PropTypes) => {
  const navigation = useNavigation<StackNavigationProp<ParamListBase>>();

  useOnMount(() => {
    navigation.reset({
      index: 0,
      routes: [{ name: props.redirectTo }],
    });
  });

  return <View />;
};
