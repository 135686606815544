import React, { useEffect, useState } from "react";
import { Animated, Easing } from "react-native";
import Svg, { Path } from "react-native-svg";

export const MapIcon: React.FC<{
  size: number;
  primaryColor?: string;
  secondaryColor?: string;
  active?: boolean;
  inactive?: boolean;
}> = ({ size = 45, primaryColor = "#000", secondaryColor = "#fff", active = false, inactive = false }) => {
  const [iconScale] = useState(new Animated.Value(1));
  const [iconOpacity] = useState(new Animated.Value(1));

  useEffect(() => {
    Animated.timing(iconScale, {
      toValue: active ? 1.3 : 1,
      easing: Easing.out(Easing.linear),
      duration: 200,
      delay: 100,
      useNativeDriver: true,
    }).start();
  }, [active]);

  useEffect(() => {
    Animated.timing(iconOpacity, {
      toValue: inactive ? 0.7 : 1,
      easing: Easing.out(Easing.linear),
      duration: 200,
      delay: 100,
      useNativeDriver: true,
    }).start();
  }, [inactive]);

  return (
    <Animated.View style={{ width: size, height: size, transform: [{ scale: iconScale }], opacity: iconOpacity }}>
      <Svg width={size} height={size} viewBox="0 0 24 24" fill={primaryColor}>
        <Path d="M0,0h24v24H0V0z" fill="none" />
        <Path
          d="M18.5,10.2c0,2.57-2.1,5.79-6.16,9.51L12,20.01l-0.34-0.31C7.6,15.99,5.5,12.77,5.5,10.2 c0-3.84,2.82-6.7,6.5-6.7S18.5,6.35,18.5,10.2z"
          fillOpacity={1}
        />
        <Path
          d="M12,11c1.33,0,4,0.67,4,2v0.16C15.03,14.28,13.6,15,12,15s-3.03-0.72-4-1.84V13C8,11.67,10.67,11,12,11z M12,10 c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2S13.1,10,12,10z M18,10.2C18,6.57,15.35,4,12,4s-6,2.57-6,6.2c0,2.34,1.95,5.44,6,9.14 C16.05,15.64,18,12.54,18,10.2z M12,2c4.2,0,8,3.22,8,8.2c0,3.32-2.67,7.25-8,11.8c-5.33-4.55-8-8.48-8-11.8C4,5.22,7.8,2,12,2z"
          fill={secondaryColor}
        />
      </Svg>
    </Animated.View>
  );
};
