import gql from "graphql-tag";
import dayjs, { Dayjs } from "dayjs";
import { FormStatus, LocationNameInput } from "libs/types/API";
import {
  FormType,
  FormFieldType,
  FormFieldsAmhp,
  FormFieldsDoctor,
  MHAForm,
  MHAFormField,
  MHAFormSection,
} from "libs/types/mhaForms";
import { Forms } from "libs/mhaForms/formSections";
import { isAmhpForm, isJointForm } from "libs/mhaForms/helpers";
export type MHAFormMetadata = {
  id?: string;
  version: number;
  assessmentId?: string;
  status: FormStatus;
  amhpTeamId?: string;
  amhpTeamInboxId?: string;
  amhpTeamName?: string;
  authors: string[]; // usernames
  doctorNames?: string[];
  notes?: string[];
  signed?: string[];
  sha256?: string;
  type: FormType;
  createdBy?: string;
  createdAt?: string;
};

export type FormikValues = {
  // section
  [key: string]: {
    // field
    [key in FormFieldsAmhp | FormFieldsDoctor]?: string | Dayjs | boolean | LocationNameInput | string[];
  };
} & { formType: FormType };

export function transformResponseToModel(
  sections: MHAFormSection[],
  form: GetFormResponse,
  editable: boolean,
  formType: FormType
) {
  const transformedData = {
    sections: sections.reduce(
      (sections, section) => ({
        ...sections,
        [section.name]: section.fields.reduce(
          (fields, field) => ({
            ...fields,
            [field.name]: fieldToValue(field, form, editable, formType),
          }),
          {}
        ),
      }),
      { formType } as FormikValues
    ),
  };

  return transformedData;
}

const flattenDoctorVisits = (metadata: GetFormResponse) =>
  metadata.visitsByAssessment?.items?.reduce((acc, curr) => {
    if (curr?.assessment.doctorVisits) {
      return [...acc, ...curr?.assessment?.doctorVisits?.items];
    }
    return acc;
  }, []);

export function transformResponseToMetadata(form: GetFormResponse, type?: FormType): MHAFormMetadata {
  if (form.getForm) {
    const { data, ...metadata } = form.getForm;
    return metadata;
  } else if (form.getExternalForm) {
    const { data, ...metadata } = form.getExternalForm;
    return metadata;
  } else if (form.getAssessment) {
    if (!type) {
      throw new Error("Type Required");
    }
    return {
      amhpTeamId: form.getAssessment.amhpTeamId,
      type: type,
      assessmentId: form.getAssessment.id,
      status: FormStatus.s10_draft,
      version: -1,
      authors: [],
    };
  } else if (form.visitsByAssessment && form.visitsByAssessment?.items?.filter(notEmpty)[0]?.assessment) {
    const assessment = form.visitsByAssessment?.items?.filter(notEmpty)[0]?.assessment;
    if (!type) {
      throw new Error("Type Required");
    }
    return {
      amhpTeamId: assessment.amhpTeamId,
      type: type,
      assessmentId: assessment.id,
      status: FormStatus.s10_draft,
      version: -1,
      authors: [],
    };
  } else if (form.getS12Doctor) {
    if (!type) {
      throw new Error("Type Required");
    }
    return {
      type: type,
      status: FormStatus.s10_draft,
      version: -1,
      authors: [form.getS12Doctor.id],
      doctorNames: [form.getS12Doctor.name],
    };
  } else {
    throw new Error("Cannot generate metadata");
  }
}

function fieldToValue(field: MHAFormField, metadata: GetFormResponse, editable: boolean, formType: FormType) {
  if (editable) {
    // We add the hospital name and address on the MHA form which precedes the authority to convey form. However, the user must then
    // add the hospital address to the Authority to Convery form. We need to derive the previously added hospital details from the MHA form
    // and prepopulate the details on the authority to convey form.  Look at the GET_FORM query in MHAFormContainer.gpl.tsx. In that file
    // you will find that form.data?.getFormsByAssessment pulls in previous forms attached to the assessment.
    if (
      formType === FormType.AUTHORITY_TO_CONVEY &&
      (field.name === "hospitalName" || field.name === "hospitalAddress")
    ) {
      // Once the hospital name has been changed on the Authority to Convey form, don't override it further
      let nameExists = false;
      let addressExists = false;

      let hospitalName;
      let hospitalAddress;
      if (metadata?.getFormsByAssessment?.items) {
        // First check whether the Authority to Convey form currently has a value for the hospital name / address
        // If this is the case, we do not want to override it with values from previous forms.
        const AtCForm = metadata?.getFormsByAssessment?.items
          .filter(f => !!f && !!f.type && f.type === FormType.AUTHORITY_TO_CONVEY)
          .map(f => {
            if (!f?.type) return;
            if (f.data) {
              return f.data;
            }
            return null;
          });

        // AtCForm should contain the Authority to Convey form
        for (const f of AtCForm) {
          if (f?.sections) {
            for (const s of f?.sections) {
              for (const fld of s.fields) {
                if (fld.name === "hospitalName" && fld.value) {
                  nameExists = true;
                }
                if (fld.name === "hospitalAddress" && fld.valueLocation) {
                  addressExists = true;
                }
              }
            }
          }
        }

        if (!nameExists && !addressExists) {
          // Find a form that is not the authority to convey
          const otherForms = metadata?.getFormsByAssessment?.items
            .filter(f => !!f && !!f.type && f.type !== FormType.AUTHORITY_TO_CONVEY)
            .map(f => {
              if (!f?.type) return;
              if (f.data) {
                return f.data;
              }
              return null;
            });

          // otherForms should contain the MHA form which precedes the Authority to Convey form
          for (const f of otherForms) {
            if (f?.sections) {
              for (const s of f?.sections) {
                for (const fld of s.fields) {
                  if (fld.name === "hospitalName" && fld.value) {
                    hospitalName = fld.value;
                  }
                  if (fld.name === "hospitalAddress" && fld.valueLocation) {
                    hospitalAddress = fld.valueLocation;
                  }
                }
              }
            }
          }

          // The hospital name will either be blank or will have been populated from a previous
          // Here we are saying that if the current field on the Authotity to Convey form is the hospital name, return
          // the hospital name we found on the MHA form, if any
          if (field.name === "hospitalName") {
            return hospitalName;
          }
          // Here we are saying that if the current field on the Authotity to Convey form is the hospital address, return
          // the hospital address we found on the MHA form, if any
          return hospitalAddress;
        }
      }
    }

    // The legal name is not editable on the AMHP profile. It has been added to the schema since
    // AMHPs may be setup with an informal version of their name and they need to be able
    // to amend this when creating a form. When we save the form, we also then
    //  update the legal name to match whatever appeared on the form. This is currently the
    // only route to update the legal name. Consequently, if we see a legal name coming through in the
    // query, we return this legal name here, rather than than the regular AMHP name.
    if (field.name === "amhpName" && metadata.getAmhpProfile?.legalName) {
      return metadata.getAmhpProfile?.legalName;
    } else if (field.name === "amhpName" && metadata.getAmhpProfile?.name) {
      return metadata.getAmhpProfile?.name;
    }

    if (field.name === "patientAddress" && metadata.getAssessment?.patientAddress) {
      // Only show the form address if the form type is a conveyance form
      if (formType === FormType.AUTHORITY_TO_CONVEY) {
        return field.valueLocation;
      }
      // always update until signed
      return metadata.getAssessment.patientAddress;
    } else if (field.name === "patientName" && metadata.getAssessment?.patientName) {
      // always update until signed
      return metadata.getAssessment.patientName;
    } else if (
      field.name === "patientAddress" &&
      metadata.visitsByAssessment?.items?.filter(notEmpty)[0]?.assessment?.patientAddress
    ) {
      // Only show the form address if the form type is a conveyance form
      if (formType === FormType.AUTHORITY_TO_CONVEY) {
        return field.valueLocation;
      }
      // always update until signed
      return metadata.visitsByAssessment?.items?.filter(notEmpty)[0]?.assessment?.patientAddress;
    } else if (
      field.name === "patientName" &&
      metadata.visitsByAssessment?.items?.filter(notEmpty)[0]?.assessment?.patientName
    ) {
      // always update until signed
      return metadata.visitsByAssessment?.items?.filter(notEmpty)[0]?.assessment?.patientName;
    } else if (field.name === "authorityName" && !field.value && metadata.getAmhpProfile?.accreditation) {
      // only update if empty
      return metadata.getAmhpProfile?.accreditation;
    } else if (field.name === "practitionerName" && !field.value && metadata.getS12Doctor?.legalName) {
      return metadata.getS12Doctor.legalName;
    } else if (field.name === "practitionerAddress" && !field.value && metadata.getS12Doctor?.sectionFormAddress) {
      // only update if empty
      return metadata.getS12Doctor.sectionFormAddress;
    } else if (
      field.name === "practitionerEmailAddress" &&
      !field.value &&
      metadata.getS12Doctor?.sectionFormEmailAddress
    ) {
      // only update if empty
      return metadata.getS12Doctor.sectionFormEmailAddress;
    } else if (
      field.name === "firstPractitionerName" &&
      !field.value &&
      !metadata.getForm &&
      metadata.getS12Doctor &&
      !metadata.getExternalForm
    ) {
      // we are the first doctor to see this, they are the primary doctor
      return metadata.getS12Doctor.legalName;
    } else if (
      field.name === "firstPractitionerAddress" &&
      !field.value &&
      !metadata.getForm &&
      metadata.getS12Doctor &&
      !metadata.getExternalForm
    ) {
      // we are the first doctor to see this, they are the primary doctor
      return metadata.getS12Doctor.sectionFormAddress;
    } else if (
      field.name === "firstPractitionerEmailAddress" &&
      !field.value &&
      !metadata.getForm &&
      metadata.getS12Doctor &&
      !metadata.getExternalForm &&
      metadata.getS12Doctor.sectionFormEmailAddress
    ) {
      return metadata.getS12Doctor.sectionFormEmailAddress;
    } else if (
      field.name === "secondPractitionerName" &&
      !field.value &&
      !metadata.getForm &&
      !metadata.getExternalForm
    ) {
      const doctorVisits = flattenDoctorVisits(metadata);
      // we are the first doctor to see this, they are the primary doctor
      const secondDr = doctorVisits?.find(item => item.doctor.id !== metadata.getS12Doctor?.id);

      return secondDr?.doctor.legalName || "";
    } else if (
      field.name === "secondPractitionerAddress" &&
      !field.value &&
      !metadata.getForm &&
      !metadata.getExternalForm
    ) {
      const doctorVisits = flattenDoctorVisits(metadata);

      // we are the first doctor to see this, they are the primary doctor
      const secondDr = doctorVisits?.find(item => item.doctor.id !== metadata.getS12Doctor?.id);

      return secondDr?.doctor.sectionFormAddress || "";
    } else if (field.name === "secondPractitionerEmailAddress" && !field.value && !metadata.getExternalForm) {
      const doctorVisits = flattenDoctorVisits(metadata);

      // we are the first doctor to see this, they are the primary doctor
      const secondDr = doctorVisits?.find(item => item.doctor.id !== metadata.getS12Doctor?.id);
      return secondDr?.doctor.sectionFormEmailAddress || "";
    } else if (field.name === "amhpName" && !field.value && metadata.getAmhpProfile?.name) {
      // only update if empty
      return metadata.getAmhpProfile?.name;
    } else if (field.name === "amhpAddress" && !field.value && metadata.getAmhpProfile?.sectionFormAddress) {
      // only update if empty
      return metadata.getAmhpProfile.sectionFormAddress;
    } else if (field.name === "amhpEmailAddress" && !field.value && metadata.getAmhpProfile?.sectionFormAddress) {
      // only update if empty
      return metadata.getAmhpProfile.sectionFormEmailAddress;
    } else if (
      field.name === "amhpContactNumber" &&
      !field.value &&
      metadata.getAmhpProfile?.sectionFormContactNumber
    ) {
      return metadata.getAmhpProfile.sectionFormContactNumber;
    } else if (field.name === "amhpTeamName" && metadata.getAssessment) {
      return metadata.getAssessment.amhpTeam.name;
    } else if (
      field.name === "confirmForms" &&
      !field.value &&
      metadata.getFormsByAssessment?.items &&
      metadata.getFormsByAssessment?.items?.filter(f => !!f && !!f.type && f.type !== FormType.AUTHORITY_TO_CONVEY)
        .length > 0
    ) {
      return ["electronic"];
    } else if (
      field.name === "formsBeingSubmittedElectronically" &&
      !field.value &&
      metadata.getFormsByAssessment?.items
    ) {
      // Output list of forms
      const makeTitleFromForm = (form: FormType) => {
        const applicationType = isAmhpForm(form)
          ? "AMHP application"
          : isJointForm(form)
          ? "Joint Medical Recommendation"
          : "Medical Recommendation";
        return "S" + Forms[form](2).section + " " + applicationType;
      };
      const formCounts: { [key: string]: number } = {};
      metadata.getFormsByAssessment.items
        .filter(
          f =>
            !!f &&
            !!f.type &&
            f.type !== FormType.AUTHORITY_TO_CONVEY &&
            (f?.status === "s40_signed" || f?.status === "s50_signed_and_sent")
        )
        .map(f => {
          if (!f?.type) return;
          const formattedKey = makeTitleFromForm(f.type as FormType);
          formCounts[formattedKey] = formCounts[formattedKey] ? (formCounts[formattedKey] += 1) : 1;
        });
      const formattedEntries = Object.entries(formCounts)
        .sort()
        .map(([key, value]) => {
          const suffix = value > 1 ? "s" : "";
          return value + "x " + key + suffix;
        })
        .join(",\n");
      return formattedEntries;
    }
  }
  switch (field.type) {
    case FormFieldType.Date:
      return dayjs(field.value || "");
    case FormFieldType.Location:
      return field.valueLocation;
    case FormFieldType.Checkbox:
      return field.valueArray;
    case FormFieldType.Selection:
      return field.value === "true";
    case FormFieldType.TextInput:
    default:
      return field.value;
  }
}

export type GetFormResponse = {
  getForm?: MHAForm;
  getExternalForm?: MHAForm;
  getAmhpProfile?: {
    id: string;
    name: string;
    legalName?: string;
    accreditation?: string;
    sectionFormAddress?: LocationNameInput;
    sectionFormEmailAddress?: string;
    sectionFormContactNumber?: string;
  };
  getS12Doctor?: {
    id: string;
    name: string;
    legalName: string;
    sectionFormAddress?: LocationNameInput;
    sectionFormEmailAddress?: string;
  };
  getAssessment?: {
    id: string;
    patientAddress?: LocationNameInput;
    patientName: string;
    amhpTeamId: string;
    amhpTeam: {
      name: string;
    };
  };
  visitsByAssessment?: {
    items: Array<{
      id: string;
      assessment: {
        id: string;
        amhpTeamId: string;
        patientName: string;
        patientAddress: LocationNameInput;
        doctorVisits: {
          items: Array<{
            doctor: {
              id: string;
              legalName: string;
              sectionFormAddress?: LocationNameInput;
              sectionFormEmailAddress?: string;
            };
          }>;
        };
      };
    } | null> | null;
  };
  getFormsByAssessment?: {
    items: Array<{
      status: string;
      type: string;
      data: {
        sections?: Array<{
          name?: string;
          fields?: any;
        }>;
      };
    } | null> | null;
  };
};
// interface GetFormResponse
// Note: we bring extra data in from getFormsByAssessment, so that we can access the data on previous forms
// on the same assessment, and copy data to the authority to convey form
export const GET_FORM = gql`
  query GetForm(
    $id: ID!
    $isExistingForm: Boolean!
    $isAmhpUser: Boolean!
    $isDoctorUser: Boolean!
    $isExternalDoctor: Boolean!
    $doctorId: ID!
    $amhpId: ID!
    $isAssessment: Boolean!
    $assessmentId: ID!
    $isConveyanceForm: Boolean!
  ) {
    getAssessment(id: $assessmentId) @include(if: $isAssessment) @skip(if: $isDoctorUser) {
      id
      amhpTeamId
      patientName
      patientAddress {
        city
        postcode
        address
      }
      amhpTeam {
        id
        name
      }
    }
    getAmhpProfile(id: $amhpId) @include(if: $isAmhpUser) @skip(if: $isDoctorUser) {
      id
      name
      legalName
      accreditation
      sectionFormAddress {
        address
        city
        postcode
      }
      sectionFormEmailAddress
      sectionFormContactNumber
    }
    getS12Doctor(id: $doctorId) @include(if: $isDoctorUser) @skip(if: $isAmhpUser) {
      id
      name
      legalName
      sectionFormAddress {
        address
        city
        postcode
      }
      sectionFormEmailAddress
    }
    visitsByAssessment(assessmentId: $assessmentId)
      @skip(if: $isAmhpUser)
      @include(if: $isAssessment)
      @include(if: $isDoctorUser) {
      items {
        id
        assessment {
          id
          amhpTeamId
          patientName
          patientAddress {
            city
            postcode
            address
          }
          doctorVisits {
            items {
              doctor {
                id
                legalName
                sectionFormAddress {
                  address
                  city
                  postcode
                }
                sectionFormEmailAddress
              }
            }
          }
        }
      }
    }
    getForm(id: $id, sk: "0000") @include(if: $isExistingForm) @skip(if: $isExternalDoctor) {
      id
      version
      assessmentId
      status
      createdBy
      amhpTeamInboxId
      amhpTeamId
      doctorNames
      authors
      notes
      type
      data {
        sections {
          name
          fields {
            name
            type
            valueArray
            valueLocation {
              city
              postcode
              address
            }
            value
          }
        }
      }
      signed
      sha256
      createdAt
    }
    getFormsByAssessment(gsi1pk: $assessmentId, gsi1sk: { eq: "0000" }, limit: 5) @include(if: $isConveyanceForm) {
      items {
        id
        status
        type
        data {
          sections {
            name
            fields {
              name
              type
              value
              valueLocation {
                city
                postcode
                address
              }
            }
          }
        }
      }
    }
    getExternalForm(id: $id, sk: "0000") @include(if: $isExternalDoctor) {
      id
      version
      assessmentId
      status
      createdBy
      amhpTeamInboxId
      amhpTeamId
      authors
      notes
      type
      data {
        sections {
          name
          fields {
            name
            type
            valueArray
            valueLocation {
              city
              postcode
              address
            }
            value
          }
        }
      }
      signed
      sha256
      createdAt
    }
  }
`;

interface MHAFormsList {
  id: string;
  version: number;
  assessmentId: string;
  status: FormStatus;
  createdBy: string;
  updatedAt: string;
  amhpTeamInboxId: string;
  amhpTaamId: string;
  authors: string[];
  notes: string[]; // formatted as EVENT_TYPE@USER_FULL_NAME(optional EMAIL or PHONE)@TIMESTAMP
  type: FormType;
}

export interface GetAssessmentFormsResponse {
  getFormsByAssessment: {
    items: Array<MHAFormsList | null> | null;
  };
}

// interface GetAssessmentFormsResponse
export const GET_ASSESSMEMT_FORMS = gql`
  query GetAssessmentFormS($assessmentId: ID!) {
    getFormsByAssessment(gsi1pk: $assessmentId, gsi1sk: { eq: "0000" }, limit: 100) {
      items {
        id
        version
        assessmentId
        status
        createdBy
        updatedAt
        amhpTeamInboxId
        amhpTeamId
        authors
        notes
        type
      }
    }
  }
`;

function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}
