import React from "react";
import { createStackNavigator } from "@react-navigation/stack";

import { CCGRejectedClaimsRoutes } from "@/navigationv2/types";
import screenOptions from "@/navigationv2/config/screenOptions";
import * as Screens from "@/screens";
import TabIcon from "../TabIcon";

const RejectedStack = createStackNavigator<CCGRejectedClaimsRoutes>();

const CCGRejectedClaimsStack = () => {
  return (
    <RejectedStack.Navigator initialRouteName="RejectedClaimsScreen" screenOptions={screenOptions}>
      <RejectedStack.Screen
        name="RejectedClaimsScreen"
        component={Screens.RejectedClaimsScreen}
        options={Screens.RejectedClaimsScreen.screenOptions}
      />
      <RejectedStack.Screen
        name="ClaimProcessingDetailsScreen"
        component={Screens.ClaimProcessingDetailsScreen}
        options={Screens.ClaimProcessingDetailsScreen.screenOptions}
      />
      <RejectedStack.Screen
        name="ClaimProcessingSearchScreen"
        component={Screens.ClaimProcessingSearchScreen}
        options={Screens.ClaimProcessingSearchScreen.screenOptions}
      />
    </RejectedStack.Navigator>
  );
};

CCGRejectedClaimsStack.tabOptions = {
  tabBarIcon: TabIcon("close"),
  tabBarLabel: "Rejected",
};

export default CCGRejectedClaimsStack;
