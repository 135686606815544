/* eslint-disable react-native/no-unused-styles */
import React from "react";
import { StyleSheet, View, ViewStyle } from "react-native";

import ItemSpacer from "@/components/ItemSpacer";
import { palette, spacing } from "@/theme";

interface MultiSelectContainerProps {
  children: React.ReactNode;
  disableMargin?: "top" | "bottom" | "topAndBottom";
}

export const MultiSelectContainer = ({ disableMargin, children }: MultiSelectContainerProps) => {
  const padding = disableMargin ? `${disableMargin}MarginDisabled` : "defaultMargin";
  return (
    <View style={[styles.list, styles[padding]]}>
      <ItemSpacer gap={10}>{children}</ItemSpacer>
    </View>
  );
};

const styles: { [key: string]: ViewStyle } = StyleSheet.create({
  list: {
    padding: 10,
    backgroundColor: palette.cloud,
  },

  defaultMargin: {
    marginVertical: spacing[20],
  },
});
