import React, { useMemo } from "react";
import { View, StyleSheet } from "react-native";
import dayjs from "dayjs";

import { FormStatus } from "libs/types/API";
import { FormType } from "libs/types/mhaForms";
import { isAmhpForm, isJointForm } from "libs/mhaForms/helpers";
import { TypographyType } from "libs/types/Typography";
import useIsWebView from "@/hooks/useIsWebView";
import { spacing } from "@/theme/spacing";

import Text from "@/components/Text";
import { ContentWrap } from "@/components/ContentWrap";

export default function SignatureBlock({
  status,
  type,
  signed,
  sha256,
}: {
  status: FormStatus;
  type: FormType;
  signed?: string[];
  sha256?: string;
}) {
  const isAmhpFormType = isAmhpForm(type);
  const isWebView = useIsWebView();
  // These states always have a signature block
  const globalSignatureStates = [
    FormStatus.s40_signed,
    FormStatus.s50_signed_and_sent,
    FormStatus.s60_signed_in_amhp_team_inbox,
  ];

  // Doctors sign form when it has either the s10_draft, s20_awaiting_other_doctor or s30_changes_in_progress status
  // present blank blocks here
  const doctorFormStatuses = [
    FormStatus.s10_draft,
    FormStatus.s20_awaiting_other_doctor,
    FormStatus.s30_changes_in_progress,
    FormStatus.s60_signed_in_amhp_team_inbox,
    ...globalSignatureStates,
  ];

  // AMHPs can self sign forms, which occurs when form has either the s10_draft or s30_changes_in_progress status
  // present blank blocks here
  const amhpFormStatuses = [FormStatus.s10_draft, FormStatus.s30_changes_in_progress, ...globalSignatureStates];

  // Hide the signature block if we're not in the right state to display it
  const shouldHideSignatureForAmhpForm = isAmhpFormType && !amhpFormStatuses.includes(status);
  const shouldHideSignatureForDoctorForm = !isAmhpFormType && !doctorFormStatuses.includes(status);
  if (shouldHideSignatureForAmhpForm || shouldHideSignatureForDoctorForm) {
    return <></>;
  }

  const signaturesAndDates: string[] = useMemo(() => {
    const lengthToPad = isJointForm(type) ? 2 : 1;
    const padSignatures = (arr: string[], length: number, value = "@") =>
      arr.concat(Array(length).fill(value)).slice(0, length);

    if (signed) {
      return padSignatures(signed, lengthToPad);
    } else {
      return new Array(lengthToPad).fill("@");
    }
  }, [signed]);

  const trackingIdSection = useMemo(() => {
    const shaSubstring = sha256?.substring(0, 16);
    const shaSections = shaSubstring?.match(new RegExp(".{1,4}", "g"));
    const joinedSha = shaSections?.join(" - ");

    return (
      <Text format={TypographyType.Small} style={{ fontStyle: "italic" }}>
        Tracking ID: {joinedSha}
      </Text>
    );
  }, [sha256]);

  const signaturesAndDatesSection = useMemo(
    () =>
      signaturesAndDates.map((signatureAndDate: string, i: number, signatureArray: any) => {
        const [signature, date] = signatureAndDate.split("@");

        return (
          <View key={i} style={[{ marginBottom: 10 }, isWebView ? styles.flexRow1 : styles.flexColumn1]}>
            <View style={[styles.flexColumn3, !isWebView && { marginRight: 10 }]}>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-end",
                  justifyContent: "space-between",
                  marginRight: 12,
                }}
              >
                <Text format={TypographyType.SmallBold}>Signed</Text>
                {signature && i === signatureArray.length - 1 ? trackingIdSection : false}
              </View>
              <Text format={TypographyType.Small} style={[styles.textInner, { marginRight: 10 }]}>
                {signature}
              </Text>
            </View>
            <View style={styles.flexColumn1}>
              <Text format={TypographyType.SmallBold}>Date</Text>
              <Text format={TypographyType.Small} style={styles.textInner}>
                {date && dayjs(date).format("DD/MM/YYYY")}
              </Text>
            </View>
          </View>
        );
      }),
    [signaturesAndDates, isWebView]
  );

  return (
    <ContentWrap>
      <View style={styles.contentWrapInner}>{signaturesAndDatesSection}</View>
    </ContentWrap>
  );
}

const styles = StyleSheet.create({
  contentWrapInner: { flexDirection: "column", marginTop: spacing[20] },
  flexRow1: { flexDirection: "row", flex: 1 },
  flexColumn1: { flexDirection: "column", flex: 1 },
  flexColumn3: { flexDirection: "column", flex: 3 },
  textInner: { backgroundColor: "white", paddingVertical: 5, paddingHorizontal: 10, minHeight: 25 },
});
