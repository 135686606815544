import React from "react";
import { IconButton } from "react-native-paper";
import { StyleProp, StyleSheet, ViewStyle } from "react-native";

import Icon from "../Icon";
import { color, palette } from "../../theme";

interface SquareButtonProps {
  icon: string;
  onPress?: () => void;
  iconColor?: string;
  style?: StyleProp<ViewStyle>;
}

export default function SquareButton(props: SquareButtonProps) {
  let { icon, iconColor, style, ...restProps } = props;

  const baseStyle: ViewStyle = {
    width: 60,
    height: 60,
    flexBasis: "auto",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
    borderRadius: 20,
    backgroundColor: color.secondary,
    paddingVertical: 16.5,
    paddingHorizontal: 16.5,
    elevation: 2,
  };

  const styles: {
    [key: string]: any;
    contained: Record<string, unknown>;
  } = StyleSheet.create({
    // eslint-disable-next-line react-native/no-unused-styles
    contained: {
      ...baseStyle,
      backgroundColor: color.secondary,
    },
  });

  style = [styles.contained, props.style];

  return (
    <IconButton
      {...restProps}
      icon={() => <Icon name={icon} color={iconColor || palette.white} size={24} style={style} />}
    />
  );
}

export const Phone = (props: { onPress?: () => void }) => {
  return <SquareButton onPress={props.onPress} icon="call" />;
};

export const Mobile = (props: { onPress?: () => void }) => {
  return <SquareButton onPress={props.onPress} icon="phone-android" />;
};

export const Email = (props: { onPress?: () => void }) => {
  return <SquareButton onPress={props.onPress} icon="mail-outline" />;
};

export const Map = (props: { onPress?: () => void }) => {
  return <SquareButton onPress={props.onPress} icon="map" />;
};
