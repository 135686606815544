/**
 * Doctor page component to display the list of their unattached forms
 */
import React, { useMemo } from "react";
import { StyleSheet, View } from "react-native";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

import { amhpTeamInbox } from "libs/utils/featureFlags";
import notFalsy from "libs/utils/notFalsy";
import { FormType } from "libs/types/mhaForms";
import { mqWeb } from "@/utils/helpers";
import { spacing } from "@/theme";

import { TypographyType } from "@/models/Typography";
import { Button, ButtonList } from "@/components/Button";
import { ContentWrap } from "@/components/ContentWrap";
import { useCreateFormsBottomSheet } from "@/components/Forms/MHACreateFormItem";
import DoctorAssignedFormsList from "@/components/DoctorAssignedForms";
import Text from "@/components/Text";
import { WebSectionButton } from "@/components/WebSectionButton";

interface DoctorUnattachedFormsProps {
  navigation: any;
  siteName?: string;
}

const amhpTeamQuery = gql`
  query GetSite($id: ID!) {
    getSite(id: $id) {
      amhpTeams {
        items {
          id
          name
          featureFlags
        }
      }
    }
  }
`;

export const DoctorUnattachedForms = (props: DoctorUnattachedFormsProps) => {
  if (!props.siteName) {
    return <></>;
  }

  const isWebView = mqWeb();
  const openBottomSheet = useCreateFormsBottomSheet([FormType.A4, FormType.A8, FormType.A11]);
  const { data } = useQuery<{
    getSite: {
      amhpTeams: {
        items: Array<{
          id: string;
          name: string;
          featureFlags: string;
        } | null> | null;
      } | null;
    };
  }>(amhpTeamQuery, {
    variables: {
      id: props.siteName,
    },
    fetchPolicy: "cache-and-network",
  });

  const hasFeatureEnabled = useMemo(
    () =>
      data?.getSite?.amhpTeams?.items
        ?.filter(notFalsy)
        .map(t => t.featureFlags)
        .some(f => amhpTeamInbox(f)),
    [data?.getSite?.amhpTeams?.items]
  );

  const amhpTeams = useMemo(
    () =>
      data?.getSite?.amhpTeams?.items
        ?.filter(notFalsy)
        .filter(team => amhpTeamInbox(team.featureFlags))
        .map(i => ({
          id: i?.id || "",
          name: i?.name || "",
        })) || [],
    [data?.getSite?.amhpTeams?.items]
  );

  const Wrapper = isWebView ? React.Fragment : ContentWrap;

  return hasFeatureEnabled ? (
    <>
      <Wrapper>
        <View style={styles.webHeading}>
          <Text format={TypographyType.HeadingMedium}>MHA Forms</Text>
          {isWebView && <WebSectionButton label="Create Form" theme="primary" onPress={openBottomSheet} />}
        </View>
        <Text format={TypographyType.Regular}>
          Create MHA statutory forms, for patient to be assigned to an AMHP team.
        </Text>
        {!isWebView && (
          <ButtonList disablePadding="bottom">
            <Button onPress={openBottomSheet}>Create Form</Button>
          </ButtonList>
        )}
      </Wrapper>
      <DoctorAssignedFormsList amhpTeams={amhpTeams} />
    </>
  ) : (
    <></>
  );
};

const styles = StyleSheet.create({
  webHeading: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginBottom: spacing[10],
  },
});
