import React from "react";
import { StyleProp, View, ViewStyle } from "react-native";
import Svg, { Path } from "react-native-svg";
import { palette } from "../../theme";

const LogoVector = ({
  size = 45,
  style,
  greyscale = false,
}: {
  size?: number;
  style?: StyleProp<ViewStyle>;
  greyscale?: boolean;
}) => {
  const fillColorOne = greyscale ? palette.grey : palette.blue;
  const fillColorTwo = greyscale ? palette.grey : palette.aqua;
  return (
    <View style={style}>
      <Svg width={size} height={size} viewBox="0 0 45 45" fill="none">
        {!greyscale && (
          <Path
            d="M45 22.5C45 34.9264 34.9264 45 22.5 45C10.0736 45 0 34.9264 0 22.5C0 10.0736 10.0736 0 22.5 0C34.9264 0 45 10.0736 45 22.5Z"
            fill="white"
          />
        )}
        <Path
          d="M28.2022 12.4656C28.2022 13.8273 27.0933 14.9312 25.7253 14.9312C25.1881 14.9312 24.6908 14.7609 24.285 14.4717C24.2844 14.471 24.2838 14.4711 24.2832 14.4704C24.1073 14.3449 23.9487 14.197 23.8114 14.0309C23.408 13.7425 22.9155 13.573 22.384 13.573C21.8532 13.573 21.3614 13.742 20.9582 14.0296C20.8203 14.1968 20.6607 14.3456 20.4837 14.4717C20.4838 14.4715 20.4835 14.4719 20.4837 14.4717C20.0779 14.7607 19.5803 14.9312 19.0433 14.9312C17.6754 14.9312 16.5664 13.8273 16.5664 12.4656C16.5664 11.1039 17.6754 10 19.0433 10C19.5803 10 20.0774 10.1701 20.4832 10.4591C20.4835 10.4596 20.4839 10.4595 20.4843 10.4599C20.6608 10.5858 20.82 10.7342 20.9577 10.9009C21.361 11.1888 21.853 11.3579 22.384 11.3579C22.9157 11.3579 23.4082 11.1884 23.8117 10.8999C23.9489 10.734 24.1074 10.5863 24.283 10.4608C24.2837 10.4601 24.2843 10.4593 24.285 10.4585C24.6908 10.1693 25.1881 10 25.7253 10C27.0933 10 28.2022 11.1039 28.2022 12.4656Z"
          fill={fillColorTwo}
        />
        <Path
          d="M34.8838 25.8829C34.8838 27.2446 33.7748 28.3485 32.4068 28.3485C31.8698 28.3485 31.3726 28.1783 30.9668 27.8892C30.9664 27.8888 30.966 27.8889 30.9656 27.8884C30.7892 27.7625 30.6301 27.6142 30.4925 27.4476C30.0892 27.1596 29.5971 26.9904 29.0659 26.9904C28.5352 26.9904 28.0434 27.1593 27.6403 27.4469C27.5023 27.6142 27.3425 27.7631 27.1654 27.8893C27.1655 27.8892 27.1653 27.8895 27.1654 27.8893C26.7596 28.1784 26.2622 28.3487 25.7252 28.3487C24.3572 28.3487 23.2482 27.2448 23.2482 25.8831C23.2482 24.5214 24.3572 23.4175 25.7252 23.4175C26.2622 23.4175 26.7593 23.5876 27.165 23.8767C27.1656 23.8773 27.1661 23.8772 27.1666 23.8778C27.3428 24.0035 27.5016 24.1516 27.639 24.3179C28.0424 24.606 28.5346 24.7753 29.0659 24.7753C29.5972 24.7753 30.0894 24.606 30.4927 24.3179C30.6302 24.1515 30.7892 24.0033 30.9654 23.8776C30.9659 23.877 30.9664 23.8764 30.9668 23.8758C31.3726 23.5868 31.8698 23.4173 32.4068 23.4173C33.7748 23.4173 34.8838 24.5212 34.8838 25.8829Z"
          fill={fillColorTwo}
        />
        <Path
          d="M25.7253 10C27.0933 10 28.2022 11.1039 28.2022 12.4656C28.2022 13.234 27.8489 13.9201 27.2954 14.3723C27.0065 14.7735 26.8369 15.263 26.8369 15.7912C26.8369 16.3186 27.006 16.8074 27.294 17.2083C27.8488 17.6604 28.2028 18.3475 28.2028 19.1168C28.2028 20.4785 27.0938 21.5824 25.7258 21.5824C24.3578 21.5824 23.2489 20.4785 23.2489 19.1168C23.2489 18.5822 23.4198 18.0872 23.7103 17.6833H23.7086C23.71 17.6821 23.7115 17.6809 23.7129 17.6797C23.8382 17.5062 23.9855 17.3495 24.1509 17.2138C24.4413 16.8119 24.612 16.321 24.612 15.7912C24.612 15.2619 24.4417 14.7716 24.1518 14.3699C23.9853 14.2335 23.8371 14.0759 23.7112 13.9012C23.7102 13.9005 23.7093 13.8997 23.7083 13.8989H23.7095C23.419 13.495 23.2481 13.0001 23.2481 12.4655C23.2481 11.1038 24.3573 10 25.7253 10Z"
          fill={fillColorTwo}
        />
        <Path
          d="M25.725 16.766C27.093 16.766 28.202 17.8699 28.202 19.2316C28.202 19.7663 28.031 20.2612 27.7405 20.6652C27.7405 20.6652 27.7404 20.6652 27.7405 20.6652C27.6135 20.8416 27.4638 21.0008 27.2955 21.1383C27.0066 21.5396 26.8369 22.0291 26.8369 22.5574C26.8369 23.0847 27.0059 23.5732 27.2937 23.9741C27.8486 24.4262 28.2028 25.1134 28.2028 25.8829C28.2028 27.2446 27.0931 28.3487 25.7252 28.3487C24.3572 28.3487 23.2482 27.2448 23.2482 25.8831C23.2482 25.3485 23.4198 24.8535 23.7102 24.4495H23.7085C23.7099 24.4484 23.7112 24.4473 23.7126 24.4462C23.838 24.2724 23.9855 24.1155 24.1511 23.9796C24.4414 23.5778 24.6119 23.0871 24.6119 22.5574C24.6119 22.028 24.4415 21.5376 24.1515 21.1358C23.9853 20.9996 23.8372 20.8421 23.7114 20.6677C23.7104 20.6669 23.7093 20.666 23.7083 20.6652H23.7096C23.4191 20.2612 23.2481 19.7663 23.2481 19.2316C23.2481 17.8699 24.357 16.766 25.725 16.766Z"
          fill={fillColorTwo}
        />
        <Path
          d="M32.5225 16.766C33.8904 16.766 34.9994 17.8699 34.9994 19.2316C34.9994 19.7663 34.8284 20.2612 34.5379 20.6652C34.5371 20.6658 34.5373 20.6665 34.5365 20.6671C34.4105 20.842 34.262 20.9998 34.0953 21.1364C33.8055 21.538 33.6353 22.0283 33.6353 22.5574C33.6353 23.0859 33.8051 23.5756 34.0942 23.977C34.2619 24.1142 34.4112 24.2728 34.5378 24.4488C34.5381 24.449 34.5384 24.4493 34.5387 24.4495C34.8291 24.8535 34.9997 25.3483 34.9997 25.8829C34.9997 27.2446 33.8907 28.3485 32.5227 28.3485C31.1548 28.3485 30.0458 27.2446 30.0458 25.8829C30.0458 25.3483 30.2167 24.8535 30.5071 24.4495C30.5069 24.4497 30.5073 24.4494 30.5071 24.4495C30.6338 24.2733 30.7836 24.114 30.9516 23.9767C31.2406 23.5754 31.4103 23.0858 31.4103 22.5574C31.4103 22.0288 31.2405 21.5391 30.9512 21.1377C30.7835 21.0005 30.6341 20.8418 30.5075 20.6658C30.5072 20.6656 30.507 20.6654 30.5067 20.6652C30.2162 20.2612 30.0455 19.7663 30.0455 19.2316C30.0455 17.8699 31.1545 16.766 32.5225 16.766Z"
          fill={fillColorTwo}
        />
        <Path
          d="M21.6357 32.5344C21.6357 33.8961 20.5267 35 19.1588 35C17.7908 35 16.6818 33.8961 16.6818 32.5344C16.6818 31.1727 17.7908 30.0688 19.1588 30.0688C20.5267 30.0688 21.6357 31.1727 21.6357 32.5344Z"
          fill={fillColorOne}
        />
        <Path
          d="M13.9171 21.2379C13.5113 21.5271 13.0141 21.6973 12.4769 21.6973C11.109 21.6973 10 20.5934 10 19.2317C10 17.87 11.109 16.7661 12.4769 16.7661C13.0141 16.7661 13.5113 16.9363 13.9171 17.2255C13.9168 17.2252 13.9173 17.2257 13.9171 17.2255C14.0938 17.3515 14.2539 17.5006 14.3917 17.6675C14.795 17.9554 15.287 18.1245 15.818 18.1245C16.3488 18.1245 16.8406 17.9555 17.2438 17.6678C17.3817 17.5007 17.5413 17.3519 17.7183 17.2257C17.7182 17.2259 17.7185 17.2255 17.7183 17.2257C18.1241 16.9367 18.6218 16.7662 19.1588 16.7662C20.5267 16.7662 21.6357 17.8701 21.6357 19.2318C21.6357 20.5935 20.5267 21.6973 19.1588 21.6973C18.6218 21.6973 18.1247 21.5272 17.7189 21.2382C17.7185 21.2377 17.718 21.2378 17.7176 21.2373C17.5412 21.1115 17.3821 20.9632 17.2445 20.7966C16.8412 20.5086 16.3491 20.3394 15.818 20.3394C15.2865 20.3394 14.7941 20.5088 14.3907 20.7971C14.2534 20.9632 14.0947 21.1111 13.9188 21.2367C13.9182 21.2374 13.9176 21.2372 13.9171 21.2379Z"
          fill={fillColorOne}
        />
        <Path
          d="M21.6357 25.883C21.6357 27.2447 20.5267 28.3486 19.1588 28.3486C18.6218 28.3486 18.1247 28.1785 17.7189 27.8894C17.7191 27.8897 17.7187 27.8892 17.7189 27.8894C17.542 27.7633 17.3818 27.6141 17.2439 27.4471C16.8407 27.1593 16.3488 26.9903 15.818 26.9903C15.287 26.9903 14.7951 27.1594 14.3919 27.4471C14.254 27.6142 14.0945 27.763 13.9176 27.8891C13.9177 27.8889 13.9174 27.8893 13.9176 27.8891C13.5118 28.1783 13.0141 28.3488 12.4769 28.3488C11.109 28.3488 10 27.2449 10 25.8832C10 24.5215 11.109 23.4176 12.4769 23.4176C13.0141 23.4176 13.5113 23.5878 13.9171 23.877C13.9177 23.8778 13.9184 23.8776 13.919 23.8784C14.0947 24.0038 14.2533 24.1516 14.3905 24.3175C14.794 24.606 15.2864 24.7754 15.818 24.7754C16.3491 24.7754 16.8412 24.6062 17.2444 24.3183C17.3821 24.1516 17.5412 24.0033 17.7177 23.8774C17.7181 23.8769 17.7185 23.8765 17.7189 23.876C18.1247 23.5869 18.6218 23.4174 19.1588 23.4174C20.5267 23.4174 21.6357 24.5213 21.6357 25.883Z"
          fill={fillColorOne}
        />
        <Path
          d="M12.4769 16.7661C13.8449 16.7661 14.9539 17.87 14.9539 19.2317C14.9539 19.7664 14.7829 20.2613 14.4924 20.6653C14.4917 20.6658 14.4919 20.6664 14.4912 20.6669C14.365 20.8421 14.2163 21.0001 14.0494 21.1368C13.7597 21.5384 13.5896 22.0285 13.5896 22.5575C13.5896 23.0859 13.7593 23.5754 14.0482 23.9767C14.2163 24.114 14.3659 24.273 14.4927 24.4493C14.4925 24.4492 14.4928 24.4494 14.4927 24.4493C14.7831 24.8532 14.9542 25.3484 14.9542 25.883C14.9542 27.2447 13.8449 28.3488 12.4769 28.3488C11.109 28.3488 10 27.2449 10 25.8832C10 25.3486 10.1712 24.8536 10.4616 24.4497C10.462 24.4494 10.4619 24.4491 10.4623 24.4488C10.5888 24.2729 10.738 24.1143 10.9056 23.9772C11.1948 23.5758 11.3647 23.0861 11.3647 22.5575C11.3647 22.0288 11.1947 21.539 10.9054 21.1375C10.7378 21.0005 10.5887 20.842 10.4622 20.6662C10.4618 20.6659 10.4614 20.6656 10.461 20.6653C10.1705 20.2613 10 19.7664 10 19.2317C10 17.87 11.109 16.7661 12.4769 16.7661Z"
          fill={fillColorOne}
        />
        <Path
          d="M25.7255 23.4172C27.0935 23.4172 28.2024 24.5211 28.2024 25.8828C28.2024 26.4176 28.0314 26.9126 27.7408 27.3166C27.7409 27.3165 27.7407 27.3166 27.7408 27.3166C27.614 27.4928 27.4643 27.6519 27.2963 27.7893C27.0072 28.1906 26.8374 28.6803 26.8374 29.2088C26.8374 29.736 27.0064 30.2246 27.2942 30.6254C27.8491 31.0776 28.2032 31.7647 28.2032 32.5342C28.2032 33.8959 27.0943 34.9998 25.7263 34.9998C24.3583 34.9998 23.2493 33.8959 23.2493 32.5342C23.2493 31.9997 23.4202 31.5048 23.7106 31.1009H23.7089C23.7103 31.0998 23.7117 31.0986 23.7131 31.0975C23.8385 30.9237 23.986 30.7669 24.1515 30.631C24.4418 30.2292 24.6124 29.7385 24.6124 29.2088C24.6124 28.6792 24.4419 28.1886 24.1516 27.7867C23.9857 27.6507 23.8378 27.4935 23.7122 27.3194C23.7111 27.3184 23.7099 27.3175 23.7088 27.3166H23.7102C23.4196 26.9126 23.2486 26.4176 23.2486 25.8828C23.2486 24.5211 24.3575 23.4172 25.7255 23.4172Z"
          fill={fillColorOne}
        />
        <Path
          d="M28.2024 25.8828C28.2024 27.2445 27.0937 28.3486 25.7258 28.3486C25.1886 28.3486 24.6913 28.1783 24.2854 27.8891C24.2848 27.8884 24.2842 27.8886 24.2836 27.8878C24.1078 27.7623 23.9492 27.6144 23.812 27.4484C23.4085 27.1599 22.916 26.9905 22.3844 26.9905C21.8538 26.9905 21.3622 27.1593 20.9591 27.4468C20.821 27.6143 20.6611 27.7633 20.4837 27.8896C20.4838 27.8896 20.4837 27.8897 20.4837 27.8896C20.078 28.1786 19.5808 28.3488 19.0438 28.3488C17.6758 28.3488 16.5669 27.2449 16.5669 25.8832C16.5669 24.5215 17.6758 23.4176 19.0438 23.4176C19.5808 23.4176 20.0779 23.5877 20.4836 23.8767C20.4841 23.8774 20.4846 23.8772 20.4852 23.8778C20.6614 24.0035 20.8203 24.1517 20.9578 24.3181C21.3611 24.6061 21.8533 24.7754 22.3845 24.7754C22.9161 24.7754 23.4086 24.6059 23.8121 24.3174C23.9493 24.1515 24.1078 24.0037 24.2835 23.8782C24.2842 23.8775 24.2848 23.8767 24.2854 23.8759C24.6913 23.5867 25.1883 23.4172 25.7255 23.4172C27.0935 23.4172 28.2024 24.5211 28.2024 25.8828Z"
          fill={fillColorOne}
        />
      </Svg>
    </View>
  );
};

export default React.memo(LogoVector);
