import * as Yup from "yup";
import { ComponentElement, FormFieldsAmhp, Itemisation } from "libs/types/mhaForms";
const componentElements: ComponentElement[] = [
  {
    component: "Selection",
    fieldName: FormFieldsAmhp.NearestRelativeConsulted,
    componentProps: {
      label: "Did consultation with the nearest relative take place?",
      options: [
        {
          id: "-",
          name: "-",
        },
        {
          id: "yes",
          name: "Yes",
        },
        {
          id: "no",
          name: "No",
        },
      ],
    },
  },
  {
    component: "Text",
    componentProps: {
      guidance: "Complete (a) or (b) as applicable",
      pdfOnlyGuidance:
        "Complete the following where consultation with the nearest relative has taken place.\nComplete (a) or (b) and delete the other.",
    },
    genericProps: {
      conditionallyRenderedBy: FormFieldsAmhp.NearestRelativeConsulted,
      conditionallyRenderedValues: ["yes"],
    },
    fullWidth: true,
  },
  {
    component: "RadioOptionsList",
    fieldName: FormFieldsAmhp.DegreeOfCertainty,
    componentProps: {
      options: [
        {
          label: "I have consulted (PRINT full name and address)",
          value: "knowledge",
          prefix: "(a)",
        },
      ],
    },
    genericProps: {
      nullable: true,
      conditionallyRenderedBy: FormFieldsAmhp.NearestRelativeConsulted,
      conditionallyRenderedValues: ["yes"],
      indentation: 1,
    },
  },
  {
    component: "TextInput",
    fieldName: FormFieldsAmhp.KnownRelativeName,
    componentProps: {
      label: "Name",
    },
    genericProps: {
      conditionallyRenderedBy: FormFieldsAmhp.NearestRelativeConsulted,
      conditionallyRenderedValues: ["yes"],
      conditionallyDisabledBy: FormFieldsAmhp.DegreeOfCertainty,
      conditionallyDisabledValues: ["understanding"],
      indentation: 2,
    },
  },
  {
    component: "Location",
    fieldName: FormFieldsAmhp.KnownRelativeAddress,
    componentProps: {
      disableStrictPostcodeValidation: true,
      label: "Address",
    },
    genericProps: {
      conditionallyRenderedBy: FormFieldsAmhp.NearestRelativeConsulted,
      conditionallyRenderedValues: ["yes"],
      conditionallyDisabledBy: FormFieldsAmhp.DegreeOfCertainty,
      conditionallyDisabledValues: ["understanding"],
      indentation: 2,
    },
  },
  {
    component: "Text",
    componentProps: {
      instruction:
        "who to the best of my knowledge and belief is the patient's nearest relative within the meaning of the Act.",
    },
    genericProps: {
      conditionallyRenderedBy: FormFieldsAmhp.NearestRelativeConsulted,
      conditionallyRenderedValues: ["yes"],
      conditionallyDisabledBy: FormFieldsAmhp.DegreeOfCertainty,
      conditionallyDisabledValues: ["understanding"],
      indentation: 2,
    },
    fullWidth: true,
  },
  {
    component: "RadioOptionsList",
    fieldName: FormFieldsAmhp.DegreeOfCertainty,
    componentProps: {
      options: [
        {
          label: "I have consulted (PRINT full name and address)",
          value: "understanding",
          prefix: "(b)",
        },
      ],
    },
    genericProps: {
      conditionallyRenderedBy: FormFieldsAmhp.NearestRelativeConsulted,
      conditionallyRenderedValues: ["yes"],
      indentation: 1,
    },
  },
  {
    component: "TextInput",
    fieldName: FormFieldsAmhp.SuspectedRelativeName,
    componentProps: {
      label: "Name",
    },
    genericProps: {
      conditionallyRenderedBy: FormFieldsAmhp.NearestRelativeConsulted,
      conditionallyRenderedValues: ["yes"],
      conditionallyDisabledBy: FormFieldsAmhp.DegreeOfCertainty,
      conditionallyDisabledValues: ["knowledge"],
      indentation: 2,
    },
  },
  {
    component: "Location",
    fieldName: FormFieldsAmhp.SuspectedRelativeAddress,
    componentProps: {
      disableStrictPostcodeValidation: true,
      label: "Address",
    },
    genericProps: {
      conditionallyRenderedBy: FormFieldsAmhp.NearestRelativeConsulted,
      conditionallyRenderedValues: ["yes"],
      conditionallyDisabledBy: FormFieldsAmhp.DegreeOfCertainty,
      conditionallyDisabledValues: ["knowledge"],
      indentation: 2,
    },
  },
  {
    component: "Text",
    componentProps: {
      instruction: "who I understand has been authorised by",
    },
    genericProps: {
      conditionallyRenderedBy: FormFieldsAmhp.NearestRelativeConsulted,
      conditionallyRenderedValues: ["yes"],
      conditionallyDisabledBy: FormFieldsAmhp.DegreeOfCertainty,
      conditionallyDisabledValues: ["knowledge"],
      indentation: 2,
    },
    fullWidth: true,
  },
  {
    component: "RadioOptionsList",
    fieldName: FormFieldsAmhp.AuthorisedBy,
    componentProps: {
      itemisation: Itemisation.ROMAN,
      options: [
        {
          label: "a county court",
          value: "countyCourt",
        },
        {
          label: "the patient's nearest relative",
          value: "nearestRelative",
        },
      ],
    },
    genericProps: {
      nullable: true,
      conditionallyRenderedBy: FormFieldsAmhp.NearestRelativeConsulted,
      conditionallyRenderedValues: ["yes"],
      conditionallyDisabledBy: FormFieldsAmhp.DegreeOfCertainty,
      conditionallyDisabledValues: ["knowledge"],
      indentation: 2,
    },
  },
  {
    component: "Text",
    componentProps: {
      instruction: "to exercise the functions under the Act of the patient’s nearest relative.",
      guidance: "(Select the phrase which applies)",
      pdfOnlyGuidance: "(* Delete the phrase which does not apply)",
    },
    genericProps: {
      conditionallyRenderedBy: FormFieldsAmhp.NearestRelativeConsulted,
      conditionallyRenderedValues: ["yes"],
      conditionallyDisabledBy: FormFieldsAmhp.DegreeOfCertainty,
      conditionallyDisabledValues: ["knowledge"],
      indentation: 2,
    },
    fullWidth: true,
  },
  {
    component: "Text",
    componentProps: {
      instruction:
        "That person has not notified me or the local social services authority on whose behalf I am acting that he or she objects to this application being made.",
    },
    genericProps: {
      conditionallyRenderedBy: FormFieldsAmhp.NearestRelativeConsulted,
      conditionallyRenderedValues: ["yes"],
      conditionallyDisabledBy: FormFieldsAmhp.DegreeOfCertainty,
      conditionallyDisabledValues: ["knowledge"],
    },
    fullWidth: true,
  },
  {
    component: "Text",
    componentProps: {
      guidance: "Select (a), (b) or (c)",
      pdfOnlyGuidance:
        "Complete the following where the nearest relative has not been consulted.\nDelete whichever two of (a), (b) and (c) do not apply.",
    },
    genericProps: {
      conditionallyRenderedBy: FormFieldsAmhp.NearestRelativeConsulted,
      conditionallyRenderedValues: ["no"],
    },
    fullWidth: true,
  },
  {
    component: "RadioOptionsList",
    fieldName: FormFieldsAmhp.NotConsulted,
    componentProps: {
      itemisation: Itemisation.ALPHABETICAL,
      options: [
        {
          label:
            "I have been unable to ascertain who is this patient's nearest relative within the meaning of the Act.",
          value: "unableToAscertain",
        },
        {
          label:
            "To the best of my knowledge and belief this patient has no nearest relative within the meaning of the Act.",
          value: "bestKnowledge",
        },
        {
          label: "I understand that (PRINT full name and address)",
          value: "understanding",
        },
      ],
    },
    genericProps: {
      nullable: true,
      conditionallyRenderedBy: FormFieldsAmhp.NearestRelativeConsulted,
      conditionallyRenderedValues: ["no"],
      indentation: 1,
    },
  },
  {
    component: "TextInput",
    fieldName: FormFieldsAmhp.SuspectedRelativeNotConsultedName,
    componentProps: {
      label: "Name",
    },
    genericProps: {
      conditionallyRenderedBy: FormFieldsAmhp.NearestRelativeConsulted,
      conditionallyRenderedValues: ["no"],
      conditionallyDisabledBy: FormFieldsAmhp.NotConsulted,
      conditionallyDisabledValues: ["unableToAscertain", "bestKnowledge"],
      indentation: 2,
    },
  },
  {
    component: "Location",
    fieldName: FormFieldsAmhp.SuspectedRelativeNotConsultedAddress,
    componentProps: {
      disableStrictPostcodeValidation: true,
      label: "Address",
    },
    genericProps: {
      conditionallyRenderedBy: FormFieldsAmhp.NearestRelativeConsulted,
      conditionallyRenderedValues: ["no"],
      conditionallyDisabledBy: FormFieldsAmhp.NotConsulted,
      conditionallyDisabledValues: ["unableToAscertain", "bestKnowledge"],
      indentation: 2,
    },
  },
  {
    component: "Text",
    componentProps: {
      instruction: "is",
    },
    genericProps: {
      conditionallyRenderedBy: FormFieldsAmhp.NearestRelativeConsulted,
      conditionallyRenderedValues: ["no"],
      conditionallyDisabledBy: FormFieldsAmhp.NotConsulted,
      conditionallyDisabledValues: ["unableToAscertain", "bestKnowledge"],
      indentation: 2,
    },
    fullWidth: true,
  },
  {
    component: "RadioOptionsList",
    fieldName: FormFieldsAmhp.NotConsultedRelativeRelation,
    componentProps: {
      itemisation: Itemisation.ROMAN,
      options: [
        {
          label: "this patient's nearest relative within the meaning of the Act",
          value: "nearestRelative",
        },
        {
          label: "authorised to exercise the functions of this patient's nearest relative under the Act",
          value: "authorised",
        },
      ],
    },
    genericProps: {
      nullable: true,
      conditionallyRenderedBy: FormFieldsAmhp.NearestRelativeConsulted,
      conditionallyRenderedValues: ["no"],
      conditionallyDisabledBy: FormFieldsAmhp.NotConsulted,
      conditionallyDisabledValues: ["unableToAscertain", "bestKnowledge"],
      indentation: 2,
    },
  },
  {
    component: "Text",
    componentProps: { pdfOnlyGuidance: "Delete either (i) or (ii)" },
    genericProps: {
      indentation: 2,
    },
    fullWidth: true,
  },
  {
    component: "Text",
    componentProps: {
      instruction: "but in my opinion it",
    },
    genericProps: {
      conditionallyRenderedBy: FormFieldsAmhp.NearestRelativeConsulted,
      conditionallyRenderedValues: ["no"],
      conditionallyDisabledBy: FormFieldsAmhp.NotConsulted,
      conditionallyDisabledValues: ["unableToAscertain", "bestKnowledge"],
      indentation: 1,
    },
    fullWidth: true,
  },
  {
    component: "RadioOptionsList",
    fieldName: FormFieldsAmhp.ReasonForNoConsultation,
    componentProps: {
      itemisation: Itemisation.ROMAN,
      options: [
        {
          label: "is not reasonably practicable",
          value: "unpracticable",
        },
        {
          label: "would involve unreasonable delay",
          value: "delay",
        },
      ],
    },
    genericProps: {
      nullable: true,
      conditionallyRenderedBy: FormFieldsAmhp.NearestRelativeConsulted,
      conditionallyRenderedValues: ["no"],
      conditionallyDisabledBy: FormFieldsAmhp.NotConsulted,
      conditionallyDisabledValues: ["unableToAscertain", "bestKnowledge"],
      indentation: 1,
    },
  },
  {
    component: "Text",
    componentProps: {
      pdfOnlyGuidance: "delete as appropriate",
    },
    genericProps: {
      indentation: 1,
    },
  },
  {
    component: "Text",
    componentProps: {
      instruction: "to consult that person before making this application, because -",
    },
    genericProps: {
      conditionallyRenderedBy: FormFieldsAmhp.NearestRelativeConsulted,
      conditionallyRenderedValues: ["no"],
      conditionallyDisabledBy: FormFieldsAmhp.NotConsulted,
      conditionallyDisabledValues: ["unableToAscertain", "bestKnowledge"],
      indentation: 1,
    },
    fullWidth: true,
  },
  {
    component: "BottomTextInput",
    fieldName: FormFieldsAmhp.Opinion,
    fullWidth: true,
    componentProps: {
      label: "Opinion",
      buttonLabel: "Opinion",
      defaultText: "No details currently given",
    },
    genericProps: {
      nullable: true,
      conditionallyRenderedBy: FormFieldsAmhp.NearestRelativeConsulted,
      conditionallyRenderedValues: ["no"],
      conditionallyDisabledBy: FormFieldsAmhp.NotConsulted,
      conditionallyDisabledValues: ["unableToAscertain", "bestKnowledge"],
      indentation: 1,
    },
  },
  {
    component: "Text",
    componentProps: {
      pdfOnlyGuidance: "The remainder of the form must be completed in all cases.",
    },
  },
];

export default {
  title: "Nearest Relative",
  state: {
    nearestRelativeConsulted: "-",
    degreeOfCertainty: "knowledge",
    knownRelativeName: "",
    knownRelativeAddress: {
      city: "",
      postcode: "",
      address: "",
    },
    suspectedRelativeName: "",
    suspectedRelativeAddress: {
      city: "",
      postcode: "",
      address: "",
    },
    suspectedRelativeNotConsultedName: "",
    suspectedRelativeNotConsultedAddress: {
      city: "",
      postcode: "",
      address: "",
    },
    authorisedBy: "",
    notConsulted: "",
    notConsultedRelativeRelation: "",
    reasonForNoConsultation: "",
    opinion: "",
  },

  validation: Yup.object().shape({
    nearestRelativeConsulted: Yup.mixed()
      .oneOf(["yes", "no"], "Please select a valid option")
      .required("Please select a valid option"),
    degreeOfCertainty: Yup.mixed().when(["nearestRelativeConsulted"], {
      is: (nearestRelativeConsulted) => nearestRelativeConsulted === "yes",
      then: Yup.mixed()
        .oneOf(["knowledge", "understanding"], "Please select a valid option")
        .required("Please select a valid option"),
      otherwise: Yup.string(),
    }),
    knownRelativeName: Yup.mixed().when(["nearestRelativeConsulted", "degreeOfCertainty"], {
      is: (nearestRelativeConsulted, degreeOfCertainty) =>
        nearestRelativeConsulted === "yes" && degreeOfCertainty === "knowledge",
      then: Yup.string().trim().required("Please enter a name"),
      otherwise: Yup.string(),
    }),
    knownRelativeAddress: Yup.mixed().when(["nearestRelativeConsulted", "degreeOfCertainty"], {
      is: (nearestRelativeConsulted, degreeOfCertainty) =>
        nearestRelativeConsulted === "yes" && degreeOfCertainty === "knowledge",
      then: Yup.object().shape({
        address: Yup.string().nullable(),
        city: Yup.string().nullable(),
        postcode: Yup.string().trim().required("Please enter an address"),
      }),
      otherwise: Yup.object().shape({
        address: Yup.string(),
        city: Yup.string(),
        postcode: Yup.string(),
      }),
    }),

    suspectedRelativeName: Yup.mixed().when(["nearestRelativeConsulted", "degreeOfCertainty"], {
      is: (nearestRelativeConsulted, degreeOfCertainty) =>
        nearestRelativeConsulted === "yes" && degreeOfCertainty === "understanding",
      then: Yup.string().trim().required("Please enter a name"),
      otherwise: Yup.string(),
    }),
    suspectedRelativeAddress: Yup.mixed().when(["nearestRelativeConsulted", "degreeOfCertainty"], {
      is: (nearestRelativeConsulted, degreeOfCertainty) =>
        nearestRelativeConsulted === "yes" && degreeOfCertainty === "understanding",
      then: Yup.object().shape({
        address: Yup.string().nullable(),
        city: Yup.string().nullable(),
        postcode: Yup.string().trim().required("Please enter an address"),
      }),
      otherwise: Yup.object().shape({
        address: Yup.string(),
        city: Yup.string(),
        postcode: Yup.string(),
      }),
    }),

    suspectedRelativeNotConsultedName: Yup.mixed().when(["nearestRelativeConsulted", "notConsulted"], {
      is: (nearestRelativeConsulted, notConsulted) =>
        nearestRelativeConsulted === "no" && notConsulted === "understanding",
      then: Yup.string().trim().required("Please enter a name").defined(),
      otherwise: Yup.string(),
    }),
    suspectedRelativeNotConsultedAddress: Yup.mixed().when(["nearestRelativeConsulted", "notConsulted"], {
      is: (nearestRelativeConsulted, notConsulted) =>
        nearestRelativeConsulted === "no" && notConsulted === "understanding",
      then: Yup.object().shape({
        address: Yup.string().nullable(),
        city: Yup.string().nullable(),
        postcode: Yup.string().required("Please enter an address"),
      }),
      otherwise: Yup.object().shape({
        address: Yup.string(),
        city: Yup.string(),
        postcode: Yup.string(),
      }),
    }),
    authorisedBy: Yup.mixed().when(["nearestRelativeConsulted", "degreeOfCertainty"], {
      is: (nearestRelativeConsulted, degreeOfCertainty) =>
        nearestRelativeConsulted === "yes" && degreeOfCertainty === "understanding",
      then: Yup.mixed()
        .oneOf(["countyCourt", "nearestRelative"], "Please select a valid option")
        .required("Please select a valid option"),
      otherwise: Yup.string(),
    }),
    notConsulted: Yup.mixed().when(["nearestRelativeConsulted"], {
      is: (nearestRelativeConsulted) => nearestRelativeConsulted === "no",
      then: Yup.string().required("Please select a, b, or c"),
      otherwise: Yup.string(),
    }),
    notConsultedRelativeRelation: Yup.mixed().when(["nearestRelativeConsulted", "notConsulted"], {
      is: (nearestRelativeConsulted, notConsulted) =>
        nearestRelativeConsulted === "no" && notConsulted === "understanding",
      then: Yup.string().required("Please select i or ii"),
      otherwise: Yup.string(),
    }),
    reasonForNoConsultation: Yup.mixed().when(["nearestRelativeConsulted", "notConsulted"], {
      is: (nearestRelativeConsulted, notConsulted) =>
        nearestRelativeConsulted === "no" && notConsulted === "understanding",
      then: Yup.string().required("Please select i or ii"),
      otherwise: Yup.string(),
    }),

    opinion: Yup.mixed().when(["nearestRelativeConsulted", "notConsulted"], {
      is: (nearestRelativeConsulted, notConsulted) =>
        nearestRelativeConsulted === "no" && notConsulted === "understanding",
      then: Yup.string().trim().required("Please enter a reason"),
      otherwise: Yup.string(),
    }),
  }),

  componentElements,
};
