import * as Yup from "yup";
import { ComponentElement, FormFieldsDoctor } from "libs/types/mhaForms";

const componentElements: ComponentElement[] = [
  {
    component: "Text",
    componentProps: {
      instruction: "We, registered medical practitioners, recommend that",
      guidance: "(PRINT full name and address of patient)",
    },
    fullWidth: true,
  },
  {
    component: "TextInput",
    fieldName: FormFieldsDoctor.PatientName,
    componentProps: {
      label: "Name",
    },
    genericProps: {
      readonlyIfInitialValue: true,
    },
  },
  {
    component: "Location",
    fieldName: FormFieldsDoctor.PatientAddress,
    componentProps: {
      label: "Address",
      disableStrictPostcodeValidation: true,
    },
  },
  {
    component: "Text",
    componentProps: {
      instruction: "be admitted to a hospital for assessment in accordance with Part 2 of the Mental Health Act 1983.",
    },
    fullWidth: true,
  },
];

export default {
  title: "Patient Details",
  state: {
    patientName: "",
    patientAddress: {
      city: "",
      postcode: "",
      address: "",
    },
  },

  validation: Yup.object().shape({
    patientName: Yup.string().trim().required("Please enter the patient's name"),
    patientAddress: Yup.object().shape({
      city: Yup.string().nullable(),
      postcode: Yup.string().trim().required("Please enter an address"),
      address: Yup.string().nullable(),
    }),
  }),

  componentElements,
};
