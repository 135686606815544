import { useEffect } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useRecoilState } from "recoil";

import { isDemoEnvironment as recoilIsDemoEnvironment } from "@/utils/recoil/index";

/**
 * Detect if the current environment is the demo environment.
 *
 * TypeScript doesn't like when we set the actual `useEffect` callback to be
 * an `async` function, so unfortunately we have to define the method inside
 * the hook.
 *
 * @author [Alex McCabe]
 */
export default function useIsDemoEnvironment() {
  const [isDemoEnvironment, setDemoEnvironment] = useRecoilState(recoilIsDemoEnvironment);

  useEffect(() => {
    async function getValue() {
      const value = (await AsyncStorage.getItem("isDemoEnvironment")) === "true";

      if (value !== isDemoEnvironment) {
        setDemoEnvironment(value);
      }
    }

    getValue();
  }, []);

  return isDemoEnvironment;
}
