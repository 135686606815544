import React from "react";
import { createStackNavigator } from "@react-navigation/stack";

import Config from "@/config";
import { DoctorClaimsRoutes, DoctorTabRoutes, TabNavigationProps } from "@/navigationv2/types";
import screenOptions from "@/navigationv2/config/screenOptions";
import Screens from "@/screens";
import TabIcon from "../TabIcon";

const ClaimsStack = createStackNavigator<DoctorClaimsRoutes>();

interface PropTypes extends TabNavigationProps<DoctorTabRoutes, "DoctorClaimsTab"> {}

const DoctorClaimsStack = () => {
  return (
    <ClaimsStack.Navigator initialRouteName="ClaimsScreen" screenOptions={screenOptions}>
      <ClaimsStack.Screen
        name="ClaimsScreen"
        component={Screens.ClaimsScreen}
        options={Screens.ClaimsScreen.screenOptions}
      />
      <ClaimsStack.Screen
        name="ClaimDetailsScreen"
        component={Screens.ClaimDetailsScreen}
        options={Screens.ClaimDetailsScreen.screenOptions}
      />
      <ClaimsStack.Screen
        name="DoctorVisitDetailsScreen"
        component={Screens.DoctorVisitDetailsScreen}
        options={Screens.DoctorVisitDetailsScreen.screenOptions}
      />
      {Config.ENABLE_FORMS && (
        <ClaimsStack.Screen
          name="MHAFormScreen"
          component={Screens.MHAFormScreen}
          options={Screens.MHAFormScreen.screenOptions}
        />
      )}
    </ClaimsStack.Navigator>
  );
};

DoctorClaimsStack.tabOptions = {
  tabBarIcon: TabIcon("receipt"),
  tabBarLabel: "Claims",
};

export default DoctorClaimsStack;
