import { unstable_createElement as createElement } from "react-native-web";
require("./style.css");

const webInput = (props: any) => createElement("input", props);

export default (props: { value: number; onValueChange: (v: number) => void; step: number; min: number; max: number }) =>
  webInput({
    type: "range",
    value: props.value,
    min: props.min,
    max: props.max,
    step: props.step,
    onChange: (e: { currentTarget: { value: number } }) => props.onValueChange(e.currentTarget.value),
  });
