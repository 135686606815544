import React from "react";
import { Query, ApolloConsumer } from "react-apollo";
import { useRecoilValue } from "recoil";

import { GET_MHTS, GET_DOCTOR, GetDoctorResponse, MHTItem, convertToProfile } from "@/models/DoctorProfile";
import { getPostcodeData } from "@/models/Location";
import { userDetails } from "@/utils/recoil/index";

import { DoctorAvailability } from "./DoctorAvailability";
import Loading from "../Loading";
import { ErrorMessage } from "../Error/Error";

interface Response {
  listMHTs?: {
    items: MHTItem[];
  };
}

const DoctorAvailabilityGQL = () => {
  const user = useRecoilValue(userDetails);

  return (
    <ApolloConsumer>
      {client => (
        <Query<GetDoctorResponse> query={GET_DOCTOR} variables={{ id: user ? user.id : "" }}>
          {getDoctor => (
            <Query<Response> query={GET_MHTS} variables={{ limit: 100 }}>
              {({ error, data, loading }) => {
                if (error) {
                  return <ErrorMessage apolloError={error} />;
                } else if (loading) {
                  return <Loading />;
                } else if (data && data.listMHTs && getDoctor.data && getDoctor.data.getS12Doctor) {
                  const { items: mhtList } = data.listMHTs;
                  // only show mhts the doctor has said they are associated with.
                  const filteredMHTs = mhtList.filter(
                    mht =>
                      !getDoctor.data ||
                      !getDoctor.data.getS12Doctor ||
                      !!getDoctor.data.getS12Doctor.mhtEmployers.items?.find(
                        mhte => mhte?.mht.organisation.id === mht.organisation.id
                      )
                  );

                  return (
                    <DoctorAvailability
                      mhts={filteredMHTs}
                      doctorProfile={getDoctor.data && convertToProfile(getDoctor.data.getS12Doctor)}
                      getPostcode={getPostcodeData(client)}
                    />
                  );
                } else {
                  return <Loading />;
                }
              }}
            </Query>
          )}
        </Query>
      )}
    </ApolloConsumer>
  );
};

export default DoctorAvailabilityGQL;
