import { UserDetails } from "@/utils/recoil/props";

type UserData = UserDetails | null;
export type UserTypes = "AMHP" | "OrgClaims" | "Doctor" | "ExternalDoctor";

/**
 * Some screens display the patients / users name as the page title. On the web
 * version of the application, there is plenty of space for this to sit,
 * however on native devices, the larger font size means that the name may
 * overflow the screen.
 *
 * @param patientName
 * @param isWebView
 */
export function getNameAsPageTitle(patientName: string, isWebView = false) {
  return isWebView
    ? patientName
    : patientName
        .split(/ (.+)/)
        .map((word: string, i: number) => (i === 0 ? `${word}${"\n"}` : word))
        .join()
        .replace(/,/g, "");
}

export function isAMHP(userDetails: UserData) {
  return isUserType(userDetails, "AMHP");
}

export function isCCG(userDetails: UserData) {
  return isUserType(userDetails, "OrgClaims");
}

export function isDoctor(userDetails: UserData) {
  return isUserType(userDetails, "Doctor");
}

export function isExternalDoctor(userDetails: UserData) {
  return isUserType(userDetails, "ExternalDoctor");
}

export function isUserType(userDetails: UserData, userType: UserTypes) {
  return Boolean(userDetails?.groups && userDetails?.groups.find(item => item === userType));
}

export function isValidUser(userDetails: UserData) {
  return isAMHP(userDetails) || isCCG(userDetails) || isDoctor(userDetails);
}
