import * as Yup from "./index";
import { BaseSchema, mergeSchemas } from "./baseSchema";
import { StringSchema } from "yup";

interface CreatePostcodeSchema extends BaseSchema {
  localeIsoCode?: "GB";
  useOnsDatabase?: boolean;
  useRegex?: boolean;
  invalidPostcodeMessage?: string;
  orNFA?: boolean;
}

const PostcodeRegex = {
  GB: /^[a-z]{1,2}\d[a-z\d]?\s?\d[a-z]{2}$/,
};

const PostcodeRegexWithNFA = {
  GB: /^[a-z]{1,2}\d[a-z\d]?\s?\d[a-z]{2}$|unknown|nfa|n\/a/,
};

/**
 * Create a validation schema to validate a given postcode.
 * Future upgrades would allow for multiple country postcode validation
 * through the ISO 3166-1 Alpha-2 codes.
 *
 * @param options
 * @link https://en.wikipedia.org/wiki/List_of_ISO_3166_country_codes
 * @link https://ideal-postcodes.co.uk/guides/postcode-validation
 */
export default function createPostcodeSchema(options: CreatePostcodeSchema = {}): StringSchema {
  const {
    invalidPostcodeMessage = "Please enter a valid postcode",
    localeIsoCode = "GB",
    required = false,
    requiredMessage = "Please enter a postcode",
    orNFA = false,
  } = options;

  const regex = orNFA
    ? new RegExp(PostcodeRegexWithNFA[localeIsoCode], "i")
    : new RegExp(PostcodeRegex[localeIsoCode], "i");

  const schemas = [Yup.string(), Yup.string().matches(regex, invalidPostcodeMessage)];

  required && schemas.push(Yup.string().trim().required(requiredMessage));

  return mergeSchemas(...schemas);
}
