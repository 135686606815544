import React, { useState, useMemo, useEffect, useCallback } from "react";
import { StyleSheet, View, Animated, Platform } from "react-native";
import { Badge, TouchableRipple } from "react-native-paper";

import Icon from "../../Icon";
import Text from "../../Text";
import { TypographyType } from "@/models/Typography";
import { color, palette, spacing } from "../../../theme";
import { mqWeb } from "../../../utils/helpers";

export interface VideoActionButtonProps {
  icon: string;
  theme: "red" | "yellow";
  label: string;
  onPress: () => void;
  isActive?: boolean;
  disabled?: boolean;
  badgeCount?: number | null;
}

export const VideoActionButton = React.memo<VideoActionButtonProps>(
  ({ icon, theme, label, onPress, isActive, disabled, badgeCount }) => {
    const isWebView = mqWeb();
    const [inputActive, setInputActive] = useState<boolean>(false);
    const [badgeScale] = useState(new Animated.Value(1));

    const backgroundStyles = useMemo(
      () => [styles.bg, inputActive ? styles.bgActive : { backgroundColor: palette[theme] }],
      [inputActive]
    );

    const buttonTextStyles = useMemo(
      () => [
        styles.buttonText,
        {
          color: theme === "red" && !inputActive ? color.textWhite : color.text,
        },
      ],
      [inputActive]
    );

    const badgeWrapperStyles = useMemo(
      () => [
        styles.badgeWrapper,
        {
          transform: [{ scale: badgeScale }],
        },
      ],
      [badgeScale]
    );

    useEffect(() => {
      Animated.sequence([
        Animated.spring(badgeScale, {
          toValue: 1.3,
          useNativeDriver: false,
        }),
        Animated.spring(badgeScale, {
          toValue: 1,
          useNativeDriver: false,
        }),
      ]).start();
    }, [badgeCount]);

    useEffect(() => {
      if (typeof isActive === "boolean" && isActive !== inputActive) {
        setInputActive(isActive);
      }
    }, [isActive]);

    const ripplePress = useCallback(() => {
      onPress();
      setInputActive(!inputActive);
    }, [onPress]);

    return (
      <View style={styles.container}>
        {!!badgeCount && (
          <Animated.View style={badgeWrapperStyles}>
            <Badge style={styles.badge}>{badgeCount}</Badge>
          </Animated.View>
        )}
        <TouchableRipple
          rippleColor={palette.rippleColor}
          disabled={disabled}
          style={styles.button}
          onPress={ripplePress}
        >
          <>
            <View style={styles.buttonContent}>
              <Icon
                name={icon}
                color={theme === "red" && !inputActive ? color.textWhite : color.text}
                style={icon === "exit-to-app" && styles.iconMirror}
              />
              {isWebView ? (
                <Text format={TypographyType.TinyBold} style={buttonTextStyles}>
                  {label}
                </Text>
              ) : (
                <View />
              )}
            </View>
            <View style={backgroundStyles} />
          </>
        </TouchableRipple>
      </View>
    );
  }
);

VideoActionButton.displayName = "VideoActionButton";

const styles = StyleSheet.create({
  badgeWrapper: {
    position: "absolute",
    top: -8,
    right: 8,
    zIndex: 10,
  },

  badge: {
    backgroundColor: palette.navy,
  },

  bg: {
    position: "absolute",
    width: "100%",
    height: "100%",
    left: 0,
    top: 0,
    zIndex: -1,
  },
  bgActive: { backgroundColor: palette.grey },

  button: {
    width: "auto",
    minWidth: 44,
    height: 44,
    marginRight: spacing[15],
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 12,
    overflow: "hidden",
    ...Platform.select({
      web: {
        cursor: "pointer",
      },
    }),
  },

  buttonContent: {
    flexDirection: "row",
    paddingHorizontal: spacing[10],
  },

  buttonText: {
    textTransform: "uppercase",
    paddingLeft: spacing[5],
    lineHeight: 24,
  },

  container: {
    position: "relative",
  },

  iconMirror: {
    transform: [{ scaleX: -1 }],
  },
});
