/* Libraries */
import React from "react";
import { View } from "react-native";

/* Models, Types, Props & Utils */
import { AvailabilityState } from "libs/types/availability";
import { TypographyType } from "@/models/Typography";
import { AvailabilityType } from "libs/types/API";

/* Custom Components */
import Text from "../../Text";
import { spacing } from "../../../theme";
import { ucFirst } from "../../../utils/helpers";

export default function AvailabilityText(props: { type: AvailabilityType | AvailabilityState; style?: any }) {
  const availableStates = ["trust", "oncall", "independent"];
  const isAvailable = availableStates.includes(props.type);
  const label = isAvailable
    ? "Available"
    : props.type === "unavailable"
    ? "Unavailable"
    : props.type === "inAssessment"
    ? "In Assessment"
    : props.type === "booked"
    ? "Booked"
    : props.type === "away"
    ? "Away"
    : "No information";
  return (
    <View style={{ flexDirection: "row", alignItems: "center" }}>
      <Text format={TypographyType.RegularBold} style={props.style}>
        {label}
      </Text>
      {isAvailable && (
        <Text format={TypographyType.Small} style={[props.style, { marginLeft: spacing[5] }]}>
          {`(${props.type === "oncall" ? "On call" : ucFirst(props.type)})`}
        </Text>
      )}
    </View>
  );
}
