import React from "react";
import { Platform, StyleSheet, ViewStyle } from "react-native";
import { TouchableRipple } from "react-native-paper";
import color from "color";

import { mqWeb, ucFirst } from "@/utils/helpers";
import { TypographyType } from "@/models/Typography";
import { palette } from "@/theme";

import Text from "@/components/Text";

interface DialogButtonProps {
  mode: "confirm" | "cancel";
  onPress: () => void;
  label?: string;
  disabled?: boolean;
  isDanger?: boolean;
}

export function DialogButton(props: DialogButtonProps) {
  const isWebView = mqWeb();

  const style =
    props.mode === "confirm"
      ? props.isDanger
        ? styles.confirmDangerButton
        : styles.confirmButton
      : styles.cancelButton;
  const ButtonFormat = isWebView ? TypographyType.RegularBold : TypographyType.SmallBold;
  const rippleColor =
    props.mode === "confirm"
      ? props.isDanger
        ? color(palette.red)
            .lighten(0.2)
            .alpha(0.32)
            .rgb()
            .string()
        : color(palette.blue)
            .lighten(0.2)
            .alpha(0.32)
            .rgb()
            .string()
      : palette.rippleColor;
  const underlayColor = props.mode === "confirm" ? (props.isDanger ? palette.red : palette.blue) : palette.cloud;
  const textColor = props.mode === "confirm" ? palette.white : palette.blue;

  return (
    <TouchableRipple
      style={style}
      onPress={props.onPress}
      rippleColor={rippleColor}
      underlayColor={underlayColor}
      disabled={props.disabled}
    >
      <Text format={ButtonFormat} color={textColor}>
        {props.label || ucFirst(props.mode)}
      </Text>
    </TouchableRipple>
  );
}

const buttonDefaultStyles: ViewStyle = {
  justifyContent: "center",
  alignItems: "center",
  paddingVertical: 12,
  borderRadius: 12,
  ...Platform.select({
    web: {
      cursor: "pointer",
    },
  }),
};

const styles = StyleSheet.create({
  confirmButton: {
    ...buttonDefaultStyles,
    backgroundColor: palette.blue,
  },
  cancelButton: {
    ...buttonDefaultStyles,
    backgroundColor: palette.transparent,
  },
  confirmDangerButton: {
    ...buttonDefaultStyles,
    backgroundColor: palette.red,
  },
});
