import Config from "react-native-config";
import dotenvParseVariables from "dotenv-parse-variables";
import omit from "lodash.omit";

const configProps = omit(Config, ["getConstants"]);

// react-config-web and react-native-config have slightly different ways to get all constants
const ENV = dotenvParseVariables(configProps);

if (ENV.ENV === "prod") {
  ENV.AWS_HTTP_ENDPOINT = "https://6tfij7q6la.execute-api.eu-west-2.amazonaws.com/prod";
  ENV.AWS_EXTERNAL_USER_POOLS_ID = "eu-west-2_rfNnQkhSy";
  ENV.AWS_EXTERNAL_USER_POOLS_WEB_CLIENT_ID = "2m8nvpkag6uc31e4m4gmo63v3q";
}

ENV.ENABLE_SENTRY = ENV.ENV === "master" || ENV.ENV === "preprod" || ENV.ENV === "prod" ? "true" : false;
ENV.ENABLE_FORMS = true;
ENV.ENABLE_SSO = true;
ENV.EULA_VERSION = 3;

export default ENV;
