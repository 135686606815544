import { ClaimModalType, RangeValues } from "./ClaimProcessing.props";

import { GetUserOrgResponseOrg } from "@/models/Organisation";

import { NextToken } from "libs/types/graphql";
import dayjs, { Dayjs } from "dayjs";
import { DocumentNode } from "graphql";
import { Data } from "react-csv/components/CommonPropTypes";
import { ClaimStatus, ModelSortDirection } from "libs/types/API";
import notFalsy from "libs/utils/notFalsy";
import { CCGClaim } from "libs/types/claim";

export enum ClaimModelActionType {
  // ORGANISATIONS
  ORGANISATIONS_LOAD_SUCCESS = "ORGANISATIONS_LOAD_SUCCESS",
  ORGANISATIONS_LOAD_FAILURE = "ORGANISATIONS_LOAD_FAILURE",
  // MODIFYING CLAIMS
  UPDATE_CLAIM = "UPDATE_CLAIM",
  UPDATE_CLAIMS_SUCCESS = "UPDATE_CLAIMS_SUCCESS",
  UPDATE_CLAIMS_FAILURE = "UPDATE_CLAIMS_FAILURE",
  EXPORT_CLAIMS = "EXPORT_CLAIMS",
  // REFETCH
  REFETCH = "REFETCH",
  REFETCH_SUCCESS = "REFETCH_SUCCESS",
  REFETCH_FAILURE = "REFETCH_FAILURE",
  // LOADING CLAIMS DATA
  LOAD_CLAIMS = "LOAD_CLAIMS",
  LOAD_CLAIMS_SUCCESS = "LOAD_CLAIMS_SUCCESS",
  LOAD_CLAIMS_FAILURE = "LOAD_CLAIMS_FAILURE",
  // LOAD MORE
  LOAD_MORE_CLAIMS = "LOAD_MORE_CLAIMS",
  LOAD_MORE_CLAIMS_SUCCESS = "LOAD_MORE_CLAIMS_SUCCESS",
  LOAD_MORE_CLAIMS_FAILURE = "LOAD_MORE_CLAIMS_FAILURE",
  // NEXT / PREVIOUS PAGE
  LOAD_PAGE_CLAIMS_SUCCESS = "LOAD_PAGE_CLAIMS_SUCCESS",
  // RANGE EXPORT DATA
  LOAD_RANGE_DATA = "LOAD_RANGE_DATA",
  LOAD_RANGE_DATA_SUCCESS = "LOAD_RANGE_DATA_SUCCESS",
  LOAD_RANGE_DATA_FAILURE = "LOAD_RANGE_DATA_FAILURE",
  // CSV DATA
  SET_CSV_DATA = "SET_CSV_DATA",
  // SORT
  SET_COLUMN_SORT = "SET_COLUMN_SORT",
  // UPDATE RECORDS AFTER ACTION
  UPDATE_TOTAL_RECORDS = "UPDATE_TOTAL_RECORDS",
  SET_SEARCH_BY_DISPLAY_ID = "SET_SEARCH_BY_DISPLAY_ID",
}

export interface ClaimModelStateProps {
  claims: CCGClaim[];
  organisations: GetUserOrgResponseOrg[];
  nextToken: NextToken;
  rangeNextToken: NextToken;
  shouldLoadMore: boolean;
  refreshVariables: any;
  error: any;
  loading: boolean;
  csvData: string | Data;
  refreshRequired: boolean;
  loadingRangeData: boolean;
  rangeCsvData: Data | null;
  query?: DocumentNode;
  refreshQuery?: DocumentNode;
  totalRecords?: number;
  columnSort: { sortField: string | null; sortDirection: string };
  searchField?: string;
}

export const claimModelState: ClaimModelStateProps = {
  claims: [],
  organisations: [],
  nextToken: null,
  rangeNextToken: null,
  shouldLoadMore: false,
  refreshVariables: undefined,
  error: null,
  loading: false,
  csvData: [],
  refreshRequired: false,
  loadingRangeData: false,
  rangeCsvData: null,
  query: undefined,
  refreshQuery: undefined,
  totalRecords: 0,
  columnSort: { sortField: "receivedDate", sortDirection: ModelSortDirection.ASC },
  searchField: "",
};

export function claimProcessingModelReducer(
  state: ClaimModelStateProps,
  { type, payload }: { type: ClaimModelActionType; payload?: any }
): ClaimModelStateProps {
  switch (type) {
    case ClaimModelActionType.UPDATE_TOTAL_RECORDS:
      return {
        ...state,
        totalRecords: state.claims.length,
      };
    // UPDATE CLAIMS
    case ClaimModelActionType.UPDATE_CLAIM:
      if (payload.modalType === "paid") {
        // Paid claims stay in the list
        // unlike others which we remove from the list
        return {
          ...state,
          claims: state.claims.map(claim => {
            if (claim.id === payload.claim) {
              return {
                ...claim,
                status: ClaimStatus.approved_and_paid,
              };
            }
            return { ...claim };
          }),
        };
      }
      return {
        ...state,
        claims: state.claims.filter(x => payload.claim !== x.id),
      };
    case ClaimModelActionType.UPDATE_CLAIMS_SUCCESS:
      return {
        ...state,
        error: null,
      };
    case ClaimModelActionType.UPDATE_CLAIMS_FAILURE:
      return {
        ...state,
        error: payload.error,
      };
    // LOAD MORE CLAIMS
    case ClaimModelActionType.LOAD_MORE_CLAIMS:
      return {
        ...state,
        shouldLoadMore: true,
      };
    case ClaimModelActionType.LOAD_MORE_CLAIMS_SUCCESS:
      return {
        ...state,
        shouldLoadMore: false,
        claims: [...state.claims, ...payload.additionalClaims.filter(notFalsy)],
        nextToken: payload.nextToken || null,
        totalRecords: payload.totalRecords,
      };
    case ClaimModelActionType.LOAD_PAGE_CLAIMS_SUCCESS:
      return {
        ...state,
        shouldLoadMore: false,
        claims: [...payload.additionalClaims.filter(notFalsy)],
        nextToken: payload.nextToken || null,
        totalRecords: payload.totalRecords,
      };
    case ClaimModelActionType.LOAD_MORE_CLAIMS_FAILURE:
      return {
        ...state,
        shouldLoadMore: false,
        error: payload.error,
      };
    // LOAD CLAIMS
    case ClaimModelActionType.LOAD_CLAIMS:
      return {
        ...state,
        loading: true,
      };
    case ClaimModelActionType.LOAD_CLAIMS_SUCCESS:
      return {
        ...state,
        claims: payload.claims.filter(notFalsy),
        nextToken: payload.nextToken || null,
        error: null,
        loading: false,
        totalRecords: payload.totalRecords,
      };
    case ClaimModelActionType.LOAD_CLAIMS_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case ClaimModelActionType.REFETCH:
      return {
        ...state,
        loading: true,
        claims: [],
      };
    case ClaimModelActionType.REFETCH_SUCCESS:
      return {
        ...state,
        claims: payload.claims.filter(notFalsy),
        nextToken: payload.nextToken || null,
        error: null,
        loading: false,
        totalRecords: payload.totalRecords,
      };
    case ClaimModelActionType.REFETCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    // LOAD RANGE DATA
    case ClaimModelActionType.LOAD_RANGE_DATA:
      return {
        ...state,
        loadingRangeData: true,
        rangeCsvData: null,
      };
    case ClaimModelActionType.LOAD_RANGE_DATA_SUCCESS:
      return {
        ...state,
        loadingRangeData: false,
        error: null,
        rangeCsvData: payload,
      };
    case ClaimModelActionType.LOAD_RANGE_DATA_FAILURE:
      return {
        ...state,
        loadingRangeData: false,
        error: payload.error,
      };
    case ClaimModelActionType.SET_CSV_DATA:
      return {
        ...state,
        csvData: payload,
      };
    case ClaimModelActionType.SET_COLUMN_SORT:
      return {
        ...state,
        columnSort: payload,
      };
    case ClaimModelActionType.SET_SEARCH_BY_DISPLAY_ID:
      return {
        ...state,
        searchField: payload,
      };
    default:
      return state;
  }
}

export enum ClaimUiActionType {
  SET_ON_PRESS_ACTION = "SET_ON_PRESS_ACTION",
  SET_MODAL_TYPE = "SET_MODAL_TYPE",
  SET_RANGE_VALUES = "SET_RANGE_VALUES",
  SET_SELECTED_CLAIMS = "SET_SELECTED_CLAIMS",
  RESET_ON_PRESS_AND_SELECTED_CLAIMS = "RESET_ON_PRESS_AND_SELECTED_CLAIMS",
  // TOGGLES
  DISABLE_BUTTONS = "DISABLE_BUTTONS",
  ENABLE_BUTTONS = "ENABLE_BUTTONS",
  // RANGE EXPORT
  SET_FIELD_LIST = "SET_FIELD_LIST",
}

export interface ClaimUiStateProps {
  uiLoading: boolean;
  isFirstLoad: boolean;
  selectedClaims: string[];
  modalType: ClaimModalType | null;
  onPressAction: "navigate" | "setselectedclaims";
  buttonsEnabled: boolean;
  loadMoreClaims: boolean;
  loadRangeClaims: boolean;
  rangeValues: RangeValues;
  fieldList: string[];
}

export const claimUiState: ClaimUiStateProps = {
  uiLoading: false,
  isFirstLoad: true,
  modalType: null,
  onPressAction: "navigate",
  buttonsEnabled: true,
  selectedClaims: [],
  loadMoreClaims: false,
  loadRangeClaims: false,
  rangeValues: {
    from: dayjs().subtract(1, "day"),
    to: dayjs(),
  },
  fieldList: [],
};

// UI ACTION CREATORS
export const setClaimProcessingModalType = (dispatch: any, modalType: ClaimModalType | null) => {
  return dispatch({
    type: ClaimUiActionType.SET_MODAL_TYPE,
    payload: modalType,
  });
};

export const resetSelectedClaims = (dispatch: any) => {
  return dispatch({ type: ClaimUiActionType.SET_SELECTED_CLAIMS, payload: [] });
};

interface RangeValuesPayload {
  payload: {
    from?: Dayjs | null;
    to?: Dayjs | null;
  };
}

interface ToPayload {
  payload: { to: Dayjs | null };
}

export const setExportDateRange = (dispatch: any, payload: RangeValuesPayload) => {
  return dispatch({ type: ClaimUiActionType.SET_RANGE_VALUES, ...payload });
};

// Reducer
export function claimProcessingUiReducer(
  state: ClaimUiStateProps,
  { type, payload }: { type: ClaimUiActionType | ClaimModelActionType; payload?: any }
): ClaimUiStateProps {
  switch (type) {
    case ClaimUiActionType.RESET_ON_PRESS_AND_SELECTED_CLAIMS:
      return {
        ...state,
        selectedClaims: [],
        onPressAction: "navigate",
        buttonsEnabled: true,
        modalType: null,
        uiLoading: true,
      };
    case ClaimUiActionType.SET_ON_PRESS_ACTION:
      return {
        ...state,
        onPressAction: payload,
      };
    case ClaimUiActionType.SET_RANGE_VALUES:
      return {
        ...state,
        rangeValues: setRangeValues(state, payload),
      };
    case ClaimUiActionType.SET_MODAL_TYPE:
      return {
        ...state,
        modalType: payload,
      };
    case ClaimUiActionType.SET_SELECTED_CLAIMS:
      return {
        ...state,
        selectedClaims: payload,
        modalType: null,
      };
    case ClaimUiActionType.DISABLE_BUTTONS:
      return {
        ...state,
        buttonsEnabled: false,
      };
    case ClaimUiActionType.SET_FIELD_LIST:
      return {
        ...state,
        fieldList: payload.fieldList,
      };
    case ClaimModelActionType.UPDATE_CLAIM:
      return {
        ...state,
        buttonsEnabled: false,
      };
    case ClaimModelActionType.UPDATE_CLAIMS_FAILURE:
    case ClaimModelActionType.UPDATE_CLAIMS_SUCCESS:
      return {
        ...state,
        buttonsEnabled: true,
      };
    case ClaimModelActionType.LOAD_CLAIMS:
    case ClaimModelActionType.REFETCH:
    case ClaimModelActionType.LOAD_MORE_CLAIMS:
      return {
        ...state,
        isFirstLoad: true,
        buttonsEnabled: false,
        uiLoading: false,
      };
    case ClaimModelActionType.LOAD_CLAIMS_FAILURE:
    case ClaimModelActionType.REFETCH_FAILURE:
    case ClaimModelActionType.LOAD_MORE_CLAIMS_FAILURE:
    case ClaimModelActionType.LOAD_CLAIMS_SUCCESS:
    case ClaimModelActionType.REFETCH_SUCCESS:
    case ClaimModelActionType.LOAD_MORE_CLAIMS_SUCCESS:
      return {
        ...state,
        isFirstLoad: false,
        buttonsEnabled: true,
        uiLoading: false,
      };
    default:
      return state;
  }
}

export interface ClaimProcessingStateProps extends ClaimModelStateProps, ClaimUiStateProps {}

export interface ClaimProcessingStateContext {
  state: ClaimProcessingStateProps;
  dispatch: ({ type }: { type: ClaimModelActionType | ClaimUiActionType; payload?: any }) => void;
}

function setRangeValues(state: ClaimUiStateProps, payload: RangeValuesPayload["payload"]): RangeValues {
  return {
    from:
      typeof payload.from !== "undefined" ? (payload.from !== null ? payload.from : dayjs()) : state.rangeValues.from,
    to: typeof payload.to !== "undefined" ? (payload.to !== null ? payload.to : dayjs()) : state.rangeValues.to,
  };
}
