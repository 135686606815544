import React, { useState, useMemo, useCallback } from "react";
import { StyleSheet, View, LayoutRectangle, Platform } from "react-native";
import { HelperText, TextInput as RNPTextInput, TextInputProps } from "react-native-paper";

import { InfoText } from "@/components/InfoText";

import { color, spacing } from "../../theme";
import Icon from "../Icon";

export interface S12InputProps extends TextInputProps {
  icon?: string;
  onIconPress?: () => void;
  error?: boolean;
  errorText?: string;
  containerStyle?: any;
  underlabel?: string;
  noBottomBorder?: boolean;
  supplementaryInfo?: string | null;
  stretchLines?: boolean; // auto grow component based on textArea height
  withPasswordField?: boolean;
  underLabelTextSize?: "Micro" | "Tiny" | "Small" | "Regular";
  inputBoxesStyle?: any;
  infoTextStyle?: any;
}

// TODO: when refactoring RNP components, allow an initial value, but let component manage it's inner state to prevent flickering, allow an onChange valu to 'bubble up' changed values
export const TextInput = React.memo(
  React.forwardRef((props: S12InputProps, ref: (ref: any) => void) => {
    const {
      autoCorrect = false,
      withPasswordField = false,
      containerStyle,
      inputBoxesStyle,
      infoTextStyle,
      error,
      errorText,
      icon,
      label,
      noBottomBorder,
      style,
      supplementaryInfo,
      underlabel,
      underLabelTextSize = "Small",
      ...rest
    } = props;

    const [isFocused, setIsFocused] = useState(false);
    const [scrollHeight, setScrollHeight] = useState<number | null>(null);
    const styleArr = useMemo(() => [styles.flat, props.style], [props.style]);

    const testID = `${label ? label.toString().replace(/\s/g, "") : ""}__Input`;
    const value = rest.value === null ? "" : rest.value;

    const containerStyleArr = useMemo(
      () => [styles.columnFlex, containerStyle, scrollHeight && { height: scrollHeight }].filter(x => !!x),
      [containerStyle, scrollHeight]
    );

    const onBlur = useCallback(
      e => {
        props.onBlur && props.onBlur(e);
        setIsFocused(false);
      },
      [props.onBlur]
    );

    const onFocus = useCallback(
      e => {
        props.onFocus && props.onFocus(e);
        setIsFocused(true);
      },
      [props.onFocus]
    );

    // grow the text input to match the scrollHeight up to a certain limit
    const onChange = useCallback(
      (
        e:
          | { target?: { scrollHeight?: number } | number }
          | { nativeEvent: { layout: LayoutRectangle; target?: { scrollHeight?: number } } }
      ) => {
        const target = "nativeEvent" in e && e.nativeEvent.target ? e.nativeEvent.target : "target" in e && e.target;
        target &&
          typeof target !== "number" &&
          typeof target.scrollHeight === "number" &&
          setScrollHeight(target.scrollHeight + 5);
      },
      []
    );

    const InputIcon = useMemo(() => {
      if (icon) {
        return (
          <Icon
            color={error ? color.textError : isFocused ? color.primary : color.textExtraLight}
            name={icon}
            onPress={props.onIconPress}
            style={errorText ? styles.iconBaseStyles : styles.iconBaseStylesWithoutError}
          />
        );
      }
      return null;
    }, [errorText, icon]);

    return (
      <View style={styleArr}>
        {InputIcon}
        <View style={containerStyleArr}>
          {!!supplementaryInfo && (
            <HelperText style={styles.underLabel} type="info">
              {supplementaryInfo}
            </HelperText>
          )}
          <RNPTextInput
            mode="flat"
            ref={ref}
            {...rest}
            autoCorrect={autoCorrect}
            label={label}
            onBlur={onBlur}
            onChange={props.stretchLines ? onChange : undefined}
            onChangeText={rest.onChangeText}
            onFocus={onFocus}
            onLayout={props.stretchLines ? onChange : undefined}
            selectionColor={color.primary}
            style={props.inputBoxesStyle ? [styles.inputBoxes, inputBoxesStyle] : styles.inputBoxes}
            underlineColor={noBottomBorder ? color.background : error ? color.textError : color.thinBorder}
            value={value}
            testID={testID}
          />

          {!!underlabel && (
            <>
              <InfoText
                textStyle={infoTextStyle && infoTextStyle}
                paragraphs={[underlabel]}
                textSize={underLabelTextSize}
              />
            </>
          )}

          {!!errorText && (
            <HelperText style={styles.error} type="error" visible={error}>
              {errorText}
            </HelperText>
          )}
        </View>
        {/* This empty view is define for reduce text input underline same as password input */}
        {withPasswordField && <View style={styles.freSpace} />}
      </View>
    );
  })
);

TextInput.displayName = "TextInput";

const styles = StyleSheet.create({
  flat: {
    flex: 1,
    flexDirection: "row",
    alignItems: "flex-end",
    paddingLeft: 0,
    paddingBottom: spacing[5],
    ...Platform.select({ web: { minHeight: "unset" } }),
  },
  error: { color: color.textError, minHeight: 23 },
  underLabel: { marginTop: spacing[10] },
  inputBoxes: {
    backgroundColor: color.transparent,
    paddingLeft: 0,
    marginLeft: 0,
    paddingBottom: spacing[5],
    flexGrow: 1,
  },
  iconBaseStyles: {
    marginRight: spacing[15],
    marginBottom: spacing[40],
  },
  iconBaseStylesWithoutError: {
    marginRight: spacing[15],
    marginBottom: spacing[20],
  },
  columnFlex: {
    flexDirection: "column",
    flex: 1,
  },
  freSpace: {
    width: 54,
  },
});
