import React, { useEffect, useContext } from "react";
import { Query, ApolloConsumer } from "react-apollo";
import { ParamListBase, useNavigation } from "@react-navigation/native";

import { DoctorAvailability } from "../DoctorAvailability/DoctorAvailability";
import Loading from "../Loading";
import { GET_MHTS, GET_DOCTOR, GetDoctorResponse, MHTItem, convertToProfile } from "@/models/DoctorProfile";
import { ErrorMessage } from "../Error/Error";
import { getPostcodeData } from "@/models/Location";
import { RouteKeys } from "@/navigationv2";
import {
  AvailabilityModelActionTypes,
  AvailabilityControllerActionTypes,
  StateContext,
} from "libs/state/availability/initialState";
import { AvailabilityContext } from "../DoctorAvailability/DoctorAvailabilityProvider";
import { useRecoilValue, useSetRecoilState, useRecoilState } from "recoil";
import { snackbarMessage, editableAvailability as recoilEditableAvailability, userDetails } from "../../utils/recoil";
import { StackNavigationProp } from "@react-navigation/stack";

interface Response {
  listMHTs?: {
    items: MHTItem[];
  };
}

const EditDoctorAvailability = (props: { editMode?: boolean }) => {
  const navigation = useNavigation<StackNavigationProp<ParamListBase>>();
  const user = useRecoilValue(userDetails);
  const setMessage = useSetRecoilState(snackbarMessage);
  const [editableAvailability, setEditableAvailability] = useRecoilState(recoilEditableAvailability);

  const { dispatch, controllerDispatch } = useContext<StateContext>(AvailabilityContext);

  useEffect(() => {
    if (editableAvailability) {
      dispatch({
        type: AvailabilityModelActionTypes.SET_EDIT_FORM,
        payload: editableAvailability.model,
      });
      controllerDispatch({
        type: AvailabilityControllerActionTypes.SET_EDIT_CONTROLLER,
        payload: editableAvailability.controller,
      });
    } else {
      setMessage("Could not retrieve availability. Please try again");
      navigation.navigate(RouteKeys.DoctorProfileScreen);
    }

    return () => setEditableAvailability(null);
  }, []);

  return (
    <ApolloConsumer>
      {client => (
        <Query<GetDoctorResponse> query={GET_DOCTOR} variables={{ id: user ? user.id : "" }}>
          {getDoctor => (
            <Query<Response> query={GET_MHTS} variables={{ limit: 100 }}>
              {({ error, data, loading }) => {
                if (error) {
                  return <ErrorMessage apolloError={error} />;
                } else if (loading) {
                  return <Loading />;
                } else if (data && data.listMHTs) {
                  const { items: mhtList } = data.listMHTs;
                  const filteredMHTs = mhtList.filter(
                    mht =>
                      !getDoctor.data ||
                      !getDoctor.data.getS12Doctor ||
                      !!getDoctor.data.getS12Doctor.mhtEmployers?.items?.find(
                        mhte => mhte && mhte.mht.organisation.id === mht.organisation.id
                      )
                  );
                  return (
                    <DoctorAvailability
                      mhts={filteredMHTs}
                      doctorProfile={getDoctor.data?.getS12Doctor && convertToProfile(getDoctor.data.getS12Doctor)}
                      getPostcode={getPostcodeData(client)}
                      editMode={true}
                      id={(editableAvailability && editableAvailability.id) || ""}
                    />
                  );
                } else {
                  return <Loading />;
                }
              }}
            </Query>
          )}
        </Query>
      )}
    </ApolloConsumer>
  );
};

export default EditDoctorAvailability;
