/**
 * Subscribe to the height of the viewport
 */
import { useEffect, useState } from "react";
import { Dimensions, Platform } from "react-native";

export default function useDeviceHeight(): number {
  const device = Platform.OS === "web" ? "window" : "screen";
  const [deviceHeight, setDeviceHeight] = useState(Dimensions.get(device).height);

  useEffect(() => {
    const _handleResize = () => setDeviceHeight(Dimensions.get(device).height);
    Dimensions.addEventListener("change", _handleResize);
    return () => Dimensions.removeEventListener("change", _handleResize);
  }, []);

  return deviceHeight;
}
