/**
 * List the records in the MHA Forms list
 */
import React from "react";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { AllAMHPRoutes } from "@/navigationv2/types";
import { useSetRecoilState } from "recoil";
import { snackbarMessage, lastException } from "@/utils/recoil";
import { buildErr } from "@/models/Error";
import { alert } from "@/utils/helpers";
import { deleteForm } from "@/models/MHAForm/api";
import { MHAFormRecord } from "../MHAFormRecord";
import { RouteKeys } from "@/navigationv2";
import { Forms } from "libs/mhaForms/formSections";
import { FormListItem } from "libs/types/mhaForms";
import { isAmhpForm, isConveyanceForm, canUserDeleteForm, isJointForm, getMhaFormVersion } from "libs/mhaForms/helpers";
import { FilterLabel } from "@/components/FilterLabel";
import Text from "@/components/Text";
import { TypographyType } from "@/models/Typography";
import { palette } from "@/theme";
import { FormStatus } from "libs/types/API";
import { UserDetails } from "@/utils/recoil/props";
import { recordEvent } from "@/utils/analytics";
import { AnalyticsEvent } from "libs/analytics/events";
import ENV from "@/config";
import { useAPIVersion } from "@/hooks";
import { API_V2_TOGGLES } from "@/api/types";

const recordDeleteEvent = (
  result: "success" | "failure",
  isAmhpForm: boolean,
  fr: FormListItem,
  assessmentId: string,
  user?: UserDetails
) => {
  const event =
    result === "success"
      ? isAmhpForm
        ? AnalyticsEvent.MHA_FORM_AMHP_DELETED
        : AnalyticsEvent.MHA_FORM_DOCTOR_DELETED
      : isAmhpForm
      ? AnalyticsEvent.MHA_FORM_AMHP_DELETED_FAILURE
      : AnalyticsEvent.MHA_FORM_DOCTOR_DELETED_FAILURE;

  const attributes = {
    status: fr.status,
    assessmentId: assessmentId,
    ...(user && { user: user.name }),
  };

  recordEvent(event, attributes);
};

export default function MHAFormRecordsList(props: {
  formRecords: FormListItem[];
  assessmentId: string;
  refetch: () => void;
  isAmhpForm: boolean;
  user: UserDetails | null;
  isAssessmentAmhp?: boolean;
  removeCTAButtons?: boolean;
}) {
  const navigation = useNavigation<StackNavigationProp<AllAMHPRoutes>>();
  const setMessage = useSetRecoilState(snackbarMessage);
  const setLastException = useSetRecoilState(lastException);
  const { v2 } = useAPIVersion(API_V2_TOGGLES.MHA_FORMS);

  return (
    <>
      <FilterLabel text="Sorted by *last updated* (most recent first)" disableMargin="top" />
      {!props.formRecords.length ? (
        <>
          <Text format={TypographyType.Small} color={palette.slate}>
            There are currently no medical recommendations saved as a draft.
          </Text>
        </>
      ) : (
        props.formRecords
          .filter(fr => isAmhpForm(fr.formType) === props.isAmhpForm || isConveyanceForm(fr.formType))
          .map((fr, i) => {
            const formIsUnsignedJointMedRecCreatdByAnotherDoctor =
              isJointForm(fr.formType) &&
              [FormStatus.s10_draft, FormStatus.s30_changes_in_progress].includes(fr.status) &&
              fr.createdBy !== props.user?.id;

            const mhaFormVersion = getMhaFormVersion(ENV.ENV, fr.createdAt);
            const form = Forms[fr.formType](mhaFormVersion);

            // inc override statement of reason
            const formName = form.section
              ? `Section ${form.section}@${fr.formType}`
              : fr.formType.replace(/_/g, " ") === "STATEMENT OF REASON"
              ? "NO MED REC"
              : fr.formType.replace(/_/g, " ");

            return (
              <MHAFormRecord
                key={i}
                formName={formName}
                formTitle={form.shortTitle}
                historyElements={fr.historyElements}
                topSpacing={i !== 0}
                isArchived={!!fr.isDeleted}
                ctaButtons={
                  formIsUnsignedJointMedRecCreatdByAnotherDoctor || props.removeCTAButtons
                    ? []
                    : [
                        {
                          label:
                            [FormStatus.s10_draft, FormStatus.s30_changes_in_progress].includes(fr.status) &&
                            fr.authors?.includes(props.user?.id || "")
                              ? "Edit"
                              : "View",
                          onClick: () =>
                            navigation.push(RouteKeys.MHAFormScreen, {
                              assessmentId: props.assessmentId,
                              formType: fr.formType,
                              formId: fr.id,
                            }),
                        },
                        // TODO: Switch to dialog control from web alert box when that component is developed
                        ...(!canUserDeleteForm(
                          fr.formType,
                          fr.status,
                          props.user?.groups,
                          fr.authors?.includes(props.user?.id || "") || false,
                          fr.createdBy === props.user?.id,
                          props.isAssessmentAmhp
                        )
                          ? []
                          : [
                              {
                                label: "Delete",
                                onClick: () =>
                                  alert(
                                    "Confirm Delete Form",
                                    "Are you sure you wish to delete this form?",
                                    undefined,
                                    async () => {
                                      await deleteForm(v2, fr.id)
                                        .then(() => {
                                          setMessage("Form has been deleted");
                                          props.refetch();
                                          recordDeleteEvent("success", props.isAmhpForm, fr, props.assessmentId);
                                        })
                                        .catch(e => {
                                          recordDeleteEvent("failure", props.isAmhpForm, fr, props.assessmentId);
                                          setLastException(e);
                                          buildErr(e, setLastException);
                                        });
                                    }
                                  ),
                              },
                            ]),
                      ]
                }
                lastElement={i === props.formRecords.length - 1}
              />
            );
          })
      )}
    </>
  );
}
