import { useEffect, useState } from "react";
import { Dimensions, Platform } from "react-native";

/**
 * Subscribe to a bool that is true if the viewport is wide enough to show the web layout
 */
export default function useIsWebView(): boolean {
  const webPixelWidthBreakPoint = 1000;
  const device = Platform.OS === "web" ? "window" : "screen";
  const [isWebView, setIsWebView] = useState(Dimensions.get(device).width >= webPixelWidthBreakPoint);

  useEffect(() => {
    const _handleResize = () => setIsWebView(Dimensions.get(device).width >= webPixelWidthBreakPoint);
    Dimensions.addEventListener("change", _handleResize);
    return () => Dimensions.removeEventListener("change", _handleResize);
  }, []);

  return isWebView;
}
