import React from "react";
import { TouchableOpacity, StyleSheet, View } from "react-native";
import { useRecoilValue } from "recoil";

import { FormType } from "libs/types/mhaForms";
import { Forms } from "libs/mhaForms/formSections";
import { AnalyticsEvent } from "libs/analytics/events";

import { useBottomSheet } from "@/hooks";

import { ContentWrap } from "@/components/ContentWrap";
import Text from "@/components/Text";
import { TypographyType } from "@/models/Typography";
import { RouteKeys } from "@/navigationv2";
import { spacing, palette } from "@/theme";
import { recordEvent } from "@/utils/analytics";
import { userDetails } from "@/utils/recoil";
import { ParamListBase, useNavigation } from "@react-navigation/native";
import { getMhaFormVersion } from "libs/mhaForms/helpers";
import ENV from "@/config";
import { StackNavigationProp } from "@react-navigation/stack";
import { docInformalMedRecs } from "libs/utils/featureFlags";

interface MHAFCreateItemProps {
  formType: FormType;
  disabled?: string | boolean;
  onPress: () => void;
}

export const MHAFCreateItem = ({ formType, disabled = false, onPress }: MHAFCreateItemProps) => {
  const mhaFormVersion = getMhaFormVersion(ENV.ENV);
  const form = Forms[formType](mhaFormVersion);
  const isMHAForm = formType !== "STATEMENT_OF_REASON";

  return (
    <View style={styles.wrapper}>
      {isMHAForm && <Text format={TypographyType.RegularBold} style={styles.section}>{`Section ${form.section}`}</Text>}
      <Text format={TypographyType.SmallBold} style={styles.heading}>
        {isMHAForm ? `${form.shortTitle} (${formType as string})` : form.shortTitle}
      </Text>

      <Text format={TypographyType.Tiny} style={styles.desc} color={palette.slate}>
        {form.longTitle}
      </Text>

      <TouchableOpacity disabled={!!disabled} onPress={onPress}>
        <Text format={TypographyType.SmallBold} color={disabled ? palette.greyBlue : palette.blue}>
          {"Create Form"}
        </Text>
        {disabled && (
          <Text format={TypographyType.Micro} color={palette.greyBlue}>
            {disabled}
          </Text>
        )}
      </TouchableOpacity>
    </View>
  );
};

interface Analytics {
  formType: FormType;
  amhpTeamId?: string;
  assessmentId?: string;
}

/**
 * Create the analytics event that's fired when the current doctor or amhp
 * creates a new form.
 *
 * @param formType
 * @param analytics
 */
function createFormAnalyticsEvent(isDoctor: boolean, analytics: Analytics) {
  const { amhpTeamId = "", assessmentId = "", formType } = analytics;
  const eventName = isDoctor ? AnalyticsEvent.MHA_FORM_DOCTOR_CREATED : AnalyticsEvent.MHA_FORM_AMHP_CREATED;

  recordEvent(eventName, { amhpTeamId, assessmentId, formType });
}

type NavigationParams = { [key: string]: string };
type AnalyticsParams = { [key: string]: string };

export function useCreateFormsBottomSheet(
  forms: FormType[],
  navigationParam?: NavigationParams,
  analyticsParams?: AnalyticsParams,
  featureFlags?: string,
  doctorVisits?: number
) {
  const { openBottomSheet, closeBottomSheet } = useBottomSheet();
  const user = useRecoilValue(userDetails);
  const isDoctor = user?.groups?.includes("Doctor") || false;
  const navigation = useNavigation<StackNavigationProp<ParamListBase>>();

  const formItems = Object.keys(Forms)
    .filter(
      f =>
        (f === "STATEMENT_OF_REASON" ? docInformalMedRecs(featureFlags || "{}") : true) && forms.includes(f as FormType)
    )
    .map(f => (
      <MHAFCreateItem
        key={f}
        formType={f as FormType}
        disabled={
          ["A3", "A7"].includes(f) &&
          !!doctorVisits &&
          doctorVisits < 2 &&
          "This option is unavailable because you are currently the only doctor assigned to this Visit"
        }
        onPress={() => {
          createFormAnalyticsEvent(isDoctor, { ...analyticsParams, formType: f as FormType });
          closeBottomSheet();
          if (isDoctor && !navigationParam) {
            navigation.navigate(RouteKeys.TeamSelectScreen, {
              formType: f,
            });
          } else {
            navigation.navigate(RouteKeys.MHAFormScreen, {
              ...(navigationParam || {}),
              formType: f,
            });
          }
        }}
      />
    ));

  const BottomSheetComponent = () => <ContentWrap>{formItems}</ContentWrap>;

  return () => {
    openBottomSheet({
      type: "generic",
      data: {
        heading: "Create MHA Form",
        component: BottomSheetComponent,
      },
    });
  };
}

const styles = StyleSheet.create({
  wrapper: {
    paddingTop: spacing[20],
    paddingBottom: spacing[25],
    borderBottomColor: palette.cloud,
    borderBottomWidth: 1,
  },

  section: {
    paddingBottom: spacing[5],
  },

  heading: {
    paddingBottom: spacing[10],
  },

  desc: {
    paddingBottom: spacing[5],
  },
});
