import React, { useState, useMemo } from "react";
import { View } from "react-native";
import { TouchableRipple } from "react-native-paper";
import Collapsible from "react-native-collapsible";

import { useDeviceWidth } from "../../utils/helpers";
import CardButton from "./CardButton/CardButton";
import Icon from "../Icon";
import { palette } from "../../theme";
import css from "./Card.css";

interface CardProps {
  statusBarColor: string;
  onPress?: () => void;
  buttons?: {
    label: string;
    onPress: () => void;
    icon?: string;
    disabled?: boolean;
  }[];
  expandableContent?: React.ReactNode | (() => React.ReactNode) | false;
  expanded?: boolean;
  slim?: boolean;
}

const Card: React.FC<CardProps> = ({
  buttons,
  children,
  statusBarColor,
  expanded = false,
  expandableContent,
  onPress,
  slim,
}) => {
  const deviceWidth = useDeviceWidth();
  const [isCollapsed, setIsCollapsed] = useState<boolean>(!expanded);
  const [isFirstExpansion, setIsFirstExpansion] = useState(true);

  const styles = {
    buttons: css.buttons,
    card: useMemo(
      () => [
        css.card,
        {
          paddingRight: expandableContent && !buttons ? 44 : 0,
        },
      ],
      [buttons, expandableContent]
    ),
    content: useMemo(() => [css.content, deviceWidth <= 320 && css.contentSmall, slim && css.contentSlim], [slim]),
    expandableSpacer: css.expandableSpacer,
    expandToggleArrowButton: css.expandToggleArrowButton,
    statusBar: useMemo(
      () => [css.statusBar, deviceWidth <= 320 && css.statusBarSmall, { backgroundColor: statusBarColor }],
      [statusBarColor]
    ),
  };

  const expandArrow = isCollapsed ? "keyboard-arrow-down" : "keyboard-arrow-up";

  const cardButtons = buttons ? buttons.map((btn, i) => <CardButton key={i} {...btn} />) : [];

  return (
    <TouchableRipple style={styles.card} onPress={onPress}>
      <>
        {expandableContent && !buttons && (
          <TouchableRipple
            style={styles.expandToggleArrowButton}
            onPress={e => {
              e.stopPropagation();
              setIsFirstExpansion(false);
              setIsCollapsed(!isCollapsed);
            }}
          >
            <Icon name={expandArrow} color={palette.greyBlue} />
          </TouchableRipple>
        )}

        <View style={styles.statusBar} />

        <View style={styles.content}>
          {children}
          {expandableContent && (
            <Collapsible collapsed={isCollapsed}>
              <View style={styles.expandableSpacer} />
              {typeof expandableContent === "function" ? !isFirstExpansion && expandableContent() : expandableContent}
            </Collapsible>
          )}
        </View>

        {buttons && (
          <View style={styles.buttons}>
            {cardButtons}
            {expandableContent && (
              <CardButton
                label={isCollapsed ? "View Details" : "Hide Details"}
                icon={expandArrow}
                onPress={() => setIsCollapsed(!isCollapsed)}
              />
            )}
          </View>
        )}
      </>
    </TouchableRipple>
  );
};

export default Card;
