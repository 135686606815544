import React from "react";

import { BackButtonProps } from "@/components/BackButton/BackButton.props";
import Holidays from "@/components/Holidays";
import HolidayProvider from "@/components/Holidays/state/HolidayProvider";
import { AppScreen } from "@/layouts/AppScreen/AppScreen";
import { RouteKeys } from "@/navigationv2";
import { DoctorProfileRoutes, DoctorTabRoutes, ScreenNavigationProps, ScreenOptions } from "@/navigationv2/types";
import { createScreenTitle } from "@/navigationv2/utilities";
import { color } from "@/theme";

const backButtonConfig: BackButtonProps = {
  enabled: true,
  float: true,
  color: color.textWhite,
};

const HolidaysMemo = React.memo(Holidays);

interface PropTypes extends ScreenNavigationProps<DoctorTabRoutes, DoctorProfileRoutes, RouteKeys.HolidaysScreen> {}

const HolidaysScreen = () => {
  return (
    <AppScreen contextLabel="Diary" pageTitle="Manage Time Away" backButton={backButtonConfig} testID="HolidaysScreen">
      <HolidayProvider>
        <HolidaysMemo />
      </HolidayProvider>
    </AppScreen>
  );
};

const screenOptions: ScreenOptions = {
  title: createScreenTitle("Manage Time Away"),
};

HolidaysScreen.screenOptions = screenOptions;

export default HolidaysScreen;
