import { StyleSheet, Platform } from "react-native";
import { palette, spacing } from "../../../theme";

const css = StyleSheet.create({
  cell: {
    flexDirection: "row",
    alignItems: "flex-end",
    paddingLeft: spacing[20],
  },

  cells: {
    flexDirection: "row",
    alignItems: "center",
    flexGrow: 1,
    paddingRight: 54,
  },

  expandableContent: {
    position: "relative",
    padding: spacing[20],
  },

  expandableContentBG: {
    width: "100%",
    height: "100%",
    position: "absolute",
    left: 0,
    top: 0,
    opacity: 0.025,
    zIndex: -1,
  },

  expandToggleArrowButton: {
    position: "absolute",
    right: 0,
    top: 0,
    width: 54,
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },

  expandToggleArrowButtonBorder: {
    position: "absolute",
    left: 0,
    top: 15,
    width: 1,
    height: 24,
    backgroundColor: palette.grey,
  },

  rowContent: {
    position: "relative",
    paddingVertical: spacing[15],
    ...Platform.select({
      web: {
        cursor: "pointer",
      },
    }),
  },

  rowBG: {
    width: "100%",
    height: "100%",
    position: "absolute",
    left: 0,
    top: 0,
    zIndex: -1,
  },
});

export default css;
