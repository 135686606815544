import React, { useEffect, useContext } from "react";
import { View, Platform, StyleSheet } from "react-native";
import { Dayjs } from "dayjs";

import { TextInput } from "../TextInput";
import { Checkbox } from "../Checkbox/Checkbox";
import DualTimePicker from "../TimePicker/DualTimePicker";
import DualDatePicker from "../DatePicker/DualDatePicker";

import { spacing } from "../../theme";
import { HolidayModelActionTypes, HolidayControllerActionTypes, StateContext, StateProps } from "./state/initialState";
import { HolidayContext } from "./state/HolidayProvider";
import { useRecoilValue } from "recoil";
import { availabilityCalendarDate as recoilAvailabilityCalendarDate } from "../../utils/recoil";

const HolidayForm = (props: { editMode?: boolean; state?: StateProps; dispatch?: any; controllerDispatch?: any }) => {
  const { state, dispatch, controllerDispatch } = props.editMode ? props : useContext<StateContext>(HolidayContext);
  const { holidayStartDate, holidayEndDate, holidayNotes, startTime, endTime, holidayDateError, allDayEvent } = state!;

  const availabilityCalendarDate = useRecoilValue(recoilAvailabilityCalendarDate);

  function setHolidayStartDate(date: Dayjs) {
    dispatch({
      type: HolidayModelActionTypes.SET_HOLIDAY_START_DATE,
      payload: date,
    });
  }

  function setHolidayEndDate(date: Dayjs) {
    dispatch({
      type: HolidayModelActionTypes.SET_HOLIDAY_END_DATE,
      payload: date,
    });
  }

  function setAllDayEvent() {
    setStartTime({
      hours: allDayEvent ? 9 : 0,
      minutes: 0,
    });
    setEndTime({
      hours: allDayEvent ? 17 : 23,
      minutes: allDayEvent ? 0 : 59,
    });
    controllerDispatch({
      type: HolidayControllerActionTypes.TOGGLE_ALL_DAY_EVENT,
    });
  }

  function setStartTime(time: { hours: number; minutes: number }) {
    dispatch({ type: HolidayModelActionTypes.SET_START_TIME, payload: time });
  }

  function setEndTime(time: { hours: number; minutes: number }) {
    dispatch({ type: HolidayModelActionTypes.SET_END_TIME, payload: time });
  }

  useEffect(() => {
    if (availabilityCalendarDate && !props.editMode) {
      setHolidayStartDate(availabilityCalendarDate);
      setHolidayEndDate(availabilityCalendarDate);
    }
  }, []);

  return (
    <>
      <View style={styles.row}>
        <View style={styles.leftWrapper}>
          <DualDatePicker
            label="Start Date"
            value={holidayStartDate}
            noIcon={true}
            onValueChange={value => {
              if (value > holidayEndDate && Platform.OS !== "web")
                setHolidayEndDate(
                  value
                    .hour(endTime.hours)
                    .minute(endTime.minutes)
                    .second(0)
                    .millisecond(1)
                );

              setHolidayStartDate(
                value
                  .hour(startTime.hours)
                  .minute(startTime.minutes)
                  .second(0)
                  .millisecond(1)
              );
            }}
          />
        </View>
        <View style={styles.rightWrapper}>
          <DualTimePicker
            label="From"
            disabled={allDayEvent}
            value={{
              hours: allDayEvent ? 0 : startTime.hours,
              minutes: allDayEvent ? 0 : startTime.minutes,
            }}
            noIcon={true}
            onValueChange={val => {
              setStartTime(val);
              setHolidayStartDate(
                holidayStartDate
                  .hour(val.hours)
                  .minute(val.minutes)
                  .second(0)
                  .millisecond(1)
              );
            }}
          />
        </View>
      </View>
      <View style={styles.row}>
        <View style={styles.leftWrapper}>
          <DualDatePicker
            label="End Date"
            error={holidayDateError}
            value={holidayEndDate}
            noIcon={true}
            onValueChange={value => {
              if (holidayDateError)
                controllerDispatch({
                  type: HolidayControllerActionTypes.TOGGLE_HOLIDAY_DATE_ERROR,
                });
              if (holidayStartDate.diff(value) > 0 && Platform.OS !== "web")
                setHolidayStartDate(value.hour(endTime.hours).minute(endTime.minutes));
              setHolidayEndDate(value.hour(endTime.hours).minute(endTime.minutes));
            }}
          />
        </View>
        <View style={styles.rightWrapper}>
          <DualTimePicker
            label="Until"
            disabled={allDayEvent}
            value={{
              hours: allDayEvent ? 0 : endTime.hours,
              minutes: allDayEvent ? 0 : endTime.minutes,
            }}
            noIcon={true}
            error={holidayDateError}
            onValueChange={val => {
              if (holidayDateError)
                controllerDispatch({
                  type: HolidayControllerActionTypes.TOGGLE_HOLIDAY_DATE_ERROR,
                });
              setEndTime(val);
              setHolidayEndDate(holidayEndDate.hour(val.hours).minute(val.minutes));
            }}
          />
        </View>
      </View>
      <View style={styles.checkboxWrapper}>
        <Checkbox status={allDayEvent} onPress={setAllDayEvent} label="ALL DAY" />
      </View>
      <TextInput
        icon="note"
        label="Notes"
        autoCorrect={true}
        spellCheck={true}
        returnKeyType="go"
        value={holidayNotes || undefined}
        multiline={true}
        selectTextOnFocus={true}
        numberOfLines={3}
        supplementaryInfo="Please share any additional notes if necessary."
        onChangeText={(value: string) =>
          dispatch({
            type: HolidayModelActionTypes.SET_HOLIDAY_NOTES,
            payload: value,
          })
        }
      />
    </>
  );
};

export default HolidayForm;

const styles = StyleSheet.create({
  checkboxWrapper: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginTop: spacing[10],
    marginBottom: spacing[20],
  },
  row: {
    flexDirection: "row",
    alignItems: "flex-start",
  },
  leftWrapper: {
    flex: 1,
    overflow: "hidden",
    marginRight: spacing[10],
  },
  rightWrapper: {
    flex: 1,
    overflow: "hidden",
    marginLeft: spacing[10],
  },
  column: {
    flexDirection: "column",
  },
  flex: {
    flex: 1,
  },
  flexStart: {
    alignItems: "flex-start",
  },
});
