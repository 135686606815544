import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Platform, StyleSheet, TouchableOpacity, View } from "react-native";
import Collapsible from "react-native-collapsible";
import { useSetRecoilState, useRecoilValue, useRecoilState } from "recoil";
import { useMutation } from "@apollo/react-hooks";
import dayjs, { Dayjs } from "dayjs";

import { UpdateHolidayInput } from "libs/types/API";
import { convertAvailabilityToEditForm, convertHolidayToEditForm, eventToCalendar } from "libs/dates";
import { Availability, AvailabilityType, CalendarItem } from "libs/types/availability";
import { EventItem } from "libs/types/holiday";
import {
  editableAvailability as editableAvailabilityRecoil,
  lastException,
  snackbarMessage,
  userDetails,
} from "@/utils/recoil/index";

import { DoctorProfile, GET_DOCTOR } from "@/models/DoctorProfile";
import { buildErr, saveError } from "@/models/Error";
import { UPDATE_HOLIDAYS } from "@/models/Holiday";
import { TypographyType } from "@/models/Typography";
import { RouteKeys } from "@/navigationv2";
import { checkMutationInput, setStartEndVisitTimes } from "@/utils/helpers";
import { useBottomSheet, useIsWebView } from "@/hooks";
import { palette, spacing } from "@/theme";

import { Button, ButtonList } from "@/components/Button";
import { Calendar } from "@/components/Calendar";
import { ContentWrap } from "@/components/ContentWrap";
import Icon, { IconName } from "@/components/Icon";
import ItemSpacer from "@/components/ItemSpacer";
import Text from "@/components/Text";
import HolidayForm from "@/components/Holidays/HolidayForm";
import {
  HolidayControllerActionTypes,
  HolidayModelActionTypes,
  StateContext,
} from "@/components/Holidays/state/initialState";
import { HolidayContext } from "@/components/Holidays/state/HolidayProvider";
import { WebSectionButton } from "@/components/WebSectionButton";

const CalendarMemo = React.memo(Calendar);

interface DoctorDiarySectionProps {
  navigation: any;
  doctor: DoctorProfile;
}
export const DoctorDiarySection = (props: DoctorDiarySectionProps): JSX.Element => {
  const { openBottomSheet } = useBottomSheet();
  const isWebView = useIsWebView();
  const isEditing = useRef(false);
  const user = useRecoilValue(userDetails);
  const setLastException = useSetRecoilState(lastException);
  const { state, dispatch, controllerDispatch } = useContext<StateContext>(HolidayContext);
  const [editableAvailability, setEditableAvailability] = useRecoilState(editableAvailabilityRecoil);
  const setMessage = useSetRecoilState(snackbarMessage);
  const [submitted, setSubmitted] = useState(false);
  const [selectedElement, setSelectedElement] = useState<null | string>(null);
  const [editHolidayBottomSheet, setEditHolidayBottomSheet] = useState<boolean>(false);

  const availabilityToCalendar = (a: Availability): CalendarItem => {
    return {
      id: a.id as string,
      type: a.type || AvailabilityType.independent,
      distance: 50,
      location: a.location,
      locationName: a.locationName,
      rrule: a.rrule,
      endDate: a.endDate,
      mht: a.mht,
      oncall: a.oncall,
      rotaId: a.rotaId,
      rotaName: a.rotaName,
      endHour: a.endHour,
      notes: a.notes,
      contractShortCode: a.contractList ? a.contractList.name : undefined,
    };
  };

  const calendarHolidays = useMemo(() => (props.doctor.holidays ? eventToCalendar(props.doctor.holidays) : []), [
    props.doctor.holidays,
  ]);
  const calendarAvailabilities = useMemo(() => props.doctor.availabilities?.map(availabilityToCalendar) || [], [
    props.doctor.availabilities,
  ]);
  const calendarVisits = useMemo(
    () =>
      props.doctor.visits
        ? eventToCalendar(
            props.doctor.visits.map((i: { id: string; time: Dayjs; partialPostcode: string | null }) => ({
              id: i.id,
              visible: true,
              isBooking: true,
              partialPostcode: i.partialPostcode,
              ...setStartEndVisitTimes(i.time),
            }))
          )
        : [],
    [props.doctor.visits]
  );

  const _goToVisitDetails = (id: string) =>
    props.navigation.navigate(RouteKeys.DoctorVisitDetailsScreen, {
      visitId: id,
    });
  const _onManageHolidayPress = () => props.navigation.navigate(RouteKeys.HolidaysScreen);
  const _onAddAvailabilityPress = () => props.navigation.navigate(RouteKeys.DoctorAvailabilityScreen);
  const goToVisit = useCallback((id: string) => _goToVisitDetails(id), []);
  const _onEditAvailabilityPress = useCallback((availability: Availability) => {
    if (!availability) return;
    isEditing.current = true;
    const av = convertAvailabilityToEditForm(availability);
    setEditableAvailability(av);
  }, []);
  const _onEditHolidayPress = useCallback((holiday: EventItem) => {
    if (!holiday) return;
    const { model, controller } = convertHolidayToEditForm(holiday);
    dispatch({
      type: HolidayModelActionTypes.SET_EDIT_FORM,
      payload: model,
    });
    controllerDispatch({
      type: HolidayControllerActionTypes.SET_EDIT_FORM,
      payload: controller,
    });
    setSelectedElement(holiday.id);
    setEditHolidayBottomSheet(true);
  }, []);
  function setResetHolidayForm() {
    dispatch({
      type: HolidayModelActionTypes.RESET_HOLIDAY_FORM,
      payload: dayjs(),
    });
    controllerDispatch({
      type: HolidayControllerActionTypes.RESET_CONTROLLER,
    });
  }
  const [updateHolidaysMtn] = useMutation(UPDATE_HOLIDAYS);

  useEffect(() => {
    if (editableAvailability && isEditing.current) props.navigation.navigate(RouteKeys.EditDoctorAvailabilityScreen);
  }, [editableAvailability]);

  // NOTE: This is being called from a bottom sheet, any data referenced inside must
  // be in the useEffect dependency array.
  const updateHolidays = () => {
    setSubmitted(true);
    if (state.holidayEndDate.diff(state.holidayStartDate) < 0) {
      controllerDispatch({
        type: HolidayControllerActionTypes.TOGGLE_HOLIDAY_DATE_ERROR,
      });
      setSubmitted(false);
      return;
    }
    if (!selectedElement) {
      setSubmitted(false);
      return;
    }

    updateHolidaysMtn({
      variables: {
        input: checkMutationInput<UpdateHolidayInput>({
          id: selectedElement,
          end: state.holidayEndDate.toISOString(),
          start: state.holidayStartDate.toISOString(),
          notes: state.holidayNotes,
          visible: state.holidayVisibility,
          s12DoctorHolidaysId: user ? user.id : "",
        }),
      },
      refetchQueries: [
        {
          query: GET_DOCTOR,
          variables: { id: user ? user.id : "" },
        },
      ],
    })
      .then(() => {
        setMessage("Time Away successfully updated");
        setResetHolidayForm();
        setEditHolidayBottomSheet(false);
        setSubmitted(false);
      })
      .catch(buildErr(saveError, setLastException))
      .then(() => {
        setSubmitted(false);
      });
  };

  // Edit Holiday BottomSheet
  useEffect(() => {
    if (editHolidayBottomSheet && !!selectedElement) {
      openBottomSheet({
        type: "generic",
        data: {
          heading: "Update Time Away",
          component: HolidayForm,
          componentProps: {
            editMode: true,
            state,
            dispatch,
            controllerDispatch,
          },
          componentContentWrap: true,
          confirmButton: {
            label: "Save Changes",
            onPress: updateHolidays,
            disabled: submitted,
          },
          onDismiss: () => {
            setResetHolidayForm();
            setEditHolidayBottomSheet(false);
          },
        },
      });
    }
  }, [editHolidayBottomSheet, selectedElement, state, submitted]);

  return (
    <>
      <DoctorDiaryHeading
        isWebView={isWebView}
        navigation={props.navigation}
        onManageHolidayPress={_onManageHolidayPress}
        onAddAvailabilityPress={_onAddAvailabilityPress}
      />
      {!isWebView ? (
        <ContentWrap>
          <ButtonList disablePadding="top">
            <Button mode="contained" onPress={_onAddAvailabilityPress} marginBottom={0}>
              Add Availability
            </Button>
            <Button mode="outlined" onPress={_onManageHolidayPress} marginBottom={0}>
              Manage Time Away
            </Button>
          </ButtonList>
        </ContentWrap>
      ) : null}
      <CalendarMemo
        holidays={calendarHolidays}
        visits={calendarVisits}
        holidayTimeSpan={props.doctor.holidays ? props.doctor.holidays : []}
        scheduleItems={calendarAvailabilities}
        editAvailability={_onEditAvailabilityPress}
        editHoliday={_onEditHolidayPress}
        editable={true}
        goToVisit={goToVisit}
        displaySwipeInstructions={Platform.OS !== "web"}
      />
    </>
  );
};

interface DoctorDiaryHeadingProps {
  navigation: any;
  isWebView: boolean;
  onManageHolidayPress(): void;
  onAddAvailabilityPress(): void;
}
const DoctorDiaryHeading = (props: DoctorDiaryHeadingProps) => {
  const [hideDiaryHelperText, setHideDiaryHelperText] = useState<boolean>(true);
  const diaryHelperTextStyles = useMemo(() => [styles.diaryHelperText, props.isWebView && styles.diaryHelperTextWeb], [
    props.isWebView,
  ]);
  const _onInfoToggleButtonPress = () => setHideDiaryHelperText(!hideDiaryHelperText);
  const Wrapper = useMemo(() => (props.isWebView ? React.Fragment : ContentWrap), [props.isWebView]);
  return (
    <>
      <View style={styles.diarySectionHeading}>
        <Wrapper>
          <View style={styles.diaryHeadingText}>
            <Text format={TypographyType.HeadingMedium} marginBottom={0}>
              Diary
            </Text>
            <TouchableOpacity onPress={_onInfoToggleButtonPress}>
              <View style={styles.infoToggleButton}>
                <Icon
                  name={hideDiaryHelperText ? IconName.info_outline : IconName.close}
                  size={24}
                  color={palette.blue}
                />
              </View>
            </TouchableOpacity>
          </View>
        </Wrapper>
        {props.isWebView ? (
          <View style={styles.diaryHeadingWebButtons}>
            <ItemSpacer gap={10} direction="row">
              <WebSectionButton label="Manage Time Away" theme="secondary" onPress={props.onManageHolidayPress} />
              <WebSectionButton label="Add Availability" theme="primary" onPress={props.onAddAvailabilityPress} />
            </ItemSpacer>
          </View>
        ) : null}
      </View>

      <Collapsible collapsed={hideDiaryHelperText} style={diaryHelperTextStyles}>
        <Text format={TypographyType.SmallBold} marginBottom={spacing[5]}>
          Manage Time Away
        </Text>
        <Text format={TypographyType.Tiny} color={palette.slate}>
          Manage Time Away allows you to define periods of uncontactable time, e.g. annual leave, appointments or
          maternity/paternity leave.
        </Text>
        <Text format={TypographyType.SmallBold} marginTop={spacing[15]} marginBottom={spacing[5]}>
          Add Availability
        </Text>
        <Text format={TypographyType.Tiny} color={palette.slate}>
          Add Availability allows you to define time periods during which you’re available to attend MHA assessments.
        </Text>
      </Collapsible>
      {!hideDiaryHelperText ? <View style={styles.diaryHelperTextSpacer} /> : null}
    </>
  );
};

const styles = StyleSheet.create({
  diarySectionHeading: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "baseline",
    marginBottom: spacing[20],
  },

  diaryHeadingText: {
    flexDirection: "row",
  },

  diaryHeadingWebButtons: {
    flexDirection: "row",
  },

  diaryHelperTextSpacer: {
    width: "100%",
    height: 20,
    backgroundColor: palette.white,
  },

  diaryHelperText: {
    backgroundColor: palette.cloud,
    padding: spacing[20],
    borderRadius: 10,
  },
  diaryHelperTextWeb: {
    padding: spacing[20],
  },

  infoToggleButton: {
    padding: spacing[5],
    marginLeft: spacing[10],
    borderRadius: 44,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: palette.blueSecondary,
    transform: [{ translateY: 2 }],
  },
});
