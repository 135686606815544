import dayjs from "dayjs";
import React, { useEffect, useMemo } from "react";
import { Platform } from "react-native";
import { ApolloClient } from "apollo-boost";
import { useApolloClient, useQuery } from "@apollo/react-hooks";
import { DoctorSetup } from "./DoctorSetup";
import { ErrorMessage } from "../Error/Error";
import Loading from "../Loading";
import {
  GET_DOCTOR,
  GET_DOCTOR_VISITS,
  VISIT_QUERY,
  GET_DOCTOR_CLAIMS_BY_STATUS,
  GetDoctorResponse,
  GetDoctorVisitsResponse,
  GetDoctorClaimsByStatusResponse,
  convertToProfile,
} from "@/models/DoctorProfile";
import { VisitListItem } from "libs/types/visit";
import HolidayProvider from "../Holidays/state/HolidayProvider";
import { networkError, S12Error } from "@/models/Error";
import { ClaimStatus } from "libs/types/API";
import { checkOfflineCache } from "../../utils/helpers";
import { GQL_LIMITS } from "@/models/gql/limits";
import { useRecoilState, useSetRecoilState } from "recoil";
import { doctorClaimsTabBadge, userDetails } from "../../utils/recoil/index";

interface Variables {
  username: string;
  refreshRequired: boolean;
  onRefreshComplete: (value: React.SetStateAction<boolean>) => void;
}

const DoctorSetupGQL = (props: Variables) => {
  const queryOptions = useMemo(
    () => ({
      variables: {
        id: props.username,
      },
      fetchPolicy: "cache-and-network" as const,
    }),
    [props.username]
  );

  const { loading, error, data, refetch } = useQuery<GetDoctorResponse>(GET_DOCTOR, queryOptions);

  const { getS12Doctor } = data || {};
  const setDoctorClaimsTabBadge = useSetRecoilState(doctorClaimsTabBadge);

  const openClaims =
    getS12Doctor?.claims &&
    getS12Doctor?.claims.items &&
    getS12Doctor?.claims.items.length &&
    getS12Doctor?.claims.items.filter(cl => cl && cl.status === ClaimStatus.action_required);

  useEffect(() => {
    openClaims && openClaims.length > 0 ? setDoctorClaimsTabBadge(openClaims.length) : setDoctorClaimsTabBadge(0);
  });

  useEffect(() => {
    let hasUnloaded = false;
    if (getS12Doctor && props.refreshRequired) {
      // Prevents data from being fetched twice on initial load

      refetch()
        .then(() => {
          if (hasUnloaded) {
            return;
          }
          props.onRefreshComplete(false);
        })
        .catch(() => {
          console.error(new S12Error(networkError));
        });
    }
    return () => {
      hasUnloaded = true;
    };
  }, [getS12Doctor, props.refreshRequired]);

  const convertedDoctorData = useMemo(() => (getS12Doctor ? convertToProfile(getS12Doctor) : undefined), [
    getS12Doctor,
  ]);
  const client: ApolloClient<any> = useApolloClient();
  const complete = checkOfflineCache(client, GET_DOCTOR, queryOptions.variables);

  const [user, setUserDetails] = useRecoilState(userDetails);

  useEffect(() => {
    user && convertedDoctorData && setUserDetails({ ...user, name: convertedDoctorData.name });
  }, [convertedDoctorData?.name]);

  if (!complete && error) {
    return <ErrorMessage apolloError={error} />;
  } else if (!complete && loading && !convertedDoctorData && !getS12Doctor) {
    return <Loading />;
  } else if (convertedDoctorData && getS12Doctor) {
    if (Platform.OS !== "web") {
      const visitCacheComplete = checkOfflineCache(client, GET_DOCTOR_VISITS, {
        id: props.username,
      });
      if (!visitCacheComplete) {
        client
          .query<GetDoctorVisitsResponse>({
            query: GET_DOCTOR_VISITS,
            variables: { id: props.username },
          })
          .then(
            ({ data }) =>
              data.getS12Doctor &&
              data.getS12Doctor.visits &&
              data.getS12Doctor.visits.items &&
              data.getS12Doctor.visits.items.forEach((visit: VisitListItem) => {
                if (!dayjs(visit.time).isBefore(dayjs(), "day")) {
                  client
                    .query({
                      query: VISIT_QUERY,
                      variables: { id: visit.id },
                    })
                    .catch(() => null);
                }
              })
          )
          .catch(() => null);
      }

      const claimsCacheComplete = checkOfflineCache(client, GET_DOCTOR_CLAIMS_BY_STATUS, {
        id: props.username,
      });
      if (!claimsCacheComplete) {
        client
          .query<GetDoctorClaimsByStatusResponse>({
            query: GET_DOCTOR_CLAIMS_BY_STATUS,
            variables: { id: props.username, limit: GQL_LIMITS.doctorClaims },
          })
          .catch(() => null);
      }
    }

    return (
      <HolidayProvider>
        <DoctorSetup doctor={convertedDoctorData} />
      </HolidayProvider>
    );
  } else {
    return <ErrorMessage />;
  }
};

export default DoctorSetupGQL;
