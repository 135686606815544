import React from "react";
import { View, StyleSheet, Platform } from "react-native";

import { BackButtonProps } from "@/components/BackButton/BackButton.props";
import { ContactUs } from "@/components/ContactUs/ContactUs";
import Text from "@/components/Text";
import { AuthScreen } from "@/layouts";
import { TypographyType } from "@/models/Typography";
import { ScreenOptions } from "@/navigationv2/types";
import { createScreenTitle } from "@/navigationv2/utilities";
import { spacing } from "@/theme";
import { color } from "@/theme/color";

import { version, devBuild } from "@/../package.json";

const backButtonConfig: BackButtonProps = {
  enabled: true,
  float: true,
  color: color.textWhite,
};

const AuthSupportScreen = () => {
  return (
    <AuthScreen backButton={backButtonConfig} testID="Auth-Support__Scren">
      <ContactUs />
      <View style={styles.version}>
        <Text format={TypographyType.Micro}>{`${Platform.OS === "web" ? "Website" : "App"} Version: ${version}${
          process.env.ENV !== "prod" ? `${devBuild}` : ``
        }`}</Text>
      </View>
    </AuthScreen>
  );
};

const screenOptions: ScreenOptions = {
  title: createScreenTitle("Support"),
};

AuthSupportScreen.screenOptions = screenOptions;

export default AuthSupportScreen;

const styles = StyleSheet.create({
  version: {
    alignItems: "center",
    marginVertical: spacing[20],
  },
});
