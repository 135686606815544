import React, { useCallback, useMemo } from "react";
import { View, StyleSheet, Platform, NativeSyntheticEvent, TextInputKeyPressEventData } from "react-native";
import { spacing, palette } from "@/theme";
import { TextInputMask } from "react-native-masked-text";
import { TypographyType } from "@/models/Typography";
import Text from "@/components/Text";
import { TextInput } from "../TextInput/TextInput";
import Slider from "./Slider";

export default function DualInput(props: {
  value: number;
  valueDisplay?: (num: number) => string;
  onValueChange: (value: number) => void;
  step: number;
  min: number;
  max: number;
  label: string;
  unitLabel: string;
  textInputStyle?: any;
  displaySuffix?: string;
}) {
  const onChangeText = useCallback(
    (v: string) => {
      if (v === "") {
        props.onValueChange(0);
      } else if (v.toString().slice(-2) === ":0" || v.toString().slice(-3) === ":00") {
        props.onValueChange(999);
      } else if (!isNaN(Number(v)) && Number(v) >= props.min && Number(v) <= props.max) {
        props.onValueChange(Number(v));
      }
    },
    [props.min, props.max, props.onValueChange]
  );

  const styleArray = useMemo(() => [styles.input, styles.dualInputLeft, props.textInputStyle], [props.textInputStyle]);
  const appendValueSuffix = `${props.value.toString()}${props.displaySuffix?.toString()}`;
  return (
    <View style={styles.container}>
      <View style={styles.textContainer}>
        <TextInput
          label={props.label}
          keyboardType={Platform.OS === "web" ? undefined : "number-pad"}
          value={props.displaySuffix ? appendValueSuffix : props.value.toString()}
          onChangeText={onChangeText}
          style={styleArray}
          maxLength={5}
        />
        {!!props.unitLabel && <UnitLabel label={props.unitLabel} />}
      </View>
      <Slider
        value={props.value}
        step={props.step}
        min={props.min}
        max={props.max}
        onValueChange={props.onValueChange}
        valueDisplay={props.valueDisplay}
      />
    </View>
  );
}

const UnitLabel = (props: { label: string }) => {
  return (
    <Text style={styles.marginLeftUnitLabel} format={TypographyType.Micro} color={palette.greyBlue}>
      {props.label}
    </Text>
  );
};

export function HoursDualImport(props: {
  value: number;
  onValueChange: (value: number | string) => void;
  step: number;
  min: number;
  max: number;
  label: string;
  unitLabel: string;
  textInputStyle?: any;
}) {
  const valueDisplay = useCallback(v => leftPad(v) + ":00", []);

  return (
    <View style={styles.container}>
      <View style={styles.textContainer}>
        <HoursImport
          value={props.value}
          onValueChange={props.onValueChange}
          label={props.label}
          textInputStyle={props.textInputStyle}
          min={props.min}
          max={props.max}
          dualInput
        />
        {!!props.unitLabel && <UnitLabel label={props.unitLabel} />}
      </View>
      <Slider
        value={props.value}
        step={props.step}
        min={props.min}
        max={props.max}
        onValueChange={props.onValueChange}
        valueDisplay={valueDisplay}
      />
    </View>
  );
}

export function HoursImport(props: {
  value: number;
  onValueChange: (value: number | string) => void;
  label: string;
  unitLabel?: string;
  textInputStyle?: any;
  dualInput?: boolean;
  min: number;
  max: number;
}) {
  const onKeyPress = useCallback(
    (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
      if (
        (props.value === 1 && e.nativeEvent.key >= "0" && e.nativeEvent.key <= "9") ||
        (props.value === 2 && e.nativeEvent.key >= "0" && e.nativeEvent.key <= "3")
      ) {
        props.onValueChange(props.value * 10 + Number(e.nativeEvent.key));
      } else if (e.nativeEvent.key === "Backspace") {
        props.onValueChange(Math.floor(props.value / 10));
      } else if ((props.value > 2 || props.value === 0) && e.nativeEvent.key >= "0" && e.nativeEvent.key <= "9") {
        const num = Number(e.nativeEvent.key);

        !isNaN(num) && num >= props.min && num <= props.max && props.onValueChange(num);
      }
    },
    [props.value, props.min, props.max, props.onValueChange]
  );

  const render = useCallback(
    p => (
      <TextInputMask
        {...p}
        type={"datetime"}
        keyboardType={Platform.OS === "web" ? undefined : "number-pad"}
        onKeyPress={onKeyPress}
        options={{
          format: "HH:mm",
        }}
      />
    ),
    [onKeyPress]
  );

  const styleArr = useMemo(() => [styles.input, props.dualInput ? styles.dualInputLeft : {}, props.textInputStyle], [
    props.dualInput,
    props.textInputStyle,
  ]);

  return (
    <TextInput
      label={props.label}
      underlabel={props.unitLabel}
      keyboardType={Platform.OS === "web" ? undefined : "number-pad"}
      value={leftPad(props.value) + ":00"}
      render={render}
      style={styleArr}
      maxLength={5}
    />
  );
}

function leftPad(num: number, size = 2): string {
  let s = String(num);
  while (s.length < size) {
    s = "0" + s;
  }
  return s;
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "flex-end",
  },
  textContainer: {
    flexDirection: "column",
    flexShrink: 1,
    flexBasis: "33%",
    marginRight: spacing[20],
  },
  input: {
    flexDirection: "row",
  },
  dualInputLeft: {
    marginRight: spacing[15],
    minWidth: 90,
  },
  marginLeftUnitLabel: {
    marginLeft: spacing[10],
  },
});
