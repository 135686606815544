import React from "react";
import { TouchableRipple } from "react-native-paper";

import { useDeviceWidth } from "../../../utils/helpers";
import { TypographyType } from "@/models/Typography";

import Icon from "../../Icon";
import Text from "../../Text";
import { palette } from "../../../theme";
import css from "./CardButton.css";

interface CardButtonProps {
  label: string;
  onPress: () => void;
  first?: boolean;
  icon?: string;
  disabled?: boolean;
}

const CardButton: React.FC<CardButtonProps> = ({ first, icon, label, onPress, disabled }) => {
  const deviceWidth = useDeviceWidth();
  const styles = {
    button: [css.button, { borderLeftWidth: first ? 0 : 1 }],
    icon: css.icon,
    text: css.text,
  };

  return (
    <TouchableRipple style={styles.button} onPress={onPress} disabled={disabled}>
      <>
        {!!icon && deviceWidth > 320 && <Icon size={14} style={styles.icon} name={icon} color={palette.blue} />}
        <Text
          format={disabled ? TypographyType.Micro : TypographyType.MicroBold}
          color={disabled ? palette.greyBlue : palette.blue}
          style={styles.text}
        >
          {label}
        </Text>
      </>
    </TouchableRipple>
  );
};

export default CardButton;
