import React from "react";
import { ApolloClient, NetworkStatus } from "apollo-boost";
import { GraphQLError } from "graphql";
import { useApolloClient } from "@apollo/react-hooks";

import { MHALocationSearch } from "./MHALocationSearch";
import { MHALocationSearchItem, RetrieveMHADataObject } from "./MHALocationSearch.props";
import { MHA_LOCATION_SEARCH } from "@/models/Location";
import { getAddressError, S12Error, networkError, getError } from "@/models/Error";

// caller responsible for catching errors
function doSearchWithClient() {
  const client: ApolloClient<any> = useApolloClient();
  return (searchQuery?: string, addressId?: string) => {
    return client
      .query<{ results: MHALocationSearchItem[] }>({
        query: MHA_LOCATION_SEARCH,
        variables: { term: searchQuery, addressId },
      })
      .then(
        (res: {
          data: { results: MHALocationSearchItem[] };
          errors?: readonly GraphQLError[];
          loading: boolean;
          networkStatus: NetworkStatus;
          stale: boolean;
        }) => {
          if (res.data && res.data.results) {
            return res.data.results;
          } else if (res.errors) {
            throw new S12Error(getAddressError);
          } else if (res.networkStatus === NetworkStatus.error) {
            throw new S12Error(networkError);
          } else {
            throw new S12Error(getError);
          }
        }
      );
  };
}

export default function MHALocationSearchGql(props: {
  retrieveData(data: RetrieveMHADataObject): void;
  initialResults?: MHALocationSearchItem[];
  patientAddress?: boolean;
  disableStrictPostcodeValidation?: boolean;
  label: string;
  inputText: string;
}) {
  return (
    <MHALocationSearch
      label={props.label}
      getResults={doSearchWithClient()}
      inputText={props.inputText}
      initialResults={props.initialResults}
      disableStrictPostcodeValidation={props.disableStrictPostcodeValidation}
      retrieveData={props.retrieveData}
      patientAddress={props?.patientAddress}
    />
  );
}
