/* eslint-disable react-native/no-unused-styles */
/* ^ This is set because styles are selected dynamically, based on a prop; lack of explicit reference of styles causes a false "no-unused-styles" warning, hence the disable flag above. */

import React from "react";
import { StyleSheet, StyleProp, View, ViewStyle } from "react-native";

import { palette, spacing } from "../../theme";
import { TypographyType } from "@/models/Typography";
import { ucFirst } from "../../utils/helpers";
import Text from "../Text";

interface CardLabelProps {
  color: string;
  text: string;
  style?: StyleProp<ViewStyle>;
}

const CardLabel = (props: CardLabelProps) => {
  const { color, text } = props;
  const label = text === "On call" ? text : ucFirst(text);
  return (
    <View style={styles.container}>
      <View style={[styles.label, styles[color], props.style]}>
        <Text format={TypographyType.MicroBold} style={[styles.labelText, styles[`${color}Text`]]}>
          {label}
        </Text>
      </View>
    </View>
  );
};

const styles: {
  [key: string]: any;
} = StyleSheet.create({
  container: {
    alignItems: "flex-start",
  },

  label: {
    paddingHorizontal: spacing[10],
    paddingVertical: spacing[5],
    borderRadius: 5,
  },

  labelText: {
    textAlign: "center",
  },

  blue: { backgroundColor: palette.blue },
  aqua: { backgroundColor: palette.aqua },
  red: { backgroundColor: palette.red },
  purple: { backgroundColor: palette.purple },
  yellow: { backgroundColor: palette.yellow },
  greyBlue: { backgroundColor: palette.greyBlue },
  grey: { backgroundColor: palette.grey },
  cloud: { backgroundColor: palette.cloud },
  pink: { backgroundColor: palette.pink },

  inAssessment: { backgroundColor: palette.yellow },
  available: { backgroundColor: palette.aqua },
  availableText: { color: palette.navy },
  booked: { backgroundColor: palette.orange },
  bookedText: { color: palette.white },
  trust: { backgroundColor: palette.aqua },
  trustText: { color: palette.navy },
  oncall: { backgroundColor: palette.blue },
  oncallText: { color: palette.white },
  independent: { backgroundColor: palette.purple },
  independentText: { color: palette.white },
  noInformation: { backgroundColor: palette.grey },
  noInformationText: { color: palette.greyBlue },
  unavailable: { backgroundColor: palette.greyBlue },
  unavailableText: { color: palette.white },
  away: { backgroundColor: palette.red },
  awayText: { color: palette.white },

  blueText: { color: palette.white },
  aquaText: { color: palette.white },
  redText: { color: palette.white },
  purpleText: { color: palette.white },
  yellowText: { color: palette.navy },
  greyBlueText: { color: palette.white },
  greyText: { color: palette.navy },
  cloudText: { color: palette.navy },
});

export default CardLabel;
