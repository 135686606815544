import Amplify from "@aws-amplify/core";
import { Platform } from "react-native";

import { openLinkInApp } from "@/utils";

interface OptionParams {
  awsCognitoIdentityPoolId: string;
  awsCognitoRegion: string;
  awsProjectRegion: string;
  awsUserPoolsId: string;
  awsUserPoolsWebClientId: string;
  webURI: string;
  env?: string;
}

export default function configureAmplify(options: OptionParams) {
  const {
    awsCognitoIdentityPoolId,
    awsCognitoRegion,
    awsProjectRegion,
    awsUserPoolsId,
    awsUserPoolsWebClientId,
    webURI,
    env = "prod",
  } = options;

  const redirectBasePath = Platform.select({ web: webURI, default: "s12://" });
  const authRedirectSignInUrl = redirectBasePath;
  const authRedirectSignOutUrl = `${redirectBasePath}Auth/welcome`;

  const authUrlOpener = (url: string, redirectUrl: string) => openLinkInApp({ isAuthLink: true, redirectUrl, url });

  Amplify.configure({
    aws_cognito_identity_pool_id: awsCognitoIdentityPoolId,
    aws_cognito_region: awsCognitoRegion,
    aws_project_region: awsProjectRegion,
    aws_user_pools_id: awsUserPoolsId,
    aws_user_pools_web_client_id: awsUserPoolsWebClientId,
    Auth: {
      identityPoolId: awsCognitoIdentityPoolId,
      region: awsProjectRegion,
      oauth: {
        domain: `s12solutions-${env}.auth.eu-west-2.amazoncognito.com`,
        scope: ["email", "profile", "openid", "aws.cognito.signin.user.admin"],
        redirectSignIn: authRedirectSignInUrl,
        redirectSignOut: authRedirectSignOutUrl,
        responseType: "code",
        urlOpener: authUrlOpener,
      },
    },
  });
}
