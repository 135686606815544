/* eslint-disable complexity */
import dayjs from "dayjs";
import { orderBy } from "lodash";
import { ClaimStatus, LocationNameInput, ModelSortDirection, LocationType } from "libs/types/API";
import { formatCamelCase, formatClaimStatus, formatFuelType, getInitials } from "@/utils/helpers";
import { UserDetails } from "@/utils/recoil/props";
import { doctorConfText, doctorEmpStatusText } from "./ClaimProcessing.props";
import { formatAddress } from "libs/formatters/address";
import { calculateInvoiceTotals } from "libs/utils/invoicing";
import { InvoiceCCGClaim } from "libs/types/claim";
import { RouteKeys } from "@/navigationv2";

type CsvExportMode = "doctor" | "ccg";

enum ClaimStatusTexts {
  unassigned = "Unassigned",
  team_claims = "Team Claims",
  my_claims = "My Claims",
  approved = "Approved",
  rejected = "Rejected",
  paid = "Paid",
}

const getApproverInitials = (notes: string[], currentStatus: ClaimStatus) => {
  if (!currentStatus.match(/approved/)) {
    return "N/A";
  }
  const mostRecentApprover = notes
    .reverse()
    .find(n => n.match(/Claim approved by team member/))
    ?.split("@")[1];
  return mostRecentApprover ? getInitials(mostRecentApprover) : "N/A";
};

export const getPostcodeFromLocation = ({
  locationType,
  locationText,
}: {
  locationType: string;
  locationText: string;
}): string => {
  // If location text is not defined, return an empty string
  if (!locationText) return "";
  if (locationType === LocationType.gPPractice) {
    // Location text postcode should be in a string separated by commas.
    return locationText.split(",")[1] || "";
  } else {
    return locationText;
  }
};

export const constructCsvData = (
  orgs: { featureFlags: string }[],
  claims: InvoiceCCGClaim[],
  selectedClaims?: string[],
  mode: CsvExportMode = "ccg"
) => {
  // This function would work better if a list of 'fields' was passed in
  // Which would return a label/value pair, review when doctors need this
  // Would this work better server-side?
  if (claims.length === 0) return [];
  const data = selectedClaims ? claims.filter(c => selectedClaims && selectedClaims.includes(c.id)) : claims;
  const featureFlags = orgs
    .map(o => JSON.parse(o?.featureFlags || ""))
    .reduce(
      (acc: any, curr: any) => ({
        ...acc,
        ...Object.entries(curr)
          .filter(([_, value]) => !!value)
          .reduce((a, v) => ({ ...a, [v[0]]: v[1] }), {}),
      }),
      {}
    );

  const invoiceFields = [
    "Assessment Fee (GBP)",
    "Mileage Fee (GBP)",
    "VAT (GBP)",
    "Subtotal (GBP)",
    "TOTAL Exc VAT (GBP)",
    "TOTAL Inc VAT (GBP)",
  ];

  const getInvoiceTotals = (claim: InvoiceCCGClaim) => {
    const { subtotal, vat, totalExcVat, totalIncVat } = calculateInvoiceTotals(
      claim,
      Boolean(claim.claimData?.vatRegistration)
    );

    return [
      claim.invoiceData?.assessmentFee ?? 0,
      claim.invoiceData?.travelFee ?? 0,
      vat,
      subtotal,
      totalExcVat,
      totalIncVat,
    ];
  };

  const fields = [
    mode === "ccg" ? "Claim ID" : "Invoice Number",
    "Doctor Name",
    "Doctor Contact Details",
    "Authorised By",
    "Authorisation Date",
    "Claim Status",
    "Approver Initials",
    "CCG Relation",
    "CCG Related Location",
    "Visit Date",
    "Visit Time",
    "CCG",
    featureFlags.fullPatientName ? "Patient Name" : "Patient Initials",
    "Additional Information",
    "Paid?",
    "Approved Date",
    "Paid Date",
    featureFlags.ccgDisplayClaimLocation ? "Postcode" : false,
    featureFlags.mileage ? "Mileage" : false,
    featureFlags.mileage ? "From" : false,
    featureFlags.mileage ? "To" : false,
    featureFlags.mileage && featureFlags.provideVehicleInformation ? "Vehicle Information" : false,
    featureFlags.additionalExpenses ? "Additional Expenses" : false,
    featureFlags.ccgRequiresBillingInformation ? "Company Name" : false,
    featureFlags.ccgRequiresBillingInformation ? "Company Address" : false,
    featureFlags.ccgRequiresBillingInformation ? "VAT Registration" : false,
    featureFlags.confirmAssessmentTookPlace ? "Assessment Took Place?" : false,
    featureFlags.ccgRequiresNhsNumber ? "NHS Number" : false,
    featureFlags.mhtAndLineManager ? "Selected MHT" : false,
    featureFlags.ccgRequiresLineManager || featureFlags.mhtAndLineManager ? "Doctor Line Manager" : false,
    featureFlags.ccgRequiresGmcNumber ? "Doctor GMC Number" : false,
    featureFlags.ccgRequiresAmhpTeamName ? "AMHP Team Name" : false,
    featureFlags.ccgRequiresDoctorMhtAssociations ? "Doctor MHT Associations" : false,
    featureFlags.ccgRequiresDoctorAdditionalConfirmation ? "Doctor Confirmation" : false,
    ...(featureFlags.ccgRequiresDoctorToInvoice && featureFlags.mileage
      ? invoiceFields
      : [
          featureFlags.doctorFeesMileageWithoutInvoice ? "Assessment Fee (GBP)" : false,
          featureFlags.doctorFeesMileageWithoutInvoice ? "Mileage Fee (GBP)" : false,
        ]),
    "Employer",
  ].filter(notFalse);

  const mappedData = data.map(c =>
    [
      // Claim ID
      c.displayId,

      // Doctor Name
      c.doctor.name,

      // Doctor Contact Details
      [c.doctor.email, c.doctor.phone].join("\n"),

      // Authorised By
      mode === "ccg" ? c.amhp?.name : c.visit && c.visit.assessment.amhp.name,

      // AUthorisartion Date
      dayjs(c.createdAt).format("DD MMM YYYY"),

      // CLaim Status
      formatClaimStatus(c.status),

      // Approver Initials
      getApproverInitials(c.notes, c.status),

      // CCG Relation
      formatCamelCase(c.locationType),

      // CCG Related Location
      c.locationText,

      // Visit Date
      dayjs(c.visitDate).format("DD MMM YYYY"),

      // Visit Time
      dayjs(c.visitDate).format("HH:mm"),

      // CCG
      c.organisation.name,

      // Patient Initials / Name
      featureFlags.fullPatientName && c.patientFullName ? c.patientFullName : c.patientInitials,

      // Additional Information
      c.additionalInformation,

      // Paid?
      c.status === ClaimStatus.approved_and_paid ? "Yes" : "No",

      // Approved Date
      getApprovedDate(c.notes) || "N/A",

      // Paid Date
      getPaidDate(c.notes) || "N/A",

      // Postcode
      featureFlags.ccgDisplayClaimLocation
        ? getPostcodeFromLocation({ locationType: c.locationType, locationText: c.locationText })
        : false,

      // Mileage
      featureFlags.mileage ? c.mileage : false,

      // From
      featureFlags.mileage ? c.startingPostcode : false,

      // To
      featureFlags.mileage ? c.assessmentPostcode : false,

      // Vehicle Information
      featureFlags.mileage && featureFlags.provideVehicleInformation
        ? c.claimData?.fuelType
          ? formatFuelType(c.claimData.fuelType, c.claimData.engineSize || null)
          : ""
        : false,

      // Additional Expenses
      featureFlags.additionalExpenses ? c.additionalExpenses : false,

      // Company Name
      featureFlags.ccgRequiresBillingInformation ? c.claimData?.billingCompanyName || "" : false,

      // Company Address
      featureFlags.ccgRequiresBillingInformation
        ? c.claimData?.billingAddress
          ? formatAddress(c.claimData.billingAddress)
          : ""
        : false,

      // VAT Registration
      featureFlags.ccgRequiresBillingInformation ? c.claimData?.vatRegistration || "" : false,

      // Assessment Took Place
      featureFlags.confirmAssessmentTookPlace
        ? c.assessmentTookPlace
          ? "Yes"
          : c.assessmentTookPlace === false
          ? "No"
          : " "
        : false,

      // NHS Number
      featureFlags.ccgRequiresNhsNumber ? c.patientNhsNumber : false,

      // Selected MHT
      featureFlags.mhtAndLineManager
        ? c.isIndependentDoctor
          ? "N/A"
          : c.doctor.mhtEmployers?.items.map(item => item.mht.organisation.name).join(",\n")
        : false,

      // Doctor Line Manager
      featureFlags.ccgRequiresLineManager || featureFlags.mhtAndLineManager
        ? c.isIndependentDoctor
          ? "N/A"
          : c.doctor.lineManager
        : false,

      // Doctor GMC Number
      featureFlags.ccgRequiresGmcNumber ? c.doctor.gmc : false,

      // AMHP Team Name
      featureFlags.ccgRequiresAmhpTeamName ? c.amhpTeamId : false,

      // Doctor MHT Associations
      featureFlags.ccgRequiresDoctorMhtAssociations
        ? c.isIndependentDoctor
          ? "N/A"
          : c.defaultMHT || "Not Specified"
        : false,

      // Doctor Confirmation
      featureFlags.ccgRequiresDoctorAdditionalConfirmation
        ? c.doctorAdditionalConf
          ? doctorConfText[c.doctorAdditionalConf]
          : "Not Specified"
        : false,

      // Assessment Fee (GBP) - Mileage Fee (GBP) - VAT (GBP) - Subtotal (GBP) - TOTAL Exc VAT (GBP) - TOTAL Inc VAT (GBP)
      ...(featureFlags.ccgRequiresDoctorToInvoice && featureFlags.mileage
        ? getInvoiceTotals(c)
        : [
            // Assessment Fee (GBP)
            featureFlags.doctorFeesMileageWithoutInvoice ? c.invoiceData?.assessmentFee : false,

            // Mileage Fee (GBP)
            featureFlags.doctorFeesMileageWithoutInvoice ? c.invoiceData?.travelFee : false,
          ]),

      // Employer - //! NOTE: this is being added to the end as of issue 152, though may require additional logic applying to it for the new claims system as it is developed
      c.doctor.employer ? c.doctor.employer : "N/A",
    ].filter(notFalse)
  );

  return [fields, ...mappedData];
};

export const shouldUseOrgLevelQuery = (user: UserDetails | null, orgId?: string) => {
  const csuId = user ? user.csu : undefined;

  return !!orgId || !csuId;
};

function notFalse<TValue>(value: TValue | boolean): value is TValue {
  return typeof value !== "boolean" || value !== false;
}

export const retrieveSortFeatures = (
  sortField: string | null,
  currentSortCriteria: { sortField: string | null; sortDirection: string }
) => {
  // If we are on the same field as previously, switch the sort order
  if (sortField === currentSortCriteria.sortField) {
    return currentSortCriteria.sortDirection === ModelSortDirection.ASC
      ? // We are on the smee field as is currently sorted, so switch sort order
        { sortField, sortDirection: ModelSortDirection.DESC }
      : { sortField, sortDirection: ModelSortDirection.ASC };
  } else {
    // If we have switched to a new field, the sort order
    return { sortField, sortDirection: ModelSortDirection.ASC };
  }
};

export const retrieveStatusText = (status: string, assigneeId: string | undefined, userId: string | undefined) => {
  switch (status) {
    case ClaimStatus.under_review:
      if (assigneeId === userId) {
        return ClaimStatusTexts.my_claims;
      } else if (assigneeId === "!!!") {
        return ClaimStatusTexts.unassigned;
      } else {
        return ClaimStatusTexts.team_claims;
      }
    case ClaimStatus.approved:
      return ClaimStatusTexts.approved;
    case ClaimStatus.rejected:
      return ClaimStatusTexts.rejected;
    case ClaimStatus.approved_and_paid:
      return ClaimStatusTexts.paid;
    default:
      return null;
  }
};

export const adjustFilter = (filter: any, from: string | undefined, to: string | undefined): any => {
  if (from && to) {
    return {
      ...filter,
      and: [...filter.and, { receivedDate: { ge: from } }, { receivedDate: { le: to } }],
    };
  }
  return filter;
};

export const retrieveFilter = (
  claimsScreen: string,
  orgId: string | undefined,
  user?: UserDetails | null,
  searchField?: string
) => {
  let filter;
  let csuOrg: string | undefined;
  let csuFilter = {};
  if (user) {
    csuOrg = user.organisations?.find(org => org === user.csu);
  }
  if (csuOrg) {
    // Handle filtering where the user belongs to a CSU
    csuFilter = { claimCsuId: { eq: csuOrg } };
  }

  switch (claimsScreen) {
    case RouteKeys.ClaimProcessingSearchScreen:
      // If we are here, the user has chosen to enter a value as search criteria. We will have passed
      // in the searchfield and this value will always exist at this point.
      if (orgId) {
        filter = {
          and: [
            { claimOrganisationId: { eq: orgId } },
            { displayId: { contains: searchField } },
            { status: { ne: ClaimStatus.action_required } },
          ],
        };
      } else {
        filter = {
          and: [
            { ...csuFilter },
            { displayId: { contains: searchField } },
            { status: { ne: ClaimStatus.action_required } },
          ],
        };
      }
      return filter;
    case RouteKeys.UnassignedClaimsScreen:
      if (orgId) {
        filter = {
          and: [
            { claimOrganisationId: { eq: orgId } },
            { assigneeId: { eq: "!!!" } },
            { status: { eq: ClaimStatus.under_review } },
          ],
        };
      } else {
        filter = {
          and: [{ ...csuFilter }, { assigneeId: { eq: "!!!" } }, { status: { eq: ClaimStatus.under_review } }],
        };
      }
      return filter;
    case RouteKeys.TeamClaimsScreen:
      if (orgId) {
        filter = {
          and: [
            { claimOrganisationId: { eq: orgId } },
            { assigneeId: { ne: "!!!" } },
            { status: { eq: ClaimStatus.under_review } },
          ],
        };
      } else {
        filter = {
          and: [{ ...csuFilter }, { assigneeId: { ne: "!!!" } }, { status: { eq: ClaimStatus.under_review } }],
        };
      }
      return filter;
    case RouteKeys.MyClaimsScreen:
      if (orgId) {
        filter = {
          and: [
            { claimOrganisationId: { eq: orgId } },
            { assigneeId: { eq: user?.id } },
            { status: { eq: ClaimStatus.under_review } },
          ],
        };
      } else {
        filter = {
          and: [{ ...csuFilter }, { assigneeId: { eq: user?.id } }, { status: { eq: ClaimStatus.under_review } }],
        };
      }
      return filter;
    case RouteKeys.ApprovedClaimsScreen:
      if (orgId) {
        filter = {
          and: [{ claimOrganisationId: { eq: orgId } }, { status: { eq: ClaimStatus.approved } }],
        };
      } else {
        filter = {
          // If all filters have an and clause, even if there is just one item, it is then easier to adjust the filter
          // to accommodate other criteria - dates for instance.
          and: [{ ...csuFilter, status: { eq: ClaimStatus.approved } }],
        };
      }
      return filter;
    case RouteKeys.ApprovedPaidClaimsScreen:
      if (orgId) {
        filter = {
          and: [
            { claimOrganisationId: { eq: orgId } },
            {
              status: { eq: ClaimStatus.approved_and_paid },
            },
          ],
        };
      } else {
        filter = {
          and: [
            {
              status: { eq: ClaimStatus.approved_and_paid },
            },
            { ...csuFilter },
          ],
        };
      }
      return filter;
    case RouteKeys.RejectedClaimsScreen:
      if (orgId) {
        filter = {
          and: [{ claimOrganisationId: { eq: orgId } }, { status: { eq: ClaimStatus.rejected } }],
        };
      } else {
        filter = {
          // If all filters have an and clause, even if there is just one item, it is then easier to adjust the filter
          // to accommodate other criteria - dates for instance.
          and: [{ ...csuFilter, status: { eq: ClaimStatus.rejected } }],
        };
      }
      return filter;
    default:
      return {};
  }
};

export enum NotesTexts {
  approved = "Claim approved by team member",
  reopened = "Claim reopened by team member",
  paid = "Claim marked as paid by team member",
  unpaid = "Claim marked as unpaid by team member",
  // Previously there was a different text based on how the claim was marked as paid. From the list we
  // got the version above. From the details we got the following version. The paidAlt text is included for
  // backward compatibility.
  paidAlt = "Claim paid by team member",
}

const getOrderedArrayFromNotes = (notes: string[]) => {
  if (!notes) return [];
  // Split the notes and return usable object
  const notesArr = notes.map(n => {
    const note = n.split("@");
    return { text: note[0], user: note[1], date: note[2] };
  });
  if (notesArr.length === 0) {
    return [];
  }
  // Order the array latest first
  return orderBy(notesArr, ["date"], ["desc"]);
};

export const getApprovedDate = (notes: string[] | null) => {
  // Notes on the claim record the actions on the claim.
  // For unasigned claims, we do not have notes since the claim
  // has not been assigned, approved, paid etc.
  if (!notes) return null;
  const orderedArr = getOrderedArrayFromNotes(notes);
  for (const oa of orderedArr) {
    if (oa.text === NotesTexts.reopened) {
      // There is an action that negates the approval
      return null;
    }
    if (oa.text === NotesTexts.approved) {
      // Return date of most recent approval
      return dayjs(oa.date).format("DD MMM YYYY");
    }
  }
  // No approval date found
  return null;
};

export const getPaidDate = (notes: string[] | null) => {
  // Notes on the claim record the actions on the claim.
  // For unasigned claims, we do not have notes since the claim
  // has not been assigned, approved, paid etc.
  if (!notes) return null;
  const orderedArr = getOrderedArrayFromNotes(notes);
  for (const oa of orderedArr) {
    if (oa.text === NotesTexts.unpaid) {
      // There is an action that negates the paid status
      return null;
    }
    if (oa.text === NotesTexts.paid || oa.text === NotesTexts.paidAlt) {
      // Return date of most recent transition to paid
      return dayjs(oa.date).format("DD MMM YYYY");
    }
  }
  // No approval date found
  return null;
};
