import React, { useMemo } from "react";
import { StyleSheet, View, Platform } from "react-native";

import { TypographyType } from "@/models/Typography";
import Icon, { IconName } from "../../Icon";
import Text from "../../Text";
import { palette, spacing } from "../../../theme";

interface StatusBarProps {
  status: StatusType;
}

type StatusSettings = {
  icon: IconName;
  text: string;
  textColor: string;
  backgroundColor: string;
};

export enum StatusType {
  SLOW_CONNECTION = "slow-connection",
  NO_CONNECTION = "no-connection",
  NOT_RECORDING = "not-recording",
  PERMISSIONS_BLOCKED = "permissions-blocked",
}

const StatusBar: React.FC<StatusBarProps> = ({ status }) => {
  const settings: StatusSettings = getSettings(status);

  const barBGStyles = useMemo(
    () => [
      styles.barBG,
      {
        backgroundColor: settings.backgroundColor,
        opacity: status === StatusType.NOT_RECORDING ? 1 : 0.9,
      },
    ],
    [status]
  );

  return (
    <View style={styles.bar}>
      <Icon name={settings.icon} color={settings.textColor} size={14} />
      <Text format={TypographyType.MicroBold} style={styles.text} color={settings.textColor}>
        {settings.text}
      </Text>
      <View style={barBGStyles} />
    </View>
  );
};

export default React.memo(StatusBar);

const styles = StyleSheet.create({
  bar: {
    position: "relative",
    width: "100%",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    ...Platform.select({
      web: {
        paddingVertical: spacing[5],
      },
    }),
  },

  barBG: {
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    zIndex: -1,
  },

  text: {
    fontSize: 12,
    lineHeight: 16,
    paddingLeft: spacing[5],
    textTransform: "uppercase",
  },
});

// TO-DO: There's probably a more cool-kid way to do this; feel free to be cool...
function getSettings(status: StatusType): StatusSettings {
  let settings: StatusSettings;

  if (status === StatusType.SLOW_CONNECTION) {
    settings = {
      icon: IconName.warning,
      text: "Slow connection",
      textColor: palette.white,
      backgroundColor: palette.red,
    };
  } else if (status === StatusType.NO_CONNECTION) {
    settings = {
      icon: IconName.signal_wifi_off,
      text: "Connection lost",
      textColor: palette.white,
      backgroundColor: palette.red,
    };
  } else if (status === StatusType.PERMISSIONS_BLOCKED) {
    settings = {
      icon: IconName.lock,
      text: "Permissions blocked for microphone or video input, please re-enable these and reload this window",
      textColor: palette.white,
      backgroundColor: palette.red,
    };
  } else {
    // notification === StatusType.NOT_RECORDING
    settings = {
      icon: IconName.videocam_off,
      text: "This call is not being recorded",
      textColor: palette.navy,
      backgroundColor: palette.aqua,
    };
  }

  return settings;
}
