import { LocationInput, LocationNameInput, ClaimStatus } from "libs/types/API";
import gql from "graphql-tag";
import { AssessmentDateInfo } from "./UserProfile";
import dayjs from "dayjs";
import {
  CCGFragment,
  DoctorVisitsWithClaimIdResponse,
  DoctorCondensedFragment,
  DoctorCondensedResponse,
  AmhpTeamIdAndName,
  LocationCoordsFragment,
  LocationNameFragment,
  OrganisationIdName,
  AmhpTeamUsers,
  AmhpTeamUsersResponse,
  AmhpTeamCommonAddresses,
  AmhpTeamCommonAddressesResponse,
  DoctorVisitsWithClaimId,
  AssessmentFormListFragment,
  AssessmentFormListResponse,
  DoctorVisitsCondensedForAssessment,
} from "./gql/fragments";
import { FormListItem } from "libs/types/mhaForms";

import { formatShortDateTime } from "libs/dates";

export function formDataToModel(forms: AssessmentFormListResponse["forms"]): FormListItem[] {
  return (
    forms.items
      ?.filter(notEmpty)
      .sort((dayA, dayB) => (dayjs(dayB.updatedAt).isAfter(dayjs(dayA.updatedAt)) ? 1 : -1))
      .map(f => {
        return {
          id: f.id,
          status: f.status,
          formType: f.type,
          historyElements: f.notes.map(
            // notes format: Text@USER_FULL_NAME@TIMESTAMP
            // USER_FULL_NAME can sometimes be multiple email addresses, so we don't want and to be in uppercase
            // hence .replace(" AND ", " and ")
            n =>
              `${n.split("@")[0]} ${n
                .split("@")[1]
                .toUpperCase()
                .replace(" AND ", " and ")} on ${formatShortDateTime(n.split("@")[2])}`.replace("(AT)", "@")
          ),
          isDeleted: f.isDeleted,
          signed: f.signed || [],
          createdBy: f.createdBy || "",
          authors: f.authors || [],
          data: f.data,
          createdAt: f.createdAt,
          expired: dayjs(f.updatedAt ? f.updatedAt : f.createdAt)
            .add(30, "day")
            .isBefore(dayjs()),
        };
      }) || []
  );
}

function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

export interface ContactedDoctorResponse {
  id: string;
  createdAt: string;
  s12Doctor: DoctorCondensedResponse;
}

export type AssessmentItem = {
  id: string;
  ccgId: string;
  patientName: string;
  patientNhsNumber?: string;
  patientAddress?: LocationNameInput;
  assessmentDate: string;
  amhpTeamId: string;
  amhpTeam: {
    id: string;
    name: string;
    featureFlags: string;
    commonAddresses: {
      items?: AmhpTeamCommonAddressesResponse[] | null | undefined;
    };
    users: { items?: AmhpTeamUsersResponse[] | null | undefined };
  };
  amhp: {
    id: string;
    name: string;
  };
  location: LocationInput;
  locationName: LocationNameInput;
  doctorVisits: {
    items?: DoctorVisitsWithClaimIdResponse[] | null | undefined;
  } | null;
  contactedDoctors: {
    items?: ContactedDoctorResponse[] | null | undefined;
  };
} & AssessmentFormListResponse;

export interface CreateAssessmentItem {
  id: string;
  ccgId: string;
  patientName: string;
  patientNhsNumber?: string;
  assessmentDate: string;
  amhp: {
    id: string;
    name: string;
  };
  location: LocationInput;
  locationName: LocationNameInput;
}

export interface SortedAssessments<T extends AssessmentDateInfo> {
  active: T[];
  archived: T[];
  count: {
    total: number;
    active: number;
    archived: number;
  };
}

export interface GetAssessmentResponse {
  assessment: AssessmentItem;
}

export const GET_ASSESSMENT = gql`
  query GetAssessment($id: ID!) {
    assessment: amhpAssessment(id: $id) {
      id
      ccgId
      patientName
      patientNhsNumber
      patientAddress {
        ...fullLocation
      }
      assessmentDate
      amhpTeamId
      amhpTeam {
        ...AmhpTeamIdAndNameParts
        ...AmhpTeamCommonAddresses
        ...AmhpTeamUsers
        featureFlags
      }
      amhp {
        id
        name
      }
      location {
        ...latLon
      }
      locationName {
        ...fullLocation
      }
      doctorVisits {
        items {
          ...DoctorVisitsWithClaimId
          claim {
            locationText
            locationType
            claimPostcode
            organisation {
              id
              name
              featureFlags
            }
          }
        }
      }
      contactedDoctors(limit: 50) {
        items {
          id
          createdAt
          s12Doctor {
            ...DoctorCondensed
          }
        }
      }
      ...AssessmentFormList
    }
  }
  ${AmhpTeamIdAndName}
  ${LocationCoordsFragment}
  ${LocationNameFragment}
  ${DoctorCondensedFragment}
  ${DoctorVisitsWithClaimId}
  ${AmhpTeamUsers}
  ${AmhpTeamCommonAddresses}
  ${AssessmentFormListFragment}
`;

export interface GetAmhpCreateAssessmentResponse {
  assessment: CreateAssessmentItem;
}

// Create Assessment
export const AMHP_GET_CREATE_ASSESSMENT = gql`
  query AmhpGetCreateAssessment($id: ID!) {
    assessment: amhpAssessment(id: $id) {
      id
      ccgId
      patientName
      assessmentDate
      patientNhsNumber
      amhp {
        id
        name
      }
      location {
        ...latLon
      }
      locationName {
        ...fullLocation
      }
    }
  }
  ${LocationCoordsFragment}
  ${LocationNameFragment}
`;
export interface GetTeamInformationResponse {
  profile: {
    id: string;
    name: string;
    teams: {
      items: {
        amhpTeam: {
          id: string;
          name: string;
          featureFlags: string;
          users: {
            items: {
              amhp: {
                id: string;
                name: string;
                deleted: boolean;
              };
            }[];
          };
          commonAddresses?: {
            items: {
              location: {
                lat: number;
                lon: number;
              };
              locationName: {
                city?: string;
                postcode: string;
                address?: string;
              };
              ccg?: {
                id: string;
                name: string;
                featureFlags: string;
              };
            }[];
          };
        };
      }[];
    };
  };
}
export const GET_TEAM_INFORMATION = gql`
  query myProfile {
    profile: myProfile {
      id
      name
      teams {
        items {
          id
          amhpTeam {
            id
            name
            featureFlags
            users(limit: 500) {
              items {
                id
                amhp {
                  id
                  name
                  deleted
                }
              }
            }
            commonAddresses(limit: 50) {
              items {
                id
                location {
                  ...latLon
                }
                locationName {
                  ...fullLocation
                }
                ccg {
                  ...CCGCondensed
                }
              }
            }
          }
        }
      }
    }
  }
  ${CCGFragment}
  ${LocationCoordsFragment}
  ${LocationNameFragment}
`;

export interface MutateAssessmentResponse {
  assessment?: {
    id: string;
    ccgId: string;
    assessmentDate: string;
    amhp: {
      id: string;
      name: string;
    };
    amhpTeam: {
      id: string;
      name: string;
    };
    patientName: string;
    location: LocationInput;
    locationName: LocationNameInput;
  };
}

export const UPDATE_ASSESSMENT = gql`
  mutation UpdateAssessment($input: UpdateAssessmentInput!) {
    assessment: updateAssessment(input: $input) {
      id
      ccgId
      assessmentDate
      amhp {
        id
        name
      }
      amhpTeam {
        ...AmhpTeamIdAndNameParts
      }
      patientName
      patientNhsNumber
      location {
        ...latLon
      }
      locationName {
        ...fullLocation
      }
      patientAddress {
        ...fullLocation
      }
    }
  }
  ${AmhpTeamIdAndName}
  ${LocationCoordsFragment}
  ${LocationNameFragment}
`;

export const DELETE_ASSESSMENT = gql`
  mutation DeleteAssessment($input: DeleteAssessmentInput!) {
    deleteAssessment(input: $input) {
      id
    }
  }
`;

export const CREATE_ASSESSMENT = gql`
  mutation CreateAssessment($input: CreateAssessmentInput!) {
    assessment: createAssessment(input: $input) {
      id
      ccgId
      assessmentDate
      amhp {
        id
        name
      }
      amhpTeam {
        ...AmhpTeamIdAndNameParts
      }
      patientName
      patientNhsNumber
      location {
        ...latLon
      }
      locationName {
        ...fullLocation
      }
    }
  }
  ${AmhpTeamIdAndName}
  ${LocationCoordsFragment}
  ${LocationNameFragment}
`;

export const CREATE_CONTACTED_DOCTORS = gql`
  mutation CreateContactedDoctor($input: CreateContactedDoctorInput!) {
    createContactedDoctor(input: $input) {
      id
    }
  }
`;

export const DELETE_CONTACTED_DOCTORS = gql`
  mutation DeleteContactedDoctor($id: ID!) {
    deleteContactedDoctor(input: { id: $id }) {
      id
    }
  }
`;

export const GET_CCGS = gql`
  query ListOrganisations($type: OrgType!, $limit: Int) {
    listOrganisations(type: $type, limit: $limit) {
      items {
        ...OrganisationIdAndName
      }
    }
  }
  ${OrganisationIdName}
`;

export const GET_CCGS_WITH_CLAIMS_ENABLED = gql`
  query ListCCGsWithClaims {
    listOrganisations(type: CCG, limit: 250, filter: { featureFlags: { contains: "\\"claims\\":true" } }) {
      items {
        ...OrganisationIdAndName
        featureFlags
      }
    }
  }
  ${OrganisationIdName}
`;

export interface DoctorVisitsResponse {
  doctor: {
    id: string;
    name: string;
    phone: string | null;
  };
  time: string;
  notes?: string[]; // formatted as plain strings
  claim: {
    id: string;
    status: ClaimStatus;
    doctor: {
      id: string;
    };
  };
}

export enum AssessmentCategory {
  MyAssessments = "my",
  TeamAssessments = "team",
  MyArchive = "myArchived",
  TeamArchive = "teamArchived",
}

export interface AssessmentResultItem {
  id: string;
  amhpTeam: {
    id: string;
    name: string;
  };
  amhp: {
    id: string;
    name: string;
  };
  patientName: string;
  location: LocationInput;
  locationName: LocationNameInput;
  assessmentDate?: string;
  doctorVisits: {
    items: Array<DoctorVisitsResponse | null>;
  } | null;
}

export interface GetTeamAssessmentsQueryResponseTeams {
  items: {
    amhpTeam: {
      id: string;
      name: string;
      assessments: AssessmentListResponse | null;
    };
  }[];
}

export interface AssessmentListResponse {
  items: Array<AssessmentResultItem | null> | null;
  nextToken: string | null;
}

export interface GetTeamAssessmentsQueryResponse {
  myAssessments: AssessmentListResponse;
  teamAssessments: AssessmentListResponse;
  myArchivedAssessments: AssessmentListResponse;
  teamArchivedAssessments: AssessmentListResponse;
  [key: string]: AssessmentListResponse;
}

// Query to get a list of assessments associated with a specific
// AMHP or AMHP Team, including archived.
export const getAmhpAssessmentsQuery = gql`
  query(
    $today: String!
    $myNextToken: String
    $teamNextToken: String
    $myArchivedNextToken: String
    $teamArchivedNextToken: String
    $amhpId: ID
    $amhpTeamId: ID
    $limit: Int
  ) {
    myAssessments: assessmentsByAmhp(
      amhpId: $amhpId
      assessmentDate: { ge: $today }
      sortDirection: DESC
      limit: $limit
      nextToken: $myNextToken
    ) {
      items {
        ...assessmentsItems
      }
      nextToken
    }
    myArchivedAssessments: assessmentsByAmhp(
      amhpId: $amhpId
      assessmentDate: { lt: $today }
      sortDirection: DESC
      limit: $limit
      nextToken: $myArchivedNextToken
    ) {
      items {
        ...assessmentsItems
      }
      nextToken
    }
    teamAssessments: assessmentsByAmhpTeam(
      amhpTeamId: $amhpTeamId
      assessmentDate: { ge: $today }
      sortDirection: ASC
      limit: $limit
      nextToken: $teamNextToken
    ) {
      items {
        ...assessmentsItems
      }
      nextToken
    }
    teamArchivedAssessments: assessmentsByAmhpTeam(
      amhpTeamId: $amhpTeamId
      assessmentDate: { lt: $today }
      sortDirection: DESC
      limit: $limit
      nextToken: $teamArchivedNextToken
    ) {
      items {
        ...assessmentsItems
      }
      nextToken
    }
  }
  fragment assessmentsItems on Assessment {
    id
    assessmentDate
    patientName
    amhp {
      id
      name
    }
    amhpTeam {
      id
      name
    }
    doctorVisits {
      items {
        ...DoctorVisitsCondensed
      }
    }
  }
  ${DoctorVisitsCondensedForAssessment}
`;
