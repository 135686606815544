import { FormType, FormSampleText } from "libs/types/mhaForms";

/*
  array item === paragraph; use \n to separate lines within a paragraph
  text copied from: https://github.com/s12solutions/s12/issues/2641
*/
const Section2SampleText =
  "[PATIENT] has a mental disorder (named if possible) characterised by the following symptoms...\n\n The nature of their disorder is demonstrated by...\nAND/OR The degree of their disorder is demonstrated by...\n\nCommunity treatment is not possible because...\nand this makes 24-hour specialist hospital admission necessary.\n\nHospital admission is necessary in the interests of [PATIENT]'s health because...\nAND/OR in the interests of [PATIENT]’s safety, because...\nAND/OR, there is a view to protect others in that...\n\nInformal admission is not possible because [PATIENT] is not agreeing to admission, AND/OR the established nature of their mental disorder strongly indicates that they will not sustain a commitment to informal admission AND/OR [PATIENT] lacks capacity but their needs cannot be appropriately met by relying on the Mental Capacity Act alone, and their objection to the proposed plan means that they are ineligible for an authorisation under The Deprivation of Liberty Safeguards.";
const Section3SampleText = Section2SampleText.concat(
  "\n\nThe patient is already subject to section 2, or the nature and current degree of the patient’s mental disorder, the essential elements of the treatment plan to be followed and the likelihood of the patient accepting treatment as an informal patient are already sufficiently established to make it unnecessary to undertake a new assessment under section 2."
);
const Section4SampleText =
  "It is of urgent necessity for the patient to be admitted and detained without waiting for a second doctor, because there is…\n\nan immediate and significant risk of mental or physical harm to the patient or others, in that…., AND/OR,\n\n a danger of serious harm to property, demonstrated by….. AND/OR \n\na need for the use of restrictive interventions due to…";

export const PropBasedSampleTexts: Record<FormSampleText, string> = {
  [FormSampleText.SECTION_4_OPINION]: Section2SampleText,
  [FormSampleText.SECTION_4_REASONS]: Section4SampleText,
};

export const SampleTexts: Record<FormType, string | null> = {
  [FormType.A2]: null,
  [FormType.A3]: Section2SampleText,
  [FormType.A4]: Section2SampleText,
  [FormType.A6]: null,
  [FormType.A7]: Section3SampleText,
  [FormType.A8]: Section3SampleText,
  [FormType.A10]: null,
  [FormType.A11]: null,
  [FormType.AUTHORITY_TO_CONVEY]: null,
  [FormType.STATEMENT_OF_REASON]: null,
};
