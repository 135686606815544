/* Libraries */
import React from "react";
import { StyleSheet, View } from "react-native";
import { ParamListBase, useNavigation } from "@react-navigation/native";

import { formatLongDate, formatShortDate } from "libs/dates";
import { formatAddress } from "libs/formatters/address";
import { DoctorClaimListItem } from "libs/types/claim";
import { ClaimStatus } from "libs/types/API";

import { TypographyType } from "@/models/Typography";
import { color, spacing, palette } from "@/theme";
import { getColorByClaimStatus, useDeviceWidth } from "@/utils/helpers";

import { Card } from "@/components/Cardd";
import { SmallDescription } from "@/components/Description";
import { IconName } from "@/components/Icon";
import Text from "@/components/Text";
import TextWithIcon from "@/components/Text/TextWithIcon";
import { StackNavigationProp } from "@react-navigation/stack";
import { AllDoctorRoutes } from "@/navigationv2/types";

const Spacer = () => <View style={styles.spacer} />;

const createExpandableContent = (claim: DoctorClaimListItem) => (
  <>
    <Text format={TypographyType.Micro} color={color.textExtraLight} style={styles.doctorVisitsLabel}>
      Visit Information
    </Text>
    <TextWithIcon iconName={IconName.today} format={TypographyType.Small} style={{ color: color.textLight }}>
      {formatLongDate(claim.visit.time)}
    </TextWithIcon>
    <TextWithIcon iconName={IconName.location_on} style={{ color: color.textLight }} format={TypographyType.Small}>
      {formatAddress(claim.visit.assessment.locationName)}
    </TextWithIcon>
    <Text format={TypographyType.Micro} color={color.textExtraLight} style={styles.doctorVisitsLabel}>
      CCG
    </Text>
    <Text format={TypographyType.Small} style={styles.doctorVisitsLabel}>
      {claim.organisation.name}
    </Text>
  </>
);

export const ClaimResult = (props: { claim: DoctorClaimListItem }) => {
  const { claim } = props;
  const navigation = useNavigation<StackNavigationProp<ParamListBase>>();

  const deviceWidth = useDeviceWidth();
  const leftSideColor = getColorByClaimStatus(claim.status);

  return (
    <Card
      slim={true}
      statusBarColor={leftSideColor}
      expandableContent={createExpandableContent.bind(null, claim)}
      onPress={() =>
        navigation.navigate("ClaimDetailsScreen", {
          claimId: claim.id,
        })
      }
    >
      <View style={[styles.cardHeading, deviceWidth <= 999 && styles.cardHeadingSmall]}>
        <Text style={styles.patientName} format={TypographyType.RegularBold}>
          {claim.visit.assessment.patientName}
        </Text>
        <View style={styles.cardHeadingRow}>
          <SmallDescription label="Claim ID">{claim.displayId}</SmallDescription>
          <Spacer />
          <SmallDescription label="Last Updated">{formatShortDate(claim.updatedAt)}</SmallDescription>
        </View>
      </View>
    </Card>
  );
};

const styles = StyleSheet.create({
  ccgLabel: {
    marginLeft: spacing[5],
    color: palette.greyBlue,
  },

  dateLabel: {
    color: palette.slate,
  },

  visitCCGInfo: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-end",
  },
  patientName: {
    flexBasis: "60%",
  },

  cardHeading: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    flexShrink: 1,
    paddingRight: 24,
  },

  cardHeadingRow: {
    marginTop: spacing[10],
    marginRight: spacing[10],
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },

  cardHeadingSmall: {
    flexDirection: "column",
    paddingRight: 14,
    alignItems: "flex-start",
  },

  doctorVisitsLabel: {
    marginBottom: spacing[10],
  },
  spacer: {
    height: "100%",
    width: 50,
    flexShrink: 1,
    minWidth: 10,
  },
});
