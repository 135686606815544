import React, { forwardRef } from "react";
import { View } from "react-native";
import { Formik, FormikHelpers } from "formik";

import * as Yup from "libs/validators/yup";
import createPostcodeSchema from "libs/validators/yup/createPostcodeSchema";

import ManualAddressFormFields from "./ManualAddressFormFields";

type FormikSubmitHandler<V> = (value: ManualAddressFormValues, actions: FormikHelpers<V>) => void;
type ForwardedRef = (ref: any) => void;

interface PropTypes {
  onSubmit: FormikSubmitHandler<ManualAddressFormValues>;
  autofocusFirst?: boolean;
  initialValues?: Partial<ManualAddressFormValues>;
  disableStrictPostcodeValidation?: boolean;
}

export interface ManualAddressFormValues {
  address: string;
  city: string;
  postcode: string;
}

const defaultValidationSchema = Yup.object().shape({
  address: Yup.string(),
  city: Yup.string(),
  postcode: createPostcodeSchema({ required: true }),
});

const disabledStrictPostcodeValidationSchema = Yup.object().shape({
  address: Yup.string(),
  city: Yup.string(),
  postcode: Yup.string().required("Please enter a postcode"),
});

const baseInitialValues: ManualAddressFormValues = {
  address: "",
  city: "",
  postcode: "",
};

const ManualAddressForm = (props: PropTypes, forwardedRef: ForwardedRef) => {
  const { autofocusFirst = false, initialValues, disableStrictPostcodeValidation, onSubmit, ...rest } = props;
  const validationSchema = disableStrictPostcodeValidation
    ? disabledStrictPostcodeValidationSchema
    : defaultValidationSchema;

  return (
    <View {...rest}>
      <Formik
        initialValues={{ ...baseInitialValues, ...initialValues }}
        innerRef={forwardedRef}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        <ManualAddressFormFields
          autofocusFirst={autofocusFirst}
          disableStrictPostcodeValidation={disableStrictPostcodeValidation}
        />
      </Formik>
    </View>
  );
};

export default forwardRef(ManualAddressForm);
