import React, { useMemo } from "react";
import { View } from "react-native";

import { TypographyType } from "@/models/Typography";
import { useDeviceWidth } from "../../../utils/helpers";
import Text from "../../Text";
import css from "./CardLabelRow.css";

interface CardLabelRowProps {
  labelBGColor: string;
  labelText: string;
  labelTextColor: string;
}

const CardLabelRow: React.FC<CardLabelRowProps> = ({ children, labelBGColor, labelText, labelTextColor }) => {
  const deviceWidth = useDeviceWidth();

  const styles = {
    content: deviceWidth > 320 ? css.content : css.contentSmall,
    label: useMemo(
      () => [
        css.label,
        {
          marginBottom: deviceWidth > 320 ? 0 : 15,
          backgroundColor: labelBGColor,
        },
      ],
      [deviceWidth, labelBGColor, labelTextColor]
    ),
    row: useMemo(() => [deviceWidth > 320 ? css.row : css.rowSmall], [deviceWidth]),
  };

  return (
    <View style={styles.row}>
      <View style={styles.label}>
        <Text format={TypographyType.MicroBold} color={labelTextColor}>
          {labelText}
        </Text>
      </View>
      <View style={styles.content}>{children}</View>
    </View>
  );
};

export default CardLabelRow;
