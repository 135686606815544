import * as Yup from "yup";
import dayjs from "dayjs";
import createPostcodeSchema from "libs/validators/yup/createPostcodeSchema";
import { ComponentElement, FormFieldsDoctor } from "libs/types/mhaForms";
import createDateSchema from "libs/validators/yup/createDateSchema";

const DoctorDetails: ComponentElement[] = [
  {
    component: "Text",
    componentProps: {
      instruction: "I",
      guidance:
        "(PRINT full name, address and, if sending by means of electronic communication, email address of medical practitioner)",
    },
    fullWidth: true,
  },
  {
    component: "TextInput",
    fieldName: FormFieldsDoctor.PractitionerName,
    componentProps: {
      label: "Name",
    },
  },
  {
    component: "Location",
    fieldName: FormFieldsDoctor.PractitionerAddress,
    componentProps: {
      label: "Address ",
      addressChangeWarning:
        "Address changes made here will automatically be applied to all future MHA forms containing your address.",
    },
  },
  {
    component: "TextInput",
    fieldName: FormFieldsDoctor.PractitionerEmailAddress,
    componentProps: {
      label: "Email Address",
      underlabel: "Changes made here apply to all subsequent forms.",
    },
  },
];

const PatientDetails: ComponentElement[] = [
  {
    component: "Text",
    componentProps: {
      instruction: "a registered medical practitioner, have undertaken a Mental Health Act assessment of",
      guidance: "(PRINT full name and address of patient)",
    },
    fullWidth: true,
  },
  {
    component: "TextInput",
    fieldName: FormFieldsDoctor.PatientName,
    componentProps: {
      label: "Name",
    },
    genericProps: {
      readonlyIfInitialValue: true,
    },
  },
  {
    component: "Location",
    fieldName: FormFieldsDoctor.PatientAddress,
    componentProps: {
      label: "Address",
      disableStrictPostcodeValidation: true,
      addressChangeWarning:
        "Patient address changes made here will automatically be applied to all unsigned forms for this assessment.",
    },
  },
];

const LastExamined: ComponentElement[] = [
  {
    component: "Text",
    componentProps: {
      instruction: "I last examined this patient on",
    },
    fullWidth: true,
  },
  {
    component: "Date",
    fieldName: FormFieldsDoctor.ExaminationDate,
    componentProps: {
      label: "Date",
    },
  },
  {
    component: "Checkbox",
    fieldName: FormFieldsDoctor.Background,
    componentProps: {
      options: [
        {
          status: "previousAcquaintance",
          label: "I had previous acquaintance with the patient before I conducted that examination.",
        },
        {
          status: "approved",
          label:
            "I am approved under section 12 of the Act as having special experience in the diagnosis or treatment of mental disorder.",
        },
      ],
    },
  },
  {
    component: "Text",
    componentProps: {
      guidance: "(Check as applicable)",
      pdfOnlyGuidance: "(* Delete if not applicable)",
    },
    fullWidth: true,
  },
];

const Opinion: ComponentElement[] = [
  {
    component: "Text",
    componentProps: {
      instruction: `This patient is not suffering from mental disorder of a nature or degree which warrants the use of compulsory powers. The outcome was that they should be:\n\n`,
    },
    fullWidth: true,
  },
  {
    component: "Checkbox",
    fieldName: FormFieldsDoctor.InformalDecision,
    componentProps: {
      options: [
        {
          status: "admittedInformally",
          label: "admitted informally to hospital",
        },
        {
          status: "supported",
          label: "supported by specialist community mental health services",
        },
        {
          status: "discharged",
          label: "discharged back to the care of their GP practice",
        },
      ],
    },
  },
  {
    component: "Text",
    componentProps: {
      guidance: "(Check as applicable)",
      pdfOnlyGuidance: "(* Delete if not applicable)",
    },
    fullWidth: true,
  },
];

const Reasons: ComponentElement[] = [
  {
    component: "Text",
    componentProps: {
      instruction: `My reasons for this opinion is that:\n\n`,
      guidance:
        "(Your reasons should describe the patient's symptoms and behaviour, and explain how those symptoms and behaviour lead you to your opinion; explain why informal admission is appropriate, or why the patient does not need to be admitted to hospital and can be supported instead in the community. You should describe any risks identified during the assessment to self and others and explain how these risks will be managed without detention. Include details of follow up arrangements and what is being done to mitigate identified risks.)",
    },
    fullWidth: true,
  },
  {
    component: "BottomTextInput",
    fieldName: FormFieldsDoctor.DetailedReasons,
    fullWidth: true,
    componentProps: {
      label: "Reasons",
      helperText:
        "(Your reasons should describe the patient's symptoms and behaviour, and explain how those symptoms and behaviour lead you to your opinion; explain why informal admission is appropriate, or why the patient does not need to be admitted to hospital and can be supported instead in the community. You should describe any risks identified during the assessment to self and others and explain how these risks will be managed without detention. Include details of follow up arrangements and what is being done to mitigate identified risks.)",
      buttonLabel: "Reasons",
      defaultText: "No details currently given",
    },
  },
];

const StatementOfReasonDoctorDetails = {
  title: "Doctor Details",
  state: {
    practitionerName: "",
    practitionerEmailAddress: "",
    practitionerAddress: {
      city: "",
      postcode: "",
      address: "",
    },
  },

  validation: Yup.object().shape({
    practitionerName: Yup.string().trim().required("Please enter your name"),
    practitionerAddress: Yup.object().shape({
      address: Yup.string(),
      city: Yup.string(),
      postcode: createPostcodeSchema({
        invalidPostcodeMessage: "Please enter a valid address",
        required: true,
        requiredMessage: "Please enter an address",
      }),
    }),
  }),

  componentElements: DoctorDetails,
};

const StatementOfReasonPatientDetails = {
  title: "Patient Details",
  state: {
    patientName: "",
    patientAddress: {
      city: "",
      postcode: "",
      address: "",
    },
  },

  validation: Yup.object().shape({
    patientName: Yup.string().trim().required("Please enter patient name"),
    patientAddress: Yup.object().shape({
      address: Yup.string(),
      city: Yup.string(),
      postcode: createPostcodeSchema({
        invalidPostcodeMessage: "Please enter a valid address",
        required: true,
        orNFA: true,
        requiredMessage: "Please enter an address",
      }),
    }),
  }),

  componentElements: PatientDetails,
};

const StatementOfReasonLastExamined = {
  title: "Last Examined",
  state: {
    examinationDate: "",
    background: "",
  },

  validation: Yup.object().shape({
    examinationDate: Yup.lazy(() =>
      createDateSchema({
        min: dayjs().subtract(15, "day").startOf("day"),
        max: dayjs().endOf("day"),
        required: true,
      })
    ),
    background: Yup.array(),
  }),

  componentElements: LastExamined,
};

const StatementOfReasonOpinion = {
  title: "Outcome",
  state: {
    informalDecision: [],
  },

  validation: Yup.object().shape({
    informalDecision: Yup.array(),
  }),

  componentElements: Opinion,
};

const StatementOfReasonReasons = {
  title: "Reasons for Outcome",
  state: {
    detailedReasons: "",
  },

  validation: Yup.object().shape({
    detailedReasons: Yup.string(),
  }),

  componentElements: Reasons,
};

export {
  StatementOfReasonDoctorDetails,
  StatementOfReasonPatientDetails,
  StatementOfReasonLastExamined,
  StatementOfReasonOpinion,
  StatementOfReasonReasons,
};
