import * as React from "react";
import SliderInput from "./SliderInput";
import { View, StyleSheet } from "react-native";
import { TypographyType } from "@/models/Typography";
import { color } from "@/theme";
import Text from "../Text";

export default function Slider(props: {
  value: number;
  valueDisplay?: (num: number) => string;
  onValueChange: (value: number) => void;
  step: number;
  min: number;
  max: number;
}) {
  return (
    <View style={styles.sliderContainer}>
      <View style={styles.slider}>
        <SliderInput
          value={props.value}
          onValueChange={props.onValueChange}
          step={props.step}
          min={props.min}
          max={props.max}
        />
      </View>
      <View style={styles.dualInputFieldUnderText}>
        <Text format={TypographyType.Micro} style={styles.underText}>
          {props.valueDisplay ? props.valueDisplay(props.min) : props.min.toString()}
        </Text>
        <Text format={TypographyType.Micro} style={styles.underText}>
          {props.valueDisplay ? props.valueDisplay(props.max) : props.max.toString()}
        </Text>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  sliderContainer: {
    flexBasis: "75%",
    flexShrink: 1,
  },
  slider: {
    marginBottom: -11, // needed to align sliders with textInputs
  },
  dualInputFieldUnderText: {
    flexDirection: "row",
    justifyContent: "space-between",
    margin: 0,
  },
  underText: {
    color: color.textExtraLight,
  },
});
