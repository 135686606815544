import React, { useCallback, useMemo } from "react";
import { StyleProp, StyleSheet, TouchableOpacity, View, ViewStyle, Platform, Text as RNText } from "react-native";
import { Selectize } from "react-native-material-selectize";

import { TypographyType } from "@/models/Typography";
import { color, palette, spacing } from "../../theme";
import Icon from "../Icon";
import Text from "../Text";

const MemoizedSelectize = React.memo(Selectize);

const MultiSelect = (props: {
  options: string[];
  onValueChange: (v: string[]) => void;
  value: string[];
  label: string;
  style?: StyleProp<ViewStyle>;
  icon?: string;
}) => {
  const ref = React.useRef<typeof Selectize>();
  const [focused, setFocused] = React.useState(false);
  const { options } = props;

  const textInputProps = React.useMemo(
    () => ({
      autoCapitalize: "words" as const,
      blurOnSubmit: true,
      onFocus: () => setFocused(true),
      onBlur: (text: any) => {
        setFocused(false);
        ref &&
          ref.current &&
          (props.options.indexOf(text as string) === -1 || ref.current._onSubmitEditing()) &&
          ref.current.setState({ text: "" });
      },
      onSubmitEditing: (text: any) => props.options.indexOf(text as string) > -1,
      style: {
        overflow: "hidden" as const,
      },
    }),
    [props.options]
  );

  const onChangeSelectedItems = useCallback(
    (i: { result: string[] }) => {
      props.onValueChange(i.result.map(r => options[options.indexOf(r)]).filter(x => x));
    },
    [props.onValueChange, options]
  );

  const renderRow = useCallback(
    (id: string, onPress: () => void, _item: any, style: StyleProp<ViewStyle>) => (
      <TouchableOpacity activeOpacity={0.6} key={id} onPress={onPress} style={[styles.listRow, style]}>
        <Text format={TypographyType.Regular} style={styles.listRowText}>
          {id}
        </Text>
      </TouchableOpacity>
    ),
    []
  );

  const renderChip = useCallback(
    (id: string, onClose: () => void, _item: any, style: StyleProp<ViewStyle>, iconStyle: StyleProp<ViewStyle>) => (
      <Chip key={id} iconStyle={iconStyle} onClose={onClose} text={id} style={style} />
    ),
    []
  );

  const containerStyleArr = useMemo(() => [styles.wrapper, props.style], [props.style]);

  return (
    <View style={containerStyleArr}>
      {props.icon && (
        <Icon name={props.icon} size={24} color={focused ? color.primary : color.textExtraLight} style={styles.icon} />
      )}
      {/* Selectize is not efficient and copies onchanged props to state on each render. Avoid Re-rendering */}
      <MemoizedSelectize
        ref={ref}
        items={props.options}
        label={props.label}
        baseColor={color.placeholder}
        tintColor={color.primary}
        errorColor={color.textError}
        containerStyle={styles.container}
        inputContainerStyle={styles.inputContainer}
        listStyle={styles.list}
        showItems="onFocus"
        textInputProps={textInputProps}
        selectedItems={props.value}
        onChangeSelectedItems={onChangeSelectedItems}
        renderRow={renderRow}
        renderChip={renderChip}
      />
      {!focused && <Icon name={"arrow-drop-down"} size={24} color={color.primary} style={styles.iconPositionRight} />}
    </View>
  );
};

MultiSelect.displayName = "MultiSelect";

export default MultiSelect;

const Chip = React.memo(
  (props: { onClose: () => void; text: string; style: StyleProp<ViewStyle>; iconStyle: StyleProp<ViewStyle> }) => {
    const isIOS = Platform.OS === "ios";
    const isWeb = Platform.OS === "web";
    const { iconStyle, onClose, style, text } = props;

    return (
      <View style={[chipStyles.root, style]}>
        <View style={chipStyles.container}>
          <RNText style={chipStyles.text} numberOfLines={1}>
            {text}
          </RNText>
          <TouchableOpacity style={[chipStyles.iconWrapper, iconStyle]} onPress={onClose}>
            <RNText
              style={[
                chipStyles.icon,
                isWeb ? chipStyles.iconWeb : isIOS ? chipStyles.iconIOS : chipStyles.iconAndroid,
              ]}
            >
              ✕
            </RNText>
          </TouchableOpacity>
        </View>
      </View>
    );
  }
);

Chip.displayName = "Chip";

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    flexDirection: "row",
    alignItems: "flex-start",
    ...Platform.select({
      web: {
        minHeight: "unset",
      },
    }),
  },
  list: {
    position: "relative",
    zIndex: 1000,
    width: "100%",
    backgroundColor: color.background,
    maxHeight: 300,
  },
  listRow: {
    paddingVertical: 10,
    paddingHorizontal: 10,
  },
  listRowText: {
    color: color.textLight,
  },
  container: { flex: 1 },
  inputContainer: {
    paddingLeft: 8,
    overflow: "hidden",
    borderBottomColor: color.thinBorder,
    borderBottomWidth: 1,
  },
  icon: {
    marginRight: spacing[15],
    marginTop: spacing[30],
  },
  iconPositionRight: {
    position: "absolute",
    right: 0,
    top: spacing[40],
  },
});

const chipStyles = StyleSheet.create({
  root: {
    justifyContent: "center",
    borderRadius: 50,
    backgroundColor: color.backgroundGreyDark,
    paddingHorizontal: 10,
    paddingRight: 2,
    paddingVertical: 4,
    height: 28,
    marginBottom: 4,
    marginRight: 4,
  },
  container: {
    flexDirection: "row",
    overflow: "hidden",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  text: {
    color: color.text,
  },
  iconWrapper: {
    borderRadius: 50,
    backgroundColor: palette.cloud,
    height: 26,
    width: 26,
    overflow: "hidden",
    marginLeft: 5,
    marginTop: 0,
    marginBottom: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    paddingTop: 2,
    textAlign: "center",
    color: color.text,
  },
  iconIOS: {
    fontSize: 13,
    lineHeight: 14,
  },
  iconAndroid: {
    fontSize: 13,
    lineHeight: 13,
    paddingTop: 3,
    paddingLeft: 1,
  },
  iconWeb: {
    paddingTop: 0,
    fontSize: 12,
    lineHeight: 12,
  },
});
