import { StyleSheet } from "react-native";
import { palette, spacing } from "../../../theme";

const css = StyleSheet.create({
  createAssessmentButton: {
    width: "100%",
    marginTop: spacing[30],
    marginBottom: 0,
  },

  exportAssessmentButton: {
    width: "100%",
    marginTop: spacing[15],
    marginBottom: 0,
  },

  heading: {
    marginBottom: 0,
  },

  helperText: {
    marginTop: spacing[30],
  },

  mainContentWrapper: {
    backgroundColor: palette.cloud,
  },

  subHeading: {
    flexDirection: "row",
    alignItems: "flex-end",
    marginTop: spacing[5],
  },

  subHeadingLabel: {
    marginRight: spacing[5],
  },
});

export default css;
