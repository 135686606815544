/**
 * Handle whether the App Update Modal should be displayed.
 */
import React, { useEffect, useState } from "react";
import { Linking, Platform } from "react-native";
import { useRecoilState, useRecoilValue } from "recoil";

import { useDialog, useOnMount } from "@/hooks";
import { appUpdateDimissed as recoilAppUpdateDimissed, loading as recoilLoading } from "@/utils/recoil/index";
import { getAppVersion } from "@/utils";

import { version } from "../../../package.json";

const AppUpdateModalContainer = () => {
  const loading = useRecoilValue(recoilLoading);
  const { openDialog, closeDialog } = useDialog();

  const [isFetching, setFetching] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [appUpdateDimissed, setAppUpdateDimissed] = useRecoilState(recoilAppUpdateDimissed);

  const onDismiss = () => {
    setShowModal(false);
    setAppUpdateDimissed(true);
  };

  const appUrl = Platform.select({
    ios: "https://apps.apple.com/us/app/s12-solutions/id1475529365?ls=1",
    android: "https://play.google.com/store/apps/details?id=com.s12solutions.s12",
    default: "https://my.s12solutions.com",
  });

  const onAppStorePress = () => {
    Linking.canOpenURL(appUrl).then(canOpen => {
      if (canOpen) {
        Linking.openURL(appUrl).then(() => {
          onDismiss();
        });
      }
    });
  };

  useOnMount(() => {
    if (!loading && Platform.OS === "ios" && !appUpdateDimissed && !isFetching) {
      setFetching(true);
      getAppVersion(appUpdateDimissed, version).then((appOutOfDate: boolean) => setShowModal(appOutOfDate));
    }
  });

  useEffect(() => {
    if (showModal && Platform.OS === "ios") {
      openDialog({
        heading: "Update Available",
        message: [`An update for this app is available.`, `Please download the latest updated from the app store.`],
        confirmButton: {
          label: "Update",
          onPress: onAppStorePress,
        },
        dismissable: true,
        onDismiss,
      });
    } else {
      closeDialog();
    }
  }, [showModal]);

  return <React.Fragment />;
};

export default AppUpdateModalContainer;
