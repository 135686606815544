/**
 * Subscribe to the horizontal padding amount for content based on device width
 */
import { useEffect, useState } from "react";
import { Dimensions, Platform } from "react-native";

export default function useContentPadding(): number {
  const device = Platform.OS === "web" ? "window" : "screen";
  const [padding, setPadding] = useState<number>(calculatePadding(Dimensions.get(device).width));

  useEffect(() => {
    const _handleResize = () => {
      const newPadding = calculatePadding(Dimensions.get(device).width);
      setPadding(newPadding);
    };

    Dimensions.addEventListener("change", _handleResize);
    return () => Dimensions.removeEventListener("change", _handleResize);
  }, []);

  function calculatePadding(deviceWidth: number) {
    return deviceWidth <= 320 ? 20 : deviceWidth <= 410 ? 30 : deviceWidth <= 999 ? 40 : 30;
  }

  return padding;
}
