import React from "react";
import { StyleSheet } from "react-native";
import Auth from "@aws-amplify/auth";

import ENV from "@/config";
import { AuthScreen } from "@/layouts";
import Text from "@/components/Text";
import { TypographyType } from "@/models/Typography";
import { RouteKeys } from "@/navigationv2";
import { AuthNavigationProps, ScreenOptions } from "@/navigationv2/types";
import { createScreenTitle } from "@/navigationv2/utilities";
import { palette, spacing } from "@/theme";

interface PropTypes extends AuthNavigationProps<RouteKeys.WelcomeScreen> {}

export default function WelcomeScreen(props: PropTypes) {
  const buttons = [
    {
      label: ENV.ENABLE_SSO ? "Login with password" : "Login",
      onPress: () => props.navigation.navigate("SignIn"),
      testID: "Login-email-password",
    },
  ];

  if (ENV.ENABLE_SSO) {
    buttons.push({
      label: "Use Microsoft login",
      onPress: () => Auth.federatedSignIn({ customProvider: "Microsoft" }),
      testID: "Login-microsoft",
    });
  }

  return (
    <AuthScreen welcome buttons={buttons} testID={"WelcomeScreen"}>
      <Text format={TypographyType.HeadingLarge} style={styles.heading}>
        Welcome
      </Text>
      <Text format={TypographyType.Small} style={styles.message}>
        Please use the button below to login
      </Text>
    </AuthScreen>
  );
}

const screenOptions: ScreenOptions = {
  title: createScreenTitle("Welcome"),
};

WelcomeScreen.screenOptions = screenOptions;

const styles = StyleSheet.create({
  heading: {
    marginBottom: spacing[20],
    textAlign: "center",
  },

  message: {
    textAlign: "center",
    color: palette.greyBlue,
  },
});
