import { UserDetails } from "../../utils/recoil/props";

export const organisationIdFromPropsAndUser = (
  route: { params: { organisationId?: string } },
  user: UserDetails | null
) =>
  route.params?.organisationId ||
  (user && user.organisations?.length === 1 && user.organisations[0]) ||
  (user && !user.csu && user.organisations?.length && user.organisations[0]) ||
  undefined;
