import { Search } from "./Search";
import { Result } from "./Result";
import { HelperText } from "./HelperText";
import { SearchOptions } from "./SearchOptions";

export const Mod = {
  Search,
  Result,
  HelperText,
  SearchOptions,
};
