import React, { useMemo } from "react";
import { View } from "react-native";
import Text from "../../Text";
import SectionHeading from "../../SectionHeading/SectionHeading";
import FilterLabel from "../../FilterLabel/FilterLabel";
import { formatLongDate } from "libs/dates";
import { TypographyType } from "@/models/Typography";
import styles from "./styles";
import { palette } from "../../../theme/palette";
import { DoctorVisitsWithClaimIdResponse } from "@/models/gql/fragments";
import { amphClaimStatusCopy } from "../../../utils/helpers";
import dayjs, { Dayjs } from "dayjs";
import { ContactedDoctor } from "../AssessmentDetails.props";
import EventsList from "../../EventsList/EventsList";
import { AssessmentItem } from "@/models/Assessment";

export interface TopLevelProps {
  isWebView: boolean;
  hasVisits: boolean;
  visits: DoctorVisitsWithClaimIdResponse[];
  contactedDoctors: ContactedDoctor[];
  assessment: AssessmentItem;
  contactDoctor: (doctorId: string, doctorPhone: string, assessmentId: string) => void;
  viewProfile: (doctorId: string) => void;
  createClaim: (visitId: string) => void;
}

export const ScheduledDoctorsHeading = () => (
  <>
    <SectionHeading heading="Scheduled" webText={formatLongDate()} />
    <FilterLabel text="Sorted by *date doctor added*" />
  </>
);

export const NoScheduledDoctorsText = (props: { hasVisits: boolean; isWebView: boolean }) =>
  !props.hasVisits ? (
    <Text format={TypographyType.Small} color={palette.greyBlue} style={props.isWebView && styles.noResultsTextMobile}>
      No doctors have been added to this assessment.
    </Text>
  ) : (
    <View />
  );

export const CardHeader = React.memo((props: { doctorName: string; visitTime: Dayjs; today: Dayjs }) => (
  <View style={styles.cardHeader}>
    <Text format={TypographyType.RegularBold} style={styles.flex}>
      {props.doctorName}
    </Text>
    <View style={styles.cardHeaderDate}>
      <Text format={TypographyType.TinyBold}>
        {props.visitTime.isSame(props.today, "day") ? "Today " : `${props.visitTime.format("D/MM/YY")} `}
      </Text>
      <Text format={TypographyType.Tiny}>at</Text>
      <Text format={TypographyType.TinyBold}>{` ${props.visitTime.format("HH:mm")}`}</Text>
    </View>
  </View>
));

export const DoctorVisitDetails = (props: { doctorVisit: DoctorVisitsWithClaimIdResponse }) => {
  return (
    <>
      <Text format={TypographyType.Micro} color={palette.greyBlue}>
        Visit Notes
      </Text>
      <Text format={TypographyType.Tiny}>
        {props.doctorVisit.notes ? props.doctorVisit.notes[0] : " - No notes - "}
      </Text>
      {props.doctorVisit.claimDisplayId && props.doctorVisit.claimStatus && (
        <View style={styles.claimInfo}>
          <View style={styles.flex}>
            <Text format={TypographyType.Micro} color={palette.greyBlue}>
              Claim ID
            </Text>
            <Text format={TypographyType.Tiny}>{props.doctorVisit.claimDisplayId}</Text>
          </View>
          <View style={styles.flex}>
            <Text format={TypographyType.Micro} color={palette.greyBlue}>
              Claim Status
            </Text>
            <Text format={TypographyType.Tiny}>{amphClaimStatusCopy(props.doctorVisit.claimStatus)}</Text>
          </View>
        </View>
      )}
    </>
  );
};

export const ContactedDoctorsHeading = (props: { today: Dayjs }) => (
  <>
    <SectionHeading heading="Contacted" webText={formatLongDate(props.today)} />
    <FilterLabel text="Sorted by *time doctor added*" />
  </>
);

export const NoContactedDoctorsText = React.memo((props: { isWebView: boolean }) => (
  <Text format={TypographyType.Small} color={palette.greyBlue} style={props.isWebView && styles.noResultsTextMobile}>
    You are yet to contact any doctors.
  </Text>
));

export const ContactedDoctorsHistory = (props: { contactedDoctor: ContactedDoctor }) => {
  const contactLogList = useMemo(
    () =>
      props.contactedDoctor.timeStamp.map((time: string, index: number) => (
        <Text key={index} format={TypographyType.Tiny} color={palette.slate}>
          {dayjs(time).format("dddd D MMMM YYYY [at] HH:mm")}
        </Text>
      )),
    [props.contactedDoctor.timeStamp.length]
  );
  return (
    <>
      <Text format={TypographyType.Micro} color={palette.greyBlue} style={styles.contactHistoryLabel}>
        Contact History:
      </Text>
      <EventsList events={contactLogList} themeColor={palette.aqua} />
    </>
  );
};
