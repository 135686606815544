import { AllAMHPRoutes } from "@/navigationv2/types";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { StyleSheet, Platform } from "react-native";

import {
  amhpTeamUsesJointMedRecs,
  amhpTeamMhaHospitalEmails,
  amhpTeamEmailFormsToSelf,
  amhpTeamEmailFormsToApprovedLists,
  amhpTeamDownloadForms,
} from "libs/utils/featureFlags";
import { TypographyType } from "@/models/Typography";
import { Accordion, AccordionSection } from "../../Accordion";
import { Button, ButtonList } from "../../Button";
import ContentWrap from "../../ContentWrap/ContentWrap";
import { mqWeb } from "../../../utils/helpers";
import { FormListItem, SignedForm } from "libs/types/mhaForms";
import { FormStatus } from "libs/types/API";
import { isAmhpForm, isJointForm, isConveyanceForm } from "libs/mhaForms/helpers";
import { RouteKeys } from "@/navigationv2";

import { useRecoilValue } from "recoil";
import { userDetails } from "../../../utils/recoil";
import { MHAFormRecordsList } from "../../Forms/MHAFormRecord";
import Text from "../../Text";

import { useBottomSheet } from "@/hooks";
import BottomSheets from "../BottomSheets";

interface AssessmentDoctorFormsProps {
  assessmentId: string;
  formRecords: FormListItem[]; // includes doctor and amhp
  refetch: () => void;
  amhpTeamId: string;
  amhpTeamFeatures: string;
  amhpTeamAllowsExternalDoctors: boolean;
  patientName: string;
}

enum DoctorFormCategoryIndex {
  Drafts,
  ChangesInProgress,
  Signed,
}

const AssessmentDoctorForms: React.FC<AssessmentDoctorFormsProps> = ({
  assessmentId,
  formRecords,
  refetch,
  amhpTeamFeatures,
  amhpTeamId,
  amhpTeamAllowsExternalDoctors,
  patientName,
}) => {
  const navigation = useNavigation<StackNavigationProp<AllAMHPRoutes>>();
  const user = useRecoilValue(userDetails);
  const isWebView = mqWeb();
  const TopContentWrapper = useMemo(() => (isWebView ? React.Fragment : ContentWrap), [isWebView]);
  const doctorForms = formRecords.filter(fr => !isAmhpForm(fr.formType) && !isConveyanceForm(fr.formType));

  // "Draft" forms
  const drafts = doctorForms.filter(fr => fr && fr.status === FormStatus.s10_draft);

  // "Changes in Progress" forms
  const changesInProgress = doctorForms.filter(
    fr => fr && [FormStatus.s20_awaiting_other_doctor, FormStatus.s30_changes_in_progress].includes(fr.status)
  );

  // "Signed" forms
  // (joint forms flow is: s10_draft > primary doc signs > s20_awaiting_other_doctor > second doc signs > s40_signed)
  const signed = doctorForms.filter(
    fr => fr && [FormStatus.s40_signed, FormStatus.s50_signed_and_sent].includes(fr.status)
  );

  const allAMHPAndDoctorSignedForms: Array<SignedForm> = useMemo(
    () =>
      formRecords
        .filter(fr => [FormStatus.s40_signed, FormStatus.s50_signed_and_sent].includes(fr.status))
        .map(({ id, formType, signed, createdAt, expired }) => ({
          id,
          formType,
          signed,
          createdAt,
          patientName,
          expired,
        })),
    [formRecords]
  );

  // We don't want to allow the AMHP to send an External Form, if a joint form for the assessment already exists with two authors
  const jointMedRecsWithTwoAuthors = doctorForms.filter(fr => isJointForm(fr.formType) && fr.authors?.length === 2);

  // TODO: review how we choose to display hospital emails
  const { openBottomSheet, closeBottomSheet } = useBottomSheet();
  const [emailOrExportFormsOption, setEmailOrExportFormsOption] = useState<"email" | "download" | null>(null);
  useEffect(() => {
    if (emailOrExportFormsOption) {
      openBottomSheet({
        type: "generic",
        data: {
          heading: emailOrExportFormsOption === "email" ? "Email Forms" : "Download Forms",
          component: BottomSheets.EmailOrExportForms,
          componentProps: {
            type: emailOrExportFormsOption,
            signedForms: allAMHPAndDoctorSignedForms,
            amhpTeamFeatures,
            amhpTeamId,
            assessmentId,
            resetBottomSheet: () => {
              refetch();
              setEmailOrExportFormsOption(null);
              closeBottomSheet();
            },
          },
          onDismiss: () => setEmailOrExportFormsOption(null),
        },
      });
    }
  }, [emailOrExportFormsOption, assessmentId, amhpTeamId, amhpTeamFeatures, allAMHPAndDoctorSignedForms]);

  const onSendToDoctorPress = useCallback(async () => {
    navigation.navigate(RouteKeys.SendToDoctorScreen, {
      assessmentId,
    });
  }, [assessmentId]);

  const hasEmailToHospitalFeature = amhpTeamMhaHospitalEmails(amhpTeamFeatures);
  const hasEmailToSelfFeature = amhpTeamEmailFormsToSelf(amhpTeamFeatures);
  const hasEmailToListsFeature = amhpTeamEmailFormsToApprovedLists(amhpTeamFeatures);
  const hasDownloadFeature = Platform.OS === "web" && amhpTeamDownloadForms(amhpTeamFeatures);
  const hasJointMedRecsFeature = amhpTeamUsesJointMedRecs(amhpTeamFeatures);
  const isTraineeAmhp = !!user?.isTraineeAmhp;

  return (
    <>
      <TopContentWrapper>
        <Text format={TypographyType.HeadingMedium}>Doctor Forms</Text>
        {isTraineeAmhp ? (
          <Text format={TypographyType.Regular}>
            You have been set up as a trainee AMHP and users with this setting are unable to create or sign
            applications. Please get in touch with our Support Team if you have been set up incorrectly.
          </Text>
        ) : (
          <Text format={TypographyType.Regular}>Review and manage doctor MHA statutory forms for this assessment.</Text>
        )}
        <ButtonList>
          {(hasEmailToHospitalFeature || hasEmailToSelfFeature || hasEmailToListsFeature) &&
            !!signed.length &&
            !isTraineeAmhp && (
              <Button
                onPress={() => setEmailOrExportFormsOption("email")}
                style={styles.button}
                marginBottom={0}
                mode="outlined"
              >
                Email Signed Forms
              </Button>
            )}
          {hasDownloadFeature && !!signed.length && !isTraineeAmhp && (
            <Button
              onPress={() => setEmailOrExportFormsOption("download")}
              marginBottom={0}
              style={styles.button}
              mode="outlined"
            >
              Download Signed Forms
            </Button>
          )}
          {amhpTeamAllowsExternalDoctors && !isTraineeAmhp && (
            <Button
              onPress={onSendToDoctorPress}
              style={styles.button}
              disabled={hasJointMedRecsFeature && !!jointMedRecsWithTwoAuthors.length}
              disabledInfoText={
                hasJointMedRecsFeature && !!jointMedRecsWithTwoAuthors.length
                  ? "Joint form with two authors already exists for this assessment"
                  : "Add patient address to send an MHA Form"
              }
              marginBottom={0}
            >
              Send Form to Off-platform Doctor
            </Button>
          )}
        </ButtonList>
      </TopContentWrapper>
      <Accordion
        marginTop={30}
        defaultActiveIndex={
          // Sets which category should be open by default
          signed.length
            ? DoctorFormCategoryIndex.Signed
            : changesInProgress.length
            ? DoctorFormCategoryIndex.ChangesInProgress
            : drafts.length
            ? DoctorFormCategoryIndex.Drafts
            : -1
        }
      >
        <AccordionSection
          index={DoctorFormCategoryIndex.Drafts}
          heading="Drafts"
          itemCount={drafts.length}
          disabled={!drafts.length}
        >
          <MHAFormRecordsList
            formRecords={drafts}
            assessmentId={assessmentId}
            isAmhpForm={false}
            refetch={refetch}
            user={user}
            removeCTAButtons={true}
          />
        </AccordionSection>
        <AccordionSection
          index={DoctorFormCategoryIndex.ChangesInProgress}
          heading="Changes in Progress"
          itemCount={changesInProgress.length}
          disabled={!changesInProgress.length}
        >
          <MHAFormRecordsList
            formRecords={changesInProgress}
            assessmentId={assessmentId}
            isAmhpForm={false}
            refetch={refetch}
            user={user}
          />
        </AccordionSection>
        <AccordionSection
          index={DoctorFormCategoryIndex.Signed}
          heading="Signed"
          itemCount={signed.length}
          disabled={!signed.length}
        >
          <MHAFormRecordsList
            formRecords={signed}
            assessmentId={assessmentId}
            isAmhpForm={false}
            refetch={refetch}
            user={user}
          />
        </AccordionSection>
      </Accordion>
    </>
  );
};

const styles = StyleSheet.create({
  button: { width: "100%" },
});

export default AssessmentDoctorForms;
