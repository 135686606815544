import dayjs, { Dayjs } from "dayjs";
import isBetween from "dayjs/plugin/isBetween";

import { AvailabilityType, CalendarItem, CalendarEventItem } from "libs/types/availability";
import { EventItem } from "libs/types/holiday";

import { convertDateToRRuleFormat } from "./convert";

dayjs.extend(isBetween);

export function eventToCalendar<T extends EventItem>(events: T[]): CalendarItem[] {
  const calendarItems: CalendarItem[] = events
    .map((h: T) => {
      let day = h.start;
      const { end } = h;
      const dates = [];
      while (dayjs(day).isBefore(dayjs(end))) {
        dates.push(day);
        day = dayjs(day).add(1, "day").hour(0).minute(0);
      }

      const allDates =
        dates.length === 1
          ? [
              {
                startDate: convertDateToRRuleFormat(h.start, {
                  hours: dayjs(h.start).hour(),
                  minutes: dayjs(h.start).minute(),
                  seconds: 1,
                }),
                endDate: convertDateToRRuleFormat(h.end, {
                  hours: dayjs(h.end).hour(),
                  minutes: dayjs(h.end).minute(),
                }),
                endHour: parseInt(
                  `${dayjs(h.end).hour() < 10 ? "0" + dayjs(h.end).hour() : dayjs(h.end).hour()}${
                    dayjs(h.end).minute() < 10 ? "0" + dayjs(h.end).minute() : dayjs(h.end).minute()
                  }`
                ),
                visible: h.visible,
              },
            ]
          : dates.map((dt, i) => {
              const endHours = i === dates.length - 1 ? h.end.hour() : 23;
              const endMinutes = i === dates.length - 1 ? h.end.minute() : 59;
              return {
                startDate: convertDateToRRuleFormat(dt, {
                  hours: dayjs(dt).hour(),
                  minutes: dayjs(dt).minute(),
                  seconds: 1,
                }),
                endDate: convertDateToRRuleFormat(dt, {
                  hours: endHours,
                  minutes: endMinutes,
                }),
                endHour: parseInt(
                  `${endHours < 10 ? "0" + endHours : endHours}${endMinutes < 10 ? "0" + endMinutes : endMinutes}`
                ),
                visible: h.visible,
              };
            });

      const returnVal: CalendarItem[] = allDates.map((d) => ({
        id: h.id,
        notes: h.notes,
        type: h.isBooking ? AvailabilityType.booking : d.visible ? AvailabilityType.away : AvailabilityType.hidden,
        endHour: d.endHour,
        distance: 50,
        location: null,
        locationName: null,
        ...(h.partialPostcode && {
          partialPostcode: h.partialPostcode,
        }),
        rrule: `DTSTART:${d.startDate};RRULE:FREQ=DAILY;INTERVAL=1;WKST=MO;UNTIL=${d.endDate}`,
        mht: undefined,
        contractShortCode: undefined,
      }));

      return returnVal;
      // since the map has an array of items, it needs to be flattened
    })
    .reduce((acc, curr) => {
      acc.push(...curr);
      return acc;
    }, []);

  return calendarItems;
}

export function mergeHolidaysToAvailabilities<T extends CalendarEventItem>(
  calendarItems: T[] & {
    overnightElement?: number;
  }
) {
  const holidays = calendarItems.filter((ci) =>
    [AvailabilityType.away, AvailabilityType.hidden].includes(ci.type || AvailabilityType.independent)
  );
  const additionalItems: T[] = [];
  if (!holidays.length) return calendarItems;
  const otherItems = calendarItems.filter(
    (ci) => ci.type !== AvailabilityType.away && ci.type !== AvailabilityType.hidden
  );
  otherItems.map((oi) => {
    if (oi.overnightElement) return additionalItems.push(oi);
    let holidayStarts: Dayjs[] = [];
    let holidayEnds: Dayjs[] = [];
    holidays.forEach((holiday: T) => {
      holidayStarts.push(holiday.start);
      holidayEnds.push(holiday.end);
    });

    holidayStarts = [...holidayStarts, holidayStarts[0].hour(23).minute(59).second(59)].sort((a, b) =>
      a.isAfter(b) ? 1 : -1
    );

    holidayEnds = [holidayEnds[0].hour(0).minute(0).second(0), ...holidayEnds].sort((a, b) => (a.isAfter(b) ? 1 : -1));

    const filtered = holidayEnds
      .map((he, i) => {
        return he < holidayStarts[i]
          ? {
              start: he,
              end: holidayStarts[i].subtract(2, "second"),
            }
          : null;
      })
      .filter((h) => !!h);

    let additions: T[] = filtered
      .filter((h) => h && (h.start.isBetween(oi.start, oi.end) || h.end.isBetween(oi.start, oi.end)))
      .map((h) => ({
        start: h && (oi.start > h.start ? oi.start : h.start),
        end: h && (oi.end < h.end ? oi.end : h.end.add(1, "second")),
      }))
      .map((a) => ({
        ...oi,
        start: a.start,
        end: a.end,
      }));

    if (additions.length === 0) {
      const noOverlap = filtered.filter(
        (h) => h && (oi.start.isBetween(h.start, h.end) || oi.end.isBetween(h.start, h.end))
      );

      additions = noOverlap.length > 0 ? [oi] : additions;
    }
    additionalItems.push(...additions);

    return oi;
  });
  return [...holidays, ...additionalItems].sort((a, b) => a.start.valueOf() - b.start.valueOf());
}
