import React, { useCallback, useMemo } from "react";
import { View, StyleSheet } from "react-native";
import { ParamListBase, useNavigation } from "@react-navigation/native";

import { RouteKeys } from "@/navigationv2";
import { formatCardDate } from "libs/dates";
import { TypographyType } from "@/models/Typography";
import { palette } from "@/theme";
import { getAssessmentLabelColor, ucFirst } from "@/utils/helpers";

import { Card, CardInfoItem } from "@/components/Card";
import CardLabel from "@/components/CardLabel/CardLabel";
import Text from "@/components/Text";
import { VisitResultItem } from "./VisitResultItem.props";
import { formatAddress } from "libs/formatters/address";
import { formatOrdinalDate } from "libs/dates/format";
import { StackNavigationProp } from "@react-navigation/stack";

export const VisitResult = (props: { visit: VisitResultItem }) => {
  const { visit } = props;
  const navigation = useNavigation<StackNavigationProp<ParamListBase>>();
  const { labelColor, daysLabel } = getAssessmentLabelColor(visit.time);

  const _onVisitResultPress = useCallback(
    () =>
      navigation.navigate(RouteKeys.DoctorVisitDetailsScreen, {
        visitId: visit.id,
      }),
    [visit.id]
  );

  const headingContent = useMemo(() => (daysLabel ? <CardLabel color={labelColor} text={daysLabel} /> : <View />), [
    daysLabel,
  ]);

  const visitInfoList = useMemo(
    () => (
      <>
        <CardInfoItem
          key={`${visit.time}_${visit.assessment.patientName}`}
          color={palette[labelColor]}
          lastItem={!visit.claim}
        >
          <Text format={TypographyType.Tiny}>{formatAddress(visit.assessment.locationName)}</Text>
          <Text format={TypographyType.Micro} style={styles.slateText}>
            {formatCardDate(visit.time)}
          </Text>
        </CardInfoItem>
        {visit.claim && (
          <CardInfoItem
            key={`${visit.time}_${visit.assessment.patientName}_${visit.claim.id}`}
            color={palette[labelColor]}
            lastItem={true}
          >
            <Text format={TypographyType.Tiny}>Claim created ({formatOrdinalDate(visit.claim.createdAt)})</Text>
            <Text format={TypographyType.Micro} style={styles.slateText}>
              Status: {ucFirst(visit.claim.status.split("_").join(" "))}
            </Text>
          </CardInfoItem>
        )}
      </>
    ),
    [visit]
  );

  return (
    <Card
      heading={visit.assessment.patientName}
      indicatorColor={palette[labelColor]}
      accentColor={palette[labelColor]}
      headingContent={headingContent}
      onPress={_onVisitResultPress}
      infoHeading={"Details"}
      infoList={visitInfoList}
    />
  );
};

export default VisitResult;

const styles = StyleSheet.create({
  slateText: {
    color: palette.slate,
  },
});
