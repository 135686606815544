import React, { useRef } from "react";
import { StyleSheet, TouchableOpacity, Text as RNText } from "react-native";
import { useHover } from "react-native-web-hooks";

import { TypographyType } from "@/models/Typography";
import { palette, spacing } from "@/theme";

import Text from "@/components/Text";

import { GpPracticeItem, GpOrgSearchItem, GPSearchByFilter } from "../GPSearch.props";

type ResultProps =
  | {
      result: GpOrgSearchItem;
      readOnly?: boolean;
      query: string;
      searchBy: GPSearchByFilter;
      getGPData: (orgId: string, roleId: string) => Promise<GpPracticeItem>;
      setSelectedResult: React.Dispatch<React.SetStateAction<GpPracticeItem | null>>;
      setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
    }
  | {
      result: GpOrgSearchItem;
      readOnly: true;
    };

export function Result(props: ResultProps): JSX.Element {
  const resultRef = useRef<TouchableOpacity>(null);
  const isHovered = useHover(resultRef);

  function _onResultPress() {
    !props.readOnly &&
      props
        .getGPData(props.result.id, props.result.roleId)
        .then((result: GpPracticeItem) => {
          props.setSelectedResult(result);
        })
        .catch(e => {
          props.setSelectedResult(null);
          props.setErrorMessage("Error. Please try again");
        });
  }

  const queryIndexInResult = props.readOnly
    ? -1
    : props.searchBy === "name"
    ? props.result.name.indexOf(props.query.toUpperCase())
    : props.result.postcode.indexOf(props.query.toUpperCase());
  const NameText =
    props.readOnly || props.searchBy === "postcode" ? (
      <Text
        format={props.readOnly ? TypographyType.RegularBold : TypographyType.Regular}
        color={!props.readOnly && isHovered ? palette.blue : palette.navy}
      >
        {props.result.name}
      </Text>
    ) : (
      <RNText>
        <Text
          format={props.readOnly ? TypographyType.RegularBold : TypographyType.Regular}
          color={!props.readOnly && isHovered ? palette.blue : palette.navy}
        >
          {props.result.name.substring(0, queryIndexInResult)}
        </Text>
        <Text
          style={!isHovered && styles.highlightedText}
          format={TypographyType.RegularBold}
          color={props.readOnly ? palette.navy : palette.blue}
        >
          {props.result.name.substring(queryIndexInResult, queryIndexInResult + props.query.length)}
        </Text>
        <Text
          format={props.readOnly ? TypographyType.RegularBold : TypographyType.Regular}
          color={!props.readOnly && isHovered ? palette.blue : palette.navy}
        >
          {props.result.name.substring(queryIndexInResult + props.query.length)}
        </Text>
      </RNText>
    );

  const PostcodeText =
    props.readOnly || props.searchBy === "name" ? (
      <Text format={TypographyType.Small} color={!props.readOnly && isHovered ? palette.blue : palette.slate}>
        {props.result.postcode}
      </Text>
    ) : (
      <RNText>
        <Text format={TypographyType.Small} color={!props.readOnly && isHovered ? palette.blue : palette.slate}>
          {props.result.postcode.substring(0, queryIndexInResult)}
        </Text>
        <Text
          style={!isHovered && styles.highlightedText}
          format={TypographyType.SmallBold}
          color={props.readOnly ? palette.slate : palette.blue}
        >
          {props.result.postcode.substring(queryIndexInResult, queryIndexInResult + props.query.length)}
        </Text>
        <Text format={TypographyType.Small} color={!props.readOnly && isHovered ? palette.blue : palette.slate}>
          {props.result.postcode.substring(queryIndexInResult + props.query.length)}
        </Text>
      </RNText>
    );

  return (
    <TouchableOpacity
      onPress={_onResultPress}
      style={[
        styles.result,
        !props.readOnly && isHovered && styles.resultHovered,
        props.readOnly && styles.resultReadOnly,
      ]}
      ref={resultRef}
      disabled={props.readOnly}
    >
      <>
        {NameText}
        {PostcodeText}
      </>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  result: {
    paddingHorizontal: spacing[30],
    paddingVertical: spacing[15],
    borderBottomWidth: 1,
    borderBottomColor: palette.grey,
  },
  resultHovered: {
    backgroundColor: palette.blueSecondary,
  },
  resultReadOnly: {
    paddingHorizontal: spacing[15],
    paddingVertical: spacing[15],
    borderBottomWidth: 0,
    backgroundColor: palette.cloud,
  },

  highlightedText: {
    backgroundColor: palette.blueSecondary,
  },
});
