import * as Yup from "yup";
import { ComponentElement, FormFieldsAmhp, FormType } from "libs/types/mhaForms";
import { FormSection } from "../..";

const componentElements: ComponentElement[] = [
  {
    component: "Text",
    componentProps: {
      instruction: "Summary of Risk/Care and Support Needs",
    },
    fullWidth: true,
    genericProps: {
      pdfBreak: [FormType.AUTHORITY_TO_CONVEY],
    },
  },
  {
    component: "BottomTextInput",
    fieldName: FormFieldsAmhp.SummaryOfRisk,
    fullWidth: true,
    componentProps: {
      label: "Summary of Risk/Care and Support Needs",
      buttonLabel: "Reasons",
      defaultText: "No details currently given",
    },
  },
  {
    component: "Text",
    componentProps: {
      instruction:
        "Mental Health Act S137(2)\nA constable or any other person required or authorised by or by virtue of this Act to take any person into custody, or to convey or detain any person shall, for the purposes of taking him into custody or conveying or detaining him, have all the powers, authorities, protection and privileges which a constable has within the area for which he acts as constable\n\nMental Health Act Code of Practice 17.18\nPeople authorised by the applicant to transport patients act in their own right and not as the agent of the applicant. They may act on their own initiative to restrain patients and prevent them absconding, if absolutely necessary. When they are the applicant, AMHPs retain a professional responsibility to ensure that the patient is transported in a lawful and humane manner and should give guidance to those asked to assist.",
    },
    fullWidth: true,
  },
  {
    component: "Text",
    componentProps: {
      instruction: "Additional Information",
    },
    fullWidth: true,
  },
  {
    component: "BottomTextInput",
    fieldName: FormFieldsAmhp.AdditionalInformation,
    fullWidth: true,
    componentProps: {
      label: "Additional Information",
      compact: true,
    },
  },
  {
    component: "Text",
    componentProps: {
      instruction: "Booking Reference",
    },
    fullWidth: true,
  },
  {
    component: "TextInput",
    fieldName: FormFieldsAmhp.BookingReference,
    componentProps: {
      placeholder: "Add a booking reference here if applicable",
    },
  },
];

const summaryOfRisk: FormSection = {
  title: "Summary of Risk/Care and Support Needs",
  state: {
    summaryOfRisk: "",
    additionalInformation: "",
    bookingReference: "",
  },

  validation: Yup.object().shape({
    summaryOfRisk: Yup.string()
      .trim()
      .required("Please complete Summary of Risk/Care and Support Needs")
      .min(20, "Please complete a detailed Summary of Risk/Care and Support Needs"),
  }),

  componentElements,
};

export default summaryOfRisk;
