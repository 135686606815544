import dayjs from "dayjs";
import { ComponentElement, FormType, FormFieldsAmhp, FormFieldsDoctor, MhaFormVersion } from "libs/types/mhaForms";

import {
  AMHPDetails,
  AssessmentDetails,
  HospitalDetails,
  LocalAuthority,
  NearestRelative,
  NearestRelativeConsulted,
  PatientDetails,
  PatientDetailsTreatment,
  EmergencyAdmissionDetails,
  A6LocalAuthority,
  A6AssessmentDetails,
  A10HospitalDetails,
  ConveyanceAmhpDetails,
  ConveyancePatientDetails,
  ConveyanceFormDetails,
  ConveyanceSummaryOfRisk,
} from "./amhp";

import {
  StatementOfReasonDoctorDetails,
  StatementOfReasonPatientDetails,
  StatementOfReasonLastExamined,
  StatementOfReasonOpinion,
  StatementOfReasonReasons,
} from "./doctor/statementOfReason/statementOfReason";

import { A11ExaminationDetails, A11Opinion, A11PatientDetails, A11Reasons, A11DoctorDetails } from "./doctor/A11";
import {
  FirstDoctorDetails,
  FirstDoctorExaminationDetails,
  JointDetentionDetails,
  JointDetentionDetailsTreatment,
  JointHospitalRecommendations,
  JointPatientDetails,
  SecondDoctorDetails,
  SecondDoctorExaminationDetails,
  DoctorDetails,
  DoctorPatientDetails,
  DoctorPatientDetailsTreatment,
  ExaminationDetails,
  DetentionDetails,
  DetentionTreatmentDetails,
  HospitalRecommendations,
} from "./doctor";
import { LocationNameInput } from "libs/types/API";

type Fields = FormFieldsDoctor | FormFieldsAmhp;

export interface FormSection {
  state: { [key in Fields]?: string | boolean | dayjs.Dayjs | LocationNameInput | string[] };
  validation: any;
  componentElements: ComponentElement[];
  title: string;
}

type FormDefinition = {
  title: string;
  longTitle?: string;
  shortTitle?: string;
  section?: number;
  regulation?: string;
  formSections: FormSection[];
};

type VersionedFormDefinition = FormDefinition & {
  version: MhaFormVersion;
};

const conveyanceForm = {
  [FormType.AUTHORITY_TO_CONVEY]: defineVersionedForms([
    {
      version: 2,
      title: "Authority To Convey",
      formSections: [ConveyanceAmhpDetails, ConveyancePatientDetails, ConveyanceFormDetails, ConveyanceSummaryOfRisk],
    },
  ]),
};

const statementOfReasonForm = {
  [FormType.STATEMENT_OF_REASON]: defineVersionedForms([
    {
      version: 2,
      title: "Medical Statement of Reason",
      shortTitle: "Medical Statement of Reason",
      longTitle: "Informal record of assessment",
      regulation: "N/A",
      formSections: [
        StatementOfReasonDoctorDetails,
        StatementOfReasonPatientDetails,
        StatementOfReasonLastExamined,
        StatementOfReasonOpinion,
        StatementOfReasonReasons,
      ],
    },
  ]),
};

const admissionsForms = {
  [FormType.A2]: defineVersionedForms([
    {
      version: 1,
      title: "Application by an AMHP for admission for assessment",
      longTitle: "Section 2 – application by an approved mental health professional for admission for assessment",
      shortTitle: "AMHP Application",
      section: 2,
      regulation: "4(1)(a)(ii)",
      formSections: [
        HospitalDetails,
        AMHPDetails.v1,
        PatientDetails,
        LocalAuthority,
        NearestRelative,
        AssessmentDetails,
      ],
    },
    {
      version: 2,
      title: "Application by an AMHP for admission for assessment",
      longTitle: "Section 2 – application by an approved mental health professional for admission for assessment",
      shortTitle: "AMHP Application",
      section: 2,
      regulation: "4(1)(a)(ii)",
      formSections: [
        HospitalDetails,
        AMHPDetails.v2,
        PatientDetails,
        LocalAuthority,
        NearestRelative,
        AssessmentDetails,
      ],
    },
  ]),
  [FormType.A3]: defineVersionedForms([
    {
      version: 1,
      title: "Joint Medical Recommendation - Primary Practitioner",
      longTitle: "Section 2 – joint medical recommendation for admission for assessment",
      section: 2,
      shortTitle: "Joint Medical Recommendation",
      regulation: "4(1)(b)(i)",
      formSections: [
        JointPatientDetails,
        FirstDoctorDetails.v1,
        FirstDoctorExaminationDetails,
        SecondDoctorDetails.v1,
        SecondDoctorExaminationDetails,
        JointDetentionDetails,
      ],
    },
    {
      version: 2,
      title: "Joint Medical Recommendation - Primary Practitioner",
      longTitle: "Section 2 – joint medical recommendation for admission for assessment",
      section: 2,
      shortTitle: "Joint Medical Recommendation",
      regulation: "4(1)(b)(i)",
      formSections: [
        JointPatientDetails,
        FirstDoctorDetails.v2,
        FirstDoctorExaminationDetails,
        SecondDoctorDetails.v2,
        SecondDoctorExaminationDetails,
        JointDetentionDetails,
      ],
    },
  ]),
  [FormType.A4]: defineVersionedForms([
    {
      version: 1,
      title: "Medical Recommendation",
      longTitle: "Section 2 – medical recommendation for admission for assessment",
      shortTitle: "Medical Recommendation",
      section: 2,
      regulation: "4(1)(b)(ii)",
      formSections: [DoctorDetails.v1, DoctorPatientDetails, ExaminationDetails, DetentionDetails],
    },
    {
      version: 2,
      title: "Medical Recommendation",
      longTitle: "Section 2 – medical recommendation for admission for assessment",
      shortTitle: "Medical Recommendation",
      section: 2,
      regulation: "4(1)(b)(ii)",
      formSections: [DoctorDetails.v2, DoctorPatientDetails, ExaminationDetails, DetentionDetails],
      // formSections: [statementOfReason],
    },
  ]),
  [FormType.A6]: defineVersionedForms([
    {
      version: 1,
      title: "Application by an AMHP for admission for treatment",
      longTitle: "Section 3 – application by an approved mental health professional for admission for treatment",
      shortTitle: "AMHP Application",
      section: 3,
      regulation: "4(1)(c)(ii)",
      formSections: [
        HospitalDetails,
        AMHPDetails.v1,
        PatientDetailsTreatment,
        A6LocalAuthority,
        NearestRelativeConsulted,
        A6AssessmentDetails,
      ],
    },
    {
      version: 2,
      title: "Application by an AMHP for admission for treatment",
      longTitle: "Section 3 – application by an approved mental health professional for admission for treatment",
      shortTitle: "AMHP Application",
      section: 3,
      regulation: "4(1)(c)(ii)",
      formSections: [
        HospitalDetails,
        AMHPDetails.v2,
        PatientDetailsTreatment,
        A6LocalAuthority,
        NearestRelativeConsulted,
        A6AssessmentDetails,
      ],
    },
  ]),
  [FormType.A7]: defineVersionedForms([
    {
      version: 1,
      title: "Joint Medical Recommendation - Primary Practitioner",
      longTitle: "Section 3 – joint medical recommendation for admission for treatment",
      shortTitle: "Joint Medical Recommendation",
      section: 3,
      regulation: "4(1)(d)(i)",
      formSections: [
        JointPatientDetails,
        FirstDoctorDetails.v1,
        FirstDoctorExaminationDetails,
        SecondDoctorDetails.v1,
        SecondDoctorExaminationDetails,
        JointDetentionDetailsTreatment,
        JointHospitalRecommendations,
      ],
    },
    {
      version: 2,
      title: "Joint Medical Recommendation - Primary Practitioner",
      longTitle: "Section 3 – joint medical recommendation for admission for treatment",
      shortTitle: "Joint Medical Recommendation",
      section: 3,
      regulation: "4(1)(d)(i)",
      formSections: [
        JointPatientDetails,
        FirstDoctorDetails.v2,
        FirstDoctorExaminationDetails,
        SecondDoctorDetails.v2,
        SecondDoctorExaminationDetails,
        JointDetentionDetailsTreatment,
        JointHospitalRecommendations,
      ],
    },
  ]),
  [FormType.A8]: defineVersionedForms([
    {
      version: 1,
      title: "Medical Recommendation",
      longTitle: "Section 3 – medical recommendation for admission for treatment",
      shortTitle: "Medical Recommendation",
      section: 3,
      regulation: "4(1)(d)(ii)",
      formSections: [
        DoctorDetails.v1,
        DoctorPatientDetailsTreatment,
        ExaminationDetails,
        DetentionTreatmentDetails,
        HospitalRecommendations,
      ],
    },
    {
      version: 2,
      title: "Medical Recommendation",
      longTitle: "Section 3 – medical recommendation for admission for treatment",
      shortTitle: "Medical Recommendation",
      section: 3,
      regulation: "4(1)(d)(ii)",
      formSections: [
        DoctorDetails.v2,
        DoctorPatientDetailsTreatment,
        ExaminationDetails,
        DetentionTreatmentDetails,
        HospitalRecommendations,
      ],
    },
  ]),
  [FormType.A10]: defineVersionedForms([
    {
      version: 1,
      title: "Emergency Application",
      longTitle:
        "Section 4 – emergency application by an approved mental health professional for admission for assessment",
      shortTitle: "AMHP Application",
      section: 4,
      regulation: "4(1)(e)(ii)",
      formSections: [A10HospitalDetails, AMHPDetails.v1, PatientDetails, A6LocalAuthority, EmergencyAdmissionDetails],
    },
    {
      version: 2,
      title: "Emergency Application",
      longTitle:
        "Section 4 – emergency application by an approved mental health professional for admission for assessment",
      shortTitle: "AMHP Application",
      section: 4,
      regulation: "4(1)(e)(ii)",
      formSections: [A10HospitalDetails, AMHPDetails.v2, PatientDetails, A6LocalAuthority, EmergencyAdmissionDetails],
    },
  ]),
  [FormType.A11]: defineVersionedForms([
    {
      version: 1,
      title: "Medical Recommendation",
      longTitle: "Section 4 – medical recommendation for emergency admission for assessment",
      shortTitle: "Medical Recommendation",
      section: 4,
      regulation: "4(1)(f)",
      formSections: [A11DoctorDetails.v1, A11PatientDetails, A11ExaminationDetails, A11Opinion, A11Reasons],
    },
    {
      version: 2,
      title: "Medical Recommendation",
      longTitle: "Section 4 – medical recommendation for emergency admission for assessment",
      shortTitle: "Medical Recommendation",
      section: 4,
      regulation: "4(1)(f)",
      formSections: [A11DoctorDetails.v2, A11PatientDetails, A11ExaminationDetails, A11Opinion, A11Reasons],
    },
  ]),
};

export type FormsFunc = {
  [key in keyof typeof FormType]: (version: MhaFormVersion) => FormDefinition;
};

export const Forms: FormsFunc = {
  ...statementOfReasonForm,
  ...conveyanceForm,
  ...admissionsForms,
};

/**
 * Produces a function which will return a form version from the set of definitions.
 * Forms are selected by the form version number. If a form does not have a version
 * number specified, then it is assumed that that version number did not produce a new version of that form,
 * and the highest available form version number is selected instead.
 *
 * It is not required that there be a new form for each version, we can assume the previous version is correct.
 *
 * @param definitions Array of versioned form definitions
 */
function defineVersionedForms(definitions: VersionedFormDefinition[]): (version: MhaFormVersion) => FormDefinition {
  return function (version: number) {
    const definition =
      definitions.find((d) => d.version === version) || definitions.sort((a, b) => a.version - b.version).pop();
    if (!definition) {
      throw new Error("Form Definition not found");
    }
    return definition;
  };
}
