import React from "react";

import { AppScreen } from "@/layouts/AppScreen/AppScreen";
import { BackButtonProps } from "@/components/BackButton/BackButton.props";
import AvailabilityProvider from "@/components/DoctorAvailability/DoctorAvailabilityProvider";
import EditDoctorAvailability from "@/components/EditDoctorAvailability";
import { RouteKeys } from "@/navigationv2";
import { DoctorProfileRoutes, DoctorTabRoutes, ScreenNavigationProps, ScreenOptions } from "@/navigationv2/types";
import { createScreenTitle } from "@/navigationv2/utilities";
import { color } from "@/theme";

interface PropTypes
  extends ScreenNavigationProps<DoctorTabRoutes, DoctorProfileRoutes, RouteKeys.EditDoctorAvailabilityScreen> {}

const EditDoctorAvailabilityScreen = () => {
  const backButtonConfig: BackButtonProps = {
    enabled: true,
    float: true,
    color: color.textWhite,
  };
  return (
    <AppScreen
      contextLabel="Diary"
      pageTitle="Edit Availability"
      backButton={backButtonConfig}
      testID="EditDoctorAvailabilityScreen"
    >
      <AvailabilityProvider>
        <EditDoctorAvailability />
      </AvailabilityProvider>
    </AppScreen>
  );
};

const screenOptions: ScreenOptions = {
  title: createScreenTitle("Edit My Availability"),
};

EditDoctorAvailabilityScreen.screenOptions = screenOptions;

export default EditDoctorAvailabilityScreen;
