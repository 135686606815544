import { Platform } from "react-native";
import Auth from "@aws-amplify/auth";

export default function configureAuth() {
  if (Platform.OS === "web") {
    try {
      Auth.configure({
        storage: window.sessionStorage,
      });
    } catch (err) {
      // TODO: no way to handle not being able to store credentials from web, show generic error message, send error to server, and bail?
      console.log(err);
    }
  }
}
