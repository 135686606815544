import { useMemo } from "react";
import { useSetRecoilState } from "recoil";

import { globalDialogData } from "@/utils/recoil";
import { DialogData } from "@/utils/recoil/props";

export default function useDialog() {
  const setDialogData = useSetRecoilState(globalDialogData);

  const openDialog = (data: DialogData) => setDialogData(data);
  const closeDialog = () => setDialogData(null);

  return useMemo(
    () => ({
      openDialog,
      closeDialog,
    }),
    []
  );
}
