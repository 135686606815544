import React, { useState, useEffect } from "react";

import { BackButtonProps } from "@/components/BackButton/BackButton.props";
import VisitDetails from "@/components/DoctorVisitDetails";
import { AppScreen } from "@/layouts/AppScreen/AppScreen";
import { useOnScreenFocus } from "@/hooks";
import { RouteKeys } from "@/navigationv2";
import {
  DoctorProfileRoutes,
  DoctorVisitsRoutes,
  DoctorTabRoutes,
  ScreenNavigationProps,
  ScreenOptions,
} from "@/navigationv2/types";
import { createScreenTitle } from "@/navigationv2/utilities";
import { color } from "@/theme";
import { mqWeb } from "@/utils/helpers";
import { getNameAsPageTitle } from "@/utils/user";

const backButtonConfig: BackButtonProps = {
  enabled: true,
  float: true,
  color: color.textWhite,
};

interface PropTypes
  extends ScreenNavigationProps<
    DoctorTabRoutes,
    DoctorProfileRoutes & DoctorVisitsRoutes,
    RouteKeys.DoctorVisitDetailsScreen
  > {}

export default function DoctorVisitDetailsScreen(props: PropTypes) {
  if (!props.route.params) {
    throw new Error("Improper params");
  }

  const isWebView = mqWeb();
  const { visitId = "", notificationUpdate = "" } = props.route.params;
  const [patientName, setPatientName] = useState("");
  const [refreshRequired, setRefreshRequired] = useState(false);
  const pageTitle = getNameAsPageTitle(patientName, isWebView);

  useOnScreenFocus(() => {
    setRefreshRequired(true);
  });

  useEffect(() => {
    props.navigation.setOptions({
      title: createScreenTitle(`${patientName} Visit Details`),
    });
  }, [patientName, props.navigation]);

  return (
    <AppScreen
      contextLabel="Visit"
      pageTitle={pageTitle}
      backButton={backButtonConfig}
      testID="DoctorVisitDetailsScreen"
    >
      <VisitDetails
        id={visitId}
        retrievePatientName={setPatientName}
        setRefreshDone={() => setRefreshRequired(false)}
        refreshRequired={refreshRequired}
        notificationUpdate={notificationUpdate}
      />
    </AppScreen>
  );
}

const screenOptions: ScreenOptions = {
  title: createScreenTitle("Visit Details"),
};

DoctorVisitDetailsScreen.screenOptions = screenOptions;
