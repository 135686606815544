/* eslint-disable no-template-curly-in-string */
import * as Yup from "libs/validators/yup";
import { addMethod, StringSchema, Ref } from "libs/validators/yup";

import { isValidEmail, isValidPhone } from "libs/validators/index";

declare module "yup" {
  interface StringSchema<T> {
    phoneOrEmail(message: string): Yup.Schema<string>;
    requiredIf(ref: Ref, message: string): Yup.Schema<string>;
  }
}

/**
 * Validate the given string is either a valid email address or valid
 * phone number.
 *
 * @param this Yup.string()
 * @param message Error message
 */
function phoneOrEmail(this: StringSchema, message?: string) {
  const msg = message || "${path} is a not a valid phone number or email address";

  return this.test("phoneOrEmail", msg, function(value: string) {
    const { createError, path } = this;
    const isValid = isValidEmail(value) || isValidPhone(value);

    if (value && !isValid) {
      return createError({
        path,
        message: msg,
      });
    }

    return true;
  });
}

/**
 * Set the field to required if another field contains any value.
 *
 * @param this Yup.string()
 * @param ref Yup.ref('name')
 * @param message Error message
 */
function requiredIf(this: StringSchema, ref: Ref, message?: string) {
  const msg = message || "${path} is a required field";

  return this.test("requiredIf", msg, function(value: string) {
    const { createError, path } = this;

    if (this.resolve(ref) && !value) {
      return createError({
        path,
        message: msg,
      });
    }

    return true;
  });
}

addMethod<StringSchema>(Yup.string, "phoneOrEmail", phoneOrEmail);
addMethod<StringSchema>(Yup.string, "requiredIf", requiredIf);
