import * as Yup from "yup";
import createPostcodeSchema from "libs/validators/yup/createPostcodeSchema";
import { ComponentElement, FormFieldsAmhp } from "libs/types/mhaForms";
import { FormSection } from "..";

const v1ComponentElements: ComponentElement[] = [
  {
    component: "Text",
    componentProps: {
      instruction: "I",
      guidance: "(PRINT your full name and address)",
    },
    fullWidth: true,
  },
  {
    component: "TextInput",
    fieldName: FormFieldsAmhp.AmhpName,
    componentProps: {
      label: "Name",
    },
    genericProps: {
      readonlyIfInitialValue: false,
    },
  },
  {
    component: "Location",
    fieldName: FormFieldsAmhp.AmhpAddress,
    componentProps: {
      label: "Address",
      addressChangeWarning:
        "Address changes made here will automatically be applied to all future MHA forms containing your address.",
    },
  },
];

const v2ComponentElements: ComponentElement[] = [
  {
    component: "Text",
    componentProps: {
      instruction: "I",
      guidance: "(PRINT your full name, address and, if sending by means of electronic communication, email address)",
    },
    fullWidth: true,
  },
  {
    component: "TextInput",
    fieldName: FormFieldsAmhp.AmhpName,
    componentProps: {
      label: "Name",
    },
    genericProps: {
      readonlyIfInitialValue: false,
    },
  },
  {
    component: "Location",
    fieldName: FormFieldsAmhp.AmhpAddress,
    componentProps: {
      label: "Address",
    },
  },
  {
    component: "TextInput",
    fieldName: FormFieldsAmhp.AmhpEmailAddress,
    componentProps: {
      label: "Email Address",
      underlabel: "Changes made here apply to all subsequent forms.",
    },
  },
];

const v1: FormSection = {
  title: "Your Details",
  state: {
    amhpName: "",
    amhpAddress: {
      city: "",
      postcode: "",
      address: "",
    },
  },

  validation: Yup.object().shape({
    amhpName: Yup.string().trim().required("Please enter your name"),
    amhpAddress: Yup.object().shape({
      city: Yup.string(),
      postcode: createPostcodeSchema({
        invalidPostcodeMessage: "Please enter a valid address",
        required: true,
        requiredMessage: "Please enter an address",
      }),
      address: Yup.string(),
    }),
  }),

  componentElements: v1ComponentElements,
};

const v2: FormSection = {
  title: "Your Details",
  state: {
    amhpName: "",
    amhpAddress: {
      city: "",
      postcode: "",
      address: "",
    },
    amhpEmailAddress: "",
  },

  validation: Yup.object().shape({
    amhpName: Yup.string().trim().required("Please enter your name"),
    amhpAddress: Yup.object().shape({
      city: Yup.string(),
      postcode: createPostcodeSchema({
        invalidPostcodeMessage: "Please enter a valid address",
        required: true,
        requiredMessage: "Please enter an address",
      }),
      address: Yup.string(),
    }),
    amhpEmailAddress: Yup.string()
      .trim()
      .email("Please enter a valid email address")
      .required("Please enter an email address"),
  }),

  componentElements: v2ComponentElements,
};

export default {
  v1,
  v2,
};
