import React from "react";
import { createStackNavigator } from "@react-navigation/stack";

import Screens from "@/screens";
import screenOptions from "../config/screenOptions";
import { AppRoutes } from "../types";

const AuthStack = createStackNavigator<AppRoutes>();

const Auth = () => {
  return (
    <AuthStack.Navigator screenOptions={screenOptions}>
      <AuthStack.Screen
        name="Welcome"
        component={Screens.WelcomeScreen}
        options={Screens.WelcomeScreen.screenOptions}
      />

      <AuthStack.Screen name="SignUp" component={Screens.SignUpScreen} options={Screens.SignUpScreen.screenOptions} />

      <AuthStack.Screen
        name="Confirmation"
        component={Screens.ConfirmationScreen}
        options={Screens.ConfirmationScreen.screenOptions}
      />

      <AuthStack.Screen name="SignIn" component={Screens.SignInScreen} options={Screens.SignInScreen.screenOptions} />

      <AuthStack.Screen
        name="ForgotPassword"
        component={Screens.ForgotPasswordScreen}
        options={Screens.ForgotPasswordScreen.screenOptions}
      />

      <AuthStack.Screen
        name="NewPassword"
        component={Screens.NewPasswordScreen}
        options={Screens.NewPasswordScreen.screenOptions}
      />

      <AuthStack.Screen
        name="Support"
        component={Screens.AuthSupportScreen}
        options={Screens.AuthSupportScreen.screenOptions}
      />
    </AuthStack.Navigator>
  );
};

export default React.memo(Auth);
