import React, { forwardRef, useCallback, useMemo, useState } from "react";
import { unstable_createElement as createElement } from "react-native-web";
import InputMask from "react-input-mask";
import { supportsDateInput, Event, Ref } from "@/utils/web";
import dayjs from "dayjs";

require("./style.css");

interface PropTypes {
  onValueChange: (v: Date | "") => void;
  value: Date | "";
  disabled?: boolean;
  min?: Date;
  max?: Date;
  onBlur?: () => void;
  open?: boolean;
  setOpen?: (arg0: boolean) => void;
}

const webInput = (props: any) => createElement("input", props);

const DatePicker = forwardRef((props: PropTypes, ref: Ref) => {
  const { disabled = false, max, min, onBlur, onValueChange, value } = props;
  const [currentValue, setCurrentValue] = useState(value);

  const [displayValue, setDisplayValue] = useState(value instanceof Date ? dayjs(value).format("DD/MM/YYYY") : "");
  const shouldDisplayDateInput = useMemo(supportsDateInput, []);

  // Set min date time to 00:00 so that day can be selected in calendar
  if (min) {
    min.setHours(0);
    min.setMinutes(0);
    min.setSeconds(0);
    min.setMilliseconds(0);
  }

  /**
   * Handle the input blur event.
   */
  const onInputBlur = () => {
    onBlur && onBlur();
  };

  /**
   * Handle the input change event. If it's a valid date, run
   * `onValueChange` callback with the new date.
   *
   * @param event React.SyntheticEvent
   */
  const onDateInputChange = (event: Event) => {
    const { currentTarget } = event;
    const value = new Date(currentTarget.value);
    const isValidDate = value.toString() !== "Invalid Date";

    if (isValidDate) {
      // current value is the internal value, we allow this to be set
      // so that the user can continue typing until a valid value is reached
      setCurrentValue(value);

      if (
        (props.min && props.min.getTime() > value.getTime()) ||
        (props.max && props.max.getTime() < value.getTime())
      ) {
        // don't dispatch invalid date changes,
        return;
      }

      onValueChange(value);
    }
  };

  /**
   * Handle change event in the case that the browser does not support the
   * date input type.
   *
   * @param event
   */
  const onTextInputChange = useCallback(
    (event: Event) => {
      const { currentTarget } = event;
      // replace all non-character-non dashes with understore to show user where more info is needed
      // change dash to slash for display
      let value = currentTarget.value.replace(/[^\d/-]+/i, "_").replace("-", "/");

      if (value.match(/^\d{2}$/) !== null) {
        value += "/";
      } else if (value.match(/^\d{2}\/\d{2}$/) !== null) {
        value += "/";
      }

      const parts = value.split("/").map(item => parseInt(item, 10));

      const date = new Date(parts[2], parts[1] - 1, parts[0]);
      // new date is flexible and can take  out-of-range parts and construct a correct date
      // so month 13 results in month 1 and the year to be incremented
      // test that the  created date has the same values as the displayed parts.
      const isValidDate =
        date.toString() !== "Invalid Date" &&
        value.length === 10 &&
        value.indexOf("_") === -1 &&
        parts[1] === date.getMonth() + 1 &&
        parts[0] === date.getDate() &&
        parts[2] === date.getFullYear();

      setDisplayValue(value);

      if (isValidDate) {
        // current value is the internal value, we allow this to be set
        // so that the user can continue typing until a valid value is reached

        setCurrentValue(date);
        if (
          (props.min && props.min.getTime() > date.getTime()) ||
          (props.max && props.max.getTime() < date.getTime())
        ) {
          // don't dispatch invalid date changes
          return;
        }
        onValueChange(date);
      }
    },
    [onValueChange]
  );

  const onKeyUp = useCallback((event: Event) => {
    const { keyCode } = event;

    if (keyCode === 13) {
      ref.current && ref.current.blur();
    }
    event.preventDefault();
    return false;
  }, []);

  const onKeyDown = useCallback((event: Event) => {
    event.preventDefault();
    return false;
  }, []);

  if (shouldDisplayDateInput) {
    return webInput({
      className: "date-input",
      type: "date",
      pattern: "[0-9]{4}-[0-9]{2}-[0-9]{2}",
      value: currentValue ? formatDate(currentValue) : "",
      min: (min && formatDate(min)) || formatDate(new Date()),
      ...(max && { max: formatDate(max) }),
      onBlur: onInputBlur,
      onChange: onDateInputChange,
      step: "1",
      onKeyDown,
      disabled,
      ref,
      tabIndex: -1,
    });
  }

  return (
    <InputMask
      disabled={disabled}
      mask="99/99/9999"
      onBlur={onInputBlur}
      onChange={onTextInputChange}
      value={displayValue}
    >
      {() =>
        webInput({
          className: "date-input",
          onKeyUp,
          ref,
        })
      }
    </InputMask>
  );
});

DatePicker.displayName = "DatePicker";

export default DatePicker;

function formatDate(date: Date) {
  return `${date.getFullYear()}-${leftPad(date.getMonth() + 1)}-${leftPad(date.getDate())}`;
}

function leftPad(num: number, size = 2): string {
  let s = String(num);
  while (s.length < size) {
    s = "0" + s;
  }
  return s;
}
