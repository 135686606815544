import React from "react";
import { View } from "react-native";

import { DoctorClaimDetail } from "libs/types/claim";

import { TypographyType } from "@/models/Typography";

import Text from "@/components/Text";

interface Props {
  claim: DoctorClaimDetail;
}

export const AdditionalInfo = ({ claim }: Props) => {
  return (
    <>
      {claim.organisation.additionalNotesText ? (
        <Text format={TypographyType.Regular}>{claim.organisation.additionalNotesText}</Text>
      ) : (
        <>
          <Text format={TypographyType.Regular}>
            All claims must be submitted within 6 months of the assessment date. There is a risk that payment will not
            be made if submitted after this date.
          </Text>
          <Text format={TypographyType.Regular}>
            The assessing Doctor is liable with regards to paying their own tax &amp; NI on the payment of this claim,
            as well as any pension payments. Failure of the declaration of earnings to Her Majesty's Customs &amp;
            Revenue could result in financial investigation.
          </Text>
          <Text format={TypographyType.Regular}>
            Please make sure business insurance for car use is in place before claiming mileage.
          </Text>
          <Text format={TypographyType.RegularBold}>
            Doctors employed by an NHS Trust: Claims for MHA assessments can only be made for the following:
          </Text>
          <Text format={TypographyType.Regular}>Assessments that are undertaken out of hours</Text>
          <Text format={TypographyType.Regular}>Assessments that are undertaken in a police station (24/7)</Text>
          <Text format={TypographyType.Regular}>Assessments that are undertaken out of area</Text>
          <Text format={TypographyType.Regular}>
            Assessments that are undertaken during normal working hours that have resulted in the normal working day
            being extended.
          </Text>
        </>
      )}
    </>
  );
};
