import React from "react";
import Svg, { Path } from "react-native-svg";

export const CoverIcon: React.FC<{ size: number; color?: string }> = ({ size = 45, color = "#FFFFFF" }) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <Path
      d="M9.5,13.1l1.4,1.4L6.4,19H10v2H3v-7h2v3.6L9.5,13.1 M10.9,9.5l-1.4,1.4L5,6.4V10H3V3h7v2H6.4L10.9,9.5 M14.5,13.1l4.5,4.5
  V14h2v7h-7v-2h3.6l-4.5-4.5L14.5,13.1 M13.1,9.5L17.6,5H14V3h7v7h-2V6.4l-4.5,4.5L13.1,9.5z"
      fill={color}
    />
  </Svg>
);
