import React from "react";
import { ApolloClient, NetworkStatus } from "apollo-boost";
import { GraphQLError } from "graphql";
import { useApolloClient } from "@apollo/react-hooks";

import { LocationSearch } from "./LocationSearch";
import { LocationSearchItem } from "./LocationSearch.props";
import { LOCATION_SEARCH } from "@/models/Location";
import { getAddressError, S12Error, networkError, getError } from "@/models/Error";

// caller responsible for catching errors
function doSearchWithClient() {
  const client: ApolloClient<any> = useApolloClient();
  return (searchQuery?: string, addressId?: string) => {
    return client
      .query<{ results: LocationSearchItem[] }>({
        query: LOCATION_SEARCH,
        variables: { term: searchQuery, addressId },
      })
      .then(
        (res: {
          data: { results: LocationSearchItem[] };
          errors?: GraphQLError[];
          loading: boolean;
          networkStatus: NetworkStatus;
          stale: boolean;
        }) => {
          if (res.data && res.data.results) {
            return res.data.results;
          } else if (res.errors) {
            throw new S12Error(getAddressError);
          } else if (res.networkStatus === NetworkStatus.error) {
            throw new S12Error(networkError);
          } else {
            throw new S12Error(getError);
          }
        }
      );
  };
}

export default function LocationSearchGql(props: {
  searchQuery: string;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
  searchResults: LocationSearchItem[];
  setSearchResults: React.Dispatch<React.SetStateAction<LocationSearchItem[]>>;
  initialResults?: LocationSearchItem[];
  retrieveData(data: unknown): void;
}) {
  return (
    <LocationSearch
      searchQuery={props.searchQuery}
      setSearchQuery={props.setSearchQuery}
      searchResults={props.searchResults}
      setSearchResults={props.setSearchResults}
      initialResults={props.initialResults}
      retrieveData={props.retrieveData}
      getResults={doSearchWithClient()}
    />
  );
}
