/* eslint-disable complexity */
import React from "react";

import { formatAddress } from "libs/formatters/address";
import { DoctorProfile } from "@/models/DoctorProfile";
import { TypographyType } from "@/models/Typography";
import { useIsWebView } from "@/hooks";
import { formatEngineSize, formatFuelType, formatPhoneNumber } from "@/utils/helpers";

import Text from "@/components/Text";
import { ContentWrap } from "@/components/ContentWrap";

import { DoctorDetail } from "./DoctorDetail";

type Detail = { label: string; value: string };
type DetailSections = { default: Array<Detail>; claimData?: Array<Detail> };

interface DoctorDetailsListProps {
  doctor: DoctorProfile;
}
export const DoctorDetailsList = React.memo(({ doctor }: DoctorDetailsListProps) => {
  const isWebView = useIsWebView();
  const detailSections = getDetailSections(doctor);

  return (
    <>
      {detailSections.default.map((detail, i) => (
        <DoctorDetail key={i} label={detail.label} value={detail.value} isWebView={isWebView} />
      ))}

      {detailSections.claimData ? (
        <>
          <ContentWrap onlyWrap="web">
            <Text format={TypographyType.HeadingSmall} marginTop={10} marginBottom={25}>
              Claims Data
            </Text>
          </ContentWrap>
          {detailSections.claimData.map((detail, i, details) => (
            <DoctorDetail
              key={i}
              label={detail.label}
              value={detail.value}
              renderDivider={i !== details.length - 1}
              isWebView={isWebView}
            />
          ))}
        </>
      ) : null}
    </>
  );
});
DoctorDetailsList.displayName = "DoctorDetailsList";

function getDetailSections(doctor: DoctorProfile): DetailSections {
  const detailSections: DetailSections = {
    default: [
      { label: "Full Legal Name", value: doctor.legalName },
      { label: "Email Address", value: (doctor.email && doctor.email.trim().length > 0 && doctor.email) || "-" },
      {
        label: "Phone Number",
        value: (doctor.phone && doctor.phone.trim().length > 0 && formatPhoneNumber(doctor.phone)) || "-",
      },
      {
        label: "Additional Phone Number",
        value: (doctor.phone2 && doctor.phone2.trim().length > 0 && formatPhoneNumber(doctor.phone2)) || "-",
      },
      { label: "GMC Number", value: doctor.id || "-" },
      { label: "s.12 Expiry", value: doctor.isOutsideEngland ? "N/A" : doctor.s12Expiry || "-" },
      { label: "Base Postcode", value: doctor.locationName.postcode || "-" },
      { label: "Primary Team Name", value: doctor.employer || "not listed" },
      { label: "Primary Job Title", value: doctor.jobTitle || "-" },
      { label: "Responsible Officer Name", value: doctor.responsibleOfficer || "-" },
      { label: "Line Manager Name", value: doctor.lineManager || "-" },
      {
        label: "MHT Employer",
        value:
          (doctor.mhtEmployers &&
            doctor.mhtEmployers.length > 0 &&
            doctor.mhtEmployers.map(mht => mht.mht.organisation.name).join(",\n")) ||
          "not listed",
      },
      { label: "Specialisms", value: (doctor.specialties || []).join(", ") || "not listed" },
      { label: "Languages", value: (doctor.languages || []).join(", ") || "not listed" },
      { label: "Gender", value: doctor.gender || "-" },
      { label: "Notes", value: doctor.notes || "-" },
      // { label: "Hide me from search?", value: doctor.hideFromSearch ? "Yes" : "No" },
    ],
  };

  if (doctor.claimData) {
    detailSections.claimData = [
      { label: "Name/Company", value: doctor.claimData.billingCompanyName || "-" },
      {
        label: "Billing Address",
        value: doctor.claimData.billingAddress ? formatAddress(doctor.claimData.billingAddress) : "-",
      },
      { label: "VAT Registration Number", value: doctor.claimData.vatRegistration || "-" },
      { label: "Fuel Type", value: doctor.claimData.fuelType ? formatFuelType(doctor.claimData.fuelType) : "-" },
      {
        label: "Engine Size",
        value: doctor.claimData.engineSize
          ? formatEngineSize(doctor.claimData?.fuelType || null, doctor.claimData.engineSize)
          : "-",
      },
    ];
  }

  return detailSections;
}
