import React, { useEffect, useState, ReactNode } from "react";
import { Hub } from "@aws-amplify/core";
import { HubCallback } from "@aws-amplify/core/lib/Hub";
import { CommonActions } from "@react-navigation/native";

import ErrorBoundary from "@/components/ErrorBoundary";
import { useOnMount } from "@/hooks";
import { dispatch } from "@/navigationv2/rootNavigation";

interface PropTypes {
  children: ReactNode;
}

const CustomErrorBoundary = (props: PropTypes) => {
  const { children } = props;
  const [logoutCounter, setLogoutCounter] = useState(1);
  const [errorCounter, setErrorCounter] = useState(1);

  const resetError = () => {
    return setErrorCounter(errorCounter + 1);
  };

  useEffect(() => {
    errorCounter > 1 &&
      dispatch(state => {
        return CommonActions.reset({
          ...state,
          index: 0,
          key: state.routes[0].key,
          routes: [state.routes[0]],
        });
      });
  }, [errorCounter]);

  useOnMount(() => {
    const signOutListener: HubCallback = ({ payload: { event } }) => {
      if (event === "signOut") {
        setTimeout(() => setLogoutCounter(current => current + 1), 0);
      }
    };

    Hub.listen("auth", signOutListener);
    return () => Hub.remove("auth", signOutListener);
  });

  return (
    <React.Fragment key={logoutCounter}>
      <ErrorBoundary reset={resetError} key={errorCounter}>
        {children}
      </ErrorBoundary>
    </React.Fragment>
  );
};

export default CustomErrorBoundary;
