import React, { useCallback } from "react";
import { StyleSheet, View } from "react-native";
import { DropDownData } from "react-native-material-dropdown";

import { mqWeb } from "../../utils/helpers";
import { TypographyType } from "@/models/Typography";
import { TextInput } from "../TextInput/TextInput";
import SelectInput from "./SelectInput";
import Text from "../Text";
import { color } from "../../theme";

const Select = (props: {
  label: string;
  value: string;
  onValueChange: (v: string, i: number, data?: DropDownData[]) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  disabled?: boolean;
  options: { name?: string; id: string }[];
  icon?: string;
  error?: boolean;
  errorText?: string;
}) => {
  const selectRef = React.useRef<any>();
  const isWebView = mqWeb();

  const onValueChange = useCallback(
    (v: string, i: number, data?: DropDownData[]) => {
      selectRef.current && selectRef.current.blur();
      props.onValueChange(v, i, data);
    },
    [selectRef, props.onValueChange]
  );

  const render = useCallback(() => {
    return (
      <View style={styles.selectInputContainer}>
        <Text
          style={[
            styles.selectInputLabel,
            props.icon ? (isWebView ? styles.selectInputLabelWithIconWeb : styles.selectInputLabelWithIcon) : {},
          ]}
          format={TypographyType.Micro}
        >
          {props.label}
        </Text>
        <SelectInput
          value={props.value}
          data={props.options}
          onBlur={props.onBlur}
          onFocus={props.onFocus}
          disabled={props.disabled}
          label={props.label}
          onValueChange={onValueChange}
        />
      </View>
    );
  }, [props.value, props.options, props.onBlur, props.onFocus, props.disabled, props.label, onValueChange]);

  return (
    <TextInput
      icon={props.icon}
      mode="flat"
      disabled={props.disabled}
      value={props.value}
      ref={selectRef}
      // noBottomBorder
      render={render}
      style={!isWebView && styles.textInput}
      error={props.error}
      errorText={props.errorText}
    />
  );
};

const styles = StyleSheet.create({
  textInput: {
    marginBottom: 0,
  },

  selectInputContainer: {
    position: "relative",
    transform: [{ translateY: 0 }],
  },

  selectInputLabel: {
    color: color.textExtraLight,
  },
  selectInputLabelWithIcon: {
    transform: [{ translateX: 0 }],
  },
  selectInputLabelWithIconWeb: {
    transform: [{ translateX: 8 }],
  },
});

Select.displayName = "Select";

export default Select;
