import React, { useMemo } from "react";
import { StyleSheet, TextStyle, StyleProp } from "react-native";

import { TypographyType } from "@/models/Typography";
import Text from "../Text";
import { color } from "../../theme";

function Paragraph(props: { children: string; style?: StyleProp<TextStyle> }) {
  const textStyle = useMemo(() => [styles.text, props.style], [props.style]);
  return (
    <Text format={TypographyType.Regular} style={textStyle}>
      {props.children}
    </Text>
  );
}

export default React.memo(Paragraph);

const styles = StyleSheet.create({
  text: {
    color: color.text,
  },
});
