import dayjs from "dayjs";
import React, { useEffect, useState, useCallback } from "react";
import ApolloClient from "apollo-boost";
import { useRecoilValue, useRecoilState } from "recoil";

import { GET_ASSESSMENT, getAmhpAssessmentsQuery } from "@/models/Assessment";
import { DOCTOR_AND_ASSESSMENT } from "@/models/DoctorProfile";
import { GET_AMHP_PROFILE_QUERY, GetUserProfileResponse } from "@/models/UserProfile";
import { isAssessmentActive } from "@/utils/assessments";
import { checkOfflineCache } from "@/utils/helpers";
import { userDetails, refreshAmhpDashboardRequired } from "@/utils/recoil";
import { UserDetails } from "@/utils/recoil/props";

import { UserDashboard } from "./UserDashboard";
import { ErrorMessage } from "../Error/Error";
import Loading from "../Loading";
import { useQuery } from "@apollo/react-hooks";

const UserDashboardGQL = () => {
  const user = useRecoilValue(userDetails);
  const [refreshRequired, setRefreshRequired] = useRecoilState(refreshAmhpDashboardRequired);
  const [cachingFetchStarted, setCachingFetchStarted] = useState(false);
  const refreshDone = useCallback(() => setRefreshRequired(false), []);
  useEffect(() => {
    if (!cachingFetchStarted) {
      setCachingFetchStarted(true);
    }
  }, [cachingFetchStarted]);

  const { error, data, loading, client, refetch } = useQuery<GetUserProfileResponse>(GET_AMHP_PROFILE_QUERY, {
    fetchPolicy: "cache-first",
  });
  if (data && !loading && refreshRequired) {
    refetch().then(refreshDone);
  }
  const complete = checkOfflineCache(client, GET_AMHP_PROFILE_QUERY);
  if (!complete && error) {
    return <ErrorMessage apolloError={error} />;
  } else if (!complete && loading) {
    return <Loading />;
  } else if (data && data.profile) {
    if (!cachingFetchStarted) {
      getCachableAmhpAssessments(client, user);
    }
    return <UserDashboardMemo profile={data.profile} />;
  } else {
    return <Loading />;
  }
};

const UserDashboardMemo = React.memo(UserDashboard);

export default UserDashboardGQL;

// TODO: this does not functionn properly, this query isn't used anywhere else
const getCachableAmhpAssessments = (client: ApolloClient<any>, user: UserDetails | null) => {
  if (!user) return;
  user.teams.forEach(team => {
    client
      .query({
        query: getAmhpAssessmentsQuery,
        variables: {
          today: dayjs().format("YYYY-MM-DD"),
          amhpId: user.id,
          amhpTeamId: team,
          limit: 10,
        },
      })
      .then(({ data }) => {
        if (data.myAssessments && data.teamAssessments) {
          const assessments = [...data.myAssessments.items, ...data.teamAssessments.items];
          assessments.forEach((assessment: any) => {
            const today = dayjs().format("YYYY-MM-DD");
            if (isAssessmentActive(assessment, today)) {
              client
                .query({
                  query: GET_ASSESSMENT,
                  variables: { id: assessment.id },
                })
                .catch(() => null);
              assessment.doctorVisits.items.forEach((doctorVisit: any) => {
                client
                  .query({
                    query: DOCTOR_AND_ASSESSMENT,
                    variables: {
                      assessmentId: assessment.id,
                      doctorId: doctorVisit.doctor.id,
                    },
                  })
                  .catch(() => null);
              });
            }
          });
        }
      })
      .catch(() => null);
  });
};
