import React from "react";
import { createStackNavigator } from "@react-navigation/stack";

import { ExternalDoctorFormRoutes } from "@/navigationv2/types";
import screenOptions from "@/navigationv2/config/screenOptions";
import Screens from "@/screens";

const Stack = createStackNavigator<ExternalDoctorFormRoutes>();

const DoctorVisitsStack = () => {
  return (
    <Stack.Navigator screenOptions={screenOptions}>
      <Stack.Screen name="MHAExternalFormScreen" component={Screens.MHAExternalFormScreen} />
    </Stack.Navigator>
  );
};

export default React.memo(DoctorVisitsStack);
