import React from "react";
import { StyleSheet, View } from "react-native";
import { Dayjs } from "dayjs";

import Text from "../../Text";
import { InfoItemLabel } from "../../InfoItem";

import { TypographyType } from "@/models/Typography";
import { Forms } from "libs/mhaForms/formSections";
import { palette, spacing } from "../../../theme";
import { FormType } from "libs/types/mhaForms";
import { getMhaFormVersion } from "libs/mhaForms/helpers";
import ENV from "@/config";

interface AttachableFormItemProps {
  form: {
    id: string;
    formType: FormType;
    patientName: string;
    doctorName: string;
    examinationDate?: Dayjs;
    createdAt?: string;
  };
}

const AttachableFormItem: React.FC<AttachableFormItemProps> = ({ form }) => {
  if (!form) {
    return <></>;
  }

  const mhaFormVersion = getMhaFormVersion(ENV.ENV, form.createdAt);
  const formInfo = Forms[form.formType](mhaFormVersion);
  return (
    <View key={form.id} style={styles.bottomStyle}>
      <Text format={TypographyType.RegularBold} color={palette.navy}>
        {form.patientName}
      </Text>

      <View style={styles.subHeading}>
        <Text format={TypographyType.TinyBold} color={palette.navy}>{`Section ${formInfo.section} `}</Text>
        <Text
          format={TypographyType.MicroBold}
          color={palette.navy}
          style={styles.formType}
        >{`(${form.formType})`}</Text>
      </View>

      <View style={[styles.row, styles.flex]}>
        <View style={styles.flex}>
          <InfoItemLabel label="Doctor" format={TypographyType.Tiny}>
            {form.doctorName}
          </InfoItemLabel>
        </View>
        {form.examinationDate && (
          <View style={styles.flex}>
            <InfoItemLabel label="Examination Date" format={TypographyType.Tiny}>
              {form.examinationDate.format("DD/MM/YYYY")}
            </InfoItemLabel>
          </View>
        )}
      </View>
    </View>
  );
};

export default AttachableFormItem;

const styles = StyleSheet.create({
  row: { flexDirection: "row" },

  flex: { flex: 1 },

  formType: {
    transform: [{ translateY: -1 }],
  },

  subHeading: {
    flexDirection: "row",
    alignItems: "flex-end",
    marginBottom: spacing[5],
  },

  bottomStyle: {
    paddingBottom: spacing[20],
    borderBottomColor: palette.cloud,
    borderBottomWidth: 1,
  },
});
