import React from "react";
import { graphql, ChildDataProps, ApolloConsumer } from "react-apollo";

import { DOCTOR_AND_MHT, MHTItem } from "@/models/DoctorProfile";
import { getPostcodeData } from "@/models/Location";

import DoctorSetupForm from "./DoctorSetupForm";
import { GetDoctorAndMhtResponse } from "./DoctorSetupForm.props";
import { ErrorMessage } from "../Error/Error";
import Loading from "../Loading";

interface Variables {
  username: string;
}

const builder = graphql<Variables, Response, unknown, ChildDataProps<unknown, GetDoctorAndMhtResponse, unknown>>(
  DOCTOR_AND_MHT,
  {
    options: (props: Variables) => ({
      variables: {
        id: props.username,
        limit: 100,
      },
    }),
  }
);

export default builder(({ data, ...props }) => {
  const { loading, error, doctor, mhts } = data;

  if (error) {
    return <ErrorMessage apolloError={error} />;
  }

  if (loading) {
    return <Loading />;
  }

  if (doctor && mhts) {
    const doctorItem = doctor;
    const mhtItems = mhts.items
      .map((m: MHTItem) => ({
        id: m.id,
        abbreviation: m.abbreviation,
        name: m.organisation.name,
      }))
      .sort((a: { abbreviation: string }, b: { abbreviation: string }) => (a.abbreviation > b.abbreviation ? 1 : -1));

    return (
      <ApolloConsumer>
        {client => {
          return <DoctorSetupForm getPostcode={getPostcodeData(client)} doctor={doctorItem} mhts={mhtItems} />;
        }}
      </ApolloConsumer>
    );
  }

  return <ErrorMessage />;
});
