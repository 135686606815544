import React, { useState, useEffect } from "react";
import { StyleSheet, Keyboard } from "react-native";
import { FAB, Portal } from "react-native-paper";

import { AssessmentItem } from "@/models/Assessment";
import { palette } from "../../../theme";

interface AssessmentFabProps {
  doctorVisits: AssessmentItem["doctorVisits"];
  duplicate: () => void;
  editMode: boolean;
  fabGroupVisible: boolean;
  formInValid: boolean;
  saveOnPress: () => void;
  setEditMode: (state: boolean) => void;
  setFabGroupVisible: (state: boolean) => void;
  setConfirmDeletion: (state: boolean) => void;
  forms: AssessmentItem["forms"];
  cancelOnPress: () => void;
  editPress: () => void;
}

const AssessmentFAB: React.FC<AssessmentFabProps> = ({
  doctorVisits,
  duplicate,
  editMode,
  fabGroupVisible,
  formInValid,
  saveOnPress,
  setEditMode,
  setFabGroupVisible,
  setConfirmDeletion,
  forms,
  cancelOnPress,
  editPress,
}) => {
  const [fabGroupOpen, setFabGroupOpen] = useState(false);

  const fabGroupActions = [
    {
      icon: "edit",
      label: "Edit Assessment",
      color: palette.navy,
      style: styles.aquaMiniFab,
      onPress: () => {
        setFabGroupVisible(false);
        setEditMode(true);
        editPress();
      },
    },
    {
      icon: "content-copy",
      label: "Duplicate Assessment",
      color: palette.white,
      style: styles.blueMiniFab,
      onPress: duplicate,
    },
  ];

  /**
   * Keyboard listener to detect if the keyboard is open
   * If open, we'll save the state and use this to update a custom style
   * moving the buttons over the top of the keyboard
   */
  const [didKeyboardShow, setKeyboardShow] = useState(false);
  const [keyboardHeight, setKeyboardHeight] = useState(20);

  useEffect(() => {
    Keyboard.addListener("keyboardDidShow", _keyboardDidShow);
    Keyboard.addListener("keyboardDidHide", _keyboardDidHide);

    return () => {
      Keyboard.removeListener("keyboardDidShow", _keyboardDidShow);
      Keyboard.removeListener("keyboardDidHide", _keyboardDidHide);
    };
  }, []);

  const _keyboardDidShow = (e: any) => {
    setKeyboardShow(true);
    setKeyboardHeight(e.endCoordinates.height);
  };
  const _keyboardDidHide = () => {
    setKeyboardShow(false);
  };
  // end keyboard listener code

  // Delete button view actions
  const isFormsAttached = forms && forms.items && forms.items.length > 0;
  const isClaimsCreated =
    doctorVisits && doctorVisits.items && doctorVisits.items.filter(item => item.claim).length > 0;
  const isNoDeleteActions = isFormsAttached || isClaimsCreated;

  return (
    <Portal>
      <FAB.Group
        open={fabGroupOpen}
        icon={fabGroupOpen ? "close" : "more-vert"}
        fabStyle={styles.fab}
        color={palette.navy}
        visible={fabGroupVisible}
        actions={
          isNoDeleteActions
            ? fabGroupActions
            : [
                ...fabGroupActions,
                {
                  icon: "remove",
                  label: "Delete Assessment",
                  color: palette.white,
                  style: styles.redMiniFab,
                  onPress: () => setConfirmDeletion(true),
                },
              ]
        }
        onStateChange={({ open }) => setFabGroupOpen(open)}
      />
      <FAB
        visible={editMode}
        icon="clear"
        label="Save Changes"
        disabled={formInValid}
        color={palette.white}
        style={[
          styles.editSaveBtn,
          formInValid ? styles.editSaveDisabledBtn : {},
          { bottom: didKeyboardShow ? keyboardHeight + 10 : 80 },
        ]}
        onPress={saveOnPress}
      />
      <FAB
        visible={editMode}
        icon="clear"
        label="Cancel"
        color={palette.white}
        style={[styles.editCancelBtn, { bottom: didKeyboardShow ? keyboardHeight - 50 : 20 }]}
        onPress={() => {
          setEditMode(false);
          setFabGroupVisible(true);
          cancelOnPress();
        }}
      />
    </Portal>
  );
};

const styles = StyleSheet.create({
  editCancelBtn: {
    position: "absolute",
    right: 20,
    bottom: 20,
    backgroundColor: palette.yellow,
  },

  editSaveBtn: {
    position: "absolute",
    right: 20,
    bottom: 80,
    backgroundColor: palette.blue,
  },

  editSaveDisabledBtn: {
    backgroundColor: palette.blueFaded,
  },

  fab: {
    backgroundColor: palette.yellow,
  },

  aquaMiniFab: { backgroundColor: palette.aqua },
  blueMiniFab: { backgroundColor: palette.blue },
  redMiniFab: { backgroundColor: palette.red },
});

export default AssessmentFAB;
