import { FormStatus } from "libs/types/API";
import { FormCategory, UserTypeWhoCanModifyForms } from "libs/types/mhaForms";
import { validFormStatusTransitions } from "libs/mhaForms/validFormStatusTransitions";

export function canUserUpdateFormStatus(
  userType: UserTypeWhoCanModifyForms,
  formCategory: FormCategory,
  formCurrentStatus: FormStatus,
  formNextStatus: FormStatus
): boolean {
  const statusGroup = validFormStatusTransitions[formCategory][formCurrentStatus];
  const nextStatusSet = (!!statusGroup && statusGroup[formNextStatus]) || [];

  return nextStatusSet.includes(userType);
}
