import React from "react";
import { StyleSheet, View } from "react-native";
import { Dayjs } from "dayjs";

import { TypographyType } from "@/models/Typography";
import { mqWeb } from "../../../utils/helpers";

import Icon from "../../Icon";
import Text from "../../Text";
import { palette, spacing } from "../../../theme";

interface CardTimesProps {
  from: Dayjs;
  to?: Dayjs; // This is optional to support away "all day", which doesn't require a "to" time
  color: string;
  showDates?: boolean;
  reverseIcons?: boolean; // Used for "un-availabilities"; i.e. when "to" is the time their availability resumes
}

interface TimeProps {
  time: Dayjs;
  color: string;
  label: "From" | "To";
  icon: string;
  isWebView: boolean;
  showDate?: boolean;
}

const Time = (props: TimeProps) => {
  return (
    <View style={props.label === "From" && props.isWebView ? styles.fromWeb : styles.fromMobile}>
      <View style={styles.timeLabel}>
        <Icon name={props.icon} size={16} color={props.color} style={styles.timeIcon} />
        <Text format={TypographyType.Micro} color={palette.greyBlue}>
          {`${props.label}:`}
        </Text>
      </View>
      <View style={styles.time}>
        <Text format={TypographyType.HeadingSmall}>{props.time.format("h:mm")}</Text>
        <Text format={TypographyType.RegularBold}> {props.time.format("a")}</Text>
      </View>
      {props.showDate && (
        <Text format={TypographyType.TinyBold} marginTop={2}>
          {props.time.format("DD/MM/YYYY")}
        </Text>
      )}
    </View>
  );
};

const CardTimes: React.FC<CardTimesProps> = props => {
  const isWebView = mqWeb();

  const fromIcon = props.reverseIcons ? "alarm-off" : "alarm-on";
  const toIcon = props.reverseIcons ? "alarm-on" : "alarm-off";

  return (
    <View style={styles.times}>
      <Time
        time={props.from}
        color={props.reverseIcons ? palette.greyBlue : props.color}
        label="From"
        icon={fromIcon}
        showDate={props.showDates}
        isWebView={isWebView}
      />

      {props.to && (
        <Time
          time={props.to}
          color={props.reverseIcons ? props.color : palette.greyBlue}
          label="To"
          icon={toIcon}
          showDate={props.showDates}
          isWebView={isWebView}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  fromMobile: { marginRight: spacing[35] },
  fromWeb: { marginRight: spacing[50] },

  time: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-end",
  },

  times: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },

  timeIcon: {
    marginRight: spacing[5],
  },

  timeLabel: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: spacing[5],
  },
});

export default CardTimes;
