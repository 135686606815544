import React, { useMemo } from "react";
import { Dimensions, Text as RNText } from "react-native";
import { Text as RNPText } from "react-native-paper";

import { TypographyType, TypographyProps } from "@/models/Typography";
import { spacing, palette, typography } from "../../theme";
import { reducedSizes, standardSizes } from "./fontSettings";

const boldTypes = [
  TypographyType.HeadingLarge,
  TypographyType.HeadingMedium,
  TypographyType.HeadingSmall,
  TypographyType.ButtonText,
  TypographyType.RegularBold,
  TypographyType.SmallBold,
  TypographyType.TinyBold,
  TypographyType.MicroBold,
];

const Text: React.FC<TypographyProps> = props => {
  const { align, format, children, color, marginTop, marginBottom, type = "native", error, ...restProps } = props;

  const deviceWidth = Dimensions.get("window").width;
  const bold = boldTypes.includes(format);

  const styles = useMemo(
    () => [
      deviceWidth <= 320 ? reducedSizes[format] : standardSizes[format],
      {
        fontFamily: format === TypographyType.Brand ? typography.secondary.medium : bold ? "Lato-Bold" : "Lato-Regular",
      },
      { textAlign: align || "left" },
      { color: error ? palette.red : color || palette.navy },
      { marginTop: marginTop === 0 ? 0 : marginTop },
      {
        marginBottom: marginBottom === 0 ? 0 : format === TypographyType.HeadingMedium ? spacing[20] : marginBottom,
      },
      props.style,
    ],
    [deviceWidth, color, marginTop, marginBottom, props.style]
  );

  return type === "paper" ? (
    <RNPText {...restProps} style={styles}>
      {children}
    </RNPText>
  ) : (
    <RNText {...restProps} style={styles}>
      {children}
    </RNText>
  );
};

export default React.memo(Text);
