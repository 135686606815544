import React from "react";
import { RouteKeys } from "@/navigationv2";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { AllAMHPRoutes } from "@/navigationv2/types";

import { MHAFormRecord } from "../Forms/MHAFormRecord";

import { Forms } from "libs/mhaForms/formSections";
import { OfflineMHAFormData } from "../../utils/recoil/props";
import { formatShortDateTime } from "libs/dates";
import { getMhaFormVersion } from "libs/mhaForms/helpers";
import ENV from "@/config";
interface UnsavedMHAFormRecordProps {
  offlineForm: OfflineMHAFormData;
  assessmentId: string;
  clearOfflineForm: () => void;
}

const UnsavedMHAFormRecord: React.FC<UnsavedMHAFormRecordProps> = ({ offlineForm, assessmentId, clearOfflineForm }) => {
  const navigation = useNavigation<StackNavigationProp<AllAMHPRoutes>>();
  const mhaFormVersion = getMhaFormVersion(ENV.ENV);
  const form = Forms[offlineForm.type](mhaFormVersion);
  return (
    <MHAFormRecord
      formName={`Section ${form.section}@${offlineForm.type}`}
      formTitle={form.shortTitle}
      isArchived={false}
      historyElements={
        offlineForm?.metadata.notes || [`Form has unsaved changes (${formatShortDateTime(offlineForm?.createdAt)})`]
      }
      ctaButtons={[
        {
          label: "View Form",
          onClick: () =>
            navigation.push(RouteKeys.MHAFormScreen, {
              assessmentId,
              formType: offlineForm.type,
              formId: offlineForm?.metadata.id || "unsaved",
            }),
        },
        {
          label: "Discard Changes",
          onClick: clearOfflineForm,
        },
      ]}
      lastElement
    />
  );
};

export default UnsavedMHAFormRecord;
