import * as Yup from "yup";
import createPostcodeSchema from "libs/validators/yup/createPostcodeSchema";
import { ComponentElement, FormFieldsAmhp } from "libs/types/mhaForms";

const componentElements: ComponentElement[] = [
  {
    component: "Text",
    componentProps: {
      instruction: "To the managers of",
      guidance: "(name and address of hospital)",
    },
    fullWidth: true,
  },
  {
    component: "TextInput",
    fieldName: FormFieldsAmhp.HospitalName,
    componentProps: {
      label: "Hospital Name",
    },
  },
  {
    component: "Location",
    fieldName: FormFieldsAmhp.HospitalAddress,
    componentProps: {
      label: "Hospital Address",
    },
  },
];

export default {
  title: "Hospital Details",

  state: {
    hospitalName: "",
    hospitalAddress: {
      city: "",
      postcode: "",
      address: "",
    },
  },

  validation: Yup.object().shape({
    hospitalName: Yup.string()
      .trim()
      .required("Please enter the hospital name")
      .min(3, "Hospital name must be at least 3 characters in length"),
    hospitalAddress: Yup.object().shape({
      city: Yup.string(),
      postcode: createPostcodeSchema({
        invalidPostcodeMessage: "Please enter a valid address",
        required: true,
        requiredMessage: "Please enter an address",
      }),
      address: Yup.string(),
    }),
  }),

  componentElements,
};
