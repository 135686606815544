import { DefaultTheme } from "react-native-paper";
import { palette } from "./palette";

function asStrings<T extends { [key: string]: string }>(arg: T): T & { [key: string]: string } {
  return arg;
}

export const colorList = asStrings({
  ...DefaultTheme.colors,
  transparent: "rgba(0, 0, 0, 0)",
  available: palette.aqua,
  availableBorder: palette.aquaFaded,
  uncontactable: palette.red,
  uncontactableBorder: palette.redFaded,
  unavailable: palette.greyBlue,
  unavailableBorder: palette.greyBlue,
  hidden: palette.red,
  hiddenBorder: palette.redFaded,
  away: palette.red,
  awayBorder: palette.redFaded,
  noInformation: palette.grey,
  noInformationBorder: palette.greyBlue,
  trust: palette.aqua,
  trustBorder: palette.aquaFaded,
  oncall: palette.blue,
  oncallBorder: palette.blueFaded,
  independent: palette.purple,
  independentBorder: palette.purpleFaded,
  background: palette.white,
  backgroundGrey: palette.cloud,
  backgroundGreyDark: palette.grey,
  textExtraLight: palette.greyBlue,
  textLight: palette.slate,
  text: palette.navy,
  textWhite: palette.white,
  textError: palette.red,
  icon: palette.white,
  primary: palette.blue,
  sky: palette.blueSecondary,
  primaryFaded: palette.blueFaded,
  primaryBorder: palette.blueFaded,
  primaryBorderFrosted: palette.blueFaded,
  primaryShadow: palette.blueShadow,
  placeholder: palette.greyBlue,
  secondary: palette.aqua,
  secondaryBorder: palette.aquaFaded,
  thinBorder: palette.grey,
  thinBorderLight: palette.cloud,
  indicators: palette.purple,
  disabled: palette.grey,
  disabledBorder: palette.cloud,
  booking: palette.orange,
  bookingBorder: palette.orangeFaded,
  inAssessment: palette.yellow,
  inAssessmentBorder: palette.yellowFaded,
  doctor: palette.yellow,
  doctorBorder: palette.yellowFaded,
  disabledText: palette.greyBlue,
  boxShadow: palette.shadow,
  darken: "rgba(0,0,0,0.1)",
  claimProcessing: palette.blue,
  claimActionRequired: palette.yellow,
  claimApproved: palette.aqua,
  claimRejected: palette.red,
  claimOnHold: palette.yellow,
  claimPaid: palette.purple,
  linkBorder: "rgba(255,255,255,0.1)",
  linkBorderActive: "rgba(255,255,255,0.125)",
  // These are edge case colours for the logo border on splash-screen / auth flow screens;
  // Not to be used anywhere else
  logoAuthOuterBorder: "#0379CB",
  logoAuthInnerBorder: "#0996CB",
  MHAFormPink: palette.mhaFormPink,
  MHAFormBlue: "#ECF6FF",
  rippleWhite: "rgba(255,255,255,1)",
  rippleWhiteOpacity: "rgba(255,255,255,0.15)",
});

export const color = colorList;
export default colorList;

export const convertColor = (input: string) => {
  return input.length && Object.prototype.hasOwnProperty.call(color, input) ? color[input] : input;
};
export const convertBorder = (input: string) => {
  return input.length && Object.prototype.hasOwnProperty.call(color, input + "Border")
    ? color[input + "Border"]
    : input + "66";
};
