/* eslint react/prop-types: 0 complexity: 0 */
import dayjs, { Dayjs } from "dayjs";
import React, { useEffect, useState, useMemo, useRef } from "react";
import { StyleSheet, View, Linking } from "react-native";
import { Switch } from "react-native-paper";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useMutation } from "@apollo/react-hooks";
import { ParamListBase, useNavigation } from "@react-navigation/native";

import { CREATE_CONTACTED_DOCTORS, GET_ASSESSMENT } from "@/models/Assessment";
import { DELETE_DOCTOR_VISIT } from "@/models/DoctorProfile";
import { LocationInput } from "libs/types/API";

import { TypographyType } from "@/models/Typography";
import { color, spacing, palette } from "../../theme";
import { mqWeb } from "../../utils/helpers";
import { Button } from "../Button";
import { ContentWrap } from "../ContentWrap";
import { removeVisitError, buildErr } from "@/models/Error";
import Text from "../Text";
import { RouteKeys } from "@/navigationv2";
import { recordEvent } from "../../utils/analytics";
import { AnalyticsEvent } from "libs/analytics/events";

import { lastException, snackbarMessage, userDetails } from "../../utils/recoil/index";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { SearchResultItem } from "./SearchResults.props";

import Mod from "./modules";
import { StackNavigationProp } from "@react-navigation/stack";

const switchTrackColour = {
  false: palette.grey,
  true: palette.aquaFaded,
};

const sortByAvailabilityAndDistance = (a: SearchResultItem, b: SearchResultItem) => {
  const calculateScore = (item: SearchResultItem) =>
    item.isAddedToAssessment
      ? 1
      : item.nextAvailability && item.nextAvailability.availability.oncall
      ? 2
      : item.nextAvailability
      ? 3
      : !item.nextAvailability && (item.onHoliday?.isPartial || item.booked?.partiallyBooked)
      ? 4
      : !item.nextAvailability && item.availabilities.length > 0
      ? 5
      : !item.nextAvailability && item.onHoliday?.isAway
      ? 6
      : !item.nextAvailability && item.booked?.isBooked
      ? 7
      : !item.nextAvailability && item.onHoliday?.availability
      ? 8
      : 9;

  const aDoctorScore = calculateScore(a);
  const bDoctorScore = calculateScore(b);

  if (aDoctorScore !== bDoctorScore) {
    return aDoctorScore - bDoctorScore;
  }

  return a.distance - b.distance;
};

const LOCAL_SHOW_NOTES_IN_SEARCH_RESULTS = "LOCAL_SHOW_NOTES_IN_SEARCH_RESULTS";

export function SearchResultList(props: {
  results?: SearchResultItem[] | null;
  assessmentId?: string;
  assessmentDoctorVisit?: { id: string; doctor: { id: string } }[];
  assessmentDoctorIds?: string[];
  selectedItems: SearchResultItem[];
  toggleSelected: (item: SearchResultItem) => void;
  onPressAction: string;
  mainLocation: LocationInput;
  assessmentDistance: number;
  timeSpan: { start: Dayjs; end: Dayjs };
  ignoreDefaultSort: boolean;
}): JSX.Element {
  const navigation = useNavigation<StackNavigationProp<ParamListBase>>();
  const [addContactedDoctor] = useMutation(CREATE_CONTACTED_DOCTORS);
  const [deleteDoctorVisit] = useMutation(DELETE_DOCTOR_VISIT);
  const user = useRecoilValue(userDetails);
  const setMessage = useSetRecoilState(snackbarMessage);
  const setLastException = useSetRecoilState(lastException);
  const [showNotesInResults, setShowNotesInResults] = useState<boolean>(true);
  const firstRender = useRef<boolean>(true);

  useEffect(
    function updateUserShowNotesPreference() {
      if (firstRender.current) {
        firstRender.current = false;
        return;
      }

      AsyncStorage.setItem(LOCAL_SHOW_NOTES_IN_SEARCH_RESULTS, showNotesInResults ? "true" : "false");
    },
    [showNotesInResults]
  );

  useEffect(() => {
    async function setUserShowNotesPreferenceOnMount() {
      const showNotesPreference = await AsyncStorage.getItem(LOCAL_SHOW_NOTES_IN_SEARCH_RESULTS);
      if (showNotesPreference !== null) {
        setShowNotesInResults(showNotesPreference === "true");
      }
    }
    setUserShowNotesPreferenceOnMount();
  }, []);

  const addDoctorVisit = (doctorId: string) =>
    navigation.navigate(RouteKeys.ProfileScreen, {
      id: doctorId,
      assessmentId: props.assessmentId,
      assessmentLocationLat: props.mainLocation.lat,
      assessmentLocationLon: props.mainLocation.lon,
      assessmentDistance: props.assessmentDistance,
      assessmentStartTime: props.timeSpan.start.toISOString(),
      assessmentEndTime: props.timeSpan.end.toISOString(),
      addVisit: true,
    });

  const removeDoctorVisit = (visitId: string, doctorName: string) =>
    deleteDoctorVisit({
      variables: {
        id: visitId,
      },
      refetchQueries: [
        {
          query: GET_ASSESSMENT,
          variables: { id: props.assessmentId },
        },
      ],
    })
      .then(() => {
        navigation.navigate(RouteKeys.AssessmentDetailsScreen, {
          assessmentId: props.assessmentId,
        });
        setMessage(`${doctorName} removed`);
      })
      .catch(buildErr(removeVisitError, setLastException))
      .then(() => undefined);

  const sortResults = useMemo(() => {
    if (!props.results) return [];
    return props.ignoreDefaultSort ? props.results : props.results.map(r => r).sort(sortByAvailabilityAndDistance);
  }, [props.ignoreDefaultSort, props.results]);

  const contactDoctor = (
    doctorId: string,
    type: "sms" | "tel",
    phoneNumber: string,
    assessmentId: string,
    item: SearchResultItem
  ) => {
    addContactedDoctor({
      variables: {
        input: {
          assessmentId: assessmentId,
          contactedDoctorS12DoctorId: doctorId,
          createdAt: new Date().toISOString(),
        },
      },
      refetchQueries: [
        {
          query: GET_ASSESSMENT,
          variables: { id: assessmentId },
        },
      ],
    });
    recordEvent(
      AnalyticsEvent.DOCTORS_CONTACTED,
      {
        doctorId,
        ...(item.name && { doctorName: item.name }),
        ...(user && {
          amhpId: user.id,
          amhpName: user.name,
          amhpEmail: user.email,
        }),
        ...(item.nextAvailability?.availability.type && {
          doctorAvailability: item.nextAvailability?.availability.type,
        }),
        assessmentId: assessmentId || "unknown",
        method: type === "tel" ? "phone" : type,
      },
      {
        "Doctors Contacted": 1,
      }
    );
    Linking.openURL(`${type}:` + phoneNumber).catch(() => null);
  };

  const isWebView = mqWeb();
  const SearchResultsWrapper = isWebView ? ContentWrap : View;

  return (
    <>
      {props.results && Boolean(props.results.length) && (
        <ContentWrap style={doctorStyles.showResultsToggleContainer}>
          <View style={doctorStyles.showResultsToggleRow}>
            <Text format={TypographyType.Tiny} color={palette.navy}>
              Show doctor notes in results
            </Text>
            <Switch
              style={doctorStyles.notesSwitch}
              value={showNotesInResults}
              trackColor={switchTrackColour}
              onValueChange={() => setShowNotesInResults(!showNotesInResults)}
            />
          </View>
        </ContentWrap>
      )}
      <View style={[doctorStyles.searchResultsContainer, isWebView && doctorStyles.searchResultsContainerWeb]}>
        <SearchResultsWrapper>
          <View
            style={[
              // eslint-disable-next-line
              {
                marginBottom:
                  props.onPressAction === "navigate" ? 0 : props.selectedItems.length > 0 ? spacing[50] : spacing[30],
              },
              doctorStyles.content,
              isWebView && doctorStyles.contentWeb,
            ]}
          >
            {!props.results ||
              (props.results.length === 0 && (
                <ContentWrap style={doctorStyles.noResultsSection}>
                  <Text format={TypographyType.Small} style={doctorStyles.noResultsText}>
                    No doctors found that match your criteria, please consider modifying your search
                  </Text>
                  <Button
                    onPress={() =>
                      navigation.navigate(RouteKeys.CreateAssessmentScreen, {
                        assessmentId: props.assessmentId,
                        editDoctor: false,
                      })
                    }
                  >
                    Modify Search
                  </Button>
                </ContentWrap>
              ))}

            {sortResults.map((item: SearchResultItem) => {
              let functionProps = {};
              if (props.assessmentId && item.phone) {
                functionProps = {
                  onCallDoctor: () =>
                    contactDoctor(item.id, "tel", item.phone as string, props.assessmentId || "Unknown", item),
                  onTextDoctor: () =>
                    contactDoctor(item.id, "sms", item.phone as string, props.assessmentId || "Unknown", item),
                };
              }
              const visit =
                props.assessmentDoctorVisit && props.assessmentDoctorVisit.find(dv => dv.doctor.id === item.id);
              const visitId = visit ? visit.id : "";

              const hasPhoneNumber = !!item.phone;

              return (
                <View key={item.id}>
                  <Mod.DoctorSearchItem
                    {...functionProps}
                    showNotes={showNotesInResults}
                    addDoctorVisit={addDoctorVisit}
                    visitId={visitId}
                    removeDoctorVisit={removeDoctorVisit}
                    displayType={"default"}
                    availability={item.nextAvailability}
                    availabilityRotas={item.availabilityRotas}
                    isDoctorAvailable={!!item.nextAvailability}
                    {...item}
                    locationName={item.locationName.postcode}
                    onPress={() => {
                      if (props.onPressAction === "navigate") {
                        navigation.navigate(RouteKeys.ProfileScreen, {
                          id: item.id,
                          assessmentId: props.assessmentId,
                          assessmentLocationLat: props.mainLocation.lat,
                          assessmentLocationLon: props.mainLocation.lon,
                          assessmentDistance: props.assessmentDistance,
                          assessmentStartTime: props.timeSpan.start.toISOString(),
                          assessmentEndTime: props.timeSpan.end.toISOString(),
                        });
                      } else {
                        if (hasPhoneNumber) {
                          props.toggleSelected(item);
                        }
                      }
                      return false;
                    }}
                    onPressAction={props.onPressAction}
                    isSelected={props.selectedItems.findIndex(x => x.id === item.id) > -1}
                    hasPhoneNumber={hasPhoneNumber}
                    onHoliday={item.onHoliday}
                    booked={item.booked}
                    timeSpan={props.timeSpan}
                  />
                </View>
              );
            })}
          </View>
        </SearchResultsWrapper>
      </View>
    </>
  );
}

export function SearchResultsLoading(): JSX.Element {
  const isWebView = mqWeb();
  const Wrapper = React.useMemo(() => (isWebView ? ContentWrap : View), [isWebView]);
  return (
    <Wrapper>
      {[0, 1, 2, 3].map((i: number) => (
        <Mod.DoctorSearchItem
          mhtEmployers={[]}
          availabilities={[]}
          isDoctorAvailable={false}
          key={i}
          locationName={"\u2586\u2586\u2586\u2586\u2586\u2586\u2586\u2586"}
          isSelected={false}
          name={"\u2586\u2586\u2586\u2586\u2586\u2586 \u2586\u2586\u2586\u2586\u2586\u2586\u2586\u2586"}
          distance={0}
          id={i.toString()}
          availability={undefined}
          isUnknown={true}
          hasPhoneNumber={false}
          onHoliday={{
            isAway: false,
            isPartial: false,
            availability: null,
          }}
          booked={{
            isBooked: false,
            partiallyBooked: false,
          }}
          timeSpan={{
            start: dayjs(),
            end: dayjs(),
          }}
        />
      ))}
    </Wrapper>
  );
}

const doctorStyles = StyleSheet.create({
  content: {
    flex: 1,
    justifyContent: "center",
    marginHorizontal: spacing[10],
  },
  contentWeb: {
    justifyContent: "center",
    marginHorizontal: 0,
  },

  noResultsSection: {
    paddingTop: spacing[20],
    paddingBottom: spacing[15],
  },

  noResultsText: {
    textAlign: "center",
    color: color.textLight,
    marginBottom: spacing[30],
  },

  searchResultsContainer: {
    paddingTop: spacing[10],
    backgroundColor: color.backgroundGrey,
  },
  searchResultsContainerWeb: {
    paddingTop: 0,
    backgroundColor: color.textWhite,
  },

  showResultsToggleContainer: {
    borderTopWidth: 1,
    marginTop: spacing[10],
    paddingVertical: spacing[5],
    borderTopColor: palette.cloud,
  },

  showResultsToggleRow: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  notesSwitch: {
    marginLeft: spacing[30],
  },
});
