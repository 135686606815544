/* eslint-disable react-native/no-inline-styles */
/* eslint-disable react-native/no-unused-styles */
/* ^ This is set because styles are selected dynamically, based on a prop; lack of explicit reference of styles causes a false "no-unused-styles" warning, hence the disable flag above. */
import React, { useState } from "react";
import {
  Animated,
  Dimensions,
  Platform,
  SafeAreaView,
  ScrollView,
  StyleSheet,
  View,
  TouchableWithoutFeedback,
  Keyboard,
} from "react-native";

import BackButton from "../../components/BackButton/BackButton";
import { BackButtonProps } from "../../components/BackButton/BackButton.props";
import { color } from "../../theme";
import { useDeviceWidth, useKeyboard } from "../../utils/helpers";

export enum HeaderScreenTheme {
  Blue = "Blue",
  Aqua = "Aqua",
  Grey = "Grey",
}

interface PropTypes {
  children: React.ReactNode;
  header: React.ComponentType<any>;
  headerProps?: any;
  backButton?: BackButtonProps;
  theme?: HeaderScreenTheme;
  refreshControl?: any;
  passRef?: (ref: any) => void;
  testID?: string;
}

const HeaderScreen = (props: PropTypes) => {
  const { children, theme = HeaderScreenTheme.Blue, testID } = props;
  const Header = props.header;
  const [scrollY, setScrollY] = useState(new Animated.Value(0));
  const scrollView = React.useRef<any>();

  React.useEffect(() => {
    if (props.passRef) props.passRef(scrollView);
  }, []);

  const headerTransform = scrollY.interpolate({
    inputRange: [0, Dimensions.get("window").height / 2],
    outputRange: [0, (scrollY as any)._value * 0.75],
    extrapolate: "clamp",
  });
  const showBackButton = props.backButton && props.backButton.enabled && Platform.OS !== "web";
  if (props.backButton) props.backButton.color = color.textWhite;

  const deviceWidth = useDeviceWidth();
  const keyboard = useKeyboard();
  const contentBorderRadiusAmount = deviceWidth <= 320 ? 25 : 50;
  const Wrapper: React.ComponentType<any> = Platform.OS === "web" ? View : TouchableWithoutFeedback;
  return (
    <View style={[styles.screen, styles[`screen${theme}`]]} testID={`HeaderScreen-${testID}`}>
      <SafeAreaView style={styles.safeAreaView}>
        <ScrollView
          contentContainerStyle={styles.scrollView}
          scrollEventThrottle={16}
          onScroll={(e: any) =>
            e &&
            e.nativeEvent &&
            e.nativeEvent.contentOffset &&
            setScrollY(new Animated.Value(e.nativeEvent.contentOffset.y))
          }
          refreshControl={props.refreshControl}
          keyboardShouldPersistTaps="always"
          keyboardDismissMode="none"
          ref={scrollView}
          alwaysBounceVertical={!!props.refreshControl}
          bounces={!!props.refreshControl}
          bouncesZoom={!!props.refreshControl}
          directionalLockEnabled={true}
          testID="HeaderScreen-ScrollView"
        >
          {showBackButton && props.backButton && <BackButton {...props.backButton} style={styles.backButton} />}

          <Wrapper onPress={() => Platform.OS !== "web" && Keyboard.dismiss()} style={styles.headerContainer}>
            <View style={styles.contentWrapper}>
              <View style={styles.headerHidden}>
                <Header {...(props.headerProps || {})} />
              </View>
              <Animated.View style={[styles.header, { transform: [{ translateY: headerTransform }] }]}>
                <Header {...(props.headerProps || {})} />
              </Animated.View>
              <View
                style={[
                  styles.contentHeader,
                  {
                    borderTopLeftRadius: contentBorderRadiusAmount,
                    borderTopRightRadius: contentBorderRadiusAmount,
                  },
                ]}
              />
              <View style={[styles.content, { paddingBottom: keyboard.height }]}>{children}</View>
            </View>
          </Wrapper>
        </ScrollView>
      </SafeAreaView>

      <View style={styles.bottomBackgroundWhite} />
    </View>
  );
};

const styles: { [key: string]: any } = StyleSheet.create({
  bottomBackgroundWhite: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    height: 200,
    backgroundColor: color.background,
  },
  backButton: {
    top: 25,
  },
  contentWrapper: {
    flexBasis: "100%",
    flexGrow: 1,
    flexShrink: 0,
  },
  contentHeader: {
    borderWidth: 0,
    height: 50,
    backgroundColor: color.background,
    zIndex: 1,
  },
  content: {
    flex: 1,
    backgroundColor: color.background,
    shadowOffset: { width: 0, height: -8 },
    shadowColor: color.boxShadow,
    shadowOpacity: 0.15,
    shadowRadius: 8,
  },

  headerContainer: {
    position: "relative",
  },
  headerHidden: { opacity: 0 },
  header: {
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
  },

  safeAreaView: {
    position: "relative",
    flex: 1,
    zIndex: 1,
  },

  screen: { flex: 1 },

  screenBlue: { backgroundColor: color.primary },
  screenAqua: { backgroundColor: color.secondary },
  screenGrey: { backgroundColor: color.backgroundGrey },

  scrollView: {
    flexGrow: 1,
  },
});

export default HeaderScreen;
