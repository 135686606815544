import React from "react";
import { StyleSheet, View } from "react-native";
import dayjs from "dayjs";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";

import { Card, CardContainer } from "@/components/Cardd";
import { EventsList } from "@/components/EventsList";
import Text from "@/components/Text/Text";

import { AssessmentResultItem, DoctorVisitsResponse } from "@/models/Assessment";
import { TypographyType } from "@/models/Typography";
import { AllAMHPRoutes } from "@/navigationv2/types";
import { palette, spacing } from "@/theme";
import { useDeviceWidth } from "@/utils/helpers";

interface PropTypes {
  assessments: AssessmentResultItem[];
  themeColor: string;
}

const AssessmentsCards = (props: PropTypes) => {
  const { assessments, themeColor } = props;
  const deviceWidth = useDeviceWidth();
  const navigation = useNavigation<StackNavigationProp<AllAMHPRoutes>>();

  const Cards = assessments.map((assessment, index) => {
    const hasDoctorVisits = (assessment.doctorVisits?.items?.length || 0) > 0;

    return (
      <Card
        key={index}
        slim={true}
        statusBarColor={themeColor}
        expandableContent={
          hasDoctorVisits ? createExpandableContent.bind(null, themeColor, assessment.doctorVisits?.items) : false
        }
        onPress={() =>
          navigation.navigate("AssessmentDetailsScreen", {
            assessmentId: assessment.id,
          })
        }
      >
        <View style={styles.cardHeadingRow}>
          <Text
            format={TypographyType.RegularBold}
            style={[styles.cardHeading, deviceWidth <= 320 && styles.cardHeadingSmall]}
          >
            {assessment.patientName}
          </Text>
          <Text format={TypographyType.Tiny} style={styles.cardDate}>
            {assessment.assessmentDate ? dayjs(assessment.assessmentDate).format("DD/MM/YYYY") : "Unknown"}
          </Text>
        </View>
      </Card>
    );
  });

  return <CardContainer>{Cards}</CardContainer>;
};

// Removed code from below due to android crash.
// {`${visit.claim ? (visit.claim?.status !== "rejected" ? "(Claim created)" : "") : ""}`}
const createExpandableContent = (themeColor: string, visits: DoctorVisitsResponse[] | null) => (
  <>
    <Text format={TypographyType.Micro} color={palette.greyBlue} style={styles.doctorVisitsLabel}>
      Doctor Visits:
    </Text>
    <EventsList
      events={(visits || []).map((visit, index) => (
        <Text
          key={index}
          format={TypographyType.Tiny}
          style={styles.cardContent}
          color={dayjs(visit.time).isBefore(dayjs()) ? palette.slate : palette.navy}
        >
          {`${visit.doctor.name} at ${dayjs(visit.time).format("HH:mm [on] DD/MM/YYYY")} ${
            visit.claim ? (visit.claim.status !== "rejected" ? "(Claim created)" : "") : ""
          }`}
        </Text>
      ))}
      themeColor={themeColor}
    />
  </>
);

const styles = StyleSheet.create({
  cardContent: {
    flex: 1,
    flexWrap: "wrap",
  },

  cardDate: {},

  cardHeading: {
    flexShrink: 1,
    paddingRight: 24,
  },
  cardHeadingSmall: {
    paddingRight: 14,
  },

  cardHeadingRow: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },

  doctorVisitsLabel: {
    marginBottom: spacing[10],
  },
});

export default AssessmentsCards;
