import React, { useMemo } from "react";
import Loading from "../Loading";
import { Query } from "react-apollo";
import { ErrorMessage } from "../Error/Error";
import { VisitDetails } from "./VisitDetails";
import { VISIT_QUERY, VisitQueryResponse } from "@/models/DoctorProfile";
import { checkOfflineCache } from "../../utils/helpers";

interface PropTypes {
  id: string;
  refreshRequired: boolean;
  setRefreshDone: () => void;
  retrievePatientName: (val: string) => void;
  notificationUpdate?: string;
}

export default function VisitDetailsGQL(props: PropTypes) {
  const variables = useMemo(() => ({ id: props.id }), [props.id]);

  return (
    <Query<VisitQueryResponse> query={VISIT_QUERY} fetchPolicy="cache-and-network" variables={variables}>
      {({ error, data, loading, client, variables, refetch }) => {
        if (props.refreshRequired) {
          setTimeout(() => refetch(), 0);
          props.setRefreshDone();
        }
        const complete = checkOfflineCache(client, VISIT_QUERY, variables);
        if (!complete && error) {
          return <ErrorMessage apolloError={error} />;
        } else if (!complete && loading) {
          return <Loading />;
        } else if (data && data.visit) {
          const { visit } = data;
          props.retrievePatientName(visit.assessment.patientName);
          return <VisitDetails visit={visit} refetch={refetch} />;
        } else {
          return <Loading />;
        }
      }}
    </Query>
  );
}
