import dayjs, { Dayjs } from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { RRule } from "rrule";

import { TimePeriod } from "libs/types/dates";
import { Availability, AvailabilityStateModel, AvailabilityStateController } from "libs/types/availability";
import { EventItem } from "libs/types/holiday";

dayjs.extend(isBetween);

export const convertDateToRRuleFormat = (date: Dayjs, time: { hours: number; minutes: number; seconds?: number }) =>
  `${date.format("YYYYMMDD")}T${time.hours <= 9 ? "0" + time.hours : time.hours}${
    time.minutes <= 9 ? "0" + time.minutes : time.minutes
  }${time.seconds ? (time.seconds <= 9 ? "0" + time.seconds : time.seconds) : "00"}Z`;

// When getting dates out of rrule, they are in utc timezone,
// all dates in the app are based on UTC time, but the browser will try to convert
// to locale time. To deal with this, when getting dates out of the rrule library
// we convert to a local date which will match the UTC date
export const convertRRuleDateTimeToUTCDate = (rruleDate: Date): Date =>
  new Date(
    rruleDate.getUTCFullYear(),
    rruleDate.getUTCMonth(),
    rruleDate.getUTCDate(),
    rruleDate.getUTCHours(),
    rruleDate.getUTCMinutes(),
    1,
    0
  );

export const convertRRuleDateOnlyToUTCDate = (rruleDate: Date): Date =>
  new Date(rruleDate.getUTCFullYear(), rruleDate.getUTCMonth(), rruleDate.getUTCDate(), 0, 0, 1, 0);

export function converDateToDisplayTime(date: Dayjs): string {
  return date.format("HH:mm");
}

export function convertStartEndToDisplayInterval(startDate: Dayjs, endDate: Dayjs): string {
  const startHours = startDate.hour();
  const startMinutes = startDate.minute();
  const endHours = endDate.hour();
  const endMinutes = endDate.minute();

  if (startHours === 0 && (startMinutes === 0 || startMinutes === 1) && endHours === 23 && endMinutes === 59) {
    return "All Day";
  } else {
    return `${converDateToDisplayTime(startDate)} - ${converDateToDisplayTime(endDate)}`;
  }
}

export function convertStartEndToHuman(startDate: Date, endDate: Date): [string, TimePeriod] {
  const now = new Date();

  let resultText = "";
  let resultType = "future" as TimePeriod;

  if (startDate <= now && endDate >= now) {
    resultType = "present";
    resultText = "now ";
  } else {
    if (startDate.getDate() - now.getDate() !== 0) {
      resultText += startDate.getDate() + "/" + (startDate.getMonth() + 1) + " ";
    }

    if (startDate < now && endDate < now) {
      resultType = "past";
    }

    resultText += leftPad(startDate.getHours()) + ":" + leftPad(startDate.getMinutes()) + " ";
  }

  resultText += "to ";
  resultText += leftPad(endDate.getHours()) + ":" + leftPad(endDate.getMinutes());

  return [resultText, resultType];
}

export function convertIntervalToHuman(isoInterval: string): [string, TimePeriod] {
  const [startDate, endDate] = splitIntervalToDates(isoInterval);
  return convertStartEndToHuman(startDate, endDate);
}

export function splitIntervalToDates(isoInterval: string): [Date, Date] {
  const [start, end] = isoInterval.split("/");
  const startDate = new Date(start);
  const endDate = new Date(end);

  return [startDate, endDate];
}

export function leftPad(num: number, size = 2): string {
  let s = String(num);
  while (s.length < size) {
    s = "0" + s;
  }
  return s;
}

export function convertRruleEndDate(date: string) {
  const year: string = date.substr(0, 4);
  const month: string = date.substr(4, 2);
  const day: string = date.substr(6, 2);
  const hours: string = date.substr(9, 2);
  const minutes: string = date.substr(11, 2);

  const isNumeric = [year, month, day, hours, minutes].every((el) => !isNaN(Number(el)));

  return isNumeric ? dayjs(`${year}/${month}/${day}`).hour(Number(hours)).minute(Number(minutes)) : dayjs();
}

export function checkMutationInput(input: any) {
  for (const property in input) {
    if (Object.prototype.hasOwnProperty.call(input, property)) {
      if (typeof input[property] === "string") {
        input[property] = input[property].trim() === "" ? null : input[property].trim();
      }

      if (typeof input[property] === "object") {
        for (const nestedProp in input[property]) {
          if (typeof input[property][nestedProp] === "string") {
            input[property][nestedProp] =
              input[property][nestedProp].trim() === "" ? null : input[property][nestedProp].trim();
          }
        }
      }
    }
  }
  return input;
}

export function setEndOfWeek(day: Dayjs) {
  return day.subtract(1, "day").endOf("week").add(1, "day");
}

function decrementWeekdays(weekdays: string[]) {
  const days = ["MO", "TU", "WE", "TH", "FR", "SA", "SU"];
  return weekdays.map((day) => {
    const index = days.indexOf(day);
    return days[(index || 7) - 1];
  });
}

export function convertAvailabilityToEditForm(availability: Availability) {
  const av = RRule.fromString(availability.rrule).options;
  const date =
    !!availability.overnightElement && availability.overnightElement === 2
      ? dayjs(av.dtstart).subtract(1, "day")
      : dayjs(av.dtstart);
  const startTime = {
    hours: av.byhour[0],
    minutes: av.byminute[0],
  };
  const endHour = availability.endHour.toString();
  const endTime = {
    hours: parseInt(endHour.length === 4 ? endHour.substr(0, 2) : endHour.substr(0, 1)),
    minutes: parseInt(endHour.substr(-2)),
  };
  const repeatWeeklyCount =
    availability.rrule.search("INTERVAL=") > -1 ? availability.rrule.split("INTERVAL=")[1].split(";")[0] : "1";

  const defaultMHT = {
    value: !availability.mht ? "-" : availability.mht.id,
    label: !availability.mht ? "Independent" : availability.mht.organisation.name,
  };
  const defaultOncall = availability.oncall || false;
  const defaultRota = { id: availability.rotaId || "", name: availability.rotaName || "" };
  const defaultNotes = availability.notes || "";
  const defaultPostcode = availability.locationName ? availability.locationName.postcode : "";
  const isSingleItem = availability.rrule.search("FREQ") === -1 ? "false" : "true";
  const eventSeriesEndDate =
    availability.rrule.search("UNTIL") > -1
      ? convertRruleEndDate(availability.rrule.substr(availability.rrule.search("UNTIL") + 6))
      : availability.endDate && availability.endDate.startsWith("3000")
      ? null
      : isSingleItem === "true"
      ? setEndOfWeek(date)
      : null;
  const repeatWeekdays =
    availability.rrule.search("BYDAY=") > -1 ? availability.rrule.split("BYDAY=")[1].split(";")[0].split(",") : [];

  const model: AvailabilityStateModel = {
    availabilities: [
      {
        date,
        startTime,
        endTime,
        hasError: false,
        postcodeError: false,
        overnight: !!availability.overnightElement,
      },
    ],
    defaultMHT,
    defaultOncall,
    defaultRota,
    defaultNotes,
    defaultPostcode,
    repeatWeekdays:
      !!availability.overnightElement && availability.overnightElement === 2
        ? decrementWeekdays(repeatWeekdays)
        : repeatWeekdays,
    eventSeriesEndDate,
    repeatWeeklyCount,
  };

  const controller: AvailabilityStateController = {
    isSingleItem,
    eventSeriesHasEnd: !!eventSeriesEndDate,
    isSaving: false,
    postcodeError: false,
    openErrorModal: false,
    repeatWeekly: isSingleItem === "true" ? "true" : "false",
    activeElements: [],
  };

  const { id, location, locationName } = availability;

  return {
    id,
    location,
    locationName,
    model,
    controller,
  };
}

export function convertHolidayToEditForm(holiday: EventItem) {
  const startHour = holiday.start.hour();
  const startMinutes = holiday.start.minute();
  const endHour = holiday.end.hour();
  const endMinutes = holiday.end.minute();

  const model = {
    holidayStartDate: holiday.start,
    holidayEndDate: holiday.end,
    startTime: {
      hours: startHour,
      minutes: startMinutes,
    },
    endTime: {
      hours: endHour,
      minutes: endMinutes,
    },
    holidayNotes: holiday.notes || null,
    holidayVisibility: holiday.visible,
  };

  const controller = {
    allDayEvent: startHour === 0 && startMinutes === 0 && endHour === 23 && endMinutes === 59,
  };

  return { model, controller };
}
