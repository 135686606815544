/**
 * Input Indicator Icon to display over a user <VideoTile />
 * Indicated whether the relevant input device is on or off (i.e. mic on/off || cam on/off)
 */
import React, { useMemo } from "react";
import { StyleSheet, View } from "react-native";

import Icon from "../../Icon";
import { palette } from "../../../theme";

interface VideoTileInputIndicatorProps {
  device: "mic" | "video";
  deviceActive: boolean;
  audioActive?: boolean;
}

export const VideoTileInputIndicator = React.memo<VideoTileInputIndicatorProps>(
  ({ device, deviceActive, audioActive }) => {
    const MaterialIconName = {
      mic: deviceActive ? "mic" : "mic-off",
      video: deviceActive ? "videocam" : "videocam-off",
    };

    const indicatorBGStyles = useMemo(() => [styles.indicatorBG, deviceActive && styles.indicatorBGActive], [
      deviceActive,
    ]);

    const indicatorStyles = useMemo(() => [styles.indicator, audioActive && styles.indicatorSpeaking], [audioActive]);

    return (
      <View style={indicatorStyles}>
        <Icon size={16} color={palette.white} name={MaterialIconName[device]} />
        <View style={indicatorBGStyles} />
      </View>
    );
  }
);

VideoTileInputIndicator.displayName = "VideoTileInputIndicator";

const styles = StyleSheet.create({
  indicator: {
    position: "relative",
    width: 32,
    height: 32,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 6,
    overflow: "hidden",
  },

  indicatorBG: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: palette.red,
    opacity: 1,
    zIndex: -1,
  },
  indicatorBGActive: {
    backgroundColor: palette.fadedIconBG,
    opacity: 0.5,
  },
  indicatorSpeaking: {
    borderWidth: 2,
    borderColor: palette.aquaFaded,
  },
});
