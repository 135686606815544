import React, { useMemo, useCallback } from "react";
import { TouchableRipple } from "react-native-paper";
import { View } from "react-native";
import dayjs, { Dayjs } from "dayjs";
import ContentWrap from "../../ContentWrap/ContentWrap";
import styles from "./styles";
import {
  ScheduledDoctorsHeading,
  NoScheduledDoctorsText,
  DoctorVisitDetails,
  CardHeader,
  NoContactedDoctorsText,
  ContactedDoctorsHistory,
  TopLevelProps,
  ContactedDoctorsHeading,
} from "./components";
import { DoctorVisitsWithClaimIdResponse } from "@/models/gql/fragments";
import { Card, CardContainer } from "../../Cardd";
import { palette } from "../../../theme/palette";
import Text from "../../Text";

import { ContactedDoctor } from "../AssessmentDetails.props";
import { TypographyType } from "@/models/Typography";

export const DoctorCards = (props: TopLevelProps) => {
  const today = useMemo(() => dayjs(), []);

  return (
    <>
      <ContentWrap style={styles.doctorCardsHeading}>
        <ScheduledDoctorsHeading />
        <NoScheduledDoctorsText isWebView={props.isWebView} hasVisits={props.hasVisits} />
      </ContentWrap>
      {props.hasVisits && (
        <CardContainer>
          {props.visits.map((doctorVisit, index) => (
            <DoctorCard
              key={index}
              doctorVisit={doctorVisit}
              today={today}
              viewProfile={props.viewProfile}
              createClaim={props.createClaim}
            />
          ))}
        </CardContainer>
      )}

      <ContentWrap style={styles.doctorCardsHeading}>
        <ContactedDoctorsHeading today={today} />
        {props.contactedDoctors && props.contactedDoctors.length === 0 && (
          <NoContactedDoctorsText isWebView={props.isWebView} />
        )}
      </ContentWrap>

      <CardContainer>
        {props.contactedDoctors &&
          props.contactedDoctors.length > 0 &&
          props.contactedDoctors.map((d, i) => (
            <ContactedCard
              key={i}
              doctor={d}
              assessmentId={props.assessment.id}
              viewProfile={props.viewProfile}
              contactDoctor={props.contactDoctor}
            />
          ))}
      </CardContainer>
    </>
  );
};

const ContactedCard = (props: {
  doctor: ContactedDoctor;
  assessmentId: string;
  viewProfile: (doctorId: string) => void;
  contactDoctor: (doctorId: string, doctorPhone: string, assessmentId: string) => void;
}) => {
  const viewProfile = useCallback(() => props.viewProfile(props.doctor.id), [props.doctor.id]);
  const contactDoctor = useCallback(
    () =>
      props.doctor.phone ? props.contactDoctor(props.doctor.id, props.doctor.phone, props.assessmentId) : undefined,
    [props.assessmentId, props.doctor.id]
  );
  return (
    <Card
      slim={true}
      statusBarColor={palette.aqua}
      expandableContent={() => <ContactedDoctorsHistory contactedDoctor={props.doctor} />}
      onPress={viewProfile}
    >
      <View style={styles.cardHeadingRow}>
        <Text format={TypographyType.RegularBold} style={styles.cardHeading}>
          {props.doctor.name}
        </Text>
        {props.doctor.phone && (
          <TouchableRipple rippleColor={palette.rippleColor} onPress={contactDoctor}>
            <Text format={TypographyType.MicroBold} color={palette.blue}>
              CALL NOW
            </Text>
          </TouchableRipple>
        )}
      </View>
    </Card>
  );
};

const DoctorCard = (props: {
  doctorVisit: DoctorVisitsWithClaimIdResponse;
  today: Dayjs;
  viewProfile: (doctorId: string) => void;
  createClaim: (visitId: string) => void;
}) => {
  const onPress = useCallback(() => props.createClaim(props.doctorVisit.id), [props.doctorVisit]);
  const viewProfile = useCallback(() => props.viewProfile(props.doctorVisit.doctor.id), [props.doctorVisit.id]);
  return (
    <Card
      slim={true}
      statusBarColor={palette.yellow}
      expandableContent={<DoctorVisitDetails doctorVisit={props.doctorVisit} />}
      buttons={[
        {
          label: props.doctorVisit.doctorVisitClaimId ? "Claim Created" : "Create Claim",
          onPress: onPress,
          disabled: !!props.doctorVisit.doctorVisitClaimId,
        },
      ]}
      onPress={viewProfile}
    >
      <CardHeader
        doctorName={props.doctorVisit.doctor.name}
        visitTime={dayjs(props.doctorVisit.time)}
        today={props.today}
      />
    </Card>
  );
};
