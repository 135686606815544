import React, { forwardRef, useState, useMemo, useCallback } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";

// Modles, helpers, n' ting
import { TileState, TilePosition, TileName, S12HTMLVideoElement } from "../MeetingRoom.types";
import { TypographyType } from "@/models/Typography";
import { palette, spacing } from "../../../theme";

// Components
import Icon, { IconName } from "../../Icon";
import Text from "../../Text";
import { VideoTileInputIndicator } from "./VideoTileInputIndicator";
import { VideoTileCenterUserInfo } from "./VideoTileCenterUserInfo";
import { DeviceVideo } from "./AudioVideo";
import StatusBar, { StatusType } from "./StatusBar";

interface VideoTileProps {
  position: TilePosition;
  displayWaitingForAmhpMessage?: boolean;
  onPress?: (tileName: string) => void;
  tileName: TileName;
  tileState?: TileState;
  isPreview?: boolean;
  videoWidth: number;
  videoHeight: number;
  deviceHeight: number;
  deviceOrientation: "portrait" | "landscape";
}

export const VideoTile = React.memo(
  forwardRef((props: VideoTileProps, ref: React.MutableRefObject<S12HTMLVideoElement | null>) => {
    const [videoFit, setVideoFit] = useState<"contain" | "cover">("contain");

    const videoOrientation = props.videoWidth > props.videoHeight ? "landscape" : "portrait";
    const tileSizeModifier =
      props.deviceOrientation === "portrait"
        ? videoOrientation === "landscape"
          ? 0.1 // landscape video on a portrait screen
          : 0.15 // portrait video on a portrait screen
        : videoOrientation === "landscape"
        ? 0.15 // landscape video on a landscape screen
        : 0.2; // portrait video on a landscape screen

    // no need to memoize as dynamic styles are based on props
    // this is a React.memo, so only re-renders when props change anyway
    const cornerTileHeight = props.deviceHeight * tileSizeModifier;
    const cornerTileWidth = props.deviceHeight * tileSizeModifier * (props.videoWidth / props.videoHeight);

    const videoContainerStyles = [
      styles.videoContainer,
      props.position === TilePosition.center
        ? { width: "100%", height: "100%" }
        : {
            height: cornerTileHeight,
            width: cornerTileWidth,
          },
    ];

    const tileStyles = [
      styles.tile,
      styles[`${props.position}Tile`],
      props.position !== TilePosition.center && { maxWidth: cornerTileWidth },
    ];

    const videoElementStyles: React.CSSProperties = useMemo(
      () => ({
        objectFit: videoFit,
        position: "absolute",
      }),
      [videoFit]
    );

    const _onTilePress = useCallback(() => {
      if (props.onPress) {
        setVideoFit("contain");
        props.onPress(props.tileName);
      }
    }, [props.tileName, props.onPress]);

    const showWaitingForAmhpOverlay = !props.tileState && props.displayWaitingForAmhpMessage && !props.isPreview;

    const connectionIsPoor = props.tileState?.tileState?.poorConnection;

    const videoIsOffOrInactive =
      (props.tileState && !props.tileState.videoElement) ||
      (props.tileState?.tileState?.boundVideoElement &&
        props.tileState?.tileState?.boundVideoStream?.active === false) ||
      props.tileState?.hideVideo;

    const hasUserNameAndIsNotSelf = props.tileState?.userDisplayName && props.position !== TilePosition.self;

    return (
      <TouchableOpacity
        style={tileStyles}
        onPress={_onTilePress}
        disabled={props.position === TilePosition.center || props.position === TilePosition.self}
      >
        <View style={videoContainerStyles}>
          {props.tileState && connectionIsPoor && (
            <View style={styles.poorConnectionWrapper}>
              <StatusBar status={StatusType.SLOW_CONNECTION} />
            </View>
          )}
          <DeviceVideo ref={ref} style={videoElementStyles} />

          {showWaitingForAmhpOverlay && (
            <View style={styles.overlayWrapper}>
              <Icon name={IconName.person} color={palette.white} />
              <Text format={TypographyType.TinyBold} color={palette.white}>
                Waiting for AMHP to join.
              </Text>
            </View>
          )}

          {videoIsOffOrInactive && (
            <View style={styles.overlayWrapper}>
              <Icon name={IconName.videocam_off} color={palette.white} size={40} />
              {connectionIsPoor && (
                <View style={styles.poorConnectionWrapper}>
                  <StatusBar status={StatusType.SLOW_CONNECTION} />
                </View>
              )}
            </View>
          )}
          {props.position === TilePosition.bottom && props.tileState && (
            <View style={styles.cornerTileMicIndicator}>
              <VideoTileInputIndicator device="mic" deviceActive={!props.tileState?.isMuted} />
            </View>
          )}
        </View>

        {hasUserNameAndIsNotSelf && props.position !== TilePosition.center && (
          <View style={styles.nameTag}>
            <Text format={TypographyType.MicroBold} color={palette.navy} style={styles.nameTagText}>
              {props.tileState?.userId && Number(props.tileState?.userId) ? "Dr " : ""}
              {props.tileState?.userDisplayName}
            </Text>
          </View>
        )}

        {hasUserNameAndIsNotSelf && props.position === TilePosition.center && (
          <VideoTileCenterUserInfo
            tileState={props.tileState}
            videoFit={videoFit}
            poorConnection={false}
            onVideoFitToggle={() => setVideoFit(prevFit => (prevFit === "cover" ? "contain" : "cover"))}
          />
        )}
      </TouchableOpacity>
    );
  })
);

enum zindex {
  centerTile = 1,
  cornerTile = 2,
  overlayWrapper = 3,
  poorConnectionWrapper = 4,
}

const styles: { [key: string]: any } = StyleSheet.create({
  // eslint-disable-next-line react-native/no-color-literals
  tile: {
    position: "absolute",
    borderRadius: 5,
    shadowColor: "#000000",
    shadowRadius: 20,
    shadowOpacity: 0.5,
    shadowOffset: { width: 0, height: 0 },
    overflow: "hidden",
  },

  selfTile: {
    right: 15,
    top: 35,
    zIndex: zindex.cornerTile,
  },

  bottomTile: {
    right: 15,
    bottom: 15,
    zIndex: zindex.cornerTile,
  },

  leftTile: {
    left: 15,
    bottom: 15,
    zIndex: -1,
  },

  centerTile: {
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: zindex.centerTile,
  },

  cornerTileMicIndicator: {
    position: "absolute",
    zIndex: 1,
    left: 2,
    bottom: 2,
  },

  nameTag: {
    height: 22,
    paddingLeft: spacing[5],
    overflow: "hidden",
    backgroundColor: palette.yellow,
  },

  nameTagText: {
    transform: [{ translateY: 2 }],
  },

  overlayWrapper: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: palette.shadow,
    zIndex: zindex.overlayWrapper,
    overflow: "hidden",
  },

  poorConnectionIcon: {
    position: "absolute",
    right: 3,
    top: 3,
    width: 28,
    height: 28,
    borderRadius: 4,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: palette.red,
    zIndex: 2,
  },

  videoContainer: {
    position: "relative",
    overflow: "hidden",
  },

  videoLoading: {
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
});

VideoTile.displayName = "VideoTile";
