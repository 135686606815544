import React, { useCallback, useEffect, useState } from "react";
import { Platform } from "react-native";
import { useLinkTo } from "@react-navigation/native";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { useRecoilValue } from "recoil";

import { ErrorMessage } from "@/components/Error";
import { useOnMount } from "@/hooks";
import { HomeRoutes } from "@/navigationv2/types";
import { userDetails } from "@/utils/recoil";
import * as userUtils from "@/utils/user";

import CustomTabBar from "./CustomTabBar";
import * as AMHPTabStacks from "./AMHP";
import * as CCGTabStacks from "./CCG";
import * as DoctorTabStacks from "./Doctor";
import * as SharedTabStacks from "./Shared";

import { previousPathnameState } from "../../state";
import tabBarOptions from "../../config/tabBarOptions";
import tabScreenOptions from "../../config/tabScreenOptions";
import { doctorClaimsTabBadge as recoilDoctorClaimsTabBadge } from "@/utils/recoil/index";

import Loading from "@/components/Loading";
import API from "@/api";
import NewClaimsLiveWrapper from "@/components/NewClaimsWrapper/NewClaimsWrapper";

interface NewClaimsLiveCheckValue {
  live: string;
  title: string;
  text: string;
  link?: string;
}
const useNewClaimsLiveCheck = (): { loading: boolean; value: NewClaimsLiveCheckValue | null } => {
  const [loading, setLoading] = useState<boolean>(true);
  const [value, setValue] = useState<NewClaimsLiveCheckValue | null>(null);

  useEffect(() => {
    const checkVersion = async () => {
      try {
        const result = await API.get("/checkNewClaimsLive");
        setValue(result.data);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    checkVersion();
  }, []);

  return { loading, value };
};

const Tab = createBottomTabNavigator<HomeRoutes>();

/**
 * Build the tab navigation for the app. Since we have three different user
 * types we have to render tabs differently for each of them.
 */
const TabNavigation = () => {
  const user = useRecoilValue(userDetails);
  const doctorClaimsTabBadge = useRecoilValue(recoilDoctorClaimsTabBadge);
  const linkTo = useLinkTo();
  const previousPathname = useRecoilValue(previousPathnameState);
  const { loading, value } = useNewClaimsLiveCheck();

  /**
   * Attempt to redirect the user where they tried to go before they were
   * authenticated. A `setTimeout` is a weird quirk that we need here, as
   * initially React Navigation displays the URL as it's internal version. It
   * takes a split second for this to resolve out to the correct URL and to make
   * sure that everything is mounted as expected.
   *
   * And if it isn't, we don't mind.
   */
  useOnMount(function redirectWebUser() {
    if (previousPathname && Platform.OS === "web") {
      setTimeout(() => {
        try {
          linkTo(previousPathname);
        } catch (e) {
          /**
           * Ignore the potential error. If for whatever reason React Navigation
           * can't get the route from the previous pathname, we don't want the
           * app crashing. It's only a minor inconvenience that we can't
           * redirect the user where they previously wanted to go.
           */
        }
      }, 100);
    }
  });

  const tabBar = useCallback(
    (props: Parameters<typeof CustomTabBar>[0]) => {
      return <CustomTabBar {...props} isCCG={userUtils.isCCG(user)} />;
    },
    [userUtils.isCCG(user)]
  );

  if (!userUtils.isValidUser(user)) {
    return <ErrorMessage message="Invalid user type." />;
  }

  return (
    <NewClaimsLiveWrapper isCCG={userUtils.isCCG(user)}>
      <Tab.Navigator screenOptions={tabScreenOptions} tabBar={tabBar} tabBarOptions={tabBarOptions}>
        {userUtils.isAMHP(user) && (
          <>
            <Tab.Screen
              name="AMHPDashboardTab"
              component={AMHPTabStacks.DashboardStack}
              options={AMHPTabStacks.DashboardStack.tabOptions}
            />
            <Tab.Screen
              name="AMHPAssessmentsTab"
              component={AMHPTabStacks.AssessmentsStack}
              options={AMHPTabStacks.AssessmentsStack.tabOptions}
            />
          </>
        )}

        {userUtils.isCCG(user) && Platform.OS !== "web" && (
          <Tab.Screen
            name="CCGWebOnlyTab"
            component={CCGTabStacks.WebOnlyClaimsStack}
            options={CCGTabStacks.WebOnlyClaimsStack.tabOptions}
          />
        )}

        {userUtils.isCCG(user) && Platform.OS === "web" && (
          <>
            <Tab.Screen
              name="CCGUnassignedClaimsTab"
              component={CCGTabStacks.UnassignedClaimsStack}
              options={CCGTabStacks.UnassignedClaimsStack.tabOptions}
            />
            <Tab.Screen
              name="CCGTeamClaimsTab"
              component={CCGTabStacks.TeamClaimsStack}
              options={CCGTabStacks.TeamClaimsStack.tabOptions}
            />
            <Tab.Screen
              name="CCGMyClaimsTab"
              component={CCGTabStacks.MyClaimsStack}
              options={CCGTabStacks.MyClaimsStack.tabOptions}
            />
            <Tab.Screen
              name="CCGRejectedClaimsTab"
              component={CCGTabStacks.RejectedClaimsStack}
              options={CCGTabStacks.RejectedClaimsStack.tabOptions}
            />
            <Tab.Screen
              name="CCGApprovedClaimsTab"
              component={CCGTabStacks.ApprovedClaimsStack}
              options={CCGTabStacks.ApprovedClaimsStack.tabOptions}
            />
            <Tab.Screen
              name="CCGApprovedPaidClaimsTab"
              component={CCGTabStacks.ApprovedPaidClaimsStack}
              options={CCGTabStacks.ApprovedPaidClaimsStack.tabOptions}
            />
          </>
        )}

        {userUtils.isDoctor(user) && (
          <>
            <Tab.Screen
              name="DoctorProfileTab"
              component={DoctorTabStacks.ProfileStack}
              options={DoctorTabStacks.ProfileStack.tabOptions}
            />
            <Tab.Screen
              name="DoctorVisitsTab"
              component={DoctorTabStacks.VisitsStack}
              options={DoctorTabStacks.VisitsStack.tabOptions}
            />
            <Tab.Screen
              name="DoctorClaimsTab"
              component={DoctorTabStacks.ClaimsStack}
              options={{ ...DoctorTabStacks.ClaimsStack.tabOptions, tabBarBadge: doctorClaimsTabBadge }}
            />
          </>
        )}

        <Tab.Screen
          component={SharedTabStacks.SupportStack}
          name="SupportTab"
          options={SharedTabStacks.SupportStack.tabOptions}
        />
      </Tab.Navigator>
    </NewClaimsLiveWrapper>
  );
};

export default React.memo(TabNavigation);
