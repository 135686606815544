import React from "react";
import Text from "./Text";
import { View, StyleSheet } from "react-native";
import Icon from "../Icon";
import { color } from "../../theme/color";
import { TypographyProps, TypographyType } from "@/models/Typography";
import { spacing } from "../../theme/spacing";
import { standardSizes } from "./fontSettings";

const TextWithIcon = (
  props: {
    iconName: string;
    iconColor?: string;
    iconPosition?: "flex-start" | "center";
    readonly?: boolean;
  } & TypographyProps
) => {
  const { iconName, iconColor = color.primary, iconPosition = "center", format, children, ...restProps } = props;
  return props.readonly ? (
    <></>
  ) : (
    <View style={[styles.container, { alignItems: iconPosition }]}>
      <Icon color={iconColor} name={iconName} style={[styles.iconBaseStyles, getIconSize(format)]} />
      <Text {...restProps} format={format}>
        {children}
      </Text>
    </View>
  );
};

export default TextWithIcon;

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
    marginBottom: spacing[10],
  },
  iconBaseStyles: {
    marginRight: spacing[15],
  },
});

function getIconSize(format: TypographyType) {
  return standardSizes[format];
}
