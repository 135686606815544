import { PathConfigMap } from "@react-navigation/native";

const basepath = "Doctor";
const claimsPath = `${basepath}/Claims`;
const profilePath = `${basepath}/Profile`;
const visitsPath = `${basepath}/Visits`;

const doctorScreens: PathConfigMap = {
  DoctorClaimsTab: {
    screens: {
      ClaimsScreen: { path: claimsPath },
      ClaimDetailsScreen: { path: `${claimsPath}/claimdetails/:claimId` },
      DoctorVisitDetailsScreen: { path: `${claimsPath}/visitdetails/:visitId` },
      MHAFormScreen: { path: `${claimsPath}/visitdetails/:assessmentId/forms/:formType/:formId?` },
    },
  },

  DoctorProfileTab: {
    screens: {
      DoctorProfileScreen: { path: profilePath },
      DoctorAvailabilityScreen: { path: `${profilePath}/availability` },
      DoctorVisitDetailsScreen: { path: `${profilePath}/visitdetails/:visitId/:notificationUpdate?` },
      EditDoctorAvailabilityScreen: { path: `${profilePath}/editavailability` },
      EditDoctorProfileScreen: { path: `${profilePath}/edit` },
      HolidaysScreen: { path: `${profilePath}/holidays` },
      MHAFormScreen: { path: `${profilePath}/forms/:formType/:formId?` },
    },
  },

  DoctorVisitsTab: {
    screens: {
      DoctorVisitScreen: { path: visitsPath },
      DoctorVisitDetailsScreen: { path: `${visitsPath}/visitdetails/:visitId` },
      ProfileScreen: { path: `${visitsPath}/doctorprofile` },
      MapScreen: { path: `${visitsPath}/map` },
      MHAFormScreen: { path: `${visitsPath}/visitdetails/:assessmentId/forms/:formType/:formId?` },
      MeetingRoomPreviewScreen: { path: `${visitsPath}/preview/:assessmentId` },
      MeetingRoomScreen: { path: `${visitsPath}/video/:assessmentId` },
    },
  },
};

export default doctorScreens;
