import { AvailabilityState } from "libs/types/availability";
import { SearchResultItem } from "../components/SearchResults/SearchResults.props";

/**
 * Setting a featureCollection which is a layer of markers being displayed on the map.
 */
export const setAvailabilityState = (marker: SearchResultItem) => {
  const availabilityState = marker.isAddedToAssessment
    ? AvailabilityState.inAssessment
    : !!marker.nextAvailability && marker.nextAvailability.availability.type
    ? marker.nextAvailability.availability.type
    : marker.nextAvailability && marker.mhtEmployers && marker.mhtEmployers.length === 0
    ? AvailabilityState.independent
    : marker.nextAvailability
    ? AvailabilityState.available
    : marker.availabilities.length
    ? AvailabilityState.unavailable
    : AvailabilityState.noInformation;

  return availabilityState;
};

// Converts numeric degrees to radians
function toRad(v: number) {
  return (v * Math.PI) / 180;
}

export const distanceInMiles = (lat1: number, lon1: number, lat2: number, lon2: number) => {
  const R = 6371; // km
  const dLat = toRad(lat2 - lat1);
  const dLon = toRad(lon2 - lon1);
  const lat1Rad = toRad(lat1);
  const lat2Rad = toRad(lat2);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1Rad) * Math.cos(lat2Rad);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;
  // convert to miles
  return Math.round(d * 0.621371);
};
