/* eslint-disable react-native/no-unused-styles */
import React, { useEffect, useState } from "react";
import { GestureResponderEvent, StyleSheet, TextStyle, View, ViewStyle } from "react-native";
import { TouchableRipple } from "react-native-paper";

import { TypographyType } from "@/models/Typography";
import Text from "../Text";
import { palette, spacing } from "../../theme";
import { mqWeb } from "../../utils/helpers";

interface Section {
  touched: boolean;
  valid: boolean;
}

interface PBProps {
  activeSection: number;
  sections: Section[];
  onSelectSection?: (index: number) => void;
}

interface PBItemProps {
  sectionNumber: number;
  active: boolean;
  valid: boolean;
  onPress?: ((event: GestureResponderEvent) => void) | null;
}

const ProgressBarItem = (props: PBItemProps) => {
  const { active, onPress, sectionNumber, valid } = props;
  const isWebView = mqWeb();
  const itemVariant = isWebView ? "Web" : "Mobile";
  const [visited, setVisited] = useState(false);
  const [hasError, setHasError] = useState(false);
  let itemStateStyle = styles.itemDefault;
  let itemTextColor = palette.greyBlue;

  const shouldSetActiveColours = (valid && active) || (!valid && !hasError && active);
  const shouldSetCompletedColours = valid && visited;
  const shouldSetErrorColours = hasError;

  if (shouldSetActiveColours) {
    itemStateStyle = styles.itemActive;
    itemTextColor = palette.blue;
  } else if (shouldSetCompletedColours) {
    itemStateStyle = styles.itemCompleted;
    itemTextColor = palette.white;
  } else if (shouldSetErrorColours) {
    itemStateStyle = active ? styles.itemErrorActive : styles.itemError;
    itemTextColor = active ? palette.red : palette.white;
  }

  useEffect(() => {
    if (active && !visited) {
      setVisited(true);
    }

    if (visited && !active && !valid) {
      setHasError(true);
    }
  }, [active, valid]);

  return (
    <TouchableRipple style={[styles.item, styles[`item${itemVariant}`], itemStateStyle]} onPress={onPress || undefined}>
      <Text format={TypographyType.MicroBold} style={styles[`itemText${itemVariant}`]} color={itemTextColor}>
        {sectionNumber}
      </Text>
    </TouchableRipple>
  );
};

const ProgressBarComponent = (props: PBProps) => {
  const { activeSection, onSelectSection, sections } = props;

  const onPress = (index: number) => {
    onSelectSection && onSelectSection(index);
  };

  const BarItems = sections.map((section, i) => {
    const isActive = activeSection === i;
    const isPressable = Boolean(onSelectSection && !isActive);
    const handleOnPress = isPressable ? () => onPress(i) : null;

    return (
      <ProgressBarItem key={i} sectionNumber={i + 1} active={isActive} onPress={handleOnPress} valid={section.valid} />
    );
  });

  return (
    <View style={styles.bar}>
      <View style={styles.barItems}>
        <View style={styles.barItemsRail} />
        {BarItems}
      </View>
    </View>
  );
};

const styles: { [key: string]: ViewStyle | TextStyle } = StyleSheet.create({
  bar: {
    paddingHorizontal: spacing[15],
    paddingBottom: spacing[15],
  },

  barItems: {
    position: "relative",
    flexDirection: "row",
    justifyContent: "space-between",
  },

  barItemsRail: {
    position: "absolute",
    width: "100%",
    left: 0,
    top: "50%",
    height: 2,
    transform: [{ translateY: -1 }],
    backgroundColor: palette.cloud,
  },

  item: {
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 2,
    borderRadius: 50,
  },

  // Variants
  itemMobile: { width: 26, height: 26 },
  itemWeb: { width: 32, height: 32 },
  itemTextMobile: { fontSize: 10, lineHeight: 16 },
  itemTextWeb: { fontSize: 12, lineHeight: 18 },

  // States
  itemDefault: { backgroundColor: palette.cloud, borderColor: palette.grey },
  itemError: { backgroundColor: palette.red, borderColor: palette.red },
  itemErrorActive: { backgroundColor: palette.white, borderColor: palette.red },
  itemActive: { backgroundColor: palette.white, borderColor: palette.blue },
  itemCompleted: { backgroundColor: palette.blue, borderColor: palette.blue },
});

export const ProgressBar = React.memo(ProgressBarComponent, (old, props) => deepEqual(old, props));

function deepEqual(obj1: any, obj2: any) {
  if (obj1 === obj2) {
    return true;
  } else if (isObject(obj1) && isObject(obj2)) {
    if (Object.keys(obj1).length !== Object.keys(obj2).length) {
      return false;
    }
    for (const prop in obj1) {
      if (!deepEqual(obj1[prop], obj2[prop])) {
        return false;
      }
    }
    return true;
  } else {
    return false;
  }

  // Private
  function isObject(obj: any) {
    if (typeof obj === "object" && obj != null) {
      return true;
    } else {
      return false;
    }
  }
}
