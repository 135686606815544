import React, { useMemo } from "react";
import { StyleSheet, View } from "react-native";
import { TouchableRipple } from "react-native-paper";

import Icon, { IconName } from "../../Icon";
import { palette } from "../../../theme";

export interface VideoInputToggleProps {
  icon:
    | IconName.videocam
    | IconName.videocam_off
    | IconName.settings
    | IconName.mic
    | IconName.camera_front
    | IconName.camera_rear;
  active: boolean; // initial state; i.e. is the user's mic enabled or disabled by default
  onPress: () => void;
  disabled?: boolean; // disable the button; should be true when user restricts access to input devic
  size?: number;
}

export const VideoInputToggle = React.memo<VideoInputToggleProps>(props => {
  const backgroundStyles = useMemo(
    () => [styles.bg, props.active ? styles.bgActive : props.icon === "settings" ? styles.bgActive : styles.bgInactive],
    [props.active]
  );

  const containerStyle = useMemo(
    () => [
      styles.button,
      props.size
        ? {
            width: props.size,
            height: props.size,
            borderRadius: props.size / 4,
          }
        : {},
    ],
    [props.size]
  );

  return (
    <TouchableRipple
      rippleColor={palette.rippleColor}
      disabled={props.disabled}
      style={containerStyle}
      onPress={props.onPress}
    >
      <>
        <Icon name={props.icon || IconName.settings} size={props.size ? props.size - 4 : 24} />

        <View style={backgroundStyles} />
      </>
    </TouchableRipple>
  );
});

const styles = StyleSheet.create({
  bg: {
    position: "absolute",
    width: "100%",
    height: "100%",
    left: 0,
    top: 0,
    zIndex: -2,
  },
  bgLevel: {
    backgroundColor: palette.aqua,
    opacity: 1,
    position: "absolute",
    width: "100%",
    height: "100%",
    bottom: 0,
    top: 0,
    zIndex: -1,
  },
  bgActive: { backgroundColor: palette.grey, opacity: 0.15 },
  bgInactive: { backgroundColor: palette.red, opacity: 1 },

  button: {
    width: 44,
    height: 44,
    marginLeft: 10,
    position: "relative",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 12,
    overflow: "hidden",
  },
});
