import React from "react";
import { createStackNavigator } from "@react-navigation/stack";

import Config from "@/config";
import { DoctorVisitsRoutes, DoctorTabRoutes, TabNavigationProps } from "@/navigationv2/types";
import screenOptions from "@/navigationv2/config/screenOptions";
import Screens from "@/screens";
import TabIcon from "../TabIcon";

const VisitsStack = createStackNavigator<DoctorVisitsRoutes>();

interface PropTypes extends TabNavigationProps<DoctorTabRoutes, "DoctorVisitsTab"> {}

const DoctorVisitsStack = () => {
  return (
    <VisitsStack.Navigator initialRouteName="DoctorVisitScreen" screenOptions={screenOptions}>
      <VisitsStack.Screen
        name="DoctorVisitScreen"
        component={Screens.DoctorVisitScreen}
        options={Screens.DoctorVisitScreen.screenOptions}
      />
      <VisitsStack.Screen
        name="DoctorVisitDetailsScreen"
        component={Screens.DoctorVisitDetailsScreen}
        options={Screens.DoctorVisitDetailsScreen.screenOptions}
      />
      <VisitsStack.Screen name="MapScreen" component={Screens.MapScreen} options={Screens.MapScreen.screenOptions} />
      <VisitsStack.Screen
        name="ProfileScreen"
        component={Screens.ProfileScreen}
        options={Screens.ProfileScreen.screenOptions}
      />
      <VisitsStack.Screen
        name="MeetingRoomPreviewScreen"
        component={Screens.MeetingRoomPreviewScreen}
        options={Screens.MeetingRoomPreviewScreen.screenOptions}
      />
      <VisitsStack.Screen
        name="MeetingRoomScreen"
        component={Screens.MeetingRoomScreen}
        options={Screens.MeetingRoomScreen.screenOptions}
      />
      {Config.ENABLE_FORMS && (
        <VisitsStack.Screen
          name="MHAFormScreen"
          component={Screens.MHAFormScreen}
          options={Screens.MHAFormScreen.screenOptions}
        />
      )}
    </VisitsStack.Navigator>
  );
};

DoctorVisitsStack.tabOptions = {
  tabBarIcon: TabIcon("assignment-ind"),
  tabBarLabel: "Visits",
};

export default DoctorVisitsStack;
