import React, { Fragment, useMemo } from "react";
import { Platform, StyleSheet, View, TouchableWithoutFeedback } from "react-native";
import { HelperText, RadioButton as RadioButtonPaper } from "react-native-paper";

import { TypographyType } from "@/models/Typography";
import Text from "../../Text/Text";
import { RadioButtonProps } from "../../Radio/Radio";

import { VerticalSpacer } from "../../Spacer/index";
import { color, spacing, palette } from "../../../theme";
import { Itemisation } from "libs/types/mhaForms";
import { getItemisation } from "libs/mhaForms/helpers";

export type MHARadioOptionProps = Omit<RadioButtonProps, "style" | "wrapperStyle"> & {
  readonly?: boolean;
  prefix?: string;
  disabled?: boolean;
};

interface MHAItemisedRadioOptionProps extends MHARadioOptionProps {
  prefix: string;
}

// No export, access only allowed through MHARadioOptionList
const MHARadioOption = React.memo((props: MHARadioOptionProps | MHAItemisedRadioOptionProps) => {
  const { readonly, status, label, onPress, value, prefix } = props;
  const RadioButtonComponent = useMemo(() => (Platform.OS === "ios" ? RadioButtonPaper.Android : RadioButtonPaper), []);

  if (readonly) {
    return (
      <View style={{ flexDirection: "row" }}>
        {prefix && (
          <Text format={TypographyType.Regular} style={[!status && styles.strikethroughRadio, { marginRight: 10 }]}>
            {prefix}
          </Text>
        )}
        <Text format={TypographyType.Regular} style={!status && styles.strikethroughRadio}>
          {label}
        </Text>
      </View>
    );
  }

  return (
    <TouchableWithoutFeedback onPress={event => onPress && onPress(event, value)}>
      <View style={styles.radioWrapper}>
        <View style={styles.radio}>
          <RadioButtonComponent
            value={value}
            color={color.primary}
            uncheckedColor={palette.greyBlue}
            disabled={props.disabled}
            status={status ? "checked" : "unchecked"}
            onPress={Platform.OS === "ios" ? event => onPress && onPress(event, value) : undefined}
          />
        </View>

        {props.prefix && (
          <Text
            format={status ? TypographyType.TinyBold : TypographyType.Tiny}
            style={[styles.prefix, props.disabled && styles.disabled]}
          >
            {props.prefix}
          </Text>
        )}

        <Text
          format={status ? TypographyType.RegularBold : TypographyType.Regular}
          style={[styles.radioLabel, props.disabled && styles.disabled]}
        >
          {label}
        </Text>
      </View>
    </TouchableWithoutFeedback>
  );
});

interface MHARadioOptionsListProps {
  value: string;
  error: boolean;
  errorText: string;
  readonly?: boolean;
  itemisation?: Itemisation;
  options: MHARadioOptionProps[];
  onItemPress: (value: string) => void;
  onValueChange: (value: string) => void;
  disabled?: boolean;
}

export const MHARadioOptionsList = (props: MHARadioOptionsListProps) => {
  const { readonly, value, disabled, onItemPress, onValueChange, error, errorText } = props;

  if (props.itemisation && props.options.length > 25) {
    throw new Error("List length exceeds maximum allowed");
  }

  const options = useMemo(
    () =>
      props.options.map((radioOptionProps: MHARadioOptionProps, index: number) => {
        const status = radioOptionProps.value === value;

        return (
          <Fragment key={`radioOption${index}`}>
            {props.itemisation ? (
              <MHAItemisedRadioOption
                {...radioOptionProps}
                onPress={(event, value) => onItemPress(value)}
                prefix={radioOptionProps.prefix || "(" + getItemisation(props.itemisation, index) + ")"}
                readonly={readonly}
                disabled={disabled}
                status={status}
              />
            ) : (
              <MHARadioOption
                {...radioOptionProps}
                readonly={readonly}
                disabled={disabled}
                onPress={(event, value) => onItemPress(value)}
                status={status}
              />
            )}
            <VerticalSpacer height={10} />
          </Fragment>
        );
      }),
    [value]
  );

  return (
    <View style={styles.radioListContainer}>
      <RadioButtonPaper.Group onValueChange={onValueChange} value={value}>
        {options}
        {!readonly && (
          <HelperText style={styles.error} type="error" visible={error}>
            {errorText || ""}
          </HelperText>
        )}
      </RadioButtonPaper.Group>
    </View>
  );
};

export const MHAItemisedRadioOption = (props: MHAItemisedRadioOptionProps) => {
  return <MHARadioOption {...props} />;
};

const styles = StyleSheet.create({
  radioButtonOption: { alignItems: "flex-start", marginBottom: 0 },
  radioWrapper: {
    flexDirection: "row",
    paddingVertical: 2,
  },
  radio: {
    marginRight: spacing[10],
    width: 24,
    height: 24,
    margin: 0,
    borderRadius: 12,
  },
  strikethroughRadio: {
    textDecorationLine: "line-through",
  },
  radioListContainer: {
    flexDirection: "column",
    marginTop: spacing[10],
  },
  prefix: {
    marginRight: spacing[10],
    lineHeight: 24,
    minWidth: 20,
  },
  radioLabel: { lineHeight: 24, flex: 1 },
  disabled: {
    color: color.disabledText,
  },
  error: { color: color.textError, minHeight: 23 },
});
