import React from "react";
import Text from "../Text";
import { View } from "react-native";
import { TypographyProps, TypographyType } from "@/models/Typography";
import { palette } from "../../theme";

const InfoItem = (
  props: {
    linkLabel?: string;
    linkOnPress?: () => void;
  } & TypographyProps
) => {
  const { linkLabel, linkOnPress, format, ...restProps } = props;

  const { linkSize } = getInfoItemSize(format);
  return (
    <View>
      <Text {...restProps} format={format}></Text>
      {linkLabel && linkOnPress && (
        <Text format={linkSize} onPress={linkOnPress} color={palette.blue}>
          {linkLabel}
        </Text>
      )}
    </View>
  );
};

export default InfoItem;

export function getInfoItemSize(size: TypographyType) {
  const sizes: {
    [key: string]: {
      icon: number;
      linkSize: TypographyType;
    };
  } = {
    [TypographyType.HeadingLarge]: {
      icon: 40,
      linkSize: TypographyType.RegularBold,
    },
    [TypographyType.HeadingMedium]: {
      icon: 34,
      linkSize: TypographyType.RegularBold,
    },
    [TypographyType.HeadingMedium]: {
      icon: 28,
      linkSize: TypographyType.RegularBold,
    },
    [TypographyType.ButtonText]: {
      icon: 24,
      linkSize: TypographyType.RegularBold,
    },
    [TypographyType.Regular]: {
      icon: 22,
      linkSize: TypographyType.SmallBold,
    },
    [TypographyType.RegularBold]: {
      icon: 22,
      linkSize: TypographyType.SmallBold,
    },
    [TypographyType.Small]: {
      icon: 20,
      linkSize: TypographyType.TinyBold,
    },
    [TypographyType.SmallBold]: {
      icon: 20,
      linkSize: TypographyType.TinyBold,
    },
    [TypographyType.Tiny]: {
      icon: 18,
      linkSize: TypographyType.MicroBold,
    },
    [TypographyType.TinyBold]: {
      icon: 18,
      linkSize: TypographyType.MicroBold,
    },
    [TypographyType.Micro]: {
      icon: 16,
      linkSize: TypographyType.MicroBold,
    },
    [TypographyType.MicroBold]: {
      icon: 16,
      linkSize: TypographyType.MicroBold,
    },
  };

  return sizes[size];
}
