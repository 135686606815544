import Analytics from "@aws-amplify/analytics";

interface OptionParams {
  pinpointAppId: string;
  pinpointRegion: string;
}

/**
 * Configure application analytics. User sign in is required before tracking.
 *
 * @param {OptionParams} options
 */
export default function configureAnalytics(options: OptionParams) {
  const { pinpointAppId, pinpointRegion } = options;

  Analytics.configure({
    autoSessionRecord: true,
    AWSPinpoint: {
      appId: pinpointAppId,
      region: pinpointRegion,
      /**
       * Don't record any info prior to signin
       */
      mandatorySignIn: true,
    },
  });
}
