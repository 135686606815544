import React, { useState, useCallback } from "react";
import { PanResponderInstance, RefreshControl } from "react-native";

import { ClaimStatus } from "libs/types/API";
import ClaimProcessing from "@/components/ClaimProcessing";
import ClaimProcessingProvider from "@/components/ClaimProcessing/ClaimProcessingProvider";
import { useRecoilValue, useRecoilState } from "recoil";
import { userDetails, refreshClaimsRequired as recoilRefreshClaimsRequired } from "@/utils/recoil/index";
import { FIND_CLAIMS } from "@/models/Claim";
import { organisationIdFromPropsAndUser } from "./helpers";
import { RouteKeys } from "@/navigationv2";
import { CCGApprovedClaimsRoutes, CCGTabRoutes, ScreenNavigationProps, ScreenOptions } from "@/navigationv2/types";
import { createScreenTitle } from "@/navigationv2/utilities";
import { useOnMount, useOnScreenFocus } from "@/hooks";
import { redirectUserAfterLogin } from "@/screens/utils";
import { AppScreen } from "@/layouts/AppScreen";

interface PropTypes
  extends ScreenNavigationProps<CCGTabRoutes, CCGApprovedClaimsRoutes, RouteKeys.ApprovedClaimsScreen> {}

const ApprovedClaimsScreen = (props: PropTypes) => {
  const user = useRecoilValue(userDetails);
  const { route } = props;

  const [refreshClaimsRequired, setRefreshClaimsRequired] = useRecoilState(recoilRefreshClaimsRequired);

  const [isUnmounted, setIsUnmounted] = useState(false);
  // If isUnmounted is set to true, via  useEffect(() => () => setIsUnmounted(true), []) this will
  // prevent refresh when claim is marked as Paid. The claim stays in the unpaid list.
  const onRefresh = useCallback(() => !isUnmounted && setRefreshClaimsRequired(true), [isUnmounted]);

  const organisationId = organisationIdFromPropsAndUser(route, user);

  const selectedQuery = FIND_CLAIMS;

  /**
   * If the user has tried to access a previous url but was unauthenticated,
   * they are dumped to the login screen where `previousUrl` is set. Then on
   * successful log in, this is passed to the screen where a hard redirect
   * is made to the previous route.
   */
  useOnMount(() => {
    redirectUserAfterLogin();
    return () => setIsUnmounted(true);
  });

  useOnScreenFocus(() => {
    setRefreshClaimsRequired(true);
  });

  return (
    <>
      <AppScreen
        contextLabel="Claims"
        pageTitle="Approved"
        refreshControl={<RefreshControl refreshing={refreshClaimsRequired} onRefresh={onRefresh} />}
        testID="CCG__ApprovedClaims__Screen"
      >
        <ClaimProcessingProvider>
          <ClaimProcessing
            orgId={organisationId}
            selectedQuery={selectedQuery}
            claimsScreen={RouteKeys.ApprovedClaimsScreen}
            type={ClaimStatus.approved}
            refreshRequired={refreshClaimsRequired}
            onRefreshComplete={setRefreshClaimsRequired}
          />
        </ClaimProcessingProvider>
      </AppScreen>
    </>
  );
};

const screenOptions: ScreenOptions = {
  title: createScreenTitle("Approved Claims"),
};

ApprovedClaimsScreen.screenOptions = screenOptions;

export default ApprovedClaimsScreen;
