//                         0  1  2  3    4   5   6   7   8   9
// export const spacing = [0, 4, 8, 12, 16, 24, 32, 48, 64, 80];

export const spacing = {
  5: 5,
  10: 10,
  15: 15,
  20: 20,
  25: 25,
  30: 30,
  35: 35,
  40: 40,
  45: 45,
  50: 50,
  60: 60,
  70: 70,
  80: 80,
};
