import gql from "graphql-tag";
import React, { useMemo } from "react";
import { graphql } from "react-apollo";

import { Gender, NearbyS12DoctorsQueryVariables, LocationInput, LocationNameInput } from "libs/types/API";
import { ErrorMessage } from "../Error";
import SearchResults from "./SearchResults";
import { recordEvent } from "../../utils/analytics";
import { SearchResultItem } from "./SearchResults.props";
import dayjs, { Dayjs } from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { filterSearchResults } from "./SearchResultFilters";
import {
  DoctorCondensedFragment,
  DoctorVisitsCondensed,
  MhtEmployerDefaults,
  LocationNameFragment,
  AvailabilitiesFragment,
  OrganisationIdName,
  LocationCoordsFragment,
  AvailabilityResponse,
} from "@/models/gql/fragments";
import { AnalyticsEvent } from "libs/analytics/events";
import { useSetRecoilState } from "recoil";
import { searchResults } from "../../utils/recoil/index";
dayjs.extend(isBetween);

const thisMonthAvailabilities = () => {
  const d = new Date();
  return `"${d.getFullYear()}${d.getMonth() < 9 ? "0" : ""}${d.getMonth() + 1}"`;
};

const thisMonthHolidays = () => {
  const d = new Date();
  return `"${d.getFullYear()}-${d.getMonth() < 9 ? "0" : ""}${d.getMonth() + 1}"`;
};

function convertToModel(response: SearchResponseItem): SearchResultItem {
  return {
    ...response,
    availabilities: response.availabilities.items?.filter(notEmpty) || [],
    mhtEmployers: response.mhtEmployers.items?.filter(notEmpty) || [],
    visits: response.visits.items?.filter(notEmpty).map(v => ({ ...v, time: dayjs(v.time) })) || [],
    holidays:
      response.holidays.items?.filter(notEmpty).map(h => ({ ...h, start: dayjs(h.start), end: dayjs(h.end) })) || [],
  };
}

interface SearchResponseItem {
  id: string;
  name: string;
  gender: Gender | null;
  specialties: string[];
  languages: string[];
  phone: string | null;
  distance: number;
  location: LocationInput;
  locationName: LocationNameInput;
  notes: string | null;
  availabilities: {
    items: Array<(AvailabilityResponse & { notes: string | null }) | null> | null;
  };
  visits: {
    items: Array<{
      id: string;
      time: string;
      partialPostcode: string | null;
      notes?: string[]; // formatted as plain strings
    } | null> | null;
  };
  holidays: {
    items: Array<{
      id: string;
      start: string;
      end: string;
      visible: boolean;
    } | null> | null;
  };
  mhtEmployers: {
    items: Array<{
      id: string;
      mht: {
        id: string;
        abbreviation: string;
        organisation: {
          name: string;
          id: string;
        };
      };
    } | null> | null;
  };
}

const query = gql`
  query NearbyS12Doctors(
    $location: LocationInput!
    $distance: Int!
    $hasAssessment: Boolean!
    $assessmentId: ID!
    $start: String!
    $end: String!
    $doctorName: String
  ) {
    doctors: findDoctors(
      location: $location
      distance: $distance
      limit: 150
      doctorName: $doctorName
    ) {
      items {
        ...DoctorCondensed
        specialties
        languages
        gender
        distance
        notes
        location {
          ...latLon
        }
        locationName {
          ...fullLocation
        }
        mhtEmployers {
          items {
            ...MhtEmployerDefaultItems
          }
        }
        availabilities(limit: 500, endDate: { gt: ${thisMonthAvailabilities()} } ) {
          items {
            ...availabilitiesDefault
            notes
          }
        }
        visits(limit: 250, time: { between: [$start, $end]}) {
          items {
            id
            time
            partialPostcode
            notes
          }
        }
        holidays(limit: 250, end:{ge: ${thisMonthHolidays()}}, sortDirection: ASC) {
            items {
                id
                start
                end
                visible
            }
        }
      }
      total
      nextToken
    }
    assessment: amhpAssessment(id: $assessmentId) @include(if: $hasAssessment) {
      id
      doctorVisits {
        items {
          ...DoctorVisitsCondensed
        }
      }
    }
  }
  ${OrganisationIdName}
  ${LocationCoordsFragment}
  ${LocationNameFragment}
  ${AvailabilitiesFragment}
  ${MhtEmployerDefaults}
  ${DoctorCondensedFragment}
  ${DoctorVisitsCondensed}
`;

type Variables = NearbyS12DoctorsQueryVariables & {
  locationName: string;
  timeSpan: {
    start: Dayjs;
    end: Dayjs;
  };
  assessment?: {
    id: string;
  };
  doctorGender?: Gender | null;
  doctorLanguages?: string[];
  doctorSpecialties?: string[];
  doctorName?: string;
};

const builder = graphql<
  Variables,
  {
    doctors: { items: SearchResponseItem[]; total: number };
    assessment?: {
      doctorVisits?: { items: { id: string; doctor: { id: string } }[] };
    };
  }
>(query, {
  options: (props: Variables) => ({
    fetchPolicy: "no-cache",
    variables: {
      distance: props.distance,
      location: props.location,
      doctorName: props.doctorName?.replace(/^d(octo)?r\.?\s/i, ""),
      hasAssessment: !!props.assessment,
      assessmentId: props.assessment && props.assessment.id,
      start: dayjs(props.timeSpan.start)
        .subtract(2, "hour")
        .toISOString(),
      end: dayjs(props.timeSpan.end)
        .add(2, "hour")
        .toISOString(),
    },
  }),
});

// TODO: Loading pages, error pages, error message routing
export default builder(({ data, ...props }) => {
  const setSearchResults = useSetRecoilState(searchResults);
  const [filteredDoctors, setFilteredDoctors] = React.useState<SearchResultItem[] | null>(null);

  const { loading, error, assessment } = data || {};

  const resultData = useMemo(
    () =>
      props.assessment && {
        id: props.assessment.id,
        doctors: assessment && assessment.doctorVisits && assessment.doctorVisits.items.map(i => i.doctor),
        doctorVisits: assessment && assessment.doctorVisits && assessment.doctorVisits.items,
      },
    [props.assessment, assessment]
  );

  const doctors = data && data.doctors;
  const {
    doctorGender,
    doctorLanguages,
    doctorSpecialties,
    location,
    timeSpan,
    distance,
    locationName,
    doctorName,
  } = props;

  React.useEffect(() => {
    if (doctors) {
      // We have a set of doctors, however, we need to further filter based on the additional criteria.
      // At the end of this section, docs will contain the subset of doctors with the specified gender, language, specialisms etc.
      // This filtering happens in filterSearchResults.
      let docs = filterSearchResults(
        doctors.items.map(d => convertToModel(d)),
        {
          doctorGender,
          doctorLanguages,
          doctorSpecialties,
          timeSpan,
          doctorName,
          location,
          distance,
        }
      );
      docs = docs.map(d => ({
        ...d,
        isAddedToAssessment:
          (assessment &&
            assessment.doctorVisits &&
            assessment.doctorVisits.items.map(i => i.doctor.id).indexOf(d.id) > -1) ||
          undefined,
      }));

      setSearchResults(docs);
      setFilteredDoctors(docs);

      recordEvent(
        AnalyticsEvent.FIND_DOCTORS,
        {
          locationName: locationName,
          location: `${location.lat.toString()},${location.lon.toString()}`,
        },
        {
          distance,
          "start hour": timeSpan.start.hour(),
          "timeframe (hrs)": timeSpan.end.diff(timeSpan.start, "hour", true),
          "results returned": docs.length,
          "available doctors": docs.filter(d => !!d.nextAvailability).length,
        }
      );
    } else {
      setSearchResults(null);
      setFilteredDoctors(null);
    }
  }, [doctors]);

  if (!data) {
    return <ErrorMessage />;
  }

  if (error) {
    return <ErrorMessage apolloError={error} />;
  } else if (doctors || loading) {
    return (
      <SearchResultsMemo
        assessment={resultData}
        isLoading={loading}
        results={loading ? undefined : filteredDoctors}
        searchCriteria={props}
      />
    );
  } else {
    // This should never trigger
    // TODO: record if this is triggered, it will be a bug.
    return <ErrorMessage />;
  }
});

const SearchResultsMemo = React.memo(SearchResults);

function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && typeof value !== "undefined";
}
