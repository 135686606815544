import React from "react";
import { View, StyleSheet } from "react-native";
import Icon from "../Icon";
import InfoItem, { getInfoItemSize } from "./InfoItem";
import { TypographyProps } from "@/models/Typography";
import { spacing, color } from "../../theme";

const InfoItemIcon = (
  props: {
    iconName: string;
    iconColor?: string;
    iconPosition?: "flex-start" | "center";
    linkLabel?: string;
    linkOnPress?: () => void;
  } & TypographyProps
) => {
  const {
    iconName,
    iconColor = color.primary,
    iconPosition = "center",
    linkLabel,
    linkOnPress,
    format,
    ...restProps
  } = props;

  const { icon } = getInfoItemSize(format);
  return (
    <View style={[styles.container, { alignItems: iconPosition }]}>
      <Icon color={iconColor} name={iconName} style={styles.iconBaseStyles} size={icon} />
      <View style={styles.flex}>
        <InfoItem linkLabel={linkLabel} linkOnPress={linkOnPress} format={format} {...restProps} />
      </View>
    </View>
  );
};

export default InfoItemIcon;

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
  },
  iconBaseStyles: {
    marginRight: spacing[15],
  },
  flex: { flex: 1 },
});
