import { useEffect, useMemo } from "react";
import { useSetRecoilState, useRecoilValue } from "recoil";

import {
  userDetails,
  globalBottomSheetData,
  globalBottomSheetVisible,
  globalBottomSheetScrollToTop,
} from "@/utils/recoil";
import { BottomSheetData } from "@/utils/recoil/props";

export default function useBottomSheet() {
  const setRecoilBottomSheetData = useSetRecoilState(globalBottomSheetData);
  const setGlobalBottomSheetVisible = useSetRecoilState(globalBottomSheetVisible);
  const setGlobalBottomSheetScrollToTop = useSetRecoilState(globalBottomSheetScrollToTop);
  const user = useRecoilValue(userDetails);

  let timeOut: ReturnType<typeof setTimeout>;

  function openBottomSheet<T extends Record<string, any>>(data: BottomSheetData<T>) {
    clearTimeout(timeOut);
    setRecoilBottomSheetData(data);
    setGlobalBottomSheetVisible(true);
  }
  function closeBottomSheet() {
    setGlobalBottomSheetVisible(false);
  }
  function scrollBottomSheetScrollViewToTop() {
    setGlobalBottomSheetScrollToTop(true);
  }

  useEffect(() => {
    if (!user) {
      setGlobalBottomSheetVisible(false);
      setRecoilBottomSheetData(null);
    }
  }, [user]);

  return useMemo(
    () => ({
      openBottomSheet,
      closeBottomSheet,
      scrollBottomSheetScrollViewToTop,
    }),
    []
  );
}
