import React, { useEffect } from "react";
import { StyleSheet, View } from "react-native";
import { useSetRecoilState } from "recoil";

import { Button } from "@/components/Button";
import { BackButtonProps } from "@/components/BackButton/BackButton.props";
import { ContentWrap } from "@/components/ContentWrap";
import { VersionHistory, Codepush } from "@/components/VersionHistory";
import Text from "@/components/Text";
import { AppScreen } from "@/layouts/AppScreen/AppScreen";
import { TypographyType } from "@/models/Typography";
import { RouteKeys } from "@/navigationv2";
import { SharedTabRoutes, SupportRoutes, ScreenNavigationProps, ScreenOptions } from "@/navigationv2/types";
import { createScreenTitle } from "@/navigationv2/utilities";
import { color } from "@/theme/color";
import { spacing } from "@/theme/spacing";
import { hideBottomNav } from "@/utils/recoil/index";

import { version, codepush } from "@/../package.json";

interface PropTypes extends ScreenNavigationProps<SharedTabRoutes, SupportRoutes, RouteKeys.AppUpdateScreen> {}

export default function AppUpdateScreen(props: PropTypes) {
  const { navigation } = props;
  const setHideBottomNav = useSetRecoilState(hideBottomNav);
  const versionNumber = version.split(".").join("_");
  const backButtonConfig: BackButtonProps = { enabled: false };
  const parentNavigationState = navigation.dangerouslyGetParent()?.dangerouslyGetState();
  const navigationState = navigation.dangerouslyGetState();
  const routeName = (parentNavigationState?.routeNames[0] || navigationState.routeNames[0]) as keyof SharedTabRoutes;

  useEffect(() => {
    setHideBottomNav(true);
    return () => setHideBottomNav(false);
  }, []);

  if (!codepush || !(codepush as Codepush)[versionNumber]) {
    navigation.goBack();
  }

  return (
    <View style={styles.container}>
      <View style={styles.content}>
        <AppScreen
          contextLabel="Update"
          pageTitle="New Features"
          backButton={backButtonConfig}
          testID="AppUpdateScreen"
        >
          <ContentWrap>
            <Text format={TypographyType.HeadingSmall} style={styles.heading}>
              Features:
            </Text>
            <VersionHistory />
          </ContentWrap>
        </AppScreen>
      </View>
      <ContentWrap>
        <Button
          onPress={() => {
            setHideBottomNav(false);
            navigation.navigate(routeName);
          }}
          style={styles.button}
        >
          Continue
        </Button>
      </ContentWrap>
    </View>
  );
}

const screenOptions: ScreenOptions = {
  title: createScreenTitle("New Features"),
};

AppUpdateScreen.screenOptions = screenOptions;

const styles = StyleSheet.create({
  container: {
    backgroundColor: color.background,
    flex: 1,
  },
  content: { flex: 1 },
  heading: {
    marginBottom: spacing[30],
  },
  button: { marginTop: -15, marginBottom: spacing[25] },
});
