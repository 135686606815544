import { color } from "@/theme";
import React from "react";

export const themes = {
  pink: {
    backgroundColor: color.MHAFormPink,
  },
  blue: {
    backgroundColor: color.MHAFormBlue,
  },
};

export const MhaFormThemeContext = React.createContext(themes.blue);
