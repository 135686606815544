import React from "react";
import { StyleSheet, View } from "react-native";
import { TypographyType } from "@/models/Typography";
import Text from "../../components/Text";
import { spacing } from "../../theme";
import { codepush } from "../../../package.json";

export interface Swipe {
  icon: JSX.Element;
  color: string;
  onPress: () => void;
}

export interface Codepush {
  [name: string]: string;
}

export const VersionHistory = () => {
  return (
    <>
      {Object.keys(codepush)
        // show highest numbers first
        .sort((a, b) => b.localeCompare(a))
        .map(v => (
          <View key={v}>
            <Text format={TypographyType.SmallBold} style={styles.heading}>
              {v.split("_").join(".")}
            </Text>
            <Text format={TypographyType.Regular} style={styles.heading}>
              {(codepush as Codepush)[v]}
            </Text>
          </View>
        ))}
    </>
  );
};

const styles = StyleSheet.create({
  heading: {
    marginBottom: spacing[30],
  },
});
