import React, { useRef } from "react";
import { StyleSheet, View, SafeAreaView } from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scrollview";

import { AvailabilityState } from "libs/types/availability";

import AvailabilityDot from "@/components/AvailabilityDot";
import { BackButtonProps } from "@/components/BackButton/BackButton.props";
import Header from "@/components/Header";
import Text from "@/components/Text";
import { SafeAreaScrollContainer } from "@/layouts";
import { TypographyType } from "@/models/Typography";
import { color, spacing } from "@/theme";

import { AvailabilityText } from "../AvailabilityText";
import { Contact } from "../Contact";

type KeyboardAwareScrollView2 = typeof KeyboardAwareScrollView & {
  scrollToBottom: () => void;
};

const backButtonConfig: BackButtonProps = {
  enabled: true,
  float: true,
  color: color.textWhite,
};

const Loading = () => {
  const scrollRef = useRef<KeyboardAwareScrollView2>();

  return (
    <SafeAreaView style={styles.container}>
      <SafeAreaScrollContainer ref={scrollRef} backButton={backButtonConfig} style={styles.scrollContainer}>
        <Header
          pageContextLabel="Doctor Profile"
          heading={"\u2586\u2586\u2586\u2586\u2586 \u2586\u2586\u2586\u2586\u2586"}
          headingStyle={styles.fadedText}
        >
          <View style={styles.availability}>
            <AvailabilityDot status={AvailabilityState.noInformation} style={styles.availabilityDot} />
            <AvailabilityText type={AvailabilityState.noInformation} style={styles.availabilityText} />
          </View>
          <View style={styles.distance}>
            <Text format={TypographyType.TinyBold} style={[styles.whiteText, styles.fadedText]}>
              {"\u2586\u2586\u2586\u2586\u2586\u2586"}
            </Text>
            <Text format={TypographyType.Tiny} style={[styles.whiteText, styles.fadedText]}>
              {" \u2586\u2586\u2586\u2586\u2586\u2586"}
            </Text>
          </View>
        </Header>

        <Contact
          phone={"\u2586\u2586\u2586\u2586\u2586 \u2586\u2586\u2586 \u2586\u2586\u2586"}
          phone2={"\u2586\u2586\u2586\u2586\u2586 \u2586\u2586\u2586 \u2586\u2586\u2586"}
          notes={
            "\u2586\u2586\u2586 \u2586\u2586\u2586\u2586\u2586 \u2586\u2586\u2586\u2586\u2586 \u2586\u2586\u2586\u2586\u2586 \u2586\u2586\u2586\u2586 \u2586\u2586\u2586\u2586 \u2586\u2586\u2586 \u2586\u2586\u2586\u2586 \u2586\u2586\u2586."
          }
          availabilityState={AvailabilityState.noInformation}
          email={
            "\u2586\u2586\u2586\u2586.\u2586\u2586\u2586\u2586\u2586\u2586\u2586\u2586@\u2586\u2586\u2586\u2586\u2586\u2586.\u2586\u2586.\u2586\u2586"
          }
          isAvailable={false}
          textStyle={styles.greyText}
          doctorId={"0"}
        />
      </SafeAreaScrollContainer>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  availability: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },

  availabilityDot: {
    marginRight: spacing[10],
  },

  availabilityText: {
    color: color.background,
  },

  container: {
    flex: 1,
  },

  distance: {
    marginTop: spacing[5],
    paddingLeft: 34,
    flexDirection: "row",
    justifyContent: "flex-start",
  },

  fadedText: {
    opacity: 0.15,
  },

  greyText: {
    color: color.thinBorder,
  },

  scrollContainer: {
    flex: 1,
    flexBasis: "100%",
  },

  whiteText: { color: color.background },
});

export default Loading;
