import React, { useEffect, useState, useCallback } from "react";
import { View, StyleSheet, Keyboard } from "react-native";
import { ContentWrap } from "@/components/ContentWrap";
import { TypographyType } from "@/models/Typography";
import { color, spacing, palette } from "@/theme";
import Text from "../../Text";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { API_V2_TOGGLES } from "@/api/types";
import API from "@/api";
import {
  GetTeamInformationResponse,
  CreateAssessmentItem,
  CREATE_ASSESSMENT,
  UPDATE_ASSESSMENT,
  MutateAssessmentResponse,
  GetTeamAssessmentsQueryResponse,
  getAmhpAssessmentsQuery,
} from "@/models/Assessment";
import { CREATE_DOCTOR_VISIT } from "@/models/DoctorProfile";
import Icon from "../../Icon";
import { checkMutationInput, mqWeb, formatNhsNumber } from "@/utils/helpers";
import DualDatePicker from "../../DatePicker/DualDatePicker";
import Select from "../../Select";
import { amhpShouldProvideNhsNumber } from "libs/utils/featureFlags";
import {
  CreateAssessmentFunc,
  CreateAssessmentState,
  UpdateInput,
} from "../../CreateAssessment/CreateAssessment.props";
import dayjs, { Dayjs } from "dayjs";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  userDetails,
  quickAssessment,
  quickAssessmentLocationState,
  lastException,
  snackbarMessage,
  refreshTeamInboxRequired,
} from "@/utils/recoil";
import { TextInput as RNPTextInput, TouchableRipple } from "react-native-paper";
import { TextInput } from "../../TextInput/TextInput";
import { Button, ButtonList } from "../../Button";
import { useAPIVersion, useBottomSheet } from "@/hooks";
import { recordEvent } from "@/utils/analytics";
import { AnalyticsEvent } from "libs/analytics/events";
import {
  buildErr,
  createAssessmentError,
  S12Error,
  updateAssessmentError,
  saveFormError,
  addVisitError,
} from "@/models/Error";
import { saveForm } from "@/models/MHAForm/api";
import { FormStatus, UpdateAssessmentInput } from "libs/types/API";
import { RouteKeys } from "@/navigationv2";

const assessmentPropsToState = (
  assessment: CreateAssessmentItem | null | undefined,
  state: CreateAssessmentState,
  user?: { id?: string } | null
) => {
  const assessmentDate = assessment ? dayjs(assessment.assessmentDate) : dayjs();

  return Object.assign(
    {},
    state,
    assessment && {
      assessmentId: assessment.id,
      ...assessment,
      assessmentDate: assessmentDate.diff(dayjs()) < 0 ? dayjs() : assessmentDate,
      amhpName: assessment.amhp.name,
      amhpId: (assessment.amhp && assessment.amhp.id) || (user && user.id) || "-1", // TODO: update spec with id AND name
      // if the assessment date is today or in the past
      timeSpan:
        dayjs().isSame(assessmentDate, "day") || assessmentDate.diff(dayjs()) < 0
          ? // then start the search on today's date and time
            // and end it 4 hours fom now
            updateTimeSpanForStartTime(
              {
                start: dayjs(),
                end: dayjs().add(4, "hour"),
              },
              new Date().getHours()
            )
          : // but if the assessment date is one or more days from now
            // set the start hour to 6am and search for the next 8 hours by default
            {
              end: assessmentDate.hour(6).add(8, "hour"),
              start: assessmentDate.hour(6),
            },
    }
  );
};

interface TimeSpan {
  start: Dayjs;
  end: Dayjs;
}

const updateTimeSpanForStartTime = (timeSpan: TimeSpan, startHour: any) => {
  return {
    start: timeSpan.start
      .hour(startHour)
      .minute(0)
      .second(0),
    end: timeSpan.start
      .hour(startHour)
      .minute(0)
      .second(0)
      .add(timeSpan.end.diff(timeSpan.start, "hour", true), "hour"),
  };
};

// eslint-disable-next-line complexity, @typescript-eslint/explicit-module-boundary-types
export const QuickAssessment = (props: {
  formMetadata: any;
  patientName: string;
  locationSearch: boolean;
  locationSearchFun: (a: boolean, b: any) => void;
  navigation: any;
  examinationDate: any;
  doctorId: any;
  teamInformationData: any;
}) => {
  const { locationSearch, locationSearchFun } = props;
  const isWebView = mqWeb();
  const user = useRecoilValue(userDetails);
  const assessmentRecoil = useRecoilValue(quickAssessment);
  const setAssessmentRecoil = useSetRecoilState(quickAssessment);
  const quickAssessmentLocationRecoil = useRecoilValue(quickAssessmentLocationState);
  const setLastException = useSetRecoilState(lastException);
  const dateInput = React.createRef<RNPTextInput>();
  const patientNameInput = React.useRef<any>();
  const locationNameInput = React.useRef<any>();
  const { openBottomSheet, closeBottomSheet } = useBottomSheet();
  const doctorName = props.formMetadata?.doctorNames?.[0] || "";
  const [assessment, setAssessment] = useState<CreateAssessmentItem>();
  const { v2: shouldUseCreateDoctorVisitV2 } = useAPIVersion(API_V2_TOGGLES.CREATE_DOCTOR_VISIT);
  const [createAssessment, { loading }] = useMutation(CREATE_ASSESSMENT);
  const [createDoctorVisit] = useMutation(CREATE_DOCTOR_VISIT);
  const { v2 } = useAPIVersion(API_V2_TOGGLES.MHA_FORMS);
  const { loading: gtaLoading, error, data, refetch, variables } = useQuery<GetTeamAssessmentsQueryResponse>(
    getAmhpAssessmentsQuery,
    {
      variables: {
        today: dayjs().format("YYYY-MM-DD"),
        amhpId: user?.id,
        amhpTeamId: props.formMetadata.amhpTeamId,
      },
      fetchPolicy: "cache-and-network",
    }
  );
  const setMessage = useSetRecoilState(snackbarMessage);
  const setRefreshTeamInboxRequired = useSetRecoilState(refreshTeamInboxRequired);
  const [updateAssessmentMtn] = useMutation<MutateAssessmentResponse, UpdateInput>(UPDATE_ASSESSMENT);
  const INITIAL_STATE: CreateAssessmentState = {
    patientName: assessmentRecoil ? assessmentRecoil.patientName : props.patientName ? props.patientName : "", // TODO: CHECK
    patientNhsNumber: assessmentRecoil ? assessmentRecoil.patientNhsNumber : "",
    location: {
      lat: 0,
      lon: 0,
    },
    locationName: {
      city: "",
      postcode: "",
      address: "",
      addressNotes: assessmentRecoil ? assessmentRecoil.locationName.addressNotes : "",
    },
    ccgId: "",
    timeSpan: {
      start: dayjs(),
      end: dayjs().add(4, "hour"),
    },
    distance: 40,
    assessmentDate: assessmentRecoil ? assessmentRecoil.assessmentDate : dayjs(),
    doctorLanguages: [],
    doctorSpecialties: [],
    doctorGender: null,
    amhpId: assessmentRecoil ? assessmentRecoil.amhpId : user ? user.id : "-1",
    amhpName: assessmentRecoil ? assessmentRecoil.amhpName : user ? user.name : "",
    showAutocomplete: false,
    assessmentId: undefined,
  };
  const [assessmentData, setAssessmentData] = useState<CreateAssessmentState>(() => {
    return assessmentPropsToState(assessment, {
      ...INITIAL_STATE,
      ...(assessmentRecoil && {
        ...assessmentRecoil,
        assessmentDate: assessmentRecoil ? assessmentRecoil.assessmentDate : dayjs(),
        timeSpan: updateTimeSpanForStartTime(
          assessmentRecoil
            ? assessmentRecoil.timeSpan
            : {
                start: dayjs(),
                end: dayjs().add(4, "hour"),
              },
          assessmentRecoil ? assessmentRecoil.timeSpan.start.hour() : new Date().getHours()
        ),
      }),
    });
  });

  const amhpTeam = currentTeam(props.teamInformationData?.profile?.teams, props.formMetadata.amhpTeamId);
  const teamMembers = teamMembersForTeam(props.teamInformationData?.profile?.teams, amhpTeam?.id || null);
  const commonAddresses = commonAddressesForTeam(props.teamInformationData?.profile?.teams, amhpTeam?.id || null);
  const teamDisplayName = displayNameForTeam(props.teamInformationData?.profile?.teams, props.formMetadata.amhpTeamId);
  const nhsNumberRequired = amhpShouldProvideNhsNumber((amhpTeam && amhpTeam.featureFlags) || 0);
  const assessmentDate = INITIAL_STATE.assessmentDate;
  const [patientFullName, setPatientFullName] = useState(INITIAL_STATE.patientName);
  const [availableLocation, setAvailableLocation] = useState(false);
  const [addressNotes, setAddressNotes] = useState(INITIAL_STATE.locationName.addressNotes);
  const [nhsNumber, setNhsNumber] = useState(INITIAL_STATE.patientNhsNumber);
  const finalData = {
    ...assessmentRecoil,
    location: quickAssessmentLocationRecoil ? quickAssessmentLocationRecoil.location : { lat: 0, lon: 0 },
    locationName: {
      city: quickAssessmentLocationRecoil ? quickAssessmentLocationRecoil.locationName.city : "",
      postcode: quickAssessmentLocationRecoil ? quickAssessmentLocationRecoil.locationName.postcode : "",
      address: quickAssessmentLocationRecoil ? quickAssessmentLocationRecoil.locationName.address : "",
      addressNotes: assessmentData.locationName.addressNotes,
    },
    ccgId: quickAssessmentLocationRecoil ? quickAssessmentLocationRecoil.ccgId : "",
  };
  const [disabled, setDisabled] = useState(false);

  const updateTimeSpanForAssessmentDate = (timeSpan: TimeSpan, date: Dayjs) => ({
    start: date
      .hour(6)
      .minute(0)
      .second(0),
    end: date
      .hour(6)
      .minute(0)
      .second(0)
      .add(timeSpan.end.diff(timeSpan.start, "hour", true), "hour"),
  });

  function throttleChangeState(callback: (key: string, v: any) => void, delay: number) {
    let isThrottled = false;
    let args: any[] | undefined;

    function wrapper(...wrapperArgs: any[]) {
      if (isThrottled) {
        args = wrapperArgs;
        return;
      }

      isThrottled = true;
      callback(wrapperArgs[0], wrapperArgs[1]);

      setTimeout(() => {
        isThrottled = false;
        if (args) {
          wrapper(...args);
          args = undefined;
        }
      }, delay);
    }

    return wrapper;
  }

  const onChangeState = useCallback<(k: string, v: any) => void>(
    throttleChangeState((k: string, v: any) => {
      if (k === "assessmentDate") {
        setAssessmentData(prevState => ({
          ...prevState,
          assessmentDate: v,
          timeSpan: updateTimeSpanForAssessmentDate(prevState.timeSpan, v),
        }));
      } else {
        setAssessmentData(prevState => {
          return {
            ...prevState,
            [k]: v,
          };
        });
      }
    }, 60),
    []
  );

  function commonAddressesForTeam(
    teams: GetTeamInformationResponse["profile"]["teams"] | undefined,
    currentTeamId: string | null
  ) {
    const team = currentTeam(teams, currentTeamId);

    if (!team) {
      return [];
    }

    return (
      team.commonAddresses?.items.map((ca, i) => ({
        ...ca,
        id: i.toString(),
      })) || []
    );
  }

  function currentTeam(
    teams: GetTeamInformationResponse["profile"]["teams"] | undefined,
    currentTeamId: string | null
  ) {
    if (!teams || !teams.items || !teams.items.length) {
      return null;
    }

    const team =
      currentTeamId && teams.items.find(teamUser => teamUser.amhpTeam.id === currentTeamId)
        ? teams.items.find(teamUser => teamUser.amhpTeam.id === currentTeamId)
        : teams.items[0];

    if (!team) {
      return null;
    }
    return team.amhpTeam;
  }

  function displayNameForTeam(
    teams: GetTeamInformationResponse["profile"]["teams"] | undefined,
    currentTeamId: string | null
  ) {
    const team = currentTeam(teams, currentTeamId);

    if (!team || !teams || !teams.items || teams.items.length < 2) {
      return undefined;
    }

    return team.name;
  }

  function teamMembersForTeam(
    teams: GetTeamInformationResponse["profile"]["teams"] | undefined,
    currentTeamId: string | null
  ) {
    const team = currentTeam(teams, currentTeamId);

    if (!team) {
      return [];
    }

    return (
      team.users?.items
        .filter(notEmpty)
        ?.map(a => ({ id: a.amhp?.id, name: a.amhp?.name, deleted: a.amhp?.deleted }))
        // remove duplicates
        .filter((member, i, a) => a.findIndex(x => x.id === member.id) === i)
        // remove deleted
        .filter((member, i, a) => !member?.deleted ?? false)
        // sort ascending
        .sort((a, b) => {
          return (a.name > b.name && 1) || -1;
        }) || []
    );
  }

  function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
    return value !== null && value !== undefined;
  }

  const onNhsNumberChange = (value: string) => {
    if (value.length <= 10) {
      setNhsNumber(formatNhsNumber(value));
      setAssessmentData({
        ...assessmentData,
        patientNhsNumber: formatNhsNumber(value),
      });
      setAssessmentRecoil({
        ...assessmentData,
        patientNhsNumber: formatNhsNumber(value),
      });
    }
  };

  const submitText = () => {
    setTimeout(
      () =>
        setAssessmentData({
          ...assessmentData,
          patientName: patientFullName,
          patientNhsNumber: nhsNumber,
          doctorName,
          locationName: {
            ...assessmentData.locationName,
            addressNotes: addressNotes,
          },
        }),
      100
    );
    Keyboard.dismiss();
  };

  const locationNameRender = React.useCallback(renderProps => {
    const { style, value } = renderProps;
    value !== "" ? setAvailableLocation(true) : setAvailableLocation(false);
    return (
      <TouchableRipple
        onPress={() => {
          Keyboard.dismiss();
          locationSearchFun(!locationSearch, commonAddresses);
          setAssessmentData({
            ...assessmentData,
            showAutocomplete: true,
          });
        }}
        testID={"LocationInputRipple"}
      >
        <Text format={TypographyType.Regular} style={style}>
          {value}
        </Text>
      </TouchableRipple>
    );
  }, []);

  const saveData = async (setLastException: (arg0: S12Error) => void, createAssessment: CreateAssessmentFunc) => {
    return createAssessment({
      variables: {
        input: checkMutationInput({
          patientName: finalData.patientName ? finalData.patientName : "",
          patientNhsNumber: finalData.patientNhsNumber ? finalData.patientNhsNumber : "",
          location: finalData.location,
          locationName: finalData.locationName,
          ccgId: finalData.ccgId,
          assessmentDate: dayjs(assessmentDate).format("YYYY-MM-DD"),
          amhpId: finalData.amhpId ? finalData.amhpId : "XXX",
          amhpTeamId: props.formMetadata.amhpTeamId,
          createdAt: new Date().toISOString(),
        }),
      },
    })
      .then((res: any) => {
        const assessmentId = res.data.assessment.id;
        recordEvent(AnalyticsEvent.ASSESSMENT_CREATE, {
          locationName: finalData.locationName.postcode,
          location: `${finalData.location.lat},${finalData.location.lon}`,
          ...(assessmentData.doctorName && {
            doctorName: assessmentData.doctorName,
          }),
          ...(assessmentData.doctorGender && {
            doctorGender: assessmentData.doctorGender,
          }),
          ...(assessmentData.doctorLanguages &&
            assessmentData.doctorLanguages.length && {
              doctorLanguages: assessmentData.doctorLanguages.join(","),
            }),
          ...(assessmentData.doctorSpecialties &&
            assessmentData.doctorSpecialties.length && {
              doctorSpecialties: assessmentData.doctorSpecialties.join(","),
            }),
          amhpId: finalData.amhpId ? finalData.amhpId : "XXX",
          teamId: props.formMetadata.amhpTeamId,
          assessmentId: assessmentId,
        });
        return assessmentId;
      })
      .catch(buildErr(createAssessmentError, setLastException));
  };
  const confirmCreateAssessment = () => {
    setDisabled(true);
    props.formMetadata.amhpTeamId &&
      saveData(setLastException, createAssessment)
        .then((assessmentId: string) => {
          attachForm(assessmentId);
        })
        .catch(buildErr(createAssessmentError, setLastException));
  };

  const saveFormToAssessment = (successMessage: string, assessmentId: string) => {
    saveForm(v2, {
      id: props.formMetadata.id,
      assessmentId: assessmentId,
      amhpTeamId: props.formMetadata.amhpTeamId,
      amhpTeamInboxId: "!!!",
      type: props.formMetadata.type,
      authors: props.formMetadata.authors,
      version: props.formMetadata.version,
      nextVersion: props.formMetadata.version + 1,
      status: FormStatus.s40_signed,
    }).then(response => {
      setDisabled(false);
      if ("isError" in response && response.isError && response.message?.includes("Network Error")) {
        const error = new S12Error(saveFormError);
        setLastException(error);
        buildErr(saveFormError, setLastException)("Network Error");
      } else {
        if (finalData.patientName !== props.patientName) {
          updateAssessmentMtn({
            variables: {
              input: checkMutationInput<UpdateAssessmentInput>({
                id: assessmentId,
                patientName: finalData.patientName ? finalData.patientName : "",
              }),
            },
          });
        }
        setRefreshTeamInboxRequired(true);
        props.navigation.navigate("AMHPAssessmentsTab");
        setTimeout(() => {
          props.navigation.navigate(RouteKeys.AssessmentDetailsScreen, {
            assessmentId: assessmentId,
          });
        }, 1000);
        closeBottomSheet();
        setMessage(successMessage);
      }
    });
  };

  function attachForm(assessmentId: string) {
    const assesmentDate = props.examinationDate ? dayjs(props.examinationDate) : dayjs();
    const assessmentDateTime = assesmentDate.hour(12).minute(0);
    const assessmentDateTimeString = assessmentDateTime.format();

    const variables = {
      doctorId: props.doctorId,
      assessmentId: assessmentId,
      time: assessmentDateTimeString,
    };

    const createDoctorVisitPromise = shouldUseCreateDoctorVisitV2
      ? API.post("/createDoctorVisit", variables)
      : createDoctorVisit({
          variables,
        });

    createDoctorVisitPromise
      .then(() => {
        refetch(variables);
        saveFormToAssessment("Visit added and form successfully attached", assessmentId);
      })
      .catch(buildErr({ ...addVisitError, additional: props.doctorId }, setLastException));
  }

  useEffect(() => {
    setAssessmentRecoil(assessmentData);
  }, []);

  return (
    <ContentWrap>
      <Text format={TypographyType.HeadingSmall}>Add Details</Text>
      {teamDisplayName && (
        <View style={styles.teamContainer}>
          <Icon name="group" size={24} color={color.textExtraLight} style={styles.teamIcon} />
          <View style={styles.teamText}>
            <Text format={TypographyType.Micro} color={palette.greyBlue}>
              Team
            </Text>
            <Text format={TypographyType.Regular}>{teamDisplayName}</Text>
          </View>
        </View>
      )}
      <View style={[styles.requiredInputContainer, !isWebView && styles.assignedAMHPMobile]}>
        <Select
          label="Assigned AMHP"
          icon="person-outline"
          value={assessmentData.amhpId !== "" ? assessmentData.amhpId : user !== null ? user.id : ""}
          onBlur={() => setTimeout(() => patientNameInput.current && patientNameInput.current.focus(), 20)}
          onValueChange={(_v, i, d: [{ value: string; label: string }]) => {
            onChangeState("amhpId", d[i].value);
            onChangeState("amhpName", d[i].label);
            setAssessmentRecoil({
              ...assessmentData,
              amhpId: d[i].value,
              amhpName: d[i].label,
            });
          }}
          options={teamMembers.length > 0 ? teamMembers : []}
        />
      </View>
      <TextInput
        icon="contacts"
        ref={patientNameInput}
        label="Patient's full Legal Name"
        autoCorrect={false}
        returnKeyType="next"
        onSubmitEditing={submitText}
        value={patientFullName}
        onChangeText={v => {
          setPatientFullName(v);
          setAssessmentData({
            ...assessmentData,
            patientName: v,
          });
          setAssessmentRecoil({
            ...assessmentData,
            patientName: v,
          });
        }}
        testID="CreateAssessment__patient-name__input"
      />
      {!!nhsNumberRequired && (
        <TextInput
          icon="local-hospital"
          keyboardType="number-pad"
          label="Patient's NHS number"
          value={nhsNumber}
          maxLength={10}
          onChangeText={onNhsNumberChange}
        />
      )}

      <TextInput
        ref={locationNameInput}
        icon={"location-on"}
        label="Assessment Location"
        value={
          quickAssessmentLocationRecoil && quickAssessmentLocationRecoil.locationName.address
            ? quickAssessmentLocationRecoil.locationName.address +
              (quickAssessmentLocationRecoil.locationName.city
                ? ", " + quickAssessmentLocationRecoil.locationName.city
                : "")
            : ""
        }
        render={locationNameRender}
        testID="CreateAssessment__location-name__input"
      />
      <TextInput
        icon="note"
        label="Optional Location Notes"
        autoCorrect={true}
        spellCheck={true}
        returnKeyType="go"
        value={addressNotes || undefined}
        multiline={true}
        selectTextOnFocus={true}
        numberOfLines={3}
        supplementaryInfo="Add more information if required, e.g. Ward Name"
        onChangeText={v => {
          setAddressNotes(v);
          setAssessmentData({
            ...assessmentData,
            locationName: {
              postcode: "",
              addressNotes: v,
            },
          });
          setAssessmentRecoil({
            ...assessmentData,
            locationName: {
              postcode: "",
              addressNotes: v,
            },
          });
        }}
      />
      <Text format={TypographyType.RegularBold}>Date</Text>
      <DualDatePicker
        ref={dateInput}
        min={dayjs(assessmentDate)
          .subtract(30, "day")
          .toDate()}
        max={dayjs()
          .add(28, "day")
          .toDate()}
        value={assessmentDate}
        onValueChange={value => {
          onChangeState("assessmentDate", value);
          setAssessmentRecoil({
            ...assessmentData,
            assessmentDate: value,
            timeSpan: updateTimeSpanForAssessmentDate(assessmentData.timeSpan, value),
          });
        }}
      />
      <ButtonList>
        <Button
          onPress={confirmCreateAssessment}
          disabled={availableLocation && patientFullName !== "" ? loading || gtaLoading || disabled : true}
        >
          {!disabled ? "Confirm" : "Creating Assessment"}
        </Button>
      </ButtonList>
    </ContentWrap>
  );
};

const styles = StyleSheet.create({
  bottom20: { paddingBottom: spacing[20] },
  center: { textAlign: "center" },
  teamContainer: {
    flexDirection: "row",
    alignItems: "flex-end",
    marginBottom: spacing[20],
    marginTop: spacing[20],
  },
  teamText: {
    flexDirection: "column",
  },
  teamIcon: {
    marginRight: spacing[20],
  },
  requiredInputContainer: {
    flexDirection: "row",
    flex: 1,
    justifyContent: "center",
    alignItems: "stretch",
  },
  assignedAMHPMobile: {
    marginBottom: spacing[30],
  },
});
