import React, { useMemo } from "react";
import { StyleSheet, View } from "react-native";
import { TouchableRipple } from "react-native-paper";

import { mqWeb } from "../../utils/helpers";
import { TypographyType } from "@/models/Typography";
import { palette, spacing } from "../../theme";

import { Text } from "../index";
import { color } from "../../theme/color";

const Card = (props: {
  accentColor: string;
  indicatorColor: string;
  heading: string;
  onPress: () => void;
  headingContent?: React.ReactNode;
  infoHeading?: string;
  infoList?: React.ReactNode | React.ReactNode[];
}) => {
  const { accentColor, heading, headingContent, indicatorColor, infoHeading, infoList, onPress } = props;

  const { Micro, RegularBold } = TypographyType;
  const isWebView = mqWeb();
  const containerStyles = useMemo(() => [styles.card, isWebView ? styles.cardWeb : {}], []);

  const backgroundHeadingStyles = useMemo(() => [styles.indicator, { backgroundColor: indicatorColor }], [
    indicatorColor,
  ]);

  const borderMaskTop = useMemo(() => [styles.borderMask, styles.borderMaskTop], []);
  const borderMaskBottom = useMemo(() => [styles.borderMask, styles.borderMaskBottom], []);
  const infoBorder = useMemo(() => [styles.infoBorder, { backgroundColor: accentColor }], [accentColor]);

  return (
    <TouchableRipple rippleColor={palette.rippleColor} style={containerStyles} onPress={onPress}>
      <>
        <View style={styles.headingBar}>
          <View style={backgroundHeadingStyles} />
          <Text format={RegularBold} style={styles.headingText}>
            {heading}
          </Text>
          {headingContent && <View style={styles.headingRightContent}>{headingContent}</View>}
        </View>

        {infoList && (!Array.isArray(infoList) || infoList.length > 0) && (
          <View style={styles.infoContainer}>
            {infoHeading && (
              <Text format={Micro} style={styles.infoHeadingText}>
                {infoHeading}
              </Text>
            )}
            <View style={styles.info}>
              <View style={infoBorder} />
              <View style={borderMaskTop} />
              <View style={borderMaskBottom} />
              {infoList}
            </View>
          </View>
        )}
      </>
    </TouchableRipple>
  );
};

const styles = StyleSheet.create({
  borderMask: {
    position: "absolute",
    left: 23,
    width: 4,
    backgroundColor: palette.white,
  },
  borderMaskTop: {
    top: 0,
    height: 22,
  },
  borderMaskBottom: {
    bottom: 0,
    height: 27,
  },

  card: {
    marginBottom: spacing[10],
    backgroundColor: palette.white,
    overflow: "hidden",
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    borderBottomRightRadius: 6,
    borderBottomLeftRadius: 6,
    shadowColor: color.primaryShadow,
    shadowOpacity: 0.3,
    shadowOffset: { width: 0, height: 0 },
    shadowRadius: 6,
  },
  cardWeb: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
  },

  headingBar: {
    position: "relative",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    // cursor: "pointer",
  },

  headingRightContent: {
    paddingHorizontal: spacing[25],
    flexShrink: 0,
  },

  headingText: {
    paddingLeft: spacing[25],
    paddingVertical: spacing[25],
    overflow: "hidden",
    flexWrap: "wrap",
    width: 0,
    flexGrow: 1,
  },

  indicator: {
    position: "absolute",
    left: 0,
    top: 0,
    width: 4,
    height: "100%",
  },

  info: {
    marginLeft: spacing[5],
    paddingHorizontal: spacing[25],
    paddingTop: spacing[15],
    paddingBottom: spacing[5],
  },

  infoContainer: {
    borderTopWidth: 1,
    borderTopColor: palette.cloud,
  },

  infoBorder: {
    position: "absolute",
    width: 1,
    height: "100%",
    left: 25,
    top: 0,
    opacity: 0.3,
  },

  infoHeadingText: {
    paddingLeft: spacing[25],
    paddingTop: spacing[15],
    color: palette.greyBlue,
    backgroundColor: palette.white,
    textTransform: "uppercase",
    zIndex: 1,
  },
});

export default React.memo(Card);
