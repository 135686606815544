import React, { useEffect, useState, useMemo } from "react";
import { useSetRecoilState } from "recoil";
import dayjs, { Dayjs } from "dayjs";
import API from "@/api";

import { Gender, NearbyS12DoctorsQueryVariables } from "libs/types/API";
import { searchResults } from "../../utils/recoil/index";

import { ErrorMessage } from "../Error";
import SearchResults from "./SearchResults";
import { SearchResultItem } from "./SearchResults.props";
import { AnalyticsEvent } from "libs/analytics/events";
import { recordEvent } from "../../utils/analytics";

type Variables = NearbyS12DoctorsQueryVariables & {
  locationName: string;
  timeSpan: {
    start: Dayjs;
    end: Dayjs;
  };
  assessment?: {
    id: string;
  };
  doctorGender?: Gender | null;
  doctorLanguages?: string[];
  doctorSpecialties?: string[];
  doctorName?: string;
};

type DataType = {
  data?: {
    // doctors: { items: SearchResponseItem[]; total: number };
    assessment?: {
      doctorVisits?: { items: { id: string; doctor: { id: string } }[] };
    };
  };
};

type SearchResultsContextProps = Variables & DataType;

const SearchResultsMemo = React.memo(SearchResults);

const SearchResultsContext: React.FC<SearchResultsContextProps> = ({ data, ...props }) => {
  const setSearchResults = useSetRecoilState(searchResults);
  const [doctors, setDoctors] = useState<SearchResultItem[] | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const { assessment } = data || {};

  const resultData = useMemo(
    () =>
      props.assessment && {
        id: props.assessment.id,
        doctors: assessment && assessment.doctorVisits && assessment.doctorVisits.items.map(i => i.doctor),
        doctorVisits: assessment && assessment.doctorVisits && assessment.doctorVisits.items,
      },
    [props.assessment, assessment]
  );

  const {
    doctorGender,
    doctorLanguages,
    doctorSpecialties,
    location,
    timeSpan,
    distance,
    locationName,
    doctorName,
  } = props;

  useEffect(() => {
    async function getNearbyS12Doctors() {
      setLoading(true);

      try {
        const response = await API.get("/nearbyS12Doctors", {
          params: {
            location,
            distance,
            hasAssessment: !!props.assessment, // will work this one out later
            assessmentId: props.assessment && props.assessment.id,
            timeSpan: {
              start: dayjs(timeSpan.start).toISOString(),
              end: dayjs(timeSpan.end).toISOString(),
            },
            doctorName: doctorName ? doctorName.replace(/^d(octo)?r\.?\s/i, "") : "", // remove doctor or dr from name
            doctorGender,
            doctorLanguages: JSON.stringify(doctorLanguages),
            doctorSpecialties: JSON.stringify(doctorSpecialties),
          },
        });

        const docs = response.data.doctors.items as SearchResultItem[];

        setDoctors(docs);
        setSearchResults(docs);
        setLoading(false);

        // Record events
        recordEvent(
          AnalyticsEvent.FIND_DOCTORS,
          {
            locationName: locationName,
            location: `${location.lat.toString()},${location.lon.toString()}`,
          },
          {
            distance,
            "start hour": timeSpan.start.hour(),
            "timeframe (hrs)": timeSpan.end.diff(timeSpan.start, "hour", true),
            "results returned": docs.length,
            "available doctors": docs.filter(d => !!d.nextAvailability).length,
          }
        );
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    }
    if (!doctors) {
      getNearbyS12Doctors();
    }
  }, [doctors]);

  if (error) {
    return <ErrorMessage message={error} />;
  }

  return (
    <SearchResultsMemo
      assessment={resultData}
      isLoading={loading}
      results={loading ? undefined : doctors}
      searchCriteria={props}
    />
  );
};

export default SearchResultsContext;
