import React, { useMemo } from "react";
import { View, StyleSheet } from "react-native";

import Text from "../../Text";
import ContentWrap from "../../ContentWrap/ContentWrap";

import { TypographyType } from "@/models/Typography";
import { AssessmentDateInfo } from "@/models/UserProfile";
import { sortUserAssessments, earliestFutureOrLastAssessementVisitTime } from "../../../utils/assessments";
import { formatShortDate } from "libs/dates";
import { color, spacing } from "../../../theme";
import { mqWeb } from "@/utils/helpers";

interface DashboardAssessmentsProps {
  assessments: ({
    id: string;
    patientName: string;
  } & AssessmentDateInfo)[];
}

const DashboardAssessments: React.FC<DashboardAssessmentsProps> = ({ assessments }) => {
  const isWebView = mqWeb();
  const userAssessments = useMemo(
    () =>
      assessments.length
        ? sortUserAssessments<
            {
              id: string;
              patientName: string;
            } & AssessmentDateInfo
          >(assessments)
        : false,
    [assessments]
  );

  const { HeadingMedium, HeadingSmall, Regular, RegularBold, Small, Micro } = TypographyType;

  return (
    <ContentWrap>
      <Text format={HeadingMedium}>Assessments</Text>
      <Text format={HeadingSmall} style={styles.headingSmall}>
        Next Assessment
      </Text>

      {!userAssessments && (
        <Text format={Small} style={styles.noAssessmentsText}>
          You do not currently have any saved assessments.
        </Text>
      )}

      {userAssessments && (
        <>
          {userAssessments.active.length > 0 && (
            <View style={isWebView ? styles.nextAssessmentWeb : styles.nextAssessmentMobile}>
              <View style={styles.detail}>
                <Text format={Micro} style={styles.detailLabel}>
                  Patient Name
                </Text>
                <Text format={Regular}>{userAssessments.active[0].patientName}</Text>
              </View>

              <View style={styles.detail}>
                <Text format={Micro} style={styles.detailLabel}>
                  {userAssessments.active[0].doctorVisits &&
                  userAssessments.active[0].doctorVisits.items &&
                  userAssessments.active[0].doctorVisits.items.length
                    ? "Next Visit Time"
                    : "Assessment Date"}
                </Text>
                <Text format={Regular}>
                  {formatShortDate(earliestFutureOrLastAssessementVisitTime(userAssessments.active[0]))}
                </Text>
              </View>
            </View>
          )}

          <View style={styles.stats}>
            <Text format={HeadingSmall} style={styles.headingSmall}>
              Assessment Statistics
            </Text>
            <View style={styles.statsContent}>
              <View style={styles.stat}>
                <Text format={RegularBold} style={styles.statText}>
                  {userAssessments.count.total}
                </Text>
                <View style={styles.statLabel}>
                  <Text format={Micro} style={styles.statLabelText}>
                    Total
                  </Text>
                </View>
              </View>

              <View style={styles.stat}>
                <Text format={RegularBold} style={styles.statText}>
                  {userAssessments.count.active}
                </Text>
                <View style={styles.statLabel}>
                  <Text format={Micro} style={styles.statLabelText}>
                    Active
                  </Text>
                </View>
              </View>

              <View style={styles.stat}>
                <Text format={RegularBold} style={styles.statText}>
                  {userAssessments.count.archived}
                </Text>
                <View style={styles.statLabel}>
                  <Text format={Micro} style={styles.statLabelText}>
                    Archived
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </>
      )}
    </ContentWrap>
  );
};

export default DashboardAssessments;

const styles = StyleSheet.create({
  detail: {
    flexBasis: "50%",
    marginBottom: spacing[30],
  },

  detailLabel: {
    color: color.textExtraLight,
    marginBottom: spacing[5],
  },

  headingSmall: {
    marginBottom: spacing[30],
  },

  nextAssessmentWeb: {
    display: "flex",
    flexDirection: "row",
  },

  nextAssessmentMobile: {
    display: "flex",
    flexDirection: "column",
  },

  noAssessmentsText: {
    color: color.textLight,
    marginBottom: spacing[30],
  },

  stats: {
    marginTop: spacing[10],
  },

  statsContent: {
    flexDirection: "row",
  },

  stat: {
    alignItems: "center",
    marginRight: spacing[60],
  },

  statText: {
    marginBottom: spacing[5],
  },

  statLabel: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },

  statLabelText: {
    color: color.textExtraLight,
    textTransform: "uppercase",
  },
});
