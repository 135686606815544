import { atom, selector } from "recoil";
import { saveString, loadString, load } from "../../utils/storage";
import {
  UserDetails,
  Signup,
  DuplicateAssessmentState,
  MapCriteria,
  ModalData,
  DialogData,
  BottomSheetData,
  OfflineMHAFormData,
  QuickAssessmentState,
  QuickAssessmentLocationState,
} from "./props";
import { CognitoUser } from "@aws-amplify/auth";
import { CreateAssessmentState } from "../../components/CreateAssessment/CreateAssessment.props";
import { ScreenProps as SearchResults } from "../../screens/amhp/SearchResultsScreen";
import { EditableAvailabilityState } from "libs/types/availability";
import { S12Error } from "@/models/Error";
import dayjs, { Dayjs } from "dayjs";
import { SearchResultItem } from "../../components/SearchResults/SearchResults.props";
import { NavigationState } from "@react-navigation/core";
import { BottomTabNavigationOptions } from "@react-navigation/bottom-tabs";

export const apiStatus = {
  isComplete: false,
  isErrored: false,
  inProgress: false,
  isSuccess: false,
  error: null,
};

// Navigation Items
export const navigationState = atom<null | NavigationState>({
  key: "navigationState",
  default: null,
});

interface NavigationActions {
  dispatch: (action: string) => boolean;
  goBack: (routeKey?: string | null) => boolean;
  dismiss: () => boolean;
  pop: () => boolean;
  navigate<
    T extends {
      [key: string]: any;
    }
  >(options: {
    routeName:
      | string
      | {
          routeName: string;
          params?: T;
          action?: string;
          key?: string;
        };
    params?: T;
    action?: string;
    key?: string;
  }): boolean;
}

// this isn't a selector b/c selectors don't yet memoise. revisit when recoil develops more
export const navigationActions = atom<NavigationActions>({
  key: "navigationActions",
  default: {
    pop: () => false,
    dispatch: (action: string) => false,
    goBack: () => false,
    dismiss: () => false,
    navigate: arg => false,
  },
});

// BOTTOM NAV TAB DRAWER
export const tabDrawerOpen = atom({
  key: "tabDrawerOpen",
  default: false,
});

export const hideBottomNav = atom({
  key: "hideBottomNav",
  default: false,
});

// USER DATA
export const signup = atom<Signup>({
  key: "signup",
  default: {
    email: "",
    password: "",
    challenge: "",
  },
});

let cognitoUser: CognitoUser | any;

export const getCognitoUser = (signalNumber: number) => !isNaN(signalNumber) && cognitoUser;
export const setCognitoUser = (val: CognitoUser | any) => (cognitoUser = val);
export const cognitoUserSignal = atom<number>({
  key: "cognitoUserSignal",
  default: 0,
});

export const reset = atom<Signup>({
  key: "reset",
  default: { email: "", password: "" },
});
export const justLoggedIn = atom({ key: "justLoggedIn", default: false });

// BADGES
export const doctorClaimsTabBadge = atom<BottomTabNavigationOptions["tabBarBadge"]>({
  key: "doctorClaimsTabBadge",
  default: 0,
});

// SEARCH CRITERIA
export const assessment = atom<null | CreateAssessmentState>({
  key: "assessment",
  default: null,
});

// QUICK ASSESSMENT
export const quickAssessment = atom<null | QuickAssessmentState>({
  key: "quickAssessment",
  default: null,
});

// QUICK ASSESSMENT LOCATION STATE
export const quickAssessmentLocationState = atom<null | QuickAssessmentLocationState>({
  key: "quickAssessmentLocationState",
  default: null,
});

export const duplicateAssessment = atom<null | DuplicateAssessmentState>({
  key: "duplicateAssessment",
  default: null,
});

export const searchCriteria = atom<null | SearchResults>({
  key: "searchCriteria",
  default: null,
});

export const mapCriteria = atom<null | MapCriteria>({
  key: "mapCriteria",
  default: null,
});
export const searchResults = atom<null | SearchResultItem[]>({
  key: "searchResults",
  default: null,
});

// HOLIDAY DATA

export const holidayStartDate = atom<Dayjs>({
  key: "holidayStartDate",
  default: dayjs(),
});
export const holidayEndDate = atom<Dayjs>({
  key: "holidayEndDate",
  default: dayjs(),
});

export const holidayNotes = atom({ key: "holidayNotes", default: "" });
export const holidayVisibility = atom({
  key: "holidayVisibility",
  default: true,
});
export const resetHolidayForm = atom({
  key: "resetHolidayForm",
  default: false,
});
export const holidayDateError = atom({
  key: "holidayDateError",
  default: false,
});

// EDIT AVAILABILITY
export const editableAvailability = atom<null | EditableAvailabilityState>({
  key: "editableAvailability",
  default: null,
});

// VIEW CONTROLLING
export const loading = atom({ key: "loading", default: false });
export const snackbarMessage = atom<string>({
  key: "snackbarMessage",
  default: "",
});

export const refreshAssessmentsRequired = atom({
  key: "refreshAssessmentsRequired",
  default: false,
});
export const refreshClaimsRequired = atom({
  key: "refreshClaimsRequired",
  default: false,
});
export const refreshTeamInboxRequired = atom({
  key: "refreshTeamInboxRequired",
  default: false,
});
export const refreshAmhpDashboardRequired = atom({
  key: "refreshAmhpDashboardRequired",
  default: false,
});
export const refreshDoctorFormInboxRequired = atom({
  key: "refreshDoctorFormInboxRequired",
  default: false,
});
export const refreshDoctorDraftFormInboxRequired = atom({
  key: "refreshDoctorDraftFormInboxRequired",
  default: false,
});
export const loadingNavigation = atom({
  key: "loadingNavigation",
  default: false,
});
export const showBottomSheet = atom({
  key: "showBottomSheet",
  default: false,
});

// ERRORS
export const lastException = atom<null | S12Error>({
  key: "lastException",
  default: null,
});

// POSSIBLE REMOVE
export const availabilityCalendarDate = atom<null | Dayjs>({
  key: "availabilityCalendarDate",
  default: null,
});

// GLOBAL MODAL
export const globalModalData = atom<ModalData | null>({
  key: "globalModalData",
  default: null,
});

// GLOBAL DIALOG
export const globalDialogData = atom<DialogData | null>({
  key: "globalDialogData",
  default: null,
});

// GLOBAL BOTTOMSHEET
export const globalBottomSheetData = atom<BottomSheetData<Record<string, unknown>> | null>({
  key: "globalBottomSheetData",
  default: null,
});
export const globalBottomSheetVisible = atom<boolean>({
  key: "globalBottomSheetVisible",
  default: false,
});
export const globalBottomSheetScrollToTop = atom<boolean>({
  key: "globalBottomSheetScrollToTop",
  default: false,
});

// Version update
export const appUpdateDimissed = atom({
  key: "appUpdateDimissed",
  default: false,
});

// ENVIRONMENTS
const isDemoEnvironmentInternal = atom({
  key: "isDemoEnvironmentInternal",
  default: false,
});

export const isDemoEnvironment = selector<boolean>({
  key: "isDemoEnvironment",
  get: ({ get }) => get(isDemoEnvironmentInternal),
  set: ({ set }, val) => {
    saveString("isDemoEnvironment", val.toString());
    set(isDemoEnvironmentInternal, val);
  },
});

// offline form mutations
export const offlineMHAForm = atom<OfflineMHAFormData | null>({
  key: "offlineFormMutations",
  default: null,
});

// Amhp Team if multiple exist
const amhpTeamIdInternal = atom<string | null>({
  key: "amhpTeamId_internal",
  default: loadString("amhpTeam").then(p => p || null),
});

export const pushToken = atom<string | null>({
  key: "pushToken",
  default: null,
});

// Doctor module selected amhp team id
export const selectedAmhpTeamId = atom<string | "">({
  key: "selectedAmhpTeamId",
  default: "",
});

export const amhpTeamId = selector<string | null>({
  key: "amhpTeamId",
  get: ({ get }) => {
    const res = get(amhpTeamIdInternal);
    if (res) return res;
    return null;
  },
  set: ({ set }, val) => {
    set(amhpTeamIdInternal, val);
    if (typeof val === "string") {
      saveString("amhpTeam", val);
    }
  },
});

export const userInternal = atom<UserDetails | null>({
  key: "userInternal",
  default: null,
});

export const userDetails = selector<UserDetails | null>({
  key: "user",
  get: ({ get }) => {
    const res = get(userInternal);
    return res;
  },
  set: ({ set, get }, val) => {
    set(userInternal, val);
    try {
      const teamId = get(amhpTeamId);
      if (typeof teamId === "string" && val && "teams" in val && val.teams.includes(teamId)) {
        set(amhpTeamId, teamId);
      }
    } catch (e) {
      // loadable not yet ready
    }
  },
});

export interface MeetingPrefs {
  audio: { value: string; label: string } | null;
  microphone: { value: string; label: string } | null;
  video: { value: string; label: string } | null;
  quality: {
    width: number;
    height: number;
    frameRate: number;
    maxBandwidthKbps: number;
    value: any;
  } | null;
}

// Video Meeting Prefs
export const meetingPrefs = atom<MeetingPrefs>({
  key: "meetingPrefs",
  default: load("meetingPrefs").then(
    (prefs: any) =>
      prefs || {
        audio: null,
        microphone: null,
        video: null,
        quality: null,
      }
  ),
});

export const mhaFormApiStatus = atom<typeof apiStatus>({
  key: "mhaFormApiStatus",
  default: apiStatus,
});

export const ccgFilter = atom<string | null>({
  key: "ccgFilter",
  default: null,
});

// App login form password screen
export const initialLogin = atom({
  key: "initialLogin",
  default: true,
});
