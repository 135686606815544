import React, { useState, useMemo } from "react";
import { StyleSheet, View, Linking, Platform } from "react-native";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { BackButtonProps } from "@/components/BackButton/BackButton.props";
import { AppScreen } from "@/layouts/AppScreen/AppScreen";
import { RouteKeys } from "@/navigationv2";
import { DoctorTabRoutes, ScreenNavigationProps, ScreenOptions, TeamSelectRoutes } from "@/navigationv2/types";
import { createScreenTitle } from "@/navigationv2/utilities";
import { color, spacing, palette } from "@/theme";
import { ContentWrap } from "../components/ContentWrap";
import Loading from "../components/Loading";
import Text from "../components/Text";
import Select from "../components/Select";
import ItemSpacer from "../components/ItemSpacer";
import { Button, ButtonList } from "../components/Button";
import { TypographyType } from "@/models/Typography";

import { useRecoilValue, useSetRecoilState } from "recoil";
import { userDetails, selectedAmhpTeamId } from "@/utils/recoil";
import { amhpTeamInbox } from "libs/utils/featureFlags";
import { ParamListBase, useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";

const backButtonConfig: BackButtonProps = {
  enabled: true,
  float: true,
  color: color.textWhite,
};

interface PropTypes extends ScreenNavigationProps<DoctorTabRoutes, TeamSelectRoutes, RouteKeys.TeamSelectScreen> {}

const amhpTeamQuery = gql`
  query GetSite($id: ID!) {
    getSite(id: $id) {
      amhpTeams {
        items {
          id
          name
          emails
          phoneNumbers
          mhaInboxInstructions
          featureFlags
        }
      }
    }
  }
`;

const TeamSelectScreen = (props: PropTypes) => {
  const user = useRecoilValue(userDetails);
  const setSelectedAmhpTeamId = useSetRecoilState(selectedAmhpTeamId);
  const [selectedTeamIndex, setSelectedTeamIndex] = useState<number>(0);
  const navigation = useNavigation<StackNavigationProp<ParamListBase>>();

  const { loading, error, data } = useQuery<{
    getSite: {
      amhpTeams: {
        items: {
          id: string;
          name: string;
          emails: string[] | null;
          phoneNumbers: string[] | null;
          mhaInboxInstructions: string | null;
          featureFlags: string;
        }[];
      };
    };
  }>(amhpTeamQuery, {
    variables: {
      id: user?.sites?.[0],
    },
    fetchPolicy: "cache-and-network",
  });

  // creating amhp team dropdown -> check if amhp team has amhpTeamInbox feature enabled &
  // is not the currently assigned team, to avoid user "reassigning" form to same team
  const amhpTeams = useMemo(
    () => [
      {
        name: "- Select -",
        id: "",
      },
      ...(data?.getSite.amhpTeams.items.filter(a => amhpTeamInbox(a.featureFlags)).filter(a => a.id) || []),
    ],
    [data?.getSite.amhpTeams.items]
  );

  const selectedTeam = amhpTeams[selectedTeamIndex];
  return (
    <AppScreen contextLabel="AMHP Team" pageTitle="Select AMHP Team" backButton={backButtonConfig}>
      <ContentWrap>
        {loading ? (
          <Loading text="Loading AMHP Teams" />
        ) : error ? (
          <Text format={TypographyType.HeadingSmall} style={styles.smallHeading}>
            There was an error loading AMHP teams.
          </Text>
        ) : amhpTeams.length <= 1 ? (
          <Text format={TypographyType.HeadingSmall} style={styles.smallHeading}>
            You cannot send this form to any AMHP teams.
          </Text>
        ) : (
          <>
            <ItemSpacer gap={20}>
              <Select
                label="Select AMHP Team"
                value={selectedTeam.id}
                onValueChange={(_v, i) => {
                  setSelectedTeamIndex(i);
                }}
                options={amhpTeams}
              />
            </ItemSpacer>
          </>
        )}
        <ButtonList>
          <Button
            onPress={() => {
              setSelectedAmhpTeamId(selectedTeam.id);
              navigation.navigate(RouteKeys.MHAFormScreen, {
                formType: props.route.params.formType,
              });
            }}
            disabled={selectedTeam.id === ""}
          >
            Next
          </Button>
          <Text
            format={TypographyType.SmallBold}
            color={palette.blue}
            onPress={() => navigation.goBack()}
            style={styles.center}
          >
            Go Back
          </Text>
        </ButtonList>
      </ContentWrap>
    </AppScreen>
  );
};

const screenOptions: ScreenOptions = {
  title: createScreenTitle("Select AMHP Team"),
};

TeamSelectScreen.screenOptions = screenOptions;

export default TeamSelectScreen;

const styles = StyleSheet.create({
  smallHeading: {
    marginBottom: spacing[15],
  },

  // confirmation: { flexDirection: "row", paddingBottom: spacing[30], flexWrap: "wrap" },

  center: { textAlign: "center" },
  // contactDetails: {
  //   marginTop: spacing[20],
  // },

  // infoText: {
  //   marginVertical: spacing[20],
  // },
});
