import { FormType } from "libs/types/mhaForms";

export function isAmhpForm(type: FormType): boolean {
  return type === FormType.A2 || type === FormType.A6 || type === FormType.A10;
}

export function isConveyanceForm(type: FormType): boolean {
  return type === FormType.AUTHORITY_TO_CONVEY;
}

export function isUnofficialForm(type: FormType): boolean {
  return type === FormType.STATEMENT_OF_REASON;
}

export const isPsuedoAmhpForm = (type: FormType) =>
  isAmhpForm(type) || isConveyanceForm(type) || isUnofficialForm(type);
