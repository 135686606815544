import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Platform, StyleSheet, View } from "react-native";
import { TouchableRipple } from "react-native-paper";
import Collapsible from "react-native-collapsible";
import dayjs, { Dayjs } from "dayjs";
import { ApolloError } from "apollo-boost";

import { ContentWrap, Icon, Text } from "@/components";
import { MultiSelectCheckbox, MultiSelectContainer, MultiSelectOption } from "@/components/MultiSelect";
import { RadioButton, RadioButtonGroup } from "@/components/Radio/Radio";
import DualDatePicker from "@/components/DatePicker/DualDatePicker";
import Loading from "@/components/Loading";
import { IconName } from "@/components/Icon";
import { LoadMoreButton } from "@/components/RectangleButton/RectangleButton";
import { CSVExportButton } from "./ExportButton";

import { TypographyType } from "@/models/Typography";
import { palette, spacing } from "@/theme";
import { getAssessmentLabelColor, getColorByClaimStatus, ucFirst } from "@/utils/helpers";
import { formatCardDate } from "libs/dates";
import { ClaimStatus } from "libs/types/API";
import { getDefaultDateRange } from "./helpers";

interface Item {
  id: string;
  heading: string;
  time: string;
  status?: ClaimStatus;
}

export interface PropertiesToExport<T extends Record<string, unknown>, U extends keyof T = keyof T> {
  values: {
    displayItem: U;
    dependent?: U;
  };
  heading: string;
  callback?: (args: { displayItem: T[U]; dependent?: T[U] }) => void;
}

interface QueryData<T extends Record<string, any>> {
  timestamp: number;
  items: T[] | null;
  nextToken?: string | null;
}

interface ExportItemsProps<T extends Record<string, any>> {
  type: "assessments" | "visits" | "claims";
  displayItems: Item[];
  queryData: QueryData<T>;
  propertiesToExport: PropertiesToExport<T>[];
  getItems: (dateRange?: { from: string; to: string }) => void;
  loadMore?: any;
  // nextToken: string | null;
  loading?: boolean;
  error?: ApolloError;
}

const ListItem = ({
  id,
  isChecked,
  onPress,
  color,
  patientName,
  time,
}: {
  id: string;
  isChecked: boolean;
  onPress: (id: string) => void;
  color: string;
  patientName: string;
  time: string;
}) => (
  <MultiSelectOption checked={isChecked} onPress={() => onPress(id)} color={color}>
    <Text format={TypographyType.Small} style={{ fontWeight: isChecked ? "bold" : "normal" }}>
      {patientName}
    </Text>
    <Text format={TypographyType.Tiny}>{formatCardDate(time)}</Text>
  </MultiSelectOption>
);

const ExportListItem = React.memo(ListItem);

export const ExportItems = ({
  type,
  displayItems,
  getItems,
  queryData,
  propertiesToExport,
  loadMore,
  loading,
  error,
}: ExportItemsProps<Record<string, any>>) => {
  const [exportType, setExportType] = useState<"list" | "dateRange">("list");
  const [itemsToDisplay, setItemsToDisplay] = useState<Item[]>([]);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [dateRange, setDateRange] = useState<{ from: Dayjs; to: Dayjs }>({
    from: dayjs().subtract(1, "day"),
    to: dayjs(),
  });
  // This defined the upper and lower range for the date picker
  const defaultDateRange = getDefaultDateRange();

  // setting state, to allow "emptying" of array when switching export type
  useEffect(() => {
    setItemsToDisplay(displayItems);
  }, [displayItems, queryData.timestamp]);

  // check individual item
  const checkItem = useCallback((id: string) => {
    setSelectedItems(prevItems =>
      prevItems.includes(id) ? prevItems.filter(itemId => itemId !== id) : [...prevItems, id]
    );
  }, []);

  // checking all items
  const checkAll = () => {
    setSelectedItems(prevItems =>
      prevItems.length === displayItems.length ? [] : displayItems.map((displayItem: { id: string }) => displayItem.id)
    );
  };

  // itemList to display list of items if given, otherwise loading status
  const itemList = useMemo(() => {
    if (loading && !itemsToDisplay.length) return <Loading />;

    if (!itemsToDisplay.length) {
      return (
        <ContentWrap>
          <Text format={TypographyType.Small}>No items to display</Text>
        </ContentWrap>
      );
    }

    return itemsToDisplay.map(item => {
      // getAssessmentLabelColor: displays card color based on date i.e. archived, today, in future
      // works for assessments and visits.
      // TODO: add fn for claims to display color based on status and toggle between fns based on type
      const labelColor =
        type === "claims" && item.status
          ? getColorByClaimStatus(item.status)
          : getAssessmentLabelColor(item.time).labelColor;
      const isChecked = selectedItems.includes(item.id);

      return (
        <ExportListItem
          key={item.id}
          id={item.id}
          isChecked={isChecked}
          onPress={checkItem}
          color={labelColor}
          patientName={item.heading}
          time={item.time}
        />
      );
    });
  }, [itemsToDisplay, selectedItems, loading]);

  // searching by date by passing in from and to date strings
  const searchByDate = () => {
    getItems({
      from: dayjs(dateRange.from)
        .subtract(1, "day")
        .endOf("day")
        .toISOString(),
      to: dayjs(dateRange.to)
        .endOf("day")
        .toISOString(),
    });
  };

  const items = useMemo(
    () => queryData.items?.filter((item: { id: string }) => selectedItems.includes(item.id)) || [],
    [queryData.timestamp, queryData.items, selectedItems]
  );

  return (
    <>
      <ContentWrap>
        <Text format={TypographyType.HeadingSmall} style={styles.smallHeading}>
          Select {ucFirst(type)}
        </Text>
        <RadioButtonGroup
          onValueChange={(type: "list" | "dateRange") => {
            if (exportType !== type) {
              setExportType(type);
              setSelectedItems([]);
              type === "dateRange" && setItemsToDisplay([]);
              type === "list" && getItems();
            }
          }}
          value={exportType}
        >
          <RadioButton value="list" status={exportType === "list"} label="Select from list" />
          <RadioButton value="dateRange" status={exportType === "dateRange"} label="Select by date" />
        </RadioButtonGroup>
        <Collapsible collapsed={exportType === "list"} duration={350} easing={"easeInOut"}>
          <View style={styles.datePicker}>
            <DualDatePicker
              label="From"
              min={defaultDateRange!.from}
              max={dayjs(dateRange.to).toDate() || defaultDateRange!.to}
              value={dateRange.from}
              onValueChange={value =>
                setDateRange(prevDateRange => ({
                  ...prevDateRange,
                  from: value,
                }))
              }
              noIcon
            />
            <DualDatePicker
              label="To"
              min={dayjs(dateRange.from).toDate() || defaultDateRange!.from}
              max={defaultDateRange!.to}
              value={dateRange.to}
              onValueChange={value =>
                setDateRange(prevDateRange => ({
                  ...prevDateRange,
                  to: value,
                }))
              }
              noIcon
            />
            <TouchableRipple
              onPress={searchByDate}
              disabled={!dayjs(dateRange.from).isBefore(dayjs(dateRange.to))}
              style={styles.searchIcon}
            >
              <Icon color={palette.white} name={IconName.search} />
            </TouchableRipple>
          </View>
        </Collapsible>
        <View style={[styles.row, styles.marginBottom30]}>
          <Text format={TypographyType.Small} color={palette.greyBlue} style={styles.flex}>
            {`Displaying ${exportType === "list" ? "last " : " "}${itemsToDisplay.length} ${type}.`}
          </Text>
          <TouchableRipple onPress={checkAll} disabled={!itemsToDisplay.length}>
            <MultiSelectCheckbox
              checked={!!selectedItems.length && selectedItems.length === queryData?.items?.length}
            />
          </TouchableRipple>
        </View>
        <CSVExportButton
          propertiesToExport={propertiesToExport}
          itemsToExport={items}
          fileName={type}
          disabled={loading || !selectedItems.length}
        />
      </ContentWrap>
      {/* TODO: check ErrorMessage component -> useNavigation hook -> getting error: Couldn't find a navigation object. Is your component inside a screen in a navigator? */}
      {/* {error && <ErrorMessage apolloError={error} />} */}
      {!error && !!itemsToDisplay.length && <MultiSelectContainer>{itemList}</MultiSelectContainer>}
      {!!queryData.nextToken && !!itemsToDisplay.length && LoadMoreButton(() => loadMore(), loading)}
    </>
  );
};

const styles = StyleSheet.create({
  smallHeading: {
    marginBottom: spacing[15],
  },
  row: { flexDirection: "row" },
  marginBottom30: { marginBottom: spacing[30] },
  flex: { flex: 1 },
  datePicker: { flexDirection: "row", alignItems: "center" },
  searchIcon: {
    height: spacing[40],
    width: spacing[40],
    borderRadius: spacing[20],
    backgroundColor: palette.blue,
    justifyContent: "center",
    alignItems: "center",
    marginBottom: spacing[15],
    ...Platform.select({
      web: {
        cursor: "pointer",
      },
    }),
  },
});
