import { useEffect } from "react";

/**
 * Run the callback only the first time the component has mounted. The reason
 * that we abstract this into its own hook is that otherwise we would have to
 * pass in dependencies to the dependency array.
 *
 * Sometimes we definitely only want things to run exactly once.
 *
 * @param callback Function to run when the component first mounts.
 */
export default function useOnMount(callback: () => void): void {
  useEffect(callback, []);
}
