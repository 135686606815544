import React from "react";
import { createStackNavigator } from "@react-navigation/stack";

import screenOptions from "@/navigationv2/config/screenOptions";
import { CCGApprovedPaidClaimsRoutes } from "@/navigationv2/types";
import * as Screens from "@/screens";
import TabIcon from "../TabIcon";

const ApprovedPaidStack = createStackNavigator<CCGApprovedPaidClaimsRoutes>();

const CCGApprovedClaimsStack = () => {
  return (
    <ApprovedPaidStack.Navigator initialRouteName="ApprovedPaidClaimsScreen" screenOptions={screenOptions}>
      <ApprovedPaidStack.Screen
        name="ApprovedPaidClaimsScreen"
        component={Screens.ApprovedPaidClaimsScreen}
        options={Screens.ApprovedPaidClaimsScreen.screenOptions}
      />
      <ApprovedPaidStack.Screen
        name="ClaimProcessingDetailsScreen"
        component={Screens.ClaimProcessingDetailsScreen}
        options={Screens.ClaimProcessingDetailsScreen.screenOptions}
      />
      <ApprovedPaidStack.Screen
        name="ClaimProcessingSearchScreen"
        component={Screens.ClaimProcessingSearchScreen}
        options={Screens.ClaimProcessingSearchScreen.screenOptions}
      />
    </ApprovedPaidStack.Navigator>
  );
};

CCGApprovedClaimsStack.tabOptions = {
  tabBarIcon: TabIcon("credit-card"),
  tabBarLabel: "Paid",
};

export default CCGApprovedClaimsStack;
