import React, { useState, useEffect } from "react";

import AssessmentDetails from "@/components/AssessmentDetails";
import { BackButtonProps } from "@/components/BackButton/BackButton.props";
import { useOnScreenFocus } from "@/hooks";
import { AppScreen } from "@/layouts/AppScreen/AppScreen";
import { RouteKeys } from "@/navigationv2";
import {
  AllAMHPRoutes,
  AMHPAssessmentsRoutes,
  AMHPTabRoutes,
  ScreenNavigationProps,
  ScreenOptions,
} from "@/navigationv2/types";
import { createScreenTitle } from "@/navigationv2/utilities";
import { mqWeb } from "@/utils/helpers";
import { color } from "@/theme";
import { getNameAsPageTitle } from "@/utils/user";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";

export interface AssessmentDetailsNavProps {
  assessmentId: string;
}

const backButtonConfig: BackButtonProps = {
  enabled: true,
  float: true,
  color: color.textWhite,
};

interface PropTypes
  extends ScreenNavigationProps<AMHPTabRoutes, AMHPAssessmentsRoutes, RouteKeys.AssessmentDetailsScreen> {}

export default function AssessmentDetailsScreen(props: PropTypes) {
  const navigation = useNavigation<StackNavigationProp<AllAMHPRoutes>>();
  const { route } = props;
  const isWebView = mqWeb();
  const [patientName, setPatientName] = useState("");
  const [refreshRequired, setRefreshRequired] = useState(false);
  const { assessmentId, notificationUpdate } = route.params;
  const pageTitle = getNameAsPageTitle(patientName, isWebView);

  useOnScreenFocus(() => {
    setRefreshRequired(true);
  });

  useEffect(() => {
    patientName && navigation.setOptions({ title: createScreenTitle(`${patientName} Assessment Details`) });
  }, [navigation, patientName]);

  return (
    <AppScreen
      contextLabel="Assessments"
      pageTitle={pageTitle}
      backButton={backButtonConfig}
      testID="AssessmentDetailsScreen"
    >
      <AssessmentDetails
        id={assessmentId}
        setRefreshDone={() => setRefreshRequired(false)}
        refreshRequired={refreshRequired}
        retrievePatientName={setPatientName}
        notificationUpdate={notificationUpdate}
      />
    </AppScreen>
  );
}

const screenOptions: ScreenOptions = {
  title: createScreenTitle("Assessment Details"),
};

AssessmentDetailsScreen.screenOptions = screenOptions;
