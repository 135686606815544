import React, { useReducer, createContext, ReactNode, useMemo } from "react";
import { availabilityModelReducer, availabilityControllerReducer } from "libs/state/availability/availabilityReducer";
import {
  availabilityModelState,
  availabilityControllerState,
  StateContext,
} from "libs/state/availability/initialState";

export const AvailabilityContext = createContext<StateContext>({
  state: { ...availabilityModelState, ...availabilityControllerState },
  dispatch: () => null,
  controllerDispatch: () => null,
});

function AvailabilityProvider(props: { children: ReactNode }) {
  const [modelState, dispatch] = useReducer(availabilityModelReducer, availabilityModelState);
  const [controllerState, controllerDispatch] = useReducer(availabilityControllerReducer, availabilityControllerState);

  const value = useMemo(
    () => ({
      state: { ...modelState, ...controllerState },
      dispatch,
      controllerDispatch,
    }),
    [modelState, controllerState]
  );

  return <AvailabilityContext.Provider value={value}>{props.children}</AvailabilityContext.Provider>;
}

export default AvailabilityProvider;
