import React, { useMemo } from "react";
import { View, StyleSheet, Platform, Keyboard, StyleProp, ViewStyle } from "react-native";

import { TypographyType } from "@/models/Typography";
import Text from "../Text";
import { TextInput } from "../TextInput/TextInput";
import { TouchableRipple } from "react-native-paper";
import TimePicker from "./TimePicker";
import { color } from "../../theme";
import { mqWeb } from "../../utils/helpers";

export interface DualTimePickerProps {
  label?: string;
  value: { hours: number; minutes: number };
  noIcon?: boolean;
  disabled?: boolean;
  error?: boolean;
  errorText?: string | null;
  onWebBlur?: () => void;
  onValueChange: (v: { hours: number; minutes: number }) => void;
  style?: StyleProp<ViewStyle>;
}

const DualTimePicker = React.forwardRef((props: DualTimePickerProps, ref: (ref: any) => void) => {
  const inputRef = React.useRef<any>();
  const [open, setOpen] = React.useState(false);
  const isWebView = mqWeb();

  const onPress = React.useCallback(() => {
    if (props.disabled) {
      return;
    }

    if (Platform.OS === "web") {
      inputRef.current && inputRef.current.focus();
    } else {
      setOpen(true);
      Keyboard.dismiss();
    }
  }, [inputRef, props.disabled]);

  const render = React.useCallback(
    p => {
      // TODO: maybe on web don't render this at all?
      const { style, value } = p;
      return (
        <View style={styles.textContainer}>
          <TouchableRipple style={styles.touchable} disabled={props.disabled} onPress={onPress}>
            <Text format={TypographyType.Regular} style={[style, Platform.OS === "web" && styles.webText]}>
              {value}
            </Text>
          </TouchableRipple>
          {props.label && (
            <Text format={TypographyType.Micro} style={styles.label} color={color.textExtraLight}>
              {props.label}
            </Text>
          )}
        </View>
      );
    },
    [props.disabled, props.label]
  );

  const styleArr = useMemo(() => [styles.container, isWebView ? styles.containerWeb : {}, props.style], [props.style]);

  const iconProp = props.noIcon ? {} : { icon: "today" };

  return (
    <View style={styleArr}>
      <TextInput
        {...iconProp}
        error={props.error}
        {...(props.errorText ? { errorText: props.errorText } : {})}
        label={props.label}
        ref={ref}
        style={styles.left}
        disabled={props.disabled}
        onIconPress={onPress}
        value={`${leftPad(props.value.hours)}:${leftPad(props.value.minutes)}`}
        render={render}
      />
      <TimePicker
        ref={inputRef}
        label={props.label}
        disabled={props.disabled}
        value={props.value}
        open={open}
        onValueChange={props.onValueChange}
        onWebBlur={props.onWebBlur}
        setOpen={setOpen}
      />
    </View>
  );
});

DualTimePicker.displayName = "DualTimePicker";

export default DualTimePicker;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    alignItems: "baseline",
  },
  containerWeb: {
    alignItems: "stretch",
    flexDirection: "column",
  },
  textContainer: {
    position: "relative",
  },
  touchable: { backgroundColor: color.background, paddingTop: 2 },
  webText: {
    flexBasis: "25%",
  },
  left: {
    minWidth: 180,
  },
  label: {
    position: "absolute",
    top: 3,
    left: 12,
  },
});

function leftPad(num: number, size = 2): string {
  let s = String(num);
  while (s.length < size) {
    s = "0" + s;
  }
  return s;
}
