import {
  ClaimStatus,
  CreateAvailabilityInput,
  Gender,
  LocationInput,
  LocationNameInput,
  LocationType,
  ClaimDataInput,
} from "libs/types/API";
import { Availability } from "libs/types/availability";
import { DoctorClaimListItem } from "libs/types/claim";
import { VisitListItem, DoctorVisit } from "libs/types/visit";
import gql from "graphql-tag";

import { NextToken } from "libs/types/graphql";

import dayjs from "dayjs";
import {
  AmhpTeamIdAndName,
  AvailabilitiesExtendedFragment,
  CCGFragment,
  DoctorCondensedFragment,
  DoctorFragment,
  DoctorVisitsWithClaimId,
  LocationCoordsFragment,
  LocationNameFragment,
  MhtDefaults,
  MhtEmployerDefaults,
  OrganisationIdName,
  AssessmentFormListFragment,
  AssessmentFormListResponse,
} from "./gql/fragments";
import { Holiday } from "libs/types/holiday";

export { Gender } from "libs/types/API";

export interface MHTItem {
  id: string;
  abbreviation: string;
  organisation: {
    id: string;
    name: string;
    rotas: { id: string | null; name: string | null };
  };
}

export interface DoctorProfile {
  id: string;
  s12Expiry: string | null;
  name: string;
  phone: string | null;
  phone2: string | null;
  email: string;
  employer?: string | null;
  jobTitle?: string | null;
  responsibleOfficer?: string | null;
  lineManager?: string | null;
  availabilities?: Availability[];
  pushTokens?: string[];
  mhtEmployers: {
    mht: {
      organisation: {
        id: string;
        name: string;
      };
    };
  }[];
  claims?: DoctorClaimsResponse[];

  visits?: DoctorVisit[];

  holidays?: Holiday[];
  location: LocationInput;
  locationName: LocationNameInput;
  distance?: number;
  legalName: string;
  siteName?: string;
  specialties: string[];
  languages: string[];
  gender: Gender | null;
  hideFromSearch?: boolean | null;
  notes?: string;

  availabilityLocations?: LocationInput[];
  availabilityPostcodes?: string[];

  // Billing information
  claimData?: ClaimDataInput;
  isOutsideEngland?: boolean;
  // A flag on the doctor's profile which indicates that the doctor
}

export interface DoctorClaimsResponse {
  id: string;
  status: ClaimStatus;
  visit: {
    id: string;
    assessment: {
      id: string;
    };
  };
}

export interface SearchResultDto {
  fullName: string;
  distance: number;
  locationCity: string;
  availabilities: Availability[];
  specialties?: string[];
  languages?: string[];
  gender: Gender;

  associations: { name: string }[];
}

const thisMonthAvailabilities = () =>
  `"${new Date().getFullYear()}${new Date().getMonth() < 9 ? "0" : ""}${new Date().getMonth() + 1}"`;

const thisMonthHolidays = () =>
  `"${new Date().getFullYear()}-${new Date().getMonth() < 9 ? "0" : ""}${new Date().getMonth() + 1}"`;

const thisMonthVisits = () =>
  `"${new Date().getFullYear()}-${new Date().getMonth() < 9 ? "0" : ""}${new Date().getMonth() + 1}"`;

export const GET_MHTS = gql`
  query ListMHTs($filter: ModelMHTFilterInput, $limit: Int) {
    listMHTs(filter: $filter, limit: $limit) {
      items {
        ...MhtDefaults
      }
    }
  }
  ${MhtDefaults}
`;

export const CREATE_MHT_S12_DOCTOR = gql`
  mutation CreateMHTS12Doctor($input: CreateMHTS12DoctorInput!) {
    createMHTS12Doctor(input: $input) {
      id
    }
  }
`;

export const DELETE_MHT_S12_DOCTOR = gql`
  mutation DeleteMHTS12Doctor($input: DeleteMHTS12DoctorInput!) {
    deleteMHTS12Doctor(input: $input) {
      id
    }
  }
`;

export const CREATE_DOCTOR_VISIT = gql`
  mutation CreateDoctorVisit($doctorId: ID!, $assessmentId: ID!, $time: String!, $notes: [String]) {
    createDoctorVisit(doctorId: $doctorId, assessmentId: $assessmentId, time: $time, notes: $notes) {
      id
      assessment {
        id
      }
    }
  }
`;

export const UPDATE_DOCTOR_VISIT = gql`
  mutation UpdateDoctorVisit($input: UpdateDoctorVisitInput!) {
    updateDoctorVisit(input: $input) {
      id
    }
  }
`;

export const DELETE_DOCTOR_VISIT = gql`
  mutation DeleteDoctorVisit($id: ID!) {
    visit: deleteDoctorVisit(input: { id: $id }) {
      id
    }
  }
`;

export const CREATE_DOCTOR_AVAILABILITY = gql`
  mutation CreateAvailability(
    $id: ID!
    $input: CreateAvailabilityInput!
    $profileLocations: [LocationInput]
    $profilePostcodes: [String]
  ) {
    availabilityType: createAvailability(input: $input) {
      type
    }
    profileId: updateS12Doctor(
      input: { id: $id, availabilityLocations: $profileLocations, availabilityPostcodes: $profilePostcodes }
    ) {
      id
    }
  }
`;

export const UPDATE_DOCTOR_AVAILABILITY = gql`
  mutation UpdateAvailability(
    $id: ID!
    $input: UpdateAvailabilityInput!
    $profileLocations: [LocationInput]
    $profilePostcodes: [String]
  ) {
    availabilityType: updateAvailability(input: $input) {
      type
    }
    profileId: updateS12Doctor(
      input: { id: $id, availabilityLocations: $profileLocations, availabilityPostcodes: $profilePostcodes }
    ) {
      id
    }
  }
`;

export interface CreateDoctorAvailabilityInput {
  id: string;
  input: CreateAvailabilityInput;
  profileLocations?: LocationInput[];
  profilePostcodes?: string[];
}

export const DELETE_DOCTOR_AVAILABILITY = gql`
  mutation DeleteAvailability($input: DeleteAvailabilityInput!) {
    deleteAvailability(input: $input) {
      id
    }
  }
`;

export const DOCTOR_AND_MHT = gql`
  query getS12DoctorAndMhts($id: ID!, $filter: ModelMHTFilterInput, $limit: Int) {
    doctor: getS12Doctor(id: $id) {
      ...DoctorDefaults
      mhtEmployers {
        items {
          ...MhtEmployerDefaultItems
        }
      }
      location {
        ...latLon
      }
      locationName {
        ...fullLocation
      }
      availabilityPostcodes
      availabilityLocations {
        ...latLon
      }
      claimData {
        billingAddress {
          address
          city
          postcode
        }
        billingCompanyName
        vatRegistration
        fuelType
        engineSize
      }
      sectionFormAddress {
        address
        city
        postcode
      }
      sectionFormEmailAddress
    }

    mhts: listMHTs(filter: $filter, limit: $limit) {
      items {
        ...MhtDefaults
      }
    }
  }
  ${DoctorFragment}
  ${LocationCoordsFragment}
  ${LocationNameFragment}
  ${MhtEmployerDefaults}
`;

export const DOCTOR_AND_ASSESSMENT = gql`
  query getS12DoctorAndAssessment($doctorId: ID!, $assessmentId: ID!) {
    doctor: getS12Doctor(id: $doctorId) {
      ...DoctorDefaults
      mhtEmployers {
        items {
          ...MhtEmployerDefaultItems
        }
      }
      location {
        ...latLon
      }
      locationName {
        ...fullLocation
      }
      availabilities(limit: 500, endDate: { gt: ${thisMonthAvailabilities()} } ) {
        items {
          ...availabilitiesExtended
        }
      }
      holidays(limit: 250, end:{ge: ${thisMonthHolidays()}}, sortDirection: ASC) {
        items {
            id
            start
            end
            notes
            visible
        }
      }
      visits(limit: 500, time: {ge: ${thisMonthVisits()}}) {
        items {
          id
          time
          partialPostcode
          notes
        }
      }
    }
    assessment: amhpAssessment(id: $assessmentId) {
      id
      assessmentDate
      createdAt
      amhpId
      amhpTeamId
      forms {
        items {
          id
          authors
        }
      }
      location {
        lon
        lat
      }
      doctorVisits {
        items {
          ...DoctorVisitsWithClaimId
        }
      }
    }
  }
  ${LocationCoordsFragment}
  ${OrganisationIdName}
  ${LocationNameFragment}
  ${DoctorFragment}
  ${AvailabilitiesExtendedFragment}
  ${DoctorCondensedFragment}
  ${DoctorVisitsWithClaimId}
  ${MhtEmployerDefaults}
`;

export const UPDATE_DOCTOR_PROFILE = gql`
  mutation UpdateS12Doctor($input: UpdateS12DoctorInput!) {
    updateS12Doctor(input: $input) {
      id
    }
  }
`;

export interface GetDoctorVisitsResponse {
  getS12Doctor: {
    id: string;
    visits: {
      items: (VisitListItem | null)[] | null;
      nextToken?: string;
    } | null;
  };
}

export const GET_DOCTOR_VISITS = gql`
  query GetS12Doctor($id: ID!, $nextToken: String) {
    getS12Doctor(id: $id) {
      id
      visits(sortDirection: DESC, limit: 10, nextToken: $nextToken) {
        items {
          id
          time
          assessment {
            id
            patientName
            assessmentDate
            location {
              ...latLon
            }
            locationName {
              ...fullLocation
            }
            amhp {
              id
              name
            }
          }
          claim {
            id
            status
            createdAt
          }
        }
        nextToken
      }
    }
  }
  ${LocationCoordsFragment}
  ${LocationNameFragment}
`;

export interface ClaimsByStatusSectionResponse {
  items: DoctorClaimListItem[];
  nextToken: NextToken;
}

export interface GetDoctorClaimsByStatusResponse {
  actionRequired: ClaimsByStatusSectionResponse;
  underReview: ClaimsByStatusSectionResponse;
  approved: ClaimsByStatusSectionResponse;
  rejected: ClaimsByStatusSectionResponse;
  paid: ClaimsByStatusSectionResponse;
  onHold: ClaimsByStatusSectionResponse;
}

export interface GetDoctorClaimsResponse {
  getS12Doctor: {
    id: string;
    claims?: {
      items?: (DoctorClaimListItem | null)[];
    };
  };
}

export const GET_DOCTOR_CLAIMS_BY_STATUS = gql`
  query(
    $actionRequiredNextToken: String
    $underReviewNextToken: String
    $approvedNextToken: String
    $paidNextToken: String
    $rejectedNextToken: String
    $id: ID!
    $limit: Int!
  ) {
    actionRequired: claimsByDoctor(
      claimDoctorId: $id
      statusUpdatedAt: { beginsWith: { status: action_required } }
      nextToken: $actionRequiredNextToken
      limit: $limit
    ) {
      items {
        ...doctorClaimsFragment
      }
      nextToken
    }
    underReview: claimsByDoctor(
      claimDoctorId: $id
      statusUpdatedAt: { beginsWith: { status: under_review } }
      nextToken: $underReviewNextToken
      limit: $limit
    ) {
      items {
        ...doctorClaimsFragment
      }
      nextToken
    }
    approved: claimsByDoctor(
      claimDoctorId: $id
      statusUpdatedAt: { beginsWith: { status: approved, updatedAt: "" } }
      nextToken: $approvedNextToken
      limit: $limit
    ) {
      items {
        ...doctorClaimsFragment
      }
      nextToken
    }
    paid: claimsByDoctor(
      claimDoctorId: $id
      statusUpdatedAt: { beginsWith: { status: approved_and_paid } }
      limit: $limit
      sortDirection: DESC
      nextToken: $paidNextToken
    ) {
      items {
        ...doctorClaimsFragment
      }
      nextToken
    }
    rejected: claimsByDoctor(
      claimDoctorId: $id
      statusUpdatedAt: { beginsWith: { status: rejected } }
      sortDirection: DESC
      nextToken: $rejectedNextToken
      limit: $limit
    ) {
      items {
        ...doctorClaimsFragment
      }
      nextToken
    }
    onHold: claimsByDoctor(
      claimDoctorId: $id
      statusUpdatedAt: { beginsWith: { status: on_hold } }
      sortDirection: DESC
      nextToken: $rejectedNextToken
      limit: $limit
    ) {
      items {
        ...doctorClaimsFragment
      }
      nextToken
    }
  }
  fragment doctorClaimsFragment on Claim {
    id
    visit {
      id
      time
      assessment {
        id
        patientName
        locationName {
          address
          city
          postcode
        }
      }
    }
    claimOrganisationId
    organisation {
      id
      name
    }
    updatedAt
    displayId
    status
    paid
  }
`;

export interface GetDoctorResponse {
  getS12Doctor: S12Doctor;
}

export function convertToProfile(response: S12Doctor): DoctorProfile {
  return {
    ...response,
    availabilities: response.availabilities.items?.filter(notEmpty) || [],
    mhtEmployers: response.mhtEmployers.items?.filter(notEmpty) || [],
    claims: response.claims?.items?.filter(notEmpty) || [],
    visits: response.visits?.items?.filter(notEmpty).map(v => ({ ...v, time: dayjs(v.time) })) || [],
    holidays:
      response.holidays?.items?.filter(notEmpty).map(h => ({ ...h, start: dayjs(h.start), end: dayjs(h.end) })) || [],
  };
}

interface S12Doctor {
  id: string;
  s12Expiry: string | null;
  name: string;
  phone: string | null;
  phone2: string | null;
  email: string;
  employer?: string | null;
  jobTitle?: string | null;
  responsibleOfficer?: string | null;
  lineManager: string | null;
  availabilities: { items: Array<Availability | null> | null };
  mhtEmployers: {
    items: Array<{
      mht: {
        organisation: {
          id: string;
          name: string;
        };
      };
    } | null> | null;
  };
  claims?: {
    items: Array<DoctorClaimsResponse | null> | null;
  };
  visits?: {
    items: Array<DoctorVisit | null> | null;
  };
  holidays?: {
    items: Array<{
      id: string;
      start: string;
      end: string;
      visible: boolean;
      notes: string;
    } | null> | null;
  };
  location: LocationInput;
  locationName: LocationNameInput;
  distance?: number;
  legalName: string;
  siteName?: string;
  specialties: string[];
  languages: string[];
  gender: Gender | null;
  hideFromSearch?: boolean | null;
  notes?: string;

  availabilityLocations?: LocationInput[];
  availabilityPostcodes?: string[];

  // Billing information
  claimData?: ClaimDataInput;
}

export const GET_DOCTOR_TOKENS = gql`
  query GetS12Doctor($id: ID!) {
    getS12Doctor(id: $id) {
      id
      pushTokens
    }
  }
`;

export const GET_DOCTOR = gql`
  query GetS12Doctor($id: ID!) {
    getS12Doctor(id: $id) {
      ...DoctorDefaults
      mhtEmployers {
        items {
          ...MhtEmployerDefaultItems
        }
      }
      location {
        ...latLon
      }
      locationName {
        ...fullLocation
      }
      pushTokens
      availabilities(limit: 500, endDate: { gt: ${thisMonthAvailabilities()}} ) {
        items {
          ...availabilitiesExtended
        }
      }

      claims(limit: 500) {
        items {
          id
          status
        }
      }
      visits(limit: 500, time: {ge: ${thisMonthVisits()}}) {
        items {
          id
          time
          partialPostcode
          notes
        }
      }
      holidays(limit: 250, end:{ge: ${thisMonthHolidays()}}, sortDirection: ASC) {
        items {
          id
          start
          end
          notes
          visible
        }
      }
      claimData {
        billingCompanyName
        billingAddress {
          address
          city
          postcode
        }
        vatRegistration
        fuelType
        engineSize
      }
      siteName
      isOutsideEngland
    }
  }
  ${LocationCoordsFragment}
  ${LocationNameFragment}
  ${DoctorFragment}
  ${AvailabilitiesExtendedFragment}
  ${MhtEmployerDefaults}
`;
export interface DoctorContact {
  id: string;
  email: string;
  name: string;
  phone: string;
  phone2: string;
}
export interface VisitDetailItem {
  id: string;
  time: string;
  notes: string[]; // formatted as plain strings
  doctorVisitDoctorId: string;
  assessment: {
    id: string;
    ccgDetermination?: LocationType;
    patientName: string;
    assessmentDate: string;
    location: LocationInput;
    locationName: LocationNameInput;
    doctorVisits: {
      items: DoctorContact[];
    };
    ccg?: {
      id: string;
      name: string;
      featureFlags: string;
    };
    amhpTeam: {
      id: string;
      name: string;
      featureFlags: string;
    };
    amhp: {
      id: string;
      name: string;
      phone: string;
      email: string;
    };
    patientAddress?: {
      address: string;
      city: string;
      postcode: string;
    };
  } & AssessmentFormListResponse;
}

export interface VisitQueryResponse {
  visit: VisitDetailItem;
}
export const VISIT_QUERY = gql`
  query GetDoctorVisit($id: ID!) {
    visit: getDoctorVisit(id: $id) {
      id
      time
      notes
      doctorVisitDoctorId
      assessment {
        id
        doctorVisits {
          items {
            doctor {
              id
              name
              email
              phone
              phone2
            }
          }
        }
        ccgDetermination
        patientName
        assessmentDate
        location {
          ...latLon
        }
        locationName {
          ...fullLocation
        }
        ccg {
          ...CCGCondensed
        }
        amhpTeam {
          ...AmhpTeamIdAndNameParts
          featureFlags
        }
        amhp {
          id
          name
          phone
          email
        }
        ...AssessmentFormList
        patientAddress {
          address
          city
          postcode
        }
      }
    }
  }
  ${CCGFragment}
  ${AmhpTeamIdAndName}
  ${LocationCoordsFragment}
  ${LocationNameFragment}
  ${AssessmentFormListFragment}
`;

function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && typeof value !== "undefined";
}
