import * as Yup from "yup";
import { ComponentElement, FormFieldsAmhp } from "libs/types/mhaForms";
import { FormSection } from "../..";
import createPostcodeSchema from "libs/validators/yup/createPostcodeSchema";

const componentElements: ComponentElement[] = [
  {
    component: "Text",
    componentProps: {
      instruction: "Delegate my authority to ",
      guidance: "(PRINT name of people/agency/provider)",
    },
    fullWidth: true,
  },
  {
    component: "TextInput",
    fieldName: FormFieldsAmhp.DelegateAuthority,
    componentProps: {
      label: "People/Agency/Provider",
    },
  },
  {
    component: "Text",
    componentProps: {
      instruction: "under s.6 of the Mental Health Act 1983, to take into their custody and convey ",
      guidance: "(PRINT full name of patient)",
    },
    fullWidth: true,
  },
  {
    component: "TextInput",
    fieldName: FormFieldsAmhp.PatientName,
    componentProps: {
      label: "Patient Name",
    },
  },
  {
    component: "Text",
    componentProps: {
      instruction: "from",
      guidance: "(PRINT patient’s current location)",
    },
    fullWidth: true,
  },
  {
    component: "Location",
    fieldName: FormFieldsAmhp.PatientAddress,
    componentProps: {
      label: "Patient's current location",
      disableStrictPostcodeValidation: true,
    },
  },
  {
    component: "Text",
    componentProps: {
      instruction: "to this hospital",
      guidance: "(PRINT hospital ward, name and address)",
    },
    fullWidth: true,
  },
  {
    component: "TextInput",
    fieldName: FormFieldsAmhp.HospitalName,
    componentProps: {
      label: "Hospital Ward, Name",
    },
  },
  {
    component: "Location",
    fieldName: FormFieldsAmhp.HospitalAddress,
    componentProps: {
      label: "Hospital Address",
    },
  },
];

const patientDetails: FormSection = {
  title: "Patient Details",
  state: {
    delegateAuthority: "",
    patientName: "",
    patientAddress: {
      city: "",
      postcode: "",
      address: "",
    },
    hospitalName: "",
    hospitalAddress: {
      address: "",
      city: "",
      postcode: "",
    },
  },

  validation: Yup.object().shape({
    delegateAuthority: Yup.string().trim().required("Please enter an authority to delegate to"),
    patientName: Yup.string().trim().required("Patient name is required"),
    patientAddress: Yup.object().shape({
      city: Yup.string().nullable(),
      postcode: Yup.string().trim().required("Please enter an address"),
      address: Yup.string().nullable(),
    }),
    hospitalName: Yup.string()
      .trim()
      .required("Please enter the hospital name")
      .min(3, "Hospital name must be at least 3 characters in length"),
    hospitalAddress: Yup.object().shape({
      city: Yup.string(),
      postcode: createPostcodeSchema({
        invalidPostcodeMessage: "Please enter a valid address",
        required: true,
        requiredMessage: "Please enter an address",
      }),
    }),
  }),

  componentElements,
};

export default patientDetails;
