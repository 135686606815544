import React, { useMemo, useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

import Loading from "@/components/Loading";
import { ContentWrap } from "@/components/ContentWrap";
import ItemSpacer from "@/components/ItemSpacer";
import Select from "@/components/Select";
import Text from "@/components/Text";

import { EmailAddressInput } from "libs/types/API";
import { TypographyType } from "@/models/Typography";
import { spacing, palette } from "@/theme";
import { mqWeb } from "@/utils/helpers";

// HospitalEmailsResponse
const GET_AMHP_TEAM_HOSPITAL_EMAILS = gql`
  query GetAmhpTeam($id: ID!) {
    getAmhpTeam(id: $id) {
      hospitalEmails {
        name
        wards {
          name
          email
        }
      }
    }
  }
`;

interface HospitalEmail {
  name: string;
  wards: Array<EmailAddressInput>;
}

interface HospitalEmailsResponse {
  getAmhpTeam: {
    hospitalEmails: Array<HospitalEmail>;
  };
}

export const HospitalEmails = (props: {
  amhpTeamId?: string;
  selectedHospital: number;
  setSelectedHospital: React.Dispatch<React.SetStateAction<number>>;
  hospitalEmailAddress: string[];
  setHospitalEmailAddress: React.Dispatch<React.SetStateAction<string[]>>;
  setHospitalChoice: (choice: { hospital?: string; ward?: string }) => void;
  displayComponent: boolean;
}) => {
  const isWebView = mqWeb();
  const [selectedWard, setSelectedWard] = useState(0);
  const {
    amhpTeamId,
    selectedHospital,
    hospitalEmailAddress,
    setSelectedHospital,
    setHospitalEmailAddress,
    setHospitalChoice,
    displayComponent,
  } = props;
  if (!amhpTeamId) {
    return <></>;
  }
  const { loading: loadingHospitals, error, data } = useQuery<HospitalEmailsResponse>(GET_AMHP_TEAM_HOSPITAL_EMAILS, {
    variables: { id: amhpTeamId },
    fetchPolicy: "cache-and-network",
  });

  const hospitals = useMemo(() => {
    const hospitals =
      data?.getAmhpTeam?.hospitalEmails?.map(h => {
        return {
          name: h.name,
          id: h.name.replace(/\s/g, "_"),
          wards: h.wards,
        };
      }) || [];

    if (!hospitalEmailAddress && hospitals.length > 0) {
      setHospitalChoice({
        hospital: hospitals[0].name,
        ward: hospitals[0].wards[0].name,
      });
      setHospitalEmailAddress([hospitals[0].wards[0].email]);
    }
    return hospitals;
  }, [data?.getAmhpTeam?.hospitalEmails]);

  useEffect(() => {
    if (displayComponent && hospitals && hospitals.length) {
      setHospitalChoice({
        hospital: hospitals[0].name,
        ward: hospitals[0].wards[0].name,
      });
      setHospitalEmailAddress([hospitals[0].wards[0].email]);
      setSelectedWard(0);
    }
  }, [hospitals, displayComponent]);

  // If we change the hospital, make sure we default to the email address of the first ward in that hospital
  useEffect(() => {
    if (displayComponent && hospitals && hospitals.length) {
      setHospitalEmailAddress([hospitals[selectedHospital].wards[0].email]);
      setSelectedWard(0);
    }
  }, [selectedHospital]);

  return props.displayComponent ? (
    loadingHospitals ? (
      <Loading text="Loading Hospitals" />
    ) : error ? (
      <Text format={TypographyType.HeadingSmall} style={styles.smallHeading}>
        There was an error loading hospital ward information
      </Text>
    ) : (
      <ContentWrap style={styles.selectContent}>
        <Text format={TypographyType.HeadingSmall} style={styles.smallHeading}>
          Select Hospital
        </Text>
        <ItemSpacer gap={20}>
          <Select
            label="Hospital"
            value={hospitals.length ? hospitals[selectedHospital].id : ""}
            onValueChange={(_v, i, d: [{ value: string; label: string }]) => {
              setHospitalChoice({
                hospital: d[i].label,
                ward: hospitals[i]?.wards[0].name,
              });
              setSelectedHospital(i);
            }}
            options={hospitals.map(h => ({ name: h.name, id: h.id }))}
          />
          <Select
            label="Ward"
            value={
              isWebView
                ? hospitalEmailAddress && hospitalEmailAddress[0]
                : (hospitals.length > 0 && hospitals[selectedHospital].wards[0].name) || ""
            }
            onValueChange={(_v, i, d: [{ value: string; label: string }]) => {
              setHospitalChoice({
                ward: d[i].label,
              });
              setHospitalEmailAddress([d[i].value]);
              setSelectedWard(i);
            }}
            // For the create unique ID add underscore and index of array end of the email
            options={
              hospitals[selectedHospital]?.wards.map((w, i) => ({
                name: w.name,
                id: w.email.concat("~" + i.toString()),
              })) || []
            }
          />
          {hospitals[selectedHospital].wards[selectedWard] && (
            <View style={styles.emailAddressesContent}>
              <Text format={TypographyType.Regular} style={styles.smallHeading}>
                Selected email address(es)
              </Text>
              <Text format={TypographyType.Micro} color={palette.greyBlue} style={styles.smallHeading}>
                {hospitals[selectedHospital].wards[selectedWard].email.replace(/,/g, ", ")}
              </Text>
            </View>
          )}
        </ItemSpacer>
      </ContentWrap>
    )
  ) : (
    <></>
  );
};

const styles = StyleSheet.create({
  smallHeading: {
    marginBottom: spacing[15],
  },
  selectContent: {
    paddingRight: spacing[30],
  },
  emailAddressesContent: {
    flexDirection: "column",
    alignItems: "flex-start",
    flex: 1,
    flexWrap: "wrap",
    marginBottom: spacing[15],
  },
});
