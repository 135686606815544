export default {
  AWS_PROJECT_REGION: "eu-west-2",
  AWS_COGNITO_IDENTITY_POOL_ID: "eu-west-2:41686262-de52-4be3-a4a9-a5e9a8b94393",
  AWS_COGNITO_REGION: "eu-west-2",
  AWS_USER_POOLS_ID: "eu-west-2_yFuDXac1m",
  AWS_USER_POOLS_WEB_CLIENT_ID: "658mqr0kjmnmap69d9a0kdcv3n",
  AWS_APPSYNC_GRAPHQLENDPOINT: "https://laxvket2c5h2bezfnuenjbd6xq.appsync-api.eu-west-2.amazonaws.com/graphql",
  AWS_HTTP_ENDPOINT: "https://7qygrylgph.execute-api.eu-west-2.amazonaws.com/preprod",
  ENV: "preprod",
  AWS_PINPOINT_APP_ID: "1670e908192044a69fa03ae344669624",
  AWS_PINPOINT_REGION: "eu-west-1",
  AWS_EXTERNAL_USER_POOLS_ID: "eu-west-2_Q6V0Av4tz",
  AWS_EXTERNAL_USER_POOLS_WEB_CLIENT_ID: "28avk4v7b0ngh6bo6barn5j9hc",
  WEB_URI: "https://preprod.v2.s12solutions.com",
};
