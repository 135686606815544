import { Dayjs } from "dayjs";
import { LocationNameInput, FormStatus } from "./API";
import { TypographyProps } from "./Typography";
import summaryOfRisk from "../mhaForms/formSections/amhp/conveyance/summaryOfRisk";

// These are the types of fields that can be rendered in a form as defined
// by the form elements. It is a superset of form fields saved in the database
export enum FormFieldType {
  BottomTextInput = "BottomTextInput",
  TextInput = "TextInput",
  Checkbox = "Checkbox",
  Text = "Text",
  Date = "Date",
  Time = "Time",
  Location = "Location",
  Selection = "Selection",
}

export enum FormType {
  "AUTHORITY_TO_CONVEY" = "AUTHORITY_TO_CONVEY",
  "A2" = "A2",
  "A3" = "A3",
  "A4" = "A4",
  "A6" = "A6",
  "A7" = "A7",
  "A8" = "A8",
  "A10" = "A10",
  "A11" = "A11",
  "STATEMENT_OF_REASON" = "STATEMENT_OF_REASON",
}

export enum FormSampleText {
  "SECTION_4_OPINION" = "SECTION_4_OPINION",
  "SECTION_4_REASONS" = "SECTION_4_REASONS",
}

export const FormTypes = [
  FormType.AUTHORITY_TO_CONVEY,
  FormType.A2,
  FormType.A3,
  FormType.A4,
  FormType.A6,
  FormType.A7,
  FormType.A8,
  FormType.A10,
  FormType.A11,
  FormType.STATEMENT_OF_REASON,
];

// FIELDS
export enum FormFieldsAmhp {
  AmhpName = "amhpName",
  AmhpAddress = "amhpAddress",
  AmhpEmailAddress = "amhpEmailAddress",
  Approved = "approved",
  AssessmentDate = "assessmentDate",
  AssessmentTime = "assessmentTime",
  AuthorisedBy = "authorisedBy",
  AuthorityName = "authorityName",
  DegreeOfCertainty = "degreeOfCertainty",
  Informed = "informed",
  KnownRelativeAddress = "knownRelativeAddress",
  KnownRelativeName = "knownRelativeName",
  HospitalName = "hospitalName",
  HospitalAddress = "hospitalAddress",
  LocalAuthorityName = "localAuthorityName",
  NearestRelativeConsulted = "nearestRelativeConsulted",
  NearestRelativeKnown = "nearestRelativeKnown",
  NoNearestRelatives = "noNearestRelatives",
  NotConsulted = "notConsulted",
  NotConsultedRelativeRelation = "notConsultedRelativeRelation",
  Opinion = "opinion",
  PatientAddress = "patientAddress",
  PatientName = "patientName",
  ReasonForNoConsultation = "reasonForNoConsultation",
  Reasons = "reasons",
  SuspectedRelativeAddress = "suspectedRelativeAddress",
  SuspectedRelativeName = "suspectedRelativeName",
  SuspectedRelativeNotConsultedAddress = "suspectedRelativeNotConsultedAddress",
  SuspectedRelativeNotConsultedName = "suspectedRelativeNotConsultedName",
  // Conveyancing
  AmhpContactNumber = "amhpContactNumber",
  AmhpTeamName = "amhpTeamName",
  DelegateAuthority = "delegateAuthority",
  ConfirmForms = "confirmForms",
  ConfirmFormsAccompanying = "confirmFormsAccompanying",
  FormsBeingSubmitted = "formsBeingSubmitted",
  FormsBeingSubmittedElectronically = "formsBeingSubmittedElectronically",
  SummaryOfRisk = "summaryOfRisk",
  AdditionalInformation = "additionalInformation",
  BookingReference = "bookingReference",
}

export enum FormFieldsDoctor {
  AssessmentDate = "assessmentDate",
  AssessmentTime = "assessmentTime",
  Background = "background",
  DetailedReasons = "detailedReasons",
  ExaminationDate = "examinationDate",
  ExaminationType = "examinationType",
  FirstPractitionerAddress = "firstPractitionerAddress",
  FirstPractitionerEmailAddress = "firstPractitionerEmailAddress",
  FirstPractitionerExaminationDate = "firstPractitionerExaminationDate",
  FirstPractitionerName = "firstPractitionerName",
  Hospitals = "hospitals",
  PatientAddress = "patientAddress",
  PatientDetainReason = "patientDetainReason",
  PatientName = "patientName",
  PractitionerAddress = "practitionerAddress",
  PractitionerEmailAddress = "practitionerEmailAddress",
  PractitionerName = "practitionerName",
  OpinionReason = "opinionReason",
  ReasonsForDetention = "reasonsForDetention",
  SecondPractitionerAddress = "secondPractitionerAddress",
  SecondPractitionerExaminationDate = "secondPractitionerExaminationDate",
  SecondPractitionerName = "secondPractitionerName",
  SecondPractitionerEmailAddress = "secondPractitionerEmailAddress",
  Section2delay = "section2delay",
  InformalDecision = "informalDecision",
}

type DBFormField = {
  name: string;
  type: FormFieldType;
  valueArray: Array<string | null> | null;
  valueLocation: LocationNameInput | null;
  value: string | null;
};

type FormField = {
  name: FormFieldsAmhp | FormFieldsDoctor; // internal name
  type: FormFieldType;
  valueArray?: string[]; // for fields requiring an array to store data
  valueLocation?: LocationNameInput; // for location ffields
  value?: string; // for all other fields
};

export type Sections = {
  name: string; // convenience possibly to hydrate on client?
  fields: FormField[];
};

export type SignedForm = {
  id: string;
  formType: FormType;
  signed: string[];
  createdAt: string;
  patientName: string;
};

export type ExternalDoctorRecipient = {
  email?: string;
  phone?: string;
  fullName: string;
};

export type CreateMHAFormInput = {
  assessmentId?: string; // if not set, amhpTeamInboxId must be set
  amhpTeamInboxId?: string; // set when sending to an inbox only, no assessment id
  amhpTeamId: string;
  amhpTeamName?: string;
  authors?: string[]; // usernames of authors, if amhp is sending the form to an external doctor(s). Otherwise not set
  doctorNames?: string[]; // names of doctors, to display inside amhp team inbox
  status: FormStatus;
  doctorMHAFormAddress?: LocationNameInput;
  isExternalDoctor?: boolean;
  recipient?: ExternalDoctorRecipient; // used only when isExternalDoctor = true
  type: FormType;
  data?: {
    sections: {
      name: string;
      fields: MHAFormField[];
    }[];
  };
};

export type UpdateMHAFormInput = {
  id: string; // existing form ID
  nextVersion: number; // integer = version + 1 from existing model
  assessmentId?: string; // can be new when form attached to assessment
  status: FormStatus;
  amhpTeamId?: string; // only ever used when doctor reassigns form to different amhp team
  amhpTeamName?: string;
  amhpTeamInboxId?: string | null; // if null, moved out of inbox and into an assessment.
  authors?: string[]; // update when need to add an author
  doctorMHAFormAddress?: LocationNameInput;
  isExternalDoctor?: boolean;
  createdBy?: string; // cognito username of user who created the form; needed to check if user allowed to reassign amhp team;
  type: FormType;
  // no notes, notes are generated server-side
  data?: {
    // full data model needed. Overrides existing data
    sections: Sections[];
  };
};

export interface MHAFormField {
  name: FormFieldsAmhp | FormFieldsDoctor;
  type: FormFieldType;
  valueArray: Array<string | null> | null;
  value: string | null;
  valueLocation: LocationNameInput | null;
}

export interface MHAForm {
  id: string;
  version: number;
  assessmentId: string;
  type: FormType;
  status: FormStatus;
  createdAt: string;
  createdBy: string;
  amhpTeamInboxId?: string;
  amhpTeamId: string;
  authors: string[]; // usernames
  notes: string[]; // formatted as EVENT_TYPE@USER_FULL_NAME(optional EMAIL or PHONE)@TIMESTAMP
  data: MHAFormData | null;
  isDeleted?: true | null;
  signed: string[];
  sha256: string;
}
export interface MHAFormData {
  sections: MHAFormSection[];
}

export interface MHAFormSection {
  name: string;
  fields: MHAFormField[];
}

export enum Itemisation {
  ROMAN = "Roman",
  ALPHABETICAL = "Alphabetical",
}

// MHA FORM LABEL
interface InformationFormLabel {
  instruction: string;
  guidance?: string;
  pdfOnlyGuidance?: string;
  appOnlyGuidance?: string;
}

interface GuidanceFormLabel {
  instruction?: string;
  guidance: string;
  pdfOnlyGuidance?: string;
  appOnlyGuidance?: string;
}

interface InstructionAndGuidanceFormLabel {
  instruction: string;
  guidance: string;
  pdfOnlyGuidance?: string;
  appOnlyGuidance?: string;
}

interface ReadonlyGuidanceLabel {
  instruction?: string;
  guidance?: string;
  pdfOnlyGuidance?: string;
  appOnlyGuidance?: string;
}

export type MHAFormLabelProps = (
  | InformationFormLabel
  | GuidanceFormLabel
  | InstructionAndGuidanceFormLabel
  | ReadonlyGuidanceLabel
) & {
  readonly?: boolean;
  disabled?: boolean;
  formType?: FormType;
};
interface FormLabelElement {
  component: "Text";
  componentProps: MHAFormLabelProps;
  genericProps?: ConditionalElementProps;
  fullWidth?: true;
}

// MHA LOCATION

type FormLocation = LocationNameInput & {
  description: string;
};
export interface LocationInputProps {
  label: string;
  error?: boolean;
  errorText?: string;
  locationValue?: string;
  addressChangeWarning?: string;
  disableStrictPostcodeValidation?: boolean;
}

interface LocationElement {
  component: "Location";
  fieldName: FormFieldsAmhp | FormFieldsDoctor;
  componentProps: LocationInputProps;
  genericProps?: ConditionalElementProps;
}

interface DoctorLocationElement {
  component: "DoctorLocation";
  fieldName: FormFieldsAmhp | FormFieldsDoctor;
  componentProps: LocationInputProps;
  genericProps?: ConditionalElementProps;
}

// MHA DATE
export interface DateProps {
  value?: Dayjs; // Value can be optional, so we can define component elements that do not have a value to begin with
  label?: string;
}

interface DateElement {
  component: "Date";
  fieldName: FormFieldsAmhp | FormFieldsDoctor;
  componentProps: DateProps;
  genericProps?: ConditionalElementProps;
}

// MHA TIME
export interface TimeProps {
  value?: {
    hours: number;
    minutes: number;
  }; // Value can be optional, so we can define component elements that do not have a value to begin with
  label?: string;
}
interface TimeElement {
  component: "Time";
  fieldName: FormFieldsAmhp | FormFieldsDoctor;
  componentProps: TimeProps;
  genericProps?: ConditionalElementProps;
}

// MHA CHECKBOX

export interface MHACheckboxOptionProps {
  status: string;
  label?: string | undefined;
  prefix?: string;
}

export interface MHAItemisedCheckboxOptionProps extends MHACheckboxOptionProps {
  prefix: string;
}

export interface MHACheckboxProps {
  itemisation?: Itemisation;
  options: (MHACheckboxOptionProps | MHAItemisedCheckboxOptionProps)[];
  readonly?: boolean;
  disabled?: boolean;
  error?: boolean;
  errorText?: string;
}

export interface MHAEditableCheckboxComponentProps extends MHACheckboxProps {
  checkedValues: string[];
  onPress: (value: string[]) => void;
}

interface CheckboxElement {
  component: "Checkbox";
  fieldName: FormFieldsAmhp | FormFieldsDoctor;
  componentProps: MHACheckboxProps;
  genericProps?: ConditionalElementProps;
}

interface TextInputElement {
  component: "TextInput";
  fieldName: FormFieldsAmhp | FormFieldsDoctor;
  componentProps: MHATextInputProps;
  genericProps?: ConditionalElementProps;
}

// MHA TEXT WITH ICON
export interface TextWithIconProps extends TypographyProps {
  iconName: string;
  iconColor?: string;
  iconPosition?: "flex-start" | "center";
}
interface TextWithIconElement {
  component: "TextWithIcon";
  componentProps: TextWithIconProps;
  genericProps?: ConditionalElementProps;
}

// MHA BOTTOM TEXTINPUT
export interface BottomTextInputProps {
  label?: string;
  value?: string;
  defaultText?: string;
  buttonLabel?: string;
  error?: boolean;
  errorText?: string;
  setValue?: (value: string) => void;
  helperText?: string;
  onBlur?: () => void;
  readonly?: boolean;
  formType?: FormType;
  sampleText?: FormSampleText;
  hideSampleText?: boolean;
  compact?: boolean;
}
interface BottomTextInputElement {
  component: "BottomTextInput";
  fieldName: FormFieldsAmhp | FormFieldsDoctor;
  componentProps: BottomTextInputProps;
  genericProps?: ConditionalElementProps;
  fullWidth?: boolean;
}

// MHA RADIO
export interface RadioOptionsListProps {
  itemisation?: Itemisation;
  options: {
    label: string;
    value: string;
    prefix?: string;
  }[];
}

interface RadioOptionsListElement {
  component: "RadioOptionsList";
  fieldName: FormFieldsAmhp | FormFieldsDoctor;
  componentProps: RadioOptionsListProps;
  genericProps?: ConditionalElementProps;
}

// MHA SELECTION
export interface MHASelectionComponentProps {
  label: string;
  options: {
    id: string;
    name: string;
    conditionalComponents?: ComponentElement[];
  }[];
  readonly?: boolean;
  disabled?: boolean;
  error?: boolean;
  errorText?: string;
}

export interface MHAEditableSelectionComponentProps extends MHASelectionComponentProps {
  value: string;
  setValue: (value: string) => void;
}

interface SelectionElement {
  component: "Selection";
  fieldName: FormFieldsAmhp | FormFieldsDoctor;
  componentProps: MHASelectionComponentProps;
  genericProps?: ConditionalElementProps;
}

export type ComponentElement =
  | FormLabelElement
  | LocationElement
  | DoctorLocationElement
  | DateElement
  | TimeElement
  | CheckboxElement
  | TextInputElement
  | TextWithIconElement
  | BottomTextInputElement
  | RadioOptionsListElement
  | SelectionElement;

export type indentationLevel = 1 | 2 | 3;
interface ConditionalElementProps {
  externalDoctorCopy?: string;
  conditionallyRenderedValues?: string[];
  conditionallyRenderedBy?: FormFieldsAmhp | FormFieldsDoctor;
  conditionallyDisabledValues?: string[];
  conditionallyDisabledBy?: FormFieldsAmhp | FormFieldsDoctor;
  conditionallyDisabledSection?: string;
  conditionallyReadonlyBy?: FormFieldsAmhp | FormFieldsDoctor;
  conditionallyReadonlyValues?: string[];
  conditionallyReadonlySection?: string;
  readonlyIfInitialValue?: boolean;
  nullable?: boolean;
  indentation?: indentationLevel;
  pdfBreak?: FormType[];
}

interface CopiedPaperTextInputProps {
  mode?: "flat" | "outlined";
  disabled?: boolean;
  label?: string;
  placeholder?: string;
  error?: boolean;
  onChangeText?: (text: string) => void;
  underlineColor?: string;
  multiline?: boolean;
  numberOfLines?: number;
  value?: string;
  style?: any;
}

export interface CopiedS12InputProps extends CopiedPaperTextInputProps {
  icon?: string;
  onIconPress?: () => void;
  error?: boolean;
  errorText?: string;
  containerStyle?: any;
  underlabel?: string;
  noBottomBorder?: boolean;
  supplementaryInfo?: string;
  stretchLines?: boolean; // auto grow component based on textArea height
}

export interface MHATextInputProps extends CopiedS12InputProps {
  readonly?: boolean;
  externalDoctorCopy?: string;
}

export type CreateMHAFormField = {
  type: FormFieldType;
  name: FormFieldsAmhp | FormFieldsDoctor;
  value: string | null;
  valueArray: string[] | null;
  valueLocation: LocationNameInput | null;
};

export type FormListItem = {
  id: string;
  status: FormStatus;
  formType: FormType;
  historyElements: string[];
  signed: string[];
  createdBy?: string;
  authors?: string[];
  data?: {
    sections: Array<{ name: string }>;
  };
  isDeleted?: boolean;
  expired?: boolean;
  createdAt: string;

  // fields for basic/reassignable version
  patientName?: string;
  amhpTeamName?: string;
  reassignable?: boolean; // enable reassign button if other teams exist that have feature enabled
  examinationDate?: Dayjs;
};

export type MhaFormVersion =
  | 1 // Original MHA form definitions
  | 2; // Policy change which now requires email addresses of form creators

/**
 * RE: External Doctors
 * An External Doctor can:
 *  - complete a Single Med Rec (in which case they are a "Doctor"), or
 *  - co-sign a Joint Med Rec (in which case they are a "SecondDoctorInJointForm")
 */
export type UserTypeWhoCanModifyForms = "AMHP" | "Doctor" | "PrimaryDoctorInJointForm" | "SecondDoctorInJointForm";
export type FormCategory = "AMHPApplication" | "UnattachedSingleMedRec" | "SingleMedRec" | "JointMedRec";

export type ValidFormStatusTransitionsObject = {
  [key in FormCategory]: {
    [key in FormStatus]?: { [key in FormStatus]?: Array<UserTypeWhoCanModifyForms> };
  };
};

export interface FormattedPdfData {
  [key: string]: {
    [key: string]: MHAFormField;
  };
}
