import React, { useCallback } from "react";
import { TextInput } from "../TextInput/TextInput";
import { TouchableRipple } from "react-native-paper";
import { TypographyType } from "@/models/Typography";
import { LocationSearchItem, RetrieveDataObject } from "../LocationSearch/LocationSearch.props";
import Text from "../Text";
import { useBottomSheet } from "@/hooks";
import { Keyboard } from "react-native";

export interface LocationProps {
  label?: string;
  locationValue?: string;
  setLocationValue: (value: RetrieveDataObject) => void;
  onDismiss?: () => void;
  initialResults?: LocationSearchItem[];
  hideIcon?: boolean;
  disabled?: boolean;
  error?: boolean;
  errorText?: {
    postcode: string;
  };
}

export const Location = (props: LocationProps) => {
  const { openBottomSheet, closeBottomSheet } = useBottomSheet();
  const label = props.label || "Address";
  const icon = props.hideIcon ? undefined : "location-on";

  const passState = useCallback(
    (locationData: RetrieveDataObject) => {
      props.setLocationValue(locationData);
      closeBottomSheet();
    },
    [props.setLocationValue]
  );

  const onContainerPress = (value: string) => {
    Keyboard.dismiss();
    showLocationSearch(value);
  };

  const showLocationSearch = (value: string) => {
    return openBottomSheet({
      type: "locationSearch",
      data: {
        initialQuery: props.locationValue || value,
        initialResults: props.initialResults || [],
        retrieveData: passState,
        onDismiss: props.onDismiss,
      },
    });
  };

  const TextRender = useCallback(
    renderProps => {
      const { style, value } = renderProps;
      return (
        <TouchableRipple onPress={() => onContainerPress(value)}>
          <Text format={TypographyType.Regular} style={style}>
            {value}
          </Text>
        </TouchableRipple>
      );
    },
    [props.initialResults, passState]
  );

  return (
    <TextInput
      icon={icon}
      label={label}
      error={props.error}
      errorText={props.errorText?.postcode}
      value={props.locationValue || ""}
      render={TextRender}
    />
  );
};
