import React, { useMemo } from "react";
import { View, StyleSheet } from "react-native";

import { mqWeb, useDeviceWidth } from "../../../utils/helpers";
import { palette, spacing } from "../../../theme";

import { LogoVector } from "../../../components/LogoVector";

const TopLogoArea = () => {
  const deviceWidth = useDeviceWidth();
  const isWebView = mqWeb();

  const bgSwoosSizeModifier = useMemo(() => (isWebView ? 4 : 2), [isWebView]);

  const bgSwoosh = React.useMemo(
    () => [
      styles.bgSwoosh,
      {
        width: deviceWidth * bgSwoosSizeModifier,
        height: deviceWidth * bgSwoosSizeModifier,
        borderRadius: deviceWidth * bgSwoosSizeModifier,
      },
    ],
    [deviceWidth, bgSwoosSizeModifier]
  );

  return (
    <View style={styles.container}>
      <View style={styles.bgSwooshContainer}>
        <View style={bgSwoosh} />
      </View>

      <View style={styles.logo}>
        <View style={styles.logoOuterCircle} />
        <View style={styles.logoInnerCircle} />
        <LogoVector size={55} />
      </View>
    </View>
  );
};

export default TopLogoArea;

const styles = StyleSheet.create({
  bgSwooshContainer: {
    position: "absolute",
    left: 0,
    bottom: 0,
    width: "100%",
    height: "100%",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
    overflow: "hidden",
  },
  bgSwoosh: {
    borderWidth: 10,
    borderColor: palette.bgSwooshBorder,
    backgroundColor: palette.blue,
  },

  container: {
    position: "relative",
    alignItems: "center",
    overflow: "hidden",
    paddingTop: spacing[20],
    paddingBottom: spacing[30],
    backgroundColor: palette.transparent,
  },

  logo: {
    position: "relative",
    justifyContent: "center",
    alignItems: "center",
    width: 80,
    height: 80,
  },

  logoOuterCircle: {
    position: "absolute",
    left: 0,
    top: 0,
    width: 80,
    height: 80,
    borderRadius: 100,
    backgroundColor: palette.aqua,
    opacity: 0.15,
  },

  logoInnerCircle: {
    position: "absolute",
    left: 6,
    top: 6,
    width: 68,
    height: 68,
    borderRadius: 100,
    backgroundColor: palette.aqua,
    opacity: 0.33,
  },
});
