import { useEffect, useState } from "react";
import { Linking, Platform } from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";

/**
 * Get initial navigation state if it exists. This will restore the user to
 * their previous location within the app on restart.
 *
 * The __DEV__ condition here sets the initial value if we are in the dev
 * environment. Read the official documentation for more information on how
 * this works.
 *
 * This will only restore state on native devices, and will not work on web.
 *
 * @param persistenceKey AsyncStorage key that state is storef against.
 * @link https://reactnavigation.org/docs/state-persistence/
 */
export default function useInitialNavigationState(persistenceKey: string) {
  const [isReady, setIsReady] = useState(!__DEV__);
  const [initialState, setInitialState] = useState();

  useEffect(() => {
    const restoreState = async () => {
      try {
        const initialUrl = await Linking.getInitialURL();
        const shouldRestore = Platform.OS !== "web" && initialUrl == null;

        if (shouldRestore) {
          const savedStateString = await AsyncStorage.getItem(persistenceKey);
          const state = savedStateString ? JSON.parse(savedStateString) : undefined;

          if (state !== undefined) {
            setInitialState(state);
          }
        }
      } finally {
        setIsReady(true);
      }
    };

    if (!isReady) {
      restoreState();
    }
  }, [isReady, persistenceKey]);

  return { initialState, isReady };
}
