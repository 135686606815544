import React from "react";
import { View } from "react-native";
import { Divider } from "react-native-paper";

import { Button } from "@/components/Button";
import { ContentWrap } from "@/components/ContentWrap";
import { DetailsSection } from "@/components/DetailsSection";
import { IconName } from "@/components/Icon";
import ItemSpacer from "@/components/ItemSpacer";
import { TextWithIcon } from "@/components/Text";

import { TypographyType } from "@/models/Typography";
import { AvailabilityState } from "libs/types/availability";
import { Gender, LocationNameInput } from "libs/types/API";

import { color, spacing } from "@/theme";
import { formatAddress } from "libs/formatters/address";
import { displayAvailabilityType } from "@/utils/helpers";

interface BottomSheetUserProfile {
  availabilityStatus: AvailabilityState;
  distance: number;
  address: LocationNameInput;
  gender: Gender;
  mhts: string[];
  specialisms: string[];
  languages: string[];
  notes: string;
  onButtonPress: () => void;
}

export const UserDetails = ({
  availabilityStatus,
  distance,
  address,
  gender,
  mhts,
  specialisms,
  languages,
  notes,
  onButtonPress,
}: BottomSheetUserProfile) => {
  const isAvailable = [
    AvailabilityState.oncall,
    AvailabilityState.available,
    AvailabilityState.independent,
    AvailabilityState.trust,
    AvailabilityState.inAssessment,
  ].includes(availabilityStatus);

  return (
    <ContentWrap>
      <ItemSpacer gap={10}>
        <TextWithIcon
          iconName={isAvailable ? IconName.check_circle : IconName.remove_circle}
          iconColor={isAvailable ? color.available : color.textError}
          format={TypographyType.Regular}
          style={{ color: color.textLight }}
        >
          {displayAvailabilityType(availabilityStatus)}
        </TextWithIcon>
        <TextWithIcon iconName={IconName.near_me} format={TypographyType.Regular} style={{ color: color.textLight }}>
          {`${distance.toFixed(2)} miles away`}
        </TextWithIcon>
        <TextWithIcon
          iconName={IconName.location_on}
          format={TypographyType.Regular}
          style={{ color: color.textLight }}
        >
          {formatAddress(address)}
        </TextWithIcon>
        <TextWithIcon iconName={IconName.note} format={TypographyType.Regular} style={{ color: color.textLight }}>
          {notes}
        </TextWithIcon>
      </ItemSpacer>
      <View style={{ padding: 30 }}>
        <Divider />
      </View>
      <DetailsSection
        columns={1}
        items={[
          {
            label: "Gender",
            value: gender,
          },
          {
            label: "Associated MHTs",
            value: mhts.join(", ") || "None",
          },
          {
            label: "Specialisms",
            value: specialisms.join(", ") || "None",
          },
          {
            label: "Languages",
            value: languages.join(", ") || "None",
          },
        ]}
      />
      <Button onPress={onButtonPress} width="100%" marginTop={spacing[50]}>
        View Full Profile
      </Button>
    </ContentWrap>
  );
};
