import React from "react";
import { StackActions } from "@react-navigation/core";
import { BackButtonProps } from "@/components/BackButton/BackButton.props";
import { PreviewScreen } from "@/components/MeetingRoom";
import { AppScreen } from "@/layouts/AppScreen/AppScreen";
import { color } from "@/theme";
import { RouteKeys } from "@/navigationv2";

import {
  AMHPAssessmentsRoutes,
  DoctorTabRoutes,
  DoctorVisitsRoutes,
  ScreenNavigationProps,
  ScreenOptions,
} from "@/navigationv2/types";
import { createScreenTitle } from "@/navigationv2/utilities";

interface PropTypes
  extends ScreenNavigationProps<
    DoctorTabRoutes,
    AMHPAssessmentsRoutes & DoctorVisitsRoutes,
    RouteKeys.MeetingRoomPreviewScreen
  > {}

const MeetingRoomPreviewScreen = (props: PropTypes) => {
  const { route } = props;
  const backButtonConfig: BackButtonProps = {
    enabled: true,
    float: true,
    color: color.textWhite,
    onPress: () => props.navigation.dispatch(StackActions.pop()),
  };

  return (
    <AppScreen
      contextLabel="Meeting Room"
      pageTitle="Device Selection"
      backButton={backButtonConfig}
      testID="MeetingRoomPreviewScreen"
    >
      <PreviewScreen assessmentId={route.params.assessmentId} />
    </AppScreen>
  );
};

const screenOptions: ScreenOptions = {
  title: createScreenTitle("Meeting Room Preview"),
};

MeetingRoomPreviewScreen.screenOptions = screenOptions;

export default MeetingRoomPreviewScreen;
