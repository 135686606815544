/**
 * Component (i.e. content) for the confirm visit bottom sheet
 */
import React, { Dispatch, useRef, useState } from "react";
import { Keyboard, StyleSheet, TouchableOpacity, View } from "react-native";
import { TextInput as RNPTextInput } from "react-native-paper";
import dayjs, { Dayjs } from "dayjs";

import { TypographyType } from "@/models/Typography";
import { spacing } from "@/theme";

import { ContentWrap } from "@/components/ContentWrap";
import DualDatePicker from "@/components/DatePicker/DualDatePicker";
import DualTimePicker from "@/components/TimePicker/DualTimePicker";
import Text from "@/components/Text";
import { TextInput } from "@/components/TextInput";

type AssessmentTime = {
  hours: number;
  minutes: number;
};

interface ConfirmVisitBottomSheetProps {
  assessmentDate: Dayjs;
  assessmentTime: AssessmentTime;
  setAssessmentDate: Dispatch<React.SetStateAction<Dayjs>>;
  setAssessmentTime: Dispatch<React.SetStateAction<AssessmentTime>>;
  setVisitNotes: Dispatch<React.SetStateAction<string>>;
}

export const ConfirmVisitBottomSheet = ({
  assessmentDate,
  assessmentTime,
  setAssessmentDate,
  setAssessmentTime,
  setVisitNotes,
}: ConfirmVisitBottomSheetProps) => {
  const notesInput = useRef<RNPTextInput>();
  // Text inputs should always be able to set their own inner state to prevent prop digest cycle related flickering
  const [notesText, setNotesText] = useState<string>("");
  return (
    <ContentWrap>
      <Text format={TypographyType.Regular} marginBottom={30}>
        Please confirm the time and date of the doctor visit.
      </Text>

      <View>
        <Text format={TypographyType.RegularBold}>Date:</Text>
        <DualDatePicker
          max={dayjs()
            .add(28, "day")
            .toDate()}
          min={dayjs(assessmentDate)
            .subtract(30, "day")
            .toDate()}
          value={assessmentDate}
          onValueChange={value => setAssessmentDate(value)}
          style={styles.noStretch}
        />
      </View>

      <View>
        <Text format={TypographyType.RegularBold}>Time:</Text>
        <DualTimePicker label="Visit time" value={assessmentTime} onValueChange={v => setAssessmentTime(v)} />
      </View>

      <TouchableOpacity activeOpacity={1} onPress={() => notesInput.current && notesInput.current.focus()}>
        <>
          <Text format={TypographyType.RegularBold} style={styles.notesHeading}>
            Visit Notes:
          </Text>
          <Text format={TypographyType.Micro} style={styles.notesInfoText}>
            Please do not include any clinical information from assessment notes
          </Text>
        </>
      </TouchableOpacity>

      <TextInput
        label=""
        ref={notesInput}
        numberOfLines={2}
        multiline={true}
        autoCorrect={true}
        spellCheck={true}
        value={notesText}
        returnKeyType="done"
        blurOnSubmit={true}
        onSubmitEditing={() => {
          Keyboard.dismiss();
        }}
        onChangeText={v => {
          setNotesText(v);
          setVisitNotes(v);
        }}
        style={styles.noStretch}
      />
    </ContentWrap>
  );
};

const styles = StyleSheet.create({
  noStretch: {
    flex: 0,
    flexShrink: 0,
    flexBasis: "auto",
  },

  notesHeading: {
    marginBottom: spacing[10],
  },

  notesInfoText: {
    marginBottom: spacing[15],
  },
});
