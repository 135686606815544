import React, { useMemo } from "react";
import { View, StyleSheet } from "react-native";

import { TypographyType } from "@/models/Typography";
import { palette, spacing } from "../../theme";

import Icon, { IconName } from "../Icon";
import ItemSpacer from "../ItemSpacer";
import Text from "../Text";

interface InfoTextProps {
  paragraphs: string[];
  textSize?: "Micro" | "Tiny" | "Small" | "Regular";
  textStyle?: any;
}

export const InfoText = ({ paragraphs, textSize = "Small", textStyle }: InfoTextProps) => {
  const TextComponents = paragraphs.map((p, i) => (
    <Text key={i} format={TypographyType[textSize]} color={palette.greyBlue}>
      {p}
    </Text>
  ));

  const iconStyles = useMemo(
    () => [
      styles.icon,
      {
        transform: [{ translateY: 2 }],
      },
    ],
    [textSize]
  );

  const iconSize = useMemo(
    () => (textSize === "Regular" ? 22 : textSize === "Small" ? 18 : textSize === "Tiny" ? 16 : 14),
    [textSize]
  );

  return (
    <View style={styles.container}>
      <Icon size={iconSize} color={palette.greyBlue} name={IconName.info_outline} style={iconStyles} />
      <View style={textStyle || styles.text}>
        <ItemSpacer gap={10}>{TextComponents}</ItemSpacer>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "flex-start",
  },

  icon: {
    transform: [{ translateY: 2 }],
  },

  text: {
    flexShrink: 1,
    paddingLeft: spacing[10],
  },
});
