/**
 * Claim Processing - Data
 * Fetch claim data according to the user logged in and what columns they can view.
 * Get the claims for each type, then pass to the claims table component or the CSV generator.
 */
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { Platform } from "react-native";
import dayjs from "dayjs";
import merge from "lodash.merge";
import { CSVLink } from "react-csv";
import { useRecoilValue } from "recoil";
import { TablePaginator } from "@/components/TablePaginator";
import { formatDateFileName } from "libs/dates/format";
import { ClaimStatus, FindClaimsQueryVariables } from "libs/types/API";
import { NextToken } from "libs/types/graphql";
import {
  hasAdditionalExpenses,
  hasMileage,
  ccgRequiresAmhpTeamName,
  ccgDisplayClaimLocation,
  ccgRequiresDoctorAdditionalConfirmation,
  mhtAndLineManager,
  ccgRequiresDoctorEmployedStatus,
} from "libs/utils/featureFlags";
import { GET_USER_ORGS, GetUserOrgResponse, GetUserOrgResponseOrg, GetUserOrgVariables } from "@/models/Organisation";
import { GQL_LIMITS } from "@/models/gql/limits";
import { userDetails } from "@/utils/recoil";
import { retrieveFilter, adjustFilter, constructCsvData } from "./helpers";
import { QUERY_GET_MHTS } from "@/models/Claim";
import { ErrorMessage } from "@/components/Error/Error";
import ClaimProcessing from "./ClaimProcessing";
import { ClaimProcessingQueryVariables, CCGClaimsResponse, CCGClaimsResponseWithItems } from "./ClaimProcessing.props";
import { ClaimProcessingContext } from "./ClaimProcessingProvider";
import { ClaimModelActionType, ClaimUiActionType } from "./ClaimProcessingState";

import notFalsy from "libs/utils/notFalsy";
import { CCGClaim } from "libs/types/claim";
import { acceptEulaError } from "@/models/Error";

interface Props {
  orgId?: string;
  type: ClaimStatus | "My" | "Team" | "Search";
  refreshRequired: boolean;
  selectedQuery: any;
  variables?: FindClaimsQueryVariables;
  claimsScreen: string;
  onRefreshComplete: (value: React.SetStateAction<boolean>) => void;
  displayId?: string;
}

interface ExportVariables extends ClaimProcessingQueryVariables {
  from?: string;
  to?: string;
}

const updateFetchedClaims = (
  previousResult: CCGClaimsResponseWithItems,
  { fetchMoreResult }: { fetchMoreResult: CCGClaimsResponseWithItems }
) => {
  const mergedClaims = merge({}, previousResult, {
    claims: {
      items: [...(previousResult.findClaims?.items || []), ...(fetchMoreResult.findClaims?.items || [])],
      nextToken: fetchMoreResult.findClaims?.nextToken,
    },
  });
  return mergedClaims;
};

export default function ClaimProcessQuery(props: Props) {
  const user = useRecoilValue(userDetails);
  const [page, setPage] = useState(0);
  const [hasUnloaded, setHasUnloaded] = useState(false);
  useEffect(
    () => () => {
      setHasUnloaded(true);
    },
    []
  );

  const { state, dispatch } = useContext(ClaimProcessingContext);

  const variables: ClaimProcessingQueryVariables = useMemo(
    () => ({
      ...props.variables,
      userId: user ? user.id : "",
      nextToken: null,
      limit: GQL_LIMITS.ccgClaims,
      csuId: user ? user.csu : undefined,
      orgId: props.orgId,
    }),
    [props.orgId, user]
  );

  const orgsVariables = useMemo(
    () => ({
      userId: user ? user.id : "",
    }),
    []
  );

  const orgsQuery = useQuery<GetUserOrgResponse, GetUserOrgVariables>(GET_USER_ORGS, {
    variables: orgsVariables,
    fetchPolicy: "cache-and-network",
    skip: variables.userId === "",
  });

  // Get the filter that applies to the specific claims screen
  const filter = retrieveFilter(props.claimsScreen, props.orgId, user, props.displayId);
  const orderClause = {
    orderClause: {
      direction: state.columnSort.sortDirection,
      field: state.columnSort.sortField ? state.columnSort.sortField : "createdAt",
    },
  };

  const paginationClause = {
    paginateClause: { limit: GQL_LIMITS.ccgClaims, offset: 0 },
  };

  const claimsVariables = { ...variables, filter, ...orderClause, ...paginationClause };

  const { loading: queryLoading, error, data, refetch, fetchMore } = useQuery<
    CCGClaimsResponse,
    ClaimProcessingQueryVariables
  >(props.selectedQuery, {
    query: props.selectedQuery,
    variables: {
      ...claimsVariables,
    },
    fetchPolicy: "network-only",
  });

  const from = dayjs(state.rangeValues.from)
    .startOf("day")
    .toISOString();
  const to = dayjs(state.rangeValues.to)
    .endOf("day")
    .toISOString();
  // The filter is used to derive the set of claims that appears on the screen. We need to
  // pass the same criteria when setting up the download query. However, we also need to
  // pass the to and from dates as part of the filter. These are the dates entered on the export popup.
  const adjustedFilter = adjustFilter(claimsVariables.filter, from, to);

  const [
    loadRangeData,
    { called, loading: loadingRangeData, data: rangeDataResponse, fetchMore: fetchMoreRangeData },
  ] = useLazyQuery<CCGClaimsResponse>(props.selectedQuery, {
    query: props.selectedQuery,
    variables: {
      ...claimsVariables,
      filter: { ...adjustedFilter },
      paginateClause: { limit: GQL_LIMITS.ccgExportCsvClaims, offset: 0 },
    },
    fetchPolicy: "network-only",
  });

  React.useEffect(() => {
    if (!state.isFirstLoad && props.refreshRequired) {
      // Prevents data from being fetched twice on initial load
      refetch()
        .then(({ data }) => {
          const { items, total } = data?.findClaims || {};
          if (hasUnloaded) {
            return;
          }
          dispatch({
            type: ClaimModelActionType.REFETCH_SUCCESS,
            payload: {
              claims: items,
              nextToken: data?.findClaims?.nextToken,
              totalRecords: total,
            },
          });

          props.onRefreshComplete(false);
        })
        .catch(error => {
          dispatch({
            type: ClaimModelActionType.REFETCH_FAILURE,
            payload: { error },
          });
          props.onRefreshComplete(false);
        });
    } else if (props.refreshRequired) {
      props.onRefreshComplete(false);
    }
  }, [data, props.refreshRequired, state.claims]);

  React.useEffect(() => {
    if (state.shouldLoadMore) {
      fetchMore({
        query: props.selectedQuery,
        variables: {
          ...variables,
          nextToken: state.nextToken,
        },
        updateQuery: updateFetchedClaims,
      })
        .then(({ data }) => {
          const { items, total } = data.findClaims || {};
          return dispatch({
            type: ClaimModelActionType.LOAD_MORE_CLAIMS_SUCCESS,
            payload: {
              additionalClaims: items?.filter(notFalsy) || [],
              nextToken: data.findClaims?.nextToken,
              totalRecords: total,
            },
          });
        })
        .catch(error =>
          dispatch({
            type: ClaimModelActionType.LOAD_MORE_CLAIMS_FAILURE,
            payload: { error },
          })
        );
    }
  }, [state.shouldLoadMore]);

  useEffect(() => {
    if (state.loadingRangeData) {
      loadRangeData({
        variables: {
          ...variables,
          from: dayjs(state.rangeValues.from)
            .endOf("day")
            .toISOString(),
          to: dayjs(state.rangeValues.to)
            .endOf("day")
            .toISOString(),
          limit: GQL_LIMITS.ccgExportCsvClaims,
        } as ExportVariables,
      });
    }
  }, [state.loadingRangeData]);

  useEffect(() => {
    // When we click on the EXPORT button on the CSV export popup, we set the loadingRangeData
    // to indicate that the button was clicked.
    if (called && rangeDataResponse && state.loadingRangeData) {
      // We've got data back
      // Construct rangeData which triggers csvDownload
      // Use fetchMore if there's a nextToken
      const mergedClaims = [];
      const fetchMoreRangeClaims = async (nextoken: NextToken) => {
        await fetchMoreRangeData({
          variables: {
            ...variables,
            from: dayjs(state.rangeValues.from)
              .endOf("day")
              .format(),
            to: dayjs(state.rangeValues.to)
              .endOf("day")
              .format(),
            nextToken: nextoken,
            limit: GQL_LIMITS.ccgExportCsvClaims,
          },
          updateQuery: updateFetchedClaims,
        }).catch(error =>
          dispatch({
            type: ClaimModelActionType.LOAD_RANGE_DATA_FAILURE,
            payload: { error },
          })
        );
      };

      const rangeNextToken = rangeDataResponse.findClaims?.nextToken;
      const claims = rangeDataResponse?.findClaims?.items?.filter((x: CCGClaim) => !!x) || [];
      mergedClaims.push(...claims);

      // Continue the loop if we have more to fetch
      if (rangeNextToken) {
        fetchMoreRangeClaims(rangeNextToken);
        return;
      }

      dispatch({
        type: ClaimModelActionType.LOAD_RANGE_DATA_SUCCESS,
        payload: constructCsvData(
          // filter to only items in the selected org
          orgsQuery?.data?.getUser?.organisations?.items
            ?.filter(i => i.organisation.id === props.orgId)
            .map(i => i.organisation) || [],
          mergedClaims
        ),
      });
    }
  }, [rangeDataResponse, state.loadingRangeData, called]);

  useEffect(() => {
    if (queryLoading && !state.loading) {
      dispatch({ type: ClaimModelActionType.LOAD_CLAIMS });
    }
    if (data && data.findClaims && !queryLoading && state.loading) {
      const nextToken: NextToken = data.findClaims?.nextToken || null;
      const claims = data.findClaims?.items?.filter(notFalsy) || [];
      const total = data.findClaims?.total || 0;
      dispatch({
        type: ClaimModelActionType.LOAD_CLAIMS_SUCCESS,
        payload: {
          claims,
          nextToken,
          totalRecords: total,
        },
      });
    }
  }, [data, queryLoading, state.claims, state.loading]);

  // trigger downloading range data by using CSVLink instead of CSVDownload
  // CSV download does not allow filename to be set, and on windows without an extension
  // it is causing issues for Kris, she can't open these files
  const linkToDownload = useRef<{ link?: { click?: () => void } } | null>(null);
  useEffect(() => {
    if ((state.rangeCsvData?.length || 0) > 0) {
      if (linkToDownload.current?.link?.click) {
        setTimeout(() => linkToDownload?.current?.link?.click && linkToDownload?.current?.link.click(), 1000);
      }
    }
  }, [state.rangeCsvData]);

  if (!user || !user.id) {
    return <ErrorMessage message="User Not Found, Login required" />;
  }

  const orgs: GetUserOrgResponseOrg[] =
    orgsQuery.data &&
    orgsQuery.data.getUser &&
    orgsQuery.data.getUser.organisations &&
    orgsQuery.data.getUser.organisations.items
      ? orgsQuery.data.getUser.organisations.items.map(i => i.organisation)
      : [];

  if (orgsQuery.error) {
    dispatch({
      type: ClaimModelActionType.ORGANISATIONS_LOAD_FAILURE,
      payload: { error: orgsQuery.error },
    });
  }

  // we want a list of all the featureFlags from all the orgs, use union operator to sum them
  const featuresFromAllOrgs = orgs
    .map(o => JSON.parse(o?.featureFlags || "{}"))
    .reduce(
      (acc: any, curr: any) => ({
        ...acc,
        ...Object.entries(curr)
          .filter(([_, value]) => !!value)
          .reduce((a, v) => ({ ...a, [v[0]]: v[1] }), {}),
      }),
      {}
    );

  const currentOrg = orgs.find(o => o.id === props.orgId);
  const featuresFromCurrentOrg = currentOrg?.featureFlags || featuresFromAllOrgs;

  // The field list can change if the user chooses a different organisation.
  const generateFieldList = () => {
    if (props.type === "Search") {
      // We will have arrived here from the ClaimProcessingSearchScreen. The field list in this case
      // is the minimal fields on the claim, with the claim status in addition.
      dispatch({
        type: ClaimUiActionType.SET_FIELD_LIST,
        payload: {
          fieldList: ["claimid", "doctor", "ccg", "status", "receiveddate", "visitdate"]
            .filter(x => !!x)
            // Show more fields in the approved section to account for paid status
            .slice(0, props.type === "Search" ? 7 : 6) as string[],
        },
      });
    } else {
      dispatch({
        type: ClaimUiActionType.SET_FIELD_LIST,
        payload: {
          fieldList: [
            "claimid",
            "doctor",
            mhtAndLineManager(featuresFromCurrentOrg) && "defaultMHT",
            // Note: if MHT and Lind Manager is specified, we ignore the following field in order to have space.
            // For unassigned view, set an employer column; else set a distance column
            // The ClaimProcessingCard will render accordingly
            // Note: only six fields are viewable on the claim screen so if we have the feature flag set for
            // Doctor Additional Confirmation, the following options will be hidden.
            !mhtAndLineManager(featuresFromCurrentOrg) &&
              !ccgRequiresDoctorAdditionalConfirmation(featuresFromCurrentOrg) &&
              (props.orgId === "15F" || props.orgId === "s12TestCCG2"
                ? // Note: 15F: NHS LEEDS CCG, if this CCG, we override and always specify employer instead of distance traveled field
                  //! DEV, s12TestCCG2 also used for testing purposes
                  "employer"
                : ccgDisplayClaimLocation(featuresFromAllOrgs)
                ? "postcode"
                : hasMileage(featuresFromAllOrgs) && "distance"),
            "ccg",
            mhtAndLineManager(featuresFromCurrentOrg) && "lineManager",
            !mhtAndLineManager(featuresFromCurrentOrg) &&
              ccgRequiresAmhpTeamName(featuresFromAllOrgs) &&
              "amhpteamname",
            !mhtAndLineManager(featuresFromCurrentOrg) &&
              ccgRequiresDoctorAdditionalConfirmation(featuresFromCurrentOrg) &&
              "doctorAdditionalConf",
            hasAdditionalExpenses(featuresFromAllOrgs) && props.type === "approved" && "expenses",
            ccgRequiresDoctorEmployedStatus(featuresFromCurrentOrg) && "doctorEmployedStatus",
            "receiveddate",
            // For NHS Coventry and Warwickshire CCG we are replacing the visit date with the approved date on the approved screen.
            // Note: the approved date doesn't appear in the claim. We need to extract it from the notes.
            props.orgId === "B2M3M" && props.type === "approved" ? "approvedDate" : "visitdate",
          ]
            .filter(x => !!x)
            // Show more fields in the approved section to account for paid status
            .slice(
              0,
              props.type === "approved" ? 8 : ccgRequiresDoctorEmployedStatus(featuresFromCurrentOrg) ? 7 : 6
            ) as string[],
        },
      });
    }
  };

  // Generate the field list initially
  if (state.fieldList.length === 0 && orgs.length > 0) {
    generateFieldList();
  }

  // Regenerate the field list if the organisation changes. This applies for instance
  // to the additional doctor confirmation which may be required for one org and
  // not for another
  useEffect(() => {
    // Create a list of column types for claims list views
    if (orgs.length > 0) {
      generateFieldList();
    }
  }, [props.orgId]);

  const getNextPage = (nextPage: number) => {
    setPage(nextPage);
    const nextVariables = {
      ...claimsVariables,
      paginateClause: { limit: GQL_LIMITS.ccgClaims, offset: nextPage * GQL_LIMITS.ccgClaims },
    };

    fetchMore({
      query: props.selectedQuery,
      variables: {
        ...nextVariables,
        nextToken: state.nextToken,
      },
      updateQuery: updateFetchedClaims,
    })
      .then(({ data }) => {
        const { items, total } = data.findClaims || {};
        return dispatch({
          type: ClaimModelActionType.LOAD_PAGE_CLAIMS_SUCCESS,
          payload: {
            additionalClaims: items?.filter(notFalsy) || [],
            nextToken: data.findClaims?.nextToken,
            totalRecords: total,
          },
        });
      })
      .catch(error =>
        dispatch({
          type: ClaimModelActionType.LOAD_MORE_CLAIMS_FAILURE,
          payload: { error },
        })
      );
  };

  const claimProcessing = (
    <>
      <ClaimProcessing
        claims={state.claims.filter(x => props.type !== "Team" || x.assigneeId !== variables.userId)}
        organisations={orgs}
        type={props.type}
        refreshQuery={props.selectedQuery}
        refreshVariables={variables}
        error={state.error}
        loading={state.loading || state.uiLoading}
        resetPage={setPage}
        displayId={props.displayId}
      />
      {!state.uiLoading && (
        <TablePaginator
          currentPage={page}
          handlePageChange={getNextPage}
          total={state.totalRecords || 0}
          pageSize={GQL_LIMITS.ccgClaims}
        />
      )}
    </>
  );

  if (queryLoading || error || data) {
    return (
      <>
        {claimProcessing}
        {state.rangeCsvData &&
          state.rangeCsvData !== null &&
          state.rangeCsvData.length > 0 &&
          Platform.OS === "web" && (
            <CSVLink
              filename={
                formatDateFileName(state.rangeValues.from) + "__" + formatDateFileName(state.rangeValues.to) + ".csv"
              }
              ref={(r: any) => (linkToDownload.current = r)}
              data={state.rangeCsvData}
              target="_self"
            />
          )}
      </>
    );
  } else {
    return <ErrorMessage />;
  }
}
