import { StyleSheet } from "react-native";
import { palette, spacing } from "../../theme";

const css = StyleSheet.create({
  aside: {
    paddingRight: 0,
    borderRightWidth: 0,
  },
  asideWeb: {
    flexBasis: "34%",
    alignSelf: "flex-start",
    paddingRight: spacing[40],
    borderRightWidth: 2,
    borderRightColor: palette.cloud,
  },

  main: {
    paddingTop: spacing[30],
    paddingLeft: 0,
  },
  mainWeb: {
    flexBasis: "66%",
    paddingTop: 0,
    paddingLeft: spacing[40],
  },

  wrap: {
    flexDirection: "column",
  },
  wrapWeb: {
    flexDirection: "row",
  },
});

export default css;
