/* eslint complexity: 0 */
import React from "react";
import { GestureResponderEvent, StyleSheet, View } from "react-native";
import dayjs, { Dayjs } from "dayjs";

import { AvailabilityState, Availability } from "libs/types/availability";

import { getAvailabilityState, getAvailabilityColorForDoctorSearchItem } from "@/models/Availability";
import { TypographyType } from "@/models/Typography";
import { formatSearchResulsPostcode, displayAvailabilityType } from "@/utils/helpers";
import { useDeviceWidth } from "@/hooks";
import { color, palette, spacing } from "@/theme";

import { CardLabel } from "@/components/CardLabel";
import { GenericCard } from "@/components/GenericCard";
import Icon, { IconName } from "@/components/Icon";
import { SwipeAction } from "@/components/SwipeActions";
import Text from "@/components/Text";

import { DoctorSearchResult, MHTItem } from "../../SearchResults.props";
import { formatDateTimeOnly, formatShorterDate } from "libs/dates/format";
import { Badge } from "react-native-paper";

export function DoctorSearchItem(
  props: DoctorSearchResult & {
    onCallDoctor?: any;
    onTextDoctor?: any;
    visitId?: string;
    addDoctorVisit?: (doctorId: string) => void;
    removeDoctorVisit?: (visitId: string, doctorName: string) => Promise<void>;
    onPress?: (e: GestureResponderEvent) => void;
    isSelected: boolean;
    isAddedToAssessment?: boolean;
    isUnknown?: boolean;
    onPressAction?: string;
    timeSpan: TimeSpan;
    onHoliday?: {
      isAway: boolean;
      isPartial: boolean;
      availability: Dayjs | null;
    };
    nextAvailability?: any;
    // I attempted to construct a proper type for this below but its so difficult without a full knowledge of the systems workings
    // {
    //   distance?: number;
    //   end?: string;
    //   start?: string;
    //   availability?: {
    //     distance?: number;
    //     endDate?: string;
    //     endHour?: number;
    //     id?: string;
    //     location?: {
    //       lon?: number;
    //       lat?: number;
    //     };
    //     locationName?: {
    //       address?: string;
    //       addressNotes?: string;
    //       city?: string;
    //       postcode?: string;
    //     };
    //     notes?: string;
    //     onCall: boolean;
    //     overnightElement?: number;
    //     rotaId?: string | number;
    //     rotaName?: string;
    //     rrule?: string;
    //     type?: string;
    //   };
    // }
    booked?: {
      isBooked: boolean;
      partiallyBooked: boolean;
    };
    showNotes?: boolean;
  }
): JSX.Element {
  const deviceWidth = useDeviceWidth();
  const smallDevice = deviceWidth <= 320;

  const mhts =
    props.mhtEmployers.length &&
    props.mhtEmployers.map((mht: { id: string; mht: MHTItem } | string) =>
      typeof mht === "object" ? mht.mht.abbreviation : mht
    );

  const doctorAvailability = getAvailabilityState(
    !!props.isAddedToAssessment,
    props.isDoctorAvailable,
    props.availability,
    props.mhtEmployers,
    props.availabilities,
    props.onHoliday,
    props.booked
  );

  const isAvailable = !doctorAvailabilityTypes.includes(doctorAvailability);

  const notAvailable = AvailabilityState.unavailable ? color.unavailable : color.noInformation;

  const LeftActions = (progress: any, dragX: any) => (
    <SwipeAction
      direction="left"
      dragX={dragX}
      swipeActions={[
        {
          icon: <Icon name="call" size={24} />,
          color: isAvailable ? color.available : notAvailable,
          onPress: props.onCallDoctor,
        },
        {
          icon: <Icon name="message" size={24} />,
          color: color.claimProcessing,
          onPress: props.onTextDoctor,
        },
      ]}
    />
  );

  const RightActions = (progress: any, dragX: any) => (
    <SwipeAction
      direction="right"
      dragX={dragX}
      swipeActions={[
        {
          icon: (
            <Icon
              name={props.isAddedToAssessment ? "remove-circle" : "person-add"}
              size={36}
              color={props.isAddedToAssessment ? color.textWhite : color.text}
            />
          ),
          color: props.isAddedToAssessment ? color.textError : color.inAssessment,
          onPress: props.isAddedToAssessment
            ? () => props.removeDoctorVisit && props.removeDoctorVisit(props.visitId!, props.name)
            : () => props.addDoctorVisit && props.addDoctorVisit(props.id),
        },
      ]}
    />
  );

  const availabilityColor = getAvailabilityColorForDoctorSearchItem(doctorAvailability);

  const doctorMHTs: string =
    doctorAvailability === AvailabilityState.trust
      ? props.availability && props.availability.availability && props.availability.availability.mht
        ? props.availability.availability.mht.abbreviation
        : "N/A"
      : mhts
      ? mhts.join(", ")
      : "";

  // TODO: replace ucFirst by rewording availability states across the platform - Michael
  return (
    <GenericCard
      onPress={props.onPress}
      marker={availabilityColor}
      onSwipeLeft={props.onCallDoctor && LeftActions}
      onSwipeRight={props.addDoctorVisit && RightActions}
      padding={{ padding: 25 }}
      style={[styles.card, !props.hasPhoneNumber && props.onPressAction === "sms" && styles.faded]}
    >
      {doctorAvailability === AvailabilityState.oncall &&
      props.availabilityRotas &&
      props.availabilityRotas.length > 0 ? (
        <>
          <CardLabel color={doctorAvailability} text={`On call`} style={styles.cardLabel} />
          {props.availabilityRotas.length > 1 && <Badge style={styles.badge}>{props.availabilityRotas.length}</Badge>}
        </>
      ) : (
        <CardLabel
          color={doctorAvailability}
          text={displayAvailabilityType(doctorAvailability)}
          style={styles.cardLabel}
        />
      )}
      <Text
        format={smallDevice ? TypographyType.RegularBold : TypographyType.HeadingSmall}
        style={smallDevice ? styles.nameSmall : styles.name}
      >
        {props.name}
      </Text>
      {doctorAvailability !== AvailabilityState.inAssessment && (
        <View style={styles.details}>
          <Icon
            size={18}
            name="access-time"
            color={doctorAvailability === AvailabilityState.noInformation ? palette.greyBlue : availabilityColor}
            style={styles.availabilityIcon}
          />
          {availabilityCopy(props.availability, props.booked, props.onHoliday, doctorAvailability, props.timeSpan)}
        </View>
      )}

      <View style={styles.locationInfo}>
        <Icon name={IconName.location_on} size={18} color={availabilityColor} style={styles.availabilityIcon} />
        <View style={styles.locationInfoText}>
          <Text format={TypographyType.TinyBold} color={palette.navy}>
            {`${props.distance < 0.01 ? "0" : props.distance.toFixed(1)} `}
          </Text>
          <Text format={TypographyType.Tiny} color={palette.navy}>
            miles away
          </Text>
          <Text format={TypographyType.Micro} color={palette.greyBlue}>
            {` (${formatSearchResulsPostcode(
              props.availability && props.availability.availability && props.availability.availability.locationName
                ? props.availability.availability.locationName.postcode
                : props.locationName
            )})`}
          </Text>
        </View>
        {Boolean(doctorMHTs.length) && (
          <>
            <View style={styles.locationInfoSeparator} />
            <Text format={TypographyType.TinyBold} color={palette.slate}>
              {doctorMHTs}
            </Text>
          </>
        )}
      </View>

      {/* List out the rotas */}
      {doctorAvailability === AvailabilityState.oncall &&
      props.availabilityRotas &&
      props.availabilityRotas.length > 0 ? (
        <View style={styles.locationInfo}>
          <Icon name={IconName.date_range} size={18} color={availabilityColor} style={styles.availabilityIcon} />
          <Text format={TypographyType.TinyBold} color={palette.slate}>
            Rota{`${props.availabilityRotas.length > 1 ? `s` : ``}`}:
          </Text>
          {props.availabilityRotas.map((a, index) => (
            <Text key={`${a.id}`} format={TypographyType.Tiny} color={palette.slate}>
              {`${index === 0 ? ` ` : `, `}${a.name}`}
            </Text>
          ))}
        </View>
      ) : (
        <></>
      )}
      {props.showNotes && Boolean(props.notes) && (
        <View style={styles.doctorProfileNotes}>
          <Text format={TypographyType.Tiny} color={palette.slate}>
            {/* both new and legacy systems in play here */
            props.availability?.availability.notes || props.nextAvailability?.availability?.notes || props.notes}
          </Text>
        </View>
      )}
      {props.onPressAction && props.onPressAction === "sms" && props.hasPhoneNumber && (
        <View style={[styles.iconBottomRight, props.isSelected && styles.selected]}>
          {props.isSelected && <Icon size={16} color={"white"} name="check" />}
        </View>
      )}
    </GenericCard>
  );
}

interface TimeSpan {
  start: Dayjs;
  end: Dayjs;
}

const doctorAvailabilityTypes = [
  AvailabilityState.unavailable,
  AvailabilityState.noInformation,
  AvailabilityState.away,
  AvailabilityState.booked,
];

function availabilityCopy(
  availability: { start: Dayjs; end: Dayjs; availability: Availability } | undefined,
  booked: { partiallyBooked: boolean } | undefined,
  onHoliday:
    | {
        availability?: Dayjs | null;
        isPartial: boolean;
        isAway: boolean;
      }
    | undefined,
  doctorAvailability: AvailabilityState,
  timeSpan: { start: Dayjs; end: Dayjs }
) {
  return (availability && booked && booked.partiallyBooked) || (availability && onHoliday && onHoliday.isPartial) ? (
    <Text format={TypographyType.Tiny}>Partially available; please check diary</Text>
  ) : !availability && booked && booked.partiallyBooked ? (
    <Text format={TypographyType.Tiny}>Partially booked; please check diary</Text>
  ) : onHoliday && onHoliday.isAway && onHoliday.availability ? (
    <View style={styles.availabilityCopy}>
      <Text format={TypographyType.Tiny}>Away until </Text>
      <Text format={TypographyType.TinyBold}>{formatDateTimeOnly(onHoliday.availability)}</Text>
      <Text format={TypographyType.Tiny}> on </Text>
      <Text format={TypographyType.TinyBold}>{formatShorterDate(onHoliday.availability)}</Text>
    </View>
  ) : availability ? (
    <View style={styles.availabilityCopy}>
      <Text format={TypographyType.Tiny}>Available </Text>
      <Text format={TypographyType.TinyBold}>
        {timeSpan.start.isSame(availability.start, "day") &&
        !(availability.availability.overnightElement && availability.availability.overnightElement === 2)
          ? ""
          : dayjs.isDayjs(availability.start)
          ? availability.start.format("DD/MM")
          : dayjs(availability.start).format("DD/MM")}
      </Text>
      <Text format={TypographyType.Tiny}> from </Text>
      <Text format={TypographyType.TinyBold}>{formatDateTimeOnly(availability.start)}</Text>
      <Text format={TypographyType.Tiny}> to </Text>
      <Text format={TypographyType.TinyBold}>
        {timeSpan.end.isAfter(availability.start, "day") ||
        (availability.availability.overnightElement && availability.availability.overnightElement === 1)
          ? dayjs.isDayjs(availability.end)
            ? availability.end.format("DD/MM HH:mm")
            : dayjs(availability.end).format("DD/MM HH:mm")
          : formatDateTimeOnly(availability.end)}
      </Text>
    </View>
  ) : onHoliday && onHoliday.availability ? (
    <View style={styles.availabilityCopy}>
      <Text format={TypographyType.Tiny}>Next available </Text>
      <Text format={TypographyType.TinyBold}>{formatShorterDate(onHoliday.availability)}</Text>
      <Text format={TypographyType.Tiny}> at </Text>
      <Text format={TypographyType.TinyBold}>{formatDateTimeOnly(onHoliday.availability)}</Text>
    </View>
  ) : doctorAvailability === AvailabilityState.noInformation ? (
    <Text format={TypographyType.Tiny}>No future availability</Text>
  ) : (
    <Text format={TypographyType.Tiny}>Please check diary</Text>
  );
}

const styles = StyleSheet.create({
  availabilityCopy: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },

  card: {
    marginBottom: spacing[10],
  },

  cardLabel: {
    position: "absolute",
    right: 0,
    top: 0,
  },

  badge: {
    backgroundColor: palette.red,
    position: "relative",
    bottom: 8,
    left: 8,
    zIndex: 1,
    color: palette.white,
    fontWeight: "bold",
  },

  details: {
    marginTop: spacing[10],
    flexDirection: "row",
    alignItems: "center",
  },

  locationInfo: {
    marginTop: spacing[5],
    flexDirection: "row",
    alignItems: "center",
  },
  locationInfoText: {
    flexDirection: "row",
    alignItems: "baseline",
  },
  locationInfoSeparator: {
    width: 3,
    height: 3,
    borderRadius: 3,
    marginHorizontal: spacing[10],
    backgroundColor: palette.greyBlue,
    transform: [{ translateY: 2 }], // line height is a bitch
  },

  availabilityIcon: {
    marginRight: spacing[5],
  },

  doctorProfileNotes: {
    marginTop: spacing[10],
  },

  name: {
    paddingRight: 120,
  },
  nameSmall: {
    paddingRight: 100,
  },

  selected: {
    backgroundColor: color.available,
    borderColor: color.availableBorder,
  },
  iconBottomRight: {
    position: "absolute",
    right: 10,
    bottom: 10,
    height: 30,
    width: 30,
    borderRadius: 19,
    justifyContent: "center",
    alignContent: "space-around",
    alignItems: "center",
    backgroundColor: color.background,
    borderColor: color.backgroundGrey,
    borderWidth: 4,
  },

  faded: {
    opacity: 0.4,
  },
});
