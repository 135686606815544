import React, { useState } from "react";
import { StyleSheet, View } from "react-native";

import { AssessmentItem } from "@/models/Assessment";
import { Button } from "../../Button";
import { palette, spacing } from "../../../theme";
import { useDialog } from "@/hooks";

interface AssessmentButtonProps {
  cancelOnPress: () => void;
  deleteOnPress: (state: any) => void;
  doctorVisits: AssessmentItem["doctorVisits"];
  duplicateOnPress: () => void;
  editMode: boolean;
  editOnPress: (state: any) => void;
  formInValid: boolean;
  saveOnPress: () => void;
  submitted: boolean;
  forms: AssessmentItem["forms"];
}

const AssessmentButtons: React.FC<AssessmentButtonProps> = ({
  cancelOnPress,
  deleteOnPress,
  doctorVisits,
  duplicateOnPress,
  editMode,
  editOnPress,
  formInValid,
  saveOnPress,
  submitted,
  forms,
}) => {
  const isFormsAttached = forms && forms.items && forms.items.length > 0;
  const isClaimsCreated =
    doctorVisits && doctorVisits.items && doctorVisits.items.filter(item => item.claim).length > 0;
  const disableDeleteButton = isFormsAttached || isClaimsCreated;
  const [shouldDisableDeleteButton, setShouldDisableDeleteButton] = useState(false);
  const { openDialog, closeDialog } = useDialog();
  return (
    <View style={styles.buttonWrapperWeb}>
      {!editMode ? (
        <>
          <Button
            mode="outlined"
            style={styles.buttonWeb}
            disabled={submitted || editMode}
            webHeaderButton={true}
            onPress={duplicateOnPress}
          >
            Duplicate
          </Button>
          <Button
            color={palette.red}
            style={styles.buttonWeb}
            disabled={submitted || editMode || shouldDisableDeleteButton}
            webHeaderButton={true}
            onPress={() => {
              if (disableDeleteButton) {
                setShouldDisableDeleteButton(true);
                openDialog({
                  heading: "Delete Disabled",
                  message: "Assessment can’t be deleted as a claim or statutory form exists.",
                  dismissable: false,
                  confirmButton: {
                    label: "OK",
                    onPress: () => {
                      closeDialog();
                      setShouldDisableDeleteButton(false);
                    },
                  },
                });
              } else {
                deleteOnPress({});
              }
            }}
          >
            Delete
          </Button>
          <Button style={styles.buttonWeb} disabled={submitted} webHeaderButton={true} onPress={editOnPress}>
            Edit
          </Button>
        </>
      ) : (
        <>
          <Button
            mode="outlined"
            style={styles.buttonWeb}
            disabled={submitted}
            webHeaderButton={true}
            onPress={cancelOnPress}
          >
            Cancel
          </Button>
          <Button style={styles.buttonWeb} disabled={formInValid} webHeaderButton={true} onPress={saveOnPress}>
            Save Changes
          </Button>
        </>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  buttonWeb: {
    marginLeft: spacing[5],
    marginBottom: 0,
  },

  buttonWrapperWeb: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
});

export default AssessmentButtons;
