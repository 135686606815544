import { FormCategory, FormType } from "libs/types/mhaForms";
import { isAmhpForm } from "libs/mhaForms/helpers/isAmhpForm";
import { isJointForm } from "libs/mhaForms/helpers/isJointForm";
import { isConveyanceForm } from "./isAmhpForm";

export function getFormCategory(formType: FormType, isUnattachedForm: boolean): FormCategory {
  if (isAmhpForm(formType) || isConveyanceForm(formType)) {
    return "AMHPApplication";
  } else {
    if (isJointForm(formType)) {
      return "JointMedRec";
    } else {
      if (isUnattachedForm) {
        return "UnattachedSingleMedRec";
      } else {
        return "SingleMedRec";
      }
    }
  }
}
