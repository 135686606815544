import React, { useMemo } from "react";
import { View } from "react-native";
import { useFormikContext } from "formik";

import { Button } from "@/components/Button";
import { TextInput } from "@/components/TextInput/TextInput";
import { useFocusInput, useOnMount } from "@/hooks";

import { ManualAddressFormValues } from "./ManualAddressForm";

interface PropTypes {
  autofocusFirst?: boolean;
  disableStrictPostcodeValidation?: boolean;
}

type FieldName = keyof ManualAddressFormValues;

const ManualAddressFormFields = (props: PropTypes) => {
  const { autofocusFirst, disableStrictPostcodeValidation } = props;
  const {
    errors,
    handleBlur,
    handleChange,
    initialValues,
    isSubmitting,
    isValid,
    submitForm,
    touched,
    validateForm,
    values,
  } = useFormikContext<ManualAddressFormValues>();

  const { setRef, focusInput } = useFocusInput();

  /**
   * Detect whether any of the `values` in the form have changed from the
   * `initialValues`.
   *
   * This can be used to disable the submit button if the user has not changed
   * the form in any way. Useful to prevent a fully valid initialised form from
   * hitting the API.
   */
  const hasChanged = useMemo(() => {
    return Object.keys(values).some((key: FieldName) => {
      const current = values[key];
      const initial = initialValues[key];

      return current !== initial;
    });
  }, [values]);

  const canSubmit = isValid && hasChanged && !isSubmitting;

  const getError = (fieldName: FieldName): string => {
    return errors[fieldName] || "";
  };

  const hasError = (fieldName: FieldName): boolean => {
    return Boolean(errors[fieldName] && touched[fieldName]);
  };

  useOnMount(() => {
    autofocusFirst && focusInput("address");
    validateForm();
  });

  return (
    <View>
      <TextInput
        autoCorrect={false}
        error={hasError("address")}
        errorText={getError("address")}
        label="Address Line 1"
        disabled={!autofocusFirst}
        onBlur={handleBlur("address")}
        onChangeText={handleChange("address")}
        onSubmitEditing={() => focusInput("city")}
        ref={element => setRef("address", element)}
        returnKeyType="next"
        testID="manual-address-address-input"
        value={values.address}
      />

      <TextInput
        autoCorrect={false}
        error={hasError("city")}
        errorText={getError("city")}
        label="Address Line 2"
        disabled={!autofocusFirst}
        onBlur={handleBlur("city")}
        onChangeText={handleChange("city")}
        onSubmitEditing={() => focusInput("postcode")}
        ref={element => setRef("city", element)}
        returnKeyType="next"
        testID="manual-address-city-input"
        value={values.city}
      />

      <TextInput
        autoCorrect={false}
        error={hasError("postcode")}
        errorText={getError("postcode")}
        label="Postcode"
        disabled={!autofocusFirst}
        supplementaryInfo={disableStrictPostcodeValidation ? 'Enter the postcode if known, otherwise enter "NFA"' : ""}
        onBlur={handleBlur("postcode")}
        onChangeText={handleChange("postcode")}
        onSubmitEditing={() => canSubmit && submitForm}
        ref={element => setRef("postcode", element)}
        returnKeyType="done"
        testID="manual-address-postcode-input"
        value={values.postcode}
      />

      <Button disabled={!canSubmit} onPress={submitForm}>
        Save Address
      </Button>
    </View>
  );
};

export default ManualAddressFormFields;
