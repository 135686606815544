import { useMutation } from "@apollo/react-hooks";
import { formatLongDate } from "libs/dates";
import { formatAddress } from "libs/formatters/address";
import { UpdateAssessmentInput } from "libs/types/API";
import { FormType } from "libs/types/mhaForms";
import { amhpShouldProvideNhsNumber, amhpTeamMHAExternalDoctors, amhpTeamMHAForms } from "libs/utils/featureFlags";
import dayjs, { Dayjs } from "dayjs";
import React, { SyntheticEvent, useCallback, useEffect, useState } from "react";
import { Animated, Easing, Platform, StyleSheet, View } from "react-native";
import openMap, { createMapLink } from "react-native-open-maps";
import { useSetRecoilState } from "recoil";

import {
  ContactedDoctorResponse,
  CREATE_CONTACTED_DOCTORS,
  DELETE_ASSESSMENT,
  formDataToModel,
  GET_ASSESSMENT,
  getAmhpAssessmentsQuery,
  GetAssessmentResponse,
  MutateAssessmentResponse,
  UPDATE_ASSESSMENT,
} from "@/models/Assessment";
import { buildErr, deleteAssessmentError, updateAssessmentError } from "@/models/Error";
import { TypographyType } from "@/models/Typography";
import { RouteKeys } from "@/navigationv2";
import { allPropsSameButNavigation } from "../../screens/utils";
import { palette, spacing } from "../../theme";
import { checkMutationInput, formatNhsNumber, mqWeb, useDeviceHeight } from "../../utils/helpers";
import { duplicateAssessment, lastException, snackbarMessage } from "../../utils/recoil";
import { Checkbox } from "../Checkbox/Checkbox";
import { ContentWrap } from "../ContentWrap";
import { DeleteInput, UpdateInput } from "../CreateAssessment/CreateAssessment.props";
import DualDatePicker from "../DatePicker/DualDatePicker";
import { Description } from "../Description/Description";
import { useDialog } from "@/hooks";
import { useCreateFormsBottomSheet } from "../Forms/MHACreateFormItem";
import MHALocation from "../Forms/MHALocation";
import { IconName } from "../Icon";
import { InfoItemIcon, InfoItemIconList, InfoItemLabel, InfoItemLabelList } from "../InfoItem";
import { Location } from "../Location";
import { CreateContactedDoctorsInput, CreateContactedDoctorsReturn } from "../SearchResults/SearchResults.props";
import Select from "../Select";
import { SplitSection } from "../SplitSection";
import { Tabs } from "../Tabs";
import Text, { TextWithIcon } from "../Text";
import { TextInput } from "../TextInput/TextInput";
import { AssessmentButtons } from "./AssessmentButtons";
import { ContactedDoctor } from "./AssessmentDetails.props";
import { AssessmentDoctorForms } from "./AssessmentDoctorForms";
import { AssessmentDoctors } from "./AssessmentDoctors";
import { AssessmentFAB } from "./AssessmentFAB";
import { AssessmentMyForms } from "./AssessmentMyForms";
import FormsUpsell from "./FormsUpsell";
import { color } from "@/theme";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { AllAMHPRoutes } from "@/navigationv2/types";
import { DELETE_DOCTOR_VISIT } from "../../models/DoctorProfile";
import { DELETE_CONTACTED_DOCTORS, DoctorVisitsResponse } from "../../models/Assessment";
import { DeleteDoctorVisitInput, DeleteContactedDoctorInput } from "../../../../common/types/API";

interface ContactedDoctors {
  items?: ContactedDoctorResponse[] | null | undefined;
}
const mapAndSortContactedDoctors = (contactedDoctors: ContactedDoctors) => {
  if (contactedDoctors && contactedDoctors.items && contactedDoctors.items.length) {
    const doctorsMod = contactedDoctors.items.map((i: ContactedDoctorResponse) => ({
      timeStamp: [i.createdAt],
      ...i.s12Doctor,
    }));

    const result: ContactedDoctor[] = [];
    doctorsMod.forEach(item => {
      const existing = result.filter(resultItem => resultItem.id === item.id);

      if (existing.length) {
        const existingIndex = result.indexOf(existing[0]);
        const { timeStamp } = result[existingIndex];
        timeStamp.push(...item.timeStamp);
        timeStamp.sort(
          (a, b) =>
            dayjs(b)
              .toDate()
              .getTime() -
            dayjs(a)
              .toDate()
              .getTime()
        );
      } else {
        result.push(item);
      }
    });
    return result;
  } else {
    return [];
  }
};

export const AssessmentDetails = React.memo((props: GetAssessmentResponse & { refetch: () => void }) => {
  const { assessment, refetch } = props;
  const navigation = useNavigation<StackNavigationProp<AllAMHPRoutes>>();

  const {
    location,
    locationName,
    assessmentDate,
    amhp,
    amhpTeam,
    patientName,
    patientNhsNumber,
    ccgId,
    doctorVisits,
    contactedDoctors,
    patientAddress,
  } = assessment;

  const [contacted, setContacted] = useState<ContactedDoctor[]>([]);
  const [submitted, setSubmitted] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [saveEditOnLocationUpdate, setSaveEditOnLocationUpdate] = useState(false);
  const [confirmDeletion, setConfirmDeletion] = useState(false);
  const [fabGroupVisible, setFabGroupVisible] = useState(true);
  const [tabContent, setTabContent] = useState(0);

  const initialData = {
    amhp,
    location,
    ccgId,
    locationName,
    locationNotes: locationName.addressNotes,
    assessmentDate,
    patientName,
    patientNhsNumber,
    patientAddress,
  };

  // edited data
  const [editedData, setEditedData] = useState({
    amhp: initialData.amhp,
    location: initialData.location,
    ccgId: initialData.ccgId,
    locationName: initialData.locationName,
    patientAddress: initialData.patientAddress,
  });

  const [assessmentAsPatientLocation, setAssessmentAsPatientLocation] = useState(
    JSON.stringify(initialData.patientAddress) === JSON.stringify(initialData.locationName)
  );
  const [editedPatientName, setEditedPatientName] = useState<string>(initialData.patientName);
  const [editedNhsNumber, setEditedNhsNumber] = useState<string>(initialData.patientNhsNumber || "");
  const [editedAssessmentDate, setEditedAssessmentDate] = useState<Dayjs>(dayjs(initialData.assessmentDate));
  const [editedLocationNotes, setEditedLocationNotes] = useState<string>(initialData.locationNotes || "");
  const [renderEditModeWebOverlay, setRenderEditModeWebOverlay] = useState<boolean>(false);
  const [editModeWebOverlayOpacity] = useState(new Animated.Value(0));

  const setDuplicateAssessment = useSetRecoilState(duplicateAssessment);
  const setMessage = useSetRecoilState(snackbarMessage);
  const setLastException = useSetRecoilState(lastException);

  // mutations
  const [deleteAssessmentMtn] = useMutation<MutateAssessmentResponse, DeleteInput>(DELETE_ASSESSMENT);
  const [updateAssessmentMtn] = useMutation<MutateAssessmentResponse, UpdateInput>(UPDATE_ASSESSMENT);
  const [addContactedDoctor] = useMutation<CreateContactedDoctorsReturn, CreateContactedDoctorsInput>(
    CREATE_CONTACTED_DOCTORS
  );
  const [deleteDoctorVisit] = useMutation<DoctorVisitsResponse, DeleteDoctorVisitInput>(DELETE_DOCTOR_VISIT);
  const [deleteContactedDoctor] = useMutation<ContactedDoctorResponse, DeleteContactedDoctorInput>(
    DELETE_CONTACTED_DOCTORS
  );

  useEffect(() => {
    setContacted(mapAndSortContactedDoctors(contactedDoctors));
  }, [contactedDoctors]);

  useEffect(() => {
    setEditedPatientName(patientName);
  }, [patientName]);

  const findDoctors = useCallback(async () => {
    navigation.navigate(RouteKeys.CreateAssessmentScreen, {
      assessmentId: assessment.id,
      editDoctor: true,
    });
  }, [assessment.id]);

  const formTypes = [FormType.A2, FormType.A6, FormType.A10];
  const navigationParams = { assessmentId: assessment.id };
  const analyticsParams = {
    ahmpTeamId: assessment.amhpTeamId,
    assessmentId: assessment.id,
  };

  const openCreateFormBottomSheet = useCreateFormsBottomSheet(formTypes, navigationParams, analyticsParams);

  const isWebView = mqWeb();
  const deviceHeight = useDeviceHeight();

  const editModeWebOverlayStyles = React.useMemo(
    () => [
      styles.editModeWebOverlay,
      {
        height: deviceHeight,
        opacity: editModeWebOverlayOpacity,
      },
    ],
    [deviceHeight]
  );

  useEffect(() => {
    setEditedData({
      ...editedData,
      patientAddress: assessmentAsPatientLocation ? editedData.locationName : initialData.patientAddress,
    });
  }, [assessmentAsPatientLocation]);

  useEffect(() => {
    if (!isWebView) {
      return;
    }

    if (isWebView && editMode) {
      setRenderEditModeWebOverlay(true);
      Animated.timing(editModeWebOverlayOpacity, {
        toValue: 0.85,
        easing: Easing.out(Easing.linear),
        duration: 150,
        delay: 0,
        useNativeDriver: true,
      }).start();
    }

    if (isWebView && !editMode) {
      Animated.timing(editModeWebOverlayOpacity, {
        toValue: 0,
        easing: Easing.out(Easing.linear),
        duration: 150,
        delay: 0,
        useNativeDriver: true,
      }).start(() => setRenderEditModeWebOverlay(false));
    }
  }, [isWebView, editMode]);

  const renderDoctors = () => (
    <AssessmentDoctors
      addContactedDoctor={addContactedDoctor}
      assessment={assessment}
      contacted={contacted}
      editMode={editMode}
      findDoctors={findDoctors}
      submitted={submitted}
    />
  );

  const renderMyForms = () => (
    <AssessmentMyForms
      assessmentId={assessment.id}
      formRecords={formDataToModel(assessment.forms)}
      hasPatientAddress={!!patientAddress?.postcode}
      createMHAForm={openCreateFormBottomSheet}
      refetch={refetch}
      amhpTeamFeatures={assessment.amhpTeam.featureFlags}
      amhpTeamId={assessment.amhpTeamId}
      assessmentAmhpId={assessment.amhp.id}
      patientName={patientName}
    />
  );

  const amhpTeamAllowsExternalDoctors = amhpTeam && amhpTeamMHAExternalDoctors(assessment.amhpTeam.featureFlags);

  const renderDoctorForms = () => (
    <AssessmentDoctorForms
      assessmentId={assessment.id}
      formRecords={formDataToModel(assessment.forms)}
      refetch={refetch}
      amhpTeamFeatures={assessment.amhpTeam.featureFlags}
      amhpTeamId={assessment.amhpTeamId}
      patientName={patientName}
      amhpTeamAllowsExternalDoctors={amhpTeamAllowsExternalDoctors}
    />
  );

  const AMHP_TEAM_HAS_ENABLED_FORMS = amhpTeamMHAForms(assessment.amhpTeam.featureFlags);

  const renderTabContent = AMHP_TEAM_HAS_ENABLED_FORMS
    ? [renderDoctors, renderMyForms, renderDoctorForms]
    : [renderDoctors, () => <FormsUpsell />];

  const duplicate = () => {
    setDuplicateAssessment({
      id: "-",
      doctorVisits: { items: [] },
      amhp,
      amhpTeam: {
        id: amhpTeam.id,
        name: amhpTeam.name,
      },
      assessmentDate: dayjs().format(),
      ccgId,
      location,
      locationName,
      patientName,
    });

    navigation.navigate(RouteKeys.CreateAssessmentScreen);
  };

  const deleteAssessment = async () => {
    setSubmitted(true);
    const deletePromises: Promise<any>[] = [];

    // eslint-disable-next-line no-unused-expressions
    assessment.contactedDoctors?.items?.map(contDoctor =>
      deletePromises.push(
        deleteContactedDoctor({
          variables: {
            id: contDoctor.id,
          },
        })
      )
    );
    // eslint-disable-next-line no-unused-expressions
    assessment.doctorVisits?.items?.map(doctorVisit =>
      deletePromises.push(
        deleteDoctorVisit({
          variables: {
            id: doctorVisit.id,
          },
        })
      )
    );
    deletePromises.push(
      deleteAssessmentMtn({
        variables: {
          input: {
            id: assessment.id,
          },
        },
        refetchQueries: [
          {
            query: getAmhpAssessmentsQuery,
            variables: {
              today: dayjs().format("YYYY-MM-DD"),
              amhpId: amhp.id,
              amhpTeamId: amhpTeam.id,
              limit: 10,
            },
          },
        ],
      })
    );
    await Promise.all(deletePromises)
      .then(() => {
        setMessage("Assessment successfully deleted");
        navigation.goBack();
      })
      .catch(e => {
        setSubmitted(false);
        setConfirmDeletion(false);
        buildErr(deleteAssessmentError, setLastException)(e);
      });
  };

  const { openDialog, closeDialog } = useDialog();

  useEffect(() => {
    if (confirmDeletion) {
      openDialog({
        heading: "Confirm Deletion",
        message: [
          "Are you sure you want to delete this assessment?",
          "If you have already booked the doctor(s), they will be removed from this assessment. Please contact the doctor(s) to let them know they are no longer required.",
          "This action cannot be undone.",
        ],
        confirmButton: {
          label: "Delete",
          isDanger: true,
          onPress: () => {
            deleteAssessment();
            closeDialog();
          },
        },
        dismissable: true,
        onDismiss: () => setConfirmDeletion(false),
      });
    } else {
      closeDialog();
    }
  }, [confirmDeletion]);

  const saveChanges = (data?: typeof editedData | SyntheticEvent) => {
    const updateData = data && "amhp" in data ? data : editedData;
    setSubmitted(true);
    const addressNotes = editedLocationNotes && editedLocationNotes.trim() !== "" ? editedLocationNotes : null;
    const locationName = {
      ...updateData.locationName,
      addressNotes,
    };

    updateAssessmentMtn({
      variables: {
        input: checkMutationInput<UpdateAssessmentInput>({
          id: assessment.id,
          amhpId: updateData.amhp.id,
          location: updateData.location,
          locationName,
          patientName: editedPatientName,
          patientNhsNumber: editedNhsNumber.replace(/\s/g, ""),
          assessmentDate: dayjs(editedAssessmentDate).format("YYYY-MM-DD"),
          ccgId: editedData.ccgId,
          ...(updateData.patientAddress && { patientAddress: updateData.patientAddress }),
        }),
      },
      refetchQueries: [
        {
          query: GET_ASSESSMENT,
          variables: { id: assessment.id },
        },
      ],
    })
      .then(() => {
        setSubmitted(false);
        setFabGroupVisible(true);
        setEditMode(false);
        setAssessmentAsPatientLocation(
          JSON.stringify(initialData.patientAddress) === JSON.stringify(initialData.locationName)
        );
        setMessage("Assessment successfully updated");
      })
      .catch(e => {
        setSubmitted(false);
        setFabGroupVisible(true);
        setEditMode(false);
        buildErr(
          {
            ...updateAssessmentError,
            additional: assessment.id,
          },
          setLastException
        )(e);
      });
  };

  const formInValid = submitted || !editedPatientName || (!!editedPatientName && editedPatientName.trim() === "");
  const onMapLinkPress = () => {
    const params = {
      travelType: "drive",
      query: [locationName.address, locationName.city, locationName.postcode].filter(x => x).join(", "),
    };

    Platform.OS === "web" ? window.open(createMapLink(params), "_blank") : openMap(params);
  };

  const nhsNumberRequired = amhpTeam && amhpShouldProvideNhsNumber(amhpTeam.featureFlags);

  const editPress = () => {
    setAssessmentAsPatientLocation(
      JSON.stringify(initialData.patientAddress) === JSON.stringify(initialData.locationName)
    );
  };

  const AsideContent = (
    <>
      {!editMode ? (
        <>
          <View style={{ marginBottom: spacing[20] }}>
            <InfoItemLabelList>
              <InfoItemLabel label="AMHP" format={TypographyType.Regular}>
                {amhp.name}
              </InfoItemLabel>
              <InfoItemLabel label="AMHP Team" format={TypographyType.Regular}>
                {amhpTeam.name}
              </InfoItemLabel>
            </InfoItemLabelList>
          </View>

          <InfoItemIconList>
            <InfoItemIcon iconName="today" format={TypographyType.Small}>
              {formatLongDate(assessmentDate)}
            </InfoItemIcon>
            <InfoItemIcon
              iconName={IconName.location_on}
              iconPosition="flex-start"
              format={TypographyType.Small}
              linkLabel="View on Map"
              linkOnPress={onMapLinkPress}
            >
              {formatAddress(locationName)}
            </InfoItemIcon>
            {!!locationName.addressNotes && (
              <InfoItemLabel label="Location Notes" format={TypographyType.Regular}>
                {locationName.addressNotes || "-"}
              </InfoItemLabel>
            )}
          </InfoItemIconList>
        </>
      ) : (
        <>
          <Select
            label="Assigned AMHP"
            icon="person-outline"
            value={
              assessment.amhpTeam.users && assessment.amhpTeam.users.items && assessment.amhpTeam.users.items.length
                ? editedData.amhp.id
                : ""
            }
            onValueChange={(_v, i, d: [{ value: string; label: string }]) => {
              setEditedData({
                ...editedData,
                amhp: {
                  id: d[i].value,
                  name: d[i].label,
                },
              });
            }}
            options={
              assessment.amhpTeam.users.items
                ? assessment.amhpTeam.users.items
                    .map(i => i.amhp ?? [])
                    .filter(a => !a?.deleted ?? false)
                    .sort((a, b) => {
                      return (a.name > b.name && 1) || -1;
                    })
                : []
            }
          />
          <Description label="AMHP Team" style={styles.amhpTeamDescription}>
            {amhpTeam.name}
          </Description>
          <Text format={TypographyType.Small} color={palette.greyBlue} style={styles.dateLabel}>
            Assessment Date
          </Text>
          <DualDatePicker
            max={dayjs()
              .add(28, "day")
              .toDate()}
            min={dayjs(assessmentDate)
              .subtract(30, "day")
              .toDate()}
            value={editedAssessmentDate}
            onValueChange={setEditedAssessmentDate}
          />
          <Location
            label="Assessment Address"
            locationValue={
              editedData.locationName.address
                ? editedData.locationName.address +
                  (editedData.locationName.city ? ", " + editedData.locationName.city : "")
                : ""
            }
            setLocationValue={val => {
              setEditedData({
                ...editedData,
                ...val,
                patientAddress: assessmentAsPatientLocation ? val.locationName : editedData.patientAddress,
                ccgId: val && val.ccg ? val.ccg.id : editedData.ccgId,
              });
            }}
          />
          <TextInput
            icon="note"
            label="Optional Location Notes"
            autoCorrect={true}
            spellCheck={true}
            value={editedLocationNotes}
            multiline={true}
            selectTextOnFocus={true}
            numberOfLines={3}
            supplementaryInfo="Add more information if required, e.g. Ward Name"
            onChangeText={setEditedLocationNotes}
          />
        </>
      )}
      <Text format={TypographyType.HeadingSmall} style={styles.headingSmall}>
        Patient
      </Text>
      {!editMode ? (
        <InfoItemLabelList>
          {nhsNumberRequired && (
            <InfoItemLabel label="NHS Number" format={TypographyType.Regular}>
              {patientNhsNumber || "-"}
            </InfoItemLabel>
          )}
          <InfoItemLabel label="Patient Name" format={TypographyType.Regular}>
            {patientName}
          </InfoItemLabel>
          {AMHP_TEAM_HAS_ENABLED_FORMS && (
            <InfoItemLabel label="Patient Address" format={TypographyType.Regular}>
              {patientAddress && patientAddress.postcode !== "" ? formatAddress(patientAddress) : "-"}
            </InfoItemLabel>
          )}
        </InfoItemLabelList>
      ) : (
        <>
          {!!nhsNumberRequired && (
            <TextInput
              icon="local-hospital"
              label="Patient's NHS number"
              value={formatNhsNumber(editedNhsNumber)}
              maxLength={12}
              onChangeText={value => {
                setEditedNhsNumber(formatNhsNumber(value));
              }}
            />
          )}
          <TextInput
            icon="contacts"
            label="Patient Name"
            autoCorrect={false}
            returnKeyType="next"
            value={editedPatientName}
            onChangeText={setEditedPatientName}
          />
          {AMHP_TEAM_HAS_ENABLED_FORMS ? (
            <>
              <MHALocation
                label="Patient Address"
                disableStrictPostcodeValidation
                locationValue={formatAddress(
                  editedData.patientAddress || {
                    address: "",
                    postcode: "",
                    city: "",
                  }
                )}
                setLocationValue={({ locationName }) => {
                  const data = { ...editedData, patientAddress: locationName };
                  setEditedData(data);

                  if (saveEditOnLocationUpdate) {
                    saveChanges(data);
                    setSaveEditOnLocationUpdate(false);
                  }
                }}
                disabled={assessmentAsPatientLocation}
              />
              <Checkbox
                status={assessmentAsPatientLocation}
                label="Use same address as Assessment Location"
                onPress={() => setAssessmentAsPatientLocation(!assessmentAsPatientLocation)}
              />

              <View style={styles.disclaimerText}>
                <TextWithIcon
                  iconName="info-outline"
                  iconColor={palette.greyBlue}
                  iconPosition="flex-start"
                  format={TypographyType.Small}
                  style={{
                    color: palette.slate,
                    marginLeft: spacing[15],
                  }}
                >
                  {`Patient details are used for all MHA forms for this assessment.\n\nChanges to patient details will automatically be applied to all MHA forms for this assessment, which are yet to be signed.`}
                </TextWithIcon>
              </View>
            </>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );

  const MainContent = (
    <>
      {renderEditModeWebOverlay && <Animated.View style={editModeWebOverlayStyles} />}
      {AMHP_TEAM_HAS_ENABLED_FORMS ? (
        <View>
          <Tabs
            tabs={[
              {
                label: "doctors",
                active: tabContent === 0,
                badgeCount: 0,
                onPress: () => setTabContent(0),
              },
              {
                label: "amhp forms",
                active: tabContent === 1,
                badgeCount: 0,
                onPress: () => setTabContent(1),
              },
              {
                label: "doctor forms",
                active: tabContent === 2,
                badgeCount: 0,
                onPress: () => setTabContent(2),
              },
            ]}
          />
        </View>
      ) : (
        <View>
          <Tabs
            tabs={[
              {
                label: "doctors",
                active: tabContent === 0,
                badgeCount: 0,
                onPress: () => setTabContent(0),
              },
              {
                label: "Statutory MHA Forms",
                active: tabContent === 1,
                badgeCount: 0,
                onPress: () => setTabContent(1),
              },
            ]}
          />
        </View>
      )}
      <View>{renderTabContent[tabContent]()}</View>
    </>
  );

  return (
    <View style={!isWebView && styles.mobileScreen}>
      {!isWebView && (
        <AssessmentFAB
          doctorVisits={assessment.doctorVisits}
          duplicate={duplicate}
          editMode={editMode}
          fabGroupVisible={fabGroupVisible}
          formInValid={formInValid}
          saveOnPress={saveChanges}
          setEditMode={setEditMode}
          editPress={() => {
            editPress();
          }}
          setFabGroupVisible={setFabGroupVisible}
          setConfirmDeletion={setConfirmDeletion}
          forms={assessment.forms}
          cancelOnPress={() => {
            setEditedData(initialData);
            setEditedLocationNotes(initialData.locationName.addressNotes || "");
            setEditedNhsNumber(initialData.patientNhsNumber || "");
            setEditedPatientName(initialData.patientName || "");
            setAssessmentAsPatientLocation(
              JSON.stringify(initialData.patientAddress) === JSON.stringify(initialData.locationName)
            );
            setEditedAssessmentDate(dayjs(initialData.assessmentDate));
          }}
        />
      )}
      <ContentWrap style={[styles.claimHeader, isWebView ? styles.claimHeaderWeb : {}]}>
        <Text format={TypographyType.HeadingMedium} style={isWebView && styles.claimHeaderTextWeb}>
          Assessment Details
        </Text>
        {isWebView && (
          <AssessmentButtons
            cancelOnPress={() => {
              setEditMode(false);
              setEditedData(initialData);
              setEditedLocationNotes(initialData.locationName.addressNotes || "");
              setEditedNhsNumber(initialData.patientNhsNumber || "");
              setEditedPatientName(initialData.patientName || "");
              setAssessmentAsPatientLocation(
                JSON.stringify(initialData.patientAddress) === JSON.stringify(initialData.locationName)
              );
              setEditedAssessmentDate(dayjs(initialData.assessmentDate));
            }}
            deleteOnPress={() => setConfirmDeletion(true)}
            doctorVisits={doctorVisits}
            duplicateOnPress={duplicate}
            editMode={editMode}
            editOnPress={() => {
              editPress();
              setEditMode(true);
            }}
            formInValid={formInValid}
            saveOnPress={saveChanges}
            submitted={submitted}
            forms={assessment.forms}
          />
        )}
      </ContentWrap>
      <SplitSection aside={AsideContent} main={MainContent} />
      {!isWebView && <View style={styles.fabSpacer} />}
    </View>
  );
}, allPropsSameButNavigation);

const styles = StyleSheet.create({
  amhpTeamDescription: {
    marginBottom: 0,
    flexBasis: "auto",
  },

  claimHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  claimHeaderWeb: {
    marginBottom: spacing[40],
    alignItems: "flex-start",
  },
  claimHeaderTextWeb: {
    marginBottom: 0,
  },

  editModeWebOverlay: {
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    backgroundColor: palette.white,
    zIndex: 1,
  },

  fabSpacer: {
    height: 100,
    backgroundColor: color.background,
  },

  headingSmall: {
    marginTop: spacing[30],
    marginBottom: spacing[15],
  },

  mobileScreen: {
    marginBottom: spacing[50] * -1,
  },

  dateLabel: { marginTop: 10 },

  disclaimerText: {
    marginTop: spacing[30],
    paddingRight: Platform.OS === "web" ? 0 : spacing[30],
  },
});
