import * as Yup from "yup";
import { ComponentElement, FormFieldsDoctor, Itemisation } from "libs/types/mhaForms";

const componentElements: ComponentElement[] = [
  {
    component: "Text",
    componentProps: {
      instruction: "I am of the opinion,",
    },
    fullWidth: true,
  },
  {
    component: "Text",
    componentProps: {
      instruction:
        "(a) this patient is suffering from mental disorder of a nature or degree which warrants the detention of the patient in hospital for assessment (or for assessment followed by medical treatment) for at least a limited period,",
    },
    fullWidth: true,
    genericProps: {
      indentation: 1,
    },
  },
  {
    component: "Text",
    componentProps: {
      instruction: "AND",
    },
    fullWidth: true,
  },
  {
    component: "Text",
    componentProps: {
      instruction: "(b) this patient ought to be so detained",
    },
    fullWidth: true,
    genericProps: {
      indentation: 1,
    },
  },

  {
    component: "Checkbox",
    fieldName: FormFieldsDoctor.ReasonsForDetention,
    componentProps: {
      itemisation: Itemisation.ROMAN,
      options: [
        {
          status: "patientHealth",
          label: "in the interests of the patient’s own health",
        },
        {
          status: "patientSafety",
          label: "in the interests of the patient’s own safety",
        },
        {
          status: "generalSafety",
          label: "with a view to the protection of other persons,",
        },
      ],
    },
    genericProps: {
      indentation: 2,
    },
  },
  {
    component: "Text",
    componentProps: {
      guidance: "(Check all that apply)",
      pdfOnlyGuidance: "(Delete the indents not applicable)",
    },
    fullWidth: true,
    genericProps: {
      indentation: 2,
    },
  },
  {
    component: "Text",
    componentProps: {
      instruction: "AND",
    },
    fullWidth: true,
  },
  {
    component: "Text",
    componentProps: {
      instruction:
        "(c) it is of urgent necessity for the patient to be admitted and detained under section 2 of the Act.",
    },
    genericProps: {
      indentation: 1,
    },
    fullWidth: true,
  },
];

export default {
  title: "Opinion",

  state: {
    reasonsForDetention: [],
  },

  validation: Yup.object().shape({
    reasonsForDetention: Yup.array().min(1, "Please select at least one of the options").required(),
  }),

  componentElements,
};
