import React from "react";
import { StyleSheet, View } from "react-native";

import { EventItem } from "libs/types/holiday";
import { TypographyType } from "@/models/Typography";
import { mqWeb } from "../../../utils/helpers";
import { palette, spacing } from "../../../theme";

import { Card, CardContainer, CardTimes } from "../../Cardd";
import { ContentWrap } from "../../ContentWrap";
import Text from "../../Text";

interface HolidayListProps {
  heading: string;
  description: string;
  defaultCopy: string;
  state: EventItem[];
  color: string;
  editHoliday: (holiday: EventItem) => void;
  deleteHoliday: (holiday: EventItem) => void;
}

const HolidayList: React.FC<HolidayListProps> = props => {
  const isWebView = mqWeb();

  const MainWrapper = isWebView ? ContentWrap : View;
  const TextWrapper = isWebView ? View : ContentWrap;

  const Content =
    props.state.length > 0 ? (
      props.state.map((holiday: EventItem) => {
        const expandableNotes = (
          <>
            <Text format={TypographyType.Micro} color={palette.greyBlue} marginBottom={spacing[5]}>
              Notes
            </Text>
            <Text format={TypographyType.Small} color={holiday.notes ? palette.navy : palette.greyBlue}>
              {holiday.notes ? holiday.notes : "No notes added for this availability"}
            </Text>
          </>
        );
        return (
          <Card
            key={holiday.id}
            statusBarColor={props.color}
            buttons={[
              {
                label: "Delete",
                onPress: () => props.deleteHoliday(holiday),
                icon: "delete",
              },
              {
                label: "Edit",
                onPress: () => props.editHoliday(holiday),
                icon: "edit",
              },
            ]}
            expandableContent={expandableNotes}
          >
            <CardTimes from={holiday.start} to={holiday.end} color={props.color} showDates={true} reverseIcons={true} />
          </Card>
        );
      })
    ) : (
      <TextWrapper>
        <Text
          format={TypographyType.Small}
          marginTop={isWebView ? 0 : spacing[20]}
          marginBottom={isWebView ? spacing[10] : spacing[30]}
          color={palette.greyBlue}
        >
          {props.defaultCopy}
        </Text>
      </TextWrapper>
    );

  return (
    <MainWrapper style={styles.wrapper}>
      <TextWrapper>
        <Text format={TypographyType.HeadingSmall} marginBottom={spacing[15]}>
          {props.heading}
        </Text>
        <Text format={TypographyType.Small} marginBottom={spacing[30]} color={palette.slate}>
          {props.description}
        </Text>
      </TextWrapper>
      <CardContainer>{Content}</CardContainer>
    </MainWrapper>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    width: "100%",
  },
});

export default HolidayList;
