import React, { useState, useCallback } from "react";
import { RefreshControl } from "react-native";
import { useRecoilValue } from "recoil";

import DoctorSetup from "@/components/DoctorSetup";
import { useOnMount, useOnScreenFocus } from "@/hooks";
import { AppScreen } from "@/layouts/AppScreen/AppScreen";
import { ScreenOptions } from "@/navigationv2/types";
import { createScreenTitle } from "@/navigationv2/utilities";
import { userDetails } from "@/utils/recoil/index";

import { redirectUserAfterLogin } from "../utils";

const DoctorProfileScreen = () => {
  const user = useRecoilValue(userDetails);
  const [refreshRequired, setRefreshRequired] = useState(false);
  const [isUnmounted, setIsUnmounted] = useState(false);
  const onRefresh = useCallback(() => !isUnmounted && setRefreshRequired(true), [isUnmounted]);

  /**
   * If the user has tried to access a previous url but was unauthenticated,
   * they are dumped to the login screen where `previousUrl` is set. Then on
   * successful log in, this is passed to the screen where a hard redirect
   * is made to the previous route.
   */
  useOnMount(() => {
    redirectUserAfterLogin();

    return () => setIsUnmounted(true);
  });

  useOnScreenFocus(() => {
    setRefreshRequired(true);
  });

  return (
    <AppScreen
      contextLabel="Profile"
      pageTitle={user ? user.name : ""}
      refreshControl={<RefreshControl refreshing={refreshRequired} onRefresh={onRefresh} />}
      testID="DoctorProfileScreen"
    >
      <DoctorSetup
        username={user ? user.id : ""}
        refreshRequired={refreshRequired}
        onRefreshComplete={setRefreshRequired}
      />
    </AppScreen>
  );
};

const screenOptions: ScreenOptions = {
  title: createScreenTitle("My Profile"),
};

DoctorProfileScreen.screenOptions = screenOptions;

export default DoctorProfileScreen;
