import React from "react";

import MHAFormContainer from "@/components/Forms/MHAFormContainer";
import { RouteKeys } from "@/navigationv2";
import { AMHPDashboardRoutes, AMHPTabRoutes, ScreenNavigationProps, ScreenOptions } from "@/navigationv2/types";
import { createScreenTitle } from "@/navigationv2/utilities";

interface PropTypes
  extends ScreenNavigationProps<AMHPTabRoutes, AMHPDashboardRoutes, RouteKeys.TeamInboxFormViewerScreen> {}

export const TeamInboxFormViewerScreen = (props: PropTypes) => {
  const { route } = props;
  const { formType, formId } = route.params;

  return <MHAFormContainer assessmentId="!!!" formType={formType} formId={formId} />;
};

const screenOptions: ScreenOptions = {
  title: createScreenTitle("Team Inbox Form"),
};

TeamInboxFormViewerScreen.screenOptions = screenOptions;
