import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { StyleSheet, View, Keyboard } from "react-native";
import { useFormikContext } from "formik";
import { DropDownData } from "react-native-material-dropdown";

import { FormType, FormTypes } from "libs/types/mhaForms";
import { isAmhpForm, getMhaFormVersion, isPsuedoAmhpForm } from "libs/mhaForms/helpers";
import { Forms } from "libs/mhaForms/formSections";
import ENV from "@/config";
import { TypographyType } from "@/models/Typography";
import { spacing } from "@/theme";

import { ContentWrap } from "../../ContentWrap";
import { Button, ButtonList } from "../../Button";
import { InfoText } from "../../InfoText";
import Select from "../../Select";
import { SplitFormInputs } from "../../SplitFormInputs";
import Text from "../../Text";
import { TextInput } from "../../TextInput/TextInput";

import { SendToDoctorFormValues } from "./SendToDoctorForm";

interface OnSelectChangeData {
  value: string;
  index: number;
  data?: DropDownData[];
}

interface PropTypes {
  existingJointFormsInS20?: {
    id: string;
    type: FormType;
    status: string;
    createdAt: string;
  }[];
  existingJointFormIsBeingCreatedOrUpdated: boolean;
  useJointMedRecs: boolean;
}

const SendToDoctorFormFields = (props: PropTypes) => {
  const { existingJointFormsInS20 = [], existingJointFormIsBeingCreatedOrUpdated } = props;
  const {
    errors,
    handleBlur,
    handleChange,
    isSubmitting,
    isValid,
    setFieldTouched,
    setFieldValue,
    submitForm,
    touched,
    validateForm,
    values,
  } = useFormikContext<SendToDoctorFormValues>();
  const canSubmit = isValid && !isSubmitting;

  const phoneOrEmailInput = useRef<any>();

  const hasError = (fieldName: keyof SendToDoctorFormValues): boolean => {
    return Boolean(errors[fieldName] && touched[fieldName]);
  };

  let focusTextInput: { focus: () => void };

  /**
   * Create the dropdown values for the `formTypes` select box.
   */
  const mhaFormTypes = useMemo(() => {
    const defaultItem = { id: "", name: "-- select a form --" };
    let forms = [];

    if (props.useJointMedRecs && !!existingJointFormsInS20.length) {
      forms = existingJointFormsInS20
        .filter(item => !isPsuedoAmhpForm(item.type))
        .map(item => {
          const mhaFormVersion = getMhaFormVersion(ENV.ENV, item.createdAt);
          const form = Forms[item.type](mhaFormVersion);
          const name = `Section ${form.section} - ${form.title} (${item.type})`;
          return { id: item.type, name, status: item.status };
        });
    } else {
      const validForms = FormTypes.filter(value => !isPsuedoAmhpForm(value)).filter(
        f => f !== FormType.A3 && f !== FormType.A7
      );
      forms = validForms.map(item => {
        const mhaFormVersion = getMhaFormVersion(ENV.ENV); // New forms wont have created date, so get version based on current date
        const form = Forms[item](mhaFormVersion);
        const name = `Section ${form.section} - ${form.title} (${item})`;

        return { id: item, name };
      });
    }

    return [defaultItem, ...forms];
  }, [existingJointFormsInS20, FormTypes]);

  /**
   * Handle the value change when choosing form type.
   *
   * If using an existing form we also have to set the `values.formId` value
   * to the form.id of the chosen form.
   *
   * @param data
   */
  const handleFormType = useCallback(
    (data: OnSelectChangeData) => {
      const existingIndex = data.index - 1;
      const formId = (!!existingJointFormsInS20.length && existingJointFormsInS20[existingIndex].id) || "";
      const formStatus = (!!existingJointFormsInS20.length && existingJointFormsInS20[existingIndex].status) || "";
      setFieldValue("formStatus", formStatus);
      setFieldValue("formId", formId);
      setFieldTouched("formType", true);
      setFieldValue("formType", data.value);
    },
    [existingJointFormsInS20]
  );

  /**
   * Handle select input value change.
   *
   * This method uses a switch statement as depending the input "fieldName"
   * we need to handle the values differently.
   *
   * By default we assign the value to a Formik value, but we also need to
   * manually set the `touched[fieldName]` otherwise any potential errors won't
   * get handled by Formik.
   *
   * @param fieldName Which field has been changed
   * @param data Select onValueChange callback parameters mapped to an object
   */
  const onSelectChange = (fieldName: string, data: OnSelectChangeData) => {
    switch (fieldName) {
      case "formType":
        handleFormType(data);
        break;

      default:
        setFieldTouched(fieldName, true);
        setFieldValue(fieldName, data.value);
    }
  };

  useEffect(() => {
    validateForm();
  }, [validateForm]);

  useEffect(() => {
    focusTextInput.focus();
  }, []);

  return (
    <>
      <ContentWrap>
        <Text format={TypographyType.HeadingSmall} style={styles.headingSmall}>
          Doctor Details
        </Text>
        <Text format={TypographyType.Regular} style={[]}>
          Please enter the details of the off-platform doctor.
        </Text>

        <View style={styles.inputs}>
          <SplitFormInputs>
            <View>
              <TextInput
                error={hasError("fullName")}
                errorText={errors.fullName as string}
                label="Full Name"
                onBlur={handleBlur("fullName")}
                onChangeText={handleChange("fullName")}
                value={values.fullName}
                style={styles.textInput}
                disabled={existingJointFormIsBeingCreatedOrUpdated}
                returnKeyType="next"
                onSubmitEditing={() => {
                  phoneOrEmailInput.current && phoneOrEmailInput.current.focus();
                }}
                ref={input => {
                  focusTextInput = input;
                }}
              />
            </View>
            <View>
              <TextInput
                error={hasError("phoneOrEmail")}
                errorText={errors.phoneOrEmail as string}
                label="Phone Number or Email Address"
                onChangeText={handleChange("phoneOrEmail")}
                ref={phoneOrEmailInput}
                value={values.phoneOrEmail}
                style={styles.textInput}
                disabled={existingJointFormIsBeingCreatedOrUpdated}
              />
            </View>
          </SplitFormInputs>
        </View>
      </ContentWrap>

      <ContentWrap>
        <Text format={TypographyType.HeadingSmall} style={styles.headingSmall}>
          MHA Form
        </Text>
        <Text format={TypographyType.Regular}>Select which form you want the off-platform doctor to complete.</Text>

        <View style={styles.inputs}>
          <Select
            error={hasError("formType")}
            errorText={errors.formType as string}
            label="MHA Form"
            value={values.formType}
            onValueChange={(value, index, data) => onSelectChange("formType", { value, index, data })}
            onBlur={() => {
              Keyboard.dismiss();
            }}
            options={mhaFormTypes}
            disabled={existingJointFormIsBeingCreatedOrUpdated}
          />
        </View>
      </ContentWrap>

      <ContentWrap>
        <InfoText
          paragraphs={[
            `Once the doctor has received and completed and signed their form, it will appear in the “Signed” section of your doctor forms.`,
            `If you require changes to be made to the signed form, please contact the off-platform doctor, ask them to sign-in to the website, remove their signature and make the requested changes, before resigning the form`,
          ]}
        />
        <ButtonList>
          <Button disabled={!canSubmit} onPress={submitForm} marginBottom={0}>
            Send Form
          </Button>
        </ButtonList>
      </ContentWrap>
    </>
  );
};

const styles = StyleSheet.create({
  headingSmall: {
    marginBottom: spacing[15],
  },

  inputs: {
    paddingVertical: spacing[20],
  },

  textInput: {
    paddingBottom: 0,
  },
});

SendToDoctorFormFields.defaultValues = {
  existingForms: [],
};

export default SendToDoctorFormFields;
