import React, { useMemo, useEffect, useState, useCallback } from "react";
import { StyleSheet, View, Platform } from "react-native";
import { useQuery } from "@apollo/react-hooks";
import dayjs from "dayjs";
import { useRecoilState } from "recoil";
import gql from "graphql-tag";

import {
  amhpTeamMhaHospitalEmails,
  amhpTeamEmailFormsToSelf,
  amhpTeamEmailFormsToApprovedLists,
  amhpTeamDownloadForms,
} from "libs/utils/featureFlags";
import { FormType, MHAFormData } from "libs/types/mhaForms";
import { FormStatus } from "libs/types/API";

import { CardContainer } from "@/components/Cardd";
import ContentWrap from "@/components/ContentWrap/ContentWrap";
import Text from "@/components/Text";
import SubmittedForms from "./SubmittedForms";

import { TypographyType } from "@/models/Typography";
import { color, spacing } from "@/theme";
import { refreshTeamInboxRequired } from "@/utils/recoil";
import { mqWeb } from "@/utils/helpers";
import { getValueFromDataSections } from "@/components/Forms/MHAFormContainer/helpers";
import { Button } from "@/components/Button";
import { useBottomSheet } from "@/hooks";
import BottomSheets from "@/components/AssessmentDetails/BottomSheets";
import { ButtonList } from "../../Button/ButtonList";

export const amhpInboxQuery = gql`
  query GetFormsByAmhpInboxId($amhpTeamInboxId: ID!) {
    getFormsByAmhpInboxId(gsi2pk: $amhpTeamInboxId) {
      items {
        id
        type
        amhpTeamId
        version
        authors
        doctorNames
        status
        data {
          sections {
            name
            fields {
              name
              value
              valueArray
              valueLocation {
                address
                city
                postcode
              }
              type
            }
          }
        }
        signed
        createdAt
      }
    }
  }
`;

export interface AmhpInboxResponse {
  getFormsByAmhpInboxId: {
    items: {
      id: string;
      type: FormType;
      amhpTeamId: string;
      version: number;
      authors: string[];
      doctorNames: string[];
      status: FormStatus;
      data: MHAFormData | null;
      createdAt: string;
      signed: string[];
    }[];
  };
}

const DashboardFormInbox: React.FC<{
  amhpTeamId: string | undefined;
  featureFlags: string;
}> = ({ amhpTeamId, featureFlags }) => {
  const { HeadingMedium, HeadingSmall, Small } = TypographyType;
  const [teamInboxRefresh, setTeamInboxRefresh] = useRecoilState(refreshTeamInboxRequired);
  const isWebView = mqWeb();
  const FormsWrapper = useMemo(() => (isWebView ? ContentWrap : React.Fragment), [isWebView]);
  const { openBottomSheet, closeBottomSheet } = useBottomSheet();
  const [emailOrExportFormsOption, setEmailOrExportFormsOption] = useState<"email" | "download" | null>(null);
  const { error, data, refetch, variables } = useQuery<AmhpInboxResponse>(amhpInboxQuery, {
    variables: {
      amhpTeamInboxId: amhpTeamId,
    },
    fetchPolicy: "cache-and-network",
  });
  const refreshDone = useCallback(() => setTeamInboxRefresh(false), []);
  useEffect(() => {
    if (data && teamInboxRefresh) {
      refetch(variables).then(refreshDone);
    }
  }, [teamInboxRefresh, data]);

  const forms = useMemo(() => {
    return (
      data?.getFormsByAmhpInboxId.items
        .filter(item => item && item.data !== null)
        .map(f => ({
          id: f.id,
          formType: f.type,
          // we have filtered out null data above, ! is safe
          patientName: (getValueFromDataSections(f.data!.sections, "name", "patientName") as string) || "",
          doctorName: f.doctorNames ? f.doctorNames[0] : "",
          // we have filtered out null data above, ! is safe
          examinationDate: getValueFromDataSections(f.data!.sections, "type", "Date")
            ? // we have filtered out null data above, ! is safe
              dayjs(getValueFromDataSections(f.data!.sections, "type", "Date") as string)
            : dayjs(),
          createdAt: f.createdAt,
          signed: f.signed,
        })) || []
    );
  }, [data?.getFormsByAmhpInboxId.items]);

  const renderFormData = useMemo(() => {
    if (forms) {
      return (
        <FormsWrapper>
          <CardContainer>
            <SubmittedForms forms={forms} />
          </CardContainer>
        </FormsWrapper>
      );
    } else if (error) {
      return (
        <ContentWrap>
          <Text format={TypographyType.Regular}>There was an error loading forms. Try again.</Text>
        </ContentWrap>
      );
    } else {
      return (
        <ContentWrap>
          <Text format={TypographyType.Regular}>Loading!</Text>
        </ContentWrap>
      );
    }
  }, [data, error, isWebView]);

  useEffect(() => {
    if (emailOrExportFormsOption && forms) {
      openBottomSheet({
        type: "generic",
        data: {
          heading: emailOrExportFormsOption === "email" ? "Email Forms" : "Download Forms",
          component: BottomSheets.EmailOrExportForms,
          componentProps: {
            type: emailOrExportFormsOption,
            signedForms: forms,
            amhpTeamFeatures: featureFlags,
            amhpTeamId,
            resetBottomSheet: () => {
              setEmailOrExportFormsOption(null);
              closeBottomSheet();
            },
            showPatientNameOnFormOptions: true,
          },
          onDismiss: () => setEmailOrExportFormsOption(null),
        },
      });
    }
  }, [emailOrExportFormsOption, featureFlags, data?.getFormsByAmhpInboxId.items]);

  const hasEmailToHospitalFeature = amhpTeamMhaHospitalEmails(featureFlags);
  const hasEmailToSelfFeature = amhpTeamEmailFormsToSelf(featureFlags);
  const hasEmailToListsFeature = amhpTeamEmailFormsToApprovedLists(featureFlags);
  const hasDownloadFeature = Platform.OS === "web" && amhpTeamDownloadForms(featureFlags);

  return (
    <>
      <ContentWrap>
        <View style={styles.webHeading}>
          <Text format={HeadingMedium}>MHA Forms</Text>
          {!!forms.length &&
            isWebView &&
            (hasEmailToHospitalFeature || hasEmailToSelfFeature || hasEmailToListsFeature || hasDownloadFeature) && (
              <View style={{ flexDirection: "row" }}>
                {(hasEmailToHospitalFeature || hasEmailToSelfFeature || hasEmailToListsFeature) && (
                  <Button onPress={() => setEmailOrExportFormsOption("email")} style={{ marginRight: spacing[10] }}>
                    Email Forms
                  </Button>
                )}
                {hasDownloadFeature && (
                  <Button onPress={() => setEmailOrExportFormsOption("download")}>Download Forms</Button>
                )}
              </View>
            )}
        </View>
        {!data?.getFormsByAmhpInboxId.items.length ? (
          <Text format={Small} style={styles.noFormsText}>
            You do not currently have any submitted forms.
          </Text>
        ) : (
          <Text format={HeadingSmall} style={styles.headingSmall}>
            Submitted MHA Forms
          </Text>
        )}
        {!!forms.length &&
          !isWebView &&
          (hasEmailToHospitalFeature || hasEmailToSelfFeature || hasEmailToListsFeature || hasDownloadFeature) && (
            <ButtonList>
              {(hasEmailToHospitalFeature || hasEmailToSelfFeature || hasEmailToListsFeature) && (
                <Button onPress={() => setEmailOrExportFormsOption("email")}>Email Forms</Button>
              )}
              {hasDownloadFeature && (
                <Button onPress={() => setEmailOrExportFormsOption("download")}>Download Forms</Button>
              )}
            </ButtonList>
          )}
      </ContentWrap>
      {data?.getFormsByAmhpInboxId.items && data?.getFormsByAmhpInboxId.items?.length > 0 && renderFormData}
    </>
  );
};

export default DashboardFormInbox;

const styles = StyleSheet.create({
  headingSmall: {
    marginBottom: spacing[30],
  },

  noFormsText: {
    color: color.textLight,
    marginBottom: spacing[30],
  },

  webHeading: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
});
