import * as Yup from "yup";
import { ComponentElement, FormFieldsDoctor } from "libs/types/mhaForms";

const componentElements: ComponentElement[] = [
  {
    component: "Text",
    componentProps: {
      instruction: `I am also of the opinion that, taking into account the nature and degree of the mental disorder from which the patient is suffering and all the other circumstances of the case, appropriate medical treatment is available to the patient at the following hospital (or one of the following hospitals): -\n\n`,
      guidance:
        "(Enter name of hospital(s). If appropriate treatment is available only in a particular part of the hospital, say which part.)",
    },
    fullWidth: true,
  },
  {
    component: "BottomTextInput",
    fullWidth: true,
    fieldName: FormFieldsDoctor.Hospitals,
    componentProps: {
      label: "Hospitals",
      helperText:
        "Enter name of hospital(s). If appropriate treatment is available only in a particular part of the hospital, say which part.",
      buttonLabel: "Hospitals",
      defaultText: "No details currently given",
      hideSampleText: true,
    },
  },
];

export default {
  title: "Hospital Details",
  state: {
    hospitals: "",
  },

  validation: Yup.object().shape({
    hospitals: Yup.string()
      .trim()
      .min(10, "Please enter detailed hospital information")
      .required("Please enter hospital information"),
  }),

  componentElements,
};
