import * as Yup from "yup";
import { ComponentElement, FormFieldsDoctor, FormType, FormSampleText } from "libs/types/mhaForms";

const componentElements: ComponentElement[] = [
  {
    component: "Text",
    componentProps: {
      instruction: "My reasons for these opinions are:",
      guidance:
        "(Your reasons should cover (a), (b) and (c) above. As part of them: describe the patient’s symptoms and behaviour and explain how those symptoms and behaviour lead you to your opinion; and explain why the patient ought to be admitted to hospital urgently and why informal admission is not appropriate.)",
    },
    genericProps: {
      pdfBreak: [FormType.A11],
    },
    fullWidth: true,
  },
  {
    component: "BottomTextInput",
    fieldName: FormFieldsDoctor.OpinionReason,
    fullWidth: true,
    componentProps: {
      label: "Opinion Reasons",
      helperText:
        "My reasons for these opinions are: (Your reasons should cover (a), (b) and (c) above. As part of them: describe the patient’s symptoms and behaviour and explain how those symptoms and behaviour lead you to your opinion; and explain why the patient ought to be admitted to hospital urgently and why informal admission is not appropriate.)",
      buttonLabel: "Opinion Reasons",
      defaultText: "No details currently given",
      sampleText: FormSampleText.SECTION_4_OPINION,
    },
  },
  {
    component: "Text",
    componentProps: {
      instruction:
        "Compliance with the provisions of Part 2 of the Act relating to applications under section 2 would involve undesirable delay, because –",
      guidance:
        "(Say approximately how long you think it would take to obtain a second medical recommendation and what risk such a delay would pose to the patient or to other people.)",
    },
    fullWidth: true,
  },
  {
    component: "BottomTextInput",
    fieldName: FormFieldsDoctor.Section2delay,
    fullWidth: true,
    componentProps: {
      label: "Reasons",
      helperText:
        "My reasons for these opinions are: (Your reasons should cover (a), (b) and (c) above. As part of them: describe the patient’s symptoms and behaviour and explain how those symptoms and behaviour lead you to your opinion; and explain why the patient ought to be admitted to hospital urgently and why informal admission is not appropriate.)",
      buttonLabel: "Reasons",
      defaultText: "No details currently given",
      sampleText: FormSampleText.SECTION_4_REASONS,
    },
  },
];

export default {
  title: "Reasons",

  state: {
    opinionReason: "",
    section2delay: "",
  },

  validation: Yup.object().shape({
    opinionReason: Yup.string().trim().required("Please enter a reason"),
    section2delay: Yup.string().trim().required("Please enter a reason"),
  }),

  componentElements,
};
