import React from "react";
import { createStackNavigator } from "@react-navigation/stack";

import { DoctorProfileRoutes, DoctorTabRoutes, TabNavigationProps } from "@/navigationv2/types";
import screenOptions from "@/navigationv2/config/screenOptions";
import Screens from "@/screens";
import TabIcon from "../TabIcon";

const ProfileStack = createStackNavigator<DoctorProfileRoutes>();

interface PropTypes extends TabNavigationProps<DoctorTabRoutes, "DoctorProfileTab"> {}

const DoctorProfileStack = () => {
  return (
    <ProfileStack.Navigator initialRouteName="DoctorProfileScreen" screenOptions={screenOptions}>
      <ProfileStack.Screen
        name="DoctorProfileScreen"
        component={Screens.DoctorProfileScreen}
        options={Screens.DoctorProfileScreen.screenOptions}
      />
      <ProfileStack.Screen
        name="EditDoctorProfileScreen"
        component={Screens.EditDoctorProfileScreen}
        options={Screens.EditDoctorProfileScreen.screenOptions}
      />
      <ProfileStack.Screen
        name="DoctorAvailabilityScreen"
        component={Screens.DoctorAvailabilityScreen}
        options={Screens.DoctorAvailabilityScreen.screenOptions}
      />
      <ProfileStack.Screen
        name="EditDoctorAvailabilityScreen"
        component={Screens.EditDoctorAvailabilityScreen}
        options={Screens.EditDoctorAvailabilityScreen.screenOptions}
      />
      <ProfileStack.Screen
        name="DoctorVisitDetailsScreen"
        component={Screens.DoctorVisitDetailsScreen}
        options={Screens.DoctorVisitDetailsScreen.screenOptions}
      />
      <ProfileStack.Screen
        name="HolidaysScreen"
        component={Screens.HolidaysScreen}
        options={Screens.HolidaysScreen.screenOptions}
      />
      <ProfileStack.Screen
        name="MHAFormScreen"
        component={Screens.MHAFormScreen}
        options={Screens.MHAFormScreen.screenOptions}
      />
      <ProfileStack.Screen
        name="TeamSelectScreen"
        component={Screens.TeamSelectScreen}
        options={Screens.TeamSelectScreen.screenOptions}
      />
    </ProfileStack.Navigator>
  );
};

DoctorProfileStack.tabOptions = {
  tabBarIcon: TabIcon("person-outline"),
  tabBarLabel: "Profile",
};

export default DoctorProfileStack;
