import React, { useContext } from "react";
import { Dimensions, View, Text, StyleSheet } from "react-native";
import { FormType } from "libs/types/mhaForms";
import { ContentWrap } from "../..";

import { color, spacing } from "@/theme";
import useIsWebView from "@/hooks/useIsWebView";
import { MhaFormThemeContext } from "../MHAFormContainer/ThemeContext";

export default function ReadonlyHeader({
  regulation,
  form,
  longTitle,
}: {
  regulation?: string;
  form: FormType;
  longTitle?: string;
}) {
  const isWebView = useIsWebView();
  const theme = useContext(MhaFormThemeContext);

  const formTitle =
    form === FormType.AUTHORITY_TO_CONVEY || FormType.STATEMENT_OF_REASON ? form.replace(/_/g, " ") : `Form ${form}`;
  return (
    <ContentWrap>
      <View style={[theme, styles.wrapper]}>
        <View style={[styles.outer, !isWebView && styles.outerSmallDevice]}>
          <View style={styles.inner}>
            <Text style={styles.form}>{formTitle}</Text>
            {regulation && <Text style={styles.italic}>{regulation}</Text>}
          </View>
          {!FormType.STATEMENT_OF_REASON && <Text style={styles.mha}>Mental Health Act 1983</Text>}
        </View>
        {longTitle && <Text style={styles.longTitle}>{longTitle}</Text>}
      </View>
    </ContentWrap>
  );
}

const { width } = Dimensions.get("screen");
const styles = StyleSheet.create({
  form: { marginRight: 5, fontSize: width > 320 ? 16 : 14, fontWeight: "bold" },
  mha: { fontSize: width > 320 ? 16 : 14, fontWeight: "bold" },
  italic: { fontStyle: "italic" },
  longTitle: { marginBottom: 10, fontSize: 12, fontWeight: "bold" },
  inner: { flexDirection: "row", alignItems: "center" },
  outer: { justifyContent: "space-between", flexDirection: "row", marginBottom: 10 },
  outerSmallDevice: { flexDirection: "column" },
  wrapper: {
    paddingVertical: spacing[10],
  },
});
