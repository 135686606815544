import dayjs from "dayjs";
import { MhaFormVersion } from "libs/types/mhaForms";
import { getMhaFormVersion2ActiveDate } from "libs/config/index";

export const getMhaFormVersion = (env: string, formCreationDate?: string): MhaFormVersion => {
  // If formCreationDate supplied, validate it, as it will often be transferred as a string
  if (formCreationDate && !dayjs(formCreationDate).isValid()) {
    throw new Error("Invalid date supplied");
  }
  const version2ActiveDate = dayjs(getMhaFormVersion2ActiveDate(env));
  const formIsNewlyCreatedAfterV2 = !formCreationDate && dayjs().isAfter(version2ActiveDate);
  const formWasCreatedAfterV2ActiveDate = formCreationDate && dayjs(formCreationDate).isAfter(version2ActiveDate);

  if (formIsNewlyCreatedAfterV2 || formWasCreatedAfterV2ActiveDate) {
    return 2;
  }

  return 1;
};
