/* eslint-disable react-native/no-inline-styles */
import { Forms } from "libs/mhaForms/formSections";
import { isEditable, getMhaFormVersion, isConveyanceForm, isUnofficialForm } from "libs/mhaForms/helpers";
import { FormStatus } from "libs/types/API";
import { FormType } from "libs/types/mhaForms";
import { useFormik } from "formik";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Animated, Platform, StyleSheet, View } from "react-native";
import { ParamListBase, useNavigation } from "@react-navigation/native";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { AnalyticsEvent } from "libs/analytics/events";
import { ContentWrap, Text } from "../../../components";
import { useOnMount } from "../../../hooks";
import { AppScreen } from "../../../layouts/AppScreen";
import { buildErr, S12Error, saveFormError, updateFormStatusError } from "@/models/Error";
import { MHAFormMetadata, FormikValues } from "@/models/MHAForm/MHAForm";
import { TypographyType } from "@/models/Typography";
import { color, palette, spacing, timing } from "../../../theme";
import { EventAttributes, recordEvent } from "../../../utils/analytics";
import { mqWeb } from "../../../utils/helpers";
import {
  lastException,
  offlineMHAForm,
  snackbarMessage,
  userDetails as userDetailsRecoil,
} from "../../../utils/recoil";
import { BackButtonProps } from "../../BackButton/BackButton.props";
import { SplitSection } from "../../SplitSection";
import { ReadonlyHeader, SignatureBlock } from "../index";
import { MHAFormSection } from "../MHAFormSection";
import { ActionButtons, AsideContent, BottomContent, ReviewActionButtons } from "./BottomContent";
import { buildFormikSections, isFormOwner, SectionInfo } from "./helpers";
import ENV from "@/config";
import { StackNavigationProp } from "@react-navigation/stack";
import { MhaFormThemeContext, themes } from "../MHAFormContainer/ThemeContext";
import { selectedAmhpTeamId, refreshDoctorDraftFormInboxRequired } from "@/utils/recoil";
import { RouteKeys } from "@/navigationv2";

interface FormContainerProps {
  assessmentId?: string;
  formType: FormType;
  formData?: FormikValues | null;
  isExternalDoctor?: boolean;
  formMetadata?: MHAFormMetadata | null;
  saveForm: (
    formMetadata: Omit<MHAFormMetadata, "notes">,
    formData?: FormikValues,
    prevStatus?: FormStatus
  ) => Promise<Record<string, any> | { isError: true; message: string }>;
  blurCounter?: number; // increasing number each time the navigation goes away from forms, use with useEfffect to determine how to handle navigation away
}

const backButtonConfig: BackButtonProps = {
  enabled: true,
  float: true,
  color: color.textWhite,
};

const externalButtonConfig: BackButtonProps = {
  enabled: false,
};

// Note: container memoised to not update when navigation changes
const MHAFormContainer = (props: FormContainerProps) => {
  const { assessmentId, saveForm, formType, formData, formMetadata, isExternalDoctor = false } = props;
  const navigation = useNavigation<StackNavigationProp<ParamListBase>>();

  // setting local "isSaving" flag as version number for newly created forms might not get returned quick enough before a user navigates to next form section
  const isSaving = useRef(false);

  // performance hack, server metadata
  const metadata = useRef(formMetadata);
  const mhaFormVersion = getMhaFormVersion(ENV.ENV, formMetadata?.createdAt);
  const userDetails = useRecoilValue(userDetailsRecoil);
  const [readonly, setReadonly] = useState(
    isReadonly(formMetadata?.status, userDetails?.id, formMetadata?.createdBy, formMetadata?.authors)
  );
  const setDoctorDraftFormInboxRefresh = useSetRecoilState(refreshDoctorDraftFormInboxRequired);
  const amhpTeamId = useRecoilValue(selectedAmhpTeamId);
  const setMessage = useSetRecoilState(snackbarMessage);
  const setLastException = useSetRecoilState(lastException);

  const [offlineForm, setOfflineForm] = useRecoilState(offlineMHAForm);
  // The next section to navigate to, this will align with activeSlideContent when no animation is active
  const [activeSlide, setActiveSlide] = useState(0);
  // The current section
  const [activeSlideContent, setActiveSlideContent] = useState(0);

  // UI state
  const [mainOpacity] = useState(new Animated.Value(1));
  const [mainTranslate] = useState(new Animated.Value(0));
  const [scrollView, setScrollView] = useState<{
    current: { scrollTo: (scrollPosition: { x: number; y: number }) => void };
  } | null>(null);

  const { formSections } = Forms[formType](mhaFormVersion);

  const { initialState, validationSchema, sectionData } = useMemo(
    () => buildFormikSections(formSections, userDetails, formType),
    [formSections]
  );

  const formikData = useFormik<typeof initialState>({
    initialValues: formData ? ({ ...formData, formType } as FormikValues) : initialState,
    ...(!readonly && { validationSchema }),
    onSubmit: async values => {
      // TODO: does this fire if there are errors?
      if (!metadata.current?.status) {
        return;
      }

      if (!userDetails) {
        // only logged in users should be able to save forms
        return;
      }
      await saveForm(
        {
          assessmentId,
          ...metadata.current,
          amhpTeamId: metadata.current?.amhpTeamId || "!!!", // !!! is our shorthand for 'not exists'
          version: metadata.current?.version || -1, // new forms must have version -1, form updates must have the latest version
          authors: [userDetails.id],
        },
        values
      ).then(response => {
        if ("isError" in response && response.isError && response.message?.includes("Network Error")) {
          const error = new S12Error(saveFormError);
          setLastException(error);
          buildErr(saveFormError, setLastException)("Network Error");
        } else if ("isError" in response) {
          buildErr(saveFormError, setLastException)(response.message);
        } else {
          setMessage("Form saved successfully");
          offlineForm && setOfflineForm(null);
        }
        navigation.goBack();
      });
    },
  });

  const { errors, touched, values, resetForm } = formikData;
  const isUnattachedForm = !!formMetadata && (!formMetadata.assessmentId || formMetadata.assessmentId === "!!!");

  useOnMount(() => {
    if (formikData) {
      formikData.validateForm();
    }

    if (metadata.current && metadata.current.id) {
      recordEvent(AnalyticsEvent.MHA_FORM_VIEWED, {
        amhpTeamId: metadata.current.amhpTeamId || "",
        assessmentId: assessmentId || "",
        formId: metadata.current.id,
        formType: metadata.current?.type || "",
      });
    }
  });

  const currentValues = useRef(values);
  currentValues.current = values;

  // if we pull new data from the server, reset the form with the new data.
  useEffect(() => {
    if (formData) {
      resetForm({
        values: { ...formData, formType } as FormikValues,
        errors: formikData.errors,
        touched: formikData.touched,
      });
    }
  }, [formData]);

  // anytime the server metadata changes, update the ref metadata
  // if the server metadata updates the status, or the user changes, or authors list changes, re-determine readonly status
  useEffect(() => {
    metadata.current = formMetadata;
    setReadonly(
      curr => curr || isReadonly(formMetadata?.status, userDetails?.id, formMetadata?.createdBy, formMetadata?.authors)
    );
  }, [formMetadata]);

  // data for bottom status bar
  const sectionInfo = sectionData.map(section => {
    return {
      touched: Boolean(touched[section.name]),
      valid: !errors[section.name],
    } as SectionInfo;
  });

  useEffect(() => {
    // If we exit the forms page for any reason, save progress if there are form changes
    if (props.blurCounter && props.blurCounter > 0) {
      saveProgress({ exitForm: false });
    }
  }, [props.blurCounter]);

  const saveProgress = (options: { callback?: () => void; exitForm?: boolean; showChangesAlreadySaved?: boolean }) => {
    const currentSectionName = sectionData[activeSlideContent].name;
    if (process.env.NODE_ENV === "test" && !!options.callback) {
      // Allows storybook to function
      options.callback();
    }
    if (!userDetails) {
      // don't save forms for users we don't know
      return;
    }
    // only saves data if the current section has data that is different from the initial state,
    // or if the form is in offline mode
    // or if no previous save has been made,
    if (
      JSON.stringify((formData || initialState)[currentSectionName]) !==
        JSON.stringify(currentValues.current[currentSectionName]) ||
      !!offlineForm ||
      (metadata.current?.version === -1 && !isSaving.current)
    ) {
      isSaving.current = true;
      const updatedMetaData = {
        // provide default values if metadata does not exist (new form), otherwise metadata overrides these
        assessmentId,
        type: metadata.current?.type || formType,
        amhpTeamId: metadata.current?.amhpTeamId || amhpTeamId || "!!!",
        version: metadata.current?.version || -1,
        authors: [userDetails.id],
        status: FormStatus.s10_draft,
        ...metadata.current,
      };

      saveForm(updatedMetaData, currentValues.current).then(response => {
        if (
          "isError" in response &&
          response.isError &&
          response.message?.includes("Network Error") &&
          options.exitForm
        ) {
          const error = new S12Error(saveFormError);
          setLastException(error);
          buildErr(saveFormError, setLastException)("Network Error");
        } else if ("isError" in response) {
          buildErr(saveFormError, setLastException)(response.message);
        } else {
          options.exitForm && setMessage("Form saved successfully");
          offlineForm && setOfflineForm(null);
        }

        if (!isUnattachedForm) {
          options.exitForm && navigation.canGoBack() && navigation.goBack();
        } else {
          setDoctorDraftFormInboxRefresh(true);
          options.exitForm && navigation.navigate(RouteKeys.DoctorProfileScreen);
        }
      });
    } else if (
      options.showChangesAlreadySaved &&
      JSON.stringify((formData || initialState)[currentSectionName]) ===
        JSON.stringify(currentValues.current[currentSectionName]) &&
      userDetails &&
      userDetails.id.substring(5, 0) === "Form-"
    ) {
      // current section has data that is the same as the initial state
      // and user is external doctor...
      // This means "Save and Exit" button is now displaying as "Save Progress" for the external doctor
      // As the "Save Progress" button is always enabled and an External Doctor has nothing to navigate back to, we should give them some feedback when they press it
      setMessage("Current changes already saved");
    } else {
      if (!isUnattachedForm) {
        options.exitForm && navigation.canGoBack() && navigation.goBack();
      } else {
        setDoctorDraftFormInboxRefresh(true);
        options.exitForm && navigation.navigate(RouteKeys.DoctorProfileScreen);
      }
    }
    options.callback && options.callback();
    if (scrollView?.current) scrollView.current.scrollTo({ x: 0, y: 0 });
    formikData.setFieldTouched(currentSectionName, true);
    formikData.validateForm();
  };

  const saveAndExit = () =>
    saveProgress({
      // callback: () => navigation.navigate(RouteKeys.AssessmentDetailsScreen, { assessmentId }),
      exitForm: true,
    });

  const createStatusAnalyticsEvent = useCallback(
    (status: FormStatus) => {
      const getEventName = (status: FormStatus): AnalyticsEvent | null => {
        const isDoctor =
          userDetails?.groups?.some((item: string) => item === "Doctor" || item === "ExternalDoctor") || false;

        const formIsSigned = status === FormStatus.s40_signed || status === FormStatus.s20_awaiting_other_doctor;
        const formChangesInProgress = status === FormStatus.s30_changes_in_progress;

        if (formIsSigned) {
          return isDoctor ? AnalyticsEvent.MHA_FORM_DOCTOR_SIGNED : AnalyticsEvent.MHA_FORM_AMHP_SIGNED;
        } else if (formChangesInProgress) {
          return isDoctor ? AnalyticsEvent.MHA_FORM_DOCTOR_REMOVED_SIGNATURE : null;
        }

        return null;
      };

      const eventName = getEventName(status);

      const eventData: EventAttributes = {
        amhpTeamId: metadata.current?.amhpTeamId || "",
        assessmentId: assessmentId || "",
        formId: metadata.current?.id || "",
        formType: metadata.current?.type || "",
      };

      if (eventName) {
        recordEvent(eventName, eventData);
      }
    },
    [userDetails]
  );

  const updateStatus = useCallback(
    async (newStatus: FormStatus, successMessage: string) => {
      // have we saved this to the server before?
      if (metadata.current) {
        // hold onto the current server status
        const prevStatus = metadata.current?.status;
        await saveForm(
          {
            ...metadata.current,
            status: newStatus,
          },
          currentValues.current,
          prevStatus
        )
          .then(response => {
            if ("isError" in response && response.isError && response.message?.includes("Network Error")) {
              const error = new S12Error(saveFormError);
              setLastException(error);
              buildErr(saveFormError, setLastException)("Network Error");
            } else if ("isError" in response) {
              buildErr(saveFormError, setLastException)(response.message);
            } else {
              createStatusAnalyticsEvent(newStatus);

              // user making edits shouldn't be a "visual" status change, as user will stay on page and make
              // changes before moving the form into its next status; so remove readOnly status otherwise
              // show success message and navigate back to prev screen
              const userCanNowMakeEdits =
                // AMHP making edits moves form from s40_signed > s10_draft
                // Doctor making edits to a single med rec moves form from s40_signed > s30_changes_in_progress
                (prevStatus === FormStatus.s40_signed &&
                  [FormStatus.s10_draft, FormStatus.s30_changes_in_progress].includes(newStatus)) ||
                // Doctor making edits to a joint med rec moves from (s20_awaiting_other_doctor || s40_signed) > s30_changes_in_progress
                ([FormStatus.s20_awaiting_other_doctor, FormStatus.s40_signed].includes(prevStatus) &&
                  newStatus === FormStatus.s30_changes_in_progress);
              if (userCanNowMakeEdits) {
                setReadonly(false);
                setActiveSlide(0);
              } else {
                setMessage(successMessage);
                if (newStatus === FormStatus.s10_draft) {
                  navigation.goBack();
                }
              }
            }
          })
          .catch(buildErr(updateFormStatusError, setLastException));
        // If we hit an error, keep the user on the page
      }
    },
    [formData, formMetadata]
  );

  const onSelectSection = (index: number) => {
    const isCurrentlyActive = index === activeSlideContent;

    for (let i = 0; i <= index; i++) {
      const sectionName = sectionData[i].name;
      formikData.setFieldTouched(sectionName, true);
    }

    !isCurrentlyActive && saveProgress({ callback: () => setActiveSlide(index) });
  };

  const onToggleReviewMode = useCallback(() => {
    setReadonly(curr => (metadata.current && metadata.current.status !== FormStatus.s10_draft) || !curr);
  }, []);

  const isWebView = mqWeb();

  useEffect(() => {
    if (activeSlide === activeSlideContent) {
      return;
    }

    setTimeout(() => {
      setActiveSlideContent(activeSlide);
    }, timing.quick);

    Animated.sequence([
      Animated.parallel([
        Animated.timing(mainOpacity, {
          toValue: 0,
          duration: timing.quick,
          useNativeDriver: true,
        }),
        Animated.timing(mainTranslate, {
          toValue: 30,
          duration: 0,
          delay: timing.quick,
          useNativeDriver: true,
        }),
      ]),
      Animated.parallel([
        Animated.timing(mainTranslate, {
          toValue: 0,
          duration: timing.slow,
          useNativeDriver: true,
        }),
        Animated.timing(mainOpacity, {
          toValue: 1,
          duration: timing.slow,
          delay: timing.quick,
          useNativeDriver: true,
        }),
      ]),
    ]).start();
  }, [activeSlide]);

  const aside = isWebView && (
    <AsideContent
      activeSection={activeSlideContent}
      formType={formType}
      isFormOwner={isFormOwner(formType, userDetails)}
      isExternalDoctor={userDetails ? userDetails.id.substring(5, 0) === "Form-" : false}
      onSelectSection={onSelectSection}
      saveAndExit={() => saveProgress({ exitForm: true, showChangesAlreadySaved: true })}
      sections={sectionData.map(s => s.title)}
      shouldSaveProgress
      isUnattachedForm={isUnattachedForm}
    />
  );

  const isBlueForm = isConveyanceForm(formType) || isUnofficialForm(formType);
  const formTheme = isBlueForm ? themes.blue : themes.pink;

  const MainContent = (
    <MhaFormThemeContext.Provider value={formTheme}>
      <View
        style={[
          styles.flex,
          readonly && formTheme,
          readonly && styles.readonlyContainer,
          readonly && Platform.OS !== "web" && styles.readonlyMobileContainer,
        ]}
      >
        {readonly ? (
          <>
            <ReadonlyHeader
              regulation={Forms[formType](mhaFormVersion).regulation}
              form={formType}
              longTitle={Forms[formType](mhaFormVersion).longTitle}
            />
            {sectionData.map((section, i) => (
              <MHAFormSection
                key={"formSection" + i}
                elements={section.componentElements}
                sectionName={section.name}
                formData={formikData}
                readonly={true}
              />
            ))}
            {formMetadata && (
              <SignatureBlock
                status={formMetadata.status}
                type={formMetadata.type}
                signed={formMetadata.signed}
                sha256={formMetadata.sha256}
              />
            )}
            {formMetadata && (
              <ReviewActionButtons
                formType={formType}
                formData={currentValues.current}
                formMetadata={formMetadata}
                formStatus={formMetadata?.status || FormStatus.s10_draft}
                userDetails={userDetails}
                isEditable={isEditable(
                  formMetadata?.status || FormStatus.s10_draft,
                  userDetails?.groups,
                  formMetadata.createdBy === userDetails?.id,
                  userDetails?.id.substring(5, 0) === "Form-" || false,
                  formMetadata.authors,
                  !formMetadata.assessmentId
                )}
                goBack={() => {
                  metadata.current?.status === FormStatus.s10_draft ||
                  metadata.current?.status === FormStatus.s60_signed_in_amhp_team_inbox
                    ? setReadonly(false)
                    : navigation.goBack();
                  if (scrollView?.current) scrollView.current.scrollTo({ x: 0, y: 0 });
                }}
                saveNewStatus={updateStatus}
                isUnattachedForm={isUnattachedForm}
              />
            )}
          </>
        ) : (
          <View style={styles.flex}>
            <Animated.View
              style={{
                opacity: mainOpacity,
                transform: [{ translateY: mainTranslate }],
                flex: 1,
                marginBottom: spacing[30],
              }}
            >
              <ContentWrap>
                <Text format={TypographyType.HeadingMedium}>
                  {`${activeSlideContent + 1}. ${sectionData[activeSlideContent].title}`}
                </Text>
              </ContentWrap>

              <MHAFormSection
                elements={sectionData[activeSlideContent].componentElements}
                sectionName={sectionData[activeSlideContent].name}
                formData={formikData}
                readonly={false}
              />
            </Animated.View>
            <ActionButtons
              activeSection={activeSlideContent}
              isLastSection={activeSlideContent === sectionData.length - 1}
              isWebView={isWebView}
              isFormOwner={isFormOwner(formType, userDetails)}
              formType={formType}
              save={(advance: -1 | 1 | undefined) =>
                saveProgress({ callback: () => advance && setActiveSlide(curr => curr + advance) })
              }
              sectionInfo={sectionInfo}
              saveAndExit={saveAndExit}
              review={onToggleReviewMode}
              submit={formikData.handleSubmit}
              shouldSaveProgress
              isUnattachedForm={isUnattachedForm}
              isExternalDoctor={userDetails ? userDetails.id.substring(5, 0) === "Form-" : false}
            />
          </View>
        )}
      </View>
    </MhaFormThemeContext.Provider>
  );

  const contextLabel =
    formType === FormType.AUTHORITY_TO_CONVEY || formType === FormType.STATEMENT_OF_REASON
      ? "Form"
      : `MHA 1983 - Regulation ${Forms[formType](mhaFormVersion).regulation}`;
  const pageTitle =
    formType === FormType.AUTHORITY_TO_CONVEY || formType === FormType.STATEMENT_OF_REASON
      ? Forms[formType](mhaFormVersion).title
      : `Section ${Forms[formType](mhaFormVersion).section}`;

  return (
    <>
      <AppScreen
        contextLabel={contextLabel}
        pageTitle={pageTitle}
        backButton={isExternalDoctor ? externalButtonConfig : backButtonConfig}
        passRef={setScrollView}
        mobileHeaderContent={() =>
          Forms[formType](mhaFormVersion).shortTitle ? (
            <Text format={TypographyType.RegularBold} color={palette.white}>
              {`${Forms[formType](mhaFormVersion).shortTitle} (${formType})`}
            </Text>
          ) : null
        }
        isExternal={isExternalDoctor}
      >
        {isWebView && !readonly ? <SplitSection aside={aside} main={MainContent} stickyAside /> : MainContent}
      </AppScreen>
      {!readonly && (
        <BottomContent
          activeSection={activeSlideContent}
          isWebView={isWebView}
          onSelectSection={onSelectSection}
          sectionInfo={sectionInfo}
        />
      )}
    </>
  );
};

function isReadonly(
  formStatus: FormStatus | undefined,
  userId: string | undefined,
  createdBy: string | undefined,
  authors: string[] | undefined
) {
  const isNotInProgress =
    formStatus && ![FormStatus.s10_draft, FormStatus.s30_changes_in_progress].includes(formStatus);
  const isInAmhpInbox = formStatus && formStatus === FormStatus.s60_signed_in_amhp_team_inbox;
  const viewerIsNotFormCreator = !createdBy ? false : !(userId && userId === createdBy);
  const isExternalDoctor = userId && userId.substring(5, 0) === "Form-";
  const amhpLookingAtExternalDoctorForm =
    userId && userId === createdBy && !authors?.includes(userId) && authors?.some(a => a.startsWith("Form-"));

  if (isNotInProgress) {
    return true; // form is readonly (cannot be edited)
  }

  if (viewerIsNotFormCreator || amhpLookingAtExternalDoctorForm) {
    if (isExternalDoctor) {
      return false; // form is not readonly (can be edited)
    } else {
      return true; // form is readonly (cannot be edited)
    }
  }

  if (isInAmhpInbox) {
    return true; // form is in inbox (cannot be edited)
  }

  return false;
}

const styles = StyleSheet.create({
  flex: {
    flex: 1,
  },
  readonlyContainer: {
    alignSelf: "center",
    marginLeft: spacing[10],
  },
  readonlyMobileContainer: {
    width: "100%",
  },
});

export default MHAFormContainer;
