import React, { forwardRef } from "react";
import { Formik, FormikHelpers } from "formik";
import { View } from "react-native";
import * as Yup from "libs/validators/yup";

import "../../../utils/formValidation";
import { FormType, FormTypes } from "libs/types/mhaForms";
import { isAmhpForm, isConveyanceForm } from "libs/mhaForms/helpers";

import SendToDoctorFormFields from "./SendToDoctorFormFields";

type FormikSubmitHandler<V> = (value: SendToDoctorFormValues, actions: FormikHelpers<V>) => void;

export interface SendToDoctorFormValues {
  fullName: string;
  phoneOrEmail: string;
  formType: string;
  formId?: string;
  formStatus?: string;
}

interface PropTypes {
  existingJointFormsInS20?: {
    id: string;
    type: FormType;
    status: string;
    createdAt: string;
  }[];
  existingJointFormIsBeingCreatedOrUpdated: boolean;
  onSubmit: FormikSubmitHandler<SendToDoctorFormValues>;
  ref: any;
  useJointMedRecs: boolean;
}

const isPsuedoAmhpForm = (type: FormType) => isAmhpForm(type) || isConveyanceForm(type);
const VALID_FORM_TYPES = FormTypes.filter(value => !isPsuedoAmhpForm(value));

const validationSchema = Yup.object().shape<SendToDoctorFormValues>({
  fullName: Yup.string().required("Please enter the doctors full name"),
  phoneOrEmail: (Yup.string().required("Please enter a phone number or email address") as any).phoneOrEmail(
    "Please enter a valid phone number or email address"
  ),
  formType: Yup.string()
    .oneOf(VALID_FORM_TYPES)
    .required("Please select an MHA Form"),
});

const SendToDoctorForm = forwardRef((props: PropTypes, forwardedRef: (ref: any) => void) => {
  const {
    existingJointFormsInS20,
    existingJointFormIsBeingCreatedOrUpdated,
    onSubmit,
    useJointMedRecs,
    ...rest
  } = props;
  const initialValues: SendToDoctorFormValues = {
    formId: "",
    formType: "",
    formStatus: "",
    fullName: "",
    phoneOrEmail: "",
  };

  return (
    <View {...rest}>
      <Formik
        innerRef={forwardedRef}
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        validateOnBlur
      >
        <SendToDoctorFormFields
          existingJointFormsInS20={existingJointFormsInS20}
          existingJointFormIsBeingCreatedOrUpdated={existingJointFormIsBeingCreatedOrUpdated}
          useJointMedRecs={useJointMedRecs}
        />
      </Formik>
    </View>
  );
});

SendToDoctorForm.displayName = "SendToDoctorForm";

export default SendToDoctorForm;
