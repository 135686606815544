import { useCallback, useEffect, useRef } from "react";
import { AppState, Platform } from "react-native";
import useOnMount from "./useOnMount";

type VoidFunction = () => void;

/**
 * Run the given callback when the app is foregrounded.
 *
 * NOTE: Will not run on "web" devices, only native
 *
 * @param callback
 */
export default function useOnAppFocus(callback?: VoidFunction) {
  const savedCallback = useRef<VoidFunction>();

  const listener = useCallback(
    (appState: any) => {
      if (appState === "active" && savedCallback.current) {
        savedCallback.current();
      }
    },
    [savedCallback.current]
  );

  useEffect(() => {
    savedCallback.current = callback;
  });

  useOnMount(() => {
    if (Platform.OS !== "web") {
      AppState.addEventListener("change", listener);

      return () => AppState.removeEventListener("change", listener);
    }

    return () => null;
  });
}
