import React from "react";
import { StyleSheet } from "react-native";

import { TypographyType } from "@/models/Typography";
import { spacing, palette } from "../../../theme";
import Text from "../../Text";
import { TextInput } from "../../TextInput/TextInput";
import { MHATextInputProps } from "libs/types/mhaForms";
import { InfoItemLabel } from "../../InfoItem";

export const MHATextInput = React.memo(
  React.forwardRef((props: MHATextInputProps, ref: (ref: any) => void) => {
    const hasExternalDoctorCopy = Boolean(props.externalDoctorCopy);

    if (props.readonly) {
      return (
        <Text
          style={[styles.readonlyInput, !props.value && styles.shaded]}
          format={TypographyType.RegularBold}
          color={props.externalDoctorCopy && !props.value ? palette.red : palette.navy}
        >
          {props.value || props.externalDoctorCopy || "N/A"}
        </Text>
      );
    }

    if (hasExternalDoctorCopy) {
      return (
        <InfoItemLabel label={props.label || ""} format={TypographyType.RegularBold} color={palette.red}>
          {props.externalDoctorCopy}
        </InfoItemLabel>
      );
    }

    return <TextInput {...props} ref={ref} />;
  })
);

MHATextInput.displayName = "MHATextInput";

const styles = StyleSheet.create({
  readonlyInput: {
    backgroundColor: "white",
    paddingHorizontal: spacing[20],
    paddingVertical: spacing[15],
  },
  shaded: {
    backgroundColor: palette.cloud,
  },
});
