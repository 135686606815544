import React from "react";
import { Platform, StyleSheet } from "react-native";
import { useRecoilValue } from "recoil";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";

import BackButton from "@/components/BackButton/BackButton";
import PlainMap from "@/components/Map/PlainMap";
import QueryMap from "@/components/Map/QueryMap";
import { RedirectUser } from "@/components/RedirectUser";
import { useOnAppBlur } from "@/hooks";
import { RouteKeys } from "@/navigationv2";
import { BaseScreenProps, AllDoctorRoutes, AllAMHPRoutes, ScreenOptions } from "@/navigationv2/types";
import { createScreenTitle } from "@/navigationv2/utilities";
import { palette, spacing } from "@/theme";
import { mapCriteria as recoilMapCriteria } from "@/utils/recoil/index";

interface PropTypes extends BaseScreenProps<AllDoctorRoutes & AllAMHPRoutes, RouteKeys.MapScreen> {}

/**
 * WARNING
 * This MapScreen component is used across both the AMHP and the Doctor stacks.
 * This means that we need to provide both the Doctor and the AMHP routes to the
 * React Navigation typing system.
 *
 * If you are to implement a redirect to a specific screen from this component,
 * please perform the required `isDoctor` and `isAMHP` checks before choosing
 * the route.
 */
export default function MapScreen() {
  const navigation = useNavigation<StackNavigationProp<AllDoctorRoutes & AllAMHPRoutes>>();
  const mapCriteria = useRecoilValue(recoilMapCriteria);
  const displayPlainMap = mapCriteria?.single && mapCriteria?.markerLat && mapCriteria?.markerLon;
  const backButtonTopOffset = Platform.OS === "ios" && Platform.Version >= "11" ? spacing[30] : spacing[15];

  useOnAppBlur(navigation.goBack);

  if (!mapCriteria) {
    return <RedirectUser redirectTo={RouteKeys.AssessmentScreen} />;
  }

  const plainLocation = {
    lat: Number(mapCriteria.locationLat),
    lon: Number(mapCriteria.locationLon),
  };

  return (
    <>
      <BackButton enabled float={true} style={[styles.backButton, { top: backButtonTopOffset }]} />

      {displayPlainMap && (
        <PlainMap
          location={plainLocation}
          marker={{
            lat: Number(mapCriteria.markerLat),
            lon: Number(mapCriteria.markerLon),
          }}
          type={mapCriteria.type}
        />
      )}

      {!displayPlainMap && (
        <QueryMap
          assessmentId={mapCriteria.assessmentId}
          location={{
            lat: Number(mapCriteria.locationLat),
            lon: Number(mapCriteria.locationLon),
          }}
          distance={Number(mapCriteria.distance)}
        />
      )}
    </>
  );
}

const screenOptions: ScreenOptions = {
  title: createScreenTitle("Map"),
};

MapScreen.screenOptions = screenOptions;

const styles = StyleSheet.create({
  backButton: {
    height: 40,
    width: 40,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 20,
    backgroundColor: palette.blue,
  },
});
