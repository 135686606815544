import { LONG_DATE_FORMAT } from "libs/dates";
import { LocationType } from "libs/types/API";
import { amhpTeamVideoMeetings, amhpTeamUsesJointMedRecs, hasClaims, amhpTeamMHAForms } from "libs/utils/featureFlags";
import { FormTypes, FormType } from "libs/types/mhaForms";
import dayjs from "dayjs";
import React, { useState } from "react";
import { Linking, Platform, StyleSheet, View } from "react-native";
import openMap, { createMapLink } from "react-native-open-maps";

import { TypographyType } from "@/models/Typography";
import { spacing, palette } from "../../theme";
import { formatPhoneNumber } from "../../utils/helpers";
import { Button } from "../Button";
import { ContentWrap } from "../ContentWrap";
import { SplitSection } from "../SplitSection";
import { SectionItem } from "../DetailsSection/DetailsSection";
import { Location } from "../Location";
import ItemSpacer from "@/components/ItemSpacer";
import { RetrieveDataObject } from "../LocationSearch/LocationSearch.props";
import Text from "../Text";
import { DoctorForms } from "./DoctorForms";
import { IconName } from "../Icon";
import { InfoItemIcon, InfoItemLabel, InfoItemLabelList, InfoItemIconList } from "../InfoItem";
import { useCreateFormsBottomSheet } from "../Forms/MHACreateFormItem";
import { VisitDetailItem } from "@/models/DoctorProfile";
import { isAmhpForm, isJointForm } from "libs/mhaForms/helpers";
import { formDataToModel } from "@/models/Assessment";
import { formatAddress } from "libs/formatters/address";
import { ContactBox } from "../InfoBox";
import { recordEvent } from "../../utils/analytics";
import { Email as SquareEmailButton } from "../SquareButton";
import { AnalyticsEvent } from "libs/analytics/events";
import { useNavigateUserToMeetingRoom } from "../MeetingRoom/helpers";
import { isConveyanceForm } from "../../../../packages/libs/mhaForms/helpers/isAmhpForm";

import { useNavigation } from "@react-navigation/core";
import { StackNavigationProp } from "@react-navigation/stack";
import { AllAMHPRoutes } from "@/navigationv2/types";
import { RouteKeys } from "@/navigationv2";

export const VisitDetails = (props: { visit: VisitDetailItem; refetch: () => void }) => {
  const { visit, refetch } = props;

  const navigateUserToMeeting = useNavigateUserToMeetingRoom();
  const navigation = useNavigation<StackNavigationProp<AllAMHPRoutes>>();

  const {
    id,
    patientName,
    // assessmentDate,
    locationName,
    location,
    amhp,
    amhpTeam,
    patientAddress,
    forms,
    doctorVisits,
    ccg,
  } = visit.assessment;

  const [editMode, setEditMode] = useState(false);

  const [editedPatientAddress, setEditedPatientAddress] = useState<RetrieveDataObject>({
    location: {
      lat: 0,
      lon: 0,
    },
    description: "",
    locationName: {
      address: "",
      city: "",
      postcode: "",
    },
  });

  const {
    time,
    // notes
  } = visit;

  // TODO: Move into function outside of component
  const hasPaymentDetermination = !!props.visit.assessment.ccgDetermination;
  const paymentDetermination: SectionItem = {
    value: "",
    label: "CCG",
    secondaryValue: "",
  };
  if (hasPaymentDetermination && props.visit.assessment.ccg) {
    paymentDetermination.label += hasClaims(props.visit.assessment.ccg.featureFlags)
      ? " - On Platform"
      : " - Not on Platform";
    paymentDetermination.value = props.visit.assessment.ccg.name;
    paymentDetermination.secondaryValue =
      props.visit.assessment.ccgDetermination === LocationType.gPPractice
        ? "Determined by: GP Practice"
        : props.visit.assessment.ccgDetermination === LocationType.patientAddress
        ? "Determined by: Patient Address"
        : " Determined by: Assessment Location";
  }

  const formListItems = formDataToModel(forms);
  const assessmentAlreadyContainsASignleMedRec =
    formListItems.filter(f => !isAmhpForm(f.formType) && !isJointForm(f.formType)).length >= 1;
  const formTypes =
    !amhpTeamUsesJointMedRecs(amhpTeam.featureFlags) || assessmentAlreadyContainsASignleMedRec
      ? FormTypes.filter(f => !isAmhpForm(f)).filter(
          f => f !== FormType.A3 && f !== FormType.A7 && !isConveyanceForm(f) && f !== FormType.STATEMENT_OF_REASON
        ) // Remove option to create a joint form
      : FormTypes.filter(f => !isAmhpForm(f) && !isConveyanceForm(f) && f !== FormType.STATEMENT_OF_REASON); // Include options to create a joint form

  const AMHP_TEAM_HAS_ENABLED_FORMS = amhpTeamMHAForms(amhpTeam.featureFlags);

  const navigationParams = { assessmentId: id };
  const analyticsParams = {
    ahmpTeamId: amhpTeam.id,
    assessmentId: id,
  };

  const openBottomSheet = useCreateFormsBottomSheet(
    formTypes,
    navigationParams,
    analyticsParams,
    ccg?.featureFlags,
    doctorVisits?.items?.length
  );

  const videoMeetingButtonDisabled = Math.abs(dayjs(time).diff(dayjs(), "hour")) >= 24;

  const onMapLinkPress = () => {
    const params = {
      travelType: "drive",
      query: [locationName.address, locationName.city, locationName.postcode].filter(x => x).join(", "),
      latitude: location.lat,
      longitude: location.lon,
    };

    Platform.OS === "web" ? window.open(createMapLink(params), "_blank") : openMap(params);
  };

  const getFromByDoctor = (doctorId: string) => {
    return forms?.items?.length && forms.items.find(form => form?.createdBy === doctorId);
  };

  const AsideContent = (
    <>
      <Text format={TypographyType.HeadingMedium} style={{ marginBottom: spacing[20] }}>
        Visit Details
      </Text>

      <InfoItemLabelList>
        <InfoItemLabel label="Patient" format={TypographyType.Regular}>
          {patientName}
        </InfoItemLabel>
        {!editMode ? (
          editedPatientAddress.description !== "" ? (
            <InfoItemLabel
              label="Patient Address"
              format={TypographyType.Regular}
              style={{ marginBottom: spacing[20] }}
            >
              {editedPatientAddress.description}
            </InfoItemLabel>
          ) : (
            <></>
          )
        ) : (
          <>
            <Location
              hideIcon
              locationValue={editedPatientAddress.description}
              setLocationValue={setEditedPatientAddress}
            />
            <InfoItemIcon
              iconName={IconName.info_outline}
              iconColor={palette.greyBlue}
              iconPosition="flex-start"
              format={TypographyType.Small}
              color={palette.slate}
            >
              {`These details may later be updated by the AMHP associated with this visit.\n\nUpdated patient details will automatically be applied to all your MHA forms for this visit.`}
            </InfoItemIcon>
            <Button onPress={() => setEditMode(false)} style={styles.button}>
              Save
            </Button>
          </>
        )}
      </InfoItemLabelList>

      <InfoItemIconList>
        <InfoItemIcon iconName={IconName.watch_later} format={TypographyType.Small}>
          {dayjs(time).format("HH:mm")}
        </InfoItemIcon>
        <InfoItemIcon iconName={IconName.today} format={TypographyType.Small}>
          {dayjs(time).format(LONG_DATE_FORMAT)}
        </InfoItemIcon>
        <InfoItemIcon
          iconName={IconName.location_on}
          format={TypographyType.Small}
          iconPosition="flex-start"
          linkLabel="View On Map"
          linkOnPress={onMapLinkPress}
        >
          {formatAddress(locationName)}
        </InfoItemIcon>
      </InfoItemIconList>

      <View style={{ marginTop: spacing[10] }}>
        <InfoItemLabelList>
          {locationName.addressNotes && (
            <InfoItemLabel label="Location Notes" format={TypographyType.Regular}>
              {locationName.addressNotes}
            </InfoItemLabel>
          )}
          {visit.notes && (
            <InfoItemLabel label="Notes" format={TypographyType.Regular}>
              {visit.notes.join("\n")}
            </InfoItemLabel>
          )}
        </InfoItemLabelList>
      </View>

      {amhpTeamVideoMeetings(amhpTeam.featureFlags) && Platform.OS === "web" ? (
        <Button
          width="100%"
          marginTop={spacing[20]}
          marginBottom={0}
          onPress={() => navigateUserToMeeting(id, true)}
          disabled={videoMeetingButtonDisabled}
        >
          {videoMeetingButtonDisabled ? "Video Meeting Unavailable" : "Join Video Meeting"}
        </Button>
      ) : null}

      <Text format={TypographyType.HeadingSmall} style={{ marginTop: spacing[30], marginBottom: spacing[15] }}>
        AMHP
      </Text>

      <InfoItemLabelList>
        <InfoItemLabel label="Name" format={TypographyType.Regular}>
          {amhp.name}
        </InfoItemLabel>
        <InfoItemLabel label="Team" format={TypographyType.Regular} style={{ marginBottom: spacing[20] }}>
          {amhpTeam.name}
        </InfoItemLabel>
      </InfoItemLabelList>

      <InfoItemIconList>
        {amhp.phone && (
          <InfoItemIcon
            iconName={IconName.phone}
            format={TypographyType.Small}
            iconPosition="flex-start"
            linkLabel="Call AMHP"
            linkOnPress={() =>
              Linking.openURL(`${Platform.OS === "ios" ? "telprompt:" : "tel:"}${amhp.phone}`).catch(() => null)
            }
          >
            {formatPhoneNumber(amhp.phone)}
          </InfoItemIcon>
        )}
        {amhp.email && (
          <InfoItemIcon
            iconName={IconName.email}
            format={TypographyType.Small}
            iconPosition="flex-start"
            linkLabel="Send Email"
            linkOnPress={() => Linking.openURL(`mailto:${amhp.email}`).catch(() => null)}
          >
            {amhp.email}
          </InfoItemIcon>
        )}
      </InfoItemIconList>
      {doctorVisits?.items?.length && (
        <Text format={TypographyType.HeadingSmall} style={{ marginTop: spacing[30], marginBottom: spacing[15] }}>
          {/* Could say "Other doctors" here */}
          Doctors
        </Text>
      )}
      {doctorVisits?.items?.length &&
        doctorVisits.items
          .filter((item: any) => {
            const {
              doctor: { id },
            } = item;
            return id !== visit.doctorVisitDoctorId;
          })
          .map((visit: any, i: number) => {
            const signedForm = getFromByDoctor(visit.doctor.id);

            return (
              <View style={{ marginBottom: spacing[20] }} key={i}>
                <InfoItemLabelList>
                  <InfoItemLabel label="Name" format={TypographyType.Regular} style={{ marginBottom: spacing[20] }}>
                    {visit.doctor.name}
                  </InfoItemLabel>
                </InfoItemLabelList>

                <InfoItemIconList>
                  {visit.doctor.phone && (
                    <InfoItemIcon
                      iconName={IconName.phone}
                      format={TypographyType.Small}
                      iconPosition="flex-start"
                      linkLabel="Call Doctor"
                      linkOnPress={() =>
                        Linking.openURL(`${Platform.OS === "ios" ? "telprompt:" : "tel:"}${visit.doctor.phone}`).catch(
                          () => null
                        )
                      }
                    >
                      {formatPhoneNumber(visit.doctor.phone)}
                    </InfoItemIcon>
                  )}
                  {visit.doctor.email && (
                    <InfoItemIcon
                      iconName={IconName.email}
                      format={TypographyType.Small}
                      iconPosition="flex-start"
                      linkLabel="Send Email"
                      linkOnPress={() => Linking.openURL(`mailto:${visit.doctor.email}`).catch(() => null)}
                    >
                      {visit.doctor.email}
                    </InfoItemIcon>
                  )}
                  {signedForm && !["A3", "A7"].includes(signedForm.type) && (
                    <InfoItemIcon
                      iconName={IconName.description}
                      format={TypographyType.Small}
                      iconPosition="flex-start"
                      linkLabel="Preview form"
                      linkOnPress={() =>
                        navigation.push(RouteKeys.MHAFormScreen, {
                          assessmentId: id,
                          formType: signedForm?.type,
                          formId: signedForm?.id,
                        })
                      }
                    >
                      {["A4", "A8", "A11"].includes(signedForm.type)
                        ? `Medical Recommendation (${signedForm?.type})`
                        : signedForm?.type}
                    </InfoItemIcon>
                  )}
                </InfoItemIconList>
              </View>
            );
          })}
    </>
  );

  return AMHP_TEAM_HAS_ENABLED_FORMS ? (
    <SplitSection
      aside={AsideContent}
      main={
        <View>
          {editMode && <View style={styles.overlay} />}
          <DoctorForms
            assessmentId={id}
            visitId={visit.id}
            formRecords={formListItems.filter(
              fr =>
                (!isAmhpForm(fr.formType) && isJointForm(fr.formType)) ||
                (!isJointForm(fr.formType) && fr.createdBy === visit.doctorVisitDoctorId)
            )}
            createMHAForm={openBottomSheet}
            refetch={refetch}
            doctorId={visit.doctorVisitDoctorId}
            hasPatientAddress={!!patientAddress}
            featureFlags={amhpTeam?.featureFlags}
            navigationParam={navigationParams}
          />
        </View>
      }
    />
  ) : (
    <SplitSection
      aside={AsideContent}
      main={
        <View>
          <ContentWrap>
            <Text format={TypographyType.HeadingMedium}>Statutory MHA Forms</Text>
            <ItemSpacer gap={10}>
              <Text format={TypographyType.Small}>
                S12 Solutions enables AMHPs and doctors to submit statutory MHA forms electronically.
              </Text>
              <Text format={TypographyType.Small}>
                This feature isn’t enabled for the AMHP team associated with this assessment; please get in touch with
                the S12 Solutions team if you’d like to know more about digital statutory MHA form submission or to
                request support with local conversations about enabling this feature:
              </Text>
            </ItemSpacer>
            <ContactBox
              label="Email Address"
              data={`info@s12solutions.com`}
              lastInList={true}
              onPress={() =>
                // do we care if the mail app failed to open?
                Linking.openURL("mailto:info@s12solutions.com?subject=MHA%20Forms%20Enquiry")
                  .then(() => recordEvent(AnalyticsEvent.MHA_FORMS_ENQUIRY))
                  .catch(() => null)
              }
              icon={<SquareEmailButton />}
            />
          </ContentWrap>
        </View>
      }
    ></SplitSection>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: palette.white,
    opacity: 0.7,
    zIndex: 5,
  },
  button: {
    width: "100%",
    marginVertical: spacing[20],
  },
});
