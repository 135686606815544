import React from "react";
import { Animated, StyleSheet, TouchableOpacity, View } from "react-native";
import { color, spacing } from "../../theme";

export interface Swipe {
  icon: JSX.Element;
  color: string;
  onPress: () => void;
}

export const SwipeAction = (props: {
  direction: "left" | "right";
  dragX: any;
  swipeActions: Swipe[];
  fullWidth?: boolean;
}) => {
  const inputRange = props.direction === "left" ? [0, 70] : [-70, 0];
  const outputRange = props.direction === "left" ? [50, 0] : [0, -50];
  const translateX = props.dragX.interpolate({
    inputRange,
    outputRange,
    extrapolate: "clamp",
  });
  return (
    <View
      style={[
        styles.wrapper,
        !props.fullWidth
          ? {
              transform: [{ translateX: props.direction === "left" ? 6 : -6 }],
            }
          : {},
      ]}
    >
      {props.swipeActions.map((swipeAction: Swipe, i: number) => {
        const border = !props.fullWidth
          ? props.direction === "left"
            ? {
                borderTopLeftRadius: i === 0 ? 6 : 0,
                borderBottomLeftRadius: i === props.swipeActions.length - 1 ? 6 : 0,
              }
            : {
                borderTopRightRadius: i === 0 ? 6 : 0,
                borderBottomRightRadius: i === props.swipeActions.length - 1 ? 6 : 0,
              }
          : {};
        const Icon = () => swipeAction.icon;
        return (
          <TouchableOpacity
            key={i}
            onPress={swipeAction.onPress}
            style={[
              styles.button,
              {
                ...border,
                backgroundColor: swipeAction.color,
              },
              i !== props.swipeActions.length - 1 ? styles.divider : {},
            ]}
            activeOpacity={1}
          >
            <Animated.View
              style={[
                styles.button,
                {
                  transform: [{ translateX }],
                },
              ]}
            >
              <Icon />
            </Animated.View>
          </TouchableOpacity>
        );
      })}
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    width: 70,
    marginBottom: spacing[10],
    alignItems: "center",
  },
  button: {
    flex: 1,
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  divider: {
    borderBottomWidth: 1,
    borderBottomColor: color.textWhite,
  },
});
