import dayjs, { Dayjs } from "dayjs";
import React, { useCallback, useState, useEffect, useMemo } from "react";
import { StyleSheet, View, Platform, Keyboard } from "react-native";
import { useMutation } from "@apollo/react-hooks";
import { TextInput as RNPTextInput, TouchableRipple } from "react-native-paper";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { AnalyticsEvent } from "libs/analytics/events";
import { amhpShouldProvideNhsNumber } from "libs/utils/featureFlags";
import allLanguages from "libs/utils/languages";
import allSpecialties from "libs/utils/specialties";
import { UpdateAssessmentInput } from "libs/types/API";

import { useBottomSheet } from "@/hooks";
import { CreateAssessmentItem, UPDATE_ASSESSMENT, CREATE_ASSESSMENT } from "@/models/Assessment";
import { TypographyType } from "@/models/Typography";
import { buildErr, createAssessmentError, S12Error, updateAssessmentError } from "@/models/Error";
import { AllAMHPRoutes } from "@/navigationv2/types";
import { ScreenProps as SearchResultsScreenProps } from "@/screens/amhp/SearchResultsScreen";
import { color, palette, spacing } from "@/theme";
import { recordEvent } from "@/utils/analytics";
import { checkMutationInput, mqWeb, formatNhsNumber } from "@/utils/helpers";
import { userDetails, assessment as recoilAssessment, searchCriteria, lastException } from "@/utils/recoil/index";

import { CreateAssessmentFunc, CreateAssessmentState, UpdateAssessmentFunc } from "./CreateAssessment.props";
import { Button } from "../Button";
import { ContentWrap } from "../ContentWrap";
import DualDatePicker from "../DatePicker/DualDatePicker";
import Icon from "../Icon";
import { LocationSearchItem, RetrieveDataObject } from "../LocationSearch/LocationSearch.props";
import Select from "../Select";
import MultiSelect from "../Select/MultiSelect";
import DualInput from "../Slider/DualInput";
import DualTimepickerInput from "../Slider/DualTimepickerInput";
import Text from "../Text";
import { TextInput } from "../TextInput/TextInput";

interface TimeSpan {
  start: Dayjs;
  end: Dayjs;
}

function throttleChangeState(callback: (key: string, v: any) => void, delay: number) {
  let isThrottled = false;
  let args: any[] | undefined;

  function wrapper(...wrapperArgs: any[]) {
    if (isThrottled) {
      args = wrapperArgs;
      return;
    }

    isThrottled = true;
    callback(wrapperArgs[0], wrapperArgs[1]);

    setTimeout(() => {
      isThrottled = false;
      if (args) {
        wrapper(...args);
        args = undefined;
      }
    }, delay);
  }

  return wrapper;
}

const updateTimeSpanForAssessmentDate = (timeSpan: TimeSpan, date: Dayjs) => ({
  start: date
    .hour(6)
    .minute(0)
    .second(0),
  end: date
    .hour(6)
    .minute(0)
    .second(0)
    .add(timeSpan.end.diff(timeSpan.start, "hour", true), "hour"),
});

const updateTimeSpanForStartTime = (timeSpan: TimeSpan, startHour: any) => {
  return {
    start: timeSpan.start
      .hour(startHour)
      .minute(0)
      .second(0),
    end: timeSpan.start
      .hour(startHour)
      .minute(0)
      .second(0)
      .add(timeSpan.end.diff(timeSpan.start, "hour", true), "hour"),
  };
};

const updateTimeSpanForDuration = (timeSpan: TimeSpan, duration: number) => ({
  start: timeSpan.start,
  end: timeSpan.start.add(duration, "hour"),
});

const isAssessmentUpdated = (assessment: CreateAssessmentItem | undefined, assessmentData: CreateAssessmentState) => {
  return (
    assessment &&
    (assessment.assessmentDate !== dayjs(assessmentData.assessmentDate).format("YYYY-MM-DD") ||
      assessment.locationName.address !== assessmentData.locationName.address ||
      assessment.locationName.postcode !== assessmentData.locationName.postcode ||
      assessment.amhp.id !== assessmentData.amhpId)
  );
};

const saveData = async (
  setLastException: (arg0: S12Error) => void,
  assessment: CreateAssessmentItem | undefined,
  assessmentData: CreateAssessmentState,
  setAssessmentData: (arg0: CreateAssessmentState) => void,
  setSearchCriteria: (arg0: SearchResultsScreenProps) => void,
  createAssessment: CreateAssessmentFunc,
  updateAssessment: UpdateAssessmentFunc | undefined,
  teamId: string
) => {
  const {
    patientName,
    patientNhsNumber,
    location,
    locationName,
    assessmentDate,
    doctorGender,
    doctorLanguages,
    doctorSpecialties,
    doctorName,
    amhpId,
    distance,
    timeSpan,
    ccgId,
  } = assessmentData;

  // TODO: handle error case.
  type SetSearchData = (value: void | { data?: { assessment?: { id: string } } }) => string | Promise<string>;

  const setSearchData: SetSearchData = value => {
    if (value && value.data && value.data.assessment && value.data.assessment.id) {
      // we set state in case of back button use, we can persist that the user has saved the assessment
      setAssessmentData({
        ...assessmentData,
        assessmentId: value.data.assessment.id,
      });
    } else {
      const error = new S12Error(createAssessmentError);
      setLastException(error);
      return Promise.reject(error);
    }
    setSearchCriteria({
      assessment: value && value.data && value.data.assessment ? { id: value.data.assessment.id } : undefined,
      location,
      locationName: locationName.address,
      timeSpan,
      distance,
      assessmentDate: assessmentDate.toISOString(),
      doctorGender,
      doctorLanguages,
      doctorSpecialties,
      doctorName,
    } as SearchResultsScreenProps);
    return (value.data.assessment as { id: string }).id;
  };

  if (!assessment || assessment.id === "-") {
    // new assessment of duplicate assessment
    return createAssessment({
      variables: {
        input: checkMutationInput({
          patientName,
          patientNhsNumber,
          location,
          locationName,
          ccgId,
          assessmentDate: dayjs(assessmentDate).format("YYYY-MM-DD"),
          amhpId: amhpId,
          amhpTeamId: teamId,
          createdAt: new Date().toISOString(),
        }),
      },
    })
      .then(setSearchData)
      .then((assessmentId: string) => {
        recordEvent(AnalyticsEvent.ASSESSMENT_CREATE, {
          locationName: locationName.postcode,
          location: `${location.lat},${location.lon}`,
          ...(assessmentData.doctorName && {
            doctorName: assessmentData.doctorName,
          }),
          ...(assessmentData.doctorGender && {
            doctorGender: assessmentData.doctorGender,
          }),
          ...(assessmentData.doctorLanguages &&
            assessmentData.doctorLanguages.length && {
              doctorLanguages: assessmentData.doctorLanguages.join(","),
            }),
          ...(assessmentData.doctorSpecialties &&
            assessmentData.doctorSpecialties.length && {
              doctorSpecialties: assessmentData.doctorSpecialties.join(","),
            }),
          amhpId,
          teamId,
          assessmentId: assessmentId,
        });
        return assessmentId;
      })
      .catch(buildErr(createAssessmentError, setLastException));
  } else if (updateAssessment && isAssessmentUpdated(assessment, assessmentData)) {
    // update assessment
    return updateAssessment({
      variables: {
        input: checkMutationInput<UpdateAssessmentInput>({
          id: assessment.id,
          patientNhsNumber,
          location,
          locationName,
          ...(assessment.locationName !== assessmentData.locationName && {
            ccgId: ccgId,
          }),
          assessmentDate: dayjs(assessmentDate).format("YYYY-MM-DD"),
          amhpId: amhpId,
        }),
      },
    })
      .then(setSearchData)
      .then(() => {
        recordEvent(AnalyticsEvent.ASSESSMENT_UPDATED, {
          locationName: locationName.postcode,
          location: `${location.lat},${location.lon}`,
          ...(assessmentData.doctorName && {
            doctorName: assessmentData.doctorName,
          }),
          ...(assessmentData.doctorGender && {
            doctorGender: assessmentData.doctorGender,
          }),
          ...(assessmentData.doctorLanguages &&
            assessmentData.doctorLanguages.length && {
              doctorLanguages: assessmentData.doctorLanguages.join(","),
            }),
          ...(assessmentData.doctorSpecialties &&
            assessmentData.doctorSpecialties.length && {
              doctorSpecialties: assessmentData.doctorSpecialties.join(","),
            }),
          amhpId,
          teamId,
          assessmentId: assessment.id,
        });
        return assessment.id;
      })
      .catch(
        buildErr(
          {
            ...updateAssessmentError,
            additional: assessment.id,
          },
          setLastException
        )
      );
  } else {
    setSearchData({
      data: {
        assessment,
      },
    });
    recordEvent(AnalyticsEvent.ASSESSMENT_UPDATED, {
      locationName: locationName.postcode,
      location: `${location.lat},${location.lon}`,
      ...(assessmentData.doctorName && {
        doctorName: assessmentData.doctorName,
      }),
      ...(assessmentData.doctorGender && {
        doctorGender: assessmentData.doctorGender,
      }),
      ...(assessmentData.doctorLanguages &&
        assessmentData.doctorLanguages.length && {
          doctorLanguages: assessmentData.doctorLanguages.join(","),
        }),
      ...(assessmentData.doctorSpecialties &&
        assessmentData.doctorSpecialties.length && {
          doctorSpecialties: assessmentData.doctorSpecialties.join(","),
        }),
      amhpId,
      teamId,
      assessmentId: assessment.id,
    });
    return assessment.id;
  }
};

const INITIAL_STATE: CreateAssessmentState = {
  patientName: "", // TODO: CHECK
  patientNhsNumber: "",
  location: {
    lat: 0,
    lon: 0,
  },
  locationName: {
    city: "",
    postcode: "",
    address: "",
    addressNotes: null,
  },
  ccgId: "",
  timeSpan: {
    start: dayjs(),
    end: dayjs().add(4, "hour"),
  },
  distance: 40,
  assessmentDate: dayjs(),
  doctorLanguages: [],
  doctorSpecialties: [],
  doctorGender: null,
  amhpId: "-1",
  amhpName: "",
  showAutocomplete: false,
  assessmentId: undefined,
};

const assessmentPropsToState = (
  assessment: CreateAssessmentItem | null | undefined,
  state: CreateAssessmentState,
  user?: { id?: string } | null
) => {
  const assessmentDate = assessment ? dayjs(assessment.assessmentDate) : dayjs();

  return Object.assign(
    {},

    state,
    {
      amhpId: (user && user.id) || "-1",
    },
    assessment && {
      assessmentId: assessment.id,
      ...assessment,
      assessmentDate: assessmentDate.diff(dayjs()) < 0 ? dayjs() : assessmentDate,
      amhpName: assessment.amhp.name,
      amhpId: (assessment.amhp && assessment.amhp.id) || (user && user.id) || "-1", // TODO: update spec with id AND name
      // if the assessment date is today or in the past
      timeSpan:
        dayjs().isSame(assessmentDate, "day") || assessmentDate.diff(dayjs()) < 0
          ? // then start the search on today's date and time
            // and end it 4 hours fom now
            updateTimeSpanForStartTime(
              {
                start: dayjs(),
                end: dayjs().add(4, "hour"),
              },
              new Date().getHours()
            )
          : // but if the assessment date is one or more days from now
            // set the start hour to 6am and search for the next 8 hours by default
            {
              end: assessmentDate.hour(6).add(8, "hour"),
              start: assessmentDate.hour(6),
            },
    }
  );
};

interface PropTypes {
  assessmentId?: string;
  assessment?: CreateAssessmentItem;
  myId?: string;
  teamId?: string;
  teamDisplayName?: string;
  featureFlags?: string;
  teamMembers?: { id: string; name: string }[];
  commonAddresses: LocationSearchItem[];
  startHour?: number;
  editDoctor?: boolean;
}

export function CreateAssessment(props: PropTypes) {
  const user = useRecoilValue(userDetails);
  const assessment = useRecoilValue(recoilAssessment);
  const searchCriteriaValus = useRecoilValue(searchCriteria);
  const setAssessment = useSetRecoilState(recoilAssessment);
  const setSearchCriteria = useSetRecoilState(searchCriteria);
  const setLastException = useSetRecoilState(lastException);
  const navigation = useNavigation<StackNavigationProp<AllAMHPRoutes>>();

  const isWebView = mqWeb();

  const dateInput = React.createRef<RNPTextInput>();
  const nhsNumberRequired = amhpShouldProvideNhsNumber(props?.featureFlags || "{}");

  const [createAssessment, { loading }] = useMutation(CREATE_ASSESSMENT);
  const [updateAssessment] = useMutation(UPDATE_ASSESSMENT);

  const [assessmentData, setAssessmentData] = useState<CreateAssessmentState>(() => {
    return assessmentPropsToState(
      props.assessment,
      {
        ...INITIAL_STATE,
        ...(props.assessmentId && {
          ...assessment,
          assessmentDate: assessment ? assessment.assessmentDate : dayjs(),
          timeSpan: updateTimeSpanForStartTime(
            assessment
              ? assessment.timeSpan
              : {
                  start: dayjs(),
                  end: dayjs().add(4, "hour"),
                },
            assessment ? assessment.timeSpan.start.hour() : new Date().getHours()
          ),
        }),
      },
      user
    );
  });

  // don't save on main object for performance reasons, textinput is a controlled component
  const [patientName, setPatientName] = useState(assessmentData.patientName);
  const [doctorName, setDoctorName] = useState(assessmentData.doctorName || "");
  const [findDoc, setFindDoc] = useState(false);
  const [suffix, setSuffix] = useState(":00");
  const [searchDoctorLanguages, setSearchDoctorLanguages] = useState(
    assessmentData && assessmentData.assessmentId && searchCriteriaValus && searchCriteriaValus.doctorLanguages
      ? searchCriteriaValus.doctorLanguages
      : assessmentData.doctorLanguages
  );
  const [searchDoctorSpecialties, setSearchDoctorSpecialties] = useState(
    assessmentData && assessmentData.assessmentId && searchCriteriaValus && searchCriteriaValus.doctorSpecialties
      ? searchCriteriaValus.doctorSpecialties
      : assessmentData.doctorSpecialties
  );
  const [searchDoctorGender, setSearchDoctorGender] = useState(
    searchCriteriaValus && searchCriteriaValus.doctorGender
      ? searchCriteriaValus.doctorGender
      : assessmentData.doctorGender
  );
  const [addressNotes, setAddressNotes] = useState(
    assessmentData.locationName.addressNotes === null ? undefined : assessmentData.locationName.addressNotes
  );

  const patientNameInput = React.useRef<any>();
  const locationNameInput = React.useRef<any>();
  const doctorNameInput = React.useRef<any>();
  const [nhsNumber, setNhsNumber] = useState(assessmentData.patientNhsNumber || "");

  useEffect(() => {
    setAssessmentData(assessmentPropsToState(props.assessment, assessmentData, user));
  }, [props.assessment]);

  useEffect(() => {
    setAssessment(assessmentData);
    if (assessmentData && assessmentData.assessmentId && !props.editDoctor) {
      if (searchCriteriaValus && searchCriteriaValus.doctorName) {
        if (findDoc && assessmentData.doctorName !== undefined) {
          setDoctorName(assessmentData.doctorName);
        } else {
          setDoctorName(searchCriteriaValus.doctorName);
        }
      }
      if (
        searchCriteriaValus &&
        searchCriteriaValus.doctorLanguages &&
        searchCriteriaValus.doctorLanguages.length !== 0
      ) {
        if (findDoc) {
          setSearchDoctorLanguages(assessmentData.doctorLanguages);
        } else {
          setSearchDoctorLanguages(searchCriteriaValus.doctorLanguages);
        }
      }
      if (
        searchCriteriaValus &&
        searchCriteriaValus.doctorSpecialties &&
        searchCriteriaValus.doctorSpecialties.length !== 0
      ) {
        if (findDoc) {
          setSearchDoctorSpecialties(assessmentData.doctorSpecialties);
        } else {
          setSearchDoctorSpecialties(searchCriteriaValus.doctorSpecialties);
        }
      }
      if (searchCriteriaValus && searchCriteriaValus.doctorGender && searchCriteriaValus.doctorGender !== null) {
        if (findDoc) {
          setSearchDoctorGender(assessmentData.doctorGender);
        } else {
          setSearchDoctorGender(searchCriteriaValus.doctorGender);
        }
      }
    }
    if (props.assessment === undefined || (props.editDoctor && !findDoc)) {
      setSearchDoctorLanguages([]);
      setSearchDoctorSpecialties([]);
      setSearchDoctorGender(null);
      setDoctorName("");
    }
  }, [assessmentData]);

  useEffect(() => {
    if (assessmentData.assessmentId === undefined || props.editDoctor) {
      setSearchDoctorLanguages([]);
      setSearchDoctorSpecialties([]);
      setSearchDoctorGender(null);
      setDoctorName("");
    }
  }, [props.editDoctor]);

  const submitText = () => {
    setTimeout(
      () =>
        setAssessmentData({
          ...assessmentData,
          patientName,
          patientNhsNumber: nhsNumber.replace(/\s/g, ""),
          doctorName,
          locationName: {
            ...assessmentData.locationName,
            addressNotes: addressNotes,
          },
        }),
      100
    );
    Keyboard.dismiss();
  };

  const onButtonPress = () => {
    setFindDoc(true);
    props.teamId &&
      saveData(
        setLastException,
        props.assessment,
        {
          ...assessmentData,
          patientName,
          patientNhsNumber: nhsNumber.replace(/\s/g, ""),
          doctorName,
          doctorLanguages: searchDoctorLanguages,
          doctorSpecialties: searchDoctorSpecialties,
          doctorGender: searchDoctorGender,
          locationName: {
            ...assessmentData.locationName,
            addressNotes: addressNotes,
          },
        },
        setAssessmentData,
        setSearchCriteria,
        createAssessment,
        updateAssessment,
        props.teamId
      )
        .then((assessmentId: string) => {
          navigation.setParams({ assessmentId });
          navigation.navigate("SearchResultsScreen");
        })
        .catch(buildErr(createAssessmentError, setLastException));
  };

  const {
    distance,
    amhpId,
    locationName,
    assessmentDate,
    timeSpan,
    doctorLanguages,
    doctorGender,
    doctorSpecialties,
    showAutocomplete,
    // doctor name and patient name not set here
  } = assessmentData;

  const formValid =
    amhpId.trim() !== "" &&
    patientName &&
    patientName.trim() !== "" &&
    locationName.address &&
    locationName.address.trim() !== "";

  // this function cannot use any values from the outer function
  // for the useCallbacks that wrap it to work
  const onChangeState = useCallback<(k: string, v: any) => void>(
    throttleChangeState((k: string, v: any) => {
      if (k === "assessmentDate") {
        setAssessmentData(prevState => ({
          ...prevState,
          assessmentDate: v,
          timeSpan: updateTimeSpanForAssessmentDate(prevState.timeSpan, v),
        }));
      } else if (k === "doctorGender") {
        setSearchDoctorGender(v);
      } else if (k === "doctorLanguages") {
        setSearchDoctorLanguages(v);
      } else if (k === "doctorSpecialties") {
        setSearchDoctorSpecialties(v);
      } else {
        setAssessmentData(prevState => {
          return {
            ...prevState,
            [k]: v,
          };
        });
      }
    }, 60),
    []
  );

  const locationNameRender = React.useCallback(
    renderProps => {
      const { style, value } = renderProps;
      return (
        <TouchableRipple
          onPress={() => {
            Keyboard.dismiss();
            setAssessmentData({
              ...assessmentData,
              showAutocomplete: true,
            });
          }}
          testID={"LocationInputRipple"}
        >
          <Text format={TypographyType.Regular} style={style}>
            {value}
          </Text>
        </TouchableRipple>
      );
    },
    [assessmentData]
  );

  const onNhsNumberChange = (value: string) => {
    setNhsNumber(formatNhsNumber(value));
  };

  const { openBottomSheet, closeBottomSheet } = useBottomSheet();
  useEffect(() => {
    if (showAutocomplete) {
      openBottomSheet({
        type: "locationSearch",
        data: {
          initialQuery: "",
          initialResults: props.commonAddresses,
          onDismiss: () => setAssessmentData({ ...assessmentData, showAutocomplete: false }),
          retrieveData: (data: RetrieveDataObject) => {
            setAssessmentData({
              ...assessmentData,
              location: data.location,
              locationName: data.locationName,
              ccgId: data.ccg ? data.ccg.id : "XXX",
              showAutocomplete: false,
            });
          },
        },
      });
    } else {
      closeBottomSheet();
    }
  }, [showAutocomplete, assessmentData, props.commonAddresses]);

  return (
    <>
      <View style={styles.mandatoryForm}>
        <ContentWrap style={styles.mandatoryForm}>
          <Text format={TypographyType.HeadingMedium}>Add Details</Text>
          {!!props.teamDisplayName && (
            <View style={styles.teamContainer}>
              <Icon name="group" size={24} color={color.textExtraLight} style={styles.teamIcon} />
              <View style={styles.teamText}>
                <Text format={TypographyType.Micro} color={palette.greyBlue}>
                  Team
                </Text>
                <Text format={TypographyType.Regular}>{props.teamDisplayName}</Text>
              </View>
            </View>
          )}
          <View style={[styles.requiredInputContainer, !isWebView && styles.assignedAMHPMobile]}>
            <Select
              label="Assigned AMHP"
              icon="person-outline"
              value={props.teamMembers && props.teamMembers.length ? amhpId : ""}
              onBlur={() => setTimeout(() => patientNameInput.current && patientNameInput.current.focus(), 20)}
              onValueChange={useCallback((_v, i, d: [{ value: string; label: string }]) => {
                onChangeState("amhpId", d[i].value);
                onChangeState("amhpName", d[i].label);
              }, [])}
              options={props.teamMembers ? props.teamMembers : []}
            />
          </View>
          <TextInput
            icon="contacts"
            ref={patientNameInput}
            label="Patient's full Legal Name"
            autoCorrect={false}
            returnKeyType="next"
            disabled={(!!props.assessment && props.assessment.id !== "-") || !!props.assessmentId}
            onSubmitEditing={submitText}
            value={patientName}
            onChangeText={setPatientName}
            testID="CreateAssessment__patient-name__input"
          />

          {!!nhsNumberRequired && (
            <TextInput
              icon="local-hospital"
              keyboardType="number-pad"
              label="Patient's NHS number"
              value={nhsNumber}
              maxLength={12}
              onChangeText={onNhsNumberChange}
            />
          )}

          <TextInput
            ref={locationNameInput}
            icon={"location-on"}
            label="Assessment Location"
            value={
              locationName.address ? locationName.address + (locationName.city ? ", " + locationName.city : "") : ""
            }
            render={locationNameRender}
            testID="CreateAssessment__location-name__input"
          />
          <TextInput
            icon="note"
            label="Optional Location Notes"
            autoCorrect={true}
            spellCheck={true}
            returnKeyType="go"
            value={addressNotes}
            multiline={true}
            selectTextOnFocus={true}
            numberOfLines={3}
            supplementaryInfo="Add more information if required, e.g. Ward Name"
            onChangeText={setAddressNotes}
          />
          <Text format={TypographyType.RegularBold}>Date</Text>
          <DualDatePicker
            ref={dateInput}
            max={dayjs()
              .add(28, "day")
              .toDate()}
            min={dayjs(assessmentDate)
              .subtract(30, "day")
              .toDate()}
            value={assessmentDate}
            onValueChange={useCallback(value => onChangeState("assessmentDate", value), [])}
          />
        </ContentWrap>
      </View>

      <View style={styles.optionalForm}>
        <View style={styles.searchSettings}>
          <ContentWrap>
            <Text format={TypographyType.HeadingMedium}>Search Settings</Text>
            <Text format={TypographyType.RegularBold} style={styles.searchSettingsHeading}>
              Search Radius
            </Text>
            {/* eslint-disable-next-line */}
            <View style={styles.mbottom20}>
              <DualInput
                label="Within"
                unitLabel="Miles"
                value={Math.floor(distance)}
                onValueChange={useCallback(v => onChangeState("distance", v), [])}
                step={1}
                min={1}
                max={100}
              />
            </View>

            <Text format={TypographyType.RegularBold} style={styles.searchSettingsHeading}>
              Doctor Availability
            </Text>

            <DualInput
              label="From"
              unitLabel="Time"
              value={timeSpan.start.hour()}
              onValueChange={useCallback(
                v => {
                  if (v === 999) {
                    setSuffix("");
                    onChangeState("timeSpan", updateTimeSpanForStartTime(timeSpan, Number(0)));
                  } else {
                    onChangeState("timeSpan", updateTimeSpanForStartTime(timeSpan, Number(v)));
                    setTimeout(() => {
                      setSuffix(":00");
                    }, 1500);
                  }
                },
                [timeSpan]
              )}
              step={1}
              min={0}
              max={23}
              displaySuffix={suffix}
            />

            <DualInput
              label="Within"
              unitLabel="Hours"
              value={Math.floor(dayjs(timeSpan.end).diff(timeSpan.start, "hour", true))}
              onValueChange={useCallback(
                v => onChangeState("timeSpan", updateTimeSpanForDuration(timeSpan, Number(v))),
                [timeSpan]
              )}
              step={1}
              min={1}
              max={24}
            />
          </ContentWrap>
        </View>

        <ContentWrap style={styles.doctorAttributes}>
          <Text format={TypographyType.RegularBold}>(Optional)</Text>
          <Text format={TypographyType.HeadingMedium}>Doctor Attributes</Text>
          <MultiSelect
            options={useMemo(() => allLanguages, [])}
            value={props.editDoctor && !findDoc ? assessmentData.doctorLanguages : searchDoctorLanguages}
            label="Languages"
            icon="language"
            style={styles.languages}
            onValueChange={useCallback((values: string[]) => onChangeState("doctorLanguages", values), [])}
          />
          <MultiSelect
            options={useMemo(() => allSpecialties, [])}
            value={props.editDoctor && !findDoc ? assessmentData.doctorSpecialties : searchDoctorSpecialties}
            style={styles.specialties}
            icon="work"
            label="Specialisms"
            onValueChange={useCallback((values: string[]) => {
              onChangeState("doctorSpecialties", values);
            }, [])}
          />
          <View style={styles.requiredInputContainer}>
            <Select
              icon="wc"
              label="Gender"
              value={searchDoctorGender || ""}
              onFocus={() => Keyboard.dismiss()}
              onValueChange={useCallback((value: string) => {
                onChangeState("doctorGender", value || null);
              }, [])}
              options={[
                { name: "- Select -", id: "" },
                { name: "Female", id: "F" },
                { name: "Male", id: "M" },
              ]}
            />
          </View>

          <View style={styles.requiredInputContainer}>
            <TextInput
              icon="contacts"
              ref={doctorNameInput}
              label="Doctor name"
              autoCorrect={false}
              returnKeyType="done"
              onSubmitEditing={submitText}
              value={doctorName}
              onChangeText={setDoctorName}
              onBlur={() => doctorName !== undefined && setDoctorName(doctorName.trim())}
            />
          </View>
          <Button
            onPress={onButtonPress}
            disabled={!formValid || loading}
            style={styles.findDoctorsBottomButton}
            testID="Find-Doctors__Button"
          >
            {loading ? "Loading..." : "Find Doctors"}
          </Button>
        </ContentWrap>
      </View>
    </>
  );
}
export default CreateAssessment;

const styles = StyleSheet.create({
  assignedAMHPMobile: {
    marginBottom: spacing[30],
  },

  findDoctorsBottomButton: {
    marginTop: spacing[20],
    marginBottom: 0,
  },
  languages: {
    zIndex: (Platform.OS !== "web" && 2) || undefined,
  },
  specialties: {
    zIndex: (Platform.OS !== "web" && 1) || undefined,
  },
  mandatoryForm: {
    paddingVertical: 0,
    backgroundColor: color.background,
  },
  optionalForm: {
    paddingVertical: 0,
    backgroundColor: color.background,
  },
  doctorAttributes: {
    backgroundColor: color.background,
  },
  requiredInputContainer: {
    flexDirection: "row",
    flex: 1,
    justifyContent: "center",
    alignItems: "stretch",
  },
  searchSettings: {
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: color.thinBorder,
    paddingVertical: spacing[50],
    marginVertical: spacing[50],
    backgroundColor: color.backgroundGrey,
  },
  searchSettingsHeading: {
    marginBottom: spacing[5],
  },
  mbottom20: { marginBottom: 20 },

  teamContainer: {
    flexDirection: "row",
    alignItems: "flex-end",
    marginBottom: spacing[30],
  },
  teamText: {
    flexDirection: "column",
  },
  teamIcon: {
    marginRight: spacing[20],
  },
});
