import React from "react";
import { Button, ButtonList } from "@/components/Button";
import { StyleSheet, View, TouchableOpacity } from "react-native";
import { palette, spacing } from "@/theme";
import Text from "../Text";
import { Mod } from "@/components/GPSearch/modules";
import { TypographyType } from "@/models/Typography";
import LocationSearch from "@/components/LocationSearch";
import { LocationSearchItem } from "@/components/LocationSearch/LocationSearch.props";

interface AddressSearchWithCCGProps {
  searchQuery: string;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
  searchResults: LocationSearchItem[];
  setSearchResults: React.Dispatch<React.SetStateAction<LocationSearchItem[]>>;
  initialResults?: LocationSearchItem[];
  retrieveData(data: unknown): void;
  handleConfirm: (data: unknown) => void;
}
const AddressSearchWithCCG: React.FC<AddressSearchWithCCGProps> = props => {
  const {
    searchQuery,
    setSearchQuery,
    searchResults,
    setSearchResults,
    initialResults,
    retrieveData,
    handleConfirm,
  } = props;

  const clearResults = () => {
    setSearchQuery("");
    setSearchResults([]);
  };

  return (
    // A retriveData handler has been added to Sheet.tsx. This checks for the two methods of displaying LocationSearch.
    // Where the withConfirm flag is true, we will save the results of the search and wait for confirmation. Otherwise we
    // will return immediately with the results.
    <>
      <LocationSearch
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        searchResults={searchResults}
        setSearchResults={setSearchResults}
        initialResults={initialResults}
        retrieveData={retrieveData}
      />

      <View style={styles.confirmationScreen}>
        {!!searchResults && searchResults.length > 0 && (
          <>
            {searchResults[0].locationName && (
              <View>
                <Text format={TypographyType.HeadingSmall}>Confirm Selection</Text>
                <View style={styles.textRow}>
                  <Text format={TypographyType.Regular}>You have selected the following address: </Text>
                </View>
                <Mod.Result
                  result={{
                    id: searchResults[0].id,
                    roleId: "",
                    name: searchResults[0].locationName.address ? searchResults[0].locationName.address : "",
                    postcode: searchResults[0].locationName.postcode,
                  }}
                  readOnly
                />
              </View>
            )}
            {searchResults[0].ccg && (
              <View>
                <View style={styles.textRow}>
                  <Text format={TypographyType.Regular}>which belongs to the </Text>
                  <Text format={TypographyType.RegularBold}>CCG</Text>
                  <Text format={TypographyType.Regular}>:</Text>
                </View>
                <Mod.Result
                  result={{
                    id: searchResults[0].ccg.id,
                    roleId: "",
                    name: searchResults[0].ccg.name,
                    postcode: "",
                  }}
                  readOnly
                />
                <ButtonList>
                  <Button onPress={() => handleConfirm(searchResults)} width="100%">
                    Confirm
                  </Button>
                  <TouchableOpacity onPress={clearResults}>
                    <Text format={TypographyType.SmallBold} color={palette.blue} align="center">
                      Search Again
                    </Text>
                  </TouchableOpacity>
                </ButtonList>
              </View>
            )}
          </>
        )}
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  confirmationScreen: {
    padding: spacing[30],
  },

  results: {
    paddingVertical: spacing[30],
  },
  resultsLabels: {
    paddingHorizontal: spacing[30],
  },

  resultsHeading: {
    marginBottom: spacing[10],
  },

  searchingText: {
    marginTop: spacing[15],
  },

  textRow: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: spacing[20],
    marginBottom: spacing[10],
  },
});

export default AddressSearchWithCCG;
