import React, { forwardRef, useImperativeHandle, useState } from "react";
import { StyleSheet, View, Text, LayoutChangeEvent, Platform } from "react-native";
import { TouchableRipple } from "react-native-paper";
import { BottomTabBarProps } from "@react-navigation/bottom-tabs";
import { Route } from "@react-navigation/native";

import { useDeviceWidth } from "@/hooks";
import { palette, color } from "@/theme";

interface PropTypes extends BottomTabBarProps {
  items: Route<string>[];
  tabBarHeight: number;
}

export interface NestedItemsHandle {
  height: number;
}

const NestedItems = forwardRef<NestedItemsHandle, PropTypes>((props, forwardedRef) => {
  const { descriptors, items, navigation, state, tabBarHeight } = props;
  const deviceWidth = useDeviceWidth();
  const [height, setHeight] = useState(0);

  const onItemPress = (route: Route<string>, isFocused: boolean) => {
    const customEvent = navigation.emit({
      type: "tabPress",
      target: route.key,
      canPreventDefault: true,
    });

    if (!isFocused && !customEvent.defaultPrevented) {
      navigation.navigate(route.name);
    }
  };

  const onLayout = (event: LayoutChangeEvent) => {
    setHeight(event.nativeEvent.layout.height);
  };

  useImperativeHandle(forwardedRef, () => {
    return { height };
  });

  return (
    <View
      onLayout={onLayout}
      style={[styles.container, state.index >= 5 && styles.containerInactive, { top: tabBarHeight }]}
    >
      {items.map((route, index) => {
        const isFocused = index === state.index;
        const descriptor = descriptors[route.key];
        const { options } = descriptor;

        const label =
          options.tabBarLabel !== undefined
            ? options.tabBarLabel
            : options.title !== undefined
            ? options.title
            : route.name;

        return (
          <TouchableRipple
            key={index}
            style={[styles.item, isFocused && styles.itemCurrent]}
            rippleColor={index <= 4 ? color.rippleWhiteOpacity : color.rippleWhite}
            underlayColor={index <= 4 ? color.rippleWhiteOpacity : color.rippleWhite}
            disabled={isFocused}
            onPress={() => onItemPress(route, isFocused)}
          >
            <>
              {options?.tabBarIcon && (
                <View style={styles.iconContainer}>
                  {options.tabBarIcon({
                    color: state.index < 5 ? palette.white : palette.greyBlue,
                    focused: isFocused,
                    size: 22,
                  })}
                </View>
              )}
              <Text style={[styles.label, state.index >= 5 && styles.labelInactive]}>{label}</Text>
              <View style={[styles.border, state.index >= 5 && styles.borderInactive, { width: deviceWidth - 80 }]} />
            </>
          </TouchableRipple>
        );
      })}
    </View>
  );
});

const styles = StyleSheet.create({
  container: {
    backgroundColor: palette.blue,
    left: 0,
    position: "absolute",
    width: "100%",
  },

  containerInactive: {
    backgroundColor: palette.cloud,
  },

  border: {
    position: "absolute",
    left: 40,
    bottom: 0,
    height: 1,
    backgroundColor: color.linkBorder,
  },

  borderInactive: {
    backgroundColor: palette.grey,
  },

  iconContainer: {
    marginRight: 10,
  },

  item: {
    alignItems: "center",
    flexDirection: "row",
    height: 50,
    paddingHorizontal: 40,
    position: "relative",
    ...Platform.select({
      web: {
        cursor: "pointer",
      },
    }),
  },

  itemCurrent: {
    backgroundColor: color.linkBorderActive,
  },

  label: {
    color: palette.white,
  },

  labelInactive: {
    color: palette.greyBlue,
  },
});

export default NestedItems;
