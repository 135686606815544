/**
 * Claims Processing - Table
 * This renders the actual table rows with the results of claims for the specific filters/screen.
 */
import React, { useContext } from "react";
import { StyleSheet } from "react-native";
import { TypographyType } from "@/models/Typography";
import { color, spacing } from "@/theme";

import ClaimProcessingCard from "@/components/ClaimProcessingCard";
import { ContentWrap } from "@/components/ContentWrap";
import Text from "@/components/Text";
import { ClaimProcessingStateContext } from "@/components/ClaimProcessing/ClaimProcessingState";
import { ClaimProcessingContext } from "@/components/ClaimProcessing/ClaimProcessingProvider";

/**
 * ClaimProcessingList(type, label, userId)
 * Render the list
 * @params type {string} - the type of table/claim-screen we are showing
 * @params label {any} - the readable label for this type of claim
 * @return userId {string | undefined} -
 */
export default function ClaimProcessingList(props: { label: string; type: string; userId: string | undefined }) {
  const { label, type, userId } = props;
  const { state } = useContext<ClaimProcessingStateContext>(ClaimProcessingContext);
  const claims = state.claims.filter(claim => claim.id);

  return (
    <>
      {claims.length >= 1 ? (
        claims.map(claim => <ClaimProcessingCard key={claim.id} claim={claim} userId={userId} type={type} />)
      ) : (
        <ContentWrap>
          <Text format={TypographyType.Small} style={styles.noResults}>
            You have no {label.toLowerCase()} claims.
          </Text>
        </ContentWrap>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  flex: {
    flex: 1,
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
  },
  marginSmall: {
    marginBottom: spacing[10],
  },
  marginBig: {
    marginBottom: spacing[40],
  },
  noResults: {
    paddingVertical: spacing[20],
    paddingLeft: spacing[50],
    backgroundColor: color.backgroundGrey,
    borderBottomColor: color.thinBorderLight,
    borderBottomWidth: 1,
  },
});
