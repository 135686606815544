import React, { useMemo } from "react";
import { View, StyleSheet, StyleProp, ViewStyle } from "react-native";

import { TypographyType } from "@/models/Typography";
import Text from "../Text";
import MiniLabel from "../MiniLabel";
import { spacing, color } from "../../theme";
import { mqWeb } from "../../utils/helpers";

export function Description(props: {
  label: string;
  style?: StyleProp<ViewStyle>;
  data?: string;
  secondaryData?: string;
  color?: string;
  displayError?: boolean;
  bold?: boolean;
  children?: string | JSX.Element;
  testID?: string;
}) {
  const isWebView = mqWeb();
  const labelStyle = useMemo(() => (props.color ? { color: props.color } : {}), [props.color]);
  const dataStyle = useMemo(
    () =>
      props.displayError
        ? { ...styles.data, color: color.textError }
        : props.color
        ? { ...styles.data, color: props.color }
        : { ...styles.data },
    [props.color, props.displayError]
  );

  const testID = props.testID || props.label.replace(/\s/g, "");

  const containerStyles = useMemo(() => [styles.container, props.style], [isWebView, props.style]);

  return (
    <View style={containerStyles} testID={`Description__${testID}`}>
      <MiniLabel style={labelStyle} testID={`Description-MiniLabel__${testID}`}>
        {props.label}
      </MiniLabel>
      {typeof props.data === "object" || typeof props.children === "object" ? (
        props.data || props.children
      ) : (
        <Text
          format={props.bold ? TypographyType.RegularBold : TypographyType.Regular}
          style={dataStyle}
          testID={`Description-Text__${testID}`}
        >
          {props.data || props.children || ""}
        </Text>
      )}
      {props.secondaryData && (
        <Text format={TypographyType.Micro} style={dataStyle} testID={`Description-SecondaryData__${testID}`}>
          {props.secondaryData}
        </Text>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    flexBasis: "100%",
    marginBottom: spacing[30],
  },

  data: {
    color: color.text,
  },
});
