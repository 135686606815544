import dayjs from "dayjs";

export const getDefaultDateRange = () => {
  // This date range provides the upper and lower limits to the export date range.
  const MIN_DATE = dayjs("2000-01-01").toDate();
  const MAX_DATE = dayjs()
    .add(1, "month")
    .toDate();
  const defaultDateRange = {
    from: MIN_DATE,
    to: MAX_DATE,
  };
  return defaultDateRange;
};
