import React, { useEffect, useRef, useState } from "react";
import { Platform, StyleSheet, TextInput, TouchableOpacity, View } from "react-native";
import Collapsible from "react-native-collapsible";

import { TypographyType } from "@/models/Typography";
import { useBottomSheet } from "@/hooks";
import { palette, spacing } from "@/theme";

import Icon, { IconName } from "@/components/Icon";
import ItemSpacer from "@/components/ItemSpacer";
import Text from "@/components/Text";

import { SearchOptions } from "./SearchOptions";
import { GPSearchForFilter, GPSearchByFilter } from "../GPSearch.props";

interface SearchProps {
  query: string;
  setQuery: React.Dispatch<React.SetStateAction<string>>;
  setSearchResults: React.Dispatch<React.SetStateAction<[]>>;
  searchFor: GPSearchForFilter;
  setSearchFor: React.Dispatch<React.SetStateAction<GPSearchForFilter>>;
  searchBy: GPSearchByFilter;
  setSearchBy: React.Dispatch<React.SetStateAction<GPSearchByFilter>>;
  settingsPanelVisible: boolean;
  setSettingsPanelVisible: React.Dispatch<React.SetStateAction<boolean>>;
  initialQuery?: string;
  withConfirm?: string;
}

export function Search(props: SearchProps): JSX.Element {
  const searchInputRef = useRef<TextInput>(null);
  const [searchBarActive, setSearchBarActive] = useState<boolean>(false);
  const { closeBottomSheet } = useBottomSheet();

  function _onResetSearch() {
    props.setQuery("");
    props.setSearchResults([]);
    searchInputRef.current && searchInputRef.current.focus();
  }

  function _onSettingsPannelToggle() {
    if (props.settingsPanelVisible) {
      searchInputRef.current && searchInputRef.current.focus();
    }
    props.setSettingsPanelVisible(!props.settingsPanelVisible);
  }

  function _onSearchForValueChange(value: GPSearchForFilter) {
    if (props.searchFor !== value) {
      props.setSearchFor(value);
    }
  }

  function _onSearchByValueChange(value: GPSearchByFilter) {
    if (props.searchBy !== value) {
      props.setSearchBy(value);
      props.setQuery("");
      !props.query.length && searchInputRef.current && searchInputRef.current.focus();
    }
  }

  function _onSearchBarInputFocus() {
    setSearchBarActive(true);
    if (!!props.query.length && props.settingsPanelVisible) {
      props.setSettingsPanelVisible(false);
    }
  }

  function _onSearchBarInputBlur() {
    setSearchBarActive(false);
  }

  useEffect(() => {
    if (searchInputRef.current) {
      setSearchBarActive(searchInputRef.current.isFocused());
    }
  }, [searchInputRef]);

  useEffect(() => {
    props.setSettingsPanelVisible(!props.query.length);
  }, [props.query]);

  return (
    <View style={[styles.search, (props.settingsPanelVisible || searchBarActive) && styles.searchActive]}>
      {/* Search Bar */}
      <View style={styles.searchBar}>
        <TouchableOpacity style={styles.backButton} onPress={closeBottomSheet}>
          <Icon size={24} name={IconName.keyboard_backspace} color={palette.navy} />
        </TouchableOpacity>
        <TextInput
          value={props.query}
          onChangeText={val => props.setQuery(val)}
          onFocus={_onSearchBarInputFocus}
          onBlur={_onSearchBarInputBlur}
          style={[styles.searchBarInput, !!props.query.length && styles.searchBarInputInUse]}
          placeholder="TYPE HERE TO SEARCH . . ."
          placeholderTextColor={palette.placeholderText}
          ref={searchInputRef}
          autoFocus={Platform.OS === "web"}
          returnKeyLabel="Search"
        />
        <TouchableOpacity
          style={[styles.searchBarButton, !!props.query.length && styles.searchBarButtonEnabled]}
          onPress={props.query.length ? _onResetSearch : () => searchInputRef.current && searchInputRef.current.focus()}
        >
          <Icon
            size={24}
            name={props.query.length ? IconName.refresh : IconName.search}
            color={props.query.length ? palette.blue : palette.greyBlue}
          />
        </TouchableOpacity>
      </View>

      {/* Settings Toggle */}
      <TouchableOpacity style={styles.settingsPanelToggle} onPress={_onSettingsPannelToggle}>
        <Icon size={18} name={IconName.tune} color={palette.blue} style={styles.settingsPanelToggleIcon} />
        <Text format={TypographyType.TinyBold} color={palette.blue}>
          {props.settingsPanelVisible ? "Hide" : "Edit"} Settings
        </Text>
      </TouchableOpacity>

      {/* Settings Panel */}
      <Collapsible collapsed={!props.settingsPanelVisible} style={styles.settingsPanel}>
        <ItemSpacer gap={20}>
          <>
            <Text style={styles.radioGroupLabel} format={TypographyType.Tiny} color={palette.greyBlue}>
              Search For:
            </Text>
            <SearchOptions
              options={[
                { label: "GP Practice", value: "practice" },
                { label: "GP Branch Surgery", value: "surgery" },
              ]}
              value={props.searchFor}
              onValueChange={_onSearchForValueChange}
            />
          </>
          <>
            <Text style={styles.radioGroupLabel} format={TypographyType.Tiny} color={palette.greyBlue}>
              Search By:
            </Text>
            <SearchOptions
              options={[
                { label: "Name", value: "name" },
                { label: "Postcode", value: "postcode" },
              ]}
              value={props.searchBy}
              onValueChange={_onSearchByValueChange}
            />
          </>
        </ItemSpacer>
      </Collapsible>
    </View>
  );
}

const styles = StyleSheet.create({
  backButton: {
    position: "absolute",
    top: 25,
    left: 27, // Needs to be 30 pixels in but adjusted by 3px, due to amoung of white space rendered inside the material design icon SVG
    width: 24,
    height: 24,
    justifyContent: "center",
    alignItems: "center",
    zIndex: 3,
    backgroundColor: palette.transparent,
  },

  radioGroupLabel: {
    marginBottom: spacing[10],
  },

  search: {
    backgroundColor: palette.white,
    shadowColor: palette.blueShadowLight,
    shadowRadius: 30,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.75,
    elevation: 5,
  },
  searchActive: {
    shadowColor: palette.blueShadow,
    shadowRadius: 40,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 1,
    elevation: 15,
  },

  searchBar: {
    position: "relative",
    overflow: "hidden",
  },

  searchBarInput: {
    backgroundColor: palette.white,
    height: 74,
    paddingLeft: spacing[60],
    paddingRight: spacing[30],
    color: palette.navy,
    fontFamily: "Lato-Bold",
    fontSize: 18,
    zIndex: 2,
  },
  searchBarInputInUse: {
    fontSize: 20,
  },

  searchBarButton: {
    position: "absolute",
    top: 15,
    right: 20,
    width: 44,
    height: 44,
    borderRadius: 44,
    backgroundColor: palette.cloud,
    justifyContent: "center",
    alignItems: "center",
    zIndex: 3,
  },
  searchBarButtonEnabled: {
    backgroundColor: palette.blueSecondary,
  },

  settingsPanelToggle: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingHorizontal: spacing[30],
    paddingBottom: spacing[15],
  },
  settingsPanelToggleIcon: {
    marginRight: spacing[5],
  },

  settingsPanel: {
    padding: spacing[30],
    backgroundColor: palette.cloud,
    borderBottomWidth: 3,
    borderBottomColor: palette.blue,
  },
});
