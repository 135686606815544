/* eslint react/prop-types: 0 */
import Auth from "@aws-amplify/auth";
import React, { useState, useRef } from "react";
import { StyleSheet, TouchableWithoutFeedback, View, Keyboard } from "react-native";
import { useRecoilValue, useSetRecoilState, useRecoilState } from "recoil";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { BackButtonProps } from "@/components/BackButton/BackButton.props";
import Text from "@/components/Text";
import { AuthScreen } from "@/layouts";
import { S12Error, newPasswordError } from "@/models/Error";
import { TypographyType } from "@/models/Typography";
import { RouteKeys } from "@/navigationv2";
import { AuthNavigationProps, ScreenOptions } from "@/navigationv2/types";
import { createScreenTitle } from "@/navigationv2/utilities";
import { color, spacing } from "@/theme";
import {
  userDetails,
  cognitoUserSignal as recoilCognitoUserSignal,
  signup,
  getCognitoUser,
  setCognitoUser,
  loading,
  justLoggedIn,
} from "@/utils/recoil/index";
import { updateUserDetails } from "@/components/Root/utils";

import { PasswordInput } from "@/components/PasswordInput";
import ENV from "@/config";

const isEnvProdOrMaster = !!(ENV.ENV === "prod" || ENV.ENV === "master");

interface PropTypes extends AuthNavigationProps<RouteKeys.NewPasswordScreen> {}

// This View is only meant for when a user signs in with a valid
// password and then is asked to create a new password by cognito
const NewPasswordScreen = (props: PropTypes) => {
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const cognitoUserSignal = useRecoilValue(recoilCognitoUserSignal);
  const cognitoUser = getCognitoUser(cognitoUserSignal);
  const setSignup = useSetRecoilState(signup);
  const [user, setUserDetails] = useRecoilState(userDetails);

  const [username] = useState(user ? user.email : "");
  const setLoading = useSetRecoilState(loading);
  const setJustLoggedIn = useSetRecoilState(justLoggedIn);

  const secondInput = useRef<any>();

  if (!cognitoUser) {
    props.navigation.navigate(RouteKeys.SignInScreen);
  }

  const changePassword = () => {
    if (cognitoUser) {
      setButtonDisabled(true);
      Auth.completeNewPassword(cognitoUser, password, cognitoUser.challengeParam.requiredAttributes)
        .catch(e => {
          if (e.message) {
            // ! HACKY FIX: Amplify auth package returning error on password reset but actually succeeded
            // ! S12-1178
            if (e.message === "Invalid device key given.") {
              Auth.currentAuthenticatedUser({ bypassCache: false }).then(user => {
                setCognitoUser(user);
                setJustLoggedIn(true);
                if (isEnvProdOrMaster) {
                  AsyncStorage.setItem(username.toLowerCase().trim(), "0");
                  AsyncStorage.removeItem("loginEmail");
                }
                setTimeout(() => setLoading(false), 800);
                updateUserDetails(user, setUserDetails);
              });
            } else {
              setErrorMessage("Could not set new password, please check your passwords and try again");

              const error = new S12Error(newPasswordError, e);
              if (typeof cognitoUser?.getUsername === "function") {
                error.additional = "user: " + cognitoUser.getUsername();
              }
              error.save();
            }
          }

          setButtonDisabled(false);
        })
        .then(user => {
          if (user && user.challengeName === "SMS_MFA") {
            setSignup({
              email: cognitoUser.username,
              password,
              challenge: user.challengeName,
            });
            props.navigation.navigate(RouteKeys.Confirmation);
          } else {
            Keyboard.dismiss();
          }
          setButtonDisabled(false);
        });
    } else {
      setErrorMessage("Could not confirm your identity, please sign in with your current credentials again");
    }
  };

  const passwordsNoMatch = password.length > 0 && passwordConfirm.length > 0 && password !== passwordConfirm;

  const backButtonConfig: BackButtonProps = {
    enabled: true,
    float: true,
    color: "white",
  };

  const renderContent = () => {
    return (
      <View>
        <TouchableWithoutFeedback style={styles.fill} onPress={e => e.stopPropagation()}>
          <View>
            <PasswordInput
              error={!password || (!!password && password.trim().length > 0 && password.trim().length < 8)}
              errorText="Password must contain at least 8 characters"
              label="New password"
              icon="lock-outline"
              autoCorrect={false}
              autoCapitalize="none"
              returnKeyType="next"
              value={password}
              onChangeText={value => setPassword(value)}
              onSubmitEditing={() => {
                secondInput.current && secondInput.current.focus();
              }}
            />
            <PasswordInput
              ref={secondInput}
              error={passwordsNoMatch}
              errorText="Your password and confirmation password do not match"
              label="Confirm new password"
              icon="lock-outline"
              autoCorrect={false}
              autoCapitalize="none"
              returnKeyType="done"
              value={passwordConfirm}
              onChangeText={value => setPasswordConfirm(value)}
              onSubmitEditing={() => changePassword()}
            />
            {errorMessage !== "" && (
              <Text format={TypographyType.Micro} style={styles.errorMessage}>
                {errorMessage}
              </Text>
            )}
          </View>
        </TouchableWithoutFeedback>
      </View>
    );
  };

  return (
    <AuthScreen
      backButton={backButtonConfig}
      buttons={[
        {
          label: "Set New Password",
          disabled: password.length < 8 || passwordConfirm.length < 8 || passwordsNoMatch || buttonDisabled,
          onPress: () => changePassword(),
          testID: "Set-Password__Button",
        },
        {
          label: "Cancel",
          textButton: true,
          onPress: () => props.navigation.goBack(),
          testID: "Cancel-Password__Button",
        },
      ]}
      testID="Change-Password__Screen"
    >
      <Text format={TypographyType.HeadingMedium} style={styles.headline}>
        Set New Password
      </Text>
      <View style={styles.helperTextContainer}>
        <Text format={TypographyType.Regular}>Please enter a password to use with this app</Text>
      </View>
      {renderContent()}
    </AuthScreen>
  );
};

const screenOptions: ScreenOptions = {
  title: createScreenTitle("New Password"),
};

NewPasswordScreen.screenOptions = screenOptions;

export default NewPasswordScreen;

const styles = StyleSheet.create({
  fill: {
    flex: 1,
    flexDirection: "column",
    maxWidth: 800,
    minWidth: 275,
    width: "100%",
    flexBasis: "auto",
    flexShrink: 0,
    marginBottom: spacing[10],
  },
  headline: {
    marginBottom: 40,
    alignSelf: "center",
  },
  errorMessage: {
    color: color.textError,
    alignSelf: "center",
    marginTop: -spacing[15],
    marginBottom: spacing[10],
  },

  helperTextContainer: {
    marginBottom: 50,
    alignSelf: "center",
    maxWidth: 275,
  },
});
