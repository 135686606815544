import { useEffect } from "react";
import { useRecoilState } from "recoil";

import { S12Error } from "@/models/Error";
import { lastException as lastExceptionState } from "@/utils/recoil";

type LastException = S12Error | null;
type SetLastException = (exception: S12Error) => void;

export default function useS12LastException(): [LastException, SetLastException] {
  const [lastException, setLastException] = useRecoilState(lastExceptionState);

  useEffect(() => {
    return lastException && lastException instanceof S12Error ? lastException.save() : undefined;
  }, [lastException]);

  return [lastException, setLastException];
}
