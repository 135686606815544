import { AssessmentItem } from "./Assessment";
import { MutationFn } from "react-apollo";
import { LocationNameInput } from "libs/types/API";
import gql from "graphql-tag";
import { AmhpTeamIdAndName, DoctorVisitsCondensed } from "./gql/fragments";

export const GET_AMHP_PROFILE_QUERY = gql`
  query myProfile {
    profile: myProfile {
      id
      name
      pushTokens
      teams {
        items {
          id
          amhpTeam {
            ...AmhpTeamIdAndNameParts
            featureFlags
          }
        }
      }
      assessments(limit: 500, sortDirection: DESC) {
        items {
          id
          patientName
          assessmentDate
          doctorVisits {
            items {
              ...DoctorVisitsCondensed
            }
          }
        }
      }
    }
  }
  ${AmhpTeamIdAndName}
  ${DoctorVisitsCondensed}
`;

export const UPDATE_AMHP_PROFILE = gql`
  mutation UpdateAmhpProfile($input: UpdateAmhpProfileInput!) {
    updateAmhpProfile(input: $input) {
      id
    }
  }
`;

export interface AssessmentDateInfo {
  id: string;
  assessmentDate?: string;
  doctorVisits: {
    items: Array<{
      time: string;
    } | null> | null;
  } | null;
}

export interface AMHP {
  id: string;
  name: string;
}

export interface AMHPTeam {
  id: string;
  name: string;
  users: {
    items: {
      amhp: AMHP;
    };
  };
  featureFlags: string;
  assessments: {
    items: AssessmentItem[];
  };
}

export interface UserProfile {
  id: string;
  name: string;
  pushTokens?: string[];
  assessments: {
    items: ({
      id: string;
      patientName: string;
    } & AssessmentDateInfo)[];
  };
  teams: {
    items: {
      amhpTeam: AMHPTeam;
    }[];
  };
}

export interface GetUserProfileResponse {
  profile: UserProfile;
}

export interface AmhpReturn {
  id: string;
}

export interface UpdateInput {
  input: {
    id: string;
    email?: string;
    phone?: string;
    name?: string;
    warrantInfo?: string;
    accreditation?: string;
    sectionFormAddress?: LocationNameInput;
    pushTokens?: string[];
  };
}

export type UpdateAmhpProfileFunc = MutationFn<AmhpReturn, UpdateInput>;
