import React, { forwardRef, useImperativeHandle, useState } from "react";
import { StyleSheet, View, TouchableOpacity } from "react-native";
import Collapsible from "react-native-collapsible";
import { formatAddress } from "libs/formatters/address";
import { ClaimDataInput } from "libs/types/API";
import { DoctorDetail } from "libs/types/claim";
import Icon, { IconName } from "@/components/Icon";
import { Button } from "../Button";
import { Card } from "../Cardd";
import { CardContainer } from "../Card";
import { ContentWrap } from "../ContentWrap";
import { Modal } from "../Modal/Modal";
import Text from "../Text";
import { TextInput } from "../TextInput/TextInput";

import { TypographyType } from "@/models/Typography";
import { palette, color, spacing } from "@/theme";
import { Checkbox } from "@/components/Checkbox/Checkbox";

import { useIsWebView } from "@/hooks";

interface BillingInformationPropTypes {
  /**
   * Current doctor details.
   */
  doctor: DoctorDetail;
  /**
   * Initial data to populate the input fields.
   */
  initialData: ClaimDataInput;
  /**
   * Callback when form is saved.
   */
  onSave: (data: ClaimDataInput) => void;
  submitted?: boolean;
  /**
   * Should the doctor provide their billing information.
   */
  mustProvideBillingInformation?: boolean;
}

type Ref = {
  closeModal: () => void;
  openModal: () => void;
};

/**
 * Display the doctors billing information and allow them to edit it in a modal.
 *
 * @author [Alex McCabe](https://github.com/alexmccabe)
 * @todo Convert modal usage to bottom sheet.
 * @param {BillingInformationPropTypes} props
 * @param {Object} forwardedRef
 */
const BillingInformation: React.RefForwardingComponent<Ref, BillingInformationPropTypes> = (props, forwardedRef) => {
  const { doctor, initialData, mustProvideBillingInformation = false, onSave, submitted } = props;
  const [isModalVisible, setModalVisible] = useState(false);
  const [modalClaimData, setModalClaimData] = useState<ClaimDataInput>(initialData);
  const [isVatRegistered, setIsVatRegistered] = useState(!!initialData.vatRegistration);
  const [hideAccountConfHelperText, setHideAccountConfHelperText] = useState<boolean>(true);
  const isWebView = useIsWebView();
  const accountConfHelperTextStyles = React.useMemo(
    () => [styles.accountConfHelperText, isWebView && styles.accountConfHelperTextWeb],
    [isWebView]
  );
  const confirmationHeaderText = `Account Confirmation \nDetails`;

  const _onInfoToggleButtonPress = () => setHideAccountConfHelperText(!hideAccountConfHelperText);

  const closeModal = () => {
    setModalVisible(false);
  };

  const openModal = () => {
    setModalVisible(true);
  };

  useImperativeHandle(
    forwardedRef,
    () => {
      return {
        closeModal,
        openModal,
      };
    },
    []
  );

  return (
    <>
      <ContentWrap>
        <Text format={TypographyType.HeadingSmall} style={styles.sectionHeader}>
          Account Confirmation Details
          <Text format={TypographyType.HeadingSmall} style={styles.required}>
            *
          </Text>
        </Text>
        <Text format={TypographyType.Small} style={styles.billingInfoText}>
          This CCG requires confirmation of your account details to verify your identity in their system. This
          information needs to be completed in order to be able to submit your claim. You do not need to add your sort
          code or account number.
        </Text>
      </ContentWrap>

      {!doctor.claimData ? (
        <ContentWrap style={{ marginBottom: spacing[20] }}>
          <Text format={TypographyType.Small} style={styles.pb15}>
            You don't have any billing information saved
          </Text>
          <Button onPress={openModal}>Add Account Confirmation Details</Button>
        </ContentWrap>
      ) : (
        <View style={{ marginBottom: spacing[20] }}>
          <CardContainer>
            <ContentWrap>
              <Card
                statusBarColor={color.doctor}
                buttons={[
                  {
                    label: "Edit",
                    onPress: openModal,
                  },
                ]}
              >
                <>
                  <Text format={TypographyType.SmallBold}>{doctor.claimData?.billingCompanyName}</Text>
                  {doctor.claimData?.billingAddress && (
                    <Text format={TypographyType.Small}>{formatAddress(doctor.claimData?.billingAddress)}</Text>
                  )}
                  {(doctor.claimData?.vatRegistration?.length || 0) > 0 && (
                    <Text format={TypographyType.Small}>Vat Registration: {doctor.claimData.vatRegistration}</Text>
                  )}
                </>
              </Card>
            </ContentWrap>
          </CardContainer>
        </View>
      )}

      <Modal
        onDismiss={closeModal}
        dismissable={true}
        visible={isModalVisible}
        headline={isWebView ? "Account Confirmation Details" : confirmationHeaderText}
        buttons={[
          {
            label: "Cancel",
            onPress: closeModal,
          },
          {
            label: "Add Account Confirmation Details",
            disabled:
              (!mustProvideBillingInformation &&
                (!modalClaimData || modalClaimData?.billingAddress?.postcode?.trim() === "")) ||
              (mustProvideBillingInformation &&
                (modalClaimData?.billingAddress?.postcode?.trim() === "" ||
                  modalClaimData?.billingCompanyName?.trim() === "" ||
                  (isVatRegistered &&
                    (modalClaimData?.vatRegistration?.trim() === "" || !modalClaimData?.vatRegistration)))),
            onPress: () => onSave(modalClaimData),
          },
        ]}
      >
        <>
          <TouchableOpacity onPress={_onInfoToggleButtonPress}>
            <View style={styles.infoToggleButton}>
              <Icon
                name={hideAccountConfHelperText ? IconName.info_outline : IconName.close}
                size={24}
                color={palette.blue}
              />
            </View>
          </TouchableOpacity>
          <Collapsible collapsed={hideAccountConfHelperText} style={accountConfHelperTextStyles}>
            <Text format={TypographyType.Tiny} style={styles.billingInfoText}>
              This is to confirm the name and address registered to your bank account that this CCG has in their
              systems. If you are not a company, please add the name as it appears on your bank account that your
              payments are sent to.
            </Text>
            <Text format={TypographyType.Tiny} style={styles.billingInfoText}>
              Please note, once this is added, it will be saved to your profile. If you need to update it at any time,
              please inform the relevant CCGs as well as updating the information in your profile.
            </Text>
          </Collapsible>
          <TextInput
            label="Name/Company"
            disabled={submitted}
            value={modalClaimData?.billingCompanyName || ""}
            returnKeyType="next"
            blurOnSubmit={true}
            onChangeText={value =>
              setModalClaimData({
                ...modalClaimData,
                billingCompanyName: value,
              })
            }
          />
          <TextInput
            label="Billing Address (Line 1)"
            disabled={submitted}
            value={modalClaimData?.billingAddress?.address || ""}
            returnKeyType="next"
            blurOnSubmit={true}
            onChangeText={value =>
              setModalClaimData({
                ...modalClaimData,
                billingAddress: {
                  ...modalClaimData?.billingAddress,
                  address: value,
                },
              } as ClaimDataInput)
            }
          />
          <TextInput
            label="Billing Address (City)"
            disabled={submitted}
            value={modalClaimData?.billingAddress?.city || ""}
            returnKeyType="next"
            blurOnSubmit={true}
            onChangeText={value =>
              setModalClaimData({
                ...modalClaimData,
                billingAddress: {
                  ...modalClaimData?.billingAddress,
                  city: value,
                },
              } as ClaimDataInput)
            }
          />
          <TextInput
            label="Billing Address (Postcode)"
            disabled={submitted}
            value={modalClaimData?.billingAddress?.postcode || ""}
            returnKeyType="next"
            autoCapitalize="characters"
            blurOnSubmit={true}
            onChangeText={value =>
              setModalClaimData({
                ...modalClaimData,
                billingAddress: {
                  ...modalClaimData?.billingAddress,
                  postcode: value,
                },
              })
            }
          />
          <Checkbox
            status={isVatRegistered}
            onPress={() => {
              if (isVatRegistered) {
                setModalClaimData({
                  ...modalClaimData,
                  vatRegistration: null,
                });
              }
              setIsVatRegistered(!isVatRegistered);
            }}
            label="I or my company is VAT registered"
            style={!isVatRegistered && { marginBottom: 1 }}
          />
          {isVatRegistered && (
            <TextInput
              label="VAT Registration Number"
              disabled={submitted}
              autoCorrect={false}
              spellCheck={false}
              value={modalClaimData?.vatRegistration || ""}
              returnKeyType="done"
              blurOnSubmit={true}
              onChangeText={value =>
                setModalClaimData({
                  ...modalClaimData,
                  vatRegistration: value,
                })
              }
            />
          )}
        </>
      </Modal>
    </>
  );
};

BillingInformation.displayName = "BillingInformation";

const styles = StyleSheet.create({
  pb15: {
    paddingBottom: 15,
  },

  sectionHeader: {
    paddingVertical: spacing[20],
  },
  billingInfoText: {
    marginBottom: spacing[5],
  },
  required: {
    color: "red",
    marginLeft: spacing[5],
  },
  accountConfHelperText: {
    backgroundColor: palette.cloud,
    padding: spacing[20],
    borderRadius: 10,
  },
  accountConfHelperTextWeb: {
    padding: spacing[20],
  },
  infoToggleButton: {
    alignSelf: "flex-end",
    width: spacing[40],
    padding: spacing[5],
    marginLeft: spacing[10],
    borderRadius: 44,
    justifyContent: "center",
    alignItems: "center",
    transform: [{ translateY: 2 }],
  },
});

export default forwardRef(BillingInformation);
