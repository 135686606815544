import React, { useMemo } from "react";
import { StyleSheet, StyleProp, View, ViewStyle } from "react-native";

import { useDeviceWidth, useIsWebView } from "@/hooks";
import { spacing } from "../../theme";

interface ContentWrapProps {
  children: React.ReactNode;
  style?: StyleProp<ViewStyle>;
  onlyWrap?: "web" | "mobile";
}
export const ContentWrap = (props: ContentWrapProps) => {
  const { children, style } = props;
  const deviceWidth = useDeviceWidth();
  const isWebView = useIsWebView();

  const styleArr = useMemo(
    () => [
      styles.base,
      deviceWidth <= 320
        ? styles.phoneSmall
        : deviceWidth <= 410
        ? styles.phone
        : deviceWidth <= 999
        ? styles.standard
        : styles.web,
      style,
    ],
    [deviceWidth, style]
  );

  if (
    (isWebView && props.onlyWrap && props.onlyWrap === "mobile") ||
    (!isWebView && props.onlyWrap && props.onlyWrap === "web")
  ) {
    return <View>{children}</View>;
  } else {
    return <View style={styleArr}>{children}</View>;
  }
};

const styles = StyleSheet.create({
  base: {
    maxWidth: 1000,
    alignSelf: "center",
    width: "100%",
  },

  phoneSmall: { paddingHorizontal: spacing[20] },
  phone: { paddingHorizontal: spacing[30] },
  standard: { paddingHorizontal: spacing[40] },
  web: { paddingLeft: spacing[10], paddingRight: spacing[10] },
});

export default ContentWrap;
