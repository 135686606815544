import * as React from "react";
import { StyleSheet, TextProps, TextStyle } from "react-native";

import { TypographyType } from "@/models/Typography";
import Text from "../Text";
import { color, spacing } from "../../theme";

const MiniLabel = (props: TextProps & TextStyle & { children: string; dark?: boolean }) => {
  return (
    <Text format={TypographyType.Tiny} style={[styles.text, props.style, props.dark && styles.dark]}>
      {props.children}
    </Text>
  );
};

export default React.memo(MiniLabel);

const styles = StyleSheet.create({
  text: {
    color: color.textExtraLight,
    marginBottom: spacing[5],
  },
  dark: {
    color: color.text,
  },
});
