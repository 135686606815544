import React from "react";
import { createStackNavigator } from "@react-navigation/stack";

import screenOptions from "@/navigationv2/config/screenOptions";
import { CCGTeamClaimsRoutes } from "@/navigationv2/types";
import * as Screens from "@/screens";
import TabIcon from "../TabIcon";

const TeamStack = createStackNavigator<CCGTeamClaimsRoutes>();

const CCGTeamClaimsStack = () => {
  return (
    <TeamStack.Navigator initialRouteName="TeamClaimsScreen" screenOptions={screenOptions}>
      <TeamStack.Screen
        name="TeamClaimsScreen"
        component={Screens.TeamClaimsScreen}
        options={Screens.TeamClaimsScreen.screenOptions}
      />
      <TeamStack.Screen
        name="ClaimProcessingDetailsScreen"
        component={Screens.ClaimProcessingDetailsScreen}
        options={Screens.ClaimProcessingDetailsScreen.screenOptions}
      />
      <TeamStack.Screen
        name="ClaimProcessingSearchScreen"
        component={Screens.ClaimProcessingSearchScreen}
        options={Screens.ClaimProcessingSearchScreen.screenOptions}
      />
    </TeamStack.Navigator>
  );
};

CCGTeamClaimsStack.tabOptions = {
  tabBarIcon: TabIcon("people-outline"),
  tabBarLabel: "Team Claims",
};

export default CCGTeamClaimsStack;
