import React, { useState } from "react";

import MHAFormContainer from "@/components/Forms/MHAFormContainer";
import { useOnScreenBlur } from "@/hooks";
import { RouteKeys } from "@/navigationv2";
import { MHAFormRoutes, DoctorTabRoutes, ScreenNavigationProps, ScreenOptions } from "@/navigationv2/types";
import { createScreenTitle } from "@/navigationv2/utilities";

interface PropTypes extends ScreenNavigationProps<DoctorTabRoutes, MHAFormRoutes, RouteKeys.MHAFormScreen> {}

const MHAFormScreen = (props: PropTypes) => {
  const { formType, formId, assessmentId } = props.route.params;
  const [blurCounter, setBlurCounter] = useState(0);

  useOnScreenBlur(() => {
    setBlurCounter(curr => curr + 1);
  });

  return <MHAFormContainer assessmentId={assessmentId} blurCounter={blurCounter} formType={formType} formId={formId} />;
};

const screenOptions: ScreenOptions<PropTypes> = (props: PropTypes) => {
  return {
    title: createScreenTitle(`${props.route.params.formType.replace(/_/g, " ")} Assessment Form`),
  };
};

MHAFormScreen.screenOptions = screenOptions;

export default MHAFormScreen;
