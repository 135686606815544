/**
 * Subscribe to the width of the viewport
 */
import { useEffect, useState } from "react";
import { Dimensions, Platform } from "react-native";

export default function useDeviceWidth(): number {
  const device = Platform.OS === "web" ? "window" : "screen";
  const [deviceWidth, setDeviceWidth] = useState(Dimensions.get(device).width);

  useEffect(() => {
    const _handleResize = () => setDeviceWidth(Dimensions.get(device).width);
    Dimensions.addEventListener("change", _handleResize);
    return () => Dimensions.removeEventListener("change", _handleResize);
  }, []);

  return deviceWidth;
}
