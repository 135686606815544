import gql from "graphql-tag";
import { OrganisationIdNameTypeFeatures } from "./gql/fragments";

export interface Organisation {
  name: string;
  shortcode: string;
  ccgs: string[];
}

export const GET_USER_ORGS = gql`
  query GetUserOrgs($userId: ID!) {
    getUser(id: $userId) {
      id
      organisations(limit: 50) {
        items {
          id
          organisation {
            ...OrganisationIdNameTypeFeatures
          }
        }
      }
    }
  }
  ${OrganisationIdNameTypeFeatures}
`;

export interface GetUserOrgVariables {
  userId: string;
}

export interface GetUserOrgResponseOrg {
  id: string;
  name: string;
  type: string;
  featureFlags: string;
}

export interface GetUserOrgResponse {
  getUser: {
    id: string;
    organisations: {
      items: {
        id: string;
        organisation: GetUserOrgResponseOrg;
      }[];
    };
  };
}
