import React from "react";
import { StyleSheet, View } from "react-native";
import { useRecoilValue } from "recoil";

import NavigationItem from "./NavigationItem";

import { bottomTabBarPropsState } from "../state";

const DesktopWeb = () => {
  const bottomTabBarProps = useRecoilValue(bottomTabBarPropsState);
  const { descriptors, navigation, state } = bottomTabBarProps;
  const shouldRender = state.routes?.length;

  if (!shouldRender) {
    return null;
  }

  return (
    <View style={styles.container}>
      {state.routes?.map((route, index) => {
        return (
          <NavigationItem
            descriptors={descriptors}
            key={index}
            index={index}
            navigation={navigation}
            route={route}
            state={state}
          />
        );
      })}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    borderTopRightRadius: 12,
    borderBottomRightRadius: 12,
    flexDirection: "row",
    height: "100%",
    overflow: "hidden",
  },
});

export default React.memo(DesktopWeb);
