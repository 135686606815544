/* eslint-disable react-native/no-unused-styles */
/* ^ This is set because styles are selected dynamically, based on a prop; lack of explicit reference of styles causes a false "no-unused-styles" warning, hence the disable flag above. */

import React from "react";
import { Platform, StyleSheet, View } from "react-native";

import { color, spacing } from "../../theme";
import { TypographyType } from "@/models/Typography";
import { TouchableRipple } from "react-native-paper";
import Icon from "../Icon";
import Text from "../Text";

export interface CardButton {
  label: string;
  icon?: string;
  disabled?: boolean;
  onPress?: () => void;
  renderButton?: () => void;
}

const CardButtons = (props: { buttons: CardButton[] }) => {
  const buttons = props.buttons.length;
  return (
    <View style={styles.buttons}>
      {props.buttons.map((button: CardButton, index: number) =>
        button.renderButton ? (
          <View
            style={[styles.button, { flexBasis: `${100 / buttons}%` }, index !== buttons - 1 && styles.borderRight]}
            key={index}
          >
            <>{button.renderButton()}</>
          </View>
        ) : (
          <TouchableRipple
            key={index}
            rippleColor={`${color.thinBorder}`}
            style={[styles.button, { flexBasis: `${100 / buttons}%` }, index !== buttons - 1 && styles.borderRight]}
            onPress={button.onPress}
            disabled={button.disabled}
          >
            <>
              {!!button.icon && <Icon name={button.icon} size={18} color={color.textExtraLight} style={styles.icon} />}
              <Text
                format={TypographyType.TinyBold}
                style={[styles.buttonText, button.disabled && { color: color.disabled }]}
              >
                {button.label.toUpperCase()}
              </Text>
            </>
          </TouchableRipple>
        )
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  borderRight: {
    borderRightWidth: 1,
    borderColor: color.thinBorder,
  },

  button: {
    flexGrow: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: spacing[15],
    borderRadius: 0,
    ...Platform.select({
      web: {
        cursor: "pointer",
      },
    }),
  },

  buttonText: {
    textAlign: "center",
    color: color.primary,
  },

  buttons: {
    flexDirection: "row",
    borderTopWidth: 1,
    borderColor: color.thinBorder,
  },

  icon: {
    marginRight: spacing[5],
  },
});

export default CardButtons;
