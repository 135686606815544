import Auth from "@aws-amplify/auth";
import React, { useState } from "react";
import { Platform, StyleSheet, TouchableWithoutFeedback, View } from "react-native";
import { TextInput } from "react-native-paper";
import { useSetRecoilState, useRecoilState } from "recoil";
import { ParamListBase, useNavigation } from "@react-navigation/native";

import { BackButtonProps } from "@/components/BackButton/BackButton.props";
import Text from "@/components/Text";
import { AuthScreen } from "@/layouts";
import { TypographyType } from "@/models/Typography";
import { RouteKeys } from "@/navigationv2";
import { AuthNavigationProps, ScreenOptions } from "@/navigationv2/types";
import { createScreenTitle } from "@/navigationv2/utilities";
import { color, spacing } from "@/theme";
import { alert } from "@/utils/helpers";

import {
  getCognitoUser,
  cognitoUserSignal as recoilCognitoUserSignal,
  loading,
  signup as recoilSignup,
} from "@/utils/recoil/";

import { newSMSCodeError, S12Error } from "@/models/Error";
import { recordEvent } from "@/utils/analytics";
import { AnalyticsEvent } from "libs/analytics/events";
import { StackNavigationProp } from "@react-navigation/stack";

interface PropTypes extends AuthNavigationProps<RouteKeys.Confirmation> {}

const ConfirmationScreen = () => {
  const [authCode, setAuthCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigation = useNavigation<StackNavigationProp<ParamListBase>>();
  const [cognitoUserSignal, setCognitoUserSignal] = useRecoilState(recoilCognitoUserSignal);
  const cognitoUser = getCognitoUser(cognitoUserSignal);
  const setLoading = useSetRecoilState(loading);
  const [signup, setSignup] = useRecoilState(recoilSignup);
  const { email, password, challenge } = signup;

  if (!signup || !email || email.length === 0 || !cognitoUser) {
    navigation.navigate(RouteKeys.SignInScreen);
  }

  const confirmSignUp = async () => {
    if (!email || !password) {
      setErrorMessage("Email or username invalid, please restart login");
      return;
    }

    if (Platform.OS !== "web") {
      setLoading(true);
    }

    if (challenge === "SMS_MFA") {
      await Auth.confirmSignIn(cognitoUser, authCode, "SMS_MFA").catch(e => {
        setTimeout(() => setLoading(false), 800);
        recordEvent(AnalyticsEvent.WRONG_SMS_CODE, e.message ? { message: e.message } : undefined);
        setAuthCode("");
        setErrorMessage("Invalid Confirmation Code");
      });
    } else {
      await Auth.confirmSignUp(email, authCode)
        .then(async () => {
          await Auth.signIn(email, password)
            .then(() => setTimeout(() => setLoading(false), 800))
            .catch(err => {
              // cognito will store the error message
              setTimeout(() => setLoading(false), 800);
              alert("Signup confirmation failed");
              const e = new S12Error(newSMSCodeError, err);
              e.save();
              navigation.navigate(RouteKeys.Auth);
            });
        })
        .catch(err => {
          const e = new S12Error(newSMSCodeError, err);
          e.save();
          setTimeout(() => setLoading(false), 800);
          setErrorMessage(err.message);
        });
    }
  };

  const restartLogin = () => {
    setSignup({
      ...signup,
      password: "",
      challenge: undefined,
    });
    setAuthCode("");
    setCognitoUserSignal(0);
    navigation.navigate(RouteKeys.SignInScreen);
  };

  const backButtonConfig: BackButtonProps = {
    enabled: true,
    float: true,
    color: "white",
  };
  return (
    <AuthScreen
      backButton={backButtonConfig}
      buttons={[
        {
          label: "Confirm",
          disabled: !authCode || !!(authCode && authCode.trim() === ""),
          onPress: () => confirmSignUp(),
        },
        { label: "Restart Login", onPress: restartLogin, textButton: true },
      ]}
    >
      <Text format={TypographyType.HeadingLarge} style={styles.headline}>
        User Verification
      </Text>
      <View style={styles.helperTextContainer}>
        <Text format={TypographyType.Tiny} style={styles.helperText}>
          Please enter the confirmation code, sent to your registered email or phone number
        </Text>
      </View>
      <TouchableWithoutFeedback style={[styles.fill, { marginTop: spacing[10] }]} onPress={e => e.stopPropagation()}>
        <View style={styles.fill}>
          <TextInput
            label="Confirmation Code"
            autoCorrect={false}
            autoCapitalize="none"
            keyboardType="numeric"
            returnKeyType="done"
            value={authCode}
            onChangeText={value => setAuthCode(value)}
            style={styles.input}
          />
        </View>
      </TouchableWithoutFeedback>
      <View>
        <Text format={TypographyType.Micro} style={styles.errorMessage}>
          {errorMessage}
        </Text>
      </View>
    </AuthScreen>
  );
};

const screenOptions: ScreenOptions = {
  title: createScreenTitle("Sign Up Confirmation"),
};

ConfirmationScreen.screenOptions = screenOptions;

export default ConfirmationScreen;

const styles = StyleSheet.create({
  errorMessage: {
    color: color.textError,
    paddingLeft: 10,
  },

  fill: {
    flex: 1,
    flexDirection: "column",
    maxWidth: 800,
    minWidth: 275,
    width: "100%",
    flexBasis: "auto",
    flexShrink: 0,
  },
  headline: {
    marginBottom: 40,
    alignSelf: "center",
  },
  input: {
    marginBottom: spacing[25],
    backgroundColor: color.white,
  },
  helperTextContainer: {
    marginBottom: 50,
    alignSelf: "center",
    maxWidth: 275,
  },
  helperText: {
    color: color.textLight,
    textAlign: "center",
  },
});
