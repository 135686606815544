import { GestureResponderEvent, StyleProp, StyleSheet, TouchableWithoutFeedback, View, ViewStyle } from "react-native";
import { RadioButton as RadioButtonPaper } from "react-native-paper";
import Text from "../Text/Text";
import { TypographyType } from "@/models/Typography";
import React from "react";
import { color, palette, spacing } from "../../theme";

export interface RadioButtonProps {
  value: string;
  status: boolean;
  onPress?: (event: GestureResponderEvent, value: string) => void; // Should the onPress be optional??
  label?: string;
  wrapperStyle?: StyleProp<ViewStyle>;
  style?: StyleProp<ViewStyle>;
  onGreyBG?: boolean;
  labelFontFormat?: TypographyType;
}

export const RadioButtonGroup = RadioButtonPaper.Group;

export const RadioButton = ({
  value,
  status,
  onPress,
  label,
  labelFontFormat,
  wrapperStyle,
  style,
  onGreyBG = false,
}: RadioButtonProps) => {
  return (
    <TouchableWithoutFeedback onPress={event => onPress && onPress(event, value)}>
      <View style={[styles.radioWrapper, wrapperStyle]}>
        <View style={[radioStyles(status, onGreyBG), style]}>
          <RadioButtonPaper value={value} color={"transparent"} uncheckedColor={"transparent"} />
        </View>
        <Text format={labelFontFormat || TypographyType.Tiny} style={labelStyles(status)}>
          {label}
        </Text>
      </View>
    </TouchableWithoutFeedback>
  );
};

const radioStyles = (status: boolean, onGreyBG: boolean) => [
  styles.radio,
  {
    borderColor: status ? color.primary : color.textExtraLight,
    backgroundColor: status ? color.primary : onGreyBG ? palette.cloud : palette.white,
  },
];

const labelStyles = (status: boolean) => ({
  fontFamily: status ? "Lato-Bold" : "Lato-Regular",
});

const styles = StyleSheet.create({
  radioWrapper: {
    alignItems: "center",
    flexDirection: "row",
    marginBottom: spacing[15],
  },
  radio: {
    width: 20,
    height: 20,
    borderWidth: 2,
    borderRadius: 10,
    marginRight: spacing[20],
  },
});
