import React from "react";
import { GestureResponderEvent, StyleSheet, TouchableOpacity, View } from "react-native";
import { BottomTabBarProps } from "@react-navigation/bottom-tabs";
import { Route, CommonActions } from "@react-navigation/native";

import Text from "@/components/Text";
import { TypographyType } from "@/models/Typography";
import { palette } from "@/theme";

interface PropTypes extends BottomTabBarProps {
  index: number;
  route: Route<string>;
  isActive?: boolean;
  isLastItem?: boolean;
  onPress?: (event: GestureResponderEvent) => void;
  options?: {
    tabBarAccessibilityLabel?: string;
    tabBarBadge?: number | string;
    tabBarIcon?: (props: { focused: boolean; size: number; color: string }) => React.ReactNode;
    tabBarLabel?: string;
    tabBarTestID?: string;
    title?: string;
  };
}

const TabItem = (props: PropTypes) => {
  const { descriptors, index, isActive, isLastItem, navigation, onPress, options, route, state } = props;
  const descriptor = descriptors[route.key];
  const opts = options || (descriptor && descriptor.options) || {};
  const isFocused = isActive || state.index === index;
  const isLast = isLastItem !== undefined ? isLastItem : state.routes.length === index + 1;
  const label = opts.tabBarLabel !== undefined ? opts.tabBarLabel : opts.title !== undefined ? opts.title : route.name;

  /**
   * Handle tab press. This is a fairly complex process on the web, due to
   * the fact that if a user comes direct to the screen from a URL there
   * is no previous history to navigate to, so React Navigation can't
   * handle it.
   *
   * Instead we have to have a workaround that clears the entire children
   * state routes and resets it back to as if we had just mounted the
   * navigation.
   */
  const onTabPress = (event: GestureResponderEvent) => {
    const customEvent = navigation.emit({
      type: "tabPress",
      target: route.key,
      canPreventDefault: true,
    });

    if (onPress) {
      onPress(event);
    } else {
      if (!customEvent.defaultPrevented) {
        if (isFocused) {
          navigation.dispatch(navState => {
            const routes = navState.routes.map(item => {
              return isFocused ? { name: item.name } : item;
            });

            return CommonActions.reset({ ...navState, index, routes });
          });
        } else {
          navigation.dispatch({
            ...CommonActions.navigate(route.name),
            target: state.key,
          });
        }
      }
    }
  };

  const onTabLongPress = () => {
    navigation.emit({
      type: "tabLongPress",
      // target: route.key,
    });
  };

  return (
    <TouchableOpacity
      accessibilityRole="button"
      accessibilityState={{ selected: isFocused }}
      accessibilityLabel={options?.tabBarAccessibilityLabel}
      testID={options?.tabBarTestID}
      onPress={onTabPress}
      onLongPress={onTabLongPress}
      style={styles.touchableOpacity}
    >
      <View style={styles.item}>
        {Boolean(opts?.tabBarBadge) && <View style={styles.badge} />}

        {opts?.tabBarIcon && (
          <View style={styles.iconContainer}>
            {opts.tabBarIcon({
              color: isFocused ? palette.blue : palette.greyBlue,
              focused: isFocused,
              size: 22,
            })}
          </View>
        )}

        <Text
          format={TypographyType.MicroBold}
          style={[styles.label, { color: isFocused ? palette.blue : palette.greyBlue }]}
        >
          {label}
        </Text>

        {!isLast && <View style={styles.divider} />}
      </View>
    </TouchableOpacity>
  );
};

/**
 * Set the vertical padding of the tab bar item, this prevents the icon and
 * label from sitting against the edge of the container.
 */
const itemVerticalPadding = 12;

/**
 * Set the divider height. The divider sits between the tab bar items.
 */
const dividerHeight = 25;

const styles = StyleSheet.create({
  touchableOpacity: { flex: 1 },

  item: {
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: itemVerticalPadding,
    paddingTop: itemVerticalPadding,
    position: "relative",
  },

  badge: {
    position: "absolute",
    top: 6,
    width: 15,
    height: 15,
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
    borderBottomRightRadius: 15,
    borderBottomLeftRadius: 15,
    backgroundColor: palette.red,
    shadowColor: palette.shadow,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.25,
    shadowRadius: 10,
    transform: [{ translateX: 10 }],
    zIndex: 2,
  },

  divider: {
    backgroundColor: palette.grey,
    height: dividerHeight,
    position: "absolute",
    right: 0,
    width: 1,
  },

  iconContainer: {
    marginBottom: 5,
  },

  label: {
    lineHeight: itemVerticalPadding,
    textAlign: "center",
  },
});

export default TabItem;
