import React, { useRef, useEffect, useMemo, useState } from "react";
import { View, TouchableOpacity, StyleSheet, Platform } from "react-native";
import Text, { TextWithIcon } from "../../../Text";
import Icon, { IconName } from "../../../Icon";
import { TypographyType } from "@/models/Typography";
import { spacing, color, palette } from "../../../../theme";
import { TextInput } from "../../../TextInput/TextInput";
import { TextInput as TextInputType } from "react-native-paper";
import { RetrieveMHADataObject } from "../MHALocationSearch/MHALocationSearch.props";

import ManualAddressForm, { ManualAddressFormValues } from "./ManualAddressForm";
import { Button } from "@/components/Button";
import { InfoItemIcon } from "@/components/InfoItem";

interface PropTypes {
  label: string;
  inputText: string;
  errorMessage: string | null;
  search: () => void;
  onChangeText: (input: string) => void;
  setCustomLocation: (data: RetrieveMHADataObject) => void;
  children: React.ReactNode;
  patientAddress?: boolean;
  disableStrictPostcodeValidation?: boolean;
  onDismiss?: () => void;
  onClear?: () => void;
}

const INITIAL_ADDRESS = {
  address: "",
  city: "",
  postcode: "",
};

export default function MHASearchInput(props: PropTypes) {
  const address = props.inputText.split(",");
  const textInputRef = useRef<TextInputType>(null);
  const [manualEntry, setManualEntry] = useState(false);

  const givenAddress = useMemo(() => {
    const hasCurrent = address.length >= 3;

    if (hasCurrent) {
      return {
        address: address.slice(0, address.length - 2).join(", "),
        city: address[address.length - 2].trim(),
        postcode: address[address.length - 1].trim(),
      };
    }

    return INITIAL_ADDRESS;
  }, []);

  const manualInitialValues = {
    address: givenAddress.address || "",
    city: givenAddress.city || "",
    postcode: givenAddress.postcode,
  };

  const onManualAddressSubmit = (values: ManualAddressFormValues) => {
    props.setCustomLocation({ locationName: values });
  };

  useEffect(() => {
    if (manualEntry) {
      props.onClear && props.onClear();
      textInputRef?.current && textInputRef.current.blur();
    } else {
      textInputRef?.current && textInputRef.current.focus();
    }
  }, [manualEntry]);

  return (
    <View style={styles.container}>
      {!manualEntry && (
        <View style={styles.inputContainer}>
          {manualEntry && <View style={styles.overlay} />}

          <TextInput
            ref={textInputRef}
            label={props.label}
            autoCorrect={false}
            autoCapitalize="characters"
            disabled={manualEntry}
            returnKeyType="done"
            value={props.inputText}
            onChangeText={val => props.onChangeText(val)}
            style={styles.inputBoxes}
            testID="search-input"
          />

          <TouchableOpacity
            onPress={props.onClear}
            activeOpacity={1}
            disabled={props.inputText === "" || !props.onClear}
          >
            <Icon
              name={props.inputText && props.inputText !== "" ? IconName.refresh : IconName.search}
              color={props.inputText && props.inputText !== "" ? color.primary : palette.greyBlue}
            />
          </TouchableOpacity>
        </View>
      )}

      {!manualEntry && props?.patientAddress && (
        <View style={styles.infoText}>
          <TextWithIcon
            iconName="info-outline"
            iconColor={palette.blue}
            iconPosition="flex-start"
            format={TypographyType.Small}
            style={{
              color: palette.slate,
            }}
          >
            If the patient has no fixed abode, Click "Enter Address Manually" and add NFA into the postcode line.
          </TextWithIcon>
        </View>
      )}
      <Button mode="contained" style={styles.clear} onPress={() => setManualEntry(!manualEntry)}>
        {manualEntry ? "Use Location Search" : "Enter address manually"}
      </Button>

      <View style={styles.resultsContainer}>
        {manualEntry || props.inputText === "" ? (
          <>
            {!manualEntry && <View style={styles.overlay} />}

            <ManualAddressForm
              initialValues={manualInitialValues}
              disableStrictPostcodeValidation={props.disableStrictPostcodeValidation}
              onSubmit={onManualAddressSubmit}
              autofocusFirst={manualEntry}
            />
          </>
        ) : (
          <>
            {!!props.errorMessage && (
              <Text format={TypographyType.Tiny} type="paper" style={styles.errorMessage}>
                {props.errorMessage}
              </Text>
            )}
            {props.children}
          </>
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    ...Platform.select({
      web: {
        minHeight: 100,
      },
      default: {
        flexBasis: "auto",
        flex: 0,
        flexShrink: 1,
      },
    }),
    overflow: "hidden",
  },
  inputContainer: {
    flexDirection: "row",
    maxHeight: 80,
    marginVertical: spacing[15],
  },
  inputBoxes: {
    backgroundColor: color.background,
    zIndex: 10,
    flex: 1,
  },
  errorMessage: {
    paddingVertical: spacing[10],
    color: color.textError,
  },
  resultsContainer: {
    ...Platform.select({
      web: {
        paddingBottom: spacing[50],
      },
    }),
    flex: 1,
    flexGrow: 1,
    // flexShrink: 1,
    flexBasis: "100%",
  },
  clear: {
    color: color.oncall,
    marginBottom: spacing[15],
    alignSelf: "center",
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: color.background,
    opacity: 0.45,
    zIndex: 30,
  },
  infoText: {
    marginTop: spacing[10],
    marginBottom: spacing[10],
    // paddingRight: Platform.OS === "web" ? 0 : spacing[30],
  },
});
