import { FormStatus } from "libs/types/API";

export function isEditable(
  formStatus: FormStatus,
  userGroups: Array<"AMHP" | "Doctor" | "ExternalDoctor" | string> | undefined,
  isCreator: boolean,
  isExternalDoctor: boolean,
  authors: string[],
  isUnattachedForm?: boolean
): boolean {
  const isInProgress = [FormStatus.s10_draft, FormStatus.s30_changes_in_progress].includes(formStatus);
  const isInAmhpInbox = formStatus === FormStatus.s60_signed_in_amhp_team_inbox;
  const amhpLookingAtExternalDoctorForm =
    userGroups?.includes("AMHP") && isCreator && authors.some((a) => a.startsWith("Form-"));

  if (amhpLookingAtExternalDoctorForm) {
    return false;
  }

  if (!isCreator) {
    if ((isExternalDoctor || isUnattachedForm) && isInProgress) {
      return true;
    }
    return false;
  }

  if (isInProgress) {
    return true;
  }

  if (isInAmhpInbox && userGroups?.includes("Doctor")) {
    return false;
  }

  return false;
}
