import React, { useMemo, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { useRecoilValue } from "recoil";

import { useAPIVersion } from "@/hooks";
import { API_V2_TOGGLES } from "@/api/types";
import SearchResultsV1 from "@/components/SearchResults/SearchResults.gql";
import SearchResultsV2 from "@/components/SearchResults/SearchResults.context";

import { LocationInput, Gender } from "libs/types/API";

import { BackButtonProps } from "@/components/BackButton/BackButton.props";
import { ErrorMessage } from "@/components/Error/Error";
import { RedirectUser } from "@/components/RedirectUser";
import { ContentWrap } from "@/components/ContentWrap";
import Loading from "@/components/Loading";

import { AppScreen } from "@/layouts/AppScreen/AppScreen";
import { RouteKeys } from "@/navigationv2";
import { AMHPAssessmentsRoutes, AMHPTabRoutes, ScreenNavigationProps, ScreenOptions } from "@/navigationv2/types";
import { createScreenTitle } from "@/navigationv2/utilities";
import { color } from "@/theme";
import { mqWeb } from "@/utils/helpers";
import { searchCriteria as recoilSearchCriteria } from "@/utils/recoil/index";

export interface ScreenProps {
  locationName: string;
  location: LocationInput;
  timeSpan: { start: Dayjs; end: Dayjs };
  distance: number;
  assessment?: { id: string; doctors?: { id: string; name: string }[] };
  doctorGender?: Gender | null;
  doctorLanguages?: string[];
  doctorSpecialties?: string[];
  doctorName?: string;
}

interface PropTypes
  extends ScreenNavigationProps<AMHPTabRoutes, AMHPAssessmentsRoutes, RouteKeys.SearchResultsScreen> {}

export default function SearchResultsScreen() {
  const searchCriteria = useRecoilValue(recoilSearchCriteria);
  const { v2, loading } = useAPIVersion(API_V2_TOGGLES.NEARBY_DOCTOR_SEARCH);

  const SearchResults = v2 ? SearchResultsV2 : SearchResultsV1;
  const SearchResultsMemo = React.memo(SearchResults);

  const [assessment, setAssessment] = useState<ScreenProps>({
    locationName: "",
    location: { lat: 52, lon: 0 },
    timeSpan: { start: dayjs(), end: dayjs() },
    distance: 10,
    ...searchCriteria,
  });

  React.useEffect(() => {
    if (searchCriteria) {
      setAssessment({
        ...searchCriteria,
        timeSpan: {
          start: searchCriteria.timeSpan.start,
          end: searchCriteria.timeSpan.end,
        },
      });
    }
  }, [searchCriteria]);

  const backButtonConfig: BackButtonProps = useMemo(
    () => ({
      enabled: true,
      float: true,
      color: color.textwhite,
      routeName: RouteKeys.CreateAssessmentScreen,
      routeParams: {
        assessmentId: assessment.assessment && assessment.assessment.id,
        editDoctor: false,
      },
    }),
    [assessment.assessment && assessment.assessment.id]
  );
  const isWebView = mqWeb();

  return searchCriteria ? (
    <AppScreen
      contextLabel="Assessment"
      pageTitle={isWebView ? "Search Results" : `Search${"\n"}Results`}
      backButton={backButtonConfig}
    >
      {assessment.assessment ? (
        loading ? (
          <ContentWrap>
            <Loading />
          </ContentWrap>
        ) : (
          <SearchResultsMemo {...assessment} />
        )
      ) : (
        <ErrorMessage />
      )}
    </AppScreen>
  ) : (
    <RedirectUser redirectTo={RouteKeys.AssessmentScreen} />
  );
}

const screenOptions: ScreenOptions = {
  title: createScreenTitle("Search Results"),
};

SearchResultsScreen.screenOptions = screenOptions;
