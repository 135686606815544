import React, { useContext } from "react";
import { View, StyleSheet } from "react-native";
import { MHAFormLabelProps } from "libs/types/mhaForms";
import { TypographyType } from "@/models/Typography";
import { color, spacing } from "@/theme";
import Text from "@/components/Text";
import { MhaFormThemeContext, themes } from "../MHAFormContainer/ThemeContext";
import { isConveyanceForm } from "libs/mhaForms/helpers";

export const MHAFormLabel = (props: MHAFormLabelProps) => {
  const { instruction, guidance, pdfOnlyGuidance } = props;
  if (!instruction && !guidance && !props.readonly) {
    // if there is nothing to render in the editable mode, don't render an empty pink box
    return <></>;
  }
  // If no form type passed, accept the default
  let theme = useContext(MhaFormThemeContext);
  // The form type will determine the background color of the text A4, A8, A11 should be pink
  if (props.formType) {
    theme = isConveyanceForm(props.formType) ? themes.blue : themes.pink;
  }

  //* Guidance
  // guidance = all interfaces
  // pdfOnlyGuidance = only displays in the PDF version
  return (
    <View style={[theme, styles.infoTextWrapper, props.readonly && styles.infoTextWrapperReadonly]}>
      <Text format={TypographyType.Tiny}>
        {instruction}
        {instruction && " "}
        {(!!guidance || (!!pdfOnlyGuidance && props.readonly)) && (
          <Text format={TypographyType.Tiny} style={styles.guidance}>
            {props.readonly && pdfOnlyGuidance ? pdfOnlyGuidance : guidance}
          </Text>
        )}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  infoTextWrapper: {
    paddingVertical: spacing[10],
    flexDirection: "row",
    alignItems: "baseline",
    paddingHorizontal: spacing[40],
  },
  infoTextWrapperReadonly: { paddingHorizontal: 0, paddingBottom: 0 },
  guidanceWithInstruction: {
    marginLeft: spacing[5],
  },
  guidance: {
    fontStyle: "italic",
  },
});
