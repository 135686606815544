import InAppBrowser from "react-native-inappbrowser-reborn";
import { Platform, Linking } from "react-native";

interface WebLink extends Link {
  openInNewTab?: boolean;
}

const isWeb = Platform.OS === "web";

interface Link {
  url: string;
}

type GuardWebFunction = typeof openNative | typeof openInApp;

/**
 * Guard against web-based devices.
 *
 * @param fn Function to run if not a web-based device.
 */
function guardWeb<T extends Link>(fn: GuardWebFunction) {
  return (options: T) => {
    return isWeb ? openLinkWeb(options) : fn(options);
  };
}

interface OpenInAppAuthorisedLink extends WebLink {
  isAuthLink: true | undefined;
  redirectUrl: string;
  cancelCallback?: () => void;
}

interface OpenInAppUnauthorisedLink extends WebLink {
  isAuthLink?: false;
  redirectUrl?: never;
  cancelCallback?: () => void;
}

type OpenInAppLink = OpenInAppAuthorisedLink | OpenInAppUnauthorisedLink;

/**
 * Open the given URL using the in-app browser.
 *
 * @author [Alex McCabe](https://github.com/alexmccabe)
 * @param {OpenInAppParams} options
 * @param {string} options.url URL to open.
 * @param {Boolean} [options.isAuthLink=false] Used in conjunction with
 *     `useInAppBrowser: true`. If set to `true` this will open a special
 *     version of the in-app browser that will perform an authorisation request
 *     to the given URL.
 * @param {string} [options.redirectUrl] Required if `isAuthLink: true`.
 * @param {Function} [options.cancelCallback] an optional callback to fire when cancelled
 * @throws {Error} When isAuthLink is true but redirectUrl is empty.
 * @throws {Error} When InAppBrowser or Linking are unable to open the
 *     given URL.
 * @throws {Error} When InAppBrowser is not available. This error should never
 *     be thrown as we are checking the Platform before running this function.
 */
async function openInApp(options: OpenInAppLink): Promise<any> {
  const { isAuthLink = false, redirectUrl, url } = options;
  // const isAvailable = await InAppBrowser.isAvailable();
  const isAvailable = true; // * Tweak to test issue with some SSO users not being able to authenticate

  if (isAuthLink && !redirectUrl) {
    throw new Error("`redirectUrl` is a required parameter when using `isAuthLink`.");
  }

  if (isAvailable) {
    InAppBrowser.close();

    try {
      const result =
        isAuthLink && redirectUrl ? await InAppBrowser.openAuth(url, redirectUrl) : await InAppBrowser.open(url);

      if (result.type === "success") {
        return await Linking.openURL(result.url);
      }
      if (result.type === "cancel" && options.cancelCallback) {
        options.cancelCallback();
      }
    } catch (error) {
      console.error(error);
      throw new Error("Error opening url: `" + url + "`.");
    }
  }
}

interface OpenNativeLink extends WebLink {
  validate?: boolean;
}

/**
 * Open a URL natively. This will kick the user out to the native devices
 * default browser.
 *
 * @author [Alex McCabe](https://github.com/alexmccabe)
 * @param {OpenNativeParams} options
 * @param {string} options.url URL to open.
 * @param {Boolean} [options.validate=false] If set to `true` the url will be
 *     validated before attempting to open. This could prevent any malformed
 *     URLs from being opened and causing errors. Only used when
 *     `useInAppBrowser: false`
 */
function openNative(options: OpenNativeLink) {
  const { url, validate = true } = options;

  if (validate) {
    return Linking.canOpenURL(url).then(success => {
      if (success) {
        return Linking.openURL(url);
      }

      throw new Error("Unable to open URL: `" + url + "`");
    });
  }

  return Linking.openURL(url);
}

/**
 * Open the given URL using the web-based device browser. Should never be called
 * on a native device (iOS, Android).
 *
 * @author [Alex McCabe](https://github.com/alexmccabe)
 * @param {WebParams} options
 * @param {string} options.url URL to open.
 * @param {Boolean} [options.openInNewTab=false] Should the URL be opened in
 *     a new tab.
 */
export function openLinkWeb(options: WebLink) {
  const { openInNewTab = false, url } = options;

  openInNewTab ? window.open(url, "_blank") : (window.location.href = url);
}

export const openLinkNative = guardWeb<OpenNativeLink>(openNative);
export const openLinkInApp = guardWeb<OpenInAppLink>(openInApp);
