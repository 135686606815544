import { FormType } from "libs/types/mhaForms";
import { FormStatus } from "libs/types/API";

import { isAmhpForm } from "libs/mhaForms/helpers/isAmhpForm";
import { isConveyanceForm } from "./isAmhpForm";

export function canUserDeleteForm(
  formType: FormType,
  formStatus: FormStatus,
  userGroups: Array<"AMHP" | "Doctor" | "ExternalDoctor" | string> | undefined,
  isAuthor: boolean,
  isCreator: boolean,
  isAssessmentAmhp = false
): boolean {
  if ((isAmhpForm(formType) || isConveyanceForm(formType)) && userGroups?.includes("AMHP")) {
    // amhps can delete amhp forms when they own the assessment
    if (isAssessmentAmhp || isAuthor) {
      return true;
    }
    return false;
  } else if (isAmhpForm(formType) || isConveyanceForm(formType)) {
    // non-amhps can't delete an amhp form
    return false;
  } else if (userGroups?.includes("AMHP")) {
    // amhps can delete doctor forms when they are in control of it
    return formStatus === FormStatus.s40_signed || formStatus === FormStatus.s50_signed_and_sent;
  } else if (isAuthor && isCreator) {
    // doctors can delete a doctor form when they are an author and creator
    // we check both author and creator, to stop second doctors on joint forms deleting them;
    // (second doctors on joint forms are only an author of the form; never the creator)
    return true;
  } else {
    return false;
  }
}
