import React, { useState, useCallback } from "react";
import { RefreshControl } from "react-native";
import { useRecoilValue } from "recoil";

import { AppScreen } from "@/layouts/AppScreen/AppScreen";
import ClaimResults from "@/components/DoctorClaimsResults";
import { useOnMount, useOnScreenFocus } from "@/hooks";
import { RouteKeys } from "@/navigationv2";
import { DoctorTabRoutes, DoctorClaimsRoutes, ScreenNavigationProps, ScreenOptions } from "@/navigationv2/types";
import { createScreenTitle } from "@/navigationv2/utilities";
import { userDetails } from "@/utils/recoil";

interface PropTypes extends ScreenNavigationProps<DoctorTabRoutes, DoctorClaimsRoutes, RouteKeys.ClaimsScreen> {}

export default function ClaimsScreen() {
  const user = useRecoilValue(userDetails);
  const [refreshRequired, setRefreshRequired] = useState(false);
  const [isUnmounted, setIsUnmounted] = useState(false);

  const onRefresh = useCallback(() => !isUnmounted && setRefreshRequired(true), [isUnmounted]);

  useOnMount(() => {
    return () => setIsUnmounted(true);
  });

  useOnScreenFocus(() => {
    setRefreshRequired(true);
  });

  return (
    <AppScreen
      contextLabel="Claims"
      pageTitle="My Claims"
      refreshControl={<RefreshControl refreshing={refreshRequired} onRefresh={onRefresh} />}
      greyWebBG={true}
    >
      <ClaimResults
        username={user ? user.id : ""}
        refreshRequired={refreshRequired}
        onRefreshComplete={setRefreshRequired}
      />
    </AppScreen>
  );
}

const screenOptions: ScreenOptions = {
  title: createScreenTitle("My Claims"),
};

ClaimsScreen.screenOptions = screenOptions;
