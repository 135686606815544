import dayjs, { Dayjs } from "dayjs";
import React, { useMemo } from "react";
import { Animated, StyleSheet, View } from "react-native";
import { useLazyQuery, useMutation, useQuery } from "@apollo/react-hooks";

import {
  CREATE_DOCTOR_VISIT,
  DELETE_DOCTOR_VISIT,
  DOCTOR_AND_ASSESSMENT,
  convertToProfile,
} from "@/models/DoctorProfile";
import API from "@/api";
import { useAPIVersion } from "@/hooks";
import { API_V2_TOGGLES } from "@/api/types";

import { CREATE_CONTACTED_DOCTORS, GET_ASSESSMENT, GetAssessmentResponse } from "@/models/Assessment";
import { color } from "../../theme";
import { recordEvent } from "../../utils/analytics";
import { ErrorMessage } from "../Error/Error";
import Loading from "../Loading";
import { AppScreen } from "../../layouts/AppScreen/AppScreen";
import { DoctorProfile } from "./DoctorProfile";
import {
  CreateDoctorVisitFunc,
  CreateDoctorVisitV2Func,
  DeleteDoctorVisitFunc,
  GetS12DoctorResponse,
  AssessmentWithDoctors,
  CreateDoctorVisitVariables,
  CreateDoctorVisitResponse,
} from "./DoctorProfile.props";
import { BackButtonProps } from "../BackButton/BackButton.props";
import { checkOfflineCache, checkMutationInput } from "../../utils/helpers";
import { LocationInput } from "libs/types/API";
import { AnalyticsEvent } from "libs/analytics/events";

import { Availability } from "libs/types/availability";
import notFalsy from "../../../../packages/libs/utils/notFalsy";

const backButtonConfig: BackButtonProps = {
  enabled: true,
  float: true,
  color: color.textWhite,
};

export type DoctorLoadResponse = { doctor: GetS12DoctorResponse; assessment: AssessmentWithDoctors };

interface PropTypes {
  id?: string;
  assessmentId?: string;
  assessmentLocation?: LocationInput;
  assessmentDistance?: number;
  assessmentTimeSpan?: { start: Dayjs; end: Dayjs };
  addVisit?: boolean;
  onDoctorLoad?: (data: DoctorLoadResponse) => void;
}

export default function DoctorProfileGQL(props: PropTypes) {
  const { v2 } = useAPIVersion(API_V2_TOGGLES.CREATE_DOCTOR_VISIT);
  const [deleteDoctorVisit] = useMutation(DELETE_DOCTOR_VISIT);
  const [createDoctorVisit, mutationResult] = useMutation(CREATE_DOCTOR_VISIT);
  const [createContactedDoctor] = useMutation(CREATE_CONTACTED_DOCTORS);
  const addDoctorLoading = mutationResult.loading;

  const queryOptions = useMemo(
    () => ({
      variables: { doctorId: props.id, assessmentId: props.assessmentId },
      fetchPolicy: "cache-and-network" as const,
    }),
    [props.id, props.assessmentId]
  );

  const { error, data, loading, client, variables, refetch } = useQuery<DoctorLoadResponse>(
    DOCTOR_AND_ASSESSMENT,
    queryOptions
  );

  // Used to trigger refetch after `createDoctorVisitV2`
  const [getAssessment] = useLazyQuery<GetAssessmentResponse>(GET_ASSESSMENT, {
    variables: { id: props.assessmentId },
  });

  const complete = checkOfflineCache(client, DOCTOR_AND_ASSESSMENT, variables);

  data && props.onDoctorLoad && props.onDoctorLoad(data);

  const createDoctorVisitV2 = async (body: CreateDoctorVisitVariables) => {
    const res = await API.post<CreateDoctorVisitResponse, CreateDoctorVisitVariables>("/createDoctorVisit", body);
    getAssessment();

    return res;
  };

  if (!complete && error) {
    return (
      <AppScreen contextLabel="Doctor Profile" pageTitle={""} backButton={backButtonConfig}>
        <ErrorMessage apolloError={error} />
      </AppScreen>
    );
  } else if (!complete && loading) {
    return (
      <AppScreen contextLabel="Doctor Profile" pageTitle={""} backButton={backButtonConfig}>
        <Loading />
      </AppScreen>
    );
  } else if (data && data.doctor && data.assessment) {
    const { doctor, assessment } = data;
    return (
      <View style={styles.animationContainer}>
        <Animated.View style={styles.animationItem}>
          <DoctorProfile
            doctor={convertToProfile(doctor)}
            refresh={() =>
              refetch
                ? refetch({
                    id: props.id,
                    assessmentId: props.assessmentId,
                    assessmentLocation: assessment.location,
                    assessmentTimeSpan: props.assessmentTimeSpan,
                    assessmentDistance: props.assessmentDistance,
                  })
                : Promise.reject(new Error("Could not get data"))
            }
            assessment={assessment}
            assessmentLocation={props.assessmentLocation || assessment.location}
            assessmentDistance={props.assessmentDistance}
            assessmentTimeSpan={props.assessmentTimeSpan}
            addDoctorToAssessment={createDoctorVisitBuilder(
              v2,
              createDoctorVisit,
              createDoctorVisitV2,
              doctor,
              assessment
            )}
            addContactedDoctor={createContactedDoctor}
            addDoctorLoading={addDoctorLoading}
            addVisit={props.addVisit}
            removeDoctorFromAssessment={deleteDoctorVisitBuilder(
              deleteDoctorVisit,
              doctor,
              assessment &&
                assessment.doctorVisits?.items
                  ?.filter(notFalsy)
                  .filter(i => doctor && i.doctor.id === doctor.id && !i.doctorVisitClaimId)
                  // first or default hack
                  .reduce((_prev, curr) => curr, undefined)
            )}
          />
        </Animated.View>
      </View>
    );
  } else {
    return (
      <AppScreen contextLabel="Doctor Profile" pageTitle={""} backButton={backButtonConfig}>
        <Loading />
      </AppScreen>
    );
  }
}

// caller responsible for catching error
const deleteDoctorVisitBuilder = (fn?: DeleteDoctorVisitFunc, doctor?: { id: string }, visit?: { id: string }) => {
  return doctor && visit && fn
    ? () => {
        return fn({
          variables: {
            id: visit.id,
          },
        }).then(x => (x && x.data ? Promise.resolve(x.data.visit) : Promise.reject(new Error("Invalid Arguments"))));
      }
    : undefined;
};

// if doctor and assessment exist, return a function that will execute
// the mutation fn and return the assessmentId as a result.
//  user of the function is responsible to handle promise
// rejections
const createDoctorVisitBuilder = (
  useV2: boolean,
  createDoctorVisitV1: CreateDoctorVisitFunc,
  createDoctorVisitV2: CreateDoctorVisitV2Func,
  doctor: { id: string; email: string } | null,
  assessment: {
    id: string;
    createdAt: string;
    amhpTeamId: string;
    amhpId: string;
    doctorVisits: {
      items: Array<{
        id: string;
        doctor: {
          id: string;
        };
        notes?: string[]; // plain strings
      } | null> | null;
    } | null;
  } | null
) => {
  const existingVisits =
    assessment && assessment?.doctorVisits?.items?.length ? assessment.doctorVisits.items.length + 1 : 1;
  return doctor && assessment
    ? async (confirmedDateTime: string, notes: string[] | undefined, availabilities: Availability[]) => {
        const data = {
          doctorId: doctor.id,
          assessmentId: assessment.id,
          time: confirmedDateTime,
          notes,
        };

        const res = await (useV2
          ? createDoctorVisitV2(data)
          : createDoctorVisitV1({
              variables: checkMutationInput<CreateDoctorVisitVariables>({
                doctorId: doctor.id,
                assessmentId: assessment.id,
                time: confirmedDateTime,
                notes,
              }),
              refetchQueries: [
                {
                  query: GET_ASSESSMENT,
                  variables: { id: assessment.id },
                },
              ],
            }));

        if (res && res.data) {
          recordEvent(
            AnalyticsEvent.DOCTOR_CONFIRMED,
            {
              teamId: assessment.amhpTeamId,
              amhpId: assessment.amhpId,
              assessmentId: assessment.id,
              doctorId: doctor.id,
              doctorEmail: doctor.email || "no email",
              doctorAvailability: availabilities
                .map(a => a.type)
                .filter(x => !!x)
                .join(","),
              doctorAvailabilityDetail: availabilities
                .filter(a => a.type)
                .map(a => (a.contractList ? a.contractList : a.mht ? a.mht.abbreviation : undefined))
                .join(","),
            },
            {
              [`doctor ${existingVisits} added`]: dayjs().diff(assessment.createdAt, "minute"),
            }
          );
          return Promise.resolve(res.data.createDoctorVisit.assessment.id);
        }
        // TODO: Handle and log errors
        return Promise.reject(new Error("Server Error"));
      }
    : () => {
        // TODO: Handle and log errors
        return Promise.reject(new Error("Invalid Arguments"));
      };
};

const styles = StyleSheet.create({
  animationItem: {
    flex: 1,
  },
  animationContainer: {
    position: "relative",
    flex: 1,
    flexGrow: 1,
  },
});
