import React from "react";
import { Query } from "react-apollo";

import { AmhpAssessmentQueryVariables } from "libs/types/API";

import { checkOfflineCache } from "@/utils/helpers";

import { ErrorMessage } from "../Error/Error";
import Loading from "../Loading";
import { GET_ASSESSMENT, GetAssessmentResponse } from "@/models/Assessment";

import SendToDoctor from "./SendToDoctor";

interface PropTypes {
  assessmentId: string;
}

const SendToDoctorGQL = (props: PropTypes) => {
  const { assessmentId } = props;

  return (
    <Query<GetAssessmentResponse, AmhpAssessmentQueryVariables>
      query={GET_ASSESSMENT}
      variables={{ id: assessmentId }}
      fetchPolicy="cache-and-network"
    >
      {({ client, data, error, loading, variables }) => {
        const complete = checkOfflineCache(client, GET_ASSESSMENT, variables);

        if (!complete && error) {
          return <ErrorMessage apolloError={error} />;
        } else if (!complete && loading) {
          return <Loading />;
        } else if (data && data.assessment) {
          const { assessment } = data;
          return <SendToDoctor assessment={assessment} />;
        } else {
          return <Loading />;
        }
      }}
    </Query>
  );
};

export default SendToDoctorGQL;
