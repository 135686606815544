import dayjs from "dayjs";
import {
  HolidayModelActionTypes,
  HolidayControllerActionTypes,
  holidayModelState,
  holidayControllerState,
  StateModelProps,
  StateControllerProps,
} from "./initialState";

export function holidayModelReducer(
  state: StateModelProps,
  { type, payload }: { type: string; payload?: any }
): StateModelProps {
  switch (type) {
    case HolidayModelActionTypes.SET_HOLIDAY_START_DATE:
      return {
        ...state,
        holidayStartDate: payload,
      };
    case HolidayModelActionTypes.SET_HOLIDAY_END_DATE:
      return {
        ...state,
        holidayEndDate: payload,
      };
    case HolidayModelActionTypes.SET_START_TIME:
      return {
        ...state,
        startTime: payload,
        holidayStartDate: state.holidayStartDate
          .hour(payload.hours)
          .minute(payload.minutes)
          .millisecond(1),
      };
    case HolidayModelActionTypes.SET_END_TIME:
      return {
        ...state,
        endTime: payload,
        holidayEndDate: state.holidayEndDate
          .hour(payload.hours)
          .minute(payload.minutes)
          .millisecond(1),
      };
    case HolidayModelActionTypes.SET_HOLIDAY_NOTES:
      return {
        ...state,
        holidayNotes: payload,
      };
    case HolidayModelActionTypes.TOGGLE_HOLIDAY_VISIBILITY:
      return {
        ...state,
        holidayVisibility: !state.holidayVisibility,
      };
    case HolidayModelActionTypes.SET_EDIT_FORM:
      return {
        ...state,
        ...payload,
      };
    case HolidayModelActionTypes.RESET_HOLIDAY_FORM:
      return {
        ...holidayModelState,
        holidayStartDate: dayjs(new Date(new Date(payload).setHours(9, 0, 0, 1))),
        holidayEndDate: dayjs(new Date(new Date(payload).setHours(17, 0, 0, 1))),
      };
    default:
      return state;
  }
}

export function holidayControllerReducer(
  state: StateControllerProps,
  { type, payload }: { type: string; payload?: any }
): StateControllerProps {
  switch (type) {
    case HolidayControllerActionTypes.TOGGLE_ALL_DAY_EVENT:
      return {
        ...state,
        allDayEvent: !state.allDayEvent,
      };
    case HolidayControllerActionTypes.SET_EDIT_FORM:
      return {
        ...state,
        ...payload,
      };
    case HolidayControllerActionTypes.TOGGLE_HOLIDAY_DATE_ERROR:
      return {
        ...state,
        holidayDateError: !state.holidayDateError,
      };
    case HolidayControllerActionTypes.RESET_CONTROLLER:
      return holidayControllerState;
    default:
      return state;
  }
}
