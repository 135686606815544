/* eslint-disable @typescript-eslint/camelcase */
import ReactDOM from "react-dom";
import * as React from "react";
import { RecoilRoot } from "recoil";
import { Platform } from "react-native";

// import ContainerNavigator from "@/navigation/ContainerNavigator";
import Loading from "@/components/Loading";
// import { onNavigationChange } from "@/app/utils/helpers";
import Root from "@/components/Root/Root";

const navigationPersistenceKey = __DEV__ ? `NavigationStateDEV${Platform.OS.toLocaleUpperCase()}` : null;
/*
import whyDidYouRender from "@welldone-software/why-did-you-render";

whyDidYouRender(React, {
  trackAllPureComponents: true
});
*/
// const MemoContainer = React.memo(ContainerNavigator, () => true);

ReactDOM.render(
  <RecoilRoot>
    <React.Suspense fallback={<Loading />}>
      {/* <MemoContainer
        persistenceKey={navigationPersistenceKey}
        enableURLHandling={false}
        onNavigationStateChange={onNavigationChange}
      /> */}
      <Root />
    </React.Suspense>
  </RecoilRoot>,
  document.getElementById("root")
);
