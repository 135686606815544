import React, { useMemo, useCallback, useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";

import { useRecoilState, useRecoilValue } from "recoil";
import { ParamListBase, useNavigation } from "@react-navigation/native";
import dayjs from "dayjs";

import { FormStatus } from "libs/types/API";
import { FormType } from "libs/types/mhaForms";

import {
  saveForm as apiSaveForm,
  formatFormikToStorageSections,
  UPDATE_AMHP_PROFILE,
  UpdateAmhpProfileFunc,
} from "@/models/MHAForm/api";
import {
  GET_FORM,
  GetFormResponse,
  transformResponseToModel,
  MHAFormMetadata,
  transformResponseToMetadata,
  FormikValues,
} from "@/models/MHAForm/MHAForm";
import { apiStatus, mhaFormApiStatus, userDetails, offlineMHAForm } from "../../../utils/recoil";

import MHAFormContainer from "./MHAFormContainer";
import { Forms } from "libs/mhaForms/formSections";
import { getMhaFormVersion } from "libs/mhaForms/helpers";
import ENV from "@/config";
import { StackNavigationProp } from "@react-navigation/stack";
import Loading from "@/components/Loading";
import { useAPIVersion } from "@/hooks";
import { API_V2_TOGGLES } from "@/api/types";

interface Variables {
  assessmentId?: string;
  blurCounter?: number;
  formType?: FormType;
  formId?: string;
}

const MHAFormContainerGQL = (props: Variables) => {
  const user = useRecoilValue(userDetails);
  const navigation = useNavigation<StackNavigationProp<ParamListBase>>();

  const [formId, setFormId] = useState(props.formId);
  const [submitStatus, setSubmitStatus] = useRecoilState(mhaFormApiStatus);
  const [offlineForm, setOfflineForm] = useRecoilState(offlineMHAForm);

  const { v2 } = useAPIVersion(API_V2_TOGGLES.MHA_FORMS);

  const queryOptions = useMemo(
    () => ({
      variables: {
        id: formId || "!!!",
        isExistingForm: !!formId,
        isAmhpUser: user?.groups?.includes("AMHP") || false,
        isDoctorUser: user?.groups?.includes("Doctor") || false,
        isExternalDoctor: user?.id.indexOf("Form-") === 0 || false,
        isAssessment: !!props.assessmentId,
        doctorId: user && user.groups && user.groups.includes("Doctor") ? user.id : "!!!",
        amhpId: user && user.groups && user.groups.includes("AMHP") ? user.id : "!!!",
        assessmentId: props.assessmentId || "!!!",
        isConveyanceForm: props.formType === FormType.AUTHORITY_TO_CONVEY,
      },
      fetchPolicy: "cache-and-network" as const,
      errorPolicy: "ignore" as const,
      returnPartialData: true,
    }),
    [props.assessmentId, formId]
  );

  const form = useQuery<GetFormResponse>(GET_FORM, queryOptions);

  const { data, metadata } = useMemo((): {
    data: { sections: FormikValues } | null;
    metadata: MHAFormMetadata | null;
  } => {
    const formType =
      props.formType ||
      (form.data?.getForm && "type" in form.data?.getForm && form.data?.getForm.type) ||
      form.data?.getExternalForm?.type ||
      null;

    if (form.data && Object.keys(form.data).length > 0 && formType && !form.loading) {
      const { data } = form.data.getForm || form.data.getExternalForm || {};
      const meta = transformResponseToMetadata(form.data, formType);

      const mhaFormVersion = getMhaFormVersion(ENV.ENV, meta.createdAt);
      const formSections =
        (data && data.sections) ||
        formatFormikToStorageSections(
          Forms[formType](mhaFormVersion).formSections.reduce(
            (acc, curr, i) => (typeof curr.state !== "undefined" ? { ...acc, [`section${i + 1}`]: curr.state } : acc),
            {
              formType: formType as FormType,
            } as FormikValues
          )
        );

      const usernameIncludesFormId = RegExp(form.data.getForm?.id || "");
      const formAuthorIsExternalDoctor: boolean =
        form.data &&
        form.data.getForm?.authors.length === 1 &&
        usernameIncludesFormId.test(form.data.getForm.authors[0]);

      const isFormViewerAmhp = user && user.groups && user.groups.includes("AMHP");
      /*
      To override form data, the form must be in an editable state (not signed),
      And we only allow the author of the form to update the data, so that they review the changes
      before passing it to the next user. Otherwise, if we have one user pass a form that has different
      data when the next person receives it, it will lead to confusion.
      We assume that if a form does not have a status or an id, it is a new form that should be updated
      We also allow an AMHP to update an external doctor form, with the caveat that it must not be signed,
      this is because the external doctor does not have access to the assessment and so cannot be the person
      to sync the data between assessemnt and form.
      */
      const canOverrideFormDataWithAssessmentData: boolean =
        !("status" in meta) ||
        !meta.id ||
        (meta.status !== FormStatus.s40_signed &&
          meta.status !== FormStatus.s50_signed_and_sent &&
          (meta.authors.includes(user?.id || "nobody") || !!(formAuthorIsExternalDoctor && isFormViewerAmhp)));
      // If you are looking to prepopulate fields on a form from data entered on a previous form, look at transformResponseToModel below.
      return {
        data: transformResponseToModel(formSections, form.data, canOverrideFormDataWithAssessmentData, formType),
        metadata: meta,
      };
    } else {
      return { data: null, metadata: null };
    }
  }, [
    // specify each item, as the data reference can stay the same but new objects added in future requests.
    form.data?.getForm,
    form.data?.getExternalForm,
    form.data?.getAssessment,
    form.data?.getS12Doctor,
    form.data?.visitsByAssessment,
    form.data?.getAmhpProfile,
    form.data?.getFormsByAssessment,
    form.loading,
  ]);
  const [formMetadata, setFormMetadata] = useState<MHAFormMetadata | null>(metadata);

  useEffect(() => {
    // If GetForm is undefined then refetch the code using below parameters.
    if (!form.data?.getForm) {
      form.refetch(queryOptions.variables);
    }
    if (form.data?.getForm) {
      const { data, ...metadata } = form.data?.getForm; // TODO handle external form here

      setFormMetadata(metadata);
    } else if (form.data?.getExternalForm) {
      const { data, ...metadata } = form.data?.getExternalForm; // TODO handle external form here

      setFormMetadata(metadata);
    }
  }, [form.data?.getForm, form.data?.getExternalForm, formId]);

  const [updateAmhpProfile] = useMutation<UpdateAmhpProfileFunc>(UPDATE_AMHP_PROFILE);

  /**
   * Submit form data to API
   *
   * @prop metatdata
   * @prop data
   * @prop prevStatus The status of the form before saving
   * @todo 1. Will not work for amhp team inbox submissions
   * @todo 2. Show error message / toast
   * @todo 3. Set error on api status
   */
  const saveForm = useCallback(
    async (metadata: Omit<MHAFormMetadata, "notes">, data?: FormikValues, prevStatus?: FormStatus) => {
      if (apiStatus.inProgress) {
        console.warn("Attempted to save MHA Form Data when save is currently in progress.");
        return {
          isError: true,
          message: "Save in progress",
        };
      }

      setSubmitStatus({ ...apiStatus, inProgress: true });

      const formType = props.formType || ("type" in metadata && metadata.type) || props.formType;

      if (!formType) {
        throw new Error("Invalid form type");
      }
      const meta = {
        ...formMetadata,
        ...metadata,
        id: formId === "unsaved" ? undefined : formId || metadata.id,
        type: formType || props.formType,
        amhpTeamId: metadata.amhpTeamId || "!!!" /* [1] */,
        nextVersion: nextVersion({ ...formMetadata, ...metadata }),
      };
      // We have added the legal name to the amhp profile schema, but we have no way to update it on any screen.
      // Therefore, we are using changes to the amhp name on the MHA form to update this legal name.
      // When we have made a change to the data on the form, and we move from one section of the form to another,
      // we reach this point in the code and the form data is updated. In order to also update the legal name on the amhp
      // profile, we search through the form sections, pull out the amhp name, and update the legal name on the amhp profile.
      // In the file called MHAForm.ts, you will see that we capture the legal name from the amhp profile and stick this in the
      // name field on the form.
      for (const section in data) {
        if (data[section].amhpName) {
          updateAmhpProfile({
            variables: {
              input: {
                id: queryOptions.variables.amhpId,
                legalName: data[section].amhpName,
              },
            },
          });
        }
      }

      const results = await apiSaveForm(v2, meta, data, (user ? user.id.indexOf("Form-") : -1) > -1);

      const newStatus = "status" in results ? results.status : null;
      if (prevStatus !== newStatus) {
        form.refetch(form.variables);
      }

      if ("isError" in results) {
        setSubmitStatus({
          ...submitStatus,
          inProgress: false,
          isComplete: true,
          isErrored: true,
          error: null /* [3] */,
        });
        if (results.message?.includes("Network Error")) {
          // if saving offline, make sure we are not updating status as otherwise will run into issue saving these changes when back online
          const metadata = prevStatus ? { ...meta, status: prevStatus } : meta;
          setOfflineForm({
            type: meta.type || formType,
            data: data as FormikValues,
            metadata,
            createdAt: dayjs(),
          });
        }
        /* [2] */
      } else {
        setFormMetadata(results);
        setSubmitStatus({ ...submitStatus, inProgress: false, isComplete: true, isSuccess: true });

        if (!formId || formId === "unsaved") {
          navigation.setParams({ formId: results.id });
          setFormId(results.id);
        }
      }

      return results;
    },
    [apiStatus.inProgress, metadata, formMetadata, props.formType, form.data, formId]
  );

  const hasUnsavedChanges =
    (formId === "unsaved" && offlineForm) || (offlineForm && offlineForm.metadata.id === formId);

  if (formMetadata || (!form.loading && !form.data?.getForm && !form.data?.getExternalForm)) {
    const formType =
      props.formType ||
      (formMetadata && "type" in formMetadata && formMetadata.type) ||
      (metadata && "type" in metadata && metadata.type) ||
      null;
    if (!formType) {
      throw new Error("Invalid Routing");
    }

    return (
      <MHAFormContainer
        formType={formType}
        {...props}
        formData={hasUnsavedChanges ? offlineForm?.data : data?.sections}
        formMetadata={hasUnsavedChanges ? offlineForm?.metadata : formMetadata || metadata}
        isExternalDoctor={user?.id.indexOf("Form-") === 0 || false}
        saveForm={saveForm}
        blurCounter={props.blurCounter}
      />
    );
  } else {
    return <Loading />;
  }
};

export default MHAFormContainerGQL;

function nextVersion(metadata: { version?: number } | null): number {
  if (metadata && typeof metadata.version === "number") {
    return metadata.version + 1;
  }
  return 0;
}
