import { formatLongDate } from "libs/dates";
import React, { useCallback, useState } from "react";
import { Platform, RefreshControl, StyleSheet, View } from "react-native";
import { FAB, Portal } from "react-native-paper";
import { useRecoilValue, useRecoilState } from "recoil";

import AssessmentResults from "@/components/AssessmentResults";
import { AppScreen } from "@/layouts/AppScreen/AppScreen";
import { useOnMount, useOnScreenFocus } from "@/hooks";
import { RouteKeys } from "@/navigationv2";
import { AllAMHPRoutes, ScreenOptions } from "@/navigationv2/types";
import { createScreenTitle } from "@/navigationv2/utilities";
import { color, palette, spacing } from "@/theme";
import { useDeviceWidth, mqWeb } from "@/utils/helpers";
import {
  userDetails,
  refreshAssessmentsRequired as recoilRefreshAssessmentsRequired,
  amhpTeamId as recoilAmhpTeamId,
} from "@/utils/recoil/index";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { useExportAssessments } from "@/components/ExportCSV";

export default function AssessmentScreen() {
  const navigation = useNavigation<StackNavigationProp<AllAMHPRoutes>>();
  const displayExportAssessments = useExportAssessments();
  const user = useRecoilValue(userDetails);
  const [refreshAssessmentsRequired, setRefreshAssessmentsRequired] = useRecoilState(recoilRefreshAssessmentsRequired);
  const amhpTeamId = useRecoilValue(recoilAmhpTeamId);
  const [isUnmounted, setIsUnmounted] = useState(false);
  const onRefresh = useCallback(() => !isUnmounted && setRefreshAssessmentsRequired(true), [isUnmounted]);
  const isWebView = mqWeb();
  const deviceWidth = useDeviceWidth();

  useOnMount(() => {
    return () => setIsUnmounted(true);
  });

  useOnScreenFocus(() => {
    setRefreshAssessmentsRequired(true);
  });

  return (
    <AppScreen
      contextLabel={isWebView ? "S12 Solutions" : formatLongDate()}
      pageTitle="Assessments"
      refreshControl={<RefreshControl refreshing={refreshAssessmentsRequired} onRefresh={onRefresh} />}
      testID="Assessment__Screen"
      greyWebBG={false}
    >
      <View style={styles.container}>
        <AssessmentResults
          refreshRequired={refreshAssessmentsRequired}
          onRefreshComplete={setRefreshAssessmentsRequired}
          amhpID={user ? user.id : ""}
          amhpTeamId={amhpTeamId || (user && user.teams && user.teams[0] ? user.teams[0] : "")}
        />
      </View>

      {!isWebView && (
        <Portal>
          {Platform.OS === "web" && (
            <FAB
              onPress={displayExportAssessments}
              style={[styles.fab, styles.topFab, deviceWidth <= 320 && styles.fabSmall]}
              color={palette.navy}
              icon="save"
              label="Export Assessments"
              testID="ExportAssessments__Button"
            />
          )}
          <FAB
            onPress={() => navigation.navigate(RouteKeys.CreateAssessmentScreen)}
            style={[styles.fab, deviceWidth <= 320 && styles.fabSmall]}
            color={palette.navy}
            icon="create"
            label="Create Assessment"
            testID="CreateAssessment__Button"
          />
        </Portal>
      )}
    </AppScreen>
  );
}

const screenOptions: ScreenOptions = {
  title: createScreenTitle("My Assessments"),
};

AssessmentScreen.screenOptions = screenOptions;

const styles = StyleSheet.create({
  container: {
    backgroundColor: color.background,
    flexDirection: "column",
  },
  fab: {
    backgroundColor: palette.yellow,
    position: "absolute",
    bottom: 20,
    right: spacing[20],
  },
  topFab: {
    bottom: 80,
  },
  fabSmall: {
    transform: [{ scale: 0.85 }, { translateY: 15 }, { translateX: 32 }],
  },
  fabDisabled: {
    backgroundColor: color.backgroundGreyDark,
  },
  today: {
    color: color.primaryBorderFrosted,
  },
});
