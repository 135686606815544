import React, { Dispatch, SetStateAction } from "react";
import { StyleSheet, View } from "react-native";

import Text from "@/components/Text";
import { MultiSelectContainer, MultiSelectOption } from "@/components/MultiSelect";

import { TypographyType } from "@/models/Typography";
import { FormType } from "libs/types/mhaForms";
import { Forms } from "libs/mhaForms/formSections";
import { palette, spacing } from "@/theme";
import { getMhaFormVersion } from "libs/mhaForms/helpers";
import ENV from "@/config";

interface AssessmentFormsMultiSelectProps {
  forms: {
    id: string;
    formType: FormType;
    signed: string[];
    createdAt: string;
    patientName: string;
    expired?: boolean;
  }[];
  selectedForms: string[];
  setSelectedForms: Dispatch<SetStateAction<string[]>>;
  showPatientNameOnFormOptions?: boolean;
}

export const AssessmentFormsMultiSelect: React.FC<AssessmentFormsMultiSelectProps> = ({
  forms,
  selectedForms,
  setSelectedForms,
  showPatientNameOnFormOptions = false,
}) => {
  return (
    <MultiSelectContainer>
      {forms.map(form => {
        const signees =
          form.signed.length === 1
            ? form.signed[0].split("@")[0]
            : form.signed.length === 2
            ? `${form.signed[0].split("@")[0]} and ${form.signed[1].split("@")[0]}`
            : `${form.signed
                .slice(0, -1)
                .map(s => s.split("@")[0])
                .join(", ")}, and ${form.signed[form.signed.length - 1]}`;
        const signedByText = `Signed by ${signees}`;
        const checked = selectedForms.includes(form.id);
        const mhaFormVersion = getMhaFormVersion(ENV.ENV, form.createdAt);
        const formInfo = Forms[form.formType](mhaFormVersion);

        const formTitle =
          form.formType === FormType.AUTHORITY_TO_CONVEY ? (
            <View style={styles.heading}>
              <Text
                format={showPatientNameOnFormOptions ? TypographyType.Small : TypographyType.RegularBold}
                color={checked ? palette.blue : palette.navy}
              >
                {formInfo.title}
              </Text>
            </View>
          ) : (
            <View style={styles.heading}>
              <Text
                format={showPatientNameOnFormOptions ? TypographyType.Small : TypographyType.RegularBold}
                color={checked ? palette.blue : palette.navy}
              >{`Section ${formInfo.section} `}</Text>
              <Text
                format={TypographyType.Tiny}
                color={checked ? palette.blue : palette.navy}
                style={styles.formType}
              >{`(${form.formType})`}</Text>
            </View>
          );

        return (
          <MultiSelectOption
            disabled={form.expired}
            key={form.id}
            checked={checked}
            onPress={() => {
              if (selectedForms.includes(form.id)) {
                setSelectedForms(selectedForms.filter(formId => formId !== form.id));
              } else {
                setSelectedForms([...selectedForms, form.id]);
              }
            }}
          >
            {showPatientNameOnFormOptions ? (
              <Text format={TypographyType.RegularBold} color={checked ? palette.blue : palette.navy} marginBottom={5}>
                {form.patientName}
              </Text>
            ) : null}

            {formTitle}

            {formInfo.shortTitle && (
              <View style={styles.subHeading}>
                <Text format={TypographyType.Small} color={checked ? palette.blue : palette.navy}>
                  {formInfo.shortTitle}
                </Text>
              </View>
            )}

            <Text format={TypographyType.Micro} color={palette.greyBlue} marginTop={5}>
              {signedByText}
            </Text>
          </MultiSelectOption>
        );
      })}
    </MultiSelectContainer>
  );
};

const styles = StyleSheet.create({
  formType: {
    transform: [{ translateY: -1 }],
  },

  heading: {
    flexDirection: "row",
    alignItems: "flex-end",
  },

  subHeading: {
    marginBottom: spacing[5],
  },
});
