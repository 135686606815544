import dayjs, { Dayjs } from "dayjs";
import { string } from "libs/validators/yup";
const now = dayjs();
export const availabilityModelState: StateModelProps = {
  availabilities: [
    {
      date: now,
      startTime: {
        hours: now.add(1, "hour").get("hour"),
        minutes: now.hour(),
      },
      endTime: {
        hours: now.add(2, "hour").get("hour"),
        minutes: 0,
      },
      hasError: false,
      postcodeError: false,
      touched: false,
    },
  ],
  defaultMHT: {
    value: "-",
    label: "Independent",
  },
  defaultOncall: false,
  defaultRota: { id: "", name: "" },
  defaultNotes: "",
  defaultPostcode: "", // value of the first availability you add
  repeatWeekdays: [],
  eventSeriesEndDate: dayjs(),
  repeatWeeklyCount: "1",
};

export const availabilityControllerState: StateControllerProps = {
  // UI state
  isSingleItem: "true",
  eventSeriesHasEnd: true,
  isSaving: false,
  postcodeError: false, // at least one of the availabilities has postcodeError; refactor to array of indicies of availability items with error on them
  openErrorModal: false,
  repeatWeekly: "false",
  activeElements: [],
  showMoreInformation: false,
};

export enum AvailabilityModelActionTypes {
  ADD_AVAILABILITY = "ADD_AVAILABILITY",
  SET_AVAILABILITY = "SET_AVAILABILITY",
  SET_ALL_AVAILABILITIES = "SET_ALL_AVAILABILITIES",
  DELETE_AVAILABILITY = "DELETE_AVAILABILITY",
  RESET_AVAILABILITY_DATE = "RESET_AVAILABILITY_DATE",
  SET_SINGLE_AVAILABILITY = "SET_SINGLE_AVAILABILITY",
  SET_DEFAULT_MHT = "SET_DEFAULT_MHT",
  SET_DEFAULT_ONCALL = "SET_DEFAULT_ONCALL",
  SET_DEFAULT_ROTA = "SET_DEFAULT_ROTA",
  SET_BASE_POSTCODE = "SET_BASE_POSTCODE",
  SET_DEFAULT_NOTE = "SET_DEFAULT_NOTE",
  SET_REPEAT_WEEKDAYS = "SET_REPEAT_WEEKDAYS",
  SET_END_DATE = "SET_END_DATE",
  REPEAT_WEEKLY_COUNT = "REPEAT_WEEKLY_COUNT",
  SET_EDIT_FORM = "SET_EDIT_FORM",
  RESET_FORM = "RESET_FORM",
}

export enum AvailabilityControllerActionTypes {
  TOGGLE_SINGLE_ITEM = "TOGGLE_SINGLE_ITEM",
  SET_WEEK = "SET_WEEK",
  TOGGLE_HAS_END_DATE = "TOGGLE_HAS_END_DATE",
  TOGGLE_REPEAT_WEEK = "TOGGLE_REPEAT_WEEK",
  SET_SAVING = "SET_SAVING",
  RESET_FORM = "RESET_FORM",
  SET_EDIT_FORM = "SET_EDIT_FORM",
  RESET_CONTROLLER = "RESET_CONTROLLER",
  SET_TIME_ERROR = "SET_TIME_ERROR",
  TOGGLE_REPEAT_WEEKLY = "CHECK_REPEAT_WEEKLY",
  SET_ACTIVE_ELEMENT = "SET_ACTIVE_ELEMENT",
  SET_OPEN_ERROR_MODAL = "SET_OPEN_ERROR_MODAL",
  SET_POSTCODE_ERROR = "SET_POSTCODE_ERROR",
  SET_EDIT_CONTROLLER = "SET_EDIT_CONTROLLER",
  SET_SHOW_MORE_INFORMATION = "SET_SHOW_MORE_INFORMATION",
}

export const weekdays = [
  { label: "Mon", value: "MO" },
  { label: "Tue", value: "TU" },
  { label: "Wed", value: "WE" },
  { label: "Thu", value: "TH" },
  { label: "Fri", value: "FR" },
  { label: "Sat", value: "SA" },
  { label: "Sun", value: "SU" },
];

export interface MHTState {
  value: string;
  label: string;
}

export interface AvailabilityState {
  date: Dayjs;
  startTime: {
    hours: number;
    minutes: number;
  };
  endTime: {
    hours: number;
    minutes: number;
  };
  hasError: boolean;
  postcodeError: boolean;
  touched: boolean;
  postcode?: string;
  mht?: MHTState;
  oncall?: boolean;
  overnight?: boolean;
  notes?: string;
  rota?: { id: string; name: string };
}

export interface StateModelProps {
  availabilities: AvailabilityState[];
  defaultMHT: MHTState;
  defaultOncall: boolean;
  defaultNotes: string;
  defaultRota: { id: string; name: string };
  defaultPostcode: string;
  repeatWeekdays: string[];
  eventSeriesEndDate: Dayjs;
  repeatWeeklyCount: string;
}

export interface StateControllerProps {
  isSingleItem: string;
  eventSeriesHasEnd: boolean;
  isSaving: boolean;
  postcodeError: boolean;
  openErrorModal: boolean;
  repeatWeekly: string;
  activeElements: number[];
  showMoreInformation: boolean;
}

interface StateProps extends StateModelProps, StateControllerProps {}

export interface StateContext {
  state: StateProps;
  dispatch: ({ type }: { type: string; payload?: any }) => void;
  controllerDispatch: ({ type }: { type: string; payload?: any }) => void;
}
