import React from "react";
import { Picker, StyleSheet } from "react-native";

const SelectInput = React.forwardRef(
  (
    props: {
      value: string;
      label: string;
      disabled?: boolean;
      data: { id: string; name: string }[];
      onValueChange: (v: string, i?: number, data?: { value: string; label?: string }[]) => void;
      onBlur?: () => void;
      onFocus?: () => void;
    },
    ref: (ref: any) => void
  ) => (
    <Picker
      ref={ref}
      enabled={!(props.disabled === true)}
      selectedValue={props.value}
      onValueChange={(v, i) =>
        props.onValueChange(
          v,
          i,
          props.data.map(d => ({ value: d.id, label: d.name }))
        )
      }
      style={styles.picker}
    >
      {props.data.map(o => (
        <Picker.Item label={o.name || ""} value={o.id} key={o.id} />
      ))}
    </Picker>
  )
);

SelectInput.displayName = "SelectInput";

export default SelectInput;

const styles = StyleSheet.create({
  picker: { height: 40 },
});
