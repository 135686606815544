import React, { useMemo, useCallback } from "react";
import { StyleSheet } from "react-native";
import { IconButton } from "react-native-paper";
import { ParamListBase, useNavigation } from "@react-navigation/native";

import { BackButtonProps } from "./BackButton.props";
import { mqWeb, useDeviceWidth } from "@/utils/helpers";
import { color } from "@/theme";
import { StackNavigationProp } from "@react-navigation/stack";

const BackButton = (props: BackButtonProps) => {
  const isWebView = mqWeb();
  const navigation = useNavigation<StackNavigationProp<ParamListBase>>();
  const deviceWidth = useDeviceWidth();

  const backButtonStyle = props.float
    ? deviceWidth > 320 && deviceWidth < 1000
      ? styles.float
      : [styles.float, styles.floatSmall]
    : styles.button;

  const onPress = useCallback(() => {
    if (props.onPress) props.onPress();

    props.routeName ? navigation.navigate(props.routeName, props.routeParams) : navigation.goBack();
  }, [props.onPress, props.routeName, props.routeParams]);

  const backButtonIcon = useMemo(() => [backButtonStyle, props.style, isWebView && styles.webButton], [
    props.style,
    props.float,
    deviceWidth,
  ]);

  if (!navigation.canGoBack()) {
    return null;
  }

  return (
    <IconButton
      size={25}
      onPress={onPress}
      icon="keyboard-backspace"
      color={props.color || color.textWhite}
      style={backButtonIcon}
      accessibilityLabel="BackButton"
    />
  );
};

export default BackButton;

const styles = StyleSheet.create({
  button: {
    position: "relative",
    top: 0,
    left: 0,
  },
  float: {
    position: "absolute",
    top: -3,
    left: 25,
    zIndex: 50,
  },
  floatSmall: {
    left: 5,
    top: -5,
  },

  webButton: {
    top: -2,
    left: 16,
  },
});
