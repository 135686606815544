import React, { useState, useMemo } from "react";
import { Query } from "react-apollo";
import { ApolloClient } from "apollo-boost";
import { useApolloClient } from "@apollo/react-hooks";
import { useSetRecoilState, useRecoilValue, useRecoilState } from "recoil";

import {
  AMHP_GET_CREATE_ASSESSMENT,
  GetTeamInformationResponse,
  GET_TEAM_INFORMATION,
  GetAmhpCreateAssessmentResponse,
  CreateAssessmentItem,
} from "@/models/Assessment";
import { buildErr, getAssessmentError } from "@/models/Error";
import {
  lastException,
  amhpTeamId as recoilAmhpTeamId,
  duplicateAssessment as recoilDuplicateAssessment,
} from "@/utils/recoil";

import CreateAssessment from "./CreateAssessment";

interface PropTypes {
  assessmentId?: string;
  editDoctor?: boolean;
  isDuplicate?: boolean;
  refreshRequired: boolean;
  onRefreshComplete: (arg0: boolean) => void;
}

export default function CreateAssessmentGQL(props: PropTypes) {
  const queryOptions = useMemo(
    () => ({
      query: AMHP_GET_CREATE_ASSESSMENT,
      variables: { id: props.assessmentId },
      fetchPolicy: props.refreshRequired ? ("network-only" as const) : ("cache-first" as const),
    }),
    [props.assessmentId, props.refreshRequired]
  );
  const [assessment, setAssessment] = useState<CreateAssessmentItem>();

  const amhpTeamId = useRecoilValue(recoilAmhpTeamId);
  const [duplicateAssessment, setDuplicateAssessment] = useRecoilState(recoilDuplicateAssessment);
  const setLastException = useSetRecoilState(lastException);

  const client: ApolloClient<any> = useApolloClient();

  if (props.assessmentId) {
    client
      .query<GetAmhpCreateAssessmentResponse>(queryOptions)
      .then(res => {
        if (res.data) {
          setTimeout(() => props.onRefreshComplete(true));
          setTimeout(() => setAssessment(res.data.assessment), 500);
        }
      })
      .catch(
        buildErr(
          {
            ...getAssessmentError,
            additional: props.assessmentId,
          },
          setLastException
        )
      );
  }

  if (props.isDuplicate && duplicateAssessment && !assessment) {
    setAssessment(duplicateAssessment);
    setDuplicateAssessment(null);
  }

  return (
    <Query<GetTeamInformationResponse> query={GET_TEAM_INFORMATION} fetchPolicy="cache-and-network">
      {({ data }) => {
        const amhpTeam = currentTeam(data?.profile?.teams, amhpTeamId);
        const teamMembers = teamMembersForTeam(data?.profile?.teams, amhpTeam?.id || null);
        const commonAddresses = commonAddressesForTeam(data?.profile?.teams, amhpTeam?.id || null);

        return (
          <CreateAssessment
            assessmentId={props.assessmentId}
            editDoctor={props.editDoctor}
            assessment={assessment}
            teamId={amhpTeamId || (data && data.profile && data.profile.teams.items[0].amhpTeam.id)}
            featureFlags={(amhpTeam && amhpTeam.featureFlags) || "{}"}
            teamDisplayName={displayNameForTeam(data?.profile?.teams, amhpTeamId)}
            teamMembers={teamMembers}
            commonAddresses={commonAddresses}
            // if we fetch an existing assessment, create a new component by changing the key
            key={assessment ? assessment.id : "new"}
            startHour={props.refreshRequired ? new Date().getHours() : undefined}
          />
        );
      }}
    </Query>
  );
}

function teamMembersForTeam(
  teams: GetTeamInformationResponse["profile"]["teams"] | undefined,
  currentTeamId: string | null
) {
  const team = currentTeam(teams, currentTeamId);

  if (!team) {
    return [];
  }

  return (
    team.users?.items
      .filter(notEmpty)
      ?.map(a => ({ id: a.amhp?.id, name: a.amhp?.name, deleted: a.amhp?.deleted }))
      // remove duplicates
      .filter((member, i, a) => a.findIndex(x => x.id === member.id) === i)
      // remove deleted
      .filter((member, i, a) => !member?.deleted ?? false)
      // sort ascending
      .sort((a, b) => {
        return (a.name > b.name && 1) || -1;
      }) || []
  );
}

function displayNameForTeam(
  teams: GetTeamInformationResponse["profile"]["teams"] | undefined,
  currentTeamId: string | null
) {
  const team = currentTeam(teams, currentTeamId);

  if (!team || !teams || !teams.items || teams.items.length < 2) {
    return undefined;
  }

  return team.name;
}

function commonAddressesForTeam(
  teams: GetTeamInformationResponse["profile"]["teams"] | undefined,
  currentTeamId: string | null
) {
  const team = currentTeam(teams, currentTeamId);

  if (!team) {
    return [];
  }

  return (
    team.commonAddresses?.items.map((ca, i) => ({
      ...ca,
      id: i.toString(),
    })) || []
  );
}

function currentTeam(teams: GetTeamInformationResponse["profile"]["teams"] | undefined, currentTeamId: string | null) {
  if (!teams || !teams.items || !teams.items.length) {
    return null;
  }

  const team =
    currentTeamId && teams.items.find(teamUser => teamUser.amhpTeam.id === currentTeamId)
      ? teams.items.find(teamUser => teamUser.amhpTeam.id === currentTeamId)
      : teams.items[0];

  if (!team) {
    return null;
  }
  return team.amhpTeam;
}

function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}
