import React from "react";
import { StyleSheet, View, StyleProp, ViewStyle } from "react-native";
import { ApolloError } from "apollo-boost";
import * as Sentry from "@sentry/core";
import { useNavigation, useRoute, ParamListBase } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";

import Icon from "../Icon";

import { useIsFirstRouteInParent } from "@/hooks";
import { S12Error, networkError } from "@/models/Error";
import { TypographyType } from "@/models/Typography";
import { spacing, color } from "@/theme";

import { Button } from "../Button";
import MiniLabel from "../MiniLabel";
import Text from "../Text";

interface ErrorBoundaryMessagePropTypes {
  error: S12Error;
  reset: () => void;
}

export const ErrorBoundaryMessage = (props: ErrorBoundaryMessagePropTypes) => {
  return (
    <View style={styles.boundaryContainer}>
      <Icon name="warning" color={color.unavailable} size={48} style={styles.icon} />
      <View style={styles.label}>
        <MiniLabel style={styles.label}>{props.error.displayText || "There was an application error."}</MiniLabel>
        {!!props.error.id && <Text format={TypographyType.RegularBold}>{`Error Id: ${props.error.id}`}</Text>}
        <Button mode="contained" onPress={() => props.reset()} style={styles.button}>
          Reload
        </Button>
      </View>
    </View>
  );
};

interface ErrorMessagePropTypes {
  message?: string;
  apolloError?: ApolloError;
  style?: StyleProp<ViewStyle>;
}

export const ErrorMessage = (props: ErrorMessagePropTypes) => {
  const navigation = useNavigation<StackNavigationProp<ParamListBase>>();
  const route = useRoute();
  const isFirstRouteInParent = useIsFirstRouteInParent();

  if (props.apolloError && !props.apolloError.networkError) {
    Sentry.captureException(new S12Error(props.apolloError));
  }

  return (
    <View style={[styles.container, props.style]}>
      <Icon name="warning" color={color.unavailable} size={48} style={styles.icon} />
      <View style={styles.label}>
        <MiniLabel style={styles.label}>
          {props.apolloError && props.apolloError.networkError
            ? networkError.message
            : props.message || "There was an error displaying this data"}
        </MiniLabel>

        {isFirstRouteInParent && (
          <Button mode="contained" onPress={() => navigation.replace(route.name, route?.params)} style={styles.button}>
            Reload
          </Button>
        )}

        {!isFirstRouteInParent && (
          <Button mode="contained" onPress={navigation.goBack} style={styles.button}>
            Go Back
          </Button>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  boundaryContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    height: "100%",
  },
  container: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: spacing[20],
    backgroundColor: color.background,
  },
  label: {
    maxWidth: 350,
    alignItems: "center",
  },
  icon: {
    marginBottom: spacing[15],
  },
  button: {
    alignSelf: "auto",
    marginBottom: 0,
    maxWidth: 250,
    minWidth: 200,
    marginTop: spacing[10],
  },
});
