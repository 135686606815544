import React, { useMemo } from "react";
import { StyleSheet, View } from "react-native";

import { TypographyType } from "@/models/Typography";
import { palette } from "../../../theme";

import { ContentWrap } from "../../ContentWrap";
import { LogoVector } from "../../LogoVector";
import Text from "../../Text";

interface VideoContainerBackgroundProps {
  showWaitingForPeopleText: boolean;
}

export const VideoContainerBackground = React.memo<VideoContainerBackgroundProps>(({ showWaitingForPeopleText }) => {
  const contentStyles = useMemo(() => [styles.content, { height: showWaitingForPeopleText ? "50%" : "100%" }], [
    showWaitingForPeopleText,
  ]);
  return (
    <View style={styles.bg}>
      <ContentWrap style={contentStyles}>
        {showWaitingForPeopleText && (
          <Text
            format={TypographyType.HeadingMedium}
            color={palette.white}
            marginTop={50}
            marginBottom={75}
            align="center"
          >
            Waiting for other participants to join
          </Text>
        )}

        <View style={styles.brand}>
          <LogoVector size={70} greyscale />
          <Text format={TypographyType.Brand} color={palette.grey} style={styles.text}>
            S12 Solutions
          </Text>
        </View>
        <Text format={TypographyType.Micro} color={palette.grey} marginTop={-18} style={styles.subText}>
          Video Meeting Room
        </Text>
      </ContentWrap>
    </View>
  );
});

const styles = StyleSheet.create({
  bg: {
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    backgroundColor: palette.shadowDark,
    zIndex: -100,
    opacity: 1,
  },

  brand: {
    flexDirection: "row",
    alignItems: "center",
    transform: [{ translateX: -10 }],
    opacity: 0.15,
  },

  content: {
    justifyContent: "center",
    alignItems: "center",
  },

  text: {
    fontSize: 26,
    marginLeft: -3,
    transform: [{ translateY: -8 }],
  },
  subText: {
    fontSize: 11.6,
    marginLeft: -3,
    textTransform: "uppercase",
    transform: [{ translateX: 26 }, { translateY: -8 }],
    opacity: 0.15,
  },
});
