import { useBottomSheet } from "@/hooks";
import { userDetails } from "@/utils/recoil";
import { useRecoilValue } from "recoil";
import { ExportAssessments } from "./ExportAssessments";
import { ExportClaims } from "./ExportClaims";
import { ExportVisits } from "./ExportVisits";

export const useExportVisits = () => {
  const { openBottomSheet } = useBottomSheet();
  const user = useRecoilValue(userDetails);

  const displayExportVisits = () =>
    openBottomSheet({
      type: "generic",
      data: {
        heading: "Export Visits",
        component: ExportVisits,
        componentProps: {
          username: user?.id,
        },
      },
    });

  return displayExportVisits;
};

export const useExportClaims = () => {
  const { openBottomSheet } = useBottomSheet();
  const user = useRecoilValue(userDetails);

  const displayExportClaims = () =>
    openBottomSheet({
      type: "generic",
      data: {
        heading: "Export Claims",
        component: ExportClaims,
        componentProps: {
          username: user?.id,
        },
      },
    });

  return displayExportClaims;
};

export const useExportAssessments = () => {
  const { openBottomSheet } = useBottomSheet();
  const user = useRecoilValue(userDetails);

  const displayExportAssessments = () =>
    openBottomSheet({
      type: "generic",
      data: {
        heading: "Export Assessments",
        component: ExportAssessments,
        componentProps: {
          username: user?.id,
        },
      },
    });

  return displayExportAssessments;
};
