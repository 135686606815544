import * as Yup from "yup";
import { ComponentElement, FormFieldsAmhp } from "libs/types/mhaForms";
import { FormSection } from "../..";

const componentElements: ComponentElement[] = [
  {
    component: "Checkbox",
    fieldName: FormFieldsAmhp.ConfirmForms,
    componentProps: {
      options: [
        {
          status: "electronic",
          label:
            "I confirm that the following statutory forms have been submitted electronically to the receiving hospital:",
        },
      ],
    },
  },
  {
    component: "BottomTextInput",
    fieldName: FormFieldsAmhp.FormsBeingSubmittedElectronically,
    componentProps: {
      label: "Forms being submitted electronically",
      defaultText: "(e.g. 1x S2 AMHP application, 2 x S2 Medical Recommendations)",
      compact: true,
    },
    genericProps: {
      conditionallyRenderedBy: FormFieldsAmhp.ConfirmForms,
      conditionallyRenderedValues: ["electronic"],
    },
  },
  {
    component: "Checkbox",
    fieldName: FormFieldsAmhp.ConfirmFormsAccompanying,
    componentProps: {
      options: [
        {
          status: "accompanying",
          label: "I confirm that the following statutory forms will accompany the patient, if any: ",
        },
      ],
    },
  },
  {
    component: "BottomTextInput",
    fieldName: FormFieldsAmhp.FormsBeingSubmitted,
    componentProps: {
      label: "Forms being submitted",
      defaultText: "(e.g. 1x S2 AMHP application, 2 x S2 Medical Recommendations)",
      compact: true,
    },
    genericProps: {
      conditionallyRenderedBy: FormFieldsAmhp.ConfirmFormsAccompanying,
      conditionallyRenderedValues: ["accompanying"],
    },
  },
];

const formDetails: FormSection = {
  title: "Form Details",
  state: {
    confirmForms: [],
    confirmFormsAccompanying: [],
    formsBeingSubmitted: "",
    formsBeingSubmittedElectronically: "",
  },

  validation: Yup.object().shape(
    {
      confirmForms: Yup.array().when("confirmFormsAccompanying", {
        is: (formsAccompanying: string[]) => formsAccompanying && formsAccompanying.length > 0,
        then: Yup.array().notRequired(),
        otherwise: Yup.array().min(1, "you must select at least one option"),
      }),
      confirmFormsAccompanying: Yup.array().when("confirmForms", {
        is: (confirmForms: string[]) => confirmForms && confirmForms.length > 0,
        then: Yup.array().notRequired(),
        otherwise: Yup.array().min(1, "you must select at least one option"),
      }),
      formsBeingSubmitted: Yup.string().when("confirmFormsAccompanying", {
        is: (confirmFormsAccompanying: string) => confirmFormsAccompanying.includes("accompanying"),
        then: Yup.string().trim().required("Please list forms accompanying patient"),
        otherwise: Yup.string().nullable().notRequired(),
      }),
      formsBeingSubmittedElectronically: Yup.string().when("confirmForms", {
        is: (confirmForms: string) => confirmForms.includes("electronic"),
        then: Yup.string().trim().required("Please list forms being submitted electronically"),
        otherwise: Yup.string().nullable().notRequired(),
      }),
    },
    [["confirmForms", "confirmFormsAccompanying"]]
  ),

  componentElements,
};

export default formDetails;
