/**
 * Custom version of the MHA Form record List
 * Basic - Because it doesn't contain history elements
 * Reassign - because it adds the option for the reassign button
 */
import React from "react";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { AllAMHPRoutes } from "@/navigationv2/types";
import { useSetRecoilState } from "recoil";
import { snackbarMessage, lastException } from "@/utils/recoil";
import { buildErr } from "@/models/Error";
import { alert } from "@/utils/helpers";
import { Dayjs } from "dayjs";
import { deleteForm } from "@/models/MHAForm/api";
import { MHAFormRecord } from "../MHAFormRecord";
import { RouteKeys } from "@/navigationv2";
import { Forms } from "libs/mhaForms/formSections";
import { FormListItem, FormType } from "libs/types/mhaForms";
import { isAmhpForm, isConveyanceForm, canUserDeleteForm, isJointForm, getMhaFormVersion } from "libs/mhaForms/helpers";
import { FilterLabel } from "@/components/FilterLabel";
import Text from "@/components/Text";
import { TypographyType } from "@/models/Typography";
import { palette } from "@/theme";
import { FormStatus } from "libs/types/API";
import { UserDetails } from "@/utils/recoil/props";
import { recordEvent } from "@/utils/analytics";
import { AnalyticsEvent } from "libs/analytics/events";
import ENV from "@/config";
import { AssignedForm } from "@/components/DoctorAssignedForms/DoctorAssignedFormsList";
import { useAPIVersion, useBottomSheet } from "@/hooks";
import { transformResponseToMetadata, transformResponseToModel } from "@/models/MHAForm/MHAForm";
import AssignForReview from "../../MHAFormContainer/AssignForReview";
import { API_V2_TOGGLES } from "@/api/types";

const recordDeleteEvent = (
  result: "success" | "failure",
  isAmhpForm: boolean,
  fr: FormListItemBasicReassignable,
  assessmentId: string,
  user?: UserDetails
) => {
  const event =
    result === "success"
      ? isAmhpForm
        ? AnalyticsEvent.MHA_FORM_AMHP_DELETED
        : AnalyticsEvent.MHA_FORM_DOCTOR_DELETED
      : isAmhpForm
      ? AnalyticsEvent.MHA_FORM_AMHP_DELETED_FAILURE
      : AnalyticsEvent.MHA_FORM_DOCTOR_DELETED_FAILURE;

  const attributes = {
    status: fr.status,
    assessmentId: assessmentId,
    ...(user && { user: user.name }),
  };

  recordEvent(event, attributes);
};

interface FormListItemBasicReassignable {
  id: string;
  status: FormStatus;
  formType: FormType;
  historyElements: string[];
  signed: string[];
  createdBy?: string;
  authors?: string[];
  data: AssignedForm;
  isDeleted?: boolean;
  createdAt: string;
  patientName: string;
  amhpTeamName?: string;
  reassignable?: boolean; // enable reassign button if other teams exist that have feature enabled
  examinationDate?: Dayjs;
  amhpTeam?: any;
}

export default function MHAFormRecordsListBasicWithReassign(props: {
  formRecords: FormListItemBasicReassignable[];
  assessmentId: string;
  refetch: () => void;
  isAmhpForm: boolean;
  user: UserDetails | null;
  isAssessmentAmhp?: boolean;
  removeCTAButtons?: boolean;
}) {
  const navigation = useNavigation<StackNavigationProp<AllAMHPRoutes>>();
  const setMessage = useSetRecoilState(snackbarMessage);
  const setLastException = useSetRecoilState(lastException);
  const { openBottomSheet, closeBottomSheet } = useBottomSheet();
  const { v2 } = useAPIVersion(API_V2_TOGGLES.MHA_FORMS);
  const reassignForm = (form: FormListItemBasicReassignable) => {
    // forms without data cannot be acted on
    if (!form.data || !form.data || form.data.data === null) {
      return;
    }

    const { sections } = form.data.data;
    const meta = transformResponseToMetadata({ getForm: form.data }, form.formType);
    const data = transformResponseToModel(sections, {}, false, form.formType);

    openBottomSheet({
      type: "generic",
      data: {
        heading: "Reassign Form",
        component: AssignForReview,
        componentProps: {
          formData: data.sections,
          metadata: meta,
          onSuccess: () => {
            closeBottomSheet();
            recordEvent(AnalyticsEvent.MHA_FORM_REASSIGNED, {
              newAmhpTeam: meta.amhpTeamId || "!!!",
            });
          },
          onError: () => recordEvent(AnalyticsEvent.MHA_FORM_REASSIGNED_FAILURE),
        },
      },
    });
  };

  return (
    <>
      <FilterLabel text="Sorted by *last updated* (most recent first)" disableMargin="top" />
      {!props.formRecords.length ? (
        <>
          <Text format={TypographyType.Small} color={palette.slate}>
            There are currently no medical recommendations saved as a draft.
          </Text>
        </>
      ) : (
        props.formRecords
          .filter(fr => isAmhpForm(fr.formType) === props.isAmhpForm || isConveyanceForm(fr.formType))
          .map((fr, i) => {
            const formIsUnsignedJointMedRecCreatdByAnotherDoctor =
              isJointForm(fr.formType) &&
              [FormStatus.s10_draft, FormStatus.s30_changes_in_progress].includes(fr.status) &&
              fr.createdBy !== props.user?.id;

            const mhaFormVersion = getMhaFormVersion(ENV.ENV, fr.createdAt);
            const form = Forms[fr.formType](mhaFormVersion);
            const formName = form.section ? `Section ${form.section}@${fr.formType}` : fr.formType.replace(/_/g, " ");

            return (
              <MHAFormRecord
                key={i}
                formName={formName}
                formTitle={form.shortTitle}
                patientName={fr.patientName}
                amhpTeamName={fr.amhpTeamName}
                amhpTeam={fr.amhpTeam}
                examinationDate={fr.examinationDate ? fr.examinationDate.format("DD/MM/YYYY") : ""}
                historyElements={[]} // we don't want history in this section, send empty object
                topSpacing={i !== 0}
                isArchived={!!fr.isDeleted}
                ctaButtons={
                  formIsUnsignedJointMedRecCreatdByAnotherDoctor || props.removeCTAButtons
                    ? []
                    : [
                        {
                          label:
                            [FormStatus.s10_draft, FormStatus.s30_changes_in_progress].includes(fr.status) &&
                            fr.authors?.includes(props.user?.id || "")
                              ? "Edit"
                              : "View",
                          onClick: () =>
                            navigation.push(RouteKeys.MHAFormScreen, {
                              assessmentId: props.assessmentId,
                              formType: fr.formType,
                              formId: fr.id,
                            }),
                        },
                        ...(fr.reassignable
                          ? [
                              {
                                label: "Reassign",
                                onClick: () => reassignForm(fr),
                              },
                            ]
                          : []),
                        // TODO: Switch to dialog control from web alert box when that component is developed
                        ...(!canUserDeleteForm(
                          fr.formType,
                          fr.status,
                          props.user?.groups,
                          fr.authors?.includes(props.user?.id || "") || false,
                          fr.createdBy === props.user?.id,
                          props.isAssessmentAmhp
                        )
                          ? []
                          : [
                              {
                                label: "Delete",
                                onClick: () =>
                                  alert(
                                    "Confirm Delete Form",
                                    "Are you sure you wish to delete this form?",
                                    undefined,
                                    async () => {
                                      await deleteForm(v2, fr.id)
                                        .then(() => {
                                          setMessage("Form has been deleted");
                                          props.refetch();
                                          recordDeleteEvent("success", props.isAmhpForm, fr, props.assessmentId);
                                        })
                                        .catch(e => {
                                          recordDeleteEvent("failure", props.isAmhpForm, fr, props.assessmentId);
                                          setLastException(e);
                                          buildErr(e, setLastException);
                                        });
                                    }
                                  ),
                              },
                            ]),
                      ]
                }
                lastElement={i === props.formRecords.length - 1}
              />
            );
          })
      )}
    </>
  );
}
