import React, { useMemo } from "react";
import { View } from "react-native";
import styles from "./styles";
import {
  ScheduledDoctorsHeading,
  NoScheduledDoctorsText,
  DoctorVisitDetails,
  NoContactedDoctorsText,
  ContactedDoctorsHistory,
  ContactedDoctorsHeading,
  TopLevelProps,
} from "./components";
import { DoctorVisitsWithClaimIdResponse } from "@/models/gql/fragments";
import { palette } from "../../../theme/palette";

import { ContactedDoctor } from "../AssessmentDetails.props";
import { Table, TableRow } from "../../Table";
import dayjs from "dayjs";

export const DoctorTables = (props: TopLevelProps) => {
  const today = useMemo(() => dayjs(), []);

  return (
    <>
      <View>
        <ScheduledDoctorsHeading />
        <NoScheduledDoctorsText hasVisits={props.hasVisits} isWebView={props.isWebView} />

        {!!props.visits && props.visits.length > 0 && (
          <VisitsTable visits={props.visits} viewProfile={props.viewProfile} createClaim={props.createClaim} />
        )}
      </View>

      <View style={styles.contactedDoctorsTable}>
        <ContactedDoctorsHeading today={today} />

        {props.contactedDoctors && props.contactedDoctors.length > 0 ? (
          <ContactedTable
            doctors={props.contactedDoctors}
            assessmentId={props.assessment.id}
            viewProfile={props.viewProfile}
            contactDoctor={props.contactDoctor}
          />
        ) : (
          <NoContactedDoctorsText isWebView={props.isWebView} />
        )}
      </View>
    </>
  );
};

const visitHeadings = [
  { text: "Doctor", icon: "person-outline" },
  { text: "Visit Time", icon: "alarm" },
  { text: "Claim", icon: "receipt" },
];

const visitWidths = ["40%", "35%", "25%"];

const VisitsTable = (props: {
  visits: DoctorVisitsWithClaimIdResponse[];
  createClaim: (visitId: string) => void;
  viewProfile: (doctorId: string) => void;
}) => {
  const rows = useMemo(() => props.visits.map(v => createVisitRowObject(v, props.createClaim, props.viewProfile)), [
    props.visits,
  ]);
  return <Table themeColor={palette.yellow} columnWidths={visitWidths} headings={visitHeadings} rows={rows} />;
};

const createVisitRowObject = (
  visit: DoctorVisitsWithClaimIdResponse,
  createClaim: (visitId: string) => void,
  viewProfile: (doctorId: string) => void
) => {
  const claimCellData = visit.doctorVisitClaimId
    ? "Claim Created"
    : {
        text: "Create Claim",
        onPress: () => createClaim(visit.id),
      };

  const cellData = [visit.doctor.name, dayjs(visit.time).format("HH:mm [_]DD/MM/YYYY"), claimCellData];

  const row: TableRow = {
    items: cellData,
    onPress: () => viewProfile(visit.doctor.id),
    expandableContent: () => <DoctorVisitDetails doctorVisit={visit} />,
  };
  return row;
};

const contactedHeadings = [
  { text: "Doctor", icon: "person-outline" },
  { text: "Phone", icon: "phone-android" },
];
const contactedWidths = ["50%", "50%"];

const ContactedTable = (props: {
  doctors: ContactedDoctor[];
  assessmentId: string;
  viewProfile: (doctorId: string) => void;
  contactDoctor: (doctorId: string, doctorPhone: string, assessmentId: string) => void;
}) => {
  const rows = useMemo(
    () =>
      props.doctors.map(d => createContactedRowObject(d, props.assessmentId, props.viewProfile, props.contactDoctor)),
    [props.doctors, props.assessmentId]
  );
  return <Table themeColor={palette.aqua} columnWidths={contactedWidths} headings={contactedHeadings} rows={rows} />;
};

const createContactedRowObject = (
  doctor: ContactedDoctor,
  assessmentId: string,
  viewProfile: (doctorId: string) => void,
  contactDoctor: (doctorId: string, doctorPhone: string, assessmentId: string) => void
) => {
  const phoneCellData = doctor.phone
    ? {
        text: "Call Now",
        onPress: () => contactDoctor(doctor.id, doctor.phone as string, assessmentId),
      }
    : "No Number";

  const cellData = [doctor.name, phoneCellData];

  const row: TableRow = {
    items: cellData,
    onPress: () => viewProfile(doctor.id),
  };

  row.expandableContent = () => <ContactedDoctorsHistory contactedDoctor={doctor} />;

  return row;
};
