import gql from "graphql-tag";
import dayjs from "dayjs";
import { DoctorClaimDetail } from "libs/types/claim";
import {
  LocationWithoutAddressNotes,
  CCGFragment,
  LocationNameFragment,
  AmhpTeamIdAndName,
  DoctorCondensedFragment,
  DoctorVisitsCondensed,
  UnderReviewClaimsFragment,
  TeamClaimsFragment,
  RejectedApprovedClaimsFragment,
  ClaimFragments,
  fieldsOnCCGClaim,
  OrganisationIdName,
  ClaimsFragmentWithoutAssignee,
} from "./gql/fragments";

const beginningOfTime = () => {
  return `"${dayjs("1970-01-01")
    .endOf("day")
    .toISOString()}"`;
};

const todayDateString = () => {
  return `"${dayjs()
    .endOf("day")
    .toISOString()}"`;
};

export const CREATE_CLAIM = gql`
  mutation CreateClaim($input: CreateClaimInput!) {
    createClaim(input: $input) {
      id
    }
  }
`;

export const UPDATE_CLAIM = gql`
  mutation UpdateClaim($input: UpdateClaimInput!) {
    updateClaim(input: $input) {
      id
      status
    }
  }
`;

export const DELETE_CLAIM = gql`
  mutation DeleteClaim($input: DeleteClaimInput!) {
    deleteClaim(input: $input) {
      id
    }
  }
`;

export interface ClaimForDoctorResponse {
  getClaim: DoctorClaimDetail;
}

// TODO: trim this down - michael
export const CLAIM_QUERY_FOR_DOCTOR = gql`
  query GetClaim($id: ID!) {
    getClaim(id: $id) {
      invoiceData {
        assessmentFee
        travelFee
        locationOfAssessment
      }
      ...DefaultClaimParts
      claimData {
        fuelType
        engineSize
      }
      startingPostcode
      lineManager
      mileage
      notes
      createdAt
      receivedDate
      additionalExpenses
      additionalInformation
      doctorAdditionalConf
      isIndependentDoctor
      doctorEmployedStatus
      patientNhsNumber
      locationType
      locationText
      organisation {
        ...OrganisationIdAndName
        locationName {
          city
          postcode
          address
        }
        featureFlags
        phone
        email
        invoiceEmail
        expensesInstruction
        declarationText
        additionalNotesText
        paymentRates {
          assessmentFee
          mileage
          description
        }
      }
      doctor {
        ...DoctorCondensed
        lineManager
        defaultMHT
        claimData {
          billingCompanyName
          billingAddress {
            address
            city
            postcode
          }
          vatRegistration
          fuelType
          engineSize
        }
        mhtEmployers {
          items {
            id
            mht {
              id
              abbreviation
              organisation {
                id
                name
                additionalNotesText
                featureFlags
              }
            }
          }
        }
      }

      visit {
        ...DoctorVisitsCondensed
        assessment {
          id
          createdAt
          patientName
          patientNhsNumber
          assessmentDate
          amhpTeam {
            ...AmhpTeamIdAndNameParts
          }
          amhp {
            id
            email
            phone
            name
          }
          locationName {
            ...LocationWithoutAddressNotes
          }
        }
      }
    }
  }
  ${ClaimFragments}
  ${OrganisationIdName}
  ${AmhpTeamIdAndName}
  ${DoctorCondensedFragment}
  ${DoctorVisitsCondensed}
  ${LocationWithoutAddressNotes}
`;

export const GET_CCG_CLAIM = gql`
  query GetClaim($id: ID!) {
    getClaim(id: $id) {
      ...fieldsOnClaim
    }
  }
  ${fieldsOnCCGClaim}
`;

export const MY_CLAIMS = gql`
  query MyClaims($userId: ID!, $nextToken: String, $filter: ModelClaimFilterInput, $claimLimit: Int) {
    getUser(id: $userId) {
      id
      organisations(limit: 50) {
        items {
          id
          organisation {
            ...UnderReviewClaims
          }
        }
        nextToken
      }
    }
  }
  ${UnderReviewClaimsFragment}
`;

export const MY_CLAIMS_FOR_ORG = gql`
  query MyOrgClaims(
    $claimOrganisationId: ID!
    $userId: ID!
    $nextToken: String
    #$filter: ModelClaimFilterInput
    $claimLimit: Int
  ) {
    getUser(id: $userId) {
      id
      organisations(limit: 50, filter: { organisationUserOrganisationId: { eq: $claimOrganisationId } }) {
        items {
          id
          organisation {
            ...UnderReviewClaims
          }
        }
        nextToken
      }
    }
  }
  ${UnderReviewClaimsFragment}
`;

export const TEAM_CLAIMS = gql`
  query TeamClaims($userId: ID!, $nextToken: String, $filter: ModelClaimFilterInput) {
    getUser(id: $userId) {
      id
      organisations(limit: 50) {
        items {
          id
          organisation {
            ...TeamClaims
          }
        }
      }
    }
  }
  ${TeamClaimsFragment}
`;

export const TEAM_CLAIMS_FOR_ORG = gql`
  query TeamOrgClaims($claimOrganisationId: ID!, $userId: ID!, $nextToken: String) {
    getUser(id: $userId) {
      id
      organisations(limit: 50, filter: { organisationUserOrganisationId: { eq: $claimOrganisationId } }) {
        items {
          id
          organisation {
            ...TeamClaims
          }
        }
        nextToken
      }
    }
  }
  ${TeamClaimsFragment}
`;

export const REJECTED_CLAIMS = gql`
  query AllClaims($userId: ID!, $status: ClaimStatus!, $nextToken: String, $filter: ModelClaimFilterInput) {
    getUser(id: $userId) {
      id
      organisations(limit: 50) {
        items {
          id
          organisation {
            ...RejectedApprovedClaims
          }
        }
        nextToken
      }
    }
  }
  ${RejectedApprovedClaimsFragment}
`;

// Unused
export const APPROVED_OR_PAID_CLAIMS = gql`
  query AllClaims($userId: ID!, $nextToken: String) {
    getUser(id: $userId) {
      id
      organisations(limit: 50) {
        items {
          id
          organisation {
            id
            featureFlags
            claims(
              sortDirection: DESC
              limit: 10
              statusAssigneeIdReceivedDate: { beginsWith: { status: approved } }
              nextToken: $nextToken
            ) {
              items {
                ...fieldsOnClaim
              }
              nextToken
            }
          }
        }
      }
    }
  }
  ${fieldsOnCCGClaim}
`;

// FOR UNASSIGNED CLAIMS
export const UNASSIGNED_CLAIMS = gql`
  query AllClaims($userId: ID!, $status: ClaimStatus!, $nextToken: String, $filter: ModelClaimFilterInput) {
    getUser(id: $userId) {
      id
      organisations(limit: 50) {
        items {
          id
          organisation {
            ...RejectedApprovedClaims
          }
        }
        nextToken
      }
    }
  }
  ${RejectedApprovedClaimsFragment}
`;

// Uses SQL table for claims screens and exports
export const FIND_CLAIMS = gql`
  query FindClaims(
    $filter: ModelClaimFilterInput
    $orderClause: GenericOrderClauseInput
    $paginateClause: PaginateClauseInput
  ) {
    findClaims(filter: $filter, orderClause: $orderClause, paginateClause: $paginateClause) {
      items {
        id
        claimAssessmentId
        claimVisitId
        claimDoctorId
        doctor {
          id
          gmc
          s12Expiry
          name
          legalName
          phone
          phone2
          email
          employer
          jobTitle
          responsibleOfficer
          lineManager
          specialties
          languages
          gender
          notes
          pushTokens
          distance
          availabilityPostcodes
          siteName
          createdAt
          isOutsideEngland
          mhtEmployers {
            items {
              mht {
                organisation {
                  name
                }
              }
            }
          }
        }
        assigneeId
        assigneeName
        amhpTeamId
        claimAmhpId
        claimOrganisationId
        claimCsuId
        organisation {
          id
          name
          featureFlags
          type
          phone
          email
          expensesInstruction
          declarationText
          additionalNotesText
          invoiceEmail
          locationName {
            postcode
          }
        }
        status
        displayId
        mileage
        locationText
        locationType
        assessmentPostcode
        locationName {
          city
          postcode
          address
          addressNotes
        }
        patientInitials
        patientFullName
        startingPostcode
        visitDate
        assessmentTookPlace
        notes
        unread
        createdAt
        updatedAt
        receivedDate
        additionalExpenses
        additionalInformation
        doctorAdditionalConf
        isIndependentDoctor
        doctorEmployedStatus
        defaultMHT
        patientNhsNumber
        declaration
        paid
        amhp {
          id
          name
          phone
          email
        }
        claimData {
          billingAddress {
            city
            postcode
            address
            addressNotes
          }
          billingCompanyName
          vatRegistration
          fuelType
          engineSize
        }
        invoiceData {
          assessmentFee
          travelFee
          locationOfAssessment
        }
        lineManager
      }
      orderClause {
        direction
        field
      }
      paginateClause {
        limit
        offset
      }
      total
    }
  }
`;

export const QUERY_GET_MHTS = gql`
  query {
    listMHTs(limit: 500) {
      items {
        id
        abbreviation
        organisation {
          id
        }
      }
    }
  }
`;

export const CLAIMS_BY_ORG_UNASSIGNED = gql`
  query OrgClaims($claimOrganisationId: ID!, $userId: ID!, $status: ClaimStatus!, $nextToken: String) {
    getUser(id: $userId) {
      id
      organisations(limit: 50, filter: { organisationUserOrganisationId: { eq: $claimOrganisationId } }) {
        items {
          id
          organisation {
            ...RejectedApprovedClaims
          }
        }
        nextToken
      }
    }
  }
  ${RejectedApprovedClaimsFragment}
`;

// REJECTED, APPROVED
export const CLAIMS_BY_ORG = gql`
  query OrgClaims(
    $claimOrganisationId: ID!
    $userId: ID!
    $status: ClaimStatus!
    $nextToken: String
    $filter: ModelClaimFilterInput
  ) {
    getUser(id: $userId) {
      id
      organisations(limit: 50, filter: { organisationUserOrganisationId: { eq: $claimOrganisationId } }) {
        items {
          id
          organisation {
            ...ClaimsFragmentWithoutAssignee
          }
        }
        nextToken
      }
    }
  }
  ${ClaimsFragmentWithoutAssignee}
`;

// TODO: This is actually get DoctorVisit details, rename?
export const GET_CLAIM_DETAILS = gql`
  query GetClaimDetails($id: ID!) {
    visit: getDoctorVisit(id: $id) {
      id
      time
      notes
      assessment {
        id
        patientName
        patientNhsNumber
        ccgId
        ccg {
          ...CCGCondensed
        }
        locationName {
          ...fullLocation
        }
        amhp {
          id
          email
          phone
          name
        }
      }
      doctor {
        ...DoctorCondensed
      }
      team {
        id
        featureFlags
      }
    }
  }
  ${LocationNameFragment}
  ${DoctorCondensedFragment}
  ${CCGFragment}
`;

export const GET_CSU_UNASSIGNED_CLAIMS = gql`
  query GetCSUUnassignedClaims($csuId: ID!, $nextToken: String, $limit: Int!, $to: String = "Z", $from: String = "") {
    claims: claimsByCsuAssignee(
      claimCsuId: $csuId
      statusAssigneeIdReceivedDate: {
        between: [
          { status: under_review, assigneeId: "!!!", receivedDate: $from }
          { status: under_review, assigneeId: "!!!", receivedDate: $to }
        ]
      }
      sortDirection: ASC
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ...fieldsOnClaim
      }
      nextToken
    }
  }
  ${fieldsOnCCGClaim}
`;

export const GET_CSU_MY_CLAIMS = gql`
  query GetCSUMyClaims(
    $csuId: ID!
    $userId: ID!
    $limit: Int!
    $nextToken: String
    $to: String = "Z"
    $from: String = ""
  ) {
    claims: claimsByCsuAssignee(
      claimCsuId: $csuId
      statusAssigneeIdReceivedDate: {
        between: [
          { status: under_review, assigneeId: $userId, receivedDate: $from }
          { status: under_review, assigneeId: $userId, receivedDate: $to }
        ]
      }
      sortDirection: ASC
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ...fieldsOnClaim
      }
      nextToken
    }
  }
  ${fieldsOnCCGClaim}
`;

export const GET_CSU_TEAM_CLAIMS = gql`
  query GetCSUTeamClaims(
    $csuId: ID!
    $limit: Int!
    $nextToken: String
    $from: AWSDateTime = ${beginningOfTime()}
    $to: AWSDateTime = ${todayDateString()}
  ) {
    claims: claimsByTeamCsu(
      claimCsuId: $csuId
      statusReceivedDateAssigneeId: {
        between: [
          { status: under_review, receivedDate: $from, assigneeId: "!!" }
          { status: under_review, receivedDate: $to, assigneeId: "0" }
        ]
      }
      sortDirection: ASC
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ...fieldsOnClaim
      }
      nextToken
    }
  }
  ${fieldsOnCCGClaim}
`;

export const GET_CSU_REJECTED_CLAIMS = gql`
  query GetCSURejectedClaims($csuId: ID!, $limit: Int!, $nextToken: String, $to: String = "Z", $from: String = "") {
    claims: claimsByCsu(
      claimCsuId: $csuId
      statusReceivedDate: {
        between: [{ status: rejected, receivedDate: $from }, { status: rejected, receivedDate: $to }]
      }
      sortDirection: DESC
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ...fieldsOnClaim
      }
      nextToken
    }
  }
  ${fieldsOnCCGClaim}
`;

export const GET_CSU_APPROVED_CLAIMS = gql`
  query GetCSUApprovedClaims(
    $csuId: ID!
    $limit: Int!
    $nextToken: String
    $to: String = "Z"
    $from: String = ""
    $show: ClaimStatus = approved_and_paid
  ) {
    claims: claimsByCsu(
      claimCsuId: $csuId
      statusReceivedDate: { between: [{ status: approved, receivedDate: $from }, { status: $show, receivedDate: $to }] }
      sortDirection: DESC
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ...fieldsOnClaim
      }
      nextToken
    }
  }
  ${fieldsOnCCGClaim}
`;

// ORG LEVEL BELOW
export const GET_ORG_UNASSIGNED_CLAIMS = gql`
  query GetOrgUnassignedClaims($orgId: ID!, $limit: Int!, $nextToken: String, $to: String = "Z", $from: String = "") {
    claims: claimsByOrgAssignee(
      claimOrganisationId: $orgId
      statusAssigneeIdReceivedDate: {
        between: [
          { status: under_review, assigneeId: "!!!", receivedDate: $from }
          { status: under_review, assigneeId: "!!!", receivedDate: $to }
        ]
      }
      sortDirection: ASC
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ...fieldsOnClaim
      }
      nextToken
    }
  }
  ${fieldsOnCCGClaim}
`;

export const GET_ORG_MY_CLAIMS = gql`
  query GetOrgMyClaims(
    $orgId: ID!
    $userId: ID!
    $limit: Int!
    $nextToken: String
    $to: String = "Z"
    $from: String = ""
  ) {
    claims: claimsByOrgAssignee(
      claimOrganisationId: $orgId
      statusAssigneeIdReceivedDate: {
        between: [
          { status: under_review, assigneeId: $userId, receivedDate: $from }
          { status: under_review, assigneeId: $userId, receivedDate: $to }
        ]
      }
      sortDirection: ASC
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ...fieldsOnClaim
      }
      nextToken
    }
  }
  ${fieldsOnCCGClaim}
`;

export const GET_ORG_TEAM_CLAIMS = gql`
  query GetOrgTeamClaims(
    $orgId: ID!
    $limit: Int!
    $nextToken: String
    $from: AWSDateTime = ${beginningOfTime()}
    $to: AWSDateTime = ${todayDateString()}
  ) {
    claims: claimsByTeamOrg(
      claimOrganisationId: $orgId
      statusReceivedDateAssigneeId: {
        between: [
          { status: under_review, receivedDate: $from, assigneeId: "!!" }
          { status: under_review, receivedDate: $to, assigneeId: "0" }
        ]
      }
      sortDirection: ASC
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ...fieldsOnClaim
      }
      nextToken
    }
  }
  ${fieldsOnCCGClaim}
`;

export const GET_ORG_REJECTED_CLAIMS = gql`
  query GetCSURejectedClaims($orgId: ID!, $limit: Int!, $nextToken: String, $to: String = "Z", $from: String = "") {
    claims: claimsByOrg(
      claimOrganisationId: $orgId
      statusReceivedDate: {
        between: [{ status: rejected, receivedDate: $from }, { status: rejected, receivedDate: $to }]
      }
      sortDirection: DESC
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ...fieldsOnClaim
      }
      nextToken
    }
  }
  ${fieldsOnCCGClaim}
`;

export const GET_ORG_APPROVED_CLAIMS = gql`
  query GetOrgApprovedClaims(
    $orgId: ID!
    $limit: Int!
    $nextToken: String
    $to: String = "Z"
    $from: String = ""
    $show: ClaimStatus = approved_and_paid
  ) {
    claims: claimsByOrg(
      claimOrganisationId: $orgId
      statusReceivedDate: { between: [{ status: approved, receivedDate: $from }, { status: $show, receivedDate: $to }] }
      sortDirection: DESC
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ...fieldsOnClaim
      }
      nextToken
    }
  }
  ${fieldsOnCCGClaim}
`;
