import React from "react";

import { DoctorClaimDetail } from "libs/types/claim";

import { TypographyType } from "@/models/Typography";
import { palette, spacing } from "@/theme";

import { ContentWrap } from "@/components/ContentWrap";
import ItemSpacer from "@/components/ItemSpacer";
import { SectionDivider } from "@/components/SectionDivider";
import Text from "@/components/Text";

interface Props {
  claim: DoctorClaimDetail;
  invoiceDownloadError: string | null;
  isMobileDevice: boolean;
  shouldDoctorSendInvoiceToCCG: boolean;
}

export const SubmitClaim = ({ claim, invoiceDownloadError, isMobileDevice, shouldDoctorSendInvoiceToCCG }: Props) => {
  const createInvoiceNotice = (isMobileDevice: boolean) => {
    const claimOrgEmailOrName = claim.organisation.invoiceEmail || claim.organisation.email || claim.organisation.name;
    return isMobileDevice ? (
      <ItemSpacer gap={15}>
        <Text format={TypographyType.Small}>
          This organisation requires an invoice to be sent via email to {claimOrgEmailOrName}.
        </Text>
        <Text format={TypographyType.Small}>
          Click below to send a copy of the invoice for you to email to {claim.organisation.name}, to your email.
        </Text>
        <Text format={TypographyType.Small}>
          After that your claim will be submitted, but will not be processed until the invoice has been emailed to
          {claimOrgEmailOrName}.
        </Text>
      </ItemSpacer>
    ) : (
      <ItemSpacer gap={15}>
        <Text format={TypographyType.Small}>
          This organisation requires an invoice to be downloaded and sent via email to {claimOrgEmailOrName}.
        </Text>
        <Text format={TypographyType.Small}>
          Click below to download the invoice for you to email to {claim.organisation.name}
        </Text>
        <Text format={TypographyType.Small}>
          After that your claim will be submitted, but will not be processed until the invoice has been emailed to{" "}
          {claimOrgEmailOrName}
        </Text>
      </ItemSpacer>
    );
  };

  return (
    <>
      {shouldDoctorSendInvoiceToCCG && (
        <>
          <ContentWrap>
            {invoiceDownloadError && (
              <Text format={TypographyType.RegularBold} color={palette.red}>
                {invoiceDownloadError}
              </Text>
            )}
            <Text format={TypographyType.RegularBold} marginBottom={15}>
              Invoice Notice
            </Text>
            {createInvoiceNotice(isMobileDevice)}
          </ContentWrap>
          <SectionDivider short />
        </>
      )}

      <ContentWrap>
        <Text format={TypographyType.HeadingSmall} marginBottom={spacing[15]}>
          Assessing Doctor's Declaration and Signature
        </Text>

        {claim.organisation.declarationText ? (
          <Text format={TypographyType.Regular}>{claim.organisation.declarationText}</Text>
        ) : (
          <ItemSpacer gap={15}>
            <Text format={TypographyType.Regular}>
              I certify that the above information is correct and the examination was not carried out in respect of my
              contractual duties with the NHS, and no other claim, including mileage claims, has been made by me
              regarding this assessment.
            </Text>
            <Text format={TypographyType.Regular}>
              I confirm that assessments that are undertaken during normal working hours which are not part of my
              contractual duties have resulted in time shifting and my normal working day being extended.
            </Text>
            <Text format={TypographyType.Regular}>
              I understand that this form will be used for the purpose of verification of this claim, and if deemed
              appropriate by the relevant NHS body can be used for the investigation, prevention, detection and
              prosecution of fraud. I confirm the above notes have been understood.
            </Text>
          </ItemSpacer>
        )}
      </ContentWrap>
    </>
  );
};
