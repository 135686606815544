import { FormStatus } from "libs/types/API";

export function isFormSigned(status: FormStatus): boolean {
  return [
    FormStatus.s20_awaiting_other_doctor,
    FormStatus.s40_signed,
    FormStatus.s50_signed_and_sent,
    FormStatus.s60_signed_in_amhp_team_inbox,
  ].includes(status);
}
