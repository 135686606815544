import React, { useEffect } from "react";
import { StyleSheet, View } from "react-native";
import { BottomTabBarProps } from "@react-navigation/bottom-tabs";
import { Edge, SafeAreaView } from "react-native-safe-area-context";
import { useSetRecoilState } from "recoil";

import Breakpoints from "@/config/breakpoints";
import { useDeviceWidth } from "@/hooks";
import { bottomTabBarPropsState } from "@/navigationv2/state";
import { palette } from "@/theme";

import CCGTabBar from "./CCGTabBar/CCGTabBar";
import TabItem from "./TabItem";

interface PropTypes extends BottomTabBarProps {
  isCCG: boolean;
}

const edges: readonly Edge[] = ["bottom", "left", "right"];

/**
 * Render a custom tab bar in place of the default React Navigation 5 version.
 *
 * @param props
 * @todo Switching tabs triggers re-renders, suggest testing any possible
 *       performance implications associated with this.
 *       https://github.com/s12solutions/s12/pull/2552#discussion_r485628719
 */
const CustomTabBar = (props: PropTypes) => {
  const { descriptors, isCCG, navigation, state } = props;
  const focusedOptions = descriptors[state.routes[state.index].key].options;
  const deviceWidth = useDeviceWidth();
  const hideTabBar = deviceWidth >= Breakpoints.desktop || focusedOptions.tabBarVisible === false;
  const setTabBarProps = useSetRecoilState(bottomTabBarPropsState);

  useEffect(() => {
    setTabBarProps({ descriptors, navigation, state });
  }, [descriptors, navigation, setTabBarProps, state]);

  if (hideTabBar) {
    return null;
  }

  return (
    <SafeAreaView edges={edges} style={styles.safeAreaView}>
      {!isCCG && (
        <View style={styles.bar}>
          {state.routes.map((route, index) => (
            <TabItem {...{ index, route, ...props }} key={index} />
          ))}
        </View>
      )}

      {isCCG && <CCGTabBar barStyle={styles.bar} {...props} />}
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  bar: {
    flexDirection: "row",
    backgroundColor: palette.white,
    shadowColor: palette.shadow,
  },

  safeAreaView: {
    backgroundColor: palette.white,
    shadowOffset: {
      width: 0,
      // Set the position of the shadow
      height: -10,
    },
    shadowOpacity: 0.05,
    shadowRadius: 10,
  },
});

export default CustomTabBar;
