/* Libraries */
import React from "react";
import { View, StyleSheet } from "react-native";

/* Models, Types, Props & Utils */
import { DoctorProfile } from "@/models/DoctorProfile";
import { TypographyType } from "@/models/Typography";
import { mqWeb } from "@/utils/helpers";

/* Custom Components */
import { ContentWrap } from "../../ContentWrap";
import { Description } from "../../Description";
import Text from "../../Text";
import { formatPostcode } from "../../../utils/helpers";
import { spacing } from "../../../theme";

export default function Bio(props: DoctorProfile) {
  const isWebView = mqWeb();
  return (
    <ContentWrap>
      <View style={styles.bio}>
        <Text format={TypographyType.HeadingMedium} style={styles.fullWidth}>
          Bio
        </Text>
        <Description label="Legal Name" style={isWebView ? styles.halfWidth : styles.fullWidth}>
          {props.legalName}
        </Description>
        <Description label="GMC Number" style={isWebView ? styles.quarterWidth : styles.fullWidth}>
          {props.id}
        </Description>
        <Description label="Gender" style={isWebView ? styles.quarterWidth : styles.fullWidth}>
          {!props.gender || props.gender === "U"
            ? "-"
            : props.gender === "M" || props.gender === "TM"
            ? "Male"
            : "Female"}
        </Description>
        <Description label="Specialisms">{(props.specialties || []).join(", ") || "Not Listed"}</Description>
        <Description label="Languages">{(props.languages || []).join(", ") || "Not Listed"}</Description>
        <Description label="Associated MHTs">
          {(props.mhtEmployers && props.mhtEmployers.map(mht => mht.mht.organisation.name).join(",\n")) || "None"}
        </Description>
        <Description label="Base Postcode" style={[styles.halfWidth, styles.halfWidthPadded]}>
          {(props.locationName && formatPostcode(props.locationName.postcode)) || "None"}
        </Description>
        <Description label="Primary Job Title" style={styles.halfWidth}>
          {props.jobTitle || "N/A"}
        </Description>
        <Description label="Primary Team Name" style={[styles.halfWidth, styles.halfWidthPadded]}>
          {props.employer || "N/A"}
        </Description>
        <Description label="Responsible Officer Name" style={styles.halfWidth}>
          {props.responsibleOfficer || "N/A"}
        </Description>
        <Description label="Line Manager Name" style={[styles.halfWidth, styles.noMarginBottom]}>
          {props.lineManager || "N/A"}
        </Description>
      </View>
    </ContentWrap>
  );
}

const styles = StyleSheet.create({
  bio: {
    paddingBottom: 0,
    flexDirection: "row",
    flexWrap: "wrap",
  },
  fullWidth: {
    flexBasis: "100%",
  },
  halfWidth: {
    width: "50%",
    flexBasis: "50%",
  },
  quarterWidth: {
    width: "25%",
    flexBasis: "25%",
  },
  halfWidthPadded: {
    paddingRight: spacing[10],
  },

  noMarginBottom: {
    marginBottom: 0,
  },
});
