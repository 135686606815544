/* eslint-disable complexity */
import React, { useEffect, useState, useMemo, useContext } from "react";
import { useMutation } from "@apollo/react-hooks";
import { UPDATE_CLAIM } from "@/models/Claim";
import { Keyboard, Platform, StyleSheet, View } from "react-native";

import { useNavigation } from "@react-navigation/native";
import dayjs from "dayjs";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { StackNavigationProp } from "@react-navigation/stack";
import { AnalyticsEvent } from "libs/analytics/events";
import { ClaimStatus, LocationType, UpdateClaimInput, LocationNameInput } from "libs/types/API";

import { buildErr, updateClaimError } from "@/models/Error";
import { TypographyType } from "@/models/Typography";
import { color, spacing, palette } from "@/theme";
import { recordEvent } from "@/utils/analytics";
import { checkMutationInput, mqWeb, ucFirst, formatFuelType } from "@/utils/helpers";
import { NotesTexts } from "../ClaimProcessing/helpers";
import { Button } from "../Button";
import ClaimContact from "../ClaimContact";
import ClaimHistory from "../ClaimHistory";
import SectionDivider from "../SectionDivider/SectionDivider";
import { ContentWrap } from "../ContentWrap";
import { DetailsSection } from "../DetailsSection";
import { SectionItem } from "../DetailsSection/DetailsSection";
import { Modal } from "../Modal";
import { RectangleButton } from "../RectangleButton";
import Text from "../Text";
import { TextInput } from "../TextInput/TextInput";
import {
  hasMileage,
  shouldProvideVehicleInformation,
  hasAdditionalExpenses,
  ccgRequiresNhsNumber,
  shouldConfirmAssessmentTookPlace,
  ccgRequiresBillingInformation,
  ccgRequiresGmcNumber,
  ccgRequiresAmhpTeamName,
  ccgRequiresDoctorToInvoice,
  ccgRequiresDoctorMhtAssociations,
  ccgRequiresDoctorAdditionalConfirmation,
  doctorFeesMileageWithoutInvoice,
  ccgRequiresDoctorEmployedStatus,
} from "libs/utils/featureFlags";
import { formatAddress } from "libs/formatters/address";

import { AllCCGRoutes } from "@/navigationv2/types";
import { snackbarMessage, lastException, userDetails, refreshClaimsRequired } from "@/utils/recoil";
import { calculateInvoiceTotals } from "libs/utils/invoicing";
import { InfoText } from "../InfoText";
import { CCGClaimDetails } from "libs/types/claim";
import { EVENTUAL_CONSISTENCY_TIMEOUT } from "@/config/constants";
import { ClaimProcessingContext } from "../ClaimProcessing/ClaimProcessingProvider";
import { ClaimProcessingStateContext, ClaimModelActionType } from "../ClaimProcessing/ClaimProcessingState";
import { doctorConfText, doctorEmpStatusText } from "../ClaimProcessing/ClaimProcessing.props";

interface PropTypes {
  claim: CCGClaimDetails;
}

export const ClaimProcessingDetails = (props: PropTypes) => {
  const navigation = useNavigation<StackNavigationProp<AllCCGRoutes>>();
  const { dispatch } = useContext<ClaimProcessingStateContext>(ClaimProcessingContext);
  const { claim } = props;
  const [submitted, setSubmitted] = useState(false);
  const [modalType, setModalType] = useState<string | null>(null);
  const [rejectNote, setRejectNote] = useState("");

  // const [mhts, setMhts] = React.useState<{ id: string; abbreviation: string }[]>([]);

  const user = useRecoilValue(userDetails);
  const setMessage = useSetRecoilState(snackbarMessage);
  const setLastException = useSetRecoilState(lastException);
  const setRefreshClaimsRequired = useSetRecoilState(refreshClaimsRequired);

  const [updateClaim] = useMutation(UPDATE_CLAIM);

  const userId = user ? user.id : "!!!";
  const userName = user ? user.name : null;
  const isWeb = mqWeb();
  const [renderedWebButtons, setRenderedWebButtons] = useState<JSX.Element | null>(null);
  const doctorEmployers = claim.doctor.mhtEmployers?.items?.map(employer => employer.mht.abbreviation);

  const submitClaim = (status: ClaimStatus, modalType?: string) => {
    setSubmitted(true);
    setModalType(null);
    const approvedNote = `Claim approved by team member@${user ? user.name : "N/A"}@${dayjs().format()}`;

    const otherNote = `Claim ${status === ClaimStatus.under_review ? "reopened" : "rejected"} by team member@${
      user ? user.name : "N/A"
    }@${dayjs().format()}@${rejectNote}`;

    const paidNote = `${NotesTexts.paid}@${user ? user.name : "N/A"}@${dayjs().format()}`;

    const unpaidNote = `${NotesTexts.unpaid}@${user ? user.name : "N/A"}@${dayjs().format()}`;

    const notes =
      modalType === "paid"
        ? paidNote
        : modalType === "unpaid"
        ? unpaidNote
        : status === ClaimStatus.approved
        ? approvedNote
        : otherNote;

    const input = checkMutationInput<UpdateClaimInput>({
      id: claim.id,
      claimVisitId: claim.claimVisitId,
      status,
      notes,
    });

    updateClaim({
      variables: {
        input,
      },
    })
      .then(() => {
        setMessage(status === ClaimStatus.under_review ? "Claim reopened" : `Claim ${status.replace(/_/g, " ")}`);

        const eventStatus =
          modalType === "paid"
            ? AnalyticsEvent.CLAIM_PAID
            : modalType === "unpaid"
            ? AnalyticsEvent.CLAIM_UNPAID
            : status === ClaimStatus.approved
            ? AnalyticsEvent.CLAIM_APPROVED
            : AnalyticsEvent.CLAIM_REJECTED;

        recordEvent(
          eventStatus,
          {},
          {
            [`Minutes To Claim ${status}`]: dayjs(claim.updatedAt).diff(dayjs(), "minute"),
          }
        );

        setModalType(null);
        navigation.goBack();
        dispatch({ type: ClaimModelActionType.LOAD_CLAIMS });
        // Allow 5s for eventual consistency before refetching data
        setTimeout(() => setRefreshClaimsRequired(true), EVENTUAL_CONSISTENCY_TIMEOUT);
      })
      .catch(e => {
        setSubmitted(false);
        buildErr(
          {
            ...updateClaimError,
            additional: claim.id,
          },
          setLastException
        )(e);
      });
  };

  const assignClaim = () => {
    setSubmitted(true);
    const input = {
      id: claim.id,
      claimVisitId: claim.claimVisitId,
      assigneeId: userId,
      assigneeName: userName,
      notes: `Claim ${modalType === "reassign" ? "re-" : ""}assigned to team member@${
        user ? user.name : "N/A"
      }@${dayjs().format()}`,
      status: claim.status,
    };

    updateClaim({
      variables: checkMutationInput({
        input,
      }),
    })
      .then(() => {
        setMessage(`Claim ${modalType === "reassign" ? "re-" : ""}assigned`);
        const eventStatus = modalType === "reassign" ? AnalyticsEvent.CLAIM_REASSIGNED : AnalyticsEvent.CLAIM_ASSIGNED;
        recordEvent(
          eventStatus,
          {
            ccg: claim.claimOrganisationId,
          },
          {
            // eslint-disable-next-line
            [`Minutes To ${modalType ? ucFirst(modalType) : "assign"} Claim`]: dayjs(claim.updatedAt).diff(
              dayjs(),
              "minute"
            ),
          }
        );
        setModalType(null);
        navigation.goBack();
        // Allow 5s for eventual consistency before refetching data
        dispatch({ type: ClaimModelActionType.LOAD_CLAIMS });
        setTimeout(() => setRefreshClaimsRequired(true), EVENTUAL_CONSISTENCY_TIMEOUT);
      })
      .catch(e => {
        setSubmitted(false);
        setModalType(null);
        buildErr(
          {
            ...updateClaimError,
            additional: claim.id,
          },
          setLastException
        )(e);
      });
  };

  const displayBillingInformation = ccgRequiresBillingInformation(claim.organisation.featureFlags);
  const shouldDisplayDoctorGmcNumber = ccgRequiresGmcNumber(claim.organisation.featureFlags);
  // TODO: claim.organisation.featureFlags just tests if the number is greater than 0,
  // which just tests if it accepts claims, not if it accepts additional expenses and other featureFlags.
  const detailsSectionItems = [
    {
      label: "Doctor Starting Postcode",
      value: claim.startingPostcode && claim.startingPostcode.toUpperCase(),
      bold: true,
    },
    hasMileage(claim.organisation.featureFlags) && {
      label: "Doctor Distance Travelled",
      value: claim.mileage ? `${claim.mileage} miles` : "Not supplied",
      bold: true,
    },
    shouldProvideVehicleInformation(claim.organisation.featureFlags) && {
      label: "Vehicle Information",
      value:
        claim.claimData && claim.claimData.fuelType
          ? formatFuelType(claim.claimData.fuelType, claim.claimData.engineSize)
          : "Not supplied",
      bold: true,
    },
    hasAdditionalExpenses(claim.organisation.featureFlags) &&
      !ccgRequiresDoctorToInvoice(claim.organisation.featureFlags) && {
        label: "Additional Travel Expenses",
        value: claim.additionalExpenses ? `£${claim.additionalExpenses}` : "-",
        bold: true,
      },
    {
      label: "Independent Doctor",
      value: claim.isIndependentDoctor ? "Yes" : "No",
      flex: 1,
    },
    !claim.isIndependentDoctor && {
      label: "Doctor Line Manager",
      value: claim.lineManager || undefined,
      flex: 1,
    },
    !claim.isIndependentDoctor && {
      label: "Employer",
      value: claim.defaultMHT ? claim.defaultMHT : "Not Specified",
      // flex: shouldDisplayDoctorGmcNumber ? 2 : 3,
      flex: 1,
    },
    shouldDisplayDoctorGmcNumber && { label: "Doctor GMC Number", value: claim.doctor.gmc || undefined },
    {
      label: "Visit Time",
      value: dayjs(claim.visitDate).format("HH:mm"),
      bold: true,
    },
    {
      label: "Date",
      value: dayjs(claim.visitDate).format("dddd D MMMM YYYY"),
    },
    ccgRequiresNhsNumber(claim.organisation.featureFlags) && {
      label: "Patient NHS Number",
      value: claim.patientNhsNumber || "-",
      bold: true,
    },
    {
      label: claim.patientFullName ? "Patient Name" : "Patient Initials",
      value: claim.patientFullName || claim.patientInitials,
    },
    claim.additionalInformation && {
      label: "Additional Information",
      value: claim.additionalInformation,
      flex: 2,
    },
    {
      label: "Patient's GP Practice",
      value: claim.locationType === LocationType.gPPractice ? claim.locationText : undefined,
      flex: 2,
    },
    {
      label: "Patient's Address",
      value: claim.locationType === LocationType.patientAddress ? claim.locationText : undefined,
      flex: 2,
    },
    {
      label: "Location",
      value: claim.assessmentPostcode || undefined,
    },
    ccgRequiresDoctorMhtAssociations(claim.organisation.featureFlags) &&
      !claim.isIndependentDoctor && {
        label: "Doctor MHT Associations",
        value: claim.doctor.mhtEmployers?.items.map(item => item.mht.organisation.name).join(",\n"),
        flex: 3,
      },
    shouldConfirmAssessmentTookPlace(claim.organisation.featureFlags) && {
      label: "Assessment Took Place?",
      value: claim.assessmentTookPlace === true ? "Yes" : claim.assessmentTookPlace === false ? "No" : "-",
      flex: 3,
    },
    displayBillingInformation && {
      label: "Company Name",
      value: claim.claimData?.billingCompanyName || "-",
    },
    displayBillingInformation && {
      label: "Company Address",
      value: claim.claimData?.billingAddress ? formatAddress(claim.claimData.billingAddress as LocationNameInput) : "-",
    },
    displayBillingInformation && {
      label: "VAT Registration",
      value: claim.claimData?.vatRegistration || "-",
    },
    ccgRequiresAmhpTeamName(claim.organisation.featureFlags) && { label: "AMHP Team Name", value: claim.amhpTeamId },
    ccgRequiresDoctorAdditionalConfirmation(claim.organisation.featureFlags) && {
      label: "Doctor Confirmation",
      value: claim.doctorAdditionalConf ? doctorConfText[claim.doctorAdditionalConf] : "Not Specified",
    },
    ccgRequiresDoctorEmployedStatus(claim.organisation.featureFlags) && {
      label: "Payroll",
      value: claim.doctorEmployedStatus ? doctorEmpStatusText[claim.doctorEmployedStatus] : "No",
    },
  ].filter(x => !!x) as SectionItem[];

  const invoiceSectionItems = useMemo(() => {
    if (!ccgRequiresDoctorToInvoice(claim.organisation.featureFlags) || !claim.invoiceData) {
      return [];
    }
    const { subtotal, vat, totalExcVat, totalIncVat } = calculateInvoiceTotals(
      claim,
      Boolean(claim.claimData?.vatRegistration)
    );

    return [
      { label: "Assessment Fee (GBP)", value: claim.invoiceData.assessmentFee },
      { label: "Mileage Fee (GBP)", value: claim.invoiceData.travelFee },
      hasAdditionalExpenses(claim.organisation.featureFlags) && {
        label: "Additional Travel Expenses",
        value: claim.additionalExpenses ? `£${claim.additionalExpenses}` : "-",
        bold: true,
      },
      {
        label: "VAT (GBP)",
        value: vat,
      },
      {
        label: "Subtotal (GBP)",
        value: subtotal,
      },
      {
        label: "TOTAL Exc VAT (GBP)",
        value: totalExcVat,
      },
      {
        label: "TOTAL Inc VAT (GBP)",
        value: totalIncVat,
      },
    ].filter(x => !!x) as SectionItem[];
  }, [claim.organisation.featureFlags, claim.invoiceData]);

  // We can require a doctor to add fees even if the CCG does not reqire the doctor to invoice. However,
  // there is no need to show the doctor fee and the mileage if invoicing is turned on. In this case, this
  // date is shown in the invoice section
  const feesSectionItems = useMemo(() => {
    // Ignore if the data is showin in the invoice section above ^^
    if (ccgRequiresDoctorToInvoice(claim.organisation.featureFlags)) {
      return [];
    }
    if (!doctorFeesMileageWithoutInvoice(claim.organisation.featureFlags) || !claim.invoiceData) {
      return [];
    }
    // Doctor isn't invoicing, but is still required to enter the assessment fee and may enter
    // a mileage also.
    return [
      { label: "Assessment Fee (GBP)", value: claim.invoiceData.assessmentFee },
      { label: "Mileage Fee (GBP)", value: claim.invoiceData.travelFee },
    ].filter(x => !!x) as SectionItem[];
  }, [claim.organisation.featureFlags, claim.invoiceData]);

  const actionBtns = [
    {
      label: "Reject",
      modal: "reject",
      icon: "clear",
      color: color.claimRejected,
      textWhite: true,
      visible: claim.status === ClaimStatus.under_review && claim.assigneeId === userId,
      reject: true,
    },
    {
      label: "Approve",
      modal: "approve",
      icon: "done",
      color: color.secondary,
      textWhite: false,
      visible: claim.status === ClaimStatus.under_review && claim.assigneeId === userId,
      reject: false,
    },
    {
      label: "Assign To Me",
      modal: "assign",
      icon: "assignment",
      color: color.primary,
      textWhite: true,
      visible: claim.status === ClaimStatus.under_review && claim.assigneeId === "!!!",
      reject: false,
    },
    {
      label: "Reassign To Me",
      modal: "reassign",
      icon: "compare-arrows",
      color: color.primary,
      textWhite: true,
      visible: claim.status === ClaimStatus.under_review && claim.assigneeId !== userId && claim.assigneeId !== "!!!",
      reject: false,
    },
    {
      label: "Undo Approval",
      modal: "reopen",
      icon: "clear",
      color: color.primary,
      textWhite: true,
      visible: claim.status === ClaimStatus.approved,
      reject: true,
    },
    {
      label: "Mark as Paid",
      modal: "paid",
      icon: "payment",
      color: color.secondary,
      textWhite: true,
      visible: claim.status === ClaimStatus.approved,
    },
    {
      label: "Mark as Unpaid",
      modal: "unpaid",
      icon: "cancel",
      color: color.claimRejected,
      textWhite: true,
      visible: claim.status === ClaimStatus.approved_and_paid,
    },
  ];

  useEffect(() => {
    setRenderedWebButtons(
      <>
        {actionBtns.map(
          (btn, i) =>
            btn.visible && (
              <RectangleButton
                key={i}
                onPress={() => setModalType(btn.modal)}
                mode={btn.textWhite ? "cancel" : "action"}
                style={{
                  marginLeft: spacing[10],
                  backgroundColor: btn.color,
                }}
                icon={btn.icon}
                disabled={submitted}
              >
                {btn.label.toUpperCase()}
              </RectangleButton>
            )
        )}
      </>
    );
  }, []);

  const constructClaimModalHeader = (modalType: string) => {
    return !(modalType === "paid" || modalType === "unpaid")
      ? `${ucFirst(modalType)} Claim`
      : `Mark Claim as ${ucFirst(modalType)}`;
  };

  return (
    <>
      <ContentWrap>
        <View style={[styles.topSection, { marginBottom: isWeb ? spacing[50] : spacing[40] }]}>
          <View style={styles.row}>
            <View>
              <Text
                format={isWeb ? TypographyType.HeadingLarge : TypographyType.HeadingMedium}
                style={styles.detailsHeader}
              >
                Claim Details
              </Text>
              {claim.assigneeName && (
                <View style={styles.assigneeName}>
                  <Text format={TypographyType.Micro} style={styles.assignee}>
                    Assignee:
                  </Text>
                  <Text format={TypographyType.Small}>{claim.assigneeName}</Text>
                </View>
              )}
            </View>
            <View style={[styles.claimStatus, { backgroundColor: claimColor[claim.status] }]}>
              <Text format={TypographyType.MicroBold} style={styles.claimStatusText}>
                {claimLabel[claim.status]}
              </Text>
            </View>
          </View>
          {isWeb && renderedWebButtons}
        </View>
      </ContentWrap>
      <DetailsSection header="Assessment Visit" columns={3} items={detailsSectionItems} />
      <SectionDivider />
      {invoiceSectionItems.length > 0 && (
        <>
          <DetailsSection header="Invoice Details" columns={3} items={invoiceSectionItems} />
          <ContentWrap>
            <InfoText
              paragraphs={[
                "The information in this section was accurate at the point the original invoice was generated.",
              ]}
            />
          </ContentWrap>
          <SectionDivider />
        </>
      )}
      {/* This section will only show if the above section doesn't show and the appropriate flag is set  */}
      {feesSectionItems.length > 0 && (
        <DetailsSection header="Doctor Fee and Mileage" columns={3} items={feesSectionItems} />
      )}
      <ContentWrap>
        <Text format={TypographyType.HeadingMedium}>Claim History</Text>
        <ClaimHistory
          claimNotes={
            claim.notes && claim.notes.length > 0
              ? [
                  `Claim created by AMHP@${claim.amhp.name}@${claim.createdAt}`,
                  `Claim submitted by Doctor@${claim.doctor.name}@${claim.receivedDate}`,
                  ...claim.notes,
                ]
              : [
                  `Claim created by AMHP@${claim.amhp.name}@${claim.createdAt}`,
                  `Claim submitted by Doctor@${claim.doctor.name}@${claim.receivedDate}`,
                ]
          }
        />
      </ContentWrap>

      <SectionDivider />

      <ClaimContact type="AMHP" name={claim.amhp.name} phone={claim.amhp.phone} email={claim.amhp.email} />

      <SectionDivider />

      <ClaimContact type="Doctor" name={claim.doctor.name} phone={claim.doctor.phone} email={claim.doctor.email} />

      {!isWeb && (
        <>
          <SectionDivider />
          <ContentWrap>
            {actionBtns.map(
              (btn, i) =>
                btn.visible && (
                  <Button
                    key={i}
                    onPress={() => setModalType(btn.modal)}
                    color={btn.reject ? palette.red : palette.aqua}
                    style={styles.buttonFullWidth}
                    disabled={submitted}
                    webHeaderButton={true}
                  >
                    {btn.label}
                  </Button>
                )
            )}
          </ContentWrap>
        </>
      )}
      {/* CCGModal */}
      {modalType && (
        <Modal
          visible={modalType !== null}
          dismissable={true}
          onDismiss={() => {
            modalType === "reject" && setRejectNote("");
            setModalType(null);
          }}
          headline={constructClaimModalHeader(modalType)}
          buttons={
            modalType === "reject" || modalType === "reopen"
              ? [
                  {
                    label: "Cancel",
                    onPress: () => setModalType(null),
                  },
                  {
                    label: `${ucFirst(modalType)} Claim`,
                    onPress: () =>
                      submitClaim(modalType === "reject" ? ClaimStatus.rejected : ClaimStatus.under_review),
                    disabled: rejectNote.length < 3 || submitted,
                  },
                ]
              : modalType === "assign" || modalType === "reassign"
              ? [
                  {
                    label: "Cancel",
                    onPress: () => setModalType(null),
                  },
                  {
                    label: `${ucFirst(modalType)} Claim`,
                    onPress: assignClaim,
                    disabled: submitted,
                  },
                ]
              : modalType === "paid" || modalType === "unpaid"
              ? [
                  {
                    label: "Cancel",
                    onPress: () => setModalType(null),
                  },
                  {
                    label: `Mark Claim as ${ucFirst(modalType)}`,
                    onPress: () =>
                      submitClaim(
                        modalType === "paid" ? ClaimStatus.approved_and_paid : ClaimStatus.approved,
                        modalType
                      ),
                    disabled: submitted,
                  },
                ]
              : [
                  {
                    label: "Cancel",
                    onPress: () => setModalType(null),
                  },
                  {
                    label: "Approve Claim",
                    onPress: () => submitClaim(ClaimStatus.approved),
                    disabled: submitted,
                  },
                ]
          }
        >
          {modalType === "reject" || modalType === "reopen" ? (
            <>
              <Text format={TypographyType.Micro} style={styles.notesInfoText}>
                Please explain why this claim is being re-opened. This information will be visible to the doctor and
                other claim processors. When clicking the Reopen Claim button, the claim will return to the Unassigned
                Tab.
              </Text>
              <TextInput
                label=""
                numberOfLines={2}
                multiline={true}
                autoFocus={true}
                autoCorrect={true}
                spellCheck={true}
                value={rejectNote}
                returnKeyType="done"
                blurOnSubmit={true}
                onSubmitEditing={() => {
                  Keyboard.dismiss();
                }}
                onChangeText={v => setRejectNote(v)}
                style={styles.noStretch}
              />
            </>
          ) : modalType === "assign" || modalType === "reassign" ? (
            <>
              <Text format={TypographyType.Regular} style={styles.modalInfoText}>
                You are about to {modalType} this claim to yourself.
              </Text>
              <Text format={TypographyType.Regular} style={styles.modalInfoText}>
                Are you sure you want to do this?
              </Text>
            </>
          ) : modalType === "paid" || modalType === "unpaid" ? (
            <>
              <Text format={TypographyType.Regular} style={styles.modalInfoText}>
                You are about to mark this claim as {modalType}.
              </Text>
              <Text format={TypographyType.Regular} style={styles.modalInfoText}>
                Are you sure you want to do this?
              </Text>
            </>
          ) : (
            <>
              <Text format={TypographyType.Regular} style={styles.modalInfoText}>
                By approving this claim form, you’re confirming that the information provided is accurate and the fee is
                eligible for payment.
              </Text>
            </>
          )}
        </Modal>
      )}
    </>
  );
};

const claimColor = {
  // eslint-disable-next-line
  action_required: color.claimActionRequired,
  // eslint-disable-next-line
  under_review: color.claimProcessing,
  approved: color.claimApproved,
  rejected: color.claimRejected,
  // eslint-disable-next-line
  approved_and_paid: color.claimPaid,
  on_hold: color.claimOnHold,
};

const claimLabel = {
  // eslint-disable-next-line
  action_required: "-",
  // eslint-disable-next-line
  under_review: "OPEN",
  approved: "APPROVED",
  rejected: "REJECTED",
  // eslint-disable-next-line
  approved_and_paid: "PAID",
  on_hold: "ON HOLD",
};

const styles = StyleSheet.create({
  topSection: {
    marginBottom: spacing[50],
    flexDirection: "row",
    alignItems: "flex-start",
  },
  headerContent: {
    color: color.textWhite,
  },
  buttonFullWidth: {
    width: "100%",
  },
  noStretch: {
    flex: 0,
    flexShrink: 0,
    flexBasis: "auto",
    paddingBottom: spacing[15],
  },
  notesHeading: {
    marginBottom: spacing[10],
  },
  notesInfoText: {
    marginBottom: spacing[15],
  },
  modalInfoText: {
    marginBottom: spacing[30],
  },
  row: { flex: 1, flexDirection: "row" },
  assigneeName: { flexDirection: "row", alignItems: "flex-end" },
  assignee: { color: color.textExtraLight, marginRight: 5 },
  claimStatus: {
    borderRadius: 5,
    paddingVertical: 6,
    paddingHorizontal: 10,
    marginTop: Platform.OS === "web" ? 4 : 6,
    maxHeight: 32,
    marginLeft: spacing[20],
  },
  claimStatusText: {
    color: color.textWhite,
  },
  detailsHeader: {
    marginBottom: 0,
  },
});
