import React, { useMemo } from "react";
import { View } from "react-native";

import { mqWeb } from "../../../utils/helpers";
import css from "./CardContainer.css";

const CardContainer: React.FC = ({ children }) => {
  const isWebView = mqWeb();

  const styles = {
    container: useMemo(() => (isWebView ? css.containerWeb : css.container), [isWebView]),
  };

  return <View style={styles.container}>{children}</View>;
};

export default CardContainer;
