import React from "react";
import { StyleSheet, View, Switch as SwitchHandlerWeb, Platform } from "react-native";
import { Switch as SwitchHandler } from "react-native-paper";
import Text from "../Text";
import { TypographyType } from "@/models/Typography";
import { spacing } from "../../theme/spacing";

const Switch = (props: {
  headline?: string;
  onState: string;
  offState: string;
  value: boolean;
  onValueChange: (value: boolean) => void;
}) => {
  const SwitchComponent = Platform.OS !== "web" ? SwitchHandler : SwitchHandlerWeb;
  return (
    <>
      {props.headline && (
        <Text format={TypographyType.Small} style={styles.headline}>
          {props.headline}
        </Text>
      )}
      <View style={styles.switchContainer}>
        <Text format={TypographyType.Tiny}>{props.onState}</Text>
        <SwitchComponent value={props.value} onValueChange={props.onValueChange} style={styles.switch} />
        <Text format={TypographyType.Tiny}>{props.offState}</Text>
      </View>
    </>
  );
};

export default Switch;

const styles = StyleSheet.create({
  headline: {
    paddingBottom: spacing[15],
  },
  switchContainer: {
    flexDirection: "row",
    alignItems: "center",
    paddingBottom: spacing[50],
  },
  switch: {
    transform: [{ rotate: "180deg" }],
    marginHorizontal: spacing[30],
  },
});
