import React, { useEffect, useState, useMemo, useCallback } from "react";
import { Platform, View } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";

import { formatLongDate } from "libs/dates";

import { AssessmentResultItem, AssessmentCategory } from "@/models/Assessment";
import { TypographyType } from "@/models/Typography";

import { Button } from "@/components/Button";
import { ContentWrap } from "@/components/ContentWrap";
import { FilterLabel } from "@/components/FilterLabel";
import { LoadMoreButton } from "@/components/RectangleButton";
import { SectionDivider } from "@/components/SectionDivider";
import { SectionHeading } from "@/components/SectionHeading";
import { SplitSection } from "@/components/SplitSection";
import Text from "@/components/Text/Text";
import { AllAMHPRoutes } from "@/navigationv2/types";

import { palette } from "@/theme";
import { mqWeb } from "@/utils/helpers";
import { sortAssessments } from "@/utils/assessments";

import css from "./AssessmentsSection.css";
import { AssessmentsCards } from "../AssessmentsCards";
import { AssessmentsTable } from "../AssessmentsTable";
import { useExportAssessments } from "@/components/ExportCSV";

interface AssessmentSectionProps {
  heading: string;
  nextToken: string | null;
  fetchMoreFn: () => void;
  themeColor: string;
  category: AssessmentCategory;
  subHeading?: string | false;
  assessments?: AssessmentResultItem[];
  bottomDivider?: boolean;
}

const AssessmentSection: React.FC<AssessmentSectionProps> = props => {
  const { heading, subHeading, nextToken, fetchMoreFn, themeColor, category, assessments = [], bottomDivider } = props;
  const navigation = useNavigation<StackNavigationProp<AllAMHPRoutes>>();
  const displayExportAssessments = useExportAssessments();

  const isWebView = mqWeb();
  const sortedAssessments = useMemo(
    () =>
      category === AssessmentCategory.MyArchive || category === AssessmentCategory.TeamArchive
        ? sortAssessments(assessments).reverse()
        : sortAssessments(assessments),
    [assessments]
  );
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (nextToken) setIsLoading(false);
    return () => {
      setIsLoading(false);
    };
  }, [nextToken]);
  const doFetchMore = useCallback(() => {
    setIsLoading(true);
    fetchMoreFn();
  }, []);

  // Text between asterisks is bold
  const filteredByDescription =
    category === AssessmentCategory.MyArchive || category === AssessmentCategory.TeamArchive
      ? "Sorted by *assessment date* - most recent first"
      : "Sorted by *assessment date* - soonest first";

  const Data = isWebView ? (
    <AssessmentsTable assessments={sortedAssessments} category={category} themeColor={themeColor} />
  ) : (
    <AssessmentsCards assessments={sortedAssessments} themeColor={themeColor} />
  );

  /* ⚠️TO-DO: Turn this back on when we sort assessments by "Scheduled" and "Unbooked" */
  // const AssessmentsKey = (
  //   <>
  //     <AssessmentKeyItem
  //       indicatorColor={palette.aqua}
  //       label="Scheduled"
  //       description="These assessments have one or more doctor visits scheduled."
  //       marginBottom
  //     />
  //     <AssessmentKeyItem
  //       indicatorColor={palette.blue}
  //       label="Unbooked"
  //       description="No doctor visits have been added to these assessments."
  //     />
  //   </>
  // );

  // Displays to the left on web, on-top on mobile
  const AsideContent = (
    <>
      <Text format={TypographyType.HeadingMedium} style={css.heading}>
        {heading}
      </Text>
      {subHeading && (
        <View style={css.subHeading}>
          <Text format={TypographyType.Tiny} color={palette.greyBlue} style={css.subHeadingLabel}>
            Team:
          </Text>
          <Text format={TypographyType.TinyBold}>{subHeading}</Text>
        </View>
      )}
      <Text format={TypographyType.Tiny} color={palette.greyBlue} style={css.helperText}>{`${
        isWebView ? "Click" : "Tap"
      } on an assessment to view the details.`}</Text>
      {/* {AssessmentsKey} */}
      {isWebView && category === AssessmentCategory.MyAssessments && (
        <Button
          style={css.createAssessmentButton}
          color={palette.yellow}
          onPress={() => navigation.navigate("CreateAssessmentScreen")}
        >
          Create Assessment
        </Button>
      )}
      {Platform.OS === "web" && isWebView && category === AssessmentCategory.MyAssessments && (
        <Button style={css.exportAssessmentButton} color={palette.yellow} onPress={displayExportAssessments}>
          Export Assessments
        </Button>
      )}
    </>
  );

  const MainContentWrapper = isWebView ? View : ContentWrap;

  // Displays to the right on web, below on mobile
  const MainContent = (
    <>
      <MainContentWrapper style={!isWebView && css.mainContentWrapper}>
        {isWebView && <SectionHeading heading="Assessments" webText={formatLongDate()} />}
        <FilterLabel text={filteredByDescription} />
        {assessments.length === 0 && (
          <Text
            format={TypographyType.Tiny}
            color={palette.slate}
            marginTop={isWebView ? 0 : 10}
            marginBottom={isWebView ? 0 : 30}
          >
            No assessments in this category.
          </Text>
        )}
      </MainContentWrapper>
      {assessments.length > 0 && (
        <>
          {Data}
          {nextToken && LoadMoreButton(doFetchMore, isLoading)}
        </>
      )}
    </>
  );

  return (
    <>
      <SplitSection aside={AsideContent} main={MainContent} />
      {bottomDivider && <SectionDivider />}
    </>
  );
};

export default AssessmentSection;
