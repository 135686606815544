import Auth from "@aws-amplify/auth";
import React, { useRef, useState, useEffect } from "react";
import { StyleSheet, Keyboard } from "react-native";
import { useSetRecoilState, useRecoilState } from "recoil";

import { Button, ButtonList } from "@/components/Button";
import { ContentWrap } from "@/components/ContentWrap";
import Loading from "@/components/Loading";
import Text from "@/components/Text";
import { TypographyType } from "@/models/Typography";
import { palette, spacing } from "@/theme";
import {
  cognitoUserSignal as recoilCognitoUserSignal,
  setCognitoUser,
  getCognitoUser,
  justLoggedIn,
} from "@/utils/recoil";

import Mod from "./modules";
import { PasswordInput } from "@/components/PasswordInput";

interface PropTypes {
  userId: string;
  pw?: string;
}

export default function ExternalLogin(props: PropTypes) {
  const [mode, setMode] = useState<"Login" | "SetPassword">(props.pw ? "SetPassword" : "Login");

  const [cognitoUserSignal, setCognitoUserSignal] = useRecoilState(recoilCognitoUserSignal);

  const cognitoUser = getCognitoUser(cognitoUserSignal);
  const setJustLoggedIn = useSetRecoilState(justLoggedIn);

  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [errorText, setErrorText] = useState("");
  const secondInput = useRef<any>();
  const [buttonDisabled, setButtonDisabled] = useState(false);

  useEffect(() => {
    async function go() {
      if (mode === "SetPassword") {
        let user = null;
        try {
          user = await Auth.signIn(props.userId, props.pw);
          if (user && user.challengeName === "NEW_PASSWORD_REQUIRED") {
            setCognitoUser(user);
            setCognitoUserSignal(2);
          }
        } catch (e) {
          if (e.code === "NotAuthorizedException" && e.message === "Incorrect username or password.") {
            setMode("Login");
          } else if (
            e.code === "NotAuthorizedException" &&
            e.message === "Temporary password has expired and must be reset by an administrator."
          ) {
            setErrorText(e.message);
          } else if (e.message === "Password attempts exceeded") {
            setErrorText(e.message);
          } else if (e.code === "UserNotFoundException") {
            setErrorText(e.message);
            // TODO: 404 message
          }

          // console.error("can't start login", e);
        }
      }
    }
    go();
  }, [props.userId, props.pw]);

  const updatePassword = async () => {
    const timeout = 0;

    setButtonDisabled(true);

    if (newPassword !== newPasswordConfirm) {
      setPasswordError("Passwords do not match");
      setButtonDisabled(false);
      return;
    }

    setTimeout(async () => {
      Auth.completeNewPassword(cognitoUser, newPassword, cognitoUser.challengeParam.requiredAttributes)
        .catch(e => {
          // ("Could not set new password, please check your passwords and try again")
          // Sentry.captureException(e)
          if (e.code === "InvalidPasswordException") {
            setPasswordError(e.message);
          }
          setButtonDisabled(false);
        })
        .then(user => {
          Keyboard.dismiss();
          setButtonDisabled(false);
        });
    }, timeout);
  };

  const login = async () => {
    setButtonDisabled(true);
    let user = null;
    try {
      user = await Auth.signIn(props.userId, password);
    } catch (e) {
      if (e.code === "NotAuthorizedException" && e.message === "Incorrect username or password.") {
        setPasswordError(e.message);
      } else if (e.message === "Password attempts exceeded") {
        setErrorText(e.message);
      } else if (e.code === "UserNotFoundException") {
        setErrorText(e.message);
        // TODO: 404 message
      }
    }

    setButtonDisabled(false);

    if (user) {
      setJustLoggedIn(true);
    }
  };

  const onSetNewPassword = (value: string) => {
    if (errorText !== "") setErrorText("");
    if (passwordError !== "") setPasswordError("");
    setNewPassword(value);
  };

  if (!cognitoUser && mode === "SetPassword") {
    return <Loading />;
  }

  if (mode === "SetPassword") {
    return (
      <ContentWrap>
        <Text format={TypographyType.HeadingMedium} style={styles.headline} testID="ExternalLogin__Header">
          S12 Solutions
        </Text>
        <Mod.ExternalAuthForm>
          <Text format={TypographyType.HeadingSmall} style={styles.subHeadline}>
            Set Password
          </Text>
          <Text format={TypographyType.Small} color={palette.slate} style={styles.infoText}>
            Please set a password of at least 10 characters.
          </Text>
          <PasswordInput
            icon="lock-outline"
            label="Password"
            returnKeyType="go"
            autoCapitalize="none"
            autoCorrect={false}
            error={passwordError !== ""}
            errorText={passwordError}
            value={newPassword}
            onSubmitEditing={() => {
              secondInput.current && secondInput.current.focus();
            }}
            onChangeText={value => onSetNewPassword(value)}
            autoFocus={true}
          />
          <PasswordInput
            icon="lock-outline"
            label="Confirm Password"
            returnKeyType="go"
            autoCapitalize="none"
            autoCorrect={false}
            ref={secondInput}
            error={passwordError !== ""}
            errorText={passwordError}
            value={newPasswordConfirm}
            onChangeText={value => setNewPasswordConfirm(value)}
            onSubmitEditing={() => updatePassword()}
          />
          {errorText !== "" && (
            <Text format={TypographyType.Tiny} color={palette.red} style={styles.errorText}>
              {errorText}
            </Text>
          )}
          <ButtonList disablePadding="bottom">
            <Button disabled={buttonDisabled} onPress={updatePassword} marginBottom={0} width="100%">
              Set Password
            </Button>
          </ButtonList>
        </Mod.ExternalAuthForm>
      </ContentWrap>
    );
  } else {
    return (
      <>
        <Text format={TypographyType.HeadingMedium} style={styles.headline} testID="ExternalLogin__Header">
          S12 Solutions
        </Text>
        <Mod.ExternalAuthForm>
          <Text format={TypographyType.HeadingSmall} style={styles.subHeadline}>
            Login
          </Text>
          <Text format={TypographyType.Small} color={palette.slate} style={styles.infoText}>
            Please login using the password you created.
          </Text>
          <PasswordInput
            icon="lock-outline"
            label="Password"
            returnKeyType="go"
            autoCapitalize="none"
            autoCorrect={false}
            error={passwordError !== ""}
            errorText={passwordError}
            value={password}
            onChangeText={setPassword}
            onSubmitEditing={() => login()}
            autoFocus={true}
          />
          {errorText !== "" && (
            <Text format={TypographyType.Tiny} color={palette.red} style={styles.errorText}>
              {errorText}
            </Text>
          )}
          <ButtonList disablePadding="bottom">
            <Button disabled={buttonDisabled} onPress={login} marginBottom={0} width="100%">
              Login
            </Button>
          </ButtonList>
        </Mod.ExternalAuthForm>
      </>
    );
  }
}

const styles = StyleSheet.create({
  errorText: {
    textAlign: "center",
  },

  headline: {
    marginBottom: spacing[40],
    alignSelf: "center",
  },
  subHeadline: {
    marginBottom: spacing[15],
    alignSelf: "center",
  },
  infoText: {
    alignSelf: "center",
    textAlign: "center",
    marginBottom: spacing[40],
  },
});
