import React from "react";
import { createStackNavigator } from "@react-navigation/stack";

import screenOptions from "@/navigationv2/config/screenOptions";
import { CCGMyClaimsRoutes } from "@/navigationv2/types";
import * as Screens from "@/screens";
import TabIcon from "../TabIcon";

const MyStack = createStackNavigator<CCGMyClaimsRoutes>();

const CCGMyClaimsStack = () => {
  return (
    <MyStack.Navigator initialRouteName="MyClaimsScreen" screenOptions={screenOptions}>
      <MyStack.Screen
        name="MyClaimsScreen"
        component={Screens.MyClaimsScreen}
        options={Screens.MyClaimsScreen.screenOptions}
      />
      <MyStack.Screen
        name="ClaimProcessingDetailsScreen"
        component={Screens.ClaimProcessingDetailsScreen}
        options={Screens.ClaimProcessingDetailsScreen.screenOptions}
      />
      <MyStack.Screen
        name="ClaimProcessingSearchScreen"
        component={Screens.ClaimProcessingSearchScreen}
        options={Screens.ClaimProcessingSearchScreen.screenOptions}
      />
    </MyStack.Navigator>
  );
};

CCGMyClaimsStack.tabOptions = {
  tabBarIcon: TabIcon("person-outline"),
  tabBarLabel: "My Claims",
};

export default CCGMyClaimsStack;
