import React, { useState, useEffect, useRef } from "react";
import { StyleSheet, View, Dimensions, TouchableOpacity } from "react-native";
import ReactMapGL, { Marker, FlyToInterpolator } from "react-map-gl";
import WebMercatorViewport from "viewport-mercator-project";
import Icon, { IconName } from "../../Icon";
import { color, palette, timing } from "../../../theme";
import { setAvailabilityState } from "../../../utils/mapping";
import { SearchResultItem } from "../../SearchResults/SearchResults.props";
import { AvailabilityState } from "libs/types/availability";
import { RouteKeys } from "@/navigationv2";
import { useRecoilValue } from "recoil";
import { searchCriteria as recoilSearchCriteria } from "../../../utils/recoil";
import { formatPostcode } from "../../../utils/helpers";
import { DoctorVisitsRoutes } from "@/navigationv2/types";
import { useNavigation, ParamListBase } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { MapIcon } from "../icons/MapIcon";
import { useBottomSheet, useIsWebView } from "@/hooks";
import BottomSheets from "../BottomSheets";
import MapUser from "../UserCard";

interface MainLocation {
  lat: number;
  lon: number;
}

export default function Map(props: {
  location: MainLocation;
  distance: number;
  marker?: SearchResultItem[];
  timeSpan?: {
    start: Date;
    end: Date;
  };
  assessmentId?: string;
}) {
  const { location, marker = [] } = props;
  const searchCriteria = useRecoilValue(recoilSearchCriteria);

  const navigation = useNavigation<StackNavigationProp<ParamListBase>>();
  const isWebView = useIsWebView();
  const { openBottomSheet, closeBottomSheet } = useBottomSheet();

  const [mapMarker, setMapMarker] = useState<
    (SearchResultItem & {
      availabilityState: string;
    })[]
  >([]);
  const [selected, setSelected] = useState<
    | (SearchResultItem & {
        availabilityState: AvailabilityState;
      })
    | null
  >(null);
  const [mapUserIsActive, setMapUserIsActive] = useState(false);
  // TODO: fix height issue
  const [viewport, setViewport] = useState({
    longitude: location.lon,
    latitude: location.lat,
    width: Dimensions.get("window").width,
    height: Dimensions.get("window").height,
    zoom: 14,
    maxZoom: 16,
    transitionDuration: timing.quick,
    transitionInterpolator: new FlyToInterpolator(),
  });

  useEffect(() => {
    const adjMarker = marker.map((m: SearchResultItem) => ({
      ...m,
      availabilityState: setAvailabilityState(m),
    }));
    setMapMarker(adjMarker);

    const handleResize = () => {
      setViewport({
        ...viewport,
        width: Dimensions.get("window").width,
        height: Dimensions.get("window").height,
      });
    };
    Dimensions.addEventListener("change", handleResize);
    return () => Dimensions.removeEventListener("change", handleResize);
  }, []);

  const refMap = useRef<ReactMapGL>(null);
  refMap.current &&
    refMap.current.getMap().on("load", () => {
      const nec: MainLocation = {
        lon: location.lon + 0.00000001,
        lat: location.lat + 0.00000001,
      };
      const swc: MainLocation = {
        lon: location.lon - 0.00000001,
        lat: location.lat - 0.00000001,
      };
      mapMarker.length > 0 &&
        mapMarker.forEach(
          (
            m: SearchResultItem & {
              availabilityState: AvailabilityState;
            }
          ) => {
            if (m.location.lon > nec.lon) {
              nec.lon = m.location.lon;
            }
            if (m.location.lat > nec.lat) {
              nec.lat = m.location.lat;
            }
            if (m.location.lon < swc.lon) {
              swc.lon = m.location.lon;
            }
            if (m.location.lat < swc.lat) {
              swc.lat = m.location.lat;
            }
          }
        );
      const newViewport = new WebMercatorViewport(viewport);
      const { longitude, latitude, zoom } = newViewport.fitBounds(
        [
          [nec.lon, nec.lat],
          [swc.lon, swc.lat],
        ],
        { padding: 100 }
      );
      setViewport({
        ...viewport,
        longitude,
        latitude,
        zoom,
      });
    });

  const handleMapClick = (arg0: { leftButton?: boolean }) => {
    if (!arg0.leftButton) return;
    setSelected(null);
    setMapUserIsActive(false);
  };

  function getStatus(
    user: SearchResultItem & {
      availabilityState: string;
    }
  ) {
    if (user.booked?.isBooked) {
      return AvailabilityState.booked;
    } else if (user.onHoliday?.isAway) {
      return AvailabilityState.away;
    } else {
      return user.availabilityState;
    }
  }

  function displayUserData() {
    if (isWebView) {
      if (!selected) return;

      const status = getStatus(selected);
      return openBottomSheet({
        type: "generic",
        data: {
          heading: selected.name,
          headingIconProps: {
            name: IconName.location_on,
            color: color[status],
          },
          component: BottomSheets.UserDetails,
          componentProps: {
            availabilityStatus: status,
            distance: selected.distance,
            address: selected.locationName,
            gender: selected.gender,
            mhts: selected.mhtEmployers.map(m => m.mht.abbreviation),
            specialisms: selected.specialties,
            languages: selected.languages,
            notes: selected.notes,
            onButtonPress: () => {
              closeBottomSheet();
              setSelected(null);
              navigateToUserProfile();
            },
          },
          onDismiss: () => setSelected(null),
          backgroundTransparent: true,
        },
      });
    } else {
      setMapUserIsActive(true);
    }
  }

  function hideUserData() {
    setSelected(null);
    if (isWebView) {
      return closeBottomSheet();
    } else {
      setMapUserIsActive(false);
    }
  }

  useEffect(() => {
    if (selected) {
      displayUserData();
    } else {
      hideUserData();
    }
  }, [selected]);

  useEffect(() => {
    mapUserIsActive && setMapUserIsActive(false);
    setSelected(null);
  }, [isWebView]);

  const navigateToUserProfile = () => {
    if (!selected?.id) return;

    navigation.push(RouteKeys.ProfileScreen, {
      id: selected.id,
      assessmentId: props.assessmentId,
      ...(!!searchCriteria &&
        ({
          assessmentLocationLat: searchCriteria?.location.lat,
          assessmentLocationLon: searchCriteria?.location.lon,
          assessmentDistance: searchCriteria?.distance,
          assessmentStartTime: searchCriteria?.timeSpan.start.toISOString(),
          assessmentEndTime: searchCriteria?.timeSpan.end.toISOString(),
        } as DoctorVisitsRoutes["ProfileScreen"])),
    });
  };

  return (
    <View style={styles.container}>
      <ReactMapGL
        ref={refMap}
        mapStyle="https://api.maptiler.com/maps/streets/style.json?key=omwfDcyrASd6Qybt7Hk2"
        onViewportChange={newViewport => setViewport(newViewport)}
        onClick={handleMapClick}
        {...viewport}
      >
        {location && (
          <Marker latitude={location.lat} longitude={location.lon}>
            <View style={styles.assessmentLocation} />
          </Marker>
        )}
        {mapMarker.length > 0 &&
          mapMarker.map((m: SearchResultItem & { availabilityState: AvailabilityState }) => {
            return (
              <Marker latitude={m.location.lat} longitude={m.location.lon} offsetLeft={-12} offsetTop={-24} key={m.id}>
                <TouchableOpacity
                  activeOpacity={1}
                  onPress={() => {
                    setViewport({
                      ...viewport,
                      longitude: m.location.lon,
                      latitude: m.location.lat,
                    });
                    setSelected(m);
                  }}
                  style={styles.markerContainer}
                >
                  <MapIcon
                    size={42}
                    primaryColor={color[getStatus(m)]}
                    active={!!selected && selected.id === m.id}
                    inactive={!!selected && selected.id !== m.id}
                  />
                </TouchableOpacity>
              </Marker>
            );
          })}
      </ReactMapGL>
      <TouchableOpacity
        onPress={() =>
          setViewport({
            ...viewport,
            longitude: location.lon,
            latitude: location.lat,
            transitionDuration: timing.quick,
            transitionInterpolator: new FlyToInterpolator(),
          })
        }
        activeOpacity={0.85}
        style={styles.locationIcon}
      >
        <Icon name="gps-fixed" color="#333" />
      </TouchableOpacity>
      <View style={styles.infoBoxContainer}>
        <MapUser
          user={{
            userName: selected?.name || "",
            userLocation:
              selected?.locationName &&
              selected?.locationName?.postcode &&
              formatPostcode(selected.locationName.postcode),
            distance: selected?.distance?.toFixed(1) || "",
            availabilityState: selected?.availabilityState,
            nextAvailability: selected?.nextAvailability,
          }}
          onPress={navigateToUserProfile}
          isActive={mapUserIsActive}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: "relative",
  },
  assessmentLocation: {
    width: 22,
    height: 22,
    borderRadius: 11,
    borderWidth: 3,
    backgroundColor: palette.greyBlue,
    borderColor: palette.navy,
  },
  locationIcon: {
    position: "absolute",
    top: 0,
    right: 15,
    padding: 20,
  },
  markerContainer: {
    zIndex: 5,
    height: 24,
    width: 24,
    padding: 15,
  },
  infoBoxContainer: {
    position: "absolute",
    bottom: 40,
    left: 10,
    zIndex: 10,
  },
});
