/* eslint-disable react-native/no-inline-styles */
import { Auth } from "@aws-amplify/auth";
import React, { useCallback, useEffect, useMemo, useState, ReactElement, useRef, memo } from "react";
import {
  Animated,
  Dimensions,
  Keyboard,
  Platform,
  SafeAreaView,
  ScrollView,
  StyleSheet,
  TouchableWithoutFeedback,
  KeyboardAvoidingView,
  View,
  NativeSyntheticEvent,
  NativeScrollEvent,
} from "react-native";
import { TouchableRipple, Portal, Chip } from "react-native-paper";
import { useRoute, RouteProp } from "@react-navigation/native";

import { BackButton, ContentWrap, LogoVector, Text } from "@/components";
import { BackButtonProps } from "@/components/BackButton/BackButton.props";
import { TypographyType } from "@/models/Typography";
import { RootNavigationRoutes } from "@/navigationv2/types";
import { DesktopWeb as DesktopNavWeb } from "@/navigationv2/web";
import { palette, spacing } from "@/theme";
import { alert, useDeviceHeight, useDeviceWidth, useKeyboard } from "@/utils/helpers";

import { Wave1, Wave2 } from "./Waves";
import { useSignout } from "@/hooks";

import ENV from "@/config";
let env = ENV.ENV || "master";

const getEnvPillColour = () => {
  if (env === "prod") {
    return undefined;
  }
  if (env === "preprod") {
    return palette.orange;
  }
  // set master or sandbox to 'development'
  env = "development";
  return palette.blueFaded;
};
const envPillBg = getEnvPillColour();

const keyboardDismiss = () => Platform.OS !== "web" && Keyboard.dismiss();
const zeroArray = [0];
const { HeadingLarge, HeadingSmall, TinyBold, Micro, MicroBold } = TypographyType;

const WebWavesHeaderBG = memo(({ deviceWidth }: { deviceWidth: number }) => (
  <>
    <Wave2 width={deviceWidth} style={[css.wave, css.waveMirrored, { left: 0, top: 15 }]} />
    <Wave1 width={deviceWidth} style={[css.wave, css.waveMirrored, { left: 0, top: -40 }]} />
    <Wave1 width={deviceWidth} style={[css.wave, { left: 0, top: -25, transform: [{ rotate: "-1deg" }] }]} />
  </>
));

WebWavesHeaderBG.displayName = "WebWaves";

interface AppScreenProps {
  contextLabel: string;
  pageTitle: string;
  backButton?: BackButtonProps;
  children?: React.ReactNode;
  passRef?: (ref: any) => void;
  refreshControl?: any;
  testID?: string;
  mobileHeaderContent?: React.ComponentType<any>;
  greyWebBG?: boolean;
  isExternal?: boolean;
}

export const AppScreen = (props: AppScreenProps) => {
  const {
    backButton,
    children,
    contextLabel,
    pageTitle,
    passRef,
    refreshControl,
    testID,
    mobileHeaderContent,
    greyWebBG = false,
    isExternal = false,
  } = props;
  const signout = useSignout();
  const isMobile = useIsMobile();
  const deviceWidth = useDeviceWidth();
  const deviceHeight = useDeviceHeight();
  const topBarHeight = isMobile ? (deviceWidth <= 320 ? 30 : 40) : 90;
  const route = useRoute<RouteProp<RootNavigationRoutes, "Eula">>();
  const showBackButton = backButton && backButton.enabled;
  let isAccepted = true;

  if (route.params?.isAccepted === "false") {
    isAccepted = false;
  }

  const containerStyles = useMemo(
    () => [
      css.screen,
      {
        backgroundColor: isMobile ? palette.blue : !isMobile && greyWebBG ? palette.cloud : palette.white,
      },
    ],
    [isMobile]
  );

  const topBarStyles = useMemo(
    () => [
      css.topBar,
      {
        width: deviceWidth,
        height: topBarHeight,
        alignItems: isMobile ? ("center" as const) : ("flex-start" as const),
      },
    ],
    [deviceWidth, topBarHeight, isMobile]
  );

  const topBarMaskStyles = useMemo(() => [css.topBarMask, { width: deviceWidth }], [deviceWidth]);

  const pageTitleWithoutBreaks = useMemo(() => pageTitle.replace("\n", ""), [pageTitle]);

  const bottomBackgroundStyles = useMemo(
    () => [
      css.bottomBGColor,
      {
        width: deviceWidth,
        height: deviceHeight * 0.25,
      },
      !isMobile && greyWebBG ? { backgroundColor: palette.cloud } : { backgroundColor: palette.white },
    ],
    [deviceWidth, deviceHeight]
  );

  const logoutPress = useCallback(
    () =>
      alert(
        "Logout",
        "Are you sure you want to logout?",
        () => null,
        () => signout(),
        "Cancel logout",
        "Yes, logout"
      ),
    []
  );

  return (
    <Portal.Host>
      <KeyboardAvoidingView
        behavior="height"
        keyboardVerticalOffset={Platform.OS === "android" ? -150 : 0}
        style={containerStyles}
        testID={`AppScreen-${testID}`}
      >
        <SafeAreaView style={css.safeAreaView} testID="AppScreen-SafeAreaView">
          <View style={css.wrapper} testID="AppScreen-MainView">
            <View style={topBarStyles}>
              <ContentWrap style={css.contentWrap}>
                {Platform.OS !== "web" && <View style={topBarMaskStyles} />}
                {showBackButton && backButton && <BackButton {...backButton} />}
                <LogoVector
                  size={isMobile ? (deviceWidth <= 320 ? 20 : 30) : 25}
                  style={isMobile ? css.logoVectorMobile : css.logoVector}
                />
                {env !== "prod" && (
                  <Chip style={css.pill} textStyle={css.pillText}>
                    {env}
                  </Chip>
                )}
                {!isMobile && (
                  <TouchableRipple
                    rippleColor={"rgba(255,255,255,0.1)"}
                    onPress={logoutPress}
                    style={css.webTopBarLink}
                  >
                    <Text format={Micro} style={css.webTopBarLinkText}>
                      Logout
                    </Text>
                  </TouchableRipple>
                )}
              </ContentWrap>
            </View>

            {!isMobile && (
              <>
                <View style={css.webNavBarWrap}>
                  <View style={css.webNavBarInner}>
                    <View style={css.webNavBar}>
                      <ContentWrap style={css.webNavBarContent}>
                        <View>
                          <Text format={MicroBold} style={css.contextLabelWeb}>
                            {contextLabel}
                          </Text>
                          <Text format={HeadingSmall} style={css.pageTitleWeb}>
                            {pageTitleWithoutBreaks}
                          </Text>
                        </View>

                        {isAccepted && <DesktopNavWeb />}
                      </ContentWrap>
                    </View>
                  </View>
                </View>
                <WebWavesHeaderBG deviceWidth={deviceWidth} />
              </>
            )}
            <HeaderOffsetScroller
              contextLabel={contextLabel}
              pageTitle={pageTitle}
              mobileHeaderContent={mobileHeaderContent}
              refreshControl={refreshControl}
              passRef={passRef}
              isExternal={isExternal}
            >
              {children}
            </HeaderOffsetScroller>
          </View>
        </SafeAreaView>

        <View style={bottomBackgroundStyles} />
      </KeyboardAvoidingView>
    </Portal.Host>
  );
};

const HeaderOffsetScroller = (props: {
  children: React.ReactNode;
  contextLabel: string;
  pageTitle: string;
  mobileHeaderContent: any;
  refreshControl?: ReactElement<any>;
  passRef?: (ref: any) => void;
  isExternal?: boolean;
}) => {
  const scrollView = useRef<any>();
  const MobileHeaderContent = props.mobileHeaderContent || View;
  const keyboard = useKeyboard();
  const isMobile = useIsMobile();
  const deviceHeight = useDeviceHeight();
  const deviceWidth = useDeviceWidth();
  const topBarHeight = isMobile ? (deviceWidth <= 320 ? 30 : 40) : 90;
  const [mobileHeaderHeight, setMobileHeaderHeight] = useState(0);
  const [scrollY, setScrollY] = useState(new Animated.Value(0));
  const stickyCornerStyles = useMemo(
    () => [css.stickyCornersContainer, { width: deviceWidth, top: mobileHeaderHeight }],
    [deviceWidth, mobileHeaderHeight]
  );
  const headerTranslateY = scrollY.interpolate({
    inputRange: [0, mobileHeaderHeight],
    outputRange: [0, (mobileHeaderHeight / 2) * -1],
    extrapolate: "clamp",
  });

  useEffect(() => {
    if (props.passRef) props.passRef(scrollView);
  }, [scrollView]);

  const _setScrollYState = useCallback((e: NativeSyntheticEvent<NativeScrollEvent>) => {
    setScrollY(new Animated.Value(e.nativeEvent.contentOffset.y));
  }, []);

  const scrollViewStyles = useMemo(
    () => [
      css.scrollView,
      { marginTop: isMobile ? topBarHeight : 160 },
      /**
       * In React Navigation 5, ScrollViews are given unlimited height if there is no
       * Tab Bar. For some reason. This means that they're unable to be scrolled. Fantastic.
       * @link https://github.com/react-navigation/react-navigation/issues/8631
       */
      !isMobile && Platform.OS === "web" && { maxHeight: "calc(100vh - 160px)" },
      /**
       * Introduce new property "isExternal" to manage the external user UI issue when accessing web through small devices
       * This flag is use only with following conditions
       */
      isMobile && Platform.OS === "web" && props.isExternal && { maxHeight: "calc(100vh - 50px)" },
    ],
    [isMobile, topBarHeight]
  );

  const screenContentStyles = useMemo(
    () => [
      css.content,
      {
        marginTop: isMobile ? mobileHeaderHeight : 0,
        paddingTop: isMobile ? 50 : 25,
        paddingBottom: keyboard.height,
        backgroundColor: isMobile ? palette.white : "",
        minHeight: deviceHeight * 0.75 - mobileHeaderHeight,
      },
    ],
    [isMobile, deviceHeight, mobileHeaderHeight]
  );

  return (
    <>
      {isMobile && (
        <Animated.View
          style={[
            [css.mobileHeader, deviceWidth <= 320 && css.mobileHeaderSmall],
            {
              top: topBarHeight,
              width: deviceWidth,
              transform: [{ translateY: headerTranslateY }],
            },
          ]}
          onLayout={(e: { nativeEvent: any }) => setMobileHeaderHeight(e.nativeEvent.layout.height)}
        >
          <ContentWrap>
            <Text style={css.contextLabelMobile} format={TinyBold}>
              {props.contextLabel}
            </Text>
            <Text style={css.pageTitleMobile} format={HeadingLarge}>
              {props.pageTitle}
            </Text>
            {props.mobileHeaderContent && (
              <View style={css.mobileHeaderContentWrapper}>
                <MobileHeaderContent />
              </View>
            )}
          </ContentWrap>
        </Animated.View>
      )}

      <ScrollView
        style={scrollViewStyles}
        scrollEventThrottle={8}
        stickyHeaderIndices={isMobile && Platform.OS !== "web" ? zeroArray : undefined}
        keyboardShouldPersistTaps="always"
        keyboardDismissMode="none"
        ref={scrollView}
        refreshControl={props.refreshControl}
        onScroll={_setScrollYState}
        onMomentumScrollBegin={_setScrollYState}
        onMomentumScrollEnd={_setScrollYState}
        testID="AppScreen__ScrollView"
      >
        {isMobile && (
          <View style={stickyCornerStyles}>
            <View style={css.stickyCornersShadow} />
            <View style={css.stickyCorners} />
          </View>
        )}

        {Platform.OS !== "web" && (
          <TouchableWithoutFeedback onPress={keyboardDismiss} testID="scroller-touchable">
            <View style={screenContentStyles}>{props.children}</View>
          </TouchableWithoutFeedback>
        )}

        {Platform.OS === "web" && (
          <>
            <View style={screenContentStyles}>{props.children}</View>
          </>
        )}
      </ScrollView>
    </>
  );
};

const css = StyleSheet.create({
  bottomBGColor: {
    position: "absolute",
    left: 0,
    bottom: 0,
    zIndex: -1,
  },
  contentWrap: {
    flexDirection: "row",
    justifyContent: "center",
  },
  content: {
    position: "relative",
  },

  contextLabelMobile: {
    color: palette.blueFaded,
    marginBottom: spacing[5],
  },
  contextLabelWeb: {
    color: palette.greyBlue,
    marginBottom: spacing[5],
  },

  logoVector: { marginTop: 10 },
  logoVectorMobile: { marginTop: 5 },

  mobileHeader: {
    position: "absolute",
    left: 0,
    paddingTop: spacing[15],
    paddingBottom: spacing[30],
  },
  mobileHeaderSmall: {
    paddingTop: spacing[20],
    paddingBottom: spacing[25],
  },

  mobileHeaderContentWrapper: {
    marginTop: spacing[5],
  },

  pageTitleMobile: {
    color: palette.white,
  },
  pageTitleWeb: {
    color: palette.navy,
  },

  pill: {
    marginLeft: 10,
    marginTop: Platform.OS === "web" ? 10 : 5,
    height: Platform.OS === "web" ? 25 : 30,
    backgroundColor: envPillBg,
  },
  pillText: {
    top: Platform.OS === "web" ? 0 : 2,
    paddingTop: 0,
    marginTop: 0,
  },

  safeAreaView: {
    position: "relative",
    flex: 1,
    overflow: "hidden",
  },

  screen: {
    flex: 1,
  },

  scrollView: {
    flex: 1,
  },

  stickyCornersContainer: {
    position: "absolute",
    left: 0,
    height: 40,
    backgroundColor: palette.blue,
  },
  stickyCorners: {
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    borderTopLeftRadius: 40,
    borderTopRightRadius: 40,
    backgroundColor: palette.white,
    shadowColor: palette.white,
    shadowOffset: { width: 0, height: 10 },
    shadowOpacity: 1,
    shadowRadius: 10,
  },
  stickyCornersShadow: {
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    borderTopLeftRadius: 40,
    borderTopRightRadius: 40,
    backgroundColor: palette.white,
    shadowColor: palette.shadow,
    shadowOffset: { width: 0, height: -7.5 },
    shadowOpacity: 0.15,
    shadowRadius: 5,
  },

  topBar: {
    position: "absolute",
    left: 0,
    top: 0,
    backgroundColor: palette.blue,
    zIndex: 1,
  },
  topBarMask: {
    position: "absolute",
    left: 0,
    top: -100,
    height: 100,
    backgroundColor: palette.blue,
  },

  wave: {
    position: "absolute",
    zIndex: -1,
  },
  waveMirrored: {
    transform: [{ scaleX: -1 }],
  },

  webNavBarWrap: {
    position: "absolute",
    left: 0,
    top: 45,
    width: "100%",
    height: 95,
    zIndex: 11,
    alignItems: "center",
  },
  webNavBarInner: {
    maxWidth: 1000,
    alignSelf: "center",
    width: "100%",
  },
  webNavBar: {
    width: "100%",
    height: 90,
    borderRadius: 12,
    backgroundColor: palette.white,
    shadowColor: palette.shadow,
    shadowOffset: { width: 0, height: 10 },
    shadowOpacity: 0.075,
    shadowRadius: 20,
    paddingLeft: spacing[20],
  },
  webNavBarContent: {
    height: "100%",
    paddingRight: 0,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },

  webTopBarLink: {
    position: "absolute",
    right: 30,
    top: 5,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    borderBottomRightRadius: 6,
    borderBottomLeftRadius: 6,
    paddingHorizontal: 12,
    paddingVertical: 8,
    ...(Platform.OS === "web" && {
      cursor: "pointer",
    }),
  },
  webTopBarLinkText: {
    color: palette.white,
    textTransform: "uppercase",
    textDecorationLine: "underline",
    textDecorationColor: palette.white,
  },

  wrapper: {
    position: "relative",
    flex: 1,
  },
});

function useIsMobile() {
  const viewport = Platform.OS === "web" ? "window" : "screen";
  const [isMobile, setIsMobile] = useState(Dimensions.get(viewport).width <= 999);
  useEffect(() => {
    const _handleResize = () => setIsMobile(Dimensions.get(viewport).width <= 999);
    Dimensions.addEventListener("change", _handleResize);
    return () => Dimensions.removeEventListener("change", _handleResize);
  }, []);
  return isMobile;
}
