import React, { useState, useEffect } from "react";

import { RouteKeys } from "@/navigationv2";
import { AllCCGRoutes, CCGTabRoutes, ScreenNavigationProps, ScreenOptions } from "@/navigationv2/types";
import { createScreenTitle } from "@/navigationv2/utilities";

import ClaimProcessingDetails from "@/components/ClaimProcessingDetails";
import { BackButtonProps } from "@/components/BackButton/BackButton.props";
import { AppScreen } from "@/layouts/AppScreen";
import { color } from "@/theme";
import ClaimProcessingProvider from "@/components/ClaimProcessing/ClaimProcessingProvider";

interface PropTypes extends ScreenNavigationProps<CCGTabRoutes, AllCCGRoutes, RouteKeys.ClaimProcessingDetailsScreen> {}

const ClaimProcessingDetailsScreen = (props: PropTypes) => {
  const { navigation, route } = props;

  const { claimId } = route.params;
  const [displayId, setDisplayId] = useState("");

  const backButtonConfig: BackButtonProps = {
    enabled: true,
    float: true,
    color: color.textWhite,
  };

  useEffect(() => {
    displayId &&
      navigation.setOptions({
        title: createScreenTitle(`Claim Details (${displayId})`),
      });
  }, [displayId, navigation]);

  return (
    <AppScreen contextLabel="Claims" pageTitle={displayId} backButton={backButtonConfig}>
      <ClaimProcessingProvider>
        <ClaimProcessingDetails id={claimId} retrieveDisplayId={(id: string) => setDisplayId(id)} />
      </ClaimProcessingProvider>
    </AppScreen>
  );
};

const screenOptions: ScreenOptions = {
  title: createScreenTitle("Claim Details"),
};

ClaimProcessingDetailsScreen.screenOptions = screenOptions;

export default ClaimProcessingDetailsScreen;
