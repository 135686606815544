import React, { useMemo, useState, useEffect } from "react";
import { StyleSheet, View } from "react-native";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

import { TypographyType } from "@/models/Typography";
import { palette, spacing } from "@/theme";

import Loading from "@/components/Loading";
import Text from "@/components/Text";
import { ContentWrap } from "@/components/ContentWrap";
import { RadioButton, RadioButtonGroup } from "@/components/Radio/Radio";
import ItemSpacer from "@/components/ItemSpacer";

// pre-approved email lists
const GET_APPROVED_EMAIL_LISTS = gql`
  query GetAmhpTeam($id: ID!) {
    getAmhpTeam(id: $id) {
      mhaEmailAddresses {
        name
        recipients {
          email
          name
        }
      }
    }
  }
`;

interface MhaEmailAddresses {
  name?: string;
  recipients?: {
    email?: string;
    name?: string;
  }[];
}

interface MhaEmailAddressesResponse {
  getAmhpTeam: {
    mhaEmailAddresses: MhaEmailAddresses[];
  };
}

export const EmailLists = (props: {
  amhpTeamId?: string;
  emailAddresses: string[];
  setEmailAddresses: React.Dispatch<React.SetStateAction<string[]>>;
  setApprovedRecipientChoice: React.Dispatch<React.SetStateAction<string[]>>;
  displayComponent: boolean;
}) => {
  const [activeEmailSet, setActiveEmailSet] = useState("0");
  const { amhpTeamId, setEmailAddresses, displayComponent, setApprovedRecipientChoice } = props;
  if (!amhpTeamId) {
    return <></>;
  }
  const { loading: loadingLists, error, data } = useQuery<MhaEmailAddressesResponse>(GET_APPROVED_EMAIL_LISTS, {
    variables: { id: amhpTeamId },
    fetchPolicy: "cache-and-network",
  });

  const emails = useMemo(
    () => data?.getAmhpTeam?.mhaEmailAddresses?.map(ad => ad?.recipients?.map(add => add?.email ?? "") || []),
    [data?.getAmhpTeam?.mhaEmailAddresses]
  );

  useEffect(() => {
    if (displayComponent) {
      setEmailAddresses(emails && emails.length ? emails[0] : []);
      setApprovedRecipientChoice(data?.getAmhpTeam?.mhaEmailAddresses[0]?.recipients?.map(e => e.name ?? "") ?? []);
    }
  }, [emails, displayComponent]);

  const radioButtonGroupValueChange = (value: string) => {
    if (!data?.getAmhpTeam?.mhaEmailAddresses[parseInt(value)]) return;
    setActiveEmailSet(value);
    setApprovedRecipientChoice(
      data?.getAmhpTeam?.mhaEmailAddresses[parseInt(value)]?.recipients?.map(e => e.name ?? "") ?? []
    );
    emails?.length && setEmailAddresses(emails[parseInt(value)]);
  };

  const emailLists = useMemo(() => {
    const lists =
      data?.getAmhpTeam.mhaEmailAddresses?.map((a, i) => {
        const isActive = i.toString() === activeEmailSet;
        return (
          <View
            key={i}
            style={[
              styles.listItem,
              {
                borderBottomWidth: i === data?.getAmhpTeam?.mhaEmailAddresses?.length - 1 ? 0 : 1,
              },
            ]}
          >
            <>
              <RadioButton value={i.toString()} status={isActive} />
              <View>
                {a.recipients?.map(aa => (
                  <View style={styles.listItemContent} key={aa.email}>
                    <Text format={TypographyType.SmallBold} color={isActive ? palette.slate : palette.greyBlue}>
                      {aa.name}{" "}
                    </Text>
                    <Text format={TypographyType.Small} color={isActive ? palette.slate : palette.greyBlue}>
                      ({aa.email})
                    </Text>
                  </View>
                ))}
              </View>
            </>
          </View>
        );
      }) || [];

    return lists;
  }, [emails, activeEmailSet]);

  return (
    <ContentWrap>
      {displayComponent ? (
        loadingLists ? (
          <Loading text="Loading Lists" />
        ) : error ? (
          <Text format={TypographyType.HeadingSmall} style={styles.smallHeading}>
            There was an error loading email lists
          </Text>
        ) : (
          <>
            <Text format={TypographyType.HeadingSmall} style={styles.smallHeading}>
              Select Recipients
            </Text>
            {emailLists.length ? (
              <ItemSpacer gap={20}>
                <RadioButtonGroup onValueChange={radioButtonGroupValueChange} value={activeEmailSet}>
                  {emailLists}
                </RadioButtonGroup>
              </ItemSpacer>
            ) : (
              <Text format={TypographyType.Small} color={palette.greyBlue} style={styles.defaultListCopy}>
                You do not have any pre-configured recipient lists.
              </Text>
            )}
          </>
        )
      ) : (
        <></>
      )}
    </ContentWrap>
  );
};

const styles = StyleSheet.create({
  smallHeading: {
    marginBottom: spacing[15],
  },
  listItem: {
    flexDirection: "row",
    paddingVertical: spacing[20],
    borderBottomColor: palette.grey,
    alignItems: "flex-start",
  },
  listItemContent: { paddingBottom: spacing[10] },
  defaultListCopy: { marginBottom: spacing[30] },
});
