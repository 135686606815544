import React from "react";
import { Platform, StyleSheet, TouchableWithoutFeedback, View, ViewStyle } from "react-native";

import { TypographyType } from "@/models/Typography";
import { palette, spacing } from "@/theme";

import ItemSpacer from "@/components/ItemSpacer";
import Text from "@/components/Text";

interface SearchOptionsProps {
  value: string;
  onValueChange(value: string): void;
  options: Array<{
    label: string;
    value: string;
  }>;
}

export function SearchOptions(props: SearchOptionsProps) {
  return (
    <ItemSpacer gap={5}>
      {props.options.map((option, key) => (
        <SearchOption
          key={key}
          label={option.label}
          selected={props.value === option.value}
          onPress={() => props.onValueChange(option.value)}
        />
      ))}
    </ItemSpacer>
  );
}

function SearchOption(props: { label: string; selected: boolean; onPress(): void }) {
  return (
    <TouchableWithoutFeedback onPress={props.onPress}>
      <View style={styles.option}>
        <View style={props.selected ? styles.radioSelected : styles.radio}>
          {props.selected && <View style={styles.radioInner} />}
        </View>
        <Text format={TypographyType.Tiny} style={labelStyles(props.selected)}>
          {props.label}
        </Text>
      </View>
    </TouchableWithoutFeedback>
  );
}

const labelStyles = (selected: boolean) => ({
  fontFamily: selected ? "Lato-Bold" : "Lato-Regular",
});

const defaultRadioStyles: ViewStyle = {
  position: "relative",
  width: 20,
  height: 20,
  borderRadius: 24,
  borderWidth: 2,
  marginRight: spacing[10],
};

const styles = StyleSheet.create({
  option: {
    flexDirection: "row",
    ...Platform.select({
      web: {
        cursor: "pointer",
      },
    }),
  },

  radio: {
    ...defaultRadioStyles,
    borderColor: palette.greyBlue,
    backgroundColor: palette.transparent,
  },
  radioSelected: {
    ...defaultRadioStyles,
    borderColor: palette.blue,
    backgroundColor: palette.blue,
  },

  radioInner: {
    position: "absolute",
    left: 1,
    top: 1,
    width: 14,
    height: 14,
    borderWidth: 2,
    borderRadius: 14,
    borderColor: palette.white,
  },
});
