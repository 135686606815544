/* eslint-disable complexity */
import dayjs from "dayjs";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { StyleSheet, View } from "react-native";
import { Divider, TouchableRipple } from "react-native-paper";

import { useSetRecoilState } from "recoil";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";

import { AnalyticsEvent } from "libs/analytics/events";
import { formatAddress } from "libs/formatters/address";
import { CreateClaimInput, LocationNameInput, LocationType, AmhpAssessmentQueryVariables } from "libs/types/API";
import {
  ccgRequiresNhsNumber,
  hasClaims,
  hasFullPatientName,
  shouldConfirmAssessmentTookPlace,
} from "libs/utils/featureFlags";

import { useAPIVersion, useBottomSheet } from "@/hooks";
import { GET_ASSESSMENT, UPDATE_ASSESSMENT, GetAssessmentResponse } from "@/models/Assessment";
import { DoctorVisitsWithClaimIdResponse } from "../../models/gql/fragments";
import { CREATE_CLAIM } from "@/models/Claim";
import { UPDATE_DOCTOR_VISIT } from "@/models/DoctorProfile";
import { buildErr, createClaimError, S12Error } from "@/models/Error";
import { TypographyType } from "@/models/Typography";
import { AllAMHPRoutes } from "@/navigationv2/types";
import { palette, spacing } from "@/theme";
import { recordEvent } from "@/utils/analytics";
import {
  checkMutationInput,
  createClaimId,
  formatAllUpperCase,
  formatPostcode,
  getInitials,
  formatNhsNumber,
} from "@/utils/helpers";
import { snackbarMessage, lastException } from "@/utils/recoil/index";

import { CreateClaimFunc, UpdateAssessmentFunc, UpdateDoctorVisitFunc, VisitItem } from "./CreateClaim.props";
import { Button } from "../Button";
import { Checkbox } from "../Checkbox/Checkbox";
import { ContentWrap } from "../ContentWrap";
import DualDatePicker from "../DatePicker/DualDatePicker";
import GPSearchV1 from "../GPSearch";
import GPSearchV2 from "../GPSearch/GPSearch.context";
import { GpPracticeItem } from "../GPSearch/GPSearch.props";
import { RetrieveDataObject } from "../LocationSearch/LocationSearch.props";
import { HorizontalSpacer } from "../Spacer";
import Switch from "../Switch";
import Text from "../Text";
import { TextInput } from "../TextInput/TextInput";
import DualTimePicker from "../TimePicker/DualTimePicker";
import { API_V2_TOGGLES } from "@/api/types";

const INITIAL_LOCATION = {
  city: "",
  postcode: "",
  address: "",
};

const LAST_YEAR = new Date(new Date().setFullYear(new Date().getFullYear() - 1));

interface PropTypes {
  visitData: VisitItem;
}

// TODO: When this gets ref
const CreateClaim = (props: PropTypes) => {
  const { id, assessment, doctor, time, team } = props.visitData;

  // Handle gp search version
  const { v2 } = useAPIVersion(API_V2_TOGGLES.GP_SEARCH);
  const GPSearch = useMemo(() => (v2 ? GPSearchV2 : GPSearchV1), [v2]);

  const [hasPractice, setHasPractice] = useState(true);
  const [gpPractice, setGpPractice] = useState<GpPracticeItem | null>(null);
  const navigation = useNavigation<StackNavigationProp<AllAMHPRoutes>>();
  const [ccgNotAvailable, setCcgNotAvailable] = useState(
    !assessment.ccg || !assessment.ccg.featureFlags || !hasClaims(assessment.ccg.featureFlags)
  );
  const [assessmentDate, setAssessmentDate] = useState(dayjs(time));
  const [assessmentTime, setAssessmentTime] = useState({
    hours: new Date(time).getHours(),
    minutes: new Date(time).getMinutes(),
  });
  const [hasResidence, setHasResidence] = useState(true);
  const [ccg, setCcg] = useState<{
    id: string;
    name: string;
    featureFlags: string;
  } | null>({
    id: assessment.ccg?.id as string,
    name: assessment.ccg?.name as string,
    featureFlags: assessment.ccg?.featureFlags as string,
  });
  const [patientAddress, setPatientAddress] = useState<LocationNameInput>(INITIAL_LOCATION);
  const [assessmentLocation, setAssessmentLocation] = useState<LocationNameInput>(INITIAL_LOCATION);
  const [assessmentTookPlace, setAssessmentTookPlace] = useState<boolean | null>(null);
  const [nhsNumber, setNhsNumber] = useState(assessment.patientNhsNumber || "");
  const [nhsNumberNotFound, setNhsNumberNotFound] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [overrideDefault, setOverrideDefault] = useState(false);
  const [createClaim] = useMutation<CreateClaimFunc>(CREATE_CLAIM);
  const [updateAssessment] = useMutation<UpdateAssessmentFunc>(UPDATE_ASSESSMENT);
  const [updateDoctorVisit] = useMutation<UpdateDoctorVisitFunc>(UPDATE_DOCTOR_VISIT);
  const setMessage = useSetRecoilState(snackbarMessage);
  const setLastException = useSetRecoilState(lastException);
  const [previousAssessmentLocation, setPreviousAssessmentLocation] = useState<LocationNameInput>(
    assessment.locationName
  );
  const [previousCcg, setPreviousCcg] = useState<{
    id: string;
    name: string;
    featureFlags: string;
  } | null>(assessment.ccg || null);

  // detect if the CCG has been determined afresh wirth this claim creation
  const [newCCGSelected, setNewCCGSelected] = useState(false);

  const isFirstRun = useRef(true);

  const assessmentQuery = useQuery<GetAssessmentResponse, AmhpAssessmentQueryVariables>(GET_ASSESSMENT, {
    variables: { id: assessment.id },
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    // We do not want to force the user to accept default values from previous claim so if anything has changed we ignore defaults
    if (!overrideDefault) {
      const previousClaim:
        | DoctorVisitsWithClaimIdResponse
        | undefined = assessmentQuery.data?.assessment.doctorVisits?.items?.find(item => item.claimDisplayId !== null);

      // The most important thing is to derive the CCG information if it exists on the previous claim.
      if (previousClaim?.claim.organisation) {
        setCcg({
          id: previousClaim.claim.organisation.id,
          name: previousClaim.claim.organisation.name,
          featureFlags: previousClaim.claim.organisation.featureFlags,
        });
      }
      if (previousClaim?.claim.locationType === "gPPractice" && previousClaim?.claim.locationText) {
        // When we save the GP address to the claim, we save it as a comma separated field - name , postcode and id
        const ltArr: string[] = previousClaim?.claim.locationText.split(",");
        setGpPractice({ name: ltArr[0], postcode: ltArr[1], id: ltArr[2], town: "", contact: "", ccgId: "" });
      }

      if (previousClaim?.claim.locationType === "patientAddress" && previousClaim?.claim.claimPostcode) {
        // Turn off the GP practice switch
        setHasPractice(false);
        //  Claims based on patient address only contain the first part of the patient postcode. Consequently, we have added
        // a new claim postcode field in order to addess the full patient postcode
        setPatientAddress({ city: "", postcode: previousClaim.claim.claimPostcode, address: "" });
      }

      if (previousClaim?.claim.locationType === "assessmentLocation" && previousClaim?.claim.locationText) {
        // Turn off the GP practice and patient residence switches
        setHasPractice(false);
        setHasResidence(false);
      }
    }
  }, [assessmentQuery]);

  useEffect(
    function updateAssessmentWithCCGId() {
      if (isFirstRun.current) {
        isFirstRun.current = false;
        return;
      }
      updateAssessment({
        variables: {
          input: checkMutationInput({
            id: assessment.id,
            ...(!!ccg && { ccgId: ccg.id }),
            ...(assessmentLocation !== INITIAL_LOCATION && {
              locationName: assessmentLocation,
            }),
            ccgDetermination: !ccg
              ? null
              : hasPractice
              ? LocationType.gPPractice
              : hasResidence
              ? LocationType.patientAddress
              : LocationType.assessmentLocation,
          }),
        },
        refetchQueries: [
          {
            query: GET_ASSESSMENT,
            variables: { id: assessment.id },
          },
        ],
      });
    },
    [ccg]
  );

  useEffect(() => {
    if (!hasPractice && !hasResidence) {
      // if GP practice and patient residence switches off
      setAssessmentLocation({
        city: assessment.locationName.city,
        postcode: assessment.locationName.postcode,
        address: assessment.locationName.address,
        addressNotes: assessment.locationName.addressNotes,
      });
      if (!overrideDefault) {
        setCcgNotAvailable(!assessment.ccg || !assessment.ccg.featureFlags || !hasClaims(assessment.ccg.featureFlags));
      }
    }

    // if the assessment location has changed, then re-determine the associated CCG
    if (!hasPractice || !hasResidence) {
      if (assessment.locationName === previousAssessmentLocation && ccg !== previousCcg) {
        setCcg(previousCcg || null);
        setCcgNotAvailable(!previousCcg || !previousCcg.featureFlags || !hasClaims(previousCcg.featureFlags));
      } else {
        setPreviousCcg(ccg || null);
        setPreviousAssessmentLocation(assessment.locationName);
      }
    }

    if (assessment.locationName === previousAssessmentLocation && ccg !== previousCcg) {
      setCcg(previousCcg || null);
      setCcgNotAvailable(!previousCcg || !previousCcg.featureFlags || !hasClaims(previousCcg.featureFlags));
      setNewCCGSelected(false);
    } else {
      setPreviousCcg(ccg || null);
      setPreviousAssessmentLocation(assessment.locationName);
    }
  }, [hasResidence, hasPractice]);

  function locationLookup() {
    return {
      headline: hasResidence ? "patient's address" : "assessment location",
      label: hasResidence ? "Patient's Address" : "Assessment Location",
      locationValue: hasResidence ? patientAddress : assessmentLocation,
      setLocationValue: hasResidence ? setPatientAddress : setAssessmentLocation,
      type: hasResidence ? LocationType.patientAddress : LocationType.assessmentLocation,
    };
  }

  const formValid =
    (ccg && ccgRequiresNhsNumber(ccg.featureFlags)
      ? nhsNumber.replace(/\s/g, "").length === 10 || nhsNumberNotFound
      : true) &&
    (ccg && shouldConfirmAssessmentTookPlace(ccg.featureFlags) ? assessmentTookPlace !== null : true) &&
    ((hasPractice && gpPractice) ||
      (hasResidence && patientAddress.postcode !== "") ||
      (!hasResidence && assessmentLocation.postcode !== ""));

  const resetForm = () => {
    setGpPractice(null);
    setPatientAddress(INITIAL_LOCATION);
    setAssessmentLocation(INITIAL_LOCATION);
    setNhsNumber(assessment.patientNhsNumber || "");
    setNhsNumberNotFound(false);
    setAssessmentTookPlace(null);
  };

  const { headline, label, locationValue, setLocationValue, type } = locationLookup();

  const onNhsNumberChange = (value: string) => {
    if (value.length <= 12) {
      setNhsNumber(formatNhsNumber(value));
    }
  };

  const submitClaim = () => {
    if (doctor.id && id && ccg && hasClaims(ccg.featureFlags)) {
      const newAssessmentDate = assessmentDate
        .hour(assessmentTime.hours)
        .minute(assessmentTime.minutes)
        .second(0)
        .toISOString();

      setSubmitted(true);
      const input = checkMutationInput<CreateClaimInput>({
        claimDoctorId: doctor.id || "",
        claimAmhpId: assessment.amhp.id,
        amhpTeamId: props.visitData.team.id,
        claimVisitId: id || "",
        claimOrganisationId: ccg.id,
        displayId: createClaimId(doctor.name),
        locationText:
          hasPractice && gpPractice
            ? `${formatAllUpperCase(gpPractice.name)}, ${gpPractice.postcode}, ${gpPractice.id}`
            : formatPostcode(locationValue.postcode),
        claimPostcode: hasPractice && gpPractice ? gpPractice.postcode : locationValue.postcode,
        locationType: hasPractice ? LocationType.gPPractice : type,
        locationName: assessment.locationName,
        assessmentPostcode: formatPostcode(assessment.locationName.postcode),
        patientInitials: getInitials(assessment.patientName),
        ...(hasFullPatientName(ccg.featureFlags) && {
          patientFullName: assessment.patientName,
        }),
        ...(shouldConfirmAssessmentTookPlace(ccg.featureFlags) && {
          assessmentTookPlace: assessmentTookPlace,
        }),
        ...(ccgRequiresNhsNumber(ccg.featureFlags) && {
          patientNhsNumber: nhsNumber.trim() === "" ? "unknown" : nhsNumber.replace(/\s/g, ""),
        }),
        visitDate: newAssessmentDate,
      });

      if (nhsNumber.trim() !== assessment.patientNhsNumber && ccgRequiresNhsNumber(team.featureFlags)) {
        updateAssessment({
          variables: {
            input: checkMutationInput({
              id: assessment.id,
              patientNhsNumber: nhsNumber.trim().replace(/\s/g, ""),
            }),
          },
          refetchQueries: [
            {
              query: GET_ASSESSMENT,
              variables: { id: assessment.id },
            },
          ],
        });
      }

      if (newAssessmentDate !== new Date(time).toISOString()) {
        updateDoctorVisit({
          variables: {
            input: {
              id,
              time: newAssessmentDate,
            },
          },
        });
      }

      createClaim({
        variables: {
          input,
        },
        refetchQueries: [
          {
            query: GET_ASSESSMENT,
            variables: { id: assessment.id },
          },
        ],
      })
        .then(() => {
          setMessage("Claim successfully submitted");
          recordEvent(
            AnalyticsEvent.CLAIM_OPENED,
            {
              ccg: ccg.id,
              amhp: assessment.amhp.id,
              doctor: doctor.id,
              locationType: hasPractice ? LocationType.gPPractice : type,
            },
            {
              "Minutes To Create Claim": dayjs(time).diff(dayjs(), "minute"),
            }
          );

          navigation.goBack();
        })
        .catch(e => {
          setSubmitted(false);
          buildErr(createClaimError, setLastException)(e);
        });
    } else {
      setLastException(new S12Error({ message: "Missing data, could not submit" }));
    }
  };

  const { openBottomSheet, closeBottomSheet } = useBottomSheet();

  return (
    <>
      <ContentWrap>
        <Text format={TypographyType.HeadingMedium}>Details</Text>
        <View style={{ flexDirection: "row" }}>
          <View style={styles.leftWrapper}>
            <DualDatePicker
              label="Assessment Date"
              value={assessmentDate}
              noIcon={true}
              min={LAST_YEAR}
              onValueChange={value => {
                setAssessmentDate(value.hour(assessmentTime.hours).minute(assessmentTime.minutes));
              }}
            />
          </View>
          <View style={styles.rightWrapper}>
            <DualTimePicker
              label="Assessment Time"
              value={assessmentTime}
              noIcon={true}
              onValueChange={setAssessmentTime}
            />
          </View>
        </View>
        <Switch
          headline="Do you know your patient's GP practice?"
          onState="Yes"
          offState="No"
          value={hasPractice}
          onValueChange={(val: boolean) => {
            resetForm();
            setHasPractice(val);
            // We need to allow the user to override the defaulting of values derived from the previous claim
            // setOverrideDefault(true);
          }}
        />
        {hasPractice ? (
          <>
            <Text format={TypographyType.Small} marginBottom={3}>
              Please enter the GP practice name below:
            </Text>
            <TextInput
              icon="domain"
              label="GP Practice"
              value={(gpPractice && gpPractice.name) || ""}
              render={renderProps => {
                const { style, value } = renderProps;
                return (
                  <TouchableRipple
                    onPress={() => {
                      openBottomSheet({
                        type: "generic",
                        data: {
                          component: GPSearch,
                          componentProps: {
                            initialQuery: (gpPractice && gpPractice.name) || "",
                            retrieveData: (data: GpPracticeItem) => {
                              setGpPractice(prev => {
                                // We need to ensure that once we have changed the GP practice, we do not continue
                                // to push the value from the previous claim into the field.Otherwise we would never
                                // be able to change the value.
                                setOverrideDefault(true);
                                return data;
                              });
                              setCcg(data.ccg || null);
                              setNewCCGSelected(true);
                              setNhsNumber(assessment.patientNhsNumber || "");
                              setNhsNumberNotFound(false);
                              setAssessmentTookPlace(null);
                              setCcgNotAvailable(
                                !data.ccg || !data.ccg.featureFlags || !hasClaims(data.ccg.featureFlags)
                              );

                              closeBottomSheet();
                            },
                          },
                        },
                      });
                    }}
                  >
                    <Text format={TypographyType.Regular} style={style}>
                      {value}
                    </Text>
                  </TouchableRipple>
                );
              }}
            />
          </>
        ) : (
          <>
            <Switch
              headline="Do you know the patient’s usual place of residence?"
              onState="Yes"
              offState="No"
              value={hasResidence}
              onValueChange={(val: boolean) => {
                resetForm();
                setHasResidence(val);
              }}
            />
            <Text format={TypographyType.Small}>{`Please enter the ${headline} below`}</Text>
            <TextInput
              icon={"location-on"}
              label={label}
              value={formatAddress(locationValue)}
              render={renderProps => {
                const { style, value } = renderProps;
                return (
                  <TouchableRipple
                    onPress={() => {
                      // We need to allow the user to override the defaulting of values derived from the previous claim
                      // setOverrideDefault(true);
                      openBottomSheet({
                        type: "locationSearch",
                        data: {
                          initialQuery: locationValue ? formatAddress(locationValue) : "",
                          // Optional parameter withConfirm controls whether the location search displays the CCG and awaits a confirmation
                          withConfirm: true,
                          retrieveData: (data: RetrieveDataObject) => {
                            setLocationValue(prev => {
                              // We need to ensure that once we have changed the location, we do not continue
                              // to push the value from the previous claim into the field. Otherwise we would never
                              // be able to change the value.
                              setOverrideDefault(true);
                              return data.locationName;
                            });
                            setCcg(data.ccg || null);
                            setNewCCGSelected(true);
                            setCcgNotAvailable(
                              !data.ccg || !data.ccg.featureFlags || !hasClaims(data.ccg.featureFlags)
                            );
                            setNhsNumber(assessment.patientNhsNumber || "");
                            setNhsNumberNotFound(false);
                            setAssessmentTookPlace(null);

                            closeBottomSheet();
                          },
                        },
                      });
                    }}
                  >
                    <Text format={TypographyType.Regular} style={style}>
                      {value}
                    </Text>
                  </TouchableRipple>
                );
              }}
            />
          </>
        )}

        {(newCCGSelected ||
          (gpPractice && gpPractice.name) ||
          (!hasPractice && (!hasResidence || patientAddress !== INITIAL_LOCATION))) &&
          ccg &&
          ccgRequiresNhsNumber(ccg.featureFlags) && (
            <>
              <TextInput
                icon="local-hospital"
                keyboardType="number-pad"
                label="Patient's NHS number"
                value={formatNhsNumber(nhsNumber)}
                maxLength={12}
                onChangeText={onNhsNumberChange}
                disabled={nhsNumberNotFound}
              />
              <View style={styles.checkboxWrapper}>
                <Checkbox
                  status={nhsNumberNotFound}
                  onPress={() => {
                    setNhsNumber(nhsNumberNotFound ? assessment.patientNhsNumber || "" : "");
                    setNhsNumberNotFound(!nhsNumberNotFound);
                  }}
                />
                <Text format={TypographyType.Tiny} style={styles.checkboxLabel} color={palette.navy}>
                  NHS Number not found
                </Text>
              </View>
            </>
          )}

        {(newCCGSelected ||
          (gpPractice && gpPractice.name) ||
          (!hasPractice && (!hasResidence || patientAddress !== INITIAL_LOCATION))) &&
          ccg &&
          shouldConfirmAssessmentTookPlace(ccg.featureFlags) && (
            <View style={styles.assessmentTookPlaceWrapper}>
              <Text format={TypographyType.Small} style={{ marginBottom: spacing[15] }}>
                Did the assessment take place?
              </Text>
              <View style={styles.multipleCheckboxes}>
                <Checkbox
                  status={assessmentTookPlace === true}
                  onPress={() => setAssessmentTookPlace(assessmentTookPlace ? null : true)}
                  label="Yes"
                />
                <HorizontalSpacer width={spacing[20]} />
                <Checkbox
                  status={assessmentTookPlace === false}
                  onPress={() => setAssessmentTookPlace(assessmentTookPlace === false ? null : false)}
                  label="No"
                />
              </View>
            </View>
          )}

        {!ccgNotAvailable || (!formValid && !submitted) ? (
          <>
            {ccg &&
              ccg.name &&
              ((!hasPractice && !hasResidence) || patientAddress !== INITIAL_LOCATION || gpPractice !== null) && (
                <Text format={TypographyType.RegularBold} style={styles.disclaimer}>
                  {ccg.name}
                </Text>
              )}

            <Text format={TypographyType.Micro} style={styles.disclaimer} marginTop={spacing[20]}>
              By clicking Create Claim, you’re confirming that the information entered above is correct to the best of
              your knowledge.
            </Text>
            <Button
              onPress={submitClaim}
              disabled={!formValid || submitted}
              style={[styles.button, styles.buttonTop, styles.sectionBottom]}
            >
              Create Claim
            </Button>
          </>
        ) : (
          <>
            {ccg && ccg.name && (
              <Text format={TypographyType.RegularBold} style={styles.disclaimer}>
                {ccg.name}
              </Text>
            )}

            <Text format={TypographyType.Micro} style={styles.disclaimer} marginTop={spacing[10]}>
              {`The CCG${
                ccg && ccg.name ? " (" + ccg.name + ")" : ""
              } associated with this patient’s GP practice is not currently available on this platform. Please revert to the CCG’s paper claim form process.`}
            </Text>
            <Button
              onPress={() => navigation.navigate("ClaimProcessScreen")}
              style={[styles.button, styles.buttonTop]}
              mode="outlined"
            >
              Learn more...
            </Button>
            <Button onPress={navigation.goBack} style={[styles.button, styles.sectionBottom]}>
              Back to assessment
            </Button>
          </>
        )}
      </ContentWrap>
      {!ccgNotAvailable && (
        <>
          <Divider />
          <ContentWrap style={styles.sectionTop}>
            <Text format={TypographyType.HeadingMedium}>What happens next?</Text>
            <Text format={TypographyType.Small}>
              This claim form will be sent to the assigned doctor, who'll add travel information to support their
              mileage claim, as appropriate.
            </Text>
            <Text format={TypographyType.Small} style={styles.lineBreak}>
              Once complete, the doctor will submit the claim form to the accountable organisation for review.
            </Text>
          </ContentWrap>
        </>
      )}
    </>
  );
};

export default CreateClaim;

const styles = StyleSheet.create({
  disclaimer: {
    textAlign: "center",
  },
  button: {
    width: "100%",
  },
  buttonTop: {
    marginTop: spacing[30],
  },
  sectionTop: {
    marginTop: spacing[50],
  },
  sectionBottom: {
    marginBottom: spacing[50],
  },
  lineBreak: {
    paddingTop: spacing[30],
  },
  leftWrapper: {
    flex: 1,
    overflow: "hidden",
    marginRight: spacing[10],
  },
  rightWrapper: {
    flex: 1,
    overflow: "hidden",
    marginLeft: spacing[10],
  },
  checkboxWrapper: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: spacing[40],
    paddingLeft: spacing[40],
  },
  checkboxLabel: {
    flex: 1,
  },
  assessmentTookPlaceWrapper: {
    alignItems: "flex-start",
    marginBottom: spacing[30],
  },
  multipleCheckboxes: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  checkboxSpacing: { marginRight: spacing[30], flexGrow: 0 },
});
