import { useEffect, useState } from "react";

export type PreviousRoute = {
  params: Record<string, unknown> | null;
  path: string | null;
} | null;

type SetPreviousRoute = (previousRoute: PreviousRoute) => void;

/**
 * This will fetch the previous route from the URL. It implements a hacky
 * process that will redirect the user to the intended endpoint when they
 * have logged in. When upgrading to React Router 5, re-implement this in
 * a nicer way if possible.
 */
export default function usePreviousRouteFromUrl(navigation: any): [PreviousRoute, SetPreviousRoute] {
  const [previousRoute, setPreviousRoute] = useState<PreviousRoute>(null);
  const { router, state } = navigation;
  const path = previousRoute?.path;

  useEffect(() => {
    try {
      const previousRoute = router.getPathAndParamsForState(state);

      if (previousRoute?.path.indexOf("Auth") !== 0) {
        setPreviousRoute(previousRoute);
      }
    } catch (e) {
      // router.getPathAndParamsForState can throw errors.
    }
  }, [path]);

  return [previousRoute, setPreviousRoute];
}
