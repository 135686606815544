import { useEffect } from "react";
import { useRecoilValue } from "recoil";
import { pushToken, userDetails } from "@/utils/recoil";
import Auth from "@aws-amplify/auth";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { UPDATE_AMHP_PROFILE } from "@/models/UserProfile";
import { UPDATE_DOCTOR_PROFILE } from "@/models/DoctorProfile";
import { Platform } from "react-native";

const GET_AMHP_USER_TOKENS = gql`
  query GetAmhpProfile($id: ID!) {
    userInfo: getAmhpProfile(id: $id) {
      id
      pushTokens
    }
  }
`;

const GET_DOCTOR_USER_TOKENS = gql`
  query GetS12Doctor($id: ID!) {
    userInfo: getS12Doctor(id: $id) {
      id
      pushTokens
    }
  }
`;

const querysByUserType = {
  doctor: GET_DOCTOR_USER_TOKENS,
  amhp: GET_AMHP_USER_TOKENS,
};

const mutationsByUserType = {
  doctor: UPDATE_DOCTOR_PROFILE,
  amhp: UPDATE_AMHP_PROFILE,
};

export default function useSignout() {
  const user = useRecoilValue(userDetails);
  const token = useRecoilValue(pushToken);

  const userType = user?.groups.includes("Doctor") ? "doctor" : "amhp";

  const [loadingUserData, { error, data }] = useLazyQuery<{
    userInfo: { id: string; pushTokens: string[] };
  }>(querysByUserType[userType], {
    variables: { id: user?.id || "" },
    fetchPolicy: "cache-and-network",
  });
  const [updateUserTokens] = useMutation(mutationsByUserType[userType]);

  useEffect(() => {
    if (data?.userInfo?.pushTokens?.length) {
      const pushTokens = data.userInfo.pushTokens.filter(tk => tk !== token);
      updateUserTokens({
        variables: {
          input: {
            id: user?.id,
            pushTokens,
          },
        },
      });
      Auth.signOut();
    }
  }, [data, token]);

  useEffect(() => {
    if (error) Auth.signOut();
  }, [error]);

  const signOutUserAndRemovePushTokenWhenOnMobile = () => {
    if (Platform.OS !== "web") {
      loadingUserData();
    } else {
      Auth.signOut();
    }
  };

  return signOutUserAndRemovePushTokenWhenOnMobile;
}
