import {
  hasMileage,
  ccgRequiresDoctorToInvoice,
  shouldProvideVehicleInformation,
  ccgRequiresBillingInformation,
  ccgDoesNotAcceptHospitalAssessments,
  ccgRequiresDoctorAdditionalConfirmation,
  doctorFeesMileageWithoutInvoice,
  mhtAndLineManager,
  ccgRequiresDoctorEmployedStatus,
} from "libs/utils/featureFlags";
import {
  ClaimDataInput,
  FuelType,
  Engine,
  InvoiceDataInput,
  DoctorConfirmation,
  DoctorEmployedStatusValues,
} from "libs/types/API";

interface FuelOption {
  value: FuelType;
  label: string;
}

interface EngineSize {
  value: Engine;
  label: string;
}

export interface shouldDisableSubmitParams {
  featureFlags: string;
  mileageDisclaimer: boolean;
  doctorAdditionalConf: DoctorConfirmation | null;
  distanceTravelled: string;
  fuelType: FuelOption | null;
  engineSize: EngineSize | null | undefined;
  claimData: ClaimDataInput;
  invoiceData: InvoiceDataInput;
  assessmentWasNotAtHospital: boolean;
  patientNotInpatient: boolean;
  showLineManager: boolean;
  showLineManagerAndMht: boolean;
  lineManager: string;
  defaultMHT: { id: string; abbreviation: string };
  submitted: boolean;
  isIndependentDoctor: boolean;
  employedBySWYorkshire: boolean | null;
  doctorEmployedStatus: DoctorEmployedStatusValues | null;
}

// Verify that all invoice informnation is supplied
export const checkInvoiceDetails = (
  distanceTravelled: string,
  invoiceData: InvoiceDataInput,
  claimData: ClaimDataInput,
  shouldDoctorSendInvoiceToCCG: boolean
): boolean => {
  if (!shouldDoctorSendInvoiceToCCG) return false;

  const noDistance = Number(distanceTravelled) === 0 && Number(invoiceData.travelFee) > 0;
  const isMissingLocation = invoiceData?.locationOfAssessment?.trim() === "";
  const isMissingPostcode = !claimData?.billingAddress?.postcode;
  const noAssessmentFee = Number(invoiceData.assessmentFee) <= 0;

  // DEBUG
  // console.log("checkInvoiceDetails", {
  //   noDistance,
  //   isMissingLocation,
  //   isMissingPostcode,
  //   noAssessmentFee,
  // });

  return noDistance || isMissingLocation || isMissingPostcode || noAssessmentFee;
};

export const checkFees = (invoiceData: InvoiceDataInput, showFeesMileageWithoutInvoice: boolean): boolean => {
  if (!showFeesMileageWithoutInvoice) return false;
  const noAssessmentFee = Number(invoiceData.assessmentFee) <= 0;
  return noAssessmentFee;
};

// Determine whether enough mileage info has been supplied to submit claim
export const checkVehicleInfo = (
  distanceTravelled: string,
  fuelType: FuelOption | null,
  engineSize: Engine | null | undefined,
  vehicleInformationRequested: boolean
): boolean => {
  if (!vehicleInformationRequested) {
    return false;
  }
  return !(
    Number(distanceTravelled) === 0 ||
    (fuelType && (engineSize || ["lpg", "electric", "bicycle"].includes(fuelType?.value)))
  );
};

const shouldDisableSubmit = (params: shouldDisableSubmitParams) => {
  const {
    featureFlags,
    mileageDisclaimer,
    doctorAdditionalConf,
    distanceTravelled,
    fuelType,
    engineSize,
    claimData,
    invoiceData,
    assessmentWasNotAtHospital,
    patientNotInpatient,
    showLineManager,
    showLineManagerAndMht,
    lineManager,
    defaultMHT,
    submitted,
    isIndependentDoctor,
    employedBySWYorkshire,
    doctorEmployedStatus,
  } = params;
  // Establish flags
  const mileageAllowed = hasMileage(featureFlags);
  const shouldDoctorSendInvoiceToCCG = ccgRequiresDoctorToInvoice(featureFlags);
  const showFeesMileageWithoutInvoice = doctorFeesMileageWithoutInvoice(featureFlags);
  const mustProvideBillingInformation = ccgRequiresBillingInformation(featureFlags);
  const vehicleInformationRequested = shouldProvideVehicleInformation(featureFlags);
  const shouldConfirmAssessmentWasNotAtHospital = ccgDoesNotAcceptHospitalAssessments(featureFlags);
  const requiresMhtAndLineManager = mhtAndLineManager(featureFlags);
  // Determine whether to display submit button
  const noDisclaimer = mileageAllowed && !mileageDisclaimer;
  const noDoctorAdditionalConf =
    mileageDisclaimer && ccgRequiresDoctorAdditionalConfirmation(featureFlags) && !doctorAdditionalConf;
  const noDoctorEmployedStatus =
    ccgRequiresDoctorEmployedStatus(featureFlags) && employedBySWYorkshire === true && doctorEmployedStatus === null;
  const isMissingVehicleInfo = checkVehicleInfo(
    distanceTravelled,
    fuelType,
    engineSize?.value,
    vehicleInformationRequested
  );
  const isMissingInvoiceInfo = checkInvoiceDetails(
    distanceTravelled,
    invoiceData,
    claimData,
    shouldDoctorSendInvoiceToCCG
  );

  let isMissingMhtOrLineManager = requiresMhtAndLineManager && (!lineManager || !defaultMHT.id);
  // If  doctor is flagged as an independent doctor  we do not prevent submit
  if (isIndependentDoctor) {
    isMissingMhtOrLineManager = false;
  }
  const isMissingFees = checkFees(invoiceData, showFeesMileageWithoutInvoice);

  const isMissingBillingInfo =
    mustProvideBillingInformation && (!claimData?.billingCompanyName || !claimData?.billingAddress?.postcode);
  const isAtHospital = shouldConfirmAssessmentWasNotAtHospital && (!assessmentWasNotAtHospital || !patientNotInpatient);
  let isMissingLineManager = showLineManager && !lineManager;
  // Line manager is not required if the doctor is an independent doctor
  if (isIndependentDoctor) {
    isMissingLineManager = false;
  }

  // DEBUG
  // console.log("shouldDisableSubmit", {
  //   noDisclaimer,
  //   isMissingVehicleInfo,
  //   isMissingInvoiceInfo,
  //   isMissingBillingInfo,
  //   isAtHospital,
  //   isMissingLineManager,
  //   isMissingMhtOrLineManager,
  //   noDoctorEmployedStatus
  // });

  return (
    noDisclaimer ||
    noDoctorAdditionalConf ||
    isMissingVehicleInfo ||
    isMissingInvoiceInfo ||
    isMissingFees ||
    isMissingBillingInfo ||
    isAtHospital ||
    isMissingLineManager ||
    isMissingMhtOrLineManager ||
    noDoctorEmployedStatus ||
    submitted
  );
};

export default shouldDisableSubmit;
