import React from "react";
import { ApolloClient, NetworkStatus } from "apollo-boost";
import { GraphQLError } from "graphql";
import { useApolloClient } from "@apollo/react-hooks";

import { getAddressError, S12Error, networkError, getError } from "@/models/Error";

import { GP_ORG_SEACH, GET_GP_PRACTICE_FROM_ORG } from "@/models/GpPractice";
import { GpOrgSearchItem, GpPracticeItem } from "./GPSearch.props";
import { GPSearch } from "./GPSearch";

function getSearchResultsWithClient(client: ApolloClient<any>) {
  return (term: string, postcode: string, searchFor: string) => {
    return client
      .query<{ results: Array<GpOrgSearchItem> }>({
        query: GP_ORG_SEACH,
        variables: { term, postcode, searchFor },
      })
      .then(
        (res: {
          data: { results: Array<GpOrgSearchItem> };
          errors?: GraphQLError[];
          loading: boolean;
          networkStatus: NetworkStatus;
          stale: boolean;
        }) => {
          if (res.data && res.data.results) {
            return res.data.results;
          } else if (res.errors) {
            throw new S12Error(getAddressError);
          } else if (res.networkStatus === NetworkStatus.error) {
            throw new S12Error(networkError);
          } else {
            throw new S12Error(getError);
          }
        }
      );
  };
}

function getResultDataWithClient(client: ApolloClient<any>) {
  return (orgId: string, roleId: string) => {
    return client
      .query<{ result: GpPracticeItem }>({
        query: GET_GP_PRACTICE_FROM_ORG,
        variables: { orgId, roleId },
      })
      .then(
        (res: {
          data: { result: GpPracticeItem };
          errors?: GraphQLError[];
          loading: boolean;
          networkStatus: NetworkStatus;
          stale: boolean;
        }) => {
          if (res.data && res.data.result) {
            return res.data.result;
          } else if (res.errors) {
            throw new S12Error(getAddressError);
          } else if (res.networkStatus === NetworkStatus.error) {
            throw new S12Error(networkError);
          } else {
            throw new S12Error(getError);
          }
        }
      );
  };
}

interface GPSearchGqlProps {
  initialQuery?: string;
  retrieveData(data: unknown): void;
}
export default function GPSearchGql(props: GPSearchGqlProps) {
  const client: ApolloClient<any> = useApolloClient();
  return (
    <GPSearch
      initialQuery={props.initialQuery}
      getGPData={getResultDataWithClient(client)}
      getResults={getSearchResultsWithClient(client)}
      retrieveData={props.retrieveData}
    />
  );
}
