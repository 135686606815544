import React, { useMemo } from "react";
import { View } from "react-native";

import css from "./SplitSection.css";
import { mqWeb } from "../../utils/helpers";

import ContentWrap from "../ContentWrap/ContentWrap";
import SectionDivider from "../SectionDivider/SectionDivider";

interface SplitSectionProps {
  aside: React.ReactNode;
  main: React.ReactNode;
  renderBottomDivider?: boolean;
  stickyAside?: boolean;
}

const SplitSection: React.FC<SplitSectionProps> = ({
  aside,
  main,
  renderBottomDivider = false,
  stickyAside = false,
}) => {
  const isWebView = mqWeb();
  const styles = {
    aside: useMemo(() => [isWebView ? css.asideWeb : css.aside], [isWebView]),
    main: useMemo(() => [isWebView ? css.mainWeb : css.main], [isWebView]),
    wrap: useMemo(() => [isWebView ? css.wrapWeb : css.wrap], [isWebView]),
  };
  if (stickyAside && isWebView) {
    // Allows us to use 'sticky'
    // @ts-ignore
    styles.aside.push({ position: "sticky", top: 0 });
  }

  const Wrapper = useMemo(() => (isWebView ? ContentWrap : React.Fragment), [isWebView]);

  const Content = useMemo(() => (isWebView ? React.Fragment : ContentWrap), [isWebView]);

  return (
    <Wrapper>
      <View style={styles.wrap}>
        <View style={styles.aside}>
          <Content>{aside}</Content>
        </View>
        <View style={styles.main}>{main}</View>
      </View>
      {renderBottomDivider && <SectionDivider />}
    </Wrapper>
  );
};

export default SplitSection;
