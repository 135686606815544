import { useEffect } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { HubCallback } from "@aws-amplify/core/lib/Hub";
import Auth from "@aws-amplify/auth";
import { Hub } from "@aws-amplify/core";
import { ApolloClient } from "apollo-boost";

import { userDetails } from "@/utils/recoil";
import { previousPathnameState } from "@/navigationv2/state";
import { updateUserDetails } from "../utils";
import { S12Error } from "@/models/Error";
import { useS12LastException } from "@/hooks";
import { remove } from "@/utils/storage";

/**
 * Respond to AWS Amplify Hub events
 *
 * @param {ApolloClient<unknown>} apolloClient
 */
export default function useOnAuthStateChange(apolloClient: ApolloClient<unknown>) {
  const [user, setUserDetails] = useRecoilState(userDetails);
  const [, setS12LastException] = useS12LastException();
  const setPreviousPathname = useSetRecoilState(previousPathnameState);

  useEffect(
    function setUser() {
      const authListener: HubCallback = response => {
        const { payload } = response;

        switch (payload.event) {
          case "signIn":
            Auth.currentAuthenticatedUser({ bypassCache: false }).then(data => {
              updateUserDetails(data, setUserDetails);
            });
            break;

          case "signIn_failure":
          case "signOut":
            apolloClient.clearStore();
            apolloClient.cache.reset();
            setUserDetails(null);
            setPreviousPathname("");
            remove("amhpTeam");
            remove("isDemoEnvironment");
            break;

          case "customState_failure": {
            // Handle custom auth failures, probably only SSO errors
            const errorMsg = payload.data?.message;
            if (errorMsg) {
              // Error messages get returned like this PreSignUp+failed+with+error+Self+Sign+Up+Not+Allowed.+ so we want to regex those dodgy characters out
              setS12LastException(new S12Error({ message: errorMsg.replace(/\+/g, " ") }));
            }
            break;
          }

          default:
        }
      };

      Hub.listen("auth", authListener);

      return () => {
        Hub.remove("auth", authListener);
      };
    },
    [user]
  );
}
