import React from "react";
import { createStackNavigator } from "@react-navigation/stack";

import { AMHPDashboardRoutes, AMHPTabRoutes, TabNavigationProps } from "@/navigationv2/types";
import screenOptions from "@/navigationv2/config/screenOptions";
import Screens from "@/screens";
import TabIcon from "../TabIcon";

const DashboardStack = createStackNavigator<AMHPDashboardRoutes>();

interface PropTypes extends TabNavigationProps<AMHPTabRoutes, "AMHPDashboardTab"> {}

const AMHPDashboardStack = () => {
  return (
    <DashboardStack.Navigator initialRouteName="UserDashboardScreen" screenOptions={screenOptions}>
      <DashboardStack.Screen
        name="UserDashboardScreen"
        component={Screens.UserDashboardScreen}
        options={Screens.UserDashboardScreen.screenOptions}
      />
      <DashboardStack.Screen
        name="TeamInboxFormViewerScreen"
        component={Screens.TeamInboxFormViewerScreen}
        options={Screens.TeamInboxFormViewerScreen.screenOptions}
      />
    </DashboardStack.Navigator>
  );
};

AMHPDashboardStack.tabOptions = {
  tabBarIcon: TabIcon("person-outline"),
  tabBarLabel: "Dashboard",
};

export default AMHPDashboardStack;
