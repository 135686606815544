import React, { useMemo, useEffect, useRef } from "react";
import { StyleSheet, View, Animated } from "react-native";

import Icon, { IconName } from "../../Icon";
import { palette } from "../../../theme";
import { TouchableRipple } from "react-native-paper";

export interface AudioInputToggleProps {
  icon: IconName.mic | IconName.mic_off | IconName.mic_none | IconName.settings;
  active: boolean; // initial state; i.e. is the user's mic enabled or disabled by default
  onPress: () => void;
  disabled?: boolean; // disable the button; should be true when user restricts access to input device
  size?: number;
  audioLevel?: number | undefined;
  previewMode?: boolean;
}

export const AudioInputToggle: React.FC<AudioInputToggleProps> = props => {
  const volumeLevel = useRef(new Animated.Value(props.audioLevel || 0)).current;

  const backgroundStyles = useMemo(
    () => [
      styles.bg,
      props.active
        ? props.previewMode
          ? styles.bgActivePreview
          : styles.bgActive
        : props.icon === IconName.settings
        ? styles.bgActive
        : styles.bgInactive,
    ],
    [props.active]
  );

  // let previousAudioLevel = usePrevious(props.audioLevel, props.audioLevel);

  useEffect(() => {
    // if (props.audioLevel !== previousAudioLevel) {
    Animated.spring(volumeLevel, {
      toValue: props.audioLevel || 0,
      speed: 8,
      useNativeDriver: true,
    }).start();
    // }
  }, [props.audioLevel]);

  const scaleY = volumeLevel;

  const translateY = volumeLevel.interpolate({
    inputRange: [0, 1],
    outputRange: [-22, 0],
    extrapolate: "clamp",
  });
  const rotateX = "180deg";

  const containerStyle = useMemo(
    () => [
      styles.button,
      props.size
        ? {
            width: props.size,
            height: props.size,
            borderRadius: props.size / 4,
          }
        : {},
    ],
    [props.size]
  );

  return (
    <TouchableRipple disabled={props.disabled} onPress={props.onPress}>
      <View style={containerStyle}>
        <Icon color={palette.white} name={props.icon || IconName.settings} size={props.size ? props.size - 4 : 24} />

        <View style={backgroundStyles} />
        {props.active && (
          <Animated.View
            style={[
              styles.bgLevel,
              {
                transform: [{ rotateX }, { translateY }, { scaleY }, { perspective: 1000 }],
              },
            ]}
          />
        )}
      </View>
    </TouchableRipple>
  );
};

const styles = StyleSheet.create({
  bg: {
    position: "absolute",
    width: "100%",
    height: "100%",
    left: 0,
    top: 0,
    zIndex: -2,
  },
  bgLevel: {
    backgroundColor: palette.aqua,
    opacity: 1,
    position: "absolute",
    width: "100%",
    height: "100%",
    bottom: 0,
    top: 0,
    zIndex: -1,
  },
  bgActive: { backgroundColor: palette.shadow, opacity: 0.35 },
  bgInactive: { backgroundColor: palette.red, opacity: 1 },
  bgActivePreview: { backgroundColor: palette.shadow, opacity: 0.55 },

  button: {
    position: "relative",
    width: 44,
    height: 44,
    marginLeft: 10,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 12,
    overflow: "hidden",
  },
});

/*
const usePrevious = (value: any, initialValue: any) => {
  const ref = useRef(initialValue);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const useAnimationFrame = (callback: (time: number) => void) => {
  const requestRef = useRef<number | null | undefined>();
  const previousTimeRef = useRef<number | null | undefined>(null);

  const animate = (time: number) => {
    if (previousTimeRef.current != undefined) {
      const deltaTime = time - (previousTimeRef.current || 0);
      callback(deltaTime);
    }
    previousTimeRef.current = time;
    requestRef.current = requestAnimationFrame(animate);
  };

  useEffect(() => {
    requestRef.current = requestAnimationFrame(animate);
    return () =>
      requestRef.current ? cancelAnimationFrame(requestRef.current) : void 0;
  }, []); // Make sure the effect runs only once
};

const useAnimatedValue = (initialValue: number): Animated.Value => {
  const ref = useRef(new Animated.Value(initialValue));
  return ref.current;
};

interface AnimationConfig {
  type: "timing" | "spring";
  toValue: number;
  initialValue: number;
}

const useAnimation = (config: AnimationConfig): Animated.Value => {
  const animatedValue = useAnimatedValue(getInitialValue(config));

  const animate = () => {
    if (config.type === "timing") {
      Animated.timing(animatedValue, config).start();
    } else if (config.type === "spring") {
      Animated.spring(animatedValue, config).start();
    } else {
      // @ts-ignore
      throw new Error("unsupported animation type=" + config.type);
    }
  };
  useEffect(animate, [config.toValue]);

  return animatedValue;
};

const getInitialValue = (config: AnimationConfig) => {
  if (typeof config.initialValue !== "undefined") return config.initialValue;
  else {
    return config.toValue;
  }
};
*/
