import React, { useCallback } from "react";
import { StyleSheet, TouchableOpacity, View, Platform } from "react-native";
import { CommonActions } from "@react-navigation/native";
import { Hoverable } from "react-native-web-hooks";

import Text from "@/components/Text";
import { palette, spacing } from "@/theme";
import { TypographyType } from "@/models/Typography";
import { BottomTabNavigationOptions, BottomTabBarProps } from "@react-navigation/bottom-tabs";

interface PropTypes extends BottomTabBarProps {
  index: number;
  route: any;
  options?: BottomTabNavigationOptions;
}

const emptyObject = {};

const NavigationItem = (props: PropTypes) => {
  const isFocused = props.state.index === props.index;
  const descriptor = props.descriptors[props.route.key];
  const options = (descriptor && descriptor.options) || emptyObject;

  /**
   * Handle tab press. This is a fairly complex process on the web, due to
   * the fact that if a user comes direct to the screen from a URL there
   * is no previous history to navigate to, so React Navigation can't
   * handle it.
   *
   * Instead we have to have a workaround that clears the entire children
   * state routes and resets it back to as if we had just mounted the
   * navigation.
   */
  const onTabPress = useCallback(() => {
    const customEvent = props.navigation.emit({
      type: "tabPress",
      target: props.route.key,
      canPreventDefault: true,
    });

    if (!customEvent.defaultPrevented) {
      if (isFocused || Platform.OS === "web") {
        props.navigation.dispatch(navState => {
          const routes = navState.routes.map(item => {
            return isFocused || Platform.OS === "web"
              ? {
                  name: item.name,
                  params: undefined,
                  key: isFocused ? undefined : item.key,
                }
              : item;
          });

          return CommonActions.reset({
            ...props.navigation.dangerouslyGetState(),
            index: props.index,
            routes,
          });
        });
      } else {
        props.navigation.dispatch({
          ...CommonActions.navigate(props.route.name),
          target: props.state.key,
        });
      }
    }
  }, [props.navigation, props.index, props.route.key, props.route.name, isFocused]);

  return (
    <TouchableOpacity onPress={onTabPress} style={styles.touchableOpacity}>
      <Hoverable>
        {isHovered => {
          return (
            <View style={[styles.tab, isFocused && styles.tabFocused, isHovered && styles.tabHovered]}>
              {Boolean(options?.tabBarBadge) && <View style={styles.badge} />}

              {options?.tabBarIcon?.({
                color: isFocused ? palette.blue : palette.greyBlue,
                size: 22,
                focused: isFocused,
              })}

              <Text format={TypographyType.MicroBold} style={[styles.label, isFocused && styles.labelFocused]}>
                {options?.tabBarLabel}
              </Text>
            </View>
          );
        }}
      </Hoverable>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  touchableOpacity: { flex: 1, height: "100%" },

  tab: {
    alignItems: "center",
    borderRadius: 0,
    flex: 1,
    justifyContent: "center",
    position: "relative",
    ...Platform.select({
      web: {
        cursor: "pointer",
      },
    }),
  },

  tabFocused: {
    backgroundColor: palette.cloud,
  },

  tabHovered: {
    backgroundColor: palette.cloud,
  },

  badge: {
    position: "absolute",
    width: 12.5,
    height: 12.5,
    backgroundColor: palette.red,
    borderTopLeftRadius: 12.5,
    borderTopRightRadius: 12.5,
    borderBottomRightRadius: 12.5,
    borderBottomLeftRadius: 12.5,
    transform: [{ translateY: -25 }, { translateX: 10 }],
  },

  label: {
    paddingHorizontal: spacing[25],
    paddingTop: spacing[5],
    color: palette.greyBlue,
    textAlign: "center",
    textTransform: "uppercase",
  },

  labelFocused: {
    color: palette.blue,
  },
});

export default NavigationItem;
