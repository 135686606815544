import { StyleSheet, Platform } from "react-native";
import { spacing, palette } from "../../theme";

const css = StyleSheet.create({
  buttons: {
    paddingLeft: 6,
    flexDirection: "row",
    borderTopWidth: 1,
    borderTopColor: palette.grey,
    ...Platform.select({
      web: {
        cursor: "pointer",
      },
    }),
  },

  card: {
    position: "relative",
    overflow: "hidden",
    marginBottom: spacing[10],
    backgroundColor: palette.white,
    shadowColor: palette.blueShadow,
    borderRadius: 6,
    shadowRadius: 6,
    shadowOpacity: 0.3,
    shadowOffset: { width: 0, height: 0 },
    elevation: 2,
  },

  content: {
    position: "relative",
    paddingLeft: 30,
    paddingRight: 24,
    paddingVertical: 25,
  },
  contentSmall: {
    paddingLeft: 20,
    paddingRight: 14,
  },
  contentSlim: {
    paddingVertical: 20,
  },

  expandableSpacer: {
    height: spacing[20],
  },

  expandToggleArrowButton: {
    position: "absolute",
    right: 0,
    top: 0,
    width: 44,
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    borderLeftWidth: 2,
    borderLeftColor: palette.cloud,
    ...Platform.select({
      web: {
        cursor: "pointer",
      },
    }),
  },

  statusBar: {
    position: "absolute",
    left: 0,
    top: 0,
    width: 6,
    height: "100%",
    zIndex: 1,
  },
  statusBarSmall: {
    width: 5,
  },
});

export default css;
