import { Platform } from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import messaging, { FirebaseMessagingTypes } from "@react-native-firebase/messaging";
import PushNotification, { PushNotification as PushNotificationAndroidInterface } from "react-native-push-notification";
import PushNotificationIOS, {
  PushNotification as PushNotificationIOSInterface,
} from "@react-native-community/push-notification-ios";
import { navigate } from "@/navigationv2/rootNavigation";

interface RemoteMessage {
  title: string;
  body: string;
  stack?: string;
  screen?: string;
  params?: string;
  recipient: string;
}

/**
 * helper function to navigate user to appropriate screen upon
 * clicking on a notification
 */
export function navigateFromNotificationToScreen(info: RemoteMessage) {
  if (!info || !info.stack) return;
  const { stack, screen, params } = info;
  const navParams = params ? JSON.parse(params) : {};

  return navigate && navigate(stack, { screen, params: navParams });
}

/**
 * helper function to send out local notification upon verifying that
 * logged in user is recipient of message. will create and pass along an
 * userInfo object which includes screen, params and a notification message
 * to be displayed on screen (where applicable)
 */
export async function sendLocalNotification(message?: { [key: string]: string }) {
  // check if logged in user is recipient of message, if not return, otherwise send a local notification
  const userId = await AsyncStorage.getItem("userId");
  if (!message || !userId || message.recipient !== userId) return;

  const userInfo = {
    stack: message.stack,
    screen: message.screen,
    params: message?.params,
  };

  return Platform.OS === "ios"
    ? PushNotificationIOS.presentLocalNotification({
        alertTitle: message.title,
        alertBody: message.body,
        userInfo,
      })
    : PushNotification.localNotification({
        channelId: "s12_app",
        title: message.title,
        message: message.body,
        userInfo,
      });
}

/**
 * ios only -> helper function to deal with incoming local push notifications,
 * to be attached to eventListener
 */
export function handleIncomingLocalNotificationIOS(notification: PushNotificationIOSInterface) {
  const data = notification.getData() as RemoteMessage;
  return navigateFromNotificationToScreen(data);
}

/**
 * helper function to deal with incoming local & remote push notifications,
 * when app in foreground / active
 */
export const foregroundNotificationsListener = () => {
  if (Platform.OS === "web") return;
  messaging().onMessage(async (remoteMessage: FirebaseMessagingTypes.RemoteMessage) =>
    sendLocalNotification(remoteMessage?.data)
  );
};

/**
 * helper function to request userPermissions to allow push notifications
 */
async function requestUserPermission() {
  const authStatus = await messaging().requestPermission();
  try {
    const token = await messaging().getToken();
    console.log("device token", token, authStatus);
    AsyncStorage.setItem("pushToken", token);
  } catch (e) {
    console.error("token registration failed?", e);
  }
}

/**
 * helper function to handle incoming local notifications using react-native-push-notifications
 */
export function handleLocalNotificationAndroid() {
  return PushNotification.configure({
    popInitialNotification: true,
    // called when a remote is received or opened, or local notification is opened
    onNotification: (
      notification: PushNotificationAndroidInterface & {
        data: RemoteMessage;
      }
    ) => {
      if (notification.userInteraction) {
        return navigateFromNotificationToScreen(notification.data);
      }

      return null;
    },
  });
}

/**
 * main fuction to configure / set up push notifications
 */
export function configurePushNotificationToken() {
  /**
   * called when a new registration token is generated for the device, i.e. when a token
   * expires or when the server invalidates the token.
   */
  messaging().onTokenRefresh(() => {
    messaging()
      .getToken()
      .then((refreshedToken: string) => {
        try {
          AsyncStorage.setItem("pushToken", refreshedToken);
        } catch (e) {
          console.error("token registration failed?", e);
        }
      })
      .catch((err: string) => {
        console.log("Unable to retrieve refreshed token ", err);
      });
  });

  return requestUserPermission();
}
