import React, { useEffect, useState } from "react";
import { View } from "react-native";

import { DoctorClaimListItem } from "libs/types/claim";

import { ContentWrap, Text } from "@/components";
import { TypographyType } from "@/models/Typography";
import { palette } from "@/theme";
import { mqWeb } from "@/utils/helpers";
import { spacing } from "@/theme/spacing";

import { DoctorClaimNextTokens } from "./ClaimResults.gql";
import { ToggleButton } from "../Button/Button";
import { Button } from "@/components/Button";
import { CardContainer } from "../Card";
import { ClaimResult } from "./ClaimResult";
import { FilterLabel } from "../FilterLabel";
import { SectionDivider } from "../SectionDivider";
import { SectionHeading } from "../SectionHeading";
import { LoadMoreButton } from "../RectangleButton/RectangleButton";
import { useExportClaims } from "../ExportCSV";

const claimCards = (claims: DoctorClaimListItem[]) => {
  return claims.map((claim: DoctorClaimListItem) => (
    <React.Fragment key={claim.id}>
      <ClaimResult claim={claim} />
    </React.Fragment>
  ));
};

interface ClaimResultsPropTypes {
  loading: boolean;
  actionRequiredClaims: DoctorClaimListItem[];
  underReviewClaims: DoctorClaimListItem[];
  approvedClaims: DoctorClaimListItem[];
  rejectedClaims: DoctorClaimListItem[];
  paidClaims: DoctorClaimListItem[];
  onHoldClaims: DoctorClaimListItem[];
  setFetchMoreTypeFn: any;
  nextTokens: DoctorClaimNextTokens;
}

export const ClaimResults = (props: ClaimResultsPropTypes) => {
  const [actionRequired, setActionRequired] = useState<DoctorClaimListItem[]>([]);
  const [underReview, setUnderReview] = useState<DoctorClaimListItem[]>([]);
  const [approved, setApproved] = useState<DoctorClaimListItem[]>([]);
  const [rejected, setRejected] = useState<DoctorClaimListItem[]>([]);
  const [paid, setPaid] = useState<DoctorClaimListItem[]>([]);
  const [onHold, setOnHold] = useState<DoctorClaimListItem[]>([]);
  const displayExportClaims = useExportClaims();

  const isWebView = mqWeb();

  useEffect(() => {
    setActionRequired(props.actionRequiredClaims);
    setUnderReview(props.underReviewClaims);
    setApproved(props.approvedClaims);
    setRejected(props.rejectedClaims);
    setPaid(props.paidClaims);
    setOnHold(props.onHoldClaims);
  }, [props]);

  return (
    <>
      {isWebView && (
        <ContentWrap>
          <Button onPress={displayExportClaims} style={{ marginTop: spacing[15], alignSelf: "flex-end" }}>
            Export Claims
          </Button>
        </ContentWrap>
      )}
      <ClaimSection
        headline="Action Required"
        items={actionRequired}
        fetchMoreFn={() => props.setFetchMoreTypeFn("actionRequired")}
        nextToken={props.nextTokens.actionRequired}
      />

      <SectionDivider greyBG={isWebView} />

      <ClaimSection
        headline="Under Review"
        items={underReview}
        fetchMoreFn={() => props.setFetchMoreTypeFn("underReview")}
        nextToken={props.nextTokens.underReview}
      />

      <SectionDivider greyBG={isWebView} />

      <ClaimSection
        headline="Approved"
        items={approved}
        fetchMoreFn={() => props.setFetchMoreTypeFn("approved")}
        nextToken={props.nextTokens.approved}
      />

      <SectionDivider greyBG={isWebView} />

      <ClaimSection
        headline="Paid"
        items={paid}
        fetchMoreFn={() => props.setFetchMoreTypeFn("paid")}
        nextToken={props.nextTokens.paid}
        isReversed={true}
        hide={true}
      />

      <SectionDivider greyBG={isWebView} />

      <ClaimSection
        headline="On Hold"
        items={onHold}
        fetchMoreFn={() => props.setFetchMoreTypeFn("onHold")}
        nextToken={props.nextTokens.onHold}
        isReversed={true}
      />

      <SectionDivider greyBG={isWebView} />

      <ClaimSection
        headline="Rejected"
        items={rejected}
        fetchMoreFn={() => props.setFetchMoreTypeFn("rejected")}
        nextToken={props.nextTokens.rejected}
        isReversed={true}
      />
    </>
  );
};

interface ClaimsSectionPropTypes {
  headline: string;
  items: DoctorClaimListItem[];
  fetchMoreFn: () => void;
  nextToken: string | null;
  hide?: boolean;
  isReversed?: boolean;
}

const ClaimSection = (props: ClaimsSectionPropTypes) => {
  const [hide, setHide] = useState<boolean>(props.hide || false);

  return (
    <>
      <ContentWrap>
        <SectionHeading heading={props.headline} />
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <FilterLabel text={"Sorted by last updated, " + (props.isReversed ? "newest first" : "oldest first")} />
          {props.items.length > 0 && (
            <ToggleButton
              toggleFn={setHide}
              value={hide}
              icon={hide ? "keyboard-arrow-down" : "keyboard-arrow-up"}
              text={hide ? "show" : "hide"}
              compact={true}
              style={{ flexBasis: 100 }}
            />
          )}
        </View>
        {props.items.length === 0 && (
          <Text format={TypographyType.Small} style={{ color: palette.slate }}>
            You have no {props.headline.toLowerCase()} claims.
          </Text>
        )}
      </ContentWrap>

      {!hide && props.items.length >= 1 && <CardContainer>{claimCards(props.items)}</CardContainer>}
      {props.nextToken && !hide && (
        <ContentWrap
          style={{
            flexDirection: "row",
            justifyContent: "center",
            marginTop: spacing[10],
          }}
        >
          {LoadMoreButton(props.fetchMoreFn, !props.nextToken)}
        </ContentWrap>
      )}
    </>
  );
};

export default ClaimResults;
