import React, { useEffect, useMemo } from "react";
import { StyleSheet } from "react-native";
import { useMutation } from "@apollo/react-hooks";

import { SectionDivider } from "../SectionDivider/SectionDivider";
import { DetailsSection, SectionItem } from "../DetailsSection/DetailsSection";
import Select from "../Select";
import Mod from "./modules";

import { UserProfile as UserProfileType, UPDATE_AMHP_PROFILE, UpdateAmhpProfileFunc } from "@/models/UserProfile";
import { mqWeb, saveCurrentPushNotificationToken, savePushNotificationToken } from "@/utils/helpers";

import { useRecoilState, useSetRecoilState } from "recoil";
import { amhpTeamId as recoilAmhpTeamId, pushToken as recoilPushToken } from "@/utils/recoil/index";
import { loadString, saveString } from "@/utils/storage";
import { amhpTeamInbox } from "libs/utils/featureFlags";

export const UserDashboard = (props: { profile: UserProfileType }) => {
  const { profile } = props;
  const isWebView = mqWeb();

  const setPushToken = useSetRecoilState(recoilPushToken);
  const [amhpTeamId, setAmhpTeamId] = useRecoilState(recoilAmhpTeamId);
  const [updateAmhpProfile] = useMutation<UpdateAmhpProfileFunc>(UPDATE_AMHP_PROFILE);

  useEffect(() => {
    // We save the user's current AMHP team in local storage.
    // If the user is removed form a team, we need to reset the user
    // to the first team they are actually on.
    let hasUnloaded = false;
    function go() {
      loadString("amhpTeam").then(p => {
        if (hasUnloaded) {
          return;
        }
        if (props.profile.teams.items.findIndex(item => item?.amhpTeam?.id === p) > -1) {
          setAmhpTeamId(p);
        } else if (props.profile.teams.items.length > 0) {
          saveString("amhpTeam", props.profile.teams.items[0]?.amhpTeam?.id);
          setAmhpTeamId(props.profile.teams.items[0]?.amhpTeam?.id);
        }
      });
    }
    if (props.profile.teams.items) {
      go();
    }
    return () => {
      hasUnloaded = true;
    };
  }, [props.profile.teams.items]);

  useEffect(() => {
    // saving current pushToken to recoil to quickly pull this value when logging out and running a mutation to delete from db
    saveCurrentPushNotificationToken(setPushToken);
  }, []);

  useEffect(() => {
    if (profile.id)
      savePushNotificationToken(profile.pushTokens, (pushTokens: string[]) =>
        updateAmhpProfile({
          variables: {
            input: {
              id: profile.id,
              pushTokens,
            },
          },
        })
      );
  }, [profile]);

  const amhpTeams =
    profile.teams.items.length && profile.teams.items.length === 1
      ? profile.teams.items[0]?.amhpTeam.name
      : profile.teams.items.length && profile.teams.items.length > 1
      ? profile.teams.items.map(item => item?.amhpTeam.name).join(", ")
      : "No Team";

  const displayAMHPTeamInboxWhenRequired = useMemo(() => {
    const amhpTeams = profile.teams.items.map(t => {
      return { amhpTeam: t.amhpTeam.id, featureFlags: t.amhpTeam.featureFlags };
    });
    const currentTeam = amhpTeams.find(t => t.amhpTeam === (amhpTeamId || amhpTeams[0].amhpTeam));

    return currentTeam && amhpTeamInbox(currentTeam.featureFlags) ? (
      <>
        <SectionDivider />
        <Mod.DashboardFormInbox
          amhpTeamId={currentTeam.amhpTeam || amhpTeams[0].amhpTeam}
          featureFlags={currentTeam.featureFlags}
        />
      </>
    ) : (
      <></>
    );
  }, [amhpTeamId]);

  const detailSectionItems: SectionItem[] = (profile.teams.items.length > 1
    ? [
        {
          label: ``,
          flex: 2,
          value: (
            <Select
              label="Current Team"
              value={amhpTeamId || profile.teams.items[0]?.amhpTeam?.id || ""}
              onValueChange={v => setAmhpTeamId(v)}
              options={profile.teams.items.map((userTeams: { amhpTeam: { id: string; name: string } }) => ({
                id: userTeams?.amhpTeam?.id,
                name: userTeams?.amhpTeam?.name,
              }))}
            />
          ),
        } as SectionItem,
      ]
    : []
  ).concat([
    {
      label: "Name",
      value: profile.name,
      testID: "AMHP__UserProfile__Name",
      descriptionStyle: isWebView ? styles.descriptionStyleWeb : {},
    } as SectionItem,
    {
      label: `AMHP Team${amhpTeams && amhpTeams.length > 1 ? "s" : ""}`,
      value: amhpTeams,
      descriptionStyle: isWebView ? styles.descriptionStyleWeb : {},
    } as SectionItem,
  ]);

  return (
    <>
      <DetailsSection header="Your Details" items={detailSectionItems} columns={2} />
      <SectionDivider compressed={isWebView} />
      <Mod.DashboardAssessments assessments={profile.assessments.items} />
      {displayAMHPTeamInboxWhenRequired}
    </>
  );
};

const styles = StyleSheet.create({
  descriptionStyleWeb: {
    marginBottom: 0,
  },
});
