/**
 * Get the doctor MHA form list, separate the Draft and Submitted forms and
 * structure the accordion and display calls to render the full list.
 */
import React, { useMemo, useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { useQuery } from "@apollo/react-hooks";
import dayjs from "dayjs";
import { useRecoilState, useRecoilValue } from "recoil";
import gql from "graphql-tag";
import Text from "../Text";
import { Accordion, AccordionSection } from "@/components/Accordion";
import { refreshDoctorDraftFormInboxRequired, refreshDoctorFormInboxRequired, userDetails } from "@/utils/recoil";
import { TypographyType } from "@/models/Typography";
import { spacing } from "@/theme";
import { mqWeb } from "@/utils/helpers";
import MHAFormRecordsListBasicWithReassign from "../Forms/MHAFormRecord/MHAFormRecordsListBasicWithReassign";
import { getValueFromDataSections } from "../Forms/MHAFormContainer/helpers";
import { FormType, MHAFormData } from "libs/types/mhaForms";
import { FormStatus } from "libs/types/API";

export interface AssignedForm {
  id: string;
  type: FormType;
  amhpTeamId: string;
  amhpTeamInboxId: string;
  amhpTeam?: {
    id: string;
    mhaInboxInstructions: string | null;
  };
  status: FormStatus;
  signed: string[];
  authors: string[];
  notes: string[]; // formatted as EVENT_TYPE@USER_FULL_NAME(optional EMAIL or PHONE)@DATE_TIME
  updatedAt: string;
  createdBy: string;
  version: number;
  assessmentId: string;
  createdAt: string;
  sha256: string;
  data: MHAFormData | null;
}

const assignedFormsQuery = gql`
  query GetFormsByCreatedBy($createdBy: String, $status: ModelStringKeyConditionInput) {
    getFormsByCreatedBy(gsi3pk: $createdBy, gsi3sk: $status) {
      items {
        id
        type
        status
        signed
        authors
        notes
        updatedAt
        createdBy
        version
        amhpTeamId
        amhpTeamInboxId
        amhpTeam {
          id
          mhaInboxInstructions
        }
        assessmentId
        createdAt
        sha256
        data {
          sections {
            name
            fields {
              name
              value
              valueArray
              valueLocation {
                address
                city
                postcode
              }
              type
            }
          }
        }
        createdAt
      }
    }
  }
`;

interface AssignedFormsResponse {
  getFormsByCreatedBy: {
    items: AssignedForm[];
  };
}

const DoctorAssignedFormsList: React.FC<{
  amhpTeams: {
    id: string;
    name: string;
  }[];
}> = ({ amhpTeams }) => {
  const isWebView = mqWeb();

  const user = useRecoilValue(userDetails);
  const [doctorFormInboxRefresh, setDoctorFormInboxRefresh] = useRecoilState(refreshDoctorFormInboxRequired);
  const [doctorDraftFormInboxRefresh, setDoctorDraftFormInboxRefresh] = useRecoilState(
    refreshDoctorDraftFormInboxRequired
  );
  const { error, data, loading, refetch, variables } = useQuery<AssignedFormsResponse>(assignedFormsQuery, {
    variables: {
      createdBy: user?.id,
      status: {
        eq: "s60_signed_in_amhp_team_inbox#0000",
      },
    },
    fetchPolicy: "cache-and-network",
  });
  const draft = useQuery<AssignedFormsResponse>(assignedFormsQuery, {
    variables: {
      createdBy: user?.id,
      status: {
        eq: "s10_draft#0000",
      },
    },
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (doctorFormInboxRefresh) {
      refetch(variables);
      setDoctorFormInboxRefresh(false);
    }
  }, [doctorFormInboxRefresh]);

  useEffect(() => {
    if (doctorDraftFormInboxRefresh && draft) {
      draft.refetch(draft.variables);
      setDoctorDraftFormInboxRefresh(false);
    }
  }, [doctorDraftFormInboxRefresh]);

  enum DoctorUnattachedFormCategoryIndex {
    Drafts,
    Submitted,
  }

  function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
    return value !== null && value !== undefined;
  }

  //* DRAFT
  const renderDraftFormData = useMemo(() => {
    if (draft.data) {
      const forms =
        draft.data.getFormsByCreatedBy?.items
          ?.filter(i => i.data !== null && i.assessmentId === "!!!")
          ?.filter(notEmpty)
          .sort((dayA, dayB) => (dayjs(dayB.updatedAt).isAfter(dayjs(dayA.updatedAt)) ? 1 : -1))
          .map(f => ({
            id: f.id,
            status: f.status,
            formType: f.type,
            historyElements: [],
            signed: f.signed || [],
            createdBy: f.createdBy || "",
            authors: f.authors || [],
            data: f as AssignedForm & { data: Exclude<AssignedForm["data"], null> },
            createdAt: f.createdAt,
            patientName: (getValueFromDataSections(f.data!.sections, "name", "patientName") as string) || "",
            amhpTeamName: amhpTeams.find(a => a.id === f.amhpTeamId)?.name || "",
            // TODO: we should not lookup on type Date, future forms may have multiple dates in a form
            examinationDate: getValueFromDataSections(f.data!.sections, "type", "Date")
              ? // we have filtered out null data above, ! is safe
                // TODO: we should not lookup on type Date, future forms may have multiple dates in a form
                dayjs(getValueFromDataSections(f.data!.sections, "type", "Date") as string)
              : dayjs(),
            reassignable: false, // Draft forms cannot be reassigned
          })) || [];

      return (
        <AccordionSection
          index={DoctorUnattachedFormCategoryIndex.Drafts}
          heading="Draft"
          itemCount={forms.length}
          disabled={!forms.length}
        >
          <MHAFormRecordsListBasicWithReassign
            formRecords={forms}
            assessmentId={"!!!"}
            isAmhpForm={false}
            refetch={draft.refetch}
            user={user}
          />
        </AccordionSection>
      );
    } else {
      return (
        <AccordionSection index={DoctorUnattachedFormCategoryIndex.Drafts} heading="Draft" disabled={true}>
          <></>
        </AccordionSection>
      );
    }
  }, [draft.data, draft.error, draft.loading, isWebView]);

  //* SUBMITTED
  const renderFormData = useMemo(() => {
    if (data) {
      const forms =
        data.getFormsByCreatedBy?.items
          ?.filter(i => i.data !== null)
          ?.filter(notEmpty)
          .sort((dayA, dayB) => (dayjs(dayB.updatedAt).isAfter(dayjs(dayA.updatedAt)) ? 1 : -1))
          .map(f => ({
            id: f.id,
            status: f.status,
            formType: f.type,
            patientName: (getValueFromDataSections(f.data!.sections, "name", "patientName") as string) || "",
            amhpTeamName: amhpTeams.find(a => a.id === f.amhpTeamId)?.name || "",
            reassignable: !!amhpTeams.filter(a => a.id !== f.amhpTeamId)?.length,
            // we have filtered out null data above, ! is safe
            // TODO: we should not lookup on type Date, future forms may have multiple dates in a form
            examinationDate: getValueFromDataSections(f.data!.sections, "type", "Date")
              ? // we have filtered out null data above, ! is safe
                // TODO: we should not lookup on type Date, future forms may have multiple dates in a form
                dayjs(getValueFromDataSections(f.data!.sections, "type", "Date") as string)
              : dayjs(),
            historyElements: [], // Hide history from this list
            signed: f.signed || [],
            createdBy: f.createdBy || "",
            authors: f.authors || [],
            data: f as AssignedForm & { data: Exclude<AssignedForm["data"], null> },
            createdAt: f.createdAt,
            amhpTeam: f.amhpTeam || null,
          })) || [];

      return (
        <AccordionSection
          index={DoctorUnattachedFormCategoryIndex.Submitted}
          heading="Submitted"
          itemCount={forms.length}
          disabled={!forms.length}
        >
          <Text format={TypographyType.SmallBold} style={styles.helperText}>
            This list displays medical recommendations that have been sent to an AMHP team for review but have yet to be
            attached to an assessment. Until they are reviewed and actioned, you can reassign them to another AMHP team,
            view or delete them. Once an AMHP team has created an assessment from your medical recommendation it will be
            visible on the details page of the corresponding visit.
          </Text>

          <MHAFormRecordsListBasicWithReassign
            formRecords={forms}
            assessmentId={"!!!"}
            isAmhpForm={false}
            refetch={refetch}
            user={user}
          />
        </AccordionSection>
      );
    } else {
      return (
        <AccordionSection index={DoctorUnattachedFormCategoryIndex.Submitted} heading="Submitted" disabled={true}>
          <></>
        </AccordionSection>
      );
    }
  }, [data, error, loading, isWebView]);

  return (
    <Accordion
      marginTop={30}
      defaultActiveIndex={
        draft.data?.getFormsByCreatedBy.items.length
          ? DoctorUnattachedFormCategoryIndex.Drafts
          : data?.getFormsByCreatedBy.items.length
          ? DoctorUnattachedFormCategoryIndex.Submitted
          : -1
      }
    >
      {renderDraftFormData}
      {renderFormData}
    </Accordion>
  );
};

export default DoctorAssignedFormsList;

const styles = StyleSheet.create({
  helperText: {
    marginBottom: spacing[20],
  },
});
