export default [
  "Amharic",
  "Arabic",
  "Bengali",
  "Bulgarian",
  "Cantonese",
  "Farsi",
  "French",
  "German",
  "Greek",
  "Gujurati",
  "Hausa",
  "Hindi",
  "Italian",
  "Japanese",
  "Kannada",
  "Kashmiri",
  "Maltese",
  "Malayalam",
  "Mandarin",
  "Marathi",
  "Memon",
  "Nepalese",
  "Pashto",
  "Polish",
  "Portuguese",
  "Punjabi",
  "Romanian",
  "Portuguese",
  "Russian",
  "Sinhala",
  "Spanish",
  "Swahili",
  "Sylhet",
  "Tamil",
  "Telugu",
  "Turkish",
  "Urdu",
  "Welsh",
  "Yoruba",
];
