import { PathConfigMap } from "@react-navigation/native";

const basepath = "CCG";
const unassignedPath = `${basepath}/UnassignedClaims`;
const teamPath = `${basepath}/TeamClaims`;
const myPath = `${basepath}/MyClaims`;
const rejectedPath = `${basepath}/RejectedClaims`;
const approvedPath = `${basepath}/ApprovedClaims`;
const approvedPaidPath = `${basepath}/ApprovedPaidClaims`;

const ccgScreens: PathConfigMap = {
  CCGUnassignedClaimsTab: {
    screens: {
      UnassignedClaimsScreen: { path: `${unassignedPath}` },
      ClaimProcessingDetailsScreen: { path: `${unassignedPath}/claimprocessingdetails/:claimId` },
      ClaimProcessingSearchScreen: { path: `${unassignedPath}/claimprocessingsearch/:displayId` },
    },
  },

  CCGTeamClaimsTab: {
    screens: {
      TeamClaimsScreen: { path: `${teamPath}` },
      ClaimProcessingDetailsScreen: { path: `${teamPath}/claimprocessingdetails/:claimId` },
      ClaimProcessingSearchScreen: { path: `${teamPath}/claimprocessingsearch/:displayId` },
    },
  },

  CCGMyClaimsTab: {
    screens: {
      MyClaimsScreen: { path: `${myPath}` },
      ClaimProcessingDetailsScreen: { path: `${myPath}/claimprocessingdetails/:claimId` },
      ClaimProcessingSearchScreen: { path: `${myPath}/claimprocessingsearch/:displayId` },
    },
  },

  CCGRejectedClaimsTab: {
    screens: {
      RejectedClaimsScreen: { path: `${rejectedPath}` },
      ClaimProcessingDetailsScreen: { path: `${rejectedPath}/claimprocessingdetails/:claimId` },
      ClaimProcessingSearchScreen: { path: `${rejectedPath}/claimprocessingsearch/:displayId` },
    },
  },

  CCGApprovedClaimsTab: {
    screens: {
      ApprovedClaimsScreen: { path: `${approvedPath}` },
      ClaimProcessingDetailsScreen: { path: `${approvedPath}/claimprocessingdetails/:claimId` },
      ClaimProcessingSearchScreen: { path: `${approvedPath}/claimprocessingsearch/:displayId` },
    },
  },

  CCGApprovedPaidClaimsTab: {
    screens: {
      ApprovedPaidClaimsScreen: { path: `${approvedPaidPath}` },
      ClaimProcessingDetailsScreen: { path: `${approvedPaidPath}/claimprocessingdetails/:claimId` },
      ClaimProcessingSearchScreen: { path: `${approvedPaidPath}/claimprocessingsearch/:displayId` },
    },
  },
};

export default ccgScreens;
