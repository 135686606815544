import React from "react";
import {
  // View,
  StyleSheet,
  View,
} from "react-native";

import Text from "../../components/Text";
import { ContentWrap } from "../../components/ContentWrap";
import { TypographyType } from "@/models/Typography";
import { spacing } from "../../theme/spacing";

import { SectionDivider } from "../SectionDivider/SectionDivider";

interface PropTypes {
  ccgs: { name: string; id: string }[];
}

export const ClaimProcess =
  // eslint-disable-next-line
  (props: PropTypes) => {
    return (
      <>
        <ContentWrap>
          <Text format={TypographyType.HeadingMedium}>Assessment Claim Process Explained</Text>
          <Text format={TypographyType.Regular}>
            S12 Solutions has developed a simple, standardised, GDPR-compliant claim form process. Once Create Claim is
            initiated by the AMHP, the platform prompts them to select the patient’s GP practice to define the
            organisation that’s accountable for paying the claim. As per NHS England’s 'Who Pays Guidance', if the
            patient’s GP practice is unknown, the AMHP will be asked to input the patient’s usual place of residence, or
            if also unknown, the platform will use the assessment location. Once complete, the AMHP will issue the claim
            form to the doctor, who will add the additional information required by the paying authority, then submit
            the form.
          </Text>
          <Text format={TypographyType.Regular} style={styles.groupContainer}>
            Within their dashboard, a doctor can see whether a claim requires action, is under review, rejected – with
            explanatory notes and actions - or approved.
          </Text>
          <Text format={TypographyType.Regular} style={styles.groupContainer}>
            This process applies to CCGs enrolled on the platform because they belong to an area that’s commissioned S12
            Solutions. If the required CCG is not currently enrolled, the AMHP will be notified that the platform’s
            Create Claim process is not available for the CCG identified. In this scenario, the doctor will see the
            status ‘CCG – Not on Platform’ in the assessment within their Visits tab, along with the name of the
            accountable CCG so that the doctor may follow the CCG’s usual paper claim form process.
          </Text>
        </ContentWrap>
        <SectionDivider />
        {!!props.ccgs.length && (
          <ContentWrap>
            <Text format={TypographyType.HeadingMedium}>Enrolled CCGs are listed below:</Text>
            {props.ccgs.map((ccg, i) => (
              <View style={i !== 0 ? styles.groupContainer : {}} key={ccg.name}>
                <Text format={TypographyType.Regular} style={styles.groupHeading}>
                  {ccg.name.toUpperCase()} - {ccg.id}
                </Text>
              </View>
            ))}
          </ContentWrap>
        )}
      </>
    );
  };

const styles = StyleSheet.create({
  divider: {
    marginVertical: spacing[50],
  },
  groupContainer: {
    marginTop: spacing[10],
  },
  groupHeading: {
    paddingBottom: spacing[10],
  },
  groupItem: {
    paddingBottom: spacing[5],
  },
});
