import React from "react";
import { createStackNavigator } from "@react-navigation/stack";

import screenOptions from "@/navigationv2/config/screenOptions";
import { CCGWebOnlyRoutes } from "@/navigationv2/types";
import * as Screens from "@/screens";
import TabIcon from "../TabIcon";

const WebOnlyStack = createStackNavigator<CCGWebOnlyRoutes>();

const CCGWebOnlyClaimsStack = () => {
  return (
    <WebOnlyStack.Navigator initialRouteName="WebOnlyClaimsScreen" screenOptions={screenOptions}>
      <WebOnlyStack.Screen name="WebOnlyClaimsScreen" component={Screens.WebOnlyClaimsScreen} />
    </WebOnlyStack.Navigator>
  );
};

CCGWebOnlyClaimsStack.tabOptions = {
  tabBarIcon: TabIcon("lock-outline"),
  tabBarLabel: "Claims",
};

export default CCGWebOnlyClaimsStack;
