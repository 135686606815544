import React, { useCallback, useEffect, useMemo, useState, useRef } from "react";
import { Animated, Easing, StyleSheet, View, ViewStyle, LayoutChangeEvent } from "react-native";
import { BottomTabBarProps } from "@react-navigation/bottom-tabs";
import Auth from "@aws-amplify/auth";

import { palette } from "@/theme";
import { alert } from "@/utils/helpers";

import NestedItems, { NestedItemsHandle } from "./NestedItems";
import TabItem from "../TabItem";
import TabIcon from "../../TabIcon";
import { useSignout } from "@/hooks";

interface PropTypes extends BottomTabBarProps {
  barStyle?: ViewStyle;
}

const CCGTabBar = (props: PropTypes) => {
  const { barStyle, state } = props;
  const signout = useSignout();
  const [isOpen, setOpen] = useState(false);
  const [height, setHeight] = useState(0);
  const nestedItemsRef = useRef<NestedItemsHandle>(null);
  const [nestedItemsHeight, setNestedItemsHeight] = useState(0);
  const tabBarPosition = useMemo(() => new Animated.Value(0), []);

  /**
   * This is the first 5 of the CCG routes (CCGUnassignedClaimsTab,
   * CCGTeamClaimsTab, CCGMyClaimsTab, CCGRejectedClaimsTab and
   * CCGApprovedClaimsTab). The rest of the routes should be applied to the
   * tab bar as is, where as these get nested.
   */
  const nestedItems = state.routes.slice(0, 5);
  const tabBarItems = state.routes.slice(5);

  const onLayout = (event: LayoutChangeEvent) => {
    setHeight(event.nativeEvent.layout.height);
  };

  const onSignoutPress = useCallback(() => {
    setOpen(false);
    alert(
      "Logout",
      "Are you sure you want to logout?",
      () => null,
      () => signout(),
      "Cancel logout",
      "Yes, logout"
    );
  }, [alert, Auth]);

  useEffect(
    function updateNestedItemsHeight() {
      if (nestedItemsRef.current) {
        setNestedItemsHeight(nestedItemsRef.current.height);
      }
    },
    [nestedItemsRef.current]
  );

  useEffect(
    function toggleOpenState() {
      const toValue = isOpen ? nestedItemsHeight * -1 : 0;

      Animated.timing(tabBarPosition, {
        toValue,
        easing: Easing.out(Easing.cubic),
        useNativeDriver: true,
      }).start();
    },
    [isOpen]
  );

  useEffect(
    function closeOnRouteChange() {
      setTimeout(() => {
        setOpen(false);
      }, 100);
    },
    [state.index]
  );

  return (
    <>
      <Animated.View style={{ transform: [{ translateY: tabBarPosition }] }}>
        <View style={barStyle} onLayout={onLayout}>
          <TabItem
            {...props}
            index={0}
            isActive={state.index < nestedItems.length}
            isLastItem={false}
            onPress={() => setOpen(!isOpen)}
            options={{
              tabBarIcon: TabIcon("assignment-ind"),
              tabBarLabel: "Claims",
            }}
            route={{ name: "CCGClaimsTab", key: "CCGClaimsTab" }}
          />

          {tabBarItems.map((route, index) => (
            <TabItem
              {...{ route, ...props }}
              index={index + nestedItems.length}
              isLastItem={false}
              key={index + nestedItems.length}
            />
          ))}

          <TabItem
            {...props}
            index={state.routes.length + 1}
            isActive={false}
            isLastItem={true}
            onPress={onSignoutPress}
            options={{
              tabBarIcon: TabIcon("lock-outline"),
              tabBarLabel: "Logout",
            }}
            route={{ name: "CCGLogoutTab", key: "CCGLogoutTab" }}
          />

          <NestedItems {...props} items={nestedItems} ref={nestedItemsRef} tabBarHeight={height} />
        </View>
      </Animated.View>
      {/* This sneakey overlay will hide the nested navigation items that are shown when they should be hidden. */}
      <View style={[styles.sneakyOverlay, { height, top: height }]} />
    </>
  );
};

const styles = StyleSheet.create({
  sneakyOverlay: {
    backgroundColor: palette.white,
    position: "absolute",
    width: "100%",
    zIndex: 200,
  },
});

export default CCGTabBar;
