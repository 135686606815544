import * as Yup from "yup";
import { ComponentElement, FormFieldsDoctor, Itemisation } from "libs/types/mhaForms";

const componentElements: ComponentElement[] = [
  {
    component: "Text",
    componentProps: {
      instruction: "In my opinion,",
    },
    fullWidth: true,
  },
  {
    component: "Text",
    componentProps: {
      instruction:
        "(a) this patient is suffering from mental disorder of a nature or degree which makes it appropriate for the patient to receive medical treatment in a hospital,",
    },
    fullWidth: true,
    genericProps: {
      indentation: 1,
    },
  },
  {
    component: "Text",
    componentProps: {
      instruction: "AND",
    },
    fullWidth: true,
  },
  {
    component: "Text",
    componentProps: {
      instruction: "(b) it is necessary",
    },
    fullWidth: true,
    genericProps: {
      indentation: 1,
    },
  },
  {
    component: "Checkbox",
    fieldName: FormFieldsDoctor.ReasonsForDetention,
    componentProps: {
      itemisation: Itemisation.ROMAN,
      options: [
        {
          status: "health",
          label: "for the patient's own health",
        },
        {
          status: "safety",
          label: "for the patient's own safety",
        },
        {
          status: "protectionOfOthers",
          label: "for the protection of other persons",
        },
      ],
    },
    genericProps: {
      indentation: 2,
    },
  },
  {
    component: "Text",
    componentProps: {
      guidance: "(Check as applicable)",
      pdfOnlyGuidance: "(Delete the indents not applicable)",
    },
    fullWidth: true,
    genericProps: {
      indentation: 2,
    },
  },
  {
    component: "Text",
    componentProps: {
      instruction: `that this patient should receive treatment in hospital,\n\nAND`,
    },
    fullWidth: true,
  },
  {
    component: "Text",
    componentProps: {
      instruction: "(c) such treatment cannot be provided unless the patient is detained under section 3 of the Act,",
    },
    genericProps: {
      indentation: 1,
    },
    fullWidth: true,
  },
  {
    component: "Text",
    componentProps: {
      instruction: "because - ",
      guidance:
        "(Your reasons should cover (a), (b) and (c) above. As part of them: describe the patient's symptoms and behaviour and explain how those symptoms and behaviour lead you to your opinion; say whether other methods of treatment or care (eg out-patient treatment or social services) are available and, if so, why they are not appropriate; indicate why informal admission is not appropriate.)",
    },
    fullWidth: true,
  },
  {
    component: "BottomTextInput",
    fieldName: FormFieldsDoctor.DetailedReasons,
    fullWidth: true,
    componentProps: {
      label: "Reasons",
      helperText: `My reasons for these opinions are:\n\n(Your reasons should cover (a), (b) and (c) above. As part of them: describe the patient's symptoms and behaviour and explain how those symptoms and behaviour lead you to your opinion; say whether other methods of treatment or care (eg out-patient treatment or social services) are available and, if so, why they are not appropriate; indicate why informal admission is not appropriate.)`,
      buttonLabel: "Reasons",
      defaultText: "No details currently given",
    },
  },
];

export default {
  title: "Opinion",
  state: {
    reasonsForDetention: [],
    detailedReasons: "",
  },

  validation: Yup.object().shape({
    reasonsForDetention: Yup.array().min(1, "Please select at least one of the options").required(),
    detailedReasons: Yup.string().trim().min(20, "Please enter detailed reasons").required("Please enter a reason"),
  }),

  componentElements,
};
