import React, { useEffect, useContext, useCallback } from "react";
import { StyleSheet, View, Platform } from "react-native";
import { useMutation } from "@apollo/react-hooks";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useNavigation } from "@react-navigation/native";

import { AnalyticsEvent } from "libs/analytics/events";
import { setWeekday, setEndOfWeek, buildCreateAvailabilityInput } from "libs/dates";
import {
  AvailabilityControllerActionTypes,
  AvailabilityModelActionTypes,
  AvailabilityState,
} from "libs/state/availability/initialState";
import { LocationInput, LocationNameInput } from "libs/types/API";

import { useDialog } from "@/hooks";
import {
  GET_DOCTOR,
  MHTItem,
  DoctorProfile,
  CREATE_DOCTOR_AVAILABILITY,
  UPDATE_DOCTOR_AVAILABILITY,
} from "@/models/DoctorProfile";
import { saveError, buildErr } from "@/models/Error";
import { PostcodeItem } from "@/models/Location";
import { TypographyType } from "@/models/Typography";
import { spacing, color } from "@/theme";
import { recordEvent } from "@/utils/analytics";
import { mqWeb } from "@/utils/helpers";
import {
  userDetails,
  availabilityCalendarDate as recoilAvailabilityCalendarDate,
  lastException,
  snackbarMessage,
} from "@/utils/recoil/index";

import { AvailabilityDate } from "./AvailabilityDate";
import { AvailabilityContext } from "./DoctorAvailabilityProvider";
import { AvailabilityDetails } from "./AvailabilityDetails";
import { AvailabilityPattern } from "./AvailabilityPattern";
import { CreateAvailabilityFunc } from "./DoctorAvailability.props";

import { AdditionalInformation, InfoIcon } from "../AdditionalInformation";
import { Button } from "../Button";
import { ContentWrap } from "../ContentWrap";
import { RadioButton, RadioButtonGroup } from "../Radio/Radio";
import SectionDivider from "../SectionDivider/SectionDivider";
import Text from "../Text";
import { StackNavigationProp } from "@react-navigation/stack";
import { AllDoctorRoutes } from "@/navigationv2/types";
import dayjs from "dayjs";

type GetPostcode = (
  postcode: string,
  findExactMatch?: boolean
) => Promise<{
  location: LocationInput;
  locationName: LocationNameInput;
}>;

interface PropTypes {
  mhts?: MHTItem[];
  doctorProfile?: DoctorProfile;
  editMode?: boolean;
  id?: string;
  getPostcode: GetPostcode;
}

export const DoctorAvailability = (props: PropTypes) => {
  const [createAvailability] = useMutation<CreateAvailabilityFunc>(CREATE_DOCTOR_AVAILABILITY);
  const [updateAvailability] = useMutation(UPDATE_DOCTOR_AVAILABILITY);
  const user = useRecoilValue(userDetails);
  const availabilityCalendarDate = useRecoilValue(recoilAvailabilityCalendarDate);
  const setLastException = useSetRecoilState(lastException);
  const setMessage = useSetRecoilState(snackbarMessage);
  const { state, dispatch, controllerDispatch } = useContext(AvailabilityContext);
  const navigation = useNavigation<StackNavigationProp<AllDoctorRoutes>>();

  const setTimeAndDate = useCallback(() => {
    const day = availabilityCalendarDate || new Date();
    dispatch({
      type: AvailabilityModelActionTypes.RESET_AVAILABILITY_DATE,
      payload: day,
    });
  }, [availabilityCalendarDate, dispatch]);

  useEffect(() => {
    if (!props.editMode) {
      dispatch({
        type: AvailabilityModelActionTypes.SET_AVAILABILITY,
        payload: {
          index: 0,
          data: {
            date: availabilityCalendarDate || state.availabilities[0].date,
          },
        },
      });
      dispatch({
        type: AvailabilityModelActionTypes.SET_BASE_POSTCODE,
        payload: props.doctorProfile ? props.doctorProfile.locationName.postcode : "",
      });
    }
  }, [props.doctorProfile]);

  // reset availabilityDate on localState
  useEffect(() => {
    if (!props.editMode) {
      setTimeAndDate();
    }
  }, [props.editMode, setTimeAndDate]);

  const saveAvailabilities = useCallback(() => {
    controllerDispatch({
      type: AvailabilityControllerActionTypes.SET_SAVING,
      payload: true,
    });

    // creating a set of unique postcodes, to avoid checking the same postcode multiple time via api calls
    const availabilityPostcodes = [
      state.defaultPostcode,
      ...new Set(
        state.availabilities
          .filter((av: AvailabilityState) => av.postcode && av.postcode !== state.defaultPostcode)
          .map((av: AvailabilityState) => av.postcode)
      ),
    ];

    // once we got all of our unique postcodes, lets map over them and check if these are valid postcodes
    const postcodesWithLocationData = availabilityPostcodes.map(async (postcode: string) => {
      let postcodeData: PostcodeItem | null = null;
      let hasError = false;
      if (postcode !== (props.doctorProfile && props.doctorProfile.locationName.postcode)) {
        try {
          postcodeData = await props.getPostcode(postcode, true);
        } catch (e) {
          hasError = true;
        }
      }
      return {
        postcode,
        postcodeData: postcodeData,
        postcodeError: hasError,
      };
    });

    // run a promise all to resolve all of the async calls to the api
    Promise.all(postcodesWithLocationData).then(
      (
        values: {
          postcode: string;
          postcodeError: boolean;
          postcodeData: PostcodeItem | null;
        }[]
      ) => {
        let availabilities: AvailabilityState[] = [];
        let formHasErrors = false;
        availabilities = state.availabilities.map((av: AvailabilityState, i: number) => {
          // trigger potential error if the start and end time doesn't align with the type of availability (daytime / overnight)
          const hasError = !av.overnight
            ? dayjs()
                .hour(av.endTime.hours)
                .minute(av.endTime.minutes)
                .subtract(1, "minute")
                .isBefore(
                  dayjs()
                    .hour(av.startTime.hours)
                    .minute(av.startTime.minutes)
                )
            : av.startTime.hours < av.endTime.hours ||
              (av.startTime.hours === av.endTime.hours && av.startTime.minutes < av.endTime.minutes);

          // checking individual availabilities and assigning default values if they don't come with their own values for postcodes, mhts etc
          const postcode = av.postcode && !av.postcodeError ? av.postcode : state.defaultPostcode;
          const postcodeItem = values.find(v => v.postcode === postcode);
          const postcodeError = postcodeItem ? postcodeItem.postcodeError : false;
          const mht = av.mht ? av.mht : state.defaultMHT;
          const oncall = av.oncall ? av.oncall : state.defaultOncall;
          const notes = av.notes !== undefined ? av.notes : state.defaultNotes;

          // if the form has an error due to times not being correct or the postcode invalid, set overall formHasError flag to true
          if (hasError || postcodeError) formHasErrors = true;

          if (values[0].postcodeError)
            controllerDispatch({
              type: AvailabilityControllerActionTypes.SET_POSTCODE_ERROR,
              payload: true,
            });

          // open collapsed element if it has an error, to be visible for user
          if (!state.activeElements.includes(i) && postcodeError)
            controllerDispatch({
              type: AvailabilityControllerActionTypes.SET_ACTIVE_ELEMENT,
              payload: i,
            });

          return {
            ...av,
            postcode,
            hasError,
            postcodeError,
            mht,
            oncall,
            notes,
          };
        });

        // setting all availabilities with its values/ defaultvalues and errors (if there)
        dispatch({
          type: AvailabilityModelActionTypes.SET_ALL_AVAILABILITIES,
          payload: availabilities,
        });

        // showing modal if form has error
        if (formHasErrors) {
          controllerDispatch({
            type: AvailabilityControllerActionTypes.SET_OPEN_ERROR_MODAL,
            payload: true,
          });
          return;
        }

        availabilities.forEach((av: AvailabilityState, i: number) => {
          const postcodeItem = values.find(v => v.postcode === av.postcode);
          let newPostcode: string | null = null;
          let newLocation: LocationInput | null = null;
          let postcodes: string[] | null = null;
          let locations: LocationInput[] | null = null;

          // adding new postcode and location as this will added to doctor profile in its general availability locations etc
          if (postcodeItem && postcodeItem.postcodeData) {
            newPostcode = postcodeItem.postcodeData.locationName.postcode;
            postcodes = (props.doctorProfile ? props.doctorProfile.availabilityPostcodes || [] : []).concat(
              newPostcode
            );
            newLocation = postcodeItem.postcodeData.location;
            locations = (props.doctorProfile ? props.doctorProfile.availabilityLocations || [] : []).concat(
              newLocation
            );
          }

          // setting end date -> if not a repeated event setting to end of week, otherwise useing end date that has been provided
          const endDate =
            state.availabilities.length === 1 && state.repeatWeekly === "false"
              ? setEndOfWeek(av.date)
              : state.eventSeriesEndDate;

          // setting repeat weekdays to values given or if not provided selecting week day of availability date
          const repeatWeekdays = state.repeatWeekdays.length === 0 ? [setWeekday(av.date.day())] : state.repeatWeekdays;

          const doctor = props.doctorProfile
            ? {
                id: props.doctorProfile.id,
                location: props.doctorProfile.location,
                locationName: props.doctorProfile.locationName,
                availabilityLocations: props.doctorProfile.availabilityLocations || [props.doctorProfile.location],
                availabilityPostcodes: props.doctorProfile.availabilityPostcodes || [
                  props.doctorProfile.locationName.postcode,
                ],
              }
            : undefined;

          // oh...here comes this horrible big monster, hungry for inputs...the fn itself ain't too bad but doesn't look particular appealing
          let input = buildCreateAvailabilityInput(
            doctor,
            postcodes,
            locations,
            newPostcode,
            newLocation,
            av.date,
            av.startTime,
            av.endTime,
            state.isSingleItem,
            repeatWeekdays,
            state.repeatWeeklyCount,
            state.eventSeriesHasEnd,
            endDate,
            av.mht ? av.mht.value : state.defaultMHT.value,
            av.oncall ? av.oncall : state.defaultOncall,
            av.notes !== undefined ? av.notes : state.defaultNotes,
            !!av.overnight,
            state.defaultRota?.id || null,
            state.defaultRota?.name || null,
            props.editMode
          );

          // creating or updating based on editMode prop
          const mutation = props.editMode && props.id ? updateAvailability : createAvailability;

          input =
            props.editMode && props.id
              ? {
                  ...input,
                  input: {
                    ...input.input,
                    id: props.id,
                  },
                }
              : input;

          mutation({
            variables: input,
            refetchQueries: [
              {
                query: GET_DOCTOR,
                variables: { id: user ? user.id : "" },
              },
            ],
          })
            .then(() => {
              if (i === availabilities.length - 1) {
                setMessage("Saved");
                !props.editMode &&
                  recordEvent(AnalyticsEvent.AVAILABILITY_CREATED, {
                    method: state.isSingleItem === "true" ? "repeating" : "one-off",
                    itemsCreated: state.availabilities.length.toString(),
                    endDate: state.eventSeriesEndDate.toISOString(),
                    ...(input.input.type && { type: input.input.type }),
                  });

                navigation.goBack();
              }
            })
            .catch(buildErr(saveError, setLastException));
        });
      }
    );
  }, [
    controllerDispatch,
    createAvailability,
    dispatch,
    navigation,
    props,
    setLastException,
    setMessage,
    state.activeElements,
    state.availabilities,
    state.defaultMHT,
    state.defaultNotes,
    state.defaultOncall,
    state.defaultPostcode,
    state.eventSeriesEndDate,
    state.eventSeriesHasEnd,
    state.isSingleItem,
    state.repeatWeekdays,
    state.repeatWeekly,
    state.repeatWeeklyCount,
    updateAvailability,
    user,
  ]);

  const isWebView = mqWeb();
  const { openDialog, closeDialog } = useDialog();

  useEffect(() => {
    if (state.openErrorModal) {
      openDialog({
        heading: "Input Errors",
        message: [`Invalid details.`, `Please review and resubmit.`],
        confirmButton: {
          label: "Review Details",
          onPress: () =>
            controllerDispatch({
              type: AvailabilityControllerActionTypes.SET_OPEN_ERROR_MODAL,
              payload: false,
            }),
        },
        dismissable: false,
      });
    } else {
      closeDialog();
    }
  }, [closeDialog, controllerDispatch, openDialog, state.openErrorModal]);

  return (
    <>
      <ContentWrap>
        <Text format={TypographyType.HeadingSmall} style={styles.headerSpacing}>
          Details
        </Text>
        <Text format={Platform.OS === "web" ? TypographyType.Small : TypographyType.Micro} style={styles.headerSpacing}>
          This is your default information; please amend as required.
        </Text>
        <AvailabilityDetails mhts={props.mhts || []} isCustom={false} />
      </ContentWrap>

      <SectionDivider />

      <ContentWrap>
        <View style={styles.row}>
          <Text format={TypographyType.HeadingSmall} style={styles.headerSpacing}>
            Availability Type
          </Text>
          <InfoIcon
            onToggle={() =>
              controllerDispatch({
                type: AvailabilityControllerActionTypes.SET_SHOW_MORE_INFORMATION,
              })
            }
          />
        </View>
      </ContentWrap>
      {state.showMoreInformation && (
        <AdditionalInformation
          onClose={() =>
            controllerDispatch({
              type: AvailabilityControllerActionTypes.SET_SHOW_MORE_INFORMATION,
            })
          }
        >
          <>
            <Text format={TypographyType.RegularBold}>Repeating</Text>
            <Text format={TypographyType.Small} style={styles.headerSpacing}>
              Repeat an availability event across days in the week and across multiple weeks
            </Text>
            <Text format={TypographyType.RegularBold}>One-off</Text>
            <Text format={TypographyType.Small} style={styles.headerSpacing}>
              Add one or more unique availability events; click the downward arrow to customise the default information
              for each entry as required.
            </Text>
            <Text format={TypographyType.RegularBold}>Overnight</Text>
            <Text format={TypographyType.Small} style={styles.headerSpacing}>
              Create an availability event that makes you available for up to 24 hours from your Start time.
            </Text>
          </>
        </AdditionalInformation>
      )}
      <ContentWrap>
        <RadioButtonGroup
          onValueChange={(val: string) => {
            if (state.isSingleItem !== val) {
              controllerDispatch({
                type: AvailabilityControllerActionTypes.TOGGLE_SINGLE_ITEM,
                payload: val,
              });
              if (val)
                dispatch({
                  type: AvailabilityModelActionTypes.SET_SINGLE_AVAILABILITY,
                });
              controllerDispatch({
                type: AvailabilityControllerActionTypes.TOGGLE_REPEAT_WEEKLY,
                payload: "false",
              });
            }
          }}
          value={state.isSingleItem}
        >
          <RadioButton value={"true"} status={state.isSingleItem === "true"} label={"Repeating"} />
          <RadioButton value={"false"} status={state.isSingleItem === "false"} label={"One-Off"} />
        </RadioButtonGroup>
      </ContentWrap>

      {state.availabilities.map((av: AvailabilityState, i: number) => (
        <AvailabilityDate index={i} availability={av} key={i} mhts={props.mhts || []} />
      ))}

      {!props.editMode && state.isSingleItem === "false" && (
        <ContentWrap>
          <Button
            onPress={() => dispatch({ type: AvailabilityModelActionTypes.ADD_AVAILABILITY })}
            style={isWebView ? styles.buttonWeb : styles.buttonMobile}
            mode="outlined"
          >
            Add Another Availability
          </Button>
        </ContentWrap>
      )}
      {state.isSingleItem === "true" && <AvailabilityPattern />}
      <SectionDivider />

      <ContentWrap style={isWebView ? styles.buttonWrapWeb : styles.buttonWrapMobile}>
        <Button onPress={navigation.goBack} style={isWebView ? styles.buttonWeb : styles.buttonMobile} mode="outlined">
          Cancel
        </Button>
        <Button
          onPress={saveAvailabilities}
          style={isWebView ? styles.buttonWeb : styles.buttonMobile}
          mode="contained"
          disabled={state.isSaving}
        >
          Save
        </Button>
      </ContentWrap>
    </>
  );
};

const styles = StyleSheet.create({
  buttonWrapMobile: {
    marginTop: spacing[25],
    marginBottom: spacing[20] * -1,
  },
  buttonWrapWeb: {
    marginTop: spacing[30],
    flexDirection: "row",
    justifyContent: "flex-end",
  },

  buttonMobile: {
    marginBottom: spacing[10],
  },
  buttonWeb: {
    marginLeft: spacing[10],
    marginBottom: 0,
  },

  bottomSpacing: {
    paddingBottom: spacing[30],
  },

  confirmationText: {
    marginTop: spacing[10],
  },

  input: {
    marginHorizontal: spacing[20],
    minWidth: 100,
    borderBottomColor: color.backgroundGrey,
    borderBottomWidth: 1,
    textAlign: "center",
  },

  repeatErrorText: {
    marginTop: spacing[25] * -1,
  },

  row: {
    flexDirection: "row",
    alignItems: "center",
  },

  timeInput: {
    backgroundColor: color.background,
  },

  timePickerRowWeb: {
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  timePickerOne: {
    flex: 1,
    zIndex: 5,
  },
  timePickerOneWeb: {
    paddingRight: spacing[20],
  },
  timePickerTwo: {
    flex: 1,
    zIndex: 5,
  },
  timePickerTwoWeb: {
    paddingLeft: spacing[20],
  },

  headerSpacing: {
    marginBottom: spacing[20],
  },
});
