import { RRule, RRuleSet } from "rrule";
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import isBetween from "dayjs/plugin/isBetween";

import { AvailabilityType, AvailabilityState } from "libs/types/availability";
import { convertRRuleDateOnlyToUTCDate } from "./convert";
import { LocationInput, LocationNameInput, CreateAvailabilityInput } from "libs/types/API";

import { convertDateToRRuleFormat, checkMutationInput } from "libs/dates/convert";

dayjs.extend(isBetween);
dayjs.extend(utc);

type AvailabilityRule = {
  id: string;
  rrule: string;
  endHour: number;
  overnightElement?: number;
  notes?: string | null;
};

export function availabilitiesToTypeForTimespan<
  T extends {
    id: string;
    rrule: string;
    endHour: number;
    type: AvailabilityType;
    overnightElement?: number;
  },
  U extends { rrule: string; endHour: number }
>(
  availabilities: T[],
  holidays: U[] = [],
  visits: U[] = [],
  start: Dayjs = dayjs(),
  end: Dayjs = dayjs()
): AvailabilityState | AvailabilityType {
  if (!availabilities.length) {
    return AvailabilityState.noInformation;
  }

  const result = availabilitiesToNextAvailable(availabilities, holidays, visits, start, end);
  if (!result || !result.length) {
    return AvailabilityState.unavailable;
  } else {
    return result[0].availability.type || AvailabilityState.available;
  }
}

// best method to check doctor availability based on a start and end time
export function availabilitiesToNextAvailable<T extends AvailabilityRule, U extends { rrule: string; endHour: number }>(
  availabilities: T[] = [],
  holidays: U[] = [],
  visits: U[] = [],
  startTime?: Dayjs,
  endTime?: Dayjs
): {
  start: Dayjs;
  end: Dayjs;
  availability: T;
}[] {
  const now = dayjs();
  const start = startTime || now.hour(0).minute(0).second(0).millisecond(0);

  // splitting overnight availabilities into two separate rrules to check against given timeframe
  const adjAvailabilities = createOvernightAvailabilities(availabilities, true);

  const results = calendarItemsToAvailabilityDates(adjAvailabilities, holidays, visits, start, endTime);

  const result = results
    .sort((a, b) => a.end.valueOf() - b.end.valueOf())
    .filter((v) => v.end.valueOf() >= start.valueOf())
    .sort((a, b) => a.start.valueOf() - b.start.valueOf())
    .filter((v, i, a) => a.findIndex((t) => t.item.id === v.item.id) === i)
    .map((e) => {
      if (e.item.overnightElement) {
        const av = availabilities.find((a) => a.id === e.item.id) || e.item;
        const rule = RRule.fromString(av.rrule);
        const endTime = av.endHour.toString().substr(2);
        const endHour = Number(endTime.length === 3 ? endTime.substr(0, 1) : endTime.substr(0, 2));
        const endMinutes = Number(endTime.substr(-2));
        return {
          item: {
            ...e.item,
            rrule: av.rrule,
          },
          start:
            e.item.overnightElement === 1
              ? e.start
              : e.start.subtract(1, "day").hour(rule.options.byhour[0]).minute(rule.options.byminute[0]),
          end: e.item.overnightElement === 1 ? e.end.add(1, "day").hour(endHour).minute(endMinutes) : e.end,
        };
      }
      return e;
    });

  if (!result || !result.length) {
    return [];
  }
  return result.map((r) => ({
    start: r.start,
    end: r.end,
    availability: r.item as T,
  }));
}

// checks if the person is available, defaults to TODAY
export function isAvailable<T extends AvailabilityRule, U extends { rrule: string; endHour: number }>(
  availabilities: T[],
  holidays: U[] = [],
  visits: U[] = [],
  startDate: Dayjs = dayjs().hour(0),
  endDate: Dayjs = dayjs().add(1, "day")
): boolean {
  if (!availabilities.length) {
    return false;
  }
  const next = availabilitiesToNextAvailable(availabilities, holidays, visits, startDate, endDate);
  if (!next || next.length === 0) {
    return false;
  }
  return true;
}

// be careful using this function as it will over-fetch results starting with the first hour of the day.
export function calendarItemsToAvailabilityDates<
  T extends AvailabilityRule,
  U extends { rrule: string; endHour: number }
>(
  // TODO: when location does not exist, we need to pass in the base postcode
  items: T[] = [],
  holidays: U[] = [],
  visits: U[] = [],
  start: Dayjs, // ignores time component
  end?: Dayjs
): { start: Dayjs; end: Dayjs; item: T }[] {
  let result: { start: Dayjs; end: Dayjs; item: T }[] = [];
  // assume: display availabilities the same if they have the same type, location, and contractShortCode

  const calendarItems = [...createOvernightAvailabilities(items, false), ...holidays, ...visits];

  // const groupAvailabilities: { [key: string]: any } = { default: items };
  const groupAvailabilities: { [key: string]: T[] } = calendarItems.reduce(
    (prev, curr, i) => ({ ...prev, [i.toString()]: [curr] }),
    {}
  );

  // TODO: justify this as a need or remove and make 'end' required
  if (!end) {
    // auto-set end to 7 days in future
    end = dayjs(start).add(7, "day");
  }

  for (const group in groupAvailabilities) {
    if (Object.prototype.hasOwnProperty.call(groupAvailabilities, group)) {
      const rruleSet = new RRuleSet(true);

      groupAvailabilities[group].forEach((a: T) => {
        if (a.rrule) {
          const rule = a.rrule.replace("BYSECOND=0", "BYSECOND=1");
          rruleSet.rrule(RRule.fromString(rule.replace(";", "\n")));
        }
      });
      // only use the date component of start because if availability start < start, the rule won't be found.
      // other methods need to handle filtering down to the hour
      end = end.add(1, "minute");

      const availabilityDates = rruleSet.between(convertRRuleDateOnlyToUTCDate(start.toDate()), end.toDate(), true);

      // conversions needed due to rrule library working in utc
      result = result.concat(
        availabilityDates.map((ad: Date) => {
          const availabilityDate = dayjs(ad).utc();
          const startTime = availabilityDate;
          // const startTime = convertRRuleDateTimeToUTCDate(ad);
          // eslint-disable-next-line
          const { endHour } = groupAvailabilities[group][0];
          let hour, minutes;
          // endHour can be in the format of hh or hhmm or hmm
          if (endHour && endHour > 99) {
            // let end = endHour.toString().startsWith("99") ? endHour.toString().substr(2,) : endHour.toString()
            const endHourLength = endHour.toString().length;
            hour = Number(endHour.toString().substr(0, endHourLength - 2));
            minutes = Number(endHour.toString().substr(endHourLength - 2));
          } else {
            hour = groupAvailabilities[group][0].endHour;
            minutes = availabilityDate.minute();
          }

          const endTime = availabilityDate.hour(hour).minute(minutes).millisecond(1);

          return {
            start: startTime,
            item: groupAvailabilities[group][0],
            end: endTime,
          };
        })
      );
    }
  }
  return result;
}

export function isAvailableAtHour<T extends { start: Dayjs; end: Dayjs }>(
  // TODO: when location does not exist, we need to pass in the base postcode
  items: T[] = [],
  hour: Dayjs
): boolean {
  for (const item of items) {
    if (item.end > hour && item.start < hour) {
      return true;
    }
    return false;
  }
  return false;
}

export function setWeekday(day: number) {
  const weekdays = [
    { label: "Mon", value: "MO" },
    { label: "Tue", value: "TU" },
    { label: "Wed", value: "WE" },
    { label: "Thu", value: "TH" },
    { label: "Fri", value: "FR" },
    { label: "Sat", value: "SA" },
    { label: "Sun", value: "SU" },
  ];

  return weekdays[(day || 7) - 1].value;
}

function incrementWeekdays(weekdays: string[]) {
  const days = ["MO", "TU", "WE", "TH", "FR", "SA", "SU"];
  return weekdays.map((day) => {
    const index = days.indexOf(day) + 2;
    return setWeekday(index === 8 ? 1 : index);
  });
}

export const createOvernightAvailabilities = <T extends AvailabilityRule>(
  availabilities: T[],
  splitUp: boolean
): T[] => {
  // when dealing with overnight availabilities, we save these to the db as having an endHour prefixed with "99"
  // ...yeah should've given that an additional property rather
  // but when using these inside a calendar we need to split them up in two rrules
  const events: T[] = [];
  availabilities.forEach((av) => {
    if (av.endHour.toString().startsWith("99")) {
      const rruleDate = av.rrule.split("DTSTART:")[1].split("Z")[0];
      const date = rruleDate.split("T")[0];
      const time = rruleDate.split("T")[1];
      const isOneOff = !av.rrule.includes("BYDAY=");
      const days = isOneOff ? dayjs(date).format("dd").toUpperCase() : av.rrule.split("BYDAY=")[1].split(";")[0];
      // here we go, creating two elements with the "overnightElement" property telling us which one comes first, to display appropriately inside a calendar
      const eventRules = [
        {
          overnightElement: 1,
          endHour: splitUp ? 2359 : Number(av.endHour.toString().substr(2)),
          rrule: !isOneOff
            ? `DTSTART:${date}T${time}Z${av.rrule.split("Z")[1].split("BYDAY=")[0]}BYDAY=${days};${av.rrule
                .split("Z")[1]
                .split("BYDAY=")[1]
                .split(";")
                .slice(1)
                .join(";")}`
            : `DTSTART:${date}T${time}Z${av.rrule.split("Z").slice(1).join("Z")}`,
        },
        {
          overnightElement: 2,
          endHour: Number(av.endHour.toString().substr(2)),
          rrule: !isOneOff
            ? `DTSTART:${dayjs(date).add(1, "day").format("YYYYMMDD")}T${splitUp ? "000001" : time}Z${
                av.rrule.split("Z")[1].split("BYDAY=")[0]
              }BYDAY=${incrementWeekdays(days.split(","))};${
                av.rrule.split("Z")[1].split("BYDAY=")[1].split(";").slice(1).join(";").split("BYHOUR=")[0]
              }BYHOUR=${splitUp ? 0 : Number(time.substr(0, 2))};${av.rrule
                .split("Z")[1]
                .split("BYDAY=")[1]
                .split(";")
                .slice(1)
                .join(";")
                .split("BYHOUR=")[1]
                .split(";")
                .slice(1)
                .join(";")}`
            : `DTSTART:${dayjs(date).add(1, "day").format("YYYYMMDD")}T${splitUp ? "000001" : time}Z${av.rrule
                .split("Z")
                .slice(1)
                .join("Z")}`,
        },
      ];

      const avs = eventRules.map((rrule: { endHour: number; rrule: string }) => ({
        ...av,
        ...rrule,
      }));
      events.push(...avs);
    } else {
      events.push(av);
    }
  });

  return events;
};

export const buildCreateAvailabilityInput = (
  doctorProfile:
    | {
        id: string;
        location: LocationInput;
        locationName: LocationNameInput;
        availabilityLocations: LocationInput[];
        availabilityPostcodes: string[];
      }
    | undefined,
  postcodes: string[] | null,
  locations: LocationInput[] | null,
  newPostcode: string | null,
  newLocation: LocationInput | null,
  availabilityDate: Dayjs,
  startTime: { hours: number; minutes: number },
  endTime: { hours: number; minutes: number },
  isSingleItem: string,
  repeatWeekdays: string[],
  repeatWeeklyCount: string,
  eventSeriesHasEnd: boolean,
  endDate: Dayjs,
  mht: string | null,
  oncall: boolean,
  notes: string,
  isOvernightEvent: boolean,
  rotaId: string | null,
  rotaName: string | null,
  editMode: boolean | undefined
) => {
  // manually creating rrule by inputs given
  const startDate = `DTSTART:${convertDateToRRuleFormat(availabilityDate, startTime)}`;
  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  let rrule: string = `${startDate};RRULE:UNTIL=${convertDateToRRuleFormat(availabilityDate.add(1, "day"), {
    hours: 23,
    minutes: 59,
  })}`;
  if (isSingleItem === "true") {
    rrule = `${startDate};RRULE:FREQ=WEEKLY;WKST=MO;INTERVAL=${
      repeatWeeklyCount === "0" || !Number(repeatWeeklyCount) || Number(repeatWeeklyCount) <= 0
        ? "1"
        : repeatWeeklyCount
    };BYDAY=${repeatWeekdays.join(",")};BYHOUR=${startTime.hours};BYMINUTE=${startTime.minutes};BYSECOND=1${
      eventSeriesHasEnd ? ";UNTIL=" : ""
    }${
      eventSeriesHasEnd
        ? convertDateToRRuleFormat(endDate, {
            hours: 23,
            minutes: 59,
          })
        : ""
    }`;
  }

  const userId = doctorProfile ? doctorProfile.id : "";

  // wrapped in checkMutationInput to remove any empty strings before running the mutation
  const availabilityInput: CreateAvailabilityInput = checkMutationInput({
    availabilityDoctorId: userId,
    location: newLocation || (doctorProfile && doctorProfile.location),
    locationName: newPostcode ? { postcode: newPostcode } : doctorProfile && doctorProfile.locationName,
    endHour: Number(
      `${isOvernightEvent ? "99" : ""}${endTime.hours}${endTime.minutes <= 9 ? "0" : ""}${endTime.minutes}`
    ),
    endDate:
      isSingleItem === "true"
        ? eventSeriesHasEnd
          ? convertDateToRRuleFormat(endDate, { hours: 23, minutes: 59 })
          : convertDateToRRuleFormat(dayjs(new Date(3000, 0, 1)), {
              hours: 0,
              minutes: 9,
            })
        : convertDateToRRuleFormat(dayjs(availabilityDate).add(1, "day"), {
            hours: 0,
            minutes: 1,
          }),
    rrule: rrule,
    contractList: null,
    oncall: oncall,
    notes: notes,
    rotaId: rotaId,
    rotaName: rotaName,
    s12DoctorAvailabilitiesId: userId,
  });

  // quick last check if mht given and as such availability marked as trust or independent
  const input: CreateAvailabilityInput =
    mht === "-"
      ? {
          type: AvailabilityType.independent,
          availabilityMhtId: null,
          ...availabilityInput,
        }
      : {
          type: AvailabilityType.trust,
          availabilityMhtId: mht,
          ...availabilityInput,
        };

  // We have a secondary index on the rotaId. In create mode, when the rotaId is not defined, we need to
  // remove it from the input object on the mutation. This does not apply to edit mode.
  // In edit mode, the rotaId must be null if the rota has changed to being unspecified.
  // Therefore in edit mode, if the rota has changed to null, we DO pass down null values.
  if (!rotaId && !editMode) {
    delete input.rotaId;
    delete input.rotaName;
  }
  return {
    input,
    id: userId,
    profileLocations: locations || (doctorProfile ? doctorProfile.availabilityLocations : []),
    profilePostcodes: postcodes || (doctorProfile ? doctorProfile.availabilityPostcodes : []),
  };
};
