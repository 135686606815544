import React from "react";
import { Query } from "react-apollo";

import { LocationNameInput } from "libs/types/API";

import { GET_CLAIM_DETAILS } from "@/models/Claim";

import CreateClaim from "./CreateClaim";
import { VisitResponse } from "./CreateClaim.props";
import { ErrorMessage } from "../Error/Error";
import Loading from "../Loading";

interface PropTypes {
  assessmentId?: string;
  doctorId?: string;
  visitId?: string;
  ccgId?: string;
  location?: LocationNameInput;
}

export default function CreateClaimGQL(props: PropTypes) {
  return (
    <Query<VisitResponse> query={GET_CLAIM_DETAILS} variables={{ id: props.visitId }}>
      {({ error, data, loading }) => {
        if (error) {
          return <ErrorMessage apolloError={error} />;
        } else if (loading) {
          return <Loading />;
        } else if (data && data.visit) {
          const { visit } = data;
          return <CreateClaim visitData={visit} />;
        } else {
          return <Loading />;
        }
      }}
    </Query>
  );
}
