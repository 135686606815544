import React, { useRef } from "react";
import { Platform, StyleSheet, View } from "react-native";
import { TouchableRipple } from "react-native-paper";
import { useHover } from "react-native-web-hooks";

import { MultiSelectCheckbox } from "./MultiSelectCheckbox";
import { palette, spacing } from "../../../theme";

interface MHAFormMultiSelectOptionProps {
  checked: boolean;
  children: React.ReactNode;
  onPress: () => void;
  readonly?: boolean;
}

export const MHAFormMultiSelectOption = ({ checked, children, onPress, readonly }: MHAFormMultiSelectOptionProps) => {
  const optionRef = useRef<TouchableRipple>(null);
  const isHovered = useHover(optionRef);

  return (
    <TouchableRipple ref={optionRef} rippleColor={palette.rippleColor} style={styles.option} onPress={onPress}>
      <>
        <View style={styles.row}>
          <View style={styles.content}>{children}</View>

          {!readonly && (
            <View style={styles.checkbox}>
              <MultiSelectCheckbox checked={checked} />
            </View>
          )}
        </View>
        {isHovered && <View style={styles.hoveredBG} />}
      </>
    </TouchableRipple>
  );
};

const styles = StyleSheet.create({
  checkbox: {
    flexShrink: 0,
  },

  content: {
    flex: 1,
    flexShrink: 1,
    paddingRight: spacing[20],
  },

  hoveredBG: {
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    backgroundColor: palette.cloud,
    zIndex: -1,
    opacity: 0.5,
  },

  option: {
    position: "relative",
    paddingHorizontal: spacing[20],
    paddingVertical: spacing[15],
    backgroundColor: palette.white,
    shadowColor: palette.blueShadow,
    borderRadius: 6,
    shadowRadius: 6,
    shadowOpacity: 0.3,
    shadowOffset: { width: 0, height: 0 },
    ...Platform.select({
      web: {
        cursor: "pointer",
      },
    }),
  },

  row: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    flex: 1,
  },
});
