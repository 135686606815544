import React, { useMemo } from "react";
import { StyleSheet, View } from "react-native";

import { mqWeb } from "../../utils/helpers";
import { palette, spacing } from "../../theme";
import ContentWrap from "../ContentWrap/ContentWrap";
import LogoVector from "../LogoVector/LogoVector";

interface SectionDividerProps {
  greyBG?: boolean;
  short?: boolean;
  compressed?: boolean;
}

export const SectionDivider: React.FC<SectionDividerProps> = React.memo(
  ({ greyBG = false, short = false, compressed = false }) => {
    const isWebView = mqWeb();
    let height = short ? (isWebView ? 90 : 75) : isWebView ? 120 : 100;
    if (compressed) {
      height = height / 3;
    }
    const containerStyles = useMemo(
      () => [
        styles.container,
        {
          height,
        },
      ],
      [isWebView, short]
    );

    const dividerStyles = useMemo(
      () => [
        styles.divider,
        {
          backgroundColor: greyBG ? palette.grey : palette.cloud,
        },
      ],
      [isWebView, greyBG]
    );

    const logoStyles = useMemo(() => [styles.logo, { backgroundColor: greyBG ? palette.cloud : palette.white }], [
      greyBG,
    ]);

    return (
      <ContentWrap style={containerStyles}>
        <View style={dividerStyles} />
        <View style={logoStyles}>
          <LogoVector size={35} greyscale={true} />
        </View>
      </ContentWrap>
    );
  }
);

SectionDivider.displayName = "SectionDivider";

const styles = StyleSheet.create({
  container: {
    position: "relative",
    justifyContent: "center",
    alignItems: "center",
  },

  divider: {
    height: 2,
    width: "100%",
  },

  logo: {
    position: "absolute",
    paddingHorizontal: spacing[20],
  },
});

export default React.memo(SectionDivider);
