import { StyleSheet } from "react-native";
import { palette, spacing } from "../../theme";

export const meetingStyles = StyleSheet.create({
  screen: {
    flexDirection: "column",
    justifyContent: "flex-start",
    backgroundColor: palette.blue,
    width: "100%",
    height: "100%",
  },

  videoContainer: {
    position: "relative",
    flex: 1,
    borderBottomRightRadius: 30,
    borderBottomLeftRadius: 30,
    backgroundColor: palette.white,
    shadowColor: palette.shadow,
    shadowOpacity: 0.35,
    shadowOffset: { width: 0, height: 4 },
    shadowRadius: 30,
    overflow: "hidden",
    zIndex: 1,
  },

  videoTopNotificationArea: {
    // position: "absolute",
    // left: 0,
    // top: 0,
    width: "100%",
    zIndex: 100,
  },

  notificationContainer: {
    position: "absolute",
    left: 0,
    bottom: 0,
    width: "100%",
    zIndex: 999999,
  },

  controlBar: {
    height: 84,
    paddingHorizontal: spacing[20],
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: palette.blue,
  },

  controlBarButtonsGroup: {
    flexDirection: "row",
    alignItems: "center",
  },

  descriptionItem: {
    marginBottom: spacing[15],
  },

  sessionTimeoutModalContent: {
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: spacing[30],
  },

  audioElement: {
    height: 0,
    width: 0,
  },
});
