import { useCallback } from "react";
import { TextInput } from "react-native";

interface Refs {
  [key: string]: TextInput;
}

/**
 * Build a list of references to form input fields and then move focus to a
 * given input when required. This allows you to not keep a list of inputRefs
 * in every form component, and prevents manual checking as to whether ia ref
 * is set.
 *
 * Usage
 * -----
 * const { focusInput, setRef} = useFocusInput();
 *
 * return (
 *   <>
 *     <TextInput
 *       onSubmitEditing={() => focusInput("input2")}
 *       ref={element => setRef("input1", element)}
 *       returnKeyType="next"
 *     />
 *
 *     <TextInput ref={element => setRef("input2", element)} />
 *   </>
 * );
 */
export default function useFocusInput() {
  const refs: Refs = {};

  const focusInput = useCallback(
    (id: string) => {
      refs[id] && refs[id].focus();
    },
    [refs]
  );

  const setRef = useCallback(
    (id: string, element: TextInput) => {
      refs[id] = element;
    },
    [refs]
  );

  return { focusInput, setRef, refs };
}
