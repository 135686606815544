import React, { useContext, useEffect, useState } from "react";
import { View, StyleSheet } from "react-native";
import Select from "../../Select/Select";

import { TextInput } from "../../TextInput";
import { Checkbox } from "../../Checkbox/Checkbox";
import { MHTItem } from "@/models/DoctorProfile";
import {
  AvailabilityControllerActionTypes,
  AvailabilityModelActionTypes,
  StateContext,
} from "libs/state/availability/initialState";
import { AvailabilityContext } from "../DoctorAvailabilityProvider";
import { mqWeb } from "../../../utils/helpers";
import { color, spacing } from "@/theme";
import { TypographyType } from "@/models/Typography";
import Icon from "../../Icon";
import Text from "../../Text";

export function AvailabilityDetails(props: { isCustom: boolean; index?: number; mhts?: any }) {
  const { state, dispatch, controllerDispatch } = useContext<StateContext>(AvailabilityContext);
  const {
    availabilities,
    defaultMHT,
    defaultOncall,
    defaultRota,
    defaultNotes,
    defaultPostcode,
    postcodeError,
  } = state;
  const { isCustom, index = 0 } = props;
  const [rotas, setRotas] = useState<{ id: string; name: string }[]>([]);
  const isWebView = mqWeb();

  const mhtDropdownOptions = props.mhts
    ? [
        { id: "-", name: "Independent" },
        ...props.mhts
          .map((m: MHTItem) => ({
            id: m.id,
            name: m.organisation.name,
          }))
          .sort((a: { name: string }, b: { name: string }) => (a.name > b.name ? 1 : -1)),
      ]
    : [];

  // When the chosen MHT changes, we would like to show a list of available
  // rotas associated with that MHT. If rotas are present on the MHT, create
  // create the options list for the select.
  useEffect(() => {
    if (defaultMHT) {
      retriveRotasFromMHT(defaultMHT);
    }
  }, [defaultMHT]);

  const retriveRotasFromMHT = (mht: any) => {
    const seletedMHT = props.mhts.find((m: any) => m.id === mht.value);
    if (seletedMHT?.organisation?.rotas) {
      const rotaOptions = [{ id: null, name: "-" }, ...seletedMHT.organisation.rotas];
      setRotas(rotaOptions);
    } else {
      // If the chosen MHT has no roats associated, the list of rotas should be empty. This
      // may happen when we choose an MHT with no rotas
      setRotas([]);
    }
  };

  const config = isCustom
    ? {
        postcode:
          availabilities[index].postcode || availabilities[index].postcode === ""
            ? availabilities[index].postcode
            : defaultPostcode,
        mht: availabilities[index].mht ? availabilities[index].mht : defaultMHT,
        oncall: availabilities[index].oncall !== undefined ? availabilities[index].oncall : defaultOncall,
        notes: availabilities[index].notes !== undefined ? availabilities[index].notes : defaultNotes,
        rota: availabilities[index].rota !== undefined ? availabilities[index].rota : defaultRota,
        error: availabilities[index].postcodeError,
        setPostcode: (val: string) =>
          dispatch({
            type: AvailabilityModelActionTypes.SET_AVAILABILITY,
            payload: {
              index: index,
              data: {
                postcode: val,
                postcodeError: false,
              },
            },
          }),
        setMHT: (val: any) =>
          dispatch({
            type: AvailabilityModelActionTypes.SET_AVAILABILITY,
            payload: {
              index: index,
              data: {
                mht: val,
              },
            },
          }),
        setOncall: (val: boolean) =>
          dispatch({
            type: AvailabilityModelActionTypes.SET_AVAILABILITY,
            payload: {
              index: index,
              data: {
                oncall: val,
              },
            },
          }),
        setRota: (val: any) =>
          dispatch({
            type: AvailabilityModelActionTypes.SET_AVAILABILITY,
            payload: {
              index: index,
              data: {
                rota: val,
              },
            },
          }),
        setNotes: (val: string) =>
          dispatch({
            type: AvailabilityModelActionTypes.SET_AVAILABILITY,
            payload: {
              index: index,
              data: {
                notes: val,
              },
            },
          }),
      }
    : {
        postcode: defaultPostcode,
        mht: defaultMHT,
        oncall: defaultOncall,
        notes: defaultNotes,
        error: postcodeError,
        rota: defaultRota,
        setPostcode: (val: string) => {
          if (postcodeError)
            controllerDispatch({
              type: AvailabilityControllerActionTypes.SET_POSTCODE_ERROR,
              payload: false,
            });
          dispatch({
            type: AvailabilityModelActionTypes.SET_BASE_POSTCODE,
            payload: val,
          });
        },
        setMHT: (val: any) =>
          dispatch({
            type: AvailabilityModelActionTypes.SET_DEFAULT_MHT,
            payload: val,
          }),
        setOncall: (val: boolean) =>
          dispatch({
            type: AvailabilityModelActionTypes.SET_DEFAULT_ONCALL,
            payload: val,
          }),
        setRota: (val: any) =>
          dispatch({
            type: AvailabilityModelActionTypes.SET_DEFAULT_ROTA,
            payload: val,
          }),
        setNotes: (val: string) =>
          dispatch({
            type: AvailabilityModelActionTypes.SET_DEFAULT_NOTE,
            payload: val,
          }),
      };

  const customProps = !isCustom
    ? {
        supplementaryInfo:
          "Please share any additional information such as your criteria for attending assessments, travel limitations or contact preferences.",
      }
    : {};

  return (
    <>
      <TextInput
        autoCorrect={false}
        icon="location-on"
        label="Starting Postcode"
        autoCapitalize="none"
        returnKeyType="done"
        value={config.postcode}
        onChangeText={value => config.setPostcode(value)}
        error={config.error}
        errorText="Please enter a valid postcode"
      />
      {props.mhts && (
        <View style={{ flexDirection: isWebView ? "row" : "column" }}>
          <View>
            <View style={styles.mhtInfoWrapper}>
              <Icon style={styles.infoIcon} name="info" color={color.secondary} />
              <Text format={TypographyType.Small}>
                If your MHT doesn’t appear here, please edit your profile and add your MHT using the dropdown under MHT
                Employer.
              </Text>
            </View>
            <Select
              icon="business"
              label="MHT association"
              value={(config.mht && config.mht.value) || mhtDropdownOptions[0].name}
              onValueChange={(_v: string, i: number, d: { value: string; label: string }[]) => {
                if (d[i].value === "-" && config.oncall) {
                  config.setOncall(false);
                }
                config.setMHT(d[i]);
              }}
              options={mhtDropdownOptions}
            />
          </View>
          {config.mht && config.mht.value !== "-" && (
            <View
              style={
                isWebView
                  ? {
                      marginLeft: 20,
                      justifyContent: "center",
                    }
                  : { marginTop: 10 }
              }
            >
              <Checkbox
                disabled={config.mht && config.mht.value === "-"}
                status={!!config.oncall}
                label="On call"
                onPress={() => {
                  if (config.oncall) {
                    // If the current state is checked. Then we will set this to unchecked.
                    // set the rota to its initial state
                    config.setRota({ id: "", name: "" });
                  }
                  config.setOncall(!config.oncall);
                }}
              />
            </View>
          )}
        </View>
      )}
      {config.oncall && rotas.length > 0 && (
        <View style={{ flexDirection: isWebView ? "row" : "column", marginTop: 10 }}>
          <Select
            label="Rotas"
            icon="date-range"
            value={config.rota?.id || ""}
            onValueChange={v => {
              const seletedRota = rotas.find(r => r.id === v);
              config.setRota(seletedRota);
            }}
            options={rotas || []}
          />
        </View>
      )}
      <TextInput
        {...customProps}
        icon="note"
        label="Notes"
        autoCorrect={true}
        spellCheck={true}
        returnKeyType="go"
        value={config.notes}
        multiline={true}
        selectTextOnFocus={true}
        numberOfLines={2}
        onChangeText={(value: string) => config.setNotes(value)}
      />
    </>
  );
}

const styles = StyleSheet.create({
  mhtInfoWrapper: {
    flexDirection: "row",
    alignItems: "flex-start",
    marginTop: spacing[30],
    marginBottom: spacing[20],
    paddingRight: spacing[10],
  },
  infoIcon: {
    marginRight: spacing[10],
  },
});
