import React from "react";
import { graphql, ChildDataProps } from "react-apollo";

import { ClaimProcessingDetails } from "./ClaimProcessingDetails";
import { GetCCGClaimResponse } from "./ClaimProcessingDetails.props";
import { GET_CCG_CLAIM } from "@/models/Claim";
import { ErrorMessage } from "../Error/Error";
import Loading from "../Loading";

interface Variables {
  id: string;
  retrieveDisplayId: (id: string) => void;
}

const builder = graphql<
  Variables,
  Response,
  Record<string, unknown>,
  ChildDataProps<Record<string, unknown>, GetCCGClaimResponse, Record<string, unknown>>
>(GET_CCG_CLAIM, {
  options: (props: Variables) => ({
    variables: {
      id: props.id,
    },
  }),
});

export default builder(({ data, ...props }) => {
  const { loading, error, getClaim } = data;
  if (error) {
    return <ErrorMessage apolloError={error} />;
  } else if (loading) {
    return <Loading />;
  } else if (getClaim) {
    const claim = getClaim;
    props.retrieveDisplayId(claim.displayId);
    return <ClaimProcessingDetails claim={claim} />;
  } else {
    return <ErrorMessage />;
  }
});
