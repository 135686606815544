enum RouteKeys {
  ApprovedClaimsScreen = "ApprovedClaimsScreen",
  ApprovedPaidClaimsScreen = "ApprovedPaidClaimsScreen",
  AppUpdateScreen = "AppUpdateScreen",
  AssessmentDetailsScreen = "AssessmentDetailsScreen",
  AssessmentScreen = "AssessmentScreen",
  Auth = "Auth",
  AuthSupportScreen = "Support",
  ChangePasswordScreen = "ChangePasswordScreen",
  ClaimDetailsScreen = "ClaimDetailsScreen",
  ClaimProcessingDetailsScreen = "ClaimProcessingDetailsScreen",
  ClaimProcessingSearchScreen = "ClaimProcessingSearchScreen",
  ClaimProcessScreen = "ClaimProcessScreen",
  ClaimsScreen = "ClaimsScreen",
  Confirmation = "Confirmation",
  CreateAssessmentScreen = "CreateAssessmentScreen",
  CreateClaimScreen = "CreateClaimScreen",
  CreditsScreen = "CreditsScreen",
  DoctorAvailabilityScreen = "DoctorAvailabilityScreen",
  DoctorProfileScreen = "DoctorProfileScreen",
  DoctorVisitDetailsScreen = "DoctorVisitDetailsScreen",
  DoctorVisitScreen = "DoctorVisitScreen",
  EditDoctorAvailabilityScreen = "EditDoctorAvailabilityScreen",
  EditDoctorProfileScreen = "EditDoctorProfileScreen",
  EulaScreen = "Eula",
  ForgotPasswordScreen = "ForgotPassword",
  HolidaysScreen = "HolidaysScreen",
  MapScreen = "MapScreen",
  MeetingRoomPreviewScreen = "MeetingRoomPreviewScreen",
  MeetingRoomScreen = "MeetingRoomScreen",
  MHAExternalFormScreen = "MHAExternalFormScreen",
  MHAFormScreen = "MHAFormScreen",
  MyClaimsScreen = "MyClaimsScreen",
  NewPasswordScreen = "NewPassword",
  PrivacyPolicyScreen = "PrivacyPolicyScreen",
  ProfileScreen = "ProfileScreen",
  RejectedClaimsScreen = "RejectedClaimsScreen",
  SearchResultsScreen = "SearchResultsScreen",
  SendToDoctorScreen = "SendToDoctorScreen",
  SignInScreen = "SignIn",
  SignUpScreen = "SignUp",
  Support = "Support",
  SupportExplainedScreen = "SupportExplainedScreen",
  SupportScreen = "SupportScreen",
  TeamClaimsScreen = "TeamClaimsScreen",
  TeamInboxFormViewerScreen = "TeamInboxFormViewerScreen",
  UnassignedClaimsScreen = "UnassignedClaimsScreen",
  UserDashboardScreen = "UserDashboardScreen",
  WelcomeScreen = "Welcome",
  TeamSelectScreen = "TeamSelectScreen",
}

export default RouteKeys;
