/* ==========================================================================
   #NAVIGATION TYPES
========================================================================== */

/**
 * This file describes the entire React Navigation 5 structure, and allows for
 * strong typing and autocomplete on routes and navigation objects.
 *
 * Throughout this file you will see the liberal use of the `undefined` type.
 * For React Navigation 5, this means that we are saying that the screen exists,
 * but we aren't providing and route params to it.
 *
 * @link https://reactnavigation.org/docs/typescript
 */

import { RouteProp, CompositeNavigationProp, ParamListBase } from "@react-navigation/native";
import { StackNavigationProp, StackNavigationOptions } from "@react-navigation/stack";
import { BottomTabNavigationProp } from "@react-navigation/bottom-tabs";

import { FormType } from "libs/types/mhaForms";

/**
 * @link https://github.com/react-navigation/react-navigation/issues/6931#issuecomment-643392469
 */
type NestedNavigatorParams<ParamList> =
  | {
      [K in keyof ParamList]: undefined extends ParamList[K]
        ? { screen: K; params?: ParamList[K] }
        : { screen: K; params: ParamList[K] };
    }[keyof ParamList]
  | undefined;

/**
 * Set basic typing for a simple screen. This would be at the root level of the
 * application, for example the Auth Navigator, or when more complex typing is
 * not necessary.
 */
export interface BaseScreenProps<ScreenRoutes extends ParamListBase, RouteName extends keyof ScreenRoutes> {
  navigation: StackNavigationProp<ScreenRoutes, RouteName>;
  route: RouteProp<ScreenRoutes, RouteName>;
}

/**
 * BaseScreenProps shortcut for Unauthenticated Navigation routes.
 */
export type AuthNavigationProps<RouteName extends keyof AppRoutes> = BaseScreenProps<AppRoutes, RouteName>;

export type RootNavigationProps<RouteName extends keyof RootNavigationRoutes> = BaseScreenProps<
  RootNavigationRoutes,
  RouteName
>;

/**
 * Typing for BottomTabNavigator screens. These screens should only be used for
 * rendering their children StackNavigators, and as such don't need access to
 * the `route` prop.
 *
 * This is made more complex by the fact that we have dynamic tabs based on
 * whether the authenticated user is an AMHP, CCG or Doctor.
 */
export interface TabNavigationProps<TabRoutes extends ParamListBase, RouteName extends keyof TabRoutes> {
  navigation: BottomTabNavigationProp<TabRoutes, RouteName>;
}

/**
 * This is a complex type. Sorry.
 *
 * As we have dynamic tabs based on whether you are
 * logged in as an AMHP, CCG or Doctor, we render the tab bar differently. As
 * such, to get the correct typing for a child screen, you need to pass the
 * correct Tab Routes, then the routes for the Stack Navigation and finally
 * the Route Name.
 *
 * React Navigation 5 will combine the Tab Navigator and the Stack Navigator
 * into a single type, allowing us to correctly type the params and the
 * `navigation` methods.
 */
export interface ScreenNavigationProps<
  TabRoutes extends ParamListBase,
  ScreenRoutes extends ParamListBase,
  RouteName extends Extract<keyof ScreenRoutes, string>
> {
  navigation: CompositeNavigationProp<
    StackNavigationProp<ScreenRoutes, RouteName>,
    BottomTabNavigationProp<TabRoutes, RouteName>
  >;
  route: RouteProp<ScreenRoutes, RouteName>;
}

export type RootNavigationRoutes = {
  ExternalDoctorNavigator: undefined;
  Eula?: { isAccepted: "true" | "false" };
  AuthNavigator: undefined;
  HomeNavigator: undefined;
};

export type AppRoutes = {
  Confirmation: undefined;
  ForgotPassword: { params: string };
  NewPassword: undefined;
  SignIn: undefined;
  SignUp: undefined;
  Support: undefined;
  Welcome: undefined;
};

/* User Type tab props
========================================================================== */
export type SharedTabRoutes = {
  SupportTab: NestedNavigatorParams<SupportRoutes>;
};

export type AMHPTabRoutes = SharedTabRoutes & {
  AMHPDashboardTab: NestedNavigatorParams<AMHPDashboardRoutes>;
  AMHPAssessmentsTab: NestedNavigatorParams<AMHPAssessmentsRoutes>;
};

export type CCGTabRoutes = SharedTabRoutes & {
  CCGApprovedClaimsTab: NestedNavigatorParams<CCGApprovedClaimsRoutes>;
  CCGApprovedPaidClaimsTab: NestedNavigatorParams<CCGApprovedPaidClaimsRoutes>;
  CCGMyClaimsTab: NestedNavigatorParams<CCGMyClaimsRoutes>;
  CCGRejectedClaimsTab: NestedNavigatorParams<CCGTeamClaimsRoutes>;
  CCGTeamClaimsTab: NestedNavigatorParams<CCGTeamClaimsRoutes>;
  CCGUnassignedClaimsTab: NestedNavigatorParams<CCGUnassignedClaimsRoutes>;
  CCGWebOnlyTab: NestedNavigatorParams<CCGWebOnlyRoutes>;
};

export type DoctorTabRoutes = SharedTabRoutes & {
  DoctorClaimsTab: NestedNavigatorParams<DoctorClaimsRoutes>;
  DoctorProfileTab: NestedNavigatorParams<DoctorProfileRoutes>;
  DoctorVisitsTab: NestedNavigatorParams<DoctorVisitsRoutes>;
};

/* AMHP User route props
========================================================================== */
export type AMHPAssessmentsRoutes = MapRoutes &
  MeetingRoutes &
  MHAFormRoutes & {
    AssessmentDetailsScreen: { assessmentId: string; notificationUpdate?: string };
    AssessmentScreen: undefined;
    CreateAssessmentScreen?: { assessmentId: string; editDoctor: boolean };
    CreateClaimScreen: { visitId: string };
    ClaimProcessScreen: undefined;
    ProfileScreen: { assessmentId: string; id: string };
    SearchResultsScreen: undefined;
    SendToDoctorScreen: { assessmentId: string };
  };

export type AMHPDashboardRoutes = {
  UserDashboardScreen: undefined;
  TeamInboxFormViewerScreen: { formId: string; formType: FormType };
};

export type AllAMHPRoutes = AMHPAssessmentsRoutes & AMHPDashboardRoutes & AMHPTabRoutes;

/* CCG User route props
========================================================================== */
export type CCGApprovedClaimsRoutes = {
  ApprovedClaimsScreen: { organisationId?: string; displayId: string };
  ClaimProcessingDetailsScreen: { claimId: string };
  ClaimProcessingSearchScreen: { displayId: string };
};

export type CCGApprovedPaidClaimsRoutes = {
  ApprovedPaidClaimsScreen: { organisationId?: string; displayId: string };
  ClaimProcessingDetailsScreen: { claimId: string };
  ClaimProcessingSearchScreen: { displayId: string };
};

export type CCGMyClaimsRoutes = {
  MyClaimsScreen: { organisationId?: string; displayId: string };
  ClaimProcessingDetailsScreen: { claimId: string };
  ClaimProcessingSearchScreen: { displayId: string };
};

export type CCGRejectedClaimsRoutes = {
  RejectedClaimsScreen: { organisationId?: string; displayId: string };
  ClaimProcessingDetailsScreen: { claimId: string };
  ClaimProcessingSearchScreen: { displayId: string };
};

export type CCGTeamClaimsRoutes = {
  TeamClaimsScreen: { organisationId?: string; displayId: string };
  ClaimProcessingDetailsScreen: { claimId: string };
  ClaimProcessingSearchScreen: { displayId: string };
};

export type CCGUnassignedClaimsRoutes = {
  UnassignedClaimsScreen: { organisationId?: string; displayId: string };
  ClaimProcessingDetailsScreen: { claimId: string };
  ClaimProcessingSearchScreen: { displayId: string };
};

export type CCGWebOnlyRoutes = {
  WebOnlyClaimsScreen: { organisationId?: string; displayId: string };
};

export type AllCCGRoutes = CCGApprovedClaimsRoutes &
  CCGApprovedPaidClaimsRoutes &
  CCGMyClaimsRoutes &
  CCGRejectedClaimsRoutes &
  CCGTeamClaimsRoutes &
  CCGUnassignedClaimsRoutes &
  CCGWebOnlyRoutes &
  CCGTabRoutes;

/* Doctor User route props
========================================================================== */
export type DoctorClaimsRoutes = MHAFormRoutes & {
  ClaimsScreen: undefined;
  ClaimDetailsScreen: { claimId: string };
  DoctorVisitDetailsScreen: undefined;
};

export type DoctorProfileRoutes = MHAFormRoutes & {
  DoctorAvailabilityScreen: undefined;
  DoctorProfileScreen: undefined;
  DoctorVisitDetailsScreen: { visitId: string };
  EditDoctorAvailabilityScreen: undefined;
  EditDoctorProfileScreen: undefined;
  HolidaysScreen: undefined;
  TeamSelectScreen: undefined;
};

export type DoctorVisitsRoutes = MapRoutes &
  MeetingRoutes &
  MHAFormRoutes & {
    DoctorVisitDetailsScreen: { visitId: string; notificationUpdate: string };
    DoctorVisitScreen: undefined;
    ProfileScreen: {
      id: string;
      assessmentId?: string;
      assessmentLocationLat?: number;
      assessmentLocationLon?: number;
      assessmentDistance: number;
      assessmentStartTime?: string; // create a string for url params
      assessmentEndTime?: string; // create a string for url params
      addVisit?: boolean;
    };
  };

export type AllDoctorRoutes = DoctorTabRoutes & DoctorClaimsRoutes & DoctorVisitsRoutes & SupportRoutes;

/* External Doctor Routes
========================================================================== */
export type ExternalDoctorFormRoutes = {
  MHAExternalFormScreen: { formId: string; pw?: string };
};

/* Shared Routes
========================================================================== */
export type SupportRoutes = {
  AppUpdateScreen: undefined;
  ChangePasswordScreen: undefined;
  ClaimProcessScreen: undefined;
  CreditsScreen: undefined;
  Eula: undefined;
  PrivacyPolicyScreen: undefined;
  SupportExplainedScreen: undefined;
  SupportScreen: undefined;
};

export type MHAFormRoutes = {
  MHAFormScreen: { formType: FormType; assessmentId?: string; formId?: string };
};
export type TeamSelectRoutes = {
  TeamSelectScreen: { formType: FormType; assessmentId?: string; formId?: string };
};

export type MapRoutes = {
  MapScreen: undefined;
};

export type MeetingRoutes = {
  MeetingRoomPreviewScreen: { assessmentId: string };
  MeetingRoomScreen: { assessmentId: string };
};

export type HomeRoutes = AMHPTabRoutes & CCGTabRoutes & DoctorTabRoutes;

/* Other
========================================================================== */

/**
 * Set the screen options type for each screen. This will correctly type either
 * the object or the function form of the screen options parameter.
 *
 * @param PropTypes Optional parameter that will be provided to the functional
 *   version of the ScreenOptions. This will allow access to route params and
 *   the navigation.
 * @link https://reactnavigation.org/docs/screen-options/#screenoptions-prop-on-the-navigator
 */
export type ScreenOptions<PropTypes = unknown> =
  | ((props: PropTypes) => StackNavigationOptions)
  | StackNavigationOptions;

export type StackSetName = "AMHP" | "CCG" | "Doctor" | "External";

export enum CognitoUserGroupName {
  "AMHP" = "AMHP",
  "Doctor" = "Doctor",
  "OrgClaims" = "OrgClaims",
  "ExternalDoctor" = "ExternalDoctor",
}

export const CognitoUserGroups: { [key: string]: StackSetName | undefined } = {
  AMHP: "AMHP",
  Doctor: "Doctor",
  OrgClaims: "CCG",
  ExternalDoctor: "External",
};
