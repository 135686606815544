import React from "react";
import { View, StyleSheet } from "react-native";
import Text from "../../../Text";
import { TypographyType } from "@/models/Typography";
import { spacing } from "../../../../theme";

export function MHAFormRecordHeading(props: { formName: string; formTitle?: string }) {
  const { formName, formTitle } = props;
  const [formSection, formType] = formName.split("@");

  return (
    <View style={styles.recordHeading}>
      <View style={styles.heading}>
        <View style={styles.formName}>
          <Text format={TypographyType.SmallBold}>{formSection}</Text>
          {formType && (
            <Text format={TypographyType.MicroBold} style={styles.smallHeading}>
              ({formType})
            </Text>
          )}
        </View>
      </View>
      {formTitle && <Text format={TypographyType.SmallBold}>{formTitle}</Text>}
    </View>
  );
}

const styles = StyleSheet.create({
  recordHeading: { marginBottom: spacing[15] },
  heading: { flexDirection: "row", alignItems: "center", marginBottom: spacing[5] },
  formName: { flex: 1, flexDirection: "row", alignItems: "flex-end" },
  smallHeading: { paddingBottom: 2, paddingLeft: spacing[5] },
});
