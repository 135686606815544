import * as React from "react";
import { Query } from "react-apollo";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { ClaimForDoctorResponse, CLAIM_QUERY_FOR_DOCTOR } from "@/models/Claim";

import { ClaimDetails } from "./ClaimDetails";
import { ErrorMessage } from "../Error/Error";
import Loading from "../Loading";

const QUERY_GET_MHTS = gql`
  query {
    listMHTs(limit: 500) {
      items {
        id
        abbreviation
        organisation {
          id
        }
      }
    }
  }
`;

interface PropTypes {
  id: string;
  retrievePatientName: (val: string) => void;
}

export default function ClaimDetailsGQL(props: PropTypes) {
  const [mhts, setMhts] = React.useState<{ id: string; abbreviation: string }[]>([]);
  const { loading: queryLoading, data } = useQuery(QUERY_GET_MHTS);

  React.useEffect(() => {
    if (!queryLoading) {
      const mhtList = data?.listMHTs?.items.map((item: any) => {
        return { id: item.id, abbreviation: item.abbreviation };
      });
      setMhts(mhtList);
    }
  }, [queryLoading]);

  return (
    <Query<ClaimForDoctorResponse> query={CLAIM_QUERY_FOR_DOCTOR} variables={{ id: props.id }}>
      {({ error, data, loading }) => {
        if (error) {
          return <ErrorMessage apolloError={error} />;
        } else if (loading) {
          return <Loading />;
        } else if (data && data.getClaim) {
          const claim = data.getClaim;
          props.retrievePatientName(claim.visit.assessment.patientName);
          return <ClaimDetails claim={claim} mhts={mhts} />;
        } else {
          return <Loading />;
        }
      }}
    </Query>
  );
}
