import { FormStatus } from "libs/types/API";
import { ValidFormStatusTransitionsObject } from "libs/types/mhaForms";

export const validFormStatusTransitions: ValidFormStatusTransitionsObject = {
  AMHPApplication: {
    // Form has this status when an AMHP first creates it
    // Once the AMHP form has been signed, it never goes back to this status
    [FormStatus.s10_draft]: {
      [FormStatus.s10_draft]: ["AMHP"], // AMHP can save (update) the form
      [FormStatus.s40_signed]: ["AMHP"], // AMHP can sign the form
    },

    // Form has this status because the AMHP has removed their signature, to make edits
    [FormStatus.s30_changes_in_progress]: {
      [FormStatus.s30_changes_in_progress]: ["AMHP"], // AMHP can save (update) the form
      [FormStatus.s40_signed]: ["AMHP"], // AMHP can re-sign the form
    },

    // All AMHP forms are set to this status, whenever they are signed by the AMHP
    [FormStatus.s40_signed]: {
      [FormStatus.s30_changes_in_progress]: ["AMHP"], // AMHP can remove their signature to make edits
      [FormStatus.s50_signed_and_sent]: ["AMHP"], // AMHP can download or email the signed form
    },

    // AMHP Application has this status because an AMHP has clicked a button to email or download the signed form
    [FormStatus.s50_signed_and_sent]: {
      [FormStatus.s30_changes_in_progress]: ["AMHP"], // AMHP can remove their singature to make edits
    },
  },

  UnattachedSingleMedRec: {
    // Form has this status when a Doctor first creates it
    // Once the Doctor signs and sends the form to an AMHP team, it never goes back to this status
    [FormStatus.s10_draft]: {
      [FormStatus.s10_draft]: ["Doctor"], // Doctor can save (update) the form
      [FormStatus.s60_signed_in_amhp_team_inbox]: ["Doctor"], // Doctor can sign the form and send to AMHP Team inbox
    },

    // Form is in this status when a doctor signs and sends an unattached form to an AMHP Team
    [FormStatus.s60_signed_in_amhp_team_inbox]: {
      [FormStatus.s60_signed_in_amhp_team_inbox]: ["Doctor"], // Doctor can reassign teams without changing the status
      [FormStatus.s40_signed]: ["AMHP"], // AMHP can attach the form to an assessment
    },
  },

  SingleMedRec: {
    // Form has this status when a Doctor first creates it
    // Once the Doctor signs the form, it never goes back to this status
    [FormStatus.s10_draft]: {
      [FormStatus.s10_draft]: ["Doctor"], // Doctor can save (update) the form
      [FormStatus.s40_signed]: ["Doctor"], // Doctor can sign the form
    },

    // Form has this status because the Doctor has removed their signature, to make edits
    [FormStatus.s30_changes_in_progress]: {
      [FormStatus.s30_changes_in_progress]: ["Doctor"], // Doctor can save (update) the form
      [FormStatus.s40_signed]: ["Doctor"], // Doctor can re-sign the form
    },

    // All Single Med Rec forms are set to this status, whenever they are signed by the Doctor
    [FormStatus.s40_signed]: {
      [FormStatus.s30_changes_in_progress]: ["Doctor"], // Doctor can remove their signature to make edits
      [FormStatus.s50_signed_and_sent]: ["AMHP"], // AMHP can download or email the signed form
    },

    // Single Med Rec has this status because an AMHP has clicked a button to email or download the signed form
    [FormStatus.s50_signed_and_sent]: {
      [FormStatus.s30_changes_in_progress]: ["Doctor"], // Doctor can remove their signature to make edits
    },
  },

  JointMedRec: {
    // Form has this status when a Doctor first creates it
    // Once the Doctor signs the form, it never goes back to this status
    [FormStatus.s10_draft]: {
      [FormStatus.s10_draft]: ["PrimaryDoctorInJointForm"], // Doctor can save (update) the form
      [FormStatus.s20_awaiting_other_doctor]: ["PrimaryDoctorInJointForm"], // Primary Doctor can sign the form
    },

    // Form has this status because the Primary Doctor has signed the form, but the Second Doctor has not
    [FormStatus.s20_awaiting_other_doctor]: {
      [FormStatus.s30_changes_in_progress]: ["PrimaryDoctorInJointForm"], // Primary Doctor can remove form signatures, to make AMHP requested edits
      [FormStatus.s40_signed]: ["SecondDoctorInJointForm"], // Second Doctor can add their signature to the form
    },

    // Form has this status because the Primary Doctor has removed the form signatures, to make AMHP requested edits
    [FormStatus.s30_changes_in_progress]: {
      [FormStatus.s30_changes_in_progress]: ["PrimaryDoctorInJointForm"], // Doctor can save (update) the form
      [FormStatus.s20_awaiting_other_doctor]: ["PrimaryDoctorInJointForm"], // Primary Doctor can re-sign the form
    },

    // All Joint Med Rec forms are set to this status, whenever they have been signed by both Doctors
    [FormStatus.s40_signed]: {
      [FormStatus.s30_changes_in_progress]: ["PrimaryDoctorInJointForm"], // Primary Doctor can remove form signatures, to make AMHP requested edits
      [FormStatus.s50_signed_and_sent]: ["AMHP"], // AMHP can download or email the signed form
    },

    [FormStatus.s50_signed_and_sent]: {
      [FormStatus.s30_changes_in_progress]: ["PrimaryDoctorInJointForm"], // Primary Doctor can remove form signatures, to make AMHP requested edits
    },
  },
};
