/* eslint-disable react-native/no-inline-styles */
import React, { useEffect, useState, useMemo } from "react";
import { Linking, Platform, StyleSheet, View } from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scrollview";
import { FAB, FABGroupAction, Portal } from "react-native-paper";
import { useRecoilValue, useSetRecoilState } from "recoil";
import dayjs, { Dayjs } from "dayjs";
import { useNavigation } from "@react-navigation/native";

import { LocationInput } from "libs/types/API";
import { AnalyticsEvent } from "libs/analytics/events";
import { eventToCalendar, availabilitiesToNextAvailable } from "libs/dates";
import { Availability, AvailabilityType, CalendarItem } from "libs/types/availability";

import { DoctorProfile as DP } from "@/models/DoctorProfile";
import { GET_ASSESSMENT } from "@/models/Assessment";
import { getAvailabilityState } from "@/models/Availability";
import { addVisitError, buildErr, removeVisitError } from "@/models/Error";
import { TypographyType } from "@/models/Typography";

import {
  userDetails,
  searchCriteria as recoilSearchCriteria,
  lastException,
  mapCriteria,
  snackbarMessage,
} from "@/utils/recoil/index";
import { mqWeb, setStartEndVisitTimes, callbackWithThenable } from "@/utils/helpers";
import { recordEvent } from "@/utils/analytics";

import { RouteKeys } from "@/navigationv2";
import { useBottomSheet } from "@/hooks";
import { color, spacing } from "@/theme";

import { AppScreen } from "@/layouts/AppScreen/AppScreen";
import { CreateContactedDoctorsFunc } from "@/components/SearchResults/SearchResults.props";
import { BackButtonProps } from "@/components/BackButton/BackButton.props";
import { filterAndConvertAvailabilityDataToDisplayStructure } from "@/components/SearchResults/SearchResultFilters";
import AvailabilityDot from "@/components/AvailabilityDot";
import { Calendar } from "@/components/Calendar";
import { ContentWrap } from "@/components/ContentWrap";
import { SectionDivider } from "@/components/SectionDivider/SectionDivider";
import Text from "@/components/Text";

import { AvailabilityText } from "./AvailabilityText";
import { Bio } from "./Bio";
import { ConfirmVisitBottomSheet } from "./BottomSheets";
import { Contact } from "./Contact";
import { AssessmentWithDoctors, DoctorProfile as ProfileProps } from "./DoctorProfile.props";
import notFalsy from "libs/utils/notFalsy";
import { AllAMHPRoutes } from "@/navigationv2/types";
import { StackNavigationProp } from "@react-navigation/stack";
import Loading from "@/components/Loading";

// TODO: This is copied from doctor setup, refactor
const availabilityToCalendar = (a: Availability): CalendarItem => {
  return {
    id: a.id,
    type: a.type || AvailabilityType.independent,
    distance: 50, // TODO: distance calculations needed from somewhere based on search
    location: a.location,
    locationName: a.locationName,
    rrule: a.rrule,
    mht: a.mht,
    oncall: a.oncall,
    rotaId: a.rotaId,
    rotaName: a.rotaName,
    endHour: a.endHour,
    notes: a.notes,
    contractShortCode: a.contractList ? a.contractList.name : undefined,
  };
};

export { Loading as DoctorProfileLoading } from "./Loading";

type KeyboardAwareScrollView2 = typeof KeyboardAwareScrollView & {
  current: {
    scrollToEnd: () => void;
  };
};

type PropTypes = ProfileProps & {
  assessment?: AssessmentWithDoctors;
  addContactedDoctor?: CreateContactedDoctorsFunc;
  assessmentLocation?: LocationInput;
  assessmentTimeSpan?: { start: Dayjs; end: Dayjs };
  addDoctorLoading?: boolean;
  addVisit?: boolean;
};

export const DoctorProfile = (props: PropTypes) => {
  // "Private" state and refs
  const [fabOpen, setFabOpen] = useState(false);
  const [loadingAssessments, setLoadingAssessments] = useState(false);
  const [showConfirmVisit, setShowConfirmVisit] = useState(false);
  const [assessmentDate, setAssessmentDate] = useState<Dayjs>(dayjs);
  const [assessmentTime, setAssessmentTime] = useState<{
    hours: number;
    minutes: number;
  }>({ hours: new Date().getHours() + 1, minutes: 0 });
  const [visitNotes, setVisitNotes] = useState<string>("");
  const [scrollRef, setScrollRef] = useState<KeyboardAwareScrollView2 | null>(null);
  const navigation = useNavigation<StackNavigationProp<AllAMHPRoutes>>();

  // "Global" state and hooks
  const user = useRecoilValue(userDetails);
  const searchCriteria = useRecoilValue(recoilSearchCriteria);
  const setMessage = useSetRecoilState(snackbarMessage);
  const setMapCriteria = useSetRecoilState(mapCriteria);
  const setLastException = useSetRecoilState(lastException);
  const isWebView = mqWeb();
  const { openBottomSheet } = useBottomSheet();

  // Callbacks
  const confirmVisit = () => {
    const assesmentDate = dayjs(assessmentDate);
    const assessmentDateTime = assesmentDate.hour(assessmentTime.hours).minute(assessmentTime.minutes);
    const assessmentDateTimeString = assessmentDateTime.format();

    if (addDoctorToAssessment) {
      setLoadingAssessments(true);
      addDoctorToAssessment(
        assessmentDateTimeString,
        visitNotes ? [visitNotes] : undefined,
        availabilitiesToNextAvailable(
          scheduleItems,
          holidayAsCalendar,
          visitsAsCalendar,
          assessmentDateTime,
          assessmentDateTime
        )
          .filter(a => a.availability && a.availability.type)
          .map(a => a.availability)
      )
        .then(() => {
          if (refresh) {
            refresh();
          }
          setMessage(`${doctor.name} added`);
        })
        .catch(buildErr({ ...addVisitError, additional: doctor.id }, setLastException))
        .then(() => {
          setShowConfirmVisit(false);
          if (assessment) {
            navigation.navigate("AssessmentScreen");
            navigation.navigate("AssessmentDetailsScreen", {
              assessmentId: assessment.id,
            });
            setLoadingAssessments(false);
          }
        });
    }
  };

  // "Private" hooks
  useEffect(() => {
    if (props.assessment) {
      setAssessmentDate(dayjs(props.assessment.assessmentDate));
    }
  }, [props.assessment]);

  useEffect(() => {
    if (props.addVisit) setShowConfirmVisit(true);
    return () => setFabOpen(false);
  }, [props.addVisit]);

  /* -> Confirm visit bottom sheet handler <- */
  useEffect(() => {
    if (showConfirmVisit) {
      openBottomSheet({
        type: "generic",
        data: {
          heading: "Confirm Doctor",
          component: ConfirmVisitBottomSheet,
          componentProps: {
            assessmentDate,
            assessmentTime,
            setAssessmentDate,
            setAssessmentTime,
            setVisitNotes,
          },
          onDismiss: () => setShowConfirmVisit(false),
          confirmButton: {
            label: "Add to Assessment",
            onPress: confirmVisit,
            disabled: addDoctorLoading,
          },
        },
      });
    }
  }, [showConfirmVisit, assessmentTime, assessmentDate, visitNotes]);

  if (!props.doctor) {
    return <View />;
  }

  const { id, availabilities = [], location, name, phone } = props.doctor;

  const {
    addContactedDoctor,
    addDoctorToAssessment,
    addDoctorLoading,
    assessmentLocation,
    assessmentDistance,
    removeDoctorFromAssessment,
    assessment,
    doctor,
    assessmentTimeSpan,
    refresh,
  } = props;

  const Mod = {
    AvailabilityText,
    Bio,
    Contact,
  };

  const doctorName = `${name}`;

  const { nextAvailability, distance, onHoliday, booked } = filterAndConvertAvailabilityDataToDisplayStructure<DP>({
    distance: assessmentDistance || 9999,
    location: assessmentLocation || (assessment ? assessment.location : { lat: 0, lon: 0 }),
    timeSpan: assessmentTimeSpan || { start: dayjs(), end: dayjs() },
  })(props.doctor);

  const backButtonConfig: BackButtonProps = {
    enabled: true,
    float: true,
    color: color.textWhite,
  };

  const isInAssessment =
    doctor &&
    (assessment?.doctorVisits?.items?.filter(notFalsy).filter(i => doctor && i.doctor.id === doctor.id).length || 0) >
      0;

  const availabilityState = getAvailabilityState(
    !!isInAssessment,
    !!nextAvailability,
    nextAvailability,
    doctor.mhtEmployers,
    availabilities,
    onHoliday,
    booked
  );

  const scheduleItems = useMemo(() => availabilities.map(availabilityToCalendar), [availabilities]);

  const holidayAsCalendar = useMemo(() => (doctor.holidays ? eventToCalendar(doctor.holidays || []) : []), [
    doctor.holidays,
  ]);

  const visitsAsCalendar = useMemo(
    () =>
      doctor.visits
        ? eventToCalendar(
            doctor.visits.map(i => ({
              id: i.id,
              visible: true,
              isBooking: true,
              partialPostcode: i.partialPostcode,
              ...setStartEndVisitTimes(i.time),
            }))
          )
        : [],
    [doctor.visits]
  );

  const isClaimCreated =
    isInAssessment &&
    (assessment?.doctorVisits?.items
      ?.filter(notFalsy)
      .filter(i => doctor && i.doctor.id === doctor.id && i.doctorVisitClaimId).length || 0) > 0;

  const isAuthorOfForm = React.useMemo(
    () =>
      ([] as string[])
        .concat(...(assessment?.forms?.items?.filter(notFalsy).map(af => af.authors) || []))
        .includes(doctor.id),
    [assessment?.forms]
  );

  const passRef = (ref: KeyboardAwareScrollView2) => setScrollRef(ref);

  const mobileHeaderContent = () => (
    <>
      {!!assessment && (
        <View style={styles.availability}>
          <AvailabilityDot status={availabilityState} style={styles.availabilityDot} />
          <Mod.AvailabilityText
            type={availabilityState}
            style={isWebView ? styles.availabilityTextWeb : styles.availabilityText}
          />
        </View>
      )}
      {assessmentLocation && location && (
        <View style={styles.distance}>
          <Text format={TypographyType.TinyBold} style={isWebView ? styles.navyText : styles.whiteText}>
            {distance.toFixed(1)} mile
            {(distance && distance > 1) || (distance && distance < 1) ? "s" : undefined}
            &nbsp;
          </Text>
          <Text format={TypographyType.Tiny} style={isWebView ? styles.navyText : styles.whiteText}>
            from assessment location
          </Text>
        </View>
      )}
    </>
  );

  const updateMapCriteria = callbackWithThenable(
    () => {
      const params = {
        locationLat: (assessmentLocation && assessmentLocation.lat) || 0,
        locationLon: (assessmentLocation && assessmentLocation.lon) || 0,
        markerLat: (location && location.lat) || 0,
        markerLon: (location && location.lon) || 0,
        type: availabilityState,
        single: true,
        assessmentId: (assessment && assessment.id) || "",
      };
      setMapCriteria(params);
    },
    [props, assessmentLocation, location, availabilityState, assessment],
    () => (navigation as any).push(RouteKeys.MapScreen)
  );

  return (
    <AppScreen
      contextLabel="Doctor Profile"
      pageTitle={doctorName}
      backButton={backButtonConfig}
      passRef={passRef}
      mobileHeaderContent={mobileHeaderContent}
    >
      {loadingAssessments && <Loading />}
      {isWebView && (
        <>
          <ContentWrap>
            <Text format={TypographyType.HeadingMedium}>Status</Text>
            {mobileHeaderContent()}
          </ContentWrap>
          <SectionDivider compressed={true} />
        </>
      )}
      <Mod.Contact
        {...props.doctor}
        isAvailable={!!nextAvailability}
        availabilityState={availabilityState}
        addContactedDoctor={addContactedDoctor}
        assessmentId={assessment && assessment.id}
        doctorId={props.doctor.id}
        name={props.doctor.name}
      />
      <SectionDivider compressed={isWebView} />
      <Mod.Bio {...props.doctor} />
      <SectionDivider compressed={isWebView} />
      <ContentWrap>
        <Text format={TypographyType.HeadingMedium}>Diary</Text>
      </ContentWrap>
      <ContentWrap onlyWrap="web">
        <MemoCalendar
          holidays={holidayAsCalendar}
          visits={visitsAsCalendar}
          scheduleItems={scheduleItems}
          startDate={assessmentTimeSpan && assessmentTimeSpan.start}
        />
      </ContentWrap>

      <Portal>
        <FAB.Group
          open={fabOpen}
          icon={fabOpen ? "close" : "more-vert"}
          fabStyle={styles.fabStyles}
          color={color.text}
          visible={!showConfirmVisit}
          actions={
            // web has click issues when fab is closed still receiving events
            Platform.OS === "web" && !fabOpen
              ? []
              : ([
                  !isInAssessment
                    ? {
                        icon: "person-add",
                        label: "Confirm Doctor",
                        color: color.text,
                        style: styles.yellowMiniFab,
                        onPress: () => {
                          setShowConfirmVisit(true);
                        },
                      }
                    : isClaimCreated || isAuthorOfForm
                    ? {
                        icon: "remove-circle",
                        label: isClaimCreated ? "Claim exists" : "Form exists",
                        color: color.textError,
                        style: styles.yellowMiniFab,
                        onPress: () => undefined,
                      }
                    : {
                        icon: "remove-circle",
                        label: "Remove Assessment Visit",
                        color: color.text,
                        style: styles.yellowMiniFab,
                        onPress: () => {
                          if (removeDoctorFromAssessment) {
                            removeDoctorFromAssessment()
                              .then(() => refresh && refresh().then(() => setMessage("Removed")))
                              .catch(buildErr(removeVisitError, setLastException));
                          }
                        },
                      },
                  {
                    icon: "playlist-add-check",
                    label: "Mark Contacted",
                    color: color.background,
                    style: styles.greenMiniFab,
                    onPress: () => {
                      if (assessment && addContactedDoctor) {
                        addContactedDoctor({
                          variables: {
                            input: {
                              assessmentId: assessment.id,
                              contactedDoctorS12DoctorId: id,
                              createdAt: new Date().toISOString(),
                            },
                          },
                          refetchQueries: [
                            {
                              query: GET_ASSESSMENT,
                              variables: { id: assessment.id },
                            },
                          ],
                        });
                        recordEvent(
                          AnalyticsEvent.DOCTORS_CONTACTED,
                          {
                            doctorId: id,
                            ...(doctor.email && {
                              doctorEmail: doctor.email,
                            }),
                            doctorName: doctor.name,
                            ...(user && {
                              amhpId: user.id,
                              amhpName: user.name,
                              amhpEmail: user.email,
                            }),
                            doctorAvailability: availabilityState,
                            assessmentId: assessment.id,
                            method: "phone",
                          },
                          {
                            "Doctors Contacted": 1,
                          }
                        );
                      }
                    },
                  },
                  Platform.OS !== "web" &&
                    !!phone && {
                      icon: "phone",
                      label: "Call Now",
                      color: color.background,
                      style: styles.greenMiniFab,
                      onPress: () => {
                        if (assessment && addContactedDoctor) {
                          addContactedDoctor({
                            variables: {
                              input: {
                                assessmentId: assessment.id,
                                contactedDoctorS12DoctorId: id,
                                createdAt: new Date().toISOString(),
                              },
                            },
                            refetchQueries: [
                              {
                                query: GET_ASSESSMENT,
                                variables: { id: assessment.id },
                              },
                            ],
                          });
                          recordEvent(
                            AnalyticsEvent.DOCTORS_CONTACTED,
                            {
                              doctorId: id,
                              ...(doctor.email && {
                                doctorEmail: doctor.email,
                              }),
                              doctorName: doctor.name,
                              ...(user && {
                                amhpId: user.id,
                                amhpName: user.name,
                                amhpEmail: user.email,
                              }),
                              doctorAvailability: availabilityState,
                              assessmentId: assessment.id,
                              method: "phone",
                            },
                            {
                              "Doctors Contacted": 1,
                            }
                          );
                        }
                        Linking.openURL(`${Platform.OS === "ios" ? "telprompt:" : "tel:"}${phone}`).catch(() =>
                          setFabOpen(false)
                        );
                      },
                    },
                  Platform.OS !== "web" &&
                    !!phone && {
                      icon: "message",
                      label: "Send SMS",
                      color: color.background,
                      style: styles.blueMiniFab,
                      onPress: () => {
                        if (assessment && addContactedDoctor) {
                          addContactedDoctor({
                            variables: {
                              input: {
                                assessmentId: assessment.id,
                                contactedDoctorS12DoctorId: id,
                                createdAt: new Date().toISOString(),
                              },
                            },
                            refetchQueries: [
                              {
                                query: GET_ASSESSMENT,
                                variables: { id: assessment.id },
                              },
                            ],
                          });
                          recordEvent(
                            AnalyticsEvent.DOCTORS_CONTACTED,
                            {
                              doctorId: id,
                              ...(doctor.email && {
                                doctorEmail: doctor.email,
                              }),
                              doctorName: doctor.name,
                              ...(user && {
                                amhpId: user.id,
                                amhpName: user.name,
                                amhpEmail: user.email,
                              }),
                              doctorAvailability: availabilityState,
                              assessmentId: assessment.id,
                              method: "sms",
                            },
                            {
                              "Doctors Contacted": 1,
                            }
                          );
                        }

                        Linking.openURL("sms:" + phone).catch(() => setFabOpen(false));
                      },
                    },
                  {
                    icon: "today",
                    label: "View Diary",
                    color: color.background,
                    style: styles.blueMiniFab,
                    onPress: () => {
                      scrollRef && scrollRef.current && scrollRef.current.scrollToEnd();
                    },
                  },
                  ...(Platform.OS === "web"
                    ? [
                        {
                          icon: "person-pin-circle",
                          label: "View Location",
                          color: color.background,
                          style: styles.blueMiniFab,
                          onPress: () => updateMapCriteria(),
                        },
                      ]
                    : []),
                ].filter(i => i) as FABGroupAction[])
          }
          onStateChange={({ open }) => setFabOpen(open)}
        />
      </Portal>
    </AppScreen>
  );
};

const MemoCalendar = React.memo(Calendar);

const styles = StyleSheet.create({
  availability: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },

  availabilityDot: {
    marginRight: spacing[10],
  },

  availabilityText: {
    color: color.background,
  },
  availabilityTextWeb: {
    color: color.text,
  },

  distance: {
    marginTop: spacing[5],
    paddingLeft: 34,
    flexDirection: "row",
    justifyContent: "flex-start",
  },

  fabStyles: {
    backgroundColor: color.doctor,
  },
  yellowMiniFab: { backgroundColor: color.doctor },
  greenMiniFab: { backgroundColor: color.secondary },
  blueMiniFab: { backgroundColor: color.primary },

  textSeparator: {
    width: 4,
    height: 4,
    marginLeft: spacing[10],
    marginRight: spacing[10],
    borderRadius: 10,
    backgroundColor: color.primaryBorder,
    transform: [{ translateY: 2 }],
  },

  whiteText: { color: color.background },
  navyText: { color: color.text },

  modalInner: {
    flexBasis: "auto",
    flex: 0,
    flexShrink: 1,
  },
});
