import React from "react";
import { View, StyleSheet } from "react-native";
import Text from "../Text";
import { TypographyType } from "@/models/Typography";
import { LocationSearchItem } from "./LocationSearch.props";
import { spacing, color } from "../../theme";

export const LocationSuggestion = (props: { suggestion: LocationSearchItem }) => {
  const { suggestion } = props;
  return (
    <View style={[styles.resultRow, styles.rowBorder]}>
      <Text format={TypographyType.Regular} type="paper" numberOfLines={2} style={styles.locationText}>
        {suggestion.itemText
          ? suggestion.itemText.replace(/,/g, ", ")
          : suggestion.locationName && suggestion.locationName.address
          ? [
              suggestion.locationName.address.replace(/,/g, ", "),
              suggestion.locationName.city,
              suggestion.locationName.postcode,
            ].join(", ")
          : ""}
      </Text>
      {suggestion.isExpandable && (suggestion.expandsTo || 0) > 0 && (
        <Text format={TypographyType.SmallBold} type="paper">
          {`+${suggestion.expandsTo} options`}
        </Text>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  resultRow: {
    flex: 1,
    flexDirection: "column",
    alignItems: "baseline",
    justifyContent: "space-between",
    padding: spacing[5],
    flexWrap: "wrap",
    width: "100%",
  },
  rowBorder: {
    borderBottomWidth: 1,
    borderBottomColor: color.thinBorder,
  },
  locationText: {
    marginRight: spacing[5],
    overflow: "hidden",
  },
});
