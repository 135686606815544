import React from "react";
import { StyleProp, View, ViewStyle } from "react-native";
import Svg, { Path } from "react-native-svg";

const Wave1 = ({ width, style }: { width: number; style?: StyleProp<ViewStyle> }) => (
  <View style={[style, { width }]}>
    <Svg width={"100%"} height={"100%"} viewBox="0 0 1280 98" fill="none">
      <Path
        opacity="0.15"
        d="M1280 79C1044.03 90.1818 992.36 121.098 668.498 64.9505C391.511 16.9294 70.8448 58.2889 2.14688e-06 73.0395L8.5322e-06 -2.28882e-05L1280 8.9013e-05L1280 79Z"
        fill="#1065D0"
      />
    </Svg>
  </View>
);

export default Wave1;
