import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { StyleSheet, View, Keyboard } from "react-native";
import { FormikProps } from "formik";
import API from "@aws-amplify/api";
import APIV2 from "@/api";
import { useSetRecoilState } from "recoil";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";

import { AnalyticsEvent } from "libs/analytics/events";
import { amhpTeamUsesJointMedRecs } from "libs/utils/featureFlags";
import { isJointForm } from "libs/mhaForms/helpers";
import { isValidEmail, isValidPhone } from "libs/validators/index";
import { FormStatus } from "libs/types/API";

import { GetAssessmentResponse } from "@/models/Assessment";
import { buildErr, sendToDoctorFormError } from "@/models/Error";
import { TypographyType } from "@/models/Typography";
import { recordEvent } from "@/utils/analytics";
import { lastException } from "@/utils/recoil";
import { useAPIVersion, useDialog, useIsWebView } from "@/hooks";

import { ContentWrap } from "@/components/ContentWrap";
import { AllAMHPRoutes } from "@/navigationv2/types";
import ItemSpacer from "@/components/ItemSpacer";
import Text from "@/components/Text";

import SendToDoctorForm, { SendToDoctorFormValues } from "./SendToDoctorForm";
import { palette, spacing } from "@/theme";
import { API_V2_TOGGLES } from "@/api/types";

interface PropTypes extends GetAssessmentResponse {}

const SendToDoctor = (props: PropTypes) => {
  const { assessment } = props;
  const navigation = useNavigation<StackNavigationProp<AllAMHPRoutes>>();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const setLastException = useSetRecoilState(lastException);
  const formRef: React.Ref<FormikProps<SendToDoctorFormValues>> = useRef(null);
  const isWebView = useIsWebView();
  const { v2 } = useAPIVersion(API_V2_TOGGLES.MHA_FORMS);

  /**
   * We only want the AMHP to be able to send joint forms to external doctors, if:
   *  - there is only one author (i.e. joint form is not being worked on by a second, on-platform doctor), and
   *  - the form is ready for the External Doctor to sign (i.e. s20_awaiting_other_doctor)
   */
  const existingJointFormsInS20 = useMemo(() => {
    const forms = assessment.forms?.items || [];
    return forms
      .filter(notEmpty)
      .filter(form => isJointForm(form.type))
      .filter(form => form.authors.length === 1)
      .filter(form => form.status === FormStatus.s20_awaiting_other_doctor)
      .map(form => ({ type: form.type, id: form.id, status: form.status, createdAt: form.createdAt }));
  }, [assessment]);

  /**
   * If a joint form is currently being created by an on-platform doctor, we want to know about it so we can:
   *  - display a message to the AMHP on the Send To External Doctor form, that a joint form is being created and they can send it once ready for the other doctor to sign
   *  - disable all the external form input fields
   */
  const existingJointFormIsBeingCreatedOrUpdated = useMemo(() => {
    const forms = assessment.forms?.items || [];
    return !!forms
      .filter(notEmpty)
      .filter(form => isJointForm(form.type))
      .filter(form => form.authors.length === 1)
      .filter(form => [FormStatus.s10_draft, FormStatus.s30_changes_in_progress].includes(form.status)).length;
  }, [assessment]);

  const onSubmit = useCallback(async (values: SendToDoctorFormValues) => {
    Keyboard.dismiss();
    const { phoneOrEmail, ...rest } = values;
    const body = {
      ...rest,
      assessmentId: assessment.id,
      email: isValidEmail(phoneOrEmail) ? phoneOrEmail : null,
      phone: isValidPhone(phoneOrEmail) ? phoneOrEmail : null,
    };

    const sendToExternalDoctorEndpoint = "/mhaforms/sendToExternalDoctor";
    const sendToExternalDoctorParams = { body };
    const sendToExternalDoctorPromise = () =>
      v2
        ? APIV2.post(sendToExternalDoctorEndpoint, sendToExternalDoctorParams)
        : API.post("RestAPI", sendToExternalDoctorEndpoint, sendToExternalDoctorParams);

    await sendToExternalDoctorPromise()
      .then(() => {
        if (formRef.current) {
          formRef.current.setSubmitting(false);
        }

        setShowSuccessMessage(true);

        recordEvent(AnalyticsEvent.MHA_FORM_AMHP_SENT_EXTERNAL, {
          amhpTeamId: assessment.amhpTeamId,
          assessmentId: assessment.id,
          formId: values.formId ? values.formId : "",
          formType: values.formType,
          usingExistingForm: values.formId ? "true" : "false",
        });
      })
      .catch(err => {
        recordEvent(AnalyticsEvent.MHA_FORM_AMHP_SENT_EXTERNAL_FAILURE);
        formRef && formRef.current && formRef.current.setSubmitting(false);
        buildErr(sendToDoctorFormError, setLastException)(err);
      });
  }, []);

  const { openDialog, closeDialog } = useDialog();

  useEffect(() => {
    if (showSuccessMessage) {
      openDialog({
        heading: "MHA Form Sent",
        message: existingJointFormsInS20.length
          ? [
              `What Happens Next?`,
              `If you are sending a joint medical recommendation form to an off-platform doctor, the off-platform doctor will now be sent a link to login to the S12 Solutions app.`,
              `The off-platform doctor will be able to sign-in, see information entered by the other doctor and sign the form.`,
            ]
          : [
              `What Happens Next?`,
              `The off-platform doctor will now be provided with a link to login to the S12 Solutions app, and complete the form you have given them.`,
              `Once they have filled the form in, they will be able to immediately sign the form (the form will appear in the "Signed" section, in the "Doctor Forms" tab of the Assessment details).`,
              `If you require changes to be made to the form, please contact the off-platform doctor, ask them to sign-in into the app again, remove their signature and edit the form, before re-signing.`,
            ],
        confirmButton: {
          label: "Back to Assessment Details",
          onPress: () => {
            setShowSuccessMessage(false);
            setTimeout(navigation.goBack, 300);
          },
        },
        dismissable: false,
      });
    } else {
      closeDialog();
    }
  }, [showSuccessMessage]);

  const SectionInfoWrapper = isWebView ? ContentWrap : React.Fragment;
  const infoSectionSetyles = useMemo(() => [styles.infoSection, isWebView && styles.infoSectionWeb], [isWebView]);

  return (
    <>
      <ContentWrap>
        <Text format={TypographyType.HeadingMedium}>Send MHA Form</Text>
      </ContentWrap>
      <SectionInfoWrapper>
        {existingJointFormIsBeingCreatedOrUpdated && (
          <View style={infoSectionSetyles}>
            <ItemSpacer gap={10}>
              <Text format={TypographyType.RegularBold}>N.B:</Text>
              <Text format={TypographyType.Regular}>
                A Joint Medical Recommendation for this assessment is currently being completed by an on-platform
                Doctor.
              </Text>
              <Text format={TypographyType.Regular}>
                Once the on-platform Doctor has signed the Joint Medical Recommendation, you will be able to send it to
                an off-platform doctor, for them to view and sign.
              </Text>
            </ItemSpacer>
          </View>
        )}
      </SectionInfoWrapper>

      <SendToDoctorForm
        existingJointFormsInS20={existingJointFormsInS20}
        existingJointFormIsBeingCreatedOrUpdated={existingJointFormIsBeingCreatedOrUpdated}
        ref={formRef}
        onSubmit={onSubmit}
        useJointMedRecs={amhpTeamUsesJointMedRecs(assessment.amhpTeam.featureFlags)}
      />
    </>
  );
};

export default SendToDoctor;

function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

const styles = StyleSheet.create({
  infoSection: {
    backgroundColor: palette.cloud,
    marginBottom: spacing[20],
    padding: spacing[30],
  },
  infoSectionWeb: {
    borderRadius: 10,
  },
});
