import React, { useReducer, createContext, ReactNode, useMemo } from "react";
import { holidayModelReducer, holidayControllerReducer } from "./holidayReducer";
import { holidayModelState, holidayControllerState, StateContext } from "./initialState";

export const HolidayContext = createContext<StateContext>({
  state: { ...holidayModelState, ...holidayControllerState },
  dispatch: () => null,
  controllerDispatch: () => null,
});

function HolidayProvider(props: { children: ReactNode }) {
  const [modelState, dispatch] = useReducer(holidayModelReducer, holidayModelState);

  const [controllerState, controllerDispatch] = useReducer(holidayControllerReducer, holidayControllerState);

  const data = useMemo(
    () => ({
      state: { ...modelState, ...controllerState },
      dispatch,
      controllerDispatch,
    }),
    [modelState, controllerState]
  );

  return <HolidayContext.Provider value={data}>{props.children}</HolidayContext.Provider>;
}

export default HolidayProvider;
