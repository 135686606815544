import React from "react";
import { StyleSheet, Text as RNText, View } from "react-native";

import { AuthScreenProps } from "@/models/AuthScreen";
import { fontSizes, palette, spacing, typography } from "@/theme";
import { TypographyType } from "@/models/Typography";

import { LogoVector as Logo } from "@/components/LogoVector";
import { Button } from "@/components/Button";
import Text from "@/components/Text";

const AuthScreen = (props: AuthScreenProps) => {
  const { buttons = [], children, testID } = props;

  const actions = buttons.map(({ label, onPress, disabled = false, textButton = false, testID }, key, buttons) => {
    const marginBottom = key === buttons.length - 1 ? 0 : 15;
    if (textButton) {
      return (
        <Text
          key={key}
          format={TypographyType.TinyBold}
          onPress={onPress}
          style={[styles.buttonText, { marginBottom }]}
          testID={`AuthScreen__Action-Link__${testID}`}
        >
          {label}
        </Text>
      );
    }

    return (
      <Button
        key={key}
        disabled={disabled}
        onPress={onPress}
        style={[styles.button, { marginBottom }]}
        testID={`AuthScreen__Action-Button__${testID}`}
      >
        {label}
      </Button>
    );
  });

  return (
    <View style={styles.container} testID={`AuthScreen__${testID}`}>
      <View style={styles.loginPanel}>
        <View style={styles.loginPanelContent}>
          {children}
          {buttons && buttons.length > 0 && <View style={styles.loginPanelButtons}>{actions}</View>}
        </View>
      </View>
      <View style={styles.brandPanel}>
        <View style={styles.logo}>
          <Logo size={100} />
          <View style={styles.logoInnerBorder} />
          <View style={styles.logoOuterBorder} />
        </View>
        <RNText style={titleStyle}>S12 Solutions</RNText>
        <RNText style={tagLineStyle}>{`Connecting AMHPs and s.12 doctors`}</RNText>
      </View>
    </View>
  );
};

export default AuthScreen;

const styles = StyleSheet.create({
  brandFont: {
    fontFamily: typography.secondary.medium,
  },

  brandName: {
    marginTop: spacing[50],
    color: palette.white,
    fontSize: fontSizes.h2,
    textTransform: "uppercase",
  },

  brandPanel: {
    backgroundColor: palette.blue,
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },

  brandTagLine: {
    marginTop: spacing[10],
    color: palette.blueFaded,
    fontSize: fontSizes.regular,
    textAlign: "center",
  },

  button: {
    marginBottom: 0,
    width: "100%",
  },
  buttonText: {
    textAlign: "center",
  },

  container: {
    flex: 1,
    flexDirection: "row",
    backgroundColor: palette.aqua,
  },

  loginPanel: {
    minWidth: 450,
    flexBasis: "33.333%",
    backgroundColor: palette.white,
    alignItems: "center",
    justifyContent: "center",
    shadowColor: palette.shadow,
    shadowOffset: { width: 15, height: 0 },
    shadowRadius: 15,
    shadowOpacity: 0.25,
    zIndex: 1,
  },
  loginPanelButtons: {
    marginTop: spacing[50],
  },
  loginPanelContent: {
    minWidth: 450,
    paddingHorizontal: spacing[50],
  },

  logo: {
    position: "relative",
  },
  logoInnerBorder: {
    position: "absolute",
    left: -10,
    top: -10,
    width: "calc(100% + 20px)",
    height: "calc(100% + 20px)",
    zIndex: -1,
    borderWidth: 20,
    borderRadius: 100,
    borderColor: palette.aqua,
    borderStyle: "solid",
    opacity: 0.3,
  },
  logoOuterBorder: {
    position: "absolute",
    left: -20,
    top: -20,
    width: "calc(100% + 40px)",
    height: "calc(100% + 40px)",
    zIndex: -1,
    borderWidth: 16,
    borderRadius: 100,
    borderColor: palette.aqua,
    borderStyle: "solid",
    opacity: 0.15,
  },
});

const titleStyle = [styles.brandFont, styles.brandName];
const tagLineStyle = [styles.brandFont, styles.brandTagLine];
