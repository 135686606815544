/* eslint-disable react-native/no-unused-styles */
import React from "react";
import { StyleSheet, View, ViewStyle } from "react-native";

import ItemSpacer from "../ItemSpacer";
import { spacing } from "../../theme";

interface ButtonListProps {
  children: React.ReactNode;
  disablePadding?: "top" | "bottom" | "topAndBottom";
}

export const ButtonList = ({ children, disablePadding }: ButtonListProps) => {
  const padding = disablePadding ? `${disablePadding}PaddingDisabled` : "defaultPadding";
  return (
    <View style={styles[padding]}>
      <ItemSpacer gap={10}>{children}</ItemSpacer>
    </View>
  );
};

const styles: { [key: string]: ViewStyle } = StyleSheet.create({
  defaultPadding: {
    paddingVertical: spacing[30],
  },

  topPaddingDisabled: {
    paddingTop: 0,
    paddingBottom: spacing[30],
  },

  bottomPaddingDisabled: {
    paddingTop: spacing[30],
    paddingBottom: 0,
  },

  topAndBottomPaddingDisabled: {
    paddingTop: 0,
    paddingBottom: 0,
  },
});
