/* ==========================================================================
   #AUTO LOGOUT WEB
========================================================================== */

/**
 * Automatically log the user out after a set period of time.
 * Accepts a method that handles the logout.
 * Will (optionally) display a warning before the `onLogout` method is called.
 *
 * @author Alex McCabe
 * @link https://www.skptricks.com/2018/12/how-to-implement-auto-logout-client-side-in-react.html
 */

import React, { useCallback, useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { StyleSheet, View } from "react-native";

import { TypographyType } from "@/models/Typography";
import { mqWeb } from "@/utils/helpers";
import { palette, spacing } from "@/theme";

import { DialogButton } from "@/components/#global/Dialog/modules/DialogButton";
import ItemSpacer from "@/components/ItemSpacer";
import Text from "@/components/Text";

export interface PropTypes {
  /**
   * Callback called when the user has been inactive for
   * `logoutThresholdSeconds` (default 1 hour).
   */
  onLogout: () => void;
  /**
   * Time in seconds until the `onLogout` param is called. Defaults to 1 hour.
   */
  logoutThresholdSeconds?: number;
  /**
   * Callback called when the user has been inactive for
   * `logoutThresholdSeconds - warningThresholdSectionds`.
   */
  onWarning?: () => void;
  /**
   * Display a warning message to the user that they are about to be logged out.
   */
  showWarning?: boolean;
  /**
   * Time in seconds *before* the `onLogout` callback is called. Defaults to
   * 5 minutes.
   */
  warningThresholdSeconds?: number;
}

enum Events {
  load,
  mousedown,
  click,
  scroll,
  keypress,
}

type Timeout = ReturnType<typeof setTimeout> | null;

const getWarningTimeMessage = (threshold: number): string => {
  const expiryTime = dayjs().add(threshold, "second");

  return expiryTime.format("HH:mm");
};

dayjs.extend(relativeTime);

/**
 * Set timeouts for when the user has been inactive, which will then call a
 * warning callback (if supplied and enabled) and then the logout callback.
 *
 * A user is described as inactive if they haven't clicked, scrolled or pressed
 * a key on their device. Every time the user fires one of these events, the
 * timers are reset.
 */
const AutoLogoutWeb = (props: PropTypes) => {
  const {
    logoutThresholdSeconds = 1200,
    onLogout,
    onWarning,
    showWarning = true,
    warningThresholdSeconds = 300,
  } = props;

  const isWebView = mqWeb();

  const logoutTimeout = useRef<Timeout>(null);
  const warningTimeout = useRef<Timeout>(null);
  const [isWarningVisible, setWarningVisible] = useState(false);
  const logoutThreshold = logoutThresholdSeconds - warningThresholdSeconds;

  const clearTimeouts = useCallback(() => {
    logoutTimeout.current && clearTimeout(logoutTimeout.current);
    warningTimeout.current && clearTimeout(warningTimeout.current);
  }, []);

  const resetTimeouts = useCallback(() => {
    clearTimeouts();
    setTimeouts();
  }, []);

  const setTimeouts = useCallback(() => {
    logoutTimeout.current = setTimeout(() => {
      setWarningVisible(false);
      onLogout();
      clearTimeouts();
    }, logoutThresholdSeconds * 1000);

    if (showWarning) {
      warningTimeout.current = setTimeout(() => {
        setWarningVisible(true);
        onWarning && onWarning();
      }, logoutThreshold * 1000);
    }
  }, [logoutThresholdSeconds, onWarning, showWarning, warningThresholdSeconds]);

  useEffect(
    function resetTimeoutsOnUserInteraction() {
      Object.values(Events).forEach(event => window.addEventListener(event, resetTimeouts));

      return () => Object.values(Events).forEach(event => window.removeEventListener(event, resetTimeouts));
    },
    [resetTimeouts]
  );

  useEffect(
    function setSetTimeoutsOnFirstMountOrPropsChange() {
      resetTimeouts();

      return clearTimeouts;
    },
    [clearTimeouts, logoutThresholdSeconds, resetTimeouts, warningThresholdSeconds]
  );

  const HeadingFormat = isWebView ? TypographyType.HeadingSmall : TypographyType.RegularBold;
  const TextFormat = isWebView ? TypographyType.Regular : TypographyType.Small;
  const TextFormatBold = isWebView ? TypographyType.RegularBold : TypographyType.SmallBold;

  return (
    <>
      {showWarning && isWarningVisible && (
        <View style={styles.overlay}>
          <View style={styles.warningBoxWrapper}>
            <View style={[styles.warningBox, isWebView && styles.warningBoxWeb]}>
              <Text format={HeadingFormat}>Session Timeout Warning</Text>
              <View style={styles.content}>
                <ItemSpacer gap={15}>
                  <View style={styles.row}>
                    <Text format={TextFormat}>You will be logged out at</Text>
                    <Text format={TextFormatBold}>{` ${getWarningTimeMessage(warningThresholdSeconds)}`}.</Text>
                  </View>
                  <Text format={TextFormat}>Close this notice if you wish to stay logged in.</Text>
                </ItemSpacer>
              </View>
              <DialogButton mode="confirm" label="Keep Me Logged In" onPress={() => setWarningVisible(false)} />
            </View>
          </View>
        </View>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  content: {
    paddingVertical: spacing[30],
  },

  overlay: {
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: palette.sideSheetOverlayBackground,

    /*
      Necessary so timeout modal appears over any open global dialog/bottom sheet
      https://www.youtube.com/watch?v=qJppscXXTEA
    */
    zIndex: 999999999999,
  },

  row: {
    flexDirection: "row",
    flexWrap: "wrap",
  },

  warningBox: {
    maxWidth: 550,
    padding: spacing[30],
    borderRadius: 20,
    backgroundColor: palette.white,
  },
  warningBoxWeb: {
    padding: spacing[40],
  },

  warningBoxWrapper: {
    paddingHorizontal: spacing[10],
  },
});

export default AutoLogoutWeb;
